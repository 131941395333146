<template>
  <button
    class="KogBallStep text-muted margin-right-xxs margin-left-xxs"
    :class="{
      cursorDefault: disabled,
      'KogBallStep--active': state === BALL_STATE.ACTIVE,
      'KogBallStep--splitter': state === BALL_STATE.SPLITTER,
      'KogBallStep--answered': state === BALL_STATE.ANSWERED,
      'KogBallStep--correct': state === BALL_STATE.CORRECT,
      'KogBallStep--incorrect': state === BALL_STATE.INCORRECT,
    }"
    :disabled="disabled"
    :aria-disabled="disabled || state === BALL_STATE.ACTIVE ? 'true' : null"
    :aria-hidden="disabled ? 'true' : null"
    :aria-current="state === BALL_STATE.ACTIVE ? 'step' : null"
    :aria-label="buttonAriaLabel"
    @click="handleClick"
  >
    {{ stepNumber }}
  </button>
</template>

<script>
export const BALL_STATE = {
  ACTIVE: 'active',
  ANSWERED: 'answered',
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  SPLITTER: 'splitter',
};

export default {
  name: 'KogBallStep',
  props: {
    stepNumber: {
      type: [Number, String],
      required: true,
    },
    state: {
      type: String,
      default: '',
      validator: value => Object.values(BALL_STATE).includes(value) || value === '',
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    buttonAriaLabel: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  data() {
    return {
      BALL_STATE,
    };
  },
  computed: {
    disabled() {
      return !this.isClickable || this.state === BALL_STATE.SPLITTER;
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.KogBallStep {
  position: relative;

  width: var(--space-l);
  height: var(--space-l);
  padding: 0;

  color: var(--kogPlatformGray044);

  background-color: var(--kog-colors-grey-100);
  border-color: var(--kogPlatformGray077);
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
}
.KogBallStep:disabled {
  background-color: var(kogPlatformGray077);
}

.KogBallStep:hover:enabled {
  color: var(--kogPlatformGray009);
  border-color: var(--kogPlatformGray009);
}
.KogBallStep--splitter {
  background-color: var(--kogTransparent);
  border-width: 0;
}

.KogBallStep--active {
  background-color: var(--kogTransparent);
  border-color: var(--kogPlatformGray009);
}

.KogBallStep--answered {
  color: var(--kog-colors-light-100);
  background-color: var(--kog-colors-aubergine-900);
  border-width: 0;
}
.KogBallStep--answered:hover:enabled {
  color: var(--kog-colors-light-100);
  border-color: var(--kog-colors-aubergine-400);
  border-width: 1px;
}

.KogBallStep--correct {
  color: var(--kogPlatformGray009);
  background-color: var(--kog-colors-green-500);
  border-width: 0;
}
.KogBallStep--correct:hover:enabled {
  background-color: var(--kog-colors-green-500);
  border-color: var(--kogPlatformGray009);
  border-width: 1px;
}

.KogBallStep--incorrect {
  color: var(--kogPlatformGray009);
  background-color: var(--kog-colors-pink-500);
  border-width: 0;
}
.KogBallStep--incorrect:hover:enabled {
  border-width: 1px;
}
</style>
