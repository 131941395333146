<template>
  <kog-item-list
    role="list"
    :is-handling-tab-index="true"
  >
    <practice-item-result
      v-for="(practiceItem, index) in practiceItems"
      :key="practiceItem.id"
      role="listitem"
      :practice-item="practiceItem"
      :question="getQuestion(practiceItem.question_uuid)"
      :question-index="index + 1"
      :subject="subject"
      :source="source"
      :show-difficulty="showDifficulty"
      :opponent-practice-item="getMatchingOpponentPracticeItem(practiceItem)"
      :opponent-name="opponentName"
    />
  </kog-item-list>
</template>

<script>
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import { shouldShowDifficultyTagForSubject } from 'sharedApp/services/featureflags/fixed-feature-flags.ts';
import PracticeItemResult from 'studyApp/components/practice-item-result.vue';

export default {
  name: 'PracticeItemResults',
  components: {
    PracticeItemResult,
    KogItemList,
  },
  props: {
    practiceItems: {
      type: Array,
      required: true,
    },
    opponentPracticeItems: {
      type: Array,
      default: null,
    },
    opponentName: {
      type: String,
      default: '',
    },
    subject: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      showDifficulty: false,
    };
  },
  created() {
    this.showDifficulty = shouldShowDifficultyTagForSubject(this.subject.id);
  },
  methods: {
    getMatchingOpponentPracticeItem(practiceItem) {
      const question = practiceItem.question || this.getQuestion(practiceItem.question_uuid);
      return (
        this.opponentPracticeItems &&
        this.opponentPracticeItems.find(
          opponentPracticeItem => opponentPracticeItem.question.id === question.id,
        )
      );
    },
    getQuestion(uuid) {
      return this.questions?.find(question => question.uuid === uuid);
    },
  },
};
</script>
