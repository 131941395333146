export function getAssignableNodeCounts(node) {
  if (node.children.length === 0) {
    return { [node.id]: 1 };
  }

  const sectionCounts = {};
  node.children.forEach(childNode => {
    Object.assign(sectionCounts, getAssignableNodeCounts(childNode));
  });

  Object.assign(sectionCounts, {
    [node.id]: node.children.reduce(
      (nodeSectionCount, child) => nodeSectionCount + sectionCounts[child.id],
      0,
    ),
  });

  return sectionCounts;
}
