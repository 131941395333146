import reverse from 'learning/common/libs/kog-router.js';

export const NO_CLASS_SLUG = 'class';

export function getSubjectClassOverviewLink({ classSlug, sid, cid }) {
  return reverse('studyApp', 'newSubjectClassOverview', {
    classSlug: classSlug || NO_CLASS_SLUG,
    sid,
    cid,
  });
}
