<template>
  <div>
    <div class="kog-container-lg">
      <div
        class="PerformanceTaskContainer-wrapper kog-col-12 kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12"
      >
        <kog-loader
          :loading="isLoading"
          flex
        />
        <template v-if="!isLoading">
          <section-header
            :subject-node-id="nodeId"
            :is-in-review-mode="isInReviewMode"
            :is-showing-subtitle="isShowingSubtitle"
            :is-showing-section-tools="!isInViewSubmissionMode"
            :is-showing-activity-statistics="!isInViewSubmissionMode"
            :is-showing-book-statistics="false"
            :is-showing-syllabus-alignments="isShowingSyllabusAlignments"
          />
          <grading-overview
            v-if="isStudent && hasGradings"
            class="padd-left-m margin-top-s"
            :class-id="classId"
            :subject-id="subjectId"
            :node-id="nodeId"
            :show-teacher-view="!isStudent"
          />
          <activity-teacher-instruction
            v-if="isShowingTeacherInstruction"
            :instruction="instruction"
          />
          <div
            ref="ptContent"
            class="PerformanceTaskContainer-content"
          >
            <div
              v-for="contentItem in content"
              :key="contentItem.id"
            >
              <component
                :is="contentType(contentItem)"
                :content="contentItem"
                :user-response-data="currentOccasionResponseByQuestionId[contentItem.question_id]"
                :syllabus-mappings="syllabusMappings"
                :is-in-review-mode="isInReviewMode"
                :is-showing-user-response="isInViewSubmissionMode"
                :is-showing-key="isInReviewMode"
                :is-showing-key-toggle="isInViewSubmissionMode"
                :is-showing-alignments="isInReviewMode"
                :is-showing-rubrics="isInReviewMode"
                :is-disabled="isCurrentOccasionSubmitted"
                :is-rubrics-interactive="isRubricsInteractive"
                :is-showing-rubrics-toggle="isInViewSubmissionMode"
                class="margin-bottom-l padd-top-l"
                :is-showing-response-correctness="isCurrentOccasionGraded"
                @user-response-change="handleUserResponse"
                @rubric-selected="handleRubricSelected"
              />
              <div
                v-if="getShowQuestionMarks(contentItem)"
                class="flexContainer flexContainer-spaceBetween flexContainer-flexEnd margin-bottom-m"
              >
                <div>
                  <kog-input
                    type="text"
                    label="Marks"
                    style-type="prominent"
                    :disabled="true"
                    :size="3"
                    :value="getMarksLabel(contentItem)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <checkpoint-container
      v-if="isShowingActivityFooter"
      :current-section-node="subjectNode"
      :complete-button-icon="submitButtonIcon"
      :complete-button-text="submitButtonText"
      :completion-text="completionText"
      :complete-button-style="submitButtonStyle"
      :is-loading="$wait.is('submitting_performance_task')"
      :is-reset-button-visible="false"
      :is-complete-button-disabled="!canSubmitActivity"
      @completed="trySubmittingPerformanceTask"
    />
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import ActivityImageBlock from 'learning/study/components/activities/activity-image-block.vue';
import { ImageUploadTrackingProvide } from 'learning/study/mixins/image-upload-tracking-mixin.js';
import { RouteNavigationEventInject } from 'learning/study/mixins/route-navigation-event-mixin.js';

import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';
import ActivityDivider from 'sharedApp/components/activity-divider/activity-divider.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import { FILE_STATUS } from 'sharedApp/components/image-upload/file-status.ts';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import { FEATURES_ENUM } from 'sharedApp/const/features.js';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { getEmptyResponseForQuestion } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import ActivityContentBoxBlock from 'studyApp/components/activities/activity-content-box-block.vue';
import ActivityCustomEditorBlock from 'studyApp/components/activities/activity-custom-editor-block.vue';
import ActivityHeadingBlock from 'studyApp/components/activities/activity-heading-block.vue';
import ActivityQuestion from 'studyApp/components/activities/activity-question.vue';
import ActivityTeacherInstruction from 'studyApp/components/activities/activity-teacher-instruction.vue';
import CheckpointContainer from 'studyApp/components/section-text/checkpoint-container.vue';
import SectionHeader from 'studyApp/components/section-text/section-header.vue';
import GradingOverview from 'studyApp/containers/components/grading-overview.vue';
import ActivityMixin from 'studyApp/mixins/activity-mixin.js';
import NodeProgressMixin from 'studyApp/mixins/node-progress-mixin.js';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';
import { PERFORMANCE_TASK_MODULE } from 'studyApp/store/modules/performance-task.js';
import { QUESTION_ACTIVITY_MODULE } from 'studyApp/store/modules/question-activity.js';

export default {
  name: 'PerformanceTaskContainer',
  components: {
    CheckpointContainer,
    KogLoader,
    SectionHeader,
    ActivityTeacherInstruction,
    GradingOverview,
    KogInput,
  },
  mixins: [
    RoutesMixin,
    ActivityMixin,
    ReadingAssignmentMixin,
    NodeProgressMixin,
    ImageUploadTrackingProvide,
    RouteNavigationEventInject,
  ],
  props: {
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
    isInViewSubmissionMode: {
      type: Boolean,
      default: false,
    },
    feature: {
      type: Object,
      required: true,
    },
    isAnonymized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSaveResponseFailed: false,
    };
  },
  computed: {
    ...mapState('userModule', ['user']),
    ...mapState('subjectModule', ['subject']),
    ...mapState(PERFORMANCE_TASK_MODULE, ['ownOccasion', 'performanceTask', 'isCreatingOccasion']),
    ...mapGetters({
      syllabusNodesById: 'syllabusModule/syllabusNodesById',
      subjectNodesById: 'subjectModule/subjectNodesById',
      isSyllabusEnabled: 'subjectModule/isSyllabusEnabled',
    }),
    ...mapGetters(PERFORMANCE_TASK_MODULE, [
      'contentQuestions',
      'currentOccasion',
      'currentOccasionResponseByQuestionId',
      'isProcessingResponses',
    ]),
    subjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    isLoading() {
      return (
        this.$wait.is('loading_performance_task') ||
        this.$wait.is('loading_performance_task_occasion') ||
        this.$wait.is('loading_performance_task_occasions') ||
        this.$wait.is('loading_available_questions') ||
        this.$wait.is('loading_syllabus')
      );
    },
    isSavingResponse() {
      return (
        this.$wait.is('creating_performance_task_occasion') ||
        this.$wait.is('updating_performance_task_occasion')
      );
    },
    hasGradings() {
      const gradings =
        this.contentQuestions?.map(
          element => this.currentOccasionResponseByQuestionId[element.question_id]?.grading,
        ) || [];
      return gradings.some(e => !!e);
    },
    isStudent() {
      return this.user?.isStudent();
    },
    syllabusMappings() {
      return this.syllabusNodesById[this.nodeId];
    },
    featureId() {
      return this.feature.id;
    },
    content() {
      return this.performanceTask?.content || [];
    },
    instruction() {
      return this.performanceTask?.teacher_instruction_html;
    },
    isInTakePerformanceTaskMode() {
      return !this.isInReviewMode && !this.isInViewSubmissionMode;
    },
    isRubricsInteractive() {
      return this.isInViewSubmissionMode && this.isCurrentOccasionSubmitted;
    },
    isShowingSubtitle() {
      return !this.isInViewSubmissionMode;
    },
    isShowingSyllabusAlignments() {
      if (!this.isInReviewMode || this.user.isStudent()) {
        return false;
      }
      return true;
    },
    isShowingTeacherInstruction() {
      return this.isInReviewMode && !this.isInViewSubmissionMode;
    },
    isShowingActivityFooter() {
      return !this.isInReviewMode && !this.isInViewSubmissionMode;
    },
    isAllQuestionsAnswered() {
      const numberOfQuestions = this.contentQuestions.length;
      const numberOfResponses = Object.keys(this.currentOccasionResponseByQuestionId).length;

      if (numberOfQuestions !== numberOfResponses) {
        return false;
      }

      const lengthCheck = toCheck => toCheck.length > 0;
      const objectCheck = toCheck => Object.values(toCheck).every(lengthCheck);

      return Object.values(this.currentOccasionResponseByQuestionId).every(response => {
        const { user_response: userResponse, response_type: responseType } = response;

        if (responseType === RESPONSE_TYPES.IMR) {
          return userResponse && userResponse.file && userResponse.status === FILE_STATUS.DONE;
        }

        if (typeof userResponse === 'string' || Array.isArray(userResponse)) {
          return lengthCheck(userResponse);
        }

        return userResponse && objectCheck(userResponse);
      });
    },
    isCurrentOccasionGraded() {
      return Boolean(this.currentOccasion && this.currentOccasion.grade_sent_at);
    },
    isCurrentOccasionSubmitted() {
      return Boolean(this.currentOccasion && this.currentOccasion.submitted_at);
    },
    submitButtonStyle() {
      return this.isAllQuestionsAnswered ? 'accent' : 'primary';
    },
    completionText() {
      if (this.isCurrentOccasionSubmitted) {
        const formattedDoneAt = dateTimeFormatter(new Date(this.currentOccasion.submitted_at));
        return `Submitted ${formattedDoneAt}`;
      }

      return '';
    },
    isSubmissionInProcess() {
      return this.isCreatingOccasion || this.isProcessingResponses || this.submittingNodeProgress;
    },
    canSubmitActivity() {
      return !this.isSubmissionInProcess && !this.isSavingResponse;
    },
    submitButtonIcon() {
      return this.isSubmissionInProcess ? 'fa-spin fa-loader' : 'fa-paper-plane';
    },
    submitButtonText() {
      if (this.isCreatingOccasion) return 'Creating occasion...';
      if (this.isProcessingResponses) return 'Processing responses...';

      return 'Submit to teacher';
    },
  },
  watch: {
    featureId() {
      this.reloadPerformanceTaskContent();
    },
    isInReviewMode() {
      this.reloadPerformanceTaskContent();
    },
    isLoading(newIsLoading, oldIsLoading) {
      if (newIsLoading === false && oldIsLoading === true) {
        nextTick(() => {
          this.setImmersiveReaderContent(this.$refs.ptContent?.innerHTML);
        });
      }
    },
  },
  created() {
    this.unsubscribe = this.routeEvent.register(() => {
      return new Promise((resolve, reject) => {
        if (!this.isSubmissionInProcess) {
          resolve();
          return;
        }
        // eslint-disable-next-line no-alert
        const answer = window.confirm(
          'Your changes are currently being saved, do you still want to leave?',
        );

        if (answer) {
          this.deletePendingImageUploadResponses();
          resolve();
          return;
        }

        reject();
      });
    });
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('unload', this.handleUnload);
    this.setTrackingContext({
      feature: FEATURES_ENUM.PERFORMANCE_TASK,
      feature_id: this.featureId,
    });
    this.reloadPerformanceTaskContent();
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('unload', this.handleUnload);
  },
  methods: {
    ...mapMutations({
      setImmersiveReaderContent: 'bookModule/setImmersiveReaderContent',
    }),
    ...mapWaitingActions(PERFORMANCE_TASK_MODULE, {
      fetchPerformanceTask: 'loading_performance_task',
      fetchOwnOccasion: 'loading_performance_task_occasion',
      fetchClassOccasions: 'loading_performance_task_occasions',
      submitOwnOccasion: 'submitting_performance_task',
      createOccasionResponse: 'creating_performance_task_occasion',
      updateOccasionResponse: 'updating_performance_task_occasion',
    }),
    ...mapWaitingActions('syllabusModule', {
      fetchSyllabusDetails: 'loading_syllabus',
    }),
    ...mapWaitingActions(QUESTION_ACTIVITY_MODULE, {
      fetchAvailableQuestions: 'loading_available_questions',
    }),
    ...mapActions(PERFORMANCE_TASK_MODULE, [
      'createOccasion',
      'createOccasionResponseForStudent',
      'markOccasionResponse',
      'uploadImage',
      'deletePendingImageUploadResponses',
    ]),
    async fetchSyllabusMappings() {
      const { subject, isSyllabusEnabled } = this;
      if (isSyllabusEnabled) {
        await this.fetchSyllabusDetails(subject.syllabus_id);
      }
    },
    getShowQuestionMarks(contentItem) {
      return this.isStudent && this.isQuestion(contentItem) && !!this.getGrading(contentItem);
    },
    getGrading(item) {
      return this.currentOccasionResponseByQuestionId[item.question_id]?.grading;
    },
    getMarksLabel(item) {
      const grading = this.getGrading(item);
      const actual = grading?.marks || 0;
      const max = grading?.max || 0;
      return `${actual}/${max}`;
    },
    isQuestion(item) {
      return this.contentType(item) === ActivityQuestion;
    },
    contentType(item) {
      switch (item.block_type) {
        case BLOCK_TYPE.QUESTION: {
          return ActivityQuestion;
        }
        case BLOCK_TYPE.CUSTOM_EDITOR: {
          return ActivityCustomEditorBlock;
        }
        case BLOCK_TYPE.CONTENT_BOX: {
          return ActivityContentBoxBlock;
        }
        case BLOCK_TYPE.DIVIDER: {
          return ActivityDivider;
        }
        case BLOCK_TYPE.HEADING: {
          return ActivityHeadingBlock;
        }
        case BLOCK_TYPE.IMAGE: {
          return ActivityImageBlock;
        }
        default:
          throw new Error('Not supported content type', item.block_type);
      }
    },
    reloadPerformanceTaskContent() {
      const showAnswers = this.isInReviewMode;
      this.fetchPerformanceTask(this.featureId);
      this.fetchAvailableQuestions({
        subjectNodeId: this.nodeId,
        showAnswers,
        shuffleSeed: this.featureId,
      });
      if (this.isInReviewMode) {
        this.fetchSyllabusMappings();
      }
      if (this.isInTakePerformanceTaskMode) {
        this.fetchOwnOccasion({
          performanceTaskId: this.featureId,
          subjectClassId: this.classId,
        });
      } else if (this.isInViewSubmissionMode) {
        this.fetchClassOccasions({
          performanceTaskId: this.featureId,
          subjectClassId: this.classId,
        });
      }
    },

    async handleUserResponse({ response, questionId }) {
      if (!this.currentOccasion) {
        this.createOccasion({
          performanceTaskId: this.featureId,
          subjectClassId: this.classId,
        });
      }

      const existingResponse = this.currentOccasionResponseByQuestionId[questionId];
      try {
        if (existingResponse) {
          await this.updateOccasionResponse({ questionId, ...response });
        } else {
          await this.createOccasionResponse({ questionId, ...response });
        }
      } catch (e) {
        this.isSaveResponseFailed = true;
        let msg = 'Saving response failed';
        if (e.code === 'ERR_NETWORK') {
          msg = msg.concat(' due to network error');
        }
        msg = msg.concat('. Please refresh and try again.');
        this.$toast.showError(msg);
        throw e;
      }

      this.handleResponseSaved(response);
    },
    handleResponseSaved(response) {
      if (typeof response?.saveCompleteCallback === 'function') {
        response.saveCompleteCallback();
      }
    },
    async handleRubricSelected({ question, responseId, marks }) {
      const targetUserId = this.currentOccasion.user_id;
      const occasionId = this.currentOccasion.id;
      const existingResponseId = responseId;
      let success = false;
      try {
        let newResponseId;
        if (!existingResponseId) {
          const emptyResponse = getEmptyResponseForQuestion(question);
          newResponseId = await this.createOccasionResponseForStudent({
            occasionId,
            questionId: question.id,
            ...emptyResponse,
          });
        }

        await this.markOccasionResponse({
          performanceTaskOccasionId: occasionId,
          responseId: existingResponseId || newResponseId,
          marks,
        });
        success = true;
      } catch {
        this.$toast.showError('Failed to set the marks. Please refresh the page and try again.');
      }
      if (success) {
        this.$event.track('grade_performance_task_question', {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          subject_node_id: this.nodeId,
          performance_task_id: this.featureId,
          performance_task_occasion_id: this.currentOccasion.id,
          target_user_id: targetUserId,
        });
        if (existingResponseId) {
          this.$mixpanel.trackEventViaBackend('Performance task - Grade question', {
            performance_task_id: this.performanceTask.id,
            performance_task_occasion_id: this.currentOccasion.id,
            student_user_id: targetUserId,
            is_anonymous_grading: this.isAnonymized,
          });
        } else {
          this.$mixpanel.trackEventViaBackend('Performance task - Grade unanswered question', {
            performance_task_id: this.performanceTask.id,
            performance_task_occasion_id: this.currentOccasion.id,
            student_user_id: targetUserId,
          });
        }
      }
    },
    trySubmittingPerformanceTask() {
      const { title, text } = this.getSubmissionConfirmationDialogText(
        'performance task',
        this.isAllQuestionsAnswered,
      );

      this.$modal(KogConfirmModal, {
        title,
        text,
        buttonTitle: 'Yes, submit',
        confirmButtonStyle: 'accent',
        cancelButtonStyle: 'basic',
        onConfirm: this.submitPerformanceTask,
      });
    },
    async submitPerformanceTask() {
      if (this.isSavingResponse) {
        this.$toast.showError('Saving response in progress. Please try again later.');
        return;
      }
      if (this.isSaveResponseFailed) {
        const msg = 'Saving response failed. Please refresh, check your responses and try again.';
        this.$toast.showError(msg);
        return;
      }
      let success = false;
      try {
        if (!this.currentOccasion) {
          await this.createOccasion({
            performanceTaskId: this.featureId,
            subjectClassId: this.classId,
          });
        }
        await this.submitOwnOccasion();
        if (this.isInReadingAssignment) {
          this.showReadingAssignmentModal(this.nodeId);
        }
        this.fetchSubjectNodesProgress(this.subject.id);
        success = true;
      } catch (e) {
        this.$toast.showError('Failed to submit. Please refresh the page and try again.');
        throw e;
      }
      if (success) {
        this.$event.track('submit_performance_task', {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          subject_node_id: this.nodeId,
          performance_task_id: this.featureId,
          performance_task_occasion_id: this.currentOccasion.id,
        });
        this.$mixpanel.trackEventViaBackend('Performance task - Student submitting', {
          performance_task_id: this.featureId,
          performance_task_occasion_id: this.currentOccasion.id,
          school_id: window.KOG?.USER_CONSTANTS.User.school.id,
          source: this.$route.name === 'classReadingAssignmentTake' ? 'assignment' : 'book',
        });
      }
    },
    handleBeforeUnload(event) {
      if (this.isSubmissionInProcess) {
        // Gecko + IE
        // eslint-disable-next-line no-param-reassign
        event.returnValue = false;

        // Safari, Chrome, and other WebKit-derived browsers
        event.preventDefault();
      }
    },
    handleUnload() {
      if (this.isSubmissionInProcess) {
        const useBeacon = true;
        this.deletePendingImageUploadResponses(useBeacon);
      }
    },
  },
};
</script>

<style>
.PerformanceTaskContainer-wrapper {
  counter-reset: divider;
  min-height: 100vh;
}

.PerformanceTaskContainer-content {
  font-family: var(--kog-lato);
}
</style>
