<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :class="classAttr"
    :style="styleAttr"
  >
    <h2
      v-if="hasTitle"
      class="heading-m margin-bottom-l"
    >
      {{ content.title }}
    </h2>
    <div
      v-kog-mathjax
      class="content-editable"
      v-html="content.content_html"
    />
  </div>
</template>

<script>
import useStyleClassExtractedAttrs from 'sharedApp/composables/use-style-class-extracted-attrs.ts';

export default {
  name: 'ActivityCustomEditorBlock',
  inheritAttrs: false,
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const extractedAttrs = useStyleClassExtractedAttrs();

    return {
      ...extractedAttrs,
    };
  },
  computed: {
    hasTitle() {
      return this.content.title && this.content.title !== '';
    },
  },
};
</script>
