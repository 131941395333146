import { ContextualBalloon } from '@ckeditor/ckeditor5-ui';

import inputIcon from 'publishingApp/assets/icons/pen-to-square-regular.svg';

import KogUI from '../utils/kogui.ts';
import { registerFactoryView } from '../utils/plugin-utils.ts';
import FormView from './open-ended-task-form.js';

export default class OpenEndedTaskUI extends KogUI {
  static get requires() {
    return [ContextualBalloon];
  }

  init() {
    this.callbacks = this.editor.config.get('openEndedTask.callbacks');
    this.balloon = this.editor.plugins.get(ContextualBalloon);
    const exerciseQuestions = this.callbacks.getExerciseQuestions();
    this.questions = exerciseQuestions.filter(({ type }) => type === 'LongTextQuestion');

    const command = this.editor.commands.get('insertOpenEndedTask');
    if (this.questions.length === 0) {
      command.forceDisabled();
    }
    const hasQuestions = this.questions?.length > 0;
    const tooltip = hasQuestions
      ? 'Open ended task'
      : 'Create a mapping to a long text question to enable';

    registerFactoryView(this.editor, {
      name: 'openEndedTask',
      commandName: 'insertOpenEndedTask',
      icon: inputIcon,
      label: 'Open ended task',
      onExecute: () => this.showUI(),
      tooltip,
    });

    const updateCommand = this.editor.commands.get('updateOpenEndedTask');
    this.listenTo(updateCommand, 'execute', () => {
      this.showUI();
    });
  }

  showUI() {
    if (!this.formView) {
      this.createFormView();
    }
    const commandValue = this.editor.commands.get('insertOpenEndedTask').value;

    if (commandValue && commandValue.task) {
      const { question } = commandValue.task;
      this.formView.setFields({ questionId: question.id });
    }

    super.showUI();
  }

  createFormView() {
    this.formView = new FormView(this.editor.locale, this.questions);

    const onSubmit = () => {
      const questionId = this.formView.selectedQuestionId;
      this.editor.execute('insertOpenEndedTask', questionId);
      this.hideUI();
    };

    this.setupFormView(onSubmit);
  }
}
