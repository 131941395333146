<template>
  <div class="ActivitySheetPrint-wrapper kog-col-12 kog-col-sm-12">
    <kog-loader
      :loading="isLoading"
      flex
    />
    <template v-if="!isLoading">
      <book-print-header />
      <div class="ActivitySheetPrintContainer ActivitySheetPrintContainer--external">
        <activity-sheet-external-content-frame
          v-if="hasActiveExternalContentFrame"
          class="ActivitySheetPrintContainer-externalContent"
          :content-frame="activitySheet.activity_sheet_external_content_frame"
          :subject="subject"
          :no-min-height="true"
        />
        <div class="ActivitySheetPrintContainer-activitySheetContentWrapper">
          <div class="ActivitySheetPrint-content BookPrint-sectionContent">
            <component
              :is="contentType(contentItem)"
              v-for="contentItem in content"
              :key="contentItem.id"
              :content="contentItem"
              :is-in-review-mode="false"
              class="margin-bottom-l padd-top-l"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';
import ActivityDivider from 'sharedApp/components/activity-divider/activity-divider.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import { mapWaitingActions as mapWaitingPiniaActions } from 'sharedApp/libs/pinia.ts';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ActivityContentBoxBlock from 'studyApp/components/activities/activity-content-box-block.vue';
import ActivityCustomEditorBlock from 'studyApp/components/activities/activity-custom-editor-block.vue';
import ActivityHeadingBlock from 'studyApp/components/activities/activity-heading-block.vue';
import ActivityImageBlock from 'studyApp/components/activities/activity-image-block.vue';
import ActivityQuestion from 'studyApp/components/activities/activity-question.vue';
import ActivitySheetExternalContentFrame from 'studyApp/components/activities/activity-sheet-external-content-frame.vue';
import BookPrintHeader from 'studyApp/components/book-print-header.vue';
import SectionHeader from 'studyApp/components/section-text/section-header.vue';
import useActivitySheetStore from 'studyApp/store/modules/activity-sheet/store.ts';
import { QUESTION_ACTIVITY_MODULE } from 'studyApp/store/modules/question-activity.js';

export default {
  name: 'ActivitySheetPrint',
  components: {
    BookPrintHeader,
    KogLoader,
    SectionHeader,
    ActivitySheetExternalContentFrame,
    KogConfirmModal,
  },
  mixins: [RoutesMixin],
  emits: ['ready-to-print'],
  computed: {
    ...mapPiniaState(useActivitySheetStore, ['activitySheet']),
    ...mapState({
      subject: state => state.subjectModule.subject,
    }),
    ...mapGetters({
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    isLoading() {
      return (
        this.$wait.is('loading_activity_sheet') || this.$wait.is('loading_available_questions')
      );
    },
    feature() {
      return this.getActivityBySubjectNodeId(this.nodeId);
    },
    featureId() {
      return this.feature.id;
    },
    content() {
      return this.activitySheet?.content || [];
    },
    hasActiveExternalContentFrame() {
      return !!this.activitySheet?.activity_sheet_external_content_frame?.is_active;
    },
  },
  async created() {
    await Promise.all([
      this.fetchActivitySheet(this.featureId),
      this.fetchAvailableQuestions({ subjectNodeId: this.nodeId }),
    ]);
    this.$emit('ready-to-print');
  },
  methods: {
    ...mapWaitingActions(QUESTION_ACTIVITY_MODULE, {
      fetchAvailableQuestions: 'loading_available_questions',
    }),
    ...mapWaitingPiniaActions(useActivitySheetStore, {
      fetchActivitySheet: {
        action: 'fetchActivitySheet',
        loader: 'loading_activity_sheet',
      },
    }),
    contentType(item) {
      switch (item.block_type) {
        case BLOCK_TYPE.QUESTION: {
          return ActivityQuestion;
        }
        case BLOCK_TYPE.CUSTOM_EDITOR: {
          return ActivityCustomEditorBlock;
        }
        case BLOCK_TYPE.CONTENT_BOX: {
          return ActivityContentBoxBlock;
        }
        case BLOCK_TYPE.DIVIDER: {
          return ActivityDivider;
        }
        case BLOCK_TYPE.HEADING: {
          return ActivityHeadingBlock;
        }
        case BLOCK_TYPE.IMAGE: {
          return ActivityImageBlock;
        }
        default:
          throw new Error('Not supported content type', item.block_type);
      }
    },
  },
};
</script>

<style scoped>
.ActivitySheetPrint-wrapper {
  counter-reset: divider;
  min-height: 100vh;
  word-wrap: break-word;
}

.ActivitySheetPrint-wrapper img {
  height: auto;
}

.ActivitySheetPrint-content {
  font-family: var(--kog-lato);
}

.ActivitySheetPrintContainer-externalContent {
  grid-area: externalContent;
}

.ActivitySheetPrintContainer-externalContent :deep(.content-editable) {
  height: 100%;
}

.ActivitySheetPrintContainer-externalContent :deep(.content-editable p) {
  height: 100%;
  margin: 0;
}

.ActivitySheetPrintContainer-externalContent :deep(.content-editable iframe) {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
}

@media (min-width: 1200px) {
  .ActivitySheetPrintContainer-activitySheetContentWrapper {
    grid-area: activitySheetContent;
  }

  .ActivitySheetPrintContainer-externalContent {
    grid-area: externalContent;
  }
}
</style>
