<template>
  <div>
    <div
      role="button"
      :aria-label="itemAriaLabel"
      :aria-expanded="isOpen ? 'true' : 'false'"
      :class="headerClass"
      @click="toggleAccordion"
    >
      <slot name="closedContent" />
    </div>
    <vertical-expand>
      <div
        v-show="isOpen"
        :class="bodyClass"
      >
        <slot name="body" />
      </div>
    </vertical-expand>
  </div>
</template>

<script>
import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';

export default {
  name: 'KogAccordionItem',
  components: {
    VerticalExpand,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    toggleAccordion: {
      type: Function,
      default: () => {},
    },
    headerClass: {
      type: String,
      default: undefined,
    },
    bodyClass: {
      type: String,
      default: undefined,
    },
    itemAriaLabel: {
      type: String,
      default: undefined,
    },
  },
};
</script>
