<template>
  <kog-select
    :selected-value="value"
    :options="options"
    is-dropdown-hosted-in-parent-container
    @change="onChange"
  />
</template>

<script>
import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import { countries } from 'sharedApp/const/countries.js';

import arrayAsOptions from './array-as-options.js';

export default {
  name: 'CountriesDropdown',
  components: {
    KogSelect,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    allCountriesLabel: {
      type: String,
      default: 'All countries',
    },
    popularCountries: {
      type: Array,
      required: true,
    },
    popularCountriesLabel: {
      type: String,
      default: 'Default',
    },
  },
  emits: ['update:value'],
  computed: {
    options() {
      const options = [];
      options.push(...this.getOptionGroups());
      return options;
    },
  },
  created() {
    this.countries = countries;
  },
  methods: {
    onChange(value) {
      this.$emit('update:value', value);
    },
    getOptionGroups() {
      return [
        {
          text: this.popularCountriesLabel,
          options: arrayAsOptions(this.popularCountries),
        },
        {
          text: this.allCountriesLabel,
          options: arrayAsOptions(countries),
        },
      ];
    },
  },
};
</script>
