import { Plugin } from '@ckeditor/ckeditor5-core';

import AnyBoxEditing from './any-box-editing.js';
import AnyBoxUI from './any-box-ui.js';

export default class AnyBox extends Plugin {
  static get requires() {
    return [AnyBoxEditing, AnyBoxUI];
  }
}
