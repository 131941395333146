import { ViewCollection } from '@ckeditor/ckeditor5-ui';

import FilteredDropdownView from '../shared/filtered-dropdown-view.ts';
import FlexWrapperView from '../shared/flex-wrapper-view.ts';
import KogForm from '../utils/kogform.js';
import { htmlToText } from '../utils/plugin-utils.ts';

import './glossary-link.css';

const DEFAULT_DEFINITION_LABEL = 'Definition';
export default class FormView extends KogForm {
  constructor(locale, definitions) {
    super(locale);

    this.textInputView = this.createInput('Linked text');
    this.textInputView.fieldView.on('change:isEmpty', () => this.updateUI());

    const linkedDefinitions = definitions.map(({ id, term, definitions: termDefinitions }) => {
      const definition = termDefinitions[0];
      const definitionText = htmlToText(definition.definition_html.trim());
      return [`${term}: ${definitionText}`, id];
    });

    this.definitionDropdown = new FilteredDropdownView(this.locale, {
      label: DEFAULT_DEFINITION_LABEL,
      values: linkedDefinitions,
      enableTooltips: true,
    });

    const termDefinitionMap = new Map(definitions.map(term => [term.id, term.definitions[0].id]));

    this.definitionDropdown.on('change:selectedValue', (_event, _name, selectedTermId) => {
      this.selectedTermId = selectedTermId;
      this.selectedDefinitionId = termDefinitionMap.get(selectedTermId);
      this.updateUI();
    });

    this.saveButtonView = this.createSaveButton();
    this.cancelButtonView = this.createCancelButton();
    this.saveButtonView.set({ isEnabled: false });

    this.childViews = this.createCollection();
    const generalView = new FlexWrapperView(this.locale, {
      direction: 'col',
      children: [this.textInputView, this.definitionDropdown],
    });
    this.childViews.add(generalView);

    this.childViews.add(
      new FlexWrapperView(this.locale, {
        direction: 'row',
        children: [this.saveButtonView, this.cancelButtonView],
      }),
    );

    this.focusables = new ViewCollection([
      this.saveButtonView,
      this.cancelButtonView,
      this.textInputView,
      this.definitionDropdown,
    ]);

    this.setup('glossary-link', this.focusables);
  }

  updateUI() {
    const isTextEmpty = this.textInputView.isEmpty;
    const isDefinitionSelected = this.selectedTermId !== null;
    this.saveButtonView.set({ isEnabled: !isTextEmpty && isDefinitionSelected });
  }

  reset() {
    super.reset();
    this.textInputView.fieldView.value = '';
    this.saveButtonView.set({ isEnabled: false });
    this.selectedTermId = null;
    this.selectedDefinitionId = null;
    this.definitionDropdown.reset();
  }

  focus() {
    if (this.textInputView.isEnabled) {
      this.textInputView.focus();
    } else {
      this.definitionDropdown.focus();
    }
  }

  setFields({ linkedText, termId, definitionId }) {
    this.textInputView.fieldView.value = linkedText;
    if (termId) {
      this.selectedTermId = Number(termId);
      this.selectedDefinitionId = Number(definitionId);
      this.definitionDropdown.setSelectedValue(Number(termId));
    }
  }
}
