<template>
  <div class="ShortTextQuestionCorrectAnswers">
    <p :class="titleClasses"> Accepted answers </p>
    <ul class="content-editable">
      <!-- eslint-disable vue/no-v-html -->
      <li
        v-for="(answer, index) in correctAnswers"
        :key="index"
        v-html="answer"
      />
      <!-- eslint-enable vue/no-v-html -->
    </ul>
    <secondary-accepted-answers :secondary-answers="secondaryAcceptedAnswers" />
    <p
      v-if="answerExplanationHtml || notifyAboutMissingExplanation"
      class="divider-top padd-top-xl margin-top-l heading-s margin-bottom-xs"
    >
      Explanation:
    </p>
    <content-container
      v-if="answerExplanationHtml"
      v-kog-mathjax
      :content="answerExplanationHtml"
    />
    <p v-if="!answerExplanationHtml && notifyAboutMissingExplanation">
      <em>No answer explanation provided.</em>
    </p>
  </div>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import ContentContainer from 'sharedApp/components/content/content-container.vue';
import SecondaryAcceptedAnswers from 'sharedApp/components/questions/secondary-accepted-answers.vue';

export default {
  name: 'KogShortTextQuestionCorrectAnswers',
  components: {
    ContentContainer,
    SecondaryAcceptedAnswers,
  },
  props: {
    correctAnswers: {
      type: Array,
      required: true,
    },
    secondaryAcceptedAnswers: {
      type: Array,
      default: () => [],
    },
    answerExplanationHtml: {
      type: String,
      required: true,
    },
    notifyAboutMissingExplanation: {
      type: Boolean,
      default: true,
    },
    titleClasses: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.ShortTextQuestionCorrectAnswers {
  display: block;

  margin: 0;
  padding: 0;

  background: none;
  border: none;
}
</style>
