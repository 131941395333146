<template>
  <div
    v-if="hasAlignments"
    class="margin-bottom-s"
  >
    <span
      v-if="showHeader"
      class="text-overline"
      >Alignments</span
    >
    <div class="AlignmentList gap-xs">
      <div
        v-for="(alignmentList, alignmentName, aIndex) of syllabusAlignments"
        :key="`alignment-${aIndex}`"
        class="AlignmentList-row gap-xs"
      >
        <span class="heading-xxs font-satoshi text-uppercase">
          {{ alignmentName }}
        </span>
        <div class="AlignmentList-values gap-xs">
          <syllabus-alignment-tag
            v-for="(alignment, vIndex) of alignmentList"
            :key="`alignment-${aIndex}_value-${vIndex}`"
            :name="alignment.name"
            :short-name="alignment.short_name"
            :tag-color="alignment.color_computed"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy.js';
import isNil from 'lodash/isNil.js';
import { mapGetters, mapState } from 'vuex';

import SyllabusAlignmentTag from 'sharedApp/components/tags/syllabus-alignment-tag.vue';

export default {
  name: 'AlignmentList',
  components: {
    SyllabusAlignmentTag,
  },
  props: {
    syllabusNodes: {
      type: Array,
      default: () => [],
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      getAncestorContainerNode: 'syllabusModule/getAncestorContainerNode',
      syllabusNodesById: 'syllabusModule/syllabusNodesById',
    }),
    ...mapState({
      syllabusNodeRootDetails: state => state.syllabusModule.syllabusNodeRootDetails,
      user: state => state.userModule.user,
    }),
    syllabusAlignments() {
      if (!isNil(this.syllabusNodeRootDetails) && this.syllabusNodes.length > 0) {
        return this.alignmentsFromSyllabus;
      }
      return undefined;
    },
    hasAlignments() {
      if (isNil(this.syllabusAlignments)) {
        return false;
      }
      return Object.keys(this.syllabusAlignments).length > 0;
    },
    alignmentsFromSyllabus() {
      const mappedSyllabusNodes = this.syllabusNodes.map(
        syllabusNodeId => this.syllabusNodesById[syllabusNodeId],
      );
      const getAncestorContainerNodeId = syllabusNode => {
        const ancestorNode = this.getAncestorContainerNode(syllabusNode.id);
        return ancestorNode.id;
      };
      const mappingsGroupedByAncestorId = groupBy(mappedSyllabusNodes, getAncestorContainerNodeId);
      // In JS objects' keys are ordered by the creation time, so the goal here is to create
      // the final object based on the order induced by the 'order' field.
      const getOrderWithLastPositionAsDefault = syllabusNode =>
        syllabusNode.order || Number.MAX_SAFE_INTEGER;
      const orderBasedComparator = (node1Id, node2Id) => {
        const node1 = this.syllabusNodesById[node1Id];
        const node2 = this.syllabusNodesById[node2Id];
        return getOrderWithLastPositionAsDefault(node1) - getOrderWithLastPositionAsDefault(node2);
      };
      const orderedContainerIds = Object.keys(mappingsGroupedByAncestorId).sort(
        orderBasedComparator,
      );
      return orderedContainerIds.reduce((result, containerNodeId) => {
        const containerName = this.syllabusNodesById[containerNodeId].short_name;
        const nodesUnderContainer = [];
        mappingsGroupedByAncestorId[containerNodeId].forEach(mapping => {
          nodesUnderContainer.push(mapping);
        });
        return {
          ...result,
          [containerName]: nodesUnderContainer,
        };
      }, {});
    },
  },
};
</script>

<style>
.AlignmentList,
.AlignmentList-row,
.AlignmentList-values {
  display: grid;
}

.AlignmentList-row {
  grid-template-columns: 50px auto;
}

.AlignmentList-values {
  grid-auto-flow: column;
  justify-content: start;
}
</style>
