import * as Sentry from '@sentry/vue';
import { isNil, keyBy } from 'lodash';

import { fetchSubjectClassStudent } from '@apis/students';

import { getLeafNodes, getSubjectNodeMap } from 'sharedApp/libs/subject-tree-functions.js';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import {
  fetchAllSubjects,
  fetchLastSectionOpened,
  fetchSubject,
  fetchSubjectList,
} from 'studyApp/api/subject.js';

export default {
  namespaced: true,
  state: {
    subject: {},
    subjectMap: {},
    level: null,
    lastOpenedSubjectNode: null,
    subjectList: [],
    allSubjects: [],
  },
  getters: {
    hasSubject(state) {
      return !!(state.subject && state.subject.id);
    },
    rootNode(state) {
      if (!state.subject.subject_tree) return {};
      return state.subject.subject_tree[0];
    },
    topics(state, getters) {
      return getters.rootNode.children;
    },
    subtopics(state, getters) {
      let subtopics = [];
      getters.topics.forEach(topic => {
        subtopics = subtopics.concat(topic.children);
      });
      return subtopics;
    },
    sections(state, getters) {
      let sections = [];
      getters.subtopics.forEach(subtopic => {
        sections = sections.concat(subtopic.children);
      });
      return sections;
    },
    subsections(state, getters) {
      let subsections = [];
      getters.sections.forEach(section => {
        subsections = subsections.concat(section.children);
      });
      return subsections;
    },
    subjectNodesById(state, getters) {
      const subjectNodes = [
        ...getters.topics,
        ...getters.subtopics,
        ...getters.sections,
        ...getters.subsections,
      ];
      return keyBy(subjectNodes, 'id');
    },
    getTopicNodeByDescendantId(state, getters) {
      return subjectNodeId => {
        let currentSubjectNode = getters.subjectNodesById[subjectNodeId];
        while (!!currentSubjectNode && currentSubjectNode.level > 1) {
          currentSubjectNode = getters.subjectNodesById[currentSubjectNode.parent];
        }
        return currentSubjectNode;
      };
    },
    getSubtopicNodeByDescendantId(state, getters) {
      return subjectNodeId => {
        let currentSubjectNode = getters.subjectNodesById[subjectNodeId];
        while (!!currentSubjectNode && currentSubjectNode.level > 2) {
          currentSubjectNode = getters.subjectNodesById[currentSubjectNode.parent];
        }
        return currentSubjectNode;
      };
    },
    subtopicIds(_state, getters) {
      let subtopics = [];
      getters.topics.forEach(subtopic => {
        subtopics = subtopics.concat(subtopic.children);
      });
      return subtopics.map(subtopic => subtopic.id);
    },
    subjectNodeIds(state, getters) {
      let subjectNodeIDs = [];
      subjectNodeIDs = subjectNodeIDs.concat(getters.topics.map(topic => topic.id));
      subjectNodeIDs = subjectNodeIDs.concat(getters.subtopics.map(subtopic => subtopic.id));
      return subjectNodeIDs;
    },
    leafNodesWithContent(state) {
      if (!state.subject.subject_tree) return [];

      const subjectTree = state.subject.subject_tree[0];
      return getLeafNodes(subjectTree).filter(lf => !!lf.section_id || !!lf.activity);
    },
    previousLeafNodeWithContent(state, getters) {
      return currentNodeId => {
        const leafNodes = getters.leafNodesWithContent;
        const currentNodeIndex = leafNodes.findIndex(node => node.id === currentNodeId);
        if (currentNodeIndex === 0 || currentNodeIndex === -1) {
          return null;
        }
        return leafNodes[currentNodeIndex - 1];
      };
    },
    nextLeafNodeWithContent(state, getters) {
      return currentNodeId => {
        const leafNodes = getters.leafNodesWithContent;
        const currentNodeIndex = leafNodes.findIndex(node => node.id === currentNodeId);
        if (currentNodeIndex === leafNodes.length - 1 || currentNodeIndex === -1) {
          return null;
        }
        return leafNodes[currentNodeIndex + 1];
      };
    },
    findTopicNodeBySlug(state) {
      return topicSlug => state.subject.subject_tree[0].children.find(n => n.slug === topicSlug);
    },
    findSubtopicNodeBySlug() {
      return (topicNode, subtopicSlug) => topicNode.children.find(n => n.slug === subtopicSlug);
    },
    subjectsById(state) {
      return keyBy(state.subjectList, 'id');
    },
    allSubjectsById(state) {
      return keyBy(state.allSubjects, 'id');
    },
    educationSystemName(state) {
      return state.subject.educationsystem.name;
    },
    isNgssSubject(state, getters) {
      return isNgss(getters.educationSystemName);
    },
    isSyllabusEnabled(state) {
      return !isNil(state.subject.syllabus_id) && state.subject.syllabus_status === 'PUBLISHED';
    },
  },
  mutations: {
    setSubjectList(state, subjectList) {
      state.subjectList = subjectList;
    },
    setAllSubjects(state, allSubjects) {
      state.allSubjects = allSubjects;
    },
    setSubject(state, subject) {
      state.subject = subject;
    },
    setSubjectMap(state, subjectMap) {
      state.subjectMap = subjectMap;
    },
    setLevel(state, level) {
      state.level = level;
    },
    setLastOpenedSubjectNode(state, node) {
      state.lastOpenedSubjectNode = node;
    },
  },
  actions: {
    async fetchAllSubjects({ commit }) {
      commit('setAllSubjects', await fetchAllSubjects());
    },
    async fetchSubjectList({ commit }, subjectIds = []) {
      commit('setSubjectList', await fetchSubjectList(subjectIds));
    },
    async fetchSubject(
      { commit },
      { subjectSlug, excludeHiddenNodesForSubjectClassId = undefined },
    ) {
      try {
        const subject = await fetchSubject(subjectSlug, excludeHiddenNodesForSubjectClassId);
        const subjectMap = getSubjectNodeMap(subject.subject_tree[0]);
        commit('setSubject', subject);
        commit('setSubjectMap', subjectMap);
      } catch (error) {
        Sentry.captureMessage(`fetchSubject: ${error}`);
      }
    },
    async fetchSubjectclassDetails({ commit }, { schoolId, subjectClassId, studentId }) {
      const data = await fetchSubjectClassStudent(schoolId, subjectClassId, studentId);
      commit('setLevel', data.level);
    },
    async fetchLastSectionOpened({ commit }, subjectId) {
      try {
        const lastSectionOpened = await fetchLastSectionOpened(subjectId);
        commit('setLastOpenedSubjectNode', lastSectionOpened);
      } catch (error) {
        Sentry.captureMessage(`fetchLastSectionOpened: ${error}`);
      }
    },
  },
};
