<template>
  <div>
    <div v-if="!selectedNode">
      <button
        class="KogButtonLegacy KogButtonLegacy--primary"
        @click="openChooseTopicModal"
      >
        Choose a {{ $term('topic') }}
      </button>
      <kog-button
        class="margin-left-s"
        label="Random topic"
        button-style="primary"
        :has-icon="true"
        icon-class="fa-dice"
        icon-style="solid"
        @click="selectRandom"
      />
    </div>
    <div
      v-if="selectedNode"
      class="flexContainer flexContainer-row"
    >
      <span class="ChooseTopic-topicName flexChild-noGrow">{{ selectedNode.name }}</span>
      <kog-round-button
        ref="clearButton"
        :aria-label="changeButtonAriaLabel"
        icon-class="fa-xmark"
        size="x-small"
        icon-size="xs"
        button-style="basic"
        tooltip="Clear"
        @click="clearSelectedTopic"
      />
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';

import ChooseTopicModal from './choose-topic-modal.vue';

export default {
  name: 'ChooseTopic',
  components: {
    KogRoundButton,
    KogButton,
  },
  props: {
    selectedNode: {
      type: Object,
      default: null,
    },
    availableTopics: {
      type: Array,
      required: true,
    },
  },
  emits: ['topicSelected', 'topicCleared'],
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
    }),
    changeButtonAriaLabel() {
      return `Selected topic: ${this.selectedNode.name}. Clear selected topic.`;
    },
    topics() {
      return this.subject.subject_tree[0].children;
    },
  },
  methods: {
    openChooseTopicModal() {
      this.$modal(ChooseTopicModal, {
        onSelection: this.onSelection,
        availableTopics: this.availableTopics,
        onModalClose: this.onModalClose,
      });
    },
    clearSelectedTopic() {
      this.$emit('topicCleared');
    },
    onModalClose() {
      nextTick(() => {
        if (this.$refs.clearButton) {
          this.$refs.clearButton.$refs.root.focus();
        }
      });
    },
    onSelection(topic) {
      this.$emit('topicSelected', topic);
    },
    selectRandom() {
      const randomTopicIndex = Math.floor(Math.random() * this.availableTopics.length);
      const randomTopic = this.topics.find(
        topic => topic.id === this.availableTopics[randomTopicIndex],
      );
      this.onSelection(randomTopic);
    },
  },
};
</script>

<style scoped>
.ChooseTopic-topicName {
  padding-right: var(--space-s);
}
</style>
