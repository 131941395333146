<template>
  <div class="margin-bottom-xs">
    <div class="StudentResponseAccordionItem border-radius-8 padd-s">
      <kog-accordion-item
        :is-open="isOpen"
        :toggle-accordion="toggleIsOpen"
      >
        <template #closedContent>
          <div
            class="cursorPointer flexContainer flexContainer-spaceBetween flexContainer-alignCenter"
          >
            <div>
              <kog-tag
                class="margin-right-xs"
                :is-chip="true"
                :label="attemptsTagLabel"
                :type="attemptsTagType"
              />
              <span
                v-if="hasAttempts"
                class="text-small muted"
              >
                Last attempt: {{ getLatestAttemptDate(questionResponses) }}
              </span>
            </div>
            <div>
              <kog-icon
                :icon-class="isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
                fa-style="regular"
                size="s"
              />
            </div>
          </div>
          <div
            v-if="!isOpen"
            v-kog-mathjax
            class="StudentResponseAccordionItem-questionPreview margin-top-xs"
            v-text="getQuestionPreview(questionResponses)"
          />
        </template>
        <template #body>
          <div class="margin-top-xs">
            <question-text-preview
              class="margin-bottom-m"
              :question="question"
            />
            <div
              v-for="attempt in questionResponses.attempts"
              :key="attempt.id"
              class="flexContainer margin-bottom-xs"
            >
              <kog-icon
                theme="custom"
                class="StudentResponseAccordionItem-icon margin-right-xs"
                :class="{
                  'StudentResponseAccordionItem-icon--correct': attempt.correct,
                  'StudentResponseAccordionItem-icon--incorrect': !attempt.correct,
                }"
                :icon-class="attempt.correct ? 'fa-check' : 'fa-times'"
                fa-style="regular"
                size="s"
              />
              <div class="flexChild-size-1 margin-right-xs">
                <!-- eslint-disable vue/no-v-html -->
                <div
                  v-for="(answer, index) in attempt.user_answer"
                  :key="`${answer}-${index}`"
                  v-kog-mathjax
                  v-html="answer"
                />
                <!-- eslint-enable vue/no-v-html -->
              </div>
              <span class="muted text-small">
                {{ getFormattedDate(attempt.submitted_at) }}
              </span>
            </div>
          </div>
        </template>
      </kog-accordion-item>
    </div>
  </div>
</template>

<script>
import QuestionTextPreview from 'learning/common/components/question-text-preview.vue';

import KogAccordionItem from 'sharedApp/components/accordion/kog-accordion-item.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { dangerouslyGetUnescapedText } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { dateStringFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'StudentResponseAccordionItem',
  components: {
    KogAccordionItem,
    KogIcon,
    KogTag,
    QuestionTextPreview,
  },
  props: {
    questionResponses: {
      type: Object,
      required: true,
    },
  },
  emits: ['toggle-accordion'],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    hasAttempts() {
      return this.questionResponses.attempts.length > 0;
    },
    attemptsTagLabel() {
      if (!this.hasAttempts) {
        return 'No attempts';
      }

      return `${this.questionResponses.attempts.length}
      ${this.questionResponses.attempts.length === 1 ? 'attempt' : 'attempts'}`;
    },
    question() {
      return this.questionResponses.question ?? this.questionResponses;
    },
    attemptsTagType() {
      if (!this.hasAttempts) {
        return 'informative';
      }

      if (this.questionResponses.attempts[0].correct) {
        return 'positive';
      }

      return 'negative';
    },
  },
  methods: {
    getFormattedDate(date) {
      return dateStringFormatter(date);
    },
    getQuestionPreview(questionResponseItem) {
      if (questionResponseItem.question_html) {
        return dangerouslyGetUnescapedText(questionResponseItem.question_html);
      }

      return dangerouslyGetUnescapedText(questionResponseItem.question.question_html);
    },
    getLatestAttemptDate(questionResponseItem) {
      if (questionResponseItem.latestAttempt) {
        return this.getFormattedDate(questionResponseItem.latestAttempt);
      }

      return this.getFormattedDate(questionResponseItem.attempts[0].submitted_at);
    },
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle-accordion', this.isOpen);
    },
  },
};
</script>

<style scoped>
.StudentResponseAccordionItem-questionPreview :deep(div) {
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.StudentResponseAccordionItem {
  border: 1px solid #cbcbc9;
}

.StudentResponseAccordionItem :deep(img) {
  max-width: 100%;
  height: auto;
}

.StudentResponseAccordionItem :deep(.content-editable),
.StudentResponseAccordionItem :deep(.content-editable p),
.StudentResponseAccordionItem :deep(.content-editable table),
.StudentResponseAccordionItem :deep(.content-editable table p),
.StudentResponseAccordionItem :deep(.content-editable .extendedcontentbox > .anybox-content),
.StudentResponseAccordionItem :deep(.content-editable .extendedcontentbox > .anybox-content > p),
.StudentResponseAccordionItem :deep(.content-editable .anybox > .anybox-content),
.StudentResponseAccordionItem :deep(.content-editable .anybox > .anybox-content p) {
  overflow-x: hidden;

  margin-bottom: var(--space-xs);

  font-family: var(--kog-lato);
  font-size: var(--kog-font-size-default);
  line-height: 1.42857143;
}

.StudentResponseAccordionItem :deep(.content-editable .anybox .anybox-title > *) {
  font-size: 16px;
}

.StudentResponseAccordionItem-icon {
  min-width: 24px;
  height: 24px;
  border-radius: 100px;
}

.StudentResponseAccordionItem-icon--correct {
  background-color: var(--kog-colors-green-500);
}

.StudentResponseAccordionItem-icon--incorrect {
  background-color: var(--kog-colors-pink-500);
}
</style>
