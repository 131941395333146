<template>
  <div
    v-on-click-outside="hideMenu"
    class="KebabMenu"
  >
    <kog-kebab-button
      ref="kebabButton"
      :pressed="menuOpen"
      :horizontal="horizontal"
      @click.stop="toggleMenuOpen"
      @keydown.enter.stop="toggleMenuOpen"
    />
    <div class="KebabMenu-dropdownContainer">
      <div
        v-if="menuOpen"
        ref="kebabDropdown"
        class="KebabMenu-absoluteContainer z-index-5"
      >
        <slot
          :hide-menu="hideMenu"
          name="kebabMenuBody"
        />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { nextTick } from 'vue';
import { vOnClickOutside } from '@vueuse/components';

import KogKebabButton from 'sharedApp/components/kebab-menu/kog-kebab-button.vue';

export default {
  name: 'KogKebabMenu',
  directives: {
    onClickOutside: vOnClickOutside,
  },
  components: { KogKebabButton },
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenuOpen() {
      if (!this.menuOpen) {
        this.showMenu();
      } else {
        this.hideMenu();
      }
    },
    hideMenu() {
      this.menuOpen = false;
    },
    showMenu() {
      this.menuOpen = true;
      nextTick(() => {
        const dropDownEl = this.$refs.kebabDropdown;
        const dropDownRect = dropDownEl.getBoundingClientRect();
        const buttonRect = this.$refs.kebabButton.$refs.root.getBoundingClientRect();
        if (window.innerHeight - buttonRect.bottom < dropDownRect.height) {
          const verticalOffset = buttonRect.height + dropDownRect.height;
          dropDownEl.style.marginTop = `-${verticalOffset}px`;
        }
      });
    },
  },
};
</script>

<style scoped>
.KebabMenu {
  position: relative;
}

.KebabMenu-dropdownContainer {
  position: relative;
  width: 0;
  height: 0;
}

.KebabMenu-absoluteContainer {
  position: absolute;
  right: -36px;

  overflow: hidden;

  border-radius: 5px;
  box-shadow: 0 4px 8px -2px var(--kogShadow020);
}
</style>
