import AssignmentMenuItem from 'studyApp/menus/items/assignment-menu-item.js';
import BookMenuItem from 'studyApp/menus/items/book-menu-item.js';
import EsqPracticeTestSubMenuItem from 'studyApp/menus/items/esq-practice-test-sub-menu-item.js';
import InsightsMenuItem from 'studyApp/menus/items/insights-menu-item.js';
import MyAssignmentsMenuItem from 'studyApp/menus/items/my-assignments-menu-item.js';
import NotebookMenuItem from 'studyApp/menus/items/notebook-menu-item.js';
import OverviewMenuItem from 'studyApp/menus/items/overview-menu-item.js';
import PracticeMenuItem from 'studyApp/menus/items/practice-menu-item.js';
import StrengthBattleSubMenuItem from 'studyApp/menus/items/strength-battle-sub-menu-item.js';
import StrengthTestSubMenuItem from 'studyApp/menus/items/strength-test-sub-menu-item.js';
import NavigationBarMenuBuilder from 'studyApp/menus/navigation-bar-menu-builder.js';

export default class NavigationFactory {
  constructor({
    isTeacher,
    isStudent,
    isNgssSubject,
    educationSystem,
    ongoingAssignmentsCount,
    isSubjectClassOverviewPage,
  }) {
    this.builder = new NavigationBarMenuBuilder();
    this.isTeacher = isTeacher;
    this.isStudent = isStudent;
    this.isNgssSubject = isNgssSubject;
    this.educationSystem = educationSystem;
    this.ongoingAssignmentsCount = ongoingAssignmentsCount;
    this.isSubjectClassOverviewPage = isSubjectClassOverviewPage;
  }

  createOverviewMenu() {
    if (this.isTeacher) {
      this.createOverviewMenuForTeachers();
    } else {
      this.createOverviewMenuForStudents();
    }
  }

  createOverviewMenuForTeachers() {
    this.builder.addMenu(new OverviewMenuItem());
  }

  createOverviewMenuForStudents() {
    this.builder.addMenu(new OverviewMenuItem());
  }

  createBookMenu() {
    this.builder.addMenu(new BookMenuItem());
  }

  createNotebookMenu() {
    this.builder.addMenu(new NotebookMenuItem());
  }

  createPracticeMenu() {
    this.builder.addMenu(new PracticeMenuItem());
    this.builder.addSubMenu(new StrengthTestSubMenuItem());
    if (!this.isNgssSubject) {
      this.builder.addSubMenu(
        new EsqPracticeTestSubMenuItem({ educationSystem: this.educationSystem }),
      );
    }
    this.builder.addSubMenu(new StrengthBattleSubMenuItem());
  }

  createAssignmentMenu() {
    if (this.isTeacher) {
      this.createAssignmentMenuForTeachers();
    } else {
      this.createAssignmentMenuForStudents();
    }
  }

  createAssignmentMenuForTeachers() {
    this.builder.addMenu(
      new AssignmentMenuItem({
        badgeCount: this.ongoingAssignmentsCount,
      }),
    );
  }

  createAssignmentMenuForStudents() {
    this.builder.addMenu(
      new MyAssignmentsMenuItem({
        badgeCount: this.ongoingAssignmentsCount,
      }),
    );
  }

  createInsightsMenu() {
    this.builder.addMenu(
      new InsightsMenuItem({
        showRedDot: this.isNgssSubject,
      }),
    );
  }

  create() {
    this.createOverviewMenu();
    this.createBookMenu();
    if (this.isStudent) {
      this.createNotebookMenu();
    }
    this.createPracticeMenu();
    this.createAssignmentMenu();
    if (this.isTeacher) {
      this.createInsightsMenu();
    }
    return this.builder.build();
  }
}
