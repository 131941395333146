import { Command } from '@ckeditor/ckeditor5-core';

export const DEFAULT_REFLECTION_BOX_TITLE = 'Reflection title';
export const DEFAULT_REFLECTION_BOX_CONTENT =
  'Add only one long text question here instead of this text';

export default class InsertReflectionBoxCommand extends Command {
  execute() {
    const { model } = this.editor;
    model.change(writer => {
      model.insertObject(this.create(writer));
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(
      document.selection.getFirstPosition(),
      'reflectionBox',
    );
    this.isEnabled = allowedIn !== null;
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer) {
    const reflectionBox = writer.createElement('reflectionBox');
    const reflectionBoxIcon = writer.createElement('iconDiv');
    const reflectionBoxHeader = writer.createElement('reflectionBoxHeader');
    const reflectionBoxTitle = writer.createElement('reflectionBoxTitle');
    const reflectionBoxContent = writer.createElement('reflectionBoxContent');
    const title = writer.createElement('paragraph');
    const content = writer.createElement('paragraph');

    writer.insertText(DEFAULT_REFLECTION_BOX_TITLE, title);
    writer.insertText(DEFAULT_REFLECTION_BOX_CONTENT, content);

    writer.append(reflectionBoxHeader, reflectionBox);
    writer.append(reflectionBoxIcon, reflectionBoxHeader);
    writer.append(reflectionBoxTitle, reflectionBoxHeader);
    writer.append(title, reflectionBoxTitle);
    writer.append(reflectionBoxContent, reflectionBox);
    writer.append(content, reflectionBoxContent);

    return reflectionBox;
  }
}
