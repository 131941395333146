import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const subjectclassTeacherUsersURL = '/api/schoolstaff/subject_class/:subjectClassId/teacher_users/';
const subjectclassTeacherDetailsURL =
  '/api/schoolstaff/subject_class/:subjectClassId/teacher_users/:userId/';

const subjectclassTeacherUsersToPath = compile(subjectclassTeacherUsersURL);
const subjectclassTeacherDetailsToPath = compile(subjectclassTeacherDetailsURL);

export async function addTeacherToClass(subjectClassId, userId) {
  const url = subjectclassTeacherUsersToPath({ subjectClassId });
  const result = await axios.post(url, { user_id: userId });
  return result.data;
}

export async function removeTeacherFromClass(subjectClassId, userId) {
  const url = subjectclassTeacherDetailsToPath({ subjectClassId, userId });
  const result = await axios.delete(url);
  return result.data;
}
