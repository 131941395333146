<template>
  <div class="flexContainer">
    <div
      v-for="level in levels"
      :key="level.id"
    >
      <kog-tag
        v-if="level.group === relevantGroup"
        :label="$term(level.name)"
        class="margin-right-xxs"
      />
    </div>
  </div>
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export default {
  name: 'QuestionLevelLabels',
  components: {
    KogTag,
  },
  props: {
    levels: {
      type: Array,
      required: true,
    },
    relevantGroup: {
      type: String,
      required: true,
    },
  },
};
</script>
