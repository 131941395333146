import { inject } from 'vue';
import { useStore } from 'vuex';

export default function useToggleNodeVisibilityEventHandler() {
  const store = useStore();
  const mixpanel = inject('mixpanel');

  const handleToggleNodeVisibilityEvent = subjectNode => {
    const isNodeHiddenWhileEditing = store.getters['subjectClassModule/isNodeHiddenWhileEditing'];
    const isHidden = isNodeHiddenWhileEditing(subjectNode);

    store.dispatch('subjectClassModule/toggleNodeVisibility', subjectNode);

    const eventTitle = `Hide / Show content: ${isHidden ? 'Show' : 'Hide'} item`;
    mixpanel.trackEvent(eventTitle, {
      subject_node_id: subjectNode.id,
      subject_node_name: `${subjectNode.formatted_number_including_ancestors} ${subjectNode.name}`,
      transaction: store.state.subjectClassModule.visibilityEditingTransactionToken,
    });
  };

  return { handleToggleNodeVisibilityEvent };
}
