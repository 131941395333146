<template>
  <generic-warning-box>
    <b v-if="requestType === 'connect_classes'"> Could not fetch your classes </b>
    <b v-else-if="requestType === 'update_class'"> Could not fetch your updated class </b>
    <b v-else> Some error happened while connecting to Google Classroom </b>
    <p>
      <a
        target="_self"
        :href="redirectionUrl"
      >
        Try reconnecting Kognity to your Google account
      </a>
      or contact
      <a href="mailto:support@kognity.com"> support@kognity.com </a>
    </p>
  </generic-warning-box>
</template>

<script>
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';
import getGoogleClassroomUrl from 'studyApp/utils/teacher/google-classroom-utils.js';

export default {
  name: 'GoogleAuthErrorWarningBox',
  components: {
    GenericWarningBox,
  },
  props: {
    origin: {
      type: String,
      required: true,
    },
    requestType: {
      type: String,
      default: '',
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    assignmentId: {
      type: String,
      default: '',
    },
    googleClassId: {
      type: String,
      default: '',
    },
  },
  computed: {
    redirectionUrl() {
      return getGoogleClassroomUrl(
        this.subjectClassId,
        this.origin,
        this.assignmentId,
        this.googleClassId,
      );
    },
  },
};
</script>
