<template>
  <kog-card :default-padding="false">
    <div class="TeacherAssignmentOverviewCard-container">
      <h2 class="heading-xs margin-bottom-s">Assignments</h2>
      <div v-if="anyAssignments">
        <div
          class="flexContainer flexContainer-row flexContainer-alignCenter TeacherAssignmentOverviewCard-border padd-bottom-s"
        >
          <i
            class="fas fa-clock TeacherAssignmentOverviewCard-timelineGroup-header-icon TeacherAssignmentOverviewCard-timelineGroup-header-icon--clock"
          />
          <span class="TeacherAssignmentOverviewCard-timeLineHeader-text font-weight-bold"
            >Current assignments</span
          >
        </div>
        <div
          v-for="assignment in activeAssignments"
          :key="assignment.id"
          class="TeacherAssignmentOverviewCard-border padd-bottom-xs"
        >
          <teacher-assignment-tile :assignment="assignment" />
        </div>
        <div
          class="flexContainer flexContainer-row flexContainer-alignCenter TeacherAssignmentOverviewCard-border TeacherAssignmentOverviewCard-border--dashed padd-bottom-s"
        >
          <i
            class="fas fa-calendar-check TeacherAssignmentOverviewCard-timelineGroup-header-icon TeacherAssignmentOverviewCard-timelineGroup-header-icon--calendar"
          />
          <span class="TeacherAssignmentOverviewCard-timeLineHeader-text font-weight-bold"
            >Ended assignments</span
          >
        </div>
        <div
          v-for="assignment in pastAssignments"
          :key="assignment.id"
          class="TeacherAssignmentOverviewCard-border TeacherAssignmentOverviewCard-border--dashed padd-bottom-xs"
        >
          <teacher-assignment-tile :assignment="assignment" />
        </div>
        <div
          class="flexContainer flexContainer-row flexContainer-alignStart TeacherAssignmentOverviewCard-border TeacherAssignmentOverviewCard-border--dashed"
        >
          <i
            class="fas fa-circle TeacherAssignmentOverviewCard-timelineGroup-header-icon TeacherAssignmentOverviewCard-timelineGroup-header-icon--circle"
          />
        </div>
      </div>
      <div
        v-else
        class="muted padd-m margin-top-l"
      >
        No ongoing or recently ended assignments.
      </div>
    </div>
  </kog-card>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { isNil } from 'lodash';

import type { AssignmentListParams } from '@apis/assignment-helpers';

import KogCard from 'sharedApp/components/card/kog-card.vue';
import useAssignments from 'sharedApp/composables/use-assignments.ts';
import { dateFormatter } from 'sharedApp/utils/time-utils.js';
import TeacherAssignmentTile from 'studyApp/components/assignment/teacher-assignment-tile.vue';

const props = defineProps<{
  subjectClassId?: number;
}>();

const {
  currentAssignments: activeAssignments,
  pastAssignments,
  getCurrentAssignments,
  getPastAssignments,
} = useAssignments();

const anyAssignments = computed(() => {
  return activeAssignments.value.length > 0 || pastAssignments.value.length > 0;
});

const commonOptions: Partial<AssignmentListParams> = {};
if (!isNil(props.subjectClassId !== null)) {
  commonOptions.subjectClassId = props.subjectClassId;
}

const lastWeek = new Date();
lastWeek.setDate(lastWeek.getDate() - 7);
Promise.all([
  getPastAssignments({
    pageSize: 3,
    deadlineFromDate: dateFormatter(lastWeek),
    ...commonOptions,
  }),
  getCurrentAssignments({
    sortOrder: 'asc',
    ...commonOptions,
  }),
]);
</script>

<style scoped>
.TeacherAssignmentOverviewCard-container {
  padding: var(--space-m) var(--space-m) var(--space-m) var(--space-s);
}

.TeacherAssignmentOverviewCard-border {
  margin-left: var(--space-s);
  padding-left: var(--space-s);
  border-left: 1px solid var(--kogPlatformGray077);
}

.TeacherAssignmentOverviewCard-border--dashed {
  border-left-style: dashed;
}

.TeacherAssignmentOverviewCard-timeLineHeader-text {
  position: relative;
  left: calc(-1 * var(--space-s));
  line-height: var(--kog-font-size-default);
}

.TeacherAssignmentOverviewCard-timelineGroup-header-icon {
  position: relative;
  font-size: 16px;
  color: var(--kogPlatformGray044);
  background: var(--kogPlatformWhite);
}

.TeacherAssignmentOverviewCard-timelineGroup-header-icon--clock {
  left: calc(-1 * var(--space-s) - 8px);
}

.TeacherAssignmentOverviewCard-timelineGroup-header-icon--calendar {
  left: calc(-1 * var(--space-s) - 7px);
}

.TeacherAssignmentOverviewCard-timelineGroup-header-icon--circle {
  left: calc(-1 * var(--space-s) - 5px);
  font-size: 10px;
}
</style>
