import { inject, provide, reactive } from 'vue';

import type { InjectionKey } from 'vue';
import type {
  FBQ,
  FBQSolution,
  LTQ,
  MCQ,
  Question,
  QuestionContext,
  QuestionContextOptions,
  QuestionContextProps,
  QuestionDisplayModeType,
  QuestionPropsBuilder,
  STQ,
} from './types.ts';

import { QUESTION_TYPES } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

const USER_RESPONSE: unique symbol = Symbol('user-response');
const USER_RESPONSE_LOCKED: unique symbol = Symbol('user-response-locked');
const USER_RESPONSE_LOCKED_WITH_CORRECTNESS: unique symbol = Symbol(
  'user-response-locked-with-correctness',
);
const CORRECT_RESPONSE: unique symbol = Symbol('correct-response');

const QUESTION_DISPLAY_MODE = {
  USER_RESPONSE,
  USER_RESPONSE_LOCKED,
  USER_RESPONSE_LOCKED_WITH_CORRECTNESS,
  CORRECT_RESPONSE,
} as const;

const QuestionContextInjectionKey: InjectionKey<QuestionContext> = Symbol('question-context');

const buildLTQProps = ({
  question,
  response,
  displayMode,
  componentContext,
}: QuestionPropsBuilder<LTQ>): QuestionContextProps<LTQ> => {
  const userResponse = response?.user_response;
  const props = {
    ...componentContext,
    question,
    userResponse,
  };

  switch (displayMode) {
    case QUESTION_DISPLAY_MODE.CORRECT_RESPONSE:
    case QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED:
    case QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED_WITH_CORRECTNESS: {
      return {
        ...props,
        disabled: true,
      };
    }
    default:
      return props;
  }
};

function buildMCQProps({
  question,
  response,
  displayMode,
  componentContext,
}: QuestionPropsBuilder<MCQ>): QuestionContextProps<MCQ> {
  const userResponse = response?.user_response;
  const props = {
    ...componentContext,
    question,
    userResponse,
    multiSelect: question.context.is_multi_select,
    choices: question.context.choices,
  };

  function generateChoiceState(userChoiceResponse: number[], solution?: MCQ['solution']) {
    let choiceState = {};

    if (userChoiceResponse) {
      choiceState = Object.fromEntries(
        userChoiceResponse.map(responseId => [
          responseId,
          {
            selected: true,
            correct: solution ? solution.includes(responseId) : null,
          },
        ]),
      );
    }
    return choiceState;
  }

  switch (displayMode) {
    case QUESTION_DISPLAY_MODE.CORRECT_RESPONSE: {
      return {
        ...props,
        solutionIds: question.solution,
        type: 'solution',
        choiceState: generateChoiceState(userResponse ?? [], question.solution),
        disabled: true,
      };
    }
    case QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED_WITH_CORRECTNESS: {
      return {
        ...props,
        choiceState: generateChoiceState(userResponse ?? [], question.solution),
        disabled: true,
      };
    }
    case QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED: {
      return {
        ...props,
        choiceState: generateChoiceState(userResponse ?? []),
        type: 'solution',
        disabled: true,
      };
    }
    default:
      return {
        ...props,
        choiceState: generateChoiceState(userResponse ?? []),
      };
  }
}

const buildSTQProps = ({
  question,
  response,
  displayMode,
  componentContext,
}: QuestionPropsBuilder<STQ>): QuestionContextProps<STQ> => {
  const userResponse = response?.user_response;
  const props = {
    ...componentContext,
    question,
    userResponse,
  };
  switch (displayMode) {
    case QUESTION_DISPLAY_MODE.CORRECT_RESPONSE: {
      return {
        ...props,
        userResponse: question?.solution?.answer_list[0],
        isCorrect: true,
        disabled: true,
      };
    }
    case QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED_WITH_CORRECTNESS: {
      return {
        ...props,
        isCorrect: response?.user_response_correctness,
        isIncorrect: !response?.user_response_correctness,
        disabled: true,
      };
    }
    case QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED: {
      return {
        ...props,
        disabled: true,
      };
    }
    default:
      return props;
  }
};

const buildFBQProps = ({
  question,
  response,
  displayMode,
  componentContext,
}: QuestionPropsBuilder<FBQ>): QuestionContextProps<FBQ> => {
  const userResponse = response?.user_response;
  const props = {
    ...componentContext,
    question,
    userResponse,
  };

  const generateInputAnswers = (correctCheck: (answer: FBQSolution) => { correct: boolean }) => {
    return question.solution?.reduce(
      (acc, answer) => {
        acc[answer.html_element_uid] = { ...answer, ...correctCheck(answer) };
        return acc;
      },
      {} as Record<string, FBQSolution & { correct: boolean }>,
    );
  };

  switch (displayMode) {
    case QUESTION_DISPLAY_MODE.CORRECT_RESPONSE: {
      return {
        ...props,
        answers: question.solution,
        displayInputIndex: true,
        disabled: true,
        showWithAnswers: true,
      };
    }
    case QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED_WITH_CORRECTNESS: {
      const inputAnswersWithCorrectness = generateInputAnswers(answer => ({
        correct: Boolean(response?.user_response_correctness?.[answer.html_element_uid]),
      }));

      return {
        ...props,
        userResponse: userResponse || {},
        displayInputIndex: true,
        disabled: true,
        isShowingAnswerCorrectness: true,
        answersWithCorrectness: inputAnswersWithCorrectness,
      };
    }
    case QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED: {
      return {
        ...props,
        disabled: true,
      };
    }
    default:
      return props;
  }
};

const useQuestionContextProvide = (options: QuestionContextOptions) => {
  const { retrieveResponses, context } = options;

  function getQuestionProps(
    question: LTQ,
    displayMode: QuestionDisplayModeType,
  ): QuestionContextProps<LTQ>;
  function getQuestionProps(
    question: MCQ,
    displayMode: QuestionDisplayModeType,
  ): QuestionContextProps<MCQ>;
  function getQuestionProps(
    question: STQ,
    displayMode: QuestionDisplayModeType,
  ): QuestionContextProps<STQ>;
  function getQuestionProps(
    question: FBQ,
    displayMode: QuestionDisplayModeType,
  ): QuestionContextProps<FBQ>;
  function getQuestionProps(question: Question, displayMode: QuestionDisplayModeType) {
    switch (question.type) {
      case QUESTION_TYPES.LTQ: {
        return buildLTQProps({
          question,
          response: retrieveResponses?.(question),
          displayMode,
          componentContext: context?.LTQ ?? {},
        });
      }
      case QUESTION_TYPES.MCQ: {
        return buildMCQProps({
          question,
          response: retrieveResponses?.(question),
          displayMode,
          componentContext: context?.MCQ ?? {},
        });
      }
      case QUESTION_TYPES.STQ: {
        return buildSTQProps({
          question,
          response: retrieveResponses?.(question),
          displayMode,
          componentContext: context?.STQ ?? {},
        });
      }
      case QUESTION_TYPES.FBQ: {
        return buildFBQProps({
          question,
          response: retrieveResponses?.(question),
          displayMode,
          componentContext: context?.FBQ ?? {},
        });
      }
      default:
        throw new Error('Not a valid question', question);
    }
  }

  const questionContext = reactive({ getQuestionProps });
  provide(QuestionContextInjectionKey, questionContext);
};

const useQuestionContextInject = () => {
  const questionContext = inject(QuestionContextInjectionKey);

  if (!questionContext) {
    throw new Error(
      `Missing question context provided by "QuestionContextProvide".
      - Please use hook  "useQuestionContextProvide" on any parent component.`,
    );
  }

  return questionContext;
};

export { QUESTION_DISPLAY_MODE, useQuestionContextInject, useQuestionContextProvide };
