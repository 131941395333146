import axios from 'sharedApp/vue-utils/kog-axios.ts';

const apiBaseUrl = '/api/subject-nodes/';

export async function createSubjectNode(newNode) {
  const response = await axios.post('/api/content/subjectnodes/', newNode);
  return response.data;
}

export async function fetchSubjectNode(id) {
  const response = await axios.get(`${apiBaseUrl}${id}/`);
  return response.data;
}

export async function patchSubjectNode(subjectNodeId, updatedSubjectNodeDetails) {
  const url = `${apiBaseUrl}${subjectNodeId}/`;
  const response = await axios.patch(url, updatedSubjectNodeDetails);
  return response.data;
}

export async function patchSubjectNodeWithImage(subjectNodeId, updatedSubjectNodeDetails) {
  const url = `${apiBaseUrl}${subjectNodeId}/`;
  const formData = new FormData();
  Object.keys(updatedSubjectNodeDetails).forEach(key =>
    formData.append(key, updatedSubjectNodeDetails[key]),
  );
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await axios.patch(url, formData, config);
  return response.data;
}
