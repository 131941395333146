<template>
  <span>
    <span v-if="unlockedSetting === 'UNLOCKED'">
      The mark scheme is unlocked and will remain unlocked in this assignment after the deadline
    </span>
    <span v-else-if="unlockedSetting === 'AUTO_UNLOCK'">
      <span class="text-bold">Locked during</span> the assignment and
      <span class="text-bold"> unlocks automatically</span>
      once the deadline passes
    </span>
    <span v-else-if="unlockedSetting === 'MANUAL_UNLOCK'">
      <span class="text-bold">Locked during</span>
      the assignment and can be
      <span class="text-bold"> unlocked manually by </span>you
    </span>
  </span>
</template>

<script>
export default {
  name: 'EsqMarkschemeSettingInfo',
  props: {
    markSchemeStatus: {
      type: Object,
      required: true,
    },
  },
  computed: {
    unlockedSetting() {
      return this.markSchemeStatus.unlocked_setting;
    },
  },
};
</script>
