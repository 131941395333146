<template>
  <div :class="`AssignmentTile AssignmentTile--${type}`">
    <router-link
      :to="assignmentDetailsRoute"
      class="AssignmentTile-headerLink"
    >
      <div class="flexContainer">
        <span class="AssignmentTile-name heading-xs">
          {{ name }}
        </span>
      </div>
      <div
        :class="`text-regular flexContainer
          flexContainer-alignCenter margin-bottom-s`"
      >
        <kog-icon
          class="margin-right-xs"
          theme="custom"
          icon-class="fa-clock"
          fa-style="regular"
          size="s"
        />
        Deadline: {{ datetime(deadline) }}
      </div>
    </router-link>
    <div class="flexContainer flexContainer-alignCenter">
      <kog-tag
        v-if="hasEnded"
        class="margin-right-xxs"
        is-chip
        icon-class="fas fa-clock"
        label="Ended"
        context="Assignment ended"
        size="m"
        :type="type"
      />
      <kog-tag
        v-if="assignment.has_deadline_passed && isStudent"
        is-chip
        class="margin-right-xxs"
        icon-class="fa-flag"
        label="Late"
        size="m"
        type="negative"
      />
      <kog-tag
        v-if="!isStudent"
        is-chip
        class="margin-right-xxs"
        :icon-class="studentCompletionChipIcon"
        :label="`${studentsDone} / ${studentsTotal}`"
        :context="`${studentsDone} of ${studentsTotal} completed the assignment`"
        size="m"
        :type="type"
        @click="onCompletionChipClick"
      />
      <kog-tag
        is-chip
        :icon-class="assignmentTypeChip.icon"
        :is-svg-icon="assignmentTypeChip.isSvgIcon"
        :label="assignmentTypeChip.text"
        :context="`${assignmentTypeChip.text} assignment`"
        size="m"
        :type="type"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import {
  getTakeAssignmentRoute,
  getTeacherAssignmentsRoute,
  isExamStyleType,
  isReadingType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'AssignmentTile',
  components: {
    KogIcon,
    KogTag,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    isStudent: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['completion-chip-click'],
  computed: {
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    subjectClassId() {
      if (this.$route.params.cid) {
        return parseInt(this.$route.params.cid, 10);
      }
      return this.assignment.subject_classes[0];
    },
    subjectClass() {
      return this.subjectClassesById[this.subjectClassId];
    },
    name() {
      return this.assignment.name;
    },
    deadline() {
      return this.assignment.deadline;
    },
    classSlug() {
      const { subject_class_slug: subjectClassSlug, subject_slug: subjectSlug } = this.assignment;
      return subjectClassSlug ?? subjectSlug;
    },
    subjectId() {
      return this.assignment.subject_id;
    },
    classId() {
      return this.subjectClass.id;
    },
    assignmentType() {
      return this.assignment.assignment_type;
    },
    studentsTotal() {
      return this.assignment.student_user_ids.length;
    },
    studentsDone() {
      return this.assignment.completed_student_user_ids.length;
    },
    type() {
      return this.hasEnded ? 'positive' : 'informative';
    },
    hasEnded() {
      return Date.now() > this.deadline;
    },
    isCompleted() {
      return this.studentsDone === this.studentsTotal;
    },
    studentCompletionChipIcon() {
      return this.isCompleted ? 'fas fa-user-check' : 'fa-user-check';
    },
    assignmentTypeChip() {
      if (isReadingType(this.assignmentType)) {
        return {
          icon: 'fa-book-reader',
          isSvgIcon: false,
          text: 'Reading',
        };
      }

      if (isExamStyleType(this.assignmentType)) {
        return {
          icon: 'LTQ',
          isSvgIcon: true,
          text: this.$term('exam-style'),
        };
      }

      return {
        icon: 'fa-pencil',
        isSvgIcon: false,
        text: 'Question',
      };
    },
    assignmentDetailsRoute() {
      const { isStudent, assignment, subjectId, classId, classSlug } = this;
      if (isStudent) {
        return getTakeAssignmentRoute({
          assignment,
          subjectId,
          classId,
          classSlug,
          source: 'assignment_card',
        });
      }
      return getTeacherAssignmentsRoute({
        assignment,
        subjectId,
        classId,
        classSlug,
        source: 'assignment_card',
      });
    },
  },
  methods: {
    onCompletionChipClick(e) {
      this.$emit('completion-chip-click', e);
    },
    datetime(date) {
      return dateTimeFormatter(new Date(date));
    },
  },
};
</script>

<style scoped>
.AssignmentTile {
  padding: var(--space-s);
  border: 1px solid var(--kogTransparent);
  border-radius: 12px;
}

.AssignmentTile--informative {
  background-color: var(--kog-colors-aubergine-100);
}

.AssignmentTile--positive {
  background-color: var(--kogPlatformGreenLighten20);
}

.AssignmentTile-headerLink {
  color: var(--kogPlatformGray018);
}

.AssignmentTile-headerLink:hover,
.AssignmentTile-headerLink:focus {
  text-decoration: none;
}

.AssignmentTile-name {
  position: relative;
}

.AssignmentTile-name::before {
  content: '';

  position: absolute;
  inset: auto 0 0 0;
  transform: translateY(-5px);

  height: 1px;

  opacity: 0;
  background-color: var(--kogPlatformGray018);

  transition:
    opacity 0.1s ease-in-out,
    transform 0.1s ease-in-out;
}

.AssignmentTile-name::after {
  content: '\f054';

  position: absolute;
  inset: 50% -20px auto auto;
  transform: translate(-15px, -50%);

  display: inline-block;

  font: var(--kog-content-fa-icon);
  font-weight: 400;
  text-rendering: auto;

  opacity: 0;

  transition:
    opacity 0.1s ease-in-out,
    transform 0.1s ease-in-out;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.AssignmentTile-headerLink:hover .AssignmentTile-name::before,
.AssignmentTile-headerLink:focus .AssignmentTile-name::before {
  transform: translateY(0);
  opacity: 1;
}

.AssignmentTile-headerLink:hover .AssignmentTile-name::after,
.AssignmentTile-headerLink:focus .AssignmentTile-name::after {
  transform: translate(0, -50%);
  opacity: 1;
}
</style>
