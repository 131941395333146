import { Command } from '@ckeditor/ckeditor5-core';

export const DEFAULT_EXERCISE_BOX_TITLE = 'Exercise title';
export const DEFAULT_EXERCISE_BOX_SUBTITLE = 'Click a question to answer';
export const DEFAULT_EXERCISE_BOX_CONTENT = 'Add questions here';
export default class InsertExerciseBoxCommand extends Command {
  execute() {
    const { model } = this.editor;
    model.change(writer => {
      model.insertObject(this.create(writer));
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(
      document.selection.getFirstPosition(),
      'exerciseBox',
    );
    this.isEnabled = allowedIn !== null;
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer) {
    const exerciseBox = writer.createElement('exerciseBox');
    const exerciseBoxIcon = writer.createElement('iconDiv');
    const exerciseBoxHeader = writer.createElement('exerciseBoxHeader');
    const exerciseBoxTitle = writer.createElement('exerciseBoxTitle');
    const exerciseBoxSubtitle = writer.createElement('exerciseBoxSubtitle');
    const exerciseBoxContent = writer.createElement('exerciseBoxContent');
    const exerciseBoxContentParagraph = writer.createElement('paragraph');
    const title = writer.createElement('heading4');
    const subtitle = writer.createElement('paragraph');

    writer.insertText(DEFAULT_EXERCISE_BOX_TITLE, title);
    writer.insertText(DEFAULT_EXERCISE_BOX_SUBTITLE, subtitle);
    writer.insertText(DEFAULT_EXERCISE_BOX_CONTENT, exerciseBoxContentParagraph);

    writer.append(exerciseBoxHeader, exerciseBox);
    writer.append(exerciseBoxIcon, exerciseBoxHeader);
    writer.append(exerciseBoxTitle, exerciseBoxHeader);
    writer.append(exerciseBoxSubtitle, exerciseBoxHeader);
    writer.append(title, exerciseBoxTitle);
    writer.append(subtitle, exerciseBoxSubtitle);
    writer.append(exerciseBoxContent, exerciseBox);
    writer.append(exerciseBoxContentParagraph, exerciseBoxContent);

    return exerciseBox;
  }
}
