<template>
  <div class="NoteList">
    <note-card
      v-for="annotation in annotationsList"
      :key="annotation.id"
      :annotation="annotation"
      :go-to-book="goToBook"
      class="NoteList-card"
      @add-text="onAddText"
      @deleted-annotation="onDeleteAnnotation"
      @edit-text="onEditText"
    />
  </div>
</template>

<script>
import NoteCard from 'studyApp/components/notebook/note-card.vue';

export default {
  name: 'NoteList',
  components: {
    NoteCard,
  },
  props: {
    annotationsList: {
      type: Array,
      default: () => [],
    },
    goToBook: {
      type: Function,
      required: true,
    },
  },
  emits: ['edit-text', 'deleted-annotation', 'add-text'],
  methods: {
    onDeleteAnnotation(args) {
      this.$emit('deleted-annotation', args);
    },
    onAddText() {
      this.$emit('add-text');
    },
    onEditText() {
      this.$emit('edit-text');
    },
  },
};
</script>

<style scoped>
.NoteList {
  flex: 1;

  max-width: 750px;

  background: var(--kogPlatformWhite);
  border-radius: 5px;
  box-shadow: 0 8px 16px -4px var(--kogShadow020);
}

.NoteList-card:not(:last-of-type) {
  border-bottom: 1px solid var(--kogPlatformGray090);
}
</style>
