<template>
  <div>
    <study-dashboard-container v-if="userInstance.isStudent()" />
    <teacher-dashboard-container v-if="userInstance.isTeacher()" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { User } from 'learning/common/api/user.js';

import StudyDashboardContainer from 'studyApp/containers/dashboard/study-dashboard-container.vue';
import TeacherDashboardContainer from 'studyApp/containers/dashboard/teacher-dashboard-container.vue';

export default {
  name: 'Dashboard',
  components: {
    StudyDashboardContainer,
    TeacherDashboardContainer,
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    userInstance() {
      return new User(this.user);
    },
  },
  created() {
    this.fetchUser();
    this.fetchNotifications();
    this.$mixpanel.resetDefaultPropertiesForEvents();
  },
  methods: {
    ...mapActions({
      fetchUser: 'userModule/fetchUser',
      fetchNotifications: 'notificationsModule/fetchNotifications',
    }),
  },
};
</script>
