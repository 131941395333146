import { Plugin } from '@ckeditor/ckeditor5-core';
import { clickOutsideHandler, ContextualBalloon } from '@ckeditor/ckeditor5-ui';

import KogForm from './kogform.js';

export default class KogUI extends Plugin {
  static get requires() {
    return [ContextualBalloon];
  }

  isShowUiInProgress = false;

  formView: (KogForm & { focus: () => void }) | undefined;

  balloon: ContextualBalloon | undefined;

  setupFormView(onSubmit: () => void) {
    if (this.formView) {
      this.listenTo(this.formView, 'submit', () => onSubmit());
      this.listenTo(this.formView, 'cancel', () => this.hideUI());

      if (this.balloon) {
        clickOutsideHandler({
          emitter: this.formView,
          activator: () => this.balloon?.visibleView === this.formView,
          contextElements: this.balloon ? [this.balloon.view.element!] : [],
          callback: () => this.hideUI(),
        });
      }
    }
  }

  async triggerShowUI(prerequisiteFn: () => Promise<void>) {
    if (this.isShowUiInProgress) {
      return;
    }

    try {
      this.isShowUiInProgress = true;
      await prerequisiteFn();
      this.showUI();
    } finally {
      this.isShowUiInProgress = false;
    }
  }

  showUI() {
    if (this.formView) {
      this.showBallonEditor();
      this.formView.focus();
    }
  }

  hideUI() {
    if (this.formView) {
      this.formView.reset();
      this.hideBalloonEditor();
    }
    this.isShowUiInProgress = false;
    this.editor.editing.view.focus();
  }

  getBalloonPositionData() {
    const { view } = this.editor.editing;
    const selectionRange = view.document.selection.getFirstRange();
    if (selectionRange) {
      const target = () => view.domConverter.viewRangeToDom(selectionRange);
      return { target };
    }

    return null;
  }

  showBallonEditor() {
    if (this.formView) {
      const position = this.getBalloonPositionData();
      if (!position) {
        return;
      }
      this.balloon?.add({
        view: this.formView,
        position,
      });
    }
  }

  private hideBalloonEditor() {
    this.balloon?.remove(this.formView!);
  }
}
