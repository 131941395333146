<template>
  <generic-warning-box>
    <b> Could not connect to Google Classroom </b>

    <ol class="padd-left-m padd-top-s">
      <li class="padd-bottom-xs">
        <a
          target="_self"
          :href="redirectionUrl"
        >
          Try reconnecting Kognity to your Google account.
        </a>
        Check that you are connecting the correct account.
      </li>
      <li class="padd-bottom-xs">
        Speak to your school's Google Admin. They may have disabled integrations with Classroom.
        <a
          target="_blank"
          href="https://intercom.help/kognity/en/articles/5213634-enabling-the-google-classroom-integration-for-administrators"
        >
          Send these instructions
        </a>
        to your admin to enable the integration.
      </li>
      <li>
        If this problem persists after 24 hours, contact
        <a href="mailto:support@kognity.com"> support@kognity.com. </a>
      </li>
    </ol>
  </generic-warning-box>
</template>

<script>
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';
import getGoogleClassroomUrl from 'studyApp/utils/teacher/google-classroom-utils.js';

export default {
  name: 'GoogleAuthApiDisabledWarningBox',
  components: {
    GenericWarningBox,
  },
  props: {
    origin: {
      type: String,
      required: true,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    assignmentId: {
      type: String,
      default: '',
    },
  },
  computed: {
    redirectionUrl() {
      return getGoogleClassroomUrl(this.subjectClassId, this.origin, this.assignmentId);
    },
  },
};
</script>
