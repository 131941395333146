<template>
  <reading-assignment-focus-landing-header
    :is-assignment-done="isAssignmentDone"
    :assignment-deadline="assignmentDeadline"
    :assignment-name="assignmentName"
  >
    <template #aboveAssignmentName>
      <kog-tag
        v-if="isAssignmentDone && assignmentHandedAt"
        :label="`Handed in ${assignmentHandedAt}`"
        type="warning"
        class="margin-bottom-xxs"
      />
    </template>
    <template #actionsBottomRight>
      <div class="flexContainer flexContainer-alignCenter">
        <integration-assignment-link
          v-if="isSentToIntegration"
          class="margin-right-xs"
          :integration-provider="integrationProvider"
        />
      </div>
    </template>
  </reading-assignment-focus-landing-header>
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import IntegrationAssignmentLink from 'studyApp/components/assignment/integration-assignment-link.vue';
import ReadingAssignmentFocusLandingHeader from 'studyApp/pages/assignment/components/reading-assignment-focus-landing-header.vue';

export default {
  name: 'StudentReadingAssignmentFocusLandingHeader',
  components: {
    ReadingAssignmentFocusLandingHeader,
    IntegrationAssignmentLink,
    KogTag,
  },
  props: {
    assignmentName: {
      type: String,
      required: true,
    },
    assignmentDeadline: {
      type: String,
      required: true,
    },
    isAssignmentDone: {
      type: Boolean,
      required: true,
    },
    assignmentHandedAt: {
      type: String,
      default: null,
    },
    isSentToIntegration: {
      type: Boolean,
      default: false,
    },
    integrationProvider: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
