<template>
  <div
    class="LongTextQuestion flexContainer flexContainer-column gap-row-l"
    :style="scopedStyle"
  >
    <template v-if="shouldDisplayAll || shouldDisplayQuestionOnly">
      <question-text :question="question" />
    </template>

    <template v-if="shouldDisplayAll || shouldDisplayInputOnly">
      <div
        v-if="!withImageInput"
        class="positionRelative"
      >
        <kog-icon
          class="LongTextQuestion-TextareaIcon positionAbsolute"
          icon-class="fa-pen"
          fa-style="regular"
          size="s-touch"
        />
        <kog-textarea
          :value="answer"
          style-type="prominent"
          :label="label"
          :placeholder="placeholderValue"
          :is-label-hidden="isLabelHidden"
          fit-to-content
          :textarea-style="textareaStyle"
          :disabled="disabled"
          :is-autosave-enabled="!!autoSave"
          :save-request$="autoSave$"
          :max-length="maxLength"
          @trigger-save="event => (autoSave ? autoSave(event) : undefined)"
          @input="$emit('input', $event)"
          @blur="onBlur"
        />
      </div>
      <image-upload
        v-else
        :file-context="imageAnswer"
        :disabled="disabled"
        :max-file-size="maxFileSize"
        @input="handleImageChange"
      />
    </template>
  </div>
</template>

<script>
import { Subject } from 'rxjs';

import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import ImageUpload from 'sharedApp/components/image-upload/image-upload.vue';
import QuestionText from 'sharedApp/components/questions/question-text.vue';
import { QUESTION_TYPES } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

import DISPLAY_STATES from './display-states.js';

const validator = question => {
  const requiredProperties = ['question_html', 'id', 'type'];
  const hasAllRequiredProperties = requiredProperties.every(property => question[property]);

  const isLTQ = question.type === QUESTION_TYPES.LTQ;

  return hasAllRequiredProperties && isLTQ;
};

export default {
  name: 'LongTextQuestion',
  components: {
    QuestionText,
    KogTextarea,
    KogIcon,
    ImageUpload,
  },
  props: {
    /**
     * Contains the question text and the question type
     */
    question: {
      type: Object,
      required: true,
      validator,
    },
    /**
     * Value which will be rendered in the textarea or image upload field
     */
    answer: {
      type: [String, Object],
      default: null,
    },
    /**
     * Maximum amount of allowed characters
     */
    maxLength: {
      type: Number,
      default: null,
    },
    /**
     * Max file size if image is used as input
     */
    maxFileSize: {
      type: Number,
      default: 5,
    },
    /**
     * Label for textarea,
     * Should always be set for a11y purpose
     */
    label: {
      type: String,
      default: 'Type your answer',
    },
    /**
     * Determines the visibility of the label
     */
    isLabelHidden: {
      type: Boolean,
      default: true,
    },
    /**
     * Placeholder to be displayed when textarea has no content
     */
    placeholder: {
      type: String,
      default: 'Your answer',
    },
    /**
     * Makes the textrea un-clickable and un-editable
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Object containing any style properties to be passed to the `textarea` element
     */
    textareaStyle: {
      type: Object,
      default: () => ({
        minHeight: '230px',
      }),
    },
    /* Determines what the component will render
     * - ALL: render question text + textarea input
     * - QUESTION: render only question text
     * - INPUT: render only textarea input
     */
    displayState: {
      type: String,
      default: DISPLAY_STATES.ALL,
      validator: value => Object.values(DISPLAY_STATES).includes(value),
    },
    /**
     * Determines if auto save is turned on/off
     */
    autoSave: {
      type: Function,
      default: null,
    },
  },
  emits: ['input', 'blur'],
  data() {
    return {
      text: '',
      autoSave$: new Subject(),
    };
  },
  computed: {
    scopedStyle() {
      return {
        '--ltq-icon-label-offset':
          !this.isLabelHidden && this.label
            ? 'calc(var(--space-xl) + var(--space-xxs)'
            : 'var(--space-xs)',
      };
    },
    shouldDisplayAll() {
      return this.displayState === DISPLAY_STATES.ALL;
    },
    shouldDisplayQuestionOnly() {
      return this.displayState === DISPLAY_STATES.QUESTION;
    },
    shouldDisplayInputOnly() {
      return this.displayState === DISPLAY_STATES.INPUT;
    },
    questionContext() {
      return this.question.question_context || this.question.context;
    },
    withImageInput() {
      return this.questionContext?.input_types?.includes('image') || false;
    },
    imageAnswer() {
      return this.answer === '' ? null : this.answer;
    },
    placeholderValue() {
      return this.disabled ? '' : this.placeholder;
    },
  },
  watch: {
    answer: {
      handler(answerText) {
        this.text = answerText;
      },
      immediate: true,
    },
  },
  methods: {
    handleImageChange(imageResponse) {
      this.$emit('input', {
        value: imageResponse?.fileContext,
        onError: imageResponse?.onError,
        onProgress: imageResponse?.onProgress,
      });
    },
    onBlur() {
      this.$emit('blur', this.answer);
    },
  },
};
</script>

<style scoped>
.LongTextQuestion :deep(.KogTextarea) {
  padding-left: 40px;
  line-height: var(--space-l);
}

.LongTextQuestion-TextareaIcon {
  top: var(--ltq-icon-label-offset);
  left: var(--space-xs);
}

.LongTextQuestion :deep(p) {
  margin-bottom: 0;
}

.LongTextQuestion-imageUpload {
  overflow: hidden;

  color: var(--kogPlatformGray009);

  background: var(--kogPlatformWhite);
  border-radius: 20px;
  outline-offset: 0;
}

.LongTextQuestion-cloudIcon {
  position: absolute;
  right: 10%;
  bottom: 10%;

  display: flex;

  padding: var(--space-xxs);

  background-color: var(--kog-background-brand-900);
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
}

.LongTextQuestion-imagePreview {
  width: auto;
  max-width: 100%;
  max-height: 600px;
  object-fit: contain;
}

.LongTextQuestion-deleteImageButton {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
}
</style>
