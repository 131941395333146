import { getQuestion } from '@apis/questions';

import mixpanelModule from 'sharedApp/libs/mixpanel.js';
import multipleChoiceQuestionNumberingService from 'sharedApp/services/questions/multipleChoiceQuestionNumbering/multiple-choice-question-numbering-service.js';

const kogMultipleChoiceQuestionNumberingService = multipleChoiceQuestionNumberingService();

export async function fetchCompleteQuestion(questionId, questionType) {
  const questionWithSubtype = await getQuestion(questionId, questionType); // ts-disable
  kogMultipleChoiceQuestionNumberingService.updateMultipleChoiceQuestionWithNumbering(
    questionWithSubtype,
  );
  return questionWithSubtype;
}

export function trackAssignmentOpen(subjectName, assignmentType, source) {
  mixpanelModule.trackEvent('Assignment - Open', {
    subject_name: subjectName,
    assignment_type: assignmentType,
    source,
  });
}

export function convertToDate(assignments) {
  assignments.forEach(as => {
    const assignment = as;
    assignment.deadline = new Date(assignment.deadline);
    assignment.scheduled_sent_at = new Date(assignment.scheduled_sent_at);
  });
}

export function getMetadata(data) {
  const totalPages = Math.ceil(data.count / data.page_size);
  return {
    count: data.count,
    totalPages,
    pageSize: data.page_size,
    currentPage: data.page,
  };
}
