import * as classStudentsApi from 'schoolAdminApp/api/class-students.js';
import schoolAdminApi from 'schoolAdminApp/api/index.js';
import {
  apiDELETE,
  apiPUT,
  createApiMutations,
  generateRequestError,
  generateStoreTypesForRequest,
} from 'schoolAdminApp/store/utils.js';
import { API_URLS } from 'schoolAdminApp/urls.js';

const classDetailsStoreTypes = generateStoreTypesForRequest('classDetails');
const classTeachersStoreTypes = generateStoreTypesForRequest('classTeachers');
const classStudentsStoreTypes = generateStoreTypesForRequest('classStudents');
const schoolTeachersStoreTypes = generateStoreTypesForRequest('schoolTeachers');
const addableStudentsStoreTypes = generateStoreTypesForRequest('addableStudents');
const classRemoveTeacherTypes = generateStoreTypesForRequest('classRemoveTeacher');
const classAddTeachersStoreTypes = generateStoreTypesForRequest('classAddTeachers');
const classAddStudentsStoreTypes = generateStoreTypesForRequest('classAddStudents');
const classRemoveStudentTypes = generateStoreTypesForRequest('classRemoveStudent');

const getters = {
  isClassLoading: state => state[classDetailsStoreTypes.stateKeys.isLoading],
  isClassTeachersLoading: state => state[classTeachersStoreTypes.stateKeys.isLoading],
  isClassStudentsLoading: state => state[classStudentsStoreTypes.stateKeys.isLoading],
  isSchoolTeachersLoading: state => state[schoolTeachersStoreTypes.stateKeys.isLoading],
  isClassRemoveTeacherLoading: state => state[classRemoveTeacherTypes.stateKeys.isLoading],
  isClassRemoveStudentLoading: state => state[classRemoveStudentTypes.stateKeys.isLoading],
  isClassAddTeachersLoading: state => state[classAddTeachersStoreTypes.stateKeys.isLoading],
  isClassAddStudentsLoading: state => state[classAddStudentsStoreTypes.stateKeys.isLoading],
  isAddableStudentsLoading: state => state[addableStudentsStoreTypes.stateKeys.isLoading],
  subjectClass: state => state[classDetailsStoreTypes.stateKeys.data],
  teachers: state => state[classTeachersStoreTypes.stateKeys.data],
  students: state => state[classStudentsStoreTypes.stateKeys.data],
  schoolTeachers: state => state[schoolTeachersStoreTypes.stateKeys.data],
  addableStudents: state => state[addableStudentsStoreTypes.stateKeys.data],
  classRemoved: state => state.classRemoved,
  classDetailsErrors: state => state[classDetailsStoreTypes.stateKeys.errors],
  classTeachersErrors: state => state[classTeachersStoreTypes.stateKeys.errors],
  classStudentsErrors: state => state[classStudentsStoreTypes.stateKeys.errors],
  schoolTeachersErrors: state => state[schoolTeachersStoreTypes.stateKeys.errors],
  classRemoveTeacherErrors: state => state[classRemoveTeacherTypes.stateKeys.errors],
  classRemoveStudentErrors: state => state[classRemoveStudentTypes.stateKeys.errors],
  classAddTeachersErrors: state => state[classAddTeachersStoreTypes.stateKeys.errors],
  classAddStudentsErrors: state => state[classAddStudentsStoreTypes.stateKeys.errors],
};

const mutations = {
  ...createApiMutations(classDetailsStoreTypes),
  ...createApiMutations(classTeachersStoreTypes),
  ...createApiMutations(classStudentsStoreTypes),
  ...createApiMutations(schoolTeachersStoreTypes),
  ...createApiMutations(addableStudentsStoreTypes),
  ...createApiMutations(classRemoveTeacherTypes),
  ...createApiMutations(classAddTeachersStoreTypes),
  ...createApiMutations(classRemoveStudentTypes),
  ...createApiMutations(classAddStudentsStoreTypes),
  setClassRemoved(state, subjectClass) {
    state.classRemoved = subjectClass;
  },
};

const actions = {
  async fetchSubjectClass({ commit }, { schoolId, subjectClassId }) {
    const { REQUEST, SUCCESS, FAILURE } = classDetailsStoreTypes.mutationTypes;
    commit(REQUEST);
    try {
      const subjectClass = await schoolAdminApi.fetchSubjectClass(schoolId, subjectClassId);
      commit(SUCCESS, subjectClass);
    } catch (err) {
      const errorDetails = generateRequestError(err, 'subject-class-details');
      commit(FAILURE, errorDetails);
    }
  },
  async deleteClass(store, { schoolId, subjectClassId }) {
    const { REQUEST, SUCCESS, FAILURE } = classDetailsStoreTypes.mutationTypes;
    const { subjectClass, classDetailsErrors } = store.getters;
    store.commit(REQUEST);

    try {
      const response = await schoolAdminApi.deleteSubjectClass(schoolId, subjectClassId);
      store.commit(SUCCESS, response);
    } catch (error) {
      const errorDetails = generateRequestError(error, 'subject-class-details', 'DELETE');
      store.commit(FAILURE, errorDetails);
    }

    if (!classDetailsErrors) {
      store.commit('setClassRemoved', subjectClass);
    }
  },
  resetClassRemoved(store) {
    store.commit('setClassRemoved', null);
  },
  async saveClassDetails({ commit }, { schoolId, subjectClassId, name, endDate }) {
    const { REQUEST, SUCCESS, FAILURE } = classDetailsStoreTypes.mutationTypes;
    commit(REQUEST);

    try {
      const data = { name, end_date: endDate };
      const subjectClass = await schoolAdminApi.updateSubjectClass(schoolId, subjectClassId, data);
      commit(SUCCESS, subjectClass);
    } catch (error) {
      const errorDetails = generateRequestError(error, 'subject-class-details', 'PUT');
      commit(FAILURE, errorDetails);
    }
  },
  async fetchTeachers({ commit }, { schoolId, subjectClassId }) {
    const { REQUEST, SUCCESS, FAILURE } = classTeachersStoreTypes.mutationTypes;
    commit(REQUEST);

    try {
      const subjectClassTeachers = await schoolAdminApi.fetchSubjectClassTeachers(
        schoolId,
        subjectClassId,
      );
      commit(SUCCESS, subjectClassTeachers);
    } catch (error) {
      const errorDetails = generateRequestError(error, 'subject-class-teachers');
      commit(FAILURE, errorDetails);
    }
  },
  async fetchStudents({ commit }, { schoolId, subjectClassId }) {
    const { REQUEST, SUCCESS, FAILURE } = classStudentsStoreTypes.mutationTypes;
    commit(REQUEST);

    try {
      const subjectClassStudents = await schoolAdminApi.fetchSubjectClassStudents(
        schoolId,
        subjectClassId,
      );
      commit(SUCCESS, subjectClassStudents);
    } catch (error) {
      const errorDetails = generateRequestError(error, 'subject-class-teachers');
      commit(FAILURE, errorDetails);
    }
  },
  async fetchSchoolTeachers({ commit }, { schoolId, subjectClassId }) {
    const { REQUEST, SUCCESS, FAILURE } = schoolTeachersStoreTypes.mutationTypes;
    commit(REQUEST);

    try {
      const teachers = await schoolAdminApi.fetchTeachers(
        schoolId,
        { subject_class_id: subjectClassId },
        { formatResponse: false },
      );
      commit(SUCCESS, teachers);
    } catch (error) {
      const errorDetails = generateRequestError(error, 'fetchSchoolTeachers', 'POST');
      commit(FAILURE, errorDetails);
    }
  },
  async removeTeacherFromClass(store, { schoolId, subjectClassId, teacherIds }) {
    const parsedTeacherIds = typeof teacherIds === 'object' ? teacherIds.join() : teacherIds;
    const url = API_URLS.TEACHER_CLASS_ASSOCIATION_URL(schoolId, parsedTeacherIds, subjectClassId);

    await apiDELETE(store, url, classRemoveTeacherTypes.mutationTypes);
    await store.dispatch('fetchTeachers', { schoolId, subjectClassId });
    await store.dispatch('fetchSchoolTeachers', { schoolId, subjectClassId });
  },
  async removeStudentFromClass({ commit, dispatch }, { schoolId, subjectClassId, studentId }) {
    const { REQUEST, SUCCESS, FAILURE } = classRemoveStudentTypes.mutationTypes;
    commit(REQUEST);

    try {
      await schoolAdminApi.deleteSubjectClassStudent(schoolId, subjectClassId, studentId);
      commit(SUCCESS);
    } catch (error) {
      const errorDetails = generateRequestError(error, 'subject-class-remove-student', 'DELETE');
      commit(FAILURE, errorDetails);
    }

    await dispatch('fetchStudents', { schoolId, subjectClassId });
    await dispatch('fetchAddableStudents', { schoolId, subjectClassId });
  },
  async addTeachersToClass(store, { schoolId, subjectClassId, teacherIds }) {
    const url = API_URLS.TEACHER_CLASS_ASSOCIATION_URL(schoolId, teacherIds.join(), subjectClassId);
    await apiPUT(store, url, classAddTeachersStoreTypes.mutationTypes);
    await store.dispatch('fetchTeachers', { schoolId, subjectClassId });
    await store.dispatch('fetchSchoolTeachers', { schoolId, subjectClassId });
  },
  async addStudentsToClass({ commit, dispatch }, { schoolId, subjectClassId, studentIds }) {
    const { REQUEST, SUCCESS, FAILURE } = classAddStudentsStoreTypes.mutationTypes;
    commit(REQUEST);

    try {
      const data = { students: studentIds };
      const newSubjectClassStudent = await schoolAdminApi.createSubjectClassStudent(
        schoolId,
        subjectClassId,
        data,
      );
      commit(SUCCESS, newSubjectClassStudent);
    } catch (error) {
      const errorDetails = generateRequestError(error, 'subject-class-add-student', 'POST');
      commit(FAILURE, errorDetails);
    }

    await dispatch('fetchStudents', { schoolId, subjectClassId });
    await dispatch('fetchAddableStudents', { schoolId, subjectClassId });
  },
  async fetchAddableStudents({ commit }, { schoolId, subjectClassId }) {
    const { REQUEST, SUCCESS, FAILURE } = addableStudentsStoreTypes.mutationTypes;
    commit(REQUEST);

    try {
      const subjectClassStudents = await schoolAdminApi.fetchSubjectClassAddableStudents(
        schoolId,
        subjectClassId,
      );
      commit(SUCCESS, subjectClassStudents);
    } catch (error) {
      const errorDetails = generateRequestError(error, 'subject-class-teachers');
      commit(FAILURE, errorDetails);
    }
  },
  async setClassStudentLevel(_, { schoolId, subjectClassId, studentUserId, levelId }) {
    const updatedStudent = await classStudentsApi.setClassStudentLevel(
      schoolId,
      subjectClassId,
      studentUserId,
      levelId,
    );
    return updatedStudent;
  },
};

const initialState = {
  [classDetailsStoreTypes.stateKeys.isLoading]: false,
  [classTeachersStoreTypes.stateKeys.isLoading]: true,
  [classStudentsStoreTypes.stateKeys.isLoading]: true,
  [classRemoveTeacherTypes.stateKeys.isLoading]: false,
  [classAddTeachersStoreTypes.stateKeys.isLoading]: false,
  [classAddStudentsStoreTypes.stateKeys.isLoading]: false,
  [addableStudentsStoreTypes.stateKeys.isLoading]: true,
  [classTeachersStoreTypes.stateKeys.data]: { results: [] },
  [classStudentsStoreTypes.stateKeys.data]: { results: [] },
  classRemoved: null,
};

export const CLASS_DETAILS_PREFIX = 'CLASS_DETAILS';

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
