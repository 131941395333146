import { ref } from 'vue';
import { defineStore } from 'pinia';

import type { OpenEndedTask } from '@apis/open-ended-tasks.types';

import * as api from '@apis/open-ended-tasks.ts';

export default defineStore('publishing:openEndedTask', () => {
  const openEndedTasks = ref<OpenEndedTask[]>([]);

  async function fetchOpenEndedTasks(subjectNodeId: number) {
    const tasks = await api.fetchOpenEndedTasks(subjectNodeId);
    openEndedTasks.value = tasks;
    return tasks;
  }

  async function createOpenEndedTask(questionId: number) {
    const task = await api.createOpenEndedTask({ question_id: questionId });
    openEndedTasks.value.push(task);
    return task;
  }

  return {
    // State
    openEndedTasks,

    // Actions
    fetchOpenEndedTasks,
    createOpenEndedTask,
  };
});
