import { ref } from 'vue';

import type { AssignmentDetails } from '@apis/generated/models.ts';

import {
  fetchOngoingSubjectAssignments,
  fetchPastSubjectAssignments,
} from 'studyApp/api/assignment.js';

type response = {
  count: number;
  current_page: number;
  next: number | null;
  num_pages: number;
  page_size: number;
  previous: number | null;
  results: {
    assignment: AssignmentDetails[];
    esq_occasion: unknown;
    is_completed: boolean;
    is_sent_to_google_classroom: boolean;
    practice_occasion: unknown;
    readingitem_set: {
      node_progress: {
        id: number;
        completed: boolean;
        first_completed_at: null | string;
        occasion_id: null | number;
        subjectnode_id: number;
      };
    }[];
    subscription_access: string;
  };
};

type params = {
  subjectId: number;
  subjectClassId: number | null;
  page: number | null;
};

export default function useOccasionAssignments() {
  const ongoingAssignments = ref<response[]>([]);
  const pastAssignments = ref<response[]>([]);

  async function fetchOngoingAssignments({
    subjectId,
    subjectClassId = null,
    page = null,
  }: params) {
    ongoingAssignments.value = await fetchOngoingSubjectAssignments(
      subjectId,
      page,
      subjectClassId,
    );
  }

  async function fetchPastAssignments({ subjectId, subjectClassId = null, page = null }: params) {
    pastAssignments.value = await fetchPastSubjectAssignments(subjectId, page, subjectClassId);
  }

  return {
    ongoingAssignments,
    pastAssignments,
    fetchOngoingAssignments,
    fetchPastAssignments,
  };
}
