<template>
  <div class="margin-top-l">
    <div class="StqModal-label"> Add the correct short response </div>
    <input
      v-model="question.answer"
      type="text"
      class="KogFormInput StqModal-Answer"
      placeholder="Type the correct response"
    />
    <div
      v-if="!showAnswerWarning"
      class="StqModal-explanation"
    >
      Responses with different letter case or spacing will be accepted
    </div>
    <div
      v-else
      class="StqModal-answerWarning"
    >
      Careful! Students will need to match this perfectly. We suggest 1-2 word answers.
    </div>
  </div>
</template>

<script>
export default {
  name: 'StqAnswerInput',
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showAnswerWarning() {
      const trimmedAnswer = this.question.answer.trim().replace(/ +/g, ' ');
      const spacesCount = (trimmedAnswer.match(/ /g) || []).length;
      return spacesCount >= 2;
    },
  },
};
</script>

<style scoped>
.StqModal-label {
  margin-bottom: var(--space-s);
  color: var(--kogPlatformGray031);
}

.StqModal-explanation {
  margin-top: var(--space-xs);
  font-style: italic;
  color: var(--kogPlatformGray051);
}

.StqModal-Answer {
  width: 196px;
}

.StqModal-answerWarning {
  margin-top: var(--space-xs);
  color: var(--kogPlatformRedDarken20);
}
</style>
