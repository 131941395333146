<template>
  <kog-table
    :is-loading-table-data="isLoadingTableContent"
    class="InsightsTable flexChild-size-1 margin-top-l"
    :class="{
      'InsightsTable-OffCanvas-visible': isOffCanvasVisible,
    }"
    row-separator="border"
    is-header-sticky
    :sticky-left-column-count="stickyLeftColumnCount"
    is-vertical-scrollable
  >
    <template #colgroup>
      <col />
      <col v-if="hasSubjectLevel" />
      <col
        v-for="subjectNode in subjectNodes"
        :key="subjectNode.id"
        :span="getColspan(subjectNode)"
      />
    </template>
    <template #header>
      <kog-table-header>
        <kog-table-cell-header
          :is-sorted="sortedBy === sortingKeys.USER__FULL_NAME"
          :sort-column="() => sortColumn(sortingKeys.USER__FULL_NAME)"
          :sort-order="sortOrder"
        >
          Student
        </kog-table-cell-header>
        <kog-table-cell-header
          v-if="hasSubjectLevel"
          :is-sorted="sortedBy === sortingKeys.LEVEL__NAME"
          :sort-column="() => sortColumn(sortingKeys.LEVEL__NAME)"
          :sort-order="sortOrder"
        >
          Level
        </kog-table-cell-header>
        <kog-table-cell-text
          v-for="subjectNode in subjectNodes"
          :key="subjectNode.id"
          :is-header-cell="true"
          :is-able-to-truncate="true"
          :colspan="getColspan(subjectNode)"
          :data-level="subjectNode.level"
          class="InsightsTable-SubjectNodeColumn"
          :tag-label="!isNodeVisible(subjectNode.id) ? 'Hidden' : ''"
          :tag-props="{
            type: 'warning',
            hideOverflow: false,
          }"
        >
          {{ getSubjectNodeName(subjectNode) }}
        </kog-table-cell-text>
      </kog-table-header>
    </template>
    <template #body>
      <kog-table-row
        v-if="isReflectionsTable"
        :is-header-like="true"
      >
        <kog-table-cell-text> All students: Flagged </kog-table-cell-text>
        <kog-table-cell-empty v-if="hasSubjectLevel" />
        <kog-table-cell-generic
          v-for="topic in flattenedReflectionQuestionLeaves"
          :key="topic.id"
          class="InsightsTable-reflectionQuestion--tagWidth"
        >
          <reflection-question-flagged-cell
            v-if="getAllStudentProgress(topic).flaggedCount > 0"
            :topic="topic"
            :flagged-count="getAllStudentProgress(topic).flaggedCount"
            :on-click="openResponses"
          />
        </kog-table-cell-generic>
      </kog-table-row>
      <kog-table-row
        v-if="isReflectionsTable"
        :is-header-like="true"
      >
        <kog-table-cell-text> All students: Submissions </kog-table-cell-text>
        <kog-table-cell-empty v-if="hasSubjectLevel" />
        <kog-table-cell-generic
          v-for="topic in flattenedReflectionQuestionLeaves"
          :key="topic.id"
          class="InsightsTable-reflectionQuestion--tagWidth"
        >
          <reflection-question-summary-cell
            :topic="topic"
            :submission-count="getAllStudentProgress(topic).submissionCount"
            :student-total="students.length"
            :on-click="openResponses"
          />
        </kog-table-cell-generic>
      </kog-table-row>
      <kog-table-row
        v-for="student in students"
        :key="student.id"
      >
        <kog-table-cell-text>
          <router-link
            v-slot="{ href, navigate }"
            :to="getStudentDetailsRoute(student)"
            custom
          >
            <a
              :href="href"
              @click="
                e => {
                  trackClickStudentLink();
                  navigate(e);
                }
              "
            >
              {{ student.first_name }} {{ student.last_name }}
            </a>
          </router-link>
        </kog-table-cell-text>
        <kog-table-cell-label
          v-if="hasSubjectLevel"
          class="InsightsTable--paddLeft"
          :text="levelName(student.level.name)"
        />
        <template v-if="isQuestionsTable || isTextbookTable">
          <kog-table-cell-generic
            v-for="subjectNode in subjectNodes"
            :key="subjectNode.id"
            :data-level="subjectNode.level"
          >
            <activity-cell
              v-if="isQuestionsTable"
              :progress="getStudentProgress(student, subjectNode)"
              :color="getStudentQuestionCompletionColor(student, subjectNode)"
              @click="showStudentProgress(student, subjectNode)"
            />
            <node-progress-cell
              v-else-if="isTextbookTable"
              :is-clickable="isClickable(subjectNode.id)"
              :mode="getNodeProgressMode(subjectNode)"
              :progress="getStudentProgress(student, subjectNode)"
              @click="showStudentProgress(student, subjectNode)"
            />
          </kog-table-cell-generic>
        </template>
        <template v-else-if="isReflectionsTable">
          <template
            v-for="subjectNode in subjectNodes"
            :key="subjectNode.id"
          >
            <kog-table-cell-generic
              v-for="node in subjectNode.reflectionQuestionLeaves"
              :key="uniqueId(student, node)"
              class="InsightsTable-reflectionQuestion--tagWidth"
              :class="{
                'InsightsTable-reflectionQuestion--extraMargin': isFinalLeaf(node, subjectNode),
              }"
              :data-level="subjectNode.level"
            >
              <reflection-question-progress-cell
                :progress="getStudentProgress(student)"
                :subject-node="subjectNode"
                :node="node"
                :student-id="student.user_id"
                :on-click="openResponses"
                :is-ngss="isNgss"
              />
            </kog-table-cell-generic>
          </template>
        </template>
      </kog-table-row>
    </template>
  </kog-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import kogTableCellEmpty from 'sharedApp/components/tables/kog-table-cell-empty.vue';
import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellLabel from 'sharedApp/components/tables/kog-table-cell-label.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import sortingKeys from 'sharedApp/const/sorting-keys.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { getTerm, isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import ActivityCell from 'studyApp/components/teacher/insights/activity-cell.vue';
import NodeProgressCell from 'studyApp/components/teacher/insights/node-progress-cell.vue';
import ReflectionQuestionFlaggedCell from 'studyApp/components/teacher/insights/reflection-question-flagged-cell.vue';
import ReflectionQuestionProgressCell from 'studyApp/components/teacher/insights/reflection-question-progress-cell.vue';
import ReflectionQuestionSummaryCell from 'studyApp/components/teacher/insights/reflection-question-summary-cell.vue';

export default {
  name: 'InsightsTable',
  components: {
    ActivityCell,
    KogTableCellGeneric,
    KogTable,
    KogTableCellHeader,
    kogTableCellEmpty,
    KogTableCellText,
    KogTableCellLabel,
    KogTableHeader,
    KogTableRow,
    NodeProgressCell,
    ReflectionQuestionProgressCell,
    ReflectionQuestionSummaryCell,
    ReflectionQuestionFlaggedCell,
  },
  mixins: [RoutesMixin],
  props: {
    tabName: {
      type: String,
      required: true,
      validator: prop => ['questions', 'textbook', 'reflections'].includes(prop),
    },
    isLoadingTableContent: {
      type: Boolean,
      required: true,
    },
    getStudentQuestionCompletionColor: {
      type: Function,
      default: () => {},
    },
    showStudentProgress: {
      type: Function,
      default: () => {},
    },
    getStudentProgress: {
      type: Function,
      default: () => {},
    },
    getAllStudentProgress: {
      type: Function,
      default: () => {},
    },
    isOffCanvasVisible: {
      type: Boolean,
      default: false,
    },
    students: {
      type: Array,
      required: true,
    },
    subjectNodes: {
      type: Array,
      required: true,
    },
    leafSubjectNodeLevel: {
      type: Number,
      default: 2,
    },
    sortColumn: {
      type: Function,
      required: true,
    },
    sortedBy: {
      type: String,
      required: true,
    },
    sortOrder: {
      type: String,
      required: true,
    },
    stickyLeftColumnCount: {
      type: Number,
      default: 1,
    },
    educationSystem: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortingKeys,
    };
  },
  computed: {
    ...mapGetters({
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
    }),
    ...mapState('subjectModule', ['subject']),

    isQuestionsTable() {
      return this.tabName === 'questions';
    },
    isTextbookTable() {
      return this.tabName === 'textbook';
    },
    isReflectionsTable() {
      return this.tabName === 'reflections';
    },
    isNgss() {
      return isNgss(this.educationSystem);
    },
    flattenedReflectionQuestionLeaves() {
      return this.subjectNodes?.flatMap(node => node.reflectionQuestionLeaves) || [];
    },
    hasSubjectLevel() {
      return this.subject?.possible_levels?.length > 0;
    },
  },
  watch: {
    subjectNodes: {
      async handler() {
        const subjectNodeIds = this.subjectNodes.map(subjectNode => subjectNode.id);
        await this.fetchFeaturesAndMappings({ subjectNodeIds });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchFeaturesAndMappings: 'subjectNodeFeatureModule/fetchFeaturesAndMappings',
    }),
    getColspan(subjectNode) {
      return this.isReflectionsTable ? subjectNode.reflectionQuestionLeaves.length : 1;
    },
    uniqueId(student, node) {
      return `${student.id}${node.id}`;
    },
    openResponses(node, studentId = null) {
      const name = 'Reflection Question Feedback - Heatmap click';
      this.$mixpanel.trackEvent(name, {
        teacher_id: this.user.id,
        subject_class_id: this.classId,
      });

      const target = {
        name: 'teacherSectionResponsesOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
        query: {
          origin: 'insights',
          origin_active_tab: this.tabName,
        },
      };
      if (studentId) {
        target.query.focus = studentId;
      }
      this.$router.push(target);
    },
    isUnitLevel(subjectNode) {
      return subjectNode.number === '0';
    },
    getSubjectNodeName(subjectNode) {
      if (subjectNode.activity) {
        return subjectNode.name;
      }
      if (this.isUnitLevel(subjectNode) && this.isNgss) {
        return `Unit ${this.unitNumber(subjectNode)}`;
      }
      return `${subjectNode.number_including_ancestors} ${subjectNode.name}`;
    },
    unitNumber(subjectNode) {
      return subjectNode.number_including_ancestors.split('.')[0];
    },
    getStudentDetailsRoute(student) {
      const studentDetailsRoute = {
        name: 'studentDetails',
        params: {
          classSlug: this.subjectClassSlug,
          cid: this.classId,
          sid: this.subjectId,
          studentId: student.user_id,
        },
        query: {
          origin: 'insights',
          origin_active_tab: this.tabName,
        },
      };
      return studentDetailsRoute;
    },
    trackClickStudentLink() {
      this.$mixpanel.trackEvent('Insights - Click on student link', {
        tab: this.tabName,
      });
    },
    isFinalLeaf(node, subjectNode) {
      if (!node || !subjectNode) {
        return false;
      }
      const questions = subjectNode.reflectionQuestionLeaves.map(leaf => leaf.id);
      return questions && node.id === questions[questions.length - 1];
    },
    isClickable(subjectNodeId) {
      return !this.isNgss && !this.hasFeatures(subjectNodeId);
    },
    hasFeatures(subjectNodeId) {
      return !!this.getActivityBySubjectNodeId(subjectNodeId);
    },
    levelName(studentLevel) {
      const educationSystemName = this.subject.educationsystem.name;
      return getTerm(studentLevel, educationSystemName);
    },
    getNodeProgressMode(node) {
      if (node.level === this.leafSubjectNodeLevel) return 'circle';
      if (node.activity) return 'circle';

      return 'bar';
    },
  },
};
</script>

<style scoped>
.InsightsTable {
  min-height: fit-content;
  transition: margin-right 0.15s ease-in-out 0s;
}

.InsightsTable-OffCanvas-visible {
  margin-right: var(--off-canvas-width);
}

.InsightsTable-SubjectNodeColumn {
  min-width: 150px;
}

.InsightsTable-reflectionQuestion--tagWidth :deep(button) {
  width: 80px;
}

.InsightsTable-reflectionQuestion--extraMargin :deep(button) {
  margin-right: 25px;
}

.InsightsTable--paddLeft {
  padding-left: var(--space-xs);
}
[data-level='1'] ~ [data-level='2']:not([data-level='2'] + [data-level='2']),
[data-level='2'] ~ [data-level='3']:not([data-level='3'] + [data-level='3']),
[data-level='3'] ~ [data-level='4']:not([data-level='4'] + [data-level='4']),
[data-level='4'] ~ [data-level='5']:not([data-level='5'] + [data-level='5']) {
  box-shadow: inset var(--kog-colors-grey-500) 1px 0 0 0;
}
</style>
