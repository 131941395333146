import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { ClickObserver } from '@ckeditor/ckeditor5-engine';
import { toWidget, toWidgetEditable, Widget } from '@ckeditor/ckeditor5-widget';

import { forbidNesting, isClickInsideSelectedElement } from '../utils/commands.js';
import InsertContentBoxCommand from './content-box-command.js';

function hexToRgb(hex) {
  const tmpHex = hex.replace(/^#/, '');

  const bigint = parseInt(tmpHex, 16);
  const r = Math.floor(bigint / 65536) % 256;
  const g = Math.floor(bigint / 256) % 256;
  const b = Math.floor(bigint % 256);

  return `rgb(${r}, ${g}, ${b})`;
}

function contentBoxTagConverter(conversion) {
  conversion.for('upcast').elementToElement({
    model: 'contentBoxTag',
    view: 'content-box',
  });

  conversion.for('dataDowncast').elementToElement({
    model: 'contentBoxTag',
    view: 'content-box',
  });

  conversion.for('editingDowncast').elementToElement({
    model: 'contentBoxTag',
    view: (modelElement, { writer }) => {
      const viewElement = writer.createContainerElement('content-box');
      return toWidget(viewElement, writer, { label: 'contentBox widget' });
    },
  });

  conversion.attributeToAttribute({
    model: {
      name: 'contentBoxTag',
      key: 'templateId',
    },
    view: 'template-id',
  });
  conversion.attributeToAttribute({
    model: {
      name: 'contentBoxTag',
      key: 'titleTag',
    },
    view: 'title-tag',
  });
}

function contentBoxConverter(conversion, contentBoxTemplateMap) {
  conversion.for('upcast').elementToElement({
    model: 'contentBox',
    view: {
      name: 'div',
      classes: 'ContentBox',
    },
  });

  conversion.for('downcast').elementToElement({
    model: 'contentBox',
    view: (modelElement, { writer }) => {
      const templateId = modelElement.parent.getAttribute('templateId');
      const { color } = contentBoxTemplateMap[templateId];

      const divElement = writer.createContainerElement('div', {
        class: 'ContentBox',
        style: `background-color: ${hexToRgb(color)}`,
      });

      return divElement;
    },
  });
}

function contentBoxTitleConverter(conversion) {
  conversion.for('upcast').elementToElement({
    model: (viewElement, { writer }) => {
      const modelElement = writer.createElement('contentBoxTitle');
      const childNode = viewElement.getChild(0);

      if (childNode && childNode.name === 'span' && !childNode.hasAttribute('kog-id')) {
        /* eslint-disable no-underscore-dangle */
        const text = childNode.getChild(0)._textData;
        viewElement._removeChildren();
        writer.insertText(text, { spanKogId: 'old' }, modelElement, 0);
        /* eslint-enable no-underscore-dangle */
      }

      return modelElement;
    },
    view: {
      name: 'div',
      classes: 'ContentBox-title',
    },
  });
  conversion.for('downcast').elementToElement({
    model: 'contentBoxTitle',
    view: {
      name: 'div',
      classes: 'ContentBox-title',
    },
  });
}

function contentBoxIconConverter(conversion, contentBoxTemplateMap) {
  conversion.for('upcast').elementToElement({
    model: 'contentBoxIcon',
    view: {
      name: 'div',
      classes: 'ContentBox-icon',
    },
  });
  conversion.for('dataDowncast').elementToElement({
    model: 'contentBoxIcon',
    view: {
      name: 'div',
      classes: 'ContentBox-icon',
    },
  });
  conversion.for('editingDowncast').elementToElement({
    model: 'contentBoxIcon',
    view: (modelElement, { writer }) => {
      const templateId = modelElement.parent.parent.getAttribute('templateId');
      const { icon } = contentBoxTemplateMap[templateId];
      const div = writer.createContainerElement('div', {
        class: 'ContentBox-icon',
      });
      const i = writer.createAttributeElement('i');
      writer.addClass(['far', icon], i);
      writer.insert(writer.createPositionAt(div, 0), i);
      return div;
    },
  });
}

function contentBoxContentConverter(conversion) {
  conversion.for('upcast').elementToElement({
    model: 'contentBoxContent',
    view: {
      name: 'div',
      classes: 'ContentBox-content',
    },
  });
  conversion.for('dataDowncast').elementToElement({
    model: 'contentBoxContent',
    view: {
      name: 'div',
      classes: 'ContentBox-content',
      attributes: {
        'data-gramm': 'false',
      },
    },
  });
  conversion.for('editingDowncast').elementToElement({
    model: 'contentBoxContent',
    view: (_, { writer }) => {
      const div = writer.createEditableElement('div', {
        class: 'ContentBox-content',
        'data-gramm': 'false',
      });
      return toWidgetEditable(div, writer);
    },
  });
}

export default class ContentBoxEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    const pluginConfig = this.editor.config.get('contentBox');
    this.contentBoxTemplateMap = pluginConfig.contentBoxTemplateMap;

    this.defineSchema();
    this.defineConverters();
    this.defineCommands();
  }

  defineSchema() {
    const { editor } = this;
    const { schema } = editor.model;

    schema.register('contentBoxTag', {
      inheritAllFrom: '$blockObject',
      allowAttributes: ['templateId', 'titleTag'],
    });

    schema.register('contentBox', {
      inheritAllFrom: '$blockObject',
      allowIn: 'contentBoxTag',
      allowContentOf: '$block',
    });

    schema.register('contentBoxIcon', {
      inheritAllFrom: '$blockObject',
      allowIn: 'contentBox',
      allowContentOf: '$root',
    });

    schema.register('contentBoxTitle', {
      allowContentOf: '$root',
      isLimit: true,
      allowIn: ['contentBox'],
    });

    schema.register('contentBoxContent', {
      allowContentOf: '$root',
      isLimit: true,
      allowIn: 'contentBox',
    });

    schema.addChildCheck(forbidNesting('contentBoxTag'));
  }

  defineConverters() {
    const { conversion } = this.editor;
    contentBoxTagConverter(conversion);
    contentBoxConverter(conversion, this.contentBoxTemplateMap);
    contentBoxTitleConverter(conversion);
    contentBoxIconConverter(conversion, this.contentBoxTemplateMap);
    contentBoxContentConverter(conversion);
  }

  defineCommands() {
    const { editor } = this;
    const { document } = editor.model;
    const { view, mapper } = editor.editing;

    editor.commands.add('insertContentBox', new InsertContentBoxCommand(editor));
    editor.commands.add('updateContentBox', new Command(editor));

    view.addObserver(ClickObserver);
    editor.listenTo(view.document, 'click', (_, data) => {
      if (
        isClickInsideSelectedElement(data, document, mapper, 'contentBoxTag', ['contentBoxContent'])
      ) {
        editor.commands.get('updateContentBox').execute();
      }
    });
  }
}
