<template>
  <router-link
    :to="
      isPerformanceTask
        ? performanceTaskOverviewRoute(subjectNode)
        : studentSubmissionsRoute(subjectNode)
    "
    class="ActivityContainer-statisticsContainer flexContainer flexContainer-alignCenter padd-xs flexContainer-spaceBetween width-full anchor-unstyled"
  >
    <content-node-icon
      :icon-class="activityIcon"
      class="margin-right-s"
    />
    <div class="flexContainer flexChild-size-1 flexContainer-alignCenter">
      <div class="flexContainer flexContainer-justifyCenter flexContainer-column">
        <span class="heading-xs margin-right-xs"> Submissions </span>
      </div>
    </div>
    <div class="flexContainer flexContainer-alignCenter">
      <kog-tag
        is-chip
        class="margin-right-m"
        icon-class="fa-user-check"
        size="m"
        type="informative"
        :label="`${studentsWithSubmittedActivities}/${studentsTotal}`"
        @click.stop="openStudentSubmissionsModal"
      />
    </div>
  </router-link>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import { getFeatureTypeIcon, PERFORMANCE_TASK } from 'sharedApp/const/features.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ContentNodeIcon from 'studyApp/components/subject-class-overview/content-node-icon.vue';
import FeaturesMixin from 'studyApp/mixins/features-mixin.js';

import ActivitySubmissionsModal from './activity-submissions-modal.vue';

export default {
  name: 'ActivityStatisticsContainer',
  components: {
    ContentNodeIcon,
    KogTag,
  },
  mixins: [RoutesMixin, FeaturesMixin],
  props: {
    subject: {
      type: Object,
      required: true,
    },
    subjectNode: {
      type: Object,
      required: true,
    },
    classSlug: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      subjectClassStudentList: state => state.teacherStudentListModule.subjectClassStudentsList,
    }),
    ...mapGetters({
      classStats: 'inlineStatisticsModule/classStats',
    }),
    feature() {
      return this.getFeatureBySubjectNode(this.subjectNode);
    },
    activityIcon() {
      return getFeatureTypeIcon(this.feature?.type);
    },
    isPerformanceTask() {
      return this.feature.type === PERFORMANCE_TASK;
    },
    subjectClassName() {
      return this.classStatistics.subject_class;
    },
    studentsWithSubmittedActivities() {
      return this.classStatistics?.students_completed_nodes[this.subjectNode.id] || 0;
    },
    studentsTotal() {
      return this.classStatistics?.students_total || 0;
    },
    classStatistics() {
      return this.classStats.find(statsForClass => statsForClass.subject_class_id === this.classId);
    },
  },
  created() {
    this.fetchSubjectClassStudentsList(this.classId);
  },
  methods: {
    ...mapWaitingActions({
      fetchSubjectClassStudentsList: {
        action: 'teacherStudentListModule/fetchSubjectClassStudentsList',
        loader: 'loading-student-list',
      },
    }),
    studentSubmissionsRoute(node) {
      return {
        name: 'studentActivitySubmissions',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
        query: {
          origin: 'book_section',
          origin_node: this.$route.query.node,
        },
      };
    },
    navigateToStudentSubmissions(node) {
      this.$router.push(this.studentSubmissionsRoute(node));
    },
    performanceTaskOverviewRoute(node) {
      return {
        name: 'performanceTaskOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
        query: {
          origin: 'book_section',
          origin_node: this.$route.query.node,
        },
      };
    },
    navigateToPerformanceTaskOverview(node) {
      this.$router.push(this.performanceTaskOverviewRoute(node));
    },

    openStudentSubmissionsModal() {
      const subjectClassId = this.classId;
      const {
        subject,
        classSlug,
        subjectNode,
        subjectClassStudentList,
        user,
        feature,
        subjectClassName,
        navigateToStudentSubmissions,
        navigateToPerformanceTaskOverview,
        subtitle,
      } = this;
      this.$modal(ActivitySubmissionsModal, {
        subjectNode,
        subjectClassId,
        students: subjectClassStudentList,
        subjectClassName,
        feature,
        navigateToStudentSubmissions,
        navigateToPerformanceTaskOverview,
        user,
        subject,
        classSlug,
        subtitle,
      });
    },
  },
};
</script>

<style scoped>
.ActivityContainer-statisticsContainer {
  background: var(--kog-colors-aubergine-100);
  border-radius: 12px;
}
</style>
