<template>
  <kog-modal
    :maximize="true"
    class="QuestionAnswersModal"
    :close-modal="closeModal"
  >
    <!--Question info-->
    <template #subHeader>
      <div class="flexContainer muted flexContainer-alignCenter">
        <div class="margin-right-m"> Question {{ currentQuestionIndex + 1 }} </div>
        <div
          v-for="(subjectnode, index) in currentQuestion.subjectnode_mappings"
          :key="index"
          class="margin-right-xs"
        >
          <kog-tag :label="formatTopicNumber(subjectnode.number_including_ancestors)" />
        </div>
        <question-type-label
          :question-type="currentQuestion.type"
          :tooltip-text="questionType"
          class="margin-right-xs"
        />
        <kog-difficulty
          v-if="showDifficulty"
          :difficulty="currentQuestion.difficulty"
          class="margin-right-xs"
        />
        <kog-calculator
          :is-light-icon="true"
          :tags="currentQuestion.tags"
          class="margin-right-xs"
        />
      </div>
    </template>
    <!--/Question info-->

    <!--Body-->
    <template #modalBody>
      <div class="padd-left-l padd-right-l padd-top-m">
        <div class="absoluteContainer">
          <question-text-preview
            ref="questionPreview"
            :class="questionPreviewClasses"
            :question="currentQuestion"
            :show-answers="isAnswerShown"
          />
          <div :class="blurClasses" />
        </div>

        <assignment-question-answer
          v-if="isAnswerShown"
          :question="currentQuestion"
        />
        <div class="text-center">
          <button
            class="KogButtonLegacy KogButtonLegacy--subtleLink"
            @click="toggleShowAnswer"
          >
            <i
              :class="{
                'fa-chevron-up': isAnswerShown,
                'fa-chevron-down': !isAnswerShown,
              }"
              class="fas padd-right-xxs"
            />
            {{ toggleShowAnswerMsg }}
          </button>
        </div>
      </div>

      <div class="QuestionAnswersModal-answersContainer">
        <div class="padd-bottom-s flexContainer muted">
          <span class="QuestionAnswersModal-studentCol margin-left-l margin-right-l">
            Student
          </span>
          <span> Answers </span>
        </div>
        <div v-kog-mathjax>
          <question-answers-card
            v-for="response in questionResponses"
            :key="response.userId"
            class="QuestionAnswersModal-answersCard"
            :user-response-summary="response"
            :current-question="currentQuestion"
          />
        </div>
      </div>
    </template>
    <!--/Body-->

    <!--Footer-->
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          v-if="!hasMoreThanOneQuestion"
          class="KogButtonLegacy"
          @click="closeModal"
        >
          Close
        </button>
        <button
          v-if="hasMoreThanOneQuestion"
          :disabled="!hasPrevious"
          class="KogButtonLegacy KogButtonLegacy--iconLeft"
          @click="previousQuestion()"
        >
          <i class="fas fa-chevron-left" />Previous question
        </button>
        <button
          v-if="hasMoreThanOneQuestion"
          :disabled="!hasNext"
          class="KogButtonLegacy KogButtonLegacy--iconRight"
          @click="nextQuestion()"
        >
          Next question<i class="fas fa-chevron-right" />
        </button>
      </div>
    </template>
    <!--/Footer-->
  </kog-modal>
</template>

<script>
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

import KogDifficulty from 'learning/common/components/difficulty/kog-difficulty.vue';
import QuestionTextPreview from 'learning/common/components/question-text-preview.vue';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import { states } from 'sharedApp/components/fill-in-the-blanks-question/fill-blank-question-input.vue';
import KogCalculator from 'sharedApp/components/icons/kog-calculator.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import { formatTopicNumber } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  getFtbUIDsInOrder,
  getQuestionTypeText,
  isFBQ,
  isMCQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import QuestionAnswersCard from 'studyApp/components/teacher/assignments/details/question-answers-card.vue';
import AssignmentQuestionAnswer from 'studyApp/components/teacher/assignments/question-answer/assignment-question-answer.vue';
import QuestionTypeLabel from 'studyApp/components/teacher/assignments/question-type-label.vue';
import order from 'teachApp/utils/assignment-question-answers-sort-utils.js';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export default {
  name: 'QuestionAnswersModal',
  components: {
    KogDifficulty,
    KogModal,
    QuestionTypeLabel,
    QuestionTextPreview,
    AssignmentQuestionAnswer,
    KogCalculator,
    QuestionAnswersCard,
    KogTag,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    assignment: {
      type: Object,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      default: false,
    },
    isDone: {
      type: Function,
      default: () => {},
    },
    trackAssignmentDetailsStudentsEvent: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isAnswerShown: false,
      currentQuestion: null,
      currentQuestionIndex: 0,
      orderedQuestionIds: [],
      isQuestionClipped: false,
    };
  },
  computed: {
    blurClasses() {
      return {
        'QuestionAnswersModal-blur': this.isQuestionClipped && !this.isAnswerShown,
        'QuestionAnswersModal-blur--hidden': !this.isQuestionClipped,
      };
    },
    questionPreviewClasses() {
      return {
        'QuestionAnswersModal-questionPreview': !this.isAnswerShown,
        'QuestionAnswersModal-questionPreview--noneMaxHeight': this.isAnswerShown,
      };
    },
    toggleShowAnswerMsg() {
      return this.isAnswerShown ? 'Show less' : 'Show more';
    },
    questionType() {
      return getQuestionTypeText(this.currentQuestion);
    },
    hasNext() {
      return this.currentQuestionIndex + 1 < this.orderedQuestionIds.length;
    },
    hasPrevious() {
      return this.currentQuestionIndex > 0;
    },
    hasMoreThanOneQuestion() {
      return this.orderedQuestionIds.length > 1;
    },
    questionResponses() {
      const questionResponses = this.assignment.practice_occasions.map(occasion => {
        const isDone = this.isDone(occasion);
        const response = {
          userId: occasion.user_id,
          userName: occasion.name,
          isDone,
        };

        response.isCorrect = this.isCorrectAnswer(occasion);
        if (isFBQ(this.currentQuestion)) {
          response.studentResponse = this.getStudentFTBResponse(occasion);
        } else if (isMCQ(this.currentQuestion)) {
          const studentResponse = this.getStudentCleanedMCQResponse(occasion);
          response.studentResponse = studentResponse;
          response.cleanedStudentResponse = studentResponse;
        } else {
          response.studentResponse = this.getStudentResponse(occasion);
          response.cleanedStudentResponse = this.cleanResponse(response.studentResponse);
        }
        return response;
      });

      return order(questionResponses, this.currentQuestion.type);
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
    this.initObserver();
  },
  created() {
    this.orderedQuestionIds = this.assignment.questions.map(question => question.id);
    this.currentQuestion = this.getQuestion(this.questionId);
    this.currentQuestionIndex = this.orderedQuestionIds.indexOf(this.questionId);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keyHandler);
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    initObserver() {
      this.observer = new ResizeObserver(this.onQuestionPreviewRender);
      this.observer.observe(this.$refs.questionPreview.$refs.root);
    },
    onQuestionPreviewRender() {
      if (!this.$refs.questionPreview) {
        return;
      }
      const { scrollHeight, clientHeight } = this.$refs.questionPreview.$refs.root;
      this.isQuestionClipped = clientHeight < scrollHeight;
    },
    cleanResponse(response) {
      let cleanedResponse = response;
      if (response.length > 0) {
        cleanedResponse = cleanedResponse.toLowerCase();
        cleanedResponse = cleanedResponse.trim();
      }
      return cleanedResponse;
    },
    formatTopicNumber(topicNumber) {
      return formatTopicNumber(topicNumber);
    },
    isCorrectAnswer(studentPracticeOccasion) {
      const studentCorrectAnswersList =
        studentPracticeOccasion.answered_questions.questions_correct_answer.map(
          ({ question_id: id }) => id,
        );
      return studentCorrectAnswersList.includes(this.currentQuestion.id);
    },
    getAnswerForCurrentQuestion(occasion) {
      const {
        questions_correct_answer: correctAnswers,
        questions_incorrect_answer: incorrectAnswers,
      } = occasion.answered_questions;

      const allAnswers = correctAnswers.concat(incorrectAnswers);
      return allAnswers.find(answer => answer.question_id === this.currentQuestion.id);
    },
    getStudentResponse(occasion) {
      const answersForCurrentQuestion = this.getAnswerForCurrentQuestion(occasion);
      if (answersForCurrentQuestion) {
        return answersForCurrentQuestion.answer.trim();
      }
      return '';
    },
    getStudentFTBResponse(occasion) {
      return this.getFTBBlanksInRightOrder().map(blankUid => {
        const ftbAnswer = this.getFTBAnswer(blankUid, occasion);
        const ftbState = this.getFTBState(blankUid, occasion);
        return {
          blankUid,
          ftbAnswer,
          ftbState,
        };
      });
    },
    getStudentCleanedMCQResponse(occasion) {
      const studentChoices = this.getAnswerForCurrentQuestion(occasion);
      if (!studentChoices) {
        return null;
      }
      if (Array.isArray(studentChoices.answer)) {
        return studentChoices.answer.map(choice => ({
          ...choice,
          answer: choice.answer.trim(),
        }));
      }

      return [
        {
          is_correct: this.isCorrectAnswer(occasion),
          answer: studentChoices.answer.trim(),
        },
      ];
    },
    getFTBBlanksInRightOrder() {
      const ftqQuestion = this.assignment.questions.find(q => q.id === this.currentQuestion.id);
      return getFtbUIDsInOrder(ftqQuestion.question_html);
    },
    getFTBState(blankUid, occasion) {
      const ftbAnswer = this.getAnswerForCurrentQuestion(occasion);
      const isFTBBlankCorrect = ftbAnswer?.correctly_answered_blanks.includes(blankUid);
      return isFTBBlankCorrect ? states.correct : states.incorrect;
    },
    getFTBAnswer(blankUid, occasion) {
      const ftbAnswer = this.getAnswerForCurrentQuestion(occasion);
      return ftbAnswer?.answer[blankUid];
    },
    toggleShowAnswer() {
      const eventName = this.isAnswerShown
        ? 'Assignment Details heatmap modal - Click Show less button'
        : 'Assignment Details heatmap modal - Click Show more button';
      this.trackAssignmentDetailsStudentsEvent(eventName);
      this.isAnswerShown = !this.isAnswerShown;
    },
    getQuestion(questionId) {
      return this.assignment.questions.find(question => question.id === questionId);
    },
    nextQuestion() {
      const nextIndex = this.currentQuestionIndex + 1;
      if (nextIndex >= this.orderedQuestionIds.length) {
        return;
      }
      this.currentQuestion = this.getQuestion(this.orderedQuestionIds[nextIndex]);
      this.currentQuestionIndex = nextIndex;
      this.isAnswerShown = false;
    },
    previousQuestion() {
      const previousIndex = this.currentQuestionIndex - 1;
      if (previousIndex < 0) {
        return;
      }
      this.currentQuestion = this.getQuestion(this.orderedQuestionIds[previousIndex]);
      this.currentQuestionIndex = previousIndex;
      this.isAnswerShown = false;
    },
    keyHandler(event) {
      switch (event.keyCode) {
        case 39: // arrow right
          this.nextQuestion();
          break;
        case 37: // arrow left
          this.previousQuestion();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.QuestionAnswersModal {
  width: 75vw;
}

.QuestionAnswersModal-previewQuestion :deep(.content-editable) {
  max-width: 100%;
  word-break: normal;
}

.QuestionAnswersModal-questionPreview {
  overflow: hidden;
  max-height: 64px;
}

.QuestionAnswersModal-questionPreview--noneMaxHeight {
  overflow: auto;
  max-height: none;
}

/* stylelint-disable declaration-colon-newline-after,comment-empty-line-before,max-line-length */
.QuestionAnswersModal-blur {
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 40px;

  background-image: linear-gradient(
    to bottom,
    rgba(var(--kogPlatformWhite), 0) 0%,
    var(--kogPlatformWhite)
  );
}

.QuestionAnswersModal-blur--hidden {
  display: none;
}

.QuestionAnswersModal-answersContainer {
  padding: var(--space-l) var(--space-xl);
  background: var(--kogPlatformGray096);
  border-top: solid 1px var(--kogPlatformGray084);
}

.QuestionAnswersModal-studentCol,
.QuestionAnswersModal-answersCard :deep(.QuestionAnswersCard-studentCol) {
  flex: 0 0 224px;
  min-width: 0;
}

@media (--viewport-s) {
  .QuestionAnswersModal {
    width: 95%;
  }
}
</style>
