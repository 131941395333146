<template>
  <kog-select
    v-tooltip="{
      theme: 'kog-tooltip',
      content: disabled ? 'Student level is set in the first subject class they are added in.' : '',
      boundary: 'document.body',
    }"
    label="level"
    is-label-hidden
    :options="items"
    :selected-value="currentLevel.id"
    :disabled="disabled"
    @change="setLevel"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import { CLASS_DETAILS_PREFIX } from 'schoolAdminApp/store/modules/class-details/index.js';
import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';

export default {
  name: 'StudentLevelSelector',
  components: {
    KogSelect,
  },
  props: {
    currentLevel: {
      type: Object,
      required: true,
    },
    possibleLevels: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['level'],
  computed: {
    ...mapGetters(CLASS_DETAILS_PREFIX, { subjectClass: 'subjectClass' }),
    ...mapGetters('subjectModule', {
      educationSystemName: 'educationSystemName',
    }),

    items() {
      return this.possibleLevels.map(level => {
        let educationSystem;
        if (this.$store.hasModule('subjectModule')) {
          educationSystem = this.educationSystemName;
        }
        if (this.$store.hasModule(CLASS_DETAILS_PREFIX)) {
          educationSystem = this.subjectClass.subject.education_system;
        }
        const text = getTerm(level.name, educationSystem);
        return {
          value: level.id,
          text,
        };
      });
    },
  },
  methods: {
    setLevel(levelId) {
      this.$emit('level', levelId);
    },
  },
};
</script>

<style scoped></style>
