import SubMenuItem from 'studyApp/menus/sub-menu-item.js';

export default class StrengthBattleSubMenuItem extends SubMenuItem {
  constructor(config) {
    const defaults = {
      key: 'practice/battle',
      displayText: 'Strength battle',
    };
    super({ ...defaults, ...config });
  }

  navigateTo({ router, currentRoute }) {
    const params = {
      classSlug: currentRoute.params.classSlug,
      cid: currentRoute.params.cid,
      sid: currentRoute.params.sid,
    };
    this.goToRoute(router, currentRoute, 'classBattleSetup', params);
  }
}
