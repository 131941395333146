import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import type { AssignmentDetails, PracticeItem } from '@apis/generated';

import useAssignmentReadableSettings from 'sharedApp/composables/use-assignment-readable-settings.ts';
import { isAnswered, isSubmitted } from 'studyApp/utils/practice-item-utils.js';

type STQAnswer = { user_text: string };
type FBQAnswer = { user_answers: Record<string, string> };
type MCQAnswer = { user_response: number[] };
type ExtendedPracticeItem = PracticeItem & {
  question_uuid: string;
  is_submitted?: boolean;
  user_answer: {
    is_correct: boolean | null;
  } & (STQAnswer | FBQAnswer | MCQAnswer);
  solution?: unknown;
};
export default function useAssignmentAnswerStrategy({
  next,
  previous,
}: {
  next: () => unknown;
  previous: () => unknown;
}) {
  const store = useStore();
  const occasion = computed(() => store.state.assignmentModule.assignment);
  const assignment = computed<AssignmentDetails>(() => occasion.value?.assignment);

  const settings = ref<ReturnType<typeof useAssignmentReadableSettings>>();
  watch(
    assignment,
    currentAssignment => {
      if (!currentAssignment) return;
      settings.value = useAssignmentReadableSettings(currentAssignment);
    },
    { immediate: true },
  );

  const currentPracticeItem = computed<ExtendedPracticeItem>(
    () => store.getters['assignmentModule/currentPracticeItem'],
  );
  const currentPracticeItemIndex = computed<number>(
    () => store.getters['assignmentModule/currentPracticeItemIndex'],
  );
  const currentPageNumber = computed<number>(() => currentPracticeItemIndex.value + 1);
  const hasAnsweredCurrentQuestion = computed<boolean>(
    () => store.getters['assignmentModule/hasAnsweredCurrentQuestion'],
  );

  const practiceItems = computed<ExtendedPracticeItem[]>(
    () => store.getters['assignmentModule/getAllPracticeItems'] || [],
  );
  function isStepDone(step: number) {
    if (!step) return false;

    const practiceItem = practiceItems.value[step - 1];
    const answered = isAnswered(
      practiceItem,
      store.getters['assignmentModule/getQuestionByUuid'](practiceItem.question_uuid),
    );
    const submitted = isSubmitted(practiceItem);

    if (settings.value && settings.value.answers.displayImmediately) {
      return answered && submitted;
    }

    return answered;
  }
  function isStepCorrect(step: number) {
    if (!step) return false;

    const practiceItem = practiceItems.value[step - 1];
    return practiceItem.user_answer?.is_correct;
  }

  const currentDisplayState = computed(() => {
    const isDone = isStepDone(currentPageNumber.value);

    if (settings.value && !settings.value.answers.displayImmediately) {
      return isDone ? 'answered' : 'neutral';
    }
    if (isDone) {
      if (currentPracticeItem.value.user_answer.is_correct === null) {
        return 'neutral';
      }

      return currentPracticeItem.value.user_answer.is_correct ? 'correct' : 'incorrect';
    }
    if (hasAnsweredCurrentQuestion.value) {
      return 'awaiting_submission';
    }
    return 'awaiting_answer';
  });

  const hasAnsweredAllQuestions = computed(
    () => store.getters['assignmentModule/hasAnsweredAllQuestions'],
  );
  const hasSubmittedAllQuestions = computed(
    () => store.getters['assignmentModule/hasSubmittedAllQuestions'],
  );
  const isSubmitDisabled = computed(() => {
    if (settings.value && settings.value.answers.displayImmediately) {
      return !hasSubmittedAllQuestions.value || store.getters['wait/is']('submitting_assignment');
    }
    return !hasAnsweredAllQuestions.value || store.getters['wait/is']('submitting_assignment');
  });

  function handleNext() {
    if (!settings.value) return;

    if (settings.value.answers.displayImmediately && !currentPracticeItem.value.is_submitted) {
      store.dispatch('assignmentModule/submitQuestionAnswer');
      return;
    }

    next();
  }

  function handlePrevious() {
    previous();
  }

  return {
    handleNext,
    handlePrevious,
    isStepDone,
    isStepCorrect,
    currentDisplayState,
    isSubmitDisabled,
    hasAnsweredCurrentQuestion,
    hasAnsweredAllQuestions,
    hasSubmittedAllQuestions,
  };
}
