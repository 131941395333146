<template>
  <div class="kog-container">
    <user-profile-header :full-name="fullName" />
    <user-info :user="user" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import UserInfo from './components/user-info.vue';
import UserProfileHeader from './components/user-profile-header.vue';

export default {
  name: 'UserProfile',
  components: {
    UserProfileHeader,
    UserInfo,
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    fullName() {
      return `${this.user?.first_name} ${this.user?.last_name}`;
    },
  },
};
</script>

<style scoped></style>
