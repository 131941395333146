<template>
  <div class="BookRailUnitBinder-container">
    <div class="kog-container-fluid u-noPadding">
      <book-rail-slider-header
        class="margin-bottom-s"
        title="Unit binder"
        @close="onCloseClick"
      />
      <div class="flexContainer margin-bottom-s">
        <kog-icon
          icon-class="fa-messages-question"
          fa-style="regular"
          size="s"
          class="BookRailUnitBinder-dqbIcon padd-s margin-right-xs"
        />
        <span class="text-overline flexChild-center">Driving Question Board</span>
      </div>
      <kog-loader
        v-if="$wait.is('loading-unit-binder')"
        :loading="$wait.is('loading-unit-binder')"
        :loading-msg="'Loading the driving question board...'"
      />
      <div
        v-else
        class="BookRailUnitBinder-content"
      >
        <driving-question-image
          v-if="
            drivingQuestion && (drivingQuestion.images.length > 0 || drivingQuestion.external_url)
          "
          class="padd-bottom-m"
          :driving-question="drivingQuestion"
          :subject-node-id="drivingQuestionBoardSubjectNodeId"
          :max-height="280"
        />
        <div
          v-else
          class="BookRailUnitBinder-stickyNote margin-top-m margin-left-xxl margin-bottom-l text-medium font-satoshi padd-m"
        >
          {{ stickyNoteText }}
        </div>
        <svg-icon
          size="s"
          icon-name="squigglyLineThin"
          class="BookRailUnitBinder-squigglyLine padd-bottom-m"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import SvgIcon from 'sharedApp/components/icons/svg-icon.vue';
import { DRIVING_QUESTION_BOARD } from 'sharedApp/const/features.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import DrivingQuestionImage from 'studyApp/components/driving-question/driving-question-image.vue';
import BookRailSliderHeader from 'studyApp/components/side-navigation/book-rail-slider-header.vue';

export default {
  name: 'BookRailUnitBinder',
  components: {
    DrivingQuestionImage,
    KogIcon,
    SvgIcon,
    KogLoader,
    BookRailSliderHeader,
  },
  mixins: [RoutesMixin],
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      getTopicNodeByDescendantId: 'subjectModule/getTopicNodeByDescendantId',
      drivingQuestionsByDqbId: 'drivingQuestionModule/drivingQuestionsByDqbId',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    stickyNoteText() {
      if (this.user.hasEditContentPermission() || this.user.isInternalUser()) {
        return 'The Driving Question Board is unavailable to content administrators.';
      }
      return 'The Driving Question board will be available here.';
    },
    topic() {
      return this.getTopicNodeByDescendantId(this.nodeId);
    },
    specialIntroductionNode() {
      return this.topic?.children.find(child => child.is_special_introduction_node);
    },
    drivingQuestionBoardSubjectNodeId() {
      if (!this.specialIntroductionNode) return null;
      return this.specialIntroductionNode.children.find(
        node => this.getActivityBySubjectNodeId(node.id)?.type === DRIVING_QUESTION_BOARD,
      )?.id;
    },
    drivingQuestion() {
      if (!this.drivingQuestionBoardSubjectNodeId) return null;
      const drivingQuestionBoard = this.getActivityBySubjectNodeId(
        this.drivingQuestionBoardSubjectNodeId,
      );
      return this.drivingQuestionsByDqbId[drivingQuestionBoard.id];
    },
  },
  created() {
    this.fetchDrivingQuestions(this.classId);
    if (this.specialIntroductionNode?.children.length > 0) {
      const nodeIds = this.specialIntroductionNode.children.map(child => child.id);
      this.fetchFeaturesAndMappings({
        subjectNodeIds: nodeIds,
      });
    }
  },
  methods: {
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
    }),
    ...mapWaitingActions({
      fetchFeaturesAndMappings: {
        action: 'subjectNodeFeatureModule/fetchFeaturesAndMappings',
        loader: 'loading-unit-binder',
      },
      fetchDrivingQuestions: {
        action: 'drivingQuestionModule/fetchDrivingQuestions',
        loader: 'loading-unit-binder',
      },
    }),
    onCloseClick() {
      this.closeRailSlider();
    },
  },
};
</script>

<style scoped>
.BookRailUnitBinder-dqbIcon {
  color: var(--kog-text-default);
  background-color: var(--kog-colors-yellow-500);
  border-radius: var(--space-l);
}
.BookRailUnitBinder-content {
  overflow-x: hidden;
  height: calc(100% - var(--BookRailHeader));
}
.BookRailUnitBinder-stickyNote {
  transform: rotate(-4.5deg) translateZ(0);

  width: 200px;
  height: 200px;

  color: var(--kog-text-default);

  background-color: var(--kog-colors-yellow-500);
  box-shadow: 5px 5px 7px var(--kogShadow020);
}
.BookRailUnitBinder-squigglyLine {
  /* stylelint-disable suitcss/custom-property-no-outside-root */
  --shape-color: var(--kog-colors-grey-300);

  margin-left: 64px;
}
.u-noPadding {
  padding: 0 !important;
}
</style>
