/* eslint-disable no-use-before-define */

import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import type {
  ActivitySheet,
  ActivitySheetOccasion,
  ApiOccasionResponse,
  ImageApiUploadResponse,
  ImageApiUserResponse,
  ImageClientUserResponse,
  OccasionResponse,
  TypedOccasionApiResponse,
  TypedOccasionResponse,
  WithRequired,
} from './types';

import * as atsApi from '@apis/activity-sheet.js';

import { postImageToS3SignedUrl, UPLOAD_STATUS } from 'learning/study/utils/image-upload-utils.js';

import { FILE_STATUS } from 'sharedApp/components/image-upload/file-status.ts';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const populateClientStateResponse = async (
  response: OccasionResponse,
  handleCommit: (responseToStore: OccasionResponse) => void,
) => {
  if (!isImageResponse<ImageApiUserResponse>(response)) {
    handleCommit(response);
    return;
  }

  // Pre-commit downloading state
  handleCommit({
    ...response,
    user_response: {
      file: null,
      status: FILE_STATUS.DOWNLOADING,
    },
  });

  let file;
  let status;
  try {
    const imageResponse = await axios.get(response.signed_image_url, { responseType: 'blob' });
    file = new File([imageResponse.data], response.user_response);
    status = FILE_STATUS.DONE;
  } catch {
    file = null;
    status = FILE_STATUS.ERROR;
  }

  const clientImageUserResponse = {
    ...response,
    user_response: {
      file,
      status,
    },
  };

  handleCommit(clientImageUserResponse);
};

const uploadPendingImageResponse = async ({
  signedUrl,
  file,
  onProgress,
  onError,
}: {
  signedUrl: ImageApiUploadResponse['signed_url'];
  file: File | null;
  onProgress?: ImageClientUserResponse['onProgress'];
  onError: WithRequired<ImageClientUserResponse, 'onError'>['onError'];
}) => {
  let hasError = false;

  if (file instanceof File) {
    try {
      await postImageToS3SignedUrl(file, signedUrl, onProgress);
    } catch {
      onError();
      hasError = true;
    }
  }

  return [hasError];
};

const isImageResponse = <T>(
  response: OccasionResponse | ApiOccasionResponse,
): response is typeof response & T => {
  return response.response_type === RESPONSE_TYPES.IMR;
};

const extractPayloadByResponseType = (clientResponse: OccasionResponse) => {
  const responseToSave: OccasionResponse = {
    id: clientResponse?.id,
    question_id: clientResponse.question_id,
    response_type: clientResponse.response_type,
    user_response: clientResponse?.user_response,
  };

  if (isImageResponse<ImageClientUserResponse>(clientResponse)) {
    responseToSave.s3_object_type = clientResponse.user_response?.file?.type;
    responseToSave.user_response = clientResponse.user_response?.file?.name;
  }

  return responseToSave;
};

function useAsyncResponseHandler(options: {
  onUpdate: (response: OccasionResponse) => void;
  onCreate: (response: OccasionResponse) => void;
}) {
  type EventType = (typeof EVENT)[keyof typeof EVENT];
  type AsyncResponseEvent = { response: OccasionResponse; eventType: EventType };
  const EVENT = {
    CREATE: 'create',
    UPDATE: 'update',
  } as const;

  const { onUpdate, onCreate } = options;

  const asyncResponseMap = new Map<number, AsyncResponseEvent | null>();

  const queueCreate = (response: OccasionResponse) => add({ response, eventType: EVENT.CREATE });
  const queueUpdate = (response: OccasionResponse) => add({ response, eventType: EVENT.UPDATE });

  const add = (asyncEvent: AsyncResponseEvent) => {
    if (!asyncResponseMap.has(asyncEvent.response.question_id)) {
      asyncResponseMap.set(asyncEvent.response.question_id, null);
    }

    const existing = asyncResponseMap.get(asyncEvent.response.question_id);
    asyncResponseMap.set(asyncEvent.response.question_id, {
      ...asyncEvent,
      eventType: existing?.eventType ? existing.eventType : asyncEvent.eventType,
    });
  };

  const has = (questionId: number) => asyncResponseMap.has(questionId);

  const process = async () => {
    await Promise.allSettled(
      [...asyncResponseMap.keys()].map(questionId => {
        const responseData = asyncResponseMap.get(questionId);
        asyncResponseMap.delete(questionId);

        if (!responseData) {
          return Promise.reject();
        }

        if (responseData.eventType === EVENT.UPDATE) {
          return onUpdate(responseData.response);
        }

        return onCreate(responseData.response);
      }),
    );
  };

  return {
    queueCreate,
    queueUpdate,
    process,
    has,
  };
}

export default defineStore('activitySheet', () => {
  const asyncResponseHandler = useAsyncResponseHandler({
    onCreate: createOccasionResponse,
    onUpdate: updateOccasionResponse,
  });

  // State
  const activitySheet = ref<ActivitySheet | null>(null);
  const ownOccasion = ref<ActivitySheetOccasion | null>(null);
  const classOccasions = ref<ActivitySheetOccasion[]>([]);
  const occasionResponses = ref<Record<string, OccasionResponse>>({});
  const disabled = ref<boolean>(false);
  const creatingOccasion = ref<boolean>(false);
  const responseQueueProcesses = ref<Record<string, string>[]>([]);

  const EVENT_TIMER_DELAY_MS = 100;
  function createProcessingResponseTimer(delayMS = 0) {
    const eventId = window.crypto.randomUUID();
    const timer = setTimeout(() => {
      responseQueueProcesses.value.push({ id: eventId });
    }, delayMS);
    const clear = () => {
      clearTimeout(timer);
      responseQueueProcesses.value = responseQueueProcesses.value.filter(
        process => process.id !== eventId,
      );
    };

    return clear;
  }

  function partialUpdateOccasionResponse(response: Partial<OccasionResponse>) {
    const newState = { ...occasionResponses.value };
    if (response?.question_id) {
      const updatedOccasion: OccasionResponse = { ...newState[response.question_id], ...response };
      newState[response.question_id] = updatedOccasion;
    }
    occasionResponses.value = newState;
  }

  function clearAllOccasionResponses() {
    occasionResponses.value = {};
  }
  function clearOccasionResponseByQuestionId(questionId: number) {
    const newState = { ...occasionResponses.value };
    delete newState[questionId];
    occasionResponses.value = newState;
  }

  async function fetchActivitySheet(activitySheetId: number) {
    activitySheet.value = await atsApi.fetchActivitySheet(activitySheetId);
    disabled.value = true;
  }

  async function fetchOwnOccasion({
    subjectClassId,
    showTeacherOccasion = false,
  }: {
    subjectClassId: number;
    showTeacherOccasion?: boolean;
  }) {
    const occasion = await atsApi.fetchCurrentActivitySheetOccasion(
      activitySheet.value?.id,
      subjectClassId,
      showTeacherOccasion,
    );

    ownOccasion.value = occasion;

    if (occasion === null) {
      disabled.value = false;
    }
  }

  async function fetchClassOccasions({
    activitySheetId,
    subjectClassId,
  }: {
    activitySheetId: number;
    subjectClassId: number;
  }) {
    ownOccasion.value = null;
    disabled.value = false;
    classOccasions.value = await atsApi.fetchActivitySheetOccasions(
      activitySheetId,
      subjectClassId,
    );
  }

  async function fetchResponses({ occasionId }: { occasionId: number }) {
    const responses = (await atsApi.fetchResponses({
      sheetId: activitySheet.value?.id,
      occasionId,
    })) as OccasionResponse[];

    disabled.value = false;

    if (responses.length > 0) {
      await Promise.all(
        responses.map(response =>
          populateClientStateResponse(response, partialUpdateOccasionResponse),
        ),
      );
    }
  }

  async function fetchOwnResponses() {
    clearAllOccasionResponses();

    if (!ownOccasion.value) return;

    await fetchResponses({ occasionId: ownOccasion.value.id });
  }

  async function submitOwnOccasion() {
    const occasion = await await atsApi.updateActivitySheetOccasion({
      sheetId: activitySheet.value?.id,
      occasionId: ownOccasion.value?.id,
    });

    if (occasion) {
      ownOccasion.value = occasion;
    }
  }

  async function deleteOccasionResponse({
    questionId,
    useBeacon = false,
  }: {
    questionId: number;
    useBeacon?: boolean;
  }) {
    const responseId = occasionResponses.value[questionId]?.id;
    if (!responseId) {
      return;
    }

    const deletionPayload = {
      sheetId: activitySheet.value?.id,
      occasionId: ownOccasion.value?.id,
      responseId,
    };

    if (useBeacon) {
      atsApi.beaconDeleteActivitySheetOccasionResponse(deletionPayload);
      return;
    }

    await atsApi.deleteActivitySheetOccasionResponse(deletionPayload);
    clearOccasionResponseByQuestionId(questionId);
  }

  async function updateOccasionResponse(userResponse: OccasionResponse) {
    const oldResponse = occasionResponses.value[userResponse.question_id];
    if (!oldResponse) {
      throw new Error('Invalid response to update');
    }

    partialUpdateOccasionResponse(userResponse);

    if (
      creatingOccasion.value ||
      !oldResponse.id ||
      asyncResponseHandler.has(userResponse.question_id)
    ) {
      asyncResponseHandler.queueUpdate(userResponse);
      return;
    }

    if (isImageResponse<ImageClientUserResponse>(userResponse) && !userResponse.user_response) {
      deleteOccasionResponse({ questionId: userResponse.question_id });
      return;
    }

    const responsePayloadToUpdate = extractPayloadByResponseType(userResponse);

    const clear = createProcessingResponseTimer(EVENT_TIMER_DELAY_MS);
    try {
      await atsApi.updateActivitySheetOccasionResponse(
        {
          sheetId: activitySheet.value?.id,
          occasionId: ownOccasion.value?.id,
          responseId: oldResponse.id,
        },
        responsePayloadToUpdate,
      );
    } finally {
      clear();
    }
  }

  async function handleImageUpload({
    clientResponse,
    apiResponse,
  }: {
    clientResponse: TypedOccasionResponse<ImageClientUserResponse>;
    apiResponse: TypedOccasionApiResponse<ImageApiUploadResponse>;
  }) {
    const [hasError] = await uploadPendingImageResponse({
      signedUrl: apiResponse.signed_url,
      file: clientResponse.user_response?.file ?? null,
      onProgress: clientResponse.onProgress,
      onError: clientResponse.onError!,
    });

    if (hasError) {
      await deleteOccasionResponse({ questionId: clientResponse.question_id });
      return;
    }

    const updateResponse: TypedOccasionResponse<ImageClientUserResponse> = {
      question_id: clientResponse.question_id,
      response_type: clientResponse.response_type,
      status: UPLOAD_STATUS.UPLOADED,
      user_response: {
        ...clientResponse.user_response,
        status: FILE_STATUS.DONE,
      },
    };

    await updateOccasionResponse(updateResponse);
  }

  async function createOccasionResponse(userResponse: OccasionResponse) {
    // Set state for client responsiveness (client prediction)
    partialUpdateOccasionResponse(userResponse);

    if (creatingOccasion.value) {
      asyncResponseHandler.queueCreate(userResponse);
      return;
    }

    const clear = createProcessingResponseTimer(EVENT_TIMER_DELAY_MS);
    try {
      const responseToSave = extractPayloadByResponseType(userResponse);
      const response = (await atsApi.createActivitySheetOccasionResponse(
        {
          sheetId: activitySheet.value?.id,
          occasionId: ownOccasion.value?.id,
        },
        responseToSave,
      )) as ApiOccasionResponse;

      // Add response id
      partialUpdateOccasionResponse({
        question_id: userResponse.question_id,
        id: response?.id,
      });

      if (
        isImageResponse<ImageClientUserResponse>(userResponse) &&
        isImageResponse<ImageApiUploadResponse>(response)
      ) {
        await handleImageUpload({
          clientResponse: userResponse,
          apiResponse: response,
        });
      }
    } finally {
      clear();
      asyncResponseHandler.process();
    }
  }

  function deletePendingImageUploadResponses(useBeacon = false) {
    const pendingImageResponses = Object.values(occasionResponses.value).filter(
      response =>
        isImageResponse<ImageClientUserResponse>(response) &&
        response.user_response?.status === FILE_STATUS.UPLOADING,
    );
    pendingImageResponses.forEach(response =>
      deleteOccasionResponse({ questionId: response.question_id, useBeacon }),
    );
  }

  async function createOccasion(subjectClassId: number) {
    if (creatingOccasion.value) return;

    creatingOccasion.value = true;

    try {
      const occasion = await atsApi.createActivitySheetOccasion(
        activitySheet.value?.id,
        subjectClassId,
      );
      ownOccasion.value = occasion;
    } finally {
      creatingOccasion.value = false;
    }

    asyncResponseHandler.process();
  }

  const isProcessingResponses = computed(() => responseQueueProcesses.value.length > 0);
  const isActivitySheetDisabled = computed(() => disabled.value);
  const isCreatingOccasion = computed(() => creatingOccasion.value);

  return {
    // State
    activitySheet,
    ownOccasion,
    classOccasions,
    occasionResponses,

    // Actions
    fetchActivitySheet,
    fetchOwnOccasion,
    fetchClassOccasions,
    fetchOwnResponses,
    fetchResponses,
    createOccasion,
    submitOwnOccasion,
    createOccasionResponse,
    deleteOccasionResponse,
    updateOccasionResponse,
    deletePendingImageUploadResponses,
    clearAllOccasionResponses,

    // Getters
    isProcessingResponses,
    isActivitySheetDisabled,
    isCreatingOccasion,
  };
});
