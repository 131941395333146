<template>
  <div
    class="flexContainer flexContainer-alignCenter padd-right-xs padd-left-xs GoogleClassPill"
    :class="pillSizeClass"
  >
    <img
      :src="googleClassroomIcon"
      class="GoogleClassPill-icon margin-right-xs"
      alt=""
    />
    <span class="line-clamp-1">
      {{ googleClass.name }}
    </span>
  </div>
</template>

<script>
import googleClassroomIcon from 'sharedApp/img/google-classroom-yellow-icon-64x64_2x.png';

export default {
  name: 'GoogleClassPill',
  props: {
    googleClass: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium'].includes(value);
      },
    },
  },
  data() {
    return {
      googleClassroomIcon,
    };
  },
  computed: {
    pillSizeClass() {
      return {
        'GoogleClassPill--small': this.size === 'small',
        'GoogleClassPill--medium': this.size === 'medium',
      };
    },
  },
};
</script>

<style scoped>
.GoogleClassPill {
  background-color: var(--kogPlatformWhite);
  border: 1px var(--kogPlatformGray077) solid;
  border-radius: 16px;
}

.GoogleClassPill--small {
  height: 24px;
}

.GoogleClassPill--medium {
  height: 32px;
}

.GoogleClassPill-icon {
  width: 16px;
}
</style>
