const CsvDownloadMixin = {
  methods: {
    downloadCsvFile(fileName, csvBlob) {
      const a = document.createElement('a');
      a.setAttribute('download', fileName);
      a.setAttribute('href', window.URL.createObjectURL(csvBlob));
      a.click();
      a.remove();
    },
  },
};

export default CsvDownloadMixin;
