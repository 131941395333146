<template>
  <slide-in
    class="BookRailSlider-container"
    :class="{
      'BookRailSlider-container--focus': isAssignmentFocus,
    }"
    :is-showing-child="isBookRailSliderOpen && !(bookRailSliders.toc && isNil(currentSectionNode))"
    enter-from="left"
  >
    <kog-card
      class="BookRailSlider-content"
      :default-padding="!bookRailSliders.toc && !bookRailSliders.assignment"
    >
      <book-rail-toc
        v-if="bookRailSliders.toc && !isNil(currentSectionNode)"
        :key="currentSubtopicId"
        :show-multiple-sections="showMultipleSections"
        @scroll-to-content="$emit('scroll-to-content', $event)"
      />
      <book-rail-assignment
        v-show="bookRailSliders.assignment"
        key="assignment"
      />
      <book-rail-notebook
        v-show="bookRailSliders.notebook"
        key="notebook"
      />
      <book-rail-glossary
        v-show="bookRailSliders.glossary"
        key="glossary"
      />
      <book-rail-unit-binder
        v-show="bookRailSliders.unitBinder"
        key="unitBinder"
      />
    </kog-card>
  </slide-in>
</template>
<script>
import isNil from 'lodash/isNil.js';
import { mapGetters, mapState } from 'vuex';

import SlideIn from 'sharedApp/animations/slide-in.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import BookRailAssignment from 'studyApp/components/side-navigation//book-rail-assignment.vue';
import BookRailGlossary from 'studyApp/components/side-navigation/book-rail-glossary.vue';
import BookRailNotebook from 'studyApp/components/side-navigation/book-rail-notebook.vue';
import BookRailToc from 'studyApp/components/side-navigation/book-rail-toc.vue';
import BookRailUnitBinder from 'studyApp/components/side-navigation/book-rail-unit-binder.vue';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';

export default {
  name: 'BookRailSlider',
  components: {
    KogCard,
    SlideIn,
    BookRailToc,
    BookRailAssignment,
    BookRailNotebook,
    BookRailGlossary,
    BookRailUnitBinder,
  },
  mixins: [RoutesMixin, ReadingAssignmentMixin],
  emits: ['scroll-to-content'],
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      bookRailSliders: state => state.bookModule.bookRailSliders,
    }),
    ...mapGetters({
      isBookRailSliderOpen: 'bookModule/isBookRailSliderOpen',
      findSectionNodeById: 'bookModule/findSectionNodeById',
      subjectNodesById: 'subjectModule/subjectNodesById',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    isCurrentNodeFeature() {
      return !!this.getActivityBySubjectNodeId(this.nodeId);
    },
    currentSubjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    currentSectionNode() {
      if (this.isCurrentNodeFeature) {
        return this.currentSubjectNode;
      }
      return this.findSectionNodeById(this.nodeId);
    },
    currentSubtopicId() {
      let parentNode = this.subjectNodesById[this.currentSubjectNode.parent];
      while (parentNode && parentNode.level > 2) {
        parentNode = this.subjectNodesById[parentNode.parent];
      }
      return parentNode.id;
    },
    isReviewMode() {
      return !!this.$route.meta.isReviewMode;
    },
    isNgss() {
      return isNgss(this.subject.educationsystem.name);
    },
    showMultipleSections() {
      return this.isReviewMode && !this.isNgss;
    },
  },
  methods: { isNil },
};
</script>

<style scoped>
.BookRailSlider-container {
  position: sticky;
  top: var(--space-s);

  width: var(--study-rail-slider-width);
  height: calc(100vh - (var(--space-s) * 2));
  margin-top: var(--space-s);
}

.BookRailSlider-container--focus {
  height: calc(100vh - (var(--space-s) * 2) - var(--full-page-navbar-height));
}

.BookRailSlider-content {
  overflow-y: auto;
  height: 100%;
  border-radius: 16px;
}

.BookRailSlider-content :deep(.KogCard-contentSlot) {
  height: 100%;
}

.u-paddingHorizontalOverride {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media (--viewport-l) {
  .BookRailSlider-container {
    position: fixed;
    z-index: 105;
  }
}
</style>
