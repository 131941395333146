<template>
  <div class="NoteSearchResultItem">
    <div :class="[getColorClass(), 'NoteSearchResultItem-ellipsis']">
      {{ annotation.exact }}
    </div>
    <div class="NoteSearchResultItem-ellipsis margin-top-fine-1">
      <span v-if="annotation.note">{{ annotation.note }}</span>
      <span v-else><i>(No text in this highlight)</i></span>
    </div>
  </div>
</template>

<script>
import { getHighlightClass } from 'learning/common/libs/annotations-utils.js';

export default {
  name: 'NoteSearchResultItem',
  props: {
    annotation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getColorClass() {
      return getHighlightClass(this.annotation.color, false);
    },
  },
};
</script>

<style scoped>
.NoteSearchResultItem-ellipsis {
  overflow-x: hidden;
  width: 310px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
