<template>
  <div v-if="secondaryAnswers.length > 0">
    <button
      class="KogButtonLegacy KogButtonLegacy--link SecondaryAcceptedAnswers-secondaryToggle"
      :aria-expanded="isShowingSecondaryAnswers ? 'true' : 'false'"
      :aria-label="`${toggleText} answers`"
      @click="toggleShowSecondaryAnswers"
    >
      {{ toggleText }}
      <i
        :class="{
          'fa-chevron-up': isShowingSecondaryAnswers,
          'fa-chevron-down': !isShowingSecondaryAnswers,
        }"
        class="fas padd-left-xxs"
      />
    </button>
    <div v-if="isShowingSecondaryAnswers">
      <p class="heading-xxs">Also accepted</p>
      <ul
        :class="ulPaddingClass"
        class="content-editable"
      >
        <!-- eslint-disable vue/no-v-html -->
        <li
          v-for="(answer, i) in secondaryAnswers"
          :key="i"
          v-html="answer"
        />
        <!-- eslint-enable vue/no-v-html -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondaryAcceptedAnswers',
  props: {
    secondaryAnswers: {
      type: Array,
      required: true,
    },
    ulPaddingSize: {
      type: String,
      default: '',
      validator: value => ['m', ''].includes(value),
    },
  },
  data() {
    return {
      isShowingSecondaryAnswers: false,
    };
  },
  computed: {
    toggleText() {
      return !this.isShowingSecondaryAnswers ? 'See all' : 'See less';
    },
    ulPaddingClass() {
      if (this.ulPaddingSize.length > 0) {
        return `padd-left-${this.ulPaddingSize}`;
      }
      return '';
    },
  },
  methods: {
    toggleShowSecondaryAnswers() {
      this.isShowingSecondaryAnswers = !this.isShowingSecondaryAnswers;
    },
  },
};
</script>

<style scoped>
.SecondaryAcceptedAnswers-secondaryToggle {
  min-width: 0;
  padding: 0;
}
</style>
