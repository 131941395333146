<template>
  <kog-modal>
    <template #modalBody>
      <div
        class="flexContainer flexContainer-center flexContainer-column ClassEsqAssignmentInstructionsModal-body padd-top-xxs"
      >
        <kog-ball-step-paginator
          :number-of-pages="3"
          :current-page="currentStep"
          :is-clickable="true"
          @change-page="goToStep"
        />
        <div class="padd-right-xl padd-left-xl padd-top-m padd-bottom-m flexChild-flexStretch">
          <h3 class="heading-m text-center padd-bottom-xxl">
            {{ stepDetailsCurrentStep.title }}
          </h3>
          <div
            class="flexContainer flexContainer-alignCenter ClassEsqAssignmentInstructionsModal-details"
          >
            <i
              :class="stepDetailsCurrentStep.icon"
              class="ClassEsqAssignmentInstructionsModal-icon"
            />
            <div class="padd-left-xl">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-html="stepDetailsCurrentStep.info1" />
              <p>
                {{ stepDetailsCurrentStep.info2 }}
              </p>
            </div>
          </div>
          <div class="flexContainer flexContainer-flexEnd">
            <button
              v-if="currentStep !== 3"
              class="KogButtonLegacy KogButtonLegacy--iconRight margin-top-xxl margin-right-m"
              @click="finishTutorial"
            >
              Skip the tutorial
            </button>
            <button
              class="KogButtonLegacy KogButtonLegacy--primary KogButtonLegacy--iconRight margin-top-xxl"
              @click="nextStep"
            >
              {{ currentStep !== 3 ? 'Next' : 'Create assignment' }}
              <i
                v-if="currentStep !== 3"
                class="fas fa-chevron-right"
              />
            </button>
          </div>
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogBallStepPaginator from 'sharedApp/components/base/pagination/kog-ball-step-paginator.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import { hideEsqInstructions } from 'studyApp/utils/teacher/esq-instruction-utils.js';

export default {
  name: 'ClassEsqAssignmentInstructionsModal',
  components: {
    KogModal,
    KogBallStepPaginator,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    practiceCenterUrl: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 1,
    };
  },
  computed: {
    esqTerm() {
      return this.$term('exam-style').toLowerCase();
    },
    stepDetailsCurrentStep() {
      const stepDetails = [
        {
          title: `Control access to ${this.esqTerm} questions`,
          icon: 'fad fa-paper-plane',
          info1: `Students in this class no longer have independent access to ${this.esqTerm} questions in their <a target="_blank" href="${this.practiceCenterUrl}">Practice area</a>.`,
          info2: `Students in this class can only access the ${this.esqTerm} questions you send them in an assignment.`,
        },
        {
          title: 'Control access to the mark scheme',
          icon: 'fad fa-lock-alt',
          info1:
            'You now have complete control over when students in this class see the mark scheme.',
          info2: 'Unlock the mark scheme immediately, after the deadline, or not at all.',
        },
        {
          title: 'Submitted however you want',
          icon: 'fad fa-pencil',
          info1: 'Students practice writing for their exams or work in groups to answer online.',
          info2: 'Be sure to leave submission instructions to guide your students.',
        },
      ];
      return stepDetails[this.currentStep - 1];
    },
  },
  methods: {
    goToStep(step) {
      this.currentStep = step;
    },
    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep += 1;
        return;
      }
      this.finishTutorial();
    },
    finishTutorial() {
      hideEsqInstructions(this.userId, this.subjectClassId);
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.ClassEsqAssignmentInstructionsModal-body {
  background: no-repeat left bottom url('~sharedApp/img/kognity-sparkles-bottom-left.svg');
}

.ClassEsqAssignmentInstructionsModal-icon {
  font-size: 86px;
  color: var(--kogPlatformRedDarken10);
}

.ClassEsqAssignmentInstructionsModal-details {
  max-width: 500px;
  min-height: 120px;
  margin: auto;
}
</style>
