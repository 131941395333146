<template>
  <kog-modal
    :title="title"
    :close-modal="closeModal"
    :header-icon="headerIcon"
    :subtitle="subtitle"
  >
    <template #modalBody>
      <kog-loader
        v-if="$wait.is('loading-quiz-response-modal/*')"
        :loading="$wait.is('loading-quiz-response-modal/*')"
        loading-msg="Almost there, loading student responses..."
      />
      <template v-else>
        <div class="margin-bottom-m">
          <span class="text-bold"> Filter </span>
          <tag-radio-group
            v-model:selected-value="selectedFilter"
            class="padd-top-xxs"
            group-label="Student list filter"
            :options="filterOptions"
          />
        </div>
        <div
          class="QuizStudentResponses-response flexContainer flexContainer-spaceBetween margin-bottom-xs margin-right-xl"
        >
          <span class="text-bold">Student</span>
          <div class="QuizStudentResponses-attempts flexContainer">
            <span
              class="width-50-percent flexContainer flexContainer-alignCenter flexContainer-flexEnd text-bold"
              >Last attempt</span
            >
            <span
              class="width-50-percent flexContainer flexContainer-alignCenter flexContainer-flexEnd text-bold"
              >Attempts</span
            >
          </div>
        </div>
        <kog-item-list
          role="list"
          :is-handling-tab-index="true"
        >
          <li
            v-for="student in filteredStudents"
            :key="student.user_id"
            class="flexContainer flexContainer-alignCenter flexContainer-column margin-top-xxs kogPlatformGray096-bg border-radius-8"
          >
            <div
              v-kog-clickable:[student.user_id]="
                occasionsByUser[student.user_id] ? setExpandedId : undefined
              "
              v-kog-description:[getUniqueId(student.user_id)]="
                `Toggle expanded submissions by ${student.name}`
              "
              class="flexContainer width-full padd-top-xs padd-bottom-xs padd-left-s padd-right-s"
              :aria-expanded="student.user_id === expandedId ? 'true' : 'false'"
            >
              <div class="QuizStudentResponses-response flexContainer flexContainer-spaceBetween">
                <div class="flexContainer flexContainer-alignCenter">
                  <kog-avatar
                    :name="student.name"
                    size="m"
                    theme="accent"
                  />
                  <div class="margin-left-s">
                    {{ student.name }}
                  </div>
                </div>
                <div class="QuizStudentResponses-attempts flexContainer">
                  <span
                    class="width-50-percent flexContainer flexContainer-alignCenter flexContainer-flexEnd"
                  >
                    {{ lastOccasionScoreByUser[student.user_id] }}
                  </span>
                  <span
                    class="width-50-percent flexContainer flexContainer-alignCenter flexContainer-flexEnd"
                  >
                    {{
                      occasionsByUser[student.user_id]
                        ? occasionsByUser[student.user_id].length
                        : ''
                    }}
                  </span>
                </div>
              </div>
              <kog-round-button
                v-if="occasionsByUser[student.user_id]"
                button-style="secondary-outline"
                size="small"
                :aria-label="`Toggle expanded submissions by ${student.name}`"
                :icon-class="student.user_id === expandedId ? 'fa-chevron-up' : 'fa-chevron-down'"
                class="margin-left-xs"
                :aria-expanded="student.user_id === expandedId ? 'true' : 'false'"
              />
            </div>
            <vertical-expand>
              <div
                v-show="student.user_id === expandedId"
                class="width-full padd-s"
              >
                <kog-table>
                  <template #header>
                    <kog-table-header>
                      <kog-table-cell-header> Submitted at </kog-table-cell-header>
                      <kog-table-cell-header
                        v-for="(id, index) in quiz.ordered_question_ids"
                        :key="id"
                        class="text-center"
                      >
                        Question {{ index + 1 }}
                      </kog-table-cell-header>
                    </kog-table-header>
                  </template>
                  <template #body>
                    <kog-table-row
                      v-for="occasion in occasionsByUser[student.user_id]"
                      :key="occasion.id"
                    >
                      <kog-table-cell-text>
                        {{ datetime(occasion.submitted_at) }}
                      </kog-table-cell-text>
                      <kog-table-cell-icon
                        v-for="response in responsesByOccasion[occasion.id]"
                        :key="response.id"
                        theme="custom"
                        :icon-class="response.answered_correctly ? 'fa-check' : 'fa-times'"
                        :class="{
                          'QuizStudentResponses-correctIcon': response.answered_correctly,
                          'QuizStudentResponses-incorrectIcon': !response.answered_correctly,
                        }"
                      />
                    </kog-table-row>
                  </template>
                </kog-table>
              </div>
            </vertical-expand>
          </li>
        </kog-item-list>
      </template>
    </template>
  </kog-modal>
</template>

<script>
import { groupBy, isNil } from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapState } from 'vuex';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import TagRadioGroup from 'sharedApp/components/filter/tag-radio-group.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellIcon from 'sharedApp/components/tables/kog-table-cell-icon.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import sectionIcon from 'sharedApp/img/study/subject-class-overview-section.svg';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'QuizStudentResponsesModal',
  components: {
    TagRadioGroup,
    KogItemList,
    KogTableCellIcon,
    KogTableCellText,
    KogTableCellHeader,
    KogTable,
    KogTableHeader,
    KogTableRow,
    KogRoundButton,
    KogModal,
    KogAvatar,
    KogLoader,
    VerticalExpand,
  },
  props: {
    quizId: {
      type: Number,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    studentsInClass: {
      type: Array,
      required: true,
    },
    classId: {
      type: Number,
      required: true,
    },
    subjectNode: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      expandedId: 0,
      headerIcon: {
        type: 'image',
        imgUrl: sectionIcon,
        alt: '',
      },
      selectedFilter: '',
    };
  },
  computed: {
    ...mapState({
      studentResponses: state => state.quizModule.studentResponses,
      studentOccasions: state => state.quizModule.studentOccasions,
      quiz: state => state.quizModule.quiz,
    }),
    responsesByOccasion() {
      return groupBy(this.studentResponses, 'quiz_occasion');
    },
    occasionsByUser() {
      const submittedOccasions = this.studentOccasions.filter(occasion => occasion.is_submitted);
      return groupBy(submittedOccasions, 'user');
    },
    lastOccasionScoreByUser() {
      const lastScoreByUser = {};
      this.studentsInClass.forEach(student => {
        if (!this.occasionsByUser[student.user_id]) {
          lastScoreByUser[student.user_id] = '';
        } else {
          const lastOccasion = this.occasionsByUser[student.user_id][0];
          const correctResponses = this.responsesByOccasion[lastOccasion.id].filter(
            response => response.answered_correctly,
          );
          lastScoreByUser[student.user_id] =
            `${correctResponses.length}/${this.quiz.ordered_question_ids.length}`;
        }
      });
      return lastScoreByUser;
    },
    filterOptions() {
      const completedInfo = Object.keys(this.occasionsByUser).length;
      const notCompletedInfo = this.studentsInClass.length - completedInfo;
      return [
        {
          label: `All ${this.studentsInClass.length}`,
          value: '',
        },
        {
          label: `Completed ${completedInfo}`,
          value: 'completed',
        },
        {
          label: `Not completed ${notCompletedInfo}`,
          value: 'notCompleted',
        },
      ];
    },
    filteredStudents() {
      let students = this.studentsInClass;
      if (this.selectedFilter === 'completed') {
        students = this.studentsInClass.filter(student => !!this.occasionsByUser[student.user_id]);
      } else if (this.selectedFilter === 'notCompleted') {
        students = this.studentsInClass.filter(student => !this.occasionsByUser[student.user_id]);
      }
      return students;
    },
    mixpanelTrackingProps() {
      return {
        subject_node_name: `${this.subjectNode.name} ${this.subjectNode.formatted_number_including_ancestors}`,
        source: this.source,
      };
    },
    title() {
      return this.subjectNode.name;
    },
    subtitle() {
      return this.subjectNode.formatted_number_including_ancestors;
    },
  },
  created() {
    this.fetchQuiz(this.quizId);
    this.fetchStudentResponses({
      quizId: this.quizId,
      classId: this.classId,
    });
    this.fetchStudentOccasions({
      quizId: this.quizId,
      classId: this.classId,
    });
    if (!isNil(this.$route.query.student)) {
      this.expandedId = parseInt(this.$route.query.student, 10);
    }
    this.$mixpanel.trackEvent('Quiz - Quiz submission modal opened', this.mixpanelTrackingProps);
  },
  methods: {
    ...mapWaitingActions({
      fetchQuiz: {
        action: 'quizModule/fetchQuiz',
        loader: 'loading-quiz-response-modal/fetchQuiz',
      },
      fetchStudentResponses: {
        action: 'quizModule/fetchStudentResponses',
        loader: 'loading-quiz-response-modal/fetchStudentResponses',
      },
      fetchStudentOccasions: {
        action: 'quizModule/fetchStudentOccasions',
        loader: 'loading-quiz-response-modal/fetchStudentOccasions',
      },
    }),
    setExpandedId(id) {
      const isExpanding = id !== this.expandedId;
      if (isExpanding) {
        this.expandedId = id;
        this.$router.replace({ query: { ...this.$route.query, student: id } });
        this.$mixpanel.trackEvent('Quiz - Student attempts expanded', this.mixpanelTrackingProps);
      } else {
        this.$router.replace({ query: { ...this.$route.query, student: undefined } });
        this.expandedId = 0;
      }
    },
    datetime(date) {
      return dateTimeFormatter(new Date(date));
    },
  },
};
</script>

<style scoped>
.QuizStudentResponses-response {
  width: calc(100% - 40px);
}

.QuizStudentResponses-attempts {
  width: 30%;
}

.QuizStudentResponses-correctIcon {
  color: var(--kog-colors-green-700);
}

.QuizStudentResponses-incorrectIcon {
  color: var(--kog-colors-pink-700);
}
</style>
