<template>
  <div
    class="KogSearchResults"
    aria-live="polite"
  >
    <div
      v-if="searchResults.results.length > 0"
      id="searchResultsNotice"
      class="padd-1 padd-bottom-fine-2 text-bold"
      tabindex="-1"
    >
      Result for "{{ query }}" in {{ subject.name }}
    </div>
    <div
      v-if="searchResults.results.length === 0 && !loading"
      class="margin-l"
    >
      <div class="text-center">
        <strong>We found nothing</strong>
        <span
          class="KogEmoji KogEmoji--large KogEmoji--disappointed margin-left-fine-1"
          aria-hidden="true"
        />
      </div>
      <ul class="margin-top-1 KogSearchResults-suggestionList">
        <li class="KogSearchResults-suggestionListItem"> Check your spelling </li>
        <li class="KogSearchResults-suggestionListItem"> Use fewer words </li>
        <li class="KogSearchResults-suggestionListItem">
          Try different words that mean the same
        </li>
      </ul>
    </div>
    <div
      v-if="loading"
      class="text-center margin-l"
    >
      Fetching your results...
      <span class="KogEmoji KogEmoji--large KogEmoji--hourglass margin-left-fine-1" />
    </div>
    <div
      v-if="loading"
      class="KogSearchResults-resultsLoadingOverlay text-bold"
    />
    <search-results-list
      v-if="!loading && searchResults.results.length > 0"
      :search-results="searchResults"
      :result-click="resultClick"
      :subject="subject"
      :next-page="nextPage"
      :previous-page="previousPage"
    />
  </div>
</template>

<script>
import SearchResultsList from 'learning/common/components/search-results-list.vue';

export default {
  name: 'SearchResults',
  components: {
    SearchResultsList,
  },
  props: {
    searchResults: { type: Object, required: true },
    nextPage: { type: Function, required: true },
    previousPage: { type: Function, required: true },
    subject: { type: Object, required: true },
    resultClick: { type: Function, required: true },
    loading: { type: Boolean, required: true },
    query: { type: String, required: true },
  },
};
</script>

<style scoped>
.KogSearchResults-resultsLoadingOverlay {
  position: absolute;
  z-index: 1;
  height: 100%;
  background-color: var(--kogShadow020);
}

.KogSearchResults-suggestionList {
  padding: 0;
  list-style: none;
}

.KogSearchResults-suggestionListItem::before {
  content: '• ';
}
</style>
