import { Plugin } from '@ckeditor/ckeditor5-core';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';

import InlineQuestionEditing from './inline-question-editing.js';
import InlineQuestionUI from './inline-question-ui.js';

export default class InlineQuestion extends Plugin {
  static get requires() {
    return [InlineQuestionEditing, InlineQuestionUI, Paragraph, Heading];
  }
}
