function isAlreadyOnPath(router, currentRoute, name, params) {
  const { fullPath } = router.resolve({ name, params });
  return fullPath === currentRoute.fullPath;
}

export function goToRoute(router, currentRoute, name, params) {
  if (!isAlreadyOnPath(router, currentRoute, name, params)) {
    router.push({ name, params });
  }
}

export default class MenuItem {
  constructor({
    key,
    badgeCount = 0,
    displayText,
    iconClassActive,
    iconClassInactive,
    subMenuItems = [],
    showRedDot = false,
  }) {
    this.key = key;
    this.badgeCount = badgeCount;
    this.displayText = displayText;
    this.iconClassActive = iconClassActive;
    this.iconClassInactive = iconClassInactive;
    this.subMenuItems = subMenuItems;
    this.showRedDot = showRedDot;
  }

  navigateTo(config) {
    if (this.subMenuItems.length > 0) {
      const firstSubMenu = this.subMenuItems[0];
      firstSubMenu.navigateTo(config);
    } else {
      throw new Error('No navigation behaviour implemented.');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  goToRoute(router, currentRoute, name, params) {
    goToRoute(router, currentRoute, name, params);
  }
}
