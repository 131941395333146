import { ref } from 'vue';
import { isNil } from 'lodash';
import { defineStore } from 'pinia';

import type {
  Assignment,
  AssignmentDetails,
  PaginatedAssignmentList,
  SubjectNode,
} from '@apis/generated/models.ts';
import type { Writable } from 'type-fest';

import { AssignmentsService } from '@apis/generated/services.ts';
import * as schoolstaffAssignmentsApi from '@apis/schoolstaff-assignments.js';

import { fetchOngoingSubjectAssignments } from 'studyApp/api/assignment.js';

export default defineStore('assignment', () => {
  const defaultPaginatedAssignmentList: PaginatedAssignmentList = {
    count: 0,
    next: null,
    previous: null,
    results: [],
  } as const;

  // State
  const assignment = ref<Writable<AssignmentDetails> | null>(null);
  const assignments = ref<PaginatedAssignmentList>(defaultPaginatedAssignmentList);
  const ongoingAssignments = ref<PaginatedAssignmentList>(defaultPaginatedAssignmentList);
  const pastAssignments = ref<PaginatedAssignmentList>(defaultPaginatedAssignmentList);
  const sentAssignments = ref<Assignment[]>([]);
  const scheduledAssignments = ref<PaginatedAssignmentList>(defaultPaginatedAssignmentList);
  const isAssignmentBasketOpen = ref(false);

  // Actions
  async function createAssignment(
    user: number,
    subjectClassId: number,
    assignmentType: string,
    subjectnodes: number[],
    sendToIntegration: boolean = false,
    isMultiClass: boolean = false,
  ) {
    assignment.value = await schoolstaffAssignmentsApi.createAssignment({
      user,
      subjectClassId,
      assignmentType,
      subjectnodes,
      sendToIntegration,
      name: null,
      isMultiClass,
    });
  }

  async function fetchAssignments(subjectClassId: number) {
    assignments.value = await AssignmentsService.assignmentsList({ subjectClassId });
  }

  async function fetchOngoingAssignments({
    subjectId,
    page,
    subjectClassId,
  }: {
    subjectId?: number;
    page?: number;
    subjectClassId?: number;
  }) {
    ongoingAssignments.value = await fetchOngoingSubjectAssignments(
      subjectId,
      page,
      subjectClassId,
    );
  }

  async function fetchPastAssignments(
    payload: Parameters<typeof AssignmentsService.assignmentsList>,
  ) {
    pastAssignments.value = await AssignmentsService.assignmentsList({
      ...payload,
      filter: 'past',
    });
  }

  async function fetchSentAssignments(
    payload: Parameters<typeof AssignmentsService.assignmentsList>,
  ) {
    const paginatedAssignments = await AssignmentsService.assignmentsList({
      ...payload,
      filter: 'sent',
    });
    sentAssignments.value = paginatedAssignments.results || [];
  }

  async function fetchScheduledAssignments(
    payload: Parameters<typeof AssignmentsService.assignmentsList>,
  ) {
    scheduledAssignments.value = await AssignmentsService.assignmentsList({
      ...payload,
      filter: 'scheduled',
    });
  }

  async function fetchAssignment(assignmentId: number) {
    assignment.value = await AssignmentsService.assignmentsRetrieve({ assignmentId });
  }

  async function saveAssignment() {
    if (isNil(assignment.value)) throw new Error('Assignment is not set');
    assignment.value = await AssignmentsService.assignmentsPartialUpdate({
      assignmentId: assignment.value.id,
      requestBody: assignment.value,
    });
  }

  async function sendAssignment() {
    if (isNil(assignment.value)) throw new Error('Assignment is not set');
    await schoolstaffAssignmentsApi.sendAssignment(assignment.value.id);
    assignment.value = null;
  }

  async function deleteAssignment() {
    if (isNil(assignment.value)) throw new Error('Assignment is not set');
    await schoolstaffAssignmentsApi.deleteAssignment(assignment.value.id);
    assignment.value = null;
  }

  async function addSection(subjectNode: SubjectNode, order: number) {
    if (isNil(assignment.value)) throw new Error('Assignment is not set');
    await schoolstaffAssignmentsApi.addNodeToReadingAssignment(
      assignment.value.id,
      subjectNode.id,
      order,
    );
    assignment.value.sections.splice(order, 0, {
      subject_node_id: subjectNode.id,
      name: subjectNode.name,
      number_including_ancestors: subjectNode.formatted_number_including_ancestors,
    });
  }

  async function removeSection(subjectNodeId: number) {
    if (isNil(assignment.value)) throw new Error('Assignment is not set');
    await schoolstaffAssignmentsApi.removeNodeFromReadingAssignment(
      assignment.value.id,
      subjectNodeId,
    );
    assignment.value.sections = assignment.value.sections.filter(
      section => section.subject_node_id !== subjectNodeId,
    );
  }

  async function updateSectionsOrder(subjectNodeIds: number[]) {
    if (isNil(assignment.value)) throw new Error('Assignment is not set');
    await Promise.all(
      subjectNodeIds.map((subjectNodeId, order) =>
        schoolstaffAssignmentsApi.updateNodeOrderInReadingAssignment(
          assignment.value?.id,
          subjectNodeId,
          order,
        ),
      ),
    );
    assignment.value.sections = assignment.value.sections.toSorted(
      (a, b) =>
        subjectNodeIds.indexOf(a.subject_node_id) - subjectNodeIds.indexOf(b.subject_node_id),
    );
  }

  return {
    assignment,
    assignments,
    ongoingAssignments,
    pastAssignments,
    sentAssignments,
    scheduledAssignments,
    isAssignmentBasketOpen,
    createAssignment,
    fetchAssignments,
    fetchOngoingAssignments,
    fetchPastAssignments,
    fetchSentAssignments,
    fetchScheduledAssignments,
    fetchAssignment,
    saveAssignment,
    sendAssignment,
    deleteAssignment,
    addSection,
    removeSection,
    updateSectionsOrder,
  };
});
