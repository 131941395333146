<template>
  <kog-button
    v-if="isClickable"
    :label="progress"
    class="ActivityCell flexContainer flexContainer-alignCenter flexContainer-justifyCenter"
    :class="[`ActivityCell-color--${color}`]"
    @click="$emit('click')"
  />
  <div
    v-else
    class="ActivityCell flexContainer flexContainer-alignCenter flexContainer-justifyCenter"
    :class="[`ActivityCell-color--${color}`]"
  >
    {{ progress }}
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

export default {
  name: 'ActivityCell',
  components: {
    KogButton,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    progress: {
      type: [String, Number],
      required: true,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
};
</script>

<style scoped>
.ActivityCell,
.ActivityCell :deep(button) {
  width: 80px;
  min-width: 80px;
  height: 32px;
}

.ActivityCell-color--kogPlatformGray096 {
  background: var(--kogPlatformGray096);
}

.ActivityCell-color--kogPlatformRedLighten10 {
  background: var(--kogPlatformRedLighten10);
}

.ActivityCell-color--kogPlatformGreenBase {
  background: var(--kogPlatformGreenBase);
}

.ActivityCell-color--kogPlatformGreenBaseAlpha30 {
  background: color-mod(var(--kogPlatformGreenBase) alpha(30%));
}

.ActivityCell-color--kogPlatformGreenBaseAlpha60 {
  background: color-mod(var(--kogPlatformGreenBase) alpha(60%));
}

.ActivityCell-color--kogPlatformGreenBaseAlpha80 {
  background: color-mod(var(--kogPlatformGreenBase) alpha(80%));
}

.ActivityCell-color--kogPlatformYellowBase {
  background: var(--kogPlatformYellowBase);
}

.ActivityCell-color--kogPlatformYellowLighten10 {
  background: var(--kogPlatformYellowLighten10);
}

.ActivityCell-color--kogPlatformGreenLighten10 {
  background: var(--kogPlatformGreenLighten10);
}
</style>
