<template>
  <div>
    <span
      id="marksListLabel"
      class="heading-xxs"
    >
      MARKS PER QUESTION
    </span>
    <kog-item-list
      role="list"
      aria-labelledby="marksListLabel"
      class="flexContainer flexContainer-flexStart gap-s flexContainer-wrap flexContainer-alignCenter margin-top-xs GradingOverview-marksList"
    >
      <li
        v-for="(marksData, index) in marks"
        :key="index"
      >
        <kog-input
          style-type="prominent"
          :label="`${index + 1}`"
          :size="3"
          :value="`${marksData.actual}/${marksData.max}`"
          :disabled="true"
        />
      </li>
      <li class="margin-left-auto margin-right-xl">
        <kog-input
          label="Total"
          style-type="prominent"
          :size="5"
          :value="`${totalMarks.actual}/${totalMarks.max}`"
          :disabled="true"
        />
      </li>
    </kog-item-list>
    <div
      v-if="showTeacherView"
      class="flexContainer flexContainer-flexEnd flexContainer-alignCenter margin-top-m margin-right-xl"
    >
      <kog-tag
        v-if="isMarksSentForCurrentOccasion"
        class="margin-right-s"
        is-chip
        :icon-class="isMarksChangedAfterSendingForCurrentOccasion ? 'fa-rotate' : ''"
        :label="gradingStatusLabel"
        size="m"
        type="basic"
      />
      <kog-button
        label="Send grades"
        :button-style="sendGradesButtonStyle"
        icon-class="fa-paper-plane"
        :has-icon="true"
        :disabled="isSendGradesButtonDisabled"
        @click="onSendGradesClick"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import { PERFORMANCE_TASK_MODULE } from 'studyApp/store/modules/performance-task.js';
import { QUESTION_ACTIVITY_MODULE } from 'studyApp/store/modules/question-activity.js';

export default {
  name: 'GradingOverview',
  components: {
    KogInput,
    KogItemList,
    KogButton,
    KogTag,
  },
  props: {
    classId: {
      type: [Number, String],
      required: true,
    },
    subjectId: {
      type: [Number, String],
      required: true,
    },
    nodeId: {
      type: [Number, String],
      required: true,
    },
    showTeacherView: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(PERFORMANCE_TASK_MODULE, ['performanceTask']),
    ...mapGetters(PERFORMANCE_TASK_MODULE, [
      'contentQuestions',
      'currentOccasion',
      'currentOccasionResponseByQuestionId',
      'isAllQuestionsMarkedForCurrentOccasion',
      'isAnyQuestionsMarkedForCurrentOccasion',
      'isMarksSentForCurrentOccasion',
      'isMarksChangedAfterSendingForCurrentOccasion',
    ]),
    ...mapGetters(QUESTION_ACTIVITY_MODULE, ['getQuestionById']),
    marks() {
      const data = this.contentQuestions?.map(element => {
        const grading = this.currentOccasionResponseByQuestionId[element.question_id]?.grading;
        const actual = (this.showTeacherView ? grading?.marks_draft : grading?.marks) || 0;
        const max = this.getMaxRubricMarks(element.question_id) || grading?.max || 0;
        return { actual, max };
      });
      return data;
    },
    totalMarks() {
      const actual = this.marks?.reduce((acc, marksData) => acc + marksData.actual, 0) || 0;
      const max = this.marks?.reduce((acc, marksData) => acc + marksData.max, 0) || 0;
      return { actual, max };
    },
    gradingStatusLabel() {
      if (!this.isMarksSentForCurrentOccasion) {
        return '';
      }

      if (this.isMarksChangedAfterSendingForCurrentOccasion) {
        return 'Updates not sent';
      }

      const gradeSentTimestamp = new Date(this.currentOccasion.grade_sent_at);
      return `Sent ${dateTimeFormatter(gradeSentTimestamp)}`;
    },
    sendGradesButtonStyle() {
      if (
        this.isMarksSentForCurrentOccasion &&
        !this.isMarksChangedAfterSendingForCurrentOccasion
      ) {
        return '';
      }

      if (this.isAllQuestionsMarkedForCurrentOccasion) {
        return 'accent';
      }

      return '';
    },
    isSendGradesButtonDisabled() {
      if (!this.currentOccasion) {
        return true;
      }
      if (!this.isAnyQuestionsMarkedForCurrentOccasion) {
        return true;
      }

      const isGradingCompleted =
        this.isMarksSentForCurrentOccasion && !this.isMarksChangedAfterSendingForCurrentOccasion;
      return isGradingCompleted;
    },
  },
  mounted() {
    this.$event.track('load_performance_task_grading_view', {
      subject_id: this.subjectId,
      subject_class_id: this.classId,
      subject_node_id: this.nodeId,
      performance_task_id: this.performanceTask.id,
    });
    this.mixPanelTrackUsage();
  },
  methods: {
    ...mapActions(PERFORMANCE_TASK_MODULE, ['sendGradesForOccasion']),
    getMaxRubricMarks(questionId) {
      const question = this.getQuestionById(questionId);
      const points = question?.rubric_items?.map(rubric => rubric.points) || [0];
      return Math.max(...points);
    },
    onSendGradesClick() {
      if (!this.isAllQuestionsMarkedForCurrentOccasion) {
        this.$modal(KogConfirmModal, {
          title: 'Send without all grades?',
          text: "All answers are not graded yet. You can still send the grades you've set so far.",
          buttonTitle: 'Send',
          confirmButtonStyle: 'accent',
          cancelButtonStyle: '',
          onConfirm: this.sendGrades,
        });
        return;
      }
      this.sendGrades();
    },
    mixPanelTrackUsage() {
      if (!this.showTeacherView) {
        this.$mixpanel.trackEvent('Performance task - Student viewing scored PT', {
          performance_task_id: this.performanceTask.id,
          performance_task_occasion_id: this.currentOccasion.id,
          school_id: window.KOG?.USER_CONSTANTS.User.school.id,
        });
      }
    },
    async sendGrades() {
      const targetUserId = this.currentOccasion.user_id;
      let success = false;
      try {
        await this.sendGradesForOccasion({
          performanceTaskId: this.performanceTask.id,
          subjectClassId: this.classId,
          performanceTaskOccasionId: this.currentOccasion.id,
        });
        success = true;
      } catch {
        this.$toast.showError('Failed to send grades. Please refresh the page and try again.');
      }
      if (success) {
        this.$event.track('send_performance_task_grading_to_student', {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          subject_node_id: this.nodeId,
          performance_task_id: this.performanceTask.id,
          performance_task_occasion_id: this.currentOccasion.id,
          target_user_id: targetUserId,
        });
        this.$mixpanel.trackEvent('Performance task - Send grades to student', {
          performance_task_id: this.performanceTask.id,
          performance_task_occasion_id: this.currentOccasion.id,
          student_user_id: targetUserId,
        });
      }
    },
  },
};
</script>

<style scoped>
.GradingOverview-marksList {
  list-style: none;
}
</style>
