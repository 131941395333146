import { castArray } from 'lodash';
import cluster from 'set-clustering';
import jaroWinkler from 'talisman/metrics/jaro-winkler.js';

import { QUESTION_TYPES } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

const similarityThreshold = 0.9;

function orderByName(response1, response2) {
  return response1.userName.localeCompare(response2.userName);
}

function compareResponseValue(responseValue1, responseValue2) {
  const value1 = castArray(responseValue1)
    .map(response => response.answer ?? response)
    .join(',');
  const value2 = castArray(responseValue2)
    .map(response => response.answer ?? response)
    .join(',');

  const compareValue = value1.localeCompare(value2);
  return compareValue;
}

function orderByResponseText(response1, response2) {
  const compareValue = compareResponseValue(
    response1.cleanedStudentResponse,
    response2.cleanedStudentResponse,
  );
  return compareValue === 0 ? orderByName(response1, response2) : compareValue;
}

function studentResponsesKey(studentResponses) {
  if (Array.isArray(studentResponses)) {
    return studentResponses.map(response => response.answer ?? response).join(',');
  }
  return studentResponses;
}

function orderByExactMatch(response1, response2, responsesCount) {
  const count1 = responsesCount[studentResponsesKey(response1.cleanedStudentResponse)];
  const count2 = responsesCount[studentResponsesKey(response2.cleanedStudentResponse)];
  const compareValue = count2 - count1;
  return compareValue === 0 ? orderByResponseText(response1, response2) : compareValue;
}

function orderByNumberOfIncorrectBlanks(response1, response2) {
  const incorrect1 = response1.totalIncorrect;
  const incorrect2 = response2.totalIncorrect;
  const compareValue = incorrect2 - incorrect1;
  return compareValue === 0 ? orderByName(response1, response2) : compareValue;
}

function orderTwoIncorrectAnswers(response1, response2, questionType, responsesCount) {
  if (questionType === QUESTION_TYPES.FBQ) {
    return orderByNumberOfIncorrectBlanks(response1, response2);
  }
  return orderByExactMatch(response1, response2, responsesCount);
}

function orderAnswersByDoneAndCorrect(response1, response2, questionType, responsesCount) {
  if (response1.isDone && response2.isDone) {
    if (response1.isCorrect && response2.isCorrect) {
      return orderByName(response1, response2);
    }
    if (!response1.isCorrect && !response2.isCorrect) {
      return orderTwoIncorrectAnswers(response1, response2, questionType, responsesCount);
    }
    if (!response1.isCorrect && response2.isCorrect) {
      return -1;
    }
    return 1;
  }

  if (response1.isDone) {
    return !response1.isCorrect ? -1 : 1;
  }
  if (response2.isDone) {
    return !response2.isCorrect ? 1 : -1;
  }

  return orderByName(response1, response2);
}

function countOccurencesOfIdenticalResponses(responses) {
  const responsesCount = {};
  responses.forEach(response => {
    const { cleanedStudentResponse: studentResponses } = response;
    const key = studentResponsesKey(studentResponses);
    responsesCount[key] = (responsesCount[key] || 0) + 1;
  });
  return responsesCount;
}

function removeWhitespace(response) {
  return response ? response.replace(/\s/gi, '') : response;
}

function orderGroup(responses, questionType, responsesCount) {
  return responses.sort((resp1, resp2) =>
    orderTwoIncorrectAnswers(resp1, resp2, questionType, responsesCount),
  );
}

function orderByMostCommonMisconception(responses, questionType, responsesCount) {
  const incorrectResponses = responses.filter(resp => resp.isDone && !resp.isCorrect);
  const correctResponses = responses.filter(resp => resp.isDone && resp.isCorrect);
  const noResponses = responses.filter(resp => !resp.isDone);
  const c = cluster(incorrectResponses, (resp1, resp2) =>
    jaroWinkler(
      removeWhitespace(resp1.cleanedStudentResponse),
      removeWhitespace(resp2.cleanedStudentResponse),
    ),
  );
  const misconceptions = c.similarGroups(similarityThreshold);
  misconceptions.sort((group1, group2) => group2.length - group1.length);
  const sortedMisconceptions = misconceptions.map(group =>
    orderGroup(group, questionType, responsesCount),
  );
  const orderedResponses = [sortedMisconceptions, noResponses, correctResponses];
  return orderedResponses.flat(2);
}

function addTotalInvalidBlanksToQuestionResponses(questionResponses) {
  const questionResponsesFITB = [];
  questionResponses.forEach(response => {
    const { studentResponse } = response;
    let totalIncorrect = 0;
    if (studentResponse) {
      totalIncorrect = studentResponse.filter(sr => sr.ftbState === 'incorrect').length;
    }
    questionResponsesFITB.push({
      ...response,
      totalIncorrect,
    });
  });

  return questionResponsesFITB;
}

export default function order(responses, questionType) {
  const responsesCount = countOccurencesOfIdenticalResponses(responses);
  if (questionType !== QUESTION_TYPES.STQ) {
    const sortableResponses =
      questionType === QUESTION_TYPES.FBQ
        ? addTotalInvalidBlanksToQuestionResponses(responses)
        : responses;
    return sortableResponses.sort((resp1, resp2) =>
      orderAnswersByDoneAndCorrect(resp1, resp2, questionType, responsesCount),
    );
  }

  return orderByMostCommonMisconception(responses, questionType, responsesCount);
}
