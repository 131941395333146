<template>
  <button
    class="KogButtonLegacy KogButtonLegacy--subtleLink"
    @click="deleteAssignments()"
  >
    <kog-icon
      :is-non-actionable="true"
      icon-class="fa-trash-alt"
      fa-style="regular"
      size="s"
    />
    Delete ({{ selectedAssignmentIds.length }})
  </button>
</template>

<script>
import * as schoolStaffApi from '@apis/schoolstaff-assignments.js';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';

export default {
  name: 'BulkDeleteAssignmentsButton',
  components: {
    KogIcon,
  },
  props: {
    selectedAssignmentIds: {
      type: Array,
      required: true,
    },
  },
  emits: ['deleted'],
  computed: {
    assignmentSuffix() {
      return this.selectedAssignmentIds.length === 1 ? '' : 's';
    },
    draftDeletionConfirmation() {
      if (this.selectedAssignmentIds.length === 1) {
        return '1 draft assignment has been deleted';
      }
      return `${this.selectedAssignmentIds.length} draft assignments have been deleted`;
    },
  },
  methods: {
    deleteAssignments() {
      this.$modal(KogConfirmModal, {
        title: `Delete ${this.selectedAssignmentIds.length} draft${this.assignmentSuffix}`,
        text: 'You will not be able to retrieve these drafts later.',
        buttonTitle: 'Delete drafts',
        buttonClass: 'KogButtonLegacy--danger',
        onConfirm: this.confirmDeleteAssignments,
      });
    },
    async confirmDeleteAssignments() {
      await Promise.all(
        this.selectedAssignmentIds.map(assignmentId =>
          schoolStaffApi.deleteAssignment(assignmentId),
        ),
      );
      this.$toast.showSuccess(this.draftDeletionConfirmation);
      this.$emit('deleted');
    },
  },
};
</script>
