<template>
  <button
    class="KogButtonLegacy KogButtonLegacy--subtleLink"
    @click="archiveAssignments()"
  >
    <kog-icon
      :is-non-actionable="true"
      icon-class="fa-archive"
      fa-style="regular"
      size="s"
    />
    Archive ({{ selectedAssignmentIds.length }})
  </button>
</template>

<script>
import { archiveAssignment } from '@apis/schoolstaff-assignments.js';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';

export default {
  name: 'BulkArchiveAssignmentsButton',
  components: {
    KogIcon,
  },
  props: {
    selectedAssignmentIds: {
      type: Array,
      required: true,
    },
    listType: {
      type: String,
      required: true,
    },
  },
  emits: ['archived'],
  computed: {
    assignmentSuffix() {
      return this.selectedAssignmentIds.length === 1 ? '' : 's';
    },
    archiveConfirmation() {
      if (this.selectedAssignmentIds.length === 1) {
        return '1 assignment has been moved to your archive';
      }
      return `${this.selectedAssignmentIds.length} assignments have been moved to your archive`;
    },
  },
  methods: {
    archiveAssignments() {
      let text =
        'Any submissions received from your students after archiving these assignments will still be recorded, but you will need to unarchive the assignment to view the scores';
      let buttonTitle = 'Archive anyway';
      if (this.listType === 'draft') {
        text = 'You can always unarchive these drafts later';
        buttonTitle = 'Archive';
      }
      this.$modal(KogConfirmModal, {
        title: `Archive ${this.selectedAssignmentIds.length} assignment${this.assignmentSuffix}`,
        text,
        buttonTitle,
        buttonClass: 'KogButtonLegacy--primary',
        onConfirm: this.confirmArchiveAssignments,
      });
    },
    async confirmArchiveAssignments() {
      await Promise.all(
        this.selectedAssignmentIds.map(assignmentId => archiveAssignment(assignmentId)),
      );
      this.$toast.showSuccess(this.archiveConfirmation);
      this.$emit('archived');
    },
  },
};
</script>
