<template>
  <nav
    aria-label="table of contents"
    class="height-full"
    :class="{
      'padd-top-m padd-bottom-m': !isBookRail,
    }"
  >
    <div
      v-if="!isBookRail"
      class="flexContainer flexContainer-spaceBetween padd-left-m padd-right-m padd-bottom-s"
      :class="{
        'TableOfContents-headerShadow': showHeaderShadow,
      }"
    >
      <h2 class="heading-xs flexContainer flexContainer-alignCenter">
        <div
          class="TableOfContents-cardIcon flexContainer flexContainer-alignCenter flexContainer-justifyCenter margin-right-s"
        >
          <kog-icon
            theme="custom"
            icon-class="fa-list-alt"
            fa-style="regular"
            size="s"
          />
        </div>
        Table of contents
      </h2>
      <kog-round-button
        aria-label="Close table of contents"
        :aria-expanded="showTableOfContents ? 'true' : 'false'"
        icon-class="fa-chevron-left"
        button-style="basic"
        @click="closeToc"
      />
    </div>
    <div
      id="scrollableContent"
      class="padd-left-m padd-right-m"
      :class="{
        'TableOfContents-classOverview': !isBookRail,
        'TableOfContents-bookRail': isBookRail,
      }"
      @scroll="onScrollListener"
    >
      <table-of-contents-topics
        :topics="topics"
        :expanded-topic-id="expandedTopicId"
        :is-book-rail="isBookRail"
      />
    </div>
  </nav>
</template>

<script>
import throttle from 'lodash/throttle.js';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

import TableOfContentsTopics from './table-of-contents-topics.vue';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default {
  name: 'TableOfContents',
  components: {
    KogIcon,
    KogRoundButton,
    TableOfContentsTopics,
  },
  props: {
    showTableOfContents: {
      type: Boolean,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
    expandedTopicId: {
      type: Number,
      required: true,
    },
    isBookRail: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toc-scrolled', 'update:showTableOfContents'],
  data() {
    return {
      showHeaderShadow: false,
      onScrollListener: throttle(this.evaluateContentScroll, 300),
    };
  },
  methods: {
    evaluateContentScroll() {
      const element = document.getElementById('scrollableContent');
      const scroll = element.scrollTop > 0;
      this.showHeaderShadow = scroll;
      if (this.isBookRail) {
        this.$emit('toc-scrolled', scroll);
      }
    },
    closeToc() {
      this.$emit('update:showTableOfContents', false);
      studyAppLocalStorage.setAutoOpenSubjectClassOverviewToc(false);
    },
  },
};
</script>

<style scoped>
.TableOfContents-cardIcon {
  width: 40px;
  height: 40px;

  color: var(--kog-card-icon-color);

  background-color: var(--kog-card-icon-background);
  border-radius: 50%;
}

.TableOfContents-classOverview {
  overflow-y: auto;
  overscroll-behavior: contain;
  max-height: calc(100vh - 180px);
}

.TableOfContents-bookRail {
  overflow-y: auto;
  overscroll-behavior: contain;
  height: 100%;
}

.TableOfContents-headerShadow {
  box-shadow: 0 2px 4px 0 var(--kogShadow010);
}
</style>
