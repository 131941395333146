import {
  addListToDropdown,
  createLabeledDropdown,
  LabeledFieldView,
  Model,
  ViewCollection,
} from '@ckeditor/ckeditor5-ui';
import { Collection } from '@ckeditor/ckeditor5-utils';

import FilteredDropdownView from '../shared/filtered-dropdown-view.ts';
import FlexWrapperView from '../shared/flex-wrapper-view.ts';
import KogForm from '../utils/kogform.js';
import { DROPDOWN_OPTIONS, HEADING_LEVELS } from './content.js';

export const DEFAULT_BOX_TYPE_LABEL = 'Type of box';
export const DEFAULT_HEADING_LEVEL_LABEL = 'Box title heading level';

export default class FormView extends KogForm {
  constructor(locale) {
    super(locale);
    this.set({
      selectedBoxTypeKey: null,
      selectedHeadingLevelKey: null,
    });

    this.boxTypeDropdownView = new FilteredDropdownView(locale, {
      label: DEFAULT_BOX_TYPE_LABEL,
      values: DROPDOWN_OPTIONS,
    });

    this.headingLevelDropdownView = this.createDropdown({
      data: HEADING_LEVELS,
      label: DEFAULT_HEADING_LEVEL_LABEL,
      observableTarget: 'selectedHeadingLevelKey',
    });

    this.saveButtonView = this.createSaveButton();
    this.saveButtonView
      .bind('isEnabled')
      .to(this, 'selectedBoxTypeKey', this, 'selectedHeadingLevelKey', (...values) => {
        return values.every(val => !!val);
      });
    this.cancelButtonView = this.createCancelButton();

    this.buttonsView = new FlexWrapperView(this.locale, {
      direction: 'row',
      children: [this.saveButtonView, this.cancelButtonView],
    });

    this.boxTypeDropdownView.on('change:selectedValue', (_event, _name, selectedValue) => {
      this.selectedBoxTypeKey = selectedValue;
    });

    this.childViews = this.createCollection([
      this.boxTypeDropdownView,
      this.headingLevelDropdownView,
      this.buttonsView,
    ]);

    const focusables = new ViewCollection([
      this.boxTypeDropdownView,
      this.headingLevelDropdownView,
      this.saveButtonView,
      this.cancelButtonView,
    ]);

    this.setup('any-box', focusables);
  }

  focus() {
    this.focusCycler.focusFirst();
  }

  reset() {
    super.reset();
    this.selectedBoxTypeKey = null;
    this.selectedHeadingLevelKey = null;
    this.boxTypeDropdownView.reset();
  }

  createDropdown({ label, data, observableTarget }) {
    const dropdown = new LabeledFieldView(this.locale, createLabeledDropdown);
    dropdown.bind('isEmpty').to(this, observableTarget, val => !val);
    dropdown.set({ label });

    dropdown.fieldView.buttonView.set({
      withText: true,
      ariaLabel: label,
    });
    dropdown.fieldView.on('execute', event => {
      this.set(observableTarget, event.source.key);
      dropdown.fieldView.buttonView.set({ label: event.source.label });
    });

    const collectionItems = new Collection();
    data.forEach(item => {
      const [text, key] = item;
      const model = new Model({
        withText: true,
        label: text,
        key,
      });

      model.bind('isOn').to(this, observableTarget, val => val === key);

      collectionItems.add({
        type: 'button',
        model,
      });
    });

    addListToDropdown(dropdown.fieldView, collectionItems);

    return dropdown;
  }

  setFields(boxType, headingLevel) {
    const [_, boxTypeKey] = DROPDOWN_OPTIONS.find(item => item[1] === `${boxType}`);
    const [headingLabel, headingLevelKey] = HEADING_LEVELS.find(item => item[1] === headingLevel);
    this.selectedBoxTypeKey = boxTypeKey;
    this.boxTypeDropdownView.setSelectedValue(boxTypeKey);
    this.selectedHeadingLevelKey = headingLevelKey;
    this.headingLevelDropdownView.fieldView.buttonView.set({ label: headingLabel });
  }
}
