<template>
  <div class="ResultChoices list-group">
    <multiple-choice-question-result-choice
      v-for="(choice, index) in choices"
      :key="index"
      :choice="choice"
    />
  </div>
</template>

<script>
import MultipleChoiceQuestionResultChoice from 'teachApp/components/question/multiple-choice-question-result-choice.vue';

export default {
  name: 'MultipleChoiceQuestionResultChoices',
  components: {
    MultipleChoiceQuestionResultChoice,
  },
  props: {
    choices: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.ResultChoices {
  display: block;

  margin: 0;
  padding: 0;

  background: none;
  border: none;
}
</style>
