<template>
  <span
    ref="root"
    v-tooltip.bottom="{
      theme: 'kog-tooltip',
      content: 'Type a response',
      triggers: [],
      shown: emptyState,
    }"
    class="FillBlankQuestionInput"
  >
    <question-input
      v-model:value="answerValue"
      :disabled="isInputDisabled"
      autocomplete="off"
      type="text"
      data-hj-allow
      :label="blankLabel"
      :display-state="currentState"
      :position-index="displayInputIndex ? orderNumber : undefined"
      :question-type="questionType"
      @keydown.space.stop
      @keydown.right.stop
      @keydown.left.stop
      @input="onInputChange"
      @blur="onBlur"
    />
  </span>
</template>

<script>
import { VTooltip } from 'floating-vue';

import {
  DISPLAY_STATE,
  VALID_QUESTION_TYPES,
} from 'sharedApp/components/question-input/question-input-utility.js';
import QuestionInput from 'sharedApp/components/question-input/question-input.vue';

export const states = {
  initial: 'initial',
  correct: 'correct',
  incorrect: 'incorrect',
  neutral: 'neutral', // After a wrong answer, when the user tries again
  empty: 'empty',
  solution: 'solution',
};

export default {
  name: 'FillBlankQuestionInput',
  components: {
    QuestionInput,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    orderNumber: {
      type: Number,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    disableUserInputProp: {
      type: Boolean,
      default: false,
    },
    stateProp: {
      type: String,
      default: states.initial,
    },
    displayInputIndex: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['blur', 'input'],
  data() {
    return {
      disableUserInput: this.disableUserInputProp,
      state: this.stateProp,
      questionType: VALID_QUESTION_TYPES.FBQ,
      answerValue: '',
    };
  },
  computed: {
    currentState() {
      switch (this.state) {
        case states.solution:
          return DISPLAY_STATE.SOLUTION;
        case states.correct:
          return DISPLAY_STATE.CORRECT;
        case states.incorrect:
          return DISPLAY_STATE.INCORRECT;
        default:
          return DISPLAY_STATE.NEUTRAL;
      }
    },
    blankLabel() {
      const blankNumber = this.isInputDisabled
        ? `Answer ${this.orderNumber}`
        : `Type your answer ${this.orderNumber}`;

      if (this.correctState) {
        return `${blankNumber}. Answer is correct.`;
      }
      if (this.incorrectState) {
        return `${blankNumber}. Answer is incorrect.`;
      }

      return blankNumber;
    },
    correctState() {
      return this.state === states.correct;
    },
    incorrectState() {
      return this.state === states.incorrect;
    },
    emptyState() {
      return this.state === states.empty;
    },
    solutionState() {
      return this.state === states.solution;
    },
    isInputDisabled() {
      return this.correctState || this.disableUserInput || this.solutionState;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.answerValue = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    markAsCorrect() {
      if (!this.answerValue) {
        this.state = states.empty;
      } else {
        this.state = states.correct;
      }
    },
    // eslint-disable-next-line vue/no-unused-properties
    markAsIncorrect() {
      if (!this.answerValue && !this.disableUserInput) {
        this.state = states.empty;
      } else {
        this.state = states.incorrect;
      }
    },
    // eslint-disable-next-line vue/no-unused-properties
    markAsEmpty() {
      if (!this.answerValue) {
        this.state = states.empty;
      }
    },
    // eslint-disable-next-line vue/no-unused-properties
    markAsInitial() {
      this.state = states.initial;
    },
    onInputChange(value) {
      this.$emit('input', value);

      if (!this.answerValue) {
        this.state = states.initial;
        return;
      }
      if (this.incorrectState || !!this.answerValue) {
        this.state = states.neutral;
      }
    },
    onBlur() {
      this.$emit('blur', this);
    },
    // eslint-disable-next-line vue/no-unused-properties
    markAsUserInputDisabled() {
      this.disableUserInput = true;
    },
    // eslint-disable-next-line vue/no-unused-properties
    markAsUserInputEnabled() {
      this.disableUserInput = false;
    },
    // eslint-disable-next-line vue/no-unused-properties
    markAsSolution() {
      this.state = states.solution;
    },
    // eslint-disable-next-line vue/no-unused-properties
    setValue(value) {
      this.answerValue = value;
    },
    // eslint-disable-next-line vue/no-unused-properties
    getValue() {
      return this.answerValue;
    },
  },
};
</script>

<style scoped>
.FillBlankQuestionInput {
  display: inline-block;
  margin: 4px 0;
  line-height: 24px;
}
</style>
