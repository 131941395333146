<template>
  <div
    class="BookRail padd-bottom-m"
    :class="{
      'BookRail--collapsed': isCollapsed,
    }"
  >
    <template v-if="!isAssignmentFocus">
      <router-link
        class="BookRail-toolButton"
        :to="overviewRoute"
      >
        <div class="BookRail-toolButton-iconDiv">
          <kog-font-awesome-icon
            fa-class="fa-house"
            fa-style="regular"
            size="s"
          />
        </div>
        <div class="BookRail-toolButton-label"> Overview </div>
      </router-link>

      <div class="BookRail-divider" />
    </template>

    <template
      v-for="(link, index) of links"
      :key="`group-${index}`"
    >
      <div
        v-if="link.isGroupHeader"
        class="BookRail-modeLabel"
      >
        <div
          v-if="!isCollapsed"
          class="BookRail-toolButton-label text-bold"
        >
          {{ link.label }}
        </div>
        <div
          v-else
          class="BookRail-toolButton-iconDiv"
        >
          <kog-font-awesome-icon
            :fa-class="link.icon"
            :fa-style="getIconStyle(false)"
            :aria-label="link.label"
            size="s"
          />
        </div>
      </div>

      <button
        v-else
        :disabled="link.isDisabled"
        :aria-label="link.label"
        :aria-expanded="link.isExpandable ? (link.isActive ? 'true' : 'false') : null"
        class="BookRail-toolButton"
        :class="{
          'BookRail-toolButton--active': link.isActive,
          muted: link.isDisabled,
        }"
        @click="link.action"
      >
        <div class="BookRail-toolButton-iconDiv">
          <kog-font-awesome-icon
            :fa-class="link.icon"
            :fa-style="getIconStyle(link.isActive)"
            size="s"
          />
        </div>
        <div class="BookRail-toolButton-label">
          {{ link.label }}
        </div>
      </button>
    </template>

    <div class="BookRail-divider margin-top-auto" />
    <div
      v-if="showReviewModeToggle"
      class="BookRail-toolButton"
    >
      <kog-toggle-button
        class="BookRail-toolButton-iconDiv"
        :is-pressed="!isInReviewMode"
        aria-label="Toggle student view"
        @toggled="toggleReviewMode"
      />
      <div class="BookRail-toolButton-label"> Student view </div>
    </div>
    <button
      v-show="!isCollapsed"
      class="BookRail-toolButton"
      aria-expanded="true"
      @click="$emit('collapse')"
    >
      <div class="BookRail-toolButton-iconDiv">
        <kog-font-awesome-icon
          fa-class="fa-chevron-double-left"
          fa-style="regular"
          size="s"
        />
      </div>
      <div class="screenreader-only"> collapse book rail</div>
    </button>
    <button
      v-show="isCollapsed"
      class="BookRail-toolButton"
      aria-expanded="false"
      @click="$emit('expand')"
    >
      <div class="BookRail-toolButton-iconDiv">
        <kog-font-awesome-icon
          fa-class="fa-chevron-double-right"
          fa-style="regular"
          size="s"
        />
      </div>
      <div class="screenreader-only"> expand book rail</div>
    </button>
  </div>
</template>

<script>
import { launchAsync } from '@microsoft/immersive-reader-sdk';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import KogToggleButton from 'sharedApp/components/base/buttons/kog-toggle-button.vue';
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import { getImmersiveReaderToken } from 'studyApp/api/azure.js';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default {
  name: 'BookRail',
  components: {
    KogToggleButton,
    KogFontAwesomeIcon,
  },
  mixins: [RoutesMixin, ReadingAssignmentMixin],
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    trackingProps: {
      type: Object,
      required: true,
    },
  },
  emits: ['collapse', 'expand'],
  data() {
    return {
      isImmersiveReaderLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      hasGlossaryTerms: 'glossaryV2Module/hasTerms',
    }),
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
      bookRailSliders: state => state.bookModule.bookRailSliders,
      immersiveReaderContent: state => state.bookModule.immersiveReaderContent,
    }),
    overviewRoute() {
      return this.getRoute('subjectClassOverview');
    },
    studyRoute() {
      return this.$route.name === 'teacherAssignmentDetails'
        ? this.getRoute('classReadingAssignmentTake', { nodeId: this.$route.params.nodeId })
        : this.getRoute('classBook');
    },
    reviewRoute() {
      return this.$route.name === 'classReadingAssignmentTake'
        ? this.getRoute('teacherAssignmentDetails', { nodeId: this.$route.params.nodeId })
        : this.getRoute('reviewMode');
    },
    showReviewModeToggle() {
      return (this.user.isTeacher() || this.user.hasEditContentPermission()) && !this.isCollapsed;
    },
    isAssignmentFocusLanding() {
      return this.isAssignmentFocus && !this.nodeId;
    },
    isInReviewMode() {
      return this.$route.meta.isReviewMode;
    },
    shouldTocRender() {
      return !this.isAssignmentFocus;
    },
    links() {
      const items = [
        {
          shouldRender: this.shouldTocRender,
          isDisabled: false,
          label: 'Table of contents',
          icon: 'fa-list-alt',
          isActive: !!this.bookRailSliders.toc,
          isExpandable: true,
          action: () => {
            studyAppLocalStorage.setAutoOpenBookToc(!this.bookRailSliders.toc);
            this.toggleRailSlider('toc');
            this.$mixpanel.trackEvent('Book Rail - Toggle ToC', this.trackingProps);
          },
        },
        {
          shouldRender: !this.shouldTocRender,
          isDisabled: this.isAssignmentFocusLanding,
          label: 'Assignment',
          icon: 'fa-list-alt',
          isActive: this.bookRailSliders.assignment,
          isExpandable: true,
          action: () => {
            this.toggleRailSlider('assignment');
            this.$mixpanel.trackEvent('Book Rail - Toggle Assignment', this.trackingProps);
          },
        },
        {
          shouldRender: !this.isAssignmentFocus && isNgss(this.subject.educationsystem.name),
          isDisabled: false,
          label: 'Unit binder',
          icon: 'fa-folder',
          isActive: this.bookRailSliders.unitBinder,
          action: () => {
            this.toggleRailSlider('unitBinder');
            this.$mixpanel.trackEvent('Book Rail - Toggle Unit binder', this.trackingProps);
          },
        },
        {
          shouldRender: this.user.canAnnotate(),
          isDisabled: this.isAssignmentFocusLanding,
          label: 'Notebook',
          icon: 'fa-note',
          isActive: this.bookRailSliders.notebook,
          isExpandable: true,
          action: () => {
            this.toggleRailSlider('notebook');
            this.$mixpanel.trackEventViaBackend('Book Rail - Toggle Notebook', this.trackingProps);
          },
        },
        {
          shouldRender: this.isAssignmentFocus || this.hasGlossaryTerms,
          isDisabled: this.isAssignmentFocusLanding,
          label: 'Glossary',
          icon: 'fa-book',
          isActive: this.bookRailSliders.glossary,
          isExpandable: true,
          action: () => {
            this.toggleRailSlider('glossary');
            this.$mixpanel.trackEventViaBackend('Book Rail - Toggle Glossary', this.trackingProps);
          },
        },
        {
          shouldRender: true,
          isDisabled:
            !this.immersiveReaderContent ||
            this.isImmersiveReaderLoading ||
            this.isInReviewMode ||
            this.isAssignmentFocusLanding,
          label: this.isImmersiveReaderLoading
            ? 'Opening reading assistant...'
            : 'Reading assistance',
          icon: 'fa-glasses',
          isActive: false,
          isExpandable: false,
          action: this.openImmersiveReader,
        },
      ];

      return items.filter(item => item.shouldRender);
    },
  },
  created() {
    this.setRailSliderState({
      sliderKey: 'toc',
      flag: this.shouldTocRender && studyAppLocalStorage.getAutoOpenBookToc(),
    });
  },
  methods: {
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
      openRailSlider: 'bookModule/openRailSlider',
    }),
    ...mapMutations({
      setRailSliderState: 'bookModule/setRailSliderState',
    }),
    getRoute(routeName, additionalParams = {}) {
      return {
        name: routeName,
        params: {
          classSlug: `${this.subjectClassSlug}`,
          sid: `${this.subjectId}`,
          cid: `${this.classId}`,
          ...additionalParams,
        },
      };
    },
    getIconStyle(isLinkActive) {
      return isLinkActive ? 'solid' : 'regular';
    },
    resetActive() {
      this.links.filter(link => link.isActive).forEach(link => link.action(false));
    },
    toggleReviewMode() {
      if (!this.isInReviewMode) {
        this.$mixpanel.trackEvent('Book Rail - Switch to Review Mode', this.trackingProps);
        this.$router.push(this.reviewRoute);
      } else {
        this.$mixpanel.trackEvent('Book Rail - Switch to Study Mode', this.trackingProps);
        this.$router.push(this.studyRoute);
      }
    },
    async openImmersiveReader() {
      this.resetActive();
      const content = this.immersiveReaderContent;
      const data = {
        title: this.nodeName,
        chunks: [{ mimeType: 'text/html', content }],
      };
      const { access_token: accessToken, subdomain } = await getImmersiveReaderToken();
      this.isImmersiveReaderLoading = true;

      launchAsync(accessToken, subdomain, data)
        .catch(e => {
          this.$toast.showError('Something went wrong, Immersive Reader could not be loaded.');
          throw e;
        })
        .finally(() => {
          this.$event.track('open_reading_assistance', {
            subject_id: this.trackingProps.subject_id,
            subject_class_id: this.trackingProps.subject_class_id,
            subject_node_id: this.trackingProps.subject_node_id,
          });
          this.$mixpanel.trackEventViaBackend('Book Rail - Open Immersive Reader', {
            section_node_name: this.trackingProps.section_node_name,
          });
          this.isImmersiveReaderLoading = false;
        });
    },
    toggleRailSlider(key) {
      if (this.bookRailSliders[key]) {
        this.closeRailSlider();
      } else {
        this.openRailSlider(key);
      }
    },
  },
};
</script>

<style>
.BookRail {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: var(--study-rail-menu-width);
  height: 100vh;

  background-color: var(--kog-background-default-100);

  transition: width 0.2s ease-in-out;
}

.BookRail--collapsed {
  width: var(--study-rail-menu-collapsed-width);
}

.BookRail-modeLabel {
  margin-top: var(--space-m);
  font-family: var(--kog-satoshi);
  color: var(--kog-text-default);
  text-align: center;
}

.BookRail-modeLabel:first-of-type {
  margin-top: 0;
}

.BookRail-toolButton {
  margin-top: 0;
  margin-right: var(--space-xxs);
  margin-bottom: 0;
  margin-left: var(--space-xxs);
  padding-top: var(--space-xs);
  padding-right: var(--space-xxs);
  padding-bottom: var(--space-xs);
  padding-left: var(--space-xxs);

  font-family: var(--kog-satoshi);
  color: var(--kog-text-default);
  text-align: center;

  background-color: var(--kogTransparent);
  border: 0;
}

.BookRail-toolButton-iconDiv {
  margin-bottom: var(--space-xxs);
  padding: var(--space-xxs);
  border-radius: 14px;
}

.BookRail-toolButton-label {
  font-size: var(--kog-font-size-smaller);
  transition:
    height 0.1s ease-in-out,
    opacity 0.1s ease-in-out,
    visibility 0.1s ease-in-out;
}

.BookRail--collapsed .BookRail-toolButton-label {
  height: 0;
  visibility: hidden;
  opacity: 0;
}

.BookRail-toolButton:not([disabled]):hover {
  color: var(--kog-text-default);
  text-decoration: none;
}

.BookRail-toolButton--active .BookRail-toolButton-iconDiv {
  color: var(--kog-text-brand-hard);
  background-color: var(--kog-background-brand-900);
}

.BookRail-toolButton--active .BookRail-toolButton-label {
  font-weight: bold;
}

.BookRail-toolButton:enabled:hover:not(.BookRail-toolButton--active) .BookRail-toolButton-iconDiv {
  background-color: var(--kog-background-default-400);
}

.BookRail-divider {
  margin: var(--space-m) var(--space-m) var(--space-s);
  border-bottom: 1px solid var(--kog-border-default-500);
}
</style>
