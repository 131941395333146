<template>
  <div class="flexContainer flexContainer-column gap-m">
    <div
      v-for="dqbImage in dqbImages"
      :key="dqbImage.id"
      class="flexContainer flexContainer-center"
    >
      <div class="DrivingQuestionUpload-imageContainer">
        <image-box
          :src="dqbImage.image"
          :transparent-background="true"
          :h="600"
        />
        <div class="DrivingQuestionUpload-imageActionButton padd-m">
          <kog-round-button
            v-show="!isDqbImageDeleted(dqbImage)"
            icon-class="fa-trash-can"
            aria-label="Delete image"
            button-style="primary"
            @click="deleteDqbImage(dqbImage)"
          />
          <kog-round-button
            v-show="isDqbImageDeleted(dqbImage)"
            icon-class="fa-trash-can-undo"
            aria-label="Delete image"
            button-style="primary"
            @click="restoreDqbImage(dqbImage)"
          />
        </div>
        <div
          v-show="isDqbImageDeleted(dqbImage)"
          class="DrivingQuestionUpload-deletedOverlay"
        />
      </div>
    </div>
    <div
      v-for="(image, index) in newImages"
      :key="`preview-${index}`"
      class="flexContainer flexContainer-center"
    >
      <div class="DrivingQuestionUpload-imageContainer">
        <img
          class="DrivingQuestionUpload-previewImage"
          :src="getPreview(image)"
          alt=""
        />
        <div class="DrivingQuestionUpload-imageActionButton padd-m">
          <kog-round-button
            v-show="!isNewImageDeleted(image)"
            icon-class="fa-trash-can"
            aria-label="Delete image"
            button-style="primary"
            @click="deleteNewImage(image)"
          />
          <kog-round-button
            v-show="isNewImageDeleted(image)"
            icon-class="fa-trash-can-undo"
            aria-label="Delete image"
            button-style="primary"
            @click="restoreNewImage(image)"
          />
        </div>
        <div
          v-show="isNewImageDeleted(image)"
          class="DrivingQuestionUpload-deletedOverlay"
        />
      </div>
    </div>
    <form
      v-show="isAbleToUpload"
      enctype="multipart/form-data"
    >
      <file-upload
        class="flexContainer flexContainer-center flexContainer-column DrivingQuestionUpload-imageUpload"
        uploading-message="Uploading your image"
        name="image"
        :allowed-extensions="['jpeg', 'jpg', 'png']"
        @upload="addNewImage"
      >
        <template #body>
          <div class="absoluteContainer">
            <kog-icon
              label="Upload driving question board image"
              fa-style="light"
              icon-class="fa-file-image"
              size="xl"
            />
            <div class="DrivingQuestionUpload-cloudIcon">
              <kog-icon
                theme="light"
                fa-style="regular"
                icon-class="fa-cloud-arrow-up"
                size="xs"
              />
            </div>
          </div>
          <span class="text-regular text-bold"> Click here to upload or drag and drop </span>
          <span class="text-small"> Maximum file size 5 MB </span>
        </template>
      </file-upload>
    </form>

    <kog-input
      ref="externalUrlInput"
      v-model:value="externalUrl"
      label="Link to the driving questions board (optional)"
      label-icon="link"
      style-type="prominent"
      type="url"
      validate-on="blur"
      validation-message="Please enter a valid URL"
      @blur="isExternalLinkUrl = $refs.externalUrlInput.validity.valid"
    />
    <div class="flexContainer flexContainer-row flexContainer-flexEnd gap-m">
      <kog-button
        :disabled="!hasUnsavedChanges"
        class="flexChild-flexEnd"
        label="Undo"
        @click="reloadQuestion"
      />
      <kog-button
        :disabled="isSaveButtonDisabled || isComponentDisabled"
        :tooltip="saveButtonTooltip"
        class="flexChild-flexEnd"
        label="Save"
        button-style="accent"
        :is-loading="$wait.is('saving_driving_question')"
        @click="saveQuestion"
      />
    </div>
  </div>
</template>

<script>
import { debounce, isNil } from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import FileUpload from 'sharedApp/components/file-upload/file-upload.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

export default {
  name: 'DrivingQuestionUpload',
  components: {
    KogRoundButton,
    ImageBox,
    KogIcon,
    KogButton,
    KogInput,
    FileUpload,
  },
  mixins: [RoutesMixin],
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
  },
  emits: ['content-altered'],
  data() {
    return {
      externalUrl: '',
      isExternalLinkUrl: true,
      dqbImages: [],
      newImages: [],
      deletedDqbImages: [],
      deletedNewImages: [],
      sendUrlChangedEventDebounced: debounce(
        () => this.trackEvent('Driving Question Board - Change question URL'),
        500,
      ),
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      drivingQuestionsByDqbId: 'drivingQuestionModule/drivingQuestionsByDqbId',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    subjectNode() {
      return this.subjectNodesById[this.subjectNodeId];
    },
    drivingQuestionBoardActivity() {
      return this.getActivityBySubjectNodeId(this.subjectNodeId);
    },
    featureId() {
      return this.drivingQuestionBoardActivity.id;
    },
    drivingQuestion() {
      return this.drivingQuestionsByDqbId[this.featureId];
    },
    hasDrivingQuestion() {
      return !isNil(this.drivingQuestion);
    },
    isSaveButtonDisabled() {
      if (this.externalUrl) return !this.hasUnsavedChanges || !this.isExternalLinkUrl;
      return !this.hasUnsavedChanges;
    },
    saveButtonTooltip() {
      if (this.isComponentDisabled) {
        return 'Editors and admins cannot save a driving question';
      }
      if (!this.hasUnsavedChanges) {
        return 'No changes to save';
      }
      return '';
    },
    isComponentDisabled() {
      return this.user.hasEditContentPermission();
    },
    isAbleToUpload() {
      return this.dqbImages.length + this.newImages.length < 5;
    },
    hasUnsavedChanges() {
      return (
        !this.hasDrivingQuestion ||
        this.externalUrl !== this.drivingQuestion.external_url ||
        this.dqbImages.length !== this.drivingQuestion.images.length ||
        this.newImages.length > 0 ||
        this.deletedDqbImages.length > 0 ||
        this.deletedNewImages.length > 0
      );
    },
  },
  watch: {
    externalUrl() {
      this.sendUrlChangedEventDebounced();
    },
  },
  async created() {
    if (!this.isComponentDisabled) {
      await this.fetchDrivingQuestions(this.classId);
      this.reloadQuestion();
    }
  },
  methods: {
    ...mapActions({
      fetchDrivingQuestions: 'drivingQuestionModule/fetchDrivingQuestions',
    }),
    ...mapWaitingActions('drivingQuestionModule', {
      createDrivingQuestion: 'saving_driving_question',
      updateDrivingQuestion: 'saving_driving_question',
      uploadDrivingQuestionImages: 'saving_driving_question',
      deleteDrivingQuestionImages: 'saving_driving_question',
    }),
    reloadQuestion() {
      this.externalUrl = '';
      this.dqbImages = [];
      this.newImages = [];
      this.deletedNewImages = [];
      this.deletedDqbImages = [];
      if (this.hasDrivingQuestion) {
        this.externalUrl = this.drivingQuestion.external_url;
        this.dqbImages = this.drivingQuestion.images;
      }
    },
    async saveQuestion() {
      const deletedImagesCount = this.deletedDqbImages.length;
      const newImagesCount = this.newImages.length;
      const hasLink = this.hasDrivingQuestion;
      const payload = {
        subjectClassId: this.classId,
        featureId: this.featureId,
      };
      let createOrUpdate;
      if (this.hasDrivingQuestion) {
        createOrUpdate = this.updateDrivingQuestion;
        payload.drivingQuestionId = this.drivingQuestion.id;
      } else {
        createOrUpdate = this.createDrivingQuestion;
      }
      try {
        await createOrUpdate({
          ...payload,
          externalUrl: this.externalUrl,
        });

        await this.saveImages();
        this.trackSaveQuestion();
        this.reloadQuestion();
        this.trackEvent('Driving Question Board - Save question', {
          deleted_images_count: deletedImagesCount,
          new_images_count: newImagesCount,
          has_link: hasLink,
          source: this.$route.name === 'subjectClassOverview' ? 'overview' : 'book',
        });
      } catch (e) {
        this.$toast.showError('Unable to save driving question');
        throw e;
      }
      this.$toast.showSuccess(
        'The driving question has been saved and is now visible for students',
      );
    },
    addNewImage(image) {
      this.newImages.push(image);
      this.trackEvent('Driving Question Board - Add new image');
    },
    getPreview(image) {
      return URL.createObjectURL(image);
    },
    deleteDqbImage(dqbImage) {
      this.deletedDqbImages.push(dqbImage);
      this.trackEvent('Driving Question Board - Delete existing image');
    },
    isDqbImageDeleted(dqbImage) {
      return this.deletedDqbImages.find(image => image === dqbImage);
    },
    restoreDqbImage(dqbImage) {
      this.deletedDqbImages = this.deletedDqbImages.filter(image => image.id !== dqbImage.id);
      this.trackEvent('Driving Question Board - Restore existing image');
    },
    deleteNewImage(image) {
      this.deletedNewImages.push(image);
      this.trackEvent('Driving Question Board - Delete new image');
    },
    restoreNewImage(image) {
      this.deletedNewImages = this.deletedNewImages.filter(img => img !== image);
      this.trackEvent('Driving Question Board - Restore new image');
    },
    isNewImageDeleted(image) {
      return this.deletedNewImages.find(img => img === image);
    },
    async saveImages() {
      const deletedImageIds = this.deletedDqbImages.map(image => image.id);
      const imagesToUpload = this.newImages.filter(image => !this.deletedNewImages.includes(image));
      const payload = {
        subjectClassId: this.classId,
        featureId: this.featureId,
        drivingQuestionId: this.drivingQuestion?.id,
      };
      if (deletedImageIds.length > 0) {
        await this.deleteDrivingQuestionImages({
          ...payload,
          imageIds: deletedImageIds,
        });
      }
      if (imagesToUpload.length > 0) {
        await this.uploadDrivingQuestionImages({
          ...payload,
          images: imagesToUpload,
        });
      }
    },
    trackSaveQuestion() {
      this.$event.track('save_driving_question_board', {
        subject_id: this.subjectId,
        subject_class_id: this.classId,
        subject_node_id: this.subjectNodeId,
        dqb_driving_question_id: this.drivingQuestion.id,
        dqb_link: this.drivingQuestion.externalUrl,
        dqb_upload_image_count: this.newImages.length,
        dqb_delete_image_count: this.deletedDqbImages.length,
      });
    },
    trackEvent(eventName, additionalParams = {}) {
      const params = {
        subject_node_id: this.subjectNodeId,
        subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNode.name}`,
        ...additionalParams,
      };
      this.$mixpanel.trackEvent(eventName, params);
    },
  },
};
</script>

<style scoped>
.DrivingQuestionUpload-previewImage {
  width: auto;
  max-width: 100%;
  max-height: 600px;
  object-fit: contain;
}

.DrivingQuestionUpload-imageUpload {
  color: var(--kogPlatformGray009);
  background: var(--kogPlatformWhite);
  border-radius: 20px;
  outline-offset: 0;
}

.DrivingQuestionUpload-cloudIcon {
  position: absolute;
  right: 10%;
  bottom: 10%;

  display: flex;

  padding: var(--space-xxs);

  background-color: var(--kog-background-brand-900);
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
}

.DrivingQuestionUpload-imageContainer {
  position: relative;
  max-width: 100%;
}

.DrivingQuestionUpload-imageActionButton {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
}

.DrivingQuestionUpload-deletedOverlay {
  position: absolute;
  z-index: 1;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: var(--kogShadow080);
}
</style>
