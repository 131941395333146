import { Command } from '@ckeditor/ckeditor5-core';

export default class InsertInlineQuestionCommand extends Command {
  execute() {
    const { model } = this.editor;
    model.change(writer => {
      model.insertObject(this.create(writer));
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(
      document.selection.getFirstPosition(),
      'inlineQuestion',
    );
    this.isEnabled = allowedIn !== null;
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer) {
    const inlineQuestion = writer.createElement('inlineQuestion');
    const inlineQuestionHeader = writer.createElement('inlineQuestionHeader');
    const headerText = writer.createText('Try for yourself!');
    const inlineQuestionContent = writer.createElement('inlineQuestionContent');
    const contentParagraph = writer.createElement('paragraph');
    const contentText = writer.createText('Write the questions here...');
    const inlineQuestionToggle = writer.createElement('inlineQuestionToggle');
    const inlineQuestionAnswer = writer.createElement('inlineQuestionAnswer');
    const answerParagraph = writer.createElement('paragraph');
    const answerText = writer.createText('Write the answer here...');

    writer.append(headerText, inlineQuestionHeader);
    writer.append(inlineQuestionHeader, inlineQuestion);
    writer.append(inlineQuestionContent, inlineQuestion);
    writer.append(contentText, contentParagraph);
    writer.append(contentParagraph, inlineQuestionContent);
    writer.append(inlineQuestionToggle, inlineQuestion);
    writer.append(inlineQuestionAnswer, inlineQuestion);
    writer.append(answerText, answerParagraph);
    writer.append(answerParagraph, inlineQuestionAnswer);

    return inlineQuestion;
  }
}
