<template>
  <kog-modal title="Select subject class">
    <template #modalBody>
      <div class="margin-top-xxxl margin-bottom-xxxl">
        <div
          v-for="subjectClass in subjectClasses"
          :key="subjectClass.id"
          class="SelectClassModal-listItem cursorPointer padd-fine-2"
          role="button"
          tabindex="0"
          @keyup.enter.stop="performAction(subjectClass.id)"
          @click="performAction(subjectClass.id)"
        >
          {{ subjectClass.name }}
        </div>
      </div>
    </template>
    <template #modalFooter>
      <button
        class="KogButtonLegacy"
        @click="closeModal"
      >
        Cancel
      </button>
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'SelectClassModal',
  components: { KogModal },
  props: {
    subjectClasses: {
      type: Array,
      required: true,
    },
    actions: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    performAction(id) {
      this.closeModal();
      this.actions[id]();
    },
  },
};
</script>

<style scoped>
.SelectClassModal-listItem {
  border: 1px solid var(--kogPlatformGray084);
  border-bottom-width: 0;
}

.SelectClassModal-listItem:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.SelectClassModal-listItem:last-child {
  border-bottom-width: 1px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.SelectClassModal-listItem:hover,
.SelectClassModal-listItem:focus {
  background: var(--kogPlatformGray093);
}
</style>
