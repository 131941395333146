<template>
  <img
    ref="root"
    :src="logoUrl"
    class="KogLogo"
    alt=""
  />
</template>

<script>
import logoUrl from 'sharedApp/img/kognity-logo-no-text.png';

export default {
  name: 'KogLogo',
  data: () => ({
    logoUrl,
  }),
};
</script>

<style scoped>
.KogLogo {
  height: 100%;
}
</style>
