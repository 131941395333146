<template>
  <kog-modal :title="modalTitle">
    <template #modalBody>
      <div>
        <div class="QuestionModal-label text-bold"> Question text </div>
        <question-editor-plain-text
          v-model:value="question.question"
          :initial-focus="true"
          label="Question text"
        />
      </div>
      <question-type-tabs
        :selected-tab="questionType"
        :disabled="!!existingQuestionId"
        @select-tab="selectTab"
      />

      <template v-if="isMcq">
        <edit-mcq-choices :question="question" />
      </template>

      <stq-answer-input
        v-if="isStq"
        :question="question"
      />
      <div
        v-if="showAddExplanation"
        class="margin-top-l"
      >
        <div class="QuestionModal-label"> Add explanation (optional) </div>
        <question-editor-plain-text
          v-model:value="question.explanation"
          label="Question explanation"
        />
      </div>
      <div class="margin-top-l margin-bottom-l">
        <div class="QuestionModal-label"> Link to book </div>
        <div class="flexContainer">
          <select
            v-model="selectedTopicId"
            class="form-control margin-right-m"
            aria-label="Topic link"
          >
            <option
              v-for="node in topicList"
              :key="node.id"
              :value="node.id"
            >
              {{ node.formatted_number_including_ancestors }} {{ node.name }}
            </option>
          </select>
          <select
            v-model="selectedSubtopicId"
            class="form-control"
            aria-label="Subtopic link"
          >
            <option value=""> Don't link to a subtopic </option>
            <option
              v-for="node in subtopicList"
              :key="node.id"
              :value="node.id"
            >
              {{ node.formatted_number_including_ancestors }} {{ node.name }}
            </option>
          </select>
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div
        class="flexContainer flexChild-size-1 flexContainer-spaceBetween flexContainer-alignCenter"
      >
        <a
          href="#"
          @click.prevent="openNewIntercomChat"
        >
          Click here if you want to give feedback!
        </a>
        <div class="KogButtonSet KogButtonSet--right">
          <button
            v-if="canDelete"
            class="QuestionModal-delete margin-right-xs KogButtonLegacy--noStyle"
            @click="onDelete"
          >
            Delete
          </button>
          <button
            class="KogButtonLegacy"
            @click="onCancel"
          >
            Cancel
          </button>
          <button
            class="KogButtonLegacy KogButtonLegacy--primary"
            :disabled="!areRequiredFieldsFilled || isSaving"
            @click="onSave"
          >
            {{ saveButtonText }}
          </button>
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { escape, unescape } from 'lodash';

import {
  createTeacherQuestion,
  deleteTeacherQuestion,
  editTeacherQuestion,
} from '@apis/questions.js';

import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import { getQuestionSubtopics } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  QUESTION_TYPE_ABBREVIATIONS,
  QUESTION_TYPES,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { fetchCompleteQuestion } from 'studyApp/utils/assignment-utils.js';
import {
  trackTeacherQuestionCancel,
  trackTeacherQuestionCreate,
  trackTeacherQuestionDelete,
  trackTeacherQuestionFeedbackClick,
  trackTeacherQuestionOpenCreateModal,
  trackTeacherQuestionUpdate,
} from 'teachApp/services/tracking/assignment-tracking.js';

import EditMcqChoices from './edit-mcq-choices.vue';
import QuestionEditorPlainText from './question-editor-plain-text.vue';
import QuestionTypeTabs from './question-type-tabs.vue';
import StqAnswerInput from './stq-answer-input.vue';

function nodeIdFromSubjectNodeNumber(topics, topicNumber) {
  const node = topics.find(n => n.number === topicNumber);
  return node && node.id;
}

export default {
  name: 'QuestionModal',
  components: {
    StqAnswerInput,
    EditMcqChoices,
    KogModal,
    QuestionTypeTabs,
    QuestionEditorPlainText,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    existingQuestion: {
      type: Object,
      default: null,
    },
    subjectId: {
      type: Number,
      required: true,
    },
    subjectAndTopics: {
      type: Array,
      required: true,
    },
    selectedNode: {
      type: Object,
      required: true,
    },
    onSaveCallback: {
      type: Function,
      default: () => {},
    },
    onDeleteCallback: {
      type: Function,
      default: () => {},
    },
    modalTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      question: {
        question: '',
        context: {
          choices: [
            {
              answer_html: 'Correct choice',
              answer_explanation_html: '',
              is_correct: true,
              clientId: window.crypto.randomUUID(),
            },
            {
              answer_html: 'Incorrect choice',
              answer_explanation_html: '',
              is_correct: false,
              clientId: window.crypto.randomUUID(),
            },
            {
              answer_html: 'Incorrect choice',
              answer_explanation_html: '',
              is_correct: false,
              clientId: window.crypto.randomUUID(),
            },
            {
              answer_html: 'Incorrect choice',
              answer_explanation_html: '',
              is_correct: false,
              clientId: window.crypto.randomUUID(),
            },
          ],
        },
        answer: '',
        explanation: '',
      },
      existingQuestionId: '',
      selectedTab: QUESTION_TYPE_ABBREVIATIONS.ShortTextQuestion,
      selectedTopicId: '',
      selectedSubtopicId: '',
    };
  },
  computed: {
    saveButtonText() {
      return this.isSaving ? 'Saving...' : 'Save';
    },
    topicList() {
      return this.subjectAndTopics.filter(node => node.level === 1);
    },
    trackOptions() {
      return {
        subjectId: this.subjectId,
        subjectName: this.subjectAndTopics[0].name,
        source: 'Question assignment',
      };
    },
    selectedTopic() {
      return this.topicList.find(topic => topic.id === this.selectedTopicId);
    },
    subtopicList() {
      if (this.selectedTopic) {
        return this.selectedTopic.children;
      }
      return [];
    },
    selectedSubjectNodeId() {
      if (this.selectedSubtopicId) {
        return this.selectedSubtopicId;
      }
      return this.selectedTopicId;
    },
    canDelete() {
      return !!this.existingQuestionId;
    },
    questionType() {
      if (this.existingQuestionId) {
        return QUESTION_TYPE_ABBREVIATIONS[this.existingQuestion.type];
      }
      return this.selectedTab;
    },
    isStq() {
      return this.questionType === QUESTION_TYPE_ABBREVIATIONS.ShortTextQuestion;
    },
    isMcq() {
      return this.questionType === QUESTION_TYPE_ABBREVIATIONS.MultipleChoiceQuestion;
    },
    areRequiredFieldsFilled() {
      if (this.isStq) {
        return this.question.question.trim() && this.question.answer.trim();
      }
      if (this.isMcq) {
        return (
          this.question.question.trim() &&
          this.question.context.choices.every(choice => choice.answer_html)
        );
      }
      return false;
    },
    showAddExplanation() {
      return this.isStq;
    },
  },
  created() {
    if (this.existingQuestion) {
      this.prepopulateData();
    } else if (this.selectedNode.level === 1) {
      this.selectedTopicId = this.selectedNode.id;
    } else if (this.selectedNode.level === 2) {
      this.selectedTopicId = this.selectedNode.parent;
      this.selectedSubtopicId = this.selectedNode.id;
    } else {
      this.selectedTopicId = this.topicList[0].id;
    }
    trackTeacherQuestionOpenCreateModal(this.trackOptions);
  },
  methods: {
    async prepopulateData() {
      this.existingQuestionId = this.existingQuestion.id;
      this.question.question = this.existingQuestion.question_html;
      this.question.id = this.existingQuestionId;
      this.prepopulateSelectedSubjectNode();
      const fetchedQuestion = await fetchCompleteQuestion(
        this.existingQuestion.id,
        this.existingQuestion.type,
      );
      if (this.isMcq) {
        this.question.context.choices = fetchedQuestion.context.choices.map(choice => ({
          ...choice,
          clientId: window.crypto.randomUUID(),
        }));
      } else if (this.isStq) {
        this.question.answer = unescape(fetchedQuestion.solution.answer_list.join('\n'));
        this.question.explanation = fetchedQuestion.explanation;
      }
    },
    prepopulateSelectedSubjectNode() {
      const questionSubtopics = getQuestionSubtopics(this.existingQuestion);
      const subjectNodeNumbers = questionSubtopics[0].split('.');
      const topicNumber = subjectNodeNumbers[0];
      this.selectedTopicId = nodeIdFromSubjectNodeNumber(this.topicList, topicNumber);
      if (subjectNodeNumbers.length > 1) {
        const subtopicNumber = subjectNodeNumbers[1];
        this.selectedSubtopicId = nodeIdFromSubjectNodeNumber(this.subtopicList, subtopicNumber);
      }
    },
    async onSave() {
      let success = true;
      this.isSaving = true;
      if (this.isMcq) {
        success = await this.saveMcq();
      } else if (this.isStq) {
        success = await this.saveStq();
      }
      if (!success) {
        this.isSaving = false;
        return;
      }
      this.onSaveCallback(this.selectedNode);
      this.showSuccessToast();
      this.trackSave();
      this.closeModal();
      this.isSaving = false;
    },
    async saveStq() {
      const data = {
        question_html: this.question.question,
        answer_explanation_html: this.question.explanation,
        primary_accepted_answers: escape(this.question.answer),
        subject_node_id: this.selectedSubjectNodeId,
        is_active: true,
        feature_assignment_question: true,
        answer_strategy: 'CASE_INSENSITIVE',
      };
      try {
        if (this.existingQuestionId) {
          await editTeacherQuestion(
            this.subjectId,
            this.existingQuestionId,
            data,
            QUESTION_TYPES.STQ,
          );
        } else {
          await createTeacherQuestion(this.subjectId, data, QUESTION_TYPES.STQ);
        }
      } catch (error) {
        this.showErrorToast(error);
        return false;
      }
      return true;
    },
    async saveMcq() {
      const { question, context } = this.question;
      const { choices } = context;
      const data = {
        question_html: question,
        multiplechoicequestionchoice_set: choices,
        subject_node_id: this.selectedSubjectNodeId,
        is_active: true,
        feature_assignment_question: true,
      };
      try {
        if (this.existingQuestionId) {
          await editTeacherQuestion(
            this.subjectId,
            this.existingQuestionId,
            data,
            QUESTION_TYPES.MCQ,
          );
        } else {
          await createTeacherQuestion(this.subjectId, data, QUESTION_TYPES.MCQ);
        }
      } catch (error) {
        this.showErrorToast(error);
        return false;
      }
      return true;
    },
    onCancel() {
      this.trackCancel();
      this.closeModal();
    },
    async onDelete() {
      // eslint-disable-next-line no-alert
      if (window.confirm('Delete question? This action cannot be undone.')) {
        try {
          if (this.isMcq) {
            await deleteTeacherQuestion(
              this.subjectId,
              this.existingQuestionId,
              QUESTION_TYPES.MCQ,
            );
          } else if (this.isStq) {
            await deleteTeacherQuestion(
              this.subjectId,
              this.existingQuestionId,
              QUESTION_TYPES.STQ,
            );
          }
        } catch (error) {
          this.showErrorToast(error, true);
          return;
        }
        this.onDeleteCallback(this.existingQuestion);
        trackTeacherQuestionDelete({
          ...this.trackOptions,
          questionType: this.questionType,
        });
        this.closeModal();
      }
    },
    showSuccessToast() {
      const action = this.existingQuestionId ? 'saved' : 'created';
      this.$toast.showSuccess(`Your question was ${action} successfully`);
    },
    showErrorToast(error, deleting = false) {
      let action = '';
      if (deleting) {
        action = 'deleting';
      } else {
        action = this.existingQuestionId ? 'saving' : 'creating';
      }
      this.$toast.showError(
        `There was an error ${action} the question, please try again. ${error}`,
      );
    },
    trackSave() {
      if (this.existingQuestionId) {
        trackTeacherQuestionUpdate({
          ...this.trackOptions,
          questionType: this.questionType,
        });
      } else {
        trackTeacherQuestionCreate({
          ...this.trackOptions,
          questionType: this.questionType,
        });
      }
    },
    trackCancel() {
      if (this.question.question && !this.existingQuestionId) {
        trackTeacherQuestionCancel(this.trackOptions);
      }
    },
    openNewIntercomChat() {
      trackTeacherQuestionFeedbackClick(this.trackOptions);
      intercomModule.showNewMessage();
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style scoped>
.QuestionModal-label {
  margin-bottom: var(--space-xs);
  color: var(--kogPlatformGray031);
}

.QuestionModal-delete {
  color: var(--kogPlatformRedDarken20);
}
</style>
