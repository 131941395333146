<template>
  <div
    class="StudentStrengthQuestionsProgress flexContainer flexContainer-alignCenter flexContainer-spaceBetween padd-top-xs padd-bottom-xs padd-left-s padd-right-s text-regular kogPlatformGray096-bg border-radius-8"
  >
    <div class="flexContainer flexContainer-alignCenter">
      <kog-avatar
        :name="student.name"
        size="m"
      />
      <div class="margin-left-s">
        {{ student.name }}
      </div>
    </div>
    <div class="margin-right-xl">
      {{ student.progress }}
    </div>
  </div>
</template>

<script>
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';

export default {
  name: 'StudentStrengthQuestionsProgress',
  components: {
    KogAvatar,
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.StudentStrengthQuestionsProgress:hover {
  background-color: var(--kogPlatformGray093);
}
</style>
