<template>
  <div>
    <kog-table
      v-for="topic in topics"
      :key="topic.id"
      class="margin-bottom-l"
      :aria-hidden="!topicHasStrengthQuestions(topic) ? 'true' : null"
    >
      <template #colgroup>
        <col />
        <col width="100%" />
      </template>
      <template #header>
        <span
          id="topicSelectInfo"
          class="screenreader-only"
        >
          Check the topic checkbox to select all subtopics for that topic.
        </span>
        <kog-table-header :is-disabled="!topicHasStrengthQuestions(topic)">
          <kog-table-cell-checkbox
            :tooltip-text="!topicHasStrengthQuestions(topic) ? disabledCheckBoxText : ''"
            :is-header-cell="true"
            :is-disabled="!topicHasStrengthQuestions(topic)"
            :is-checked="topicsSelectedState(topic)"
            aria-describedby="topicSelectInfo"
            :label="`Topic ${topic.formatted_number_including_ancestors} ${topic.name}`"
            @check="toggleSelected(topic)"
            @uncheck="toggleSelected(topic)"
          />
          <kog-table-cell-text
            :is-multi-line="true"
            :is-header-cell="true"
          >
            <div
              v-if="topicHasStrengthQuestions(topic)"
              v-kog-clickable:[topic]="toggleSelected"
              v-kog-description:[getUniqueId(topic.id)]="'Click to toggle selection'"
            >
              <practice-test-node-name
                :subject-node="topic"
                :is-visible="!!subjectNodesVisibilityMap[topic.id]"
              />
            </div>
            <div v-else>
              <practice-test-node-name
                :subject-node="topic"
                :is-visible="!!subjectNodesVisibilityMap[topic.id]"
              />
            </div>
          </kog-table-cell-text>
        </kog-table-header>
      </template>
      <template #body>
        <kog-table-row
          v-for="subtopic in topic.children"
          :key="subtopic.id"
          :is-disabled="!subtopicHasStrengthQuestions(subtopic.id)"
        >
          <kog-table-cell-checkbox
            :tooltip-text="!subtopicHasStrengthQuestions(subtopic.id) ? disabledCheckBoxText : ''"
            :is-checked="isSelected(subtopic)"
            :is-disabled="!subtopicHasStrengthQuestions(subtopic.id)"
            :label="getSubtopicLabel(subtopic)"
            @check="toggleSelected(subtopic)"
            @uncheck="toggleSelected(subtopic)"
          />

          <kog-table-cell-text :is-multi-line="true">
            <div
              v-if="subtopicHasStrengthQuestions(subtopic.id)"
              v-kog-clickable:[subtopic]="toggleSelected"
              v-kog-description:[getUniqueId(subtopic.id)]="'Click to toggle selection'"
            >
              <practice-test-node-name
                :subject-node="subtopic"
                :is-visible="!!subjectNodesVisibilityMap[subtopic.id]"
              />
            </div>
            <div v-else>
              <practice-test-node-name
                :subject-node="subtopic"
                :is-visible="!!subjectNodesVisibilityMap[subtopic.id]"
              />
            </div>
          </kog-table-cell-text>
        </kog-table-row>
      </template>
    </kog-table>
  </div>
</template>

<script>
import KogTableCellCheckbox from 'sharedApp/components/tables/kog-table-cell-checkbox.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import PracticeTestNodeName from 'studyApp/pages/practice/strength-test/practice-test-node-name.vue';

export default {
  name: 'PracticeTestSetupNodeSelect',
  components: {
    PracticeTestNodeName,
    KogTable,
    KogTableHeader,
    KogTableRow,
    KogTableCellCheckbox,
    KogTableCellText,
  },
  directives: {
    KogClickable,
    KogDescription,
  },
  props: {
    checkboxStates: {
      type: Object,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
    subtopicsWithQuestions: {
      type: Array,
      required: true,
    },
    subjectNodesVisibilityMap: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['toggle-selected'],
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      disabledCheckBoxText: 'No questions available',
    };
  },
  methods: {
    toggleSelected(topic) {
      this.$emit('toggle-selected', topic);
    },
    topicsSelectedState(node) {
      if (node.selectedStatus === this.checkboxStates.selected) {
        return true;
      }
      if (node.selectedStatus === this.checkboxStates.semiselected) {
        return 'mixed';
      }
      return false;
    },
    isSelected(node) {
      return node.selectedStatus === this.checkboxStates.selected;
    },
    subtopicHasStrengthQuestions(id) {
      return this.subtopicsWithQuestions.includes(id);
    },
    topicHasStrengthQuestions(topic) {
      return topic.children.some(subtopic => this.subtopicHasStrengthQuestions(subtopic.id));
    },
    getSubtopicLabel(subtopic) {
      if (this.subtopicHasStrengthQuestions(subtopic.id)) {
        return `Subtopic ${subtopic.formatted_number_including_ancestors} ${subtopic.name}`;
      }

      return `No questions available for subtopic ${subtopic.formatted_number_including_ancestors} ${subtopic.name}`;
    },
  },
};
</script>
