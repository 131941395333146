<template>
  <kog-card
    title="Overview"
    icon-class="fa-pen-ruler"
    class="margin-top-l margin-left-l"
    :is-collapsible="true"
    :is-content-scrolling="true"
  >
    <div class="margin-bottom-xl">
      <activity-submission-class-switcher />
    </div>
    <div
      id="activity-sheet-submission-sidebar-heading"
      class="ActivitySheetSubmissionSidebar-heading flexContainer font-weight-medium"
    >
      <span class="flexChild-size-1">STUDENT</span>
      <span>STATUS</span>
    </div>
    <kog-item-list
      role="list"
      class="margin-top-m list-style-none ActivitySheetSubmissionSidebar-studentList"
      aria-labelledby="activity-sheet-submission-sidebar-heading"
      :is-handling-tab-index="true"
    >
      <li
        v-for="student in studentsList"
        :key="student.user_id"
      >
        <div
          class="ActivitySheetSubmissionSidebar-studentListItem flexContainer flexContainer-spaceBetween padd-xs margin-top-xxs"
          :class="{
            'ActivitySheetSubmissionSidebar-studentListItem--selected':
              student.user_id === selectedStudentId,
          }"
          :aria-label="getItemAriaLabel(student)"
          :aria-pressed="`${student.user_id === selectedStudentId}`"
          role="button"
          tabindex="-1"
          @click="selectStudent(student)"
          @keypress.enter="selectStudent(student)"
        >
          <div>
            {{ student.name }}
          </div>
          <kog-tag
            class="margin-left-xxl"
            is-chip
            :type="getStatusTagStyle(student)"
            :label="getStatusTagText(student)"
          />
        </div>
      </li>
    </kog-item-list>
  </kog-card>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import ActivitySubmissionClassSwitcher from 'studyApp/components/activities/activity-submission-class-switcher.vue';
import useActivitySheetStore from 'studyApp/store/modules/activity-sheet/store.ts';

export default {
  name: 'ActivitySheetSubmissionSidebar',
  components: {
    ActivitySubmissionClassSwitcher,
    KogCard,
    KogTag,
    KogItemList,
  },
  props: {
    studentsList: {
      type: Array,
      required: true,
    },
    selectedStudentId: {
      type: Number,
      default: null,
    },
  },
  emits: ['select-student'],
  computed: {
    ...mapPiniaState(useActivitySheetStore, ['classOccasions']),
  },
  methods: {
    getItemAriaLabel(student) {
      return `${student.name}, ${this.getStatusTagText(student)}`;
    },
    getOccasionForStudent(student) {
      if (!this.classOccasions) {
        return null;
      }
      const selectedStudentOccasion = this.classOccasions.find(
        occasion => occasion.user_id === student.user_id,
      );
      return selectedStudentOccasion || null;
    },
    getStatusTagText(student) {
      const occasion = this.getOccasionForStudent(student);
      return occasion?.submitted_at ? 'Submitted' : 'Not submitted';
    },
    getStatusTagStyle(student) {
      const occasion = this.getOccasionForStudent(student);
      return occasion?.submitted_at ? 'positive' : 'informative';
    },
    selectStudent(student) {
      this.$emit('select-student', student.user_id);
    },
  },
};
</script>
<style scoped>
.ActivitySheetSubmissionSidebar-heading {
  font-size: var(--kog-font-size-smaller);
}

.ActivitySheetSubmissionSidebar-studentList {
  overflow-y: auto;
}

.ActivitySheetSubmissionSidebar-studentListItem {
  cursor: pointer;
  line-height: 24px;
  border-radius: 8px;
}

.ActivitySheetSubmissionSidebar-studentListItem:hover {
  color: var(--kog-text-brand);
  background-color: var(--kog-list-item-hover-background);
}

.ActivitySheetSubmissionSidebar-studentListItem--selected {
  cursor: default;
  background-color: var(--kog-list-item-selected-background);
}

.ActivitySheetSubmissionSidebar-studentListItem--selected:hover {
  background-color: var(--kog-list-item-selected-hover-background);
}
</style>
