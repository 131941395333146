<template>
  <div
    ref="OffCanvas"
    class="OffCanvas padd-top-l padd-m"
    :class="{
      'OffCanvas-fullHeight': isFullHeight,
      'OffCanvas-studyMenuHeight': !isFullHeight,
    }"
  >
    <div class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter">
      <h1 class="margin-0 heading-s">
        {{ heading }}
      </h1>
      <button
        class="KogButtonLegacy--noStyle"
        type="button"
        @click="close"
      >
        <kog-icon
          fa-style="light"
          size="l"
          icon-class="fa-times"
        />
      </button>
    </div>
    <slot />
  </div>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import isCoordinateWithinElementBoundingRectangle from 'sharedApp/utils/html-utils.js';

export default {
  name: 'OffCanvas',
  components: {
    KogIcon,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    isClosedOnClickOutside: {
      type: Boolean,
      default: true,
    },
    isFullHeight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  mounted() {
    document.addEventListener('mousedown', this.handleClick);
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  },
  methods: {
    handleClick(event) {
      if (!this.isClosedOnClickOutside) {
        return;
      }

      const { clientX, clientY } = event;
      // We want to use click coordinates because bubbling of events
      // doesn't work with popover which is attached to the <body>
      const isClickOutsideOffCanvas = !isCoordinateWithinElementBoundingRectangle(
        clientX,
        clientY,
        this.$refs.OffCanvas,
      );
      if (isClickOutsideOffCanvas) {
        this.close();
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.OffCanvas {
  position: fixed;
  z-index: 501;
  right: 0;
  bottom: 0;

  overflow-y: scroll;

  width: var(--off-canvas-width);

  background: var(--kogPlatformWhite);
  border-left: 1px solid var(--kogPlatformGray084);
  box-shadow: 0 12px 24px -6px var(--kogShadow020);
}

.OffCanvas-studyMenuHeight {
  top: var(--study-menu-height);
}

.OffCanvas-fullHeight {
  top: 0;
}
</style>
