<template>
  <div class="padd-top-l">
    <kog-button
      v-if="!isShowingFeedbackInput"
      :has-icon="true"
      icon-class="fa-plus"
      label="Add feedback"
      :aria-label="`Add feedback to ${student.name}`"
      button-style="basic"
      @click="onAddFeedbackClick"
    />
    <div v-else>
      <kog-textarea
        v-model:value="feedbackText"
        class="ReflectionResponseCard--feedback"
        label="Feedback to student"
        :aria-label="`Feedback to student ${student.name}`"
        label-icon="comment"
        style-type="prominent"
        :is-autosave-enabled="true"
        :fit-to-content="true"
        :top-right-helper-text="feedbackSentLabelText"
        :save-request$="saveFeedbackRequest$"
        :max-length="maxNoteAndFeedbackLength"
        @trigger-save="triggerSave"
      />
      <div class="margin-top-s flexContainer flexContainer-alignCenter flexContainer-flexEnd">
        <kog-tag
          v-if="hasSentFeedback && isDraftNotSent"
          is-chip
          icon-class="fa-rotate"
          label="Update not sent"
          size="m"
          type="basic"
        />
        <kog-button
          :has-icon="true"
          :disabled="!isSendFeedbackEnabled"
          icon-class="fa-paper-plane"
          label="Send"
          :aria-label="`Send feedback to ${student.name}`"
          button-style="primary"
          @click="onSendFeedback"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Subject } from 'rxjs';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'ReflectionResponseFeedback',
  components: {
    KogTextarea,
    KogTag,
    KogButton,
  },
  props: {
    teacherId: {
      type: Number,
      required: true,
    },
    classId: {
      type: Number,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    feedbackItem: {
      type: Object,
      required: true,
    },
  },
  emits: ['trigger-save-feedback'],
  data() {
    return {
      feedbackText: '',
      isAddFeedbackClicked: false,
      maxNoteAndFeedbackLength: 2000,
      saveFeedbackRequest$: new Subject(),
    };
  },
  computed: {
    isDraftNotSent() {
      return this.hasFeedback && this.feedbackItem.draft !== this.feedbackItem.feedback;
    },
    isSendFeedbackEnabled() {
      return this.isDraftNotSent && this.feedbackText?.length > 0;
    },
    isShowingFeedbackInput() {
      return this.hasFeedback || this.isAddFeedbackClicked;
    },
    hasFeedback() {
      return Boolean(this.feedbackItem?.feedback || this.feedbackItem?.draft);
    },
    hasSentFeedback() {
      return Boolean(this.feedbackItem?.feedback);
    },
    feedbackSentLabelText() {
      if (!this.hasFeedback || !this.feedbackItem.published_at) {
        return '';
      }
      return `Sent ${dateTimeFormatter(new Date(this.feedbackItem.published_at))}`;
    },
    mixPanelData() {
      return {
        teacher_id: this.teacherId,
        subject_class_id: this.classId,
      };
    },
  },
  mounted() {
    this.setFeedbackText();
  },

  methods: {
    triggerSave($event) {
      const feedbackId = this.feedbackItem.id;
      const data = { ...$event, feedbackId };
      this.$emit('trigger-save-feedback', data);
    },
    onAddFeedbackClick() {
      this.isAddFeedbackClicked = true;
    },
    setFeedbackText() {
      let text = '';
      if (this.hasFeedback) {
        if (this.feedbackItem.draft !== '') {
          text = this.feedbackItem.draft;
        } else {
          text = this.feedbackItem.feedback;
        }
      }
      this.feedbackText = text;
    },
    onSendFeedback() {
      this.saveFeedbackRequest$.next('submit');
      const name = 'Reflection Question Feedback - Send feedback';
      this.$mixpanel.trackEvent(name, this.mixPanelData);
    },
  },
};
</script>
