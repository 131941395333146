<template>
  <div class="padd-top-xl padd-bottom-xl">
    <div class="flexContainer flexContainer-alignEnd">
      <kog-select
        :selected-value="selectedStudentId"
        label="View student submission"
        class="padd-left-m padd-right-m"
        :options="studentsOptions"
        icon="fa-user"
        @change="onStudentSelected"
      />
      <kog-tag
        class="margin-bottom-xs"
        is-chip
        :type="submittedStatusStyle"
        :label="submittedStatusLabel"
      />
    </div>
    <kog-wave-separator class="margin-top-l width-full" />
    <activity-sheet-container
      class="margin-top-s"
      :is-in-review-mode="true"
      :is-in-view-submission-mode="true"
      :feature="feature"
      :selected-student-id="selectedStudentId"
    />
  </div>
</template>
<script>
import { mapState as mapPiniaState } from 'pinia';

import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import KogWaveSeparator from 'sharedApp/components/base/separators/kog-wave-separator.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import ActivitySheetContainer from 'studyApp/containers/activity-sheet/activity-sheet-container.vue';
import useActivitySheetStore from 'studyApp/store/modules/activity-sheet/store.ts';

export default {
  name: 'ActivitySheetSubmissionsContainer',
  components: {
    KogSelect,
    KogTag,
    KogWaveSeparator,
    ActivitySheetContainer,
  },
  props: {
    feature: {
      type: Object,
      required: true,
    },
    selectedStudentId: {
      type: Number,
      default: null,
    },
    studentsList: {
      type: Array,
      required: true,
    },
  },
  emits: ['student-selection-change'],
  computed: {
    ...mapPiniaState(useActivitySheetStore, {
      atsOccasions: 'classOccasions',
    }),
    studentsOptions() {
      return this.studentsList.map(student => ({
        value: student.user_id,
        text: `${student.first_name} ${student.last_name}`,
      }));
    },
    submittedAtStatus() {
      const atsOccasion = this.atsOccasions.find(
        occasion => occasion.user_id === this.selectedStudentId,
      );
      return atsOccasion?.submitted_at || null;
    },
    submittedStatusLabel() {
      const submittedAt = this.submittedAtStatus;
      if (submittedAt) {
        const formatted = dateTimeFormatter(new Date(submittedAt));
        return `Submitted ${formatted}`;
      }
      return 'Not submitted';
    },
    submittedStatusStyle() {
      return this.submittedAtStatus ? 'positive' : 'informative';
    },
  },
  methods: {
    onStudentSelected(studentId) {
      this.$emit('student-selection-change', studentId);
    },
  },
};
</script>
