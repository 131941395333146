<template>
  <select
    :id="id"
    v-model="selectedEndDate"
    :name="name"
    class="block form-control"
    @change="onChange"
  >
    <option
      value=""
      disabled
      selected
    >
      {{ placeHolder }}
    </option>
    <option
      v-for="date in endDates"
      :key="date"
      :value="date"
    >
      {{ date }}
      <span v-if="selectedEndDate === date">(currently selected)</span>
    </option>
  </select>
</template>

<script>
import { dateFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'ClassEnddatesSelect',
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    datesToGenerate: {
      type: Number,
      default: 60,
    },
    placeHolder: {
      type: String,
      default: 'Select date',
    },
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      selectedEndDate: null,
      endDates: [],
    };
  },
  watch: {
    value() {
      this.addDateToList(this.value);
      this.selectedEndDate = this.value;
    },
  },
  mounted() {
    this.endDates = this.generateEndDatesList();
    this.selectedEndDate = this.value;
  },
  methods: {
    onChange() {
      this.$emit('update:value', this.selectedEndDate);
      this.$emit('input', this.selectedEndDate);
    },
    addDateToList(dateStr) {
      if (dateStr && !this.endDates.includes(dateStr)) {
        this.endDates.push(dateStr);
        this.endDates.sort();
      }
    },
    generateEndDatesList() {
      const startDate = new Date();
      const startDateStr = dateFormatter(startDate);
      const dates = [];

      for (let index = 1; index <= this.datesToGenerate; index += 1) {
        const monthZeroIndexed = startDate.getUTCMonth() + index;
        // Date to zero means go back one day to the last day of previous month.
        const endDate = new Date(Date.UTC(startDate.getUTCFullYear(), monthZeroIndexed, 0));
        const dateString = dateFormatter(endDate);
        dates.push(dateString);
      }

      if (!dates.includes(startDateStr)) {
        dates.push(startDateStr);
      }
      if (this.value && !dates.includes(this.value)) {
        dates.push(this.value);
      }

      dates.sort();
      return dates;
    },
  },
};
</script>
