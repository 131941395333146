<template>
  <kog-default-study-page>
    <template #body>
      <kog-loader
        :loading="$wait.is('loading_question_assignment_result/*')"
        loading-msg="Loading the assignment..."
      >
        <template v-if="isSetupDone">
          <div class="page-header">
            <h1 class="heading-xl"> {{ htmlEntities(assignment.name) }} results </h1>
          </div>
          <kog-alert
            v-if="
              assignmentSettings.deadline.isUpcoming &&
              assignmentSettings.answers.displayAfterDeadline
            "
            title="Your answers have been submitted"
            mode="success"
          >
            Your score will be available after the deadline:
            {{ date(assignment.deadline) }}
          </kog-alert>
          <integration-assignment-alert
            v-if="shouldShowIntegrationWarning"
            class="margin-bottom-m"
          />
          <div class="row">
            <div
              v-if="
                assignmentSettings.deadline.hasPassed ||
                assignmentSettings.answers.displayAfterSubmission ||
                assignmentSettings.answers.displayImmediately
              "
              class="col-xs-12 col-md-6 col-md-offset-3 text-center"
            >
              <p class="heading-m margin-top-m margin-bottom-xs">
                Your score: {{ scorePercent }}%
              </p>
              <kog-progress-bar :progress="scorePercent" />
            </div>
          </div>
          <div
            v-if="isSentToGoogleClassroom"
            class="margin-top-xxl margin-bottom-xxl"
          >
            <google-classroom-reminder />
          </div>
          <div class="row margin-bottom-xxl">
            <div class="col-xs-12">
              <h2
                class="text-center margin-bottom-m QuestionAssignmentResult-answersHeader"
                :class="{ 'margin-top-xxl': !isSentToGoogleClassroom }"
              >
                Your answers
              </h2>
              <template
                v-if="
                  assignmentSettings.deadline.isUpcoming &&
                  assignmentSettings.answers.displayAfterDeadline
                "
              >
                <p class="text-center">
                  When the deadline has passed, a breakdown of your result will be available here.
                </p>
              </template>
              <p
                v-if="
                  assignmentSettings.answers.displayAfterSubmission ||
                  assignmentSettings.answers.displayImmediately
                "
                class="margin-bottom-m text-center"
              >
                Here you find the breakdown of your result. Click on a question to view the details.
              </p>
              <template
                v-if="
                  assignmentSettings.answers.displayAfterSubmission ||
                  assignmentSettings.answers.displayImmediately ||
                  assignmentSettings.deadline.hasPassed
                "
              >
                <practice-item-results
                  v-if="practiceOccasion.practice_items"
                  :practice-items="practiceOccasion.practice_items"
                  :subject="subject"
                  :questions="assignment.questions"
                  source="Assignment Result"
                />
              </template>
              <div class="flexContainer">
                <submit-to-integration-button
                  v-if="canSubmitIntegrationAssignment"
                  button-style="accent"
                  :assignment="assignment"
                  :has-submitted-to-integration="hasSubmittedToIntegration"
                  :polling-for-submission="$wait.is('pollForSubmissionToIntegration')"
                />
                <integration-assignment-link
                  v-if="assignment.is_sent_to_integration"
                  class="margin-left-s padd-top-xs"
                  :integration-provider="integrationProvider"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <div class="QuestionAssignmentResult-infoBox">
                <h2 class="divider-bottom heading-m margin-bottom-s margin-top-s">
                  Assignment information
                </h2>
                <p class="margin-top-m heading-xxs"> Submitted: </p>
                <p>
                  {{ date(practiceOccasion.done_at) }}
                </p>
                <p class="margin-top-m heading-xxs"> Deadline: </p>
                <p>
                  {{ date(assignment.deadline) }}
                </p>
                <p class="margin-top-m heading-xxs"> Questions: </p>
                <p>
                  {{ assignment.question_count }}
                </p>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="QuestionAssignmentResult-infoBox">
                <h2 class="divider-bottom heading-m margin-bottom-s margin-top-s">
                  What's next?
                </h2>
                <p
                  class="QuestionAssignmentResult-textHeader heading-xxs margin-top-m margin-bottom-s"
                >
                  Study more {{ subject.name }}?
                </p>
                <router-link
                  :to="goToClassOverview()"
                  class="KogButtonLegacy KogButtonLegacy--primary"
                  target="_self"
                >
                  Overview
                </router-link>
                <p
                  id="all-assignments"
                  class="QuestionAssignmentResult-textHeader heading-xxs margin-top-m margin-bottom-s"
                  aria-hidden="true"
                >
                  See all {{ subject.name }} assignments:
                </p>
                <router-link
                  :to="goToMyAssignmentsList()"
                  class="KogButtonLegacy"
                  aria-labelledby="all-assignments"
                >
                  Assignment overview
                </router-link>
              </div>
            </div>
          </div>
        </template>
      </kog-loader>
    </template>
  </kog-default-study-page>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import format from 'date-fns/format/index.js';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapState, useStore } from 'vuex';

import htmlEntities from 'learning/common/libs/html-entities.js';

import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import useAssignmentReadableSettings from 'sharedApp/composables/use-assignment-readable-settings.ts';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { getDateXMinutesLess } from 'sharedApp/utils/time-utils.js';
import IntegrationAssignmentAlert from 'studyApp/components/assignment/integration-assignment-alert.vue';
import IntegrationAssignmentLink from 'studyApp/components/assignment/integration-assignment-link.vue';
import GoogleClassroomReminder from 'studyApp/components/google-classroom-reminder/google-classroom-reminder.vue';
import KogDefaultStudyPage from 'studyApp/components/kog-default-study-page.vue';
import PracticeItemResults from 'studyApp/components/practice-item-results.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';
import SubmitToIntegrationButton from 'studyApp/pages/assignment/components/submit-to-integration-button.vue';

export default {
  name: 'QuestionAssignmentResult',
  components: {
    SubmitToIntegrationButton,
    GoogleClassroomReminder,
    KogDefaultStudyPage,
    KogLoader,
    PracticeItemResults,
    KogProgressBar,
    KogAlert,
    IntegrationAssignmentAlert,
    IntegrationAssignmentLink,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const { assignmentModule, subjectModule } = store.state;

    const assignmentName = computed(() => assignmentModule.assignment?.assignment?.name ?? '');
    const subjectName = computed(() => subjectModule.subject?.name);

    useHead({
      title: () => `${assignmentName.value} | Assignment results | ${subjectName.value}`,
    });
    const { loadIntegrationAssignment, warningMessage, integrationProvider } =
      useIntegrationAssignment();

    return {
      loadIntegrationAssignment,
      integrationWarningMessage: warningMessage,
      integrationProvider,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      assignmentData: state => state.assignmentModule.assignment,
      currentSubjectClassDetails: state => state.subjectClassModule.currentSubjectClassDetails,
    }),
    assignmentSettings() {
      return useAssignmentReadableSettings(this.assignment);
    },
    isSetupDone() {
      return this.assignment && this.currentSubjectClassDetails;
    },
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    isSentToGoogleClassroom() {
      return this.assignmentData.is_sent_to_google_classroom;
    },
    assignment() {
      return this.assignmentData?.assignment ?? null;
    },
    practiceOccasion() {
      return this.assignmentData?.practice_occasion;
    },
    scorePercent() {
      const correct = this.practiceOccasion.correct_answer_count;
      const total = Math.max(this.practiceOccasion.total_answer_count, 1);
      return Math.round((100 * correct) / total);
    },
    shouldShowIntegrationWarning() {
      return (
        !!this.integrationWarningMessage ||
        (this.assignment.is_sent_to_integration &&
          !this.hasSubmittedToIntegration &&
          Date.parse(this.practiceOccasion.done_at) < getDateXMinutesLess(5, new Date()))
      );
    },
    canSubmitIntegrationAssignment() {
      return this.assignment?.is_sent_to_integration && !this.integrationWarningMessage;
    },
    hasSubmittedToIntegration() {
      return !!this.assignmentData.upstream_submission_id;
    },
  },
  beforeMount() {
    if (this.assignmentId.toString() !== this.assignmentData?.assignment?.id.toString()) {
      this.clearAssignment();
    }
  },
  async mounted() {
    this.fetchSubjectClassDetails({ subjectClassId: this.classId });
    await this.fetchAssignment();
    this.loadIntegrationAssignment(this.assignment, this.classId);
    if (this.canSubmitIntegrationAssignment && !this.assignmentData.upstream_submission_id) {
      this.startIntegrationSubmissionPolling();
    }
  },
  methods: {
    ...mapWaitingActions({
      getAssignment: {
        action: 'assignmentModule/getAssignment',
        loader: 'loading_question_assignment_result/getAssignment',
      },
      pollForSubmissionToIntegration: {
        action: 'assignmentModule/pollForSubmissionToIntegration',
        loader: 'pollForSubmissionToIntegration',
      },
      fetchSubjectClassDetails: {
        action: 'subjectClassModule/fetchSubjectClassDetails',
        loader: 'loading_question_assignment_result/fetchSubjectClassDetails',
      },
    }),
    ...mapActions('assignmentModule', ['clearAssignment']),
    async startIntegrationSubmissionPolling() {
      try {
        await this.pollForSubmissionToIntegration(this.assignment.id);
      } catch {
        this.$toast.showError(
          `Submission to ${this.integrationProvider} failed, please try again. Contact support if the issue persists.`,
        );
      }
    },
    goToClassOverview() {
      return {
        name: 'subjectClassOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
      };
    },
    goToMyAssignmentsList() {
      return {
        name: 'classAssignmentsOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
      };
    },
    async fetchAssignment() {
      await this.getAssignment(this.assignmentId);
      this.trackMixpanel('Question Assignment result - pageview');
    },
    trackMixpanel(eventName) {
      const { correct_answer_count: correctAnswerCount, total_answer_count: totalAnswerCount } =
        this.practiceOccasion;
      const eventProperties = {
        correct_answers: correctAnswerCount,
        incorrect_answers: totalAnswerCount - correctAnswerCount,
      };
      this.$mixpanel.trackEvent(eventName, eventProperties);
    },
    htmlEntities,
    date(value) {
      return value ? format(new Date(value), 'YYYY-MM-DD HH:mm') : '';
    },
  },
};
</script>

<style scoped>
.QuestionAssignmentResult-answersHeader {
  font-size: 20px;
  font-weight: unset;
}

.QuestionAssignmentResult-infoBox {
  min-height: 20px;
  margin-bottom: var(--space-m);
  padding: var(--space-m);

  background-color: var(--kog-colors-grey-100);
  border: 1px solid var(--kog-colors-grey-300);
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.QuestionAssignmentResult-textHeader {
  line-height: 16px;
}
</style>
