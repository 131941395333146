<template>
  <fill-in-the-blanks-question
    v-bind="attrs"
    :question="getQuestion"
    :user-answers="userResponse"
    :disable-user-input="disabled"
    @blur="eventFunctions.saveHandler"
    @input="eventFunctions.inputHandler"
  />
</template>

<script setup lang="ts">
import { computed, toRefs, useAttrs } from 'vue';

import type { Emit, FBQEmitResponse, Props } from './fbq.types.ts';

import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import { useQuestionAutoSave } from 'sharedApp/composables/use-question-auto-save.ts';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';

const props = withDefaults(defineProps<Props>(), {
  userResponse: undefined,
  disabled: false,
  answers: undefined,
  autoSave: false,
});

const { question, answers } = toRefs(props);
const attrs = useAttrs();
const emit = defineEmits<Emit>();

const getQuestion = computed(() => ({
  ...question.value,
  answers: answers.value,
}));

const eventFunctions = computed(() => {
  function emitChange(response: FBQEmitResponse) {
    emit('change', {
      question_uuid: question.value.uuid,
      response_type: RESPONSE_TYPES.FBR,
      user_response: response.answers,
    });
  }

  function emitResponse(response: FBQEmitResponse) {
    emit('response', {
      question_uuid: question.value.uuid,
      response_type: RESPONSE_TYPES.FBR,
      user_response: response.answers,
    });
  }

  if (props.autoSave) {
    return useQuestionAutoSave(question, {
      onInput: emitChange,
      onSave: emitResponse,
    });
  }

  return {
    inputHandler: emitChange,
    saveHandler: emitResponse,
  };
});
</script>
