<template>
  <kog-card
    class="PerformanceTaskOverviewTable flexContainer flexContainer-column flexChild-size-1"
  >
    <kog-table
      is-header-sticky
      :sticky-left-column-count="1"
      :sticky-right-column-count="2"
      is-vertical-scrollable
      class="flexContainer flexContainer-column flexChild-size-1"
      row-separator="border"
      :is-vertical-cell-padding-enabled="false"
      :style="{
        'max-height': tableMaxHeight,
      }"
    >
      <template #header>
        <kog-table-header>
          <kog-table-cell-header
            class="PerformanceTaskOverviewTable-StudentNameHeader"
            :is-sorted="sortKey === sortingKeys.USER__FULL_NAME"
            :sort-column="() => sortColumn(sortingKeys.USER__FULL_NAME)"
            :sort-order="sortOrder"
          >
            Student
          </kog-table-cell-header>
          <kog-table-cell-header
            v-for="(question, index) in contentQuestions"
            :key="question.id"
            class="PerformanceTaskOverviewTable-header"
            :is-sorted="sortKey === question.question_id"
            :sort-column="() => sortColumn(question.question_id)"
            :sort-order="sortOrder"
          >
            {{ index + 1 }}
          </kog-table-cell-header>
          <kog-table-cell-header
            :has-left-gap="true"
            :is-sorted="sortKey === 'totalScore'"
            :sort-column="() => sortColumn('totalScore')"
            :sort-order="sortOrder"
          >
            Total
          </kog-table-cell-header>
          <kog-table-cell-header> Status </kog-table-cell-header>
        </kog-table-header>
      </template>
      <template #body>
        <kog-table-row
          v-for="student in sortedStudentList"
          :key="student.userId"
          class="PerformanceTaskOverviewTable-studentRow"
        >
          <kog-table-cell-text class="PerformanceTaskOverviewTable-StudentName">
            {{ student.name }}
          </kog-table-cell-text>
          <marks-cell
            v-for="question in contentQuestions"
            :key="`${student.userId}-${question.question_id}`"
            :marks-data="getMarksByStudentAndQuestionId(student, question.question_id)"
            :question-progress-scale="questionProgressScale"
          />
          <marks-cell
            :marks-data="totalForStudent(student)"
            :has-left-gap="true"
            :question-progress-scale="questionProgressScale"
          />
          <kog-table-cell-generic
            v-if="!isReviewButtonShown(student)"
            class="PerformanceTaskOverviewTable-sentStatusCell"
          >
            <kog-icon
              fa-style="regular"
              :is-non-actionable="true"
              size="m"
              icon-class="fa-check"
              class="margin-right-s"
            />
            <span>Sent</span>
          </kog-table-cell-generic>
          <kog-table-cell-generic v-else>
            <kog-button
              label="Review"
              aria-label="Review"
              :has-icon="true"
              icon-class="fa-chevron-right"
              icon-position="right"
              size="small"
              button-style="accent"
              @click="$emit('navigate-to-student-submissions', student.user_id)"
            />
          </kog-table-cell-generic>
        </kog-table-row>
      </template>
    </kog-table>
  </kog-card>
</template>
<script>
import { isNil, sortBy } from 'lodash';
import { mapGetters } from 'vuex';

import { PERFORMANCE_TASK_MODULE } from 'publishingApp/store/modules/performance-task.js';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import sortingKeys from 'sharedApp/const/sorting-keys.js';
import FullPageTableHeightMixin from 'sharedApp/mixins/full-page-table-height-mixin.js';
import { QUESTION_ACTIVITY_MODULE } from 'studyApp/store/modules/question-activity.js';
import { QuestionProgressScale } from 'studyApp/utils/teacher/teacher-insights-utils.js';
import { SORT_ORDERS } from 'teachApp/utils/assignment-utils.js';
import { PROGRESS_SCALES } from 'teachApp/utils/heatmap-scales.js';

import marksCell from './marks-cell.vue';

export default {
  name: 'PerformanceTaskOverviewTable',
  components: {
    KogTableCellGeneric,
    KogTable,
    KogTableCellHeader,
    KogTableCellText,
    KogButton,
    marksCell,
    KogTableRow,
    KogIcon,
    KogCard,
    KogTableHeader,
  },

  mixins: [FullPageTableHeightMixin],

  props: {
    contentQuestions: {
      type: Array,
      required: true,
    },
    studentsList: {
      type: Array,
      required: true,
    },
  },
  emits: ['navigate-to-student-submissions'],
  data() {
    return {
      selectedScale: PROGRESS_SCALES.NGSS,
      sortKey: sortingKeys.USER__FULL_NAME,
      sortOrder: SORT_ORDERS.desc,
      sortingKeys,
      // eslint-disable-next-line vue/no-unused-properties
      tableDistanceFromPageBottom: 8, // needed by FullPageTableHeightMixin
    };
  },
  computed: {
    ...mapGetters({
      scoredOccasionByStudentUserId: `${PERFORMANCE_TASK_MODULE}/scoredOccasionByStudentUserId`,
      isMarksChangedAfterSendingByUserId: `${PERFORMANCE_TASK_MODULE}/isMarksChangedAfterSendingByUserId`,
      isMarkSentByUserId: `${PERFORMANCE_TASK_MODULE}/isMarkSentByUserId`,
      getMaxTotalForAllQuestions: `${QUESTION_ACTIVITY_MODULE}/getMaxTotalForAllQuestions`,
      studentsMarksByQuestionId: `${PERFORMANCE_TASK_MODULE}/studentsMarksByQuestionId`,
    }),
    questionProgressScale() {
      return new QuestionProgressScale(this.selectedScale);
    },
    sortedStudentList() {
      const copy = [...this.studentsList];
      if (this.sortKey === sortingKeys.USER__FULL_NAME) {
        return this.sortByName(copy);
      }
      if (this.sortKey === 'totalScore') {
        return this.sortByTotal(copy);
      }
      return this.sortByQuestionScore(copy);
    },
  },
  methods: {
    totalForStudent(student) {
      const studentOccasion = this.scoredOccasionByStudentUserId[student.user_id];
      if (!studentOccasion || !studentOccasion.occasion.submitted_at) {
        return null;
      }
      return {
        max: this.getMaxTotalForAllQuestions,
        marks_draft: studentOccasion?.totalScore ?? null,
      };
    },
    sortByName(students) {
      const sortedList = students.sort((student1, student2) => {
        if (this.sortOrder === SORT_ORDERS.desc) {
          return student1.last_name.localeCompare(student2.last_name);
        }
        return student2.last_name.localeCompare(student1.last_name);
      });
      return sortedList;
    },
    sortObjectByValue(object, value) {
      const sortedArray = sortBy(object, value);
      if (this.sortOrder === SORT_ORDERS.desc) {
        return sortedArray.reverse();
      }
      return sortedArray;
    },
    sortByQuestionScore(students) {
      const studentsWithMarks = this.studentsMarksByQuestionId[this.sortKey].filter(
        student => !isNil(student.marks_draft),
      );
      const userIdsSortedByMarks = this.sortObjectByValue(studentsWithMarks, ['marks_draft']).map(
        student => student.user_id,
      );
      return this.sortStudentsBySubmission(students, userIdsSortedByMarks);
    },
    sortStudentsBySubmission(students, sortedUserIds) {
      const studentsWithSubmission = this.getStudentsWithSubmission(students, sortedUserIds);
      const studentsWithoutSubmission = this.getStudentsWithoutSubmission(students, sortedUserIds);
      const sortedStudentsWithSubmission = this.sortBySortingArray(
        studentsWithSubmission,
        sortedUserIds,
      );
      if (this.sortOrder === SORT_ORDERS.desc) {
        return [...sortedStudentsWithSubmission, ...studentsWithoutSubmission];
      }
      return [...studentsWithoutSubmission, ...sortedStudentsWithSubmission];
    },
    getStudentsWithoutSubmission(students, studentsWithSubmission) {
      return students.filter(student => !studentsWithSubmission.includes(student.user_id));
    },
    getStudentsWithSubmission(students, studentsWithSubmission) {
      return students.filter(student => studentsWithSubmission.includes(student.user_id));
    },
    sortBySortingArray(students, userIdsToSortBy) {
      return students.sort(
        (student1, student2) =>
          userIdsToSortBy.indexOf(student1.user_id) - userIdsToSortBy.indexOf(student2.user_id),
      );
    },
    sortByTotal(students) {
      const userIdsSortedByTotal = this.sortObjectByValue(this.scoredOccasionByStudentUserId, [
        'totalScore',
      ]).map(data => data.occasion.user_id);

      return this.sortStudentsBySubmission(students, userIdsSortedByTotal);
    },
    sortColumn(key) {
      const isSortedDesc = this.sortOrder === SORT_ORDERS.desc;
      const order = isSortedDesc ? SORT_ORDERS.asc : SORT_ORDERS.desc;
      this.sortKey = key;
      this.sortOrder = order;
    },
    getMarksByStudentAndQuestionId(student, questionId) {
      const scoredOccasion = this.scoredOccasionByStudentUserId[student.user_id];
      return scoredOccasion?.grading[questionId] || null;
    },
    isReviewButtonShown(student) {
      const scoredOccasion = this.scoredOccasionByStudentUserId[student.user_id]?.occasion;
      if (!scoredOccasion || !scoredOccasion.latest_question_graded_at) {
        return true;
      }
      return (
        this.isMarksChangedAfterSendingByUserId[student.user_id] ||
        !this.isMarkSentByUserId[student.user_id]
      );
    },
  },
};
</script>
