<template>
  <div>
    <kog-modal
      :maximize="true"
      :title="mainModalTitle"
      :subtitle="mainModalSubtitle"
      :subtitle-tags="mainModalSubtitleTags"
      :header-icon="mainModalHeaderIcon"
      :close-modal="() => $emit('close-main-modal')"
      :is-collapsed="!isChildModalCollapsed"
      :class="{
        'u-restrictedModalHeight': isChildModalVisible,
      }"
      @header-click="onMainModalHeaderClick"
    >
      <template #modalBody>
        <slot name="mainModalBody" />
      </template>
    </kog-modal>
    <vertical-expand>
      <kog-modal
        v-if="isChildModalVisible"
        :maximize="true"
        :title="childModalTitle"
        :subtitle="childModalSubtitle"
        :subtitle-tags="childModalSubtitleTags"
        :header-icon="childModalHeaderIcon"
        :close-modal="() => $emit('close-child-modal')"
        :is-collapsed="isChildModalCollapsed"
        :class="{
          'u-restrictedModalHeight': isChildModalVisible,
        }"
        @header-click="onChildModalHeaderClick"
      >
        <template #modalBody>
          <slot name="childModalBody" />
        </template>
      </kog-modal>
    </vertical-expand>
  </div>
</template>

<script>
import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'DoubleModal',
  components: {
    KogModal,
    VerticalExpand,
  },
  props: {
    mainModalTitle: {
      type: String,
      default: '',
    },
    mainModalSubtitle: {
      type: String,
      default: '',
    },
    mainModalSubtitleTags: {
      type: Array,
      default: () => [],
    },
    mainModalHeaderIcon: {
      type: Object,
      default: () => ({}),
    },
    isChildModalVisible: {
      type: Boolean,
      required: false,
    },
    childModalTitle: {
      type: String,
      default: '',
    },
    childModalSubtitle: {
      type: String,
      default: '',
    },
    childModalSubtitleTags: {
      type: Array,
      default: () => [],
    },
    childModalHeaderIcon: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close-child-modal', 'close-main-modal'],
  data() {
    return {
      isChildModalCollapsed: !this.isChildModalVisible,
    };
  },
  watch: {
    isChildModalVisible(newValue) {
      this.isChildModalCollapsed = !newValue;
    },
    childModalTitle() {
      if (this.isChildModalVisible && this.isChildModalCollapsed) {
        this.isChildModalCollapsed = false;
      }
    },
  },
  methods: {
    onMainModalHeaderClick() {
      if (!this.isChildModalCollapsed) {
        this.isChildModalCollapsed = true;
      }
    },
    onChildModalHeaderClick() {
      if (this.isChildModalCollapsed) {
        this.isChildModalCollapsed = false;
      }
    },
  },
};
</script>

<style>
.u-restrictedModalHeight {
  max-height: calc(95vh - 100px) !important;
}
</style>
