export default [
  {
    text: 'School staff',
    options: [
      {
        text: 'Teacher',
        value: 'Teacher',
      },
      {
        text: 'Principal / Vice Principal',
        value: 'Principal / Vice Principal',
      },
      {
        text: 'Head of Department / Department Head',
        value: 'Head of Department / Department Head',
      },
      {
        text: 'Procurement / Purchasing Manager',
        value: 'Procurement / purchasing manager',
      },
      {
        text: 'Curriculum Coordinator',
        value: 'Curriculum Coordinator',
      },
      {
        text: 'Lead Teacher',
        value: 'Lead Teacher',
      },
      {
        text: 'School Administrator',
        value: 'School Administrator',
      },
      {
        text: 'Technology Integration Manager',
        value: 'Technology Integration Manager',
      },
      {
        text: 'School Head / Deputy',
        value: 'School Head/Deputy',
      },
      {
        text: 'Purchasing Coordinator',
        value: 'Purchasing Coordinator',
      },
      {
        text: 'Home School / Tutor',
        value: 'Home School / Tutor',
      },
      {
        text: 'Parent',
        value: 'Parent',
      },
      {
        text: 'Student',
        value: 'Student',
      },
    ],
  },
  {
    text: 'District staff',
    options: [
      {
        text: 'Director of Instruction (District / Group Level)',
        value: 'Director of Instruction (District/Group Level)',
      },
      {
        text: 'Instructional Specialist (District / Group Level)',
        value: 'Instructional Specialist (District/Group Level)',
      },
      {
        text: 'Curriculum Director (District / Group Level)',
        value: 'Curriculum Director (District/Group Level)',
      },
      {
        text: 'District / Group Administrator',
        value: 'District/Group Administrator',
      },
      {
        text: 'Superintendent / Assistant',
        value: 'Superintendent/Assistant',
      },
    ],
  },
];
