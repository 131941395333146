export const ANYBOX_TYPES = {
  tutorbox: {
    title: 'Exam tip',
    classes: ['genericbox', 'tutorbox'],
  },
  definitionbox: {
    title: 'Important',
    classes: ['genericbox', 'definitionbox'],
  },
  alertbox: {
    title: 'Be aware',
    classes: ['genericbox', 'alertbox'],
  },
  conceptbox: {
    title: 'Concept',
    classes: ['genericbox', 'conceptbox'],
  },
  summarybox: {
    title: 'What you should know',
    classes: ['genericbox', 'summarybox'],
  },
  termdefinitionbox: {
    title: 'Definition',
    classes: ['genericbox', 'termdefinitionbox'],
  },
  theoryofknowledge: {
    title: 'Theory of Knowledge',
    classes: ['genericbox', 'theoryofknowledge'],
  },
  internationalmindedness: {
    title: 'International Mindedness',
    classes: ['genericbox', 'internationalmindedness'],
  },
  casestudy: {
    title: 'Case study',
    classes: ['genericbox', 'casestudy'],
  },
  natureofsciencebox: {
    title: '',
    classes: ['genericbox', 'natureofsciencebox'],
  },
  claimbox: {
    title: 'Claim',
    classes: ['genericbox', 'claimbox'],
  },
  practical: {
    title: 'Practical',
    classes: ['genericbox', 'practical'],
  },
  studyskills: {
    title: 'Study skills',
    classes: ['genericbox', 'studyskills'],
  },
  skills: {
    title: 'Skills',
    classes: ['genericbox', 'skills'],
  },
  activitybox: {
    title: 'Activity',
    classes: ['genericbox', 'activitybox'],
  },
  hintbox: {
    title: 'Hint',
    classes: ['genericbox', 'hintbox'],
  },
  makingconnectionsbox: {
    title: 'Making connections',
    classes: ['genericbox', 'makingconnectionsbox'],
  },
  extendedcontentbox: {
    title: 'Extended',
    classes: ['extendedcontentbox'],
  },
  hlcontentbox: {
    title: 'Higher level (HL)',
    classes: ['hlcontentbox', 'extendedcontentbox'],
  },
  vocabularybox: {
    title: 'Vocabulary',
    classes: ['genericbox', 'vocabularybox'],
  },
  grammartipbox: {
    title: 'Grammar tip',
    classes: ['genericbox', 'grammartipbox'],
  },
  extractbox: {
    title: 'Extract',
    classes: ['genericbox', 'extractbox'],
  },
  casbox: {
    title: 'Creativity, activity, service',
    classes: ['genericbox', 'casbox'],
  },
  hlextensionbox: {
    title: 'HL Extension',
    classes: ['genericbox', 'hlextensionbox'],
  },
  mentalidadinternacionalbox: {
    title: 'Mentalidad internacional',
    classes: ['genericbox', 'mentalidadinternacionalbox'],
  },
  notagramaticalbox: {
    title: 'Nota gramatical',
    classes: ['genericbox', 'notagramaticalbox'],
  },
  extractobox: {
    title: 'Extracto',
    classes: ['genericbox', 'extractobox'],
  },
  teoriadelconocimientobox: {
    title: 'Teoría del conocimiento',
    classes: ['genericbox', 'teoriadelconocimientobox'],
  },
  creatividadactividadyserviciobox: {
    title: 'Creatividad, actividad y servicio',
    classes: ['genericbox', 'creatividadactividadyserviciobox'],
  },
  vocabulariobox: {
    title: 'Vocabulario',
    classes: ['genericbox', 'vocabulariobox'],
  },
  consejodeexamenbox: {
    title: 'Consejo de examen',
    classes: ['genericbox', 'consejodeexamenbox'],
  },
  actividadbox: {
    title: 'Actividad',
    classes: ['genericbox', 'actividadbox'],
  },
  conceptobox: {
    title: 'Concepto',
    classes: ['genericbox', 'conceptobox'],
  },
  ampliacionparaelnivelsuperiorbox: {
    title: 'Ampliación para el Nivel Superior',
    classes: ['ampliacionparaelnivelsuperiorbox'],
  },
  espritinternationalbox: {
    title: 'Esprit international',
    classes: ['genericbox', 'espritinternationalbox'],
  },
  conseilgrammairebox: {
    title: 'Conseil grammaire',
    classes: ['genericbox', 'conseilgrammairebox'],
  },
  extraitbox: {
    title: 'Extrait',
    classes: ['genericbox', 'extraitbox'],
  },
  theoriedelaconnaissancebox: {
    title: 'Théorie de la connaissance',
    classes: ['genericbox', 'theoriedelaconnaissancebox'],
  },
  creativiteactiviteservicebox: {
    title: 'Créativité, activité, service',
    classes: ['genericbox', 'creativiteactiviteservicebox'],
  },
  vocabulairebox: {
    title: 'Vocabulaire',
    classes: ['genericbox', 'vocabulairebox'],
  },
  conseilexamenbox: {
    title: 'Conseil examen',
    classes: ['genericbox', 'conseilexamenbox'],
  },
  activitebox: {
    title: 'Activité',
    classes: ['genericbox', 'activitebox'],
  },
  frenchconceptbox: {
    title: 'Concept',
    classes: ['genericbox', 'frenchconceptbox'],
  },
  approfondissementbox: {
    title: 'Approfondissement pour Niveau Supérieur',
    classes: ['genericbox', 'approfondissementbox'],
  },
  learningoutcomesbox: {
    title: 'Learning outcomes',
    classes: ['genericbox', 'learningoutcomesbox'],
  },
  learningintentionsbox: {
    title: 'Learning intentions',
    classes: ['genericbox', 'learningintentionsbox'],
  },
  keytermsbox: {
    title: 'Key terms',
    classes: ['genericbox', 'keytermsbox'],
  },
  practicalskillsbox: {
    title: 'Practical skills',
    classes: ['genericbox', 'practicalskillsbox'],
  },
  fieldworkopportunitybox: {
    title: 'Field work opportunity',
    classes: ['genericbox', 'fieldworkopportunitybox'],
  },
  mapskillsbox: {
    title: 'Map skills',
    classes: ['genericbox', 'mapskillsbox'],
  },
  practicequestionbox: {
    title: 'Practice question',
    classes: ['genericbox', 'practicequestionbox'],
  },
  yourcontextbox: {
    title: 'Your context',
    classes: ['genericbox', 'yourcontextbox'],
  },
  scienceandengineeringpracticebox: {
    title: 'Science and Engineering Practice ',
    classes: ['genericbox', 'scienceandengineeringpracticebox'],
  },
  crosscuttingconceptbox: {
    title: 'Crosscutting Concept',
    classes: ['genericbox', 'crosscuttingconceptbox'],
  },
};

export const DROPDOWN_OPTIONS = [
  ['Generic: Exam tip', 'tutorbox'],
  ['Generic: Important', 'definitionbox'],
  ['Generic: Be aware', 'alertbox'],
  ['Generic: What you should know', 'summarybox'],
  ['Generic: Definition', 'termdefinitionbox'],
  ['Generic: Case study', 'casestudy'],
  ['[Deprecated] Generic: Claim', 'claimbox'],
  ['Generic: Activity', 'activitybox'],
  ['Generic: Hint', 'hintbox'],
  ['Generic: Making connections', 'makingconnectionsbox'],
  ['Generic: Vocabulary', 'vocabularybox'],
  ['Generic: Grammar tip', 'grammartipbox'],
  ['Generic: Extract', 'extractbox'],
  ['IBDP: Concept', 'conceptbox'],
  ['IBDP: Nature of Science', 'natureofsciencebox'],
  ['IBDP: Theory of Knowledge', 'theoryofknowledge'],
  ['IBDP: International Mindedness', 'internationalmindedness'],
  ['IBDP: Creativity, activity, service', 'casbox'],
  ['IBDP: HL Extension', 'hlextensionbox'],
  ['IBDP: HL Extension (hidden in SL)', 'hlcontentbox'],
  ['IBDP Spanish: Mentalidad internacional', 'mentalidadinternacionalbox'],
  ['IBDP Spanish: Nota gramatical', 'notagramaticalbox'],
  ['IBDP Spanish: Extracto', 'extractobox'],
  ['IBDP Spanish: Teoría del conocimiento', 'teoriadelconocimientobox'],
  ['IBDP Spanish: Creatividad, actividad y servicio', 'creatividadactividadyserviciobox'],
  ['IBDP Spanish: Vocabulario', 'vocabulariobox'],
  ['IBDP Spanish: Consejo de examen', 'consejodeexamenbox'],
  ['IBDP Spanish: Actividad', 'actividadbox'],
  ['IBDP Spanish: Concepto', 'conceptobox'],
  ['IBDP Spanish: Ampliación para el Nivel Superior', 'ampliacionparaelnivelsuperiorbox'],
  ['IBDP French: Esprit international', 'espritinternationalbox'],
  ['IBDP French: Conseil grammaire', 'conseilgrammairebox'],
  ['IBDP French: Extrait', 'extraitbox'],
  ['IBDP French: Théorie de la connaissance', 'theoriedelaconnaissancebox'],
  ['IBDP French: Créativité, activité, service', 'creativiteactiviteservicebox'],
  ['IBDP French: Vocabulaire', 'vocabulairebox'],
  ['IBDP French: Conseil examen', 'conseilexamenbox'],
  ['IBDP French: Activité', 'activitebox'],
  ['IBDP French: Concept', 'frenchconceptbox'],
  ['IBDP French: Approfondissement pour Niveau Supérieur', 'approfondissementbox'],
  ['IGCSE: Extended', 'extendedcontentbox'],
  ['IGCSE: Field work opportunity', 'fieldworkopportunitybox'],
  ['IGCSE: Map skills', 'mapskillsbox'],
  ['IGCSE: Practical', 'practical'],
  ['IGCSE: Study skills', 'studyskills'],
  ['[Deprecated] IGCSE: Learning outcomes', 'learningoutcomesbox'],
  ['IGCSE: Learning intentions', 'learningintentionsbox'],
  ['IGCSE: Key terms', 'keytermsbox'],
  ['IGCSE: Practical skills', 'practicalskillsbox'],
  ['IGCSE: Practice question', 'practicequestionbox'],
  ['GCSE: Skills', 'skills'],
  ['NGSS: Your context', 'yourcontextbox'],
  ['NGSS: Science and Engineering Practice ', 'scienceandengineeringpracticebox'],
  ['NGSS: Crosscutting Concept', 'crosscuttingconceptbox'],
];
DROPDOWN_OPTIONS.sort();

export const HEADING_LEVELS = [
  ['None (span)', 'span'],
  ['h2', 'heading2'],
  ['h3', 'heading3'],
  ['h4', 'heading4'],
];
