<template>
  <multiple-choice-question
    :question="question"
    :choices="choices"
    :choice-state="choiceState"
    :multi-select="isMultiSelect"
    :disabled="disabled"
    :type="disabled ? 'solution' : 'normal'"
    @input="handleUserResponse"
  />
</template>

<script>
import { xor } from 'lodash';

import MultipleChoiceQuestion from 'sharedApp/components/multiple-choice-question/multiple-choice-question.vue';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';

export default {
  name: 'ActivityMcq',
  components: {
    MultipleChoiceQuestion,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    /*
      user_response?: number,
    */
    context: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['response'],
  computed: {
    questionContext() {
      return this.question.question_context || this.question.context;
    },
    choiceState() {
      let choiceState = {};
      if (this.context?.user_response_correctness && this.context?.user_response) {
        choiceState = Object.fromEntries(
          this.context.user_response.map(responseId => [
            responseId,
            { selected: true, correct: this.context.user_response_correctness[responseId] },
          ]),
        );
      } else if (this.context?.user_response) {
        choiceState = Object.fromEntries(
          this.context.user_response.map(responseId => [responseId, { selected: true }]),
        );
      }
      return choiceState;
    },
    isMultiSelect() {
      return this.questionContext.is_multi_select;
    },
    choices() {
      return this.questionContext.choices;
    },
  },
  methods: {
    handleUserResponse(response) {
      let newResponse;
      if (this.isMultiSelect) {
        newResponse = xor([response.answer], this.context?.user_response);
      } else {
        newResponse = [response.answer];
      }
      this.$emit('response', {
        user_response: newResponse,
        response_type: RESPONSE_TYPES.MCR,
      });
    },
  },
};
</script>
