import { ButtonView, ViewCollection } from '@ckeditor/ckeditor5-ui';
import { Locale } from '@ckeditor/ckeditor5-utils';

import KogForm from '../utils/kogform.js';

export const SPACE_VARIATIONS = [
  ['No-break space', '\u00a0'],
  ['Narrow no-break space', '\u202f'],
  ['Hair space', '\u200a'],
  ['Thin space', '\u2009'],
  ['Figure space', '\u2007'],
] as const;

export default class FormView extends KogForm {
  declare childViews: ViewCollection;

  constructor(locale: Locale) {
    super(locale);

    const buttons = SPACE_VARIATIONS.map(([label, unicode]) => {
      const button = new ButtonView(this.locale);
      button.set({
        label,
        withText: true,
      });
      button.on('execute', () => {
        this.fire('execute', { unicode });
      });
      return button;
    });

    this.childViews = this.createCollection([...buttons]);
    const focusables = new ViewCollection([...buttons]);

    this.setup('spacing', focusables);
  }

  focus() {
    this.focusCycler?.focusFirst();
  }
}
