<template>
  <kog-modal :close-modal="closeModal">
    <template #modalTitle>
      <div class="flexContainer flexContainer-alignCenter">
        <kog-avatar
          :aria-label="`Student avatar for {{ student.name }}`"
          class="margin-right-s"
          :name="student.name"
          size="l"
          :src="student.avatar"
        />
        <h2 class="heading-m">{{ student.name }}</h2>
      </div>
    </template>
    <template #modalBody>
      <div class="ReadingAssignmentDetailsModal-topContainer margin-bottom-xl">
        <div class="flexContainer flexContainer-spaceBetween">
          <h4>Completed sections</h4>
          <h4> {{ totalCompleted }}/{{ studentAggregateProgress.totalCount }} </h4>
        </div>
        <kog-progress-bar
          :progress="studentCompletionPercent"
          size="s"
          class="ReadingAssignmentDetailsTab-progressBar"
        />
      </div>

      <reading-assignment-student-progress-table
        :assignment="assignment"
        :student-section-progress="studentSectionProgress"
      />
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-button
          label="Close"
          button-style="primary"
          @click.prevent="closeModal"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

import ReadingAssignmentStudentProgressTable from './table/reading-assignment-student-progress-table.vue';

export default {
  name: 'ReadingAssignmentDetailsModal',
  components: {
    KogAvatar,
    KogButton,
    KogModal,
    KogProgressBar,
    ReadingAssignmentStudentProgressTable,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    studentAggregateProgress: {
      type: Object,
      required: true,
    },
    studentSectionProgress: {
      type: Object,
      required: true,
    },
    assignment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalCompleted() {
      const { completedInTime, completedLate } = this.studentAggregateProgress;
      return completedInTime + completedLate;
    },
    studentCompletionPercent() {
      const { totalCount } = this.studentAggregateProgress;
      return (this.totalCompleted * 100) / totalCount;
    },
  },
};
</script>

<style scoped>
.ReadingAssignmentDetailsModal-topContainer {
  width: 60%;
}
</style>
