<template>
  <kog-modal title="Review and schedule">
    <template #modalBody>
      <div
        v-if="!isBlockedContent"
        class="ScheduleModal-dateInputContainer"
      >
        <div class="flexContainer flexContainer-alignCenter">
          <strong class="margin-right-xxs"> Send at </strong>
          <div class="flexChild-canGrow">
            <div class="input-group cursorPointer">
              <div
                class="input-group-addon"
                data-toggle
              >
                <i class="far fa-clock" />
              </div>
              <flat-pickr
                id="deadline"
                v-model="sendDate"
                :config="datePickerConfig"
                class="ScheduleModal-dateInput specificity-hack"
              />
            </div>
            <div
              v-if="sendDateError"
              class="kogPlatformRedDarken20"
            >
              The scheduled time must be in the future
            </div>
          </div>
        </div>
      </div>
      <assignment-review-box
        :assignment-data="assignmentData"
        :student-list-by-class-id="studentListByClassId"
        :has-errors="hasErrors"
        :sending="sending"
        :is-saving="isSaving"
        :update-failed="updateFailed"
        :has-hidden-sections="hasHiddenSections"
      />
    </template>

    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-button
          :disabled="sending"
          label="Cancel"
          @click="closeModal"
        />
        <kog-button
          v-if="!hasErrors"
          id="schedule-assignment-button"
          :disabled="isBlockedContent"
          button-style="accent"
          label="Schedule"
          @click="scheduleAssignment()"
        />
        <kog-button
          v-if="hasErrors"
          id="schedule-assignment-button"
          :disabled="sending"
          button-style="accent"
          label="Try again"
          @click="scheduleAssignment()"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import {
  getDateAsDateObject,
  getDateInNearest5Minutes,
  getDateTimeStringsPair,
  getDateXMinutesLess,
} from 'sharedApp/utils/time-utils.js';
import BottomInfoPlugin from 'teachApp/utils/flatPickrPlugins/bottom-info-plugin.js';
import ManualTimeInputPlugin from 'teachApp/utils/flatPickrPlugins/manual-time-input-plugin.js';

import AssignmentReviewBox from './assignment-review-box.vue';

export default {
  name: 'ScheduleModal',
  components: {
    KogButton,
    KogModal,
    AssignmentReviewBox,
    flatPickr,
  },
  props: {
    assignmentData: {
      type: Object,
      required: true,
    },
    deadlineDate: {
      type: Date,
      required: true,
    },
    studentListByClassId: {
      type: Object,
      required: true,
    },
    scheduleAssignmentHandler: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    hasHiddenSections: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Function,
      required: true,
    },
    updateFailed: {
      type: Function,
      required: true,
    },
  },
  data() {
    const next5minutes = getDateInNearest5Minutes(new Date());
    return {
      sending: false,
      hasErrors: false,
      sendDate: next5minutes,
      sendDateError: false,
      onboardingTaskCompletedMessage: '',
      datePickerConfig: {
        altInput: true,
        altFormat: 'Y-m-d H:i',
        dateFormat: 'Z',
        enableTime: true,
        allowInput: false,
        time_24hr: true,
        defaultDate: next5minutes,
        minDate: next5minutes,
        maxDate: getDateXMinutesLess(5, getDateInNearest5Minutes(this.deadlineDate)),
        wrap: true,
        minuteIncrement: 5,
        plugins: [new BottomInfoPlugin(this.getDeadlineLabel), new ManualTimeInputPlugin()],
      },
    };
  },
  computed: {
    successToasterCopy() {
      if (this.onboardingTaskCompletedMessage) {
        return this.onboardingTaskCompletedMessage;
      }
      const { date, time } = getDateTimeStringsPair(this.sendDateObject);
      return `This assignment is scheduled for ${date} at ${time}`;
    },
    sendDateObject() {
      return getDateAsDateObject(this.sendDate);
    },
    isBlockedContent() {
      return this.sending || this.updateFailed() || this.isSaving();
    },
    ...mapState({
      subjectClassDetails: state => state.schoolstaffSubjectclassModule.subjectClass,
    }),
  },
  watch: {
    sendDate() {
      this.validateSendDate();
    },
  },
  methods: {
    async scheduleAssignment() {
      if (!this.validateSendDate()) {
        return;
      }
      this.hasErrors = false;
      this.sending = true;

      try {
        await this.scheduleAssignmentHandler(
          this.sendDateObject,
          this.subjectClassDetails.google_classes,
        );
      } catch (error) {
        this.hasErrors = true;
        this.sending = false;
        console.error(error); // eslint-disable-line no-console
        return;
      }
      this.sending = false;
      this.closeModal();
      this.$toast.showSuccess(this.successToasterCopy);
    },
    getDeadlineLabel() {
      const { date, time } = getDateTimeStringsPair(this.deadlineDate);
      return `Deadline: ${date} at ${time}`;
    },
    validateSendDate() {
      const now = new Date();
      const isValid = now < this.sendDateObject;
      if (!isValid) {
        this.sendDateError = true;
      } else {
        this.sendDateError = false;
      }
      return isValid;
    },
  },
};
</script>

<style scoped>
.ScheduleModal-dateInputContainer {
  width: 450px;
  margin-right: auto;
  margin-bottom: 1.5em;
  margin-left: auto;
}
</style>

<style>
.KogDatetimePicker-bottomInfo {
  margin: 0.5em auto 0.5em 0.5em;
  text-align: left;
}

.ScheduleModal-dateInput.specificity-hack {
  background-color: var(--kogPlatformWhite);
}
</style>
