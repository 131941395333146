import CsvBlobWriter from 'learning/common/libs/csv-export-utils.js';

export const NEUTRAL_COLOR = 'kogPlatformGray096';

export class QuestionProgressScale {
  constructor(scale) {
    this.scale = scale;
  }

  getProgressColor(progress) {
    const isProgressValid = progress >= 0 && progress <= 1;
    if (!isProgressValid) {
      return NEUTRAL_COLOR;
    }
    const roundedProgress = Math.round(progress * 100);
    const interval = this.scale.intervals.find(
      i => i.min <= roundedProgress && i.max >= roundedProgress,
    );
    return interval.color;
  }
}

export function buildInsightsCsvHeader(subjectnodes = []) {
  const header = subjectnodes.map(
    node => `"${node.formatted_number_including_ancestors} ${node.name}"`,
  );
  return header;
}

export function buildReflectionQuestionInsightsCsvHeader(subjectnodes = []) {
  const reflectionQuestionLeaves = subjectnodes.flatMap(node => node.reflectionQuestionLeaves);
  const header = reflectionQuestionLeaves.map(leaf => leaf.formatted_number_including_ancestors);
  return header;
}

export function buildQuestionInsightsCsvRow(student, subjectnodes, studentProgress) {
  const row = [`${student.first_name} ${student.last_name}`];
  subjectnodes.forEach(node => {
    if (!studentProgress) {
      row.push('-');
      return;
    }

    const nodeProgress = studentProgress[node.id];
    if (nodeProgress.total === 0) {
      row.push('-');
      return;
    }

    row.push(`\t${nodeProgress.correct} / ${nodeProgress.total}`);
  });

  return row;
}

export function buildTextbookInsightsRow(student, subjectnodes, allStudentsProgress) {
  const row = [`${student.first_name} ${student.last_name}`];
  subjectnodes.forEach(node => {
    const nodeProgressForStudent = allStudentsProgress[node.id][student.user_id];
    const isLeafNode = node.children.length === 0;
    if (isLeafNode) {
      row.push(nodeProgressForStudent.completed === 1 ? 'Completed' : 'Not completed');
    } else {
      row.push(`\t${nodeProgressForStudent.completed} / ${nodeProgressForStudent.total}`);
    }
  });

  return row;
}

export function buildReflectionQuestionInsightsRow(student, subjectnodes, allStudentsAnswers) {
  const studentName = `${student.first_name} ${student.last_name}`;
  const studentAnswers = allStudentsAnswers[student.user_id];
  const flaggedAnswers = Object.keys(studentAnswers)
    .filter(answer => studentAnswers[answer].is_flagged_by_teacher)
    .map(parseInt);

  const reflectionQuestionLeaves = subjectnodes.flatMap(node => node.reflectionQuestionLeaves);
  const row = reflectionQuestionLeaves.map(leaf => {
    if (!(leaf.id in studentAnswers)) {
      return 'Not submitted';
    }
    if (flaggedAnswers.includes(leaf.id)) {
      return 'Flagged';
    }
    return 'Submitted';
  });
  return [studentName, ...row];
}

export function getInsightsCsvWriter(
  subjectnodes,
  metaData = [],
  headerBuilder = buildInsightsCsvHeader,
) {
  const csvWriter = new CsvBlobWriter();

  metaData.forEach(meta => csvWriter.addMetaData(meta));

  const headers = ['', ...headerBuilder(subjectnodes)];
  csvWriter.setHeaders(headers);

  return csvWriter;
}

export function getQuestionInsightsCsvWriter(
  students,
  subjectnodes,
  allStudentsProgress,
  metaData,
) {
  const csvWriter = getInsightsCsvWriter(subjectnodes, metaData);

  students.forEach(student => {
    const studentProgress = allStudentsProgress[student.user_id];
    const row = buildQuestionInsightsCsvRow(student, subjectnodes, studentProgress);
    csvWriter.addRow(row);
  });

  return csvWriter;
}

export function getTextbookInsightsCsvWriter(
  students,
  subjectnodes,
  allStudentsProgress,
  metaData,
) {
  const csvWriter = getInsightsCsvWriter(subjectnodes, metaData);

  students.forEach(student => {
    const row = buildTextbookInsightsRow(student, subjectnodes, allStudentsProgress);
    csvWriter.addRow(row);
  });

  return csvWriter;
}

export function getReflectionQuestionInsightsCsvWriter(
  students,
  subjectnodes,
  allStudentsAnswers,
  metaData,
) {
  const csvWriter = getInsightsCsvWriter(
    subjectnodes,
    metaData,
    buildReflectionQuestionInsightsCsvHeader,
  );

  students.forEach(student => {
    const row = buildReflectionQuestionInsightsRow(student, subjectnodes, allStudentsAnswers);
    csvWriter.addRow(row);
  });

  return csvWriter;
}

export function getQuestionInsightsCsvBlob(students, subjectnodes, allStudentsProgress, metaData) {
  const writer = getQuestionInsightsCsvWriter(
    students,
    subjectnodes,
    allStudentsProgress,
    metaData,
  );
  return writer.getBlob();
}

export function getTextbookInsightsCsvBlob(students, subjectnodes, allStudentsProgress, metaData) {
  const writer = getTextbookInsightsCsvWriter(
    students,
    subjectnodes,
    allStudentsProgress,
    metaData,
  );
  return writer.getBlob();
}

export function getReflectionQuestionInsightsCsvBlob(
  students,
  subjectnodes,
  allStudentsAnswers,
  metaData,
) {
  const writer = getReflectionQuestionInsightsCsvWriter(
    students,
    subjectnodes,
    allStudentsAnswers,
    metaData,
  );
  return writer.getBlob();
}
