import { ref } from 'vue';

import type { AssignmentListParams, AssignmentListResponse } from '@apis/assignment-helpers.ts';
import type { AssignmentDetails } from '@apis/generated/models.ts';

import { getAssignmentList } from '@apis/assignment-helpers.ts';

export type AssignmentMetadata = {
  count: number;
  totalPages: number;
  pageSize: number;
  currentPage: number;
};

const defaultMetadata: AssignmentMetadata = {
  count: 0,
  totalPages: 0,
  pageSize: 0,
  currentPage: 0,
};

function getMetadata(data: AssignmentListResponse) {
  const totalPages = Math.ceil(data.count / data.page_size);
  return {
    count: data.count,
    totalPages,
    pageSize: data.page_size,
    currentPage: data.page,
  };
}

export default function useAssignments() {
  const currentAssignments = ref<AssignmentDetails[]>([]);
  const currentAssignmentsMetadata = ref<AssignmentMetadata>(defaultMetadata);
  const pastAssignments = ref<AssignmentDetails[]>([]);
  const pastAssignmentsMetadata = ref<AssignmentMetadata>(defaultMetadata);
  const scheduledAssignments = ref<AssignmentDetails[]>([]);
  const scheduledAssignmentsMetadata = ref<AssignmentMetadata>(defaultMetadata);
  const sentAssignments = ref<AssignmentDetails[]>([]);
  const sentAssignmentsMetadata = ref<AssignmentMetadata>(defaultMetadata);
  const draftAssignments = ref<AssignmentDetails[]>([]);
  const draftAssignmentsMetadata = ref<AssignmentMetadata>(defaultMetadata);
  const archivedAssignments = ref<AssignmentDetails[]>([]);
  const archivedAssignmentsMetadata = ref<AssignmentMetadata>(defaultMetadata);

  async function getCurrentAssignments(payload: Partial<AssignmentListParams>) {
    const data = await getAssignmentList({ ...payload, filter: 'current' });
    const assignments = data.results;
    currentAssignments.value = assignments;
    currentAssignmentsMetadata.value = getMetadata(data);
  }

  async function getPastAssignments(payload: Partial<AssignmentListParams>) {
    const data = await getAssignmentList({ ...payload, filter: 'past' });
    const assignments = data.results;
    pastAssignments.value = assignments;
    pastAssignmentsMetadata.value = getMetadata(data);
  }

  async function getScheduledAssignments(payload: Partial<AssignmentListParams>) {
    const data = await getAssignmentList({ ...payload, filter: 'scheduled' });
    const assignments = data.results;
    scheduledAssignments.value = assignments;
    scheduledAssignmentsMetadata.value = getMetadata(data);
  }

  async function getSentAssignments(payload: Partial<AssignmentListParams>) {
    const data = await getAssignmentList({ ...payload, filter: 'sent' });
    const assignments = data.results;
    sentAssignments.value = assignments;
    sentAssignmentsMetadata.value = getMetadata(data);
  }

  async function getDraftAssignments(payload: Partial<AssignmentListParams>) {
    const data = await getAssignmentList({ ...payload, filter: 'draft' });
    const assignments = data.results;
    draftAssignments.value = assignments;
    draftAssignmentsMetadata.value = getMetadata(data);
  }

  async function getArchivedAssignments(payload: Partial<AssignmentListParams>) {
    const data = await getAssignmentList({ ...payload, filter: 'archived' });
    const assignments = data.results;
    archivedAssignments.value = assignments;
    archivedAssignmentsMetadata.value = getMetadata(data);
  }

  return {
    currentAssignments,
    currentAssignmentsMetadata,
    pastAssignments,
    pastAssignmentsMetadata,
    scheduledAssignments,
    scheduledAssignmentsMetadata,
    sentAssignments,
    sentAssignmentsMetadata,
    draftAssignments,
    draftAssignmentsMetadata,
    archivedAssignments,
    archivedAssignmentsMetadata,
    getCurrentAssignments,
    getPastAssignments,
    getScheduledAssignments,
    getSentAssignments,
    getDraftAssignments,
    getArchivedAssignments,
  };
}
