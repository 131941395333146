<template>
  <div class="RequestTrialButton">
    <kog-button
      :label="label"
      size="small"
      @click="openModal()"
    />
  </div>
</template>

<script>
import RequestTrialModal from 'learning/common/components/start-trial/request-trial-modal.vue';
import { ALL_DEMO_TYPES } from 'learning/common/constants/demo-type-constants.js';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import helpHero from 'sharedApp/libs/helphero.js';
import isMobile from 'sharedApp/utils/detect-mobile.js';

export const OPEN_START_TRIAL_MIXPANEL_EVENT = 'Demo School - Open Start Trial';
const RESTART_MOBILE_TOUR_ID = 'M6o3G4FBpS';

export default {
  name: 'RequestTrialButton',
  components: {
    KogButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    demoType: {
      type: String,
      required: true,
      validator: demoType => ALL_DEMO_TYPES.includes(demoType),
    },
  },
  methods: {
    openModal() {
      this.$mixpanel.trackEventViaBackend(OPEN_START_TRIAL_MIXPANEL_EVENT, {});
      this.$modal(RequestTrialModal, {
        onModalClose: this.goBackToTour,
        user: this.user,
        formType: this.demoType,
        title: this.label,
      });
    },
    goBackToTour() {
      if (isMobile()) {
        helpHero.startTour(RESTART_MOBILE_TOUR_ID);
      }
    },
  },
};
</script>

<style>
.RequestTrialButton .KogButton {
  background: var(--kog-colors-green-500);
}

.RequestTrialButton .KogButton:hover:not([aria-disabled='true']) {
  background: var(--kog-colors-green-600);
}
</style>
