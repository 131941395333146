import { API_URLS } from 'publishingApp/store/endpoints.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const fetchSubjects = async (showArchived = false) => {
  const response = await axios.get(API_URLS.SUBJECTS_URL, {
    params: {
      is_archived: showArchived,
    },
  });
  return response.data;
};

const fetchSubjectDetails = async subjectId => {
  const response = await axios.get(API_URLS.getSubjectDetailsUrl(subjectId));
  return response.data;
};

const fetchSubjectDetailsWithTree = async subjectId => {
  const response = await axios.get(API_URLS.getSubjectUrl(subjectId));
  return response.data;
};

const fetchSubjectSections = async subjectId => {
  const response = await axios.get(API_URLS.getSubjectSectionsUrl(subjectId));
  return response.data;
};

const fetchSubjectGroupOptions = () => axios.get(API_URLS.SUBJECT_GROUPS_URL);

const fetchSubject = async subjectId => {
  const response = await axios.get(API_URLS.getSubjectUrl(subjectId), {
    params: {
      include_question_overview: true,
    },
  });
  return response.data;
};

const fetchSubjectQuestionSummary = async subjectId => {
  const response = await axios.get(API_URLS.getSubjectQuestionSummaryUrl(subjectId));
  return response.data;
};

const fetchSubjectQuestionStatistics = async subjectId => {
  const response = await axios.get(API_URLS.getSubjectQuestionStatisticsUrl(subjectId));
  return response.data;
};

const updateSubjectDetails = async (subjectId, data) => {
  const url = API_URLS.getSubjectDetailsUrl(subjectId);
  const response = await axios.patch(url, data);
  return response.data;
};

const uploadSubtopicThumbnail = (subtopicId, fileType) => {
  const url = API_URLS.getUploadSubtopicThumbnailS3SignatureUrl(subtopicId);
  const params = {
    s3_object_type: fileType,
  };
  return axios.get(url, { params });
};

export default {
  fetchSubjectDetails,
  fetchSubjectDetailsWithTree,
  fetchSubjectSections,
  fetchSubjectGroupOptions,
  fetchSubject,
  fetchSubjects,
  fetchSubjectQuestionSummary,
  fetchSubjectQuestionStatistics,
  updateSubjectDetails,
  uploadSubtopicThumbnail,
};
