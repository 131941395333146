<template>
  <div>
    <alignment-list
      v-if="isShowingAlignments"
      class="margin-bottom-s QuestionPreview-AlignmentList"
      :show-header="false"
      :syllabus-nodes="question.syllabus_nodes"
    />
    <template v-if="isMcq">
      <div class="QuestionPreview-questionText flexContainer">
        <span
          v-if="showOrderNumber"
          class="font-weight-bold font-satoshi margin-right-xs"
        >
          {{ order }}.
        </span>
        <question-text
          v-if="isShowingQuestionText"
          :question="question"
        />
      </div>
      <template v-if="isShowingUserResponse">
        <mcq-choice-item
          v-for="(choice, index) in mcqShuffleSortedAnswerSet"
          :key="choice.id"
          class="margin-bottom-m"
          :choice-id="choice.id"
          :is-correct="isMcqChoiceSelected(choice.id) ? isMcqChoiceCorrect(choice.id) : false"
          :is-incorrect="isMcqChoiceSelected(choice.id) ? isMcqChoiceIncorrect(choice.id) : false"
          :is-selected="isMcqChoiceSelected(choice.id)"
          :choice-html="choice.answer_html"
          :option-index="index"
          :disabled="true"
          type="normal"
        />
      </template>
      <template v-else>
        <mcq-choice-item
          v-for="(choice, index) in mcqShuffleSortedAnswerSet"
          v-show="isMcqChoiceShown(choice)"
          :key="choice.id"
          class="margin-bottom-m"
          :choice-id="choice.id"
          :is-solution="choice.is_correct"
          :choice-html="choice.answer_html"
          :option-index="index"
          :disabled="true"
          type="solution"
        />
      </template>
      <div
        v-if="isShowingExplanation && mcqExplanation"
        class="QuestionPreview-explanation margin-top-l"
      >
        <span class="heading-xs font-satoshi"> Explanation </span>
        <content-container
          v-kog-mathjax
          :content="mcqExplanation"
        />
      </div>
    </template>
    <template v-if="isStq">
      <div class="QuestionPreview-questionText flexContainer">
        <span
          v-if="showOrderNumber"
          class="font-weight-bold font-satoshi margin-right-xs"
        >
          {{ order }}.
        </span>
        <short-text-question
          :question="question"
          :display-state="stqDisplayStates.QUESTION"
          :is-showing-question-text="isShowingQuestionText"
          disabled
        />
      </div>
      <short-text-question
        v-if="isShowingUserResponse"
        :question="question"
        :initial-answer="stqStudentAnswer"
        disabled
        :is-correct="isStqCorrect"
        :is-incorrect="isStqIncorrect"
        :display-state="stqDisplayStates.INPUT"
      />
      <short-text-question
        v-else
        :question="question"
        :initial-answer="stqCorrectAnswers[0]"
        disabled
        is-correct
        :display-state="stqDisplayStates.INPUT"
      />
      <template v-if="isShowingExplanation">
        <div class="QuestionPreview-answers font-satoshi margin-top-l">
          <span class="heading-xs"> Accepted answers </span>
          <div>
            {{ stqCorrectAnswers.join(', ') }}
          </div>
        </div>
        <div
          v-if="stqSecondaryAcceptedAnswers.length > 0"
          class="QuestionPreview-explanation font-satoshi flexContainer flexContainer-column margin-top-m"
        >
          <span class="heading-xs"> Also accepted </span>
          <span>
            {{ stqSecondaryAcceptedAnswers.join(', ') }}
          </span>
        </div>
        <div
          v-if="stqExplanation"
          class="QuestionPreview-explanation margin-top-m"
        >
          <span class="heading-xs font-satoshi"> Explanation </span>
          <content-container
            v-kog-mathjax
            :content="stqExplanation"
          />
        </div>
      </template>
    </template>
    <template v-if="isFbq">
      <div class="QuestionPreview-questionText flexContainer">
        <span
          v-if="showOrderNumber"
          class="font-weight-bold font-satoshi margin-right-xs"
        >
          {{ order }}.
        </span>
        <fill-in-the-blanks-question
          v-if="isShowingUserResponse"
          :question="question"
          :user-answers="fbqStudentAnswers"
          disable-user-input
          :display-input-index="true"
          :is-showing-answer-correctness="true"
          :answers-with-correctness="fbqAnswersWithCorrectness"
        />
        <fill-in-the-blanks-question
          v-else
          :question="{ ...question, answers: fbqCorrectAnswers }"
          disable-user-input
          :display-input-index="true"
          :show-with-answers="true"
        />
      </div>
      <template v-if="isShowingExplanation">
        <div class="QuestionPreview-answers font-satoshi margin-top-l">
          <span class="heading-xs"> Accepted answers </span>
          <div>
            <div
              v-for="(answer, index) in fbqCorrectAnswers"
              :key="answer.html_element_uid"
            >
              #{{ index + 1 }}: {{ answer.answers.join(', ') }}
            </div>
          </div>
        </div>
        <div
          v-if="fbqExplanation"
          class="QuestionPreview-explanation margin-top-m"
        >
          <span class="heading-xs font-satoshi"> Explanation </span>
          <content-container
            v-kog-mathjax
            :content="fbqExplanation"
          />
        </div>
      </template>
    </template>
    <template v-if="isLtq">
      <long-text-question
        :question="question"
        :disabled="true"
        :answer="isShowingUserResponse ? ltqStudentAnswer : ''"
      />
    </template>
    <div
      v-if="isShowingKeyAndRubricContainer || shouldShowCorrectAnswersKey"
      class="QuestionPreview-keyAndRubricContainer"
    >
      <div
        v-if="(isShowingKey && questionKey) || shouldShowCorrectAnswersKey"
        class="QuestionPreview-keyContainer"
      >
        <div class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter">
          <div class="heading-xxs font-satoshi"> Key </div>
          <div
            v-if="isShowingKeyToggle || shouldShowCorrectAnswersKey"
            class="flexContainer flexContainer-alignCenter"
          >
            <span aria-hidden="true"> Show key </span>
            <kog-toggle-button
              class="QuestionPreview-keyToggle margin-left-xs"
              button-role="expander"
              :is-pressed="isKeyExpanded"
              aria-label="Show key"
              :aria-controls="`question-key-${question.id}`"
              @toggled="onToggleKey"
            />
          </div>
        </div>
        <vertical-expand>
          <div
            v-if="isKeyExpanded"
            :id="`question-key-${question.id}`"
          >
            <template v-if="shouldShowCorrectAnswersKey">
              <template v-if="isMcq">
                <div class="margin-top-m">
                  <mcq-choice-item
                    v-for="(choice, index) in mcqShuffleSortedAnswerSet"
                    :key="choice.id"
                    class="margin-bottom-m"
                    :choice-id="choice.id"
                    :is-solution="choice.is_correct"
                    :choice-html="choice.answer_html"
                    :option-index="index"
                    :disabled="true"
                    type="solution"
                  />
                </div>
              </template>
              <template v-if="isStq">
                <short-text-question
                  class="margin-top-m"
                  :question="question"
                  :initial-answer="stqCorrectAnswers[0]"
                  disabled
                  is-correct
                  :display-state="stqDisplayStates.INPUT"
                  :type="stqSolutionType"
                />
              </template>
              <template v-if="isFbq">
                <fill-in-the-blanks-question
                  :question="{ ...question, answers: fbqCorrectAnswers }"
                  disable-user-input
                  :display-input-index="true"
                  :show-with-answers="true"
                />
              </template>
            </template>
            <content-container
              v-else
              v-kog-mathjax
              class="QuestionPreview-key margin-top-s"
              :content="questionKey"
            />
          </div>
        </vertical-expand>
      </div>
      <rubrics
        v-if="isShowingRubrics"
        :rubrics="rubrics"
        :is-interactive="isRubricsInteractive"
        :is-showing-toggle="isShowingRubricsToggle"
        class="QuestionPreview-rubrics"
        :selected-rubric-value="selectedRubricValue"
        @rubric-selected="$emit('rubric-selected', { ...$event, question })"
      />
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash';
import isNil from 'lodash/isNil.js';

import Rubrics from 'learning/study/components/rubrics/rubrics.vue';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogToggleButton from 'sharedApp/components/base/buttons/kog-toggle-button.vue';
import ContentContainer from 'sharedApp/components/content/content-container.vue';
import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import LongTextQuestion from 'sharedApp/components/long-text-question/long-text-question.vue';
import McqChoiceItem from 'sharedApp/components/multiple-choice-question/mcq-choice-item.vue';
import QuestionText from 'sharedApp/components/questions/question-text.vue';
import {
  STQ_DISPLAY_STATE,
  STQ_TYPE_OPTIONS,
} from 'sharedApp/components/short-text-question/short-text-question-utility.ts';
import ShortTextQuestion from 'sharedApp/components/short-text-question/short-text-question.vue';
import {
  isFBQ,
  isLTQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import AlignmentList from 'studyApp/components/alignments/alignments-list.vue';

export default {
  name: 'QuestionPreview',
  components: {
    ContentContainer,
    KogToggleButton,
    FillInTheBlanksQuestion,
    ShortTextQuestion,
    McqChoiceItem,
    LongTextQuestion,
    QuestionText,
    AlignmentList,
    Rubrics,
    VerticalExpand,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    order: {
      type: Number,
      default: 0,
    },
    userResponseData: {
      type: Object,
      default: null,
    },
    isShowingQuestionText: {
      type: Boolean,
      default: true,
    },
    isShowingUserResponse: {
      type: Boolean,
      default: false,
    },
    isShowingIncorrectMcqOptions: {
      type: Boolean,
      default: true,
    },
    isShowingExplanation: {
      type: Boolean,
      default: true,
    },
    isShowingKey: {
      type: Boolean,
      default: false,
    },
    isShowingCorrectAnswersInKey: {
      type: Boolean,
      default: false,
    },
    isShowingKeyToggle: {
      type: Boolean,
      default: false,
    },
    isShowingAlignments: {
      type: Boolean,
      default: false,
    },
    isShowingRubrics: {
      type: Boolean,
      default: false,
    },
    isShowingRubricsToggle: {
      type: Boolean,
      default: false,
    },
    isRubricsInteractive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['rubric-selected'],
  data() {
    return {
      stqDisplayStates: STQ_DISPLAY_STATE,
      isKeyExpanded: true,
    };
  },
  computed: {
    isMcq() {
      return isMCQ(this.question);
    },
    isStq() {
      return isSTQ(this.question);
    },
    isFbq() {
      return isFBQ(this.question);
    },
    isLtq() {
      return isLTQ(this.question);
    },
    isShowingKeyAndRubricContainer() {
      const isShowingKeyContainer = this.isShowingKey && this.questionKey;
      const isShowingRubricsContainer = this.isShowingRubrics && this.rubrics;
      return isShowingKeyContainer || isShowingRubricsContainer;
    },
    mcqSelectedChoices() {
      if (!this.isMcq || !this.userResponseData) {
        return [];
      }

      return this.userResponseData.user_response.map(responseId => responseId.toString());
    },
    mcqShuffleSortedAnswerSet() {
      const questionContext = this.question.question_context || this.question.context;

      if (this.question.answers_set) {
        return sortBy(this.question.answers_set, item =>
          questionContext.choices.findIndex(choice => choice.id === item.id),
        );
      }

      return questionContext.choices.map(choice => ({
        ...choice,
        is_correct: this.solution.includes(choice.id),
      }));
    },
    stqStudentAnswer() {
      return this.isStq && this.userResponseData?.user_response;
    },
    isStqCorrect() {
      return !!this.userResponseData?.user_response_correctness;
    },
    isStqIncorrect() {
      const isCorrect = this.userResponseData?.user_response_correctness;
      if (typeof isCorrect === 'boolean') {
        return !isCorrect;
      }

      const userResponse = this.userResponseData?.user_response;
      if (isNil(userResponse) || userResponse === '') {
        return true;
      }

      return false;
    },
    solution() {
      return this.question.answers_set || this.question.solution;
    },
    stqCorrectAnswers() {
      return this.solution.answer_list;
    },
    stqSecondaryAcceptedAnswers() {
      return this.solution.secondary_accepted_answers;
    },
    stqExplanation() {
      return this.question.explanation;
    },
    fbqExplanation() {
      return this.question.explanation;
    },
    mcqExplanation() {
      return this.question.explanation;
    },
    rubrics() {
      return this.question.rubric_items;
    },
    selectedRubricValue() {
      return this.userResponseData?.grading?.marks_draft;
    },
    fbqStudentAnswers() {
      return (this.isFbq && this.userResponseData?.user_response) || {};
    },
    fbqCorrectAnswers() {
      return this.solution;
    },
    fbqAnswersWithCorrectness() {
      if (!this.userResponseData) return {};

      const correctedStudentAnswers = {};
      this.solution.forEach(fbqBlankData => {
        const fbqBlankId = fbqBlankData.html_element_uid;
        const isBlankResponseCorrect =
          this.userResponseData.user_response_correctness?.[fbqBlankId] || false;
        correctedStudentAnswers[fbqBlankId] = { correct: isBlankResponseCorrect };
      });

      return correctedStudentAnswers;
    },
    ltqStudentAnswer() {
      return this.isLtq && this.userResponseData?.user_response;
    },
    showOrderNumber() {
      return this.order > 0;
    },
    questionKey() {
      if (this.isMcq) {
        return this.mcqExplanation;
      }
      if (this.isStq) {
        return this.stqExplanation;
      }
      if (this.isFbq) {
        return this.fbqExplanation;
      }
      if (this.isLtq) {
        return this.question.explanation;
      }

      return null;
    },
    shouldShowCorrectAnswersKey() {
      return this.isShowingCorrectAnswersInKey && !this.isLtq;
    },
    stqSolutionType() {
      return STQ_TYPE_OPTIONS.SOLUTION;
    },
  },
  methods: {
    isMcqChoiceSelected(choiceId) {
      return this.mcqSelectedChoices.includes(choiceId.toString());
    },
    isMcqChoiceCorrect(choiceId) {
      const choiceCorrectness = this.userResponseData?.user_response_correctness?.[choiceId];
      if (typeof choiceCorrectness === 'boolean') {
        return choiceCorrectness;
      }

      return null;
    },
    isMcqChoiceIncorrect(choiceId) {
      const choiceCorrectness = this.userResponseData?.user_response_correctness?.[choiceId];
      if (typeof choiceCorrectness === 'boolean') {
        return !choiceCorrectness;
      }

      return null;
    },
    isMcqChoiceShown(choice) {
      return this.isShowingIncorrectMcqOptions || choice.is_correct;
    },
    onToggleKey() {
      this.isKeyExpanded = !this.isKeyExpanded;
    },
  },
};
</script>

<style scoped>
.QuestionPreview-keyAndRubricContainer {
  margin-top: var(--space-l);
  padding: var(--space-m) var(--space-s);
  background: var(--kog-teacher-instructions-background);
  border-radius: 6px;
}

.QuestionPreview-questionText + .QuestionPreview-keyAndRubricContainer {
  margin-top: 0;
}

.QuestionPreview-AlignmentList {
  padding: var(--space-m);
  background-color: var(--kog-teacher-instructions-background);
  border-radius: 6px;
}

.QuestionPreview-explanation :deep(.content-editable),
.QuestionPreview-questionText :deep(.content-editable),
.QuestionPreview-keyContainer :deep(.content-editable),
.QuestionPreview-questionText,
.QuestionPreview-answers {
  font-family: var(--kog-satoshi);
  line-height: var(--space-l);
}

.QuestionPreview-explanation :deep(.content-editable),
.QuestionPreview-keyContainer :deep(.content-editable),
.QuestionPreview-answers {
  font-size: var(--kog-font-size-default);
}

.QuestionPreview-questionText,
.QuestionPreview-questionText :deep(.content-editable) {
  font-size: var(--kog-font-size-content);
}

.QuestionPreview-explanation :deep(.content-editable p),
.QuestionPreview-keyContainer :deep(.content-editable p),
.QuestionPreview-questionText :deep(.content-editable p) {
  line-height: var(--space-l);
}

.QuestionPreview-keyContainer :deep(.content-editable p:last-of-type) {
  margin-bottom: 0;
}
</style>
