<script setup>
import { computed, onMounted, ref } from 'vue';

import brazeModule from 'sharedApp/libs/braze.js';

const props = defineProps({
  placement: {
    type: String,
    required: true,
  },
});

const cardsCount = ref(0);
const areCardsExist = computed(() => cardsCount.value > 0);

onMounted(() => {
  cardsCount.value = brazeModule.showContentCards('#braze-feed', props.placement);
});
</script>

<template>
  <div class="BrazeCards">
    <div
      v-show="areCardsExist"
      id="braze-feed"
    />
    <slot
      v-if="!areCardsExist"
      name="empty-state"
    ></slot>
  </div>
</template>

<style scoped>
.BrazeCards :deep(.ab-feed) {
  transform: none !important;

  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;

  background-color: var(--kogTransparent) !important;
  border: none !important;
  box-shadow: none !important;

  transition: none !important;
}
.BrazeCards :deep(.ab-feed-buttons-wrapper) {
  display: none;
}
.BrazeCards :deep(.ab-feed-body) {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}
.BrazeCards :deep(.ab-card) {
  width: 100% !important;
  background-color: var(--kogTransparent) !important;
  border-radius: var(--space-xs) !important;
  box-shadow: none !important;
}
</style>
