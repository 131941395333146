<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="MultipleChoiceQuestion">
    <div
      v-if="shouldDisplayAll || shouldDisplayQuestionOnly"
      class="MultipleChoiceQuestion-questionText"
      :class="{
        'margin-bottom-xs': shouldDisplayAll,
      }"
    >
      <question-text :question="question" />
    </div>
    <template v-if="shouldDisplayAll || shouldDisplayChoicesOnly">
      <span
        v-if="multiSelect"
        class="font-satoshi"
      >
        Several answers can be correct
      </span>
      <fieldset
        role="listbox"
        :aria-multiselectable="multiSelect ? 'true' : null"
        :disabled="disabled ? 'true' : null"
        class="flexContainer flexContainer-column gap-m"
      >
        <mcq-choice-item
          v-for="(choice, index) in choices"
          :key="choice.id"
          :option-index="index"
          :disabled="disabled"
          :choice-id="choice.id"
          :is-solution="solutionIds.includes(choice.id)"
          :is-selected="isChoiceSelected(choice.id)"
          :is-correct="isAnswerCorrect(choice.id)"
          :is-incorrect="isAnswerIncorrect(choice.id)"
          :choice-html="choice.answer_html"
          :type="type"
          @select="setAnswer"
        >
          <template
            v-if="!!getChoiceSlot(choice.id)"
            #itemSlot
          >
            {{ getChoiceSlot(choice.id) }}
          </template>
        </mcq-choice-item>
      </fieldset>
    </template>
  </div>
</template>

<script>
import McqChoiceItem from 'sharedApp/components/multiple-choice-question/mcq-choice-item.vue';
import QuestionText from 'sharedApp/components/questions/question-text.vue';

import { MCQ_DISPLAY_STATE, MCQ_TYPE_OPTIONS } from './choice-state-helper.js';

export default {
  name: 'MultipleChoiceQuestion',
  components: {
    McqChoiceItem,
    QuestionText,
  },
  props: {
    /**
     *  Adds logic to emit event with selected status for item on click.
     *  Emits selected: false, when clicking on an already selected item.
     *  Component will by default emit back selected: true.
     */
    multiSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * Controls the visual state for each choice item
     * {
     *   [choiceId]: {
     *     selected?: boolean,
     *     correct?: boolean,
     *     itemSlot?: VNode,
         }
     * }
     */
    choiceState: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Renders the choices matching the ids as solutions
     */
    solutionIds: {
      type: Array,
      default: () => [],
    },
    /**
     * Disables the component and choices
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines the style which the choices are rendered with
     */
    type: {
      type: String,
      default: 'normal',
      validator: val => MCQ_TYPE_OPTIONS.includes(val),
    },
    /**
     * Allows control of what part of the component should be rendered
     */
    displayState: {
      type: String,
      default: 'all',
      validator: val => Object.values(MCQ_DISPLAY_STATE).includes(val),
    },
    /**
     * The question object, needs to contain:
     * {
     *  question_html: string,
     *  type: string
     * }
     */
    question: {
      type: Object,
      default: () => ({}),
    },
    /**
     * List of available choices objects
     * [{
     *  id: number,
     *  answer_html: string
     * }]
     */
    choices: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  computed: {
    shouldDisplayAll() {
      return this.displayState === MCQ_DISPLAY_STATE.ALL;
    },
    shouldDisplayQuestionOnly() {
      return this.displayState === MCQ_DISPLAY_STATE.QUESTION;
    },
    shouldDisplayChoicesOnly() {
      return this.displayState === MCQ_DISPLAY_STATE.CHOICES;
    },
  },
  methods: {
    setAnswer(choiceId) {
      if (this.multiSelect && this.isChoiceSelected(choiceId)) {
        this.$emit('input', {
          answer: choiceId,
          selected: false,
        });
      } else {
        this.$emit('input', {
          answer: choiceId,
          selected: true,
        });
      }
    },
    isChoiceSelected(choiceId) {
      return !!this.choiceState[choiceId.toString()]?.selected;
    },
    isAnswerCorrect(choiceId) {
      const matchingChoice = this.choiceState[choiceId.toString()];
      if (!matchingChoice) return null;

      if (matchingChoice?.correct !== null) {
        return matchingChoice.correct === true;
      }

      return null;
    },
    isAnswerIncorrect(choiceId) {
      const matchingChoice = this.choiceState[choiceId.toString()];
      if (!matchingChoice) return null;

      if (matchingChoice?.correct !== null) {
        return matchingChoice.correct === false;
      }

      return null;
    },
    getChoiceSlot(choiceId) {
      return this.choiceState[choiceId]?.itemSlot ?? null;
    },
  },
};
</script>
