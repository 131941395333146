<template>
  <component
    :is="tag"
    :class="[
      'ClassPickerItem',
      {
        'ClassPickerItem--active': isSelected,
        'ClassPickerItem-dropdownTrigger': isHeader && !isTransparent,
        'ClassPickerItem-dropdownTrigger--transparent': isTransparent,
      },
    ]"
  >
    <router-link
      :to="link"
      class="ClassPickerItem-container padd-right-s padd-left-s padd-top-xs padd-bottom-xs gap-col-s"
    >
      <div
        :class="[
          'ClassPickerItem-imgContainer',
          {
            'ClassPickerItem--withIcon': !logoUrl,
          },
        ]"
      >
        <i
          v-if="!logoUrl"
          class="far fa-home-lg-alt"
        />
        <image-box
          v-else
          :src="logoUrl"
          :w="32"
          :h="32"
          fit="crop"
          alt=""
        />
      </div>

      <span
        v-if="isHeader"
        class="ClassPickerItem-highlightedText line-clamp-2"
        >{{ name }}</span
      >
      <span v-else>{{ name }}</span>

      <div
        v-if="isHeader"
        class="ClassPickerItem-caretContainer"
      >
        <i class="fas fa-caret-down" />
      </div>
    </router-link>
  </component>
</template>

<script>
import ImageBox from 'sharedApp/components/images/image-box.vue';

export default {
  name: 'ClassPickerItem',
  components: {
    ImageBox,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    subjectClass: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    logoUrl: {
      type: String,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    link() {
      if (this.isHeader) {
        const { query } = this.$route;
        return {
          query,
        };
      }
      if (this.subjectClass) {
        return {
          name: 'subjectClassOverview',
          params: {
            classSlug: this.subjectClass.slug,
            cid: this.subjectClass.id,
            sid: this.subjectClass.subject_id,
          },
        };
      }
      return {
        name: 'studyDashboard',
      };
    },
  },
};
</script>

<style>
.ClassPickerItem {
  border-radius: 12px;
}

.ClassPickerItem:hover {
  background-color: var(--kog-list-item-hover-background);
}

.ClassPickerItem-dropdownTrigger,
.ClassPickerItem-dropdownTrigger--transparent {
  border-radius: 0;
}

.ClassPickerItem-dropdownTrigger:hover {
  background-color: var(--kog-navigation-background-default-hover);
}

.ClassPickerItem-dropdownTrigger--transparent:hover {
  background-color: var(--kog-navigation-background-translucent-hover) !important;
}

.ClassPickerItem--active {
  background-color: var(--kog-list-item-selected-background);
}

.ClassPickerItem--active:hover {
  background-color: var(--kog-list-item-selected-hover-background);
}

.ClassPickerItem .ClassPickerItem-container {
  display: grid;
  grid-template-columns: var(--space-xl) auto auto;
  align-items: center;
  color: var(--kog-text-default);
}

.ClassPickerItem-dropdownTrigger .ClassPickerItem-container {
  color: var(--kog-navigation-button-default);
}

.ClassPickerItem-dropdownTrigger--transparent .ClassPickerItem-container {
  color: var(--kog-colors-white);
}

.ClassPickerItem--active .ClassPickerItem-container,
.ClassPickerItem-highlightedText {
  font-weight: bold;
}

.ClassPickerItem .ClassPickerItem-container:hover {
  text-decoration: none;
}

.ClassPickerItem-imgContainer {
  overflow: hidden;

  width: var(--space-xl);
  height: var(--space-xl);

  font-size: 0;

  background-color: var(--kog-navigation-icon-background);
  border-radius: 4px;
}

.ClassPickerItem-dropdownTrigger .ClassPickerItem-imgContainer {
  background-color: var(--kog-navigation-background-default);
}

.ClassPickerItem--withIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.ClassPickerItem-imgContainer img {
  width: 100%;
  height: 100%;
}

.ClassPickerItem .ClassPickerItem-container span {
  text-align: left;
  word-break: normal !important;
}

.ClassPickerItem-caretContainer {
  display: flex;
  align-items: center;
}
</style>
