<template>
  <block-wrapper
    :content="content"
    :block-label="blockLabel"
    :available-actions="availableActions"
    :custom-style="{
      backgroundColor: selectedTemplate.color,
    }"
    @mark-removal="$event => $emit('mark-removal', $event)"
    @unmark-removal="$event => $emit('unmark-removal', $event)"
    @move-down="$event => $emit('move-down', $event)"
    @move-up="$event => $emit('move-up', $event)"
  >
    <kog-select
      placeholder-text="Select a content box template"
      label="Content box template"
      :is-label-hidden="true"
      :options="selectOptions"
      :selected-value="selectedValue"
      @change="value => updateContentField('template_id', value)"
    />
    <span
      v-if="selectedValue"
      class="flexContainer gap-col-s margin-top-m"
    >
      <span class="ContentBox-icon">
        <kog-icon
          :icon-class="selectedTemplate.icon"
          fa-style="regular"
        />
      </span>
      <span class="ContentBox-title">
        {{ selectedTemplate.title }}
      </span>
    </span>
    <span
      v-if="selectedValue && selectedTemplate.is_teacher_only"
      class="text-muted text-italic"
      >Only visible for teachers</span
    >
    <template v-if="selectedValue">
      <kog-editor-ck5
        :content="content.content_html"
        :content-box-templates="contentBoxTemplates"
        :config="cke5Options"
        aria-label="editor for content box block"
        class="ContentBox-editor"
        @update:content="value => updateContentField('content_html', value)"
      />
    </template>
  </block-wrapper>
</template>

<script>
import KogEditorCk5 from 'publishingApp/containers/section/components/kog-editor-ck5.vue';
import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';
import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

import BlockWrapper, { ITEM_ACTIONS } from '../../block-wrapper.vue';
import BlockMixin from '../mixins/block-mixin.js';

export default {
  name: 'ContentBoxBlock',
  components: {
    KogSelect,
    BlockWrapper,
    KogEditorCk5,
    KogIcon,
  },
  mixins: [BlockMixin],
  props: {
    contentBoxTemplates: {
      type: Object,
      default: () => {},
    },
    content: {
      type: Object,
      required: true,
    },
  },
  emits: ['mark-removal', 'unmark-removal', 'move-up', 'move-down'],
  data() {
    return {
      cke5Options: {
        exclude: ['showSolution', 'inlineQuestion', 'exerciseBox', 'glossaryLink'],
      },
      availableActions: [ITEM_ACTIONS.MARK_REMOVAL],
      // eslint-disable-next-line vue/no-unused-properties
      editorFields: ['content_html'],
    };
  },
  computed: {
    availableTemplates() {
      return this.activity.getBlockContext(BLOCK_TYPE.CONTENT_BOX)?.contentBoxTemplates ?? [];
    },
    selectOptions() {
      return this.availableTemplates.map(boxTemplate => ({
        value: boxTemplate.id,
        text: boxTemplate.name,
      }));
    },
    selectedValue() {
      return this.content?.template_id;
    },
    selectedTemplate() {
      if (!this.content.template_id) {
        return {};
      }

      return (
        this.availableTemplates.find(template => template.id === this.content?.template_id) ?? {
          color: '#fd6e89',
          icon: 'fa-xmark-large',
          title: `Invalid template (id=${this.content?.template_id})`,
        }
      );
    },
  },
};
</script>

<style scoped>
.ContentBox-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-xxl);
  height: var(--space-xxl);

  font-size: var(--kog-font-size-content);

  background-color: var(--kogPlatformWhite);
  border-radius: 50%;
}

.ContentBox-icon i {
  font-size: 18px;
}

.ContentBox-title {
  display: block;

  font-family: var(--kog-lato);
  font-size: 20px;
  font-weight: bold;
  line-height: var(--space-xxl);
  color: var(--kogPlatformGray018);
}

.ContentBox-editor {
  background-color: var(--kog-colors-white);
  border-radius: var(--space-xs);
}
</style>
