<template>
  <kog-textarea
    :value="feedbackItem.feedback"
    :label="teacherFullName"
    label-icon="comment"
    style-type="prominent"
    :top-right-helper-text="feedbackSentAt"
    :disabled="true"
    :fit-to-content="true"
  />
</template>

<script>
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'ReflectionQuestionFeedback',
  components: {
    KogTextarea,
  },
  props: {
    feedbackItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    teacherFullName() {
      return `${this.feedbackItem.teacher_user.first_name} ${this.feedbackItem.teacher_user.last_name}`;
    },
    feedbackSentAt() {
      if (!this.feedbackItem?.published_at) {
        return '';
      }
      const formattedSentAt = dateTimeFormatter(new Date(this.feedbackItem.published_at));
      return `Sent ${formattedSentAt}`;
    },
  },
};
</script>
