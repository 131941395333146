<template>
  <multiple-choice-question
    v-bind="attrs"
    :question="question"
    :multi-select="multiSelect"
    @input="handleUserResponse"
  />
</template>

<script setup lang="ts">
import { toRefs, useAttrs } from 'vue';
import { xor } from 'lodash';

import type { Emit, MCQEmitResponse, Props } from './mcq.types';

import MultipleChoiceQuestion from 'sharedApp/components/multiple-choice-question/multiple-choice-question.vue';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';

const props = withDefaults(defineProps<Props>(), {
  userResponse: () => [],
  multiSelect: false,
});
const { question } = toRefs(props);

const attrs = useAttrs();
const emit = defineEmits<Emit>();

function handleUserResponse(response: MCQEmitResponse) {
  let newResponse;
  if (props.multiSelect) {
    newResponse = xor([response.answer], props.userResponse);
  } else {
    newResponse = [response.answer];
  }

  emit('change', {
    question_uuid: question.value.uuid,
    response_type: RESPONSE_TYPES.MCR,
    user_response: newResponse,
  });
  emit('response', {
    question_uuid: question.value.uuid,
    response_type: RESPONSE_TYPES.MCR,
    user_response: newResponse,
  });
}
</script>
