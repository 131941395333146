<template>
  <div class="kog-container">
    <div class="kog-row">
      <div class="kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
        <div
          v-if="practiceOccasion"
          class="margin-bottom-l"
        >
          <kog-ball-step-paginator
            :number-of-pages="questionCount"
            :current-page="currentQuestionNumber"
            :is-step-done="isPracticeItemDone"
            :is-clickable="true"
            @change-page="goToPracticeItem"
          />
        </div>
        <kog-card :default-padding="false">
          <div class="padd-l">
            <kog-loader
              :loading="!practiceOccasion"
              loading-msg="Getting your questions..."
            />
            <div v-if="practiceOccasion && currentPracticeItem">
              <div
                class="flexContainer flexContainer-alignCenter flexContainer-spaceBetween margin-bottom-m"
              >
                <h2
                  class="heading-s"
                  aria-hidden="true"
                >
                  Question {{ currentQuestionNumber }}
                </h2>

                <h2
                  v-if="isNavigationStarted"
                  ref="questionTitle"
                  class="screenreader-only"
                  tabindex="-1"
                  role="alert"
                >
                  Question {{ currentQuestionNumber }}. This question is
                  {{
                    isPracticeItemDone(currentQuestionNumber)
                      ? 'already answered'
                      : 'not answered yet'
                  }}.
                  {{
                    !isPracticeItemDone(currentQuestionNumber) &&
                    currentQuestionNumber === questionCount
                      ? 'After answering, '
                      : ''
                  }}
                  {{
                    currentQuestionNumber === questionCount
                      ? `Navigate to and press the
                        Get Your Results button to submit the test`
                      : ''
                  }}
                </h2>
                <h2
                  v-else
                  class="screenreader-only"
                >
                  Question {{ currentQuestionNumber }}. After answering, navigate to and press the
                  Next button to submit your answer and go to the next question
                </h2>
                <div class="flexContainer">
                  <question-level-labels
                    v-if="shouldShowLevelsInfo"
                    :levels="currentPracticeItem.question.attributes.levels"
                    :relevant-group="subjectClassLevelGroup"
                  />
                  <question-tags
                    v-if="subject && currentPracticeItem"
                    :tags="currentPracticeItem.question.tags"
                    :show-difficulty="showDifficulty"
                    :subject-id="subject.id"
                  />
                </div>
              </div>
              <fill-in-the-blanks-question
                v-if="isFBQ"
                :question="questionWithUpdatedQuestionHtml"
                :user-answers="currentPracticeItem.answerToSubmit"
                @input="setAnswerFbq"
              />
              <div v-if="isMCQ">
                <multiple-choice-question
                  :choice-state="choiceState"
                  :question="questionWithUpdatedQuestionHtml"
                  :choices="currentPracticeItem.question.multiplechoicequestionchoice_set"
                  :multi-select="isMultiSelect"
                  @input="setAnswerToSubmit"
                />
              </div>
              <short-text-question
                v-if="isSTQ"
                :question="questionWithUpdatedQuestionHtml"
                :initial-answer="currentPracticeItem.answerToSubmit"
                @input="setAnswerForStq"
                @submit-answer="goToNextOrSubmit"
              />
            </div>
          </div>
          <div
            v-if="practiceOccasion && currentPracticeItem"
            class="PracticeTestTake-footer padd-l"
          >
            <div
              v-show="!isSubmitting"
              class="flexContainer flexContainer-spaceBetween"
            >
              <report-feedback-button
                label="Report feedback"
                :subject-node-id="subjectNodeRootId"
                :content-id="currentPracticeItem.question.id"
              />
              <div>
                <kog-button
                  class="margin-right-m"
                  :disabled="!hasPreviousPracticeItem"
                  label="Previous"
                  aria-label="Go to previous question"
                  :has-icon="true"
                  icon-class="fa-chevron-left"
                  icon-style="solid"
                  @click="goToPreviousPracticeItem"
                />
                <kog-button
                  v-if="hasNextPracticeItem"
                  label="Next"
                  aria-label="Go to next question"
                  :has-icon="true"
                  icon-class="fa-chevron-right"
                  icon-position="right"
                  icon-style="solid"
                  button-style="primary"
                  @click="goToNextPracticeItem"
                />
                <kog-button
                  v-else
                  label="Get your results"
                  :has-icon="true"
                  icon-class="fa-check-circle"
                  icon-style="solid"
                  :button-style="allPracticeItemsAnswered ? 'accent' : ''"
                  @click="validateAnswersAndSubmit"
                />
              </div>
            </div>
          </div>
          <kog-loader
            :loading="isSubmitting"
            loading-msg="Correcting test..."
          />
        </kog-card>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { keyBy, mapValues } from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapMutations, mapState } from 'vuex';

import QuestionLevelLabels from 'learning/common/components/question-level-labels.vue';
import ReportFeedbackButton from 'learning/common/components/report-feedback-button.vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogBallStepPaginator from 'sharedApp/components/base/pagination/kog-ball-step-paginator.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import MultipleChoiceQuestion from 'sharedApp/components/multiple-choice-question/multiple-choice-question.vue';
import ShortTextQuestion from 'sharedApp/components/short-text-question/short-text-question.vue';
import { IGCSE_LEVELS } from 'sharedApp/const/level-groups.js';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { shouldShowDifficultyTagForSubject } from 'sharedApp/services/featureflags/fixed-feature-flags.ts';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import {
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import QuestionTags from 'studyApp/components/questions/question-tags.vue';
import QuestionDifficultyMixin from 'studyApp/mixins/question-difficulty-mixin.js';
import { isAnswered } from 'studyApp/utils/practice-item-utils.js';

export default {
  name: 'PracticeTestTakeContainer',
  components: {
    KogButton,
    KogCard,
    KogLoader,
    ReportFeedbackButton,
    ShortTextQuestion,
    MultipleChoiceQuestion,
    KogBallStepPaginator,
    FillInTheBlanksQuestion,
    QuestionLevelLabels,
    QuestionTags,
  },
  mixins: [QuestionDifficultyMixin, RoutesMixin],
  props: {
    isShowingNavigationModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNavigationStarted: false,
      showDifficulty: false,
      isShowingIncompleteAnswersModal: false,
      currentQuestionNumber: 1,
    };
  },
  computed: {
    ...mapState('practiceOccasionModule', ['practiceOccasion', 'currentPracticeItem']),
    ...mapState('subjectModule', ['subject']),
    ...mapState('userModule', ['user']),
    isMCQ() {
      return isMCQ(this.currentPracticeItem.question);
    },
    isSTQ() {
      return isSTQ(this.currentPracticeItem.question);
    },
    isFBQ() {
      return isFBQ(this.currentPracticeItem.question);
    },
    isMultiSelect() {
      return this.isMCQ && this.currentPracticeItem.question.is_multi_select;
    },
    allPracticeItemsAnswered() {
      return (
        this.practiceOccasion &&
        this.practiceOccasion.practice_items.every(item => isAnswered(item, item.question))
      );
    },
    isSubmitting() {
      return this.$wait.is('is_submitting');
    },
    isShowingModal() {
      return this.isShowingNavigationModal || this.isShowingIncompleteAnswersModal;
    },
    questionWithUpdatedQuestionHtml() {
      return this.getQuestionWithUpdatedHtml(
        this.currentPracticeItem.question,
        this.showDifficulty,
      );
    },
    questionCount() {
      return this.practiceOccasion.practice_items.length;
    },
    subjectClassLevelGroup() {
      return IGCSE_LEVELS;
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subject.possible_levels);
    },
    hasNextPracticeItem() {
      const isStepValid = this.currentQuestionNumber >= 1;
      const isStepInRange = this.currentQuestionNumber < this.questionCount;
      return isStepValid && isStepInRange;
    },
    hasPreviousPracticeItem() {
      return this.currentQuestionNumber > 1;
    },
    choiceState() {
      const { answerToSubmit } = this.currentPracticeItem;
      const getChoiceState = () => ({ selected: true });
      return mapValues(
        keyBy(answerToSubmit, id => id),
        getChoiceState,
      );
    },
    subjectNodeRootId() {
      return this.subject?.subject_tree[0].id;
    },
  },
  created() {
    this.showDifficulty = shouldShowDifficultyTagForSubject(this.subject.id);
  },
  mounted() {
    this.trackStartStrengthTest();
    window.addEventListener('beforeunload', this.onUnload);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  },
  methods: {
    ...mapMutations('practiceOccasionModule', ['setCurrentPracticeItem', 'setCurrentAnswer']),
    ...mapActions('practiceOccasionModule', [
      'saveOccasionAnswers',
      'fetchPracticeOccasion',
      'updateCurrentAnswer',
    ]),
    ...mapWaitingActions('practiceOccasionModule', {
      saveOccasionAnswers: 'is_submitting',
    }),
    isPracticeItemDone(step) {
      const items = this.practiceOccasion?.practice_items;
      return items && isAnswered(items[step - 1], items[step - 1].question);
    },
    setAnswerToSubmit({ answer }) {
      this.updateCurrentAnswer(answer);
    },
    setAnswerForStq(answer) {
      this.setAnswerToSubmit({ answer });
    },
    goToPracticeItem(step, setfocus = false) {
      if (step < 1 || step > this.questionCount) {
        return;
      }
      this.currentQuestionNumber = step;
      this.isNavigationStarted = true;
      nextTick(() => {
        const practiceItemIndex = step - 1;
        this.setCurrentPracticeItem(practiceItemIndex);
        if (setfocus) {
          this.$refs.questionTitle.focus();
        }
      });
      window.scrollTo(0, 0);
    },
    goToNextOrSubmit() {
      if (!this.isShowingModal && this.hasNextPracticeItem) {
        this.goToNextPracticeItem();
      } else {
        this.validateAnswersAndSubmit();
      }
    },
    trackStartStrengthTest() {
      const eventName = 'Start strength test';
      this.$mixpanel.incrementCounterProperty('eventcounter_startstrengthtest');
      const eventProperties = {
        source: 'Practice Test',
      };
      this.$mixpanel.trackEventViaBackend(eventName, eventProperties);
    },
    openIncompleteAnswersModal() {
      if (this.isShowingModal) {
        return;
      }
      this.isShowingIncompleteAnswersModal = true;
      this.$modal(KogConfirmModal, {
        title: 'Are you sure?',
        text: 'You have not answered all questions. All un-answered questions will be marked incorrect.',
        buttonTitle: 'Yes, get my results',
        onConfirm: this.submitOccasion,
        onCancel: () => {
          this.isShowingIncompleteAnswersModal = false;
        },
      });
    },
    onUnload(e) {
      e.preventDefault();
      e.returnValue = ''; // eslint-disable-line no-param-reassign
    },
    goToPreviousPracticeItem() {
      if (this.hasPreviousPracticeItem) {
        this.goToPracticeItem(this.currentQuestionNumber - 1, true);
      }
    },
    goToNextPracticeItem() {
      if (this.hasNextPracticeItem) {
        this.goToPracticeItem(this.currentQuestionNumber + 1, true);
      }
    },
    validateAnswersAndSubmit() {
      if (this.isSubmitting) {
        return;
      }
      if (this.allPracticeItemsAnswered) {
        this.submitOccasion();
      } else {
        this.openIncompleteAnswersModal();
      }
    },
    setAnswerFbq({ answers }) {
      this.setCurrentAnswer(answers);
    },
    async submitOccasion() {
      if (this.isSubmitting) {
        return;
      }
      this.$mixpanel.trackEventViaBackend('Submit strength test', {
        all_questions_answered: this.allPracticeItemsAnswered,
        source: 'Practice Test',
      });
      if (this.user.isStudent()) {
        this.$event.track('submit_strength_test', {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          practice_occasion_id: this.practiceOccasion.id,
        });
      }
      try {
        const { isTaken } = await this.saveOccasionAnswers(this.practiceOccasion);
        if (!isTaken) {
          intercomModule.trackEvent('completed-strength-test');
          this.$router.replace({ name: 'classStrengthTestResult' });
        }
      } catch {
        this.$toast.showError('There was an error while submitting your answers, please try again');
      }
    },
  },
};
</script>

<style scoped>
.PracticeTestTake-footer {
  border-top: solid 1px var(--kog-dividers-default);
}
</style>
