<template>
  <div
    ref="root"
    class="SectionActivityTemplate-wrapper"
  >
    <!-- Header portal -->
    <portal
      :to="
        mq.current === 'xl' && !isInReviewMode
          ? `${uid}-section-header-desktop`
          : `${uid}-section-header-mobile`
      "
    >
      <div class="flexContainer flexContainer-alignCenter padd-bottom-s">
        <kog-icon
          class="SectionActivityTemplate-icon"
          icon-class="fa-cogs"
          fa-style="regular"
        />
        <span class="SectionActivityTemplate-iconText"> Activity </span>
      </div>
      <section-header
        class="SectionActivityTemplate-sectionTitle"
        :subject-node-id="currentSectionNode.id"
        :is-content-containing-reflection-box="isContentContainingReflectionBox"
        :is-activity-page-template="true"
        :is-in-review-mode="isInReviewMode"
        :section-node="currentSectionNode"
      />
      <teacher-instructions
        v-if="isInReviewMode"
        :subject="subject"
        :subject-node="currentSectionNode"
        :content="currentSectionNode.instructions_html"
        :user="user"
      />
    </portal>

    <!-- Content portal -->
    <portal
      :to="isInReviewMode ? `${uid}-section-content-reviewMode` : `${uid}-section-content-study`"
    >
      <div
        class="SectionActivityTemplate SectionActivityTemplate--activity"
        :class="{
          'SectionActivityTemplate--reviewMode': isInReviewMode,
        }"
      >
        <portal-target :name="`${uid}-section-header-mobile`" />
        <annotate-text
          class="SectionActivityTemplate-activityContent"
          :current-subtopic-text="currentSubtopicText"
          :highlight-enabled="canHighlight"
          :subject="subject"
          :subject-node="currentSectionNode"
          :content="subjectNodeActivityContent"
          :user="user"
          :note-search-param="noteSearchParam"
          :subject-tree="subject.subject_tree"
          :is-in-review-mode="isInReviewMode"
        />
        <div
          class="SectionActivityTemplate-sectionContentWrapper SectionActivityTemplate-sectionContentWrapper--activity"
        >
          <div class="SectionActivityTemplate-sectionContent--activity">
            <portal-target :name="`${uid}-section-header-desktop`" />
            <annotate-text
              ref="content"
              class="SectionActivityTemplate-contentContainer"
              :current-subtopic-text="currentSubtopicText"
              :highlight-enabled="canHighlight"
              :subject="subject"
              :subject-node="currentSectionNode"
              :content="subjectNodeContent"
              :user="user"
              :note-search-param="noteSearchParam"
              :subject-tree="subject.subject_tree"
              :is-in-review-mode="isInReviewMode"
              @content-altered="loadImmersiveReader"
            />
          </div>
          <checkpoint-container
            v-if="!isInReviewMode"
            :key="currentSectionNode.id"
            :current-section-node="currentSectionNode"
            complete-button-icon="fa-badge-check"
            completion-text="Completed"
            complete-button-text="Mark as complete"
            :is-complete-button-disabled="!hasAnsweredAllReflectionQuestions"
            :complete-button-tooltip="completeButtonTooltip"
            :is-loading="$wait.is('loading_book_checkpoint')"
            :is-activity-page="true"
            @completed="markAsCompleted"
            @reset="markAsIncomplete"
          />
        </div>
      </div>
    </portal>

    <!-- Component -->
    <div
      v-if="isInReviewMode"
      class="kog-container-lg"
    >
      <div class="kog-row">
        <div class="kog-col-12 kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
          <portal-target :name="`${uid}-section-content-reviewMode`" />
        </div>
      </div>
    </div>
    <portal-target :name="`${uid}-section-content-study`" />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import AnnotateText from 'learning/common/components/annotate-text.vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { canUserHighlight } from 'sharedApp/utils/user-utils.js';
import CheckpointContainer from 'studyApp/components/section-text/checkpoint-container.vue';
import SectionCompletedModal from 'studyApp/components/section-text/section-completed-modal.vue';
import SectionHeader from 'studyApp/components/section-text/section-header.vue';
import TeacherInstructions from 'studyApp/components/section-text/teacher-instructions.vue';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';
import {
  areAllReflectionQuestionsAnswered,
  isContentContainingReflectionBox,
} from 'studyApp/utils/reflection-question-utils.js';

export default {
  name: 'SectionActivityTemplate',
  components: {
    CheckpointContainer,
    KogIcon,
    AnnotateText,
    SectionHeader,
    TeacherInstructions,
  },
  mixins: [RoutesMixin, ReadingAssignmentMixin],
  inject: ['mq'],
  props: {
    user: {
      type: Object,
      required: true,
    },
    currentSectionNode: {
      type: Object,
      required: true,
    },
    subtopicNode: {
      type: Object,
      required: true,
    },
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { uid } = useUniqueId();

    return {
      uid,
    };
  },
  computed: {
    ...mapState({
      ongoingReadingAssignmentOccasions: state =>
        state.bookModule.ongoingReadingAssignmentOccasions,
      sectionCheckpointOccasionId: state => state.bookModule.sectionCheckpointOccasionId,
      reflectionQuestions: state => state.questionsModule.reflectionQuestions,
      exerciseOccasions: state => state.exerciseOccasionsModule.exerciseOccasions,
      sectionQuestionCount: state => state.bookModule.sectionQuestionCount,
      subject: state => state.subjectModule.subject,
      level: state => state.subjectModule.level,
    }),
    ...mapGetters({
      sectionNodes: 'bookModule/sectionNodes',
      leafNodesWithContent: 'subjectModule/leafNodesWithContent',
    }),
    canHighlight() {
      return canUserHighlight(this.user, this.isInReviewMode);
    },
    subjectNodeContent() {
      return this.currentSectionNode.contents_html;
    },
    subjectNodeActivityContent() {
      return this.currentSectionNode.activity_html;
    },
    noteSearchParam() {
      return this.$route.query.note;
    },
    isContentContainingReflectionBox() {
      return isContentContainingReflectionBox(this.subjectNodeContent);
    },
    currentSubtopicText() {
      return `${this.subtopicNode.formatted_number_including_ancestors} ${this.subtopicNode.name}`;
    },
    currentLeafNodeIndex() {
      return this.leafNodesWithContent.findIndex(ln => ln.id === this.nodeId);
    },
    nextLeafNode() {
      return this.leafNodesWithContent[this.currentLeafNodeIndex + 1] ?? null;
    },
    nextPageRoute() {
      if (this.nextLeafNode) {
        return {
          name: 'classBook',
          params: {
            classSlug: this.subjectClassSlug,
            sid: this.subjectId,
            cid: this.classId,
            nodeSlug: this.nextLeafNode.slug,
            nodeId: this.nextLeafNode.id,
          },
        };
      }
      return null;
    },
    isLastNode() {
      const [lastNode] = this.sectionNodes.slice(-1);
      return lastNode.id === this.nodeId;
    },
    completeButtonTooltip() {
      if (this.hasAnsweredAllReflectionQuestions) {
        return '';
      }

      return 'You must answer all reflection questions in this section before completing';
    },
    hasAnsweredAllReflectionQuestions() {
      if (!this.isReflectionTemplate) {
        return true;
      }
      return areAllReflectionQuestionsAnswered(this.reflectionQuestions, this.exerciseOccasions);
    },
    isReflectionTemplate() {
      return this.currentSectionNode.template === 'reflection';
    },
    trackingProps() {
      const sectionType = this.isInReadingAssignment
        ? 'Reading assignment section'
        : 'Regular section';
      return {
        section_type: sectionType,
        subject_node_name: `${this.currentSectionNode.formatted_number_including_ancestors} ${this.currentSectionNode.name}`,
        question_count: this.sectionQuestionCount,
        source: this.$route.name === 'classReadingAssignmentTake' ? 'assignment' : 'book',
      };
    },
  },
  created() {
    this.fetchSectionQuestionCount({
      subjectNodeId: this.currentSectionNode.id,
      level: this.level?.id,
    });
  },
  methods: {
    ...mapActions({
      fetchSectionQuestionCount: 'bookModule/fetchSectionQuestionCount',
    }),
    ...mapWaitingActions('statisticModule', {
      updateOrCreateNodeProgress: 'loading_book_checkpoint',
    }),
    ...mapMutations({
      setImmersiveReaderContent: 'bookModule/setImmersiveReaderContent',
    }),
    async markAsCompleted() {
      try {
        await this.updateSectionProgress(true);
      } catch (error) {
        this.$toast.showError(
          'Failed to change completion status! Please refresh the page and try again!',
        );
        throw error;
      }
      this.showCompletedModal();
      this.trackSectionComplete();
    },
    async markAsIncomplete() {
      try {
        await this.updateSectionProgress(false);
      } catch (error) {
        this.$toast.showError(
          'Failed to change completion status! Please refresh the page and try again!',
        );
        throw error;
      }
    },
    showCompletedModal() {
      if (this.isInReadingAssignment) {
        this.showReadingAssignmentModal(this.nodeId);
      } else if (this.isLastNode) {
        this.$modal(SectionCompletedModal, {
          sectionNodeId: this.nodeId,
          nextPageRoute: this.nextPageRoute,
          nextSectionNode: this.nextLeafNode,
        });
      }
    },
    async updateSectionProgress(isCompleted) {
      await this.updateOrCreateNodeProgress({
        subjectNodeId: this.nodeId,
        subjectId: this.subject.id,
        occasionId: this.sectionCheckpointOccasionId,
        isCompleted,
      });
    },
    trackSectionComplete() {
      this.$mixpanel.trackEventViaBackend('Complete section', this.trackingProps);
      intercomModule.trackEvent('completed-book-section');
      if (this.user.isStudent()) {
        this.$event.track('complete_section', {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          subject_node_id: this.nodeId,
          practice_occasion_id: this.sectionCheckpointOccasionId,
        });
      }
    },
    loadImmersiveReader() {
      if (!this.isInReviewMode) {
        const content = this.$refs.content.$refs.root.innerHTML;
        this.setImmersiveReaderContent(content);
      }
    },
  },
};
</script>

<style scoped>
.SectionActivityTemplate-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.SectionActivityTemplate {
  display: grid;
  grid-template-areas:
    'sectionTitle'
    'activityContent'
    'sectionContent';
}

.SectionActivityTemplate-sectionTitle {
  grid-area: sectionTitle;
}

.SectionActivityTemplate-activityContent {
  grid-area: activityContent;
}

.SectionActivityTemplate-activityContent :deep(.content-editable) {
  height: 100%;
}

.SectionActivityTemplate-activityContent :deep(.content-editable p) {
  height: 100%;
  margin: 0;
}

.SectionActivityTemplate-activityContent :deep(.content-editable p iframe) {
  width: 100%;
  height: calc(100vh / 2);
  margin: 0;
}

.SectionActivityTemplate-sectionContentWrapper--activity {
  position: relative;
  overflow-y: scroll;
  max-height: 100vh;
}

.SectionActivityTemplate--reviewMode .SectionActivityTemplate-sectionContentWrapper--activity {
  max-height: initial;
}

.u-isBookRailSliderOpen .SectionActivityTemplate-sectionContentWrapper--activity {
  max-height: calc(100vh - var(--space-s) * 2);
}

.SectionActivityTemplate-icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: var(--space-xxl);
  height: var(--space-xxl);

  font-size: var(--kog-font-size-content);

  background-color: var(--kog-content-red-light-5);
  border-radius: 50%;
}

.SectionActivityTemplate-iconText {
  display: flex;
  align-items: center;

  padding-left: var(--space-xs);

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  font-weight: bold;
  line-height: 24px;
  color: var(--kogPlatformGray018);
}

@media (min-width: 1200px) {
  .SectionActivityTemplate--activity:not(.SectionActivityTemplate--reviewMode) {
    display: grid;
    grid-template-areas: 'sectionContent activityContent';
    grid-template-columns: 33% 67%;
  }

  .SectionActivityTemplate-sectionTitle {
    display: grid !important;
  }

  .SectionActivityTemplate-sectionContentWrapper {
    grid-area: sectionContent;
  }

  .SectionActivityTemplate-activityContent {
    grid-area: activityContent;
  }

  .SectionActivityTemplate-activityContent :deep(.content-editable p iframe) {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .SectionActivityTemplate-sectionContent--activity {
    padding-top: var(--space-xxl);
    padding-right: var(--space-xxl);
    padding-left: var(--space-l);
  }
}
</style>

<style>
.SectionActivityTemplate--activity .SectionActivityTemplate-activityContent,
.SectionActivityTemplate--activity .SectionActivityTemplate-activityContent p,
.SectionActivityTemplate--activity .SectionActivityTemplate-contentContainer .content-editable,
.SectionActivityTemplate--activity .SectionActivityTemplate-contentContainer .content-editable > p {
  font-family: var(--kog-lato);
  font-size: var(--kog-font-size-content);
  line-height: 26px;
}
</style>
