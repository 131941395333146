import url from 'url';
import VimeoPlayer from '@vimeo/player';
import truncate from 'lodash/truncate.js';
import createYoutubePlayer from 'youtube-player';

import mixpanelModule from 'sharedApp/libs/mixpanel.js';

const TITLE_MAX_LEN = 60;
const VIDEO_TYPE = {
  vimeo: 'Vimeo',
  youtube: 'YouTube',
};
const VIDEO_STATUS = {
  started: 'Started',
  paused: 'Paused',
  finished: 'Finished',
};
const YOUTUBE_STATUS = {
  0: VIDEO_STATUS.finished,
  1: VIDEO_STATUS.started,
  2: VIDEO_STATUS.paused,
};

class VideoStatistics {
  constructor(mixpanelContext = {}) {
    this.players = [];
    this.mixpanelDefaultProperties = mixpanelContext;
    this.mixpanelModule = mixpanelModule;
  }

  addStatisticsToVideo(domElement) {
    const iframes = domElement.getElementsByTagName('iframe');
    for (let i = 0; i < iframes.length; i += 1) {
      const frm = iframes[i];
      const videoUrl = frm.src;
      if (VideoStatistics.isVimeoVideo(videoUrl)) {
        this.addVimeoEventListeners(frm);
      } else if (VideoStatistics.isYoutubeVideo(videoUrl)) {
        this.addYoutubeEventListeners(frm);
      }
    }
  }

  static addQueryParams(videoUrl, queryParams = {}) {
    const urlObj = url.parse(videoUrl, true);
    urlObj.search = null;
    urlObj.query = { ...urlObj.query, ...queryParams };
    return url.format(urlObj);
  }

  static vimeoUrl(videoUrl) {
    return VideoStatistics.addQueryParams(videoUrl, { api: 1 });
  }

  static youtubeUrl(videoUrl) {
    return VideoStatistics.addQueryParams(videoUrl, { enablejsapi: 1 });
  }

  static isYoutubeVideo(videoUrl) {
    return !!videoUrl.match(/youtube/);
  }

  static isVimeoVideo(videoUrl) {
    return !!videoUrl.match(/vimeo/);
  }

  mixpanelTrackVideo(properties) {
    this.mixpanelModule.trackEvent('Video - Status changed', {
      ...this.mixpanelDefaultProperties,
      ...properties,
    });
  }

  static getYoutubeTrackingData(youtubePlayer) {
    const { videoData } = youtubePlayer.playerInfo;
    return {
      video_type: VIDEO_TYPE.youtube,
      video_title: truncate(videoData.title, TITLE_MAX_LEN),
      video_id: videoData.video_id,
      video_duration: Math.round(youtubePlayer.getDuration()),
    };
  }

  static async getVimeoTrackingData(vimeoPlayer) {
    const title = await vimeoPlayer.getVideoTitle();
    const videoId = await vimeoPlayer.getVideoId();
    const duration = await vimeoPlayer.getDuration();
    return {
      video_type: VIDEO_TYPE.vimeo,
      video_title: truncate(title, TITLE_MAX_LEN),
      video_id: `${videoId}`,
      video_duration: Math.round(duration),
    };
  }

  static onYoutubeStateChange(event, instance) {
    const mixpanelStatus = YOUTUBE_STATUS[event.data];
    if (!mixpanelStatus) {
      return;
    }
    const youtubePlayer = event.target;
    instance.mixpanelTrackVideo({
      video_status: mixpanelStatus,
      ...VideoStatistics.getYoutubeTrackingData(youtubePlayer),
    });
  }

  static async onVimeoStateChange(status, vimeoPlayer, instance) {
    const trackingData = await VideoStatistics.getVimeoTrackingData(vimeoPlayer);
    instance.mixpanelTrackVideo({
      video_status: status,
      ...trackingData,
    });
  }

  addYoutubeEventListeners(iframe) {
    const iframeEl = iframe;
    iframeEl.id = `youtube_${window.crypto.randomUUID()}`;
    iframeEl.addEventListener('load', () => {
      const player = createYoutubePlayer(iframeEl.id);
      player.on('stateChange', event => {
        VideoStatistics.onYoutubeStateChange(event, this);
      });
      this.players.push(player);
    });
    iframeEl.src = VideoStatistics.youtubeUrl(iframeEl.src);
  }

  addVimeoEventListeners(iframe) {
    const iframeEl = iframe;
    iframeEl.addEventListener('load', () => {
      const player = new VimeoPlayer(iframeEl);
      player.on('play', () => {
        VideoStatistics.onVimeoStateChange(VIDEO_STATUS.started, player, this);
      });
      player.on('pause', () => {
        VideoStatistics.onVimeoStateChange(VIDEO_STATUS.paused, player, this);
      });
      player.on('finish', () => {
        VideoStatistics.onVimeoStateChange(VIDEO_STATUS.finished, player, this);
      });
      this.players.push(player);
    });
    iframeEl.src = VideoStatistics.vimeoUrl(iframeEl.src);
  }
}

export default VideoStatistics;
