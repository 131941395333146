<template>
  <kog-signal
    v-kog-focusable="!!difficultyLevel.tooltip"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: difficultyLevel.tooltip,
      boundary: 'document.body',
    }"
    :level="difficultyLevel.level"
    signal-type="difficulty"
    :aria-label="difficultyLevel.tooltip"
  />
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogSignal from 'learning/common/components/difficulty/kog-signal.vue';

import KogFocusable from 'sharedApp/directives/kog-focusable.js';

import { QUESTION_DIFFICULTIES } from './difficulty.ts';

export default {
  name: 'KogDifficulty',
  components: {
    KogSignal,
  },
  directives: {
    tooltip: VTooltip,
    KogFocusable,
  },
  props: {
    difficulty: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    difficultyFromTags() {
      return this.tags.find(tag => tag.match(/difficulty-(easy|medium|hard)/));
    },
    difficultyLevel() {
      const difficulty =
        this.difficulty && this.difficulty.length > 0 ? this.difficulty : this.difficultyFromTags;
      switch (difficulty) {
        case QUESTION_DIFFICULTIES.easy:
          return {
            level: 1,
            tooltip: 'Difficulty: Easy',
          };
        case QUESTION_DIFFICULTIES.medium:
          return {
            level: 2,
            tooltip: 'Difficulty: Medium',
          };
        case QUESTION_DIFFICULTIES.hard:
          return {
            level: 3,
            tooltip: 'Difficulty: Hard',
          };
        default:
          return {
            level: 0,
            tooltip: 'No difficulty mapped',
          };
      }
    },
  },
};
</script>
