<template>
  <div class="QuizCompleted flexContainer flexContainer-center flexContainer-column">
    <vue-3-lottie
      class="QuizCompleted-thumbsUpAnimation"
      :animation-data="thumbsUpAnimation"
      :loop="false"
      :auto-play="true"
      :speed="1"
      @on-complete="animationCompleted"
    />
    <div
      v-if="animationComplete"
      class="flexContainer flexContainer-center flexContainer-column"
    >
      <h2 class="padd-bottom-m"> Quiz completed </h2>
      <image-box
        class="padd-bottom-m"
        :src="squigglyLineImage"
        :h="300"
        :w="300"
        alt="Squiggly line"
      />
      <b>{{ totalCorrectAnswers }} of {{ totalQuestions }} correct answers</b>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';

import ImageBox from 'sharedApp/components/images/image-box.vue';
import thumbsUpAnimation from 'studyApp/images/quiz-completed-thumbsup-animation.json';
import squigglyLineImage from 'studyApp/images/squiggly-line.svg';

defineProps({
  totalQuestions: {
    type: Number,
    required: true,
  },
  totalCorrectAnswers: {
    type: Number,
    required: true,
  },
});

const animationComplete = ref(false);
const animationCompleted = () => {
  animationComplete.value = true;
};
</script>

<style scoped>
.QuizCompleted {
  font-family: var(--kog-satoshi);
  font-size: 18px;
}

.QuizCompleted-thumbsUpAnimation {
  width: 450px;
  margin-top: -45px;
}
</style>
