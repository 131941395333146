<template>
  <div class="McqEditChoice">
    <div class="McqEditChoice-placeHolder">
      {{ placeholder }}
    </div>
    <div class="McqEditChoice-container flexContainer">
      <kog-input
        v-model:value="content"
        :input-icon="inputIcon"
        label="Choice"
        :class="inputClasses"
        :is-label-hidden="true"
      />
    </div>
  </div>
</template>

<script>
import KogInput from 'sharedApp/components/base/input/kog-input.vue';

export default {
  name: 'McqEditChoice',
  components: {
    KogInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isCorrect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    inputIcon() {
      return this.isCorrect ? 'check-circle' : 'times-circle';
    },
    inputClasses() {
      const correctness = this.isCorrect ? 'correct' : 'incorrect';

      return `flexChild-canGrow McqEditChoicePlainText--${correctness}`;
    },
  },
  watch: {
    content() {
      this.$emit('update:value', this.content);
      this.$emit('input', this.content);
    },
  },
};
</script>

<style scoped>
.McqEditChoice-container {
  position: relative;
}

.McqEditChoicePlainText--correct :deep(.KogInput-icon) {
  margin-top: 2px;
  font-size: var(--kog-font-size-medium-icon);
  color: var(--kog-colors-green-600);
}

.McqEditChoicePlainText--incorrect :deep(.KogInput-icon) {
  margin-top: 2px;
  font-size: var(--kog-font-size-medium-icon);
  color: var(--kog-colors-pink-600);
}
</style>
