<template>
  <div>
    <div class="ResourceMasonry-container">
      <div
        v-for="(column, index) in resourcesByColumn"
        :key="`column-${index}`"
        class="ResourceMasonry-column"
      >
        <div
          v-for="resource in column"
          :key="resource.section_id + resource.src"
        >
          <resource-card
            class="ResourceMasonry-item"
            :resource="resource"
            :subject="subject"
          />
        </div>
      </div>
    </div>
    <infinite-loading
      :identifier="identifier"
      @infinite="infiniteHandler"
    >
      <template #complete>
        <p class="text-center text-regular muted">All resources loaded.</p>
      </template>
    </infinite-loading>
  </div>
</template>

<script>
import debounce from 'lodash/debounce.js';
import InfiniteLoading from 'v3-infinite-loading';

import ResourceCard from 'learning/common/components/media-library/resource-card.vue';

import { VIEWPORT_L_PX, VIEWPORT_M_PX } from 'sharedApp/const/css/media.js';

export default {
  name: 'ResourceMasonry',
  components: {
    ResourceCard,
    InfiniteLoading,
  },
  props: {
    resourceList: {
      type: Array,
      default: () => [],
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      identifier: crypto.randomUUID(),
      unrenderedResources: [],
      renderedResources: [],
      numColumns: 4,
    };
  },
  computed: {
    /* Returns 4 or 3 columns depending on this.numColumns,
      column0: resource_0, resource_4, ..., resource_n,
      column1: resource_1, resource_5, ..., resource_n+1,
      ... This leads to the rows having ordered resources */
    resourcesByColumn() {
      const resourcesGroupedByColumn = new Array(this.numColumns).fill().map(() => []);
      this.renderedResources.forEach((resource, index) => {
        const currentColumn = index % this.numColumns;
        resourcesGroupedByColumn[currentColumn].push(resource);
      });
      return resourcesGroupedByColumn;
    },
  },
  watch: {
    resourceList: {
      immediate: true,
      deep: true,
      handler() {
        document.documentElement.scrollTop = 0;
        this.renderedResources = [];
        this.unrenderedResources = [...this.resourceList];

        // reset the identifier in order to reset the observer in infinite loading
        this.identifier = crypto.randomUUID();
      },
    },
  },
  created() {
    this.debouncedCheckBreakpoint = debounce(this.checkBreakpoint, 250);
    window.addEventListener('resize', this.debouncedCheckBreakpoint);
    this.debouncedCheckBreakpoint();
  },
  unmounted() {
    window.removeEventListener('resize', this.debouncedCheckBreakpoint);
  },
  methods: {
    checkBreakpoint() {
      if (window.innerWidth < VIEWPORT_M_PX) {
        this.numColumns = 2;
      } else if (window.innerWidth < VIEWPORT_L_PX) {
        this.numColumns = 3;
      } else {
        this.numColumns = 4;
      }
    },
    infiniteHandler($state) {
      if (this.unrenderedResources.length > 0) {
        this.addElementsToMasonry(5);
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    addElementsToMasonry(nrOfElements) {
      this.renderedResources.push(...this.unrenderedResources.splice(0, nrOfElements));
    },
  },
};
</script>

<style scoped>
.ResourceMasonry-container {
  display: flex;
  align-content: flex-start;
  border-radius: 3px;
}

.ResourceMasonry-column {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 25%;
  padding-right: var(--space-l);

  border-radius: 3px;
}

.ResourceMasonry-item {
  margin-bottom: var(--space-l);
}

@media (--viewport-m) {
  .ResourceMasonry-column {
    width: 33.33%;
  }
}

@media (--viewport-s) {
  .ResourceMasonry-column {
    width: 50%;
  }
}
</style>
