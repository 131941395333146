/* eslint-disable class-methods-use-this */
import linkIcon from '@ckeditor/ckeditor5-link/theme/icons/link.svg';
import { ButtonView, clickOutsideHandler, ContextualBalloon } from '@ckeditor/ckeditor5-ui';
import { Rect } from '@ckeditor/ckeditor5-utils';

import getRangeText from '../glossary-link/utils.js';
import KogUI from '../utils/kogui.ts';
import FormView from './section-link-form.js';

export default class SectionLinkUI extends KogUI {
  init() {
    this.balloon = this.editor.plugins.get(ContextualBalloon);
    this.formView = null;
    const command = this.editor.commands.get('link');
    const updateCommand = this.editor.commands.get('updateLink');

    this.editor.ui.componentFactory.add('sectionLink', locale => {
      const buttonView = new ButtonView(locale);
      buttonView.set({
        withText: true,
        tooltip: true,
        icon: linkIcon,
        label: 'Link',
      });

      buttonView.bind('isEnabled').to(command, 'isEnabled');

      this.listenTo(buttonView, 'execute', evt => {
        // eslint-disable-next-line no-param-reassign
        evt.return = this.triggerShowUI(this.getFormData.bind(this));
      });
      return buttonView;
    });

    this.listenTo(updateCommand, 'execute', evt => {
      // eslint-disable-next-line no-param-reassign
      evt.return = this.triggerShowUI(this.getFormData.bind(this));
    });
  }

  getBalloonPositionData() {
    const { view } = this.editor.editing;

    const viewDocument = view.document;
    const viewSelection = viewDocument.selection;

    // Get direction of the selection.
    const { isBackward } = viewDocument.selection;

    const target = () => {
      const range = isBackward ? viewSelection.getFirstRange() : viewSelection.getLastRange();
      if (!range) return null;
      const rangeRects = Rect.getDomRangeRects(view.domConverter.viewRangeToDom(range));
      // Select the proper range rect depending on the direction of the selection.
      if (isBackward) {
        return rangeRects[0];
      }
      // Remove the zero-width "orphan" rect in the next line for the forward selection if there's
      // another one preceding it. It is not rendered as a selection by the web browser anyway.
      if (rangeRects.length > 1 && rangeRects[rangeRects.length - 1].width === 0) {
        rangeRects.pop();
      }
      return rangeRects[rangeRects.length - 1];
    };

    return { target };
  }

  createFormView() {
    const formView = new FormView(
      this.editor.locale,
      this.linkableSections,
      this.linkableActivities,
    );

    this.listenTo(formView, 'submit', () => {
      const { state } = formView;
      const link = this.getLink(state);

      this.editor.model.change(writer => {
        const { selection } = this.editor.model.document;
        try {
          this.editor.execute('link', link);
        } catch {
          const insertPosition = selection.getFirstPosition();
          writer.insertText(state.text.trim(), { linkHref: link }, insertPosition);
        }
      });

      this.hideUI();
    });

    this.listenTo(formView, 'cancel', () => {
      this.hideUI();
    });

    this.listenTo(formView, 'unlink', () => {
      this.editor.model.change(() => {
        this.editor.execute('unlink');
      });
      this.hideUI();
    });
    clickOutsideHandler({
      emitter: formView,
      activator: () => this.balloon.visibleView === formView,
      contextElements: [this.balloon.view.element],
      callback: () => this.hideUI(),
    });

    return formView;
  }

  generateEmailQueryString(emailAttributes) {
    const url = [];
    /* eslint-disable no-restricted-syntax */
    for (const [key, value] of Object.entries(emailAttributes)) {
      if (value) {
        url.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
    return url.length > 0 ? `?${url.join('&')}` : null;
  }

  async getFormData() {
    await Promise.all([this.getLinkableSections(), this.getLinkableActivities()]);
  }

  async getLinkableSections() {
    if (this.linkableSections) {
      return;
    }

    const linkableSectionsCallback = this.editor.config.get('linkableSections.callbacks');
    this.linkableSections = (await linkableSectionsCallback.getLinkableSections()) || [];
  }

  async getLinkableActivities() {
    if (this.linkableActivities) {
      return;
    }

    const linkableActivitiesCallback = this.editor.config.get('linkableActivities.callbacks');
    this.linkableActivities = (await linkableActivitiesCallback.getLinkableActivities()) || [];
  }

  showUI() {
    if (!this.formView) {
      this.formView = this.createFormView();
    }
    const { selection } = this.editor.model.document;
    const commandValue = this.editor.commands.get('link').value;
    const selectedText = getRangeText(selection.getFirstRange());

    if (commandValue) {
      const linkType = this.getLinkType(commandValue);
      const selectedLinkText = selection.anchor?.textNode?.data;
      const inputText = selectedLinkText || selectedText;
      if (!inputText) {
        return;
      }
      this.formView.showForm({
        type: linkType,
        text: inputText,
        href: commandValue.href,
        target: commandValue.target,
      });
    } else {
      this.formView.showForm({
        type: 'sectionlink',
        text: selectedText,
      });
    }

    super.showUI();
  }

  getLink(state) {
    const {
      type,
      sectionId,
      activityId,
      emailAddress,
      emailSubject,
      emailBody,
      phoneNumber,
      url,
      target,
    } = state;

    switch (type) {
      case 'sectionlink': {
        return { href: `:${type}:${sectionId}`, target };
      }
      case 'activitylink': {
        return { href: `:${type}:${activityId}`, target };
      }
      case 'mailto': {
        const emailString = this.generateEmailQueryString({
          subject: emailSubject,
          body: emailBody,
        });
        const link = emailString
          ? `${type}:${emailAddress}${emailString}`
          : `${type}:${emailAddress}`;
        return { href: link, target };
      }
      case 'tel': {
        return { href: `${type}:${phoneNumber}`, target };
      }
      default: {
        return { href: this.getValidUrl(url), target };
      }
    }
  }

  getValidUrl(href) {
    const protocolRegex = /^[a-zA-Z]+:\/\//;
    if (!href.match(protocolRegex)) {
      return `http://${href}`;
    }
    return href;
  }

  isActivityPath(path) {
    const activityLinkRegexp = /^:activitylink:\d+$/;
    return activityLinkRegexp.test(path);
  }

  isSectionPath(path) {
    const sectionLinkRegexp = /^:sectionlink:\d+$/;
    return sectionLinkRegexp.test(path);
  }

  isEmailPath(path) {
    const mailToRegexp = /mailto:\S+/;
    return mailToRegexp.test(path);
  }

  isTelPath(path) {
    const telRegexp = /tel:\S+$/;
    return telRegexp.test(path);
  }

  getLinkType(link) {
    const path = link.href.trim();

    if (this.isActivityPath(path)) return 'activitylink';
    if (this.isSectionPath(path)) return 'sectionlink';
    if (this.isEmailPath(path)) return 'mailto';
    if (this.isTelPath(path)) return 'tel';
    return 'url';
  }
}
