<template>
  <div class="SelectSubtitle flexContainer flexContainer-spaceBetween flexContainer-alignCenter">
    <kog-table-info-selected-total
      :total-selected-items="selectedItemsTotal"
      :total-items="totalItems"
      :type="type"
      :is-total-items-info-visible="isTotalItemsVisible"
      :is-no-filter-match-info-visible="showDifficulty"
    />

    <button
      v-if="addQuestionText.length > 0"
      class="KogButtonLegacy"
      :class="{ 'KogButtonLegacy--primary': totalItems > 0 }"
      @click="$emit('add-question')"
    >
      {{ addQuestionText }}
    </button>
  </div>
</template>

<script>
import KogTableInfoSelectedTotal from 'sharedApp/components/tables/kog-table-info-selected-total.vue';

export default {
  name: 'SelectSubtitle',
  components: {
    KogTableInfoSelectedTotal,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'question',
      validator(value) {
        return ['question', 'section'].includes(value);
      },
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    addQuestionText: {
      type: String,
      default: '',
    },
    showDifficulty: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add-question'],
  computed: {
    selectedItemsTotal() {
      return this.selectedItems.length;
    },
    isTotalItemsVisible() {
      return !this.showDifficulty || this.totalItems > 0;
    },
  },
};
</script>

<style scoped>
.SelectSubtitle {
  min-height: 48px;
}

.SelectSubtitle-selectedQuestions {
  border-right: solid 1px var(--kogPlatformGray077);
}
</style>
