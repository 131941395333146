<template>
  <div
    v-if="subjectNode"
    class="AssignmentBasketNode padd-xxs flexContainer flexContainer-spaceBetween"
  >
    <kog-icon
      class="AssignmentBasketNode-icon"
      :icon-class="nodeIcon"
      fa-style="regular"
      size="s"
    />
    <div class="flexChild-canGrow">
      <div class="margin-bottom-xxs">
        <kog-icon
          v-if="showHiddenIcon"
          icon-class="fa-eye-slash"
          size="s"
          class="margin-right-xxs"
          :is-non-actionable="true"
        />
        {{ subjectNode.name }}
      </div>
      <div class="text-muted text-small">
        {{ parentSubjectNode.formatted_number_including_ancestors }}
        {{ parentSubjectNode.name }}</div
      >
    </div>
    <kog-round-button
      aria-label="Remove content from assignment"
      icon-class="fa-trash-can"
      icon-style="regular"
      size="x-small"
      button-style="secondary-basic"
      @click.prevent="removeNodeFromAssignment"
    />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapGetters } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { getNodeIcon } from 'sharedApp/const/node-icons.js';

export default {
  name: 'AssignmentBasketNode',
  components: {
    KogIcon,
    KogRoundButton,
  },
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
  },
  emits: ['remove-node'],
  setup() {
    const assignmentStore = useAssignmentStore();
    return {
      assignment: storeToRefs(assignmentStore).assignment,
    };
  },
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    subjectNode() {
      return this.subjectNodesById[this.subjectNodeId];
    },
    parentSubjectNode() {
      if (!this.subjectNode) {
        return null;
      }
      return this.subjectNodesById[this.subjectNode.parent];
    },
    activity() {
      return this.getActivityBySubjectNodeId(this.subjectNode?.id);
    },
    activityType() {
      return this.activity?.type || null;
    },
    nodeIcon() {
      return getNodeIcon(this.subjectNode, this.activityType);
    },
    showHiddenIcon() {
      return !this.isNodeVisible(this.subjectNodeId);
    },
  },
  methods: {
    removeNodeFromAssignment() {
      this.$emit('remove-node', this.subjectNodeId);
    },
  },
};
</script>

<style scoped>
.AssignmentBasketNode {
  background: var(--kogPlatformWhite);
}
.AssignmentBasketNode-icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
  margin-right: var(--space-s);

  color: var(--kog-text-default);

  border: 1px solid var(--kogPlatformGray009);
  border-radius: 50%;
}
</style>
