<template>
  <div class="flexContainer flexContainer-center">
    <div class="StrengthTestResultScore">
      <p class="text-center"> Your score: {{ percentRounded }}% </p>
      <kog-progress-bar :progress="percentRounded" />
    </div>
  </div>
</template>

<script>
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';

export default {
  name: 'StrengthTestResultScore',
  components: {
    KogProgressBar,
  },
  props: {
    percentRounded: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.StrengthTestResultScore {
  width: 50%;
}

@media (--viewport-s) {
  .StrengthTestResultScore {
    width: 100%;
  }
}
</style>
