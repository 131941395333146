<template>
  <div ref="root">
    <content-container
      v-if="!isFBQ"
      v-kog-mathjax
      :content="questionContent"
      :has-content-blanks="isSTQ || isMCQ"
    />
    <fill-in-the-blanks-question
      v-if="isFBQ"
      :question="fbqQuestion"
      :disable-user-input="true"
      :show-with-answers="showAnswers"
      :should-render-as-text="shouldRenderAsText"
    />
  </div>
</template>

<script>
import { escape } from 'lodash';

import ContentContainer from 'sharedApp/components/content/content-container.vue';
import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import {
  dangerouslyGetUnescapedText,
  getContentWithoutStars,
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

export default {
  name: 'QuestionTextPreview',
  components: {
    ContentContainer,
    FillInTheBlanksQuestion,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    showAnswers: {
      type: Boolean,
      default: false,
    },
    shouldRenderAsText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    questionTextWithoutStars() {
      return getContentWithoutStars(this.question.question_html);
    },
    fbqQuestion() {
      return {
        ...this.question,
        answers: this.question?.answers_set || this.question.solution,
      };
    },
    isSTQ() {
      return isSTQ(this.question);
    },
    isMCQ() {
      return isMCQ(this.question);
    },
    isFBQ() {
      return isFBQ(this.question);
    },
    questionContent() {
      return this.shouldRenderAsText ? this.questionText : this.questionTextWithoutStars;
    },
    questionText() {
      const dangerousUnescapedText = dangerouslyGetUnescapedText(this.question.question_html);
      return escape(dangerousUnescapedText);
    },
  },
};
</script>
