import { getFeatureTypeIcon } from './features.js';

function getNgssModuleIcon(module5ePhase) {
  switch (module5ePhase.toUpperCase()) {
    case 'ENGAGE':
      return 'fa-gears';
    case 'EXPLAIN':
      return 'fa-comments';
    case 'EXPLORE':
      return 'fa-binoculars';
    case 'ELABORATE':
      return 'fa-lightbulb-on';
    case 'EVALUATE':
      return 'fa-clipboard-list-check';
    default:
      return 'fa-book';
  }
}
function getNgssContentOptionIcon(contentOptionType) {
  switch (contentOptionType.toUpperCase()) {
    case 'LESSON PLAN':
      return 'fa-chalkboard-user';
    case 'PRESENTATION':
      return 'fa-chalkboard-user';
    case 'PRIOR KNOWLEDGE':
      return 'fa-cube';
    case 'ANCHORING PHENOMENON':
      return 'fa-anchor';
    case 'DRIVING QUESTIONS BOARD':
      return 'fa-chalkboard';
    case 'GUIDING QUESTIONS':
      return 'fa-scribble';
    default:
      return 'fa-memo';
  }
}

export function getNodeIcon(node, activityType = null) {
  if (activityType) {
    return getFeatureTypeIcon(activityType);
  }
  if (node.section_template === 'reflection') {
    return 'fa-thought-bubble';
  }

  const isNgssModule = node.ngss_section_settings && node.level === 3;
  if (isNgssModule) {
    return getNgssModuleIcon(node.ngss_section_settings.phase);
  }

  if (node.level === 3) {
    return 'fa-book';
  }

  const isNgssContentOption = node.section_content_option_type && node.level === 4;
  if (isNgssContentOption) {
    return getNgssContentOptionIcon(node.section_content_option_type);
  }

  if (node.level === 4) {
    return 'fa-book-open-reader';
  }

  return 'fa-book';
}
