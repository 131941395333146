<template>
  <kog-modal class="AddClassModal">
    <template #modalBody>
      <kog-tabs
        v-model:value="selectedMenuItemKey"
        :tabs="addClassModalMenu"
      />
      <join-class-table
        v-show="isJoiningClass"
        :user="user"
        :subject-classes="subjectClasses"
        :subjects-by-id="allSubjectsById"
        :teacher-users-by-subject-class-id="teacherUsersBySubjectClassId"
        :add-subject-class="addSubjectClass"
        :remove-subject-class="removeSubjectClass"
        @update-class-success="refreshNSelectedClasses"
        @update-class-fail="refreshNSelectedClasses"
      />
      <div
        v-show="isCreatingClass"
        class="absoluteContainer"
      >
        <div class="flexContainer">
          <figure class="margin-right-s margin-top-m">
            <div class="AddClassModal-imageContainer">
              <div
                v-if="!logoUrl"
                class="AddClassModal-coverImageCaption font-kog-small padd-xs"
              >
                Subject image cover will show here
              </div>
              <img
                v-if="logoUrl"
                alt=""
                width="96px"
                height="68px"
                :src="logoUrl"
              />
            </div>
          </figure>
          <div class="flexContainer flexContainer-justifyCenter">
            <form @submit.prevent="createClass">
              <label
                class="KogFormInput--label margin-top-m"
                for="subject"
              >
                Subject
              </label>
              <select
                id="subject"
                v-model="newClassSubject"
                class="KogFormInput margin-bottom-xxs"
                required
              >
                <option
                  value="0"
                  disabled
                  selected
                >
                  Select subject for this class
                </option>
                <optgroup
                  v-for="(subjectGroup, key) in allSubjectsWithGroups"
                  :key="key"
                  :label="key"
                >
                  <option
                    v-for="subject in subjectGroup"
                    :key="subject.id"
                    :value="subject.id"
                  >
                    {{ subject.display_name }}
                  </option>
                </optgroup>
              </select>
              <div class="AddClassModal-levelInfo">
                <p
                  v-if="shouldShowLevelsInfo"
                  class="muted margin-bottom-0"
                >
                  <levels-info-icon
                    :education-system="selectedSubject.educationsystem.name"
                    :should-display-text="true"
                  />
                </p>
              </div>
              <label
                class="KogFormInput--label margin-top-s"
                for="name"
              >
                Class name
              </label>
              <input
                id="name"
                v-model="newClassName"
                class="KogFormInput"
                placeholder="Enter class name"
                type="text"
                required
                autocomplete="off"
              />
              <label
                class="KogFormInput--label margin-top-xl"
                for="endDate"
              >
                End date
              </label>
              <class-enddates-select
                id="endDate"
                v-model:value="newClassEndDate"
                class="KogFormInput"
                name="endDate"
              />
              <p class="KogFormInput--helper muted margin-top-s">
                Set the date when you will finish teaching this course.
              </p>
            </form>
          </div>
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div
        v-if="isCreatingClass"
        class="flexContainer flexContainer-rowReverse"
      >
        <kog-button
          class="margin-left-m"
          label="Create class"
          button-style="primary"
          :disabled="addClassSubmitDisabled"
          @click="createClass"
        />
        <kog-button
          label="Cancel"
          @click="closeModal"
        />
      </div>
      <div
        v-if="isJoiningClass"
        class="flexContainer width-full flexContainer-spaceBetween"
      >
        <kog-tag
          class="flexChild-flexEnd"
          :type="wasRecentlyUpdated ? 'informative' : 'default'"
          :label="teachingSubjectClasses.length + ' classes selected'"
        />
        <kog-button
          label="Done"
          @click="closeModal"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import debounce from 'lodash/debounce.js';
import groupBy from 'lodash/groupBy.js';
import { mapGetters } from 'vuex';

import LevelsInfoIcon from 'learning/common/components/levels-info-icon.vue';

import ClassEnddatesSelect from 'schoolAdminApp/components/class-enddates-select.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTabs from 'sharedApp/components/base/tabs/kog-tabs.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import { arrangeGroupsDisplayOrder } from 'sharedApp/services/subjectsOrdering/subjects-ordering-service.js';
import { dateFormatter } from 'sharedApp/utils/time-utils.js';

import JoinClassTable from './join-class-table.vue';

export default {
  name: 'AddClassModal',
  components: {
    LevelsInfoIcon,
    KogButton,
    KogModal,
    KogTabs,
    ClassEnddatesSelect,
    KogTag,
    JoinClassTable,
  },
  props: {
    allSubjects: {
      type: Array,
      required: true,
    },
    allSubjectsById: {
      type: Object,
      required: true,
    },
    subjectClasses: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    teacherUsersBySubjectClassId: {
      type: Object,
      required: true,
    },
    addSubjectClass: {
      type: Function,
      required: true,
    },
    removeSubjectClass: {
      type: Function,
      required: true,
    },
    createSubjectClass: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      newClassName: '',
      newClassEndDate: this.twoYearsAhead(),
      newClassSubject: 0,
      wasRecentlyUpdated: false,
      selectedMenuItemKey: 'join-class',
      submitted: false,
    };
  },
  computed: {
    ...mapGetters('subjectClassModule', ['getTeachingSubjectClasses']),
    teachingSubjectClasses() {
      return this.getTeachingSubjectClasses(this.user.id);
    },
    addClassSubmitDisabled() {
      return (
        !this.newClassName.trim() ||
        !this.newClassEndDate.trim() ||
        !this.newClassSubject ||
        this.submitted
      );
    },
    addClassModalMenu() {
      return [
        {
          name: 'join-class',
          label: 'Join class',
        },
        {
          name: 'create-class',
          label: 'Create class',
        },
      ];
    },
    isCreatingClass() {
      return this.selectedMenuItemKey === 'create-class';
    },
    isJoiningClass() {
      return this.selectedMenuItemKey === 'join-class';
    },
    logoUrl() {
      return this.selectedSubject?.logo_url;
    },
    allSubjectsWithGroups() {
      const groupedSubjects = groupBy(this.allSubjects, subject => subject.education_system_name);
      return arrangeGroupsDisplayOrder(groupedSubjects);
    },
    selectedSubject() {
      return this.allSubjects.find(subject => subject.id === this.newClassSubject);
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.selectedSubject?.possible_levels);
    },
  },
  created() {
    this.debouncedUnsetRecentlyUpdated = debounce(() => {
      this.wasRecentlyUpdated = false;
    }, 750);
  },
  methods: {
    refreshNSelectedClasses() {
      this.wasRecentlyUpdated = true;
      this.debouncedUnsetRecentlyUpdated();
    },
    async createClass() {
      this.submitted = true;
      const data = {
        subjectClassName: this.newClassName,
        endDate: this.newClassEndDate,
        subjectId: this.newClassSubject,
      };
      await this.createSubjectClass(data, this.newClassName, this.twoYearsAhead());
      this.closeModal();
    },
    twoYearsAhead() {
      const twoYearsAhead = new Date();
      twoYearsAhead.setFullYear(twoYearsAhead.getFullYear() + 2);
      return dateFormatter(twoYearsAhead);
    },
  },
};
</script>

<style scoped>
.AddClassModal-imageContainer {
  overflow: hidden;

  width: 96px;
  height: 68px;

  background-color: var(--kogPlatformGray064);
  border-radius: var(--space-xs);
}

.AddClassModal-coverImageCaption {
  color: var(--kogPlatformWhite);
}

.AddClassModal-levelInfo {
  min-height: 20px;
}
</style>
