<template>
  <div
    class="FeatureSectionContainer"
    :class="{
      'FeatureSectionContainer--fullHeight': isFullHeight,
    }"
  >
    <div class="kog-container-lg">
      <div class="kog-row">
        <div class="kog-col-12 kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
          <kog-loader
            v-if="isLoadingFeatureContent"
            :loading="isLoadingFeatureContent"
            :class="{ 'FeatureText--isLoading': isLoadingFeatureContent }"
            loading-msg="Loading feature text..."
          />
          <template v-if="!isLoadingFeatureContent">
            <section-header
              class="SectionActivityTemplate-sectionTitle"
              :subject-node-id="nodeId"
              :is-content-containing-reflection-box="isContentContainingReflectionBox"
              :is-activity-page-template="false"
              :is-in-review-mode="isReviewMode"
            />
            <teacher-instructions
              v-if="isReviewMode && (isDqbActivity || featureSection.instructions_html)"
              :subject="subject"
              :content="featureSection.instructions_html"
              :subject-node="subjectNode"
              :user="user"
            >
              <slot />
            </teacher-instructions>
            <driving-question-image
              v-else-if="
                drivingQuestion &&
                (drivingQuestion.images.length > 0 || drivingQuestion.external_url)
              "
              class="padd-bottom-m"
              :driving-question="drivingQuestion"
              :subject-node-id="nodeId"
            />
            <annotate-text
              ref="content"
              :highlight-enabled="canHighlight"
              :subject="subject"
              :content="featureSection.contents_html"
              :user="user"
              :subject-tree="subject.subject_tree"
              :subject-node="subjectNode"
              :is-in-review-mode="isReviewMode"
              @content-altered="loadImmersiveReader"
            />
          </template>
        </div>
      </div>
    </div>
    <checkpoint-container
      v-if="showCheckpoint"
      :current-section-node="subjectNode"
      complete-button-icon="fa-badge-check"
      complete-button-text="Mark as complete"
      completion-text="Completed"
      :is-loading="$wait.is('loading_book_checkpoint')"
      @completed="complete"
      @reset="reset"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import AnnotateText from 'learning/common/components/annotate-text.vue';

import * as subjectNodeFeaturesApi from 'sharedApp/apis/subject-node-features.js';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import {
  DRIVING_QUESTION_BOARD,
  getFeatureCompleteEventName,
  STANDARD_CONTENT_PAGE,
} from 'sharedApp/const/features.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { canUserHighlight } from 'sharedApp/utils/user-utils.js';
import { fetchSectionDetails } from 'studyApp/api/sections.js';
import DrivingQuestionImage from 'studyApp/components/driving-question/driving-question-image.vue';
import CheckpointContainer from 'studyApp/components/section-text/checkpoint-container.vue';
import SectionHeader from 'studyApp/components/section-text/section-header.vue';
import TeacherInstructions from 'studyApp/components/section-text/teacher-instructions.vue';
import ActivityMixin from 'studyApp/mixins/activity-mixin.js';
import NodeProgressMixin from 'studyApp/mixins/node-progress-mixin.js';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';
import { isContentContainingReflectionBox } from 'studyApp/utils/reflection-question-utils.js';

export default {
  name: 'FeatureSectionContainer',
  components: {
    CheckpointContainer,
    DrivingQuestionImage,
    SectionHeader,
    KogLoader,
    TeacherInstructions,
    AnnotateText,
  },
  mixins: [RoutesMixin, ActivityMixin, NodeProgressMixin, ReadingAssignmentMixin],
  props: {
    isFullHeight: {
      type: Boolean,
      default: true,
    },
    isReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingFeatureContent: false,
      featureSection: null,
      completed: undefined,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      featuresById: 'subjectNodeFeatureModule/featuresById',
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
      drivingQuestionsByDqbId: 'drivingQuestionModule/drivingQuestionsByDqbId',
    }),
    subjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    feature() {
      return this.activityBySubjectNodeId[this.nodeId];
    },
    featureId() {
      return this.feature.id;
    },
    featureType() {
      return this.feature.type;
    },
    isContentContainingReflectionBox() {
      return isContentContainingReflectionBox(this.featureSection.contents_html);
    },
    canHighlight() {
      return canUserHighlight(this.user, this.isReviewMode);
    },
    showCheckpoint() {
      return this.hasFetchedNodeProgress && this.canBeCompleted(this.nodeId) && !this.isReviewMode;
    },
    isDqbActivity() {
      return this.featureType === DRIVING_QUESTION_BOARD;
    },
    drivingQuestion() {
      if (this.isDqbActivity) return null;
      return this.drivingQuestionsByDqbId[this.featureId];
    },
  },
  watch: {
    nodeId: {
      async handler(newValue, oldValue) {
        if (oldValue !== newValue) {
          await this.fetchFeatureAndSection();
        }
      },
    },
    progressStats() {
      this.completed = this.isSubjectNodeCompleted(this.nodeId);
    },
  },
  async created() {
    this.isLoadingFeatureContent = true;
    this.fetchReadingAssignmentForSubjectNode(this.nodeId);
    await this.fetchFeatureAndSection();
    if (
      this.featureType === 'DQB' &&
      !this.drivingQuestion &&
      !this.user.hasEditContentPermission()
    ) {
      await this.fetchDrivingQuestions(this.classId);
    }
    this.isLoadingFeatureContent = false;
  },
  methods: {
    ...mapMutations({
      setImmersiveReaderContent: 'bookModule/setImmersiveReaderContent',
    }),
    ...mapActions({
      fetchDrivingQuestions: 'drivingQuestionModule/fetchDrivingQuestions',
    }),
    async fetchFeatureAndSection() {
      const featureInstance = await subjectNodeFeaturesApi.fetchFeatureInstance({
        featureId: this.featureId,
        featureType: this.featureType,
      });
      this.featureSection = await fetchSectionDetails(featureInstance.section);
    },
    async complete() {
      await this.registerNodeProgress(this.nodeId, true);
      this.trackActivityComplete();
      if (this.isInReadingAssignment) {
        this.showReadingAssignmentModal(this.nodeId);
      }
    },
    reset() {
      this.registerNodeProgress(this.nodeId, false);
    },
    loadImmersiveReader() {
      const content = this.$refs.content.$refs.root.innerHTML;
      this.setImmersiveReaderContent(content);
    },
    trackActivityComplete() {
      if (this.user.isStudent()) {
        const activityIdKey =
          this.featureType === STANDARD_CONTENT_PAGE
            ? 'standard_content_page_id'
            : 'driving_question_board_id';
        const trackingProps = {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          subject_node_id: this.nodeId,
        };
        trackingProps[activityIdKey] = this.featureId;
        this.$event.track(getFeatureCompleteEventName(this.featureType), trackingProps);
      }
    },
  },
};
</script>

<style scoped>
.FeatureSectionContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--kogPlatformWhite);
}

.FeatureSectionContainer--fullHeight {
  height: auto;
  min-height: 100vh;
}

.FeatureText--isLoading {
  min-height: 100vh;
  padding-top: 84px;
}

.kog-container-lg {
  flex: 1 1 auto;
}
</style>
