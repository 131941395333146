<template>
  <div>
    <div
      class="ExerciseQuestionResult-wrapper"
      tabindex="0"
    >
      <div class="ExerciseQuestionResult-wrapperBody">
        <div class="flexContainer">
          <exercise-question-status-icon
            class="margin-right-s"
            :has-answered="true"
            :has-answered-correctly="isCorrect"
          />
        </div>
        <div class="flexContainer flexContainer-column flexChild-size-1">
          <div class="flexContainer flexContainer-column flexChild-size-1">
            <!-- FBQ -->
            <template v-if="isFBQ">
              <fill-in-the-blanks-question
                class="ExerciseQuestionResult-questionContent"
                :question="question"
                :user-answers="practiceItem.user_answer.user_answers"
                :is-showing-answer-correctness="true"
                :answers-with-correctness="practiceItem.solution?.correct_answers"
                :disable-user-input="true"
                :display-input-index="true"
                :data-position="position"
              />

              <div class="margin-top-xl">
                <p class="heading-xxs"> Correct answer </p>
                <div
                  v-for="(uid, index) in fbqElementUIDsInOrder"
                  :key="uid"
                >
                  <div
                    v-for="(answer, aIndex) in getAnswerForUID(uid).answers"
                    :key="aIndex"
                    class="ExerciseQuestionResult-fbqCorrectAnswer text-regular"
                  >
                    #{{ index + 1 }}: {{ answer }}
                  </div>
                </div>
              </div>
            </template>

            <!-- MCQ -->
            <template v-if="isMCQ">
              <multiple-choice-question
                class="ExerciseQuestionResult-questionContent width-full"
                :disabled="true"
                :multi-select="practiceItem.question.is_multi_select"
                :question="practiceItem.question"
                :choices="practiceItem.question.multiplechoicequestionchoice_set"
                :choice-state="choiceState"
                :data-position="position"
                :solution-ids="mcqCorrectChoiceIds"
                type="solution"
              />
            </template>

            <!-- STQ -->
            <template v-if="isSTQ">
              <short-text-question
                class="ExerciseQuestionResult-questionContent"
                :disabled="true"
                :question="practiceItem.question"
                :initial-answer="practiceItem.user_answer.user_text"
                :is-correct="isCorrect"
                :is-incorrect="!isCorrect"
                :data-position="position"
              />

              <p class="kogPlatformGray031 heading-xxs margin-top-s margin-bottom-xxs">
                Correct answer
              </p>
              <p class="u-content text-regular">
                {{ solution.answer_list.join(', ') }}
              </p>
            </template>
          </div>

          <template v-if="hasExplanation">
            <div class="ExerciseQuestionResult-explanation">
              <p class="heading-xxs">
                {{ isFBQ ? 'General explanation' : 'Explanation' }}
              </p>

              <template v-if="isMCQ">
                <ul>
                  <li
                    v-for="mcqExplanation in mcqExplanations"
                    :key="mcqExplanation.key"
                  >
                    <content-container
                      v-kog-mathjax
                      class="u-content text-regular"
                      :content="mcqExplanation.text"
                    />
                  </li>
                </ul>
              </template>
              <template v-else>
                <content-container
                  v-kog-mathjax
                  class="u-content text-regular"
                  :content="explanation"
                />
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { castArray } from 'lodash';

import ContentContainer from 'sharedApp/components/content/content-container.vue';
import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import MultipleChoiceQuestion from 'sharedApp/components/multiple-choice-question/multiple-choice-question.vue';
import ShortTextQuestion from 'sharedApp/components/short-text-question/short-text-question.vue';
import multipleChoiceQuestionNumberingService from 'sharedApp/services/questions/multipleChoiceQuestionNumbering/multiple-choice-question-numbering-service.js';
import {
  getFtbUIDsInOrder,
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

import ExerciseQuestionStatusIcon from './exercise-question-status-icon.vue';

const mcqNumberingService = multipleChoiceQuestionNumberingService();
export default {
  name: 'ExerciseQuestionResult',
  components: {
    FillInTheBlanksQuestion,
    ContentContainer,
    ShortTextQuestion,
    ExerciseQuestionStatusIcon,
    MultipleChoiceQuestion,
  },
  props: {
    practiceItem: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
    answeredPracticeItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMCQ() {
      return isMCQ(this.question);
    },
    isSTQ() {
      return isSTQ(this.question);
    },
    isFBQ() {
      return isFBQ(this.question);
    },
    question() {
      return this.answeredPracticeItem.question;
    },
    isCorrect() {
      return this.practiceItem.user_answer.is_correct;
    },
    solution() {
      const { solution } = this.practiceItem;
      if (this.isMCQ) {
        castArray(solution).forEach(solutionItem => {
          mcqNumberingService.updateSeparateMultipleChoiceQuestionChoiceWithNumbering(
            this.question,
            solutionItem,
          );
        });
      }
      return solution;
    },
    explanation() {
      if (this.isFBQ) {
        return this.solution.explanation;
      }
      return this.solution.answer_explanation_html;
    },
    mcqExplanations() {
      return this.mcqSolutionAsArray.map(choice => ({
        text: choice.answer_explanation_html,
        key: choice.id,
      }));
    },
    hasExplanation() {
      return (this.isMCQ && this.mcqExplanations.length > 0) || this.explanation;
    },
    fbqElementUIDsInOrder() {
      return getFtbUIDsInOrder(this.question.question_html);
    },
    choiceState() {
      if (!this.practiceItem.user_answer) {
        return {};
      }
      return this.practiceItem.user_answer.user_response.reduce(
        (acc, answer) => ({
          ...acc,
          [answer]: { selected: true, correct: this.isCorrectChoice(answer) },
        }),
        {},
      );
    },
    mcqSolutionAsArray() {
      return Array.isArray(this.solution) ? this.solution : [this.solution];
    },
    mcqSolutionIds() {
      return this.mcqSolutionAsArray.map(choice => choice.id);
    },
    mcqCorrectChoiceIds() {
      return this.answeredPracticeItem.question.multiplechoicequestionchoice_set
        .filter(choice => this.mcqSolutionIds.includes(choice.id))
        .map(choice => choice.id);
    },
  },
  methods: {
    getAnswerForUID(uid) {
      return this.solution.correct_answers[uid];
    },
    isCorrectChoice(id) {
      return this.mcqSolutionAsArray.map(choice => choice.id).includes(id);
    },
  },
};
</script>

<style scoped>
.ExerciseQuestionResult-wrapper {
  flex: 1;
  justify-content: flex-start;

  box-sizing: border-box;
  min-height: var(--space-xxxl);
  margin-bottom: var(--space-m);
  padding: var(--space-s) var(--space-xxxl) var(--space-xl) var(--space-m);

  background: var(--kog-colors-white);
  border-radius: var(--space-m);
}

.ExerciseQuestionResult-wrapperBody {
  display: flex;
  font-family: var(--kog-satoshi);
}

.ExerciseQuestionResult-position {
  margin-right: var(--space-xs);

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: bold;
  line-height: var(--space-xl);
}

.ExerciseQuestionResult-questionContent {
  flex: 1;
  font-family: var(--kog-satoshi);
}

.ExerciseQuestionResult-questionContent::before {
  content: attr(data-position) '.';

  float: left;

  margin-right: var(--space-xs);

  font-size: 17px;
  font-weight: bold;
  line-height: var(--space-xl);
}

.ExerciseQuestionResult-questionContent :deep(.QuestionText) {
  display: flex;
  font-family: var(--kog-satoshi);
}

.ExerciseQuestionResult-questionContent p,
.ExerciseQuestionResult-questionContent :deep(p) {
  margin: 0;
}

.ExerciseQuestionResult-questionContent :deep(p + p) {
  margin-top: var(--space-s);
}

.ExerciseQuestionResult-fbqCorrectAnswer {
  font-family: var(--kog-satoshi);
}

.ExerciseQuestionResult-explanation {
  margin-top: var(--space-l);
}

.ExerciseQuestionResult-explanation :deep(.content-editable p) {
  margin: 0;

  font-family: var(--kog-satoshi);
  font-size: 14px;
  line-height: var(--space-l);
  color: var(--kogPlatformGray018);
}

.ExerciseQuestionResult-explanation :deep(.content-editable p + p) {
  margin-top: var(--space-s);
}
</style>
