<template>
  <button
    class="SideNavigationHeader KogButtonLegacy--noStyles padd-right-0 margin-0 cursorPointer flexContainer flexContainer-spaceBetween flexContainer-alignCenter"
    :class="{ 'SideNavigationHeader--collapsed': !isExpanded }"
    :aria-label="buttonLabel"
    :aria-expanded="isExpanded ? 'true' : 'false'"
    @click="$emit('click', $event)"
  >
    <small
      aria-hidden="true"
      class="padd-left-s"
    >
      BOOK MENU
    </small>
    <i
      class="margin-right-m"
      :class="iconClasses"
    />
  </button>
</template>

<script>
export default {
  name: 'SideNavigationHeader',
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    buttonLabel() {
      return this.isExpanded ? 'Close the Book menu' : 'Open the Book menu';
    },
    iconClasses() {
      return this.isExpanded
        ? 'fas fa-chevron-left font-kog-small'
        : 'fas fa-chevron-right font-kog-small';
    },
  },
};
</script>

<style scoped>
.SideNavigationHeader {
  transform: translate(0, 0);

  clear: both;

  width: 360px;
  height: 40px;

  color: var(--kog-text-default);

  background: var(--kog-background-default-0);
  border: 1px solid var(--kog-border-default-300);
  border-radius: 0 24px 24px 0;

  transition: transform 0.15s ease-in-out 0s;
}

.SideNavigationHeader:not(:focus-visible),
.SideNavigationHeader:not(:focus) {
  box-shadow: 0 12px 24px -6px var(--kogShadow020);
}

.SideNavigationHeader--collapsed {
  transform: translate(-320px, 0);
}
</style>
