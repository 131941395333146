<template>
  <generic-warning-box>
    <b> You have been removed from this class, or it has been deleted. </b>
    <p> Disconnect from this class to remove it from Kognity. </p>
  </generic-warning-box>
</template>

<script>
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';

export default {
  name: 'GoogleAuthNoClassAccess',
  components: {
    GenericWarningBox,
  },
};
</script>
