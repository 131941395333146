<template>
  <div
    ref="root"
    class="KogRoundedDropdown"
  >
    <button
      ref="expandButton"
      class="KogRoundedDropdown-header KogButtonLegacy--noStyle"
      :aria-expanded="expand ? 'true' : 'false'"
      aria-haspopup="true"
      :aria-label="label"
      @click="toggleExpand"
    >
      <slot name="header" />
    </button>
    <div class="KogRoundedDropdown-relativeContainer">
      <div
        v-if="expand"
        v-kog-trap-focus
        :style="positionStyle"
        class="KogRoundedDropdown-menuList"
        :class="{ 'width-full': widthFull }"
        @click="hide"
        @keydown.esc="hideWithEsc"
      >
        <slot name="dropdown" />
      </div>
    </div>
  </div>
</template>

<script>
import KogTrapFocus from 'sharedApp/directives/kog-trap-focus.js';

export default {
  name: 'KogRoundedDropdown',
  directives: {
    KogTrapFocus,
  },
  props: {
    alignTo: {
      type: String,
      default: 'left',
    },
    label: {
      type: String,
      default: '',
    },
    widthFull: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['expand', 'close'],
  data() {
    return {
      expand: false,
    };
  },
  computed: {
    positionStyle() {
      switch (this.alignTo) {
        case 'left':
          return 'left: 0;';
        default:
          return 'right: 0;';
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleGlobalClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleGlobalClick);
  },
  methods: {
    toggleExpand() {
      this.expand = !this.expand;
      this.$emit('expand', this.expand);
    },
    hide() {
      this.$emit('close');
      this.expand = false;
    },
    hideWithEsc() {
      this.$emit('close');
      this.expand = false;
      this.$refs.expandButton.focus();
    },
    handleGlobalClick(event) {
      if (!this.$refs.root.contains(event.target)) {
        this.hide();
      }
    },
  },
};
</script>

<style scoped>
.KogRoundedDropdown {
  display: flex;
  flex-direction: column;
}

.KogRoundedDropdown-header {
  cursor: pointer;
  display: flex;
  flex: 1;
}

.KogRoundedDropdown-relativeContainer {
  position: relative;
}

.KogRoundedDropdown-menuList {
  position: absolute;
  z-index: 999999;

  overflow-y: auto;

  min-width: 160px;
  max-height: 80vh;
  padding: 8px 0;

  background-color: var(--kogPlatformWhite);
  border: 1px solid var(--kogPlatformGray093);
  border-radius: 10px;
  box-shadow: 0 4px 8px -2px var(--kogShadow020);
}
</style>
