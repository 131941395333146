<template>
  <kog-item-list
    class="flexContainer"
    role="radiogroup"
    :auto-focus="autoFocus"
  >
    <kog-radio-card
      v-for="(option, index) in options"
      :key="option.value"
      class="KogRadioCardGroup-card margin-top-xs"
      :value="option.value"
      :label="option.text"
      :described-by="option.describedBy"
      :is-checked="isChecked(option)"
      :is-disabled="option.disabled"
      :option="option"
      :tab-index="getTabIndex(option, index)"
      @checked="onOptionChecked"
    />
  </kog-item-list>
</template>

<script>
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';

import KogRadioCard from './kog-radio-card.vue';

export default {
  name: 'KogRadioCardGroup',
  components: {
    KogItemList,
    KogRadioCard,
  },
  props: {
    /**
     * The list of options to show as radio inputs.<br>
     * Structure of an **option** item:<br>
     * - `text: String`<br>
     * - `value: Object | String | Number`<br>
     * - `disabled: Boolean` (optional)<br>
     *
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * The value that is currently selected within the radio-group.
     * It can be used as the write-only alternative to v-model
     */
    selectedValue: {
      type: String,
      required: true,
    },
    /**
     * Applies to all `KogRadio` items.
     */
    /**
     * Proxy for `kog-item-list`'s prop. If true, the correct radio item will be
     * automatically focused when the component mounts (usually in dropdown usecase)
     */
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['checked', 'update:selectedValue'],
  computed: {
    isAnyRadioChecked() {
      return this.options.some(option => this.isChecked(option));
    },
  },
  methods: {
    isChecked(option) {
      return this.selectedValue && this.selectedValue === option.value;
    },
    getTabIndex(option, index) {
      if (option.disabled) {
        return null;
      }

      if (!this.isAnyRadioChecked) {
        return index === 0 ? 0 : -1;
      }

      return option.value === this.selectedValue ? 0 : -1;
    },
    onOptionChecked(value) {
      /**
       * Emits new value in order for it to be approved for being checked
       * by the parent component
       *
       * @event checked
       * @type {string}
       */
      this.$emit('checked', value);
      this.$emit('update:selectedValue', value);
    },
  },
};
</script>
<style>
.KogRadioCardGroup-card {
  margin-left: var(--space-m);
}

.KogRadioCardGroup-card:first-of-type {
  margin-left: 0;
}
</style>
