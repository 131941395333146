<template>
  <kog-loader
    ref="root"
    :loading="isLoadingSection"
    :class="{ 'SectionText--isLoading': isLoadingSection }"
    loading-msg="Loading section text..."
  >
    <template v-if="currentSectionNode">
      <section-activity-template
        v-if="isActivityPageTemplate"
        ref="sectionTemplate"
        :subject="subject"
        :user="user"
        :current-section-node="currentSectionNode"
        :subtopic-node="subtopicNode"
        :is-in-review-mode="isInReviewMode"
        class="SectionText-StudyMode"
        :class="{
          'u-isBookRailSliderOpen': isBookRailSliderOpen,
        }"
      />
      <section-default-template
        v-else
        ref="sectionTemplate"
        :subject="subject"
        :user="user"
        :current-section-node="currentSectionNode"
        :subtopic-node="subtopicNode"
        :is-in-review-mode="isInReviewMode"
        class="SectionText-StudyMode u-paddingTopOverride"
      />
      <section-pager v-if="!isAssignmentFocus" />
    </template>
  </kog-loader>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import isNil from 'lodash/isNil.js';
import { useRoute } from 'vue-router';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState, useStore } from 'vuex';

import { fetchSubjectNode } from 'sharedApp/apis/subject-nodes.js';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import {
  getFirstSectionFromSlugs,
  getSubjectNodeFromSlugs,
} from 'sharedApp/libs/subject-tree-functions.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ScrollPositionMixin from 'sharedApp/mixins/scroll-position-mixin.js';
import SectionActivityTemplate from 'studyApp/components/section-text/section-activity-template.vue';
import SectionDefaultTemplate from 'studyApp/components/section-text/section-default-template.vue';
import SectionPager from 'studyApp/components/section-text/section-pager.vue';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';

export default {
  name: 'SectionText',
  components: {
    SectionPager,
    SectionActivityTemplate,
    SectionDefaultTemplate,
    KogLoader,
  },
  mixins: [RoutesMixin, ScrollPositionMixin, ReadingAssignmentMixin],
  props: {
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const { subject } = store.state.subjectModule;

    const currentSectionNode = computed(() =>
      store.getters['bookModule/findSectionNodeById'](route.params.nodeId),
    );

    useHead({
      title: () => `${currentSectionNode.value?.name || ''} | ${subject.name}`,
    });

    return {
      currentSectionNode,
    };
  },
  data() {
    return {
      subjectSlug: '',
      topicSlug: '',
      subtopicSlug: '',
      sectionSlug: '',
      subsectionSlug: '',
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      user: state => state.userModule.user,
      lastOpenedSubjectNode: state => state.subjectModule.lastOpenedSubjectNode,
      sectionNodes: state => state.bookModule.sectionNodes,
    }),
    ...mapGetters({
      sectionNodes: 'bookModule/sectionNodes',
      isBookRailSliderOpen: 'bookModule/isBookRailSliderOpen',
      findTopicNodeBySlug: 'subjectModule/findTopicNodeBySlug',
      findSubtopicNodeBySlug: 'subjectModule/findSubtopicNodeBySlug',
    }),
    isLoadingSection() {
      return this.$wait.is('loading_section_text');
    },
    topicNode() {
      return this.findTopicNodeBySlug(this.topicSlug);
    },
    subtopicNode() {
      if (!this.topicNode) return {};
      return this.findSubtopicNodeBySlug(this.topicNode, this.subtopicSlug);
    },
    resourceAnchor() {
      return this.$route.query.resourceAnchor;
    },
    routeName() {
      return this.$route.name;
    },
    isActivityPageTemplate() {
      return this.currentSectionNode.template === 'activity';
    },
  },
  watch: {
    routeName: {
      handler(newName, oldName) {
        if (newName !== oldName) {
          this.loadSectionText();
        }
      },
    },
    nodeId: {
      handler(newId, oldId) {
        if (oldId && newId !== oldId) {
          this.loadSectionText();
        }
      },
    },
    currentSectionNode: {
      async handler(newNode) {
        if (newNode) {
          await this.registerSectionActivity(newNode.id);
          this.fetchLastSectionOpened(this.subject.id);
        }
      },
    },
  },
  created() {
    if (this.user.isTeacher()) {
      this.fetchSubjectClassStudents(this.classId);
    }
    this.fetchSubjectNodesProgress(this.subject.id);
    intercomModule.trackEvent('entered-book-section');
  },
  mounted() {
    this.loadSectionText();
  },
  methods: {
    ...mapWaitingActions('bookModule', {
      fetchSections: 'loading_section_text',
    }),
    ...mapWaitingActions('statisticModule', {
      fetchSubjectNodesProgress: 'loading_subject_progress_statistic',
    }),
    ...mapActions('teacherStudentListModule', {
      fetchSubjectClassStudents: 'fetchSubjectClassStudentsList',
    }),
    ...mapActions({
      fetchLastSectionOpened: 'subjectModule/fetchLastSectionOpened',
      registerSectionActivity: 'activityModule/registerSectionActivity',
      clearLTQExerciseQuestions: 'questionsModule/clearLTQExerciseQuestions',
    }),
    async loadSectionText() {
      if (this.$route.name === 'classBookResume') {
        this.handleLandingOnBook();
        return;
      }
      if (this.nodeId) {
        const node = await fetchSubjectNode(this.nodeId);
        const slugs = node.url.split('/');
        this.subjectSlug = slugs[0] || null;
        this.topicSlug = slugs[1] || null;
        this.subtopicSlug = slugs[2] || null;
        this.sectionSlug = slugs[3] || null;
        this.subsectionSlug = slugs[4] || null;
        if (node.level === 0) {
          this.handleLandingOnBook();
          return;
        }
        if (node.level === 3) {
          this.handleLandingOnSection();
        }
      }

      if (!this.currentSectionNode) {
        await this.fetchSections({
          subjectSlug: this.subjectSlug,
          topicSlug: this.topicSlug,
          subtopicSlug: this.subtopicSlug,
          sectionSlug: this.sectionSlug,
          subsectionSlug: this.subsectionSlug,
        });
      }

      if (this.resourceAnchor) {
        this.scrollToResource();
        return;
      }
      await this.asyncContentLoaded();
      this.tryRestoreLastPageOffset();
    },
    handleLandingOnSection() {
      const subjectNode = getSubjectNodeFromSlugs(
        this.subject.subject_tree[0],
        this.topicSlug,
        this.subtopicSlug,
        this.sectionSlug,
      );
      if (subjectNode.children.length > 0) {
        const firstSubsection = subjectNode.children[0];
        this.$router.replace({
          name: 'classBook',
          params: {
            classSlug: this.subjectClassSlug,
            sid: this.subjectId,
            cid: this.classId,
            nodeSlug: firstSubsection.slug,
            nodeId: firstSubsection.id,
          },
        });
      }
    },
    handleLandingOnBook() {
      let nodeSlug = null;
      let nodeId = null;
      if (this.lastOpenedSubjectNode && this.lastOpenedSubjectNode.subject_node_id) {
        nodeSlug = this.lastOpenedSubjectNode.slug;
        nodeId = this.lastOpenedSubjectNode.subject_node_id;
      } else {
        const firstSection = getFirstSectionFromSlugs(
          this.subject.subject_tree[0],
          this.subject.slug,
        );
        nodeSlug = firstSection.slug;
        nodeId = firstSection.id;
      }
      this.$router.replace({
        name: 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug,
          nodeId,
        },
      });
    },
    scrollToResource() {
      const anchor = this.resourceAnchor;
      const selector = `iframe[src*="${anchor}"], img[src*="${anchor}"]`;
      const elements = this.$refs.root.querySelectorAll(selector);
      if (elements.length > 0) {
        // passing { behavior: 'smooth' } here breaks the scroll entirely
        // for some mysterious reason
        elements[0].scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    },
    asyncContentLoaded() {
      const sectionTemplateRef = this.$refs.sectionTemplate;
      if (isNil(sectionTemplateRef)) {
        return Promise.resolve();
      }
      const contentImages = sectionTemplateRef.$refs.root.querySelectorAll('img');

      const loadingContent = [];
      contentImages.forEach(img => {
        loadingContent.push(
          new Promise((resolve, reject) => {
            if (img.complete) resolve();
            img.addEventListener('load', resolve);
            img.addEventListener('error', reject);
          }),
        );
      });

      return Promise.all(loadingContent);
    },
  },
};
</script>

<style scoped>
.SectionText--isLoading {
  min-height: 100vh;
  padding-top: 84px;
  background-color: var(--kogPlatformWhite);
}

.SectionText-StudyMode {
  height: auto;
  min-height: 100vh;
  background-color: var(--kogPlatformWhite);
}

.u-paddingTopOverride {
  padding-top: 42px !important;
}
</style>
