<template>
  <div class="AssignmentSelectStep flexContainer">
    <div class="AssignmentSelectStep-sidePanel--left padd-right-m padd-bottom-m">
      <div class="AssignmentSelectStep-sidePanel-filterBy margin-top-1 margin-bottom-fine-2">
        FILTER BY:
      </div>
      <div v-if="showDifficulty">
        <div class="AssignmentSelectStep-sidePanel--headingContainer">
          <kog-signal />
          <h3 class="AssignmentSelectStep-sidePanel--heading padd-left-fine-2"> Difficulty </h3>
        </div>
        <kog-filter-group
          :filter-list="filterList"
          :disabled="isLoadingQuestions"
          class="padd-bottom-1"
          @filter-tag="toggleFilterTag"
        />
      </div>
      <div class="AssignmentSelectStep-sidePanel--headingContainer">
        <i class="fas fa-book kogPlatformGray031 AssignmentSelectStep-sidePanel--icon" />
        <h3 class="AssignmentSelectStep-sidePanel--heading padd-left-fine-2"> Book topics </h3>
      </div>
      <book-tree
        :topics="subjectAndTopics"
        :subject-map="subjectMap"
        :max-level-shown="maxSubjectTreeLevel"
        :selected-items="selectedItems"
        :tags-by-subject-node-id="tagsBySubjectNodeId"
        :selected-node="selectedNode"
        :disabled-node-ids="subjectNodeIdsToShowHiddenTag"
        disabled-node-tooltip="This content is not available for students"
        tag-direction="row"
        @node-selected="$emit('node-selected', $event)"
      />
    </div>
    <div class="AssignmentSelectStep-sidePanel--right padd-left-m padd-bottom-m">
      <select-questions-list
        v-if="isQuestion"
        :paginated-questions="paginatedItems"
        :go-to-paginated-questions-page="fetchQuestions"
        :selected-questions="selectedItems"
        :is-loading-questions="isLoadingQuestions"
        :show-difficulty="showDifficulty"
        :filter-list="filterList"
        :toggle-filter-tag="toggleFilterTag"
        :subject-id="subjectId"
        :subject-name="subjectName"
        :is-question-selected="isQuestionSelected"
        :get-question-locations="getQuestionLocations"
        :subject-and-topics="subjectAndTopics"
        :is-all-selected="isAllSelected"
        :selected-node="selectedNode"
        :is-saving="isSaving"
        :title="titleText"
        @toggle-item="$emit('toggle-item', $event)"
        @toggle-select-all="$emit('toggle-select-all')"
        @question-saved="$emit('question-saved', $event)"
        @question-deleted="$emit('question-deleted', $event)"
      />
      <select-esq-list
        v-if="isExamStyle"
        class="margin-bottom-xxxl"
        :paginated-questions="paginatedItems"
        :go-to-paginated-questions-page="fetchQuestions"
        :selected-questions="selectedItems"
        :is-loading-questions="isLoadingQuestions"
        :subject-name="subjectName"
        :is-question-selected="isQuestionSelected"
        :subject-and-topics="subjectAndTopics"
        :is-all-selected="isAllSelected"
        :subject-id="subjectId"
        :selected-node="selectedNode"
        :title="titleText"
        :education-system="educationSystem"
        @toggle-item="$emit('toggle-item', $event)"
        @toggle-select-all="$emit('toggle-select-all')"
        @node-selected="$emit('node-selected', $event)"
        @question-saved="$emit('question-saved', $event)"
        @question-deleted="$emit('question-deleted', $event)"
      />
      <select-sections-list
        v-if="isReading"
        class="margin-bottom-xxxl"
        :sections="sections"
        :selected-sections="selectedItems"
        :is-all-selected="isAllSelected"
        :selected-node="selectedNode"
        :title="titleText"
        :hidden-subject-node-ids="hiddenSubjectNodeIds"
        @toggle-section="$emit('toggle-item', $event)"
        @toggle-select-all="$emit('toggle-select-all')"
      />
    </div>
  </div>
</template>

<script>
import BookTree from 'learning/common/components/book-tree.vue';
import KogSignal from 'learning/common/components/difficulty/kog-signal.vue';

import {
  isExamStyleType,
  isQuestionType,
  isReadingType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import SelectEsqList from 'studyApp/components/teacher/assignment-create/select-step/select-esq-list.vue';
import SelectQuestionsList from 'studyApp/components/teacher/assignment-create/select-step/select-questions-list.vue';
import SelectSectionsList from 'studyApp/components/teacher/assignment-create/select-step/select-sections-list.vue';
import KogFilterGroup from 'teachApp/components/kog-filter-group.vue';

export default {
  name: 'AssignmentSelectStep',
  components: {
    BookTree,
    KogFilterGroup,
    KogSignal,
    SelectSectionsList,
    SelectQuestionsList,
    SelectEsqList,
  },
  props: {
    assignmentType: {
      type: String,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      default: false,
    },
    isLoadingQuestions: {
      type: Boolean,
      default: false,
    },
    maxSubjectTreeLevel: {
      type: Number,
      required: true,
    },
    subjectAndTopics: {
      type: Array,
      required: true,
    },
    selectableItemCounts: {
      type: Object,
      default: () => ({}),
    },
    selectedNode: {
      type: Object,
      required: true,
    },
    isAllSelected: {
      type: Boolean,
      default: false,
    },
    sections: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    subjectId: {
      type: Number,
      required: true,
    },
    subjectMap: {
      type: Object,
      required: true,
    },
    subjectName: {
      type: String,
      default: '',
    },
    paginatedItems: {
      type: Object,
      default: () => ({}),
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    getQuestionLocations: {
      type: Function,
      required: true,
    },
    fetchQuestions: {
      type: Function,
      required: true,
    },
    hiddenSubjectNodeIds: {
      type: Array,
      required: true,
    },
    educationSystem: {
      type: String,
      required: true,
    },
  },
  emits: [
    'toggle-select-all',
    'filter-changed',
    'toggle-item',
    'question-saved',
    'question-deleted',
    'node-selected',
  ],
  data() {
    return {
      filterList: [
        {
          isSelected: true,
          filterText: 'Easy',
          filterKey: 'difficulty-easy',
        },
        {
          isSelected: true,
          filterText: 'Medium',
          filterKey: 'difficulty-medium',
        },
        {
          isSelected: true,
          filterText: 'Hard',
          filterKey: 'difficulty-hard',
        },
        {
          isSelected: true,
          filterText: 'No difficulty level',
          filterKey: 'difficulty-none',
        },
      ],
    };
  },
  computed: {
    titleText() {
      if (!this.selectedNode.name) {
        return '';
      }
      if (this.selectedNode.level === 0) {
        return `${this.selectedNode.name} - All`;
      }
      if (this.isQuestion || this.isReading) {
        return `${this.selectedNode.number_including_ancestors}. ${this.selectedNode.name}`;
      }
      if (this.isExamStyle) {
        return `${this.selectedNode.formatted_number_including_ancestors} ${this.selectedNode.name}`;
      }
      return '';
    },
    isReading() {
      return isReadingType(this.assignmentType);
    },
    isQuestion() {
      return isQuestionType(this.assignmentType);
    },
    isExamStyle() {
      return isExamStyleType(this.assignmentType);
    },
    subjectNodeIdsToShowHiddenTag() {
      if (this.isReading) {
        return [];
      }
      return this.hiddenSubjectNodeIds;
    },
    tagsBySubjectNodeId() {
      return Object.keys(this.selectedCountsBySubjectNodeId).reduce((result, nodeId) => {
        const selectedCount = this.selectedCountsBySubjectNodeId[nodeId];
        const totalCount = this.selectableItemCounts[nodeId] || 0;
        const tagValue = selectedCount > 0 ? `${selectedCount} / ${totalCount}` : `${totalCount}`;
        const tags = [{ value: tagValue }];
        if (this.isReading && this.hiddenSubjectNodeIds.includes(+nodeId)) {
          tags.push({ value: 'Hidden', type: 'warning' });
        }
        return {
          ...result,
          [nodeId]: tags,
        };
      }, {});
    },
    selectedCountsBySubjectNodeId() {
      const topicsOnly = this.subjectAndTopics.filter(node => node.level === 1);
      const topicsChildren = topicsOnly.flatMap(topic => this.getNodeDescendants(topic));
      const allNodes = [...topicsOnly, ...topicsChildren];
      const allNodeIdsSet = new Set(allNodes.map(node => node.id));
      const selectedCountsBySubjectNode = this.getSelectedCountsBySubjectNode(allNodeIdsSet);
      const result = {};
      topicsOnly.forEach(topic =>
        this.fillSelectedCountBySubjectNodeIncludingChildren(
          result,
          topic,
          selectedCountsBySubjectNode,
        ),
      );
      return result;
    },
  },
  methods: {
    toggleFilterTag({ filterKey }) {
      const filter = this.filterList.find(f => f.filterKey === filterKey);
      filter.isSelected = !filter.isSelected;
      this.applyFilterChanges();
      this.$mixpanel.trackEvent('Assignment - Filter questions on difficulty', {
        difficult_filter: filter.isSelected ? 'selected' : 'deselected',
        difficult_level: filter.filterText,
      });
    },
    applyFilterChanges() {
      const unselectedFilter = this.filterList.filter(f => !f.isSelected);
      const filter = unselectedFilter.map(f => f.filterKey).join(',');
      this.$emit('filter-changed', filter);
    },
    isQuestionSelected(question) {
      return this.selectedItems && this.selectedItems.some(q => q.id === question.id);
    },
    fillSelectedCountBySubjectNodeIncludingChildren(result, node, selectedCountsBySubjectNode) {
      if (node.id in result) {
        return result[node.id];
      }
      if (node.id in selectedCountsBySubjectNode) {
        // eslint-disable-next-line no-param-reassign
        result[node.id] = selectedCountsBySubjectNode[node.id];
        return result[node.id];
      }
      if (node.children.length > 0) {
        // eslint-disable-next-line no-param-reassign
        result[node.id] = node.children
          .map(child =>
            this.fillSelectedCountBySubjectNodeIncludingChildren(
              result,
              child,
              selectedCountsBySubjectNode,
            ),
          )
          .reduce((total, count) => total + count, 0);
        return result[node.id];
      }
      return 0;
    },
    getSelectedCountsBySubjectNode(subjectNodeIdsSet) {
      // this.selectedItems can contain either questions or subject nodes
      return this.selectedItems.reduce((result, item) => {
        const safelyIncCountForNodeId = (res, nodeId) => {
          const currentCount = res[nodeId] || 0;
          // eslint-disable-next-line no-param-reassign
          res[nodeId] = currentCount + 1;
        };
        const isQuestion = !!item.subjectnode_mappings;
        if (isQuestion) {
          // Subjectnode_mapping is based on the SubjectNode model with added params.
          // Questions can be mapped to other subjects.
          item.subjectnode_mappings
            .filter(mapping => subjectNodeIdsSet.has(mapping.id))
            .forEach(mapping => {
              const nodeId = mapping.id;
              safelyIncCountForNodeId(result, nodeId);
            });
        } else {
          safelyIncCountForNodeId(result, item.id);
        }
        return result;
      }, {});
    },
    getNodeDescendants(node) {
      const { children } = node;
      const grandChildren = children.flatMap(childNode => this.getNodeDescendants(childNode));
      return children.concat(grandChildren);
    },
  },
};
</script>

<style scoped>
.AssignmentSelectStep {
  overflow-y: hidden;
  height: 100%;
}

.AssignmentSelectStep-sidePanel--headingContainer {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  font-size: var(--kog-font-size-medium-icon);
}

.AssignmentSelectStep-sidePanel--heading {
  margin: 10px 0;
  font-size: var(--kog-font-size-default);
  font-weight: normal;
  color: var(--kogPlatformGray031);
}

.AssignmentSelectStep-sidePanel-filterBy {
  font-size: var(--kog-font-size-smaller);
  color: color-mod(var(--kogPlatformGray018) alpha(85%));
}

.AssignmentSelectStep-sidePanel--left {
  overflow-y: auto;
  width: 25%;
}

.AssignmentSelectStep-sidePanel--right {
  overflow-y: auto;
  width: 75%;
  border-left: 1px solid var(--kogPlatformGray084);
}

.AssignmentSelectStep-sidePanel--icon {
  font-size: 18px;
}
</style>
