export function findNonParagraphAncestor(writer, selection) {
  const position = selection.getFirstPosition();
  let node = position.parent;

  if (node.name !== 'paragraph') {
    return selection;
  }

  while (node.parent.name === 'paragraph') {
    node = node.parent;
  }

  const parentPosition = writer.createPositionAt(node.parent, node.index);
  const range = writer.createRange(parentPosition, parentPosition);
  const newSelection = writer.createSelection(range);
  return newSelection;
}

export function forbidNesting(name) {
  return (context, child) => {
    if (child.name === name && [...context].some(obj => obj.name === name)) {
      return false;
    }
    return undefined;
  };
}

export function getElementAncestor(element, ancestorName) {
  if (element.name === ancestorName) {
    return element;
  }
  let { parent: parentElement } = element;
  while (parentElement) {
    if (parentElement.name === ancestorName) {
      return parentElement;
    }
    parentElement = parentElement.parent;
  }
  return null;
}

export function isElementDescendantOf(element, ancestorName) {
  return getElementAncestor(element, ancestorName) !== null;
}

export function isElementTypeSelected(selection, elementType) {
  const element = selection.getSelectedElement();
  return element && element.is('element', elementType);
}

export function isElementSelected(element, document) {
  const firstRange = document.selection.getFirstRange();
  const selectedNode = firstRange.start.nodeAfter;

  return element === selectedNode;
}

function getFirstModelAncestor(element, mapper) {
  let modelElement = mapper.toModelElement(element);
  let sourceElement = element;

  while (!modelElement && sourceElement.parent) {
    sourceElement = sourceElement.parent;
    modelElement = mapper.toModelElement(sourceElement);
  }

  return modelElement;
}

export function isClickInsideSelectedElement(
  eventData,
  document,
  mapper,
  ancestorName,
  excludedAncestorNames = [],
) {
  const clickedElement = getFirstModelAncestor(eventData.target, mapper);
  if (!clickedElement) {
    return false;
  }

  const ancestor = getElementAncestor(clickedElement, ancestorName);
  if (!ancestor || !isElementSelected(ancestor, document)) {
    return false;
  }

  const isClickInsideExcludedAncestor =
    excludedAncestorNames &&
    excludedAncestorNames.some(excludedName => isElementDescendantOf(clickedElement, excludedName));
  return !isClickInsideExcludedAncestor;
}
