<template>
  <div class="QuizFrontPage">
    <div
      v-if="isTeacher"
      class="QuizFrontPage-teacherBanner"
    >
      Review the questions <span v-if="isReviewMode">and answers</span> by starting the quiz.
    </div>
    <kog-button
      class="QuizFrontPage-startQuizButton margin-top-xxxl"
      label="Start quiz"
      @click="$emit('start-quiz')"
    />
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

export default {
  name: 'QuizFrontPage',
  components: {
    KogButton,
  },
  props: {
    isTeacher: {
      type: Boolean,
      default: false,
    },
    isReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['start-quiz'],
};
</script>

<style scoped>
.QuizFrontPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.QuizFrontPage-startQuizButton {
  height: 57px;
  padding: 10px 80px;

  font-size: 32px;
  font-weight: 700;
  line-height: 33px;
  color: var(--kog-colors-aubergine-900);

  background-color: var(--kog-colors-yellow-500);
  border-color: var(--kog-colors-yellow-500);
  border-radius: 16px;
  box-shadow: 0 8px 0 0 var(--kog-colors-yellow-700);
}

.QuizFrontPage-startQuizButton.KogButton:hover {
  background-color: var(--kog-colors-yellow-500);
  box-shadow:
    0 12px 0 0 var(--kog-colors-yellow-700),
    0 8px 0 0 var(--kog-colors-yellow-700);
}

.QuizFrontPage-startQuizButton.KogButton:active {
  color: var(--kog-colors-aubergine-900);
  background-color: var(--kog-colors-yellow-700);
  box-shadow: 0 0;
}

.QuizFrontPage-teacherBanner {
  width: 100%;
  padding: var(--space-m);
  background-color: var(--kog-colors-yellow-200);
  border-radius: 12px;
}
</style>
