import { addListToDropdown, createDropdown, Model } from '@ckeditor/ckeditor5-ui';
import { Collection } from '@ckeditor/ckeditor5-utils';

import KogForm from '../utils/kogform.js';

export default class FormView extends KogForm {
  constructor(locale) {
    super(locale);

    this.columnDropdownView = this.createDropdown('Columns');

    this.saveButtonView = this.createSaveButton();
    this.cancelButtonView = this.createCancelButton();
    this.saveButtonView.set({ isEnabled: false });

    this.columns = null;
    this.columnDropdownView.on(
      'execute',
      function columnSelected(event) {
        this.columnDropdownView.buttonView.set({ label: event.source.value });
        this.saveButtonView.set({ isEnabled: true });
        this.columns = event.source.value;
      }.bind(this),
    );

    this.childViews = this.createCollection([
      this.columnDropdownView,
      this.saveButtonView,
      this.cancelButtonView,
    ]);

    this.setup('content-grid');
  }

  focus() {
    this.columnDropdownView.focus();
  }

  reset() {
    super.reset();
    this.columns = null;
    this.saveButtonView.set({ isEnabled: false });
    this.columnDropdownView.buttonView.set({ label: 'Columns' });
  }

  createDropdown(label) {
    const withText = true;
    const dropdown = createDropdown(this.locale);
    dropdown.buttonView.set({ label, withText });

    const collectionItems = new Collection();

    for (let i = 1; i <= 12; i += 1) {
      collectionItems.add({
        type: 'button',
        model: new Model({
          withText: true,
          label: `${i}`,
          value: `${i}`,
        }),
      });
    }

    addListToDropdown(dropdown, collectionItems);

    return dropdown;
  }

  setFields(columns) {
    this.columns = columns;
    this.columnDropdownView.buttonView.set({ label: columns });
  }
}
