<template>
  <span
    v-if="annotationsCount"
    class="AnnotationsCounter"
  >
    {{ annotationsCount }}
  </span>
</template>

<script>
export default {
  name: 'AnnotationsIndicator',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    annotationsCount() {
      return this.item.annotations_count ? this.item.annotations_count : 0;
    },
  },
};
</script>

<style scoped>
.AnnotationsCounter {
  display: inline-block;

  margin-right: 3px;
  padding: 1px 4px 1px 4px;

  font-size: 11px;
  font-weight: bold;
  color: var(--kog-colors-white);

  background-color: var(--kog-background-brand-900);
  border-radius: 2px;
}
</style>
