<template>
  <kog-modal
    title="End this assignment now"
    :close-modal="closeModal"
  >
    <template #modalBody>
      <p> Do you want to end this assignment now? </p>

      <kog-loader
        :loading="!isFetchingStudentsDone"
        loading-msg="Fetching assignment information..."
      />
      <div v-if="isQuestionAssignment && isFetchingStudentsDone">
        <div v-if="numOfStudentsNotDone > 0">
          <span v-if="assignment.soft_deadline">
            Students can still submit their responses, but they will be marked as 'late'.
          </span>
          <span v-else>
            Students will no longer be able to submit responses to this assignment.
          </span>
          <br />
          {{ numOfStudentsNotDone }}
          {{ numOfStudentsNotDone === 1 ? 'student has' : 'students have' }}
          not submitted the assignment.
        </div>
        <div v-else> All students have submitted the assignment. </div>
      </div>
    </template>
    <template #modalFooter>
      <button
        class="KogButtonLegacy margin-right-xxs"
        type="button"
        @click.prevent="closeModal"
      >
        Cancel
      </button>
      <button
        class="KogButtonLegacy KogButtonLegacy--danger"
        type="button"
        @click.prevent="confirmEndAssignment"
      >
        End now
      </button>
    </template>
  </kog-modal>
</template>

<script>
import * as schoolStaffApi from '@apis/schoolstaff-assignments.js';
import { fetchAssignmentStudentStatus } from '@apis/schoolstaff-assignments.js';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isQuestionType } from 'sharedApp/services/assignment/assignment-utility-service.js';

export default {
  name: 'EndNowModal',
  components: {
    KogModal,
    KogLoader,
  },
  mixin: [RoutesMixin],
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    afterEndAssignment: {
      type: Function,
      default: () => {},
    },
    source: {
      type: String,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      numOfStudentsNotDone: 0,
      isFetchingStudentsDone: false,
    };
  },
  computed: {
    successMessage() {
      return 'This assignment has ended and can now be found in your "Past assignments"';
    },
    isQuestionAssignment() {
      return isQuestionType(this.assignment.assignment_type);
    },
  },
  created() {
    this.fetchAssignmentStudentStatus();
  },
  methods: {
    async confirmEndAssignment() {
      await schoolStaffApi.endAssignmentNow(this.assignment.id);
      this.$mixpanel.trackEvent('Assignment - End Assignment', {
        num_subject_classes: this.assignment.subject_classes.length,
        source: this.source,
      });
      this.afterEndAssignment();
      this.$toast.showSuccess(this.successMessage);
      this.closeModal();
    },
    async fetchAssignmentStudentStatus() {
      const data = await fetchAssignmentStudentStatus(this.assignment.id);
      this.handleFetchNumberOfStudentsSuccess(data);
    },
    handleFetchNumberOfStudentsSuccess(data) {
      const totalNumberAssignments = data.studentStatus.total_number;
      const numberAssignmentsSubmitted = data.studentStatus.number_done;

      const numOfStudentsNotDone = totalNumberAssignments - numberAssignmentsSubmitted;
      this.numOfStudentsNotDone = numOfStudentsNotDone || 0;
      this.isFetchingStudentsDone = true;
    },
  },
};
</script>
