<template>
  <block-wrapper
    :content="content"
    :block-label="blockLabel"
    :available-actions="availableActions"
    :status="status"
    @mark-removal="$event => $emit('mark-removal', $event)"
    @unmark-removal="$event => $emit('unmark-removal', $event)"
    @move-down="$event => $emit('move-down', $event)"
    @move-up="$event => $emit('move-up', $event)"
    @edit="handleEdit"
  >
    <div v-if="content.title">
      <span class="heading-xs">
        {{ content.title }}
      </span>
    </div>
    <div v-if="content.question">
      {{ questionTypeText }}
      {{ questionPreview }}
    </div>

    <div v-else>
      <span v-if="!content.question_uuid && !content.id">
        No question selected, please
        <kog-button
          size="small"
          label="select one"
          button-style="accent"
          @click="() => handleEdit(content)"
        />
      </span>
      <span v-else>
        There's a problem with current selected question, please remove block or solve the issue.
      </span>
    </div>
  </block-wrapper>
</template>

<script>
import {
  BLOCK_TYPE,
  getBlockTypeLabel,
} from 'publishingApp/store/modules/feature/activity-constants.ts';
import { getQuestionIdFromQuestionBlock } from 'publishingApp/utils/question-block-utils.js';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import {
  dangerouslyGetUnescapedText,
  getQuestionTypeText,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

import BlockWrapper, { ITEM_ACTIONS } from '../../block-wrapper.vue';
import BlockMixin from '../mixins/block-mixin.js';
import EditQuestionModal from './edit-modal.vue';

export default {
  name: 'QuestionBlock',
  components: {
    BlockWrapper,
    KogButton,
  },
  mixins: [BlockMixin],
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  emits: ['mark-removal', 'unmark-removal', 'move-up', 'move-down'],
  data() {
    return {
      availableActions: [ITEM_ACTIONS.MARK_REMOVAL, ITEM_ACTIONS.EDIT_CONTENT],
    };
  },
  computed: {
    blockLabel() {
      const blockType = getBlockTypeLabel(this.content.block_type);

      if (this.isBlockNew) return blockType;
      return `${blockType}: ${this.questionTypeText} (${getQuestionIdFromQuestionBlock(this.content) || 'unknown id'})`;
    },
    questionPreview() {
      return dangerouslyGetUnescapedText(this.content.question.question_html);
    },
    questionTypeText() {
      if (!this.content.question) return '';
      return getQuestionTypeText(this.content.question);
    },
    blockContext() {
      return this.activity.getBlockContext(BLOCK_TYPE.QUESTION);
    },
    isBlockNew() {
      return !this.content.id;
    },
    status() {
      if (this.isBlockNew) return null;

      if (this.content.question_uuid && !this.content.question) {
        return {
          type: 'error',
          message: 'This question seems to be either deleted or not mapped correctly',
        };
      }

      if (!this.content.question_uuid && !this.content.question) {
        return {
          type: 'error',
          message: 'Could not resolve question for this block',
        };
      }

      if (this.content.question && !this.content.question.active) {
        return { type: 'warning', message: 'This question is inactive' };
      }
      return null;
    },
  },
  methods: {
    handleEdit(content) {
      const currentQuestion = this.blockContext?.availableQuestions.find(
        question => question.id === content.question_id || question.id === content.question?.id,
      );

      this.$modal(
        EditQuestionModal,
        {
          model: content,
          activityType: this.activity.type,
          availableQuestions:
            getQuestionIdFromQuestionBlock(content) && currentQuestion
              ? [currentQuestion]
              : this.blockContext.unusedAvailableQuestions,
          onUpdate: this.updateContentItem,
        },
        { closeOnEsc: false, closeOnClickOutside: false },
      );
    },
  },
};
</script>
