<template>
  <div :class="{ 'SideNav--collapsed': !isExpanded }">
    <div
      :class="{
        KogOverlay: isMobile && isExpanded,
        'KogOverlay--white': isMobile && isExpanded,
      }"
    />
    <div class="SideNav">
      <side-navigation-header
        :is-expanded="isExpanded"
        @click="toggleSideMenu"
      />
      <div
        ref="sideNavSubMenu"
        class="SideNav-subMenu"
      >
        <div class="u-animateCollapsed">
          <notebook-subject-tree-menu
            :tree="subjectTree"
            :indicator-component="'annotations-indicator'"
            :current-node="currentNode"
            :annotations-count="annotationsCount"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { mapGetters, mapState } from 'vuex';

import isMobile from 'sharedApp/utils/detect-mobile.js';
import NotebookSubjectTreeMenu from 'studyApp/components/side-navigation/notebook-subject-tree-menu.vue';
import SideNavigationHeader from 'studyApp/components/side-navigation/side-navigation-header.vue';

export default {
  name: 'NoteBookSideNavigation',
  components: {
    NotebookSubjectTreeMenu,
    SideNavigationHeader,
  },
  props: {
    currentNode: {
      type: Object,
      required: true,
    },
    annotationsCount: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
      subjectTree: state => state.subjectModule.subject.subject_tree[0],
    }),
    ...mapGetters({
      progressStats: 'statisticModule/progressStats',
    }),
    isMobile() {
      return isMobile();
    },
  },
  methods: {
    toggleSideMenu(event) {
      const expansion = !this.isExpanded;
      if (isMobile()) {
        if (expansion) {
          disableBodyScroll(this.$refs.sideNavSubMenu);
        } else {
          enableBodyScroll(this.$refs.sideNavSubMenu);
        }
      }
      this.isExpanded = expansion;
      if (event && event.stopPropagation) {
        event.stopPropagation();
      }
    },
  },
};
</script>

<style scoped>
.SideNav {
  cursor: default;

  position: fixed;
  z-index: 100;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 320px;
  height: 90%;

  color: var(--kog-text-default);

  transition: width 0.15s ease-in-out 0s;
}

.SideNav-subMenu {
  overflow-y: scroll;
  flex: 1;
  background-color: var(--kogPlatformWhite);
  box-shadow: 0 12px 24px -6px var(--kogShadow020);
}

.SideNav--collapsed .SideNav {
  cursor: pointer;
  width: 0;
}

.SideNav--collapsed .SideNav a {
  cursor: pointer;
  color: var(--kog-text-default);
}

.SideNav--collapsed .SideNav .u-hideWhenCollapsed {
  display: none;
}

.SideNav .u-animateCollapsed {
  transform: translate(0, 0);
  transition:
    transform 0.15s ease-in-out 0s,
    opacity 0.15s ease-in-out 0s;
}

.SideNav--collapsed .SideNav .u-animateCollapsed {
  transform: translate(-320px, 0);
  opacity: 0;
}

.SideNav-bottomButtons {
  width: 100%;
  text-align: center;
  background-color: var(--kog-background-default-0);
  border-top: solid 1px var(--kog-border-default-300);
}
</style>
