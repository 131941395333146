const documentType = 'DOC';
const presentationType = 'PRE';
const spreadsheetType = 'SPR';

const documentIcon = require('sharedApp/img/study/supporting-material-link-document.svg');
const presentationIcon = require('sharedApp/img/study/supporting-material-link-presentation.svg');
const spreadsheetIcon = require('sharedApp/img/study/supporting-material-link-spreadsheet.svg');

const SUPPORTING_MATERIAL = {
  DOCUMENT: {
    TYPE: documentType,
    ICON: documentIcon,
  },
  PRESENTATION: {
    TYPE: presentationType,
    ICON: presentationIcon,
  },
  SPREADSHEET: {
    TYPE: spreadsheetType,
    ICON: spreadsheetIcon,
  },
};

export function getSupportingMaterialIconUrl(type) {
  switch (type) {
    case SUPPORTING_MATERIAL.DOCUMENT.TYPE:
      return SUPPORTING_MATERIAL.DOCUMENT.ICON;
    case SUPPORTING_MATERIAL.PRESENTATION.TYPE:
      return SUPPORTING_MATERIAL.PRESENTATION.ICON;
    case SUPPORTING_MATERIAL.SPREADSHEET.TYPE:
      return SUPPORTING_MATERIAL.SPREADSHEET.ICON;
    default:
      return '';
  }
}

export function supportingMaterialTypeToText(type) {
  switch (type) {
    case SUPPORTING_MATERIAL.DOCUMENT.TYPE:
      return 'Doc';
    case SUPPORTING_MATERIAL.PRESENTATION.TYPE:
      return 'Presentation';
    case SUPPORTING_MATERIAL.SPREADSHEET.TYPE:
      return 'Spreadsheet';
    default:
      return '';
  }
}

export default SUPPORTING_MATERIAL;
