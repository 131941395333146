<template>
  <kog-new-navbar
    ref="StudyNavbar"
    class="StudyNavbar"
    :class="{
      'StudyNavbar--hidden': isHidden,
      'StudyNavbar--hiddenMobile': isHiddenMobile,
    }"
    :right-slot-expanded="searchExpanded"
    :search-expanded="searchExpanded ? true : null"
    :is-transparent="isShowingTransparent"
    role="banner"
    @on-mouse-leave="onNavbarMouseLeave"
  >
    <template #leftCornerSlot>
      <class-picker-dropdown
        :subject-classes="subjectClasses"
        :subjects-by-id="subjectsById"
        :is-transparent="isShowingTransparent"
        :user="user"
      />
    </template>
    <template
      v-if="menuItems.length > 0"
      #middleSlot
    >
      <navbar-center-menu
        v-if="selectedMenu"
        ref="mainMenu"
        :menu-items="menuItems"
        :select-menu-item="selectMenuItem"
        :selected-menu-item="selectedMenu"
        :is-transparent="isShowingTransparent"
        @hover-menu-item="onHoverMenuItem"
        @focus-sub-menu="focusMenu($refs.subMenu.$refs.root)"
      />
    </template>
    <template
      v-if="subMenuItemsToRender.length > 0"
      #subMenuSlot
    >
      <navbar-center-menu
        v-if="!searchExpanded"
        ref="subMenu"
        :menu-items="subMenuItemsToRender"
        :select-menu-item="selectSubMenu"
        :selected-menu-item="selectedSubMenu"
        :is-transparent="isShowingTransparent"
        :is-submenu="true"
        @focus-main-menu="focusMenu($refs.mainMenu.$refs.root)"
      />
    </template>
    <template #rightSlot>
      <study-navbar-account-group
        class="margin-0 padd-left-0"
        :user="user"
        :subject="subject"
        :notifications="notifications"
        :mark-as-seen="markAsSeen"
        theme="light"
      />
    </template>
  </kog-new-navbar>
</template>

<script>
import { nextTick } from 'vue';
import throttle from 'lodash/throttle.js';

import ClassPickerDropdown from 'learning/common/components/class-picker-dropdown.vue';
import StudyNavbarAccountGroup from 'learning/common/components/study-navbar-account-group.vue';

import KogNewNavbar from 'sharedApp/components/core/kog-new-navbar.vue';
import NavbarCenterMenu from 'sharedApp/components/core/navbar-center-menu.vue';

const NAVBAR_CONFIG = {
  sectionText: {
    hideOnScroll: true,
  },
  dashboard: {
    hideOnScrollMobile: true,
  },
  subjectOverview: {
    hideOnScrollMobile: true,
  },
  assignmentsOverview: {
    hideOnScrollMobile: true,
  },
  practiceTestSetup: {
    hideOnScrollMobile: true,
  },
  esqSetup: {
    hideOnScrollMobile: true,
  },
  practiceBattleSetup: {
    hideOnScrollMobile: true,
  },
  examStyleQuestionsTake: {
    hideOnScroll: true,
  },
  practiceTest: {
    hideOnScroll: true,
  },
  practiceBattleTake: {
    hideOnScroll: true,
  },
  practiceBattleResult: {
    hideOnScroll: true,
  },
};

export default {
  name: 'StudyNavbar',
  components: {
    KogNewNavbar,
    NavbarCenterMenu,
    ClassPickerDropdown,
    StudyNavbarAccountGroup,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    markAsSeen: {
      type: Function,
      default: () => {},
    },
    subject: {
      type: Object,
      default: () => {},
    },
    subjectClasses: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      required: true,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    subMenuItems: {
      type: Array,
      default: () => [],
    },
    selectMenuItem: {
      type: Function,
      default: () => {},
    },
    selectedMenu: {
      type: String,
      default: 'none',
    },
    selectSubMenu: {
      type: Function,
      default: () => {},
    },
    selectedSubMenu: {
      type: String,
      default: 'none',
    },
    routeName: {
      type: String,
      required: true,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    subjectsById: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hoveredItem: null,
      searchExpanded: false,
      isHidden: false,
      isHiddenMobile: false,
      canShowTransparent: true,
      canHide: false,
      onlyHideOnMobile: false,
      prevScrollPos: 0,
      onScrollListener: throttle(this.evaluateNavbarDisplay, 100),
    };
  },
  computed: {
    subMenuItemsToRender() {
      const hoveringSubItems = this.hoveredItem?.subMenuItems;
      if (hoveringSubItems) {
        return hoveringSubItems;
      }
      return this.subMenuItems;
    },
    isShowingTransparent() {
      return this.isTransparent && this.canShowTransparent;
    },
  },
  watch: {
    routeName: {
      handler(name) {
        this.isHidden = false;
        this.isHiddenMobile = false;
        this.canShowTransparent = true;
        window.removeEventListener('scroll', this.onScrollListener, false);
        const config = NAVBAR_CONFIG[name];
        if (config) {
          this.canHide = true;
          if (config.hideOnScroll) {
            this.onlyHideOnMobile = false;
          } else if (config.hideOnScrollMobile) {
            this.onlyHideOnMobile = true;
          }
          nextTick(() => {
            this.prevScrollPos = window.pageYOffset;
          });
          window.addEventListener('scroll', this.onScrollListener, false);
        } else if (this.isTransparent) {
          window.addEventListener('scroll', this.onScrollListener, false);
        }
      },
      immediate: true,
    },
  },
  methods: {
    onHoverMenuItem(menuItemKey) {
      this.hoveredItem = this.menuItems.find(item => item.key === menuItemKey);
    },
    onNavbarMouseLeave() {
      this.hoveredItem = null;
    },
    focusMenu(element) {
      element.querySelector('.NavbarCenterMenu-menuItem[tabindex="0"]').focus();
    },
    evaluateNavbarDisplay() {
      const currentScrollPos = window.pageYOffset;
      const scrollMaxY = document.body.scrollHeight - window.innerHeight;
      const delta = 10;

      this.canShowTransparent = currentScrollPos < 60;

      if (!this.canHide) {
        return;
      }

      if (Math.abs(this.prevScrollPos - currentScrollPos) <= delta) {
        return;
      }
      if (currentScrollPos <= delta || this.prevScrollPos > currentScrollPos) {
        this.show();
      } else if (currentScrollPos >= scrollMaxY || this.prevScrollPos < currentScrollPos) {
        this.hide();
      }
      this.prevScrollPos = currentScrollPos;
    },
    show() {
      this.isHidden = false;
      this.isHiddenMobile = false;
    },
    hide() {
      if (this.onlyHideOnMobile) {
        this.isHiddenMobile = true;
      } else {
        this.isHidden = true;
      }
    },
  },
};
</script>

<style scoped>
.StudyNavbar {
  top: 0;
  transition-duration: 0.4s;
}

.StudyNavbar--hidden {
  transform: translateY(-100%);
}

@media (width < 520px) {
  .StudyNavbar-searchBar {
    display: none;
  }
}

@media (--viewport-s) {
  .StudyNavbar--hiddenMobile {
    transform: translateY(-100%);
  }
}
</style>
