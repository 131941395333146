<template>
  <kog-icon
    v-kog-focusable="showTooltip"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: showTooltip ? iconDetails.tooltip : '',
      boundary: 'document.body',
    }"
    :icon-class="iconDetails.iconClass"
    :size="size"
    :fa-style="iconDetails.style"
    :is-svg-icon="iconDetails.isSVG"
    :theme="theme"
  />
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogFocusable from 'sharedApp/directives/kog-focusable.js';
import { getIconDetails } from 'sharedApp/services/assignment/assignment-utility-service.js';

export default {
  name: 'AssignmentTypeIcon',
  directives: {
    tooltip: VTooltip,
    KogFocusable,
  },
  components: {
    KogIcon,
  },
  props: {
    assignmentType: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 's',
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: 'dark',
    },
  },
  computed: {
    iconDetails() {
      return getIconDetails(this.assignmentType);
    },
  },
};
</script>
