const esqPrintCss = `
  body {
    font-size: 14px;
    margin-top: 1em;
    font-family: "Times New Roman", Times, serif;
    padding: 2em;
  }
  .question {
    padding: 1em;
  }
  @media print {
    .question {
      page-break-after: always;
    }
  }
  .answer {
    border: 1px solid black;
    margin-top: 1em;
    padding: 0.5em;
  }
  table {
    border-collapse: collapse;
    margin: 0.5em;
    padding: 0;
  }
  table td {
    border-bottom: 1px solid gray;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  table tr:last-child td {
    border-bottom: none;
    padding-bottom: 0;
  }
span.MJX_Assistive_MathML {
  display: none;
}
`;

function assembleEsqForPrinting(assignment, withAnswers) {
  const questionCount = assignment.questions.length;
  let printedHtml = '';

  assignment.questions.forEach((question, index) => {
    const levelNames = question.attributes.levels?.map(level => level.name).join(', ');
    const subjectNodeName = question.subjectnode_mappings[0].name;
    const cleanHtml = question.question_html.replace(/<iframe.*>.*<\/iframe>/, ' ');

    printedHtml += '<div class="question">';
    printedHtml += `<h2>Question ${index + 1} of ${questionCount}</h2>`;
    printedHtml += `<p>${subjectNodeName}</p>`;
    if (levelNames) {
      printedHtml += `<p>Levels: ${levelNames}</p>`;
    }
    printedHtml += `<p>Marks: ${question.context.marks}</p>`;
    printedHtml += cleanHtml;
    printedHtml += '</div>';
  });
  if (withAnswers) {
    printedHtml += `<h2>Answers</h2>`;
    assignment.questions.forEach((question, index) => {
      printedHtml += `<div class="answer">`;
      printedHtml += `<p>Question ${index + 1} of ${questionCount}</p>`;
      printedHtml += question.explanation;
      printedHtml += '</div>';
    });
  }
  return printedHtml;
}

export { assembleEsqForPrinting, esqPrintCss };
