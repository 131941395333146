<template>
  <ul class="MultipleChoiceQuestionResultIncorrectChoices list-group">
    <li
      v-for="(choice, index) in choices"
      :key="index"
      :class="{
        disabled: !isSelectedByUser(choice),
        'MultipleChoiceQuestionResultIncorrectChoices-incorrectUserChoice':
          isSelectedByUser(choice),
      }"
      class="padd-top-1 list-group-item"
    >
      <div
        :class="{
          'is-topRight': index === 0,
        }"
        class="MultipleChoiceQuestionResultIncorrectChoices-questionNumber"
        aria-hidden="true"
      >
        {{ choice._numberSymbol }}
      </div>
      <p
        v-if="isSelectedByUser(choice)"
        class="heading-xxs"
      >
        Your choice
      </p>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-kog-mathjax
        class="content-editable"
        v-html="choice.answer_html"
      />
      <!-- eslint-enable vue/no-v-html -->
      <p
        v-if="isSelectedByOpponent(choice) && opponentName"
        class="kogPlatformGray031"
      >
        {{ opponentName }}'s choice
      </p>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'MultipleChoiceQuestionResultIncorrectChoices',
  props: {
    userResponse: {
      type: Array,
      default: undefined,
    },
    correctChoiceIds: {
      type: Array,
      required: true,
    },
    mcqChoices: {
      type: Array,
      default: () => [],
    },
    opponentChoiceIds: {
      type: Array,
      default: () => [],
    },
    opponentName: {
      type: String,
      default: 'Opponent',
    },
  },
  computed: {
    choices() {
      return this.mcqChoices.filter(choice => !this.correctChoiceIds.includes(choice.id));
    },
  },
  methods: {
    isSelectedByOpponent(choice) {
      if (!this.opponentChoiceIds) return false;
      return this.opponentChoiceIds.includes(choice.id);
    },
    isSelectedByUser(choice) {
      return this.userResponse && this.userResponse.includes(choice.id);
    },
  },
};
</script>
<style scoped>
.MultipleChoiceQuestionResultIncorrectChoices {
  display: block;

  margin: 0;
  padding: 0;

  background: none;
  border: none;
}

.MultipleChoiceQuestionResultIncorrectChoices :deep(.content-editable img) {
  pointer-events: none;
}

.MultipleChoiceQuestionResultIncorrectChoices-questionNumber {
  position: absolute;
  top: -1px;
  right: -1px;

  padding: 5px;

  color: var(--kogPlatformWhite);
  text-align: center;

  background-color: var(--kogPlatformGray031);
  border-radius: 0 0 0 5px;
}

.MultipleChoiceQuestionResultIncorrectChoices-questionNumber.is-topRight {
  border-radius: 0 4px 0 5px;
}

.MultipleChoiceQuestionResultIncorrectChoices-incorrectUserChoice {
  color: var(--kogPlatformRedDarken20);
  background-color: var(--kogPlatformRedLighten20);
}
</style>
