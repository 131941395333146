<template>
  <div
    class="PracticeItemResult panel margin-bottom-m"
    :class="{
      PracticeItemResult__rightAnswer: practiceItem.user_answer.is_correct,
      PracticeItemResult__wrongAnswer: !practiceItem.user_answer.is_correct,
    }"
  >
    <button
      :aria-expanded="sectionIsExpanded ? 'true' : 'false'"
      aria-haspopup="true"
      :aria-controls="`question${questionIndex}`"
      class="PracticeItemResult__header padd-top-m padd-bottom-m"
      :class="{
        dropup: sectionIsExpanded,
      }"
      @click="toggleResult"
    >
      <span class="panel-title heading-xs">
        <i
          class="margin-right-m"
          :class="{
            'fas fa-check': practiceItem.user_answer.is_correct,
            'fas fa-times': !practiceItem.user_answer.is_correct,
          }"
        />
        Question {{ questionIndex }}
        <span class="margin-left-m caret" />
      </span>
      <span
        v-if="opponentPracticeItem"
        class="PracticeItemResult__opponentScore kogPlatformGray031 heading-xs"
        aria-hidden="true"
      >
        <strong>{{ opponentName }}:</strong>
        <i
          class="PracticeItemResult__opponentScoreIcon"
          :class="{
            'fas fa-check PracticeItemResult__correctUserChoice':
              opponentPracticeItem.user_answer.is_correct,
            'fas fa-times PracticeItemResult__incorrectUserChoice':
              !opponentPracticeItem.user_answer.is_correct,
          }"
        />
      </span>
    </button>
    <div
      v-show="sectionIsExpanded"
      :id="`question${questionIndex}`"
      class="padd-l clearfix"
    >
      <div class="flexContainer flexContainer-alignCenter margin-top-m flexContainer-spaceBetween">
        <p class="heading-xxs margin-bottom-xxs"> Question </p>
        <question-tags
          v-if="subject && practiceItem"
          :tags="resolvedQuestion.tags"
          :show-difficulty="showDifficulty"
          :subject-id="subject.id"
        />
      </div>
      <question-text
        v-if="!isFBQ"
        :question="questionWithUpdatedQuestionHtml"
        class="margin-bottom-1"
      />

      <div v-if="isMCQ">
        <p class="divider-top padd-top-xxl margin-top-1 heading-xxs margin-bottom-xxs">
          Correct choices
        </p>
        <multiple-choice-question-result-correct-choice
          v-for="correctChoice in mcqSolution"
          :key="correctChoice.id"
          :correct-choice="correctChoice"
          :answer-explanation-html="correctChoice.answer_explanation_html"
          :user-response="mutablePracticeItem.user_answer.user_response"
          :is-opponent-answer-correct="isOpponentAnswerCorrect"
          :opponent-name="opponentName"
          class="margin-bottom-1 margin-top-0"
        />
        <p class="divider-top padd-top-xxl margin-top-1 heading-xxs margin-bottom-xxs">
          Incorrect choices
        </p>
        <multiple-choice-question-result-incorrect-choices
          :mcq-choices="mcqChoices"
          :correct-choice-ids="correctChoiceIds"
          :user-response="mutablePracticeItem.user_answer.user_response"
          :opponent-choice-ids="opponentChoiceIds"
          :opponent-name="opponentName"
          class="margin-bottom-1 margin-top-0"
        />
      </div>
      <kog-short-text-question-result
        v-if="isSTQ"
        :practice-item="mutablePracticeItem"
      />
      <div v-if="isFBQ">
        <fill-in-the-blanks-question
          :question="questionWithUpdatedQuestionHtml"
          :disable-user-input="true"
          :user-answers="mutablePracticeItem.user_answer.user_answers"
          :is-showing-answer-correctness="true"
          :answers-with-correctness="mutablePracticeItem.solution.correct_answers"
          :display-input-index="true"
        />
        <fill-in-the-blanks-solution
          :solution="mutablePracticeItem.solution"
          :question-html="resolvedQuestion.question_html"
        />
      </div>

      <report-feedback-button
        v-if="resolvedQuestion && !isTeacherCreatedQuestion"
        :subject-node-id="subjectNodeRootId"
        :content-id="resolvedQuestion.id"
        :categories-to-hide="[]"
        :student-answer="studentAnswer(mutablePracticeItem)"
        class="margin-top-fine-2 pull-right"
        :aria-label="`Report feedback or error on Question ${questionIndex}`"
      />
      <div
        v-if="resolvedQuestion && isTeacherCreatedQuestion"
        class="margin-top-fine-2 pull-right"
      >
        Feedback to teachers' questions is disabled
      </div>
    </div>
  </div>
</template>

<script>
import { castArray, cloneDeep } from 'lodash';

import ReportFeedbackButton from 'learning/common/components/report-feedback-button.vue';

import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import FillInTheBlanksSolution from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-solution.vue';
import KogShortTextQuestionResult from 'sharedApp/components/questions/kog-short-text-question-result.vue';
import MultipleChoiceQuestionResultCorrectChoice from 'sharedApp/components/questions/multiple-choice-question-result-correct-choice.vue';
import MultipleChoiceQuestionResultIncorrectChoices from 'sharedApp/components/questions/multiple-choice-question-result-incorrect-choices.vue';
import QuestionText from 'sharedApp/components/questions/question-text.vue';
import mixpanelModule from 'sharedApp/libs/mixpanel.js';
import multipleChoiceQuestionNumberingService from 'sharedApp/services/questions/multipleChoiceQuestionNumbering/multiple-choice-question-numbering-service.js';
import {
  CREATED_BY_TEACHER_PLAIN_TEXT_TAG,
  CREATED_BY_TEACHER_TAG,
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import QuestionTags from 'studyApp/components/questions/question-tags.vue';
import QuestionDifficultyMixin from 'studyApp/mixins/question-difficulty-mixin.js';

import { studentAnswer } from '../utils/practice-item-utils.js';

const mcqNumberingService = multipleChoiceQuestionNumberingService();

export default {
  name: 'PracticeItemResult',
  components: {
    FillInTheBlanksSolution,
    ReportFeedbackButton,
    KogShortTextQuestionResult,
    MultipleChoiceQuestionResultIncorrectChoices,
    MultipleChoiceQuestionResultCorrectChoice,
    QuestionText,
    FillInTheBlanksQuestion,
    QuestionTags,
  },
  mixins: [QuestionDifficultyMixin],
  props: {
    practiceItem: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      default: null,
    },
    opponentPracticeItem: {
      type: Object,
      default: null,
    },
    opponentName: {
      type: String,
      default: '',
    },
    subject: {
      type: Object,
      default: null,
    },
    source: {
      type: String,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      mutablePracticeItem: null,
      sectionIsExpanded: false,
    };
  },
  computed: {
    opponentChoiceIds() {
      return this.opponentPracticeItem?.user_answer?.user_response;
    },
    isOpponentAnswerCorrect() {
      return this.opponentPracticeItem ? this.opponentPracticeItem.user_answer.is_correct : null;
    },
    isTeacherCreatedQuestion() {
      if (this.mutablePracticeItem.question) {
        return !!this.mutablePracticeItem.question.teacher_question;
      }
      return (
        this.question?.tags.includes(CREATED_BY_TEACHER_TAG) ||
        this.question?.tags.includes(CREATED_BY_TEACHER_PLAIN_TEXT_TAG)
      );
    },
    questionWithUpdatedQuestionHtml() {
      return this.getQuestionWithUpdatedHtml(this.resolvedQuestion, this.showDifficulty);
    },
    isMCQ() {
      return isMCQ(this.resolvedQuestion);
    },
    isSTQ() {
      return isSTQ(this.resolvedQuestion);
    },
    isFBQ() {
      return isFBQ(this.resolvedQuestion);
    },
    solutionAsArray() {
      return castArray(this.mutablePracticeItem.solution);
    },
    correctChoiceIds() {
      return this.solutionAsArray.map(choice => choice.id);
    },
    mcqChoices() {
      return (
        this.mutablePracticeItem.question?.multiplechoicequestionchoice_set ||
        this.question.context.choices
      );
    },
    mcqSolution() {
      return this.solutionAsArray.map(choice => {
        const numberSymbol =
          // eslint-disable-next-line no-underscore-dangle
          this.mcqChoices.find(mcqChoice => mcqChoice.id === choice.id)._numberSymbol;

        return {
          ...choice,
          _numberSymbol: numberSymbol,
        };
      });
    },
    subjectNodeRootId() {
      return this.subject?.subject_tree[0].id;
    },
    resolvedQuestion() {
      return this.mutablePracticeItem.question || this.question;
    },
  },
  watch: {
    practiceItem: {
      handler(item) {
        this.mutablePracticeItem = cloneDeep(item);
      },
      immediate: true,
    },
  },
  created() {
    this.applyNumberingService();
  },
  methods: {
    studentAnswer,
    applyNumberingService() {
      if (this.isMCQ) {
        mcqNumberingService.updateMultipleChoiceQuestionWithNumbering(
          this.mutablePracticeItem.question,
          false,
          this.mcqChoices,
        );

        this.solutionAsArray.forEach(solution => {
          mcqNumberingService.updateSeparateMultipleChoiceQuestionChoiceWithNumbering(
            this.mutablePracticeItem.question,
            solution,
            this.mcqChoices,
          );
        });
      }
    },
    toggleResult() {
      const eventName = this.sectionIsExpanded
        ? 'Practice Item Result - Collapse'
        : 'Practice Item Result - Expand';
      mixpanelModule.trackEvent(eventName, {
        source: this.source,
        subject: this.subject.name,
        is_correct: this.mutablePracticeItem.user_answer.is_correct,
      });
      this.sectionIsExpanded = !this.sectionIsExpanded;
    },
  },
};
</script>

<style scoped>
.PracticeItemResult__header {
  position: relative;

  width: 100%;
  padding: 10px 15px;

  text-align: left;

  border: none;
  border-radius: 4px;
}

.PracticeItemResult__header[aria-expanded='true'] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.PracticeItemResult__opponentScore {
  position: absolute;
  top: 10px;
  right: 10px;
}

.PracticeItemResult__opponentScoreIcon {
  margin-left: 5px;
  padding: 2px;
  border: 1px solid var(--kogPlatformGray084);
  border-radius: 50%;
}

/* Special size fix for the fa-times icon */
.PracticeItemResult__opponentScoreIcon.fas.fa-times {
  padding: 2px 3px;
}

.PracticeItemResult__wrongAnswer {
  border-color: var(--kogPlatformRedLighten20);
}

.PracticeItemResult__wrongAnswer > .PracticeItemResult__header {
  color: var(--kogPlatformRedDarken20);
  background-color: var(--kogPlatformRedLighten20);
}

.PracticeItemResult__rightAnswer {
  border-color: var(--kogPlatformGreenLighten20);
}

.PracticeItemResult__rightAnswer > .PracticeItemResult__header {
  color: var(--kogPlatformGreenDarken20);
  background-color: var(--kogPlatformGreenLighten20);
}

.PracticeItemResult__incorrectUserChoice {
  color: var(--kogPlatformRedDarken20);
  background-color: var(--kogPlatformRedLighten20);
}

.PracticeItemResult__correctUserChoice {
  color: var(--kogPlatformGreenDarken20);
  background-color: var(--kogPlatformGreenLighten20);
}
</style>
