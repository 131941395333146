<template>
  <battle-page-container :loading="$wait.is('loading-battle')">
    <template #header>
      <div
        v-if="currentBattle"
        class="kog-row margin-bottom-l"
      >
        <battle-header>
          <div class="margin-bottom-m">
            <kog-ball-step-paginator
              :number-of-pages="questions.length"
              :current-page="currentQuestionNumber"
            />
          </div>
          <countdown-timer
            v-if="!$wait.is('loading-battle')"
            v-model:value="timeLeft"
            class="margin-bottom-0"
            :active="waitingForAnswer"
            @end="timeUp"
          />
        </battle-header>
      </div>
    </template>
    <template v-if="!isLoading">
      <p
        ref="initialFocus"
        tabindex="-1"
        class="screenreader-only"
      >
        {{ getScreenReaderText }}
      </p>
      <div
        v-if="currentQuestionNumber !== null"
        class="kog-row"
      >
        <div
          class="kog-col-12 margin-bottom-m flexContainer flexContainer-alignCenter flexContainer-spaceBetween"
        >
          <h2 class="inline-block heading-s"> Question {{ currentQuestionNumber }} </h2>
          <question-tags
            v-if="subject && currentQuestion"
            :tags="currentQuestion.tags"
            :show-difficulty="showDifficulty"
            :subject-id="subject.id"
          />
        </div>
      </div>
      <multiple-choice-question
        :multi-select="isMultiSelectMCQ"
        :question="questionWithUpdatedQuestionHtml"
        :choices="currentChoices"
        :choice-state="choiceState"
        :solution-ids="solution ? solution : []"
        :type="solution ? 'solution' : 'normal'"
        :disabled="!!solution"
        @input="onSelect"
      />
      <kog-button
        v-if="waitingForAnswer"
        class="flexChild-flexEnd margin-top-m"
        label="Submit answer"
        button-style="primary"
        :disabled="!hasSelectedChoice"
        @click="submitAnswer"
      />
      <kog-button
        v-else
        class="flexChild-flexEnd margin-top-m"
        :label="isLastQuestion ? 'Show results' : 'Next question'"
        button-style="primary"
        :has-icon="true"
        icon-class="fa-chevron-right"
        icon-position="right"
        @click="nextQuestion"
      />
    </template>
  </battle-page-container>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { shuffle } from 'lodash';
import { mapActions, mapState, useStore } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogBallStepPaginator from 'sharedApp/components/base/pagination/kog-ball-step-paginator.vue';
import { setChoiceItemState } from 'sharedApp/components/multiple-choice-question/choice-state-helper.js';
import MultipleChoiceQuestion from 'sharedApp/components/multiple-choice-question/multiple-choice-question.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import userInitials from 'sharedApp/libs/user-initials.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { shouldShowDifficultyTagForSubject } from 'sharedApp/services/featureflags/fixed-feature-flags.ts';
import { answerQuestion, fetchBattleQuestions } from 'studyApp/api/battle.js';
import QuestionTags from 'studyApp/components/questions/question-tags.vue';
import QuestionDifficultyMixin from 'studyApp/mixins/question-difficulty-mixin.js';

import BattleHeader from './components/battle-header.vue';
import BattlePageContainer from './components/battle-page-container.vue';
import CountdownTimer from './components/countdown-timer.vue';
import BattleMixin from './mixins/battle-mixin.js';

const ONE_WEEK_IN_MILLISECONDS = 604800000;

export default {
  name: 'BattleTake',
  components: {
    CountdownTimer,
    BattleHeader,
    BattlePageContainer,
    KogBallStepPaginator,
    QuestionTags,
    KogButton,
    MultipleChoiceQuestion,
  },
  mixins: [RoutesMixin, QuestionDifficultyMixin, BattleMixin],
  setup() {
    const store = useStore();
    const { subjectModule } = store.state;

    const subjectName = computed(() => subjectModule.subject.name);

    useHead({
      title: `Take strength battle | ${subjectName.value}`,
    });
  },
  data() {
    return {
      questions: [],
      currentQuestionNumber: null,
      timeLeft: this.secondsPerQuestion,
      waitingForAnswer: false,
      solution: null,
      showDifficulty: false,
      choiceState: {},
    };
  },
  computed: {
    ...mapState('subjectModule', ['subject']),
    ...mapState('battlesModule', ['currentBattle']),
    battleId() {
      return this.$route.params.battleId;
    },
    currentItem() {
      return this.questions[this.currentQuestionNumber - 1];
    },
    currentQuestion() {
      return this.currentItem?.question;
    },
    currentChoices() {
      return shuffle(this.currentQuestion?.multiplechoicequestionchoice_set);
    },
    questionWithUpdatedQuestionHtml() {
      return this.getQuestionWithUpdatedHtml(this.currentQuestion, this.showDifficulty);
    },
    isLoading() {
      return this.$wait.is('loading-battle');
    },
    getScreenReaderText() {
      if (!this.currentQuestionNumber || this.currentQuestionNumber === 1) {
        return 'Battle has started, good luck!';
      }

      return 'New question';
    },
    isLastQuestion() {
      return this.currentQuestionNumber === this.questions.length;
    },
    isMultiSelectMCQ() {
      return this.currentItem.question?.is_multi_select;
    },
    hasSelectedChoice() {
      return Object.values(this.choiceState).some(choice => choice.selected);
    },
  },
  created() {
    this.showDifficulty = shouldShowDifficultyTagForSubject(this.subject.id);
    this.$wait.start('loading-battle');
  },
  async mounted() {
    try {
      await this.fetchBattle(this.battleId);
      if (this.currentBattle.is_done || !this.currentBattle.is_your_turn) {
        await this.navigateToBattleResult({
          replace: true,
          battleId: this.battleId,
        });
        return;
      }

      this.startBattle();
    } catch (err) {
      await this.handleError('There was an error loading this page, please try again');
      throw err;
    }

    const unwatch = this.$watch('isLoading', loading => {
      if (loading === false && this.$refs.initialFocus) {
        this.$refs.initialFocus.focus();

        unwatch();
      }
    });
  },
  methods: {
    ...mapActions('battlesModule', ['fetchBattle']),
    async startBattle() {
      try {
        const response = await fetchBattleQuestions(this.battleId);
        if (response.has_errors) {
          await this.handleError(response.errors[0]);
          return;
        }
        this.questions = response.questions;
      } catch (err) {
        await this.handleError('There was an error loading the battle questions, please try again');
        throw err;
      }
      this.$wait.end('loading-battle');
      this.nextQuestion();
    },
    async handleError(msg) {
      this.$toast.showError(
        msg,
        {},
        {
          time: ONE_WEEK_IN_MILLISECONDS,
        },
      );
      await this.navigateToBattleSetup({ replace: true });
    },
    async nextQuestion() {
      if (this.$refs.initialFocus) {
        this.$refs.initialFocus.focus();
      }

      this.currentQuestionNumber = this.currentQuestionNumber || 0;
      if (this.currentQuestionNumber === this.questions.length) {
        intercomModule.trackEvent('completed-strength-battle');
        await this.navigateToBattleResult({ battleId: this.battleId });
        return;
      }
      this.currentQuestionNumber += 1;
      this.choiceState = {};
      this.solution = null;
      this.timeLeft = this.secondsPerQuestion;
      this.waitingForAnswer = true;
    },
    onSelect(selectionEvent) {
      this.choiceState = setChoiceItemState(
        this.isMultiSelectMCQ ? this.choiceState : {},
        selectionEvent.answer,
        {
          selected: selectionEvent.selected,
        },
      );
    },
    async submitAnswer() {
      if (!this.waitingForAnswer) {
        return;
      }
      this.waitingForAnswer = false;

      try {
        const selectedChoice = Object.entries(this.choiceState).reduce((acc, [key, value]) => {
          if (value.selected) {
            acc.push(key);
          }
          return acc;
        }, []);

        const response = await answerQuestion(
          this.classId,
          this.battleId,
          this.currentItem.id,
          selectedChoice,
        );

        this.solution = response.solution;
        const opponentResponse = response.opponent_response;

        if (Array.isArray(opponentResponse)) {
          opponentResponse.forEach(this.setOpponentChoice);
        } else {
          this.setOpponentChoice(opponentResponse);
        }

        selectedChoice.forEach(this.setChoiceCorrectStatus);
      } catch (err) {
        await this.handleError('An error occurred when answering a question');
        throw err;
      }
    },
    timeUp() {
      this.submitAnswer();
    },
    setOpponentChoice(choiceId) {
      const opponentInitials = userInitials(this.currentBattle.opponent_name);
      this.choiceState = setChoiceItemState(this.choiceState, choiceId, {
        itemSlot: opponentInitials,
        correct: this.solution.includes(choiceId),
      });
    },
    setChoiceCorrectStatus(choiceId) {
      this.choiceState = setChoiceItemState(this.choiceState, choiceId, {
        correct: this.solution.includes(parseInt(choiceId, 10)),
      });
    },
  },
};
</script>
