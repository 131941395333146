<template>
  <div class="SubjectClasses">
    <h2 class="heading-s margin-top-xs margin-bottom-xs muted"> Your classes </h2>
    <div class="flexContainer flexContainer-wrap">
      <div
        v-for="subjectClass in subjectClassesWithAccess"
        :key="subjectClass.id"
        class="SubjectClasses-bookContainer margin-bottom-l KogIntercomTour-subject"
      >
        <!-- eslint-disable vuejs-accessibility/anchor-has-content -->
        <router-link
          :to="getSubjectUrl(subjectClass)"
          :aria-label="getClassCardLabel(subjectClass)"
        >
          <subject-class-card
            :subject-name="getSubjectNameById(subjectClass.subject_id)"
            :image-src="getSubjectLogo(subjectClass)"
            :endorsed="isSubjectEndorsed(subjectClass)"
            :education-system-name="getEducationSystem(subjectClass)"
            :locked="false"
            :subject-class-name="subjectClass.name"
          />
        </router-link>
        <!-- eslint-enable -->
      </div>
      <div
        v-for="subjectClass in subjectClassesWithoutAccess"
        :key="subjectClass.id"
        class="SubjectClasses-bookContainer margin-bottom-l KogIntercomTour-subject"
      >
        <subject-class-card
          :subject-name="getSubjectNameById(subjectClass.subject_id)"
          :image-src="getSubjectLogo(subjectClass)"
          :endorsed="isSubjectEndorsed(subjectClass)"
          :education-system-name="getEducationSystem(subjectClass)"
          :locked="true"
          :subject-class-name="subjectClass.name"
        />
      </div>
      <div
        v-if="!isSubjectClassesAvailable"
        class="SubjectClasses-emptyState margin-bottom-l text-regular shadow-m-020 padd-xl"
      >
        <div class="heading-s margin-bottom-l"> Welcome! It's great to see you here! </div>
        <p class="margin-bottom-l">
          You now have access to Kognity - a learning space that will support your studies in a fun
          and effective way.
        </p>
        <p class="margin-bottom-l">
          <b>Your teachers will be adding you to your classes shortly.</b> As soon as you are added
          to a class, it will show here. That's when you get access to our wide range of subject
          specific resources where you can study using interactive material, practice independently,
          track your progress and complete assignments set by your teachers.
        </p>
        <p class="margin-bottom-l">
          <b>Check back again in a few days to get started.</b> See you then!
        </p>
        <p> The Kognity team </p>
      </div>
    </div>
  </div>
</template>

<script>
import SubjectClassCard from 'studyApp/components/dashboard/subject-class-card.vue';

export default {
  name: 'SubjectClasses',
  components: {
    SubjectClassCard,
  },
  props: {
    subjectClassesWithAccess: {
      type: Array,
      required: true,
    },
    subjectClassesWithoutAccess: {
      type: Array,
      required: true,
    },
    subjectsById: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSubjectClassesAvailable() {
      return (
        this.subjectClassesWithAccess.length > 0 || this.subjectClassesWithoutAccess.length > 0
      );
    },
  },
  methods: {
    getSubjectLogo(subjectClass) {
      return this.subjectsById[subjectClass.subject_id].logo_url;
    },
    isSubjectEndorsed(subjectClass) {
      return this.subjectsById[subjectClass.subject_id].endorsed;
    },
    getEducationSystem(subjectClass) {
      return this.subjectsById[subjectClass.subject_id].education_system_name;
    },
    getSubjectUrl(subjectClass) {
      return {
        name: 'subjectClassOverview',
        params: {
          classSlug: `${subjectClass.slug}`,
          sid: `${subjectClass.subject_id}`,
          cid: `${subjectClass.id}`,
        },
      };
    },
    getSubjectNameById(subjectId) {
      return this.subjectsById[subjectId].display_name;
    },
    getClassCardLabel(subjectClass) {
      const subject = this.subjectsById[subjectClass.subject_id];
      if (subject.endorsed) {
        const educationSystemName = this.getEducationSystem(subjectClass);
        const educationSystemLongName = {
          IBDP: 'International Baccalaureate (IB) Diploma Programme',
          IGCSE: 'Cambridge Assessment International Education',
        }[educationSystemName];

        return `${subject.display_name} endorsed by ${educationSystemLongName}`;
      }

      return subject.display_name;
    },
  },
};
</script>

<style scoped>
.SubjectClasses-emptyState {
  background: var(--kogPlatformBlueLighten20);
  border: 1px solid var(--kogPlatformBlueLighten10);
  border-radius: 8px;
}

.SubjectClasses {
  padding: 0 var(--space-m);
}

.SubjectClasses-bookContainer {
  width: 50%;
}

.SubjectClasses-bookContainer:nth-of-type(even) {
  padding-left: var(--space-s);
}

.SubjectClasses-bookContainer:nth-of-type(odd) {
  padding-right: var(--space-s);
}
</style>
