<template>
  <article class="TeacherAssignmentOverview kog-container-fluid padd-bottom-xxl">
    <div
      class="TeacherAssignmentOverview-container container-wideWidth padd-left-m padd-right-m"
      :class="{
        'TeacherAssignmentOverview-container--noBackground': isShowEmptyAssignmentBox,
      }"
    >
      <h1 class="margin-0"> Assignments </h1>
      <div class="margin-bottom-xl">
        {{ subjectName }}
      </div>
      <div
        class="flexContainer flexContainer-alignBaseline"
        :class="alignmentCss"
      >
        <div class="flexContainer">
          <div
            v-if="isDropdownVisible"
            class="margin-right-s"
          >
            <kog-select
              label="Selected class"
              :is-label-hidden="true"
              :options="selectableClassesDropdownList"
              :selected-value="selectedClassId"
              @change="updateClassFilter"
            />
          </div>
          <div
            v-if="isConnectedToAnyGoogleClass"
            class="flexContainer flexContainer-alignCenter"
          >
            <img
              v-tooltip="{
                theme: 'kog-tooltip',
                content: googleClassroomIconInfo,
                popperClass: 'text-center',
                boundary: 'window',
              }"
              :aria-label="googleClassroomIconInfo"
              :src="googleClassroomIcon"
              class="TeacherAssignmentOverview-googleClassroomIcon margin-right-xs"
              :alt="googleClassroomIconInfoNoMarkup"
            />
            <span class="heading-s">Connected to Google Classroom</span>
          </div>
        </div>
        <div class="flexContainer">
          <div
            v-if="isShowExportAssignmentResultButton"
            class="margin-right-s"
          >
            <export-assignments-result
              :subject-class-id="+classId"
              source="Assignments - Past assignments"
            />
          </div>
          <create-assignment-button
            v-if="!isClassAdmin"
            :subject-classes="[subjectClass]"
            :subject="subject"
            :show-info-link="true"
            :source="sources.ASSIGNMENT_OVERVIEW"
            button-type="accent"
            :user="user"
            :css-class-object="{ 'KogButtonLegacy--primary': true }"
            dropdown-placement="bottom-end"
          />
        </div>
      </div>

      <kog-tabs
        v-model:value="activeTab"
        class="flexContainer-flexStart margin-top-m margin-bottom-l"
        :has-transparent-background="true"
        :is-left-aligned="true"
        :tabs="tabs"
        heading-size="xs"
        @input="selectTab"
      />

      <div class="padd-bottom-xs containContent">
        <kog-loader
          :loading="$wait.is(loadingState)"
          :loading-msg="loadingMessage"
        >
          <assignment-list-table
            v-if="isCurrentTabSelected && currentAssignments.length > 0"
            key="current"
            title="Active assignments"
            :user="user"
            :list-type="assignmentListTypes.CURRENT"
            :is-item-selection-allowed="false"
            :assignments="currentAssignments"
            :assignment-count="currentAssignmentsMetadata.count"
            :pagination-number-of-pages="currentAssignmentsMetadata.totalPages"
            :pagination-page-size="currentAssignmentsMetadata.pageSize"
            :update-current-page="goToCurrentAssignmentsPaginationPage"
            :pagination-current-page="currentAssignmentsMetadata.currentPage"
            :go-to-assignment="goToAssignment"
            :sort-callback="sortCurrentAssignmentsCallback"
            :is-sorting="$wait.is('sorting_current_assignments')"
            :end-now="showEndAssignmentNowModal"
            :unschedule="cancelScheduling"
            :copy="copyAssignment"
            :copy-link-to-clipboard="copyAssignmentLinkToClipboard"
            :after-copy-link-to-clipboard="afterCopyAssignmentLinkToClipboard"
          />
          <assignment-list-table
            v-if="isPastTabSelected && pastAssignments.length > 0"
            key="past"
            title="Past assignments"
            :user="user"
            :list-type="assignmentListTypes.PAST"
            :assignments="pastAssignments"
            :assignment-count="pastAssignmentsMetadata.count"
            :pagination-number-of-pages="pastAssignmentsMetadata.totalPages"
            :pagination-page-size="pastAssignmentsMetadata.pageSize"
            :update-current-page="goToPastAssignmentsPaginationPage"
            :pagination-current-page="pastAssignmentsMetadata.currentPage"
            :go-to-assignment="goToAssignment"
            :archive="archive"
            :unarchive="unarchive"
            :after-archive-assignment="afterArchiveAssignment"
            :sort-callback="sortPastAssignmentsCallback"
            :is-sorting="$wait.is('sorting_past_assignments')"
            :copy="copyAssignment"
          />
          <assignment-list-table
            v-if="isDraftTabSelected && draftAssignments.length > 0"
            key="draft"
            title="Draft assignments"
            :user="user"
            :list-type="assignmentListTypes.DRAFT"
            :is-item-selection-allowed="true"
            :assignments="draftAssignments"
            :assignment-count="draftAssignmentsMetadata.count"
            :pagination-number-of-pages="draftAssignmentsMetadata.totalPages"
            :pagination-page-size="draftAssignmentsMetadata.pageSize"
            :update-current-page="goToDraftAssignmentsPaginationPage"
            :pagination-current-page="draftAssignmentsMetadata.currentPage"
            :go-to-assignment="goToAssignment"
            :archive="archive"
            :unarchive="unarchive"
            :print="printAssignment"
            :after-archive-assignment="afterArchiveAssignment"
            :after-delete-assignment="afterDeleteAssignment"
            :sort-callback="sortDraftAssignmentsCallback"
            :is-sorting="$wait.is('sorting_draft_assignments')"
            :copy="copyAssignment"
            :delete="removeAssignment"
            @print-assignment="printAssignment"
          />
          <assignment-list-table
            v-if="isArchivedTabSelected && archivedAssignments.length > 0"
            key="archived"
            title="Archived assignments"
            :user="user"
            :list-type="assignmentListTypes.ARCHIVED"
            :is-item-selection-allowed="false"
            :assignments="archivedAssignments"
            :assignment-count="archivedAssignmentsMetadata.count"
            :pagination-number-of-pages="archivedAssignmentsMetadata.totalPages"
            :pagination-page-size="archivedAssignmentsMetadata.pageSize"
            :update-current-page="goToArchivedAssignmentsPaginationPage"
            :pagination-current-page="archivedAssignmentsMetadata.currentPage"
            :go-to-assignment="goToAssignment"
            :unarchive="unarchive"
            :sort-callback="sortArchivedAssignmentsCallback"
            :is-sorting="$wait.is('sorting_archived_assignments')"
          />
          <empty-assignment-list-box
            v-if="isShowEmptyAssignmentBox"
            :subject-classes="[subjectClass]"
            :subject="subject"
            :had-assignments-before="hadAssignmentsBefore"
            :user="user"
          />
          <div
            v-if="isShowEmptyAssignmentMessage"
            class="margin-top-xxxl text-center"
          >
            There are no {{ assignmentState }} assignments
            <br />
            for this class
          </div>
        </kog-loader>
      </div>
      <div
        v-show="false"
        ref="esqprinthelper"
        v-kog-mathjax
        aria-hidden="true"
      >
      </div>
    </div>
  </article>
</template>

<script>
import { nextTick } from 'vue';
import { useClipboard } from '@vueuse/core';
import unescape from 'lodash/unescape.js';
import { storeToRefs } from 'pinia';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState } from 'vuex';

import * as schoolStaffApi from '@apis/schoolstaff-assignments.js';

import CreateAssignmentButton, {
  SOURCES,
} from 'learning/common/components/assignment/create-assignment-button.vue';
import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import KogTabs from 'sharedApp/components/base/tabs/kog-tabs.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import useAssignments from 'sharedApp/composables/use-assignments.ts';
import useWaitFor from 'sharedApp/composables/use-wait-for.ts';
import googleClassroomIcon from 'sharedApp/img/google-classroom-yellow-icon-64x64_2x.png';
import mixpanelModule from 'sharedApp/libs/mixpanel.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import printHtml from 'sharedApp/utils/print-html.js';
import { capitalizeFirstLetter } from 'sharedApp/utils/string-utils.js';
import CopyAssignmentModal from 'studyApp/components/teacher/assignments/copy-assignment-modal.vue';
import EndNowModal from 'studyApp/components/teacher/assignments/end-now-modal.vue';
import AssignmentListTable from 'studyApp/components/teacher/assignments/list/assignment-list-table.vue';
import EmptyAssignmentListBox from 'studyApp/components/teacher/assignments/list/empty-assignment-list-box.vue';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';
import ExportAssignmentsResult from 'teachApp/composites/export-assignments-result.vue';
import {
  trackAssignmentArchive,
  trackAssignmentDelete,
  trackAssignmentUnarchive,
} from 'teachApp/services/tracking/assignment-tracking.js';
import {
  ASSIGNMENT_LIST_TYPES,
  ASSIGNMENT_TABLE_SORT_BY_OPTIONS,
  getShareableAssignmentLink,
  SORT_ORDERS,
} from 'teachApp/utils/assignment-utils.js';

import { assembleEsqForPrinting, esqPrintCss } from './esq-printing-utilities.js';

export default {
  name: 'TeacherAssignmentOverview',
  components: {
    CreateAssignmentButton,
    KogLoader,
    AssignmentListTable,
    ExportAssignmentsResult,
    EmptyAssignmentListBox,
    KogSelect,
    KogTabs,
  },
  mixins: [GoogleClassroomMixin, RoutesMixin],
  setup() {
    const { copy, isSupported } = useClipboard();
    const { waitFor } = useWaitFor();
    const assignmentStore = useAssignmentStore();
    const { fetchAssignment, deleteAssignment } = assignmentStore;
    const {
      currentAssignments,
      currentAssignmentsMetadata,
      pastAssignments,
      pastAssignmentsMetadata,
      draftAssignments,
      draftAssignmentsMetadata,
      scheduledAssignments,
      archivedAssignments,
      archivedAssignmentsMetadata,
      getCurrentAssignments,
      getPastAssignments,
      getDraftAssignments,
      getArchivedAssignments,
    } = useAssignments();

    return {
      assignment: storeToRefs(assignmentStore).assignment,
      isClipboardSupported: isSupported,
      copyToClipboard: copy,
      currentAssignments,
      currentAssignmentsMetadata,
      pastAssignments,
      pastAssignmentsMetadata,
      draftAssignments,
      draftAssignmentsMetadata,
      scheduledAssignments,
      archivedAssignments,
      archivedAssignmentsMetadata,
      fetchAssignment,
      deleteAssignment,
      getCurrentAssignments: waitFor('fetching_current_assignments', getCurrentAssignments),
      sortCurrentAssignments: waitFor('sorting_current_assignments', getCurrentAssignments),
      getPastAssignments: waitFor('fetching_past_assignments', getPastAssignments),
      sortPastAssignments: waitFor('sorting_past_assignments', getPastAssignments),
      getDraftAssignments: waitFor('fetching_draft_assignments', getDraftAssignments),
      sortDraftAssignments: waitFor('sorting_draft_assignments', getDraftAssignments),
      getArchivedAssignments: waitFor('fetching_archived_assignments', getArchivedAssignments),
      sortArchivedAssignments: waitFor('sorting_archived_assignments', getArchivedAssignments),
    };
  },
  data() {
    return {
      sources: SOURCES,
      googleClassroomIcon,
      activeTab: null,
      tabNames: {
        currentAssignments: 'current_assignments',
        pastAssignments: 'past_assignments',
        draftAssignments: 'draft_assignments',
        archivedAssignments: 'archived_assignments',
      },
      assignmentListTypes: ASSIGNMENT_LIST_TYPES,
      currentAssignmentsSorting: {
        page: 1,
        sortBy: ASSIGNMENT_TABLE_SORT_BY_OPTIONS.sent,
        sortOrder: SORT_ORDERS.desc,
      },
      draftAssignmentsSorting: {
        page: 1,
        sortBy: ASSIGNMENT_TABLE_SORT_BY_OPTIONS.created_at,
        sortOrder: SORT_ORDERS.desc,
      },
      pastAssignmentsSorting: {
        page: 1,
        sortBy: ASSIGNMENT_TABLE_SORT_BY_OPTIONS.sent,
        sortOrder: SORT_ORDERS.desc,
      },
      archivedAssignmentsSorting: {
        page: 1,
        sortBy: ASSIGNMENT_TABLE_SORT_BY_OPTIONS.archived_at,
        sortOrder: SORT_ORDERS.desc,
      },
      selectedClassId: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
      subject: state => state.subjectModule.subject,
      userProfile: state => state.teacherDetailModule.userProfile,
    }),
    ...mapGetters({
      getTeachingSubjectClasses: 'subjectClassModule/getTeachingSubjectClasses',
    }),
    isThereAnyExpiredClass() {
      return this.ownExpiredSubjectClasses.length > 0;
    },
    selectableClassesDropdownList() {
      const currentClassListItem = {
        value: this.classId,
        text: this.subjectClass.name,
      };
      const expiredClassesValues = this.ownExpiredSubjectClasses.map(sc => ({
        value: sc.id,
        text: sc.name,
      }));
      return [currentClassListItem, ...expiredClassesValues];
    },
    ownExpiredSubjectClasses() {
      return this.getTeachingSubjectClasses(this.user.id, true, this.subjectId);
    },
    isDropdownVisible() {
      return this.isThereAnyExpiredClass && !this.$wait.waiting('fetching_class');
    },
    isConnectedToAnyGoogleClass() {
      return this.subjectClass?.google_classes?.length > 0;
    },
    googleClassroomIconInfo() {
      return this.getGoogleClassroomIconInfo(this.subjectClass.google_classes);
    },
    googleClassroomIconInfoNoMarkup() {
      return this.getGoogleClassroomIconInfoNoMarkup(this.subjectClass.google_classes);
    },
    alignmentCss() {
      const between = this.isConnectedToAnyGoogleClass || this.isThereAnyExpiredClass;
      return {
        'flexContainer-spaceBetween': between,
        'flexContainer-flexEnd': !between,
      };
    },
    isClassAdmin() {
      return !this.subjectClass?.is_user_class_teacher;
    },
    hadAssignmentsBefore() {
      return this.pastAssignments.length > 0 || this.archivedAssignments.length > 0;
    },
    tabs() {
      const currentCountString = this.currentAssignmentsCount
        ? `(${this.currentAssignmentsCount})`
        : '';
      const pastCountString = this.pastAssignmentsCount ? `(${this.pastAssignmentsCount})` : '';
      const draftCountString = this.draftAssignmentsCount ? `(${this.draftAssignmentsCount})` : '';
      return [
        {
          name: this.tabNames.currentAssignments,
          label: `Active assignments ${currentCountString}`,
        },
        {
          name: this.tabNames.pastAssignments,
          label: `Past assignments ${pastCountString}`,
        },
        {
          name: this.tabNames.draftAssignments,
          label: `Draft assignments ${draftCountString}`,
        },
        {
          name: this.tabNames.archivedAssignments,
          label: 'Archived',
          iconClass: 'fa-archive',
          inSecondaryTabGroup: true,
        },
      ];
    },
    currentAssignmentsCount() {
      return this.currentAssignmentsMetadata.count;
    },
    pastAssignmentsCount() {
      return this.pastAssignmentsMetadata.count;
    },
    draftAssignmentsCount() {
      return this.draftAssignmentsMetadata.count;
    },
    isCurrentTabSelected() {
      return this.activeTab === this.tabNames.currentAssignments;
    },
    isPastTabSelected() {
      return this.activeTab === this.tabNames.pastAssignments;
    },
    isDraftTabSelected() {
      return this.activeTab === this.tabNames.draftAssignments;
    },
    isArchivedTabSelected() {
      return this.activeTab === this.tabNames.archivedAssignments;
    },
    assignmentState() {
      if (this.isCurrentTabSelected) {
        return 'current';
      }
      if (this.isPastTabSelected) {
        return 'past';
      }
      if (this.isDraftTabSelected) {
        return 'draft';
      }
      return 'archived';
    },
    loadingMessage() {
      return `Loading ${this.assignmentState} assignments`;
    },
    loadingState() {
      return `fetching_${this.assignmentState}_assignments`;
    },
    subjectName() {
      return this.subject.name;
    },
    isShowEmptyAssignmentBox() {
      return (
        (this.isCurrentTabSelected && this.currentAssignments.length === 0) ||
        (this.isPastTabSelected && this.pastAssignments.length === 0)
      );
    },
    isShowEmptyAssignmentMessage() {
      return (
        (this.isDraftTabSelected && this.draftAssignments.length === 0) ||
        (this.isArchivedTabSelected && this.archivedAssignments.length === 0)
      );
    },
    isShowExportAssignmentResultButton() {
      return this.isPastTabSelected && this.pastAssignments.length > 0;
    },
  },
  watch: {
    '$route.query': {
      handler() {
        const isFromClickOnTab = !!this.activeTab;
        this.activeTab = this.$route.query.tab || this.tabNames.currentAssignments;
        if (isFromClickOnTab) {
          this.trackTabChange();
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.selectedClassId = this.classId;

    this.goToCurrentAssignmentsPaginationPage(1).then(() =>
      this.trackViewAssignments(this.currentAssignments.length === 0),
    );
    this.goToPastAssignmentsPaginationPage(1);
    this.goToDraftAssignmentsPaginationPage(1);
    this.goToArchivedAssignmentsPaginationPage(1);

    await Promise.all([
      this.getUserProfile(),
      this.fetchSubjectClass({
        subjectClassId: this.classId,
      }),
      this.fetchSubjectClassList(),
    ]);
    this.$event.track('load_assignment_overview', {
      subject_id: this.subjectId,
      subject_class_id: this.classId,
    });
  },
  methods: {
    ...mapActions({
      getUserProfile: 'teacherDetailModule/getUserProfile',
    }),
    ...mapActions({
      fetchSubjectClassList: 'subjectClassModule/fetchSubjectClassList',
    }),
    ...mapWaitingActions({
      fetchSubjectClass: {
        action: 'schoolstaffSubjectclassModule/fetchSubjectClass',
        loader: 'fetching_class',
      },
    }),
    selectTab(tab) {
      if (tab === this.$route.query.tab) {
        return;
      }
      this.$router.push({ query: { ...this.$route.query, tab } });
    },
    updateClassFilter(classId) {
      this.selectedClassId = classId;
      this.fetchCurrentAssignments();
      this.fetchPastAssignments();
      this.fetchDraftAssignments();
      this.fetchArchivedAssignments();
      this.trackSubjectClassFilter();
    },
    goToCurrentAssignmentsPaginationPage(page) {
      this.currentAssignmentsSorting.page = page;
      return this.fetchCurrentAssignments();
    },
    sortCurrentAssignmentsCallback(sortBy, sortOrder) {
      this.currentAssignmentsSorting.sortBy = sortBy;
      this.currentAssignmentsSorting.sortOrder = sortOrder;
      this.currentAssignmentsSorting.page = 1;
      this.orderCurrentAssignments();
      this.trackSortAssignments(sortBy);
    },
    goToPastAssignmentsPaginationPage(page) {
      this.pastAssignmentsSorting.page = page;
      return this.fetchPastAssignments();
    },
    sortPastAssignmentsCallback(sortBy, sortOrder) {
      this.pastAssignmentsSorting.sortBy = sortBy;
      this.pastAssignmentsSorting.sortOrder = sortOrder;
      this.pastAssignmentsSorting.page = 1;
      this.orderPastAssignments();
      this.trackSortAssignments(sortBy);
    },
    goToDraftAssignmentsPaginationPage(page) {
      this.draftAssignmentsSorting.page = page;
      return this.fetchDraftAssignments();
    },
    sortDraftAssignmentsCallback(sortBy, sortOrder) {
      this.draftAssignmentsSorting.sortBy = sortBy;
      this.draftAssignmentsSorting.sortOrder = sortOrder;
      this.draftAssignmentsSorting.page = 1;
      this.orderDraftAssignments();
      this.trackSortAssignments(sortBy);
    },
    goToArchivedAssignmentsPaginationPage(page) {
      this.archivedAssignmentsSorting.page = page;
      return this.fetchArchivedAssignments();
    },
    sortArchivedAssignmentsCallback(sortBy, sortOrder) {
      this.archivedAssignmentsSorting.sortBy = sortBy;
      this.archivedAssignmentsSorting.sortOrder = sortOrder;
      this.archivedAssignmentsSorting.page = 1;
      this.orderArchivedAssignments();
      this.trackSortAssignments(sortBy);
    },
    fetchAssignments(actionToCall, assignmentsSortingConfig) {
      return actionToCall({
        subjectId: this.subjectId,
        subjectClassId: this.selectedClassId,
        sortBy: assignmentsSortingConfig.sortBy,
        sortOrder: assignmentsSortingConfig.sortOrder,
        page: assignmentsSortingConfig.page,
      });
    },
    showEndAssignmentNowModal(assignment) {
      this.$modal(EndNowModal, {
        assignment,
        source: 'Assignment overview',
        afterEndAssignment: () => {
          this.fetchCurrentAssignments();
          this.fetchPastAssignments();
        },
      });
    },
    fetchCurrentAssignments() {
      return this.fetchAssignments(this.getCurrentAssignments, this.currentAssignmentsSorting);
    },
    fetchPastAssignments() {
      return this.fetchAssignments(this.getPastAssignments, this.pastAssignmentsSorting);
    },
    fetchDraftAssignments() {
      return this.fetchAssignments(this.getDraftAssignments, this.draftAssignmentsSorting);
    },
    fetchArchivedAssignments() {
      return this.fetchAssignments(this.getArchivedAssignments, this.archivedAssignmentsSorting);
    },
    orderCurrentAssignments() {
      this.fetchAssignments(this.sortCurrentAssignments, this.currentAssignmentsSorting);
    },
    orderPastAssignments() {
      this.fetchAssignments(this.sortPastAssignments, this.pastAssignmentsSorting);
    },
    orderDraftAssignments() {
      this.fetchAssignments(this.sortDraftAssignments, this.draftAssignmentsSorting);
    },
    orderArchivedAssignments() {
      this.fetchAssignments(this.sortArchivedAssignments, this.archivedAssignmentsSorting);
    },
    async archive(assignment) {
      await schoolStaffApi.archiveAssignment(assignment.id);

      this.$toast.showSuccess('Assignment archived', {
        actionText: 'Undo',
        postActionText: 'Assignment unarchived',
        toasterActionPerformed: async () => {
          await schoolStaffApi.unArchiveAssignment(assignment.id);
          this.afterUnarchiveAssignment(assignment);
        },
      });

      this.afterArchiveAssignment(assignment);
    },
    async unarchive(assignment) {
      await schoolStaffApi.unArchiveAssignment(assignment.id);

      const destinationTable = assignment.sent_at ? 'Past assignments' : 'Draft assignments';
      const unarchiveSuccessMessage = `1 assignment unarchived. It is placed in '${destinationTable}' tab.`;

      const toasterId = this.$toast.showSuccess(unarchiveSuccessMessage, {
        actionText: 'View assignment',
        toasterActionPerformed: () => {
          this.$toast.close(toasterId);
          this.goToAssignment(assignment);
        },
      });

      this.afterUnarchiveAssignment(assignment);
    },
    unscheduleAssignment(assignment) {
      const data = { scheduled_sent_at: null };
      return schoolStaffApi.updateAssignmentSchedule(assignment.id, data);
    },
    async cancelScheduling(assignment) {
      await this.unscheduleAssignment(assignment);
      const updatedAssignment = {
        ...assignment,
        scheduled_sent_at: null,
        is_scheduled_to_send: false,
      };

      const toasterId = this.$toast.showSuccess(
        "Assignment cancelled. It has been moved to your 'Draft assignments'.",
        {
          actionText: 'Open the draft',
          toasterActionPerformed: () => {
            this.$toast.close(toasterId);
            this.goToAssignment(updatedAssignment);
          },
        },
      );

      this.afterUnscheduleAssignment(assignment);
    },
    goToAssignment(assignment) {
      let routeName;
      const query = {};
      if (this.isDraftTabSelected) {
        routeName = 'teacherAssignmentCreate';
        query.source = SOURCES.TEACHER_ASSIGNMENT_OVERVIEW;
      } else {
        routeName = 'teacherAssignmentDetails';
      }
      this.$router.push({
        name: routeName,
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: assignment.id,
        },
        query: {
          ...query,
        },
      });
    },
    afterArchiveAssignment(assignment) {
      if (assignment) {
        trackAssignmentArchive(this.subject, assignment.assignment_type);
      } else {
        trackAssignmentArchive(this.subject);
      }

      if (this.isDraftTabSelected) {
        this.fetchDraftAssignments();
      } else if (this.isPastTabSelected) {
        this.fetchPastAssignments();
      }
      this.fetchArchivedAssignments();
    },
    afterUnarchiveAssignment(assignment) {
      trackAssignmentUnarchive(this.subject, assignment.assignment_type);

      if (!assignment.sent_at) {
        this.fetchDraftAssignments();
      } else {
        this.fetchPastAssignments();
      }
      this.fetchArchivedAssignments();
    },
    afterUnscheduleAssignment(assignment) {
      mixpanelModule.trackEvent('Assignment - Scheduled cancelled', {
        deadline: assignment.deadline,
        scheduled_sent_at: assignment.scheduled_sent_at,
        assignment_type: assignment.assignment_type.toLowerCase(),
        num_subject_classes: assignment.subject_classes.length,
        source: 'Assignment list',
      });

      this.fetchCurrentAssignments();
      this.fetchDraftAssignments();
    },
    afterDeleteAssignment(assignment) {
      if (assignment) {
        trackAssignmentDelete(this.subject, assignment.assignment_type);
      } else {
        trackAssignmentDelete(this.subject);
      }

      this.fetchDraftAssignments();
      this.assignment = null;
    },
    afterCopyAssignmentLinkToClipboard(assignment) {
      mixpanelModule.trackEvent('Assignment - Copied shareable link', {
        assignment_type: assignment.assignment_type.toLowerCase(),
        num_subject_classes: assignment.subject_classes.length,
        source: 'Assignment list',
      });
    },
    copyAssignmentLinkToClipboard(assignment) {
      const assignmentLink = getShareableAssignmentLink(assignment.id);
      if (this.isClipboardSupported) {
        this.copyToClipboard(assignmentLink);
        this.$toast.showSuccess('Assignment link copied');
        this.afterCopyAssignmentLinkToClipboard(assignment);
      } else {
        this.$toast.showWarning(
          'This browser does not support copy to clipboard. Please use a different browser or update to newer version.',
        );
      }
    },
    copyAssignment(assignment) {
      this.$modal(CopyAssignmentModal, {
        assignment,
        subjectSlug: this.subject.slug,
        source: 'teacher_assignment_overview',
        onCopyAssignment: () => {
          this.fetchDraftAssignments();
        },
      });
    },
    removeAssignment(assignment) {
      const assignmentName = unescape(assignment.name);
      this.$modal(KogConfirmModal, {
        title: 'Delete assignment?',
        text: `Are you sure you want to delete ${assignmentName}? You cannot undo this.`,
        buttonTitle: 'Delete assignment',
        onConfirm: async () => {
          await schoolStaffApi.deleteAssignment(assignment.id);
          this.$toast.showSuccess('The assignment was deleted.');
          this.afterDeleteAssignment(assignment);
        },
      });
    },
    async printAssignment(assignment) {
      await this.fetchAssignment(assignment.id);
      this.print();
    },
    async print() {
      const title = this.assignment.name;
      const printedHtml = assembleEsqForPrinting(this.assignment, true);
      this.$refs.esqprinthelper.innerHTML = printedHtml;
      await this.$forceUpdate();
      // eslint-disable-next-line no-promise-executor-return
      await new Promise(r => setTimeout(r, 1000));
      await nextTick();
      printHtml(this.$refs.esqprinthelper.innerHTML, title, esqPrintCss);
    },
    trackViewAssignments(emptyState) {
      const additionalProperties = {
        source: this.activeTab,
      };
      if (emptyState) {
        additionalProperties.empty_state = emptyState;
      }
      this.trackEvent('View assignments', additionalProperties);
    },
    trackSortAssignments(sortBy) {
      this.trackEvent('Sort assignment', { sortBy });
    },
    trackSubjectClassFilter() {
      this.trackEvent('Assignment list - Filter subject class', {
        filter_subject_class_selection: this.selectedClassId,
      });
    },
    trackTabChange() {
      const tabShortName = capitalizeFirstLetter(this.activeTab.split('_')[0]);
      this.trackEvent(`Assignment - Tab navigate to ${tabShortName}`);
    },
    trackEvent(eventName, props = {}) {
      const eventProperties = {
        ...props,
      };
      this.$mixpanel.trackEvent(eventName, eventProperties);
    },
  },
};
</script>

<style scoped>
.TeacherAssignmentOverview {
  background: no-repeat left bottom url('~sharedApp/img/bottom-wave.svg');
  background-size: 100% auto;
}

.TeacherAssignmentOverview-container {
  background-color: var(--kog-background-default-0);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.TeacherAssignmentOverview-container--noBackground {
  background-color: var(--kogTransparent) !important;
}

@media (--viewport-s) {
  .TeacherAssignmentOverview {
    margin-bottom: 72px; /* making space for the MobileBottomNavbar */
  }
  .TeacherAssignmentOverview-container {
    margin-bottom: var(--space-m);
  }
}

.AssignmentsListContainer-icon--blue,
li:not(:active) > a {
  color: var(--kog-text-brand);
}

.TeacherAssignmentOverview-googleClassroomIcon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}
</style>
