import { Plugin } from '@ckeditor/ckeditor5-core';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';

import IconDiv from '../icon-div/icon-div.js';
import ExerciseBoxEditing from './exercise-box-editing.js';
import ExerciseBoxUI from './exercise-box-ui.js';

export default class ExerciseBox extends Plugin {
  static get requires() {
    return [ExerciseBoxEditing, ExerciseBoxUI, Paragraph, Heading, IconDiv];
  }
}
