<template>
  <ul
    :id="`topic-${topicId}-subtopics-list`"
    class="margin-top-xs margin-0 padd-0 text-left list-style-none"
  >
    <template
      v-for="subtopic in subtopics"
      :key="subtopic.id"
    >
      <li
        v-if="!subtopic.is_special_introduction_node"
        class="TableOfContentsSubtopics-listItem cursorPointer"
        @click.stop="selectSubtopic(subtopic)"
        @keypress.enter="selectSubtopic(subtopic)"
      >
        <div
          v-kog-description:[`subtopic-${subtopic.id}`]="`Scroll to subtopic`"
          role="button"
          tabindex="-1"
          class="flexContainer"
        >
          <div class="margin-right-s TableOfContentsSubtopics-itemNumber">
            {{ subtopic.formatted_number_including_ancestors }}
          </div>
          <div class="TableOfContentsSubtopics-itemName">
            {{ subtopic.name }}
            <content-hidden-label v-if="isShowHiddenLabel(subtopic.id)" />
          </div>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import KogDescription from 'sharedApp/directives/kog-description.js';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';

export default {
  name: 'TableOfContentsSubtopics',
  components: {
    ContentHiddenLabel,
  },
  directives: {
    KogDescription,
  },
  inject: {
    tableOfContentsContext: {
      from: 'tableOfContentsContext',
      default: undefined,
    },
  },
  props: {
    topicId: {
      type: Number,
      required: true,
    },
    subtopics: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    isHiddenLabelEnabled() {
      return this.user.isTeacher();
    },
  },
  methods: {
    selectSubtopic(subtopic) {
      this.tableOfContentsContext.selectedSubtopic = subtopic;
    },
    isShowHiddenLabel(subtopicId) {
      return this.isHiddenLabelEnabled && !this.isNodeVisible(subtopicId);
    },
  },
};
</script>

<style scoped>
.TableOfContentsSubtopics-listItem:hover,
.TableOfContentsSubtopics-listItem:focus {
  text-decoration: underline;
}

.TableOfContentsSubtopics-itemNumber {
  width: var(--space-l);
}

.TableOfContentsSubtopics-itemName {
  max-width: calc(100% - var(--space-l) - var(--space-s));
}
</style>
