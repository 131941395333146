import { Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Plugin } from '@ckeditor/ckeditor5-core';

import ItalicOverrideEditing from './italic-override-editing.js';

export default class ItalicOverride extends Plugin {
  static get requires() {
    return [Italic, ItalicOverrideEditing];
  }
}
