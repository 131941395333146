import { Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget, toWidgetEditable, Widget } from '@ckeditor/ckeditor5-widget';

import { forbidNesting } from '../utils/commands.js';
import InsertExerciseBoxCommand from './exercise-box-command.js';

export default class ExerciseBoxEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.editor.commands.add('insertExerciseBox', new InsertExerciseBoxCommand(this.editor));
  }

  defineSchema() {
    const { schema } = this.editor.model;

    schema.register('exerciseBox', {
      inheritAllFrom: '$blockObject',
    });

    schema.register('exerciseBoxHeader', {
      allowIn: 'exerciseBox',
      allowContentOf: '$root',
    });

    schema.register('exerciseBoxTitle', {
      isLimit: true,
      allowIn: 'exerciseBoxHeader',
      allowContentOf: '$root',
    });

    schema.register('exerciseBoxSubtitle', {
      isLimit: true,
      allowIn: 'exerciseBoxHeader',
      allowContentOf: '$root',
    });

    schema.register('exerciseBoxContent', {
      isLimit: true,
      allowIn: 'exerciseBox',
      allowContentOf: '$root',
    });

    schema.addChildCheck(forbidNesting('exerciseBox'));
  }

  defineConverters() {
    const { conversion } = this.editor;

    // <exerciseBox> converters
    conversion.for('upcast').elementToElement({
      model: 'exerciseBox',
      view: {
        name: 'div',
        classes: 'exercise-box',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'exerciseBox',
      view: {
        name: 'div',
        classes: 'exercise-box',
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'exerciseBox',
      view: (_, { writer }) => {
        const viewElement = writer.createContainerElement('div', { class: 'exercise-box' });
        return toWidget(viewElement, writer, { label: 'exercise box widget' });
      },
    });

    // <exerciseBoxHeader> converters
    conversion.elementToElement({
      model: 'exerciseBoxHeader',
      view: {
        name: 'div',
        classes: 'exercise-box-header',
      },
    });

    // <exerciseBoxTitle> converters
    conversion.for('upcast').elementToElement({
      model: 'exerciseBoxTitle',
      view: {
        name: 'div',
        classes: 'exercise-box-title',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'exerciseBoxTitle',
      view: {
        name: 'div',
        classes: 'exercise-box-title',
        attributes: {
          'data-gramm': 'false',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'exerciseBoxTitle',
      view: (_, { writer }) => {
        const viewElement = writer.createEditableElement('div', {
          class: 'exercise-box-title',
          'data-gramm': 'false',
        });
        return toWidgetEditable(viewElement, writer);
      },
    });

    // <exerciseBoxSubtitle> converters
    conversion.for('upcast').elementToElement({
      model: 'exerciseBoxSubtitle',
      view: {
        name: 'div',
        classes: 'exercise-box-subtitle',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'exerciseBoxSubtitle',
      view: {
        name: 'div',
        classes: 'exercise-box-subtitle',
        attributes: {
          'data-gramm': 'false',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'exerciseBoxSubtitle',
      view: (_, { writer }) => {
        const viewElement = writer.createEditableElement('div', {
          class: 'exercise-box-subtitle',
          'data-gramm': 'false',
        });
        return toWidgetEditable(viewElement, writer);
      },
    });

    // <exerciseBoxContent> converters
    conversion.for('upcast').elementToElement({
      model: 'exerciseBoxContent',
      view: {
        name: 'div',
        classes: 'exercise-box-content',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'exerciseBoxContent',
      view: {
        name: 'div',
        classes: 'exercise-box-content',
        attributes: {
          'data-gramm': 'false',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'exerciseBoxContent',
      view: (_, { writer }) => {
        const viewElement = writer.createEditableElement('div', {
          class: 'exercise-box-content',
          'data-gramm': 'false',
        });
        return toWidgetEditable(viewElement, writer);
      },
    });
  }
}
