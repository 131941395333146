<template>
  <kog-card
    class="AssignmentClassCard padd-s flexContainer margin-bottom-xs"
    :default-padding="false"
  >
    <p class="font-weight-bold margin-bottom-xxs">Class</p>
    <div class="flexContainer flexContainer-row flexContainer-alignCenter margin-bottom-xs">
      <image-box
        v-if="showIntegrationLogo"
        v-tooltip="integrationProviderDisplayName"
        class="AssignmentClassCard-logo margin-right-xs"
        :src="integrationLogo"
        :w="24"
        :h="24"
        :alt="`sending to ${integrationProviderDisplayName}`"
      />

      {{ subjectClass.name }}
    </div>
    <p class="font-weight-bold margin-bottom-xxs">Students</p>
    <p class="margin-bottom-0"> {{ numberOfSelectedStudents }}/{{ numberOfTotalStudents }}</p>
    <kog-skeleton
      v-if="isLoadingLmsIntegrationCategories"
      height="50px"
    />
    <kog-select
      v-else-if="showCategoryDropdown"
      :selected-value="selectedCategory"
      :options="categoryChoices"
      label="Assignment category"
      @change="categoryChanged"
    />
  </kog-card>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';

import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import KogSkeleton from 'sharedApp/components/base/skeleton/kog-skeleton.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import useIntegrationLogo from 'studyApp/composables/use-integration-logo.ts';

type SubjectClass = {
  name: string;
  student_user_ids: number[];
  id: number;
};
type StudentInClass = {
  school_student: number;
  isKognityStudent: boolean;
  isGoogleStudent: boolean;
  upstreamId: string;
};
type SelectCategoryFunction = (categoryAndClassId: { categoryId: string; classId: number }) => void;
type LmsCategory = {
  id: string;
  title: string;
};

type Props = {
  subjectClass: SubjectClass;
  selectedStudents: number[];
  studentsInClass: StudentInClass[];
  selectedGoogleClassroomRecipientUpstreamIds: string[];
  lmsCategories: LmsCategory[];
  isLoadingLmsIntegrationCategories: boolean;
  sendToIntegration: boolean;
  selectedCategory?: string;
};
const props = defineProps<Props>();

const { subjectClassesWithIntegrationClassIds, integrationProviderDisplayName, integrationLogo } =
  useIntegrationLogo();

const isStudentSelected = (student: StudentInClass) => {
  if (student.isKognityStudent) {
    return props.selectedStudents.includes(student.school_student);
  }
  if (student.isGoogleStudent) {
    return props.selectedGoogleClassroomRecipientUpstreamIds.includes(student.upstreamId);
  }

  return false;
};

const selectCategory: SelectCategoryFunction = inject(
  'updateLmsCategory',
) as SelectCategoryFunction;

const numberOfSelectedStudents = computed(() => {
  return props.studentsInClass.filter(isStudentSelected).length;
});
const numberOfTotalStudents = computed(() => props.studentsInClass.length);

const categoryChoices = computed(() => {
  if (!props.lmsCategories) {
    return [];
  }
  return props.lmsCategories.map(category => ({
    value: category.id,
    text: category.title,
  }));
});

const showCategoryDropdown = computed(() => {
  return (
    props.sendToIntegration &&
    !props.isLoadingLmsIntegrationCategories &&
    props.lmsCategories.length > 0
  );
});

const showIntegrationLogo = computed(() => {
  return (
    props.sendToIntegration &&
    subjectClassesWithIntegrationClassIds?.value.includes(props.subjectClass.id)
  );
});

function categoryChanged(categoryId: string) {
  const categoryAndClassId = {
    categoryId,
    classId: props.subjectClass.id,
  };
  selectCategory(categoryAndClassId);
}
</script>

<style scoped>
.AssignmentClassCard {
  border: 1px solid var(--kog-component-outline-border-color);
  border-radius: 12px;
}

.AssignmentClassCard-logo {
  width: 24px;
  height: 24px;
  background-color: var(--kogPlatformWhite);
}
</style>
