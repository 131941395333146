<template>
  <div
    ref="root"
    class="OpenEndedTask-wrapper"
  >
    <kog-loader :loading="loading.value">
      <question-container
        v-if="question && question.active"
        :display-mode="displayMode"
        :question="question"
        @response="handleUserResponse"
      />
    </kog-loader>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import QuestionContainer from 'sharedApp/components/question-container/question-container.vue';
import {
  QUESTION_DISPLAY_MODE,
  useQuestionContextProvide,
} from 'sharedApp/composables/question-context/index.ts';
import useOpenEndedTaskStore from 'studyApp/store/modules/open-ended-task.js';

export default {
  name: 'OpenEndedTaskContainer',
  components: {
    KogLoader,
    QuestionContainer,
  },
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    subjectId: {
      type: Number,
      required: true,
    },
    openEndedTaskId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useOpenEndedTaskStore();
    useQuestionContextProvide({
      retrieveResponses: () => store.taskResponses[props.openEndedTaskId],
    });
  },
  data() {
    return {
      displayMode: QUESTION_DISPLAY_MODE.USER_RESPONSE,
    };
  },
  computed: {
    ...mapState(useOpenEndedTaskStore, ['availableTasksBySubjectNodeId', 'loading']),
    question() {
      return this.availableTasksBySubjectNodeId[this.subjectNodeId]?.[this.openEndedTaskId]
        ?.question;
    },
  },
  methods: {
    ...mapActions(useOpenEndedTaskStore, ['updateOrCreateResponse']),
    handleUserResponse(response) {
      this.updateOrCreateResponse(this.openEndedTaskId, response);

      this.$mixpanel.trackEventViaBackend('Open Ended Task - Respond', {
        open_ended_task_id: this.openEndedTaskId,
        subject_node_id: this.subjectNodeId,
      });

      this.$event.track('answer_open_ended_task', {
        subject_id: this.subjectId,
        subject_class_id: this.subjectClassId,
        subject_node_id: this.subjectNodeId,
        open_ended_task_id: this.openEndedTaskId,
      });
    },
  },
};
</script>
