<template>
  <div class="padd-top-xl">
    <div class="flexContainer flexContainer-spaceBetween">
      <div>
        <h2 class="text-overline"> Question </h2>
        <content-container
          class="ReflectionResponses-question margin-top-m margin-left-xxs"
          :content="question.question_html"
        />
      </div>
      <div class="margin-left-m">
        <h2 class="text-overline margin-bottom-0"> Overview </h2>
        <kog-item-list
          class="flexContainer"
          role="list"
          :is-handling-tab-index="true"
        >
          <kog-tag
            class="margin-right-xs"
            is-chip
            icon-class="fa-flag"
            :label="flaggedChipLabel"
            size="m"
            type="warning"
            :context="flaggedChipContext"
            :tab-index="0"
            role="listitem"
            tooltip-text="Show all flagged"
            @click="setStandardFilter('flagged')"
          />
          <kog-tag
            class="margin-right-xs"
            is-chip
            icon-class="fa-user-check"
            :label="submittedChipLabel"
            size="m"
            type="positive"
            :context="submittedChipContext"
            :tab-index="-1"
            role="listitem"
            tooltip-text="Show all submitted"
            @click="setStandardFilter('submitted')"
          />
          <kog-tag
            is-chip
            icon-class="fa-user-times"
            :label="notSubmittedChipLabel"
            size="m"
            type="informative"
            :context="notSubmittedChipContext"
            :tab-index="-1"
            role="listitem"
            tooltip-text="Show all not submitted"
            @click="setStandardFilter('not-submitted')"
          />
        </kog-item-list>
      </div>
    </div>
    <div>
      <h2 class="text-overline"> Filter </h2>
      <div class="flexContainer flexContainer-wrap">
        <interval-selector
          v-model:selected-value="filters.datesInterval"
          class="margin-right-m margin-bottom-m ReflectionResponses-filterSelect"
          label="Filter by date interval"
          :is-label-hidden="true"
          @date-interval-selected="onDateIntervalSelected"
        />
        <kog-select
          v-model:selected-value="filters.student"
          class="margin-right-m margin-bottom-m ReflectionResponses-filterSelect"
          label="Filter by students"
          :is-label-hidden="true"
          :options="studentsOptions"
          icon="fa-user"
          @change="onStudentFilterSelected"
        />
        <kog-select
          class="margin-right-m margin-bottom-m ReflectionResponses-filterSelect"
          label="Filter by status"
          placeholder-text="Show all statuses"
          :is-label-hidden="true"
          :options="filterOptions.statuses"
          icon="fa-user-check"
          dropdown-item-type="checkbox"
          @change="onStatusFilterChecked"
        />
        <kog-select
          class="margin-right-m margin-bottom-m ReflectionResponses-filterSelect"
          label="Filter by feedback"
          placeholder-text="Show all feedback"
          :is-label-hidden="true"
          :options="filterOptions.feedback"
          icon="fa-comment"
          dropdown-item-type="checkbox"
          @change="onFeedbackFilterChecked"
        />
        <kog-select
          class="margin-right-m margin-bottom-m ReflectionResponses-filterSelect"
          label="Filter by notes"
          placeholder-text="Show all notes"
          :is-label-hidden="true"
          :options="filterOptions.notes"
          icon="fa-note"
          dropdown-item-type="checkbox"
          @change="onNotesFilterChecked"
        />
        <span
          class="screenreader-only"
          aria-live="polite"
        >
          {{ appliedFiltersText }}
        </span>
      </div>
    </div>
    <kog-item-list
      class="ReflectionResponses-answersContainer padd-top-l"
      role="list"
      aria-label="Student responses"
    >
      <reflection-response-card
        v-for="student in filteredStudents"
        :key="student.id"
        :ref="student.user_id"
        :student="student"
        :user="user"
        :answers="answers"
        :feedback-items="feedbackItems[student.user_id]"
        :notes="notes[student.user_id]"
        :question="question"
        role="listitem"
        :aria-label="student.name"
        @trigger-save-feedback="saveFeedback(student, $event)"
        @trigger-save-note="saveNote(student, $event)"
        @updateFilters="applyFilters"
      />
    </kog-item-list>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import pluralize from 'pluralize';
import { mapActions, mapGetters, mapState } from 'vuex';

import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import ContentContainer from 'sharedApp/components/content/content-container.vue';
import IntervalSelector, {
  SHOW_ALL_DATES,
} from 'sharedApp/components/datetime/interval-selector.vue';

import ReflectionResponseCard from './reflection-response-card.vue';

const statusOptions = () => [
  {
    text: 'Filter by status',
    options: [
      {
        text: 'Submitted',
        value: 'submitted',
      },
      {
        text: 'Not submitted',
        value: 'not-submitted',
      },
      {
        text: 'Flagged',
        value: 'flagged',
      },
    ],
  },
];

const feedbackOptions = () => [
  {
    text: 'Filter by feedback',
    options: [
      {
        text: 'Has feedback',
        value: 'feedback',
      },
      {
        text: 'No feedback',
        value: 'no-feedback',
      },
    ],
  },
];

const notesOptions = () => [
  {
    text: 'Filter by notes',
    options: [
      {
        text: 'Has notes',
        value: 'notes',
      },
      {
        text: 'No notes',
        value: 'no-notes',
      },
    ],
  },
];

export default {
  name: 'ReflectionResponses',
  components: {
    ContentContainer,
    KogTag,
    KogItemList,
    KogSelect,
    IntervalSelector,
    ReflectionResponseCard,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    students: {
      type: Array,
      default: () => [],
    },
    subjectNodeId: {
      type: Number,
      required: true,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      filteredStudents: [],
      filters: {
        student: '',
        statuses: [],
        feedback: [],
        notes: [],
        datesInterval: SHOW_ALL_DATES,
        fromDate: '',
        toDate: '',
      },
      filterOptions: {
        statuses: [],
        feedback: [],
        notes: [],
      },
      appliedFiltersText: '',
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      feedbackItems: state => state.feedbackItemModule.feedbackItems,
      notes: state => state.teacherLtqNotesModule.teachersNotes,
    }),
    ...mapGetters({
      getLtqAnswersGroupedByUser: 'exerciseOccasionsModule/getLtqAnswersGroupedByUser',
    }),
    answers() {
      return this.getLtqAnswersGroupedByUser(this.question.id);
    },
    numOfAnswers() {
      return Object.keys(this.answers).length;
    },
    numOfFlagged() {
      const flaggedAnswers = Object.keys(this.answers)
        .flatMap(userId => this.answers[userId])
        .filter(obj => obj.flaggedByTeacher === true);
      return flaggedAnswers.length;
    },
    flaggedChipLabel() {
      return `${this.numOfFlagged}`;
    },
    submittedChipLabel() {
      return `${this.numOfAnswers} / ${this.numberOfStudents}`;
    },
    notSubmittedChipLabel() {
      return `${this.numberOfStudents - this.numOfAnswers} / ${this.numberOfStudents}`;
    },
    flaggedChipContext() {
      return `Filter all students that are flagged. ${this.numOfFlagged} answers have been flagged`;
    },
    submittedChipContext() {
      return `Filter all students with submitted answers. ${this.numOfAnswers} of ${this.numberOfStudents} have submitted an answer`;
    },
    notSubmittedChipContext() {
      return `Filter all students with not submitted answers. ${
        this.numberOfStudents - this.numOfAnswers
      } of ${this.numberOfStudents} have not submitted an answer`;
    },
    numberOfStudents() {
      return this.students?.length || 0;
    },
    studentsOptions() {
      const defaultOptions = {
        text: 'Default',
        options: [
          {
            text: `All ${pluralize('student', this.numberOfStudents, true)}`,
            value: '',
          },
        ],
      };
      const studentOptions = {
        text: 'Filter by student',
        options: this.students.map(student => ({
          value: student.user_id,
          text: `${student.first_name} ${student.last_name}`,
        })),
      };
      return [defaultOptions, studentOptions];
    },
  },
  created() {
    this.clearFilters();
  },

  async mounted() {
    await this.fetchTeachersNotes({
      subjectNodeId: this.subjectNodeId,
      questionId: this.question.id,
      subjectClassId: this.subjectClassId,
    });

    await this.fetchFeedbackItems({
      subjectNodeId: this.subjectNodeId,
      questionId: this.question.id,
      subjectClassId: this.subjectClassId,
    });
    this.applyFilters();
    const idToScrollTo = this.$route.query.focus;
    if (idToScrollTo) {
      nextTick(() => {
        const element = this.$refs[idToScrollTo];
        if (element) {
          element[0].$el.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      });
    }
  },
  methods: {
    ...mapActions('feedbackItemModule', [
      'fetchFeedbackItems',
      'createFeedbackItem',
      'updateFeedbackItem',
    ]),
    ...mapActions({
      fetchTeachersNotes: 'teacherLtqNotesModule/fetchTeachersNotes',
      createTeacherNote: 'teacherLtqNotesModule/createTeacherNote',
      updateTeacherNote: 'teacherLtqNotesModule/updateTeacherNote',
    }),
    getAnswer(userId) {
      return this.answers?.[userId]?.[0];
    },
    isAnswerDone(userId) {
      const answer = this.getAnswer(userId);
      return answer?.status && answer.status === 'DONE';
    },
    isAnswerFlagged(userId) {
      const answer = this.getAnswer(userId);
      return answer?.flaggedByTeacher === true;
    },
    hasFeedback(userId) {
      const feedbackItems = this.feedbackItems[userId] ?? [];
      const filteredFeedbackItems = feedbackItems
        .filter(feedbackItem => feedbackItem.draft)
        .filter(feedbackItem => feedbackItem.feedback);
      return filteredFeedbackItems.length > 0;
    },
    hasNotes(userId) {
      return this.notes?.[userId]?.length > 0;
    },
    async saveFeedback(student, { feedbackId, source, value, saveCompleteCallback }) {
      if (value.trim() === '') {
        saveCompleteCallback();
        return;
      }

      let operation;
      if (source === 'submit') {
        operation = 'submit';
      } else {
        operation = 'draft';
      }

      const studentId = student.school_student;

      const studentFeedbackItems = this.feedbackItems[student.user_id] ?? [];

      const feedbackItem = studentFeedbackItems.find(item => item.id === feedbackId);

      const params = {
        subjectClassId: this.subjectClassId,
        subjectNodeId: this.subjectNodeId,
        studentId,
        questionId: this.question.id,
        text: value,
        operation,
      };
      try {
        if (!feedbackItem) {
          await this.createFeedbackItem(params);
        } else {
          await this.updateFeedbackItem({
            ...params,
            feedbackItemId: feedbackItem.id,
          });
        }
        // eslint-disable-next-line no-empty
      } catch {}

      saveCompleteCallback();
    },

    async saveNote(student, { value, saveCompleteCallback }) {
      if (value.trim() === '') {
        saveCompleteCallback();
        return;
      }

      const studentId = student.school_student;
      const teacherNoteItem = this.notes?.[student.user_id]?.find(
        note => note.teacher_user.id === this.user.id,
      );

      const params = {
        subjectClassId: this.subjectClassId,
        subjectNodeId: this.subjectNodeId,
        studentId,
        questionId: this.question.id,
        text: value,
      };
      try {
        if (!teacherNoteItem) {
          await this.createTeacherNote(params);
        } else {
          await this.updateTeacherNote({
            ...params,
            noteId: teacherNoteItem.id,
          });
        }
        // eslint-disable-next-line no-empty
      } catch {}
      saveCompleteCallback();
    },
    applyFilters() {
      this.appliedFiltersText = '';
      let newFilteredStudents = [...this.students];

      if (this.filters.student) {
        newFilteredStudents = newFilteredStudents.filter(
          student => student.user_id === this.filters.student,
        );
      }
      if (this.filters.fromDate) {
        newFilteredStudents = newFilteredStudents.filter(student => {
          if (this.isAnswerDone(student.user_id)) {
            const answer = this.getAnswer(student.user_id);
            return answer.doneAt >= this.filters.fromDate;
          }
          return false;
        });
      }
      if (this.filters.toDate) {
        newFilteredStudents = newFilteredStudents.filter(student => {
          if (this.isAnswerDone(student.user_id)) {
            const answer = this.getAnswer(student.user_id);
            return answer.doneAt <= this.filters.toDate;
          }
          return false;
        });
      }
      const statusFilterCount = this.filterOptions.statuses[0]?.options.length;
      if (this.filters.statuses.length > 0 && this.filters.statuses.length < statusFilterCount) {
        newFilteredStudents = newFilteredStudents.filter(student => {
          const submitted = this.filters.statuses.includes('submitted');
          const notSubmitted = this.filters.statuses.includes('not-submitted');
          const flagged = this.filters.statuses.includes('flagged');
          return (
            (submitted && this.isAnswerDone(student.user_id)) ||
            (notSubmitted && !this.isAnswerDone(student.user_id)) ||
            (flagged && this.isAnswerFlagged(student.user_id))
          );
        });
      }
      const feedbackFilterCount = this.filterOptions.feedback[0]?.options.length;
      if (this.filters.feedback.length > 0 && this.filters.feedback.length < feedbackFilterCount) {
        newFilteredStudents = newFilteredStudents.filter(student => {
          const feedback = this.filters.feedback.includes('feedback');
          const noFeedback = this.filters.feedback.includes('no-feedback');
          const hasFeedback = this.hasFeedback(student.user_id);
          return (feedback && hasFeedback) || (noFeedback && !hasFeedback);
        });
      }
      const notesFilterCount = this.filterOptions.notes[0]?.options.length;
      if (this.filters.notes.length > 0 && this.filters.notes.length < notesFilterCount) {
        newFilteredStudents = newFilteredStudents.filter(student => {
          const notes = this.filters.notes.includes('notes');
          const noNotes = this.filters.notes.includes('no-notes');
          const hasNotes = this.hasNotes(student.user_id);
          return (notes && hasNotes) || (noNotes && !hasNotes);
        });
      }

      this.filteredStudents = newFilteredStudents;
    },
    clearFilters() {
      this.filters.datesInterval = SHOW_ALL_DATES;
      this.filters.fromDate = '';
      this.filters.toDate = '';
      this.filters.student = '';
      this.filters.statuses = [];
      this.filters.feedback = [];
      this.filters.notes = [];
      this.filterOptions.statuses = statusOptions();
      this.filterOptions.feedback = feedbackOptions();
      this.filterOptions.notes = notesOptions();
      this.appliedFiltersText = '';
    },
    onStudentFilterSelected(value) {
      this.filters.student = value;
      this.applyFilters();
      this.appliedFiltersText = 'Student filter was applied';
    },
    selectedInFilter(filter) {
      return filter[0].options
        .filter(element => !element.disabled && element.isChecked)
        .map(element => element.value);
    },
    toggleInFilter(filter, value) {
      const elementToToggle = filter[0].options.find(element => element.value === value);
      elementToToggle.isChecked = !elementToToggle.isChecked;
    },
    onStatusFilterChecked(value) {
      this.toggleInFilter(this.filterOptions.statuses, value);
      this.filterOptions.statuses = [...this.filterOptions.statuses];
      this.filters.statuses = this.selectedInFilter(this.filterOptions.statuses);
      this.applyFilters();
      this.appliedFiltersText = 'Status filter was applied';
    },
    onDateIntervalSelected({ fromDateStr, toDateStr }) {
      this.filters.fromDate = fromDateStr;
      this.filters.toDate = toDateStr;
      this.applyFilters();
      this.appliedFiltersText = 'Date filter was applied';
    },
    onFeedbackFilterChecked(value) {
      this.toggleInFilter(this.filterOptions.feedback, value);
      this.filterOptions.feedback = [...this.filterOptions.feedback];
      this.filters.feedback = this.selectedInFilter(this.filterOptions.feedback);
      this.applyFilters();
      this.appliedFiltersText = 'Feedback filter was applied';
    },
    onNotesFilterChecked(value) {
      this.toggleInFilter(this.filterOptions.notes, value);
      this.filterOptions.notes = [...this.filterOptions.notes];
      this.filters.notes = this.selectedInFilter(this.filterOptions.notes);
      this.applyFilters();
      this.appliedFiltersText = 'Notes filter was applied';
    },
    setStandardFilter(value) {
      this.clearFilters();
      this.onStatusFilterChecked(value);
      this.applyFilters();
    },
  },
};
</script>
<style scoped>
.ReflectionResponses-question :deep(.content-editable) {
  font-family: var(--kog-lato);
  font-size: 16px;
  line-height: 24px;
}

.ReflectionResponses-question :deep(.content-editable p) {
  margin-bottom: 0;
}

.ReflectionResponses-filterSelect {
  width: 192px;
}
</style>
