import cloneDeep from 'lodash/cloneDeep.js';

import { getExerciseQuestions, getSectionQuestions } from '@apis/questions';

import feedbackApi from 'publishingApp/apis/feedback.js';
import * as api from 'publishingApp/apis/section.js';
import subjectsApi from 'publishingApp/apis/subjects.js';
import * as versionsApi from 'publishingApp/apis/versions.js';
import usePublishingOetStore from 'publishingApp/store/modules/open-ended-task.ts';

export const EDIT_SECTION_MODULE_PREFIX = 'EDIT_SECTION';

const MAX_NUM_OF_FEEDBACK_ITEMS_PER_PAGE = 3;

function replaceFieldsFromVersion(section, sectionVersion) {
  return {
    ...section,
    name: sectionVersion.name,
    contents_html: sectionVersion.contents_html,
    template: sectionVersion.template,
    activity_type: sectionVersion.activity_type,
    activity_html: sectionVersion.activity_html,
    content_option_type: sectionVersion.content_option_type,
    instructions_html: sectionVersion.instructions_html,
    attributes: { ...sectionVersion.attributes },
  };
}

export default {
  state: {
    section: null,
    originalSection: null,
    versions: [],
    versionsPagination: null,
    selectedVersion: null,
    sectionQuestions: null,
    feedbackItems: [],
    feedbackResolutions: [],
    feedbackPagination: {
      currentPage: 1,
      totalItems: 0,
      totalPages: 0,
    },
    resolvedFeedback: [],
    resolvedFeedbackPagination: null,
    exerciseQuestions: null,
    unmappedExerciseQuestions: null,
    mappedExerciseQuestions: null,
    subjectDetails: null,
    linkableSections: [],
    linkableSectionsMeta: {
      subjectId: null,
      sectionId: null,
    },
  },
  mutations: {
    setSection(state, section) {
      state.section = section;
    },
    setOriginalSection(state, section) {
      state.originalSection = cloneDeep(section);
    },
    setSectionQuestions(state, sectionQuestions) {
      state.sectionQuestions = sectionQuestions;
    },
    setVersions(state, versions) {
      state.versions = versions;
    },
    setVersionsPagination(state, data) {
      state.versionsPagination = data;
    },
    setSelectedVersion(state, selectedVersion) {
      state.selectedVersion = selectedVersion;
    },
    setFeedbackItems(state, feedbackResults) {
      state.feedbackItems = feedbackResults;
    },
    setFeedbackResolutions(state, feedbackResolutions) {
      state.feedbackResolutions = feedbackResolutions;
    },
    setFeedbackPagination(state, data) {
      state.feedbackPagination = data;
    },
    setMappedExerciseQuestions(state, questions) {
      state.mappedExerciseQuestions = questions;
    },
    setUnmappedExerciseQuestions(state, questions) {
      state.unmappedExerciseQuestions = questions;
    },
    setSubjectDetails(state, subject) {
      state.subjectDetails = subject;
    },
    setLinkableSections(state, sections) {
      state.linkableSections = sections;
    },
    setLinkableSectionsMeta(state, { subjectId, sectionId }) {
      state.linkableSectionsMeta = { subjectId, sectionId };
    },
  },
  actions: {
    async fetchSection(
      { commit, dispatch },
      {
        sectionId,
        subjectNodeId,
        questionContext = {
          getSQForAllSubjects: false,
          getEQForAllSubjects: false,
        },
        showQuestionAnswers = true,
      },
    ) {
      let section = await api.getSection(sectionId);
      const currentSubjectNodeId = [subjectNodeId ?? section.subjectnodes?.[0]?.id].filter(Number);
      const mappedSubjectNodeIds = section.subjectnodes.map(subjectNode => subjectNode.id);

      if (currentSubjectNodeId.length === 0 && mappedSubjectNodeIds.length === 0) {
        throw new Error(`Can't find or extract correct subject node id information:
            - Verify that subjectNodeId is present in the url
            - Go back to the book tree and open up current content again`);
      }

      dispatch(
        'fetchSectionQuestions',
        questionContext.getSQForAllSubjects ? mappedSubjectNodeIds : currentSubjectNodeId,
        showQuestionAnswers ? 'True' : 'False',
      );

      const eqForCurrentSubjectNode = await getExerciseQuestions({
        subjectNodeIds: currentSubjectNodeId,
      });
      commit('setMappedExerciseQuestions', eqForCurrentSubjectNode);

      if (questionContext.getEQForAllSubjects) {
        const eqForOtherSubjectNodes = await getExerciseQuestions({
          subjectNodeIds: mappedSubjectNodeIds,
        });
        const mappedEqIds = eqForCurrentSubjectNode.map(q => q.id);
        const unmappedExerciseQuestions = eqForOtherSubjectNodes.filter(
          q => !mappedEqIds.includes(q.id),
        );

        commit('setUnmappedExerciseQuestions', unmappedExerciseQuestions);
      }

      const { fetchOpenEndedTasks } = usePublishingOetStore();
      await fetchOpenEndedTasks(currentSubjectNodeId);

      const { versions, numPages, currentPage } = await versionsApi.getContentVersions(
        sectionId,
        undefined,
        { section: true },
      );
      if (versions.length > 0) {
        const latestVersionSummary = versions[0];
        const latestVersion = await versionsApi.getContentVersion(
          sectionId,
          latestVersionSummary.id,
          { section: true },
        );
        section = replaceFieldsFromVersion(section, latestVersion);
        commit('setSelectedVersion', latestVersionSummary.id);
      }
      commit('setSection', section);
      commit('setOriginalSection', section);
      commit('setVersions', versions);
      commit('setVersionsPagination', { numPages, currentPage });
    },
    async fetchSectionAtVersion({ commit, state }, { sectionId, versionId }) {
      const version = await versionsApi.getContentVersion(sectionId, versionId, { section: true });
      const section = replaceFieldsFromVersion(state.section, version);
      commit('setSection', section);
      commit('setSelectedVersion', versionId);
    },
    async saveDraft({ commit, dispatch }, section) {
      const savedSection = await api.saveDraft(section);
      const versions = await dispatch('fetchVersions', { sectionId: section.id });
      if (versions.length > 0) {
        commit('setSelectedVersion', versions[0].id);
      } else {
        commit('setSelectedVersion', null);
      }

      commit('setSection', savedSection);
      commit('setOriginalSection', savedSection);
    },
    async saveAndPublish({ commit, dispatch }, section) {
      const savedSection = await api.saveAndPublish(section);
      dispatch('fetchVersions', { sectionId: section.id });
      commit('setSection', savedSection);
      commit('setOriginalSection', savedSection);
      commit('setSelectedVersion', savedSection.active_version);
    },
    async fetchVersions({ commit }, { sectionId, page }) {
      const { versions, numPages, currentPage } = await versionsApi.getContentVersions(
        sectionId,
        page,
        { section: true },
      );
      commit('setVersions', versions);
      commit('setVersionsPagination', { numPages, currentPage });
      return versions;
    },
    async fetchSectionQuestions({ commit }, subjectNodeIds, showAnswers = 'True') {
      const options = {
        additionalParams: {
          ordering: 'contentnodemapping__order',
          showAnswers,
        },
      };
      const sectionQuestions = await getSectionQuestions(subjectNodeIds, options);
      commit('setSectionQuestions', sectionQuestions);
    },
    async updateQuestionOrder({ dispatch, state }, { sectionId, questions }) {
      await api.updateQuestionOrder(sectionId, questions);
      const subjectNodes = state.section.subjectnodes.map(subjectNode => subjectNode.id);
      await dispatch('fetchSectionQuestions', subjectNodes);
    },
    async fetchFeedback({ commit, state }, data) {
      commit('setFeedbackItems', []);
      commit('setFeedbackResolutions', []);
      const { page, isResolved } = data;
      const response = await feedbackApi.fetchFeedbackForSection({
        sectionId: state.section.id,
        resolved: isResolved,
        page,
        pageSize: MAX_NUM_OF_FEEDBACK_ITEMS_PER_PAGE,
      });
      const totalItems = response.data.count;
      const currentPage = response.data.current_page;
      const pagination = {
        totalItems,
        currentPage,
        totalPages: response.data.num_pages,
      };
      commit('setFeedbackPagination', pagination);
      commit('setFeedbackItems', response.data.results);
      commit('setFeedbackResolutions', response.data.resolutions);
    },
    async fetchSubjectDetails({ commit }, subjectId) {
      const subjectDetails = await subjectsApi.fetchSubjectDetails(subjectId);
      commit('setSubjectDetails', subjectDetails);
    },
    async fetchLinkableSections({ commit }, { subjectId, sectionId }) {
      const linkableSections = await api.getLinkableSections(subjectId, sectionId);
      commit('setLinkableSections', linkableSections);
      commit('setLinkableSectionsMeta', { subjectId, sectionId });
    },
  },
};
