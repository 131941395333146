<template>
  <kog-item-list
    role="list"
    class="list-style-none"
  >
    <li
      v-for="node in contentNodes"
      :key="node.id"
    >
      <content-node-item
        :content-type="contentType"
        :internal-link-context="getNodeLinkContext(node)"
        :button-aria-label="buttonAriaLabel(node)"
        :row-aria-label="rowAriaLabel(node)"
        position="ungrouped"
        :phase="ngssPhase(node)"
        :content-node-id="node.id"
        :title="node.name"
        :subtitle="node.formatted_number_including_ancestors"
        :icon="getNodeIcon(node)"
        :children="node.children"
        :syllabus-nodes="node.syllabus_nodes"
        :ngss-section-settings="node.ngss_section_settings"
        :has-button="node.children.length > 0"
        :user-node-reading-progress="userNodesReadingProgress[node.id]"
        :user-nodes-reading-progress="userNodesReadingProgress"
        :reading-completion-data="readingCompletionDataPerNodeId[node.id]"
        :reading-completion-data-per-node-id="readingCompletionDataPerNodeId"
        :class-size="classSize"
        @click-completion-stats="$emit('clickCompletionStats', { node })"
        @click-child-completion-stats="child => $emit('clickCompletionStats', { node: child })"
        @click-child-reflection-stats="
          child => $emit('clickCompletionStats', { node: child, filter: 'flagged' })
        "
        @click-button="$emit('clickButton', node)"
        @click-container="$emit('clickContainer', node)"
      />
    </li>
  </kog-item-list>
</template>

<script>
import { mapState } from 'vuex';

import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import { getNodeIcon } from 'sharedApp/const/node-icons.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import FeaturesMixin from 'studyApp/mixins/features-mixin.js';

import ContentNodeItem from './content-node-item.vue';

export default {
  name: 'ContentNodeList',
  components: {
    ContentNodeItem,
    KogItemList,
  },
  mixins: [RoutesMixin, FeaturesMixin],
  props: {
    contentType: {
      type: String,
      default: '',
    },
    contentNodes: {
      type: Array,
      default: () => [],
    },
    classSize: {
      type: Number,
      default: 0,
    },
    userNodesReadingProgress: {
      type: Object,
      default: () => ({}),
    },
    readingCompletionDataPerNodeId: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['clickCompletionStats', 'clickButton', 'clickContainer'],
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
  },
  methods: {
    buttonAriaLabel(node) {
      const number = node.formatted_number_including_ancestors ?? '';

      if (!this.hasChildren(node)) {
        return `Go to ${this.$term(this.contentType)} ${number}: ${node.name}`;
      }

      return `${number}: ${node.name}`;
    },
    rowAriaLabel(node) {
      const number = node.formatted_number_including_ancestors ?? '';
      return `${number}: ${node.name}`;
    },
    ngssPhase(node) {
      return node.ngss_section_settings?.phase;
    },
    getNodeLinkContext(node) {
      if (this.hasChildren(node)) {
        return null;
      }

      const nodeLinkContext = {
        name: this.user.isTeacher() ? 'reviewMode' : 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
      };

      return nodeLinkContext;
    },
    hasChildren(node) {
      return node.children.length > 0;
    },
    getNodeIcon(node) {
      const feature = this.getFeatureBySubjectNode(node);
      return getNodeIcon(node, feature?.type);
    },
  },
};
</script>
