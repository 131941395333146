import {
  addListToDropdown,
  createLabeledDropdown,
  LabeledFieldView,
  Model,
  ViewCollection,
} from '@ckeditor/ckeditor5-ui';
import { Collection } from '@ckeditor/ckeditor5-utils';

import FlexWrapperView from '../shared/flex-wrapper-view.ts';
import KogForm from '../utils/kogform.js';
import { getQuestionTextShort } from '../utils/plugin-utils.ts';

export const CONTENT_BOX_DEFAULT_DROPDOWN_LABEL = 'Question';

export default class FormView extends KogForm {
  constructor(locale, ltqs) {
    super(locale);
    this.dropdownOptions = this.createDropdownOptions(ltqs);

    this.set({
      selectedQuestionId: null,
    });

    this.saveButtonView = this.createSaveButton();
    this.saveButtonView.bind('isEnabled').to(this, 'selectedQuestionId', val => !!val);
    this.cancelButtonView = this.createCancelButton();

    this.contentBoxDropdownView = this.createDropdown({
      label: CONTENT_BOX_DEFAULT_DROPDOWN_LABEL,
      observableTarget: 'selectedQuestionId',
      options: this.dropdownOptions,
    });

    this.childViews = this.createCollection([
      this.contentBoxDropdownView,
      new FlexWrapperView(this.locale, {
        direction: 'row',
        children: [this.saveButtonView, this.cancelButtonView],
      }),
    ]);

    const focusables = new ViewCollection([
      this.contentBoxDropdownView,
      this.saveButtonView,
      this.cancelButtonView,
    ]);
    this.setup('open-ended-task', focusables);
  }

  // eslint-disable-next-line class-methods-use-this
  createDropdownOptions(questions) {
    return questions.map(question => {
      const { type, id, question_html: questionHtml } = question;
      const tooltip = `${type} - ID: ${id}`;
      const text = getQuestionTextShort(questionHtml);

      return {
        id,
        tooltip,
        text,
      };
    });
  }

  focus() {
    this.focusCycler.focusFirst();
  }

  reset() {
    super.reset();
    this.selectedQuestionId = null;
  }

  setFields({ questionId }) {
    this.selectedQuestionId = questionId;
  }

  getSelectedFormItem(questionId) {
    return this.dropdownOptions.find(question => question.id === questionId);
  }

  createDropdown({ label, options, observableTarget }) {
    const dropdown = new LabeledFieldView(this.locale, createLabeledDropdown);
    dropdown.bind('isEmpty').to(this, observableTarget, val => !val);
    dropdown.set({ label });

    dropdown.fieldView.buttonView.set({
      withText: true,
      ariaLabel: label,
    });
    dropdown.fieldView.on('execute', event => {
      this[observableTarget] = event.source.id;
    });

    dropdown.fieldView.buttonView.bind('label').to(this, observableTarget, val => {
      if (!val) {
        return CONTENT_BOX_DEFAULT_DROPDOWN_LABEL;
      }

      const selectedItem = this.getSelectedFormItem(val);
      return selectedItem?.text || 'Unavailable question';
    });

    const collectionItems = new Collection();
    options.forEach(option => {
      const model = new Model({
        withText: true,
        label: option.text,
        id: option.id,
        tooltip: option.tooltip,
      });

      model.bind('isOn').to(this, observableTarget, val => val === option.id);

      collectionItems.add({
        type: 'button',
        model,
      });
    });

    addListToDropdown(dropdown.fieldView, collectionItems);

    return dropdown;
  }
}
