<template>
  <main class="Page">
    <router-view />
  </main>
</template>

<script>
export default {
  name: 'DashboardContainer',
};
</script>
