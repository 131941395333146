const showSolutionUtil = {
  showSolutionToggle(hiddenSolutionElem) {
    const solutionContent = hiddenSolutionElem.querySelector('.showsolution-content');
    hiddenSolutionElem.classList.toggle('showsolution-expanded');
    const isSolutionHidden = solutionContent.classList.toggle('hidden-widget');
    solutionContent.setAttribute('aria-hidden', `${isSolutionHidden}`);

    const titlesElem = hiddenSolutionElem.querySelectorAll('.showsolution-title');
    titlesElem.forEach(title => {
      const isTitleHidden = title.classList.toggle('hidden-widget');
      title.setAttribute('aria-hidden', `${isTitleHidden}`);
    });

    const solutionLink = hiddenSolutionElem.querySelector('.js-showsolution-toggle');
    if (solutionLink) {
      solutionLink.setAttribute('aria-expanded', `${!isSolutionHidden}`);
    }
  },

  registerShowSolutionToggleListener(sectionElem) {
    const hiddenSolutions = sectionElem.querySelectorAll('.showsolution-toggle-link');
    hiddenSolutions.forEach(solutionElm => {
      solutionElm.classList.add('cursorPointer');
      solutionElm.addEventListener('click', () =>
        this.showSolutionToggle(solutionElm.parentElement),
      );
      solutionElm.addEventListener('keypress', event => {
        if (event.key !== 'Enter' && event.key !== ' ') {
          return;
        }
        event.preventDefault();
        this.showSolutionToggle(solutionElm.parentElement);
      });
    });
  },
};

export default showSolutionUtil;
