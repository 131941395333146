<template>
  <kog-rounded-dropdown
    id="notifications-dropdown-toggle"
    align-to="right"
    :label="notificationAriaLabel"
    @expand="markNotificationsAsSeen"
  >
    <template #header>
      <div class="NotificationsDropdown-header">
        <div
          v-tooltip="{
            theme: 'kog-tooltip',
            content: 'Notifications',
          }"
          aria-hidden="true"
          class="flexContainer flexContainer-alignCenter NotificationsDropdown-bellIconContainer flexContainer-justifyCenter"
        >
          <i
            :class="`far fa-bell
            NotificationsDropdown-bellIcon NotificationsDropdown-bellIcon--${theme}`"
          />
          <kog-notification
            v-if="unseenNotificationsNumber"
            :offset-top="-10"
            :offset-left="-9"
            :has-border="true"
          />
        </div>
      </div>
    </template>
    <template #dropdown>
      <h2 class="NotificationsDropdown-title margin-0"> Notifications </h2>
      <div
        role="list"
        class="margin-0 padd-0"
        aria-labelledby="notifications-dropdown-toggle"
      >
        <div
          v-for="(notification, index) in notifications"
          :key="index"
          role="listitem"
        >
          <notification-item
            :notification="notification"
            :is-first-new-notifications="isFirstNewNotifications(index, notification)"
            :is-last-new-notifications="isLastNewNotifications(index, notification)"
          />
        </div>
      </div>
    </template>
  </kog-rounded-dropdown>
</template>

<script>
import { VTooltip } from 'floating-vue';

import NotificationItem from 'learning/common/components/notification-item.vue';

import KogNotification from 'sharedApp/components/base/tags/kog-notification.vue';
import KogRoundedDropdown from 'sharedApp/components/dropdown/kog-rounded-dropdown.vue';

export default {
  name: 'NotificationsDropdown',
  components: {
    NotificationItem,
    KogRoundedDropdown,
    KogNotification,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    markAsSeen: {
      type: Function,
      required: true,
    },
    theme: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'light'].includes(prop),
    },
  },
  computed: {
    unseenNotifications() {
      return this.notifications.filter(n => !n.seen);
    },
    unseenNotificationsNumber() {
      return this.unseenNotifications.length;
    },
    newNotificationsNumber() {
      return this.notifications.filter(n => n.new).length;
    },
    notificationAriaLabel() {
      if (this.unseenNotificationsNumber > 0) {
        return this.unseenNotificationsNumber === 1
          ? 'One new notification'
          : `${this.unseenNotificationsNumber} new notifications`;
      }
      return 'No new notifications';
    },
  },
  methods: {
    markNotificationsAsSeen() {
      this.markAsSeen(this.unseenNotifications.map(n => n.id));
    },
    isFirstNewNotifications(index, notification) {
      return notification.new && index === 0;
    },
    isLastNewNotifications(index, notification) {
      return notification.new && index + 1 === this.newNotificationsNumber;
    },
  },
};
</script>

<style scoped>
.NotificationsDropdown-bellIconContainer {
  width: 24px;
  height: 24px;
}

.NotificationsDropdown-bellIcon {
  font-size: 20px;
}

.NotificationsDropdown-bellIcon--primary {
  color: var(--kog-text-brand);
}

.NotificationsDropdown-bellIcon--primary:hover {
  color: var(--kog-text-brand);
}

.NotificationsDropdown-bellIcon--light {
  color: var(--kog-colors-white);
}

.NotificationsDropdown-header {
  padding: 12px;
}

.NotificationsDropdown-title {
  padding: 12px 16px 20px;

  font-size: var(--kog-font-size-default);
  font-weight: bold;
  line-height: 17px;
  color: var(--kog-text-default);

  border-bottom: 1px solid var(--kog-notification-item-border);
}
</style>
