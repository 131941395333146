<template>
  <kog-tag
    :label="chipLabel"
    :is-chip="true"
    :type="tagProps.type"
    :icon-class="tagProps.iconClass"
    :context="tagProps.context"
    :tooltip-text="chipTooltipText"
    @click="onClick(node, studentId)"
  />
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export default {
  name: 'ReflectionQuestionProgressCell',
  components: {
    KogTag,
  },
  props: {
    progress: {
      type: Object,
      required: true,
    },
    node: {
      type: Object,
      required: true,
    },
    subjectNode: {
      type: Object,
      required: true,
    },
    studentId: {
      type: Number,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    isNgss: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUnitLevel() {
      return this.node.level === 3;
    },
    unitNumber() {
      return this.node.number_including_ancestors.split('.')[0];
    },
    chipLabel() {
      if (this.isUnitLevel && this.isNgss) {
        return `Unit ${this.unitNumber}`;
      }
      return this.node?.number_including_ancestors;
    },
    hasUserAnswer() {
      return !!this.progress[this.node.id];
    },
    userAnswer() {
      return this.progress[this.node.id];
    },
    isFlaggedByTeacher() {
      return this.userAnswer?.is_flagged_by_teacher;
    },
    tagProps() {
      const prefix = `Question ${this.node.number_including_ancestors}`;
      let type;
      let iconClass;
      let context;

      if (!this.hasUserAnswer) {
        type = 'informative';
        iconClass = 'fa-user-times';
        context = `${prefix}, not submitted`;
      } else if (this.isFlaggedByTeacher) {
        type = 'warning';
        iconClass = 'fa-flag';
        context = `${prefix}, flagged`;
      } else {
        type = 'positive';
        iconClass = 'fa-user-check';
        context = `${prefix}, submitted`;
      }

      return { type, iconClass, context };
    },
    chipTooltipText() {
      if (!this.isNgss) {
        return `${this.node.formatted_number_including_ancestors} ${this.node.name}`;
      }
      if (this.isUnitLevel) {
        return `Unit ${this.unitNumber}`;
      }
      const moduleName = `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNode.name}`;
      return `${moduleName}<br>${this.lessonName}`;
    },
    lessonName() {
      const lessonNode = this.subjectNode.children.find(node =>
        node.children.map(child => child.id).includes(this.node.id),
      );
      return `${lessonNode.formatted_number_including_ancestors} ${lessonNode.name}`;
    },
  },
};
</script>
