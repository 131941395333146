import type { AssignmentDetails } from '@apis/generated/models.ts';
import type { KeysToSnakeCase } from 'sharedApp/types.ts';

import { getAssignmentList as getAssignmentListApi } from '@apis/schoolstaff-assignments.js';

export type AssignmentListParams = {
  page: number;
  pageSize: number;
  subjectClassId: number;
  filter: 'current' | 'past' | 'scheduled' | 'sent' | 'draft' | 'archived';
  sortOrder: 'asc' | 'desc';
  sortBy: string | null;
  deadlineFromDate: string;
  subjectId: number;
  assignmentType: string;
};

type AssignmentListParamsPython = KeysToSnakeCase<AssignmentListParams>;

export type AssignmentListResponse = {
  readonly count: number;
  readonly current_page: number;
  readonly next: number | null;
  readonly num_pages: number;
  readonly page: number;
  readonly page_size: number;
  readonly previous: number | null;
  readonly results: AssignmentDetails[];
};

export async function getAssignmentList({
  page,
  pageSize,
  subjectClassId,
  filter,
  sortOrder,
  sortBy,
  deadlineFromDate,
  subjectId,
  assignmentType,
}: Partial<AssignmentListParams>): Promise<AssignmentListResponse> {
  const sortPrefix = sortOrder === 'desc' ? '-' : '';
  const sortString = sortBy ? `${sortPrefix}${sortBy}` : null;
  const params: Partial<AssignmentListParamsPython> = {
    page: page ?? 1,
    page_size: pageSize ?? 10,
    sort_by: sortString,
    deadline_from_date: deadlineFromDate,
    subject_id: subjectId,
    filter,
    assignment_type: assignmentType,
  };

  if (subjectClassId) {
    params.subject_class_id = subjectClassId;
  }

  const data = await getAssignmentListApi(params);
  return data;
}
