import { Plugin } from '@ckeditor/ckeditor5-core';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { toWidget, toWidgetEditable, Widget } from '@ckeditor/ckeditor5-widget';

import { forbidNesting } from '../utils/commands.js';
import InsertShowSolutionCommand from './show-solution-command.ts';

export default class ShowSolutionEditing extends Plugin {
  static get requires() {
    return [Widget, Paragraph];
  }

  init() {
    this.editor.commands.add('insertShowSolution', new InsertShowSolutionCommand(this.editor));
    this.defineSchema();
    this.defineConverters();
  }

  defineSchema() {
    const { schema } = this.editor.model;

    schema.register('showSolution', {
      inheritAllFrom: '$blockObject',
    });

    schema.register('showSolutionToggle', {
      allowIn: 'showSolution',
    });

    schema.register('showSolutionToggleButton', {
      isLimit: true,
      allowIn: 'showSolutionToggle',
      allowContentOf: '$root',
      allowAttributes: ['contentId'],
    });

    schema.register('showSolutionContent', {
      allowContentOf: '$root',
      isLimit: true,
      allowIn: 'showSolution',
      allowAttributes: ['id'],
    });

    schema.addChildCheck(forbidNesting('showSolution'));
  }

  defineConverters() {
    const { conversion } = this.editor;

    // <showSolution> converters
    conversion.for('upcast').elementToElement({
      model: 'showSolution',
      view: {
        name: 'div',
        classes: 'showsolution',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'showSolution',
      view: {
        name: 'div',
        classes: 'showsolution',
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'showSolution',
      view: (_, { writer }) => {
        const viewElement = writer.createContainerElement('div', { class: 'showsolution' });
        return toWidget(viewElement, writer, { label: 'Show solution widget' });
      },
    });

    // <showSolutionToggle> converters
    conversion.elementToElement({
      model: 'showSolutionToggle',
      view: {
        name: 'div',
        classes: 'showsolution-toggle-link',
      },
    });

    // <showSolutionToggleButton> converters
    conversion.for('upcast').elementToElement({
      model: 'showSolutionToggleButton',
      view: {
        name: 'a',
        classes: 'js-showsolution-toggle',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'showSolutionToggleButton',
      view: {
        name: 'a',
        classes: 'js-showsolution-toggle',
        attributes: {
          role: 'button',
          tabindex: '0',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'showSolutionToggleButton',
      view: (_, { writer }) => {
        const viewElement = writer.createEditableElement('a', {
          class: 'js-showsolution-toggle',
        });
        return toWidgetEditable(viewElement, writer);
      },
    });
    conversion.attributeToAttribute({
      model: {
        name: 'showSolutionToggleButton',
        key: 'contentId',
      },
      view: 'aria-controls',
    });

    // <showSolutionContent> converters
    conversion.for('upcast').elementToElement({
      model: 'showSolutionContent',
      view: {
        name: 'div',
        classes: 'showsolution-content',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'showSolutionContent',
      view: {
        name: 'div',
        classes: 'showsolution-content hidden-widget',
        attributes: {
          'data-gramm': 'false',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'showSolutionContent',
      view: (modelItem, { writer }) => {
        const viewElement = writer.createEditableElement('div', {
          class: 'showsolution-content',
          'data-gramm': 'false',
        });
        return toWidgetEditable(viewElement, writer);
      },
    });
    conversion.attributeToAttribute({
      model: {
        name: 'showSolutionContent',
        key: 'id',
      },
      view: 'id',
    });
  }
}
