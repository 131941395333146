<template>
  <div class="muted">
    <span
      v-if="totalSelectedItems >= 1"
      class="KogTableInfoSelectedTotal-selected padd-right-xs margin-right-xs"
    >
      {{ totalSelectedItems }} selected
    </span>
    <span v-if="isTotalItemsInfoVisible">
      {{ totalItems }}
      {{ type }}{{ totalItems === 1 ? '' : 's' }}
    </span>
    <span v-if="isNoFilterMatchInfoVisible">
      <span v-if="totalItems === 0"> No {{ type }} </span>
      match{{ totalItems === 1 ? 'es' : '' }} your filters
    </span>
  </div>
</template>

<script>
export default {
  name: 'KogTableInfoSelectedTotal',
  props: {
    totalSelectedItems: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      required: true,
    },
    isTotalItemsInfoVisible: {
      type: Boolean,
      default: true,
    },
    isNoFilterMatchInfoVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.KogTableInfoSelectedTotal-selected {
  border-right: solid 1px var(--kog-text-muted);
}
</style>
