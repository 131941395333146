<template>
  <kog-modal ref="root">
    <template #modalBody>
      <div class="ResourceModal">
        <image-box
          v-if="resource.type === 'image' && imageHeight"
          ref="image"
          :src="resource.src"
          :w="692"
          :h="imageHeight"
          :transparent-background="true"
        />
        <div
          v-if="resource.type === 'video'"
          class="ResourceCard-IframeContainer"
        >
          <iframe
            title="Video"
            class="ResourceCard-Iframe"
            :src="resource.src"
            frameborder="0"
            allowfullscreen
          />
        </div>

        <div
          v-if="resource.type === 'interactive'"
          class="ResourceCard-IframeContainer"
        >
          <iframe
            title="Interactive resource"
            class="ResourceCard-Iframe"
            :src="resource.src"
            frameborder="0"
            allowfullscreen
          />
        </div>
        <div class="ResourceModal-Description">
          <a
            :href="sectionUrl"
            @click="trackSectionLink"
          >
            <div
              v-tooltip="{
                theme: 'kog-tooltip',
                content: tooltipText,
              }"
              :aria-label="tooltipText"
              class="text-regular line-clamp-1"
            >
              {{ resource.subject_node_name }}
            </div>
            <div class="text-regular">
              {{ resource.subject_node_number }}
            </div>
          </a>
          <div class="flexContainer flexContainer-alignCenter flexChild-noShrink">
            <content-hidden-label v-if="resource.isHidden" />
            <resource-action-buttons
              :resource="resource"
              :subject="subject"
              source="modal"
            />
          </div>
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { nextTick } from 'vue';

import ResourceActionButtons from 'learning/common/components/media-library/resource-action-buttons.vue';
import SectionUrlMixin from 'learning/common/mixins/section-url-mixin.js';

import ImageBox from 'sharedApp/components/images/image-box.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';

export default {
  name: 'ResourceModal',
  components: {
    ContentHiddenLabel,
    ResourceActionButtons,
    KogModal,
    ImageBox,
  },
  mixins: [SectionUrlMixin],
  props: {
    resource: {
      type: Object,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tooltipText: '',
      imageHeight: 0,
    };
  },
  computed: {
    sectionUrl() {
      const sectionUrl = this.generateSectionUrl(
        this.resource.subject_node_name,
        this.resource.subject_node_id,
        this.$route.params,
        this.resource,
      );
      return sectionUrl.toString();
    },
  },
  mounted() {
    const fullSectionName = this.resource.subject_node_name;
    if (fullSectionName.length > 101) {
      this.tooltipText = fullSectionName;
    }
    nextTick(async () => {
      await new Promise(r => setTimeout(r, 150));
      const minModalHeight = this.$refs.root.$el.clientHeight;
      const modalMaxHeight = (window.innerHeight * 70) / 100;
      this.imageHeight = modalMaxHeight - minModalHeight;
    });
  },
  methods: {
    trackSectionLink() {
      this.$mixpanel.trackEvent('Media library - Click section link', {
        subject_name: this.subject.name,
        resource_url: this.resource.src,
        asset_type: this.resource.type,
        clicked_section_link_source: 'modal',
      });
    },
  },
};
</script>

<style scoped>
.ResourceModal {
  padding: var(--space-s) var(--space-s) 0;
}

.ResourceModal-Description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--space-xs);
}

.ResourceCard-IframeContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.ResourceCard-Iframe {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border: 0;
}
</style>
