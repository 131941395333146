<template>
  <div
    class="padd-top-m EsqAssignmentDetailsTabs flexContainer flexContainer-column flexChild-canGrow"
  >
    <kog-tabs
      v-model:value="activeTab"
      :tabs="tabs"
    />
    <kog-tab-panels
      :value="activeTab"
      class="flexContainer flexContainer-column flexChild-canGrow EsqAssignmentDetailsTabs-tabContainer"
    >
      <kog-tab-panel name="questions">
        <esq-assignment-details-question-table
          class="container-wideWidth padd-top-s padd-bottom-xxl padd-left-xl padd-right-xl"
          :education-system="educationSystem"
          :assignment="assignment"
        />
      </kog-tab-panel>
      <kog-tab-panel name="students">
        <basic-assignment-details-student-table
          v-if="assignment.students.length > 0"
          class="container-wideWidth padd-top-s padd-bottom-xxl padd-left-xl padd-right-xl"
          :assignment="assignment"
        />
        <p
          v-else
          class="text-center padd-top-s"
        >
          This assignment hasn't been sent to any students.
        </p>
      </kog-tab-panel>
    </kog-tab-panels>
  </div>
</template>

<script>
import pluralize from 'pluralize';

import KogTabPanel from 'sharedApp/components/base/tabs/kog-tab-panel.vue';
import KogTabPanels from 'sharedApp/components/base/tabs/kog-tab-panels.vue';
import KogTabs from 'sharedApp/components/base/tabs/kog-tabs.vue';
import mixpanelModule from 'sharedApp/libs/mixpanel.js';
import BasicAssignmentDetailsStudentTable from 'studyApp/components/teacher/assignments/details/table/basic-assignment-details-student-table.vue';
import EsqAssignmentDetailsQuestionTable from 'studyApp/components/teacher/assignments/details/table/esq-assignment-details-question-table.vue';

export default {
  name: 'EsqAssignmentDetailsTabs',
  components: {
    BasicAssignmentDetailsStudentTable,
    EsqAssignmentDetailsQuestionTable,
    KogTabs,
    KogTabPanel,
    KogTabPanels,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 'questions',
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: 'questions',
          label: this.questionsTabLabel,
          onTabSelected: this.onTabSelected,
        },
        {
          name: 'students',
          label: this.studentsTabLabel,
          onTabSelected: this.onTabSelected,
        },
      ];
    },
    totalQuestions() {
      return this.assignment.questions.length;
    },
    totalStudents() {
      return this.assignment.students.length;
    },
    questionsTabLabel() {
      return `${pluralize('Question', this.totalQuestions)} (${this.totalQuestions})`;
    },
    studentsTabLabel() {
      return `${pluralize('Student', this.totalStudents)} (${this.totalStudents})`;
    },
    educationSystem() {
      return this.subject.educationsystem.name;
    },
    defaultTab() {
      return this.$route.query.tab;
    },
  },
  mounted() {
    if (this.defaultTab) {
      this.activeTab = this.defaultTab;
    }
  },
  methods: {
    onTabSelected() {
      this.trackTabChange();
    },
    trackTabChange() {
      mixpanelModule.trackEvent('Assignment Details - Switch tab', {
        tab: this.activeTab,
        assignment_type: this.assignment.assignment_type.toLowerCase(),
      });
    },
  },
};
</script>
<style scoped>
.EsqAssignmentDetailsTabs :deep(.EsqAssignmentDetailsTabs-row) {
  overflow-y: hidden;
  height: 50px;
}

.EsqAssignmentDetailsTabs-tabContainer {
  background-color: var(--kogPlatformGray098);
}
</style>
