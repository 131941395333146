import { fetchExamStyleQuestions } from '@apis/questions.js';

const PAGE_SIZE = 50;

async function listExamStyleQuestionPerSubject(subjectId, subjectNodeId = null, page = 1) {
  const params = { page, page_size: PAGE_SIZE };
  if (subjectNodeId) {
    params.subject_node_id = subjectNodeId;
  }
  const data = await fetchExamStyleQuestions(subjectId, params);
  return data;
}

export default {
  listExamStyleQuestionPerSubject,
};
