<template>
  <div
    class="AssignmentCreateFooter"
    :style="{ bottom: isDemoFooterShowing ? demoFooterHeight : 0 }"
  >
    <div class="AssignmentCreateFooter-container flexContainer-center muted">
      <div class="flexChild-size-1 flexContainer flexContainer-alignBaseline">
        <div
          ref="assignmentName"
          :class="{
            'AssignmentCreateFooter-assignmentNameOneLine': !assignmentNameLongerThan1Line,
            'AssignmentCreateFooter-assignmentNameTwoLine': assignmentNameLongerThan1Line,
          }"
        >
          <div class="line-clamp-2">
            {{ assignmentName }}
          </div>
        </div>
        <div class="AssignmentCreateFooter-savingMessage text-small padd-left-m padd-right-l">
          {{ savingMessage }}
        </div>
      </div>
      <div class="flexChild-size-1 flexContainer-justifyCenter flexContainer">
        <a
          href="#"
          class="margin-right-xs"
          :class="{
            'AssignmentCreateFooter-step--active': isStep1Active,
            'AssignmentCreateFooter-step--inactive': !isStep1Active,
          }"
          :aria-current="currentStep === 1 ? 'step' : false"
          @keyup.enter.prevent.stop="goToStep(1)"
          @click.prevent="goToStep(1)"
        >
          1. Select {{ isReading ? 'sections' : 'questions' }}
        </a>
        »
        <a
          href="#"
          class="margin-left-xs"
          :class="{
            'AssignmentCreateFooter-step--active': isStep2Active,
            'AssignmentCreateFooter-step--inactive': !isStep2Active,
          }"
          :aria-current="currentStep === 2 ? 'step' : false"
          @keyup.enter.prevent.stop="goToStep(2)"
          @click.prevent="goToStep(2)"
        >
          2. Modify assignment
        </a>
      </div>
      <div class="flexChild-size-1 KogButtonSet KogButtonSet--right">
        <button
          class="KogButtonLegacy KogButtonLegacy--subtleLink KogButtonLegacy--s"
          :disabled="isSavingOnConfirm"
          @click="saveForLater()"
        >
          Finish later
        </button>
        <kog-button
          v-if="currentStep === 1"
          :disabled="!isStep2Active"
          label="Step 2"
          button-style="primary"
          @click="goToStep(2)"
        />
        <div
          v-if="currentStep === 2"
          class="KogButtonSet"
        >
          <kog-button
            :disabled="isSendButtonDisabled"
            label="Review and schedule"
            @click="scheduleAssignment()"
          />
          <kog-button
            :disabled="isSendButtonDisabled"
            button-style="primary"
            label="Review and send"
            @click="sendAssignment()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

import { DEMO_FOOTER_HEIGHT_PX } from 'learning/common/components/demo-footer.vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogInfoModal from 'sharedApp/components/modals/kog-info-modal.vue';
import mixpanelModule from 'sharedApp/libs/mixpanel.js';
import {
  isExamStyleType,
  isQuestionType,
  isReadingType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { getDateAsDateObject } from 'sharedApp/utils/time-utils.js';

import ScheduleModal from './sendout-step/schedule-modal.vue';
import SendoutModal from './sendout-step/sendout-modal.vue';

export const ASSIGNMENT_CREATE_FOOTER_HEIGHT_PX = 72;

export default {
  name: 'AssignmentCreateFooter',
  components: {
    KogButton,
  },
  props: {
    assignmentName: {
      type: String,
      default: '',
    },
    assignmentType: {
      type: String,
      required: true,
    },
    isSaving: {
      required: true,
      validator: prop => typeof prop === 'boolean' || prop === null,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    goToStep: {
      type: Function,
      required: true,
    },
    saveAssignmentHandler: {
      type: Function,
      required: true,
    },
    sendAssignmentHandler: {
      type: Function,
      required: true,
    },
    scheduleAssignmentHandler: {
      type: Function,
      required: true,
    },
    getAssignmentDataHandler: {
      type: Function,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
    studentListByClassId: {
      type: Object,
      required: true,
    },
    inputModel: {
      type: Object,
      required: true,
    },
    validateAssignmentForSending: {
      type: Function,
      required: true,
    },
    updateFailed: {
      type: Boolean,
      required: true,
    },
    isDemoFooterShowing: {
      type: Boolean,
      required: true,
    },
    hasHiddenSections: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      assignmentNameLongerThan1Line: false,
      isSavingOnConfirm: false,
    };
  },
  computed: {
    isSendButtonDisabled() {
      return this.isSavingOnConfirm || this.selectedItems.length === 0;
    },
    isStep1Active() {
      return this.currentStep !== 1;
    },
    isStep2Active() {
      return this.currentStep !== 2 && this.selectedItems.length > 0;
    },
    isQuestion() {
      return isQuestionType(this.assignmentType);
    },
    isReading() {
      return isReadingType(this.assignmentType);
    },
    isExamStyle() {
      return isExamStyleType(this.assignmentType);
    },
    savingMessage() {
      if (this.isSaving) {
        return 'Saving assignment...';
      }
      if (this.isSaving === false) {
        return 'Saved to drafts!';
      }
      return '';
    },
  },
  watch: {
    assignmentName: {
      immediate: true,
      handler() {
        nextTick(() => {
          const assignmentNameHeight = this.$refs.assignmentName.clientHeight;
          if (assignmentNameHeight > 25) {
            this.assignmentNameLongerThan1Line = true;
          } else {
            this.assignmentNameLongerThan1Line = false;
          }
        });
      },
    },
  },
  created() {
    this.demoFooterHeight = `${DEMO_FOOTER_HEIGHT_PX}px`;
  },
  methods: {
    async saveAssignmentOnConfirm() {
      this.isSavingOnConfirm = true;
      await this.saveAssignmentHandler();
      this.isSavingOnConfirm = false;
    },
    saveForLater() {
      this.isSavingOnConfirm = true;
      this.saveAssignmentHandler('draft');
    },
    async sendAssignment() {
      if (!this.validateAssignmentForSending()) {
        this.showMissingInputModal();
        return;
      }
      await this.saveAssignmentOnConfirm();
      const assignmentData = this.getAssignmentDataHandler();
      this.trackAssignmentPreview(assignmentData);
      this.$modal(SendoutModal, {
        assignmentData,
        studentListByClassId: this.studentListByClassId,
        sendAssignmentHandler: this.sendAssignmentHandler,
        hasHiddenSections: this.hasHiddenSections,
        isSaving: () => this.isSaving,
        updateFailed: () => this.updateFailed,
      });
    },
    scheduleAssignment() {
      this.saveAssignmentOnConfirm();
      const assignmentData = this.getAssignmentDataHandler();
      if (!this.validateAssignmentForSending()) {
        this.showMissingInputModal();
        return;
      }
      const deadlineAsDate = getDateAsDateObject(assignmentData.deadline);
      this.$modal(ScheduleModal, {
        assignmentData,
        studentListByClassId: this.studentListByClassId,
        deadlineDate: deadlineAsDate,
        hasHiddenSections: this.hasHiddenSections,
        scheduleAssignmentHandler: (sendAtDate, googleClasses) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.inputModel.scheduled_sent_at = sendAtDate;
          return this.scheduleAssignmentHandler(googleClasses);
        },
        isSaving: () => this.isSaving,
        updateFailed: () => this.updateFailed,
      });
      this.trackScheduleAssignmentModal();
    },
    trackScheduleAssignmentModal() {
      const properties = {
        subject_name: this.subjectName,
        assignment_type: this.assignmentType.toLowerCase(),
        num_subject_classes: this.inputModel.subject_classes.length,
      };
      mixpanelModule.trackEvent('Assignment - Open schedule modal', properties);
    },
    showMissingInputModal() {
      this.$modal(KogInfoModal, {
        title: 'You missed something...',
        text: `It does not seem that everything is in order for sending out the assignment.
            Have another look. We have marked invalid things in red.`,
      });
    },
    trackAssignmentPreview(assignmentData) {
      const properties = {
        class_id: assignmentData.subject_class_id,
        subject_name: this.subjectName,
        assignment_type: this.assignmentType.toLowerCase(),
        num_subject_classes: this.inputModel.subject_classes.length,
      };
      if (this.isQuestion || this.isExamStyle) {
        properties.assignment_number_questions = assignmentData.questions.length;
      }
      if (this.isReading) {
        properties.assignment_number_sections = assignmentData.section_node_ids.length;
      }
      mixpanelModule.trackEvent('Assignment - Preview', properties);
    },
  },
};
</script>

<style scoped>
.AssignmentCreateFooter {
  position: fixed;
  z-index: 500;
  right: 0;
  bottom: 0;
  left: 0;

  height: 72px;

  background-color: var(--kog-background-default-0);
  box-shadow: 0 -8px 16px -4px var(--kogShadow020);
}

.AssignmentCreateFooter-container {
  display: flex;
  height: 100%;
  margin: auto;
  padding: var(--space-s) var(--space-l);
}

.AssignmentCreateFooter-assignmentNameTwoLine {
  font-size: 16px;
}

.AssignmentCreateFooter-assignmentNameOneLine {
  font-size: 20px;
}

@media (max-width: 1280px) {
  .AssignmentCreateFooter-assignmentNameOneLine {
    padding-right: 16px;
    font-size: 18px;
  }
}

@media (max-width: 996px) {
  .AssignmentCreateFooter-assignmentNameOneLine {
    font-size: 16px;
  }
}

.AssignmentCreateFooter-step--active {
  cursor: pointer;
  color: var(--kog-link-color);
  text-decoration: underline;
}

.AssignmentCreateFooter-step--active:hover,
.AssignmentCreateFooter-step--active:focus {
  color: var(--kog-link-hover-color);
}

.AssignmentCreateFooter-step--inactive,
.AssignmentCreateFooter-step--inactive:hover {
  cursor: not-allowed;
  color: var(--kog-text-muted);
  text-decoration: none;
}

.AssignmentCreateFooter-savingMessage {
  flex-shrink: 0;
}
</style>
