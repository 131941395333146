<template>
  <section class="kog-container">
    <div class="kog-row">
      <div class="kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
        <kog-card :default-padding="false">
          <div class="padd-top-l padd-left-l padd-right-l padd-bottom-xxs">
            <h1 class="padd-bottom-xxs margin-bottom-l text-center"> Results </h1>
            <kog-loader
              :loading="!occasion"
              loading-msg="Gathering your results..."
            >
              <strength-test-result-score :percent-rounded="percentRounded" />
              <h2 class="text-center PracticeTestResult-answersHeader margin-bottom-m">
                Your answers
              </h2>
              <practice-item-results
                v-if="occasion"
                :practice-items="occasion.practice_items"
                :subject="subject"
                source="Strength Test Result"
              />
            </kog-loader>
          </div>
        </kog-card>
      </div>
    </div>
    <div class="kog-row">
      <div class="kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
        <footer
          v-if="occasion"
          class="flexContainer flexContainer-alignCenter flexContainer-flexEnd margin-top-s"
        >
          <div>
            <router-link
              v-if="nextSectionNode"
              class="text-baseline margin-right-s"
              :to="goToBookNode(nextSectionNode)"
              :disabled="isNavigationDisabled ? true : null"
              target="_self"
            >
              Continue studying {{ nextSectionNode.name }}
            </router-link>
          </div>
          <router-link
            class="KogButtonLegacy KogButtonLegacy--default KogButtonLegacy--iconLeft margin-right-s"
            :to="{
              name: 'classStrengthTestSetup',
              params: {
                classSlug: subjectClassSlug,
                sid: subjectId,
                cid: classId,
              },
            }"
            :disabled="isNavigationDisabled ? true : null"
          >
            Back to test setup
          </router-link>
          <button
            class="KogButtonLegacy KogButtonLegacy--primary KogButtonLegacy--iconLeft"
            :href="practiceTestSetupPath"
            :disabled="isNavigationDisabled"
            @click="recreateOccasionFromCurrent"
            @keyup.enter.stop="recreateOccasionFromCurrent"
          >
            Test again
          </button>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { mapWaitingActions } from 'vue-wait';
import { mapState, useStore } from 'vuex';

import reverse from 'learning/common/libs/kog-router.js';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import posthog from 'sharedApp/libs/posthog.ts';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import kogNodeLookupService from 'sharedApp/services/utils/nodeLookupService/node-lookup-service.js';
import PracticeItemResults from 'studyApp/components/practice-item-results.vue';

import StrengthTestResultScore from './strength-test-result-score.vue';

export default {
  name: 'PracticeTestResult',
  components: {
    KogCard,
    StrengthTestResultScore,
    PracticeItemResults,
    KogLoader,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const { subjectModule } = store.state;
    const subjectName = computed(() => subjectModule.subject.name);

    useHead({
      title: () => `Strength test results | ${subjectName.value}`,
    });
  },
  computed: {
    ...mapState({
      occasion: state => state.practiceOccasionModule.practiceOccasion,
      subject: state => state.subjectModule.subject,
    }),
    practiceTestSetupPath() {
      return reverse('studyApp', 'practiceTestSetup', { subject_slug: this.subject.slug });
    },
    percentRounded() {
      if (this.occasion && this.occasion.total_answer_count > 0) {
        return Math.round(
          (this.occasion.correct_answer_count / this.occasion.total_answer_count) * 100,
        );
      }
      return 0;
    },
    nextSectionNode() {
      if (this.subject && this.nextSectionNodeId) {
        const id = Number(this.nextSectionNodeId);
        return kogNodeLookupService.findNodeById(id, this.subject.subject_tree);
      }
      return null;
    },
    isNavigationDisabled() {
      return this.$wait.is('loading_occasion_creation');
    },
    nextSectionNodeId() {
      return this.$route.query.next;
    },
  },
  mounted() {
    posthog.capture('open_strength_test_result');
    const eventName = 'Strength Test - pageview';
    const {
      correct_answer_count: correctAnswerCount,
      incorrect_answer_count: incorrectAnswerCount,
    } = this.occasion;
    const eventProperties = {
      correct_answers: correctAnswerCount,
      incorrect_answers: incorrectAnswerCount,
    };
    this.$mixpanel.trackEvent(eventName, eventProperties);
  },
  methods: {
    ...mapWaitingActions('practiceOccasionModule', {
      createSubjectStrengthOccasion: 'loading_occasion_creation',
    }),
    async recreateOccasionFromCurrent() {
      const data = {
        subjectId: this.subject.id,
        selectedNodes: this.occasion.chosen_subjectnodes,
      };

      try {
        const occasion = await this.createSubjectStrengthOccasion(data);
        this.goToStrengthTestTake(occasion.id);
      } catch (e) {
        this.handleOccasionCreatedFailed(e);
      }
    },
    goToBookNode(node) {
      return {
        name: 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
      };
    },
    goToStrengthTestTake(occasionId) {
      this.$router.push({
        name: 'classStrengthTest',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          occasionId,
        },
      });
    },
    handleOccasionCreatedFailed(rawError) {
      const errorResponse = rawError.response;
      if (
        errorResponse &&
        errorResponse.data &&
        errorResponse.data.non_field_errors &&
        errorResponse.data.non_field_errors.length > 0
      ) {
        const {
          data: {
            non_field_errors: [error],
          },
        } = errorResponse;
        this.$toast.showError(error);
      } else {
        this.$toast.showError(
          'There was an error while starting the strength test, please try again',
        );
      }
    },
  },
};
</script>

<style scoped>
.PracticeTestResult-answersHeader {
  margin-top: 44px;
  font-size: 20px;
  font-weight: unset;
}
</style>
