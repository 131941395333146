const RouteNavigationEventInjection = Symbol('route-navigation-mixin');

export const RouteNavigationEventProvide = {
  data() {
    return {
      mixinRouteNavigationListeners: {},
    };
  },
  computed: {
    listeners() {
      return Object.values(this.mixinRouteNavigationListeners);
    },
  },
  provide() {
    const routeEvent = {
      register: listener => {
        const uuid = window.crypto.randomUUID();
        this.mixinRouteNavigationListeners[uuid] = listener;

        return () => {
          this.mixinRouteNavigationListeners[uuid] = null;
          delete this.mixinRouteNavigationListeners[uuid];
        };
      },
    };

    return {
      [RouteNavigationEventInjection]: routeEvent,
    };
  },
};

export const RouteNavigationEventInject = {
  inject: {
    routeEvent: {
      from: RouteNavigationEventInjection,
      default: {
        register: () => {},
      },
    },
  },
  beforeUnmount() {
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe();
    }
  },
};
