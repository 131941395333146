import { ref } from 'vue';
import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';

import type { Annotation, AnnotationMeta } from './types.ts';

import * as annotationsApi from 'learning/common/api/annotations.js';
import { DEFAULT_HIGHLIGHT_COLOR } from 'learning/common/libs/annotations-utils.js';

import { TEMP_ID } from './types.ts';

function newAnnotationTemplate(editing = true): AnnotationMeta {
  return {
    annotation: {
      note: null,
      lastModified: new Date(),
      last_modified: '',
      color: DEFAULT_HIGHLIGHT_COLOR,
      location_hint: null,
    },
    focused: false,
    confirmed: false,
    isEditing: editing,
    location: {
      subject_name: '',
      subject_node_name: '',
    },
    url: '',
    isSoftDeleted: false,
  };
}

export default defineStore('annotations', () => {
  // State
  const annotations = ref<Record<number | string, AnnotationMeta>>({});
  const focusedAnnotation = ref<AnnotationMeta | null>(null);
  const deletedAnnotations = ref<Record<number, AnnotationMeta>>({});
  const isSaving = ref<boolean>(false);
  const lastSoftDeletedId = ref<number | null>(null);
  const lastSoftRestoredId = ref<number | null>(null);

  async function fetchAnnotations(subjectNodeId: number) {
    let data: Annotation[];
    try {
      data = await annotationsApi.fetchAnnotations(subjectNodeId);
    } catch (error) {
      Sentry.captureMessage(`fetchAnnotations: Error: ${error}`);
      return [];
    }
    const newAnnotations = data.map(annotation => {
      const newAnnotationMeta = newAnnotationTemplate(false);
      newAnnotationMeta.annotation = annotation;
      newAnnotationMeta.subjectNodeId = subjectNodeId;
      newAnnotationMeta.confirmed = true;
      newAnnotationMeta.annotation.lastModified = new Date(annotation.last_modified);
      return newAnnotationMeta;
    });

    annotations.value = newAnnotations.reduce(
      (acc, annotationObj) => {
        const { id } = annotationObj.annotation;
        if (typeof id === 'number') {
          acc[id] = annotationObj;
        }
        return acc;
      },
      {} as Record<number, AnnotationMeta>,
    );

    return newAnnotations;
  }

  function getNewAnnotationTemplate() {
    return newAnnotationTemplate();
  }

  function startNewAnnotation(annotation: AnnotationMeta) {
    const newAnnitation = { ...annotation };
    newAnnitation.annotation.id = TEMP_ID;
    annotations.value[newAnnitation.annotation.id] = newAnnitation;
    focusedAnnotation.value = newAnnitation;
  }

  async function saveFocusedAnnotation() {
    isSaving.value = true;

    if (!focusedAnnotation.value) {
      return;
    }

    const postData = { ...focusedAnnotation.value.annotation };
    const isNew = postData.id === TEMP_ID;

    try {
      let data = null;
      if (!postData.id || isNew) {
        delete postData.id;
        data = await annotationsApi.createAnnotation(
          focusedAnnotation.value.subjectNodeId,
          postData,
        );
      } else {
        data = await annotationsApi.updateAnnotation(
          focusedAnnotation.value.subjectNodeId,
          postData.id,
          postData,
        );
      }

      focusedAnnotation.value.annotation.id = data.id;
      focusedAnnotation.value.annotation.note = data.note;

      annotations.value = {
        ...annotations.value,
        [data.id]: focusedAnnotation.value,
      };
      if (annotations.value[TEMP_ID]) {
        delete annotations.value[TEMP_ID];
      }

      isSaving.value = false;
      focusedAnnotation.value.isEditing = false;
    } catch (error) {
      isSaving.value = false;
      Sentry.captureMessage(`saveFocusedAnnotation: Error: ${error}`);
    }
  }

  async function saveHighlight() {
    if (!focusedAnnotation.value) {
      return;
    }

    focusedAnnotation.value.confirmed = true;
    focusedAnnotation.value.isEditing = false;
    focusedAnnotation.value.annotation.note = '';

    await saveFocusedAnnotation();
  }

  function deleteAnnotation(id: number) {
    const annotation = annotations.value[id];
    if (!annotation) {
      return null;
    }

    delete annotations.value[id];
    const { subjectNodeId } = annotation;
    annotationsApi.deleteAnnotation(subjectNodeId, id);
    return true;
  }

  async function restoreDeletedAnnotation(id: number) {
    focusedAnnotation.value = deletedAnnotations.value[id];
    await saveHighlight();
    delete deletedAnnotations.value[id];
  }

  function clearFocusedAnnotation() {
    if (!focusedAnnotation.value) {
      return;
    }

    if (isSaving.value) {
      return;
    }

    if (focusedAnnotation.value && focusedAnnotation.value.annotation.id === TEMP_ID) {
      delete annotations.value[TEMP_ID];
    }
    focusedAnnotation.value.isEditing = false;
    focusedAnnotation.value = null;
  }

  function setFocusedAnnotation(annotation: AnnotationMeta) {
    if (focusedAnnotation.value) {
      focusedAnnotation.value.focused = false;
    }
    focusedAnnotation.value = annotation;

    if (focusedAnnotation.value) {
      focusedAnnotation.value.focused = true;
    }
  }

  function setFocusedAnnotationById(id: number) {
    const annotation = annotations.value[id];
    setFocusedAnnotation(annotation);
    return annotation;
  }

  function setFocusedAnnotationUrl(url: string) {
    if (focusedAnnotation.value) {
      focusedAnnotation.value.url = url;
    }
  }

  function startEditing() {
    if (focusedAnnotation.value) {
      focusedAnnotation.value.isEditing = true;
    }
  }

  function stopEditing() {
    if (focusedAnnotation.value) {
      focusedAnnotation.value.isEditing = false;
    }
  }

  function setAnnotationSoftDeletedStatus(id: number, softDeleted: boolean) {
    const annotation = annotations.value[id];
    annotation.isSoftDeleted = softDeleted;
    annotations.value = {
      ...annotations.value,
      [id]: annotation,
    };
  }

  function softDeleteAnnotation(id: number) {
    setAnnotationSoftDeletedStatus(id, true);
    lastSoftDeletedId.value = id;
    if (id === lastSoftRestoredId.value) {
      lastSoftRestoredId.value = null;
    }
  }

  function revertSoftDeleteAnnotation(id: number) {
    setAnnotationSoftDeletedStatus(id, false);
    lastSoftRestoredId.value = id;
    if (id === lastSoftRestoredId.value) {
      lastSoftDeletedId.value = null;
    }
  }

  return {
    // State
    annotations,
    focusedAnnotation,
    deletedAnnotations,
    isSaving,
    lastSoftDeletedId,
    lastSoftRestoredId,

    // Actions
    fetchAnnotations,
    startNewAnnotation,
    saveFocusedAnnotation,
    saveHighlight,
    deleteAnnotation,
    restoreDeletedAnnotation,
    clearFocusedAnnotation,
    setFocusedAnnotationById,
    setFocusedAnnotationUrl,
    getNewAnnotationTemplate,
    startEditing,
    stopEditing,
    softDeleteAnnotation,
    revertSoftDeleteAnnotation,
    setFocusedAnnotation,
  };
});
