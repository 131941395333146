<template>
  <div
    class="QuestionInput"
    :class="[
      classAttr,
      {
        'QuestionInput-Solution': isSolution,
        'QuestionInput-Correct': isCorrect,
        'QuestionInput-Incorrect': isIncorrect,
        'QuestionInput-Index': shouldDisplayInputIndex,
        FBQInput: isFBQ,
        STQInput: isSTQ,
      },
    ]"
    :style="styleAttr"
    :data-position="positionIndex"
  >
    <kog-input v-bind="propsAndListenersToPass" />

    <kog-round-button
      v-if="isDismissible && isNeutral && !isDisabled"
      class="QuestionInput-dismissButton"
      aria-label="remove answer"
      button-style="secondary-basic"
      icon-class="fa-times"
      icon-size="s"
      :size="dismissibleButtonSize"
      @click.prevent="$emit('dismiss')"
    />

    <kog-icon
      v-if="!isNeutral"
      class="QuestionInput-StateIcon"
      :icon-class="getStateIcon"
      size="s"
    />
  </div>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useStyleClassExtractedAttrs from 'sharedApp/composables/use-style-class-extracted-attrs.ts';

import KogRoundButton from '../base/buttons/kog-round-button.vue';
import KogInput from '../base/input/kog-input.vue';
import QuestionInputMixin from './question-input-mixin.js';
import { DISPLAY_STATE, VALID_QUESTION_TYPES } from './question-input-utility.js';

export default {
  name: 'QuestionInput',
  components: {
    KogInput,
    KogIcon,
    KogRoundButton,
  },
  mixins: [QuestionInputMixin],
  inheritAttrs: false,
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    displayState: {
      type: String,
      default: DISPLAY_STATE.NEUTRAL,
      validator: prop => Object.values(DISPLAY_STATE).includes(prop),
    },
    positionIndex: {
      type: Number,
      default: undefined,
      validator: val => val > 0,
    },
    // eslint-disable-next-line vue/no-unused-properties
    questionType: {
      type: String,
      required: true,
      validator: val => Object.values(VALID_QUESTION_TYPES).includes(val),
    },
    inputIcon: {
      type: String,
      default: undefined,
    },
    isDismissible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['dismiss'],
  setup() {
    const extractedAttrs = useStyleClassExtractedAttrs();

    return {
      ...extractedAttrs,
    };
  },
  computed: {
    dismissibleButtonSize() {
      return this.isFBQ ? 'xx-small' : 'x-small';
    },
    getStateIcon() {
      return this.isCorrect || this.isSolution ? 'fa-check' : 'fa-xmark';
    },
    shouldDisplayInputIndex() {
      return this.positionIndex;
    },
    isDisabled() {
      return this.$attrs.disabled || this.isCorrect || this.isSolution;
    },
    propsAndListenersToPass() {
      return {
        ...this.otherAttrs,
        styleType: 'prominent',
        disabled: this.isDisabled,
        isLabelHidden: true,
        labelIcon: null,
        topRightHelperText: null,
        helperText: null,
        inputIcon: this.shouldDisplayInputIndex ? null : this.inputIcon,
      };
    },
  },
};
</script>

<style scoped>
.QuestionInput {
  position: relative;
  line-height: 24px;
  transition:
    background-color ease-in-out 0.15s,
    border-color ease-in-out 0.15s;
}

.FBQInput {
  display: inline-block;
  max-width: 150px;
  white-space: normal;
}

.QuestionInput :deep(.KogInput-icon) {
  font-size: 14px;
}

.FBQInput :deep(.KogInput-icon) {
  height: 30px;
}

.QuestionInput :deep(input) {
  height: 40px;
  font-family: var(--kog-satoshi);
}

.FBQInput :deep(input) {
  height: 30px;
  min-height: 30px;
  font-size: 14px;
}

.QuestionInput-Index :deep(input) {
  padding-left: var(--space-xl);
}

.QuestionInput-Index::before {
  content: attr(data-position);

  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(8px, -50%);

  display: block;

  width: var(--space-m);
  height: var(--space-m);

  font-family: var(--kog-satoshi);
  font-size: 10px;
  font-weight: bold;
  line-height: var(--space-m);
  text-align: center;

  background-color: var(--kog-colors-white);
  border-radius: 50%;
}

.QuestionInput.QuestionInput-Solution :deep(input) {
  border: none;
}
.QuestionInput.QuestionInput-Correct :deep(input),
.QuestionInput.QuestionInput-Incorrect :deep(input) {
  border-bottom-width: 2px;
}

.QuestionInput.QuestionInput-Solution :deep(input),
.QuestionInput.QuestionInput-Correct :deep(input),
.QuestionInput.QuestionInput-Incorrect :deep(input) {
  overflow: hidden;
  padding-right: var(--space-l);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.QuestionInput.QuestionInput-Solution :deep(input),
.QuestionInput.QuestionInput-Correct :deep(input) {
  background-color: var(--kog-colors-green-100);
  border-color: var(--kog-colors-green-800);
}

.QuestionInput-dismissButton,
.QuestionInput-StateIcon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.QuestionInput.QuestionInput-Solution .QuestionInput-StateIcon,
.QuestionInput.QuestionInput-Correct .QuestionInput-StateIcon {
  color: var(--kog-colors-green-800);
}

.QuestionInput.QuestionInput-Incorrect .QuestionInput-StateIcon {
  color: var(--kog-colors-pink-700);
}

.QuestionInput.QuestionInput-Incorrect :deep(input) {
  background-color: var(--kog-colors-pink-100);
  border-color: var(--kog-colors-pink-700);
}

.QuestionInput.QuestionInput-Correct :deep(input:focus),
.QuestionInput.QuestionInput-Incorrect :deep(input:focus),
.QuestionInput.QuestionInput-Correct :deep(input:focus-visible),
.QuestionInput.QuestionInput-Incorrect :deep(input:focus-visible),
.QuestionInput.QuestionInput-Correct :deep(input:hover:not(:disabled)),
.QuestionInput.QuestionInput-Incorrect :deep(input:hover:not(:disabled)) {
  padding-bottom: calc(var(--space-xs) + 1px);
}

.QuestionInput.QuestionInput-Correct :deep(input:hover:not(:disabled):not(:focus)) {
  border-color: var(--kog-colors-green-800);
}

.QuestionInput.QuestionInput-Incorrect :deep(input:hover:not(:disabled):not(:focus)) {
  border-color: var(--kog-colors-pink-700);
}
</style>
