export default class NavigationMenusBuilder {
  constructor() {
    this.menus = [];
  }

  addMenu(menu) {
    this.menus.push(menu);
  }

  addSubMenu(subMenu) {
    const lastMenu = this.menus[this.menus.length - 1];
    lastMenu.subMenuItems.push(subMenu);
  }

  build() {
    return this.menus;
  }
}
