import Gifffer from 'gifffer';

import ResourceFullscreen from 'sharedApp/components/resource-fullscreen/resource-fullscreen.vue';
import useModal from 'sharedApp/composables/use-modal.ts';

export default function useImageClickEvent() {
  const { openModal } = useModal();

  function isGifImage(src: string) {
    return src.endsWith('.gif');
  }

  function openImageDialog(imageElement: HTMLImageElement) {
    const imageSource = imageElement.getAttribute('data-largeSrc') ?? imageElement.src;
    const imageAlt = imageElement.getAttribute('alt');

    const modalProps = {
      url: imageSource,
      type: 'image',
      alt: imageAlt,
    };

    openModal(ResourceFullscreen, modalProps);
  }

  function imageClickGifHandler(imageElement: HTMLImageElement, src: string) {
    imageElement.setAttribute('data-gifffer', src);

    const alt = imageElement.getAttribute('alt') ?? '';
    imageElement.setAttribute('data-gifffer-alt', alt);

    const title = imageElement.getAttribute('title') ?? '';
    imageElement.setAttribute('data-gifffer-title', title);

    const height = imageElement.getAttribute('height') ?? '';
    imageElement.setAttribute('data-gifffer-height', height);

    const width = imageElement.getAttribute('width') ?? '';
    imageElement.setAttribute('data-gifffer-width', width);
  }

  function imageClickHandler(imageElement: HTMLImageElement) {
    const src = imageElement.getAttribute('data-largeSrc') ?? imageElement.src;

    const parentElement = imageElement.parentNode;
    if (parentElement) {
      (parentElement as HTMLElement).classList.add('content-image-figure');
    }

    if (isGifImage(src)) {
      imageClickGifHandler(imageElement, src);
    } else {
      imageElement.addEventListener('click', event =>
        openImageDialog(event.target as HTMLImageElement),
      );
    }
  }

  function registerImageClickHandler(elem: Element) {
    const imagesElement = elem.querySelectorAll('img');
    imagesElement.forEach(imageElement => imageClickHandler(imageElement));
    Gifffer();
  }

  return {
    registerImageClickHandler,
  };
}
