<template>
  <kog-select
    icon="fa-calendar"
    :label="label"
    :is-label-hidden="isLabelHidden"
    :options="dateOptions"
    :selected-value="selectedValue"
    dropdown-item-type="radio"
    @change="onOptionChecked"
    @custom-option-value-change="onCustomOptionValueChanged"
  />
</template>

<script>
import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import { getDayFromXDaysAgo, getDayFromXMonthsAgo } from 'sharedApp/utils/time-utils.js';

export const SHOW_ALL_DATES = 'all';

export function parseDateInterval(value) {
  const selectedDates = value.split(' to ');
  if (selectedDates.length === 0) {
    return null;
  }

  const fromTimestamp = Date.parse(selectedDates[0]);
  const fromDateStr = new Date(fromTimestamp).toISOString();

  let toTimestamp;
  if (selectedDates.length > 1) {
    toTimestamp = Date.parse(selectedDates[1]);
  } else {
    toTimestamp = fromTimestamp;
  }

  const toDate = new Date(toTimestamp);
  toDate.setDate(toDate.getDate() + 1);
  const toDateStr = toDate.toISOString();

  return { fromDateStr, toDateStr };
}

export default {
  name: 'IntervalSelector',
  components: {
    KogSelect,
  },
  props: {
    /**
     * The label should always be specified, for accessibility purposes.
     * If you don't want it to be visible, use `isLabelHidden: true`
     */
    label: {
      type: String,
      required: true,
    },
    isLabelHidden: {
      type: Boolean,
      default: false,
    },
    /**
     * The value of the currently selected option.
     */
    selectedValue: {
      type: [Object, String, Number],
      default: null,
    },
  },
  emits: ['date-interval-selected', 'change', 'update:selectedValue'],
  data: () => ({
    dateOptions: [],
  }),
  computed: {
    flatOptions() {
      return this.dateOptions.flatMap(op => op.options ?? op);
    },
  },
  watch: {
    selectedValue() {
      this.updateCustomOptionValue();
    },
  },
  created() {
    this.buildDateOptions();
    this.updateCustomOptionValue();
  },
  methods: {
    buildDateOptions() {
      const today = getDayFromXDaysAgo(0);
      const yesterday = getDayFromXDaysAgo(1);
      const last7Days = getDayFromXDaysAgo(7);
      const last30Days = getDayFromXDaysAgo(30);
      const last3Months = getDayFromXMonthsAgo(3);
      const last6Months = getDayFromXMonthsAgo(6);
      const last12Months = getDayFromXMonthsAgo(12);

      this.dateOptions = [
        {
          text: 'Default',
          options: [
            {
              text: 'Show all dates',
              value: SHOW_ALL_DATES,
            },
          ],
        },
        {
          text: 'Filter by date',
          options: [
            {
              text: 'Today',
              value: `${today.toISOString()} to ${today.toISOString()}`,
            },
            {
              text: 'Yesterday',
              value: `${yesterday.toISOString()} to ${yesterday.toISOString()}`,
            },
            {
              text: 'Last 7 days',
              value: `${last7Days.toISOString()} to ${today.toISOString()}`,
            },
            {
              text: 'Last 30 days',
              value: `${last30Days.toISOString()} to ${today.toISOString()}`,
            },
            {
              text: 'Last 3 months',
              value: `${last3Months.toISOString()} to ${today.toISOString()}`,
            },
            {
              text: 'Last 6 months',
              value: `${last6Months.toISOString()} to ${today.toISOString()}`,
            },
            {
              text: 'Last 12 months',
              value: `${last12Months.toISOString()} to ${today.toISOString()}`,
            },
            {
              value: 'Custom',
              text: 'Custom',
              customEditor: 'date',
              customEditorValue: '',
              customEditorConfig: {
                dateFormat: 'Y-m-d',
                enableTime: false,
                allowInput: false,
                time_24hr: true,
                maxDate: 'today',
                mode: 'range',
                placeholder: 'Select date range',
              },
            },
          ],
        },
      ];
    },
    updateCustomOptionValue() {
      if (!this.flatOptions.some(op => op.value === this.selectedValue)) {
        const customOption = this.flatOptions.find(op => op.value === 'Custom');
        if (customOption) {
          customOption.customEditorValue = this.selectedValue;
        }
      }
    },
    onCustomOptionValueChanged(option) {
      const optionToModify = this.flatOptions.find(op => op.value === option.value);
      optionToModify.customEditorValue = option.newValue;
    },
    onOptionChecked(value) {
      this.$emit('update:selectedValue', value);

      if (!value || value === SHOW_ALL_DATES) {
        this.$emit('date-interval-selected', { fromDateStr: '', toDateStr: '' });
      } else if (value.includes(' to ')) {
        const { fromDateStr, toDateStr } = parseDateInterval(value);
        /**
         * Emits parsed value of the selected radio option
         * or the one chosen in the custom date editor only when
         * this represents a correct date interval.
         * Emitted value has this format: { fromDateStr, toDateStr }
         *
         * @event date-interval-selected
         * @type {Object}
         */
        this.$emit('date-interval-selected', { fromDateStr, toDateStr });
      }

      /**
       * Emits exact value of the selected radio option
       * or the one chosen in the custom date editor
       *
       * @event change
       * @type {string}
       */
      this.$emit('change', value);
    },
  },
};
</script>
