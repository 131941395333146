<template>
  <div>
    <div class="EmptyTable flexContainer flexContainer-column flexContainer-alignCenter width-full">
      <h4 class="margin-0"> No students </h4>
      <p class="margin-top-fine-2 margin-bottom-fine-2 text-center">
        This class does not have any students assigned to it.
      </p>
      <kog-button
        :disabled="isAddableStudentsLoading"
        button-style="primary"
        label="Add students"
        @click="openAddStudentModal"
      />
    </div>
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

export default {
  name: 'StudentsEmptySection',
  components: { KogButton },
  props: {
    openAddStudentModal: {
      type: Function,
      required: true,
    },
    isAddableStudentsLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.EmptyTable {
  padding: 20px 40px 15px 40px;
  background-color: var(--kogPlatformGray096);
  border-radius: 5px;
}
</style>
