export default class StudyAppLocalStorage {
  #lastOpenTopicKey;

  #lastUsedInsightsFiltersKeys;

  #autoOpenNotebook;

  #autoOpenBookToc;

  #autoOpenSubjectClassOverviewToc;

  #unitContentExpandedInToc;

  #sendAssignmentToEdlink;

  #lastUrl;

  constructor(storage) {
    this.storage = storage;
    this.#lastOpenTopicKey = 'subjects-last-open-topic';
    this.#lastUsedInsightsFiltersKeys = 'insights-filters';
    this.#autoOpenNotebook = 'auto-open-notebook';
    this.#autoOpenBookToc = 'auto-open-book-toc';
    this.#autoOpenSubjectClassOverviewToc = 'auto-open-subject-class-overview-toc';
    this.#unitContentExpandedInToc = 'unit-content-expanded-in-toc';
    this.#sendAssignmentToEdlink = 'send-assignment-to-edlink';
    this.#lastUrl = 'last-url';
  }

  #getLastOpenTopics() {
    return JSON.parse(this.storage.getItem(this.#lastOpenTopicKey));
  }

  getLastUsedInsightsFilters() {
    return JSON.parse(this.storage.getItem(this.#lastUsedInsightsFiltersKeys));
  }

  getLastOpenTopicBySubjectUser(uid, sid, cid = 0) {
    const lastOpenedTopics = this.#getLastOpenTopics();
    const key = `${uid}-${sid}-${cid}`;
    if (lastOpenedTopics && lastOpenedTopics[key] !== undefined) {
      return lastOpenedTopics[key];
    }

    return null;
  }

  setLastOpenTopicForSubjectUser(topicId, uid, sid, cid = 0) {
    let lastOpenedTopics = this.#getLastOpenTopics();

    if (!lastOpenedTopics) {
      lastOpenedTopics = {};
    }

    lastOpenedTopics[`${uid}-${sid}-${cid}`] = topicId;
    this.storage.setItem(this.#lastOpenTopicKey, JSON.stringify(lastOpenedTopics));
  }

  getLastUsedFiltersByKey(key) {
    const lastUsedFilters = this.getLastUsedInsightsFilters();
    if (lastUsedFilters && lastUsedFilters[key] !== undefined) {
      return lastUsedFilters[key];
    }
    return null;
  }

  setLastUsedFiltersForKey(key, filters) {
    let lastUsedFilters = this.getLastUsedInsightsFilters();
    if (!lastUsedFilters) {
      lastUsedFilters = {};
    }
    lastUsedFilters[key] = filters;
    this.storage.setItem(this.#lastUsedInsightsFiltersKeys, JSON.stringify(lastUsedFilters));
  }

  getAutoOpenNotebook() {
    const value = this.storage.getItem(this.#autoOpenNotebook);
    return value === 'true' || value === null;
  }

  setAutoOpenNotebook(value) {
    this.storage.setItem(this.#autoOpenNotebook, value.toString());
  }

  getAutoOpenBookToc() {
    const value = this.storage.getItem(this.#autoOpenBookToc);
    return value === 'true' || value === null;
  }

  setAutoOpenBookToc(value) {
    this.storage.setItem(this.#autoOpenBookToc, value.toString());
  }

  getUnitContentExpandedInToc() {
    const value = this.storage.getItem(this.#unitContentExpandedInToc);
    return value === 'true' || value === null;
  }

  setUnitContentExpandedInToc(value) {
    this.storage.setItem(this.#unitContentExpandedInToc, value.toString());
  }

  getAutoOpenSubjectClassOverviewToc() {
    const value = this.storage.getItem(this.#autoOpenSubjectClassOverviewToc);
    return value === 'true' || value === null;
  }

  setAutoOpenSubjectClassOverviewToc(value) {
    this.storage.setItem(this.#autoOpenSubjectClassOverviewToc, value.toString());
  }

  getSendAssignmentToEdlink() {
    const value = this.storage.getItem(this.#sendAssignmentToEdlink);
    return value === 'true';
  }

  setSendAssignmentToEdlink(value) {
    this.storage.setItem(this.#sendAssignmentToEdlink, value.toString());
  }

  getLastUrl() {
    return this.storage.getItem(this.#lastUrl) === 'undefined'
      ? undefined
      : this.storage.getItem(this.#lastUrl);
  }

  setLastUrl(url) {
    this.storage.setItem(this.#lastUrl, url);
  }
}
