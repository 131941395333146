<template>
  <div
    class="KogRadioCard cursorPointer"
    :class="radioClasses"
  >
    <div
      class="flexContainer flexContainer-column"
      @click.stop="select()"
      @keydown.space.prevent="select()"
      @keydown.enter.prevent="select()"
    >
      <div class="KogRadioCard-content">
        <h5 class="text-medium margin-0 padd-m">
          {{ option.header }}
        </h5>
        <component :is="option.content" />
      </div>
      <div class="KogRadioCard-footer padd-xs flexContainer flexContainer-alignCenter">
        <kog-radio
          :key="option.value"
          :value="option.value"
          :label="option.label"
          :is-checked="isChecked"
          :is-disabled="option.disabled"
          :described-by="describedBy"
          :option="option"
          :tab-index="tabIndex"
          @checked="onOptionChecked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KogRadio from '../radio/kog-radio.vue';

export default {
  name: 'KogRadioCard',
  components: {
    KogRadio,
  },
  props: {
    /**
     * The value of the current radio button
     */
    value: {
      type: String,
      required: true,
    },
    /**
     * The id of an element who contains a description for what the option
     * does.
     */
    describedBy: {
      type: String,
      required: true,
    },
    /**
     * Whether the radio is checked
     */
    isChecked: {
      type: Boolean,
      default: false,
    },
    /**
     * If disabled, the radio button is not clickable or focusable
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The checked radio should always have `tabindex=0`.
     * If there is no checked radio in the group, the first radio should have `tabindex=0`.
     * All the others have `tabindex=-1`
     */
    tabIndex: {
      type: Number,
      default: null,
    },
    option: {
      type: Object,
      required: true,
    },
  },
  emits: ['checked'],
  computed: {
    radioClasses() {
      const cssClasses = [];

      if (this.isDisabled) {
        cssClasses.push('KogRadioCard-disabled');
      } else {
        cssClasses.push('KogRadioCard-enabled');
      }

      if (this.isChecked === true) {
        cssClasses.push('is-checked');
      } else {
        cssClasses.push('is-unchecked');
      }

      return cssClasses;
    },
  },
  methods: {
    onOptionChecked(value) {
      this.$emit('checked', value);
    },
    select() {
      if (this.isDisabled) {
        return;
      }
      /**
       * Emits new value after being checked
       *
       * @event checked
       * @type {string}
       */
      this.$emit('checked', this.value);
    },
  },
};
</script>

<style scoped>
.KogRadioCard {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;

  border: 1px solid var(--kogPlatformGray077);
  border-radius: var(--space-xs);
}

.KogRadioCard > .flexContainer {
  flex-grow: 1;
}

.KogRadioCard-label {
  line-height: 24px;
  color: var(--kog-radio-color);
}

.KogRadioCard-content {
  flex-grow: 1;
}

.KogRadioCard-footer {
  border-top: 1px solid var(--kogPlatformGray077);
}

.KogRadioCard.is-checked,
.KogRadioCard.is-checked .KogRadioCard-footer {
  border-color: var(--kogPlatformGray000);
}
</style>
