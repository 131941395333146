<template>
  <td
    :class="{
      'padd-right-s': isHorizontalPaddingEnabled,
      'padd-left-s': isHorizontalPaddingEnabled,
      'KogTableCellGeneric--leftGap': hasLeftGap,
    }"
  >
    <slot />
  </td>
</template>

<script>
export default {
  name: 'KogTableCellGeneric',
  props: {
    /**
     * If `false`, the cells in the table won't have left and right padding
     */
    isHorizontalPaddingEnabled: {
      type: Boolean,
      default: true,
    },
    /**
     * Show a gap to the left of the cell.
     */
    hasLeftGap: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.KogTableCellGeneric--leftGap {
  border-left: var(--space-m) solid transparent;
}
</style>
