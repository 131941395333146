<template>
  <kog-button
    :aria-label="label"
    :label="label"
    :disabled="disabled"
    button-style="accent"
    :is-loading="loading"
    @click="submit"
  />
</template>

<script>
import { mapActions } from 'vuex';

import { getIntegrationProviderDisplayName } from 'learning/common/services/integration/integration-service.js';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import {
  isExamStyleType,
  isQuestionType,
  isReadingType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { createIntegrationAssignmentSubmission } from 'studyApp/api/integration-assignment.js';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';

export default {
  name: 'SubmitToIntegrationButton',
  components: {
    KogButton,
  },
  mixins: [RoutesMixin],
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    hasSubmittedToIntegration: {
      type: Boolean,
      default: false,
    },
    pollingForSubmission: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submitted-to-integration'],
  setup() {
    const { warningMessage, providerSsoLoginUrl, integrationProvider, showIntegrationWarning } =
      useIntegrationAssignment();
    return {
      warningMessage,
      providerSsoLoginUrl,
      integrationProvider,
      showIntegrationWarning,
    };
  },
  computed: {
    disabled() {
      return this.$wait.is(this.loaderName) || this.hasSubmittedToIntegration;
    },
    loading() {
      return this.$wait.is(this.loaderName) || this.pollingForSubmission;
    },
    label() {
      return this.disabled
        ? `Result submitted to ${getIntegrationProviderDisplayName(this.integrationProvider)}`
        : `Submit result to ${getIntegrationProviderDisplayName(this.integrationProvider)}`;
    },
    loaderName() {
      return 'sending-integration-assignment-submission';
    },
    assignmentId() {
      return this.assignment.id;
    },
    assignmentType() {
      return this.assignment.assignment_type;
    },
    assignmentUrl() {
      let routeName = '';
      if (isReadingType(this.assignmentType)) {
        routeName = 'classReadingAssignmentTake';
      } else if (isQuestionType(this.assignmentType)) {
        routeName = 'classQuestionAssignmentResult';
      } else if (isExamStyleType(this.assignmentType)) {
        routeName = 'classEsqAssignmentOverview';
      }
      const route = {
        name: routeName,
        params: {
          classSlug: `${this.subjectClassSlug}`,
          sid: `${this.subjectId}`,
          cid: `${this.classId}`,
          assignmentId: `${this.assignmentId}`,
        },
      };
      return this.$router.resolve(route).href;
    },
  },
  methods: {
    ...mapActions('assignmentModule', ['getAssignment']),
    async submit() {
      this.$wait.start(this.loaderName);
      try {
        await createIntegrationAssignmentSubmission(this.assignmentId, this.classId);
        this.getAssignment(this.assignmentId);
        this.$toast.showSuccess(`Result submitted to ${this.integrationProvider}`);
      } catch (e) {
        this.showIntegrationWarning(e, this.assignmentUrl);
      } finally {
        this.$wait.end(this.loaderName);
      }
    },
  },
};
</script>
