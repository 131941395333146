export const DISPLAY_STATE = Object.freeze({
  NEUTRAL: 'neutral',
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  SOLUTION: 'solution',
});

export const VALID_QUESTION_TYPES = Object.freeze({
  STQ: 'ShortTextQuestion',
  FBQ: 'FillInTheBlanksQuestion',
});
