<template>
  <block-wrapper
    :content="content"
    :block-label="blockLabel"
    :available-actions="availableActions"
    :custom-style="{
      border: 'none',
    }"
    @mark-removal="$event => $emit('mark-removal', $event)"
    @unmark-removal="$event => $emit('unmark-removal', $event)"
    @move-down="$event => $emit('move-down', $event)"
    @move-up="$event => $emit('move-up', $event)"
  >
    <activity-divider :content="content" />
  </block-wrapper>
</template>

<script>
import ActivityDivider from 'sharedApp/components/activity-divider/activity-divider.vue';

import BlockWrapper, { ITEM_ACTIONS } from '../../block-wrapper.vue';
import BlockMixin from '../mixins/block-mixin.js';

export default {
  name: 'DividerBlock',
  components: {
    BlockWrapper,
    ActivityDivider,
  },
  mixins: [BlockMixin],
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  emits: ['mark-removal', 'unmark-removal', 'move-up', 'move-down'],
  data() {
    return {
      availableActions: [ITEM_ACTIONS.MARK_REMOVAL],
    };
  },
};
</script>

<style scoped>
.ActivityDivider {
  margin-bottom: 0;
  margin-left: 0;
}
</style>
