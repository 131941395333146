<template>
  <battle-page-container
    :loading="$wait.is('loading-battle')"
    loading-msg="Loading battle result"
  >
    <template #header>
      <div
        v-if="currentBattle"
        class="kog-row margin-bottom-m"
      >
        <battle-header>
          <div class="text-center">
            <h1 class="heading-l"> Battle </h1>
          </div>
        </battle-header>
      </div>
    </template>
    <div
      v-if="currentBattle"
      class="text-regular"
    >
      <p
        ref="initialFocus"
        tabindex="-1"
        class="screenreader-only"
      >
        {{ getScreenReaderText }}
      </p>

      <div
        class="kog-row margin-top-l"
        aria-hidden="true"
      >
        <generic-warning-box
          v-if="isBattleDone"
          class="kog-col-xs-12 kog-col-md-6 kog-offset-md-3 text-center"
          :mode="correctWarningBoxMode"
        >
          <p
            :aria-label="`Result, ${battleResultString}`"
            class="heading-xs"
          >
            {{ battleResultString }}
          </p>
        </generic-warning-box>
        <generic-warning-box
          v-else
          class="kog-col-xs-12 kog-col-md-6 kog-offset-md-3 text-center"
          mode="warning"
        >
          <strong v-if="!currentBattle.is_your_turn">
            You will be notified when {{ opponentName }} completes the battle...
          </strong>
          <strong v-else>
            <router-link
              :to="getTakeBattleRoutePath"
              target="_self"
            >
              Waiting for you to start!
            </router-link>
          </strong>
        </generic-warning-box>
      </div>
      <div class="kog-row margin-top-l">
        <div class="kog-col-xs-12 kog-col-md-6 text-center">
          <span
            id="self-progress-label"
            class="heading-m"
          >
            You
          </span>

          <p v-if="isUserOccasionDone"> Completed on {{ userDateTimeString }} </p>
          <p v-else> Not completed yet... </p>
          <kog-progress-bar
            labelled-by="self-progress-label"
            :progress="userScore"
          />
        </div>

        <div class="kog-col-xs-12 kog-col-md-6 divider-left padd-bottom-l text-center">
          <span
            id="opponent-progress-label"
            class="heading-m"
          >
            {{ opponentName }}
          </span>

          <p v-if="isOpponentOccasionDone"> Completed on {{ opponentDateTimeString }} </p>
          <p v-else> Not completed yet... </p>
          <kog-progress-bar
            labelled-by="opponent-progress-label"
            :progress="opponentScore"
          />
        </div>
      </div>
      <div
        v-if="userOccasion"
        class="kog-row margin-top-l"
      >
        <div class="kog-col-xs-12">
          <h2 class="text-center heading-s margin-top-l margin-bottom-l"> Your answers </h2>
          <practice-item-results
            v-if="userOccasion"
            :practice-items="userOccasion.practice_items"
            :opponent-practice-items="opponentOccasion && opponentOccasion.practice_items"
            :opponent-name="opponentName"
            :subject="subject"
            :source="'Strength Battle Result'"
          />
        </div>
      </div>
      <div class="kog-row">
        <div class="flexContainer flexContainer-flexEnd">
          <kog-button
            label="Set up a new battle"
            button-style="accent"
            @click="navigateToBattleSetup"
          />
        </div>
      </div>
    </div>
  </battle-page-container>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { mapActions, mapState, useStore } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import PracticeItemResults from 'studyApp/components/practice-item-results.vue';

import BattleHeader from './components/battle-header.vue';
import BattlePageContainer from './components/battle-page-container.vue';
import BattleMixin from './mixins/battle-mixin.js';

const ONE_WEEK_IN_MILLISECONDS = 604800000;

export default {
  name: 'BattleResult',
  components: {
    BattlePageContainer,
    BattleHeader,
    GenericWarningBox,
    PracticeItemResults,
    KogButton,
    KogProgressBar,
  },
  mixins: [RoutesMixin, BattleMixin],
  setup() {
    const store = useStore();
    const { subjectModule } = store.state;

    const subjectName = computed(() => subjectModule.subject.name);

    useHead({
      title: `Strength battle results | ${subjectName.value}`,
    });
  },
  computed: {
    ...mapState('battlesModule', ['currentBattle']),
    ...mapState('subjectModule', ['subject']),
    battleId() {
      return this.$route.params.battleId;
    },
    isBattleDone() {
      return this.currentBattle.is_done;
    },
    lostBattle() {
      return this.currentBattle.your_score < this.currentBattle.opponent_score;
    },
    correctWarningBoxMode() {
      if (this.currentBattle.did_you_win) {
        return 'success';
      }
      if (this.lostBattle) {
        return 'error';
      }
      return 'info';
    },
    battleResultString() {
      if (!this.currentBattle.is_done) return '';
      if (!this.currentBattle.done_successfully) return this.currentBattle.done_status;
      if (this.currentBattle.did_you_win) return 'You won!';
      if (this.lostBattle) return 'You lost...';
      return 'Battle tied.';
    },
    opponentName() {
      return this.currentBattle.opponent_name;
    },
    userOccasion() {
      return this.currentBattle.your_occasion;
    },
    opponentOccasion() {
      return this.currentBattle.opponent_occasion;
    },
    isUserOccasionDone() {
      return this.userOccasion && this.userOccasion.status === 'DONE';
    },
    isOpponentOccasionDone() {
      return this.opponentOccasion && this.opponentOccasion.status === 'DONE';
    },
    userScore() {
      if (!this.userOccasion) {
        return 0;
      }
      return (this.userOccasion.correct_answer_count * 100) / this.userOccasion.total_answer_count;
    },
    opponentScore() {
      if (!this.opponentOccasion) {
        return 0;
      }
      if (this.opponentOccasion.total_answer_count === 0) {
        return 0;
      }
      return (
        (this.opponentOccasion.correct_answer_count * 100) /
        this.opponentOccasion.total_answer_count
      );
    },
    userDateTimeString() {
      return this.getDateTimeString(this.userOccasion.done_at);
    },
    opponentDateTimeString() {
      return this.getDateTimeString(this.opponentOccasion.done_at);
    },
    getScreenReaderText() {
      if (this.isBattleDone) {
        return `Result, ${this.battleResultString}`;
      }

      if (this.currentBattle.is_your_turn) {
        return 'Waiting for you to start!';
      }

      return `Waiting for opponent. You will be notified when ${this.opponentName} completes the battle...`;
    },
    getTakeBattleRoutePath() {
      return this.getTakeBattleRoute();
    },
  },
  mounted() {
    const unwatch = this.$watch('isLoading', loading => {
      if (loading === false && this.$refs.initialFocus) {
        this.$refs.initialFocus.focus();

        unwatch();
      }
    });
  },
  async created() {
    this.$wait.start('loading-battle');
    try {
      await this.fetchBattle(this.battleId);
    } catch {
      this.$toast.showError(
        "Couldn't fetch battle result.",
        {},
        {
          time: ONE_WEEK_IN_MILLISECONDS,
        },
      );
      this.navigateToBattleSetup();
    }
    this.$wait.end('loading-battle');
    this.$mixpanel.trackEvent('Strength Battle - pageview', {
      correct_answers: this.userOccasion ? this.userOccasion.correct_answer_count : null,
      incorrect_answers: this.userOccasion ? this.userOccasion.incorrect_answer_count : null,
    });
  },
  methods: {
    ...mapActions('battlesModule', ['fetchBattle']),
    getDateTimeString(doneAt) {
      return new Date(doneAt).toLocaleString([], {
        hour: '2-digit',
        minute: '2-digit',
        day: 'numeric',
        month: 'long',
      });
    },
  },
};
</script>

<style scoped></style>
