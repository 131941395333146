<template>
  <div>
    <div
      v-if="isShowingSubtitle"
      class="margin-bottom-s text-small muted"
    >
      <span>{{ subtitle }}</span>
    </div>

    <h1
      class="flexContainer flexContainer-alignCenter font-meta-serif-pro margin-top-0 margin-bottom-0"
    >
      {{ title }}
      <content-hidden-label
        v-if="isHidden"
        class="margin-left-s"
      />
    </h1>
  </div>
</template>

<script>
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';

export default {
  name: 'SectionHeaderTitle',
  components: { ContentHiddenLabel },
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isShowingSubtitle: {
      type: Boolean,
      default: true,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
