export const contentActions = {
  markItemForRemoval({ commit }, itemClientId) {
    commit('markItemForRemoval', itemClientId);
  },

  unmarkItemForRemoval({ commit }, itemClientId) {
    commit('unmarkItemForRemoval', itemClientId);
  },

  clearItemsForRemoval({ commit }) {
    commit('clearItemsForRemoval');
  },

  markItemForUpdate({ commit }, itemClientId) {
    commit('markItemForUpdate', itemClientId);
  },

  unmarkItemForUpdate({ commit }, itemClientId) {
    commit('unmarkItemForUpdate', itemClientId);
  },

  clearItemsForUpdate({ commit }) {
    commit('clearItemsForUpdate');
  },
};
