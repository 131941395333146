<template>
  <kog-tag
    type="warning"
    label="Hidden"
    class="font-weight-normal"
    :hide-overflow="false"
  />
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export default {
  name: 'ContentHiddenLabel',
  components: { KogTag },
};
</script>
