<template>
  <kog-modal title="Add teacher">
    <template #modalBody>
      <div class="flexContainer flexContainer-spaceBetween containContent">
        <div class="width-50-percent">
          <input
            v-model="filterText"
            aria-label="Filter by name or email"
            type="text"
            placeholder="Filter by name or email"
            class="form-control"
          />
        </div>

        <span
          ref="teacherCount"
          v-tooltip="{
            theme: 'kog-tooltip',
            content: teachersNamesSelectedForAdding,
          }"
          class="padd-right-m"
        >
          {{ selectedTeachers.size }}
          <span v-if="selectedTeachers.size === 1"> teacher </span>
          <span v-else> teachers </span>
          selected
        </span>
      </div>

      <div class="flexContainer flexContainer-alignCenter margin-xxs">
        <span class="margin-right-xxs">Showing</span>
        <tag-radio-group
          v-model:selected-value="filterIsTeachingClass"
          group-label="Teacher list filter"
          :options="filterOptions"
        />
      </div>
      <kog-table>
        <template #header>
          <kog-table-header>
            <kog-table-cell-checkbox
              aria-label="Select all teachers"
              label="Teacher name"
              :is-label-hidden="false"
              :is-header-cell="true"
              :is-checked="selectAllState"
              @check="onToggleAll(true)"
              @uncheck="onToggleAll(false)"
            />
            <kog-table-cell-text :is-header-cell="true"> Current classes </kog-table-cell-text>
          </kog-table-header>
        </template>
        <template #body>
          <kog-table-row
            v-for="teacher in filteredTeachers"
            :key="teacher.id"
            :is-disabled="teacher.is_currently_teaching"
          >
            <kog-table-cell-checkbox
              :label="teacher.name"
              :is-label-hidden="false"
              :is-checked="selectedTeachers.has(teacher.id) || teacher.is_currently_teaching"
              :is-disabled="teacher.is_currently_teaching"
              :tooltip-text="teacher.email"
              @check="checkTeacher(teacher.id)"
              @uncheck="checkTeacher(teacher.id)"
            />
            <kog-table-cell-text>
              <div>
                <kog-tag
                  v-if="teacher.is_currently_teaching"
                  size="s"
                  label="This class"
                />
              </div>
              <div
                v-for="subject_class in teacher.other_classes_in_subject"
                :key="subject_class.id"
              >
                <kog-tag
                  size="s"
                  :label="subject_class.name"
                />
              </div>
            </kog-table-cell-text>
          </kog-table-row>
        </template>
      </kog-table>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-button
          label="Cancel"
          @click="closeModal"
        />
        <kog-button
          :disabled="selectedTeachers.size === 0"
          button-style="primary"
          label="Add teachers"
          @click="addTeachersToClass"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import TagRadioGroup from 'sharedApp/components/filter/tag-radio-group.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import KogTableCellCheckbox from 'sharedApp/components/tables/kog-table-cell-checkbox.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';

const SHOW_ALL = 'SHOW_ALL';
const SHOW_NOT_TEACHING = 'SHOW_NOT_TEACHING';

export default {
  name: 'ClassDetailsAddTeachersModalContainer',
  components: {
    KogModal,
    KogTag,
    TagRadioGroup,
    KogTable,
    KogTableHeader,
    KogTableRow,
    KogTableCellCheckbox,
    KogTableCellText,
    KogButton,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    addTeachers: {
      type: Function,
      required: true,
    },
    schoolTeachers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTeachers: new Set(),
      filterText: '',
      filterIsTeachingClass: SHOW_NOT_TEACHING,
    };
  },
  computed: {
    schoolId() {
      return this.$route.params.school_id;
    },
    subjectClassId() {
      return this.$route.params.subjectclass_id;
    },
    teachersNamesSelectedForAdding() {
      return this.schoolTeachers
        .filter(teacher => this.selectedTeachers.has(teacher.id))
        .map(teacher => teacher.name)
        .join(', ');
    },
    teachersUserIDsSelectedForAdding() {
      return this.schoolTeachers
        .filter(teacher => this.selectedTeachers.has(teacher.id))
        .map(teacher => teacher.user_id);
    },
    teachersToShow() {
      if (this.filterIsTeachingClass === SHOW_NOT_TEACHING) {
        return this.schoolTeachers.filter(teacher => !teacher.is_currently_teaching);
      }
      return this.schoolTeachers;
    },
    normalizedFilterText() {
      return this.filterText.toLocaleLowerCase();
    },
    filteredTeachers() {
      if (!this.filterText) {
        return this.teachersToShow;
      }

      const includesNormalizedFilterText = text =>
        text.toLocaleLowerCase().includes(this.normalizedFilterText);

      return this.teachersToShow.filter(
        teacher =>
          includesNormalizedFilterText(teacher.name) || includesNormalizedFilterText(teacher.email),
      );
    },
    filterOptions() {
      const amountNotTeachingClass = this.schoolTeachers.filter(
        teacher => !teacher.is_currently_teaching,
      ).length;
      const options = [
        {
          label: `All (${this.schoolTeachers.length})`,
          value: SHOW_ALL,
        },
        {
          label: `Not teaching class (${amountNotTeachingClass})`,
          value: SHOW_NOT_TEACHING,
        },
      ];
      return options;
    },
    selectAllState() {
      const currentSelectableTeachers = this.filteredTeachers.filter(
        teacher => !teacher.is_currently_teaching,
      );
      if (
        currentSelectableTeachers.length > 0 &&
        currentSelectableTeachers.every(teacher => this.selectedTeachers.has(teacher.id))
      ) {
        return true;
      }
      if (currentSelectableTeachers.some(teacher => this.selectedTeachers.has(teacher.id))) {
        return 'mixed';
      }
      return false;
    },
  },
  methods: {
    animateTeacherCount() {
      const { classList } = this.$refs.teacherCount;
      classList.add('ClassDetailsModals-countAnimation');
      setTimeout(() => {
        classList.remove('ClassDetailsModals-countAnimation');
      }, 200);
    },
    checkTeacher(id) {
      if (this.selectedTeachers.has(id)) {
        this.selectedTeachers.delete(id);
      } else {
        this.selectedTeachers.add(id);
      }
      this.animateTeacherCount();
    },
    addTeachersToClass() {
      this.$mixpanel.trackEvent('Class details Add Teacher modal - click Add button', {
        school_id: this.schoolId,
        class_id: this.subjectClassId,
        users_selected: this.teachersUserIDsSelectedForAdding,
      });

      const numberOfTeachersAdded = this.selectedTeachers.size;
      this.closeModal();

      this.addTeachers(
        {
          schoolId: this.schoolId,
          subjectClassId: this.subjectClassId,
          teacherIds: Array.from(this.selectedTeachers),
        },
        numberOfTeachersAdded,
      );
    },
    onToggleAll(isSelected) {
      const filteredTeachersNotCurrentlyTeaching = this.filteredTeachers
        .filter(teacher => !teacher.is_currently_teaching)
        .map(teacher => teacher.id);
      if (isSelected) {
        const teachersToSelect = filteredTeachersNotCurrentlyTeaching.filter(
          id => !this.selectedTeachers.has(id),
        );
        teachersToSelect.forEach(this.selectedTeachers.add, this.selectedTeachers);
      } else {
        filteredTeachersNotCurrentlyTeaching.forEach(
          this.selectedTeachers.delete,
          this.selectedTeachers,
        );
      }
      this.animateTeacherCount();
    },
  },
};
</script>
