<template>
  <div>
    <book-rail-slider-header
      class="padd-s padd-bottom-0"
      title="Assignment"
      @close="onCloseClick"
    />
    <quick-assignment-overview
      :key="assignmentId"
      class="BookRailAssignment-content padd-s"
      :name="assignmentName"
      :deadline="assignmentDeadline"
      :instructions="assignmentInstructions"
      :tasks="assignmentTasks"
    />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapActions, mapGetters, useStore } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import QuickAssignmentOverview from 'studyApp/components/assignment/quick-assignment-overview.vue';
import BookRailSliderHeader from 'studyApp/components/side-navigation/book-rail-slider-header.vue';
import NodeProgressMixin from 'studyApp/mixins/node-progress-mixin.js';

export default {
  name: 'BookRailAssignment',
  components: {
    BookRailSliderHeader,
    QuickAssignmentOverview,
  },
  mixins: [NodeProgressMixin],
  setup() {
    const store = useStore();
    const assignmentStore = useAssignmentStore();
    const { user } = store.state.userModule;
    const assignment = user.isTeacher()
      ? storeToRefs(assignmentStore).assignment
      : store.state.assignmentModule.assignment;
    return {
      assignment,
    };
  },
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
    }),
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    assignmentName() {
      return this.assignment?.name || '';
    },
    assignmentDeadline() {
      return this.assignment ? dateTimeFormatter(this.assignment.deadline) : '';
    },
    assignmentInstructions() {
      return this.assignment?.message || '';
    },
    assignmentNodes() {
      return this.assignment?.sections || [];
    },
    assignmentTasks() {
      return this.assignmentNodes.map(node => {
        const subjectNode = this.subjectNodesById[node.subject_node_id];
        const parentNode = this.subjectNodesById[subjectNode.parent];
        const parentNodeName = parentNode
          ? `${parentNode.formatted_number_including_ancestors} ${parentNode.name}`
          : '';
        const isCompleted = this.isSubjectNodeCompleted(node.subject_node_id);
        return {
          id: node.subject_node_id,
          slug: node.slug,
          name: node.name,
          parent: parentNodeName,
          isCompleted,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
    }),
    onCloseClick() {
      this.closeRailSlider();
    },
  },
};
</script>

<style>
:root {
  --AssignmentOverviewHeight: 62px;
  --BookRailHeader: 44px;
  --AssignmentFocusHeader: 76px;
}
</style>

<style scoped>
.BookRailAssignment-content {
  overflow-y: auto;
  overscroll-behavior: contain;
  height: calc(100vh - var(--AssignmentOverviewHeight) - var(--AssignmentFocusHeader));
}

@media (--viewport-l) {
  .BookRailAssignment-content {
    height: calc(
      100vh - var(--AssignmentOverviewHeight) - var(--AssignmentFocusHeader) - var(--space-xxxl)
    );
  }
}
</style>
