<template>
  <div class="QuestionAnswersCard-answerRow flexContainer shadow-s-010">
    <div class="QuestionAnswersCard-studentCol margin-left-l margin-right-l">
      <span class="text-ellipsis">{{ userResponseSummary.userName }}</span>
    </div>
    <div
      v-if="!isFullyAnswered"
      class="muted text-italic"
    >
      No answer submitted
    </div>
    <div
      v-else-if="isMCQ"
      class="flexContainer flexContainer-column gap-row-s"
    >
      <div
        v-for="choice in userResponseSummary.studentResponse"
        :key="choice.answer"
        class="flexContainer flexContainer-alignCenter"
      >
        <kog-icon
          v-tooltip="{
            theme: 'kog-tooltip',
            content: questionCorrectTooltipText,
            boundary: 'document.body',
          }"
          fa-style="regular"
          :icon-class="mcqResponseIconClass(choice)"
          theme="custom"
          :class="{
            'QuestionAnswersCard-icon--correct': choice.is_correct,
            'QuestionAnswersCard-icon--incorrect': !choice.is_correct,
          }"
        />
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="padd-left-m text-bold QuestionAnswersCard-mcqResponse"
          v-html="choice.answer"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
    <div
      v-else
      class="flexContainer flexContainer-alignCenter"
    >
      <kog-icon
        v-tooltip="{
          theme: 'kog-tooltip',
          content: questionCorrectTooltipText,
          boundary: 'document.body',
        }"
        fa-style="regular"
        :icon-class="questionCorrectIconClass"
        theme="custom"
        :class="{
          'QuestionAnswersCard-icon--correct': userResponseSummary.isCorrect,
          'QuestionAnswersCard-icon--incorrect': !userResponseSummary.isCorrect,
        }"
      />
      <div
        v-if="isSTQ"
        class="padd-left-m text-bold"
      >
        {{ userResponseSummary.studentResponse }}
      </div>
      <div
        v-else-if="isFBQ"
        class="padd-left-m text-bold flexContainer flexContainer-wrap"
      >
        <div
          v-for="(ftbqResponse, index) in userResponseSummary.studentResponse"
          :key="ftbqResponse.blankUid"
        >
          <div class="margin-left-xs margin-right-xs">
            <fill-blank-question-input
              :value="ftbqResponse.ftbAnswer"
              :disable-user-input-prop="true"
              :state-prop="ftbqResponse.ftbState"
              :order-number="index + 1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

import FillBlankQuestionInput from 'sharedApp/components/fill-in-the-blanks-question/fill-blank-question-input.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import {
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

export default {
  name: 'QuestionAnswersCard',
  components: {
    KogIcon,
    FillBlankQuestionInput,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    userResponseSummary: {
      type: Object,
      required: true,
    },
    currentQuestion: {
      type: Object,
      required: true,
    },
  },
  computed: {
    questionCorrectIconClass() {
      return this.userResponseSummary.isCorrect ? 'fa-check' : 'fa-times';
    },
    questionCorrectTooltipText() {
      return this.userResponseSummary.isCorrect ? 'Correct' : 'Incorrect';
    },
    isMCQ() {
      return isMCQ(this.currentQuestion);
    },
    isSTQ() {
      return isSTQ(this.currentQuestion);
    },
    isFBQ() {
      return isFBQ(this.currentQuestion);
    },
    isFullyAnswered() {
      let isComplete = this.userResponseSummary.studentResponse;
      if (this.isFBQ) {
        isComplete = this.userResponseSummary.studentResponse.every(rsp => Boolean(rsp.ftbAnswer));
      }
      return isComplete;
    },
  },
  methods: {
    mcqResponseIconClass(response) {
      return response.is_correct ? 'fa-check' : 'fa-times';
    },
  },
};
</script>

<style scoped>
.QuestionAnswersCard-answerRow {
  margin-bottom: var(--space-xxs);
  padding: var(--space-m) 0;
  background: var(--kogPlatformWhite);
  border-radius: 4px;
}

.QuestionAnswersCard-mcqResponse :deep(p) {
  margin-bottom: 0;
}

.QuestionAnswersCard-mcqResponse :deep(img) {
  display: inline-block;

  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 120px;
}

.QuestionAnswersCard-icon--correct {
  color: var(--kog-colors-green-800);
}

.QuestionAnswersCard-icon--incorrect {
  color: var(--kog-colors-pink-700);
}
</style>
