<template>
  <div>
    <span
      v-for="n in 5"
      :key="n"
      class="cursorPointer"
      :aria-label="n > 1 ? `rate as ${n} stars out of 5` : 'rate as 1 star out of 5'"
      role="button"
      tabindex="0"
      @keydown.enter="registerRatingChange(n)"
      @click="registerRatingChange(n)"
      @focus="registerHoverRating(n)"
      @blur="registerHoverRating(0)"
      @mouseenter="registerHoverRating(n)"
      @mouseleave="registerHoverRating(0)"
    >
      <i
        :class="{
          far: (hoverRating && hoverRating < n) || (!hoverRating && rating < n),
          'fas StarRatingBar-star--selected': hoverRating >= n || (!hoverRating && rating >= n),
        }"
        class="fa-2x fa-star StarRatingBar-star padd-right-fine-1"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'StarRatingBar',
  props: {
    updateFeedbackRating: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      rating: 0,
      hoverRating: 0,
    };
  },
  methods: {
    registerRatingChange(rating) {
      this.rating = rating;
      this.updateFeedbackRating(rating);
    },
    registerHoverRating(rating) {
      this.hoverRating = rating;
    },
  },
};
</script>

<style>
.StarRatingBar-star {
  font-size: 18px;
  line-height: 24px;
  color: var(--kog-colors-grey-900);
}

.StarRatingBar-star--selected {
  color: var(--kog-colors-yellow-500);
}
</style>
