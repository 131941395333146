<template>
  <div class="ExerciseQuestion">
    <div class="ExerciseQuestion-Heading padd-xs">
      <span class="font-satoshi">
        <strong>{{ questionType }}</strong> - ID: {{ questionId }}
        <kog-tag
          v-if="!isValid"
          type="negative"
          label="Hidden"
          size="s"
          tooltip-text="The question is not visible to students and teachers on the current subject node. It can be either not marked as active or not mapped to the current subject node."
        />
      </span>
    </div>
    <div class="ExerciseQuestion-Body padd-s">
      <div class="content-editable">
        <template v-if="question">
          <question-text :question="question" />
        </template>
        <template v-else>
          This question does not exist anymore, please remove from the editor.
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import type { Question } from '@apis/generated';
import type { PropType } from 'vue';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import QuestionText from 'sharedApp/components/questions/question-text.vue';

const props = defineProps({
  questionId: {
    type: String,
    required: true,
  },
  question: {
    type: Object as PropType<Question>,
    default: null,
  },
  isValid: {
    type: Boolean,
    default: false,
  },
});

const questionType = computed(() => props.question?.type || 'Unknown question type');
</script>

<style scoped>
.ExerciseQuestion {
  color: var(--kog-text-default);
  border: 1px solid var(--kog-colors-grey-400);
}

.ExerciseQuestion-Heading {
  background-color: var(--kog-colors-grey-200);
}

.ExerciseQuestion-Body {
  background-color: var(--kog-colors-white);
}
</style>
