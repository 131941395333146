<template>
  <div>
    <subtopic-checkmark
      v-if="shouldShowProgressCheckmark"
      :progress="item.progress_percent"
    />
    <section-progress-indicator
      v-if="shouldShowProgressIndicator"
      :is-completed="isCompleted"
    />
  </div>
</template>

<script>
import SubtopicCheckmark from 'sharedApp/components/icons/subtopic-checkmark.vue';
import { isSection, isSubSection, isSubTopic } from 'sharedApp/libs/subject-tree-functions.js';
import SectionProgressIndicator from 'studyApp/components/section-progress-indicator.vue';

export default {
  name: 'ProgressIndicator',
  components: {
    SectionProgressIndicator,
    SubtopicCheckmark,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSection() {
      return isSection(this.item);
    },
    isSubSection() {
      return isSubSection(this.item);
    },
    isSubTopic() {
      return isSubTopic(this.item);
    },
    isCompleted() {
      return (
        (this.isLeafNode && this.item.progress_is_completed) ||
        (!this.isLeafNode && this.item.progress_percent === 100)
      );
    },
    hasChildren() {
      return this.item.children.length > 0;
    },
    isLeafNode() {
      return (this.isSection && !this.hasChildren) || this.isSubSection;
    },
    isSectionWithSubsection() {
      return this.isSection && this.hasChildren;
    },
    shouldShowProgressCheckmark() {
      return this.isSubTopic || this.isSectionWithSubsection;
    },
    shouldShowProgressIndicator() {
      return (this.isSection && !this.hasChildren) || this.isSubSection;
    },
  },
};
</script>

<style scoped></style>
