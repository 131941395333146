<template>
  <div
    class="flexContainer flexContainer-justifyCenter MobileBottomNavbar"
    :style="{ bottom: isDemoFooterShowing ? demoFooterHeight : 0 }"
  >
    <div
      v-for="(menuItem, index) in menuItems"
      :key="index"
      :class="{
        'MobileBottomNavbar-navitem--selected': menuItem.key === selectedMenu,
        'MobileBottomNavbar-navitem--unselected': menuItem.key !== selectedMenu,
      }"
      class="flexContainer flexContainer-column flexContainer-center MobileBottomNavbar-navitem"
      role="button"
      tabindex="0"
      @keyup.enter.stop="selectMenuItem(menuItem.key)"
      @click="selectMenuItem(menuItem.key)"
    >
      <i
        :class="iconClass(menuItem)"
        class="MobileBottomNavbar-navitemIcon"
      />
      {{ menuItem.displayText }}
    </div>
  </div>
</template>

<script>
import { DEMO_FOOTER_HEIGHT_PX } from 'learning/common/components/demo-footer.vue';

export const MOBILE_BOTTOM_NAVBAR_HEIGHT_PX = 72;

export default {
  name: 'MobileBottomNavbar',
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    selectMenuItem: {
      type: Function,
      required: true,
    },
    selectedMenu: {
      type: String,
      required: true,
    },
    isDemoFooterShowing: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.demoFooterHeight = `${DEMO_FOOTER_HEIGHT_PX}px`;
  },
  methods: {
    iconClass(navItem) {
      if (navItem.key === this.selectedMenu) {
        return navItem.iconClassActive;
      }
      return navItem.iconClassInactive;
    },
  },
};
</script>

<style scoped>
.MobileBottomNavbar {
  position: fixed;
  z-index: 500;
  right: 0;
  bottom: 0;
  left: 0;

  height: 72px;

  visibility: hidden;
  background-color: var(--kogPlatformWhite);
  box-shadow: 0 -8px 16px -4px var(--kogShadow020);
}

.MobileBottomNavbar-navitem {
  flex: 1;
  font-size: var(--kog-font-size-small);
}

.MobileBottomNavbar-navitem--selected {
  color: var(--kog-text-default);
}

.MobileBottomNavbar-navitem--unselected {
  color: var(--kog-text-default);
}

.MobileBottomNavbar-navitemIcon {
  font-size: 20px;
}

@media (--viewport-s) {
  .MobileBottomNavbar {
    visibility: visible;
  }
}
</style>
