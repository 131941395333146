// eslint-disable-next-line max-classes-per-file
import {
  ButtonView,
  createLabeledDropdown,
  LabeledFieldView,
  ListItemView,
  ListView,
  SearchTextView,
  View,
  ViewCollection,
} from '@ckeditor/ckeditor5-ui';

import type {
  ButtonExecuteEvent,
  DropdownView,
  FilteredView,
  FocusableView,
} from '@ckeditor/ckeditor5-ui';
import type { Locale } from '@ckeditor/ckeditor5-utils';
import type { HideableView } from './view.types.ts';

import './filtered-dropdown-view.css';

type ButtonCustomProperty = {
  key: string;
};

function buttonCustomPropertiesFactory<TType>(locale?: Locale) {
  return new ButtonView(locale) as ButtonView & TType;
}

function buttonHasExtendedProperties<TType>(
  item: unknown,
  properties: (keyof TType)[],
): item is ButtonView & TType {
  if (!(item instanceof ButtonView)) return false;

  const validatedProperties = new Set(properties.map(property => Object.hasOwn(item, property)));

  return !validatedProperties.has(false);
}

function isListItemView(item: unknown): item is ListItemView {
  return item instanceof ListItemView;
}

class FilteredListView extends ListView implements FilteredView {
  filter(query: RegExp | null) {
    const totalItemsCount = this.items.length;
    const setItemVisibility = (item: ListItemView, isVisible = true) => {
      item.set({ isVisible });
    };

    if (!query) {
      this.items.forEach(item => {
        if (isListItemView(item)) {
          setItemVisibility(item, true);
        }
      });

      return {
        resultsCount: totalItemsCount,
        totalItemsCount,
      };
    }

    let resultsCount = 0;

    this.items.forEach(item => {
      if (!isListItemView(item)) return;

      const button = item.children.first as ButtonView;
      const filterMatch = !!button.label?.match(query);

      setItemVisibility(item, filterMatch);
      resultsCount = filterMatch ? resultsCount + 1 : resultsCount;
    });

    return { resultsCount, totalItemsCount };
  }
}

export default class FilteredDropdownView extends View implements FocusableView, HideableView {
  public declare selectedValue: string | null;

  public declare isVisible: boolean;

  private values: [string, string | number][];

  public locale: Locale;

  public enableTooltips: boolean;

  private dropdown: LabeledFieldView<DropdownView>;

  constructor(
    locale: Locale,
    options: { label: string; values: [string, string | number][]; enableTooltips?: boolean },
  ) {
    super(locale);

    const context = { enableTooltips: false, ...options };

    this.set({
      selectedValue: null,
      isVisible: true,
    });
    this.locale = locale;
    this.values = context.values;
    this.enableTooltips = context.enableTooltips;

    this.dropdown = this.createDropdownView(context.label, this.enableTooltips);

    const bind = this.bindTemplate;
    this.setTemplate({
      tag: 'div',
      attributes: {
        class: [
          bind.to('isVisible', value => {
            return value ? '' : 'hidden';
          }),
          'ck-filtered-dropdown',
        ],
        tabindex: bind.to('isVisible', value => (value ? '0' : '-1')),
      },
      children: [this.dropdown],
    });
  }

  setVisibility(isVisible: boolean) {
    this.isVisible = isVisible;
  }

  setSelectedValue(value: string | null) {
    this.set('selectedValue', value);
  }

  reset() {
    this.setSelectedValue(null);
  }

  focus() {
    this.dropdown.focus();
  }

  private createDropdownView(label: string, enableTooltips: boolean) {
    const labeledDropdown = new LabeledFieldView(this.locale, createLabeledDropdown);
    labeledDropdown.set({ label });
    labeledDropdown.fieldView.buttonView.set({ withText: true });
    labeledDropdown.fieldView.buttonView.bind('label').to(this, 'selectedValue', id => {
      const value = this.values.find(item => item[1] === id);
      return value ? value[0] : 'None';
    });

    const collection = new ViewCollection(
      this.values.map(item => {
        const [text, value] = item;

        const listItem = new ListItemView();
        const button = buttonCustomPropertiesFactory<ButtonCustomProperty>();
        button.set({
          withText: true,
          label: text,
          key: value,
          tooltip: enableTooltips ? text : '',
        });
        button.bind('isOn').to(this, 'selectedValue', id => id === value);
        button.delegate('execute').to(labeledDropdown.fieldView, 'execute');
        listItem.children.add(button);
        return listItem;
      }),
    );

    const resultView = new FilteredListView(this.locale);
    resultView.items.addMany(collection);

    const searchView = new SearchTextView(this.locale, {
      queryView: {
        label: 'Filter',
        showIcon: false,
      },
      filteredView: resultView,
    });

    labeledDropdown.fieldView.panelView.children.add(searchView);
    labeledDropdown.fieldView.on('change:isOpen', () => searchView.reset());
    labeledDropdown.fieldView.on<ButtonExecuteEvent>('execute', event => {
      if (buttonHasExtendedProperties<ButtonCustomProperty>(event.source, ['key'])) {
        this.set('selectedValue', event.source.key);
      }
    });

    labeledDropdown.bind('isEmpty').to(this, 'selectedValue', val => !val);

    return labeledDropdown;
  }
}
