import { Plugin } from '@ckeditor/ckeditor5-core';

import puzzleIcon from 'publishingApp/assets/icons/puzzle-piece-solid.svg';

import { registerFactoryView } from '../utils/plugin-utils.ts';

export default class ShowSolutionUI extends Plugin {
  init() {
    registerFactoryView(this.editor, {
      name: 'showsolution',
      commandName: 'insertShowSolution',
      icon: puzzleIcon,
      label: 'Show solution',
      onExecute: () => this.editor.execute('insertShowSolution'),
    });
  }
}
