import { Plugin } from '@ckeditor/ckeditor5-core';

import SectionLinkEditing from './section-link-editing.js';
import SectionLinkUI from './section-link-ui.js';

export default class SectionLink extends Plugin {
  static get requires() {
    return [SectionLinkEditing, SectionLinkUI];
  }
}
