<template>
  <kog-select
    :options="options"
    :selected-value="value"
    is-dropdown-hosted-in-parent-container
    @change="onChange"
  />
</template>

<script>
import KogSelect from 'sharedApp/components/base/select/kog-select.vue';

import GROUPED_ROLES_OPTIONS from './grouped-roles-options.js';

export default {
  name: 'RolesDropdown',
  components: {
    KogSelect,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'update:value'],
  computed: {
    options() {
      return GROUPED_ROLES_OPTIONS;
    },
  },
  methods: {
    onChange(value) {
      this.$emit('update:value', value);
      this.$emit('input', value);
    },
  },
};
</script>
