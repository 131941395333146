import { API_URLS } from 'publishingApp/store/endpoints.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

export async function fetchSimilarQuestions(questionId) {
  const url = API_URLS.getSimilarQuestionsUrl(questionId);
  const response = await axios.get(url);
  return response.data.results;
}

export async function fetchQuestionDetail(questionId) {
  const url = API_URLS.getQuestionDetailUrl(questionId);
  const response = await axios.get(url);
  return response.data;
}

export async function saveQuestion(question) {
  const url = API_URLS.getQuestionUrlForType(question);
  const httpMethod = question.id ? axios.put : axios.post;
  const response = await httpMethod(url, question);
  return response.data;
}

export async function deleteQuestion(question) {
  const url = API_URLS.getQuestionUrlForType(question);
  const response = await axios.delete(url);
  return response.data;
}

export async function fetchQuestionStatistics(subjectId, questionId) {
  const url = API_URLS.getQuestionStatisticsUrl(subjectId, questionId);
  const response = await axios.get(url);
  return response.data;
}

export async function addQuestionMapping(mappings) {
  const url = API_URLS.ADD_QUESTION_MAPPING_URL;
  const response = await axios.post(url, mappings);
  return response.data;
}

export async function deleteQuestionMapping(question, subjectnodeId) {
  const url = API_URLS.deleteQuestionMappingUrl(question.id, subjectnodeId);
  const response = await axios.delete(url);
  return response.data;
}

export async function getWrongAnswerStatistics(questionId) {
  const url = API_URLS.getWrongAnswerStatistics(questionId);
  const response = await axios.get(url);
  return response.data;
}

export async function bulkActivateQuestions(subjectId, questionIds) {
  const url = API_URLS.getBulkActivateQuestionsUrl(subjectId);
  const response = await axios.post(url, {
    question_ids: questionIds,
  });

  return response.status;
}

export async function bulkDeactivateQuestions(subjectId, questionIds) {
  const url = API_URLS.getBulkDeactivateQuestionsUrl(subjectId);
  const response = await axios.post(url, {
    question_ids: questionIds,
  });

  return response.status;
}

export async function bulkMapQuestions(subjectId, questionIds) {
  const url = API_URLS.getBulkMapQuestionsUrl(subjectId);
  const response = await axios.post(url, {
    questions: questionIds,
  });

  return response.data;
}

export async function bulkDuplicateQuestions(subjectId, questionIds) {
  const url = API_URLS.getBulkDuplicateQuestionsUrl(subjectId);
  const response = await axios.post(url, {
    questions: questionIds,
  });

  return response.data;
}

export default {
  fetchSimilarQuestions,
  fetchQuestionDetail,
  fetchQuestionStatistics,
  saveQuestion,
  deleteQuestion,
  bulkActivateQuestions,
  bulkDeactivateQuestions,
  bulkMapQuestions,
  bulkDuplicateQuestions,
};
