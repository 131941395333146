import { addListToDropdown, createDropdown, Model, ViewCollection } from '@ckeditor/ckeditor5-ui';
import { Collection } from '@ckeditor/ckeditor5-utils';

import FlexWrapperView from '../shared/flex-wrapper-view.ts';
import KogForm from '../utils/kogform.js';
import { getQuestionTextShort } from '../utils/plugin-utils.ts';

export default class FormView extends KogForm {
  constructor(locale, exerciseQuestions) {
    super(locale);
    this.exerciseQuestions = exerciseQuestions;
    this.questionDropdownView = this.createDropdown('Question', this.exerciseQuestions);

    this.saveButtonView = this.createSaveButton();
    this.cancelButtonView = this.createCancelButton();

    const selectedQuestion = this.exerciseQuestions[0];
    this.set('selectedQuestionId', selectedQuestion.id);
    this.questionDropdownView.buttonView.set({
      label: getQuestionTextShort(selectedQuestion.question_html),
    });

    this.questionDropdownView.on('execute', event => {
      this.questionDropdownView.buttonView.set({ label: event.source.label });
      this.selectedQuestionId = event.source.key;
    });

    const buttonWrapper = new FlexWrapperView(this.locale, {
      direction: 'row',
      children: [this.saveButtonView, this.cancelButtonView],
    });

    this.childViews = this.createCollection([this.questionDropdownView, buttonWrapper]);

    const focusables = new ViewCollection([
      this.questionDropdownView,
      this.saveButtonView,
      this.cancelButtonView,
    ]);
    this.setup('exercise-question', focusables);
  }

  focus() {
    this.questionDropdownView.focus();
  }

  setFields(questionId) {
    const qid = parseInt(questionId, 10);
    const selectedQuestion = this.exerciseQuestions.find(q => q.id === qid);
    const label = selectedQuestion
      ? getQuestionTextShort(selectedQuestion.question_html)
      : 'Invalid question';
    this.questionDropdownView.buttonView.set({ label });
    this.selectedQuestionId = questionId;
  }

  reset() {
    super.reset();
    const selectedQuestion = this.exerciseQuestions[0];
    this.selectedQuestionId = selectedQuestion.id;
    this.questionDropdownView.buttonView.set({
      label: getQuestionTextShort(selectedQuestion.question_html),
    });
  }

  createDropdown(label, questions) {
    const withText = true;
    const dropdown = createDropdown(this.locale);
    dropdown.buttonView.set({ label, withText });

    const collectionItems = new Collection();

    questions?.forEach(question => {
      const { type, id, question_html: questionHtml } = question;
      const questionText = getQuestionTextShort(questionHtml);

      const model = new Model({
        withText: true,
        tooltip: `${type} - ID: ${id}`,
        label: questionText,
        key: id,
      });

      model.bind('isOn').to(this, 'selectedQuestionId', val => val === id);

      collectionItems.add({
        type: 'button',
        model,
      });
    });

    addListToDropdown(dropdown, collectionItems);

    return dropdown;
  }
}
