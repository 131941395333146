<template>
  <div
    ref="root"
    :class="{ 'KogKebabButton-pressed': pressed }"
    class="KogKebabButton cursorPointer flexContainer flexContainer-column flexContainer-center cursorPointer"
    role="button"
    tabindex="0"
    @click="$emit('click', $event)"
    @keydown="$emit('keydown', $event)"
  >
    <i
      :class="{ 'fa-ellipsis-v': !horizontal, 'fa-ellipsis-h': horizontal }"
      class="far"
    />
  </div>
</template>

<script>
export default {
  name: 'KogKebabButton',
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
    pressed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'keydown'],
};
</script>

<style scoped>
.KogKebabButton {
  width: 36px;
  height: 36px;

  font-size: 20px;
  color: var(--kogPlatformGray031);

  border-radius: 18px;
}

.KogKebabButton-pressed,
.KogKebabButton:hover {
  background: var(--kogPlatformGray093);
}
</style>
