const getEsqInstructionsKey = (userId, subjectClassId) =>
  `hide_new_class_esq_instructions_${userId}_${subjectClassId}`;
const IS_ESQ_INSTRUCTIONS_HIDDEN = 'y';

export function isEsqInstructionsVisible(userId, subjectClassId) {
  const key = getEsqInstructionsKey(userId, subjectClassId);
  return localStorage.getItem(key) !== IS_ESQ_INSTRUCTIONS_HIDDEN;
}

export function hideEsqInstructions(userId, subjectClassId) {
  const key = getEsqInstructionsKey(userId, subjectClassId);
  localStorage.setItem(key, IS_ESQ_INSTRUCTIONS_HIDDEN);
}
