import { Plugin } from '@ckeditor/ckeditor5-core';
import { Widget } from '@ckeditor/ckeditor5-widget';

export default class IconDivEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    const { model, conversion } = this.editor;

    model.schema.register('iconDiv', {
      inheritAllFrom: '$container',
      allowIn: '$blockObject',
    });

    conversion.for('upcast').elementToElement({
      model: 'iconDiv',
      view: {
        name: 'div',
        classes: 'icon_div',
      },
    });

    conversion.for('downcast').elementToElement({
      model: 'iconDiv',
      view: (_, { writer }) => {
        const div = writer.createContainerElement('div', { class: 'icon_div' });
        const span = writer.createContainerElement('span');
        const text = writer.createText(' ');
        writer.insert(writer.createPositionAt(div, 0), span);
        writer.insert(writer.createPositionAt(span, 0), text);
        return div;
      },
    });
  }
}
