<template>
  <kog-modal
    :title="subjectNode.name"
    :close-modal="closeModal"
    :header-icon="headerIcon"
    :subtitle="subtitle"
  >
    <template #modalBody>
      <kog-loader
        v-if="isLoading"
        :loading="isLoading"
        loading-msg="Almost there, loading student submissions..."
      />
      <completion-stats-modal-body
        :students-completion-info="studentsCompletionInfo"
        completion-column-text="Submitted"
        :education-system-name="subject.educationsystem_name"
        :has-custom-completion-status="true"
        :custom-completion-statuses="completionStatuses"
      />
    </template>
    <template #modalFooter>
      <completion-stats-modal-footer
        :subject-class-name="subjectClassName"
        :subject="subject"
        :subject-class-id="subjectClassId"
        :subject-node="subjectNode"
        :user="user"
        :class-slug="classSlug"
        :close-modal="closeModal"
        :is-showing-student-submission-button="isShowingStudentSubmissionButton"
        :is-showing-performance-task-overview-button="isPerformanceTask"
        :disable-creating-assignment="disableCreatingAssignment"
        :source="source"
      />
    </template>
  </kog-modal>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { mapWaitingActions } from 'vue-wait';
import { mapState } from 'vuex';

import CompletionStatsModalBody from 'learning/study/components/subject-class-overview/completion-stats-modal-body.vue';
import CompletionStatsModalFooter from 'learning/study/components/subject-class-overview/completion-stats-modal-footer.vue';

import { PERFORMANCE_TASK_MODULE } from 'publishingApp/store/modules/performance-task.js';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import { ACTIVITY_SHEET, PERFORMANCE_TASK } from 'sharedApp/const/features.js';
import sectionIcon from 'sharedApp/img/study/subject-class-overview-section.svg';
import { mapWaitingActions as mapWaitingPiniaActions } from 'sharedApp/libs/pinia.ts';
import useActivitySheetStore from 'studyApp/store/modules/activity-sheet/store.ts';

export default {
  name: 'ActivitySubmissionsModal',
  components: {
    CompletionStatsModalBody,
    CompletionStatsModalFooter,
    KogLoader,
    KogModal,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    feature: {
      type: Object,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
    subjectClassId: {
      type: [String, Number],
      required: true,
    },
    subjectNode: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    subjectClassName: {
      type: String,
      required: true,
    },
    classSlug: {
      type: String,
      required: true,
    },
    disableCreatingAssignment: {
      type: Boolean,
      default: true,
    },
    source: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headerIcon: {
        type: 'image',
        imgUrl: sectionIcon,
        alt: '',
      },
      completionStatuses: {
        completed: 'Submitted',
        notCompleted: 'Not submitted',
      },
    };
  },
  computed: {
    ...mapPiniaState(useActivitySheetStore, {
      atsClassOccasions: 'classOccasions',
    }),
    ...mapState({
      ptClassOccasions: state => state[PERFORMANCE_TASK_MODULE].classOccasions,
    }),
    classOccasions() {
      return this.isPerformanceTask ? this.ptClassOccasions : this.atsClassOccasions;
    },
    isLoading() {
      return this.$wait.is('loading-ats-submissions') || this.$wait.is('loading-pt-submissions');
    },
    isPerformanceTask() {
      return this.feature.type === PERFORMANCE_TASK;
    },
    subtitle() {
      return this.subjectNode.formatted_number_including_ancestors;
    },
    studentsCompletionInfo() {
      return this.students.map(student => {
        const { id, user_id: userId, name, avatar_url: avatarUrl, level } = student;
        const studentOccasion = this.classOccasions.find(occasion => occasion.user_id === userId);
        const isSubmitted = !!studentOccasion?.submitted_at;
        const submissionText = isSubmitted ? 'Submitted' : '';
        return {
          id,
          name,
          avatarUrl,
          isCompleted: isSubmitted,
          completionText: submissionText,
          level,
        };
      });
    },
    isShowingStudentSubmissionButton() {
      return !this.isPerformanceTask;
    },
  },
  async created() {
    await this.fetchClassOccasions(this.feature);
  },
  methods: {
    ...mapWaitingPiniaActions(useActivitySheetStore, {
      fetchAtsClassOccasions: {
        action: 'fetchClassOccasions',
        loader: 'loading-ats-submissions',
      },
    }),
    ...mapWaitingActions({
      fetchPtClassOccasions: {
        action: `${PERFORMANCE_TASK_MODULE}/fetchClassOccasions`,
        loader: 'loading-pt-submissions',
      },
    }),
    fetchClassOccasions(feature) {
      if (feature.type === PERFORMANCE_TASK) {
        return this.fetchPtClassOccasions({
          performanceTaskId: this.feature.id,
          subjectClassId: this.subjectClassId,
        });
      }
      if (feature.type === ACTIVITY_SHEET) {
        return this.fetchAtsClassOccasions({
          activitySheetId: this.feature.id,
          subjectClassId: this.subjectClassId,
        });
      }
      return [];
    },
  },
};
</script>
