<template>
  <div>
    <template v-if="sections.length > 0">
      <kog-tag
        v-for="section in sections"
        :key="section.id"
        class="SectionTagList-tag"
        :label="section.number"
        :tooltip-text="section.name"
        :type="section.first_completed_at ? 'positive' : 'default'"
        :icon-class="section.first_completed_at ? 'fas fa-check' : 'fas fa-eye'"
      />
    </template>
    <p v-else> - </p>
  </div>
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export default {
  name: 'SectionTagList',
  components: {
    KogTag,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
.SectionTagList-tag {
  margin-bottom: var(--space-xxs);
  margin-left: var(--space-xxs);
}

.SectionTagList-tag:first-of-type {
  margin-left: 0;
}
</style>
