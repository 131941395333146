<template>
  <kog-skeleton
    v-if="isLoadingClassList"
    height="250px"
  />
  <div v-else>
    <p
      v-if="showClassesCount"
      class="margin-bottom-s"
      >{{ classesCountText }}</p
    >
    <ul class="list-unstyled">
      <li
        v-for="subjectClass in classesWithSelectedStudents"
        :key="subjectClass.id"
      >
        <assignment-class-card
          :subject-class="subjectClass"
          :selected-students="selectedStudentIds"
          :selected-google-classroom-recipient-upstream-ids="
            selectedGoogleClassroomRecipientUpstreamIds
          "
          :students-in-class="studentListByClassId[subjectClass.id]"
          :is-loading-lms-integration-categories="isLoadingLmsIntegrationCategories"
          :lms-categories="lmsIntegrationCategoryPerClass(subjectClass.id)"
          :send-to-integration="sendToIntegration"
          :selected-category="selectedCategory(subjectClass.id)"
        />
      </li>
    </ul>
    <kog-button
      class="margin-top-s width-full"
      has-icon
      label="Edit recipients"
      size="small"
      icon-class="fa-plus"
      @click="openMultiClassRecipientsModal"
    />
  </div>
</template>

<script>
import { defineComponent, inject } from 'vue';
import { storeToRefs } from 'pinia';
import pluralize from 'pluralize';
import { mapGetters, mapState } from 'vuex';

import {
  isCategoriesAllowedForAssignment,
  isIntegratedWithEdlink,
} from 'learning/common/services/integration/integration-service.js';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogSkeleton from 'sharedApp/components/base/skeleton/kog-skeleton.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import AssignmentClassCard from 'studyApp/components/teacher/assignments/assignment-class-card.vue';
import AssignmentMultiClassRecipientsModal from 'studyApp/components/teacher/assignments/assignment-multi-class-recipients-modal.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';
import useIntegrationLogo from 'studyApp/composables/use-integration-logo.ts';
import useLmsIntegrationCategories from 'studyApp/composables/use-lms-integration-categories.js';
import useStudentListByClassId from 'studyApp/composables/use-student-list-by-class-id.ts';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default defineComponent({
  name: 'RecipientClassList',
  components: {
    AssignmentClassCard,
    KogButton,
    KogSkeleton,
  },
  mixins: [RoutesMixin],
  props: {
    selectedStudentIds: {
      type: Array,
      required: true,
    },
    updateRecipients: {
      type: Function,
      required: true,
    },
    googleClassroomRecipients: {
      type: Array,
      required: true,
    },
    showClassesCount: {
      type: Boolean,
      default: false,
    },
    sendToIntegration: {
      type: Boolean,
      default: false,
    },
    categoriesByClassId: {
      type: Object,
      default: () => {},
    },
    assignmentSubjectClassIds: {
      type: Array,
      required: true,
    },
  },
  emits: ['send-to-integration-updated', 'categories-populated'],
  setup() {
    const { populateStudentListByClassId, studentListByClassId } = useStudentListByClassId();
    const featureFlagStore = useFeatureFlagStore();
    const { isEdlinkAssignmentIntegrationEnabled } = storeToRefs(featureFlagStore);

    const {
      loadLmsIntegrationCategories,
      lmsIntegrationCategories,
      isLoadingLmsIntegrationCategories,
    } = useLmsIntegrationCategories();

    const {
      subjectClassesWithIntegrationClassIds,
      integrationProviderDisplayName,
      integrationLogo,
    } = useIntegrationLogo();

    const disabledSendAssignmentToLmsTooltip = inject('disabledSendAssignmentToLmsTooltip', '');
    const { showIntegrationWarning } = useIntegrationAssignment();

    return {
      populateStudentListByClassId,
      studentListByClassId,
      isEdlinkAssignmentIntegrationEnabled,
      loadLmsIntegrationCategories,
      subjectClassesWithIntegrationClassIds,
      integrationProviderDisplayName,
      integrationLogo,
      lmsIntegrationCategories,
      disabledSendAssignmentToLmsTooltip,
      showIntegrationWarning,
      isLoadingLmsIntegrationCategories,
    };
  },
  computed: {
    ...mapGetters({
      activeClasses: 'subjectClassModule/activeClasses',
    }),
    ...mapState({
      user: state => state.userModule.user,
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
    }),
    isLoadingClassList() {
      return (
        this.$wait.is('populating_student_list_by_class_id') ||
        Object.keys(this.studentListByClassId).length === 0
      );
    },
    subjectClasses() {
      return this.activeClasses.filter(subjectClass => {
        return (
          this.subjectId === subjectClass.subject_id &&
          subjectClass.teacher_user_ids.includes(this.user.id)
        );
      });
    },
    classesWithSelectedStudents() {
      return this.subjectClasses.filter(subjectClass => {
        const studentsInClass = this.studentListByClassId[subjectClass.id];
        const hasSelectedStudentInClass = studentsInClass.some(student =>
          this.selectedStudentIds.includes(student.school_student),
        );
        if (!hasSelectedStudentInClass) {
          return false;
        }
        return true;
      });
    },
    selectedGoogleClassroomRecipientUpstreamIds() {
      return this.googleClassroomRecipients.flatMap(recipient => recipient.recipient_upstream_ids);
    },
    classesCountText() {
      return `Sending to ${this.classesWithSelectedStudents.length} ${pluralize('class', this.classesWithSelectedStudents.length)}`;
    },
    integrationProvider() {
      return this.subjectClass?.integration_providers?.length > 0
        ? this.subjectClass.integration_providers[0]
        : '';
    },
    subjectClassIntegrationSlug() {
      const subjectClassIntegrations = this.subjectClass.integrations;
      return subjectClassIntegrations.length > 0 ? subjectClassIntegrations[0].toLowerCase() : '';
    },
    isCategoriesAllowedForAssignment() {
      return (
        this.isEdlinkAssignmentIntegrationEnabled &&
        isCategoriesAllowedForAssignment(this.integrationProvider)
      );
    },
  },
  watch: {
    sendToIntegration: {
      handler(newSendToIntegration) {
        if (newSendToIntegration) {
          this.reloadIntegrationInfo();
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.$wait.start('populating_student_list_by_class_id');
    await this.populateStudentListByClassId(this.subjectClasses);
    this.$wait.end('populating_student_list_by_class_id');
  },
  methods: {
    async reloadIntegrationInfo() {
      if (isIntegratedWithEdlink(this.subjectClass.integrations)) {
        this.$wait.start('fetching_categories');
        if (this.isCategoriesAllowedForAssignment) {
          await this.loadCategories();
          this.populateCategories();
        }
        this.$wait.end('fetching_categories');
      } else {
        studyAppLocalStorage.setSendAssignmentToEdlink(false);
        this.updateSendToIntegration(false);
      }
    },
    async loadCategories() {
      try {
        await this.loadLmsIntegrationCategories(this.subjectClassIntegrationSlug, this.subjectId);
      } catch (error) {
        this.showIntegrationWarning(error);
        this.disableSendAssignmentToLmsButton(
          `Failed to fetch integration data from ${this.integrationProvider}`,
        );
      }
    },
    populateCategories() {
      const categoriesByClassId = {};
      this.assignmentSubjectClassIds.forEach(subjectClass => {
        if (this.categoriesByClassId[subjectClass]) {
          categoriesByClassId[subjectClass] = this.categoriesByClassId[subjectClass];
          return;
        }
        categoriesByClassId[subjectClass] = this.lmsIntegrationCategories[subjectClass][0].id;
      });
      this.$emit('categories-populated', categoriesByClassId);
    },
    openMultiClassRecipientsModal() {
      this.$modal(
        AssignmentMultiClassRecipientsModal,
        {
          initialSelectedStudentIds: this.selectedStudentIds,
          updateRecipients: this.updateRecipients,
          googleClassroomRecipients: this.googleClassroomRecipients,
          studentListByClassId: this.studentListByClassId,
          sendToIntegration: this.sendToIntegration,
          integrationLogo: this.integrationLogo,
          integrationProviderDisplayName: this.integrationProviderDisplayName,
          subjectClassesWithIntegrationClassIds: this.subjectClassesWithIntegrationClassIds,
        },
        {
          closeExisting: true,
        },
      );
    },
    disableSendAssignmentToLmsButton(tooltip) {
      this.$emit('send-to-integration-updated', false);
      this.disabledSendAssignmentToLmsTooltip = tooltip;
    },
    updateSendToIntegration(value) {
      this.$emit('send-to-integration-updated', value);
    },
    selectedCategory(subjectClassId) {
      return this.isCategoriesAllowedForAssignment ? this.categoriesByClassId[subjectClassId] : '';
    },
    lmsIntegrationCategoryPerClass(subjectClassId) {
      return this.isCategoriesAllowedForAssignment && this.lmsIntegrationCategories !== null
        ? this.lmsIntegrationCategories[subjectClassId]
        : [];
    },
  },
});
</script>

<style scoped>
.recipientClassList-listContainer {
  border: 1px solid var(--kog-button-default-border-default);
  border-radius: 4px;
}
.recipientClassList-tag {
  max-width: 100%;
}
</style>
