<template>
  <ul
    class="WeeklyActivitySummary flexContainer"
    aria-label="Weekly activity summary"
  >
    <li
      v-for="(day, index) in activity"
      :key="index"
      class="WeeklyActivitySummary-activityDay"
    >
      <div
        class="WeeklyActivitySummary-activityIconContainer flexContainer flexContainer-alignCenter"
      >
        <span class="screenreader-only">{{ getAriaLabelForActivity(index) }}</span>
        <i
          :class="[
            {
              'WeeklyActivitySummary-activityDayIcon--active': day.was_user_active,
              'WeeklyActivitySummary-activityDayIcon--inactive':
                !day.was_user_active && !day.disabled,
              'WeeklyActivitySummary-activityDayIcon--disabled': day.disabled,
              'fa-2x fas fa-check-circle': day.was_user_active,
              'far fa-circle': day.disabled && !day.was_user_active,
              'fa-2x fas fa-times-circle': !day.was_user_active && !day.disabled,
            },
          ]"
        />
      </div>
      <div
        aria-hidden="true"
        :class="{
          'WeeklyActivitySummary-activityDay--today': day.is_today,
          'WeeklyActivitySummary-activityDay--anotherDay': !day.is_today,
        }"
      >
        {{ day.name[0] }}
      </div>
    </li>
  </ul>
</template>

<script>
import { getWeekdayByAbbreviation } from 'sharedApp/const/weekday.js';

export default {
  name: 'WeeklyActivitySummary',
  props: {
    activity: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    getAriaLabelForActivity(index) {
      if (!this.activity[index]) {
        return null;
      }

      const currentIndexActivity = this.activity[index];

      if (currentIndexActivity.disabled) {
        return getWeekdayByAbbreviation(currentIndexActivity.name);
      }

      const weekday = getWeekdayByAbbreviation(currentIndexActivity.name);
      const isToday = currentIndexActivity.is_today ? 'today' : null;
      const partOfAStreak = currentIndexActivity.was_user_active
        ? 'part of a streak'
        : 'streak missed';

      return [weekday, isToday, partOfAStreak].filter(x => x).join(', ');
    },
  },
};
</script>

<style scoped>
.WeeklyActivitySummary {
  margin-bottom: 0;
  list-style: none;
}

.WeeklyActivitySummary-activityDay {
  padding: 0 12px;
  text-align: center;
}

.WeeklyActivitySummary-activityDay:first-child {
  padding-left: 0;
}

.WeeklyActivitySummary-activityDay:last-child {
  padding-right: 0;
}

.WeeklyActivitySummary-activityDay--today {
  font-weight: bold;
}

.WeeklyActivitySummary-activityDay--anotherDay {
  color: var(--kogPlatformGray031);
}

.WeeklyActivitySummary-activityDayIcon--active {
  color: var(--kog-colors-green-500);
}

.WeeklyActivitySummary-activityDayIcon--inactive {
  color: var(--kogPlatformGray077);
}

.WeeklyActivitySummary-activityDayIcon--disabled {
  color: var(--kogPlatformGray077);
}

.WeeklyActivitySummary-activityIconContainer {
  height: 26px;
}

@media (--viewport-s) {
  .WeeklyActivitySummary-activityDay {
    padding: 0 10px;
  }
}
</style>
