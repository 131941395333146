<template>
  <div
    ref="root"
    class="ContentNodeItem background-none"
    :class="[
      cssClass,
      {
        'ContentNodeItem--expanded': isExpanded,
      },
    ]"
  >
    <component
      :is="contentNodeTagType"
      v-bind="contentNodeProperties"
      v-kog-clickable="onNodeItemClick"
      class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter cursorPointer anchor-fully-unstyled"
      :class="{
        'padd-xs': !isExpanded,
      }"
      :aria-label="rowAriaLabel"
      :aria-expanded="isExpandable ? `${isExpanded}` : null"
      @click="trackClickNodeLink"
    >
      <div class="flexContainer flexChild-size-1">
        <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
        <div
          v-kog-clickable.prevent.stop="isEditingVisibility ? toggleVisibility : undefined"
          v-kog-description:[contentNodeId]="isEditingVisibility ? 'Toggle content visibility' : ''"
          class="absoluteContainer height-full"
          :class="{
            wiggle06: isEditingVisibility && !!contentNodeId,
          }"
          @mouseover="hoverIcon = true"
          @mouseleave="hoverIcon = false"
          @focusin="hoverIcon = true"
          @focusout="hoverIcon = false"
        >
          <subject-node-visibility-toggle
            v-if="!!contentNodeId"
            class="ContentNodeItem-visibilityToggle"
            :subject-node-id="contentNodeId"
            :activated="hoverIcon"
          />
          <img
            v-if="isIconSvg"
            class="margin-right-s"
            :src="icon"
            alt=""
            role="presentation"
          />
          <content-node-icon
            v-else
            class="margin-right-s"
            :icon-class="icon"
            :icon-style="iconStyle"
            :background-color="iconBackgroundColor"
            role="presentation"
          />
        </div>
        <div
          class="flexContainer flexChild-size-1"
          :class="{
            'ContentNodeItem-link': internalLinkContext || externalLink,
          }"
        >
          <content-node-item-title
            :title="title"
            :subtitle="subtitle"
            :phase="phase"
            :aria-label="rowAriaLabel"
            :is-hidden="isHidden"
          />
        </div>
      </div>
      <div
        class="flexContainer flexContainer-alignCenter"
        :class="{
          opacity05: isEditingVisibility,
        }"
      >
        <kog-tag
          v-if="flagCount > 0"
          is-chip
          class="ContentNodeItemChild-completionTag margin-right-s"
          icon-class="fa-flag"
          :label="`${flagCount}`"
          :context="`${flagCount} answers flagged`"
          size="m"
          type="outline"
          :disabled="isNgssSubject && !isLeafNode"
          @click="onReflectionTagClick"
        />
        <kog-tag
          v-if="!hasClassSize && hasReadingStatus"
          is-chip
          class="margin-right-xs"
          :icon-class="readingStatus.iconClass"
          :label="readingStatus.label"
          :context="readingStatusTagContext"
          size="m"
          :type="readingStatus.chipType"
        />
        <kog-tag
          v-else-if="hasClassSize && hasReadingCompletionData"
          is-chip
          class="margin-right-xs green"
          :icon-class="completionTagIconClass"
          :label="`${totalStudentsThatCompletedNode} / ${classSize}`"
          :context="completionTagContext"
          :tab-index="-1"
          size="m"
          type="outline"
          @click.stop="onCompletionTagClick"
        />
        <subject-node-assign-toggle
          v-if="contentNodeId && isLeafNode && user.isTeacher()"
          class="margin-right-m"
          :subject-node-id="contentNodeId"
        />
        <kog-round-button
          v-if="hasButton"
          :icon-class="expandIconOrButtonIcon"
          icon-size="s"
          size="small"
          button-style="secondary-outline"
          :aria-label="buttonAriaLabel"
          :aria-expanded="isExpandable ? `${isExpanded}` : null"
          tabindex="-1"
          @click.stop="onActionButtonClick"
        />
      </div>
    </component>

    <vertical-expand>
      <div
        v-if="isExpanded"
        class="padd-top-s"
      >
        <alignment-list
          :syllabus-nodes="syllabusNodes"
          :ngss-section-settings="ngssSectionSettings"
        />

        <div v-if="!isLeafNode">
          <span class="text-overline">
            {{ pluralize($term('subsection')) }}
          </span>
          <div class="ContentNodeItem-subsectionList gap-xxs">
            <content-node-item-child
              v-for="(child, index) of children"
              :key="child.id"
              class="ContentNodeItem-subsectionList-row padd-top-xs"
              :tab-index="index === 0 ? 0 : -1"
              :node="child"
              :class-size="classSize"
              :user-node-reading-progress="userNodesReadingProgress[child.id]"
              :reading-completion-data="readingCompletionDataPerNodeId[child.id]"
              :flag-count="flagsPerNode[child.id] || 0"
              content-type="subsection"
              @click-completion-stats="$emit('clickChildCompletionStats', child)"
              @click-reflection-stats="$emit('clickChildReflectionStats', child)"
            />
          </div>
        </div>
      </div>
    </vertical-expand>

    <div
      v-if="position === 'top' || position === 'middle'"
      class="ContentNodeItem-Separator padd-top-xxs margin-left-xxxl margin-right-m"
    />
  </div>
</template>

<script>
import { inject } from 'vue';
import pluralize from 'pluralize';
import { mapActions, mapGetters, mapState } from 'vuex';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ScrollPositionMixin from 'sharedApp/mixins/scroll-position-mixin.js';
import AlignmentList from 'studyApp/components/alignments/alignments-list.vue';
import ContentNodeIcon from 'studyApp/components/subject-class-overview/content-node-icon.vue';
import SubjectNodeAssignToggle from 'studyApp/components/subject-class-overview/subject-node-assign-toggle.vue';
import SubjectNodeVisibilityToggle from 'studyApp/components/subject-class-overview/subject-node-visibility-toggle.vue';
import useToggleNodeVisibilityEventHandler from 'studyApp/composables/use-toggle-node-visibility-event-handler.js';
import FeaturesMixin from 'studyApp/mixins/features-mixin.js';

import ContentNodeItemChild from './content-node-item-child.vue';
import ContentNodeItemTitle from './content-node-item-title.vue';

export default {
  name: 'ContentNodeItem',
  components: {
    SubjectNodeAssignToggle,
    SubjectNodeVisibilityToggle,
    ContentNodeItemTitle,
    ContentNodeItemChild,
    ContentNodeIcon,
    KogRoundButton,
    KogTag,
    VerticalExpand,
    AlignmentList,
  },
  directives: {
    KogClickable,
    KogDescription,
  },
  mixins: [RoutesMixin, ScrollPositionMixin, FeaturesMixin],
  props: {
    contentType: {
      type: String,
      default: '',
    },
    userNodeReadingProgress: {
      type: Object,
      default: null,
    },
    userNodesReadingProgress: {
      type: Object,
      default: () => ({}),
    },
    internalLinkContext: {
      type: Object,
      default: null,
    },
    externalLink: {
      type: String,
      default: null,
    },
    buttonAriaLabel: {
      type: String,
      default: '',
    },
    rowAriaLabel: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'ungrouped',
      validator: val => ['top', 'bottom', 'middle', 'ungrouped'].includes(val),
    },
    contentNodeId: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    iconStyle: {
      type: String,
      default: 'regular',
    },
    iconBackgroundColor: {
      type: String,
      default: 'purple',
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    buttonIcon: {
      type: String,
      default: 'fa-chevron-right',
    },
    readingCompletionData: {
      type: Set,
      default: undefined,
    },
    readingCompletionDataPerNodeId: {
      type: Object,
      default: () => ({}),
    },
    classSize: {
      type: Number,
      default: 0,
    },
    phase: {
      type: String,
      default: null,
    },
    children: {
      type: Array,
      default: () => [],
    },
    syllabusNodes: {
      type: Array,
      default: () => [],
    },
    ngssSectionSettings: {
      type: Object,
      default: () => null,
    },
  },
  emits: [
    'clickChildCompletionStats',
    'clickChildReflectionStats',
    'clickCompletionStats',
    'clickContainer',
  ],
  setup() {
    const { handleToggleNodeVisibilityEvent } = useToggleNodeVisibilityEventHandler();
    const isFilteringSubjectClassOverview = inject('isFilteringSubjectClassOverview', false);
    const activeFiltersInSubjectClassOverview = inject('activeFiltersInSubjectClassOverview', '');

    return {
      handleToggleNodeVisibilityEvent,
      isFilteringSubjectClassOverview,
      activeFiltersInSubjectClassOverview,
    };
  },
  data() {
    return {
      isExpanded: false,
      hoverIcon: false,
    };
  },
  computed: {
    ...mapState({
      isEditingVisibility: state => state.subjectClassModule.isEditingVisibility,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      isNgssSubject: 'subjectModule/isNgssSubject',
      getAllOccasionsGroupedBySubjectNode:
        'exerciseOccasionsModule/getAllOccasionsGroupedBySubjectNode',
      isNodeHiddenWhileEditing: 'subjectClassModule/isNodeHiddenWhileEditing',
    }),
    isLeafNode() {
      return this.children.length === 0;
    },
    subjectNode() {
      return this.subjectNodesById[this.contentNodeId];
    },
    isHidden() {
      if (this.subjectNode) {
        return this.isNodeHiddenWhileEditing(this.subjectNode);
      }
      return false;
    },
    cssClass() {
      const baseClass = 'ContentNodeItem';
      if (this.position === 'ungrouped') {
        return `${baseClass}--ungrouped margin-bottom-xxs`;
      }

      if (this.position === 'top') {
        return `${baseClass}--top padd-top-xs padd-right-xs padd-left-xs padd-bottom-0`;
      }

      if (this.position === 'bottom') {
        return `${baseClass}--bottom padd-top-xxs padd-right-xs padd-left-xs padd-bottom-xs`;
      }

      return 'padd-top-xxs padd-right-xs padd-left-xs padd-bottom-0';
    },
    isExpandable() {
      return !this.isLeafNode;
    },
    expandIcon() {
      return this.isExpanded ? 'fa-chevron-up' : 'fa-chevron-down';
    },
    expandIconOrButtonIcon() {
      if (this.isExpandable) {
        return this.expandIcon;
      }
      return this.buttonIcon;
    },
    readingStatus() {
      if (!this.userNodeReadingProgress) {
        return null;
      }

      const progressStats = this.userNodeReadingProgress.progress_stats;
      const completedNodes = progressStats.completed_leafs_quantity;
      const totalNodes = progressStats.total_leafs_quantity;

      const iconClasses = ['fa-badge-check'];
      let label = '';
      let chipType = 'outline';

      if (completedNodes > 0) {
        label = 'Started';
      }

      if (completedNodes === totalNodes) {
        iconClasses.push('fa-solid');
        label = 'Done';
        chipType = 'positive';
      }

      return { label, chipType, iconClass: iconClasses.join(' ') };
    },
    hasReadingStatus() {
      return !!this.readingStatus?.label;
    },
    readingStatusTagContext() {
      return `${this.$term(this.contentType)} is ${this.readingStatus?.label}`;
    },
    hasReadingCompletionData() {
      return this.readingCompletionData !== undefined;
    },
    hasClassSize() {
      return this.classSize !== 0;
    },
    totalStudentsThatCompletedNode() {
      return this.readingCompletionData?.size ?? 0;
    },
    isCompleted() {
      return this.totalStudentsThatCompletedNode === this.classSize;
    },
    isIconSvg() {
      return this.icon.endsWith('.svg');
    },
    completionTagIconClass() {
      const iconType = this.isCompleted ? 'fas' : 'far';
      return `${iconType} fa-user-check`;
    },
    completionTagContext() {
      return `${this.totalStudentsThatCompletedNode} of ${this.classSize} students have completed ${this.title}`;
    },
    reflectionChildrenIds() {
      return this.children
        .filter(item => item.section_template === 'reflection')
        .map(node => node.id);
    },
    flagCount() {
      return Object.values(this.flagsPerNode).reduce((total, count) => total + count, 0);
    },
    flagsPerNode() {
      if (this.isNgssSubject && this.isLeafNode) {
        return {};
      }
      const nodeIds = this.isLeafNode ? [this.contentNodeId] : this.reflectionChildrenIds;
      const answers = this.getAllOccasionsGroupedBySubjectNode;
      const result = {};
      nodeIds.forEach(id => {
        const practiceItems = answers[id]?.map(item => item.practice_items[0]) || [];
        const flagCount = practiceItems.filter(item => item.is_flagged_by_teacher).length;
        result[id] = flagCount;
      });
      return result;
    },
    nodeIdQueryParams() {
      return +this.$route.query.node;
    },
    contentNodeTagType() {
      if (this.externalLink) return 'a';
      if (this.internalLinkContext) return 'router-link';
      return 'div';
    },
    contentNodeProperties() {
      if (this.externalLink) return { href: this.externalLink, target: '_blank' };
      if (this.internalLinkContext) return { to: this.internalLinkContext };
      return {};
    },
  },
  mounted() {
    if (this.nodeIdQueryParams === this.contentNodeId) {
      this.isExpanded = true;
      this.$refs.root.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  },
  methods: {
    ...mapActions({
      toggleNodeVisibility: 'subjectClassModule/toggleNodeVisibility',
    }),
    onReflectionTagClick(e) {
      e.preventDefault();
      this.$emit('clickChildReflectionStats', this.subjectNode);
    },
    pluralize,
    expandOrEmit(eventName) {
      if (this.isExpandable) {
        this.storeLastPageOffset();
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
          this.$mixpanel.trackEvent('Subject class overview - Open section modal', {
            subject_node_name: `${this.subtitle} ${this.title}`,
            subject_node_phase: this.phase,
          });
          if (this.nodeIdQueryParams !== this.contentNodeId) {
            this.$router.replace({
              name: 'subjectClassOverview',
              params: {
                classSlug: this.subjectClassSlug,
                sid: this.subjectId,
                cid: this.classId,
              },
              query: {
                ...this.$route.query,
                node: this.contentNodeId,
              },
            });
            this.tryRestoreLastPageOffset();
          }
        }
      } else {
        this.$emit(eventName);
      }
    },
    onNodeItemClick() {
      this.expandOrEmit('clickContainer');
    },
    onCompletionTagClick(e) {
      e.preventDefault();
      this.$emit('clickCompletionStats');
    },
    onActionButtonClick(e) {
      e.preventDefault();
      this.expandOrEmit('clickButton');
    },
    toggleVisibility() {
      this.handleToggleNodeVisibilityEvent(this.subjectNode);
    },
    trackClickNodeLink() {
      if (this.internalLinkContext) {
        this.$mixpanel.trackEvent('Subject class overview - Content option - Open book', {
          subject_node_id: this.subjectNode.id,
          subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNode.name}`,
          content_type: this.getFeatureBySubjectNode(this.subjectNode)?.type || 'Section',
          is_filtering: this.isFilteringSubjectClassOverview,
          active_filters: this.activeFiltersInSubjectClassOverview,
        });
      }
    },
  },
};
</script>

<style scoped>
.ContentNodeItem:hover,
.ContentNodeItem--expanded {
  background-color: var(--kog-colors-aubergine-100);
}

.ContentNodeItem--expanded {
  padding: var(--space-m);
}

.ContentNodeItem-link {
  color: var(--kogInherit);
  text-decoration: none;
}

.ContentNodeItem-link:hover,
.ContentNodeItem-link:focus {
  text-decoration: underline;
}

.ContentNodeItem-Separator {
  border-bottom: 1px solid var(--kogPlatformGray090);
}

.ContentNodeItem-subsectionList,
.ContentNodeItem-subsectionList-row {
  display: flex;
}

.ContentNodeItem-subsectionList {
  flex-direction: column;
}

.ContentNodeItem-subsectionList-row {
  align-items: center;
}

.ContentNodeItem-visibilityToggle {
  position: absolute;
  right: 75%;
  bottom: 67%;
}
</style>
