<template>
  <div
    class="KogEmptyStateCard muted flexContainer flexContainer-center"
    :style="{ height: `${height}px` }"
  >
    <slot>
      {{ emptyStateText }}
    </slot>
  </div>
</template>

<script>
export default {
  name: 'KogEmptyStateCard',
  props: {
    emptyStateText: {
      type: String,
      default: '',
      required: false,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
};
</script>

<style scoped>
.KogEmptyStateCard {
  width: 100%;
  padding: 0 var(--space-m);

  text-align: center;

  border: 2px dashed var(--kogPlatformGray077);
  border-radius: var(--space-xs);
}
</style>
