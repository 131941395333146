import { ContextualBalloon } from '@ckeditor/ckeditor5-ui';

import gridIcon from 'publishingApp/assets/icons/grid-2-regular.svg';

import KogUI from '../utils/kogui.ts';
import { registerFactoryView } from '../utils/plugin-utils.ts';
import FormView from './content-grid-form.js';

export default class ContentGridUI extends KogUI {
  static get requires() {
    return [ContextualBalloon];
  }

  init() {
    this.balloon = this.editor.plugins.get(ContextualBalloon);
    this.formView = new FormView(this.editor.locale);

    const onSubmit = () => {
      const value = { columns: this.formView.columns };
      this.editor.execute('insertContentGrid', value);
      this.hideUI();
    };

    this.setupFormView(onSubmit);

    registerFactoryView(this.editor, {
      name: 'contentgrid',
      commandName: 'insertContentGrid',
      icon: gridIcon,
      label: 'Content grid',
      onExecute: () => this.showUI(),
    });

    const updateCommand = this.editor.commands.get('updateContentGrid');
    this.listenTo(updateCommand, 'execute', () => {
      this.showUI();
    });
  }

  showUI() {
    super.showUI();

    const commandValue = this.editor.commands.get('insertContentGrid').value;
    if (commandValue) {
      const { columns } = commandValue;
      this.formView.setFields(columns);
    }
  }
}
