<template>
  <div>
    <h2 class="heading-s">
      {{ title }}
    </h2>
    <google-class-pill
      :google-class="googleClass"
      size="small"
      class="margin-top-m inlineflexContainer"
    />
    <div class="muted margin-top-s"> Google Students </div>
    <div v-if="students.length > 0">
      <div
        v-for="student in students"
        :key="student.id"
        class="margin-top-s"
      >
        {{ student.name }}
      </div>
    </div>
  </div>
</template>

<script>
import GoogleClassPill from 'studyApp/components/teacher/google-class-pill.vue';

export default {
  name: 'GoogleClassModalStudentsList',
  components: {
    GoogleClassPill,
  },
  props: {
    students: {
      type: Array,
      required: true,
    },
    googleClass: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
