<template>
  <img
    v-if="hasCalculator && calcAllowedForSubject"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltipText,
    }"
    :alt="tooltipText"
    :src="calcIcon"
    class="KogCalculator"
  />
</template>

<script>
import { VTooltip } from 'floating-vue';

import { calculatorIconAllowed } from 'sharedApp/utils/calculator-icon-exceptions.js';

const noCalcAllowedIcon = require('learning/common/images/calculatorNotAllowed.svg');
const calcAllowedIcon = require('learning/common/images/calculatorAllowed.svg');
const noCalcAllowedLightIcon = require('learning/common/images/calculatorNotAllowedLight.svg');
const calcAllowedLightIcon = require('learning/common/images/calculatorAllowedLight.svg');

export default {
  name: 'KogCalculator',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    subjectId: {
      type: Number,
      default: 0,
    },
    isLightIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    calcAllowedForSubject() {
      if (this.subjectId) {
        return calculatorIconAllowed(this.subjectId);
      }
      return true;
    },
    hasCalculator() {
      return this.tags.some(tag => tag.match(/calculator-(yes|no)/));
    },
    calcAllowed() {
      const calculatorType = this.tags.find(tag => tag.match(/calculator-(yes|no)/));
      return calculatorType === 'calculator-yes';
    },
    calcIcon() {
      if (this.isLightIcon) {
        return this.calcAllowed ? calcAllowedLightIcon : noCalcAllowedLightIcon;
      }
      return this.calcAllowed ? calcAllowedIcon : noCalcAllowedIcon;
    },
    tooltipText() {
      return this.calcAllowed ? 'Use of calculator allowed' : 'Use of calculator not allowed';
    },
  },
};
</script>

<style scoped>
.KogCalculator {
  height: var(--space-l);
}
</style>
