function printHtml(html, title, css) {
  const newIframe = document.createElement('iframe');
  newIframe.width = '1px';
  newIframe.height = '1px';
  newIframe.src = 'about:blank';

  // for IE wait for the IFrame to load so we can access contentWindow.document.body
  newIframe.addEventListener('load', () => {
    const frameDoc = newIframe.contentWindow.document;
    const frameWnd = newIframe.contentWindow;

    const scriptTag = frameDoc.createElement('script');
    scriptTag.type = 'text/javascript';
    const script = frameDoc.createTextNode('function Print(){ window.focus(); window.print(); }');
    scriptTag.appendChild(script);

    const cssTag = frameDoc.createElement('style');
    cssTag.type = 'text/css';
    const cssContent = frameDoc.createTextNode(css);
    cssTag.appendChild(cssContent);

    frameDoc.title = title;
    frameDoc.body.innerHTML = html;
    frameDoc.body.appendChild(scriptTag);
    frameDoc.body.appendChild(cssTag);

    // for chrome, a timeout for loading large amounts of content
    setTimeout(() => {
      frameWnd.Print();
      scriptTag.remove();
      newIframe.remove();
    }, 200);
  });
  document.body.appendChild(newIframe);
}

export default printHtml;
