<template>
  <div
    class="SubjectClassOverviewHero text-white width-full"
    :style="{ height: height + 'px' }"
  >
    <image-box
      class="SubjectClassOverviewHero-image"
      :src="subjectLogoUrl"
      :height="height"
      :quality="75"
      auto="format"
      fit="crop"
      crop="entropy"
    />
    <div
      class="SubjectClassOverviewHero-imageOverlay flexContainer flexContainer-row flexContainer-justifyCenter positionAbsolute positionAbsolute-top positionAbsolute-left width-full height-full"
    >
      <div class="SubjectClassOverviewHero-headerContainer kog-container">
        <div class="padd-l">
          <div class="SubjectClassOverviewHero-subjectLabel heading-xxs">
            {{ subjectName }}
          </div>
          <h1 class="SubjectClassOverviewHero-classLabel margin-top-xs">
            {{ subjectClassName }}
          </h1>
          <div class="flexContainer margin-top-s flexContainer-alignCenter">
            <teacher-avatars :teachers="teachers" />
            <div
              v-if="isTeacher"
              class="SubjectClassOverviewHero-studentCountLabel margin-left-s"
            >
              {{ pluralize('student', studentCount, true) }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="endorsed"
        :class="logoContainerClasses"
      >
        <endorser-logo
          :education-system-name="educationSystemName"
          :link="true"
          :class="endorserLogoClass"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize';

import ImageBox from 'sharedApp/components/images/image-box.vue';
import EndorserLogo from 'sharedApp/components/logo/endorser-logo.vue';
import TeacherAvatars from 'studyApp/components/subject-class-overview/teacher-avatars.vue';

export default {
  name: 'SubjectClassOverviewHero',
  components: {
    ImageBox,
    TeacherAvatars,
    EndorserLogo,
  },
  props: {
    subjectClassName: {
      type: String,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
    subjectLogoUrl: {
      type: String,
      required: true,
    },
    teachers: {
      type: Array,
      required: true,
    },
    studentCount: {
      type: Number,
      required: true,
    },
    isTeacher: {
      type: Boolean,
      required: true,
    },
    educationSystemName: {
      type: String,
      required: true,
      validator: value => ['IGCSE', 'IBDP', 'NGSS'].includes(value),
    },
    endorsed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    height: 360,
  }),
  computed: {
    endorserLogoClass() {
      return `SubjectClassOverviewHero-endorsement-${this.educationSystemName}`;
    },
    logoContainerClasses() {
      return {
        'SubjectClassOverviewHero-endorsementCard-IGCSE': this.isIGCSESubject,
        positionAbsolute: true,
        'positionAbsolute-bottomRight': true,
      };
    },
    isIGCSESubject() {
      return this.educationSystemName === 'IGCSE';
    },
  },
  methods: {
    pluralize,
  },
};
</script>

<style scoped>
.SubjectClassOverviewHero {
  position: relative;
  background-color: var(--kogPlatformGray000);
}

.SubjectClassOverviewHero-image {
  opacity: 0.7;
}

.SubjectClassOverviewHero-imageOverlay {
  flex-direction: column;
  align-content: stretch;
  justify-content: space-between;
  background: linear-gradient(2.38deg, rgba(0, 0, 0, 0.38) 1.99%, rgba(0, 0, 0, 0) 95.38%);
}

.SubjectClassOverviewHero-subjectLabel {
  margin-top: 136px;
}

.SubjectClassOverviewHero-headerContainer {
  max-height: 312px;
}

.SubjectClassOverviewHero-classLabel {
  overflow: hidden;

  max-height: 60px;

  font-size: 48px;
  font-weight: bold;
  line-height: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SubjectClassOverviewHero-endorsementCard-IGCSE {
  margin-right: 24px;
  margin-bottom: 40px;
  padding: 12px;

  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
}

.SubjectClassOverviewHero-studentCountLabel {
  color: var(--kog-content-faded-white);
}

.SubjectClassOverviewHero-endorsement-IBDP {
  position: absolute;
}

@media (max-width: 768px) {
  .SubjectClassOverviewHero-endorsement-IBDP {
    right: 30px;
    bottom: 30px;
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 768px) {
  .SubjectClassOverviewHero-endorsement-IBDP {
    right: 60px;
    bottom: 60px;
    width: 120px;
    height: 120px;
  }
}

.SubjectClassOverviewHero-endorsement-IGCSE {
  width: 311px;
}
</style>
