<template>
  <kog-dropdown-button
    aria-label="Class settings dropdown"
    button-type="round"
    icon-class="fa-ellipsis-v"
    icon-style="regular"
    icon-size="s"
    dropdown-item-type="text"
    :items="dropdownItems"
    label="label goes here"
    size="small"
    button-style="basic"
    dropdown-placement="bottom-end"
    @select="handleSelect"
  />
</template>

<script>
import { mapState } from 'vuex';

import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import googleClassroomIcon from 'sharedApp/img/google-classroom-icon-32x32_2x.png';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';

export default {
  name: 'ClassKebabMenu',
  components: {
    KogDropdownButton,
  },
  mixins: [GoogleClassroomMixin],
  props: {
    subjectClass: {
      type: Object,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    isAdminClass: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['login-google-classroom', 'editClassClicked', 'leaveClassClicked'],
  computed: {
    ...mapState({
      userProfile: state => state.teacherDetailModule.userProfile,
    }),
    hideConnectToGoogleClassroom() {
      return this.shouldHideConnectToGoogleClassroom(this.subjectClass.integrations);
    },
    dropdownItems() {
      const items = [];

      if (!this.hideConnectToGoogleClassroom) {
        items.push({
          iconClass: googleClassroomIcon,
          text: 'Connect to Classroom',
          isImageIcon: true,
          handler: () => {
            this.$emit('login-google-classroom');
          },
        });
      }

      items.push({
        iconClass: 'fa-cog',
        text: 'Manage class and students',
        handler: () => {
          this.$router.push({
            name: 'manageClass',
            params: {
              classSlug: this.subjectClass.slug,
              sid: this.subject.id,
              cid: this.classId,
            },
          });
        },
      });

      items.push({
        iconClass: 'fa-edit',
        text: 'Edit class details',
        handler: () => {
          this.$emit('editClassClicked');
        },
      });

      if (!this.isAdminClass) {
        items.push({
          iconClass: 'fa-sign-out-alt',
          text: 'Leave class',
          handler: () => {
            this.$emit('leaveClassClicked');
          },
        });
      }

      return items;
    },
    classId() {
      return this.subjectClass.id;
    },
  },
  methods: {
    handleSelect(item) {
      if (item.handler) {
        item.handler();
      }
    },
  },
};
</script>
