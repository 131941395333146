<template>
  <div
    class="AssignmentCheckpointContainer width-full"
    :class="{
      'AssignmentCheckpointContainer--completed': isNodeCompleted,
    }"
  >
    <div v-if="isSubmittable && !isReviewMode">
      <section-question-container
        v-if="hasQuestions && !isNodeCompleted"
        :current-section-node="currentSectionNode"
        :question-count="sectionQuestionCount"
        @completed="$emit('completed')"
      />
      <div
        v-else
        class="flexContainer flexContainer-column flexContainer-center"
      >
        <kog-button
          v-if="!isNodeCompleted"
          :is-loading="isLoading"
          :disabled="isCompleteButtonDisabled"
          :tooltip="completeButtonTooltip"
          label="Complete task"
          button-style="accent"
          class="margin-bottom-s width-full AssignmentCheckpointContainer-submitButton"
          @click="$emit('completed')"
        />
        <div
          v-else
          class="flexContainer flexContainer-column flexContainer-center"
        >
          <span class="text-overline">Status</span>
          <h2 class="margin-top-xs">Completed</h2>
        </div>
      </div>
    </div>
    <div class="flexContainer flexContainer-spaceBetween width-full">
      <router-link
        :to="previousNodeRoute"
        class="anchor-unstyled"
      >
        <kog-round-button
          icon-class="fa-chevron-left"
          aria-label="previous node"
          :disabled="isFirstNodeInAssignment"
        />
      </router-link>
      <router-link
        :to="nextNodeRoute"
        class="anchor-unstyled flexContainer flexContainer-row flexContainer-alignCenter"
      >
        <span
          v-if="isNodeCompleted && !isLastNodeInAssignment"
          class="margin-right-m"
          >Next task</span
        >
        <kog-round-button
          icon-class="fa-chevron-right"
          aria-label="next node"
          :disabled="isLastNodeInAssignment"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapActions, mapState } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import SectionQuestionContainer from 'studyApp/components/section-text/section-question-container.vue';
import NodeProgressMixin from 'studyApp/mixins/node-progress-mixin.js';

export default {
  name: 'AssignmentCheckpointContainer',
  components: { KogRoundButton, KogButton, SectionQuestionContainer },
  mixins: [RoutesMixin, NodeProgressMixin],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    currentSectionNode: {
      type: Object,
      required: true,
    },
    isCompleteButtonDisabled: {
      type: Boolean,
      default: false,
    },
    completeButtonTooltip: {
      type: String,
      default: '',
    },
    isSubmittable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['completed'],
  setup() {
    const assignmentStore = useAssignmentStore();
    const { assignment } = storeToRefs(assignmentStore);
    return {
      teacherAssignment: assignment,
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      studentAssignment: state => state.assignmentModule.assignment,
      sectionQuestionCount: state => state.bookModule.sectionQuestionCount,
    }),
    assignment() {
      return this.user.isTeacher() ? this.teacherAssignment : this.studentAssignment;
    },
    isNodeCompleted() {
      return this.isSubjectNodeCompleted(this.nodeId);
    },
    hasQuestions() {
      return this.sectionQuestionCount > 0;
    },
    assignmentNodes() {
      return this.assignment?.sections || [];
    },
    currentNodeIndex() {
      return this.assignmentNodes.findIndex(
        readingItem => readingItem.subject_node_id === this.nodeId,
      );
    },
    isFirstNodeInAssignment() {
      return this.currentNodeIndex === 0;
    },
    isLastNodeInAssignment() {
      return this.currentNodeIndex === this.assignmentNodes.length - 1;
    },
    routeName() {
      return this.isReviewMode ? 'teacherAssignmentDetails' : 'classReadingAssignmentTake';
    },
    nextNodeRoute() {
      if (this.isLastNodeInAssignment) return '#';
      const nextNodeId = this.assignmentNodes[this.currentNodeIndex + 1]?.subject_node_id;
      return {
        name: this.routeName,
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignment.id,
          nodeId: nextNodeId,
        },
      };
    },
    previousNodeRoute() {
      if (this.isFirstNodeInAssignment) return '#';
      const previousNodeId = this.assignmentNodes[this.currentNodeIndex - 1]?.subject_node_id;
      return {
        name: this.routeName,
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignment?.id,
          nodeId: previousNodeId,
        },
      };
    },
    isReviewMode() {
      return this.$route.meta.isReviewMode;
    },
  },
  created() {
    this.fetchReadingAssignmentForSection(this.nodeId);
  },
  methods: {
    ...mapActions({
      fetchReadingAssignmentForSection: 'bookModule/fetchReadingAssignmentForSection',
    }),
  },
};
</script>

<style scoped>
.AssignmentCheckpointContainer {
  padding: var(--space-l);
  font-family: var(--kog-lato);
  background-color: var(--kog-colors-grey-100);
}

.AssignmentCheckpointContainer--completed {
  background-color: var(--kog-colors-green-500);
}

.AssignmentCheckpointContainer-submitButton {
  max-width: 736px;
}
</style>
