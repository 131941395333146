<template>
  <div
    ref="root"
    class="KogAnchor-wrapper"
    :class="{
      'padd-right-m padd-left-m': !newStyle,
      'padd-right-s padd-left-s': newStyle,
    }"
  >
    <a
      v-tooltip="{
        theme: 'kog-tooltip',
        content: showLabelAsTooltip ? label : '',
      }"
      :aria-label="label"
      :href="url"
      :target="target"
      :class="anchorLinkClass"
      @click="$emit('before-navigation')"
    >
      <i :class="[icon, { 'KogAnchor--iconNewStyle': newStyle }]" />
      <span
        v-if="!newStyle && !showLabelAsTooltip"
        class="KogAnchor--text"
      >
        {{ label }}
      </span>
    </a>
  </div>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { VTooltip } from 'floating-vue';

export default {
  name: 'KogAnchor',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    icon: {
      required: true,
      type: String,
    },
    url: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
    newStyle: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'light'].includes(prop),
    },
    showLabelAsTooltip: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['before-navigation'],
  computed: {
    anchorLinkClass() {
      const classes = ['KogAnchor-link', `KogAnchor-link--${this.theme}`];

      if (this.newStyle) {
        classes.push('KogAnchor-link--newStyle');
      }

      return classes;
    },
  },
};
</script>

<style scoped>
.KogAnchor--iconNewStyle {
  padding: 4px;
  font-size: var(--kog-font-size-navbar-icons);
}

.KogAnchor-link--newStyle {
  display: flex;
}

.KogAnchor-link {
  text-decoration: none;
}

.KogAnchor-link--primary {
  color: var(--kog-text-brand);
}

.KogAnchor-link--primary:hover {
  color: var(--kog-text-brand);
}

.KogAnchor-link--light {
  color: var(--kog-colors-white);
}

@media (--viewport-s) {
  .KogAnchor--text {
    display: none;
  }
}
</style>
