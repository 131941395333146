import AssignmentMenuItem from 'studyApp/menus/items/assignment-menu-item.js';

export default class MyAssignmentsMenuItem extends AssignmentMenuItem {
  navigateTo({ router, currentRoute }) {
    const params = {
      classSlug: currentRoute.params.classSlug,
      cid: currentRoute.params.cid,
      sid: currentRoute.params.sid,
    };
    this.goToRoute(router, currentRoute, 'classAssignmentsOverview', params);
  }
}
