<template>
  <div>
    <kog-filter
      v-for="filter in filterList"
      :key="filter.filterKey"
      :disabled="disabled"
      :filter-text="filter.filterText"
      :filter-key="filter.filterKey"
      :is-selected="filter.isSelected"
      @filter-tag="toggleFilterTag"
    />
  </div>
</template>

<script>
import KogFilter from 'teachApp/components/kog-filter.vue';

export default {
  name: 'KogFilterGroup',
  components: {
    KogFilter,
  },
  props: {
    filterList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['filter-tag'],
  methods: {
    toggleFilterTag(args) {
      this.$emit('filter-tag', args);
    },
  },
};
</script>
