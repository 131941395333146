<template>
  <div>
    <Teleport to="#StudyAppMain-navbar--portal">
      <fullpage-takeover-navbar
        v-if="isAssignmentOccasionLoaded"
        back-button-aria-label="Click to go back to previous page"
        :header-text="assignment.name"
        @back-button-click="goToAssignmentOverview"
      />
    </Teleport>
    <kog-default-study-page>
      <template #header>
        <div v-if="isAssignmentOccasionLoaded && !isIntroVisible">
          <h1 class="sr-only">{{ assignment.name }}</h1>
          <div class="margin-top-l margin-bottom-l">
            <kog-ball-step-paginator
              :has-navigation-buttons="true"
              :number-of-pages="practiceItems.length"
              :current-page="currentPageNumber"
              :is-clickable="true"
              :paginator-size="9"
              :is-step-done="answerStrategy.isStepDone"
              :is-step-correct="answerStrategy.isStepCorrect"
              @change-page="goToQuestion"
            />
          </div>
        </div>
      </template>
      <template #body>
        <kog-loader
          :loading="$wait.is('loading_assignment')"
          loading-msg="Loading assignment..."
        >
          <template v-if="isAssignmentOccasionLoaded">
            <assignment-take-overview
              v-if="isIntroVisible"
              :assignment="assignment"
              @take-assignment="startAssignment"
            />
            <template v-else>
              <div
                class="margin-bottom-m flexContainer flexContainer-alignCenter flexContainer-spaceBetween"
              >
                <h2 class="heading-s"> Question {{ currentPageNumber }} </h2>
                <div class="flexContainer flexContainer-alignCenter">
                  <div v-if="shouldShowLevelsInfo && currentPracticeItem">
                    <question-level-labels
                      :levels="currentQuestion.attributes.levels"
                      :relevant-group="subjectClassLevelGroup"
                    />
                  </div>
                  <question-tags
                    v-if="subject && currentPracticeItem"
                    :tags="currentQuestion.tags"
                    :subject-id="subject.id"
                  />
                </div>
              </div>
              <div
                v-if="currentPracticeItem"
                class="margin-top-s"
              >
                <question-container
                  :display-mode="displayMode"
                  :question="
                    isQuestionSubmitted
                      ? currentQuestionWithSolutionFromPracticeItem
                      : getCurrentQuestion
                  "
                  @response="saveAnswer"
                  @change="handleChange"
                />
              </div>
              <div class="clearfix text-right margin-top-m">
                <kog-loader
                  v-if="isSavingAssignment"
                  :loading="isSavingAssignment"
                  loading-msg="Saving answer..."
                />
                <span
                  v-else-if="answerStrategy.hasAnsweredCurrentQuestion"
                  class="muted"
                  >Answer saved</span
                >
                <kog-loader
                  v-if="isSubmittingQuestionAnswer"
                  :loading="isSubmittingQuestionAnswer"
                  loading-msg="Submitting answer..."
                />
              </div>
            </template>
          </template>
        </kog-loader>
      </template>
      <template #footer>
        <report-feedback-button
          v-if="currentPracticeItem && !solution"
          class="padd-top-m"
          :subject-node-id="subjectNodeRootId"
          :disabled="isTeacherCreatedQuestion"
          :tooltip="feedbackButtonTooltip"
          label="Report a problem"
          :content-id="currentQuestion.id"
        />
      </template>
    </kog-default-study-page>
    <kog-default-study-page
      v-if="solution && isAssignmentOccasionLoaded"
      class="margin-top-s"
    >
      <template #body>
        <div
          class="margin-bottom-m flexContainer flexContainer-alignCenter flexContainer-spaceBetween"
        >
          <h2 class="heading-s"> Correct answer </h2>
        </div>
        <div class="margin-top-s">
          <question-preview
            class="margin-top-s"
            :question="currentQuestionWithSolution"
            :is-showing-question-text="false"
            :is-showing-incorrect-mcq-options="false"
            :is-showing-explanation="true"
          />
        </div>
      </template>
      <template #footer>
        <report-feedback-button
          class="padd-top-m"
          :subject-node-id="subjectNodeRootId"
          :disabled="isTeacherCreatedQuestion"
          :tooltip="feedbackButtonTooltip"
          label="Report a problem"
          :content-id="currentQuestion.id"
        />
      </template>
    </kog-default-study-page>
    <Teleport to="#StudyAppMain-bottomMenu--portal">
      <question-assignment-bottom-navbar
        v-if="!isIntroVisible && isAssignmentOccasionLoaded"
        :display-state="answerStrategy.currentDisplayState"
        :has-previous="hasPreviousPracticeItem"
        :has-next="hasNextPracticeItem"
        :has-submitted-all-questions="answerStrategy.hasSubmittedAllQuestions"
        :has-answered-all-questions="answerStrategy.hasAnsweredAllQuestions"
        :next-queued="nextButtonQueued"
        :previous-queued="prevButtonQueued"
        :submit-queued="submitButtonQueued"
        :is-submit-disabled="answerStrategy.isSubmitDisabled"
        @previous="answerStrategy.handlePrevious"
        @next="answerStrategy.handleNext"
        @submit="showSubmitAssignmentConfirmationModal"
      />
    </Teleport>
  </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import { useHead } from '@unhead/vue';
import { VTooltip } from 'floating-vue';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState, useStore } from 'vuex';

import { DisplayAnswersSettingEnumEnum } from '@apis/generated/models.ts';

import QuestionLevelLabels from 'learning/common/components/question-level-labels.vue';
import ReportFeedbackButton from 'learning/common/components/report-feedback-button.vue';
import { getIntegrationProviderDisplayName } from 'learning/common/services/integration/integration-service.js';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogBallStepPaginator from 'sharedApp/components/base/pagination/kog-ball-step-paginator.vue';
import FullpageTakeoverNavbar from 'sharedApp/components/core/fullpage-takeover-navbar.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import KogInfoModal from 'sharedApp/components/modals/kog-info-modal.vue';
import QuestionContainer from 'sharedApp/components/question-container/question-container.vue';
import {
  QUESTION_DISPLAY_MODE,
  useQuestionContextProvide,
} from 'sharedApp/composables/question-context/index.ts';
import { IGCSE_LEVELS } from 'sharedApp/const/level-groups.js';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { shouldShowDifficultyTagForSubject } from 'sharedApp/services/featureflags/fixed-feature-flags.ts';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import {
  CREATED_BY_TEACHER_PLAIN_TEXT_TAG,
  CREATED_BY_TEACHER_TAG,
  extractSolutionFromPracticeItem,
  extractUserResponseWithCorrectnessFromPracticeItem,
  getContentWithoutStars,
  questionPreviewSolutionFormat,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import KogDefaultStudyPage from 'studyApp/components/kog-default-study-page.vue';
import QuestionPreview from 'studyApp/components/questions/question-preview.vue';
import QuestionTags from 'studyApp/components/questions/question-tags.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';
import QuestionDifficultyMixin from 'studyApp/mixins/question-difficulty-mixin.js';
import { isAnswered } from 'studyApp/utils/practice-item-utils.js';

import CompleteQuestionAssignmentModal from './complete-question-assignment-modal.vue';
import AssignmentTakeOverview from './components/assignment-take-overview.vue';
import QuestionAssignmentBottomNavbar from './components/question-assignment-bottom-navbar.vue';
import useAssignmentAnswerStrategy from './composables/use-assignment-answer-strategy.ts';

export default defineComponent({
  name: 'QuestionAssignmentTake',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    AssignmentTakeOverview,
    KogBallStepPaginator,
    KogDefaultStudyPage,
    KogLoader,
    ReportFeedbackButton,
    QuestionLevelLabels,
    QuestionTags,
    QuestionContainer,
    FullpageTakeoverNavbar,
    QuestionAssignmentBottomNavbar,
    QuestionPreview,
  },
  mixins: [RoutesMixin, QuestionDifficultyMixin],
  setup() {
    const store = useStore();
    const { assignmentModule, subjectModule } = store.state;

    const assignmentOccasion = computed(() => assignmentModule?.assignment);
    const assignment = computed(() => assignmentOccasion.value?.assignment);

    const subjectName = computed(() => subjectModule.subject?.name);
    const assignmentName = computed(() => assignment.value?.name);
    const { integrationProvider } = useIntegrationAssignment();

    useHead({
      title: () => `${assignmentName.value} | Take question assignment | ${subjectName.value}`,
    });

    const { proxy: component } = getCurrentInstance();
    useQuestionContextProvide({
      retrieveResponses: question => component.retrieveResponses(question),
      context: {
        FBQ: { autoSave: true },
        STQ: { autoSave: true },
      },
    });

    const answerStrategy = ref(null);
    answerStrategy.value = useAssignmentAnswerStrategy({
      next: () => component.goToNextPracticeItem(),
      previous: () => component.goToPreviousPracticeItem(),
    });

    return {
      assignment,
      assignmentOccasion,
      answerStrategy,
      integrationProvider,
    };
  },
  data() {
    return {
      isIntroVisible: true,
      nextButtonQueued: false,
      prevButtonQueued: false,
      submitButtonQueued: false,
      navigationEnabled: true,
      deadlineIntervalId: null,
      shownDeadlineDialogLevel: -1,
      showDifficulty: false,
    };
  },
  computed: {
    ...mapState('userModule', {
      user: state => state.user,
    }),
    ...mapState('subjectModule', {
      subject: state => state.subject,
    }),
    ...mapGetters('assignmentModule', [
      'currentPracticeItem',
      'currentPracticeItemIndex',
      'getQuestionByUuid',
    ]),
    solution() {
      return this.currentPracticeItem?.solution;
    },
    currentQuestionWithSolution() {
      const { solution, getCurrentQuestion: question } = this;

      if (!solution) {
        return question;
      }

      return questionPreviewSolutionFormat(question, solution);
    },
    currentQuestionWithSolutionFromPracticeItem() {
      const question = this.getCurrentQuestion;
      const solution = extractSolutionFromPracticeItem(question, this.currentPracticeItem);

      return {
        ...question,
        solution,
      };
    },
    displayMode() {
      const { USER_RESPONSE, USER_RESPONSE_LOCKED_WITH_CORRECTNESS } = QUESTION_DISPLAY_MODE;

      if (this.currentPracticeItem.is_submitted) {
        return USER_RESPONSE_LOCKED_WITH_CORRECTNESS;
      }
      return USER_RESPONSE;
    },
    currentPageNumber() {
      return this.currentPracticeItemIndex + 1;
    },
    source() {
      return this.$route.query.source || '';
    },
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    isSubmittingQuestionAnswer() {
      return this.$wait.is('submitting_question_answer');
    },
    isQuestionSubmitted() {
      return this.currentPracticeItem.is_submitted;
    },
    isAssignmentDone() {
      return this.assignmentOccasion.practice_occasion.is_done;
    },
    hasDeadlinePassed() {
      return this.assignment.has_deadline_passed && !this.assignment.soft_deadline;
    },
    practiceItems() {
      return this.assignmentOccasion.practice_occasion.practice_items || [];
    },
    isAssignmentOccasionLoaded() {
      return Boolean(this.assignmentOccasion);
    },
    hasNextPracticeItem() {
      return this.assignmentOccasion.current_practice_item_index + 1 < this.practiceItems.length;
    },
    hasPreviousPracticeItem() {
      return this.assignmentOccasion.current_practice_item_index > 0;
    },
    isSavingAssignment() {
      return this.$wait.is('saving_question_answer');
    },
    getCurrentQuestion() {
      const question = this.assignmentOccasion.assignment.questions.find(
        q => q.id === this.currentPracticeItem.question_id,
      );

      if (this.showDifficulty) {
        return question;
      }

      return { ...question, question_html: getContentWithoutStars(question.question_html) };
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subject.possible_levels);
    },
    subjectClassLevelGroup() {
      return IGCSE_LEVELS;
    },
    feedbackButtonTooltip() {
      return this.isTeacherCreatedQuestion ? "Feedback to teachers' questions is disabled" : '';
    },
    subjectNodeRootId() {
      return this.subject?.subject_tree[0].id;
    },
    currentQuestion() {
      return this.getQuestionByUuid(this.currentPracticeItem.question_uuid);
    },
    isTeacherCreatedQuestion() {
      return (
        this.currentQuestion.tags.includes(CREATED_BY_TEACHER_TAG) ||
        this.currentQuestion.tags.includes(CREATED_BY_TEACHER_PLAIN_TEXT_TAG)
      );
    },
    hasSubmittedAllQuestions() {
      return this.answerStrategy.hasSubmittedAllQuestions;
    },
  },
  watch: {
    hasSubmittedAllQuestions: {
      handler() {
        this.checkShouldShowCompleteAssignmentModal();
      },
    },
  },
  async created() {
    this.$wait.start('loading_assignment');
    await this.getAssignment(this.assignmentId);
    if (!this.classId) {
      const {
        id: assignmentId,
        subject_class_id: classId,
        subject_class_slug: classSlug,
      } = this.assignment;
      await this.$router.replace({
        name: 'classQuestionAssignmentTake',
        params: {
          assignmentId,
          classSlug,
          sid: `${this.subject.id}`,
          cid: `${classId}`,
        },
      });
    }
    if (this.isAssignmentDone) {
      this.goToAssignmentResult();
      return;
    }
    if (this.hasDeadlinePassed) {
      this.$modal(
        KogInfoModal,
        {
          title: 'Deadline passed',
          text:
            'Sorry, but the deadline to hand in this assignment has passed. ' +
            'You can no longer submit this assignment.',
          onModalClose: this.goToAssignmentList,
        },
        { closeOnEsc: false },
      );
      return;
    }
    this.$wait.end('loading_assignment');
    this.showDifficulty = shouldShowDifficultyTagForSubject(this.subject.id);
    this.checkShouldShowCompleteAssignmentModal();
  },
  unmounted() {
    this.stopDeadlineWatch();
  },
  methods: {
    ...mapActions('assignmentModule', [
      'getAssignment',
      'startQuestionAssignment',
      'setCurrentPracticeItem',
      'setCurrentPracticeItemAnswer',
    ]),
    ...mapWaitingActions('assignmentModule', {
      submitAssignment: 'submitting_assignment',
      saveQuestionAnswer: 'saving_question_answer',
    }),
    // eslint-disable-next-line vue/no-unused-properties
    retrieveResponses(question) {
      if (this.currentPracticeItem.question_id === question.id) {
        return extractUserResponseWithCorrectnessFromPracticeItem(
          question,
          this.currentPracticeItem,
        );
      }
      throw new Error('misalignment between practice item and retrieve responses');
    },
    async startAssignment() {
      await this.startQuestionAssignment();
      await this.setCurrentPracticeItem(this.getFirstUnansweredPracticeItem());
      this.isIntroVisible = false;
      this.startDeadlineWatch();
      this.trackMixpanel('Assignment - Open');
    },
    trackMixpanel(eventName) {
      const props = {
        assignment_type: this.assignment.assignment_type.toLowerCase(),
        num_subject_classes: this.assignment.subject_classes.length,
        source: this.source,
      };
      this.$mixpanel.trackEventViaBackend(eventName, props);
    },
    goToNextPracticeItem() {
      if (!this.hasNextPracticeItem) {
        return;
      }
      if (this.isSavingAssignment) {
        this.nextButtonQueued = true;
      } else {
        const { currentPracticeItemIndex } = this;
        this.setCurrentPracticeItem(currentPracticeItemIndex + 1);
      }
    },
    goToPreviousPracticeItem() {
      if (!this.hasPreviousPracticeItem) {
        return;
      }
      if (this.isSavingAssignment) {
        this.prevButtonQueued = true;
      } else {
        const { currentPracticeItemIndex } = this;
        this.setCurrentPracticeItem(currentPracticeItemIndex - 1);
      }
    },
    getFirstUnansweredPracticeItem() {
      const items = this.assignmentOccasion.practice_occasion.practice_items;
      const index = items.findIndex(
        item => !isAnswered(item, this.getQuestionByUuid(item.question_uuid)),
      );
      return index > -1 ? index : 0;
    },
    deadlineWatcher() {
      if (!this.assignment) {
        return;
      }

      const assignmentDeadline = new Date(this.assignment.deadline);

      const now = Date.now();
      const oneHour = 1000 * 60 * 60;
      const twentyMinutes = 1000 * 60 * 20;
      const fiveMinutes = 1000 * 60 * 5;
      const deadline = assignmentDeadline.getTime();

      let message;
      let title;

      if (now > deadline) {
        this.stopDeadlineWatch(this.deadlineWatcher);
        if (this.assignment.soft_deadline) {
          return;
        }
        message =
          "It seems that the deadline for this assignment has passed, if that's the case, you'll not be able to submit it. " +
          'Otherwise your computer can have the wrong date set, please check your computer time settings.';
        title = 'Deadline passed!';
        this.shownDeadlineDialogLevel = 3;
      } else if (now + fiveMinutes > deadline && this.shownDeadlineDialogLevel < 3) {
        message = 'Less than 5 minutes left until the deadline. You can do it!';
        title = 'Submit your assignment!';
        this.shownDeadlineDialogLevel = 3;
      } else if (now + twentyMinutes > deadline && this.shownDeadlineDialogLevel < 2) {
        message =
          'You are almost at the deadline for this assignment! ' +
          'Less than 20 minutes remain until it cannot be submitted anymore.';
        title = 'Deadline in 20 minutes!';
        this.shownDeadlineDialogLevel = 2;
      } else if (now + oneHour > deadline && this.shownDeadlineDialogLevel < 1) {
        message =
          'The deadline for this assignment is approaching! ' +
          'You have less than 60 minutes to complete it. Time to hurry up!';
        title = 'Deadline approaching!';
        this.shownDeadlineDialogLevel = 1;
      } else return;

      this.$modal(KogInfoModal, {
        title,
        text: message,
      });
    },
    startDeadlineWatch() {
      this.deadlineIntervalId = setInterval(this.deadlineWatcher, 1000);
    },
    stopDeadlineWatch() {
      clearInterval(this.deadlineIntervalId);
    },
    handleChange({ user_response: userResponse }) {
      this.setCurrentPracticeItemAnswer(userResponse);
    },
    goToAssignmentOverview() {
      this.$router.push({
        name: 'classAssignmentsOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
      });
    },
    async saveAnswer({ question_uuid: questionUuid, user_response: userResponse, enterPressed }) {
      if (this.currentPracticeItem?.question_uuid !== questionUuid) {
        return;
      }

      const isDisplayAnswerImmediately =
        this.assignment.display_answers_setting === DisplayAnswersSettingEnumEnum.IMMEDIATELY;

      if (this.currentPracticeItem && enterPressed && !isDisplayAnswerImmediately) {
        this.nextButtonQueued = true;
      }

      try {
        this.setCurrentPracticeItemAnswer(userResponse);
        await this.saveQuestionAnswer();
      } catch (error) {
        this.$toast.showError(
          'Saving attempt failed. All attempts to save prior to this point have been successful.',
        );
        throw error;
      }
      if (this.nextButtonQueued) {
        this.nextButtonQueued = false;
        this.goToNextPracticeItem();
      }
      if (this.prevButtonQueued) {
        this.prevButtonQueued = false;
        this.goToPreviousPracticeItem();
      }
      if (this.submitButtonQueued) {
        this.submitButtonQueued = false;
        this.showSubmitAssignmentConfirmationModal();
      }
    },
    showSubmitAssignmentConfirmationModal() {
      if (this.isSavingAssignment) {
        this.submitButtonQueued = true;
        return;
      }
      let confirmationText =
        'Are you sure that you want to submit your assignment?' +
        ' You cannot modify your answers after doing so.';
      if (this.assignment.is_sent_to_integration) {
        confirmationText += `\n\nThe assignment will be submitted to ${getIntegrationProviderDisplayName(this.integrationProvider)}`;
      }
      this.$modal(KogConfirmModal, {
        title: 'Submit your assignment?',
        text: confirmationText,
        buttonTitle: 'Submit assignment',
        buttonClass: 'KogButtonLegacy--primary',
        onConfirm: async () => {
          await this.submitAssignment();
          this.trackMixpanel('Assignment - Complete');
          intercomModule.trackEvent('completed-question-assignment');
          this.goToAssignmentResult();
        },
      });
    },
    checkShouldShowCompleteAssignmentModal() {
      if (this.answerStrategy.hasSubmittedAllQuestions) {
        this.$modal(CompleteQuestionAssignmentModal, {
          onConfirm: async () => {
            await this.submitAssignment();
            this.trackMixpanel('Assignment - Complete');
            intercomModule.trackEvent('completed-question-assignment');
            this.goToAssignmentResult();
          },
        });
      }
    },
    goToAssignmentResult() {
      this.$router.push({
        name: 'classQuestionAssignmentResult',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignmentId,
        },
        query: { source: 'assignment_list' },
      });
    },
    goToAssignmentList() {
      this.$router.push({
        name: 'classAssignmentsOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
      });
    },
    goToQuestion(pageNumber) {
      if (!this.navigationEnabled || this.nextButtonQueued || this.prevButtonQueued) {
        return;
      }

      this.setCurrentPracticeItem(pageNumber - 1);
    },
  },
});
</script>
