<template>
  <div class="EndorserLogo">
    <component
      :is="link ? 'a' : 'span'"
      :href="link ? endorserWebsite : null"
    >
      <img
        :src="logoUrl"
        :alt="logoAltText"
        class="EndorserLogo-Image"
      />
    </component>
  </div>
</template>
<script>
const darkTextLogoIGCSE = require('learning/common/images/cambridgeLogoFullEndorsed.png');
const lightTextLogoIGCSE = require('learning/common/images/cambridgeLogoFullEndorsedWhite.png');

const darkTextLogoIBDP = require('learning/common/images/IBLogoEndorsed.png');
const lightTextLogoIBDP = require('learning/common/images/IBLogoEndorsedWhite.png');

function IGCSEendorsementData() {
  return {
    darkTextLogo: darkTextLogoIGCSE,
    lightTextLogo: lightTextLogoIGCSE,
    endorserName: 'Cambridge Assessment endorser website link',
    website: 'https://www.cambridgeinternational.org',
  };
}

function IBDPendorsementData() {
  return {
    darkTextLogo: darkTextLogoIBDP,
    lightTextLogo: lightTextLogoIBDP,
    endorserName: 'International Baccalaureate endorser website link',
    website: 'https://www.ibo.org',
  };
}

export default {
  name: 'EndorserLogoBadge',
  props: {
    educationSystemName: {
      type: String,
      required: true,
      validator(value) {
        return ['IGCSE', 'IBDP'].includes(value);
      },
    },
    darkText: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    endorsementData() {
      if (this.educationSystemName === 'IBDP') {
        return IBDPendorsementData();
      }
      return IGCSEendorsementData();
    },
    logoAltText() {
      const { endorserName } = this.endorsementData;
      return this.link ? endorserName : '';
    },
    logoUrl() {
      const { darkTextLogo, lightTextLogo } = this.endorsementData;
      return this.darkText ? darkTextLogo : lightTextLogo;
    },
    endorserWebsite() {
      return this.endorsementData.website;
    },
  },
};
</script>
<style>
.EndorserLogo-Image {
  max-width: 100%;
}
</style>
