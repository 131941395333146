<template>
  <div class="AssignmentTakeOverview">
    <div class="AssignmentTakeOverview-box padd-l">
      <integration-assignment-alert class="margin-bottom-m" />
      <h1 class="text-center heading-m">
        <assignment-type-icon
          :assignment-type="assignment.assignment_type"
          size="m"
          :show-tooltip="false"
          aria-hidden="true"
          theme="custom"
        />
        {{ htmlEntities(assignment.name) }}
      </h1>
      <div
        v-if="assignment.is_sent_to_google_classroom && isExamStyle && isDone"
        class="margin-top-xl margin-bottom-xl"
      >
        <google-classroom-reminder />
      </div>
      <h2 class="heading-xxs margin-top-l"> Instructions: </h2>
      <p class="muted">
        {{ assignment.message || 'None' }}
      </p>
      <div v-if="isExamStyle">
        <h2 class="heading-xxs margin-top-l">
          {{ esqTerm }}
        </h2>
        <div
          v-for="(question, index) in assignment.questions"
          :key="index"
          class="flexContainer muted margin-top-xxs"
        >
          <div class="AssignmentTakeOverview-esqTableColumn"> Question {{ index + 1 }} </div>
          <div
            v-if="hasMarks(question)"
            class="AssignmentTakeOverview-esqTableColumn"
          >
            {{ marksInfo(question) }}
          </div>
          <div
            v-if="shouldShowLevelsInfo"
            class="AssignmentTakeOverview-esqTableColumn"
          >
            <question-level-labels
              :levels="question.attributes.levels"
              :relevant-group="subjectClassLevelGroup"
              class="body-text"
            />
          </div>
        </div>
      </div>

      <p
        v-if="isQuestion"
        class="heading-xxs margin-top-l"
      >
        {{ pluralize('question', assignment.question_count, true) }}
      </p>

      <h2 class="heading-xxs margin-top-l"> Deadline </h2>
      <p v-text="deadline" />

      <h2 class="heading-xxs margin-top-l"> Settings </h2>
      <!-- eslint-disable vue/no-v-html -->
      <p
        v-if="isQuestion"
        v-html="questionAssignmentSettingsInfo"
      />
      <!-- eslint-enable vue/no-v-html -->

      <esq-markscheme-status
        v-if="isExamStyle && isMarkschemeVisible"
        class="margin-top-l"
        :status="assignment.mark_scheme_status"
        :teacher-name="assignment.created_by"
        :assignment-name="assignment.name"
      />
      <div
        v-if="isExamStyle && assignment.is_sent_to_integration"
        class="margin-top-l flexContainer flexContainer-alignCenter"
      >
        <submit-to-integration-button
          v-if="canSubmitIntegrationAssignment"
          class="margin-right-s"
          button-style="accent"
          :assignment="assignment"
          :has-submitted-to-integration="hasSubmittedToIntegration"
        />
        <integration-assignment-link :integration-provider="integrationProvider" />
      </div>
      <p class="text-center margin-top-xxl">
        <kog-button
          label="Take the assignment now!"
          button-style="primary"
          :disabled="isTakeAssignmentButtonDisabled"
          @click.once="onTakeAssignmentNowClick"
        />
      </p>
      <p class="text-center margin-top-s">
        <router-link
          :to="goToAssignmentsOverview()"
          class="KogButtonLegacy KogButtonLegacy--link KogButtonLegacy--s margin-top-s"
        >
          See all your assignments, past and present.
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';
import pluralize from 'pluralize';
import { mapState } from 'vuex';

import AssignmentTypeIcon from 'learning/common/components/assignment-type-icon.vue';
import QuestionLevelLabels from 'learning/common/components/question-level-labels.vue';
import htmlEntities from 'learning/common/libs/html-entities.js';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import useAssignmentReadableSettings from 'sharedApp/composables/use-assignment-readable-settings.ts';
import { IGCSE_LEVELS } from 'sharedApp/const/level-groups.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import {
  isExamStyleAssignmentDone,
  isExamStyleType,
  isQuestionType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { hasEducationSystemMarkscheme } from 'sharedApp/services/educationSystem/education-system-service.js';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import IntegrationAssignmentAlert from 'studyApp/components/assignment/integration-assignment-alert.vue';
import IntegrationAssignmentLink from 'studyApp/components/assignment/integration-assignment-link.vue';
import GoogleClassroomReminder from 'studyApp/components/google-classroom-reminder/google-classroom-reminder.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';
import QuestionMixin from 'studyApp/mixins/question-mixin.js';
import SubmitToIntegrationButton from 'studyApp/pages/assignment/components/submit-to-integration-button.vue';

import EsqMarkschemeStatus from './esq-markscheme-status.vue';

export default {
  name: 'AssignmentTakeOverview',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    IntegrationAssignmentLink,
    SubmitToIntegrationButton,
    AssignmentTypeIcon,
    QuestionLevelLabels,
    EsqMarkschemeStatus,
    GoogleClassroomReminder,
    KogButton,
    IntegrationAssignmentAlert,
  },
  mixins: [RoutesMixin, QuestionMixin],
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  emits: ['take-assignment'],
  setup() {
    const { loadIntegrationAssignment, warningMessage, integrationProvider } =
      useIntegrationAssignment();
    return {
      loadIntegrationAssignment,
      integrationWarningMessage: warningMessage,
      integrationProvider,
    };
  },
  data: () => ({
    isTakeAssignmentButtonDisabled: false,
  }),
  computed: {
    ...mapState('subjectModule', {
      subject: state => state.subject,
    }),
    ...mapState('userModule', {
      user: state => state.user,
    }),
    deadline() {
      return dateTimeFormatter(new Date(this.assignment.deadline));
    },
    isQuestion() {
      return isQuestionType(this.assignment.assignment_type);
    },
    isExamStyle() {
      return isExamStyleType(this.assignment.assignment_type);
    },
    esqTerm() {
      const questionsCount = this.assignment.questions.length;
      const term = `${questionsCount} ${this.$term('exam-style').toLowerCase()} questions`;
      return questionsCount === 1 ? term.slice(0, -1) : term;
    },
    isDone() {
      return this.isExamStyle && isExamStyleAssignmentDone(this.assignment);
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subject.possible_levels);
    },
    subjectClassLevelGroup() {
      return IGCSE_LEVELS;
    },
    educationSystem() {
      return this.subject.educationsystem.name;
    },
    isMarkschemeVisible() {
      return hasEducationSystemMarkscheme(this.educationSystem);
    },
    canSubmitIntegrationAssignment() {
      return (
        this.isExamStyle &&
        this.isDone &&
        this.assignment.is_sent_to_integration &&
        !this.integrationWarningMessage
      );
    },
    questionAssignmentSettingsInfo() {
      let info = `
        Your responses are automatically saved, but you need to
        <strong>submit your assignment</strong> to your teacher when you are done!
      `;

      if (this.isDisplayAnswersImmediatelyEnabled) {
        info = `
          You can see the answer after each completed question.
          A submitted response can’t be changed.
          You need to <strong>submit your assignment</strong> to your teacher
          when you are done!
        `;
      }

      return info;
    },
    isDisplayAnswersImmediatelyEnabled() {
      const { answers } = useAssignmentReadableSettings(this.assignment);
      return answers.displayImmediately;
    },
    hasSubmittedToIntegration() {
      return this.assignment?.is_submitted_to_integration;
    },
  },
  created() {
    this.loadIntegrationAssignment(this.assignment, this.classId);
  },
  methods: {
    pluralize,
    goToAssignmentsOverview() {
      return {
        name: 'classAssignmentsOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
      };
    },
    onTakeAssignmentNowClick() {
      this.$emit('take-assignment');
      this.isTakeAssignmentButtonDisabled = true;
    },
    hasMarks(question) {
      return !!question.context?.marks || question.marks;
    },
    htmlEntities,
  },
};
</script>

<style scoped>
.AssignmentTakeOverview {
  padding: var(--space-l) var(--space-xxl);
  background:
    no-repeat -12px -36px url('~sharedApp/img/kognity-sparkles-left.svg'),
    no-repeat calc(100% + 20px) 100% url('~sharedApp/img/kognity-sparkles-right.svg');
}

.AssignmentTakeOverview-box {
  margin: var(--space-xs) var(--space-xxl);
  background: var(--kogPlatformWhite);
  border: 1px solid var(--kogPlatformGray084);
  border-radius: 4px;
}

.AssignmentTakeOverview-esqTableColumn {
  flex: 0 0 100px;
}
</style>
