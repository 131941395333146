<template>
  <div
    class="ContentNodeIcon flexContainer flexContainer-alignCenter flexContainer-justifyCenter"
    :class="backgroundColorClass"
  >
    <kog-icon
      class="ContentNodeIcon-icon"
      :icon-class="iconClass"
      :fa-style="iconStyle"
      :size="iconSize"
      :is-disabled="false"
      :is-svg-icon="!iconClass.startsWith('fa-')"
    />
  </div>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'ContentNodeIcon',
  components: {
    KogIcon,
  },
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      default: 's',
      validator: prop => ['s', 'm'].includes(prop),
    },
    iconStyle: {
      type: String,
      default: 'regular',
      validator: prop => ['solid', 'regular', 'light'].includes(prop),
    },
    backgroundColor: {
      type: String,
      default: () => 'purple',
      validator: prop => ['purple', 'orange', 'pink'].includes(prop),
    },
  },
  computed: {
    backgroundColorClass() {
      return `ContentNodeIcon--${this.backgroundColor}`;
    },
  },
};
</script>

<style scoped>
.ContentNodeIcon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.ContentNodeIcon--purple {
  background-color: var(--kog-colors-aubergine-300);
}

.ContentNodeIcon--orange {
  background-color: var(--kog-colors-orange-300);
}

.ContentNodeIcon--pink {
  background-color: var(--kog-colors-pink-300);
}

.ContentNodeIcon-icon {
  color: var(--kogPlatformGray009);
}

.ContentNodeIcon-icon :deep(svg) {
  /* stylelint-disable suitcss/custom-property-no-outside-root */
  --shape-color: var(--kog-colors-grey-900);
}
</style>
