export const ASSIGNMENT_LIST_TYPES = {
  ARCHIVED: 'archived',
  CURRENT: 'current',
  DRAFT: 'draft',
  PAST: 'past',
};

export const ASSIGNMENT_TABLE_SORT_BY_OPTIONS = {
  name: 'name',
  deadline: 'deadline',
  sent: 'sent',
  subject_class: 'subject_class',
  type: 'type',
  created_at: 'created_at',
  archived_at: 'archived_at',
};

export const SORT_ORDERS = {
  asc: 'asc',
  desc: 'desc',
};

export function setPreferredDeadlineHour(deadline, userId, subjectClassId) {
  localStorage.setItem(`deadline_preferred_hour-${userId}-${subjectClassId}`, deadline.getHours());
  localStorage.setItem(
    `deadline_preferred_minute-${userId}-${subjectClassId}`,
    deadline.getMinutes(),
  );
}

export function isPossibleToEndNow(assignment) {
  const now = new Date();
  const deadlineDate = new Date(assignment.deadline);
  return deadlineDate > now && !(assignment.scheduled_sent_at && !assignment.sent_at);
}

export function getShareableAssignmentLink(assignmentId) {
  const path = `/redirect/assignments/${assignmentId}/`;
  const url = new URL(path, window.location.origin);
  return url.href;
}
