<template>
  <div class="form-inline">
    <kog-radio-group
      v-model:selected-value="opponent.type"
      :options="opponentOptions"
    />
    <div>
      <div
        v-if="opponent.type === BATTLE_TYPES.classmate"
        class="form-group margin-top-s margin-left-s"
      >
        <select
          v-model="opponent.selectedClassmate"
          class="form-control"
          aria-label="Choose a classmate to battle"
        >
          <option value=""> -- Choose a classmate -- </option>
          <option
            v-for="(classmate, idx) in classmates"
            :key="idx"
            :value="idx"
          >
            {{ classmate.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import KogRadioGroup from 'sharedApp/components/base/radio/kog-radio-group.vue';
import { BATTLE_TYPES } from 'studyApp/api/battle.js';

export default {
  name: 'PickOpponent',
  components: {
    KogRadioGroup,
  },
  props: {
    value: {
      default() {
        return {
          type: BATTLE_TYPES.random,
          selectedClassmate: '',
        };
      },
      type: Object,
    },
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      opponent: { ...this.value },
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      classmates: state => state.battlesModule.classmates,
    }),
    hasClassmates() {
      return this.classmates !== null && this.classmates.length > 0;
    },
    noClassmatesReasonText() {
      if (this.user.isTeacher) {
        return '(Only available for students)';
      }

      return '(No classmates in this subject)';
    },
    opponentOptions() {
      return [
        {
          text: 'Battle the Kogbot',
          value: BATTLE_TYPES.random,
        },
        {
          text: `Battle a classmate ${this.hasClassmates ? '' : this.noClassmatesReasonText}`,
          value: BATTLE_TYPES.classmate,
          disabled: !this.hasClassmates,
        },
      ];
    },
  },
  watch: {
    opponent: {
      deep: true,
      handler() {
        this.$emit('update:value', this.opponent);
        this.$emit('input', this.opponent);
      },
    },
  },
  created() {
    this.BATTLE_TYPES = BATTLE_TYPES;
  },
};
</script>
