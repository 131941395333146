<template>
  <dropdown
    ref="root"
    theme="kog-popover"
    :shown="open"
    :triggers="[]"
    boundary="document.body"
    class="inline-block"
    placement="top"
    @show="show"
    @hide="hide"
  >
    <button
      :id="glossaryLinkId"
      class="KogButtonLegacy--noStyle GlossaryLinks-link"
      :aria-expanded="open ? 'true' : 'false'"
      aria-haspopup="true"
      @click="toggle"
    >
      {{ linkedText }}
    </button>

    <template
      v-if="open"
      #popper
    >
      <transition name="fade">
        <glossary-popover
          v-if="definition"
          role="region"
          :aria-labelledby="glossaryLinkId"
          :term-text="termText"
          :definition-html="definitionHtml"
          :show-open-drawer="!isTermsListLoading"
          @open-glossary-drawer="onOpenGlossaryDrawer(definitionId)"
        />
        <glossary-popover
          v-else
          term-text=""
          definition-html="Definition not found."
          role="region"
          :aria-labelledby="glossaryLinkId"
          @open-glossary-drawer="onOpenGlossaryDrawer"
        />
      </transition>
    </template>
  </dropdown>
</template>

<script>
import { nextTick } from 'vue';
import { Dropdown } from 'floating-vue';
import { mapActions, mapMutations, mapState } from 'vuex';

import GlossaryPopover from 'studyApp/components/glossary/glossary-popover.vue';

export default {
  name: 'GlossaryLinks',
  components: { Dropdown, GlossaryPopover },
  props: {
    subject: {
      type: Object,
      default: null,
    },
    linkedText: {
      type: String,
      required: true,
    },
    definitionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapState({
      subjectId: state => state.subjectModule.subject.id,
      isTermsListLoading: state => state.glossaryV2Module.isTermsListLoading,
      definitionsById: state => state.glossaryV2Module.definitionsById,
    }),
    definition() {
      if (this.isTermsListLoading) {
        return {
          termText: '',
          definitionHtml: 'Glossary is loading...',
        };
      }
      return this.getDefinitionById(this.definitionId);
    },
    termText() {
      return this.definition.termText;
    },
    definitionHtml() {
      return this.definition.definitionHtml;
    },
    glossaryLinkId() {
      return `GlossaryLink_${this.subject.id}_${this.definitionId}`;
    },
  },
  methods: {
    ...mapMutations({
      setDefinitionToScrollTo: 'glossaryV2Module/setDefinitionToScrollTo',
    }),
    ...mapActions({
      openRailSlider: 'bookModule/openRailSlider',
    }),
    toggle() {
      this.open = !this.open;
      if (this.open) {
        this.trackEvent('Glossary V2 - Open term popover');
      }
    },
    show() {
      this.open = true;
    },
    hide() {
      this.open = false;
    },
    onOpenGlossaryDrawer(definitionId) {
      this.openRailSlider('glossary');
      this.trackEvent('Glossary V2 popover - Open glossary drawer');
      this.hide();
      if (definitionId) {
        nextTick(() => {
          this.setDefinitionToScrollTo(definitionId);
        });
      }
    },
    trackEvent(eventName) {
      this.$mixpanel.trackEventViaBackend(eventName, {
        glossary_term: this.termText,
      });
    },
    getDefinitionById(definitionId) {
      return this.definitionsById[definitionId];
    },
  },
};
</script>

<style scoped>
.GlossaryLinks-link {
  cursor: pointer;

  color: var(--kog-content-link-blue-2);
  text-align: inherit;
  text-decoration-color: var(--kogPlatformBlueBase);
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

.GlossaryLinks-link:hover {
  color: var(--kog-content-link-focus-blue-2);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
</style>
