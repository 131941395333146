import { ContextualBalloon } from '@ckeditor/ckeditor5-ui';

import boxOpenIcon from 'publishingApp/assets/icons/box-open-regular.svg';

import KogUI from '../utils/kogui.ts';
import { registerFactoryView } from '../utils/plugin-utils.ts';
import FormView from './any-box-form.js';

export default class AnyBoxUI extends KogUI {
  static get requires() {
    return [ContextualBalloon];
  }

  init() {
    this.balloon = this.editor.plugins.get(ContextualBalloon);
    this.formView = new FormView(this.editor.locale);

    const onSubmit = () => {
      const value = {
        boxType: this.formView.selectedBoxTypeKey,
        headingLevel: this.formView.selectedHeadingLevelKey,
      };
      this.editor.execute('insertAnyBox', value);
      this.hideUI();
    };

    this.setupFormView(onSubmit);

    registerFactoryView(this.editor, {
      name: 'anybox',
      commandName: 'insertAnyBox',
      icon: boxOpenIcon,
      label: 'Anybox (deprecated)',
      onExecute: () => this.showUI(),
    });

    const updateCommand = this.editor.commands.get('updateAnyBox');
    this.listenTo(updateCommand, 'execute', () => {
      this.showUI();
    });
  }

  showUI() {
    this.showBallonEditor();
    this.formView.focus();

    const commandValue = this.editor.commands.get('insertAnyBox').value;
    if (commandValue) {
      const { boxType, headingLevel } = commandValue;
      this.formView.setFields(boxType, headingLevel);
    }
  }
}
