<template>
  <div>
    <kog-skeleton
      v-if="isLoading"
      height="32px"
      width="120px"
    />
    <a
      v-else-if="integrationAssignmentUrl"
      class="IntegrationAssignmentLink flexContainer flexContainer-row flexContainer-center padd-xxs"
      :href="integrationAssignmentUrl"
      target="_blank"
    >
      <image-box
        v-if="integrationLinkLogo"
        class="IntegrationAssignmentLink-icon"
        :src="integrationLinkLogo"
        :w="24"
        :h="24"
      />
      <span class="text-medium margin-left-xs">
        {{ linkCopy }}
      </span>
    </a>
  </div>
</template>

<script>
import {
  getIntegrationProviderDisplayName,
  getIntegrationProviderLogo,
} from 'learning/common/services/integration/integration-service.js';

import KogSkeleton from 'sharedApp/components/base/skeleton/kog-skeleton.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';

export default {
  name: 'IntegrationAssignmentLink',
  components: {
    KogSkeleton,
    ImageBox,
  },
  props: {
    integrationProvider: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { url, isLoading } = useIntegrationAssignment();

    return {
      integrationAssignmentUrl: url,
      isLoading,
    };
  },
  computed: {
    integrationProviderDisplayName() {
      return getIntegrationProviderDisplayName(this.integrationProvider);
    },
    integrationLinkLogo() {
      return getIntegrationProviderLogo(this.integrationProvider);
    },
    linkCopy() {
      return `Open in ${this.integrationProviderDisplayName}`;
    },
  },
};
</script>

<style scoped>
.IntegrationAssignmentLink {
  overflow: hidden;
  background-color: var(--kogPlatformWhite);
  border-width: 1px;
  border-radius: 4px;
}
.IntegrationAssignmentLink:hover {
  color: var(--kog-button-default-label-hover);
  background: var(--kog-button-default-background-hover);
}
.IntegrationAssignmentLink-icon {
  width: var(--space-l);
  height: var(--space-l);
  background-color: var(--kogPlatformWhite);
}
.IntegrationAssignmentLink:hover > .IntegrationAssignmentLink-icon {
  background-color: var(--kog-button-default-background-hover);
}
</style>
