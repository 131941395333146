<template>
  <div
    class="ExerciseQuestionResultModal"
    role="dialog"
    aria-modal="true"
  >
    <kog-round-button
      class="positionAbsolute positionAbsolute-topRight margin-top-l margin-right-l"
      aria-label="Close modal"
      icon-class="fa-times"
      icon-style="solid"
      size="small"
      button-style="secondary-basic"
      @click.prevent="closeModal"
    />

    <div class="flexContainer flexContainer-column flexContainer-center">
      <div
        :class="[
          'ExerciseQuestionResultModal-score',
          {
            'ExerciseQuestionResultModal-score--incomplete': !isAllAnsweredCorrectly,
            'ExerciseQuestionResultModal-score--complete': isAllAnsweredCorrectly,
          },
        ]"
      >
        {{ correctAnswerCount }} / {{ numOfQuestions }}
        <div
          v-for="i in animationCircleAmount"
          :key="i"
          ref="animationCircle"
          class="u-circle"
        />
      </div>

      <span class="ExerciseQuestionResultModal-heading">
        {{ isAllAnsweredCorrectly ? 'Exercise completed!' : 'Almost there!' }}
      </span>
    </div>

    <div class="ExerciseQuestionResultModal-message">
      {{
        isAllAnsweredCorrectly
          ? 'All questions completed'
          : 'Answer all the questions correctly to complete the exercise.'
      }}
    </div>

    <template v-if="showSolution">
      <kog-item-list
        class="width-100"
        role="list"
        focusable-elements-selector=".ExerciseQuestionResult-wrapper"
      >
        <exercise-question-result
          v-for="(pi, index) in result"
          :key="pi.id"
          class="width-full"
          :practice-item="pi"
          :answered-practice-item="getAnsweredPracticeItem(pi)"
          :position="index + 1"
        />
      </kog-item-list>
    </template>

    <div
      class="flexContainer flexContainer-row flexContainer-flexEnd width-100 margin-top-l margin-bottom-xs"
    >
      <button
        v-if="!forceShowSolution"
        class="KogButtonLegacy u-borderless"
        @click="toggleSolution"
      >
        {{ showSolution ? 'Hide' : 'Show' }}
        {{ isAllAnsweredCorrectly ? 'explanations' : 'the solution' }}
      </button>
      <button
        class="KogButtonLegacy KogButtonLegacy--primary margin-left-xs"
        @click="closeModal"
      >
        {{ isAllAnsweredCorrectly ? 'Close exercise' : 'Complete the questions' }}
      </button>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.js';

import ExerciseQuestionResult from 'learning/common/components/exercise-question/exercise-question-result.vue';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';

const RESULT_CIRCLE_DIAMETER = 104;
const RESULT_CIRCLE_RADIUS = RESULT_CIRCLE_DIAMETER / 2;
const ANIMATION_CIRCLE_AMOUNT = 8;
const ANIMATION_CIRCLE_DIAMETER = 10;
const ANIMATION_CIRCLE_RADIUS = ANIMATION_CIRCLE_DIAMETER / 2;

export default {
  name: 'ExerciseQuestionResultModal',
  components: {
    ExerciseQuestionResult,
    KogItemList,
    KogRoundButton,
  },
  props: {
    closeModal: {
      type: Function,
      default: undefined,
    },
    result: {
      type: Array,
      required: true,
    },
    isAllAnsweredCorrectly: {
      type: Boolean,
      default: false,
    },
    answeredPracticeItems: {
      type: Array,
      required: true,
    },
    forceShowSolution: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSolution: false,
    };
  },
  computed: {
    correctAnswerCount() {
      return this.result.reduce((acc, current) => {
        if (current.user_answer.is_correct) {
          return acc + 1;
        }
        return acc;
      }, 0);
    },
    numOfQuestions() {
      return this.answeredPracticeItems.length;
    },
    animationCircleAmount() {
      return ANIMATION_CIRCLE_AMOUNT;
    },
  },
  async mounted() {
    this.setupResultAnimationCircles();
    await new Promise(r => {
      setTimeout(r, 300);
    });
    if (this.isAllAnsweredCorrectly) {
      this.animateResultCircles();
    }
  },
  created() {
    this.showSolution = this.forceShowSolution;
  },
  methods: {
    getAnsweredPracticeItem(practiceItem) {
      return this.answeredPracticeItems.find(pi => pi.question.id === practiceItem.question.id);
    },
    toggleSolution() {
      this.showSolution = !this.showSolution;
    },
    setupResultAnimationCircles() {
      const distance = RESULT_CIRCLE_RADIUS - 5;
      this.$refs.animationCircle.forEach((circle, index) => {
        const xPosition = distance * Math.sin(Math.PI * 2 * (index / ANIMATION_CIRCLE_AMOUNT));
        const yPosition = distance * Math.cos(Math.PI * 2 * (index / ANIMATION_CIRCLE_AMOUNT));
        /* eslint-disable no-param-reassign */
        circle.style.left = `${xPosition + RESULT_CIRCLE_RADIUS - ANIMATION_CIRCLE_RADIUS}px`;
        circle.style.top = `${-yPosition + RESULT_CIRCLE_RADIUS - ANIMATION_CIRCLE_RADIUS}px`;
        /* eslint-enable no-param-reassign */
      });
    },
    animateResultCircles() {
      const distance = RESULT_CIRCLE_RADIUS + 30;
      anime({
        targets: '.u-circle',
        opacity: [
          { value: 1, duration: 270 },
          { value: 0, duration: 30 },
        ],
        left: (el, index) => {
          const xPosition = distance * Math.sin(Math.PI * 2 * (index / ANIMATION_CIRCLE_AMOUNT));
          return xPosition + RESULT_CIRCLE_RADIUS - ANIMATION_CIRCLE_RADIUS;
        },
        top: (el, index) => {
          const yPosition = distance * Math.cos(Math.PI * 2 * (index / ANIMATION_CIRCLE_AMOUNT));
          return -yPosition + RESULT_CIRCLE_RADIUS - ANIMATION_CIRCLE_RADIUS;
        },
        easing: 'easeOutCubic',
        duration: 300,
      });
    },
  },
};
</script>

<style scoped>
.ExerciseQuestionResultModal {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 748px;
  margin: 10px auto;
  padding: var(--space-l);

  font-family: var(--kog-lato);
  color: var(--kogPlatformGray018);

  background: var(--kog-colors-grey-200);
  border-radius: 24px;
}

.ExerciseQuestionResultModal-score {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 104px;
  height: var(--study-menu-height);
  margin-top: var(--space-l);

  font-size: 32px;
  color: var(--kogPlatformGray018);

  border-radius: 50%;
}

.ExerciseQuestionResultModal-score--incomplete {
  background-color: var(--kog-content-purple-3);
}

.ExerciseQuestionResultModal-score--complete {
  background-color: var(--kogPlatformGreenBase);
}

.u-circle {
  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  background-color: var(--kogTransparent);
  border-radius: 50%;
}

.ExerciseQuestionResultModal-score--complete .u-circle {
  background-color: var(--kogPlatformGreenBase);
}

.ExerciseQuestionResultModal-heading {
  margin: var(--space-l) 0 var(--space-s);

  font-family: var(--kog-satoshi);
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
  color: var(--kogPlatformGray018);
}

.ExerciseQuestionResultModal-message {
  margin-bottom: var(--space-xxl);

  font-family: var(--kog-lato);
  font-size: var(--kog-font-size-content);
  line-height: 28px;
  color: var(--kogPlatformGray018);
}

.u-borderless {
  border: 0;
}
</style>
