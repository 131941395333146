import { MOBILE_BOTTOM_NAVBAR_HEIGHT_PX } from 'studyApp/components/mobile-bottom-navbar.vue';

const FullPageTableHeightMixin = {
  inject: ['mq'],
  computed: {
    tableMaxHeight() {
      const headerNavbarHeight = getComputedStyle(document.documentElement).getPropertyValue(
        '--full-page-navbar-height',
      );
      const pageFooterHeight = ['xs', 's'].includes(this.mq.current)
        ? MOBILE_BOTTOM_NAVBAR_HEIGHT_PX
        : 0;
      return `calc(100vh - ${headerNavbarHeight} - ${this.tableDistanceFromPageBottom}px - ${pageFooterHeight}px)`;
    },
  },
};

export default FullPageTableHeightMixin;
