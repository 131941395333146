<template>
  <div class="flexContainer flexContainer-column flexChild-size-1">
    <fullpage-takeover-navbar
      :back-button-aria-label="backButtonAriaLabel"
      :header-text="headerText"
      @back-button-click="onBackButtonClick"
    />
    <kog-loader
      class="flexContainer flexContainer-column flexChild-size-1"
      :loading="isLoading"
    >
      <div class="PerformanceTaskOverview kog-container">
        <h1 class="heading-m padd-top-xl padd-bottom-xs">{{ headerText }}</h1>
        <kog-tag
          aria-labelledby="chip-legend"
          is-chip
          icon-class="fa-pen-ruler"
          label="Performance task"
        />
        <div class="flexContainer flexContainer-spaceBetween padd-bottom-xl">
          <alignment-list
            :show-header="true"
            :syllabus-nodes="syllabusAlignments"
            class="margin-top-m margin-right-xxxl flexChild-noShrink"
          />
          <grading-progress-card
            class="margin-top-m"
            :class-students-count="countStudentsInClass"
            :graded-count-per-question="gradedCountPerQuestion"
            :students-submitted-count="studentsSubmittedCount"
            :responses-to-grade-count="responsesToGradeCount"
            :grades-to-send-count="gradesToSendCount"
            :updates-to-send-count="updatesToSendCount"
            @navigate-to-students-submissions="navigateToStudentSubmissions"
          />
        </div>
        <performance-task-overview-table
          :students-list="studentsList"
          :content-questions="contentQuestions"
          class="margin-top-xl"
          @navigate-to-student-submissions="navigateToStudentSubmissions"
        />
      </div>
    </kog-loader>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import { PERFORMANCE_TASK_MODULE } from 'publishingApp/store/modules/performance-task.js';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import FullpageTakeoverNavbar from 'sharedApp/components/core/fullpage-takeover-navbar.vue';
import posthog from 'sharedApp/libs/posthog.ts';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import GradingProgressCard from 'studyApp/components/activities/performance-tasks/grading-progress-card.vue';
import AlignmentList from 'studyApp/components/alignments/alignments-list.vue';
import PerformanceTaskOverviewTable from 'studyApp/components/performance-task-overview/pt-overview-table.vue';
import { QUESTION_ACTIVITY_MODULE } from 'studyApp/store/modules/question-activity.js';
import { SUBJECT_CLASS_STUDENTS_MODULE } from 'studyApp/store/modules/subject-class-students.js';

export default {
  name: 'PerformanceTaskOverview',
  components: {
    FullpageTakeoverNavbar,
    GradingProgressCard,
    KogLoader,
    AlignmentList,
    KogTag,
    PerformanceTaskOverviewTable,
  },
  mixins: [RoutesMixin],
  computed: {
    ...mapState({
      subjectMap: state => state.subjectModule.subjectMap,
      subject: state => state.subjectModule.subject,
      classOccasions: state => state[PERFORMANCE_TASK_MODULE].classOccasions,
      performanceTask: state => state[PERFORMANCE_TASK_MODULE].performanceTask,
      studentsList: state => state[SUBJECT_CLASS_STUDENTS_MODULE].studentsList,
      syllabusNodeRootDetails: state => state.syllabusModule.syllabusNodeRootDetails,
    }),
    ...mapGetters({
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
      isSyllabusEnabled: 'subjectModule/isSyllabusEnabled',
      contentQuestions: `${PERFORMANCE_TASK_MODULE}/contentQuestions`,
      gradedCountPerQuestion: `${PERFORMANCE_TASK_MODULE}/gradedCountPerQuestion`,
      currentGradingState: `${PERFORMANCE_TASK_MODULE}/currentGradingState`,
    }),
    feature() {
      return this.activityBySubjectNodeId[this.nodeId];
    },
    isLoading() {
      return (
        this.$wait.is('loading-performance-task-details') ||
        this.$wait.is('loading-performance-task-occasions') ||
        this.$wait.is('loading-class-students') ||
        this.$wait.is('loading-feature') ||
        this.$wait.is('loading-syllabus-node-tree') ||
        this.$wait.is('loading_available_questions')
      );
    },
    subjectNodeFormattedNumber() {
      return this.subjectMap[this.nodeId]?.formatted_number_including_ancestors ?? '';
    },
    subjectNodeName() {
      return this.subjectMap[this.nodeId]?.name ?? '';
    },
    syllabusAlignments() {
      if (this.$wait.is('loading-syllabus-node-tree') || isNil(this.syllabusNodeRootDetails))
        return [];
      return this.feature.syllabus_nodes;
    },
    headerText() {
      return `${this.subjectNodeFormattedNumber} ${this.subjectNodeName}`;
    },
    origin() {
      return this.$route.query.origin ?? '';
    },
    backButtonAriaLabel() {
      const navigationMessage = 'Click to go back to ';
      let destination;

      switch (this.origin) {
        case 'insights':
          destination = 'insights';
          break;
        case 'class_overview':
          destination = 'the class overview';
          break;
        case 'book_section':
        default:
          destination = 'the book';
          break;
      }

      return `${navigationMessage}${destination}`;
    },
    countStudentsInClass() {
      return this.studentsList ? this.studentsList.length : 0;
    },
    responsesToGradeCount() {
      return this.currentGradingState.responsesToGradeCount;
    },
    gradesToSendCount() {
      return this.currentGradingState.gradesToSendCount;
    },
    updatesToSendCount() {
      return this.currentGradingState.updatesToSendCount;
    },
    studentsSubmittedCount() {
      return this.currentGradingState.studentsSubmittedCount;
    },
  },
  async created() {
    await this.fetchFeaturesAndMappings({ subjectNodeIds: [this.nodeId] });
    this.fetchPerformanceTask(this.feature.id);
    this.fetchStudentsList(this.classId);
    this.fetchClassOccasions({
      performanceTaskId: this.feature.id,
      subjectClassId: this.classId,
    });
    this.fetchSyllabusMappings();
    this.fetchAvailableQuestions({ subjectNodeId: this.nodeId });
    this.$mixpanel.trackEvent('Performance task - Open responses overview page', {
      performance_task_id: this.feature.id,
      school_id: window.KOG.USER_CONSTANTS.User.school.id,
    });
    posthog.startSessionRecording();
  },
  methods: {
    ...mapWaitingActions({
      fetchPerformanceTask: {
        action: `${PERFORMANCE_TASK_MODULE}/fetchPerformanceTask`,
        loader: 'loading-performance-task-details',
      },
      fetchClassOccasions: {
        action: `${PERFORMANCE_TASK_MODULE}/fetchClassOccasions`,
        loader: 'loading-performance-task-occasions',
      },
      fetchStudentsList: {
        action: `${SUBJECT_CLASS_STUDENTS_MODULE}/fetchStudentsList`,
        loader: 'loading-class-students',
      },
      fetchFeaturesAndMappings: {
        action: 'subjectNodeFeatureModule/fetchFeaturesAndMappings',
        loader: 'loading-feature',
      },
      fetchSyllabusDetails: {
        action: 'syllabusModule/fetchSyllabusDetails',
        loader: 'loading-syllabus-node-tree',
      },
      fetchAvailableQuestions: {
        action: `${QUESTION_ACTIVITY_MODULE}/fetchAvailableQuestions`,
        loader: 'loading_available_questions',
      },
    }),
    async fetchSyllabusMappings() {
      const { subject, isSyllabusEnabled } = this;
      if (isSyllabusEnabled) {
        await this.fetchSyllabusDetails(subject.syllabus_id);
      }
    },
    onBackButtonClick() {
      switch (this.origin) {
        case 'insights':
          this.$router.push({
            name: 'teacherInsights',
            params: this.$route.params,
            query: { active_tab: this.$route.query.origin_active_tab },
          });
          break;
        case 'class_overview':
          this.$router.push({
            name: 'subjectClassOverview',
            params: this.$route.params,
            query: { node: this.$route.query.origin_node },
          });
          break;
        case 'book_section':
        default:
          this.$router.push({
            name: 'reviewMode',
            params: { ...this.$route.params },
          });
          break;
      }
    },
    navigateToStudentSubmissions(studentId) {
      this.$router.push({
        name: 'studentActivitySubmissions',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: this.nodeSlug,
          nodeId: this.nodeId,
        },
        query: {
          origin: 'pt_overview',
          prev_origin_node: this.$route.query.origin_node,
          prev_origin: this.$route.query.origin,
          student: studentId,
        },
      });
    },
  },
};
</script>
<style>
.PerformanceTaskOverview {
  min-height: 100vh;
  margin-top: var(--full-page-navbar-height);
}
</style>
