<template>
  <div class="KogWaveSeparator" />
</template>

<script>
export default {
  name: 'KogWaveSeparator',
};
</script>

<style scoped>
.KogWaveSeparator {
  height: 12px;
  background: url('~sharedApp/img/wave-separator.svg');
  background-repeat: repeat-x;
}
</style>
