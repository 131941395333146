import { icons } from '@ckeditor/ckeditor5-core';
import {
  addListToDropdown,
  createLabeledDropdown,
  LabeledFieldView,
  Model,
  View,
  ViewCollection,
} from '@ckeditor/ckeditor5-ui';
import { FileDialogButtonView } from '@ckeditor/ckeditor5-upload';
import { Collection } from '@ckeditor/ckeditor5-utils';

import KogForm from '../utils/kogform.js';

import './file-download.css';

import FlexWrapperView from '../shared/flex-wrapper-view.ts';

export const TARGET_OPTIONS = [
  { label: 'Open in new tab', target: '_blank' },
  { label: 'Open in same tab', target: '_self' },
];

export default class FormView extends KogForm {
  constructor(editor) {
    super(editor.locale);
    this.editor = editor;

    this.set('selectedTarget', TARGET_OPTIONS[0].target);

    this.titleInputView = this.createInput('Title*');
    this.urlInputView = this.createInput('URL*');

    this.uploadButtonView = this.createUploadButton(this.locale);

    this.saveButtonView = this.createSaveButton();
    this.saveButtonView
      .bind('isEnabled')
      .toMany([this.titleInputView, this.urlInputView], 'isEmpty', (...values) =>
        values.every(isEmpty => !isEmpty),
      );

    this.cancelButtonView = this.createCancelButton();

    this.targetDropdownView = this.createDropdown({
      label: 'Target',
      options: TARGET_OPTIONS,
      observableTarget: 'selectedTarget',
    });

    this.uploadWrapper = new View(this.locale);
    this.uploadWrapper.setTemplate({
      tag: 'fieldset',
      children: [
        {
          tag: 'legend',
          children: ['File upload'],
        },
        this.uploadButtonView,
        {
          tag: 'p',
          children: ['Select a png, jpg or gif file with max size of 500 kB'],
        },
        {
          tag: 'p',
          children: ['Select a wav, mp3, mp4 or m4a file with max size of 50 MB.'],
        },
        {
          tag: 'p',
          children: ['Select a pdf, zip, rar, gz, 7z file with max size of 25 MB.'],
        },
      ],
    });

    this.childViews = this.createCollection([
      new FlexWrapperView(this.locale, {
        direction: 'col',
        children: [this.titleInputView, this.urlInputView, this.targetDropdownView],
      }),
      this.uploadWrapper,
      new FlexWrapperView(this.locale, {
        direction: 'row',
        children: [this.saveButtonView, this.cancelButtonView],
      }),
    ]);

    const focusables = new ViewCollection([
      this.titleInputView,
      this.urlInputView,
      this.targetDropdownView,
      this.uploadButtonView,
      this.saveButtonView,
      this.cancelButtonView,
    ]);

    this.setup('file-download', focusables);
  }

  focus() {
    this.focusCycler.focusFirst();
  }

  reset() {
    super.reset();
    this.titleInputView.fieldView.reset();
    this.urlInputView.fieldView.reset();
    this.urlInputView.errorText = null;
    this.selectedTarget = TARGET_OPTIONS[0].target;
  }

  setFields({ title, url, target }) {
    this.titleInputView.fieldView.value = title;
    this.urlInputView.fieldView.value = url;
    const selectedItem = TARGET_OPTIONS.find(item => item.target === target);
    const { label, target: selectedTarget } = selectedItem;
    this.selectedTarget = selectedTarget;
    this.targetDropdownView.fieldView.buttonView.set({ label });
  }

  createDropdown({ label, options, observableTarget }) {
    const dropdown = new LabeledFieldView(this.locale, createLabeledDropdown);
    dropdown.bind('isEmpty').to(this, observableTarget, val => !val);
    dropdown.set({ label });

    dropdown.fieldView.buttonView.set({
      withText: true,
      ariaLabel: label,
    });
    dropdown.fieldView.on('execute', event => {
      this[observableTarget] = event.source.target;
    });
    dropdown.fieldView.buttonView.bind('label').to(this, observableTarget, val => {
      const selectedItem = options.find(item => item.target === val);
      return selectedItem.label;
    });

    const collectionItems = new Collection();
    options.forEach(option => {
      const { label: optionLabel, target } = option;
      const model = new Model({
        withText: true,
        label: optionLabel,
        target,
      });

      model.bind('isOn').to(this, observableTarget, val => val === target);

      collectionItems.add({
        type: 'button',
        model,
      });
    });

    addListToDropdown(dropdown.fieldView, collectionItems);

    return dropdown;
  }

  createUploadButton(locale) {
    const uploadButtonView = new FileDialogButtonView(locale);

    uploadButtonView.set({
      acceptedType: '*',
      allowMultipleFiles: false,
      withText: true,
      label: 'Upload file',
      icon: icons.plus,
      tooltip: true,
    });

    uploadButtonView.on('done', async (evt, files) => {
      const file = files[0];
      const loader = this.editor.plugins.get('FileRepository').createLoader(file);

      try {
        const response = await loader.upload();
        this.urlInputView.errorText = null;
        this.urlInputView.fieldView.value = response.default;
      } catch (err) {
        this.urlInputView.errorText = err;
      }
    });

    return uploadButtonView;
  }
}
