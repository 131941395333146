<template>
  <div>
    <div
      v-tooltip="{
        theme: 'kog-tooltip',
        content: disabledTooltipContent,
      }"
    >
      <a
        :href="!disabled ? url : null"
        target="_blank"
        class="KogButtonLegacy KogButtonLegacy--iconLeft"
        :aria-disabled="disabled"
        @click="trackExportCsv()"
      >
        <i class="fas fa-download" />
        Download all question assignment data (csv)
      </a>
    </div>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

import { SCHOOLSTAFF_API_URLS } from '@apis/schoolstaff-assignments.js';

import mixpanelModule from 'sharedApp/libs/mixpanel.js';

export default {
  name: 'ExportAssignmentsResult',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    subjectClassId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: '',
    },
  },
  computed: {
    url() {
      return `${SCHOOLSTAFF_API_URLS['subjectclass-assignments-result']({
        subjectClassId: this.subjectClassId,
      })}?format=csv`;
    },
    disabledTooltipContent() {
      if (this.disabled) {
        return 'Please select class to download';
      }
      return '';
    },
  },
  methods: {
    trackExportCsv() {
      mixpanelModule.trackEvent('Download all question assignment reports CSV', {
        source: this.source,
      });
    },
  },
};
</script>
