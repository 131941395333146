import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';

import Component from './block.vue';

const template = {
  block_type: BLOCK_TYPE.QUESTION,
  question_id: 0,
  title: '',
};

const validator = {
  errors: [],
  validate(blockData) {
    this.errors = [];

    if (!(blockData.question_id || blockData.question)) {
      this.errors.push('Question id is empty, no question has been chosen');
    }

    return this.errors.length === 0;
  },
};

const preSaveHandler = block => {
  return {
    payload: block,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
};

export default {
  component: Component,
  template,
  validator,
  preSaveHandler,
};
