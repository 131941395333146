import slugify from 'slugify';

const SectionUrlMixin = {
  data() {
    return {};
  },
  methods: {
    generateSectionUrl(subjectNodeName, nodeId, routeParams, resource) {
      const slugifyOptions = { remove: /[*+~.()'"!:@]/g };
      const nodeSlug = slugify(subjectNodeName, slugifyOptions).toLowerCase();

      const resolved = this.$router.resolve({
        name: 'classBook',
        params: {
          ...routeParams,
          nodeId,
          nodeSlug,
        },
      });

      const sectionPath = resolved.href;
      const sectionUrl = new URL(sectionPath, window.location.href);

      let resourceSrc = resource.src;
      const isResourceProtocolIncorrect = resourceSrc.indexOf('//') === 0;
      if (isResourceProtocolIncorrect) {
        resourceSrc = `https://${resourceSrc.substring(2)}`;
      }
      const resourceUrl = new URL(resourceSrc);

      sectionUrl.search = new URLSearchParams({ resourceAnchor: resourceUrl.pathname }).toString();
      return sectionUrl;
    },
  },
};

export default SectionUrlMixin;
