const {
  QUESTION_TYPES,
} = require('sharedApp/services/questions/questionUtilityService/question-utility-service.js');

/* API URLS */
const CONTENT_SEARCH_URL = '/api/content/search/';
const CONTENT_URL = '/api/content/content/';
const EDITORS_URL = '/api/content/editors/';
const EDUCATION_SYSTEMS_URL = '/api/content/education_systems/';
const SUBJECTS_URL = '/api/content/subjects/';
const SUBJECT_EDITOR_ROLES_URL = '/api/content/subjects_permission/';
const SUBJECT_GROUPS_URL = '/api/content/subject_groups/';
const TAG_SUGGESTIONS_URL = '/api/content/tags/suggestions/';
const SUBJECT_NODES_URL = '/api/content/subjectnodes/';
const SUBJECT_EDITOR_URL = '/api/content/subject_editor/';
const ADD_QUESTION_MAPPING_URL = '/api/content/node_mappings/';

const getFeedbackListUrl = subjectnodeId => `/api/subject-nodes/${subjectnodeId}/feedback/`;
const getFeedbackDetailUrl = (feedbackId, subjectnodeId) =>
  `/api/subject-nodes/${subjectnodeId}/feedback/${feedbackId}/`;
const getFeedbackForContentUrl = contentId => `/api/content/${contentId}/feedback/`;
const getSubjectDetailsUrl = subjectId => `/api/subjects/${subjectId}/properties/`;
const getUploadBookCoverS3SignatureUrl = bookId => `/api/content/books/${bookId}/upload-cover/`;
const getUploadSubtopicThumbnailS3SignatureUrl = subtopicId =>
  `/api/content/subtopic/${subtopicId}/upload-thumbnail/`;
const getAlignmentsForContentUrl = contentId => `/api/content/content/${contentId}/`;

const getSupportingMaterialLinkUrl = subjectnodeId =>
  `/api/subject-nodes/${subjectnodeId}/supporting-material-link/`;
const postSupportingMaterialLinkUrl = subjectnodeId =>
  `/api/subject-nodes/${subjectnodeId}/supporting-material-link/`;
const patchSupportingMaterialLinkUrl = (subjectnodeId, supportingMaterialId) =>
  `/api/subject-nodes/${subjectnodeId}/supporting-material-link/${supportingMaterialId}/`;
const deleteSupportingMaterialLinkUrl = (subjectnodeId, supportingMaterialId) =>
  `/api/subject-nodes/${subjectnodeId}/supporting-material-link/${supportingMaterialId}/`;

const getSubjectNodeChildrenUrl = subjectNodeId =>
  `/api/content/subjectnodes/${subjectNodeId}/children/`;

const getBulkAddTagsToQuestionsUrl = subjectId =>
  `/api/content/subjects/${subjectId}/questions/bulk_add_tags/`;
const getSubjectUrl = subjectId => `/api/content/subjects/${subjectId}/`;
const getSubjectQuestionSummaryUrl = subjectId =>
  `/api/content/subjects/${subjectId}/questions_summary/`;
const getSubjectQuestionStatisticsUrl = subjectId =>
  `/api/content/subjects/${subjectId}/questions_statistics/`;
const getSimilarQuestionsUrl = questionId => `/api/content/questions/${questionId}/similar/`;
const getSectionDetailUrl = sectionId => `/api/content/sections/${sectionId}/`;
const getVersionListUrl = contentId => `/api/content/${contentId}/versions/`;
const getVersionDetailUrl = (contentId, versionId) =>
  `/api/content/${contentId}/versions/${versionId}/`;
const getContentTagsUrl = contentId => `/api/content/${contentId}/tags`;
const getUpdateSectionQuestionOrderUrl = sectionId =>
  `/api/content/sections/${sectionId}/assessable_content/order/`;
const getSubjectSectionsUrl = subjectId => `/api/content/subjects/${subjectId}/sections/`;
const getQuestionDetailUrl = questionId => `/api/content/questions/${questionId}/`;
const getQuestionUrlForType = question => {
  const questionPath = question.id ? `${question.id}/` : '';
  switch (question.type) {
    case QUESTION_TYPES.MCQ:
      return `/api/content/multiple_choice_questions/${questionPath}`;
    case QUESTION_TYPES.STQ:
      return `/api/content/short_text_questions/${questionPath}`;
    case QUESTION_TYPES.LTQ:
      return `/api/content/long_text_questions/${questionPath}`;
    case QUESTION_TYPES.FBQ:
      return `/api/content/fill_in_the_blanks_questions/${questionPath}`;
    default:
      throw new Error(`Cannot save unknown question type '${question.type}'.`);
  }
};
const getGlossarySubjectListUrl = () => '/api/content/glossary/subjects/';
const getQuestionStatisticsUrl = (subjectId, questionId) =>
  `/api/content/subjects/${subjectId}/questions_statistics/${questionId}/`;
const deleteQuestionMappingUrl = (questionId, subjectnodeId) =>
  `/api/content/${questionId}/subjectnode/${subjectnodeId}/`;
const getGlossaryTermsUrl = (pageNumber, pageSize, idFilters, termFilter, selectedSubjectId) => {
  const path = '/api/content/glossary/terms/';
  const url = new URL(path, window.location.origin);
  const searchParams = {
    page: pageNumber,
    page_size: pageSize,
  };
  if (idFilters && idFilters.length > 0) {
    searchParams.id__in = idFilters;
  }
  if (termFilter) {
    searchParams.term__icontains = termFilter;
  }
  if (selectedSubjectId) {
    searchParams.definitions__subject_mappings__id = selectedSubjectId;
  }
  url.search = new URLSearchParams(searchParams);
  return url.toString();
};
const getWrongAnswerStatistics = questionId => `/api/content/${questionId}/answer_statistics/`;
const getBulkActivateQuestionsUrl = subjectId =>
  `/api/content/subjects/${subjectId}/questions/bulk_activate/`;
const getBulkDeactivateQuestionsUrl = subjectId =>
  `/api/content/subjects/${subjectId}/questions/bulk_deactivate/`;
const getBulkMapQuestionsUrl = subjectNodeId =>
  `/api/content/subjectnode/${subjectNodeId}/questions/bulk_add_mapping/`;
const getBulkDuplicateQuestionsUrl = subjectId =>
  `/api/content/subjects/${subjectId}/questions/bulk_duplicate/`;

export const API_URLS = {
  CONTENT_URL,
  CONTENT_SEARCH_URL,
  EDITORS_URL,
  EDUCATION_SYSTEMS_URL,
  SUBJECTS_URL,
  SUBJECT_EDITOR_ROLES_URL,
  SUBJECT_GROUPS_URL,
  TAG_SUGGESTIONS_URL,
  SUBJECT_NODES_URL,
  SUBJECT_EDITOR_URL,
  ADD_QUESTION_MAPPING_URL,
  getAlignmentsForContentUrl,
  getFeedbackListUrl,
  getFeedbackForContentUrl,
  getFeedbackDetailUrl,
  getSubjectDetailsUrl,
  getUploadBookCoverS3SignatureUrl,
  getUploadSubtopicThumbnailS3SignatureUrl,
  getSupportingMaterialLinkUrl,
  postSupportingMaterialLinkUrl,
  patchSupportingMaterialLinkUrl,
  deleteSupportingMaterialLinkUrl,
  getBulkAddTagsToQuestionsUrl,
  getSubjectUrl,
  getSubjectQuestionSummaryUrl,
  getSubjectQuestionStatisticsUrl,
  getSimilarQuestionsUrl,
  getSectionDetailUrl,
  getVersionDetailUrl,
  getVersionListUrl,
  getContentTagsUrl,
  getUpdateSectionQuestionOrderUrl,
  getSubjectSectionsUrl,
  getQuestionDetailUrl,
  getQuestionUrlForType,
  getGlossaryTermsUrl,
  getGlossarySubjectListUrl,
  getQuestionStatisticsUrl,
  deleteQuestionMappingUrl,
  getSubjectNodeChildrenUrl,
  getWrongAnswerStatistics,
  getBulkActivateQuestionsUrl,
  getBulkDeactivateQuestionsUrl,
  getBulkMapQuestionsUrl,
  getBulkDuplicateQuestionsUrl,
};

export default {
  API_URLS,
};
