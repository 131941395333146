<template>
  <kog-table>
    <template #colgroup>
      <col width="32px" />
      <col />
      <col
        v-if="hasEducationSystemPapertype"
        width="8%"
      />
      <col
        v-if="hasEducationSystemMarks"
        width="42px"
      />
    </template>
    <template #header>
      <kog-table-header>
        <kog-table-cell-header> # </kog-table-cell-header>
        <kog-table-cell-header> Question </kog-table-cell-header>
        <kog-table-cell-header
          v-if="hasEducationSystemPapertype"
          class="text-right"
          :is-padded-left="false"
        >
          Paper
        </kog-table-cell-header>
        <kog-table-cell-header
          v-if="hasEducationSystemMarks"
          class="text-center"
        >
          Marks
        </kog-table-cell-header>
        <kog-table-cell-header />
      </kog-table-header>
    </template>

    <template #body>
      <kog-table-row
        v-for="(question, index) in assignment.questions"
        :key="`question-${question.id}`"
      >
        <kog-table-cell-text>
          {{ index + 1 }}
        </kog-table-cell-text>

        <kog-table-cell-text :is-able-to-truncate="true">
          <div
            v-kog-clickable:[question]="previewQuestion"
            v-kog-description:[getUniqueId(question.id)]="'Click to open question preview'"
            class="EsqAssignmentDetailsQuestionTable-question"
            v-text="getQuestionPreview(question)"
          />
        </kog-table-cell-text>

        <kog-table-cell-tag
          v-if="hasEducationSystemPapertype"
          :label="question.context.papertype"
          tag-alignment="end"
          :is-truncated="true"
        />

        <kog-table-cell-text
          v-if="hasEducationSystemMarks"
          class="text-center"
        >
          {{ question.context.marks }}
        </kog-table-cell-text>
        <kog-table-cell-actions
          :actions="questionActions"
          :context="question"
        />
      </kog-table-row>
    </template>
  </kog-table>
</template>

<script>
import KogTableCellActions from 'sharedApp/components/tables/kog-table-cell-actions.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellTag from 'sharedApp/components/tables/kog-table-cell-tag.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  hasEducationSystemMarks,
  hasEducationSystemPapertype,
} from 'sharedApp/services/educationSystem/education-system-service.js';
import { dangerouslyGetUnescapedText } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import QuestionPreviewModal from 'studyApp/components/teacher/assignments/question-preview-modal.vue';

export default {
  name: 'EsqAssignmentDetailsQuestionTable',
  components: {
    KogTable,
    KogTableCellActions,
    KogTableCellHeader,
    KogTableCellText,
    KogTableHeader,
    KogTableRow,
    KogTableCellTag,
  },
  directives: {
    KogClickable,
    KogDescription,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    educationSystem: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      hasEducationSystemMarks: hasEducationSystemMarks(this.educationSystem),
      hasEducationSystemPapertype: hasEducationSystemPapertype(this.educationSystem),
    };
  },
  computed: {
    questionActions() {
      return [
        {
          tooltipText: 'Preview question',
          iconClass: 'fa-search',
          callback: this.previewQuestion,
        },
      ];
    },
  },
  methods: {
    getQuestionPreview(question) {
      return dangerouslyGetUnescapedText(question.question_html);
    },
    previewQuestion(question) {
      const paginatedQuestions = {
        results: this.assignment.questions,
        page_size: 10,
        current_page: 1,
        count: this.assignment.questions.length,
      };
      this.$modal(QuestionPreviewModal, {
        question,
        assignmentType: ASSIGNMENT_TYPES.EXAMSTYLE,
        paginatedQuestions,
        subjectName: this.assignment.subject_name,
        showPosition: true,
        source: 'assignment_details',
        assignment: this.assignment,
        educationSystem: this.educationSystem,
      });
    },
  },
};
</script>

<style scoped>
.EsqAssignmentDetailsQuestionTable-question :deep(div) {
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
