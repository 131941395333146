import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget, toWidgetEditable, Widget } from '@ckeditor/ckeditor5-widget';
import { ClickObserver } from '@ckeditor/ckeditor5-engine';
import InsertContentGridCommand from './content-grid-command.js';
import { forbidNesting, isClickInsideSelectedElement } from '../utils/commands.js';

export default class ContentGridEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineCommands();
  }

  defineSchema() {
    const { schema } = this.editor.model;

    schema.register('contentGrid', {
      inheritAllFrom: '$blockObject',
    });

    schema.register('contentGridContent', {
      isLimit: true,
      allowContentOf: '$root',
      inheritAllFrom: '$inlineObject',
      allowIn: 'contentGrid',
      allowAttributes: ['columns'],
    });

    schema.addChildCheck(forbidNesting('contentGrid'));
  }

  defineConverters() {
    const { conversion } = this.editor;

    // <contentGrid> converters
    const contentGridModel = 'contentGrid';
    conversion.for('upcast').elementToElement({
      model: contentGridModel,
      view: {
        name: 'div',
        classes: 'CKEditorKognityContentGrid',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: contentGridModel,
      view: {
        name: 'div',
        classes: 'CKEditorKognityContentGrid',
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: contentGridModel,
      view: (_, { writer }) => {
        const viewElement = writer.createContainerElement('div', {
          class: 'CKEditorKognityContentGrid',
        });
        return toWidget(viewElement, writer);
      },
    });

    // <contentGridContent> converters
    const contentGridContentModel = 'contentGridContent';
    conversion.for('upcast').elementToElement({
      model: contentGridContentModel,
      view: {
        name: 'div',
        classes: 'CKEditorKognityContentGrid-grid',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: contentGridContentModel,
      view: {
        name: 'div',
        classes: 'CKEditorKognityContentGrid-grid',
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: contentGridContentModel,
      view: (modelElement, { writer }) => {
        const columns = modelElement.getAttribute('columns');
        const viewElement = writer.createEditableElement('div', {
          class: 'CKEditorKognityContentGrid-grid',
          'data-columns': columns,
        });
        return toWidgetEditable(viewElement, writer);
      },
    });

    // Columns attribute converter
    conversion.attributeToAttribute({
      model: {
        name: contentGridContentModel,
        key: 'columns',
      },
      view: 'data-columns',
    });
  }

  defineCommands() {
    const { editor } = this;
    const { document } = editor.model;
    const { view, mapper } = editor.editing;

    editor.commands.add('insertContentGrid', new InsertContentGridCommand(editor));
    editor.commands.add('updateContentGrid', new Command(editor));

    view.addObserver(ClickObserver);
    editor.listenTo(view.document, 'click', (_, data) => {
      if (
        isClickInsideSelectedElement(data, document, mapper, 'contentGrid', ['contentGridContent'])
      ) {
        editor.commands.get('updateContentGrid').execute();
      }
    });
  }
}
