<template>
  <short-text-question
    :question="question"
    :disabled="disabled"
    :initial-answer="context.user_response"
    :is-correct="isShowingAnswerCorrectness ? responseCorrectness : null"
    :is-incorrect="isShowingAnswerCorrectness ? !responseCorrectness : null"
    @blur="handleUserResponse"
  />
</template>

<script>
import ShortTextQuestion from 'sharedApp/components/short-text-question/short-text-question.vue';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';

export default {
  name: 'ActivityStq',
  components: {
    ShortTextQuestion,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    /*
      user_response?: string,
    */
    context: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['response'],
  computed: {
    isShowingAnswerCorrectness() {
      return 'user_response_correctness' in this.context;
    },
    responseCorrectness() {
      return this.context.user_response_correctness;
    },
  },
  methods: {
    handleUserResponse(response) {
      this.$emit('response', {
        user_response: response,
        response_type: RESPONSE_TYPES.STR,
      });
    },
  },
};
</script>
