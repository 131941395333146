<template>
  <div>
    <kog-item-list
      role="list"
      focusable-elements-selector=".ExerciseQuestion-wrapper"
    >
      <exercise-question
        v-for="(pi, index) in practiceItems"
        :key="pi.question.id"
        ref="exerciseQuestions"
        :position="index + 1"
        :practice-item="pi"
        :has-user-answer="hasUserAnswer(pi)"
        :practice-item-result="getPracticeItemResult(pi)"
        :tracking-props="trackingProps"
        @set-answer="({ answer }) => setAnswer(pi, answer)"
        @remove-answer="removeAnswer(pi)"
      />
    </kog-item-list>
    <exercise-question-feedback
      :number-of-questions="questions.length"
      :submission-results="submissionResults"
      :display-feedback="displayFeedback"
    />
    <kog-button
      v-if="showCheckAnswers"
      id="exercise-question-submit-btn"
      :class="[
        'margin-top-xs',
        'ExerciseQuestions-submitBtn',
        'heading-xxs',
        {
          'shadow-s-030': canSubmitAnswers,
          'ExerciseQuestions-submitBtn--disabled': !canSubmitAnswers,
        },
      ]"
      size="large"
      :disabled="!canSubmitAnswers"
      :has-icon="true"
      icon-class="fa-circle-check"
      icon-style="regular"
      button-style="primary"
      label="Check answers"
      @click="submitAnswers"
    />
    <div class="flexContainer flexContainer-justifyCenter margin-top-m">
      <kog-button
        v-if="shouldDisplayRetryButton"
        id="exercise-question-retry-btn"
        :has-icon="true"
        icon-class="fa-arrow-rotate-left"
        icon-style="regular"
        button-style="basic"
        label="Start from beginning"
        @click="beginRetry"
      />
      <kog-button
        v-if="!canSubmitAnswers && showAnswerExplanations"
        id="exercise-question-show-explanation-btn"
        :has-icon="true"
        icon-class="fa-eye"
        icon-style="regular"
        button-style="basic"
        label="Show explanations"
        @click="showExplanationModal"
      />
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import isEqual from 'lodash/isEqual.js';
import { mapState } from 'vuex';

import questionAnswersAPI from 'learning/common/api/question-answers.js';
import ExerciseQuestionFeedback from 'learning/common/components/exercise-question/exercise-question-feedback.vue';
import ExerciseQuestionResultModal from 'learning/common/components/exercise-question/exercise-question-result-modal.vue';
import ExerciseQuestion from 'learning/common/components/exercise-question/exercise-question.vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import multipleChoiceQuestionNumberingService from 'sharedApp/services/questions/multipleChoiceQuestionNumbering/multiple-choice-question-numbering-service.js';
import {
  isFBQ,
  isMCQ,
  QUESTION_TYPES,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';
import { API_PATHS } from 'studyApp/api/subject.js';

import { finishRetry, isRetryingExerciseQuestion, retryExerciseQuestion } from './retry-service.js';

const STAGGERING_FACTOR = 100;
const mcqNumberingService = multipleChoiceQuestionNumberingService();

export default {
  name: 'ExerciseQuestionGroup',
  components: {
    ExerciseQuestion,
    ExerciseQuestionFeedback,
    KogButton,
    KogItemList,
  },
  mixins: [RoutesMixin],
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    trackingProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      practiceItems: [],
      submissionResults: [],
      isSubmittingAnswers: false,
      answersToSubmit: {},
      displayFeedback: false,
      explanationsAvailable: false,
      questionEventTrackingSet: new Set(),
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    shouldDisplayRetryButton() {
      const isAnyQuestionCorrect = this.submissionResults.some(
        result => result.user_answer.is_correct === true,
      );
      return isAnyQuestionCorrect;
    },
    showCheckAnswers() {
      return !this.isAllQuestionsAnsweredCorrectly;
    },
    showAnswerExplanations() {
      return this.explanationsAvailable;
    },
    canSubmitAnswers() {
      return (
        Object.keys(this.answersToSubmit).length > 0 &&
        !this.isAllQuestionsAnsweredCorrectly &&
        !this.isSubmittingAnswers
      );
    },
    nodeProgressPracticeItemListAPIUrl() {
      return API_PATHS['node-progress-practice-item-list']({ subjectNodeId: this.subjectNodeId });
    },
    isAllQuestionsAnsweredCorrectly() {
      if (this.submissionResults.length === 0) {
        return false;
      }
      const correctlyAnsweredQuestions = this.submissionResults.filter(
        result => result.user_answer.is_correct === true,
      );
      return correctlyAnsweredQuestions.length === this.practiceItems.length;
    },
    questionIds() {
      return this.questions.map(q => q.id);
    },
  },
  watch: {
    isAllQuestionsAnsweredCorrectly(newValue) {
      if (newValue) {
        this.questions.forEach(question => finishRetry(this.subjectNodeId, question.id));
      }
    },
  },
  async created() {
    const validQuestionObjects = await this.getValidQuestionWithBaseStructure();
    await this.populateQuestionsObject(validQuestionObjects);

    this.orderedQuestionIds = validQuestionObjects.map(item => item.question.id);

    await this.fetchAndUpdateWithLatestAnswers(this.orderedQuestionIds);
  },
  methods: {
    beginRetry() {
      this.questions.forEach(question => {
        retryExerciseQuestion(this.subjectNodeId, question.id);
      });

      this.practiceItems.forEach(this.removeAnswer);

      this.submissionResults = [];
      this.explanationsAvailable = false;

      this.$mixpanel.trackEvent('Exercise question - Retry', this.trackingProps);
    },
    isInARetry() {
      const isQuestionInRetry = question =>
        isRetryingExerciseQuestion(this.subjectNodeId, question.id);
      return this.questions.some(isQuestionInRetry);
    },
    hasUserAnswer(practiceItem) {
      if (isFBQ(practiceItem.question)) {
        return (
          practiceItem.answerToSubmit &&
          Object.keys(practiceItem.answerToSubmit).every(
            blank => practiceItem.answerToSubmit[blank].length > 0,
          )
        );
      }

      return !!practiceItem.answerToSubmit;
    },
    async getValidQuestionWithBaseStructure() {
      const subjectExerciseQuestions = await axios.get(this.nodeProgressPracticeItemListAPIUrl, {
        params: { feature: 'exercise' },
      });

      const sortedValidQuestions = this.questionIds.reduce((acc, id) => {
        const practiceItem = subjectExerciseQuestions.data.find(pi => id === pi.question.id);
        if (practiceItem) {
          acc.push(practiceItem);
        }
        return acc;
      }, []);

      return sortedValidQuestions;
    },
    populateQuestionsObject(questionObjects) {
      this.practiceItems = questionObjects.map(item => {
        const { question } = item;
        if (isMCQ(question)) {
          mcqNumberingService.updateMultipleChoiceQuestionWithNumbering(question, true);
        }

        // eslint-disable-next-line no-param-reassign
        item.answerToSubmit = null;
        return item;
      });
    },
    setAnswer(practiceItem, answer) {
      if (isEqual(practiceItem.answerToSubmit, answer)) {
        return;
      }

      // eslint-disable-next-line no-param-reassign
      practiceItem.answerToSubmit = answer;

      if (this.hasUserAnswer(practiceItem)) {
        this.triggerQuestionAnswerEvent(practiceItem);

        this.answersToSubmit[practiceItem.question.id] = answer;
        this.displayFeedback = false;

        this.updateSubmissionResults([
          {
            ...practiceItem,
            user_answer: this.buildUserAnswer(practiceItem.question.type, {
              is_correct: null,
              user_answer: null,
            }),
          },
        ]);

        if (this.isInARetry) {
          finishRetry(this.subjectNodeId, practiceItem.question.id);
        }
      }
    },
    removeAnswer(practiceItem) {
      // eslint-disable-next-line no-param-reassign
      delete practiceItem.answerToSubmit;
      delete this.answersToSubmit[practiceItem.question.id];
    },
    async submitAnswers() {
      this.isSubmittingAnswers = true;

      const payload = Object.entries(this.answersToSubmit).map(([questionId, answer]) => ({
        question: questionId,
        user_answer: answer,
      }));

      this.animateQuestionsIn();

      const response = await axios.post(this.nodeProgressPracticeItemListAPIUrl, payload);

      this.updateSubmissionResults(response.data);

      await this.animateQuestionsOut();

      this.answersToSubmit = {};
      this.questionEventTrackingSet.clear();
      this.isSubmittingAnswers = false;
      this.displayFeedback = true;
      this.explanationsAvailable = this.submissionResults.length === this.questions.length;

      this.$mixpanel.trackEventViaBackend('Exercise question - Submit answers', this.trackingProps);

      if (this.user.isStudent()) {
        const occasionIdSet = new Set();
        response.data.forEach(item => occasionIdSet.add(item.occasion_id));

        if (occasionIdSet.size !== 1) {
          Sentry.captureException(
            new Error(
              `Submit Exercise Questions - Expected one occasion id but got ${
                occasionIdSet.size
              }: ${occasionIdSet.values()}`,
            ),
          );
          return;
        }

        const [occasionId] = occasionIdSet;
        this.$event.track('submit_exercise_question', {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          subject_node_id: this.subjectNodeId,
          practice_occasion_id: occasionId,
        });
      }
    },
    /**
     * @param {Object[]} updateObjects
     * @param {Object} updateObjects[].question
     * @param {number} updateObjects[].question.id
     * @param {Object} updateObjects[].solution
     * @param {Object} updateObjects[].user_answer
     */
    updateSubmissionResults(updateObjects) {
      const updatedIds = updateObjects.map(item => item.question.id);
      const isIdInSubmissionResults = id =>
        this.submissionResults.some(item => item.question.id === id);

      const updatedItems = updateObjects.map(item => {
        if (!isIdInSubmissionResults(item.question.id)) {
          // eslint-disable-next-line no-param-reassign
          item.question = this.practiceItems.find(
            pi => pi.question.id === item.question.id,
          ).question;
          return item;
        }

        const oldItem = this.submissionResults.find(
          resultItem => resultItem.question.id === item.question.id,
        );

        oldItem.solution = item.solution;
        oldItem.user_answer = item.user_answer;
        return oldItem;
      });

      this.submissionResults = [
        ...this.submissionResults.filter(sr => !updatedIds.includes(sr.question.id)),
        ...updatedItems,
      ];

      this.sortSubmissionResult();
    },
    sortSubmissionResult() {
      const sortByQuestionOrder = (a, b) => {
        const aIndex = this.orderedQuestionIds.indexOf(a.question.id);
        const bIndex = this.orderedQuestionIds.indexOf(b.question.id);

        return aIndex < bIndex ? -1 : 1;
      };

      this.submissionResults = this.submissionResults.sort(sortByQuestionOrder);
    },
    animateQuestionsIn() {
      this.$refs.exerciseQuestions.forEach(q => q.setIsSendingAnswer(true));
    },
    buildAnimationOutPromises() {
      return this.$refs.exerciseQuestions.map((question, index) => {
        return new Promise(resolve => {
          setTimeout(() => {
            question.setIsSendingAnswer(false);
            resolve();
          }, index * STAGGERING_FACTOR);
        });
      });
    },
    async animateQuestionsOut() {
      await Promise.all(this.buildAnimationOutPromises());
    },
    getPracticeItemResult(practiceItem) {
      return this.submissionResults.find(pi => pi.question.id === practiceItem.question.id);
    },
    showExplanationModal() {
      this.$mixpanel.trackEvent('Exercise question - Show explanation', this.trackingProps);

      this.$modal(ExerciseQuestionResultModal, {
        result: this.submissionResults,
        answeredPracticeItems: this.practiceItems,
        scrollingLongContent: true,
        forceShowSolution: true,
        isAllAnsweredCorrectly: this.isAllQuestionsAnsweredCorrectly,
      });
    },
    async fetchAndUpdateWithLatestAnswers(questionIds) {
      const response = await questionAnswersAPI.getUsersLastAnswers(
        this.subjectNodeId,
        questionIds,
      );

      const updateStateWithLatestAnswers = questionAnswerItem => {
        const practiceItem = this.practiceItems.find(
          pi => pi.question.id === questionAnswerItem.question_id,
        );

        if (
          !practiceItem ||
          isRetryingExerciseQuestion(this.subjectNodeId, questionAnswerItem.question_id)
        )
          return;

        practiceItem.answerToSubmit = questionAnswerItem.user_answer;

        const questionType = practiceItem.question.type;

        const resultRecord = {
          question: practiceItem.question,
          user_answer: this.buildUserAnswer(questionType, questionAnswerItem),
          solution: this.buildSolutionOutput(questionType, questionAnswerItem),
        };

        this.submissionResults.push(resultRecord);
      };

      response.results.forEach(updateStateWithLatestAnswers);
      this.sortSubmissionResult();
      this.explanationsAvailable = this.submissionResults.length === this.questions.length;
    },
    buildUserAnswer(questionType, item) {
      const structure = {
        is_correct: item.correct,
      };

      switch (questionType) {
        case QUESTION_TYPES.FBQ: {
          structure.user_answers = item.user_answer;
          break;
        }
        case QUESTION_TYPES.MCQ: {
          structure.user_response = item.user_answer;
          break;
        }
        case QUESTION_TYPES.STQ: {
          structure.user_text = item.user_answer;
          break;
        }
        default:
          Sentry.captureException(
            new Error(`Unsupported question type in buildUserAnswer: ${questionType}`),
          );
      }

      return structure;
    },
    buildSolutionOutput(questionType, item) {
      let solution = {};

      switch (questionType) {
        case QUESTION_TYPES.FBQ: {
          solution.explanation = item.question.explanation;
          solution.correct_answers = item.question.answers_set.reduce((acc, answer) => {
            acc[answer.html_element_uid] = {
              answers: answer.answers,
              correct: answer.answers.includes(item.user_answer[answer.html_element_uid]),
            };
            return acc;
          }, {});
          break;
        }
        case QUESTION_TYPES.MCQ: {
          solution = item.question.answers_set.filter(choice => choice.is_correct);
          break;
        }
        case QUESTION_TYPES.STQ: {
          solution.answer_explanation_html = item.question.explanation;
          solution.answer_list = item.question.answers_set?.answer_list ?? [];
          break;
        }
        default:
          Sentry.captureException(
            new Error(`Unsupported question type in buildSolutionOutput: ${questionType}`),
          );
      }

      return solution;
    },
    triggerQuestionAnswerEvent(practiceItem) {
      if (this.questionEventTrackingSet.has(practiceItem.question.id)) return;

      this.questionEventTrackingSet.add(practiceItem.question.id);
      this.$mixpanel.trackEvent('Exercise question - Click to answer question', this.trackingProps);
    },
  },
};
</script>

<style scoped>
.ExerciseQuestions-submitBtn {
  width: 100%;
  font-weight: 400;
}
</style>
