<template>
  <kog-default-study-page>
    <template #body>
      <h1 class="divider-bottom margin-bottom-m padd-bottom-s heading-xl"> Assignments </h1>
      <kog-loader
        :loading="!isQuestionsLoaded"
        loading-msg="Loading assignment..."
      >
        <h2
          ref="ongoingAssignmentsHeading"
          class="screenreader-only"
          tabindex="-1"
        >
          Ongoing assignments {{ writeOngoingAssignmentsPageNumber() }}
        </h2>
        <h2
          aria-hidden="true"
          class="margin-top-xxl margin-bottom-l heading-m"
        >
          Ongoing assignments
        </h2>
        <kog-table
          v-if="hasOngoingAssignments"
          :pagination-number-of-pages="ongoingAssignments.num_pages"
          :pagination-current-page="ongoingAssignments.current_page"
          :update-current-page="goToOngoingAssignmentsPage"
          pagination-type="assignments"
        >
          <template #colgroup>
            <col width="70%" />
            <col />
            <col width="30%" />
            <col />
          </template>
          <template #header>
            <kog-table-header>
              <kog-table-cell-header> Name </kog-table-cell-header>
              <kog-table-cell-header> Type </kog-table-cell-header>
              <kog-table-cell-header> Deadline </kog-table-cell-header>
              <kog-table-cell-header class="kog-hidden-xs"> Score </kog-table-cell-header>
            </kog-table-header>
          </template>
          <template #body>
            <kog-table-row
              v-for="occasion in ongoingAssignments.results"
              :key="occasion.assignment.id"
              :is-top-aligned="isTopAligned(occasion.assignment.id)"
            >
              <kog-table-cell-text
                :is-multi-line="true"
                :tag-label="isDeadlineSoon(occasion) ? 'Due soon' : ''"
                :tag-props="{
                  type: 'warning',
                  context: isDeadlineSoon(occasion) ? 'Assignment is due soon' : '',
                }"
                @on-multiple-line="updateTopAligned(occasion.assignment.id, $event)"
              >
                <div
                  v-kog-clickable:[occasion]="handleAssignmentClick"
                  v-kog-description:[getUniqueId(occasion.assignment.id)]="
                    'Click to navigate to assignment details'
                  "
                  class="AssignmentsOverview-ongoingAssignmentName"
                >
                  {{ occasion.assignment.name }}
                </div>
              </kog-table-cell-text>
              <kog-table-cell-icon
                :tooltip-text="iconDetails(occasion.assignment).tooltip"
                size="s-touch"
                :icon-class="iconDetails(occasion.assignment).iconClass"
                :fa-style="iconDetails(occasion.assignment).style"
                :is-svg-icon="iconDetails(occasion.assignment).isSVG"
              />
              <kog-table-cell-text
                :is-multi-line="true"
                @on-multiple-line="updateTopAligned(occasion.assignment.id, $event)"
              >
                <span class="screenreader-only">
                  Due {{ humanUnderstandableDate(occasion.assignment.deadline) }}
                </span>
                <span aria-hidden="true">
                  {{ date(occasion.assignment.deadline) }}
                </span>
              </kog-table-cell-text>
              <kog-table-cell-text
                v-if="isReading(occasion.assignment)"
                class="kog-hidden-xs"
              >
                <span class="screenreader-only">
                  {{ getReadingAssignmentScore(occasion, true) }}
                </span>
                <span aria-hidden="true">
                  {{ getReadingAssignmentScore(occasion) }}
                </span>
              </kog-table-cell-text>
              <kog-table-cell-text
                v-if="isExamStyle(occasion.assignment)"
                class="kog-hidden-xs"
              >
                <span class="screenreader-only">
                  score not applicable
                  <span v-if="occasion.esq_occasion.status === 'COMPLETED'">completed</span>
                </span>
                <span aria-hidden="true">
                  n/a <span v-if="occasion.esq_occasion.status === 'COMPLETED'">(Completed)</span>
                </span>
              </kog-table-cell-text>
              <kog-table-cell-text
                v-if="isQuestion(occasion.assignment)"
                class="kog-hidden-xs"
              >
                <template v-if="!occasion.practice_occasion.is_done">
                  <em v-if="!occasion.assignment.has_deadline_passed">
                    Not submitted
                    {{
                      occasion.practice_occasion.status === 'STARTED' ? '(Click to continue)' : ''
                    }}
                  </em>
                  <span v-else> - </span>
                </template>
                <template v-else>
                  <em v-if="!isScoreVisible(occasion)"> Available after deadline </em>
                  <span v-else>
                    <span class="screenreader-only">
                      {{ scoreText(occasion, true) }}
                    </span>
                    <span aria-hidden="true">
                      {{ scoreText(occasion, false) }}
                    </span>
                  </span>
                </template>
              </kog-table-cell-text>
            </kog-table-row>
          </template>
        </kog-table>
        <div v-else> You have no ongoing assignments. </div>
        <h2
          ref="pastAssignmentsHeading"
          tabindex="-1"
          class="screenreader-only"
        >
          Past assignments {{ writePastAssignmentsPageNumber() }}
        </h2>
        <h2
          aria-hidden="true"
          class="divider-top padd-top-xxl margin-top-xxl margin-bottom-l heading-m"
        >
          Past assignments
        </h2>
        <kog-table
          v-if="hasPastAssignments"
          :pagination-number-of-pages="pastAssignments.num_pages"
          :pagination-current-page="pastAssignments.current_page"
          :update-current-page="goToPastAssignmentsPage"
          pagination-type="assignments"
        >
          <template #colgroup>
            <col width="70%" />
            <col />
            <col width="30%" />
            <col />
          </template>
          <template #header>
            <kog-table-header>
              <kog-table-cell-header> Name </kog-table-cell-header>
              <kog-table-cell-header> Type </kog-table-cell-header>
              <kog-table-cell-header> Deadline </kog-table-cell-header>
              <kog-table-cell-header class="kog-hidden-xs"> Score </kog-table-cell-header>
            </kog-table-header>
          </template>
          <template #body>
            <kog-table-row
              v-for="occasion in pastAssignments.results"
              :key="occasion.assignment.id"
              :is-top-aligned="isTopAligned(occasion.assignment.id)"
            >
              <kog-table-cell-text
                :is-multi-line="true"
                :tag-label="isQuestionAssignmentLate(occasion) ? 'Late' : ''"
                :tag-props="{
                  type: 'negative',
                  context: isQuestionAssignmentLate(occasion) ? 'Assignment is late' : '',
                }"
                @on-multiple-line="updateTopAligned(occasion.assignment.id, $event)"
              >
                <div
                  v-if="isPastAssignmentClickable(occasion)"
                  v-kog-clickable:[occasion]="handleAssignmentClick"
                  v-kog-description:[getUniqueId(occasion.assignment.id)]="
                    'Click to navigate to assignment details'
                  "
                  class="AssignmentsOverview-pastAssignmentName--clickable"
                >
                  {{ occasion.assignment.name }}
                </div>
                <div v-else>
                  {{ occasion.assignment.name }}
                </div>
              </kog-table-cell-text>
              <kog-table-cell-icon
                :tooltip-text="iconDetails(occasion.assignment).tooltip"
                size="s-touch"
                :icon-class="iconDetails(occasion.assignment).iconClass"
                :fa-style="iconDetails(occasion.assignment).style"
                :is-svg-icon="iconDetails(occasion.assignment).isSVG"
              />
              <kog-table-cell-text
                :is-multi-line="true"
                @on-multiple-line="updateTopAligned(occasion.assignment.id, $event)"
              >
                <span class="screenreader-only">
                  Due {{ humanUnderstandableDate(occasion.assignment.deadline) }}
                </span>
                <span aria-hidden="true">
                  {{ date(occasion.assignment.deadline) }}
                </span>
              </kog-table-cell-text>
              <kog-table-cell-text
                v-if="isReading(occasion.assignment)"
                :class="{
                  'kog-hidden-xs': true,
                }"
              >
                <span class="screenreader-only">
                  {{ getReadingAssignmentScore(occasion, true) }}
                </span>
                <span aria-hidden="true">
                  {{ getReadingAssignmentScore(occasion) }}
                </span>
              </kog-table-cell-text>
              <kog-table-cell-text
                v-if="isExamStyle(occasion.assignment)"
                :class="{
                  'kog-hidden-xs': true,
                }"
              >
                <span class="screenreader-only"> score not applicable </span>
                <span aria-hidden="true"> n/a </span>
              </kog-table-cell-text>
              <kog-table-cell-text
                v-if="isQuestion(occasion.assignment)"
                :class="{
                  'kog-hidden-xs': true,
                }"
              >
                <template v-if="!occasion.practice_occasion.is_done">
                  <em v-if="!occasion.assignment.has_deadline_passed">
                    Not submitted
                    {{
                      occasion.practice_occasion.status === 'STARTED' ? '(Click to continue)' : ''
                    }}
                  </em>
                  <span v-else> Not submitted </span>
                </template>
                <template v-else>
                  <em v-if="!isScoreVisible(occasion)"> Available after deadline </em>
                  <span v-else>
                    <span class="screenreader-only"> Assignment score </span>
                    {{ getQuestionAssignmentScore(occasion) }}
                  </span>
                </template>
              </kog-table-cell-text>
            </kog-table-row>
          </template>
        </kog-table>
        <div v-else> You have no past assignments. </div>
      </kog-loader>
    </template>
  </kog-default-study-page>
</template>

<script>
import { useHead } from '@unhead/vue';
import format from 'date-fns/format';
import pluralize from 'pluralize';
import { mapState, useStore } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellIcon from 'sharedApp/components/tables/kog-table-cell-icon.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import useAssignmentReadableSettings from 'sharedApp/composables/use-assignment-readable-settings.ts';
import useOccasionAssignments from 'sharedApp/composables/use-occasion-assignments.ts';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import {
  getIconDetails,
  getQuestionAssignmentResultRoute,
  getTakeAssignmentRoute,
  isExamStyleType,
  isQuestionType,
  isReadingType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { humanUnderstandableDateTime } from 'sharedApp/utils/time-utils.js';
import KogDefaultStudyPage from 'studyApp/components/kog-default-study-page.vue';

export default {
  name: 'AssignmentsOverview',
  components: {
    KogLoader,
    KogTable,
    KogTableHeader,
    KogTableRow,
    KogTableCellText,
    KogTableCellHeader,
    KogTableCellIcon,
    KogDefaultStudyPage,
  },
  directives: {
    KogClickable,
    KogDescription,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const { subjectModule } = store.state;
    const { ongoingAssignments, pastAssignments, fetchOngoingAssignments, fetchPastAssignments } =
      useOccasionAssignments();

    useHead({
      title: () => `Assignments overview | ${subjectModule.subject?.name}`,
    });

    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
      ongoingAssignments,
      pastAssignments,
      fetchOngoingAssignments,
      fetchPastAssignments,
    };
  },
  data() {
    return {
      topAlignedRows: {},
      onGoingAssignmentsPage: 1,
      pastAssignmentsPage: 1,
    };
  },
  computed: {
    ...mapState('subjectModule', {
      subject: state => state.subject,
    }),
    hasOngoingAssignments() {
      return this.ongoingAssignments.results?.length;
    },
    hasPastAssignments() {
      return this.pastAssignments.results?.length;
    },
    subjectId() {
      return this.subject.id;
    },
    isQuestionsLoaded() {
      return this.pastAssignments.results && this.ongoingAssignments.results;
    },
    source() {
      return this.$route.query.source;
    },
  },
  mounted() {
    this.fetchPastAssignments({
      subjectId: this.subjectId,
      subjectClassId: this.classId,
    });
    this.fetchOngoingAssignments({
      subjectId: this.subjectId,
      subjectClassId: this.classId,
    });
    this.$mixpanel.trackEvent('Assignments overview - Open', {
      source: this.source || '',
    });
  },
  methods: {
    writeOngoingAssignmentsPageNumber() {
      return this.onGoingAssignmentsPage > 1 ? `page ${this.onGoingAssignmentsPage}` : '';
    },
    writePastAssignmentsPageNumber() {
      return this.pastAssignmentsPage > 1 ? `page ${this.pastAssignmentsPage}` : '';
    },
    isDeadlineSoon(assignmentOccasion) {
      if (assignmentOccasion.is_completed) {
        return false;
      }

      const assignmentDeadline = new Date(assignmentOccasion.assignment.deadline);
      const now = Date.now();
      const soon = 1000 * 60 * 60 * 24;
      const then = assignmentDeadline.getTime();

      return now + soon > then;
    },
    humanUnderstandableDate(deadline) {
      return humanUnderstandableDateTime(deadline);
    },
    isReading(assignment) {
      return isReadingType(assignment.assignment_type);
    },
    isQuestion(assignment) {
      return isQuestionType(assignment.assignment_type);
    },
    isExamStyle(assignment) {
      return isExamStyleType(assignment.assignment_type);
    },
    iconDetails(assignment) {
      return getIconDetails(assignment.assignment_type);
    },
    isTopAligned(rowModelId) {
      // row is top-aligned if at least one cell within spreads on multiple lines
      return this.topAlignedRows[rowModelId] > 0;
    },
    scoreText(occasion, forScreenreader) {
      const correctCount = occasion.practice_occasion.correct_answer_count;
      const totalCount = occasion.practice_occasion.total_answer_count;
      if (forScreenreader) {
        return `${correctCount} out of ${totalCount} correct answers. Assignment completed.`;
      }
      return `${correctCount}/${totalCount} (Completed)`;
    },
    goToAssignmentResult(assignment) {
      const { $router, subjectId, classId, subjectClassSlug: classSlug } = this;
      $router.push(
        getQuestionAssignmentResultRoute({
          assignment,
          classId,
          classSlug,
          subjectId,
        }),
      );
    },
    takeAssignment(assignment) {
      const { $router, subjectId, classId, subjectClassSlug: classSlug } = this;
      $router.push(
        getTakeAssignmentRoute({
          assignment,
          subjectId,
          classId,
          classSlug,
          source: 'assignment_list',
        }),
      );
    },
    updateTopAligned(rowModelId, isCellMultiline) {
      this.topAlignedRows[rowModelId] = this.topAlignedRows[rowModelId] || 0;
      if (isCellMultiline) {
        this.topAlignedRows = {
          ...this.topAlignedRows,
          [rowModelId]: this.topAlignedRows[rowModelId] + 1,
        };
      } else {
        this.topAlignedRows = {
          ...this.topAlignedRows,
          [rowModelId]: this.topAlignedRows[rowModelId] - 1,
        };
      }
    },
    goToOngoingAssignmentsPage(page) {
      this.fetchOngoingAssignments({
        subjectId: this.subjectId,
        subjectClassId: this.classId,
        page,
      });
      this.onGoingAssignmentsPage = page;
      this.$refs.ongoingAssignmentsHeading.focus();
    },
    goToPastAssignmentsPage(page) {
      this.fetchPastAssignments({
        subjectId: this.subjectId,
        page,
        subjectClassId: this.classId,
      });
      this.pastAssignmentsPage = page;
      this.$refs.pastAssignmentsHeading.focus();
    },
    getReadingAssignmentScore(occasion, accessibleVersion = false) {
      const total = occasion.readingitem_set.length;
      const completed = occasion.readingitem_set.reduce((acc, readingItem) => {
        let count = acc;
        if (readingItem.node_progress.completed || readingItem.node_progress.first_completed_at) {
          count += 1;
        }
        return count;
      }, 0);
      if (accessibleVersion) {
        return `${completed} out of ${total} ${pluralize('section', completed)} completed`;
      }
      return `${completed}/${total} ${pluralize('section', completed)} completed`;
    },
    isQuestionAssignmentLate(occasion) {
      return (
        occasion.assignment.soft_deadline &&
        !occasion.practice_occasion.is_done &&
        this.isQuestion(occasion.assignment)
      );
    },
    getQuestionAssignmentScore(occasion) {
      const { correct_answer_count: correct, total_answer_count: total } =
        occasion.practice_occasion;
      return `${correct}/${total} ${pluralize('question', correct)} correct`;
    },
    isScoreVisible(assignmentOccasion) {
      const { deadline, answers } = useAssignmentReadableSettings(assignmentOccasion.assignment);
      return deadline.hasPassed || answers.displayAfterSubmission || answers.displayImmediately;
    },
    isPastAssignmentClickable(occasion) {
      return (
        this.isReading(occasion.assignment) ||
        this.isExamStyle(occasion.assignment) ||
        (occasion.practice_occasion && occasion.practice_occasion.is_done) ||
        occasion.assignment.soft_deadline
      );
    },
    handleAssignmentClick(assignmentOccasion) {
      const { assignment, is_completed: isCompleted } = assignmentOccasion;

      if (this.isQuestion(assignment)) {
        if (isCompleted) {
          this.goToAssignmentResult(assignment);
        } else if (
          !assignment.has_deadline_passed ||
          (assignment.has_deadline_passed && assignment.soft_deadline)
        ) {
          this.takeAssignment(assignment);
        }
        return;
      }
      this.takeAssignment(assignment);
    },
    date(value) {
      return value ? format(new Date(value), 'YYYY-MM-DD HH:mm') : '';
    },
  },
};
</script>

<style scoped>
.AssignmentsOverview-ongoingAssignmentName,
.AssignmentsOverview-pastAssignmentName--clickable {
  color: var(--kog-link-color);
}

.AssignmentsOverview-ongoingAssignmentName:hover,
.AssignmentsOverview-pastAssignmentName--clickable:hover {
  color: var(--kog-link-hover-color);
}
</style>
