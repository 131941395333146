<template>
  <div v-if="isRubricsVisible">
    <div
      class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter margin-top-m margin-bottom-m"
    >
      <div class="Rubrics-heading heading-xxs font-satoshi">
        {{ isInteractive ? 'Select marks' : 'Rubric' }}
      </div>
      <div
        v-if="isShowingToggle"
        class="flexContainer flexContainer-alignCenter"
      >
        <span aria-hidden="true"> Show criteria </span>
        <kog-toggle-button
          class="Rubrics-criteriaToggle margin-left-xs"
          button-role="expander"
          :is-pressed="isCriteriaExpanded"
          aria-label="Show criteria"
          @toggled="onToggleCriteria"
        />
      </div>
    </div>
    <kog-item-list
      :role="isInteractive ? 'radiogroup' : 'list'"
      class="Rubrics-items-container"
    >
      <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
      <div
        v-for="(rubric, index) in rubrics"
        :key="rubric.id"
        :role="isInteractive ? 'radio' : 'listitem'"
        class="Rubrics-item padd-xs font-satoshi"
        :class="{
          'Rubrics-item--selected': isRubricChecked(rubric.points),
          'Rubrics-item--interactive': isInteractive,
        }"
        :aria-checked="isInteractive ? `${isRubricChecked(rubric.points)}` : null"
        :tabindex="isInteractive ? getTabIndex(rubric.points, index) : null"
        :aria-describedby="isInteractive ? `rubric-${rubric.id}-explanation` : null"
        :aria-label="isInteractive ? `${rubric.points} marks` : null"
        @click="onRubricSelected(rubric.points)"
        @keydown.space.prevent="onRubricSelected(rubric.points)"
        @keydown.enter.prevent="onRubricSelected(rubric.points)"
      >
        <div
          class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter"
          :aria-hidden="isInteractive ? 'true' : null"
        >
          <span class="Rubrics-points heading-xxs"> {{ rubric.points }} Marks </span>
          <kog-radio
            v-if="isInteractive"
            :value="`${rubric.points}`"
            :label="`${rubric.points} marks`"
            :is-label-hidden="true"
            :is-checked="isRubricChecked(rubric.points)"
            :option="{
              text: `${rubric.points} marks`,
              value: rubric.points,
            }"
            @checked="onRubricSelected(rubric.points)"
          />
        </div>
        <vertical-expand>
          <div
            v-if="isCriteriaExpanded"
            :id="`rubric-${rubric.id}-explanation`"
            class="Rubrics-criteria margin-top-xs"
          >
            {{ rubric.explanation }}
          </div>
        </vertical-expand>
      </div>
    </kog-item-list>
  </div>
</template>

<script>
import isNil from 'lodash/isNil.js';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogToggleButton from 'sharedApp/components/base/buttons/kog-toggle-button.vue';
import KogRadio from 'sharedApp/components/base/radio/kog-radio.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';

export default {
  name: 'Rubrics',
  components: {
    KogItemList,
    KogRadio,
    KogToggleButton,
    VerticalExpand,
  },
  props: {
    rubrics: {
      type: Array,
      default: () => [],
    },
    isInteractive: {
      type: Boolean,
      default: false,
    },
    isShowingToggle: {
      type: Boolean,
      default: false,
    },
    selectedRubricValue: {
      type: Number,
      default: null,
    },
  },
  emits: ['rubric-selected'],
  data() {
    return {
      isCriteriaExpanded: true,
    };
  },
  computed: {
    isRubricsVisible() {
      return this.rubrics.length > 0;
    },
    isAnyRubricChecked() {
      return this.rubrics.some(rubric => this.isRubricChecked(rubric.points));
    },
  },
  methods: {
    onToggleCriteria() {
      this.isCriteriaExpanded = !this.isCriteriaExpanded;
    },
    isRubricChecked(rubricPoints) {
      return !isNil(this.selectedRubricValue) && this.selectedRubricValue === rubricPoints;
    },
    getTabIndex(rubricPoints, index) {
      if (!this.isAnyRubricChecked) {
        return index === 0 ? 0 : -1;
      }

      return this.isRubricChecked(rubricPoints) ? 0 : -1;
    },
    onRubricSelected(value) {
      if (!this.isInteractive || value === this.selectedRubricValue) {
        return;
      }

      this.$emit('rubric-selected', { marks: value });
    },
  },
};
</script>

<style scoped>
.Rubrics-items-container {
  display: flex;
  gap: 4px;
}
.Rubrics-item {
  overflow: auto;
  flex: 1 1 0;
  background-color: var(--kog-rubric-background);
  border-radius: 8px;
}

.Rubrics-item--selected {
  cursor: default !important;
  background-color: var(--kog-selectable-card-selected-background-color);
  border: 1px solid var(--kog-selectable-card-selected-border-color);
}

.Rubrics-item--interactive {
  cursor: pointer;
}

.Rubrics-criteria {
  white-space: pre-line;
}
</style>
