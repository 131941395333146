export default function isCoordinateWithinElementBoundingRectangle(mouseX, mouseY, htmlElement) {
  const boundingBox = htmlElement.getBoundingClientRect();
  if (
    boundingBox.x <= mouseX &&
    mouseX <= boundingBox.x + boundingBox.width &&
    boundingBox.y <= mouseY &&
    mouseY <= boundingBox.y + boundingBox.height
  ) {
    return true;
  }
  return false;
}
