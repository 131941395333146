import { Plugin } from '@ckeditor/ckeditor5-core';

import SpacingUI from './spacing-ui.ts';

export default class Spacing extends Plugin {
  static get requires() {
    return [SpacingUI] as const;
  }

  static get pluginName() {
    return 'Spacing' as const;
  }
}
