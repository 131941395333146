import axios from 'sharedApp/vue-utils/kog-axios.ts';

const baseUrl = '/api/content-boxes/';

export async function createContentBoxTemplate({
  name,
  title,
  icon,
  color,
  status,
  educationLevel,
  subjectIds,
  isTeacherOnly,
}) {
  const payload = {
    name,
    title,
    icon,
    color,
    status,
    education_level: educationLevel,
    subjects: subjectIds,
    is_teacher_only: isTeacherOnly,
  };
  const response = await axios.post(baseUrl, payload);
  return response.data;
}

export async function fetchContentBoxTemplates({
  subjectId,
  status,
  templateIds,
  excludeTeacherOnlyBox,
} = {}) {
  const url = new URL(baseUrl, window.location.origin);

  if (subjectId) {
    url.searchParams.append('subjects', subjectId);
  }

  if (status) {
    url.searchParams.append('status', status);
  }

  if (templateIds) {
    url.searchParams.append('id__in', templateIds);
  }

  if (excludeTeacherOnlyBox) {
    url.searchParams.append('exclude_teacher_only_box', true);
  }
  const response = await axios.get(url);
  return response.data;
}

export async function fetchContentBoxTemplate(id) {
  const url = `${baseUrl}${id}/`;
  const response = await axios.get(url);
  return response.data;
}

export async function updateContentBoxTemplate(template) {
  const url = `${baseUrl}${template.id}/`;
  const response = await axios.put(url, template);
  return response.data;
}
