<template>
  <div>
    <draggable
      v-model="internalSections"
      animation="150"
      :item-key="section => section"
    >
      <template #item="{ element: section, index }">
        <div class="flexContainer flexContainer-alignCenter cursorGrab">
          <div class="ArrangeList_tableRowNum"> {{ index + 1 }}. </div>
          <div
            class="inlineflexContainer flexContainer-alignCenter ArrangeList_tableRow flexChild-canGrow"
            :class="{
              'ArrangeList_tableRow--first': index === 0,
              'ArrangeList_tableRow--last': index === internalSections.length - 1,
              'ArrangeList_tableRow--only': internalSections.length === 1,
            }"
          >
            <div class="flexTable__item flexTable__item-size-10 ArrangeSectionsList_section">
              {{ section.number_including_ancestors }} {{ section.name }}
              <content-hidden-label v-if="hiddenSubjectNodeIds.includes(section.id)" />
            </div>
            <div class="flexTable__item flexTable__item-size-1 text-right kogPlatformGray064">
              <i
                class="fas fa-times cursorPointer padd-xs"
                @click="removeSection(section)"
              />
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';

export default {
  name: 'ArrangeSectionsList',
  components: {
    ContentHiddenLabel,
    Draggable,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
    removeSection: {
      type: Function,
      required: true,
    },
    updateSections: {
      type: Function,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    hiddenSubjectNodeIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    internalSections: {
      get() {
        if (!this.expanded) {
          return this.sections.slice(0, 1);
        }
        return this.sections;
      },
      set(newArray) {
        this.updateSections(newArray);
      },
    },
  },
};
</script>

<style scoped>
.ArrangeSectionsList_section {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ArrangeSectionsList_section p {
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
}
</style>
