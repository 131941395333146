<template>
  <div>
    <p class="divider-top padd-top-xxl margin-top-1 heading-xxs margin-bottom-xxs">
      Your answer - {{ practiceItem.user_answer.is_correct ? 'correct' : 'incorrect' }}
    </p>
    <!-- eslint-disable vue/no-v-html -->
    <p
      class="alert list-group-item"
      :class="{
        PracticeItemResult__correctUserChoice: practiceItem.user_answer.is_correct,
        PracticeItemResult__incorrectUserChoice: !practiceItem.user_answer.is_correct,
      }"
      v-html="practiceItem.user_answer.user_text || '(No answer given)'"
    />
    <!-- eslint-enable vue/no-v-html -->
    <kog-short-text-question-correct-answers
      v-if="practiceItem.solution"
      :correct-answers="practiceItem.solution.answer_list"
      :secondary-accepted-answers="practiceItem.solution.secondary_accepted_answers"
      :answer-explanation-html="practiceItem.solution.answer_explanation_html"
      :title-classes="['margin-top-xxl', 'heading-xxs', 'margin-bottom-xxs']"
    />
  </div>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import KogShortTextQuestionCorrectAnswers from 'sharedApp/components/questions/kog-short-text-question-correct-answers.vue';

export default {
  name: 'KogShortTextQuestionResult',
  components: {
    KogShortTextQuestionCorrectAnswers,
  },
  props: {
    practiceItem: {
      type: Object,
      required: true,
    },
  },
};
</script>
