import { addListToDropdown, createDropdown, Model } from '@ckeditor/ckeditor5-ui';
import { Collection } from '@ckeditor/ckeditor5-utils';

import KogForm from '../utils/kogform.js';

const levels = [
  ['None (span)', 'span'],
  ['h2', 'heading2'],
  ['h3', 'heading3'],
  ['h4', 'heading4'],
];
export const DEFAULT_BOX_TYPE_LABEL = 'Content box template';
export const DEFAULT_HEADING_LEVEL_LABEL = 'Box title tag';

function contentBoxTemplatesMapToDropDownItems(contentBoxTemplateMap) {
  return Object.entries(contentBoxTemplateMap).map(([id, { title }]) => [title, id]);
}

export default class FormView extends KogForm {
  constructor(locale, contentBoxTemplateMap) {
    super(locale);

    this.contentBoxItems = contentBoxTemplatesMapToDropDownItems(contentBoxTemplateMap);
    this.boxTypeDropdownView = this.createDropdown(DEFAULT_BOX_TYPE_LABEL, this.contentBoxItems);
    this.headingLevelDropdownView = this.createDropdown(DEFAULT_HEADING_LEVEL_LABEL, levels);

    this.saveButtonView = this.createSaveButton();
    this.cancelButtonView = this.createCancelButton();
    this.saveButtonView.set({ isEnabled: false });

    this.templateId = null;
    this.boxTypeDropdownView.on(
      'execute',
      function boxTypeSelected(event) {
        this.boxTypeDropdownView.buttonView.set({ label: event.source.label });
        this.templateId = event.source.key;
        this.updateUI();
      }.bind(this),
    );

    this.titleTag = null;
    this.headingLevelDropdownView.on(
      'execute',
      function headingLevelSelected(event) {
        this.headingLevelDropdownView.buttonView.set({ label: event.source.label });
        this.titleTag = event.source.key;
        this.updateUI();
      }.bind(this),
    );

    this.childViews = this.createCollection([
      this.boxTypeDropdownView,
      this.headingLevelDropdownView,
      this.saveButtonView,
      this.cancelButtonView,
    ]);

    this.setup('content-box');
  }

  focus() {
    this.boxTypeDropdownView.focus();
  }

  updateUI() {
    const isEnabled = this.templateId !== null && this.titleTag !== null;
    this.saveButtonView.set({ isEnabled });
  }

  reset() {
    super.reset();
    this.templateId = null;
    this.titleTag = null;
    this.headingLevelDropdownView.buttonView.set({ label: DEFAULT_HEADING_LEVEL_LABEL });
    this.boxTypeDropdownView.buttonView.set({ label: DEFAULT_BOX_TYPE_LABEL });
    this.saveButtonView.set({ isEnabled: false });
  }

  createDropdown(label, items) {
    const withText = true;
    const dropdown = createDropdown(this.locale);
    dropdown.buttonView.set({ label, withText });

    const collectionItems = new Collection();

    items.forEach(item => {
      collectionItems.add({
        type: 'button',
        model: new Model({
          withText: true,
          label: item[0],
          key: item[1],
        }),
      });
    });

    addListToDropdown(dropdown, collectionItems);

    return dropdown;
  }

  setFields({ templateId, titleTag }) {
    const selectedTemplate = this.contentBoxItems.find(item => item[1] === `${templateId}`);
    const selectedTitleTag = levels.find(item => {
      if (titleTag === 'span') {
        return item[1] === titleTag;
      }
      return item[0] === titleTag;
    });
    this.templateId = selectedTemplate[1];
    this.boxTypeDropdownView.buttonView.set({ label: selectedTemplate[0] });
    this.titleTag = selectedTitleTag[1];
    this.headingLevelDropdownView.buttonView.set({ label: selectedTitleTag[0] });
  }
}
