<template>
  <div class="flexContainer flexContainer-wrap gap-m">
    <interval-selector
      v-if="isDateFilterEnabled"
      v-model:selected-value="filters.datesInterval"
      class="TeacherInsightsFilterPanel-filterSelect"
      label="Selected interval"
      :is-label-hidden="true"
      @change="applyFilters"
      @date-interval-selected="onDateIntervalSelected"
    />
    <kog-select
      v-if="isStudentFilterEnabled"
      v-model:selected-value="filters.student"
      class="TeacherInsightsFilterPanel-filterSelect"
      label="Selected student"
      :is-label-hidden="true"
      :options="studentsOptions"
      icon="fa-user"
      @change="applyFilters"
    />
    <kog-select
      v-if="isSubjectNodeFilterEnabled"
      v-model:selected-value="filters.subjectNode"
      class="TeacherInsightsFilterPanel-filterSelect"
      :label="`Selected ${$term('topic')}`"
      :is-label-hidden="true"
      :options="topicOptions"
      icon="fa-book"
      @change="applyFilters"
    />
    <kog-select
      v-if="isActivitiesFilterEnabled"
      class="TeacherInsightsFilterPanel-filterSelect"
      label="Selected activities"
      :is-label-hidden="true"
      dropdown-item-type="checkbox"
      placeholder-text="Activity from all available features"
      :options="activityTypes"
      icon="fa-puzzle"
      @change="onActivityTypeChecked"
    />
  </div>
</template>
<script>
import isEqual from 'lodash/isEqual.js';
import { mapGetters } from 'vuex';

import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import IntervalSelector, {
  parseDateInterval,
  SHOW_ALL_DATES,
} from 'sharedApp/components/datetime/interval-selector.vue';
import { PRACTICE_OCCASION_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

const getActivityTypes = $term => [
  {
    text: 'Filter by Activity',
    options: [
      {
        value: PRACTICE_OCCASION_TYPES.EXERCISE,
        text: 'Textbook questions',
      },
      {
        value: PRACTICE_OCCASION_TYPES.STRENGTH_TEST,
        text: 'Strength tests',
      },
      {
        value: PRACTICE_OCCASION_TYPES.STRENGTH_BATTLE,
        text: 'Strength battles',
      },
      {
        value: PRACTICE_OCCASION_TYPES.ASSIGNMENT,
        text: 'Question assignments',
      },
      {
        value: PRACTICE_OCCASION_TYPES.SECTION_CHECKPOINT,
        text: 'Book assignments (not available)',
        disabled: true,
      },
      {
        value: 'ESQ_ASSIGNMENT',
        text: `${$term('exam-style')} question assignments (not available)`,
        disabled: true,
      },
    ],
  },
];

export default {
  name: 'TeacherInsightsFilterPanel',
  components: {
    IntervalSelector,
    KogSelect,
  },
  props: {
    students: {
      type: Array,
      default: () => [],
    },
    subjectTree: {
      type: Object,
      default: () => ({}),
    },
    maxSubjectTreeLevelsShown: {
      type: Number,
      default: 1,
    },
    persistenceKey: {
      type: String,
      default: '',
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    isNgss: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['apply-filters'],
  data() {
    const activityTypes = getActivityTypes(this.$term);
    return {
      filters: {
        student: '',
        subjectNode: '',
        activities: [],
        datesInterval: SHOW_ALL_DATES,
        fromDate: '',
        toDate: '',
      },
      persistedFilterKeys: ['student', 'subjectNode', 'datesInterval', 'activities'],
      activityTypes,
    };
  },
  computed: {
    ...mapGetters({
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    isDateFilterEnabled() {
      return this.filterOptions.includes('date');
    },
    isStudentFilterEnabled() {
      return this.filterOptions.includes('student');
    },
    isSubjectNodeFilterEnabled() {
      return this.filterOptions.includes('topic');
    },
    isActivitiesFilterEnabled() {
      return this.filterOptions.includes('activity');
    },
    studentsOptions() {
      const defaultOptions = {
        text: 'Default',
        options: [
          {
            text: 'Show all students',
            value: '',
          },
        ],
      };
      const studentOptions = {
        text: 'Filter by student',
        options: this.students.map(student => ({
          value: student.id,
          text: `${student.first_name} ${student.last_name}`,
        })),
      };
      return [defaultOptions, studentOptions];
    },
    topicOptions() {
      const defaultOptions = {
        text: 'Default',
        options: [
          {
            text: `Show all ${this.$term('topic')}s in subject`,
            value: '',
          },
        ],
      };

      const topicOptionsWithChildren = {
        text: `Filter by ${this.$term('topic')}`,
        options: [...this.buildNodeOptions(this.subjectTree, this.maxSubjectTreeLevelsShown)],
      };

      return [defaultOptions, topicOptionsWithChildren];
    },
    selectedActivities() {
      return this.activityTypes[0].options
        .filter(activity => !activity.disabled && activity.isChecked)
        .map(activity => activity.value);
    },
    filtersFromRoute() {
      let filters;
      const urlFilters = this.$route.query.filters;
      if (urlFilters) {
        try {
          filters = JSON.parse(urlFilters);
        } catch {
          // ignore. url may have been cut off
        }
      }
      return filters;
    },
    filtersFromLocalStorage() {
      const filters = studyAppLocalStorage.getLastUsedFiltersByKey(this.persistenceKey);
      return filters;
    },
    persistedFilters() {
      return this.filtersFromRoute || this.filtersFromLocalStorage;
    },
  },
  watch: {
    '$route.query': {
      handler(routeQuery) {
        const activeTab = routeQuery.active_tab;
        if (!this.persistenceKey.includes(activeTab)) {
          // ignoring this route notification; it is for the previously selected tab
          return;
        }
        this.applyPersistedFilters();
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.isNgss) {
      this.activityTypes[0].options[0].text = 'Knowledge checks (in Book)';
    } else {
      this.activityTypes[0].options[0].text = 'Exercise questions (in Book)';
    }
  },
  methods: {
    buildNodeOptions(subjectNode, maxLevel) {
      if (subjectNode.level === maxLevel) {
        return null;
      }

      if (subjectNode.is_special_introduction_node) {
        return null;
      }

      const options = subjectNode.children
        .filter(sn => !sn.is_special_introduction_node)
        .map(node => ({
          options: this.buildNodeOptions(node, maxLevel),
          value: node.id,
          text: `${node.formatted_number_including_ancestors} ${node.name}`,
          hasTag: !this.isNodeVisible(node.id),
          tagProps: {
            type: 'warning',
            size: 's',
            label: 'Hidden',
          },
        }));
      return options;
    },
    onActivityTypeChecked(activityType) {
      const activityToToggle = this.activityTypes[0].options.find(
        activity => activity.value === activityType,
      );

      activityToToggle.isChecked = !activityToToggle.isChecked;

      this.activityTypes = [...this.activityTypes];
      this.filters.activities = this.selectedActivities;
      this.applyFilters({ isUserTriggered: true });
    },
    onDateIntervalSelected({ fromDateStr, toDateStr }) {
      this.filters.fromDate = fromDateStr;
      this.filters.toDate = toDateStr;
    },
    applyFilters({ isUserTriggered = true }) {
      this.persistFilters(this.filters);
      this.$emit('apply-filters', { filters: this.filters, isUserTriggered });
    },
    applyPersistedFilters() {
      const filters = this.persistedFilters;
      let didModifyFilters = false;
      if (!filters) {
        return;
      }

      Object.keys(filters).forEach(key => {
        if (this.persistedFilterKeys.includes(key) && !isEqual(filters[key], this.filters[key])) {
          this.filters[key] = filters[key];
          if (key === 'datesInterval') {
            this.onDateIntervalSelected(parseDateInterval(filters[key]));
          }
          didModifyFilters = true;
        }
      });

      this.activityTypes[0].options
        .filter(activity => this.filters.activities.includes(activity.value))
        .forEach(activity => {
          const option = activity;
          option.isChecked = true;
        });

      if (didModifyFilters) {
        this.applyFilters({ isUserTriggered: false });
      }
    },
    filtersToPersist(filters) {
      const cleanedFilters = {};
      Object.keys(filters).forEach(key => {
        if (this.persistedFilterKeys.includes(key)) {
          cleanedFilters[key] = filters[key];
        }
      });
      return cleanedFilters;
    },
    persistFilters(filters) {
      const cleanedFilters = this.filtersToPersist(filters);

      const newQuery = { ...this.$route.query, filters: JSON.stringify(cleanedFilters) };
      if (!isEqual(newQuery, this.$route.query)) {
        this.$router.replace({ query: newQuery });
      }
      studyAppLocalStorage.setLastUsedFiltersForKey(this.persistenceKey, cleanedFilters);
    },
  },
};
</script>

<style scoped>
.TeacherInsightsFilterPanel-filterSelect {
  width: 212px;
}
</style>
