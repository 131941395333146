<template>
  <div>
    <div class="EmptyTable flexContainer flexContainer-column flexContainer-alignCenter width-full">
      <h4 class="margin-0"> No teachers </h4>
      <p class="margin-top-fine-2 margin-bottom-fine-2 text-center">
        This class does not have any teachers assigned to it.
      </p>
      <button
        :disabled="isSchoolTeachersLoading"
        class="KogButtonLegacy KogButtonLegacy--primary"
        @click="openAddTeacherModal"
      >
        Add teachers
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KogTeachersEmptySection',
  props: {
    openAddTeacherModal: {
      type: Function,
      required: true,
    },
    isSchoolTeachersLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.EmptyTable {
  padding: 20px 40px 15px 40px;
  background-color: var(--kogPlatformGray096);
  border-radius: 5px;
}
</style>
