<template>
  <div
    class="flexContainer flexContainer-column"
    :class="{
      'u-stickyContentFrame': !isInReviewMode,
    }"
  >
    <annotate-text
      class="ActivitySheetExternalContentFrame-activityContent height-full"
      :class="{
        'u-collapsed': collapsed,
        'ActivitySheetExternalContentFrame-activityContent--unsetMinHeight': noMinHeight,
      }"
      :content="frameContent"
      :subject="subject"
      :user="user"
      :subject-tree="subject.subject_tree"
      :is-in-review-mode="isInReviewMode"
    />
    <kog-round-button
      v-if="!isInReviewMode && mq.current !== 'xl'"
      class="ActivitySheetExternalContentFrame-activityContent-sizeButton"
      :icon-class="buttonIcon"
      size="small"
      button-style="primary"
      aria-label="Resize content frame"
      @click="toggleCollapsed"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AnnotateText from 'learning/common/components/annotate-text.vue';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';

export default {
  name: 'ActivitySheetExternalContentFrame',
  components: {
    AnnotateText,
    KogRoundButton,
  },
  inject: ['mq'],
  props: {
    contentFrame: {
      type: Object,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
    noMinHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    frameContent() {
      return `<iframe src="${this.contentFrame.frame_content}" width="100%" height="100%"></iframe>`;
    },
    buttonIcon() {
      return this.collapsed ? 'fa-chevron-down' : 'fa-chevron-up';
    },
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style scoped>
.ActivitySheetExternalContentFrame-activityContent {
  min-height: 60vh;
  transition: all 300ms ease;
}

.ActivitySheetExternalContentFrame-activityContent.u-collapsed {
  min-height: 1vh;
}

.ActivitySheetExternalContentFrame-activityContent-sizeButton {
  transform: translateY(-50%);
  align-self: center;
  min-height: 32px;
}

.ActivitySheetExternalContentFrame-activityContent--unsetMinHeight {
  min-height: unset;
}

@media (--viewport-l) {
  .u-stickyContentFrame {
    position: sticky;
    z-index: 10;
    top: 0;
  }
}
</style>
