<template>
  <div
    class="KogButtonSet KogButtonSet--sides padd-top-s kogPlatformGray093-bg padd-right-l padd-left-l padd-bottom-l"
  >
    <div>
      <router-link
        :disabled="!previousPageRoute ? 'true' : null"
        :to="!!previousPageRoute ? previousPageRoute : '#'"
        class="KogButtonLegacy KogButtonLegacy--iconLeft"
      >
        <i class="fas fa-chevron-left" /> Previous
      </router-link>
    </div>
    <div>
      <router-link
        :disabled="!nextPageRoute ? 'true' : null"
        :to="!!nextPageRoute ? nextPageRoute : '#'"
        class="KogButtonLegacy KogButtonLegacy--iconRight"
      >
        Next <i class="fas fa-chevron-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

export default {
  name: 'SectionPager',
  mixins: [RoutesMixin],
  computed: {
    ...mapGetters({
      leafNodesWithContent: 'subjectModule/leafNodesWithContent',
    }),
    currentLeafNodeIndex() {
      return this.leafNodesWithContent.findIndex(ln => ln.id === this.nodeId);
    },
    previousLeafNode() {
      return this.leafNodesWithContent[this.currentLeafNodeIndex - 1] ?? null;
    },
    nextLeafNode() {
      return this.leafNodesWithContent[this.currentLeafNodeIndex + 1] ?? null;
    },
    routeName() {
      if (this.$route.meta.isReviewMode) {
        return 'reviewMode';
      }
      return 'classBook';
    },
    previousPageRoute() {
      if (this.previousLeafNode) {
        return {
          name: this.routeName,
          params: {
            classSlug: this.subjectClassSlug,
            sid: this.subjectId,
            cid: this.classId,
            nodeSlug: this.previousLeafNode.slug,
            nodeId: this.previousLeafNode.id,
          },
        };
      }
      return null;
    },
    nextPageRoute() {
      if (this.nextLeafNode) {
        return {
          name: this.routeName,
          params: {
            classSlug: this.subjectClassSlug,
            sid: this.subjectId,
            cid: this.classId,
            nodeSlug: this.nextLeafNode.slug,
            nodeId: this.nextLeafNode.id,
          },
        };
      }
      return null;
    },
  },
};
</script>
