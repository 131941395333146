<template>
  <div>
    <template v-if="drivingQuestion.images.length > 0">
      <div class="flexContainer flexContainer-justifyCenter flexContainer-alignEnd margin-bottom-s">
        <flicking
          v-if="drivingQuestion.images.length > 1"
          class="padd-bottom-l"
          :options="{ circular: true }"
          :plugins="flickingPlugins"
          @changed="onChange"
        >
          <image-box
            v-for="image in drivingQuestion.images"
            :key="image.id"
            v-kog-clickable="openFullscreen"
            class="flexChild-center"
            :src="image.image"
            :transparent-background="true"
            :h="maxHeight"
          />
          <template #viewport>
            <div class="flicking-pagination"></div>
            <button
              class="flicking-arrow-prev is-thin is-circle DrivingQuestionImage-flickingNavigation DrivingQuestionImage-flickingNavigationPrevious"
            />
            <button
              class="flicking-arrow-next is-thin is-circle DrivingQuestionImage-flickingNavigation DrivingQuestionImage-flickingNavigationNext"
            />
          </template>
        </flicking>
        <image-box
          v-else
          v-kog-clickable="openFullscreen"
          :src="drivingQuestion.images[0].image"
          :transparent-background="true"
          :h="maxHeight"
          class="flexChild-center"
        />
        <div class="DrivingQuestionImage-buttons flexContainer flexContainer-row">
          <kog-round-button
            v-if="user.isTeacher()"
            class="DrivingQuestionImage-buttons-upload"
            aria-label="Upload additional images"
            button-style="basic"
            icon-class="fa-upload"
            size="x-small"
            @click="openDrivingQuestionUploadModal"
          />
          <kog-round-button
            class="DrivingQuestionImage-buttons-fullScreen"
            aria-label="Open image in fullscreen"
            button-style="basic"
            icon-class="fa-expand"
            size="x-small"
            @click="openFullscreen"
          />
        </div>
      </div>
    </template>
    <kog-anchor
      v-if="!!drivingQuestion.external_url"
      class="padd-left-0 padd-right-0"
      label="Open external link to the driving question board"
      :url="drivingQuestion.external_url"
      icon="far fa-arrow-up-right-from-square"
      theme="primary"
      target="_blank"
      @before-navigation="trackOpenExternalLink"
    />
  </div>
</template>

<script>
import { Arrow, Pagination } from '@egjs/flicking-plugins';
import Flicking from '@egjs/vue3-flicking';
import { mapState } from 'vuex';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogAnchor from 'sharedApp/components/core/kog-anchor.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import ResourceFullscreen from 'sharedApp/components/resource-fullscreen/resource-fullscreen.vue';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import DrivingQuestionUploadModal from 'studyApp/components/driving-question/driving-question-upload-modal.vue';

export const DRIVING_QUESTION_MIXPANEL_EVENT = {
  OPEN_IMAGE_FULLSCREEN: 'Driving Question Board - Open image Fullscreen',
  OPEN_EXTERNAL_LINK: 'Driving Question Board - Open external link',
};

export default {
  name: 'DrivingQuestionImage',
  directives: { KogClickable },
  components: {
    KogAnchor,
    Flicking,
    ImageBox,
    KogRoundButton,
  },
  mixins: [RoutesMixin],
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
    drivingQuestion: {
      type: Object,
      required: true,
    },
    maxHeight: {
      type: Number,
      default: 384,
    },
  },
  data() {
    return {
      flickingPlugins: [new Arrow(), new Pagination({ type: 'bullet' })],
      currentImageIndex: 0,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    mixpanelTrackingProps() {
      return {
        driving_question_id: this.drivingQuestion.id,
        source: this.$route.name === 'subjectClassOverview' ? 'overview' : 'book',
      };
    },
  },
  methods: {
    onChange(event) {
      this.currentImageIndex = event.index;
    },
    openFullscreen() {
      this.$mixpanel.trackEvent(
        DRIVING_QUESTION_MIXPANEL_EVENT.OPEN_IMAGE_FULLSCREEN,
        this.mixpanelTrackingProps,
      );
      this.$modal(ResourceFullscreen, {
        url: this.drivingQuestion.images[this.currentImageIndex].image,
        type: 'image',
      });
    },
    openDrivingQuestionUploadModal() {
      this.$modal(
        DrivingQuestionUploadModal,
        {
          subjectNodeId: this.subjectNodeId,
        },
        { closeOnClickOutside: false },
      );
    },
    trackOpenExternalLink() {
      this.$mixpanel.trackEvent(
        DRIVING_QUESTION_MIXPANEL_EVENT.OPEN_EXTERNAL_LINK,
        this.mixpanelTrackingProps,
      );
    },
  },
};
</script>

<style scoped>
@import url('~@egjs/vue3-flicking/dist/flicking.css');
@import url('~@egjs/flicking-plugins/dist/arrow.css');
@import url('~@egjs/flicking-plugins/dist/pagination.css');

.DrivingQuestionImage-flickingNavigation {
  width: var(--space-xxl);
  height: var(--space-xxl);
  background-color: var(--kog-background-brand-900);
  border: 0;
}

.DrivingQuestionImage-buttons {
  position: absolute;
  z-index: 3;
  right: var(--space-l);
}

.DrivingQuestionImage-flickingNavigationNext::before,
.DrivingQuestionImage-flickingNavigationNext::after {
  right: 15px;
  width: 10px;
  height: 2px;
}

.DrivingQuestionImage-flickingNavigationPrevious::after,
.DrivingQuestionImage-flickingNavigationPrevious::before {
  left: 15px;
  width: 10px;
  height: 2px;
}

.DrivingQuestionImage-flickingNavigationNext::before,
.DrivingQuestionImage-flickingNavigationPrevious::before {
  top: calc(50% - 1px);
}

.flicking-pagination {
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: var(--space-l);
}

.flicking-camera {
  align-items: center;
}

.flicking-pagination :deep(.flicking-pagination-bullet) {
  align-items: center;
  border: 1px var(--kog-border-brand-900) solid;
}
.flicking-pagination :deep(.flicking-pagination-bullet-active) {
  background-color: var(--kog-background-brand-900);
}
</style>
