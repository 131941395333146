import { Command } from '@ckeditor/ckeditor5-core';

export default class InsertFileDownloadCommand extends Command {
  execute(configuration) {
    const { model } = this.editor;
    model.change(writer => {
      model.insertObject(this.create(writer, configuration));
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(
      document.selection.getFirstPosition(),
      'fileDownload',
    );
    this.isEnabled = allowedIn !== null;

    const firstRange = document.selection.getFirstRange();
    if (!firstRange.isCollapsed && firstRange.start.nodeAfter?.name === 'fileDownload') {
      const node = firstRange.start.nodeAfter;
      const value = {
        title: node.getAttribute('title'),
        url: node.getAttribute('url'),
        target: node.getAttribute('target'),
      };
      this.value = value;
    } else {
      this.value = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer, { title, url, target }) {
    const fileDownload = writer.createElement('fileDownload', {
      title,
      url,
      target,
    });
    return fileDownload;
  }
}
