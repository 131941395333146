<template>
  <div class="kog-container">
    <div class="kog-row">
      <div class="kog-col-lg-4 text-center hidden-xs hidden-sm">
        <div class="BattleHeader-avatarBox">
          <kog-avatar
            size="l"
            :name="userFullName"
            :src="user.profile.avatar_url"
          />
        </div>
        <div class="heading-xxs">
          {{ userName }}
        </div>
      </div>
      <div class="visible-xs visible-sm text-center heading-xxs margin-bottom-s">
        {{ userName }} vs {{ opponentName }}
      </div>
      <div class="flexContainer flexContainer-column flexContainer-justifyCenter kog-col-lg-4">
        <slot />
      </div>
      <div class="kog-col-lg-4 text-center hidden-xs hidden-sm">
        <div
          v-if="!isOpponentKogBot"
          class="BattleHeader-avatarBox"
        >
          <kog-avatar
            size="l"
            :name="opponentName"
            :src="currentBattle.opponent_avatar_url"
          />
        </div>
        <img
          v-else
          :src="kogBotUrl"
          alt=""
          class="BattleHeader-kogBot"
        />
        <div class="heading-xxs">
          {{ opponentName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';

const kogBot = require('studyApp/images/kogbot.svg');

export default {
  name: 'BattleHeader',
  components: {
    KogAvatar,
  },
  data() {
    return {
      kogBotUrl: kogBot,
    };
  },
  computed: {
    ...mapState('userModule', ['user']),
    ...mapState('battlesModule', ['currentBattle']),
    userName() {
      return this.user.first_name;
    },
    userFullName() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    opponentName() {
      return this.currentBattle.opponent_name;
    },
    isOpponentKogBot() {
      return this.currentBattle.opponent_name === 'Kogbot';
    },
  },
};
</script>

<style scoped>
.BattleHeader-avatarBox {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 100px;
  margin: 0 auto;

  background: var(--kog-card-icon-background);
  border-radius: 50px;
}

.BattleHeader-kogBot {
  height: 100px;
}
</style>
