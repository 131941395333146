<template>
  <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
  <a
    class="NotificationItem KogButtonLegacy--noStyle"
    :role="notification.click_link ? null : 'presentation'"
    :href="notification.click_link ? notification.click_link : null"
    @click="navigate(notification.click_link)"
  >
    <div class="absoluteContainer">
      <div
        :class="{
          'NotificationItem-new': notification.new,
          'NotificationItem-firstUnseen': isFirstNewNotifications,
          'NotificationItem-lastUnseen': isLastNewNotifications,
        }"
      />
    </div>
    <div class="NotificationItem-content">
      <div
        aria-hidden="true"
        class="NotificationItem-iconContainer flexContainer flexContainer-center"
      >
        <i
          v-if="!isEsqAssignmentNotification()"
          :class="[getNotificationIconClass(notification)]"
          class="NotificationItem-icon"
        />
        <svg-icon
          v-if="isEsqAssignmentNotification()"
          class="NotificationItem-icon"
          icon-name="LTQ"
          size="custom"
          custom-size="22"
        />
      </div>
      <div class="NotificationItem-message">
        <span>
          {{ sanitizedMessage }}
        </span>
        <div class="screenreader-only"> Notification sent {{ notification.timesince }} ago </div>
        <div
          aria-hidden="true"
          class="NotificationItem-timeSince"
        >
          {{ notification.timesince }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { unescape } from 'lodash';

import SvgIcon from 'sharedApp/components/icons/svg-icon.vue';

export default {
  name: 'NotificationItem',
  components: {
    SvgIcon,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    isFirstNewNotifications: {
      type: Boolean,
      default: false,
    },
    isLastNewNotifications: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sanitizedMessage() {
      const strippedMessage = this.notification.message
        .replaceAll('<strong>', '')
        .replaceAll('</strong>', '')
        .replaceAll('<br/>', '')
        .replaceAll('<b>', '')
        .replaceAll('</b>', '')
        .replaceAll('<span class="subjectnode-name">', '')
        .replaceAll('</span>', '')
        .replaceAll('<div>', '')
        .replaceAll('</div>', '');
      return unescape(strippedMessage);
    },
  },
  methods: {
    getNotificationIconClass(notification) {
      if (notification.icon_css_class === 'notification-icon-battle-trophy') {
        return 'fas fa-trophy';
      }
      if (notification.icon_css_class) {
        return notification.icon_css_class;
      }
      return 'fas fa-info-circle';
    },
    navigate(url) {
      if (url) {
        window.location.href = url;
      }
    },
    isEsqAssignmentNotification() {
      return this.getNotificationIconClass(this.notification) === 'fas fa-3x fa-file-alt';
    },
  },
};
</script>

<style scoped>
.NotificationItem {
  cursor: pointer;

  display: flex;

  min-width: 300px;

  font-size: var(--kog-font-size-student-notification);
  color: var(--kog-text-default);
  text-decoration: none;

  background-color: var(--kogPlatformWhite);
  border-bottom: 1px solid var(--kogPlatformGray084);
}

.NotificationItem-new {
  position: absolute;
  left: 0;

  width: 3px;
  height: 100%;

  background: var(--kog-notification-item-unseen-border);
}

.NotificationItem:hover {
  color: var(--kog-text-default);
  background-color: var(--kog-list-item-hover-background);
}

.NotificationItem-firstUnseen {
  border-top-right-radius: 3px;
}

.NotificationItem-lastUnseen {
  border-bottom-right-radius: 3px;
}

.NotificationItem-iconContainer {
  flex: 0 0 40px;

  height: 40px;
  margin: 8px;

  background-color: var(--kog-notification-icon-color);
  border-radius: 20px;
}

.NotificationItem-icon {
  font-size: 22px;
  color: var(--kogPlatformWhite);
  fill: currentColor;
}

.NotificationItem-timeSince {
  padding-top: 8px;
  font-size: var(--kog-font-size-small);
}

.NotificationItem-content {
  display: flex;
  padding: 8px;
  text-align: left;
}

.NotificationItem-message {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}
</style>
