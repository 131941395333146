<template>
  <kog-item-list role="list">
    <ul class="list-style-none margin-0 padd-0">
      <li
        v-for="topic in allAndTopics"
        :key="topic.id"
        class="TableOfContentsTopics-listItem containContent"
        :class="{
          'TableOfContentsTopics-listItem--selected': isSelectedTopic(topic.id),
        }"
      >
        <div class="flexContainer">
          <button
            :aria-label="`Open ${topic.name}`"
            class="KogButtonLegacy--noStyle flexContainer heading-xxs text-medium width-full"
            :tabindex="isSelectedTopic(topic.id) ? 0 : -1"
            :aria-controls="`topic-${topic.id}-subtopics-list`"
            :aria-expanded="isSelectedTopic(topic.id) ? 'true' : 'false'"
            @click="selectTopic(topic)"
          >
            <div class="margin-right-m">
              {{ topic.number }}
            </div>
            <div class="text-left">
              {{ topic.name }}
              <content-hidden-label v-if="isShowHiddenLabel(topic.id)" />
            </div>
          </button>
        </div>
        <div class="flexContainer heading-xxs text-medium margin-left-l">
          <vertical-expand-transition>
            <table-of-contents-subtopics
              v-if="isSelectedTopic(topic.id) && topic.id !== ALL_TOPIC_ID"
              :subtopics="subtopics"
              class="margin-right-m"
              :topic-id="topic.id"
            />
          </vertical-expand-transition>
        </div>
      </li>
    </ul>
  </kog-item-list>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import VerticalExpandTransition from 'sharedApp/animations/vertical-expand.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';
import { ALL_TOPIC_ID } from 'studyApp/constants/subject-class-overview-constants.js';

import TableOfContentsSubtopics from './table-of-contents-subtopics.vue';

export default {
  name: 'TableOfContentsTopics',
  components: {
    TableOfContentsSubtopics,
    VerticalExpandTransition,
    ContentHiddenLabel,
    KogItemList,
  },
  mixins: [RoutesMixin],
  inject: {
    tableOfContentsContext: {
      from: 'tableOfContentsContext',
      default: undefined,
    },
  },
  props: {
    topics: {
      type: Array,
      required: true,
    },
    expandedTopicId: {
      type: Number,
      required: true,
    },
    isBookRail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    subtopics() {
      return this.allAndTopics.find(topic => this.isSelectedTopic(topic.id)).children;
    },
    allAndTopics() {
      if (this.isBookRail) {
        return this.topics;
      }
      const allTopic = {
        id: ALL_TOPIC_ID,
        name: 'All',
        number: '#',
        children: [],
      };
      return [allTopic, ...this.topics];
    },
    isHiddenLabelEnabled() {
      return this.user.isTeacher();
    },
  },
  created() {
    this.ALL_TOPIC_ID = ALL_TOPIC_ID;
  },
  methods: {
    isSelectedTopic(topicId) {
      return topicId === this.expandedTopicId;
    },
    isAllSelected(topicId) {
      return topicId === ALL_TOPIC_ID;
    },
    selectTopic(topic) {
      this.tableOfContentsContext.selectedTopic = topic;
    },
    isShowHiddenLabel(topicId) {
      return (
        !this.isAllSelected(topicId) && this.isHiddenLabelEnabled && !this.isNodeVisible(topicId)
      );
    },
  },
};
</script>

<style scoped>
.TableOfContentsTopics-listItem {
  margin-bottom: 2px;
  padding: var(--space-xs) var(--space-m);
  border-radius: 12px;
}

.TableOfContentsTopics-listItem--isHidden {
  color: var(--kog-text-muted);
}

.TableOfContentsTopics-listItem--selected {
  background-color: var(--kog-list-item-selected-background);
}

.TableOfContentsTopics-listItem--selected.TableOfContentsTopics-listItem--isHidden {
  color: var(--kog-colors-grey-800);
}

.TableOfContentsTopics-listItem--selected:hover {
  background-color: var(--kog-list-item-selected-hover-background) !important;
}

.TableOfContentsTopics-listItem:hover {
  background-color: var(--kog-list-item-hover-background);
}
</style>
