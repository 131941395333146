<template>
  <span class="KogSignal">
    <span :class="signalBarClass(1)" />
    <span :class="signalBarClass(2)" />
    <span :class="signalBarClass(3)" />
  </span>
</template>

<script>
export default {
  name: 'KogSignal',
  props: {
    level: {
      type: Number,
      default: 0,
    },
    signalType: {
      type: String,
      default: 'icon',
    },
  },
  methods: {
    signalBarClass(barIndex) {
      return [
        {
          'KogSignal-bar-level': this.level >= barIndex && this.signalType === 'difficulty',
          'KogSignal-bar-icon': this.signalType === 'icon',
          'KogSignal-bar-difficulty': this.level < barIndex && this.signalType === 'difficulty',
        },
        `KogSignal-bar-${barIndex}`,
        'KogSignal-bar',
      ];
    },
  },
};
</script>

<style scoped>
.KogSignal {
  display: inline-block;
  font-size: 0;
}

.KogSignal-bar {
  display: inline-block;
  width: 3px;
  border-radius: 1px;
}

.KogSignal-bar-icon {
  background: var(--kogPlatformGray031);
}

.KogSignal-bar-difficulty {
  background: var(--kogPlatformGray064);
}

.KogSignal-bar-level {
  background: var(--kog-progressbar-single-primary-color);
}

.KogSignal-bar:not(:last-of-type) {
  margin-right: 2px;
}

.KogSignal-bar-1 {
  height: 8px;
}

.KogSignal-bar-2 {
  height: 12px;
}

.KogSignal-bar-3 {
  height: 16px;
}
</style>
