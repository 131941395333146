<template>
  <div class="SectionHeader">
    <section-header-title
      :subtitle="headerSubtitle"
      :title="subjectNode.name"
      :is-showing-subtitle="isShowingSubtitle"
      :is-hidden="showHiddenLabel"
    />
    <div
      class="flexContainer margin-top-xl"
      :class="{
        'flexContainer-alignCenter flexContainer-spaceBetween': shouldUseDefaultToolsLayout,
        'flexContainer-column': shouldUseActivityToolsLayout,
        'flexContainer-flexEnd': !showSyllabusAlignments,
      }"
    >
      <div
        v-if="showSyllabusAlignments"
        class="flexContainer"
      >
        <kog-loader
          :loading="$wait.is('loading-syllabus-node-tree')"
          :loading-msg="`Loading syllabus alignments for &quot;${subjectNode.name}&quot;`"
        >
          <syllabus-alignment-tag
            v-for="{ id, name, shortName, tagColor } in syllabusAlignments"
            :key="id"
            class="margin-right-xxs margin-bottom-xxs"
            :name="name"
            :short-name="shortName"
            :tag-color="tagColor"
          />
        </kog-loader>
      </div>
      <div
        v-if="isShowingSectionTools"
        class="flexContainer"
        :class="{
          'margin-left-auto margin-top-xs': shouldUseActivityToolsLayout,
        }"
      >
        <report-feedback-button
          class="SectionHeader-actionButtonsSpace"
          button-type="round"
          content-type="section"
          :subject-node-id="subjectNodeId"
          :activity-id="activityId"
          :content-id="sectionId"
          tooltip="Report feedback on content"
        />
        <template v-if="isInReviewMode">
          <kog-round-button
            class="SectionHeader-actionButtonsSpace"
            aria-label="Open print"
            icon-class="fa-print"
            icon-style="regular"
            icon-size="s"
            button-style="default"
            @click="openPrintView()"
          />
          <div v-if="subjectClasses.length > 0 && !isAssignmentFocus">
            <section-header-assignment-button
              class="SectionHeader-actionButtonsSpace"
              :subject-node-id="subjectNodeId"
              :subject-classes="classesInSubject"
            />
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="isInReviewMode && subtopicNodeId"
      class="margin-top-m"
    >
      <quiz-statistics-container v-if="isShowingQuizStatistics" />
      <activity-statistics-container
        v-if="isShowingActivityStatistics"
        :subject-node="subjectNode"
        :subject="subject"
        :subject-node-id="subjectNodeId"
        :sub-topic-id="subtopicNodeId"
        :class-slug="subjectClassSlug"
        :user="user"
        :subtitle="fullSubtopicName"
      />
      <book-statistics-container
        v-if="isShowingBookStatistics"
        :subject="subject"
        :sub-topic-id="subtopicNodeId"
        :subject-node-id="subjectNodeId"
        :user="user"
        :subject-node-title="subjectNode.name"
        :subtitle="fullSubtopicName"
        :class-slug="subjectClassSlug"
        :is-showing-reflection-questions-statistics="isContentContainingReflectionBox"
      />
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import ReportFeedbackButton from 'learning/common/components/report-feedback-button.vue';
import BookStatisticsContainer from 'learning/teach/composites/book/book-statistics-container.vue';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import SyllabusAlignmentTag from 'sharedApp/components/tags/syllabus-alignment-tag.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ActivityStatisticsContainer from 'studyApp/components/activities/activity-statistics-container.vue';
import SectionHeaderAssignmentButton from 'studyApp/components/section-text/section-header-assignment-button.vue';
import SectionHeaderTitle from 'studyApp/components/section-text/section-header-title.vue';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';
import QuizStatisticsContainer from 'studyApp/pages/features/quiz/quiz-statistics-container.vue';
import { getSectionHeaderSubtitle } from 'studyApp/utils/book-header-utils.js';

export default {
  name: 'SectionHeader',
  components: {
    SectionHeaderAssignmentButton,
    QuizStatisticsContainer,
    SectionHeaderTitle,
    SyllabusAlignmentTag,
    KogRoundButton,
    KogLoader,
    ReportFeedbackButton,
    BookStatisticsContainer,
    ActivityStatisticsContainer,
  },
  mixins: [RoutesMixin, ReadingAssignmentMixin],
  props: {
    sectionNode: {
      type: Object,
      default: null,
    },
    subjectNodeId: {
      type: Number,
      required: true,
    },
    isContentContainingReflectionBox: {
      type: Boolean,
      default: false,
    },
    isActivityPageTemplate: {
      type: Boolean,
      default: false,
    },
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
    isShowingSubtitle: {
      type: Boolean,
      default: true,
    },
    isShowingBookStatistics: {
      type: Boolean,
      default: true,
    },
    isShowingQuizStatistics: {
      type: Boolean,
      default: false,
    },
    isShowingSectionTools: {
      type: Boolean,
      default: true,
    },
    isShowingActivityStatistics: {
      type: Boolean,
      default: false,
    },
    isShowingSyllabusAlignments: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getAncestorContainerNode: 'syllabusModule/getAncestorContainerNode',
      syllabusNodesById: 'syllabusModule/syllabusNodesById',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    ...mapState({
      subject: state => state.subjectModule.subject,
      user: state => state.userModule.user,
      subjectClasses: state => state.subjectClassModule.subjectClasses,
      syllabusNodeRootDetails: state => state.syllabusModule?.syllabusNodeRootDetails,
    }),
    activityId() {
      return this.getActivityBySubjectNodeId(this.subjectNodeId)?.id;
    },
    activitySyllabusNodes() {
      return this.getActivityBySubjectNodeId(this.subjectNodeId)?.syllabus_nodes;
    },
    sectionId() {
      return this.subjectNode.section_id;
    },
    sectionSyllabusNodes() {
      return this.sectionNode?.content_syllabus_nodes;
    },
    shouldUseDefaultToolsLayout() {
      return !this.isActivityPageTemplate || this.isInReviewMode;
    },
    shouldUseActivityToolsLayout() {
      return this.isActivityPageTemplate && !this.isInReviewMode;
    },
    classesInSubject() {
      return this.subjectClasses.filter(
        subjectClass => subjectClass.subject_id === Number(this.subjectId),
      );
    },
    subjectNode() {
      return this.subjectNodesById[this.subjectNodeId];
    },
    subtopicNodeId() {
      if (this.subjectNode.level === 3) {
        return this.subjectNode.parent;
      }
      return this.subjectNodesById[this.subjectNode.parent]?.parent;
    },
    subtopicNode() {
      return this.subjectNodesById[this.subtopicNodeId];
    },
    fullSubtopicName() {
      return `${this.subtopicNode.formatted_number_including_ancestors} ${this.subtopicNode.name}`;
    },
    topicNodeId() {
      return this.subtopicNode.parent;
    },
    topicNode() {
      return this.subjectNodesById[this.topicNodeId];
    },
    syllabusAlignments() {
      if (
        !this.showSyllabusAlignments ||
        this.$wait.is('loading-syllabus-node-tree') ||
        isNil(this.syllabusNodeRootDetails)
      )
        return [];
      const syllabusNodes = this.activitySyllabusNodes || this.sectionSyllabusNodes || [];
      return syllabusNodes.map(nodeId => {
        const syllabusNode = this.syllabusNodesById[nodeId];
        return {
          id: syllabusNode.id,
          name: syllabusNode.name,
          shortName: syllabusNode.short_name,
          tagColor: syllabusNode.color_computed,
        };
      });
    },
    showSyllabusAlignments() {
      if (!this.isShowingSyllabusAlignments) {
        return false;
      }
      return !!this.subject.syllabus_id;
    },
    headerSubtitle() {
      const subtopicNode = this.subtopicNode.is_special_introduction_node
        ? null
        : this.subtopicNode;
      return getSectionHeaderSubtitle(this.topicNode, subtopicNode);
    },
    showHiddenLabel() {
      return this.user.isTeacher() && !this.isNodeVisible(this.subjectNodeId);
    },
  },
  created() {
    if (isNil(this.syllabusNodeRootDetails) && this.showSyllabusAlignments) {
      this.fetchSyllabusDetails(this.subject.syllabus_id);
    }
  },
  methods: {
    ...mapWaitingActions({
      fetchSyllabusDetails: {
        action: 'syllabusModule/fetchSyllabusDetails',
        loader: 'loading-syllabus-node-tree',
      },
    }),
    openPrintView() {
      this.trackOpenPrintView();
      const route = this.$router.resolve({
        name: 'bookPrint',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: this.subjectNode.slug,
          nodeId: this.subjectNode.id,
        },
      });
      window.open(route.href, '_blank');
    },
    trackOpenPrintView() {
      this.$mixpanel.trackEvent('Section Header - Print', this.trackingProps);
      this.$event.track('load_printable_page_view', {
        subject_id: this.subjectId,
        subject_class_id: this.classId,
        subject_node_id: this.subjectNode.id,
      });
    },
  },
};
</script>

<style scoped>
.SectionHeader {
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--kogPlatformGray093);
}

.SectionHeader-actionButtonsSpace {
  margin-right: 6px;
}

.SectionHeader h1:first-of-type {
  margin: 0;
}
</style>
