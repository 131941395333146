<template>
  <kog-loader
    :loading="isDeleting"
    :loading-msg="'leaving class...'"
  >
    <div
      ref="root"
      class="ClassCard flexContainer flexContainer-column"
    >
      <div class="ClassCard-imgContainer">
        <kog-tag
          v-if="isAdminClass"
          class="ClassCard-adminLabel"
          :is-chip="true"
          type="warning"
          label="Admin access"
        ></kog-tag>

        <div
          v-if="isConnectedToAnyGoogleClass"
          class="ClassCard-integrationDetailsContainer text-small text-white flexContainer gap-xxs flexContainer-alignCenter"
        >
          <span
            aria-hidden="true"
            class="margin-left-xxs"
          >
            Connected to
          </span>
          <google-classes-icon
            :style="'light'"
            :google-classes="subjectClass.google_classes"
          />
        </div>

        <image-box
          :src="bookCover"
          :h="48"
          fit="crop"
          crop="entropy"
        />
      </div>
      <div class="ClassCard-details flexContainer flexContainer-column flexContainer-spaceBetween">
        <div class="ClassCard-subjectName text-small text-ellipsis">
          {{ subject.name }}
        </div>
        <div class="margin-bottom-s">
          <div class="flexContainer flexContainer-spaceBetween">
            <div class="flexContainer flexContainer-alignStart">
              <router-link
                class="heading-xs ClassCard-className ClassCard--wordBreakNormal anchor-unstyled margin-right-s margin-bottom-s line-clamp-2"
                :to="classLandingPageUrl"
              >
                {{ className }}
              </router-link>
            </div>
            <class-kebab-menu
              :subject-class="subjectClass"
              :subject="subject"
              :is-admin-class="isAdminClass"
              @login-google-classroom="loginGoogleClassroom()"
              @edit-class-clicked="showEditClassModal()"
              @leave-class-clicked="showLeaveClassModal()"
            />
          </div>
          <router-link
            v-if="!isAdminClass"
            v-tooltip="{
              theme: 'kog-tooltip',
              content: studentNames,
              loadingContent: 'Loading...',
              boundary: 'document.body',
              triggers: ['hover'],
              disabled: studentCount === 0,
            }"
            :aria-label="`${pluralize('student', studentCount, true)}
            in class. Follow this link to go to the manage class page and get the full list of
            students.`"
            :to="subjectClassManagementUrl"
            class="ClassCard-anchor--unstyled text-regular muted"
          >
            {{ pluralize('student', studentCount, true) }}
          </router-link>
          <div
            v-if="isAdminClass"
            v-tooltip="{
              theme: 'kog-tooltip',
              content: teacherList.join('<br/>'),
              boundary: 'document.body',
            }"
            :aria-label="`${teacherList.length}
                ${teacherList.length === 1 ? 'teacher' : 'teachers'}
                in admin class`"
            class="margin-right-m text-regular muted line-clamp-1"
          >
            {{ teacherNames }}
          </div>
        </div>
        <div class="flexContainer flexContainer-alignCenter flexContainer-flexEnd margin-top-xxs">
          <create-assignment-button
            v-if="!isAdminClass"
            button-type="basic"
            button-size="small"
            :subject-classes="[subjectClass]"
            :subject="subject"
            :width="168"
            :user="user"
            :source="sources.TEACHER_CLASS_CARD"
          />
        </div>
      </div>
    </div>
  </kog-loader>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { VTooltip } from 'floating-vue';
import pluralize from 'pluralize';
import { mapActions } from 'vuex';

import { fetchSchoolStudentList } from '@apis/students';

import CreateAssignmentButton, {
  SOURCES,
} from 'learning/common/components/assignment/create-assignment-button.vue';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import defaultCover from 'sharedApp/img/kognity-logo-primary.png';
import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';
import EditClassModal from 'studyApp/components/teacher/edit-class-modal.vue';
import GoogleClassesIcon from 'studyApp/components/teacher/google-classes-icon.vue';
import KogRemoveClassTeacherModal from 'studyApp/components/teacher/teachers/kog-remove-class-teacher-modal.vue';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';

import ClassKebabMenu from './class-kebab-menu.vue';

export default {
  name: 'ClassCard',
  components: {
    GoogleClassesIcon,
    ClassKebabMenu,
    CreateAssignmentButton,
    ImageBox,
    KogLoader,
    KogTag,
  },
  directives: {
    tooltip: VTooltip,
  },
  mixins: [GoogleClassroomMixin],
  props: {
    subjectClass: {
      type: Object,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    teachers: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      required: true,
    },
    isAdminClass: {
      type: Boolean,
      default: false,
    },
    onAddSubjectClass: {
      type: Function,
      default: () => true,
    },
    onRemoveSubjectClass: {
      type: Function,
      default: () => true,
    },
    reloadClasses: {
      type: Function,
      default: () => true,
    },
  },
  emits: ['login-google-classroom'],
  data() {
    return {
      defaultCover,
      isDeleting: false,
      sources: SOURCES,
    };
  },
  computed: {
    bookCover() {
      return this.subject.logo_url ?? this.defaultCover;
    },
    classId() {
      return this.subjectClass.id;
    },
    className() {
      return this.subjectClass.name;
    },
    subjectClassSlug() {
      return this.subjectClass.slug;
    },
    subjectClassManagementUrl() {
      return {
        name: 'manageClass',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subject.id,
          cid: this.classId,
        },
      };
    },
    studentCount() {
      return this.subjectClass.student_user_ids.length;
    },
    isConnectedToAnyGoogleClass() {
      return this.subjectClass.google_classes.length > 0;
    },
    teacherList() {
      return this.teachers.map(teacher => this.getUserName(teacher));
    },
    teacherNames() {
      if (this.teacherList.length === 0) {
        return 'Class teachers: None assigned';
      }
      const names = this.teacherList;
      const teacherNames = names.sort().join(', ');
      return `Class teachers: ${teacherNames}`;
    },
    classLandingPageUrl() {
      return {
        name: 'subjectClassOverview',
        params: {
          classSlug: `${this.subjectClassSlug}`,
          sid: `${this.subject.id}`,
          cid: `${this.subjectClass.id}`,
        },
      };
    },
  },
  methods: {
    pluralize,
    ...mapActions({
      saveSubjectClass: 'teacherSubjectClassModule/saveClassDetails',
    }),
    getUserName(user) {
      return `${user.first_name} ${user.last_name}`;
    },
    loginGoogleClassroom() {
      this.$refs.root.click(); // close the kebab menu
      this.$emit('login-google-classroom', this.classId);
    },
    showLeaveClassModal() {
      this.$refs.root.click(); // close the kebab menu
      this.$modal(KogRemoveClassTeacherModal, {
        removeTeacher: this.leaveClass,
        teacherId: this.user.id,
        teacherName: `${this.user.first_name} ${this.user.last_name}`,
        deletingSelf: true,
      });
    },
    async leaveClass() {
      this.isDeleting = true;
      await this.onRemoveSubjectClass(this.subject.id, this.subjectClass.id);
      await this.reloadClasses();
      this.isDeleting = false;

      const toasterId = this.$toast.showSuccess(
        `You successfully removed yourself from class ${this.subjectClass.name}.`,
        {
          actionText: 'Undo',
          toasterActionPerformed: async () => {
            this.$toast.close(toasterId);
            await this.onAddSubjectClass(this.subject.id, this.subjectClass.id);
            await this.reloadClasses();
            this.$toast.showSuccess('Action undone.');
          },
        },
      );
    },
    showEditClassModal() {
      this.$refs.root.click(); // close the kebab menu
      const subjectClass = {
        id: this.subjectClass.id,
        name: this.subjectClass.name,
        logo_url: this.subject.logo_url,
        end_date: this.subjectClass.end_date,
        subject_name: this.subject.name,
        possible_levels: this.subjectClass.possible_levels,
      };
      const saveClassWrapper = async params => {
        await this.saveSubjectClass(params);
        await this.reloadClasses(true);
      };
      this.$modal(EditClassModal, {
        subjectClass,
        educationSystem: this.subject.education_system_name,
        saveSubjectClass: saveClassWrapper,
        schoolId: this.user.school.id,
      });
    },
    async studentNames() {
      let students;
      try {
        students = await this.fetchStudentsInClass();
      } catch (error) {
        Sentry.captureException(error);
        return 'Unable to load student names';
      }

      const { possible_levels: possibleLevels } = this.subjectClass;
      if (possibleLevels.length > 0) {
        const { education_system_name: educationSystemName } = this.subject;
        return students
          .map(student => {
            const userName = this.getUserName(student.student.user);
            const levelName = getTerm(student.level.name, educationSystemName);
            return `${userName} (${levelName})`;
          })
          .join('<br/>');
      }
      return students.map(student => this.getUserName(student.student.user)).join('<br/>');
    },
    fetchStudentsInClass() {
      const { id, school_id: schoolId } = this.subjectClass;
      return fetchSchoolStudentList(schoolId, id);
    },
  },
};
</script>

<style scoped>
.ClassCard {
  height: 216px;
  background: var(--kog-background-default-0);
  border-radius: 8px;
  box-shadow: 0 4px 8px -2px var(--kogShadow020);
}

.ClassCard:hover {
  box-shadow: 0 8px 16px -4px var(--kogShadow030);
}

.ClassCard-imgContainer {
  position: relative;
  overflow: hidden;
  height: 48px;
  border-radius: var(--space-xs) var(--space-xs) 0 0;
}

.ClassCard-adminLabel {
  position: absolute;
  top: calc(50% - 12px);
  left: var(--space-m);

  width: fit-content;

  color: var(--kog-colors-yellow-900);
}

.ClassCard-integrationDetailsContainer {
  position: absolute;
  top: var(--space-xs);
  right: calc(var(--space-s) * 1.5);

  padding: var(--space-xxs);
  padding-left: var(--space-xs);

  background-color: var(--kogPlatformGray000);
  border-radius: 16px;
}

.ClassCard-subjectName {
  position: absolute;
  top: -12px;

  max-width: calc(100% - 32px);
  padding: 0 4px;

  color: var(--kog-text-brand-hard);

  background: var(--kog-background-brand-900);
  border-radius: 3px;
}

.ClassCard-details {
  position: relative;
  flex: 1;
  padding: var(--space-m);
}

.ClassCard-className {
  color: var(--kogPlatformGray018);
}

.ClassCard-className:hover {
  text-decoration: underline;
}

.ClassCard--wordBreakNormal {
  /* The vue-line-clap does not support changing this as an option,
  this is a workaround */
  word-break: normal !important;
}

.ClassCard-googleClassroomIcon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.ClassCard-anchor--unstyled.muted:hover {
  color: var(--kogPlatformGray044);
  text-decoration: none;
}

.ClassCard-anchor--unstyled.kogPlatformRedDarken20:hover {
  color: var(--kogPlatformRedDarken20);
  text-decoration: none;
}

.KogButtonLegacy {
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);
}
</style>
