<template>
  <kog-new-navbar
    :right-slot-expanded="false"
    role="banner"
  >
    <template #leftCornerSlot>
      <div class="flexContainer flexContainer-center">
        <div
          class="FullpageTakeoverNavbar-backButtonContainer flexContainer flexContainer-center padd-right-s divider-right"
        >
          <kog-round-button
            :aria-label="backButtonAriaLabel"
            icon-class="fa-chevron-left"
            size="medium"
            button-style="inverted"
            @click="$emit('back-button-click')"
          />
        </div>
        <h1 class="heading-s margin-left-m">
          {{ headerText }}
        </h1>
      </div>
    </template>
  </kog-new-navbar>
</template>

<script>
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogNewNavbar from 'sharedApp/components/core/kog-new-navbar.vue';

export default {
  name: 'FullpageTakeoverNavbar',
  components: {
    KogNewNavbar,
    KogRoundButton,
  },
  props: {
    backButtonAriaLabel: {
      type: String,
      required: true,
    },
    headerText: {
      type: String,
      required: true,
    },
  },
  emits: ['back-button-click'],
};
</script>

<style scoped>
.FullpageTakeoverNavbar-backButtonContainer {
  height: var(--full-page-navbar-height);
}
</style>
