<template>
  <div
    v-if="error"
    class="PracticeTestSetupError margin-bottom-m padd-m kogPlatformRedDarken20"
    role="alert"
  >
    {{ error }}
  </div>
</template>

<script>
export default {
  name: 'PracticeTestSetupError',
  props: {
    error: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.PracticeTestSetupError {
  background: var(--kogPlatformRedLighten20);
  border: 1px solid var(--kogPlatformRedLighten10);
  border-radius: 4px;
}
</style>
