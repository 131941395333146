import { Command } from '@ckeditor/ckeditor5-core';

export default class GlossaryLinkCommand extends Command {
  execute(configuration) {
    const { model } = this.editor;
    model.change(writer => {
      model.insertObject(this.create(writer, configuration));
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(
      document.selection.getFirstPosition(),
      'glossaryLink',
    );
    this.isEnabled = allowedIn !== null;
    this.value = null;

    const firstRange = document.selection.getFirstRange();
    if (!firstRange.isCollapsed) {
      let node = null;
      if (firstRange.start.nodeBefore?.name === 'glossaryLink') {
        node = firstRange.start.nodeBefore;
      } else if (firstRange.start.nodeAfter?.name === 'glossaryLink') {
        node = firstRange.start.nodeAfter;
      }

      if (node) {
        const value = {
          termId: node.getAttribute('termId'),
          definitionId: node.getAttribute('definitionId'),
          linkedText: node.getAttribute('linkedText'),
        };
        this.value = value;
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer, { termId, definitionId, linkedText }) {
    const glossaryLink = writer.createElement('glossaryLink', { termId, definitionId, linkedText });

    writer.insertText(linkedText, glossaryLink);

    return glossaryLink;
  }
}
