<template>
  <long-text-question
    :question="question"
    :answer="answer"
    :auto-save="handleUserResponse"
    :max-length="2000"
    :max-file-size="10"
    :disabled="disabled"
    @input="handleUserInput"
  />
</template>

<script>
import LongTextQuestion from 'sharedApp/components/long-text-question/long-text-question.vue';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';

export default {
  name: 'ActivityLtq',
  components: {
    LongTextQuestion,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['response'],
  data() {
    return {
      answer: this.context.user_response ?? '',
    };
  },
  computed: {
    questionContext() {
      return this.question.question_context || this.question.context;
    },
    responseType() {
      return this.questionContext?.input_types?.includes('image')
        ? RESPONSE_TYPES.IMR
        : RESPONSE_TYPES.LTR;
    },
  },
  watch: {
    'context.user_response': {
      handler(newValue) {
        this.answer = newValue;
      },
    },
  },
  methods: {
    handleUserInput(response) {
      if (typeof response === 'string') {
        this.handleTextInput(response);
      } else {
        this.handleImageInput(response);
      }
    },
    handleImageInput(response) {
      const { value, ...rest } = response;
      this.answer = value;

      this.$emit('response', {
        response_type: RESPONSE_TYPES.IMR,
        user_response: value,
        ...rest,
      });
    },
    handleTextInput(response) {
      this.answer = response;
    },
    handleUserResponse(response) {
      const { value, ...rest } = response;

      this.$emit('response', {
        user_response: value,
        response_type: this.responseType,
        ...rest,
      });
    },
  },
};
</script>
