<template>
  <div
    :class="scaleClass"
    :style="`width: ${size}px;`"
    class="ScaleSection-all flexContainer flexContainer-center"
  >
    <div
      v-if="scaleClass === 'ScaleSection-noQuestions'"
      class="ScaleSection-noQuestionsBorder flexContainer flexContainer-center"
    >
      <div class="ScaleSection-noQuestions-line" />
    </div>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

import { getScaleClass } from 'teachApp/utils/heatmap-scales.js';

const colorCssMap = {
  kogPlatformRedLighten10: 'ScaleSection-kogPlatformRedLighten10',
  kogPlatformGreenBaseAlpha30: 'ScaleSection-kogPlatformGreenBaseAlpha30',
  kogPlatformGreenBaseAlpha60: 'ScaleSection-kogPlatformGreenBaseAlpha60',
  kogPlatformGreenBaseAlpha80: 'ScaleSection-kogPlatformGreenBaseAlpha80',
  kogPlatformGreenBase: 'ScaleSection-kogPlatformGreenBase',
  kogPlatformYellowBase: 'ScaleSection-kogPlatformYellowBase',
  kogPlatformYellowLighten10: 'ScaleSection-kogPlatformYellowLighten10',
  kogPlatformGreenLighten10: 'ScaleSection-kogPlatformGreenLighten10',
};

export default {
  name: 'ScaleSection',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    scalePercentage: {
      type: Number,
      required: true,
    },
    scale: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: null,
    },
  },
  computed: {
    scaleClass() {
      if (this.scalePercentage === -2) {
        return 'ScaleSection-noQuestions';
      }
      if (this.scalePercentage === -1) {
        return 'ScaleSection-noActivity';
      }

      const scoreClass = getScaleClass(this.scalePercentage, this.scale, colorCssMap);
      return scoreClass;
    },
  },
};
</script>

<style scoped>
.ScaleSection-all {
  flex: 1 0 50px;

  box-sizing: border-box;
  height: 21px;

  text-align: center;

  border: 1px;
  border-color: var(--kogPlatformWhite);
  border-style: solid;
}

.ScaleSection-noQuestionsBorder {
  flex: 1;
  height: 100%;
  border: 1px solid var(--kogPlatformGray093);
}

.ScaleSection-noActivity {
  background: var(--kogPlatformGray096);
}

.ScaleSection-kogPlatformRedLighten10 {
  background: var(--kogPlatformRedLighten10);
}

.ScaleSection-kogPlatformGreenBaseAlpha30 {
  background: color-mod(var(--kogPlatformGreenBase) alpha(30%));
}

.ScaleSection-kogPlatformGreenBaseAlpha60 {
  background: color-mod(var(--kogPlatformGreenBase) alpha(60%));
}

.ScaleSection-kogPlatformGreenBaseAlpha80 {
  background: color-mod(var(--kogPlatformGreenBase) alpha(80%));
}

.ScaleSection-kogPlatformGreenBase {
  background: var(--kogPlatformGreenBase);
}

.ScaleSection-kogPlatformYellowBase {
  background: var(--kogPlatformYellowBase);
}

.ScaleSection-kogPlatformYellowLighten10 {
  background: var(--kogPlatformYellowLighten10);
}

.ScaleSection-kogPlatformGreenLighten10 {
  background: var(--kogPlatformGreenLighten10);
}

.ScaleSection-noQuestions-line {
  flex: 1;

  min-width: 5px;
  max-width: 20px;
  margin: 0 6px;

  border-top: 1px solid var(--kogPlatformGray093);
}
</style>
