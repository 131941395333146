<template>
  <kog-modal title="Duplicate assignment">
    <template #modalBody>
      <form
        class="form-group"
        :class="{ 'has-error': !hasAssignmentName }"
      >
        <label for="name">Duplicate assignment as:</label>
        <input
          id="name"
          v-model="name"
          class="form-control"
          type="text"
          required
        />
        <div
          v-show="!hasAssignmentName"
          class="error"
        >
          Don't forget the assignment name
        </div>
      </form>
      <label for="target">To class:</label>
      <kog-loader
        :loading="$wait.is('loading_copy_targets')"
        loading-msg="Getting possible classes..."
      >
        <select
          v-if="hasCopyTargets"
          id="target"
          v-model="selectedClass"
          class="form-control"
        >
          <option
            v-for="subjectClass in subjectClassList"
            :key="subjectClass.id"
            :value="subjectClass.id"
          >
            {{ subjectClass.name }}
          </option>
        </select>
        <div
          v-else
          class="kogPlatformRedDarken20 padd-top-xxs"
        >
          You have no active classes to send an assignment to.
        </div>
        <div
          v-if="showWarning"
          class="kogPlatformRedDarken20 padd-top-xxs"
        >
          Questions belonging exclusively to the original subject will not be duplicated.
        </div>
      </kog-loader>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right flexContainer-alignCenter">
        <button
          class="KogButtonLegacy"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          :disabled="isCopyDisabled"
          class="KogButtonLegacy KogButtonLegacy--primary"
          @click="copy()"
        >
          Duplicate
        </button>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import * as schoolStaffApi from '@apis/schoolstaff-assignments.js';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'CopyAssignmentModal',
  components: {
    KogModal,
    KogLoader,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    subjectSlug: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      default: 'not_declared',
    },
    onCopyAssignment: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      name: '',
      selectedClass: undefined,
      copyTargets: [],
    };
  },
  computed: {
    assignmentId() {
      return this.assignment.id;
    },
    assignmentName() {
      return this.assignment.name;
    },
    subjectClassId() {
      if (this.$route.params.cid) {
        return parseInt(this.$route.params.cid, 10);
      }
      return this.assignment.subject_classes[0];
    },
    assignmentType() {
      return this.assignment.assignment_type;
    },
    subjectClassList() {
      return this.copyTargets.map(target => {
        let displayName = '';
        if (target.subject_class_id === this.subjectClassId) {
          displayName += '[This class] ';
        }

        displayName += `${target.subject_name} - ${target.subject_class_name}`;
        return {
          id: target.subject_class_id,
          name: displayName,
        };
      });
    },
    hasAssignmentName() {
      return this.name.length > 0;
    },
    showWarning() {
      if (!this.hasCopyTargets || !this.selectedClass) {
        return false;
      }

      const selectedClass = this.copyTargets.find(
        target => target.subject_class_id === this.selectedClass,
      );

      return this.assignmentType !== 'READING' && selectedClass.subject_slug !== this.subjectSlug;
    },
    isCopyDisabled() {
      return (
        !this.hasAssignmentName ||
        this.$wait.is('copying_assignment') ||
        this.subjectClassList.length <= 0
      );
    },
    hasCopyTargets() {
      return this.copyTargets.length > 0;
    },
  },
  async created() {
    this.name = `Copy of ${this.assignmentName}`;
    await this.fetchCopyTargets();
    if (this.hasCopyTargets) {
      const isCurrentSubjectClassActive = this.copyTargets.some(
        target => target.subject_class_id === this.subjectClassId,
      );

      this.selectedClass = isCurrentSubjectClassActive
        ? this.subjectClassId
        : this.copyTargets[0].subject_class_id;
    }
  },
  methods: {
    async copy() {
      this.$wait.start('copying_assignment');
      const assignmentCopy = await schoolStaffApi.copyAssignment(
        this.assignmentId,
        this.selectedClass,
        this.name,
      );
      this.$wait.end('copying_assignment');
      this.$mixpanel.trackEventViaBackend('Assignment - Copy', {
        assignment_type: this.assignmentType.toLowerCase(),
        assignment_state: 'draft',
        num_subject_classes: this.assignment.subject_classes.length,
        source: this.source,
      });
      this.closeModal();
      this.onCopyAssignment();
      const toasterId = this.$toast.showSuccess('Assignment copied successfully', {
        actionText: 'Go to copy',
        toasterActionPerformed: () => {
          this.$toast.close(toasterId);
          this.routeToCopy(assignmentCopy);
        },
      });
    },
    routeToCopy(assignment) {
      this.$router.push({
        name: 'teacherAssignmentCreate',
        params: {
          sid: `${assignment.subject_id}`,
          cid: `${this.subjectClassId}`,
          assignmentId: `${assignment.id}`,
        },
        query: {
          step: '2',
          just_copied: true,
          source: this.source,
        },
      });
    },
    async fetchCopyTargets() {
      this.$wait.start('loading_copy_targets');
      const data = await schoolStaffApi.fetchAssignmentCopyTargets(this.assignmentId);
      this.copyTargets = data.targets;
      this.$wait.end('loading_copy_targets');
    },
  },
};
</script>
