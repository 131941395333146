<template>
  <div
    v-kog-mathjax
    class="TermsList content-editable"
  >
    <div
      v-for="(letter, letterIndex) in existingLetters"
      :key="`group-${letter}`"
      v-element-visibility="isVisible => onLetterGroupVisibilityChanged(isVisible, letter)"
      :aria-hidden="letter !== currentLetter ? 'true' : null"
    >
      <h2
        :ref="`LetterGroup${letter}_heading`"
        class="screenreader-only"
        tabindex="-1"
      >
        Start of Words in Glossary Letter {{ letter }}
      </h2>
      <ul>
        <li
          v-for="(term, termIndex) in termsByLetter[letter]"
          :key="term.id"
          :ref="`Term${term.id}`"
          class="TermsList-item"
        >
          <list-item :term="term" />
          <div
            v-if="!isLastTerm(letter, letterIndex, termIndex)"
            aria-hidden="true"
            class="TermsList-itemDivider"
          >
            <i class="fas fa-circle" />
            <i class="fas fa-circle" />
            <i class="fas fa-circle" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { vElementVisibility } from '@vueuse/components';
import { mapGetters, mapMutations, mapState } from 'vuex';

import ListItem from 'studyApp/components/glossary/terms-list-item.vue';

export default {
  name: 'TermsList',
  components: {
    ListItem,
  },
  directives: {
    elementVisibility: vElementVisibility,
  },
  data() {
    return {
      visibleLetters: [],
    };
  },
  computed: {
    ...mapState({
      currentLetter: state => state.glossaryV2Module.currentLetter,
      definitionToScrollTo: state => state.glossaryV2Module.definitionToScrollTo,
    }),
    ...mapGetters({
      terms: 'glossaryV2Module/filteredTerms',
      termsByLetter: 'glossaryV2Module/termsByLetter',
      existingLetters: 'glossaryV2Module/existingLetters',
    }),
  },
  watch: {
    definitionToScrollTo(value) {
      if (value !== null) {
        const termEle = this.$refs[`Term${value}`][0];
        setTimeout(() => {
          termEle.scrollIntoView({ behavior: 'smooth' });
          this.setDefinitionToScrollTo(null);
        });

        setTimeout(() => {
          if (this.termsByLetter[this.currentLetter][0].id === value) {
            const termEleHeading = this.$refs[`LetterGroup${this.currentLetter}_heading`][0];
            termEleHeading.focus();
          }
        }, 900);
      }
    },
    visibleLetters: {
      handler(value) {
        const lastVisibleLetter = value[value.length - 1];
        this.setCurrentLetter(lastVisibleLetter);
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setCurrentLetter: 'glossaryV2Module/setCurrentLetter',
      setDefinitionToScrollTo: 'glossaryV2Module/setDefinitionToScrollTo',
    }),
    isLastTerm(letter, letterIndex, termIndex) {
      return (
        letterIndex === this.existingLetters.length - 1 &&
        termIndex === this.termsByLetter[letter].length - 1
      );
    },
    onLetterGroupVisibilityChanged(isVisible, letter) {
      if (isVisible) {
        this.visibleLetters.push(letter);
      } else {
        this.visibleLetters = this.visibleLetters.filter(l => l !== letter);
      }
    },
  },
};
</script>

<style scoped>
.TermsList ul {
  padding-left: 0;
  list-style-type: none;
}

.TermsList-item {
  margin-bottom: 16px;
}

.TermsList-itemDivider {
  width: 100%;
  text-align: center;
}

.TermsList-itemDivider i {
  padding-left: 8px;
  font-size: 6px;
  color: var(--kogPlatformGray077);
}
</style>
