import MenuItem from 'studyApp/menus/menu-item.js';

export default class AssignmentMenuItem extends MenuItem {
  constructor(config) {
    const defaults = {
      key: 'assignments',
      displayText: 'Assignments',
      iconClassActive: 'fas fa-tasks',
      iconClassInactive: 'fal fa-tasks',
    };
    super({ ...defaults, ...config });
  }

  navigateTo({ router, currentRoute }) {
    const params = {
      classSlug: currentRoute.params.classSlug,
      cid: currentRoute.params.cid,
      sid: currentRoute.params.sid,
    };
    this.goToRoute(router, currentRoute, 'teacherAssignmentOverview', params);
  }
}
