<template>
  <span
    v-tooltip="{
      theme: 'kog-tooltip',
      content: getLocationsHtml(locations),
      autoHide: false,
      boundary: 'document.body',
    }"
    class="kogPlatformGray031"
  >
    <kog-tag
      v-if="locations.length === 1"
      :label="getFirstLocationNumber(locations)"
    />
    <span v-else>
      {{ getQuestionLocation(locations) }}
    </span>
  </span>
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import QuestionLocationMixin from 'teachApp/mixins/question-location-mixin.js';

export default {
  name: 'QuestionLocation',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    KogTag,
  },
  mixins: [QuestionLocationMixin],
  props: {
    locations: {
      type: Array,
      required: true,
    },
  },
};
</script>
