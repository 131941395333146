<template>
  <div>
    <div class="text-center">
      <kog-button
        button-style="basic"
        :label="buttonText(false)"
        :tooltip="buttonText(true)"
        :has-icon="!solutionExpanded"
        :has-dropdown="true"
        :is-dropdown-open="solutionExpanded"
        :controls-element-id="solutionAreaId"
        icon-class="fa-question-circle"
        icon-style="solid"
        @click="showSolution(!solutionExpanded)"
      />
    </div>
    <div
      v-show="solutionExpanded"
      :id="solutionAreaId"
      class="padd-left-l padd-right-l"
    >
      <template v-if="isMCQ">
        <template v-if="Array.isArray(solution)">
          <h4 class="underlined"> Correct choices </h4>
          <div
            v-for="part in solution"
            :key="part.id"
          >
            <content-container
              v-kog-mathjax
              :content="part.answer_html"
            />
            <h5> Explanation </h5>
            <content-container
              v-kog-mathjax
              :content="part.answer_explanation_html"
            />
          </div>
        </template>
        <template v-else>
          <h4 class="underlined"> Correct choice {{ solution._numberSymbol }} </h4>
          <content-container
            v-kog-mathjax
            :content="solution.answer_html"
          />
          <h5> Explanation </h5>
          <content-container
            v-kog-mathjax
            :content="solution.answer_explanation_html"
          />
        </template>
      </template>
      <template v-if="isSTQ">
        <h4 class="underlined"> Solution </h4>
        <kog-short-text-question-correct-answers
          :correct-answers="solution.answer_list"
          :secondary-accepted-answers="solution.secondary_accepted_answers"
          :answer-explanation-html="solution.answer_explanation_html"
        />
      </template>
      <fill-in-the-blanks-solution
        v-if="isFBQ"
        :solution="solution"
        :question-html="question.question_html"
      />
    </div>
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import ContentContainer from 'sharedApp/components/content/content-container.vue';
import FillInTheBlanksSolution from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-solution.vue';
import KogShortTextQuestionCorrectAnswers from 'sharedApp/components/questions/kog-short-text-question-correct-answers.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import {
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

export default {
  name: 'SectionQuestionSolution',
  components: {
    ContentContainer,
    FillInTheBlanksSolution,
    KogButton,
    KogShortTextQuestionCorrectAnswers,
  },
  props: {
    practiceItem: {
      type: Object,
      required: true,
    },
    questionNumber: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      solutionExpanded: false,
    };
  },
  computed: {
    isMCQ() {
      return isMCQ(this.question);
    },
    isSTQ() {
      return isSTQ(this.question);
    },
    isFBQ() {
      return isFBQ(this.question);
    },
    solution() {
      return this.practiceItem.solution;
    },
    question() {
      return this.practiceItem.question;
    },
    solutionAreaId() {
      return this.getUniqueId('solutionArea');
    },
  },
  methods: {
    showSolution(value) {
      this.solutionExpanded = value;
    },
    buttonText(forScreenReader) {
      let endText = forScreenReader ? `to question ${this.questionNumber}` : '';
      if (!this.solutionExpanded) {
        return `Show answer and explanation ${endText}`;
      }
      endText = forScreenReader
        ? `to question ${this.questionNumber}. The answer is shown after this button`
        : '';
      return `Hide answer and explanation ${endText}`;
    },
  },
};
</script>
