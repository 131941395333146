<template>
  <kog-modal title="Review and send">
    <template #modalBody>
      <assignment-review-box
        :assignment-data="assignmentData"
        :student-list-by-class-id="studentListByClassId"
        :has-errors="hasErrors"
        :sending="sending"
        :is-saving="isSaving"
        :update-failed="updateFailed"
        :has-hidden-sections="hasHiddenSections"
      />
    </template>

    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-button
          :disabled="sending"
          label="Cancel"
          @click="closeModal"
        />
        <kog-button
          v-if="!hasErrors"
          id="send-assignment-button"
          :disabled="isSendDisabled"
          button-style="accent"
          label="Send"
          @click="sendAssignment"
        />
        <kog-button
          v-if="hasErrors"
          id="send-assignment-button"
          :disabled="sending"
          button-style="accent"
          label="Try again"
          @click="sendAssignment"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

import AssignmentReviewBox from './assignment-review-box.vue';

export default {
  name: 'SendoutModal',
  components: {
    KogButton,
    KogModal,
    AssignmentReviewBox,
  },
  props: {
    assignmentData: {
      type: Object,
      required: true,
    },
    studentListByClassId: {
      type: Object,
      required: true,
    },
    sendAssignmentHandler: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    hasHiddenSections: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Function,
      required: true,
    },
    updateFailed: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      sending: false,
      hasErrors: false,
    };
  },
  computed: {
    ...mapState({
      subjectClasses: state => state.subjectClassModule.subjectClasses,
    }),
    isSendDisabled() {
      return this.sending || this.isSaving() || this.updateFailed();
    },
    googleClasses() {
      const selectedClasses = this.subjectClasses.filter(subjectClass =>
        this.assignmentData.subject_classes.includes(subjectClass.id),
      );
      const googleClasses = [];
      selectedClasses.forEach(subjectClass => {
        subjectClass.google_classes.forEach(googleClass => {
          if (
            !googleClasses.some(gClass => gClass.id === googleClass.id) &&
            this.assignmentData.google_classroom_recipients.some(
              recipient =>
                recipient.upstream_class_id === googleClass.id &&
                recipient.recipient_upstream_ids.length > 0,
            )
          ) {
            googleClasses.push(googleClass);
          }
        });
      });
      return googleClasses;
    },
  },
  methods: {
    async sendAssignment() {
      this.hasErrors = false;
      this.sending = true;
      try {
        await this.sendAssignmentHandler(this.googleClasses);
      } catch (e) {
        this.hasErrors = true;
        console.error(e); // eslint-disable-line no-console
      }
      this.sending = false;
      if (!this.hasErrors) {
        this.closeModal();
      }
    },
  },
};
</script>
