<template>
  <div class="heading-xxs text-overline margin-bottom-s"> Select class </div>
  <kog-select
    v-model:selected-value="currentClass"
    :options="options"
    label="Current class"
    :is-label-hidden="true"
  />
</template>

<script>
import { mapState } from 'vuex';

import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

export default {
  name: 'ActivitySubmissionClassSwitcher',
  components: {
    KogSelect,
  },
  mixins: [RoutesMixin],
  computed: {
    ...mapState({
      subjectClasses: state => state.subjectClassModule.subjectClasses,
    }),
    currentClass: {
      get() {
        return this.classId;
      },
      set(value) {
        const subjectClass = this.validClasses.find(sc => sc.id === value);
        this.$router.push({
          params: {
            ...this.$route.params,
            classSlug: subjectClass.slug,
            cid: subjectClass.id,
          },
        });
      },
    },
    validClasses() {
      return this.subjectClasses.filter(sc => sc.subject_id === this.subjectId);
    },
    options() {
      return this.validClasses.map(sc => ({
        text: sc.name,
        value: sc.id,
      }));
    },
  },
};
</script>
