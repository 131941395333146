<template>
  <div
    role="button"
    class="ContentNodeItemTitle flexChild-size-1 cursorPointer flexContainer flexContainer-justifyCenter flexContainer-column"
  >
    <div
      v-if="subtitle || phase"
      class="text-overline muted"
    >
      {{ subtitle }}
      <span v-if="subtitle && phase">&ndash;</span>
      {{ phase }}
    </div>
    <span class="heading-xs font-satoshi">
      <content-hidden-label v-if="isHidden" /> {{ title }}
    </span>
  </div>
</template>

<script>
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';

export default {
  name: 'ContentNodeItemTitle',
  components: { ContentHiddenLabel },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    phase: {
      type: String,
      default: null,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'keypress'],
};
</script>
