<template>
  <kog-modal
    :maximize="true"
    :close-modal="okayButtonClicked"
    title="New question assignment setting: Instant feedback"
  >
    <template #modalBody>
      <div class="margin-m">
        <div class="NewDisplayAnswersSettingModal-body">
          <div
            class="NewDisplayAnswersSettingModal-zigzag NewDisplayAnswersSettingModal-zigzag-top"
          />
          <div class="NewDisplayAnswersSettingModal-sendoutStep">
            <kog-checkbox
              :is-checked="true"
              class="margin-left-xxs margin-bottom-xs"
              label="Allow late submissions"
              :is-disabled="true"
            />
            <div class="NewDisplayAnswersSettingModal-sendoutStep-section">
              <div class="heading-s padd-bottom-m">Display answers</div>
              <kog-radio-group
                :selected-value="availableOptions[2].value"
                aria-label="Display answers setting"
                :options="availableOptions"
                :style="{ color: 'black !important' }"
              />
            </div>
          </div>

          <div
            class="NewDisplayAnswersSettingModal-zigzag NewDisplayAnswersSettingModal-zigzag-bottom"
          />
        </div>
        <p class="margin-m">
          With the new setting you can choose to let students see the correct answer immediately
          after each question.
        </p>
        <kog-button
          class="width-full"
          label="Okay!"
          button-style="accent"
          @click="okayButtonClicked"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script setup>
import { computed } from 'vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';
import KogRadioGroup from 'sharedApp/components/base/radio/kog-radio-group.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import { DISPLAY_ANSWERS_SETTING_OPTIONS } from 'studyApp/constants/teacher-assignment-constants.ts';

const props = defineProps({
  closeModal: {
    type: Function,
    required: true,
  },
  modalShownStorageKey: {
    type: String,
    required: true,
  },
});

const availableOptions = computed(() =>
  DISPLAY_ANSWERS_SETTING_OPTIONS.map(option => ({
    ...option,
    disabled: true,
  })),
);

const okayButtonClicked = () => {
  props.closeModal();
  localStorage.setItem(`${props.modalShownStorageKey}`, true);
};
</script>

<style scoped>
.NewDisplayAnswersSettingModal-zigzag {
  height: 50px;
  background-color: var(--kog-colors-light-600);
}

.NewDisplayAnswersSettingModal-sendoutStep {
  max-width: 90%;
  margin: var(--space-xs) auto var(--space-m);
  border-radius: 8px;
  box-shadow: 0 8px 16px -4px var(--kogShadow020);
}

.NewDisplayAnswersSettingModal-sendoutStep :deep(.KogCheckbox-label),
.NewDisplayAnswersSettingModal-sendoutStep-section :deep(.KogRadio-label) {
  color: var(--kog-text-default);
}

.NewDisplayAnswersSettingModal-sendoutStep-section :deep(.KogRadio-iconInnerContainer) {
  border-color: var(--kog-border-brand-900);
}

.NewDisplayAnswersSettingModal-sendoutStep :deep(.KogCheckbox-iconPath) {
  fill: var(--kog-text-brand);
}

.NewDisplayAnswersSettingModal-sendoutStep-section {
  padding: var(--space-l);
  border-top: 1px solid var(--kogPlatformGray090);
}

.NewDisplayAnswersSettingModal-zigzag-top {
  mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg) 50%/20px 100%;
}

.NewDisplayAnswersSettingModal-zigzag-bottom {
  mask: conic-gradient(from 135deg at top, #0000, #000 1deg 89deg, #0000 90deg) 50%/20px 100%;
}

.NewDisplayAnswersSettingModal-body {
  border-right: 5px solid var(--kog-colors-light-600);
  border-left: 5px solid var(--kog-colors-light-600);
  border-radius: 5px;
}
</style>
