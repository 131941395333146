import type { BlockType } from 'publishingApp/store/modules/feature/activity-constants.ts';

import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';

import ContentBoxConfig from './content-box/config.js';
import CustomEditorConfig from './custom-editor/config.js';
import DividerConfig from './divider/config.js';
import HeadingConfig from './heading/config.js';
import ImageConfig from './image/config.js';
import QuestionConfig from './question/config.js';

export const getBlockTypeConfiguration = (blockType: BlockType | string) => {
  switch (blockType) {
    case BLOCK_TYPE.QUESTION: {
      return QuestionConfig;
    }
    case BLOCK_TYPE.CUSTOM_EDITOR: {
      return CustomEditorConfig;
    }
    case BLOCK_TYPE.CONTENT_BOX: {
      return ContentBoxConfig;
    }
    case BLOCK_TYPE.DIVIDER: {
      return DividerConfig;
    }
    case BLOCK_TYPE.HEADING: {
      return HeadingConfig;
    }
    case BLOCK_TYPE.IMAGE: {
      return ImageConfig;
    }
    default: {
      throw new Error(`Unknown block type: ${blockType}`);
    }
  }
};

export type BlockTemplate = ReturnType<typeof getBlockTypeConfiguration>['template'];
