import { ContextualBalloon } from '@ckeditor/ckeditor5-ui';

import audioIcon from 'publishingApp/assets/icons/volume-regular.svg';

import KogUI from '../utils/kogui.ts';
import { registerFactoryView } from '../utils/plugin-utils.ts';
import FormView from './audio-form.js';

export default class AudioUI extends KogUI {
  static get requires() {
    return [ContextualBalloon];
  }

  init() {
    this.balloon = this.editor.plugins.get(ContextualBalloon);
    this.formView = null;

    registerFactoryView(this.editor, {
      name: 'audio',
      commandName: 'insertAudio',
      icon: audioIcon,
      label: 'Audio',
      onExecute: () => this.showUI(),
    });

    const updateCommand = this.editor.commands.get('updateAudio');
    this.listenTo(updateCommand, 'execute', () => {
      this.showUI();
    });
  }

  createFormView() {
    this.formView = new FormView(this.editor);

    const onSubmit = () => {
      const configuration = {
        url: this.formView.urlInputView.fieldView.element.value,
        alignment: this.formView.selectedAlignment,
      };
      this.editor.execute('insertAudio', configuration);
      this.hideUI();
    };

    this.setupFormView(onSubmit);
  }

  showUI() {
    if (!this.formView) {
      this.createFormView();
    }
    this.balloon.add({
      view: this.formView,
      position: this.getBalloonPositionData(),
    });

    const commandValue = this.editor.commands.get('insertAudio').value;
    if (commandValue) {
      const { alignment, url } = commandValue;
      this.formView.setFields(alignment, url);
    }

    this.formView.focus();
  }
}
