<template>
  <kog-accordion-item
    :is-open="isExpanded"
    :toggle-accordion="onToggleExpand"
    body-class="subjectClassStudentProgressAccordionItem-accordionBody"
    :item-aria-label="className"
  >
    <template #closedContent>
      <div
        class="width-full flexContainer flexContainer-row flexContainer-alignCenter flexContainer-spaceBetween cursorPointer"
      >
        <div class="flexContainer flexContainer-column">
          <span class="subjectClassStudentProgressAccordionItem-classTitle">{{ className }}</span>
          <span>Students completed {{ studentsCompleted }}/{{ studentProgress.length }}</span>
        </div>
        <kog-icon
          :icon-class="isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
          fa-style="regular"
          size="s"
          theme="custom"
        />
      </div>
    </template>
    <template #body>
      <div class="flexContainer flexContainer-row width-full padd-top-xs">
        <div class="text-overline width-50-percent">Students</div>
        <div class="text-overline width-50-percent">Progress</div>
      </div>
      <div
        v-for="student in studentProgress"
        :key="student.user_id"
        v-kog-clickable:[student.user_id]="onStudentClick"
        class="flexContainer flexContainer-row width-full flexContainer-alignCenter margin-top-xs"
      >
        <div class="width-50-percent flexContainer flexContainer-row flexContainer-alignCenter">
          <kog-avatar
            v-if="['xl', 'l'].includes(mq.current)"
            :aria-label="`Student avatar for ${student.name}`"
            class="margin-right-xs"
            :name="student.name"
            size="s"
            :src="student.avatar"
          />
          <div class="margin-right-xs">
            {{ student.name }}
          </div>
        </div>
        <div class="width-50-percent">
          <kog-progress-bar
            :progress="getStudentCompletionPercentage(student)"
            size="s"
            type="positive"
          />
        </div>
      </div>
    </template>
  </kog-accordion-item>
</template>

<script>
import KogAccordionItem from 'sharedApp/components/accordion/kog-accordion-item.vue';
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogClickable from 'sharedApp/directives/kog-clickable.js';

export default {
  name: 'SubjectClassStudentProgressAccordionItem',
  components: {
    KogProgressBar,
    KogAvatar,
    KogIcon,
    KogAccordionItem,
  },
  directives: {
    KogClickable,
  },
  inject: ['mq'],
  props: {
    studentProgress: {
      type: Array,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
  },
  emits: ['student-clicked'],
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    studentsCompleted() {
      return this.studentProgress.filter(student => student.completedCount === student.totalCount)
        .length;
    },
  },
  methods: {
    onToggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    onStudentClick(studentId) {
      this.$emit('student-clicked', studentId);
    },
    getStudentCompletionPercentage(student) {
      return Math.round(100 * (student.completedCount / student.totalCount));
    },
  },
};
</script>

<style scoped>
.subjectClassStudentProgressAccordionItem-classTitle {
  font-size: 16px;
  font-weight: 500;
}
</style>
