<template>
  <block-wrapper
    :content="content"
    :block-label="blockLabel"
    @mark-removal="$event => $emit('mark-removal', $event)"
    @unmark-removal="$event => $emit('unmark-removal', $event)"
    @move-down="$event => $emit('move-down', $event)"
    @move-up="$event => $emit('move-up', $event)"
  >
    <kog-input
      class="HeadingBlock-input"
      :value="content.text"
      label="Insert heading"
      placeholder="Insert heading"
      :is-label-hidden="true"
      @input="value => updateContentField('text', value)"
    />
  </block-wrapper>
</template>

<script>
import KogInput from 'sharedApp/components/base/input/kog-input.vue';

import BlockWrapper from '../../block-wrapper.vue';
import BlockMixin from '../mixins/block-mixin.js';

export default {
  name: 'HeadingBlock',
  components: {
    BlockWrapper,
    KogInput,
  },
  mixins: [BlockMixin],
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  emits: ['mark-removal', 'unmark-removal', 'move-up', 'move-down'],
};
</script>

<style scoped>
.HeadingBlock-input {
  font-size: 30px;
}

.HeadingBlock-input :deep(input) {
  font-weight: 700;
}
</style>
