<template>
  <div>
    <a
      v-if="isImage"
      v-tooltip="{
        theme: 'kog-tooltip',
        content: 'Download image',
      }"
      :aria-label="linkLabel"
      :href="downloadImageUrl"
      download
      @click.stop="trackDownload"
    >
      <kog-icon
        class="ResourceCard-Header-Icon"
        icon-class="fa-file-download"
        size="s"
        :theme="theme"
        fa-style="solid"
      />
    </a>
    <button
      v-tooltip="{
        theme: 'kog-tooltip',
        content: 'View in fullscreen',
      }"
      class="KogButtonLegacy--noStyle"
      @click.stop="openFullscreen()"
    >
      <kog-icon
        class="ResourceCard-Header-Icon"
        icon-class="fa-expand"
        size="s"
        :theme="theme"
        fa-style="solid"
      />
    </button>
    <button
      v-tooltip="{
        theme: 'kog-tooltip',
        content: linkTooltipText,
        triggers: ['hover'],
      }"
      class="KogButtonLegacy--noStyle"
      @click.stop="linkCopied"
    >
      <kog-icon
        class="ResourceCard-Header-Icon"
        icon-class="fa-link"
        size="s"
        :theme="theme"
        fa-style="solid"
      />
    </button>
  </div>
</template>

<script>
import { useClipboard } from '@vueuse/core';
import { VTooltip } from 'floating-vue';

import SectionUrlMixin from 'learning/common/mixins/section-url-mixin.js';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import ResourceFullscreen from 'sharedApp/components/resource-fullscreen/resource-fullscreen.vue';
import { imgixHosts } from 'sharedApp/utils/image-utils.js';

const LINK_TOOLTIP_DEFAULT = 'Copy shareable link';
const LINK_TOOLTIP_COPIED = 'Copied!';

export default {
  name: 'ResourceActionButtons',
  components: { KogIcon },
  directives: {
    tooltip: VTooltip,
  },
  mixins: [SectionUrlMixin],
  props: {
    resource: {
      type: Object,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'dark',
    },
    source: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { copy } = useClipboard();

    return {
      copyToClipboard: copy,
    };
  },
  data() {
    return {
      linkTooltipText: LINK_TOOLTIP_DEFAULT,
    };
  },
  computed: {
    linkLabel() {
      return `Download image from ${this.resource.subject_node_name}`;
    },
    sectionUrl() {
      const sectionUrl = this.generateSectionUrl(
        this.resource.subject_node_name,
        this.resource.subject_node_id,
        this.$route.params,
        this.resource,
      );
      return sectionUrl.toString();
    },
    downloadImageUrl() {
      const url = new URL(this.resource.src);
      url.hostname = imgixHosts[0];
      url.search = '?dl';
      return url.toString();
    },
    isImage() {
      return this.resource.type === 'image';
    },
    trackingProps() {
      return {
        subject_name: this.subject.name,
        resource_url: this.resource.src,
        asset_type: this.resource.type,
      };
    },
  },
  methods: {
    openFullscreen() {
      this.$modal(
        ResourceFullscreen,
        {
          url: this.resource.src,
          type: this.resource.type,
        },
        {
          closeExisting: true,
        },
      );
      this.$mixpanel.trackEvent('Media library - Click Fullscreen mode', {
        ...this.trackingProps,
        source: this.source,
      });
    },
    linkCopied() {
      this.copyToClipboard(this.sectionUrl);
      this.linkTooltipText = LINK_TOOLTIP_COPIED;
      setTimeout(() => {
        this.linkTooltipText = LINK_TOOLTIP_DEFAULT;
      }, 1500);
      this.$mixpanel.trackEvent('Media library - Copy link', {
        ...this.trackingProps,
        source: this.source,
      });
    },
    trackDownload() {
      this.$mixpanel.trackEvent('Media library - Download', {
        ...this.trackingProps,
        source: this.source,
      });
    },
  },
};
</script>

<style scoped>
.ResourceCard-Header-Icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.ResourceCard-Header-Icon:hover {
  background-color: color-mod(var(--kogPlatformWhite) alpha(15%));
}
</style>
