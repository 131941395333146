<template>
  <kog-modal title="Choose a visualisation">
    <template #modalBody>
      <div
        v-for="(scale, index) in scales"
        :key="index"
      >
        <div class="padd-1 padd-bottom-xxl">
          <input
            :id="scale.key"
            v-model="selectedScale"
            :value="scale.key"
            name="scale"
            type="radio"
          />
          <label
            :for="scale.key"
            class="padd-left-1"
          >
            <span class="text-bold">
              {{ scale.key === 'Standard' ? 'Standard scale' : `Suggested scale for ${scale.key}` }}
            </span>
            <performance-scale
              :scale="scale.key"
              :origin="origin"
            />
          </label>
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div class="flexContainer flexContainer-alignBaseline flexContainer-spaceBetween width-full">
        <a
          href="#"
          @click.prevent="openNewIntercomChat"
        >
          Don't see what you need? Click here to let us know
        </a>
        <div class="KogButtonSet KogButtonSet--right">
          <button
            class="KogButtonLegacy"
            @click="closeModal"
          >
            Cancel
          </button>
          <button
            class="KogButtonLegacy KogButtonLegacy--primary"
            @click.once="confirmScale"
          >
            Confirm
          </button>
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import PerformanceScale from 'teachApp/components/performance-scale.vue';

export default {
  name: 'ScaleSelectionModal',
  components: {
    KogModal,
    PerformanceScale,
  },
  props: {
    scales: {
      type: Array,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    currentScale: {
      type: String,
      required: true,
    },
    selectScale: {
      type: Function,
      required: true,
    },
    origin: {
      type: String,
      required: true,
    },
  },
  created() {
    this.selectedScale = this.currentScale;
  },
  methods: {
    confirmScale() {
      this.selectScale(this.selectedScale);
      this.closeModal();
    },
    openNewIntercomChat() {
      intercomModule.showNewMessage();
    },
  },
};
</script>

<style scoped>
/* To overwrite bootstrap */

label {
  font-weight: normal;
}
</style>
