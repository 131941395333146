import { Command } from '@ckeditor/ckeditor5-core';

import { findNonParagraphAncestor } from '../utils/commands.js';

const headerToTag = headerTag =>
  ({
    heading2: 'h2',
    heading3: 'h3',
    heading4: 'h4',
  })[headerTag] || headerTag;

function create(editor, writer, { templateId, titleTag, contentNode }) {
  const pluginConfig = editor.config.get('contentBox');
  const { contentBoxTemplateMap } = pluginConfig;
  const { title: titleText } = contentBoxTemplateMap[templateId];

  const contentBoxTag = writer.createElement('contentBoxTag', {
    templateId,
    titleTag: headerToTag(titleTag),
  });

  const contentBox = writer.createElement('contentBox');
  writer.append(contentBox, contentBoxTag);

  const contentBoxIcon = writer.createElement('contentBoxIcon');
  writer.append(contentBoxIcon, contentBox);

  const contentBoxTitle = writer.createElement('contentBoxTitle');
  writer.append(contentBoxTitle, contentBox);

  let titleElement = null;
  if (titleTag === 'span') {
    titleElement = writer.createText(titleText, { spanKogId: 'new' });
  } else {
    titleElement = writer.createElement(titleTag);
    writer.insertText(titleText, titleElement);
  }
  writer.append(titleElement, contentBoxTitle);
  if (contentNode) {
    const contentClone = writer.cloneElement(contentNode);
    writer.append(contentClone, contentBox);
  } else {
    const contentBoxContent = writer.createElement('contentBoxContent');
    writer.append(contentBoxContent, contentBox);
    const content = writer.createElement('paragraph');
    const textToInsert = 'Add some content here...';
    writer.insertText(textToInsert, content);
    writer.append(content, contentBoxContent);
  }
  return contentBoxTag;
}

export default class InsertContentBoxCommand extends Command {
  execute(configuration) {
    const { model } = this.editor;
    model.change(writer => {
      const updatedConfiguration = {
        ...configuration,
        contentNode: this.value?.contentNode,
      };
      const selection = findNonParagraphAncestor(writer, model.document.selection);
      model.insertObject(create(this.editor, writer, updatedConfiguration), selection);
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(
      document.selection.getFirstPosition(),
      'contentBoxTag',
    );
    this.isEnabled = allowedIn !== null;

    const firstRange = document.selection.getFirstRange();
    if (!firstRange.isCollapsed && firstRange.start.nodeAfter?.name === 'contentBoxTag') {
      const node = firstRange.start.nodeAfter;
      const contentNode = node?.getNodeByPath([0, 2]);
      const value = {
        templateId: node.getAttribute('templateId'),
        titleTag: node.getAttribute('titleTag'),
        contentNode,
      };
      this.value = value;
    } else {
      this.value = null;
    }
  }
}
