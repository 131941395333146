<template>
  <span class="margin-bottom-xs margin-top-m">
    <span class="GoogleClassesInlineList-text">
      <slot name="prefix" />
    </span>
    <span
      v-for="(gc, index) in googleClasses"
      :key="gc.id"
    >
      <span class="GoogleClassesInlineList-text">
        {{ separator(index) }}
      </span>
      <div class="GoogleClassesInlineList-googleClassPill">
        <google-class-pill
          :google-class="gc"
          size="small"
        />
      </div>
    </span>
    <span class="GoogleClassesInlineList-text">
      <slot name="suffix" />
    </span>
  </span>
</template>

<script>
import { listSeparator } from 'sharedApp/utils/string-utils.js';
import GoogleClassPill from 'studyApp/components/teacher/google-class-pill.vue';

export default {
  name: 'GoogleClassesInlineList',
  components: {
    GoogleClassPill,
  },
  props: {
    googleClasses: {
      type: Array,
      required: true,
    },
  },
  methods: {
    separator(index) {
      return listSeparator(index, this.googleClasses.length);
    },
  },
};
</script>
<style scope>
.GoogleClassesInlineList-googleClassPill {
  display: inline-block;
}

.GoogleClassesInlineList-text {
  vertical-align: text-bottom;
}
</style>
