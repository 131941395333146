<template>
  <kog-tag
    :is-chip="true"
    :label="tagLabel"
    :type="tagType"
    :icon-class="tagIcon"
    :context="tagContext"
    @click="openSummary"
  />
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export default {
  name: 'ReflectionQuestionSummaryCell',
  components: {
    KogTag,
  },
  props: {
    topic: {
      type: Object,
      required: true,
    },
    submissionCount: {
      type: Number,
      required: true,
    },
    studentTotal: {
      type: Number,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  computed: {
    allSubmitted() {
      return this.submissionCount === this.studentTotal;
    },
    tagLabel() {
      return this.allSubmitted
        ? `${this.submissionCount} / ${this.studentTotal}`
        : `${this.studentTotal - this.submissionCount} / ${this.studentTotal}`;
    },
    tagType() {
      return this.allSubmitted ? 'positive' : 'informative';
    },
    tagIcon() {
      return this.allSubmitted ? 'fa-user-check' : 'fa-user-times';
    },
    tagContext() {
      return `${this.submissionCount} of ${this.studentTotal} submitted`;
    },
  },
  methods: {
    openSummary() {
      this.onClick(this.topic);
    },
  },
};
</script>
