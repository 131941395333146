import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView, createDropdown, SplitButtonView } from '@ckeditor/ckeditor5-ui';

import shapesIcon from 'publishingApp/assets/icons/shapes-solid.svg';

import ExerciseQuestion from '../exercise-question/exercise-question.js';

export default class ExerciseBoxUI extends Plugin {
  static get requires() {
    return [ExerciseQuestion];
  }

  init() {
    this.editor.ui.componentFactory.add('exerciseBox', locale => {
      const actionButton = new ButtonView(locale);
      actionButton.set({
        label: 'Exercise box',
        icon: shapesIcon,
        tooltip: true,
        withText: true,
      });

      actionButton.on('execute', () => this.editor.execute('insertExerciseBox'));

      const splitButton = new SplitButtonView(locale, actionButton);

      const dropdownView = createDropdown(locale, splitButton);
      dropdownView.once('change:isOpen', () => {
        const exerciseQuestionButton = this.editor.ui.componentFactory.create('exerciseQuestion');
        exerciseQuestionButton.set({
          label: 'Add exercise question',
        });
        dropdownView.panelView.children.add(exerciseQuestionButton);
      });

      return dropdownView;
    });
  }
}
