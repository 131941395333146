import { ButtonView } from '@ckeditor/ckeditor5-ui';
import { escape } from 'lodash';

import type { Editor } from '@ckeditor/ckeditor5-core';

import { dangerouslyGetUnescapedText } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

export function htmlToText(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.innerText || '';
}

export function getQuestionText(questionHtml: string) {
  return escape(dangerouslyGetUnescapedText(questionHtml));
}

export function getQuestionTextShort(questionHtml: string, limit = 45) {
  const text = getQuestionText(questionHtml);
  const preview = text.length > limit ? `${text.slice(0, limit)}...` : text;
  return preview;
}

export type FactoryViewOptions = {
  name: string;
  commandName: string;
  onExecute: () => void;
  icon?: string;
  label?: string;
  tooltip?: boolean;
};
export function registerFactoryView(editor: Editor, options: FactoryViewOptions) {
  const { name, commandName, icon, label, onExecute, tooltip = true } = options;
  editor.ui.componentFactory.add(name, locale => {
    const command = editor.commands.get(commandName);
    const buttonView = new ButtonView(locale);
    const withText = true;

    buttonView.set({ label, withText, tooltip, icon });

    if (command) {
      buttonView.bind('isEnabled').to(command, 'isEnabled');
    }

    buttonView.on('execute', () => onExecute());

    return buttonView;
  });
}
