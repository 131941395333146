const inlineQuestionAnswerToggleUtil = {
  inlineQuestionToggle(event) {
    const questionElement = event.target.parentNode.parentNode;

    const solutionElem = questionElement.querySelector('.inline-question-answer');
    solutionElem.classList.toggle('hidden-widget');

    const titles = questionElement.querySelectorAll('.inline-question-view-answer-title');
    titles.forEach(title => title.classList.toggle('hidden-widget'));
  },

  registerInlineQuestionAnswerToggleListener(elem) {
    const inlineQuestions = elem.querySelectorAll('.inline-question-view-answer');
    inlineQuestions.forEach(question =>
      question.addEventListener('click', this.inlineQuestionToggle),
    );
  },
};

export default inlineQuestionAnswerToggleUtil;
