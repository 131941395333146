<template>
  <footer
    :class="[
      'KogFooter',
      'flexContainer',
      'flexContainer-spaceBetween',
      {
        'KogFooter-absolute': absolute,
      },
    ]"
  >
    <div>{{ year }} © Kognity</div>
    <kog-item-list
      role="list"
      class="list-style-none flexContainer"
    >
      <li>
        <a
          href="/cookie-policy/"
          class="margin-right-1"
        >
          Cookies
        </a>
      </li>
      <li>
        <a href="/terms/">Terms of use</a>
      </li>
    </kog-item-list>
  </footer>
</template>

<script>
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';

export default {
  name: 'KogFooter',
  components: {
    KogItemList,
  },
  props: {
    absolute: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const year = new Date().getFullYear();
    return {
      year,
    };
  },
};
</script>

<style scoped>
.KogFooter {
  bottom: 0;

  width: 100%;
  margin: 0;
  padding: 30px 40px;

  background-color: var(--kog-footer-background);
}

.KogFooter-absolute {
  position: absolute;
}
</style>
