import { icons } from '@ckeditor/ckeditor5-core';
import {
  addListToDropdown,
  createLabeledDropdown,
  LabeledFieldView,
  Model,
  View,
  ViewCollection,
} from '@ckeditor/ckeditor5-ui';
import { FileDialogButtonView } from '@ckeditor/ckeditor5-upload';
import { Collection } from '@ckeditor/ckeditor5-utils';

import FlexWrapperView from '../shared/flex-wrapper-view.ts';
import KogForm from '../utils/kogform.js';

import './audio.css';

export const ALIGNMENT_OPTIONS = [
  { label: 'Default alignment', alignment: null },
  { label: 'Align left', alignment: 'left' },
  { label: 'Align center', alignment: 'center' },
  { label: 'Align right', alignment: 'right' },
];

export default class FormView extends KogForm {
  constructor(editor) {
    super(editor.locale);

    this.set({
      selectedAlignment: null,
    });

    this.editor = editor;
    this.locale = editor.locale;

    this.urlInputView = this.createInput('URL*');

    this.uploadButtonView = this.createUploadButton(this.locale);

    this.saveButtonView = this.createSaveButton();
    this.saveButtonView.bind('isEnabled').to(this.urlInputView, 'isEmpty', val => !val);
    this.cancelButtonView = this.createCancelButton();

    this.alignmentDropdownView = this.createDropdown({
      label: 'Alignment',
      options: ALIGNMENT_OPTIONS,
      observableTarget: 'selectedAlignment',
    });

    this.uploadWrapperView = new View(this.locale);
    this.uploadWrapperView.setTemplate({
      tag: 'fieldset',
      children: [
        {
          tag: 'legend',
          children: ['File upload'],
        },
        this.uploadButtonView,
        {
          tag: 'p',
          children: ['Select a wav, mp3, mp4 or m4a file with max size of 50 MB.'],
        },
      ],
    });

    this.childViews = this.createCollection([
      this.urlInputView,
      this.uploadWrapperView,
      this.alignmentDropdownView,
      new FlexWrapperView(this.locale, {
        direction: 'row',
        children: [this.saveButtonView, this.cancelButtonView],
      }),
    ]);

    const focusables = new ViewCollection([
      this.urlInputView,
      this.uploadButtonView,
      this.alignmentDropdownView,
      this.saveButtonView,
      this.cancelButtonView,
    ]);

    this.setup('audio', focusables);
  }

  focus() {
    this.focusCycler.focusFirst();
  }

  reset() {
    super.reset();
    this.urlInputView.fieldView.value = '';
    this.urlInputView.errorText = null;
    this.selectedAlignment = null;
  }

  createDropdown({ label, options, observableTarget }) {
    const dropdown = new LabeledFieldView(this.locale, createLabeledDropdown);
    dropdown.bind('isEmpty').to(this, observableTarget, val => !val);
    dropdown.set({ label });

    dropdown.fieldView.buttonView.set({
      withText: true,
      ariaLabel: label,
    });
    dropdown.fieldView.on('execute', event => {
      this.set(observableTarget, event.source.alignment);
      dropdown.fieldView.buttonView.set({ label: event.source.label });
    });

    const collectionItems = new Collection();
    options.forEach(option => {
      const model = new Model({
        withText: true,
        ...option,
      });

      model.bind('isOn').to(this, observableTarget, val => val === option.alignment);

      collectionItems.add({
        type: 'button',
        model,
      });
    });

    addListToDropdown(dropdown.fieldView, collectionItems);

    return dropdown;
  }

  createUploadButton(locale) {
    const uploadButtonView = new FileDialogButtonView(locale);

    uploadButtonView.set({
      acceptedType: 'audio/*',
      allowMultipleFiles: false,
      withText: true,
      label: 'Upload file',
      icon: icons.plus,
      tooltip: true,
    });

    uploadButtonView.on('done', (_evt, files) => {
      const file = files[0];
      const loader = this.editor.plugins.get('FileRepository').createLoader(file);
      loader
        .upload()
        .then(response => {
          this.urlInputView.errorText = null;
          this.urlInputView.fieldView.value = response.default;
        })
        .catch(error => {
          this.urlInputView.errorText = error;
        });
    });

    return uploadButtonView;
  }

  setFields(alignment, url) {
    this.urlInputView.fieldView.value = url;
    const selectedItem = ALIGNMENT_OPTIONS.find(item => item.alignment === alignment);
    const { label, alignment: selectedAlignment } = selectedItem;
    this.alignmentDropdownView.fieldView.buttonView.set({ label });
    this.selectedAlignment = selectedAlignment;
  }
}
