<template>
  <div>
    <section :class="pageBackgroundClass">
      <div id="StudyAppMain-navbar--portal">
        <transition name="fade">
          <study-navbar
            v-if="shouldShowStudyNavbar"
            :notifications="notifications"
            :mark-as-seen="markNotificationsAsSeen"
            :menu-items="menuItems"
            :sub-menu-items="subMenuItems"
            :select-menu-item="selectMenuItem"
            :selected-menu="selectedMenuItem"
            :select-sub-menu="selectMenuItem"
            :selected-sub-menu="selectedSubMenuItem"
            :user="user"
            :subject-classes="subjectClasses"
            :subjects-by-id="subjectsById"
            :subject="subject"
            :route-name="$route.name || 'null'"
            :is-transparent="isSubjectClassOverviewSelected"
          />
        </transition>
      </div>
      <transition name="fade">
        <study-mini-navbar
          v-if="shouldShowStudyMiniNavbar"
          :notifications="notifications"
          :mark-as-seen="markNotificationsAsSeen"
          :subject="subject"
          :user="user"
        />
      </transition>

      <main
        class="StudyAppMain"
        :class="{
          'StudyAppMain-withSubMenu': shouldShowSubMenu,
          'StudyAppMain--noTopPadding': hasTopPaddingZero,
          'StudyAppMain--noBottomPadding': hasBottomPaddingZero,
          'StudyAppMain--editingVisibility': isEditingVisibility,
        }"
      >
        <loader-indicator
          :loading="isLoading"
          loading-msg="Loading your data..."
        />
        <router-view
          v-slot="{ Component, props }"
          :class="pageClass"
        >
          <page-transition
            class="StudyAppMain-pageTransition"
            @before-navigation="onBeforeNavigationTransition"
            @after-leave-transition="onAfterNavigationTransition"
          >
            <component
              :is="Component"
              v-if="!isLoading"
              v-bind="props"
            />
          </page-transition>
        </router-view>
        <demo-footer
          v-if="isDemoUser"
          :user="user"
          :demo-type="demoType"
          :enable-trial-button="enableTrialCTA"
        />
      </main>

      <div
        id="StudyAppMain-bottomMenu--portal"
        class="sticky-bottom"
      >
        <transition name="fade">
          <mobile-bottom-navbar
            v-if="shouldShowMobileNavbar"
            :menu-items="menuItems"
            :select-menu-item="selectMenuItem"
            :selected-menu="selectedMenuItem"
            :is-demo-footer-showing="isDemoUser"
          />
        </transition>
      </div>

      <div
        v-if="!isFullScreen && user.hasEditContentPermission()"
        class="StudyAppMain-editorButtons"
      >
        <editor-buttons />
      </div>
    </section>
  </div>
</template>

<script>
import { computed } from 'vue';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import * as Sentry from '@sentry/vue';
import { keyBy, uniq } from 'lodash';
import { storeToRefs } from 'pinia';
import { mapActions, mapGetters, mapState, useStore } from 'vuex';

import DemoFooter from 'learning/common/components/demo-footer.vue';
import StudyMiniNavbar from 'learning/common/components/study-mini-navbar.vue';
import StudyNavbar from 'learning/common/components/study-navbar.vue';
import { NO_CLASS_SLUG } from 'learning/common/libs/subject-class-utils.js';
import dailySubjectActivityService from 'learning/common/services/activity/daily-subject-activity-service.js';
import useAssignmentStore from 'learning/common/store-modules/assignment.ts';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import PageTransition, { TRANSITIONS } from 'sharedApp/animations/page-transition.vue';
import LoaderIndicator from 'sharedApp/components/base/indicators/kog-loader.vue';
import { VIEWPORT_M_PX } from 'sharedApp/const/css/media.js';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import { fetchSubjectDetails } from 'studyApp/api/subject.js';
import EditorButtons from 'studyApp/components/editor-buttons.vue';
import MobileBottomNavbar from 'studyApp/components/mobile-bottom-navbar.vue';
import NavigationFactory from 'studyApp/menus/navigation-factory.js';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';

import 'sharedApp/styles/index.js';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export default {
  name: 'SubjectClassContainer',
  components: {
    StudyMiniNavbar,
    LoaderIndicator,
    StudyNavbar,
    DemoFooter,
    MobileBottomNavbar,
    EditorButtons,
    PageTransition,
  },
  mixins: [RoutesMixin, ReadingAssignmentMixin],
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const {
      fetchIsMultipleClassAssignmentEnabled,
      fetchShowFiltersOnClassOverview,
      fetchIsEdlinkAssignmentIntegrationEnabled,
    } = featureFlagStore;
    const assignmentStore = useAssignmentStore();
    const { fetchOngoingAssignments } = assignmentStore;
    const { ongoingAssignments } = storeToRefs(assignmentStore);
    const store = useStore();

    return {
      fetchIsMultipleClassAssignmentEnabled,
      fetchShowFiltersOnClassOverview,
      fetchIsEdlinkAssignmentIntegrationEnabled,
      ongoingAssignments,
      fetchOngoingAssignments,
      subjectClassesById: computed(() => store.getters['subjectClassModule/subjectClassesById']),
    };
  },
  data() {
    return {
      subjectSlug: this.$route.params.subjectSlug,
      hasTopPaddingZeroOverride: null,
      resizeObserver: null,
    };
  },
  computed: {
    ...mapState('subjectModule', {
      subject: state => state.subject,
      level: state => state.level,
      lastOpenedSubjectNode: state => state.lastOpenedSubjectNode,
    }),
    ...mapState({
      user: state => state.userModule.user,
      notifications: state => state.notificationsModule.notifications,
      allSubjectClasses: state => state.subjectClassModule.subjectClasses,
      subjectsList: state => state.subjectModule.subjectList,
      isEditingVisibility: state => state.subjectClassModule.isEditingVisibility,
    }),
    ...mapGetters('userModule', ['isDemoUser', 'demoType', 'enableTrialCTA']),
    ...mapGetters({
      hasSubject: 'subjectModule/hasSubject',
      subjectsById: 'subjectModule/subjectsById',
      getTeachingSubjectClasses: 'subjectClassModule/getTeachingSubjectClasses',
    }),
    selectedMenuItem() {
      if (this.$route.meta.menuRoute) {
        return this.$route.meta.menuRoute;
      }
      return 'subjectOverview';
    },
    isLoading() {
      return this.$wait.waiting(['loading_user', 'loading_subjects', 'loading_subject_class']);
    },
    isNgss() {
      return isNgss(this.educationSystem);
    },
    educationSystem() {
      return this.subject?.educationsystem?.name;
    },
    subMenuItems() {
      const selectedItem = this.menuItemsByKey[this.selectedMenuItem];
      if (selectedItem && selectedItem.subMenuItems) {
        return selectedItem.subMenuItems;
      }
      return [];
    },
    selectedSubMenuItem() {
      const subMenuItem = this.$route.meta?.subMenuRoute;
      if (subMenuItem) {
        return subMenuItem;
      }
      return null;
    },
    shouldShowSubMenu() {
      return this.selectedSubMenuItem !== null;
    },
    shouldShowStudyNavbar() {
      return (
        !this.isFullScreen &&
        this.hasSubject &&
        !this.isBookTabSelected &&
        !this.isReadingAssignmentFocus
      );
    },
    shouldShowStudyMiniNavbar() {
      return !this.isFullScreen && !this.isLoading && this.isBookTabSelected;
    },
    shouldShowMobileNavbar() {
      return (
        !this.isFullScreen &&
        !this.isBookTabSelected &&
        !this.isAssignmentCreatePage &&
        !this.isQuestionAssignmenTakePage
      );
    },
    hasTopPaddingZero() {
      const hasTopPaddingZero =
        this.isFullScreen ||
        this.isBookTabSelected ||
        (this.isSubjectClassOverviewSelected && !this.isLoading) ||
        this.isReadingAssignmentFocus ||
        this.isAssignmentDetailsPage;

      return this.hasTopPaddingZeroOverride ?? hasTopPaddingZero;
    },
    hasBottomPaddingZero() {
      return (
        this.isFullScreen ||
        this.isBookTabSelected ||
        this.isAssignmentOverviewPage ||
        this.isReadingAssignmentFocus
      );
    },
    isBookTabSelected() {
      const bookRoutes = ['classBook', 'reviewMode'];
      return bookRoutes.includes(this.$route.name);
    },
    isAssignmentCreatePage() {
      return this.$route.name === 'teacherAssignmentCreate';
    },
    isAssignmentDetailsPage() {
      return this.$route.name === 'teacherAssignmentDetails';
    },
    isAssignmentOverviewPage() {
      return this.$route.name === 'teacherAssignmentOverview';
    },
    isSubjectClassOverviewSelected() {
      return this.$route.name === 'subjectClassOverview';
    },
    isBookPrintPage() {
      return this.$route.name === 'bookPrint';
    },
    isQuestionAssignmenTakePage() {
      return this.$route.name === 'classQuestionAssignmentTake';
    },
    isStudentAssessmentPage() {
      return [
        'classStrengthTestSetup',
        'classStrengthTest',
        'classPracticeEsqSetup',
        'classBattleSetup',
        'classBattleTake',
        'classBattleResult',
        'classAssignmentsOverview',
        'classQuestionAssignmentTake',
        'classQuestionAssignmentResult',
        'classEsqAssignmentOverview',
        'classEsqAssignmentTake',
        'classReadingAssignmentTake',
      ].includes(this.$route.name);
    },
    menuItemsByKey() {
      const mainItems = keyBy(this.menuItems, 'key');
      const subItemsList = this.menuItems
        .filter(item => !!item.subMenuItems)
        .flatMap(item => item.subMenuItems);
      const subItems = keyBy(subItemsList, 'key');

      return {
        ...mainItems,
        ...subItems,
      };
    },
    menuItems() {
      const factory = new NavigationFactory({
        isTeacher: this.user.isTeacher(),
        isStudent: this.user.isStudent(),
        isNgssSubject: this.isNgss,
        educationSystem: this.educationSystem,
        ongoingAssignmentsCount: this.ongoingAssignments?.count ?? 0,
        isSubjectClassOverviewPage: this.isSubjectClassOverviewSelected,
      });

      return factory.create();
    },
    subjectClasses() {
      if (this.user.isStudent()) {
        return this.allSubjectClasses.filter(sc => sc.is_subscription_ok);
      }
      if (this.user.isTeacher()) {
        return this.getTeachingSubjectClasses(this.user.id);
      }
      return this.simulatedSubjectClassesFromSubjects;
    },
    simulatedSubjectClassesFromSubjects() {
      return this.subjectsList.map(subject => ({
        ...subject,
        id: subject.id,
        name: subject.display_name,
        subject_id: subject.id,
        teacher_user_ids: [],
        student_user_ids: [],
        teachers: [],
        students: [],
      }));
    },
    pageBackgroundClass() {
      if (
        this.isBookTabSelected ||
        this.isAssignmentCreatePage ||
        this.isBookPrintPage ||
        this.isAssignmentOverviewPage
      ) {
        return 'kogPlatformWhite-bg';
      }

      if (this.isSubjectClassOverviewSelected || this.isStudentAssessmentPage) {
        return 'kogPlatformGray094-bg';
      }

      return 'kogPlatformGray098-bg';
    },
    pageClass() {
      const pageClasses = [];
      if (
        !this.isBookTabSelected &&
        !this.isSubjectClassOverviewSelected &&
        !this.isFullScreen &&
        !this.isReadingAssignmentFocus
      ) {
        pageClasses.push('padd-top-l');
      }

      if (this.isFullScreen || this.isReadingAssignmentFocus) {
        pageClasses.push('page-full-screen');
      }

      return pageClasses;
    },
    isReadingAssignmentFocus() {
      return this.isAssignmentFocus;
    },
    isFullScreen() {
      return Boolean(this.$route.meta?.fullScreen);
    },
    isNoClass() {
      return Boolean(this.$route.meta?.noClass);
    },
    integrationProvider() {
      const subjectClass = this.subjectClassesById[this.classId];
      return subjectClass?.integration_providers?.length > 0
        ? subjectClass.integration_providers[0]
        : '';
    },
  },
  watch: {
    classId(newId, oldId) {
      if (Number(newId) !== Number(oldId)) {
        this.fetchSubjectClassData();
        this.fetchIsMultipleClassAssignmentEnabled(this.integrationProvider);
      }
    },
    subjectId(newId, oldId) {
      if (Number(newId) !== Number(oldId)) {
        this.fetchShowFiltersOnClassOverview(newId);
      }
    },
  },
  async created() {
    try {
      this.$wait.start('loading_user');
      if (this.isNoClass) {
        await this.fetchUser();
        this.$wait.end('loading_user');
        return;
      }
      await this.fetchUser();
      this.$featureFlags.registerContext({ userId: this.user.id, schoolId: this.user.school?.id });
      this.$event.setUser(this.user);
      this.$wait.end('loading_user');

      this.$wait.start('loading_subjects');
      await this.fetchSubjects();
      this.$wait.end('loading_subjects');
    } catch (e) {
      this.$toast.showError(
        'Page cannot be loaded, please try to refresh or contact support if it persists.',
      );
      throw e;
    }

    this.fetchSubjectClassData();
    this.initResizeObserver();
    await Promise.all([
      this.fetchIsMultipleClassAssignmentEnabled(this.integrationProvider),
      this.fetchShowFiltersOnClassOverview(this.subjectId),
      this.fetchIsEdlinkAssignmentIntegrationEnabled(this.integrationProvider),
    ]);
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    ...mapActions('subjectModule', [
      'fetchSubjectList',
      'fetchSubjectclassDetails',
      'fetchSubject',
      'fetchLastSectionOpened',
    ]),
    ...mapActions({
      fetchUser: 'userModule/fetchUser',
      registerLastActivity: 'activityModule/registerLastActivity',
      fetchQuestionMappings: 'examStyleQuestionModule/fetchQuestionMappings',
      fetchNotifications: 'notificationsModule/fetchNotifications',
      markNotificationsAsSeen: 'notificationsModule/markNotificationsAsSeen',
      fetchSubjectClassList: 'subjectClassModule/fetchSubjectClassList',
      fetchSubjectNodesVisibility: 'subjectClassModule/fetchSubjectNodesVisibility',
    }),
    async fetchSubjects() {
      if (this.user.isTeacher() || this.user.isStudent()) {
        await this.fetchSubjectClassList();
        const subjectIds = uniq(this.subjectClasses.map(sc => sc.subject_id));
        await this.fetchSubjectList(subjectIds);
      } else {
        await this.fetchSubjectList();
      }
    },
    async fetchSubjectClassData() {
      try {
        this.$wait.start('loading_subject_class');
        await Promise.all([this.fetchCurrentSubjectData(), this.fetchCurrentClassData()]);
        this.$wait.end('loading_subject_class');

        this.fetchNotifications();
        this.checkClassSlug();
      } catch (e) {
        const toastId = this.$toast.showError(
          'Page cannot be loaded, please try to refresh or contact support if it persists.',
          {
            actionText: 'Go to dashboard',
            toasterActionPerformed: () => {
              this.$toast.close(toastId);
              this.$router.replace({ name: 'studyDashboard' });
            },
          },
        );
        Sentry.captureException(e);
        throw e;
      }
    },
    async fetchCurrentSubjectData() {
      if (this.subject?.id !== this.subjectId) {
        if (this.subjectId) {
          const subject = await fetchSubjectDetails(this.subjectId);
          this.subjectSlug = subject.slug;
        }
        await Promise.all([
          this.fetchSubjectTree(),
          this.registerActivity(),
          this.fetchLastSectionOpened(this.subjectId),
          this.fetchQuestionMappings(this.subjectId),
        ]);

        this.$term.setEducationSystem(this.educationSystem);
      }

      this.setupMixpanelSubjectClassProperties();
    },
    async fetchCurrentClassData() {
      await Promise.all([
        this.fetchClassDetails(),
        this.fetchAssignments(),
        this.fetchNodesVisibility(),
      ]);
    },
    async fetchClassDetails() {
      if (this.user.isStudent()) {
        await this.fetchSubjectclassDetails({
          schoolId: this.user.school.id,
          subjectClassId: this.classId,
          studentId: this.user.id,
        });
      }
    },
    async fetchAssignments() {
      if (this.subjectId) {
        await this.fetchOngoingAssignments({
          subjectId: this.subjectId,
          subjectClassId: this.classId,
        });
      }
    },
    async fetchNodesVisibility() {
      if (this.user.isTeacher() || this.user.isStudent()) {
        await this.fetchSubjectNodesVisibility({
          subjectClassId: this.classId,
        });
      }
    },
    fetchSubjectTree() {
      const shouldFilterHiddenNodesFromSubjectTree = this.user.isStudent();
      if (shouldFilterHiddenNodesFromSubjectTree) {
        return this.fetchSubject({
          subjectSlug: this.subjectSlug,
          excludeHiddenNodesForSubjectClassId: this.classId,
        });
      }
      return this.fetchSubject({ subjectSlug: this.subjectSlug });
    },
    selectMenuItem(key) {
      const menuItem = this.menuItemsByKey[key];
      if (menuItem) {
        const currentRoute = this.$route;
        menuItem.navigateTo({
          router: this.$router,
          currentRoute,
          isTeacher: this.user.isTeacher(),
          isNgssSubject: this.isNgss,
          subjectSlug: this.subjectSlug,
          lastOpenedSubjectNode: this.lastOpenedSubjectNode,
        });
      } else {
        this.$toast.showError('Invalid route requested.');
      }
    },
    registerActivity() {
      if (this.subjectId) {
        this.registerLastActivity(this.subjectId);
        dailySubjectActivityService().registerDailySubjectActivity(this.subjectId, this.user.id);
      }
    },
    checkClassSlug() {
      const classId = Number(this.classId);
      let correctClassSlug = NO_CLASS_SLUG;
      if (this.user.isTeacher() || this.user.isStudent()) {
        const subjectClass = this.allSubjectClasses.find(sc => sc.id === classId);
        correctClassSlug = subjectClass?.slug ?? NO_CLASS_SLUG;
      }
      if (this.subjectClassSlug !== correctClassSlug) {
        this.$router.replace({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            classSlug: correctClassSlug,
          },
        });
      }
    },
    setupMixpanelSubjectClassProperties() {
      const subjectClass = this.allSubjectClasses.find(sc => sc.id === Number(this.classId));
      this.$mixpanel.registerDefaultPropertiesForEvents({
        subject_id: this.subject.id,
        subject_name: this.subject.name,
        subject_class_id: this.classId,
        education_system: this.educationSystem,
        subject_class_integrations: subjectClass?.integrations,
        subject_class_integration_providers: subjectClass?.integration_providers,
      });
    },
    onBeforeNavigationTransition(transitionName) {
      if (transitionName === TRANSITIONS.COVER_FROM_RIGHT) {
        this.hasTopPaddingZeroOverride = this.hasTopPaddingZero;
      }
    },
    onAfterNavigationTransition() {
      this.hasTopPaddingZeroOverride = null;
    },
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.onSizeChange);
      this.resizeObserver.observe(document.body);
    },
    onSizeChange() {
      this.updateIntercomVisibility();
    },
    updateIntercomVisibility() {
      if (this.isDemoUser || window.innerWidth < VIEWPORT_M_PX) {
        intercomModule.updateIsVisible(false);
      } else {
        intercomModule.updateIsVisible(true);
      }
    },
  },
};
</script>

<style scoped>
.StudyAppMain-pageTransition {
  flex: 1;
  flex-direction: column;
}

.StudyAppMain {
  position: relative;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  min-height: 100vh;
  padding-top: 56px;
  padding-bottom: var(--space-xl);

  transition: background-color 0.15s;
}

.StudyAppMain :deep(.page-full-screen) {
  min-height: 100vh;
}

.StudyAppMain-withSubMenu {
  padding-top: var(--study-menu-height);
}

.StudyAppMain--editingVisibility {
  background-color: var(--kog-colors-grey-500);
}

.StudyAppMain.StudyAppMain--noTopPadding {
  padding-top: 0;
}

.StudyAppMain.StudyAppMain--noBottomPadding {
  padding-bottom: 0;
}

@media (--viewport-s) {
  .StudyAppMain {
    padding-bottom: 104px;
  }

  .StudyAppMain-editorButtons {
    display: none;
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
</style>
