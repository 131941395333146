<template>
  <button
    class="KogToggleButton absoluteContainer"
    :class="{
      'KogToggleButton--on': isPressed,
      'KogToggleButton--off': !isPressed,
    }"
    :aria-pressed="buttonRole === 'toggle' ? (isPressed ? 'true' : 'false') : null"
    :aria-expanded="buttonRole === 'expander' ? (isPressed ? 'true' : 'false') : null"
    @click="toggle"
  >
    <kog-icon
      class="KogToggleButton-circle"
      :icon-class="isPressed ? 'fa-check-circle' : 'fa-circle-x'"
      size="s"
      :is-disabled="true"
    />
  </button>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'KogToggleButton',
  components: { KogIcon },
  props: {
    /**
     * Can be used as the write-only alternative to v-model. Its value can be *true* or *false*
     */
    isPressed: {
      type: Boolean,
      default: false,
    },
    /**
     * Used to specify the role of the button. It can be *toggle* or *expander*. This is for accessibility purposes only.
     */
    buttonRole: {
      type: String,
      default: 'toggle',
      validator: value => ['toggle', 'expander'].includes(value),
    },
  },
  emits: ['toggled', 'update:isPressed'],
  methods: {
    toggle() {
      this.$emit('toggled', !this.isPressed);
      this.$emit('update:isPressed', !this.isPressed);
    },
  },
};
</script>

<style scoped>
.KogToggleButton {
  width: 40px;
  height: 24px;

  border-color: var(--kog-button-default-border-default);
  border-style: solid;
  border-radius: 12px;
}

.KogToggleButton--on {
  background-color: var(--kog-colors-aubergine-900);
}

.KogToggleButton--off {
  background: var(--kog-button-default-background-default);
}

.KogToggleButton-circle {
  position: absolute;
  top: 2px;
  transition: right 0.4s;
}

.KogToggleButton--on .KogToggleButton-circle {
  right: 3px;
  color: var(--kogPlatformWhite);
}

.KogToggleButton--off .KogToggleButton-circle {
  right: 16px;
  color: var(--kogPlatformGray044);
}

.KogToggleButton--on .KogToggleButton-circle:hover {
  color: var(--kogPlatformWhite);
}

.KogToggleButton--off .KogToggleButton-circle:hover {
  color: var(--kogPlatformGray018);
}
</style>
