<template>
  <div class="flexContainer flexContainer-column">
    <transition name="slide">
      <off-canvas
        v-if="isOffCanvasVisible"
        :heading="selectedStudent.name"
        :is-closed-on-click-outside="false"
        :is-full-height="true"
        @close="closeOffCanvas()"
      >
        <div class="margin-top-l padd-top-l TextbookTab-OffCanvas-main">
          <h2 class="heading-s">
            {{ getSubjectNodeName(selectedSubjectNode) }}
          </h2>

          <div class="flexContainer flexContainer-alignCenter margin-top-s">
            <node-progress-cell
              class="margin-right-xs"
              mode="circle"
              :progress="getStudentTextbookProgress(selectedStudent, selectedSubjectNode)"
              :show-tooltip="false"
            />
            <div v-if="hasStudentCompletedSection(selectedStudent, selectedSubjectNode)">
              Section completed
            </div>
            <div v-else> Section not completed </div>
          </div>

          <div class="margin-top-m">
            <router-link
              v-slot="{ href, navigate }"
              :to="getSectionUrl(selectedSubjectNode)"
              custom
            >
              <a
                ref="viewSection"
                :href="href"
                @click="navigate"
              >
                View section
              </a>
            </router-link>
          </div>
          <div class="TextbookTab-OffCanvasResponses">
            <h2 class="heading-s margin-bottom-xs"> Section questions </h2>
            <kog-loader
              :loading="isFetchingOffcanvasData"
              loading-msg="Loading student responses..."
            >
              <div v-if="studentSectionQuestionProgressForNode.length > 0">
                <student-response-accordion-item
                  v-for="questionResponses in studentSectionQuestionProgressForNode"
                  :key="questionResponses.id"
                  :question-responses="questionResponses"
                  @toggle-accordion="trackToggleAccordion"
                />
              </div>
              <span v-else> Section has no questions. </span>
            </kog-loader>
          </div>
        </div>
      </off-canvas>
    </transition>

    <kog-loader
      class="QuestionsTab-loader flexChild-size-1 flexContainer flexContainer-column"
      :loading="isFetchingData"
    >
      <div class="flexContainer flexContainer-spaceBetween margin-top-xl">
        <teacher-insights-filter-panel
          :students="studentsList"
          :subject-tree="subjectTree"
          :max-subject-tree-levels-shown="maxSubjectNodeLevelsShown"
          :persistence-key="`sid-${subjectId}-cid-${classId}-${tabName}`"
          :filter-options="['date', 'student', 'topic']"
          @apply-filters="applyFilters"
        />
        <kog-button
          label="Export .csv"
          :has-icon="true"
          icon-class="fa-download"
          @click="downloadClassCsv"
        />
      </div>
      <insights-table
        v-if="!isFetchingData"
        :tab-name="tabName"
        :style="{
          'max-height': tableMaxHeight,
        }"
        :is-loading-table-content="isLoadingTableContent"
        :students="filteredStudentsList"
        :get-student-progress="getStudentTextbookProgress"
        :show-student-progress="showStudentProgress"
        :subject-nodes="subjectNodes"
        :leaf-subject-node-level="subjectTreeDepth"
        :is-off-canvas-visible="isOffCanvasVisible"
        :sort-column="sortColumn"
        :sorted-by="sortedBy"
        :sort-order="sortOrder"
        :sticky-left-column-count="stickyLeftColumnCount"
        :education-system="educationSystem"
      />
      <insights-help-article-link
        class="margin-top-l"
        :href="helpArticleLink"
        link-text="Help article: Using the textbook progress page to inform your teaching"
      />
    </kog-loader>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import isEqual from 'lodash/isEqual.js';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import { SHOW_ALL_DATES } from 'sharedApp/components/datetime/interval-selector.vue';
import sortingKeys from 'sharedApp/const/sorting-keys.js';
import CsvDownloadMixin from 'sharedApp/mixins/csv-download-mixin.js';
import FullPageTableHeightMixin from 'sharedApp/mixins/full-page-table-height-mixin.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { getTerm, isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import { dateFormatter, dateStringFormatter } from 'sharedApp/utils/time-utils.js';
import InsightsHelpArticleLink from 'studyApp/components/insights/insights-help-article-link.vue';
import InsightsTable from 'studyApp/components/insights/insights-table.vue';
import NodeProgressCell from 'studyApp/components/teacher/insights/node-progress-cell.vue';
import StudentResponseAccordionItem from 'studyApp/components/teacher/insights/student-response-accordion-item.vue';
import TeacherInsightsFilterPanel from 'studyApp/components/teacher/insights/teacher-insights-filter-panel.vue';
import OffCanvas from 'studyApp/components/teacher/off-canvas.vue';
import { getTextbookInsightsCsvBlob } from 'studyApp/utils/teacher/teacher-insights-utils.js';
import { SORT_ORDERS } from 'teachApp/utils/assignment-utils.js';

export default {
  name: 'TextbookTab',
  components: {
    KogButton,
    KogLoader,
    InsightsHelpArticleLink,
    InsightsTable,
    NodeProgressCell,
    OffCanvas,
    StudentResponseAccordionItem,
    TeacherInsightsFilterPanel,
  },
  mixins: [CsvDownloadMixin, FullPageTableHeightMixin, RoutesMixin],
  props: {
    isFetchingData: {
      type: Boolean,
      required: true,
    },
    isLoadingTableContent: {
      type: Boolean,
      required: true,
    },
    studentsList: {
      type: Array,
      required: true,
    },
    subjectClass: {
      type: Object,
      required: true,
    },
    subjectTree: {
      type: Object,
      required: true,
    },
    educationSystem: {
      type: String,
      required: true,
    },
    tabName: {
      type: String,
      required: true,
    },
  },
  emits: ['apply-filters'],
  data() {
    return {
      sortedBy: sortingKeys.USER__FULL_NAME,
      sortOrder: SORT_ORDERS.desc,
      selectedStudentId: '',
      selectedNodeId: '',
      selectedInterval: '',
      isOffCanvasVisible: false,
      offCanvasFilters: {},
      selectedStudent: {},
      selectedSubjectNode: {},
      bookProgressForNodes: {},
      // eslint-disable-next-line vue/no-unused-properties
      tableDistanceFromPageBottom: 76, // needed by FullPageTableHeightMixin
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      subjectNodeMap: state => state.teacherInsightsModule.subjectNodeMap,
      textbookTabParams: state => state.teacherInsightsModule.textbookTabParams,
    }),
    ...mapGetters({
      getStudentsBookProgressForNodes: 'teacherInsightsModule/getStudentsBookProgressForNodes',
      studentSectionQuestionProgressForNode:
        'teacherInsightsModule/getStudentSectionQuestionProgressForNode',
    }),
    sortedStudentsList() {
      const copy = [...this.studentsList];
      switch (this.sortedBy) {
        case sortingKeys.USER__FULL_NAME: {
          const sortedList = copy.sort((student1, student2) => {
            if (this.sortOrder === SORT_ORDERS.desc) {
              return student1.last_name.localeCompare(student2.last_name);
            }
            return student2.last_name.localeCompare(student1.last_name);
          });
          return sortedList;
        }
        case sortingKeys.LEVEL__NAME: {
          const sortedList = copy.sort((student1, student2) => {
            const student1LevelName = this.levelName(student1.level.name);
            const student2LevelName = this.levelName(student2.level.name);
            if (this.sortOrder === SORT_ORDERS.desc) {
              return student2LevelName.localeCompare(student1LevelName);
            }
            return student1LevelName.localeCompare(student2LevelName);
          });
          return sortedList;
        }
        default:
          return this.studentsList;
      }
    },
    filteredStudentsList() {
      const isOnlyOneStudentSelected = Boolean(this.selectedStudentId);
      if (isOnlyOneStudentSelected) {
        return [this.sortedStudentsList.find(student => student.id === this.selectedStudentId)];
      }
      return this.sortedStudentsList;
    },
    subjectNodes() {
      if (!this.selectedNodeId) {
        return this.subjectTree?.children || [];
      }

      const subjectNode = this.subjectNodeMap[this.selectedNodeId];

      if (this.hasIntroductionNodeChild(subjectNode)) {
        return this.introductionNodeFormattedSubjectStructure(subjectNode);
      }

      return [subjectNode, ...subjectNode.children];
    },
    subjectTreeDepth() {
      return Math.max(...Object.values(this.subjectNodeMap).map(node => node.level));
    },
    maxSubjectNodeLevelsShown() {
      return this.subjectTreeDepth - 1;
    },
    stickyLeftColumnCount() {
      return this.selectedNodeId ? 2 : 1;
    },
    isFetchingOffcanvasData() {
      return (
        this.$wait.is('fetching_student_section_question_practice_items') ||
        this.$wait.is('fetching_section_questions')
      );
    },
    hasSubjectIgcseLevels() {
      return isIGCSELevelsEnabled(this.subjectClass.possible_levels);
    },
    helpArticleLink() {
      if (this.isNgss) {
        return 'https://intercom.help/kognity/en/articles/6354715-using-insights-formerly-statistics-to-inform-your-teaching-the-textbook-tab';
      }
      return 'https://intercom.help/kognity/en/articles/6354453-using-insights-formerly-statistics-to-inform-your-teaching-the-textbook-tab';
    },
    isNgss() {
      return isNgss(this.educationSystem);
    },
  },
  watch: {
    subjectNodes: {
      handler() {
        this.updateBookProgressForNodes();
      },
      deep: true,
    },
    isLoadingTableContent: {
      immediate: true,
      handler() {
        this.updateBookProgressForNodes();
      },
    },
    isFetchingData: {
      immediate: true,
      handler() {
        this.updateBookProgressForNodes();
      },
    },
  },
  methods: {
    ...mapWaitingActions('teacherInsightsModule', {
      fetchSectionQuestionPracticeItems: 'fetching_student_section_question_practice_items',
      fetchSectionQuestions: 'fetching_section_questions',
    }),
    updateBookProgressForNodes() {
      if (this.isFetchingData || this.isLoadingTableContent) {
        this.bookProgressForNodes = {};
        return;
      }

      this.bookProgressForNodes = this.getStudentsBookProgressForNodes(this.subjectNodes);
    },
    applyFilters({ filters, isUserTriggered }) {
      if (this.isOffCanvasVisible) {
        this.closeOffCanvas();
      }

      this.selectedStudentId = filters.student;
      this.selectedNodeId = filters.subjectNode;

      let params = {};
      if (this.selectedInterval !== filters.datesInterval) {
        this.selectedInterval = filters.datesInterval;
      }

      if (filters.datesInterval && filters.datesInterval !== SHOW_ALL_DATES) {
        params = {
          ...params,
          from_datetime: filters.fromDate,
          to_datetime: filters.toDate,
        };
      }

      this.offCanvasFilters = params;

      const isDataFetchNeeded = !isEqual(params, this.textbookTabParams);
      if (isDataFetchNeeded) {
        this.$emit('apply-filters', {
          subjectClassId: this.classId,
          params,
        });
      }

      if (isUserTriggered) {
        this.$mixpanel.trackEvent('Insights Textbook - Click on filter', {
          filteredNodeId: this.selectedNodeId,
          filteredStudentId: this.selectedStudentId,
          filteredInterval: this.selectedInterval,
        });
      }
    },
    sortColumn(key) {
      const isSortedDesc = this.sortOrder === SORT_ORDERS.desc;
      const order = isSortedDesc ? SORT_ORDERS.asc : SORT_ORDERS.desc;
      this.sortedBy = key;
      this.sortOrder = order;
    },
    closeOffCanvas() {
      this.isOffCanvasVisible = false;
    },
    getSubjectNodeName(subjectNode) {
      return `${subjectNode.formatted_number_including_ancestors} ${subjectNode.name}`;
    },
    showStudentProgress(student, subjectNode) {
      this.fetchSectionQuestionPracticeItems({
        subjectClassId: this.classId,
        userId: student.user_id,
        params: {
          ...this.offCanvasFilters,
          subjectnode: subjectNode.id,
        },
      });

      const sectionQuestionParams = {};
      if (this.hasSubjectIgcseLevels) {
        sectionQuestionParams.level = student.level.id;
      }
      this.fetchSectionQuestions({
        subjectNodeId: subjectNode.id,
        params: {
          ...sectionQuestionParams,
        },
      });

      this.selectedStudent = student;
      this.selectedSubjectNode = subjectNode;
      this.isOffCanvasVisible = true;
      this.setUpOpenSectionLinkTracking(this.selectedSubjectNode.id);
      this.$mixpanel.trackEvent('Insights Textbook - Click on section cell');
    },
    setUpOpenSectionLinkTracking(sectionId) {
      nextTick(() => {
        if (this.$refs.viewSection) {
          this.$mixpanel.trackLinks(
            this.$refs.viewSection,
            'Insights Textbook Off-canvas - Open section',
            {
              sectionId,
            },
          );
        }
      });
    },
    hasStudentCompletedSection(student, section) {
      const progress = this.getStudentTextbookProgress(student, section);
      return progress.completed === 1;
    },
    getSectionUrl(section) {
      const resolved = this.$router.resolve({
        name: 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: section.slug,
          nodeId: section.id,
        },
      });

      return resolved.fullPath;
    },
    getStudentTextbookProgress(student, node) {
      const progressForNode = this.bookProgressForNodes[node.id][student.user_id];
      return progressForNode;
    },
    getCsvMetaData() {
      const metaData = [`"Class: ${this.subjectClass.name}"`];

      if (this.selectedNodeId) {
        const node = this.subjectNodes.find(n => n.id === this.selectedNodeId);
        const nodeName = `${node.formatted_number_including_ancestors} ${node.name}`;
        metaData.push(`"${nodeName}"`);
      }

      if (this.selectedStudentId) {
        const student = this.filteredStudentsList[0];
        const studentName = `${student.first_name} ${student.last_name}`;
        metaData.push(`"Student: ${studentName}"`);
      }

      if (this.selectedInterval && this.selectedInterval !== SHOW_ALL_DATES) {
        const [from, to] = this.selectedInterval.split(' to ');
        const datesString = `${dateStringFormatter(from)} to ${dateStringFormatter(to)}`;
        metaData.push(`"Interval: ${datesString}"`);
      }

      return metaData;
    },
    downloadClassCsv() {
      const metaData = this.getCsvMetaData();
      const blob = getTextbookInsightsCsvBlob(
        this.filteredStudentsList,
        this.subjectNodes,
        this.bookProgressForNodes,
        metaData,
      );
      const currentDate = dateFormatter(new Date());
      const fileName = `${this.subjectClass.name}_textbook_progress_created_${currentDate}.csv`;
      this.downloadCsvFile(fileName, blob);
      this.$mixpanel.trackEvent('Insights Textbook - Click Export data button', {
        filteredNodeId: this.selectedNodeId,
        filteredStudentId: this.selectedStudentId,
        filteredInterval: this.selectedInterval,
      });
    },
    trackToggleAccordion(isOpen) {
      const event = isOpen
        ? 'Insights Textbook Off-canvas - Click on expand response details'
        : 'Insights Textbook Off-canvas - Click on close response details';
      this.$mixpanel.trackEvent(event);
    },
    levelName(studentLevel) {
      const educationSystemName = this.subject.educationsystem.name;
      return getTerm(studentLevel, educationSystemName);
    },
    hasIntroductionNodeChild(node) {
      return node.children && node.children[0]?.is_special_introduction_node;
    },
    introductionNodeFormattedSubjectStructure(node) {
      const [introNode, ...childrenWithoutIntro] = node.children;
      return [node, ...introNode.children, ...childrenWithoutIntro];
    },
  },
};
</script>

<style scoped>
.TextbookTab-OffCanvas-main {
  border-top: 1px solid var(--kogPlatformGray077);
}

.TextbookTab-OffCanvasResponses {
  margin-top: 90px;
}

.slide-leave-active,
.slide-enter-active {
  transition: transform 0.15s ease-in-out 0s;
}

.slide-leave-from,
.slide-enter-to {
  transform: translateX(0%);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
