import mixpanelModule from 'sharedApp/libs/mixpanel.js';

export function trackPreviewModalEventsOnDetailsPage(modalEventName, payload, assignment) {
  const eventsMapping = {
    open: 'Assignment details - Click Question Row',
    hide: 'Assignment details question modal - Close Modal',
    next: 'Assignment details question modal - Click Next Question Button',
    previous: 'Assignment details question modal - Click Previous Question Button',
    'show-answers': 'Assignment details question modal - Click Show Answer Link',
    'hide-answers': 'Assignment details question modal - Click Hide Answer Link',
  };
  const mixpanelEventName = eventsMapping[modalEventName];
  if (!mixpanelEventName || !assignment) {
    return;
  }
  const props = {
    subject_id: assignment.subject_id,
    subject_name: assignment.subject_name,
    num_subject_classes: assignment.subject_classes.length,
    assignment_type: assignment.assignment_type?.toLowerCase(),
    ...payload,
  };
  mixpanelModule.trackEvent(mixpanelEventName, props);
}

export function trackPreviewModalEvents(modalEventName, payload) {
  const eventsMapping = {
    open: 'Assignment - Open question preview modal',
    'show-answers': 'Assignment question preview modal - Show answer',
    'hide-answers': 'Assignment question preview modal - Show answer',
  };
  const mixpanelEventName = eventsMapping[modalEventName];
  if (!mixpanelEventName) {
    return;
  }
  let action = null;
  if (mixpanelEventName === 'show-answers') {
    action = 'Show';
  } else if (mixpanelEventName === 'hide-answers') {
    action = 'Hide';
  }
  const props = {
    ...payload,
  };
  if (action) {
    props.action = action;
  }
  mixpanelModule.trackEvent(mixpanelEventName, props);
}

export default {};
