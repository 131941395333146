<template>
  <kog-default-study-page>
    <template #body>
      <kog-loader
        :loading="isLoading"
        loading-msg="Loading question..."
      >
        <div v-if="!isLoading">
          <div class="flexContainer flexContainer-spaceBetween margin-bottom-m">
            <h2 class="heading-s"> Question {{ questionIndex + 1 }} </h2>
            <div class="flexContainer flexContainer-alignCenter">
              <div v-if="shouldShowLevelsInfo">
                <question-level-labels
                  :levels="question.attributes.levels"
                  :relevant-group="subjectClassLevelGroup"
                />
              </div>
              <div v-if="question.tags">
                <question-tags
                  v-if="subject && question"
                  :tags="question.tags"
                  :show-difficulty="false"
                  :subject-id="subject.id"
                />
                <papertype-tag
                  v-if="question.context.papertype"
                  class="margin-right-xxs EsqAssignmentQuestion-papertypeTag"
                  :name="question.context.papertype"
                  :should-prefix-number-papertype="true"
                />
                <kog-tag
                  v-if="question.context.marks"
                  :label="marksInfo(question)"
                />
              </div>
            </div>
          </div>
          <div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-kog-mathjax
              v-html="question.question_html"
            />
            <!-- eslint-enable vue/no-v-html -->
            <div
              v-if="isMarkschemeVisible"
              class="flexContainer flexContainer-center padd-top-xxl"
            >
              <kog-button
                :label="markSchemeButtonLabel"
                :disabled="!answerExplanationEnabled"
                :has-icon="true"
                icon-class="far fa-lock-alt"
                :tooltip="tooltip"
                button-style="basic"
                @click="toggleShowAnswer"
              />
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-show="showAnswer"
              v-kog-mathjax
              v-html="question.explanation"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </kog-loader>
    </template>
    <template #cardBottomSlot>
      <slot name="esqCardBottomSlot" />
    </template>
  </kog-default-study-page>
</template>

<script>
import PapertypeTag from 'learning/common/components/papertype-tag.vue';
import QuestionLevelLabels from 'learning/common/components/question-level-labels.vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import { IGCSE_LEVELS } from 'sharedApp/const/level-groups.js';
import { hasEducationSystemMarkscheme } from 'sharedApp/services/educationSystem/education-system-service.js';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import KogDefaultStudyPage from 'studyApp/components/kog-default-study-page.vue';
import QuestionTags from 'studyApp/components/questions/question-tags.vue';
import QuestionMixin from 'studyApp/mixins/question-mixin.js';

export default {
  name: 'EsqAssignmentQuestion',
  components: {
    KogButton,
    KogDefaultStudyPage,
    KogLoader,
    KogTag,
    PapertypeTag,
    QuestionTags,
    QuestionLevelLabels,
  },
  mixins: [QuestionMixin],
  props: {
    answerExplanationEnabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  emits: ['toggle-show-answer'],
  data() {
    return {
      showAnswer: false,
      subjectClassLevelGroup: IGCSE_LEVELS,
    };
  },
  computed: {
    educationSystem() {
      return this.subject.educationsystem.name;
    },
    isMarkschemeVisible() {
      return hasEducationSystemMarkscheme(this.educationSystem);
    },
    markSchemeButtonLabel() {
      return this.showAnswer
        ? 'Hide example answer and mark scheme'
        : 'View example answer and mark scheme';
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subject.possible_levels);
    },
  },
  methods: {
    toggleShowAnswer() {
      this.showAnswer = !this.showAnswer;
      this.$emit('toggle-show-answer', this.showAnswer);
    },
  },
};
</script>

<style scoped>
@media (--viewport-s) {
  .EsqAssignmentQuestion-papertypeTag {
    max-width: 200px;
  }
}

@media (--viewport-xs) {
  .EsqAssignmentQuestion-papertypeTag {
    max-width: 150px;
  }
}
</style>
