<template>
  <kog-modal
    :title="`Battle in ${topicName}`"
    :subtitle="subjectName"
  >
    <template #modalBody>
      <div class="margin-bottom-xl">
        <h3 class="text-normal heading-s margin-bottom-xs"> Battle rules: </h3>
        <ul>
          <li> {{ numberOfQuestions }} questions. </li>
          <li> {{ secondsPerQuestion }} seconds per question. </li>
        </ul>
        <h4 class="header-m"> Are you ready to play? </h4>
      </div>
    </template>

    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          class="KogButtonLegacy"
          @click="onCancel"
        >
          Not now
        </button>
        <button
          class="KogButtonLegacy KogButtonLegacy--primary"
          @click="onReady"
        >
          I'm ready!
        </button>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'BattleStartModal',
  components: {
    KogModal,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    numberOfQuestions: {
      type: Number,
      required: true,
    },
    secondsPerQuestion: {
      type: Number,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
    topicName: {
      type: String,
      required: true,
    },
    onStart: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onCancel() {
      this.closeModal(false);
    },
    onReady() {
      this.onStart();
      this.closeModal(true);
    },
  },
};
</script>

<style scoped></style>
