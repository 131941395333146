<template>
  <kog-loader
    :loading="
      $wait.is([
        'loading_assignment_details',
        'loading_reading_assignment_student_progress',
        'loading_schoolstaff_subject',
      ])
    "
    loading-message="Getting assignment info..."
  >
    <div v-if="isReading">
      <reading-assignment-focus v-if="hasNodeId" />
      <teacher-assignment-details-landing v-else />
    </div>
    <assignment-details-container v-else />
  </kog-loader>
</template>

<script>
import { computed, provide, ref } from 'vue';
import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { mapWaitingActions, waitFor } from 'vue-wait';
import { mapActions, mapGetters, mapState, useStore } from 'vuex';

import * as schoolStaffApi from '@apis/schoolstaff-assignments.js';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isReadingType } from 'sharedApp/services/assignment/assignment-utility-service.js';
import TeacherAssignmentDetailsLanding from 'studyApp/pages/assignment/components/teacher-assignment-details-landing.vue';
import useClassAssignmentOwnership from 'studyApp/pages/assignment/composables/use-class-assignment-ownership.ts';
import ReadingAssignmentFocus from 'studyApp/pages/assignment/reading-assignment-focus.vue';
import AssignmentDetailsContainer from 'studyApp/pages/assignment/teacher-assignment-details.vue';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);
export default {
  name: 'TeacherAssignmentDetailsContainer',
  components: {
    ReadingAssignmentFocus,
    TeacherAssignmentDetailsLanding,
    KogLoader,
    AssignmentDetailsContainer,
  },
  mixins: [RoutesMixin],
  beforeRouteEnter(to, from, next) {
    next(() => {
      if (from.name !== 'classReadingAssignmentTake') {
        studyAppLocalStorage.setLastUrl(from.href);
      }
    });
  },
  setup() {
    const store = useStore();
    const { schoolstaffSubjectclassModule } = store.state;
    const assignmentStore = useAssignmentStore();
    const { assignment } = storeToRefs(assignmentStore);
    const { fetchAssignment } = assignmentStore;
    const fetchAssignmentDetails = waitFor('loading_assignment_details', fetchAssignment);
    const subjectClassName = computed(() => schoolstaffSubjectclassModule.subjectClass?.name);
    const assignmentName = computed(() => assignment?.name);

    const page = 'Assignment Details';
    const loaded = subjectClassName.value && assignmentName.value;
    const title = loaded ? `${subjectClassName.value} | ${assignmentName.value} | ${page}` : page;
    useHead({ title });

    const readingAssignmentStudentProgress = ref([]);
    provide('readingAssignmentStudentProgress', readingAssignmentStudentProgress);

    const { findMyClassInAssignment } = useClassAssignmentOwnership();

    return {
      assignment,
      fetchAssignmentDetails,
      readingAssignmentStudentProgress,
      findMyClassInAssignment,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    assignmentType() {
      return this.assignment?.assignment_type;
    },
    isReading() {
      return isReadingType(this.assignmentType);
    },
    hasNodeId() {
      return !!this.$route.params.nodeId;
    },
  },
  async created() {
    this.fetchSubjectClass({ subjectClassId: this.classId });
    this.fetchSubject({ subjectId: this.subjectId });
    await this.fetchAssignment();
    this.checkAssignmentClassAccess();
  },
  methods: {
    ...mapActions('schoolstaffSubjectclassModule', ['fetchSubjectClass']),
    ...mapWaitingActions('schoolstaffSubjectModule', {
      fetchSubject: 'loading_schoolstaff_subject',
    }),
    checkAssignmentClassAccess() {
      const currentSubjectClass = this.subjectClassesById[this.classId];
      if (!currentSubjectClass.teacher_user_ids.includes(this.user.id)) {
        const subjectClassToRedirect = this.findMyClassInAssignment(this.assignment, this.user);
        if (subjectClassToRedirect) {
          this.$router.replace({
            params: {
              ...this.$route.params,
              classSlug: subjectClassToRedirect.slug,
              cid: subjectClassToRedirect.id,
            },
          });
        } else {
          this.$router.replace({ name: 'studyDashboard' });
          this.$toast.showError('You do not have access to this assignment.');
        }
      }
    },
    async fetchAssignment() {
      await this.fetchAssignmentDetails(this.assignmentId);
      if (this.isReading) {
        this.$wait.start('loading_reading_assignment_student_progress');
        this.readingAssignmentStudentProgress =
          await schoolStaffApi.fetchReadingAssignmentStudentProgress(this.assignmentId);
        this.$wait.end('loading_reading_assignment_student_progress');
      }
    },
  },
};
</script>
