<template>
  <div class="SubjectClassBook-mainContainer">
    <kog-loader
      v-if="$wait.is('loading-features')"
      :loading="$wait.is('loading-features')"
    />
    <template v-else>
      <book-rail
        :is-collapsed="isBookRailCollapsed"
        :tracking-props="trackingProps"
        @collapse="collapseBookRail"
        @expand="expandBookRail"
      />
      <div
        :class="{
          'SubjectClassBook-mainContainer-content--bookRail': !isBookRailCollapsed,
          'SubjectClassBook-mainContainer-content--collapsedBookRail': isBookRailCollapsed,
        }"
        class="SubjectClassBook-mainContainer-Content"
      >
        <div class="kog-container-fluid">
          <div class="kog-row">
            <book-rail-slider
              :node-id="nodeId"
              :is-review-mode="isReviewMode ? 'true' : null"
              @scroll-to-content="onScrollToContentRequest"
            />
            <div
              class="positionRelative u-paddingHorizontalOverride SubjectClassBook-contentContainer"
              :class="{
                'SubjectClassBook-contentSliderOpen': isBookRailSliderOpen,
                'SubjectClassBook-contentSliderClosed': !isBookRailSliderOpen,
                'SubjectClassBook-noTransition': isResizing && isBookRailSliderOpen,
              }"
            >
              <feature-text v-if="isCurrentNodeFeature" />
              <template v-else>
                <section-review-mode v-if="showMultipleSections" />
                <section-text
                  v-else
                  :is-in-review-mode="isReviewMode"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="isBookRailSliderOpen"
          role="button"
          tabindex="0"
          class="SubjectClassBook-backdrop"
          @click="closeBookRail"
          @keyup.enter.prevent="closeBookRail"
        />
      </transition>
    </template>
  </div>
</template>

<script>
import { useEventBus } from '@vueuse/core';
import debounce from 'lodash/debounce.js';
import throttle from 'lodash/throttle.js';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import BookRailSlider from 'studyApp/components/side-navigation/book-rail-slider.vue';
import BookRail from 'studyApp/components/side-navigation/book-rail.vue';
import { RouteNavigationEventProvide } from 'studyApp/mixins/route-navigation-event-mixin.js';
import FeatureText from 'studyApp/pages/features/feature-text.vue';
import SectionReviewMode from 'studyApp/pages/section-review-mode.vue';
import SectionText from 'studyApp/pages/section-text.vue';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default {
  name: 'SubjectClassBook',
  components: {
    KogLoader,
    SectionText,
    SectionReviewMode,
    FeatureText,
    BookRail,
    BookRailSlider,
  },
  mixins: [RoutesMixin, RouteNavigationEventProvide],
  async beforeRouteUpdate(to, from, next) {
    const results = await Promise.allSettled(this.listeners.map(listener => listener(to, from)));
    const hasRejection = results.some(result => result.status === 'rejected');

    if (!hasRejection) {
      next();
    }
  },
  async beforeRouteLeave(to, from, next) {
    const results = await Promise.allSettled(this.listeners.map(listener => listener(to, from)));
    const hasRejection = results.some(result => result.status === 'rejected');

    if (!hasRejection) {
      next();
    }
  },
  emits: ['scroll-to-content'],
  setup() {
    const scrollToContentEventBus = useEventBus('scroll-to-content');
    return {
      scrollToContentEventBus,
    };
  },
  data() {
    return {
      isBookRailCollapsed: false,
      isResizing: false,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      bookRailSliders: state => state.bookModule.bookRailSliders,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      isBookRailSliderOpen: 'bookModule/isBookRailSliderOpen',
      findSectionNodeById: 'bookModule/findSectionNodeById',
      subjectNodesById: 'subjectModule/subjectNodesById',
      isSyllabusEnabled: 'subjectModule/isSyllabusEnabled',
      isNodeVisible: 'subjectClassModule/isNodeVisible',
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    isCurrentNodeFeature() {
      return !!this.activityBySubjectNodeId[this.nodeId];
    },
    currentNodeContentType() {
      if (this.isCurrentNodeFeature) {
        return this.getActivityBySubjectNodeId(this.nodeId).type;
      }
      return 'Section';
    },
    currentSubjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    currentSectionNode() {
      if (this.isCurrentNodeFeature) {
        return this.currentSubjectNode;
      }
      return this.findSectionNodeById(this.nodeId);
    },
    currentSubtopicId() {
      let parentNode = this.subjectNodesById[this.currentSubjectNode.parent];
      while (parentNode && parentNode.level > 2) {
        parentNode = this.subjectNodesById[parentNode.parent];
      }
      return parentNode.id;
    },
    nodePath() {
      return this.currentSectionNode?.formatted_number_including_ancestors;
    },
    currentSectionNodeName() {
      return this.currentSectionNode?.name || '';
    },
    trackingProps() {
      return {
        subject_id: this.subjectId,
        subject_class_id: this.classId,
        subject_node_id: this.nodeId,
        section_node_name: `${this.nodePath} ${this.currentSectionNodeName}`,
      };
    },
    isReviewMode() {
      return !!this.$route.meta.isReviewMode;
    },
    isNgss() {
      return isNgss(this.subject.educationsystem.name);
    },
    showMultipleSections() {
      return this.isReviewMode && !this.isNgss;
    },
  },
  watch: {
    nodeId: {
      handler() {
        if (!this.showMultipleSections) {
          this.loadFeatures();
        }
      },
    },
    isReviewMode: {
      handler() {
        this.loadFeatures();
      },
    },
    currentSubtopicId: {
      handler(newSubtopicId, oldSubtopicId) {
        if (this.user.isTeacher() && newSubtopicId !== oldSubtopicId) {
          this.fetchSubtopicStatistics({
            subjectId: this.subjectId,
            subTopicId: newSubtopicId,
          });
        }
      },
      immediate: true,
    },
  },
  async created() {
    await this.loadFeatures();
    intercomModule.updateLauncherVerticalPadding([
      {
        condition: true,
        height: 96,
      },
    ]);
    if (this.user.isStudent()) {
      this.checkNodeVisibility();
    }
  },
  mounted() {
    this.throttledPauseTransitions = throttle(this.pauseTransitions, 600, {
      leading: true,
      trailing: false,
    });
    window.addEventListener('resize', this.throttledPauseTransitions);
    this.debouncedStopResize = debounce(() => {
      this.isResizing = false;
    }, 600);
  },
  beforeUnmount() {
    this.throttledPauseTransitions.cancel();
    this.debouncedStopResize.cancel();
    window.removeEventListener('resize', this.throttledPauseTransitions);
  },
  methods: {
    ...mapMutations({
      resetImmersiveReaderContent: 'bookModule/resetImmersiveReaderContent',
    }),
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
      registerSectionActivity: 'activityModule/registerSectionActivity',
      fetchSubtopicStatistics: 'inlineStatisticsModule/fetchSubtopicStatistics',
    }),
    ...mapWaitingActions({
      fetchFeaturesAndMappings: {
        action: 'subjectNodeFeatureModule/fetchFeaturesAndMappings',
        loader: 'loading-features',
      },
    }),
    collapseBookRail() {
      this.isBookRailCollapsed = true;
    },
    expandBookRail() {
      this.isBookRailCollapsed = false;
    },
    onScrollToContentRequest(node) {
      this.$emit('scroll-to-content', node);
      this.scrollToContentEventBus.emit(node);
    },
    pauseTransitions() {
      this.isResizing = true;
      this.debouncedStopResize();
    },
    closeBookRail() {
      if (this.bookRailSliders.toc) {
        studyAppLocalStorage.setAutoOpenBookToc(false);
      }
      this.closeRailSlider();
    },
    checkNodeVisibility() {
      if (this.user.isStudent() && !this.isNodeVisible(this.nodeId)) {
        this.$toast.showError(`The page was hidden by your teacher`);
        this.$router.push({
          name: 'subjectClassOverview',
          params: {
            classSlug: `${this.subjectClassSlug}`,
            sid: `${this.subjectId}`,
            cid: `${this.classId}`,
          },
        });
      }
    },
    async loadFeatures() {
      await this.fetchFeaturesAndMappings({ subjectNodeIds: [this.nodeId] });
      this.registerSectionActivity(this.nodeId);
      this.resetImmersiveReaderContent();
      this.trackOpenBook();
      if (!this.showMultipleSections) {
        window.scrollTo({
          top: 0,
          left: 0,
        });
      }
    },
    trackOpenBook() {
      if (this.user.isTeacher()) {
        let eventName;
        if (this.isReviewMode) {
          eventName = 'load_teacher_view';
        } else {
          eventName = 'load_book_page';
        }
        this.$event.track(eventName, {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          subject_node_id: this.nodeId,
          content_type: this.currentNodeContentType,
        });
      }
      this.$mixpanel.trackEventViaBackend('Book - Open', {
        section_node_id: this.nodeId,
        section_node_number: this.currentSubjectNode.formatted_number_including_ancestors,
        section_node_name: `${this.currentSubjectNode.formatted_number_including_ancestors} ${this.currentSubjectNode.name}`,
        content_type: this.currentNodeContentType,
      });
    },
  },
};
</script>

<style scoped>
.SubjectClassBook-mainContainer {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  background-color: var(--kogPlatformGray094);
}

.SubjectClassBook-mainContainer-Content {
  display: flex;
  flex-direction: row;
  transition: padding-left 0.2s ease-in-out;
}

.SubjectClassBook-mainContainer-content--bookRail {
  padding-left: var(--study-rail-menu-width);
}

.SubjectClassBook-mainContainer-content--collapsedBookRail {
  padding-left: var(--study-rail-menu-collapsed-width);
}

.SubjectClassBook-contentSliderClosed {
  transition:
    width 0.6s ease,
    height 0.6s ease;
}

.SubjectClassBook-contentContainer {
  flex: 1;
}

.SubjectClassBook-contentSliderOpen {
  width: calc(100vw - var(--study-rail-menu-width) - var(--study-rail-slider-width));
  transition:
    width 0.6s ease,
    height 0.6s ease;
}

.SubjectClassBook-mainContainer-content--collapsedBookRail .SubjectClassBook-contentSliderOpen {
  width: calc(100% - var(--study-rail-slider-width) - var(--space-s));
}

.u-paddingHorizontalOverride {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.SubjectClassBook-backdrop {
  position: fixed;
  z-index: 100;

  display: none;

  width: 100%;
  height: 100%;

  background-color: var(--kogShadow080);
}

.SubjectClassBook-noTransition {
  transition: none !important;
}

@media (--viewport-l) {
  .SubjectClassBook-contentSliderOpen {
    width: calc(100vw - var(--study-rail-menu-width));
    max-height: 100vh;
    margin-top: 0;
    border-radius: 0;
  }

  .SubjectClassBook-mainContainer-content--collapsedBookRail .SubjectClassBook-contentSliderOpen {
    width: calc(100vw - var(--study-rail-menu-collapsed-width));
  }

  .SubjectClassBook-backdrop {
    display: block;
  }
}
</style>
