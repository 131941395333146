<template>
  <div class="padd-bottom-xl">
    <fullpage-takeover-navbar
      back-button-aria-label="Click to go back to insights"
      :header-text="`Student activity: ${studentName}`"
      @back-button-click="onBackButtonClick"
    />
    <div class="kog-container-fluid container-wideWidth padd-top-xxxl">
      <div class="flexContainer">
        <h1 class="heading-xl margin-top-xl">
          {{ studentName }}
          <kog-tag
            v-if="studentLevel"
            class="margin-left-l"
            :label="studentLevelName"
          />
        </h1>
      </div>
      <p class="text-regular muted">
        {{ formattedLastActive }}
      </p>

      <div class="flexContainer flexContainer-row margin-top-xl">
        <div class="StudentDetails-summaryCard padd-s flexChild-size-1">
          <p class="text-center heading-xxs margin-m margin-top-xxs"> Latest sections </p>
          <section-tag-list
            class="flexContainer flexContainer-center flexContainer-wrap"
            :sections="lastOpenSections"
          />
        </div>
        <div
          class="StudentDetails-summaryCard padd-s margin-left-m margin-right-m flexChild-size-1"
        >
          <p class="text-center heading-xxs margin-m margin-top-xxs"> Latest assignments </p>
          <assignment-button-list
            class="flexContainer flexContainer-center"
            :assignments="latestAssignments"
            :class-slug="subjectClassSlug"
            :subject-id="subjectId"
            :class-id="classId"
          />
        </div>
        <div class="StudentDetails-summaryCard padd-s flexChild-size-1">
          <p class="text-center heading-xxs margin-m margin-top-xxs">
            Strength tests and Battles
          </p>
          <p
            class="text-center heading-m margin-top-s"
            :class="{
              kogPlatformGreenBase: hasStrengthTestsOrBattles,
              kogPlatformGray051: !hasStrengthTestsOrBattles,
            }"
          >
            {{ numberOfStrengthBattlesAndTests }}
          </p>
        </div>
      </div>
      <div class="margin-top-xl">
        <kog-tabs
          v-model:value="tab"
          class="flexContainer-flexStart margin-top-m"
          :has-transparent-background="true"
          :tabs="tabs"
          heading-size="xs"
          @input="setTabSelected"
        />
      </div>
      <textbook-tab
        v-if="tab === tabNames.textbook"
        :user-id="studentUserId"
      />
      <assignments-tab
        v-else-if="tab === tabNames.assignments"
        :user-id="studentUserId"
      />
      <strength-tests-battles-tab
        v-else-if="tab === tabNames.strengthTestsAndBattles"
        :user-id="studentUserId"
      />
    </div>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import KogTabs from 'sharedApp/components/base/tabs/kog-tabs.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import FullpageTakeoverNavbar from 'sharedApp/components/core/fullpage-takeover-navbar.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';
import { timeSince } from 'sharedApp/utils/time-utils.js';
import AssignmentButtonList from 'studyApp/components/student-details/assignment-button-list.vue';
import AssignmentsTab from 'studyApp/components/student-details/assignments-tab.vue';
import SectionTagList from 'studyApp/components/student-details/section-tag-list.vue';
import StrengthTestsBattlesTab from 'studyApp/components/student-details/strength-tests-battles-tab.vue';
import TextbookTab from 'studyApp/components/student-details/textbook-tab.vue';

export default {
  name: 'StudentDetails',
  components: {
    KogTabs,
    KogTag,
    AssignmentButtonList,
    FullpageTakeoverNavbar,
    SectionTagList,
    TextbookTab,
    AssignmentsTab,
    StrengthTestsBattlesTab,
  },
  mixins: [RoutesMixin],
  data() {
    return {
      tab: '',
      tabNames: {
        textbook: 'textbook',
        assignments: 'assignments',
        strengthTestsAndBattles: 'strengthtestsandbattles',
      },
    };
  },
  computed: {
    ...mapState({
      subjectClass: state => state.teacherInsightsModule.subjectClass,
      studentDetails: state => state.teacherInsightsModule.studentDetails,
      studentLevel: state => state.teacherInsightsModule.studentLevel,
      user: state => state.userModule.user,
      studentsList: state => state.teacherInsightsModule.studentsList,
      studentBattlesCount: state => state.teacherInsightsModule.studentBattlesCount,
      studentStrengthTestsCount: state => state.teacherInsightsModule.studentStrengthTestsCount,
      subject: state => state.subjectModule.subject,
    }),
    ...mapGetters('teacherInsightsModule', [
      'getStudentLastActivity',
      'getStudentLastOpenSections',
      'getStudentLatestAssignments',
    ]),
    studentUserId() {
      return Number(this.$route.params.studentId);
    },
    studentName() {
      return this.studentDetails?.user.full_name;
    },
    studentLevelName() {
      const educationSystemName = this.subject?.educationsystem.name;
      const studentLevel = this.studentLevel?.name;
      return getTerm(studentLevel, educationSystemName);
    },
    lastActivity() {
      return this.getStudentLastActivity(this.studentUserId);
    },
    lastOpenSections() {
      return this.getStudentLastOpenSections(this.studentUserId);
    },
    latestAssignments() {
      return this.getStudentLatestAssignments(this.studentUserId);
    },
    formattedLastActive() {
      if (this.lastActivity) {
        const lastActive = timeSince(this.lastActivity);
        return `Last active ${lastActive} ago`;
      }
      return 'No activity';
    },
    numberOfStrengthBattlesAndTests() {
      return this.studentStrengthTestsCount + this.studentBattlesCount;
    },
    hasStrengthTestsOrBattles() {
      return this.numberOfStrengthBattlesAndTests > 0;
    },
    tabs() {
      return [
        {
          name: this.tabNames.textbook,
          label: 'Textbook statistics',
        },
        {
          name: this.tabNames.assignments,
          label: 'Assignments statistics',
        },
        {
          name: this.tabNames.strengthTestsAndBattles,
          label: 'Strength tests and battles',
        },
      ];
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.onRouteChange();
      },
      immediate: true,
    },
  },
  created() {
    const isTabAlreadySelected = !!this.tab;
    if (!isTabAlreadySelected) {
      this.tab = this.tabNames.textbook;
      this.onRouteChange();
    }

    this.fetchStudentDetails({
      schoolId: this.user.school.id,
      subjectClassId: this.classId,
      userId: this.studentUserId,
    });
    this.fetchStudentLastActivity({
      subjectId: this.subjectId,
      userId: this.studentUserId,
    });
    this.fetchStudentLastOpenSections({
      subjectId: this.subjectId,
      userId: this.studentUserId,
    });
    this.fetchStudentLatestAssignments({
      userId: this.studentUserId,
      subjectClassId: this.classId,
    });
    this.fetchStudentBattles({
      subjectClassId: this.classId,
      userId: this.studentUserId,
    });
    this.fetchStudentStrengthTests({
      subjectClassId: this.classId,
      userId: this.studentUserId,
    });
  },
  mounted() {
    this.$mixpanel.trackEvent('Insights - Open student details page');
  },
  methods: {
    ...mapWaitingActions('teacherInsightsModule', {
      fetchSubjectClass: 'fetching_subject_class',
      fetchStudentDetails: 'fetching_student_details',
      fetchStudentLastActivity: 'fetching_student_last_activity',
      fetchStudentLastOpenSections: 'fetching_last_open_sections',
      fetchStudentLatestAssignments: 'fetching_latest_assignments',
      fetchStudentBattles: 'fetching_student_battles',
      fetchStudentStrengthTests: 'fetching_student_strength_tests',
    }),
    onBackButtonClick() {
      if (this.$route.query.origin === 'insights') {
        this.$router.push({
          name: 'teacherInsights',
          params: this.$route.params,
          query: { active_tab: this.$route.query.origin_active_tab },
        });
      }
    },
    setTabSelected(tabName) {
      if (tabName === this.$route.query.active_tab) {
        return;
      }

      this.$router.replace({
        query: {
          ...this.$route.query,
          active_tab: tabName,
        },
      });
      this.$mixpanel.trackEvent('Insights - Click tab in student details page', {
        tabName,
      });
    },
    onRouteChange() {
      const activeTab = this.$route.query.active_tab;
      if (activeTab) {
        this.tab = activeTab;
      } else if (this.tab) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            active_tab: this.tab,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.StudentDetails-summaryCard {
  background-color: var(--kog-background-default-100);
}
</style>
