<template>
  <div class="KogSchoolSummary margin-top-l">
    <kog-loader :loading="$wait.is('fetching_summary') && !apiError">
      <div class="divider-bottom padd-s padd-left-l">
        <div class="heading-m"> Your school </div>
      </div>
      <div class="padd-s">
        <div class="heading-s padd-s">
          {{ user.school.name }}
        </div>
        <div
          v-if="schoolSummary"
          class="SchoolSummary-cardsContainer flexContainer flexContainer-wrap"
        >
          <div>
            <kog-school-summary-card v-bind="getAdministratorsInfo()" />
          </div>
          <div>
            <kog-school-summary-card v-bind="getClassesInfo()" />
          </div>
          <div>
            <kog-school-summary-card v-bind="getStudentsInfo()" />
          </div>
          <div>
            <kog-school-summary-card v-bind="getTeachersInfo()" />
          </div>
        </div>
      </div>
    </kog-loader>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';

import KogSchoolSummaryCard from './kog-school-summary-card.vue';

export default {
  name: 'KogSchoolSummary',
  components: {
    KogSchoolSummaryCard,
    KogLoader,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      schoolSummary: null,
      apiError: false,
    };
  },
  async mounted() {
    try {
      this.schoolSummary = await this.fetchSchoolSummary();
    } catch {
      this.$toast.showError(`
        Could not retrieve the school summary.
        Please try reloading the page, and
        if the issue continues contact us at support@kognity.com.
      `);
      this.apiError = true;
    }
  },
  methods: {
    buildUsersText(users, totalUsers, userType) {
      let title = '';
      let text = '';
      if (totalUsers === 0) {
        title = `No ${userType}`;
        text = `The school has no ${userType} yet`;
      } else if (totalUsers === 1) {
        title = `1 ${userType}`;
        text = users[0].name;
      } else {
        title = `${totalUsers} ${userType}s`;
        text = `${users[0].name}, ${users[1].name}`;
        if (totalUsers > 2) {
          text += ` and ${totalUsers - 2} more`;
        }
      }
      return { title, text };
    },
    ...mapWaitingActions('teacherSubjectClassModule', {
      fetchSchoolSummary: { action: 'fetchSchoolSummary', loader: 'fetching_summary' },
    }),
    getAdministratorsInfo() {
      return this.buildUsersText(
        this.schoolSummary.admins,
        this.schoolSummary.total_admins,
        'Kognity administrator',
      );
    },
    getStudentsInfo() {
      return this.buildUsersText(
        this.schoolSummary.students,
        this.schoolSummary.total_students,
        'student',
      );
    },
    getClassesInfo() {
      const totalClasses = this.schoolSummary.total_classes;
      let title = '';
      const text = '';
      if (totalClasses === 0) {
        title = 'No classes yet';
      } else if (totalClasses === 1) {
        title = '1 class';
      } else {
        title = `${totalClasses} classes`;
      }

      return {
        title,
        text,
      };
    },
    getTeachersInfo() {
      return this.buildUsersText(
        this.schoolSummary.teachers,
        this.schoolSummary.total_teachers,
        'teacher',
      );
    },
  },
};
</script>

<style scoped>
.KogSchoolSummary {
  background: var(--kogPlatformGray098);
  border: 1px solid var(--kogPlatformGray084);
  border-radius: var(--space-xs);
}

.SchoolSummary-cardsContainer > div {
  width: 50%;
  padding: var(--space-s);
}
</style>
