<template>
  <div class="BookRailGlossary-container">
    <div class="kog-container-fluid u-noPadding">
      <book-rail-slider-header
        class="margin-bottom-s"
        title="Glossary"
        @close="onCloseClick"
      />
      <div class="kog-row">
        <terms-list-filter
          class="BookRailGlossary-filter"
          :disabled="isTermsListLoading"
          @terms-filter-changed="onTermsFilterChanged"
        />
      </div>
      <kog-loader
        :loading="isTermsListLoading"
        :loading-msg="'Loading glossary terms...'"
      >
        <div class="kog-row BookRailGlossary-content">
          <div class="kog-col-10 BookRailGlossary-termsListContainer">
            <terms-list />
          </div>
          <div class="kog-col-2 BookRailGlossary-termsNav">
            <glossary-drawer-navigation />
          </div>
        </div>
      </kog-loader>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import GlossaryDrawerNavigation from 'studyApp/components/glossary/glossary-drawer-navigation.vue';
import TermsListFilter from 'studyApp/components/glossary/terms-list-filter.vue';
import TermsList from 'studyApp/components/glossary/terms-list.vue';
import BookRailSliderHeader from 'studyApp/components/side-navigation/book-rail-slider-header.vue';

export default {
  name: 'BookRailGlossary',
  components: {
    KogLoader,
    TermsList,
    TermsListFilter,
    BookRailSliderHeader,
    GlossaryDrawerNavigation,
  },
  computed: {
    ...mapState({
      isTermsListLoading: state => state.glossaryV2Module.isTermsListLoading,
    }),
  },
  methods: {
    ...mapMutations({
      setTermsFilterString: 'glossaryV2Module/setTermsFilterString',
    }),
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
    }),
    onTermsFilterChanged(termsFilterString) {
      this.setTermsFilterString(termsFilterString);
    },
    onCloseClick() {
      this.closeRailSlider();
    },
  },
};
</script>

<style>
:root {
  --TermsListFilterHeight: 62px;
  --BookRailHeader: 44px;
}
</style>

<style scoped>
.BookRailGlossary-filter {
  height: var(--TermsListFilterHeight);
}

.BookRailGlossary-content {
  height: calc(100% - var(--TermsListFilterHeight) - var(--BookRailHeader));
}

.BookRailGlossary-termsListContainer {
  scrollbar-width: none; /* Firefox */

  position: relative; /* To fix containment for Chrome */

  overflow-y: auto;

  max-height: calc(
    100vh - (var(--space-s) * 4) - var(--TermsListFilterHeight) - var(--BookRailHeader)
  );

  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.BookRailGlossary-termsListContainer::-webkit-scrollbar {
  width: 0;
  /* WebKit */
  height: 0;
}

.u-noPadding {
  padding: 0 !important;
}

@media (max-height: 880px) {
  .BookRailGlossary-termsNav {
    scrollbar-width: none; /* Firefox */

    overflow-y: auto;

    max-height: calc(
      100vh - (var(--space-s) * 4) - var(--TermsListFilterHeight) - var(--BookRailHeader)
    );
    padding-top: 5px;
    padding-bottom: 5px;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .BookRailGlossary-termsNav::-webkit-scrollbar {
    width: 0;
    /* WebKit */
    height: 0;
  }
}
</style>
