<template>
  <div class="TermsListFilter">
    <kog-input
      v-model:value="searchString"
      :disabled="disabled"
      class="TermsListFilter-wrapper"
      label="Search here for entries in the glossary content"
      :is-label-hidden="true"
      input-icon="search"
      type="text"
      placeholder="Filter glossary"
      @keyup="debouncedOnTermsFilterChanged"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';

import KogInput from 'sharedApp/components/base/input/kog-input.vue';

export default {
  name: 'TermsListFilter',
  components: {
    KogInput,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['terms-filter-changed'],
  data() {
    return {
      searchString: '',
      debouncedOnTermsFilterChanged: debounce(this.onTermsFilterChanged, 500),
    };
  },
  methods: {
    onTermsFilterChanged() {
      this.$emit('terms-filter-changed', this.searchString);
    },
  },
};
</script>

<style scoped>
.TermsListFilter {
  display: flex;
  padding-bottom: 22px;
}

.TermsListFilter-wrapper {
  width: 100%;
}
</style>
