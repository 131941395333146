<template>
  <kog-alert
    v-if="warningMessage"
    mode="warning"
    :icon-class="showIcon ? 'fa-warning' : ' '"
  >
    <div>
      {{ warningMessage }}
    </div>
    <a
      v-if="providerSsoLoginUrl"
      :aria-label="reAuthButtonLabel"
      :href="providerSsoLoginUrl"
    >
      <kog-button
        aria-hidden="true"
        class="margin-top-xs"
        size="small"
        :label="reAuthButtonLabel"
        has-icon
        icon-class="fa-sign-out"
      />
    </a>
  </kog-alert>
</template>

<script>
import { onBeforeUnmount } from 'vue';

import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';

export default {
  name: 'IntegrationAssignmentAlert',
  components: {
    KogButton,
    KogAlert,
  },
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { warningMessage, providerSsoLoginUrl, integrationProvider, resetIntegrationWarning } =
      useIntegrationAssignment();
    onBeforeUnmount(() => {
      resetIntegrationWarning();
    });
    return {
      warningMessage,
      providerSsoLoginUrl,
      integrationProvider,
    };
  },
  computed: {
    reAuthButtonLabel() {
      return `Reauthenticate with ${this.integrationProvider}`;
    },
  },
};
</script>
