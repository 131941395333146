<template>
  <div class="flexContainer flexContainer-column">
    <kog-loader
      class="flexChild-size-1 flexContainer flexContainer-column"
      :loading="isFetchingData"
    >
      <div class="flexContainer flexContainer-spaceBetween margin-top-xl">
        <teacher-insights-filter-panel
          :students="studentsList"
          :subject-tree="subjectTree"
          :persistence-key="`sid-${subjectId}-cid-${classId}-${tabName}`"
          :filter-options="['student', 'topic']"
          @apply-filters="applyFilters"
        />
        <kog-button
          label="Export .csv"
          :has-icon="true"
          icon-class="fa-download"
          @click="downloadClassCsv"
        />
      </div>
      <insights-table
        v-if="!isFetchingData"
        :tab-name="tabName"
        :style="{
          'max-height': tableMaxHeight,
        }"
        :is-loading-table-content="isLoadingTableContent"
        :students="filteredStudentsList"
        :subject-nodes="subjectNodesWithReflectionLeaves"
        :get-student-progress="getStudentProgress"
        :get-all-student-progress="getAllStudentProgress"
        :sort-column="sortColumn"
        :sorted-by="sortedBy"
        :sort-order="sortOrder"
        :education-system="educationSystem"
      />
      <reflection-questions-legend />
    </kog-loader>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import sortingKeys from 'sharedApp/const/sorting-keys.js';
import CsvDownloadMixin from 'sharedApp/mixins/csv-download-mixin.js';
import FullPageTableHeightMixin from 'sharedApp/mixins/full-page-table-height-mixin.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { capitalizeFirstLetter } from 'sharedApp/utils/string-utils.js';
import { dateFormatter } from 'sharedApp/utils/time-utils.js';
import InsightsTable from 'studyApp/components/insights/insights-table.vue';
import ReflectionQuestionsLegend from 'studyApp/components/insights/reflection-questions-legend.vue';
import TeacherInsightsFilterPanel from 'studyApp/components/teacher/insights/teacher-insights-filter-panel.vue';
import { getReflectionQuestionInsightsCsvBlob } from 'studyApp/utils/teacher/teacher-insights-utils.js';
import { SORT_ORDERS } from 'teachApp/utils/assignment-utils.js';

export default {
  name: 'ReflectionQuestionsTab',
  components: {
    InsightsTable,
    KogButton,
    KogLoader,
    ReflectionQuestionsLegend,
    TeacherInsightsFilterPanel,
  },
  mixins: [FullPageTableHeightMixin, RoutesMixin, CsvDownloadMixin],
  props: {
    isFetchingData: {
      type: Boolean,
      required: true,
    },
    isLoadingTableContent: {
      type: Boolean,
      required: true,
    },
    studentsList: {
      type: Array,
      required: true,
    },
    subjectClass: {
      type: Object,
      required: true,
    },
    subjectTree: {
      type: Object,
      required: true,
    },
    tabName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortedBy: sortingKeys.USER__FULL_NAME,
      sortOrder: SORT_ORDERS.desc,
      selectedStudentId: '',
      selectedNodeId: '',
      // eslint-disable-next-line vue/no-unused-properties
      tableDistanceFromPageBottom: 160, // needed by FullPageTableHeightMixin
      reflectionQuestionProgress: {},
    };
  },
  computed: {
    ...mapGetters('teacherInsightsModule', {
      getReflectionAnswersByUsers: 'getReflectionAnswersByUsers',
      getNodesWithReflectionSections: 'getNodesWithReflectionSections',
    }),
    ...mapGetters('subjectModule', {
      educationSystem: 'educationSystemName',
    }),
    ...mapState({
      subjectNodeMap: state => state.teacherInsightsModule.subjectNodeMap,
    }),
    subjectNodesWithReflectionLeaves() {
      return this.getNodesWithReflectionSections(this.subjectNodes);
    },
    subjectNodes() {
      if (!this.selectedNodeId) {
        const allTopics = this.subjectTree?.children.map(c => c.children) || [];
        return allTopics.flat();
      }

      const subjectNode = this.subjectNodeMap[this.selectedNodeId];
      return subjectNode.children;
    },
    filteredStudentsList() {
      const isOnlyOneStudentSelected = Boolean(this.selectedStudentId);
      if (isOnlyOneStudentSelected) {
        return [this.studentsList.find(student => student.id === this.selectedStudentId)];
      }
      return this.sortedStudentsList;
    },
    sortedStudentsList() {
      const copy = [...this.studentsList];
      const sortedList = copy.sort((student1, student2) => {
        if (this.sortOrder === SORT_ORDERS.desc) {
          return student1.last_name.localeCompare(student2.last_name);
        }
        return student2.last_name.localeCompare(student1.last_name);
      });
      return sortedList;
    },
  },
  watch: {
    subjectNodes: {
      handler() {
        this.updateReflectionQuestionProgress();
      },
      deep: true,
    },
    isLoadingTableContent: {
      immediate: true,
      handler() {
        this.updateReflectionQuestionProgress();
      },
    },
    isFetchingData: {
      immediate: true,
      handler() {
        this.updateReflectionQuestionProgress();
      },
    },
  },
  methods: {
    applyFilters({ filters, isUserTriggered }) {
      this.selectedStudentId = filters.student;
      this.selectedNodeId = filters.subjectNode;

      if (isUserTriggered) {
        this.$mixpanel.trackEvent('Insights Reflection Questions - Click on filter', {
          filteredNodeId: this.selectedNodeId,
          filteredStudentId: this.selectedStudentId,
        });
      }
    },
    sortColumn(key) {
      const isSortedDesc = this.sortOrder === SORT_ORDERS.desc;
      const order = isSortedDesc ? SORT_ORDERS.asc : SORT_ORDERS.desc;
      this.sortedBy = key;
      this.sortOrder = order;
    },
    getStudentProgress(student) {
      return this.reflectionQuestionProgress[student.user_id];
    },
    getCsvMetaData() {
      const metaData = [`"Class: ${this.subjectClass.name}"`];

      if (this.selectedNodeId) {
        const node = this.subjectNodeMap[this.selectedNodeId];
        const nodeName = `${node.formatted_number_including_ancestors} ${node.name}`;
        const nodeLabel = capitalizeFirstLetter(this.$term('topic'));
        metaData.push(`"${nodeLabel}: ${nodeName}"`);
      }

      if (this.selectedStudentId) {
        const student = this.filteredStudentsList[0];
        const studentName = `${student.first_name} ${student.last_name}`;
        metaData.push(`"Student: ${studentName}"`);
      }

      return metaData;
    },
    downloadClassCsv() {
      const metaData = this.getCsvMetaData();
      const blob = getReflectionQuestionInsightsCsvBlob(
        this.filteredStudentsList,
        this.subjectNodesWithReflectionLeaves,
        this.reflectionQuestionProgress,
        metaData,
      );
      const currentDate = dateFormatter(new Date());
      const fileName = `${this.subjectClass.name}_reflection_question_progress_created_${currentDate}.csv`;
      this.downloadCsvFile(fileName, blob);
      this.$mixpanel.trackEvent('Insights Reflection Questions - Click Export data button', {
        filteredNodeId: this.selectedNodeId,
        filteredStudentId: this.selectedStudentId,
        filteredInterval: this.selectedInterval,
      });
    },
    updateReflectionQuestionProgress() {
      if (this.isFetchingData || this.isLoadingTableContent) {
        this.reflectionQuestionProgress = {};
        return;
      }
      const studentIds = this.studentsList.map(student => student.user_id);
      this.reflectionQuestionProgress = this.getReflectionAnswersByUsers(studentIds);
    },
    getAllStudentProgress(subjectNode) {
      const subjectNodeId = `${subjectNode.id}`;
      const filteredStudentIds = this.filteredStudentsList.map(student => student.user_id);
      const submissionCount = filteredStudentIds.filter(id =>
        Object.keys(this.reflectionQuestionProgress[id]).includes(subjectNodeId),
      ).length;
      const flaggedCount = filteredStudentIds.filter(
        id => this.reflectionQuestionProgress[id][subjectNodeId]?.is_flagged_by_teacher,
      ).length;
      return { submissionCount, flaggedCount };
    },
  },
};
</script>
