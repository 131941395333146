import { Command } from '@ckeditor/ckeditor5-core';

export default class InsertAudioCommand extends Command {
  execute(configuration) {
    const { model } = this.editor;
    model.change(writer => {
      model.insertObject(this.create(writer, configuration));
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(
      document.selection.getFirstPosition(),
      'audioWrapper',
    );
    this.isEnabled = allowedIn !== null;

    const firstRange = document.selection.getFirstRange();
    if (!firstRange.isCollapsed && firstRange.start.nodeAfter?.name === 'audioWrapper') {
      const node = firstRange.start.nodeAfter;
      const childNode = node.getChild(0);
      const value = {
        alignment: node.getAttribute('alignment'),
        url: childNode.getAttribute('url'),
      };
      this.value = value;
    } else {
      this.value = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer, { alignment, url }) {
    const audioWrapper = writer.createElement('audioWrapper', { alignment });
    const audio = writer.createElement('audio', { url });
    writer.append(audio, audioWrapper);
    return audioWrapper;
  }
}
