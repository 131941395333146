import KOG_COLORS from 'sharedApp/services/colors/color-service.js';

export const AUBERGINE = 'aubergine';
export const BLUE = 'blue';
export const GREEN = 'green';
export const ORANGE = 'orange';
export const GRAY = 'gray';

export const SYLLABUS_COLORS = [
  {
    name: AUBERGINE,
    cssValue: KOG_COLORS.AUBERGINE_30,
  },
  {
    name: BLUE,
    cssValue: KOG_COLORS.BLUE_30,
  },
  {
    name: GREEN,
    cssValue: KOG_COLORS.GREEN_30,
  },
  {
    name: ORANGE,
    cssValue: KOG_COLORS.ORANGE_30,
  },
  {
    name: GRAY,
    cssValue: KOG_COLORS.GRAY_30,
  },
];
