<template>
  <div>
    <div
      :class="{
        'QuizContainer-wrapper--FocusFlow': isAssignmentFocus,
      }"
      class="QuizContainer-wrapper kog-col-12 kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12"
    >
      <kog-loader
        v-if="$wait.is('loading-quiz')"
        :loading="$wait.is('loading-quiz')"
        flex
      />

      <template v-else-if="quizState === QUIZ_STATE.NOT_STARTED">
        <section-header
          :subject-node-id="nodeId"
          :is-in-review-mode="isReviewMode"
          :is-showing-subtitle="true"
          :is-showing-quiz-statistics="true"
          :is-showing-book-statistics="false"
        />
        <quiz-front-page
          :is-review-mode="isReviewMode ? 'true' : null"
          :is-teacher="user.isTeacher()"
          @start-quiz="startQuiz"
        />
      </template>
      <div
        v-else-if="quizState === QUIZ_STATE.STARTED || quizState === QUIZ_STATE.REVIEW_RESULTS"
        class="flexContainer flexChild-canGrow flexContainer-column flexContainer-center"
      >
        <quiz-questions
          class="padd-top-m"
          :quiz-questions="quizQuestions"
          :is-review-mode="isReviewMode ? 'true' : null"
          @quiz-submitted="onQuizSubmitted"
          @quiz-closed="onQuizClosed"
        />
      </div>
      <template v-else-if="quizState === QUIZ_STATE.SUBMITTED">
        <section-header
          :subject-node-id="nodeId"
          :is-in-review-mode="isReviewMode"
          :is-showing-subtitle="true"
        />
        <quiz-results-page
          :questions-count="quizQuestionResponses.length"
          :questions-answered-correctly-count="questionsAnsweredCorrectlyCount"
          @restart-quiz="restartQuiz"
        />
      </template>
    </div>
    <assignment-checkpoint-container
      v-if="isAssignmentFocus && !isReviewMode"
      :current-section-node="subjectNode"
      :is-submittable="isQuizSubmitted"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import NodeProgressMixin from 'learning/study/mixins/node-progress-mixin.js';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import { QUIZ_STATE } from 'sharedApp/const/quiz.js';
import {
  getAllDescendantsNodeIdsIncludingSelf,
  getSubjectNodeMap,
} from 'sharedApp/libs/subject-tree-functions.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import SectionHeader from 'studyApp/components/section-text/section-header.vue';
import ActivityMixin from 'studyApp/mixins/activity-mixin.js';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';
import AssignmentCheckpointContainer from 'studyApp/pages/assignment/components/assignment-checkpoint-container.vue';
import QuizFrontPage from 'studyApp/pages/features/quiz/quiz-front-page.vue';
import QuizQuestions from 'studyApp/pages/features/quiz/quiz-questions.vue';
import QuizResultsPage from 'studyApp/pages/features/quiz/quiz-results-page.vue';

export default {
  name: 'QuizContainer',
  components: {
    AssignmentCheckpointContainer,
    SectionHeader,
    QuizQuestions,
    QuizFrontPage,
    QuizResultsPage,
    KogLoader,
  },
  mixins: [RoutesMixin, ActivityMixin, NodeProgressMixin, ReadingAssignmentMixin],
  props: {
    quizState: {
      type: String,
      required: true,
    },
    isReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:quizState'],
  data() {
    return {
      QUIZ_STATE,
    };
  },
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getQuestionById: 'questionsModule/getQuestionById',
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
    }),
    ...mapState({
      quiz: state => state.quizModule.quiz,
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
      quizOccasion: state => state.quizModule.quizOccasion,
      quizQuestionResponses: state => state.quizModule.quizQuestionResponses,
    }),
    subjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    quizQuestions() {
      const questions = this.quiz?.ordered_question_ids.map(
        questionId => this.getQuestionById(questionId) ?? {},
      );
      return questions.filter(question => Object.keys(question).length > 0);
    },
    isQuizSubmitted() {
      return Boolean(this.quizOccasion?.is_submitted);
    },
    activity() {
      return this.getActivityBySubjectNodeId(this.nodeId);
    },
    activityId() {
      return this.activity.id;
    },
    subjectNodeForQuestionsWithDescendants() {
      const subjectNodeMap = getSubjectNodeMap(this.subject.subject_tree[0]);
      const subjectNodeForQuestions = subjectNodeMap[this.nodeId];
      if (subjectNodeForQuestions.level !== 1) {
        return subjectNodeMap[subjectNodeForQuestions.parent];
      }
      return subjectNodeForQuestions;
    },
    mixpanelTrackingProps() {
      return {
        subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNode.name}`,
        source: this.isAssignmentFocus ? 'assignment' : 'book',
      };
    },
    analyticsTrackingProps() {
      return {
        subject_id: this.subject.id,
        subject_class_id: this.classId,
        subject_node_id: this.nodeId,
        quiz_id: this.quiz.id,
        quiz_occasion_id: this.quizOccasion.id,
      };
    },
    questionsAnsweredCorrectlyCount() {
      return this.quizQuestionResponses.filter(response => response.answered_correctly).length;
    },
  },
  watch: {
    nodeId: {
      handler() {
        this.reloadQuiz();
      },
      immediate: true,
    },
  },
  created() {
    this.$mixpanel.trackEvent('Quiz - Open quiz', this.mixpanelTrackingProps);
    this.fetchGlossaryDefinitions(this.subjectId);
    this.fetchReadingAssignmentForSubjectNode(this.nodeId);
  },
  methods: {
    ...mapActions('glossaryV2Module', {
      fetchGlossaryDefinitions: 'fetchGlossaryDefinitions',
    }),
    ...mapActions('questionsModule', {
      fetchQuestions: 'fetchQuestions',
    }),
    ...mapActions('quizModule', {
      fetchQuiz: 'fetchQuiz',
      fetchLastQuizOccasion: 'fetchLastQuizOccasion',
    }),
    ...mapMutations({
      resetQuizOccasion: 'quizModule/resetQuizOccasion',
    }),
    async reloadQuiz() {
      this.$wait.start('loading-quiz');
      const showAnswers = this.user.isStudent() ? { detail: 'True' } : { showAnswers: 'True' };
      await this.fetchQuiz(this.activityId);
      const nodeIds = [
        ...getAllDescendantsNodeIdsIncludingSelf(this.subjectNodeForQuestionsWithDescendants),
      ];

      this.fetchQuestions({
        subjectNodeIds: [nodeIds],
        questionIds: this.quiz.ordered_question_ids,
        params: showAnswers,
      });
      await this.fetchSubjectNodesProgress(this.subject.id);
      if (!this.isReviewMode && this.isSubjectNodeCompleted(this.nodeId)) {
        await this.fetchLastQuizOccasion({ quizId: this.quiz.id, isSubmitted: true });
      } else {
        this.resetQuizOccasion();
      }

      if (this.isQuizSubmitted) {
        this.$emit('update:quizState', QUIZ_STATE.SUBMITTED);
      } else {
        this.$emit('update:quizState', QUIZ_STATE.NOT_STARTED);
      }
      this.$wait.end('loading-quiz');
    },
    startQuiz() {
      this.$emit('update:quizState', QUIZ_STATE.STARTED);
      this.$mixpanel.trackEvent('Quiz - Start quiz', this.mixpanelTrackingProps);
    },
    async onQuizSubmitted() {
      this.$emit('update:quizState', QUIZ_STATE.REVIEW_RESULTS);
      await this.updateNodeProgress(true);
      if (this.isInReadingAssignment) {
        this.showReadingAssignmentModal(this.nodeId);
      }
      this.$mixpanel.trackEvent('Quiz - Complete quiz', this.mixpanelTrackingProps);
      this.$event.track('submit_quiz', this.analyticsTrackingProps);
    },
    onQuizClosed() {
      if (this.isQuizSubmitted) {
        this.$emit('update:quizState', QUIZ_STATE.SUBMITTED);
      } else {
        this.$emit('update:quizState', QUIZ_STATE.NOT_STARTED);
      }
    },
    async restartQuiz() {
      this.$emit('update:quizState', QUIZ_STATE.NOT_STARTED);
      await this.updateNodeProgress(false);
      this.resetQuizOccasion();
    },
    async updateNodeProgress(isCompleted) {
      await this.registerNodeProgress(this.nodeId, isCompleted);
      this.fetchSubjectNodesProgress(this.subject.id);
    },
  },
};
</script>

<style>
:root {
  --AssignmentCheckpointNavigation: 88px;
}
</style>

<style scoped>
.QuizContainer-wrapper {
  min-height: 100vh;
}

.QuizContainer-wrapper--FocusFlow {
  min-height: calc(
    100vh - var(--AssignmentCheckpointNavigation) - var(--full-page-navbar-height) -
      var(--space-xxl)
  );
}
</style>
