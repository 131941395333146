<template>
  <div
    :class="[
      'QuestionAssignmentBottomNavbar divider-top-2 padd-m',
      {
        'QuestionAssignmentBottomNavbar--correct': displayState === 'correct',
        'QuestionAssignmentBottomNavbar--incorrect': displayState === 'incorrect',
        'QuestionAssignmentBottomNavbar--hasInfoText': hasInfoText,
      },
    ]"
  >
    <div class="QuestionAssignmentBottomNavbar-grid">
      <div>
        <kog-button
          v-if="hasPrevious"
          size="large"
          :label="previousText"
          aria-label="Go to previous question"
          :disabled="previousQueued"
          :has-icon="true"
          icon-class="fa-chevron-left"
          icon-style="solid"
          @click="$emit('previous')"
        />
      </div>
      <div>
        <span
          v-if="hasInfoText"
          :class="[
            'QuestionAssignmentBottomNavbar--infoText',
            {
              'heading-s': displayState === 'correct' || displayState === 'incorrect',
            },
          ]"
          aria-live="polite"
        >
          {{ infoText }}
        </span>
      </div>
      <div>
        <kog-button
          v-if="displaySubmitButton"
          v-bind="submitProps"
          size="large"
          icon-position="right"
          icon-style="regular"
          :disabled="isSubmitDisabled"
          :has-icon="true"
          :button-style="buttonDisplayStateStyle"
          @click="$emit('submit')"
        />
        <kog-button
          v-else
          size="large"
          :label="nextText"
          :aria-label="nextAriaLabel"
          :tooltip="submitAssignmentTooltip"
          icon-class="fa-chevron-right"
          icon-position="right"
          icon-style="solid"
          :disabled="isNextDisabled"
          :has-icon="true"
          :button-style="buttonDisplayStateStyle"
          @click="$emit('next')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useMq } from 'vue3-mq';

import type { PropType } from 'vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

const props = defineProps({
  displayState: {
    type: String as PropType<
      'neutral' | 'awaiting_answer' | 'awaiting_submission' | 'answered' | 'correct' | 'incorrect'
    >,
    default: 'neutral',
  },
  hasPrevious: {
    type: Boolean,
    required: true,
  },
  hasNext: {
    type: Boolean,
    required: true,
  },
  hasSubmittedAllQuestions: {
    type: Boolean,
    required: true,
  },
  hasAnsweredAllQuestions: {
    type: Boolean,
    required: true,
  },
  nextQueued: {
    type: Boolean,
    default: false,
  },
  previousQueued: {
    type: Boolean,
    default: false,
  },
  isSubmitDisabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['next', 'previous', 'submit']);

const submitAssignmentTooltip = computed(() => {
  const isCurrentQuestionSubmitted =
    props.displayState === 'correct' || props.displayState === 'incorrect';
  const disabledSubmitButtonTooltip = !props.hasNext
    ? 'You skipped some questions. Go back to answer them in order to submit the assignment'
    : null;

  if (isCurrentQuestionSubmitted) {
    return props.hasSubmittedAllQuestions
      ? 'You have submitted all answers and can now submit your assignment'
      : disabledSubmitButtonTooltip;
  }
  if (!props.hasAnsweredAllQuestions && props.displayState !== 'awaiting_submission') {
    return disabledSubmitButtonTooltip;
  }
  return null;
});

const infoText = computed(() => {
  if (props.displayState === 'correct') {
    return 'Well done!';
  }
  if (props.displayState === 'incorrect') {
    return 'Please take a look at the correct answer.';
  }
  if (!props.hasNext) {
    if (props.hasAnsweredAllQuestions && props.displayState !== 'awaiting_submission') {
      return 'All set! You can submit your assignment.';
    }
    return 'Answer all the questions to complete the assignment.';
  }
  return '';
});
const hasInfoText = computed(() => {
  return infoText.value !== '';
});

const mq = useMq();
const previousText = computed(() => {
  return mq.sMinus ? 'Previous' : 'Previous question';
});
const nextText = computed(() => {
  if (props.displayState === 'awaiting_answer' || props.displayState === 'awaiting_submission') {
    return mq.sMinus ? 'Submit' : 'Submit answer';
  }

  return mq.sMinus ? 'Next' : 'Next question';
});
const nextAriaLabel = computed(() => {
  if (props.displayState === 'awaiting_submission' || props.displayState === 'awaiting_answer') {
    return 'Submit answer';
  }

  return 'Go to next question';
});

const buttonDisplayStateStyle = computed(() => {
  const isDisabledSubmitAssignmentButtonShown = !props.hasNext && !props.hasSubmittedAllQuestions;
  switch (props.displayState) {
    case 'correct':
      if (isDisabledSubmitAssignmentButtonShown) {
        return '';
      }
      return 'positive';
    case 'incorrect':
      if (isDisabledSubmitAssignmentButtonShown) {
        return '';
      }
      return 'danger';
    case 'answered':
    case 'awaiting_answer':
    case 'awaiting_submission':
      return 'primary';
    default:
      return null;
  }
});
const isNextDisabled = computed(() => {
  return props.nextQueued || props.displayState === 'awaiting_answer';
});

const displaySubmitButton = computed(() => {
  return (
    !props.hasNext &&
    props.displayState !== 'awaiting_answer' &&
    props.displayState !== 'awaiting_submission'
  );
});

const submitProps = computed(() => {
  const iconClass =
    props.displayState === 'correct' || props.displayState === 'incorrect'
      ? 'fa-circle-check'
      : 'fa-paper-plane';

  const label = 'Submit assignment';

  return {
    iconClass,
    tooltip: submitAssignmentTooltip.value,
    label: mq.sMinus ? label.split(' ')[0] : label,
  };
});
</script>

<style scoped>
.QuestionAssignmentBottomNavbar {
  position: relative;
  z-index: 1000;
  display: grid;
  background-color: var(--kog-colors-white);
}

.QuestionAssignmentBottomNavbar--correct,
.QuestionAssignmentBottomNavbar--incorrect {
  & .QuestionAssignmentBottomNavbar--infoText {
    font-family: var(--kog-satoshi);
  }
}
.QuestionAssignmentBottomNavbar--correct {
  background-color: var(--kog-colors-green-100);

  & .QuestionAssignmentBottomNavbar--infoText {
    color: var(--kog-colors-green-900);
  }
}

.QuestionAssignmentBottomNavbar--incorrect {
  background-color: var(--kog-colors-pink-100);

  & .QuestionAssignmentBottomNavbar--infoText {
    color: var(--kog-colors-pink-900);
  }
}

.QuestionAssignmentBottomNavbar-grid {
  display: grid;
  grid-template-columns: [left] 1fr [mid] 1fr [right] 1fr;
  grid-template-rows: 1fr;
  align-items: center;

  & > :nth-child(1) {
    grid-column-start: left;
    margin-right: auto;
  }

  & > :nth-child(2) {
    text-align: center;
  }

  & > :nth-child(3) {
    grid-column-start: right;
    margin-left: auto;
  }
}

@media (--viewport-s) {
  .QuestionAssignmentBottomNavbar--hasInfoText .QuestionAssignmentBottomNavbar-grid {
    grid-template-rows: repeat(2, 1fr);

    & > :nth-child(1) {
      order: 1;
    }

    & > :nth-child(2) {
      grid-column: span 3;
      order: 0;
    }

    & > :nth-child(3) {
      order: 2;
    }
  }
}
</style>
