<template>
  <kog-table-cell-label
    v-if="locations.length === 1"
    :text="getFirstLocationNumber(locations)"
    :tooltip-text="getLocationsHtml(locations)"
  />
  <kog-table-cell-text
    v-else
    :is-padded-left="false"
  >
    <span
      v-tooltip="{
        theme: 'kog-tooltip',
        content: getLocationsHtml(locations),
        boundary: 'window',
        autoHide: false,
      }"
    >
      {{ getQuestionLocation(locations) }}
    </span>
  </kog-table-cell-text>
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogTableCellLabel from 'sharedApp/components/tables/kog-table-cell-label.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import QuestionLocationMixin from 'teachApp/mixins/question-location-mixin.js';

export default {
  name: 'QuestionLocationTableCell',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    KogTableCellLabel,
    KogTableCellText,
  },
  mixins: [QuestionLocationMixin],
  props: {
    locations: {
      type: Array,
      required: true,
    },
  },
};
</script>
