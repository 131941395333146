<template>
  <div>
    <kog-loader
      v-if="!assignment"
      class="padd-top-xxxl"
      loading-msg="Loading the assignment..."
    />
    <template v-else>
      <fullpage-takeover-focus-navbar
        close-button-aria-label="Click to go back to  last page"
        back-button-aria-label="Click to go back to the class overview"
        :header-text="subjectClassName"
        :header-link-text="assignmentName"
        :header-link-route="focusLandingRoute"
        @back-button-click="navigateToPreviousRoute"
        @close-button-click="navigateToPreviousRoute"
      />
      <div class="TeacherAssignmentDetailsLanding padd-top-xxxl">
        <book-rail
          class="padd-top-xxxl"
          :is-collapsed="isBookRailCollapsed"
          :tracking-props="trackingPropsForBookRail"
          @collapse="isBookRailCollapsed = true"
          @expand="isBookRailCollapsed = false"
        />
        <div
          :class="{
            'TeacherAssignmentDetailsLanding-content--bookRail': !isBookRailCollapsed,
            'TeacherAssignmentDetailsLanding-content--collapsedBookRail': isBookRailCollapsed,
          }"
          class="TeacherAssignmentDetailsLanding-content"
        >
          <div class="kog-container-fluid">
            <div class="kog-row">
              <book-rail-slider
                class="TeacherAssignmentDetailsLanding-bookRailSlider"
                :node-id="nodeId"
                :is-review-mode="false"
              />
              <div
                class="positionRelative TeacherAssignmentDetailsLanding-mainPage flexContainer flexContainer-column flexContainer-alignCenter"
                :class="{
                  'TeacherAssignmentDetailsLanding-mainPage--railSliderOpen': isBookRailSliderOpen,
                  'TeacherAssignmentDetailsLanding-mainPage--railSliderClosed':
                    !isBookRailSliderOpen,
                }"
              >
                <scheduled-assignment-banner
                  v-if="assignment && assignment.is_scheduled_to_send"
                  class="width-full"
                  :assignment="assignment"
                  :integration-provider="integrationProvider"
                  @reload-assignment="fetchAssignment(assignment.id)"
                />
                <teacher-reading-assignment-focus-landing-header
                  :assignment="assignment"
                  :integration-provider="integrationProvider"
                  @reload-assignment="fetchAssignment(assignment.id)"
                />
                <div class="flexContainer flexContainer-column width-full">
                  <div>
                    <integration-assignment-alert
                      class="padd-right-xl padd-left-xl margin-top-m margin-bottom-m"
                    />
                  </div>
                  <div
                    class="TeacherAssignmentDetailsLanding-overview flexContainer flexContainer-alignStart"
                  >
                    <div
                      class="TeacherAssignmentDetailsLanding-leftColumn padd-left-xl padd-top-xl padd-right-l"
                    >
                      <div
                        v-if="assignment.message"
                        class="width-full padd-bottom-l"
                      >
                        <h2 class="heading-xs margin-bottom-s">Instructions</h2>
                        <p class="TeacherAssignmentDetailsLanding-instructions-text">{{
                          assignment.message
                        }}</p>
                      </div>
                      <reading-assignment-focus-tasks
                        class="TeacherAssignmentDetailsLanding-tasks flexChild-flexStart"
                        :assignment-reading-items="assignmentReadingItems"
                        :assignment-id="assignment.id"
                        @open-completion-modal="openCompletionModal"
                      />
                    </div>
                    <div
                      class="TeacherAssignmentDetailsLanding-progress padd-top-xl padd-right-xl padd-bottom-xl"
                      :class="[
                        {
                          'padd-left-xl padd-top-xl padd-right-l': ['m', 's'].includes(mq.current),
                        },
                      ]"
                    >
                      <div
                        v-if="assignment.is_sent_to_integration"
                        class="margin-bottom-m"
                      >
                        <h2 class="heading-xs margin-bottom-s">Assignment details</h2>
                        <kog-card>
                          <div class="flexContainer">
                            <div class="TeacherAssignmentDetailsLanding-detailsLabel"> Points </div>
                            <kog-skeleton
                              v-if="isLoadingIntegrationAssignment"
                              height="24px"
                              width="100%"
                            />
                            <div
                              v-else
                              class="text-bold"
                            >
                              {{ integrationAssignmentPoints }}
                            </div>
                          </div>
                          <div
                            v-if="integrationAssignmentCategory"
                            class="flexContainer margin-top-xs"
                          >
                            <div class="TeacherAssignmentDetailsLanding-detailsLabel">
                              Assignment category
                            </div>
                            <kog-skeleton
                              v-if="isLoadingIntegrationAssignment"
                              height="24px"
                              width="100%"
                            />
                            <div
                              v-else
                              class="text-bold"
                            >
                              {{ integrationAssignmentCategory }}
                            </div>
                          </div>
                        </kog-card>
                      </div>
                      <reading-assignment-student-progress :students="assignment.students" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              v-if="isBookRailSliderOpen"
              v-kog-clickable="closeRailSlider"
              class="TeacherAssignmentDetailsLanding-backdrop"
            />
          </transition>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { inject } from 'vue';
import sumBy from 'lodash/sumBy.js';
import { storeToRefs } from 'pinia';
import { mapActions, mapGetters, mapState } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogSkeleton from 'sharedApp/components/base/skeleton/kog-skeleton.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import FullpageTakeoverFocusNavbar from 'sharedApp/components/core/fullpage-takeover-focus-navbar.vue';
import { ACTIVITY_SHEET, PERFORMANCE_TASK, QUIZ } from 'sharedApp/const/features.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { getAssignmentDetailsTrackingProps } from 'sharedApp/services/assignment/assignment-utility-service.js';
import ActivitySubmissionsModal from 'studyApp/components/activities/activity-submissions-modal.vue';
import IntegrationAssignmentAlert from 'studyApp/components/assignment/integration-assignment-alert.vue';
import BookRailSlider from 'studyApp/components/side-navigation/book-rail-slider.vue';
import BookRail from 'studyApp/components/side-navigation/book-rail.vue';
import ReadingCompletionStatsModal from 'studyApp/components/subject-class-overview/reading-completion-stats-modal.vue';
import ScheduledAssignmentBanner from 'studyApp/components/teacher/assignments/scheduled-assignment-banner.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';
import ReadingAssignmentFocusTasks from 'studyApp/pages/assignment/components/reading-assignment-focus-tasks.vue';
import ReadingAssignmentStudentProgress from 'studyApp/pages/assignment/components/reading-assignment-student-progress.vue';
import TeacherReadingAssignmentFocusLandingHeader from 'studyApp/pages/assignment/components/teacher-reading-assignment-focus-landing-header.vue';
import QuizStudentResponsesModal from 'studyApp/pages/features/quiz/quiz-student-responses-modal.vue';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);
export default {
  name: 'TeacherAssignmentDetailsLanding',
  components: {
    KogSkeleton,
    KogCard,
    TeacherReadingAssignmentFocusLandingHeader,
    FullpageTakeoverFocusNavbar,
    KogLoader,
    BookRail,
    BookRailSlider,
    ReadingAssignmentFocusTasks,
    ReadingAssignmentStudentProgress,
    ScheduledAssignmentBanner,
    IntegrationAssignmentAlert,
  },
  mixins: [RoutesMixin],
  inject: ['mq'],
  setup() {
    const { points, isLoading, category, loadIntegrationAssignment } = useIntegrationAssignment();
    const assignmentStore = useAssignmentStore();
    const { assignment } = storeToRefs(assignmentStore);
    const { fetchAssignment } = assignmentStore;
    const readingAssignmentStudentProgress = inject('readingAssignmentStudentProgress', []);

    return {
      assignment,
      fetchAssignment,
      integrationAssignmentPoints: points,
      integrationAssignmentCategory: category,
      loadIntegrationAssignment,
      isLoadingIntegrationAssignment: isLoading,
      readingAssignmentStudentProgress,
    };
  },
  data() {
    return {
      isBookRailCollapsed: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
    }),
    ...mapGetters({
      isBookRailSliderOpen: 'bookModule/isBookRailSliderOpen',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
      subjectNodesById: 'subjectModule/subjectNodesById',
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    subjectClass() {
      return this.subjectClassesById[this.classId];
    },
    source() {
      return this.$route.query.source || '';
    },
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    assignmentName() {
      return this.assignment.name || '';
    },
    assignmentReadingItems() {
      return this.assignment.sections.map(node => {
        return {
          id: node.subject_node_id,
          slug: node.slug,
          name: node.name,
          studentCompletedCount: this.getStudentCompletedCount(node.subject_node_id),
          studentCount: this.assignment.students.length,
        };
      });
    },
    trackingPropsForBookRail() {
      return {
        subject_id: this.subject.id,
        subject_class_id: this.classId,
        subject_node_id: this.nodeId,
        source: 'assignment',
      };
    },
    subjectClassName() {
      return this.subjectClass.name;
    },
    integrationProvider() {
      return this.subjectClass?.integration_providers?.[0] || '';
    },
    focusLandingRoute() {
      return {
        name: 'teacherAssignmentDetails',
        params: {
          assignmentId: this.assignmentId,
          classSlug: this.subjectClass.slug,
          sid: `${this.subject.id}`,
          cid: `${this.classId}`,
        },
      };
    },
    readingCompletionData() {
      return this.readingAssignmentStudentProgress.reduce((acc, studentProgress) => {
        if (studentProgress.completed) {
          const userId = studentProgress.user_id;
          if (!acc[userId]) {
            acc[userId] = new Set();
          }
          acc[userId].add(studentProgress.subjectnode_id);
        }
        return acc;
      }, {});
    },
  },
  created() {
    this.trackAssignmentStart();
    this.closeRailSlider();
    this.loadIntegrationAssignment(this.assignment, this.classId);
  },
  methods: {
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
    }),
    navigateToPreviousRoute() {
      const previousRoute = studyAppLocalStorage.getLastUrl();
      if (previousRoute) {
        this.$mixpanel.trackEvent('Assignment - Close');
        window.location.href = previousRoute;
      } else {
        this.navigateToClassOverview();
      }
    },
    navigateToClassOverview() {
      this.$mixpanel.trackEvent('Assignment - Close');
      this.$router.push({
        name: 'subjectClassOverview',
        params: this.$route.params,
      });
    },
    trackAssignmentStart() {
      this.trackAssignment('Assignment - Open');
      this.$event.track(
        'load_assignment_details',
        {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          assignment_id: this.assignment.id,
        },
        this.user,
      );
    },
    getStudentsCompletedCountForReadingAssignment() {
      return sumBy(this.assignment.students, student => {
        const studentSectionsCompleteCount = sumBy(
          this.readingAssignmentStudentProgress,
          progressNode =>
            progressNode.user_id === student.user_id &&
            (progressNode.completed || progressNode.first_completed_at),
        );

        return studentSectionsCompleteCount === this.assignment.sections.length;
      });
    },
    trackAssignment(eventName) {
      const numberOfStudentsCompleted = this.getStudentsCompletedCountForReadingAssignment();
      const eventProperties = getAssignmentDetailsTrackingProps(
        this.assignment,
        this.assignment.students.length,
        numberOfStudentsCompleted,
        this.source,
      );
      this.$mixpanel.trackEvent(eventName, eventProperties);
    },
    getStudentCompletedCount(subjectNodeId) {
      return sumBy(
        this.readingAssignmentStudentProgress,
        nodeProgress => nodeProgress.subjectnode_id === subjectNodeId && nodeProgress.completed,
      );
    },
    openCompletionModal(subjectNodeId) {
      const activity = this.getActivityBySubjectNodeId(subjectNodeId);
      const subjectNode = this.subjectNodesById[subjectNodeId];
      if (activity?.type === PERFORMANCE_TASK || activity?.type === ACTIVITY_SHEET) {
        this.$modal(ActivitySubmissionsModal, {
          subjectNode,
          subjectClassId: this.classId,
          students: this.assignment.students,
          subjectClassName: this.subjectClass.name,
          feature: activity,
          user: this.user,
          subject: this.subject,
          classSlug: this.subjectClassSlug,
          disableCreatingAssignment: true,
          source: 'assignment_details',
        });
      }
      if (activity?.type === QUIZ) {
        this.$modal(QuizStudentResponsesModal, {
          quizId: activity.id,
          studentsInClass: this.assignment.students,
          classId: this.classId,
          source: 'assignment_details',
          subjectNode,
        });
      } else {
        this.$modal(ReadingCompletionStatsModal, {
          subjectNode,
          subjectClassId: this.classId,
          students: this.assignment.students,
          readingCompletionData: this.readingCompletionData,
          initialFilter: 'submitted',
          user: this.user,
          subject: this.subject,
          classSlug: this.subjectClassSlug,
          subjectClassName: this.subjectClass.name,
          disableCreatingAssignment: true,
          source: 'assignment_details',
        });
      }
    },
  },
};
</script>

<style scoped>
.TeacherAssignmentDetailsLanding {
  display: flex;
  flex: 1 1 auto;

  width: 100%;
  height: calc(100% - var(--space-xxxl));

  background-color: var(--kog-colors-grey-100);
}
.TeacherAssignmentDetailsLanding-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: padding-left 0.2s ease-in-out;
}

.TeacherAssignmentDetailsLanding-content--bookRail {
  padding-left: var(--study-rail-menu-width);
}

.TeacherAssignmentDetailsLanding-content--collapsedBookRail {
  padding-left: var(--study-rail-menu-collapsed-width);
}

.TeacherAssignmentDetailsLanding-mainPage {
  overflow-y: scroll;
  height: calc(100vh - var(--full-page-navbar-height));
  padding: 0;
  background-color: var(--kog-colors-grey-100);
}

.TeacherAssignmentDetailsLanding-overview {
  flex-direction: row;
}

.TeacherAssignmentDetailsLanding-leftColumn {
  flex-basis: 66.66%;
}

.TeacherAssignmentDetailsLanding-instructions-text {
  font-family: var(--kog-satoshi);
  font-size: 16px;
  line-height: 24px;
}

.TeacherAssignmentDetailsLanding-progress {
  flex-basis: 33.34%;
}

.TeacherAssignmentDetailsLanding-content--collapsedBookRail
  .TeacherAssignmentDetailsLanding-mainPage--railSliderOpen {
  width: calc(100% - var(--study-rail-slider-width) - var(--space-s));
  padding-left: var(--study-rail-menu-collapsed-width);
}

.TeacherAssignmentDetailsLanding-detailsLabel {
  min-width: 140px;
}

@media (--viewport-l) {
  .TeacherAssignmentDetailsLanding-backdrop {
    position: fixed;
    z-index: 100;

    display: block;

    width: 100%;
    height: 100%;
    padding-left: var(--study-rail-menu-width);

    background-color: var(--kogShadow080);
  }
  .TeacherAssignmentDetailsLanding-bookRailSlider {
    padding-top: var(--space-xxxl);
  }
}

@media (--viewport-m) {
  .TeacherAssignmentDetailsLanding-tasks {
    align-self: center;
  }

  .TeacherAssignmentDetailsLanding-leftColumn {
    flex-basis: 100%;
    width: 100%;
  }

  .TeacherAssignmentDetailsLanding-progress {
    flex-basis: 100%;
    align-self: center;
    width: 100%;
  }

  .TeacherAssignmentDetailsLanding-overview {
    flex-direction: column;
    margin-bottom: var(--space-xxxxl);
  }
}
</style>
