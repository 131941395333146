<template>
  <div ref="root">
    <kog-loader
      v-if="!isContentAltered"
      :loading="!isContentAltered"
      loading-msg="Beautifying the content..."
    />
    <all-content-filtered-info-box
      v-if="shouldShowLevelsInfo && subjectNode.is_content_blank_due_to_level"
    />
    <content-container
      v-show="subjectTree && isContentAltered"
      :id="subjectNodeId"
      v-kog-mathjax
      class="AnnotateText--Content"
      :content="content"
      :subject-tree="subjectTree"
      :subject-node="subjectNode"
      :get-section-url="getNodeUrl"
      :is-in-review-mode="isInReviewMode"
      :user-level="userLevel"
      :subject="subject"
      :is-glossary-used="true"
      :is-ready-to-augment="isReadyToAugmentContent"
      @init-interactive-content-done="onContentAugmented"
      @content-rendered="onContentHtmlRendered"
    />
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { isNil, keyBy } from 'lodash';
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from 'pinia';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapState } from 'vuex';

import { getExerciseQuestions } from '@apis/questions';

import AllContentFilteredInfoBox from 'learning/common/components/all-content-filtered-info-box.vue';
import ExerciseQuestionBox from 'learning/common/components/exercise-question/exercise-question-box.vue';
import ReflectionQuestion from 'learning/common/components/reflection-question/reflection-question.vue';
import {
  DEFAULT_HIGHLIGHT_COLOR,
  trackDeleteNote,
  trackSaveNote,
} from 'learning/common/libs/annotations-utils.js';
import HighlightMixin from 'learning/common/mixins/highlight-mixin.js';
import useAnnotationStore from 'learning/common/store-modules/annotations/store.ts';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import ContentContainer from 'sharedApp/components/content/content-container.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import mountComponent from 'sharedApp/utils/mount-component.ts';
import OpenEndedTaskContainer from 'studyApp/containers/open-ended-task/open-ended-task-container.vue';
import useOpenEndedTaskStore from 'studyApp/store/modules/open-ended-task.js';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';
import { isContentContainingReflectionBox } from 'studyApp/utils/reflection-question-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

function findExerciseQuestionIds(exerciseBox) {
  const questionEls = exerciseBox.querySelectorAll('kog-exercise-question');
  const questionIds = [];
  questionEls.forEach(el => {
    const questionId = el.getAttribute('question-id');
    questionIds.push(questionId);
  });
  return questionIds;
}

export default {
  name: 'AnnotateText',
  components: {
    KogLoader,
    AllContentFilteredInfoBox,
    ContentContainer,
  },
  mixins: [HighlightMixin, RoutesMixin],
  props: {
    content: {
      type: String,
      default: '',
    },
    currentSubtopicText: {
      type: String,
      default: '',
    },
    highlightEnabled: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: Object,
      default: null,
    },
    subjectNode: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
    noteSearchParam: {
      type: String,
      default: '',
    },
    subjectTree: {
      type: Array,
      required: true,
    },
    isInReviewMode: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['content-altered'],
  data() {
    return {
      initialScrollToNote: true,
      isContentAltered: false,
      exerciseBoxesDestroyMethods: [],
      reflectionBoxesDestroyMethods: [],
      openEndedTasksDestroyMethods: [],
      isContentRendered: false,
      isReflectionQuestionsRendered: false,
      isExerciseQuestionsRendered: false,
    };
  },
  computed: {
    ...mapState({
      reflectionQuestions: state => state.questionsModule.reflectionQuestions,
      bookRailSliders: state => state.bookModule.bookRailSliders,
      userLevel: state => state.subjectModule.level,
    }),
    ...mapPiniaState(useAnnotationStore, [
      'annotations',
      'focusedAnnotation',
      'isSaving',
      'lastSoftDeletedId',
    ]),
    isReadyToAugmentContent() {
      return (
        (!this.isReflectionRenderRequired || this.isReflectionQuestionsRendered) &&
        this.isExerciseQuestionsRendered
      );
    },
    isReflectionRenderRequired() {
      return isContentContainingReflectionBox(this.content);
    },
    isReflectionsListEmpty() {
      return this.reflectionQuestions.length === 0;
    },
    subjectNodeId() {
      return this.subjectNode ? this.subjectNode.id : 0;
    },
    subjectName() {
      return this.subject ? this.subject.name : '';
    },
    subjectNodeName() {
      return this.subjectNode ? this.subjectNode.name : '';
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subject.possible_levels);
    },
  },
  watch: {
    focusedAnnotation(newAnnotation, oldAnnotation) {
      // When lose focus on a draft we need to unhighlight the draft
      if (oldAnnotation && !oldAnnotation.annotation.id) {
        this.ymRemoveMenu();
      }
    },
    annotations: {
      handler() {
        if (!this.highlightEnabled) return;
        const renderedIds = Object.keys(this.renderedHighlights);
        const currentIds = this.activeAnnotationsIds();
        const newIds = currentIds.filter(id => !renderedIds.includes(id));
        const deletedIds = renderedIds.filter(id => !currentIds.includes(id));

        this.removeHighlightsFromHtml(deletedIds);
        const newHighlights = this.generateHighlightInfos(newIds);
        this.showHighlightsInHtml(newHighlights);

        if (this.noteSearchParam && this.initialScrollToNote) {
          this.scrollToHighlight(this.noteSearchParam);
          this.initialScrollToNote = false;
        }
      },
      deep: true,
    },
    lastSoftDeletedId(id) {
      const annotationObj = this.annotations[id];

      // On the teacher book this method can be called for different sn ids
      if (!id || annotationObj.subjectNodeId !== this.subjectNodeId) {
        return;
      }
      if (annotationObj) {
        this.showDeleteToaster(annotationObj);
      }
    },
    reflectionQuestions: {
      handler() {
        if (this.isContentRendered) {
          this.renderStudentReflectionQuestions();
        }
      },
      deep: true,
    },
    subjectNodeId(newValue) {
      this.dispatchFetchAnnotations(newValue);
    },
  },
  created() {
    window.addEventListener('beforeunload', this.confirmBeforeUnload);

    if (this.highlightEnabled) {
      this.initHighlightMixin(this.addNewHighlight);
    }
    this.clearReflectionQuestions();
    this.clearFeedbackItems();
    if (this.isReflectionRenderRequired) {
      this.fetchReflectionQuestions(this.subjectNodeId);
    }
    this.fetchOpenEndedTasks(this.subjectNodeId);
    this.dispatchFetchAnnotations(this.subjectNodeId);
  },
  beforeUnmount() {
    // Clear any eventual focused annotation
    this.clearFocusedAnnotation();
    this.exerciseBoxesDestroyMethods.forEach(destroy => destroy());
    this.reflectionBoxesDestroyMethods.forEach(destroy => destroy());
    this.openEndedTasksDestroyMethods.forEach(destroy => destroy());
  },
  methods: {
    dispatchFetchAnnotations(subjectNodeId) {
      if (this.highlightEnabled) {
        this.fetchAnnotations(subjectNodeId);
      }
    },
    onContentHtmlRendered() {
      this.isContentRendered = true;
      this.renderOpenEndedTasks();
      this.renderStudentExerciseQuestions();
      this.renderStudentReflectionQuestions();
    },
    onContentAugmented() {
      this.isContentAltered = true;
      nextTick(() => {
        this.$emit('content-altered');
      });
    },
    getExerciseBoxesWithQuestionIds() {
      const boxesWithIds = [];
      const exerciseBoxes = this.$refs.root?.querySelectorAll('.exercise-box') ?? [];
      exerciseBoxes.forEach(box => {
        const questionIds = findExerciseQuestionIds(box);
        boxesWithIds.push({ box, questionIds });
      });
      return boxesWithIds;
    },
    getReflectionBoxesWithQuestionIds() {
      const boxesWithIds = [];
      const reflectionBoxes = this.$refs.root?.querySelectorAll('.reflection-box') ?? [];
      reflectionBoxes.forEach(box => {
        const questionIds = findExerciseQuestionIds(box);
        boxesWithIds.push({ box, questionIds });
      });
      return boxesWithIds;
    },
    async renderStudentExerciseQuestions() {
      const showAnswers = this.isInReviewMode;
      const nodeQuestions = await getExerciseQuestions({
        subjectNodeIds: [this.subjectNodeId],
        showAnswers,
      });
      const nodeQuestionsById = keyBy(nodeQuestions, 'id');
      this.getExerciseBoxesWithQuestionIds().forEach(({ box, questionIds }) => {
        const questionIdsForCurrentNode = questionIds.filter(
          questionId => questionId in nodeQuestionsById,
        );
        const questions = questionIdsForCurrentNode.map(
          questionId => nodeQuestionsById[questionId],
        );
        const title = box.querySelector('.exercise-box-title').innerText;
        const subTitle = box.querySelector('.exercise-box-subtitle').innerText;
        const { el, destroy } = mountComponent({
          component: ExerciseQuestionBox,
          props: {
            isInReviewMode: this.isInReviewMode,
            title,
            subTitle,
            subjectNodeId: this.subjectNodeId,
            questions,
            trackingProps: {
              subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNodeName}`,
              question_count: questions.length,
            },
          },
          appContext: this.$.appContext,
        });
        box.replaceWith(el);
        this.exerciseBoxesDestroyMethods.push(destroy);
      });

      this.isExerciseQuestionsRendered = true;
    },
    async renderStudentReflectionQuestions() {
      if (!this.isReflectionRenderRequired) {
        this.isReflectionQuestionsRendered = true;
        return;
      }

      if (this.isReflectionsListEmpty) {
        return;
      }

      await this.fetchExerciseOccasions({ subjectNodeId: this.subjectNodeId });

      const indexByQuestionId = (dict, question) => {
        // eslint-disable-next-line no-param-reassign
        dict[question.id] = question;
        return dict;
      };
      const reflectionQuestionsById = this.reflectionQuestions.reduce(indexByQuestionId, {});

      this.getReflectionBoxesWithQuestionIds().forEach(({ box, questionIds }) => {
        if (!questionIds || questionIds.length === 0) {
          return;
        }
        // only support one question per reflection box for now
        const questionId = questionIds[0];
        const questionToRender = reflectionQuestionsById[questionId];
        if (!questionToRender) {
          return;
        }
        const titleEl = box.querySelectorAll('.reflection-box-title')[0];

        const hasFeedback = questionToRender.feedback_item_ids?.length > 0;
        if (hasFeedback) {
          this.fetchFeedbackItems({
            studentUserId: this.user.id,
            subjectNodeId: this.subjectNodeId,
            questionId: questionToRender.id,
          });
        }
        const boxContent = box.querySelector('.reflection-box-content');
        boxContent.innerHTML = '';
        const { destroy } = mountComponent({
          component: ReflectionQuestion,
          props: {
            question: questionToRender,
            user: this.user,
            subjectClassId: this.classId,
            subjectId: this.subjectId,
            subjectNodeId: this.subjectNodeId,
            reflectionBoxTitle: titleEl.innerText,
            currentSubtopicText: this.currentSubtopicText,
            trackingProps: {
              subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNodeName}`,
            },
          },
          element: boxContent,
          appContext: this.$.appContext,
        });
        this.reflectionBoxesDestroyMethods.push(destroy);
      });

      this.isReflectionQuestionsRendered = true;
    },
    renderOpenEndedTasks() {
      (this.$refs.root?.querySelectorAll('kog-open-ended-task') ?? []).forEach(openEndedTask => {
        const openEndedTaskId = parseInt(openEndedTask.getAttribute('task-id'), 10);
        const { destroy } = mountComponent({
          component: OpenEndedTaskContainer,
          props: {
            openEndedTaskId,
            subjectNodeId: this.subjectNodeId,
            subjectClassId: this.classId,
            subjectId: this.subjectId,
          },
          element: openEndedTask,
          appContext: this.$.appContext,
        });
        this.openEndedTasksDestroyMethods.push(destroy);
      });
    },
    activeAnnotationsIds() {
      return Object.entries(this.annotations)
        .filter(([, a]) => a.subjectNodeId === this.subjectNodeId && !a.isSoftDeleted)
        .map(([id]) => id);
    },
    showDeleteToaster(annotationObj) {
      const isNote = annotationObj.annotation.note.length > 0;
      const toasterHtml = isNote ? 'Note deleted' : 'Highlight deleted';
      const postActionText = isNote ? 'Note restored!' : 'Highlight restored!';
      this.$toast.showSuccess(toasterHtml, {
        postActionText,
        actionText: 'Undo',
        time: 10000,
        toasterActionPerformed: () => {
          this.restoreDeletedHighlight(annotationObj);
        },
        toasterClosedNoActionPressed: () => {
          const { id } = annotationObj.annotation;
          this.deleteAnnotation(id);
          trackDeleteNote(
            { ...annotationObj, location: this.getLocation() },
            { source: 'textbook' },
          );
        },
      });
    },
    confirmBeforeUnload(e) {
      const softDeletedCount = Object.values(this.annotations).filter(a => a.isSoftDeleted).length;
      if (softDeletedCount) {
        this.trackUnloadWithSoftDeleted();
        e.preventDefault();
        const event = e;
        event.returnValue = '';
        return '';
      }
      return undefined;
    },
    getLocation() {
      return {
        subject_name: this.subjectName,
        subject_node_name: this.subjectNodeName,
      };
    },
    restoreDeletedHighlight(annotation) {
      this.revertSoftDeleteAnnotation(annotation.annotation.id);
      this.setFocusedAnnotation(annotation);
    },
    async createNewAnnotation(textQuote, locationHint, color = DEFAULT_HIGHLIGHT_COLOR) {
      const annotationObj = await this.getNewAnnotationTemplate();
      Object.assign(annotationObj.annotation, textQuote);
      annotationObj.subjectNodeId = this.subjectNodeId;
      annotationObj.location = this.getLocation();
      annotationObj.annotation.color = color;
      annotationObj.annotation.location_hint = locationHint;
      this.newAnnotation(annotationObj);
      this.setFocusedAnnotationUrl(this.subjectNode.url);
      return annotationObj;
    },
    async addNewHighlight(color, textQuote, locationHint) {
      this.clearFocusedAnnotation();
      const annotationObj = await this.createNewAnnotation(textQuote, locationHint, color);
      const autoOpenNotebook = studyAppLocalStorage.getAutoOpenNotebook();
      if (!this.bookRailSliders.notebook && (isNil(autoOpenNotebook) || autoOpenNotebook)) {
        this.openRailSlider('notebook');
      }
      this.saveHighlight().then(() => {
        this.startEditing();
      });
      trackSaveNote(annotationObj, { source: 'textbook' });
    },
    trackUnloadWithSoftDeleted() {
      this.$mixpanel.trackEvent('Private Annotations - Unload before delete', {
        ...this.getLocation(),
        source: 'textbook',
      });
    },
    async annotationClickHandler(event, context) {
      if (this.isSaving) {
        return;
      }

      const annotationId = context.customObject.annotation.id;

      if (!this.bookRailSliders.notebook) {
        this.openRailSlider('notebook');
      }
      this.clearFocusedAnnotation();
      await this.setFocusedAnnotationById(annotationId);
      this.startEditing();
    },
    getNodeUrl(node) {
      const route = {
        name: 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
      };
      return this.$router.resolve(route).href;
    },
    generateHighlightInfos(ids) {
      return ids.map(id => {
        const annotation = this.annotations[id];
        return {
          id: annotation.annotation.id,
          location: annotation.annotation,
          clickHandler: this.annotationClickHandler,
          object: annotation,
        };
      });
    },
    ...mapPiniaActions(useAnnotationStore, {
      clearFocusedAnnotation: 'clearFocusedAnnotation',
      fetchAnnotations: 'fetchAnnotations',
      getNewAnnotationTemplate: 'getNewAnnotationTemplate',
      newAnnotation: 'startNewAnnotation',
      setFocusedAnnotationById: 'setFocusedAnnotationById',
      setFocusedAnnotationUrl: 'setFocusedAnnotationUrl',
      saveHighlight: 'saveHighlight',
      restoreDeletedAnnotation: 'restoreDeletedAnnotation',
      deleteAnnotation: 'deleteAnnotation',
      startEditing: 'startEditing',
      revertSoftDeleteAnnotation: 'revertSoftDeleteAnnotation',
      setFocusedAnnotation: 'setFocusedAnnotation',
    }),
    ...mapActions('feedbackItemModule', ['fetchFeedbackItems', 'clearFeedbackItems']),
    ...mapActions('questionsModule', {
      clearReflectionQuestions: 'clearReflectionQuestions',
    }),
    ...mapActions('bookModule', {
      openRailSlider: 'openRailSlider',
    }),
    ...mapPiniaActions(useOpenEndedTaskStore, ['fetchOpenEndedTasks']),
    ...mapWaitingActions('exerciseOccasionsModule', {
      fetchExerciseOccasions: 'fetching_exercise_occasions',
    }),
    ...mapWaitingActions('questionsModule', {
      fetchReflectionQuestions: 'fetching_reflection_questions',
    }),
  },
};
</script>

<style scoped>
.AnnotateText--Content {
  height: 100%;
}

.AnnotateText--Content :deep(.exercise-box) {
  display: none;
}
</style>
