<template>
  <div>
    <fullpage-takeover-navbar
      :back-button-aria-label="backButtonAriaLabel"
      :header-text="`${subjectNodeFormattedNumber} ${subjectNodeName}`"
      @back-button-click="onBackButtonClick"
    />
    <kog-loader
      v-if="isLoading"
      class="ActivitySubmissions-loader"
      :loading="isLoading"
    />
    <template v-if="!isLoading">
      <activity-sheet-submissions
        v-if="feature.type === ACTIVITY_SHEET"
        :students-list="studentsList"
        :feature="feature"
      />
      <performance-task-submissions
        v-else-if="feature.type === PERFORMANCE_TASK"
        :students-list="studentsList"
        :feature="feature"
      />
    </template>
  </div>
</template>
<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import FullpageTakeoverNavbar from 'sharedApp/components/core/fullpage-takeover-navbar.vue';
import { FEATURES_ENUM } from 'sharedApp/const/features.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ActivitySheetSubmissions from 'studyApp/containers/activity-sheet/activity-sheet-submissions.vue';
import PerformanceTaskSubmissions from 'studyApp/containers/performance-task/performance-task-submissions.vue';
import { SUBJECT_CLASS_STUDENTS_MODULE } from 'studyApp/store/modules/subject-class-students.js';

export default {
  name: 'ActivitySubmissions',
  components: {
    PerformanceTaskSubmissions,
    ActivitySheetSubmissions,
    FullpageTakeoverNavbar,
    KogLoader,
  },
  mixins: [RoutesMixin],
  data() {
    return {
      ...FEATURES_ENUM,
    };
  },
  computed: {
    ...mapState({
      subjectMap: state => state.subjectModule.subjectMap,
      studentsList: state => state[SUBJECT_CLASS_STUDENTS_MODULE].studentsList,
    }),
    ...mapGetters({
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
    }),
    subjectNodeFormattedNumber() {
      return this.subjectMap[this.nodeId]?.formatted_number_including_ancestors ?? '';
    },
    subjectNodeName() {
      return this.subjectMap[this.nodeId]?.name ?? '';
    },
    isLoading() {
      return this.$wait.is('loading_features', 'loading_class_students');
    },
    feature() {
      return this.activityBySubjectNodeId[this.nodeId];
    },
    origin() {
      return this.$route.query.origin ?? '';
    },
    backButtonAriaLabel() {
      const navigationMessage = 'Click to go back to ';
      let destination;

      switch (this.origin) {
        case 'insights':
          destination = 'insights';
          break;
        case 'class_overview':
          destination = 'the class overview';
          break;
        case 'pt_overview':
          destination = 'performance task results';
          break;
        case 'book_section':
        default:
          destination = 'the book';
          break;
      }

      return `${navigationMessage}${destination}`;
    },
  },
  created() {
    this.fetchStudentsList(this.classId);
    this.fetchFeaturesAndMappings({ subjectNodeIds: [this.nodeId] });
  },
  methods: {
    ...mapWaitingActions('subjectNodeFeatureModule', {
      fetchFeaturesAndMappings: 'loading_features',
    }),
    ...mapWaitingActions(SUBJECT_CLASS_STUDENTS_MODULE, {
      fetchStudentsList: 'loading_class_students',
    }),
    onBackButtonClick() {
      switch (this.origin) {
        case 'insights':
          this.$router.push({
            name: 'teacherInsights',
            params: this.$route.params,
            query: { active_tab: this.$route.query.origin_active_tab },
          });
          break;
        case 'class_overview':
          this.$router.push({
            name: 'subjectClassOverview',
            params: this.$route.params,
            query: { node: this.$route.query.origin_node },
          });
          break;
        case 'pt_overview':
          this.$router.push({
            name: 'performanceTaskOverview',
            params: this.$route.params,
            query: {
              origin: this.$route.query.prev_origin,
              origin_node: this.$route.query.prev_origin_node,
            },
          });
          break;
        case 'book_section':
        default:
          this.$router.push({
            name: 'reviewMode',
            params: { ...this.$route.params },
          });
          break;
      }
    },
  },
};
</script>
<style scoped>
.ActivitySubmissions-loader {
  padding-top: var(--full-page-navbar-height);
}
</style>
