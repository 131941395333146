<template>
  <div>
    <div>
      <div class="padd-top-l padd-bottom-xs">
        <strong class="padd-right-m"> Sent </strong>
        <span>{{ assignmentSentAt }}</span>
      </div>
      <div class="padd-top-xs padd-bottom-xs">
        <strong class="padd-right-m"> Deadline </strong>
        <span class="padd-right-xxs">{{ deadline }}</span>
        <span
          v-if="isQuestionAssignment"
          :class="deadlineInfoClass"
        >
          {{ deadlineInWords }}
        </span>
        <button
          v-if="canBeEndedNow"
          class="KogButtonLegacy KogButtonLegacy--link KogButtonLegacy--s"
          @click.prevent="endAssignmentNow()"
        >
          End now
        </button>
        <span
          v-if="isQuestionAssignment && assignment.soft_deadline"
          class="muted padd-left-xxs"
        >
          (Late submission is allowed)
        </span>
      </div>
      <template v-if="assignment.is_sent_to_integration">
        <div class="flexContainer padd-top-xs padd-bottom-xs">
          <strong class="padd-right-m"> Points </strong>
          <kog-skeleton
            v-if="isLoadingIntegrationAssignment"
            height="24px"
            width="120px"
          />
          <span v-else>{{ integrationAssignmentPoints }}</span>
        </div>
        <div
          v-if="integrationAssignmentCategory"
          class="flexContainer padd-top-xs padd-bottom-xs"
        >
          <strong class="padd-right-m"> Assignment category </strong>
          <kog-skeleton
            v-if="isLoadingIntegrationAssignment"
            height="24px"
            width="120px"
          />
          <span v-else>{{ integrationAssignmentCategory }}</span>
        </div>
      </template>
      <div
        v-if="isESQAssignment"
        class="padd-top-xs padd-bottom-xs"
      >
        <strong class="padd-right-m"> Mark schemes </strong>
        <esq-markscheme-setting-info :mark-scheme-status="assignment.mark_scheme_status" />
        <span
          v-if="assignment.mark_scheme_status.is_unlocked"
          class="padd-left-s text-italic"
        >
          {{ unlockedMessage }}
        </span>
      </div>
    </div>

    <div v-show="isShowDetails">
      <div class="padd-top-xs padd-bottom-xs">
        <strong class="padd-right-m"> Created by </strong>
        <span>{{ assignment.created_by }}</span>
      </div>
      <div class="padd-top-xs padd-bottom-xs">
        <strong class="padd-right-m"> Recipients </strong>
        <span>{{ recipientClassesNames }}</span>
      </div>
      <div
        v-if="isQuestionAssignment"
        class="padd-top-xs padd-bottom-xs"
      >
        <strong class="padd-right-m">Solutions and score shown on submission:</strong>
        <span>{{ showScoreAndSolution }}</span>
      </div>
      <div class="padd-top-xs padd-bottom-xs">
        <strong class="padd-right-m">Instructions</strong>
        <span>{{ assignmentMessage }}</span>
      </div>
      <div
        v-if="isESQAssignment"
        class="padd-top-xs padd-bottom-xs"
      >
        <span class="kogPlatformRedDarken20 text-bold">Note:</span>
        We are not tracking completion or scores of exam-style questions in your student or class
        statistics.
      </div>
    </div>
    <button
      class="AssignmentDetailsInfo-toggleDetailsButton KogButtonLegacy KogButtonLegacy--subtleLink KogButtonLegacy--s"
      @click="toggleDetails"
    >
      {{ moreOrLessDetails }}
    </button>
  </div>
</template>

<script>
import distanceInWordsStrict from 'date-fns/distance_in_words_strict/index.js';
import { storeToRefs } from 'pinia';
import { mapGetters } from 'vuex';

import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogSkeleton from 'sharedApp/components/base/skeleton/kog-skeleton.vue';
import useAssignmentReadableSettings from 'sharedApp/composables/use-assignment-readable-settings.ts';
import {
  isExamStyleType,
  isQuestionType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import EsqMarkschemeSettingInfo from 'studyApp/components/teacher/assignments/esq-markscheme-setting-info.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';
import { isPossibleToEndNow } from 'teachApp/utils/assignment-utils.js';

export default {
  name: 'AssignmentDetailsInfo',
  components: {
    KogSkeleton,
    EsqMarkschemeSettingInfo,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    endAssignmentNow: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const { points, category, isLoading } = useIntegrationAssignment();
    const featureFlagStore = useFeatureFlagStore();
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);

    return {
      isMultipleClassAssignmentEnabled,
      integrationAssignmentPoints: points,
      integrationAssignmentCategory: category,
      isLoadingIntegrationAssignment: isLoading,
    };
  },
  data() {
    return {
      isShowDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    assignmentSentAt() {
      return !this.assignment.is_scheduled_to_send
        ? dateTimeFormatter(new Date(this.assignment.sent_at))
        : 'Scheduled';
    },
    deadline() {
      return dateTimeFormatter(new Date(this.assignment.deadline));
    },
    deadlineInWords() {
      return distanceInWordsStrict(this.now(), this.assignment.deadline, { addSuffix: true });
    },
    deadlineInfoClass() {
      return {
        kogPlatformRedDarken20: this.now() > new Date(this.assignment.deadline),
      };
    },
    canBeEndedNow() {
      return isPossibleToEndNow(this.assignment);
    },
    assignmentMessage() {
      return this.assignment.message ? this.assignment.message : 'None';
    },
    moreOrLessDetails() {
      return !this.isShowDetails ? '+ More details' : '- Less details';
    },
    showScoreAndSolution() {
      const { answers } = useAssignmentReadableSettings(this.assignment);
      let showScoreAndSolutionText = '';
      if (answers.displayAfterDeadline) {
        showScoreAndSolutionText = 'Yes, after deadline';
      } else if (answers.displayAfterSubmission) {
        showScoreAndSolutionText = 'Yes, after student has submitted';
      } else if (answers.displayImmediately) {
        showScoreAndSolutionText = 'Yes, after student has completed each question';
      }
      return showScoreAndSolutionText;
    },
    isQuestionAssignment() {
      return isQuestionType(this.assignment.assignment_type);
    },
    isESQAssignment() {
      return isExamStyleType(this.assignment.assignment_type);
    },
    isManualUnlock() {
      const markschemeUnlockedSetting = this.assignment.mark_scheme_status.unlocked_setting;
      return markschemeUnlockedSetting === 'MANUAL_UNLOCK';
    },
    isAutoUnlock() {
      const markschemeUnlockedSetting = this.assignment.mark_scheme_status.unlocked_setting;
      return markschemeUnlockedSetting === 'AUTO_UNLOCK';
    },
    unlockedMessage() {
      const markSchemeStatus = this.assignment.mark_scheme_status;
      if (this.isAutoUnlock) {
        const unlockedAt = dateTimeFormatter(
          new Date(markSchemeStatus.markscheme_unlocked_at),
          'at',
        );
        return `Kognity unlocked the mark scheme on ${unlockedAt}`;
      }
      if (this.isManualUnlock) {
        const unlockedAt = dateTimeFormatter(
          new Date(markSchemeStatus.markscheme_unlocked_at),
          'at',
        );
        return `${markSchemeStatus.markscheme_unlocked_by} unlocked the mark scheme on ${unlockedAt}`;
      }
      return '';
    },
    recipientClassesNames() {
      if (this.isMultipleClassAssignmentEnabled) {
        const recipientClassesNames = this.assignment.subject_classes.map(
          classId => this.subjectClassesById[classId].name,
        );
        return recipientClassesNames.join(', ');
      }
      return this.subjectClassesById[this.assignment.subject_classes[0]].name;
    },
  },
  methods: {
    toggleDetails() {
      this.isShowDetails = !this.isShowDetails;
    },
    now() {
      return new Date();
    },
  },
};
</script>
<style scoped>
.AssignmentDetailsInfo-toggleDetailsButton {
  padding-left: 0;
}
</style>
