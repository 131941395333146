<template>
  <transition :name="transitionName">
    <slot />
  </transition>
</template>

<script>
const TRANSITIONS = {
  DEFAULT: 'no-transition',
  SLIDE_FROM_RIGHT: 'slide-from-right',
  SLIDE_FROM_LEFT: 'slide-from-left',
};

export default {
  name: 'WizardPageSlide',
  props: {
    currentStepIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      transitionName: TRANSITIONS.DEFAULT,
    };
  },
  watch: {
    currentStepIndex(newStepIndex, oldStepIndex) {
      if (Number.isNaN(oldStepIndex)) {
        return;
      }

      if (newStepIndex > oldStepIndex) {
        this.transitionName = TRANSITIONS.SLIDE_FROM_RIGHT;
      } else {
        this.transitionName = TRANSITIONS.SLIDE_FROM_LEFT;
      }
    },
  },
};
</script>

<style scoped>
.slide-from-right-enter-from {
  will-change: transform;
  transform: translateX(100%);
}

.slide-from-right-enter-to,
.slide-from-left-enter-to {
  transform: translateX(0%);
}

.slide-from-left-enter-from {
  will-change: transform;
  transform: translateX(-100%);
}

.slide-from-right-leave-from,
.slide-from-left-leave-from {
  position: absolute;
  top: 0;
  transform: translateX(0%);
}

.slide-from-right-leave-to {
  position: absolute;
  top: 0;
  transform: translateX(-100%);
}

.slide-from-left-leave-to {
  position: absolute;
  top: 0;
  transform: translateX(100%);
}

.slide-from-right-enter-active,
.slide-from-right-leave-active,
.slide-from-left-enter-active,
.slide-from-left-leave-active {
  transition-duration: 0.35s;
  transition-property: transform;
}
</style>
