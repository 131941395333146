/*
A bottomInfoPlugin for the flatPickr component.

This flatPickr plugin shows information in the calendar popover's footer.

The plugin accepts a function as an argument. The function should return
 text or html.

The text is wrapped into div with KogDatetimePicker-bottomInfo css class.

Example:
    <flat-pickr
      v-model="date"
      :config="config"
      class="KogFormInput"
    />
    ...
    ...
    config: {
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      allowInput: true,
      defaultDate: new Date(),
      plugins: [
        new bottomInfoPlugin(() => 'example of text'),
      ],
    },
 */
const getBottomDiv = containerElement => {
  let bottomDiv = null;
  const cssClass = 'KogDatetimePicker-bottomInfo';
  const found = containerElement.getElementsByClassName(cssClass);
  if (found.length > 0) {
    [bottomDiv] = found;
  } else {
    bottomDiv = document.createElement('div');
    bottomDiv.classList.add(cssClass);
    containerElement.appendChild(bottomDiv);
  }
  return bottomDiv;
};

function bottomInfoPlugin(bottomTextHandler) {
  return fp => ({
    onOpen() {
      const bottomDiv = getBottomDiv(fp.calendarContainer);
      bottomDiv.innerHTML = bottomTextHandler();
    },
  });
}

export default bottomInfoPlugin;
