import { Plugin } from '@ckeditor/ckeditor5-core';

/**
 * This plugin is used to override the data the ckeditor5-math plugin uses in its upcast.
 * Reason being that the browser always interprets text before mathjax hence it reads
 * "<x" as a tag and breaks mathjax output.
 *
 * Old math plugin in CK4 used to encode/decode the values when working with them
 * but since we can't override what this plugin works with we space the upcast data to prevent
 * browser from interpreting it as a tag.
 *
 * https://docs.mathjax.org/en/v2.7-latest/tex.html#tex-and-latex-in-html-documents
 */
export default class MathOverrideEditing extends Plugin {
  init() {
    this.editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        classes: 'math-tex',
      },
      model: {
        key: 'mathtex-inline',
        value: viewElement => {
          const child = viewElement.getChild(0);
          if (child.data && typeof child.data === 'string') {
            const spacedEquation = child.data
              .replaceAll(/(<|>)(?!\s)/g, '$1 ') // space lt|gt not followed by a whitespace
              .replaceAll(/(?<!\s)(<|>)/g, ' $1'); // space lt|gt not preceded by a whitespace

            // eslint-disable-next-line no-underscore-dangle
            child._textData = spacedEquation;
          } else {
            // eslint-disable-next-line no-underscore-dangle
            viewElement._removeClass('math-tex');
          }
          return null;
        },
      },
      converterPriority: 'highest',
    });
  }
}
