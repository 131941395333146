<template>
  <kog-card
    ref="root"
    class="margin-top-xs"
    :default-padding="false"
  >
    <div class="flexContainer">
      <div
        class="flexContainer flexContainer-column padd-top-l padd-bottom-l padd-left-l padd-right-l divider-right, ReflectionResponseCard--leftColumn"
      >
        <div class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter">
          <div class="heading-xs flexChild-canGrow">
            <kog-avatar
              :src="student.avatar_url"
              :name="student.name"
              size="l"
              theme="accent"
              class="padd-right-s"
            />
            {{ student.name }}
          </div>
          <kog-tag
            :is-chip="true"
            :context="answerStatusTagText(true)"
            :label="answerStatusTagText(false)"
            :type="tagType"
          />
        </div>
        <div>
          <div
            v-if="isAnswerDone"
            class="padd-top-l whitespace-pre-wrap"
          >
            {{ answerText }}
          </div>
          <reflection-response-feedback-item
            v-for="feedbackItem in feedbackItemsWithDefault"
            :key="feedbackItem.id"
            :teacher-id="user.id"
            :student="student"
            :class-id="classId"
            :feedback-item="feedbackItem"
            @trigger-save-feedback="$emit('trigger-save-feedback', $event)"
          />
        </div>
      </div>
      <div class="flexContainer flexContainer-column flexChild-canGrow padd-l">
        <div
          class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter"
          :class="{ 'padd-bottom-m': !isShowingNoteInput }"
        >
          <kog-tag
            is-chip
            icon-class="fa-note"
            label="Teacher notes"
            size="m"
            type="basic"
            class="text-bold"
          />
          <kog-tag
            v-if="hasAnswer"
            :is-chip="true"
            :type="isFlaggedByTeacher ? 'warning' : 'default'"
            :label="isFlaggedByTeacher ? 'Flagged' : 'Flag'"
            icon-class="fa-flag"
            @click="toggleFlaggedByTeacher(student.user_id)"
          />
        </div>
        <div>
          <kog-button
            v-if="!isShowingNoteInput"
            :has-icon="true"
            icon-class="fa-plus"
            label="Add notes"
            button-style="basic"
            :aria-label="`Add notes to ${student.name}`"
            @click="onAddNoteClick"
          />
          <kog-textarea
            v-else
            v-model:value="noteText"
            label="My notes"
            :is-label-hidden="true"
            class="ReflectionResponseCard--note margin-top-xs"
            style-type="prominent"
            :fit-to-content="true"
            :is-autosave-enabled="true"
            :save-request$="saveNoteRequest$"
            :max-length="maxNoteAndFeedbackLength"
            @trigger-save="$emit('trigger-save-note', $event)"
          />
          <div
            v-for="note in otherNotes"
            :key="note.teacher_user.id"
            class="margin-top-l"
          >
            <kog-avatar
              :src="note.teacher_user.avatar_url"
              :name="`${note.teacher_user.first_name} ${note.teacher_user.last_name}`"
              size="s"
              theme="accent"
              class="padd-right-xs"
            />
            <span
              class="heading-xxs"
              aria-hidden="true"
            >
              {{ note.teacher_user.first_name }}
              {{ note.teacher_user.last_name }}
            </span>
            <kog-textarea
              v-model:value="note.text"
              :label="`${note.teacher_user.first_name} ${note.teacher_user.last_name}`"
              :is-label-hidden="true"
              class="ReflectionResponseCard--note margin-top-xs"
              style-type="prominent"
              :fit-to-content="true"
              :disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
  </kog-card>
</template>

<script>
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
import { mapActions } from 'vuex';

import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { dateTimeFormatter, humanUnderstandableDateTime } from 'sharedApp/utils/time-utils.js';

import ReflectionResponseFeedbackItem from './reflection-response-feedback-item.vue';

export default {
  name: 'ReflectionResponseCard',
  components: {
    KogAvatar,
    KogButton,
    KogCard,
    KogTextarea,
    KogTag,
    ReflectionResponseFeedbackItem,
  },
  mixins: [RoutesMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
      default: () => {},
    },
    feedbackItems: {
      type: Array,
      default: () => [],
    },
    notes: {
      type: Array,
      default: () => [],
    },
    question: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['updateFilters', 'trigger-save-feedback', 'trigger-save-note'],
  data() {
    return {
      noteText: '',
      isAddNoteClicked: false,
      debouncedUpdateFilters: debounce(() => this.$emit('updateFilters'), 1000),
      maxNoteAndFeedbackLength: 2000,
      saveNoteRequest$: new Subject(),
    };
  },
  computed: {
    feedbackItemsWithDefault() {
      return this.feedbackItems.length > 0 ? this.feedbackItems : [{}];
    },
    userId() {
      return this.student?.user_id;
    },
    ownNote() {
      if (!this.notes) {
        return null;
      }
      return this.notes.find(note => note.teacher_user.id === this.user.id);
    },
    hasOwnNote() {
      return Boolean(this.ownNote);
    },
    otherNotes() {
      if (!this.notes) {
        return null;
      }
      return this.notes.filter(note => note.teacher_user.id !== this.user.id);
    },
    isShowingNoteInput() {
      return this.hasOwnNote || this.isAddNoteClicked;
    },
    answer() {
      return this.answers?.[this.userId]?.[0];
    },
    hasAnswer() {
      return this.answer !== undefined;
    },
    answerText() {
      return this.answer?.userAnswer || '';
    },
    tagType() {
      if (this.isAnswerDone) {
        return 'positive';
      }
      return 'informative';
    },
    isAnswerDone() {
      return this.answer?.status && this.answer.status === 'DONE';
    },
    isFlaggedByTeacher() {
      return this.answer?.flaggedByTeacher === true;
    },
    mixPanelData() {
      return {
        teacher_id: this.user.id,
        subject_class_id: this.classId,
      };
    },
  },
  mounted() {
    this.setNoteText();
  },
  methods: {
    ...mapActions({
      updateExerciseOccasion: 'exerciseOccasionsModule/updateExerciseOccasion',
      updateUseReflectionQuestionProgressCache:
        'teacherInsightsModule/updateUseReflectionQuestionProgressCache',
    }),
    onAddNoteClick() {
      this.isAddNoteClicked = true;
      const name = 'Reflection Question Feedback - Add note';
      this.$mixpanel.trackEvent(name, this.mixPanelData);
    },
    answerStatusTagText(isForScreenReader = false) {
      if (!this.isAnswerDone) {
        return 'Not submitted';
      }
      const { doneAt } = this.answer;
      if (isForScreenReader) {
        const friendlyDate = humanUnderstandableDateTime(doneAt);
        return `Submitted on ${friendlyDate}`;
      }
      const friendlyDate = dateTimeFormatter(new Date(doneAt));
      return `Submitted ${friendlyDate}`;
    },
    toggleFlaggedByTeacher() {
      const newFlaggedStatus = !this.answer.flaggedByTeacher;
      this.updateExerciseOccasion({
        subjectNodeId: this.nodeId,
        ltqId: this.question.id,
        flaggedByTeacher: newFlaggedStatus,
        studentId: this.userId,
        operation: 'submit',
        practiceItemId: this.answer.practiceItemId,
        subjectClassId: this.classId,
      }).then(this.debouncedUpdateFilters);
      this.updateUseReflectionQuestionProgressCache({
        useCache: false,
      });
      const name = 'Reflection Question Feedback - Toggle flag';
      this.$mixpanel.trackEvent(name, {
        ...this.mixPanelData,
        new_value: newFlaggedStatus,
      });
    },
    setNoteText() {
      if (this.hasOwnNote) {
        this.noteText = this.ownNote.text;
      }
    },
  },
};
</script>

<style scoped>
.ReflectionResponseCard--singleColumn {
  width: 100%;
}

.ReflectionResponseCard--leftColumn {
  width: 67%;
}

.ReflectionResponseCard--feedback :deep(textarea) {
  max-height: 200px;
}

.ReflectionResponseCard--note :deep(textarea) {
  max-height: 150px;
}
</style>
