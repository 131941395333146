import mixpanelModule from 'sharedApp/libs/mixpanel.js';

let mixpanelDefaultProperties = {};

function trackAudioEvent(event, additionalProperties) {
  // eslint-disable-next-line camelcase
  const { subject_name, subject_node_name } = mixpanelDefaultProperties;
  const properties = {
    audio_type: 'HTML',
    ...additionalProperties,
    subject_name,
    section_name: subject_node_name,
  };
  mixpanelModule.trackEvent(`Audio - ${event}`, properties);
}

function attachAudioListener(audio) {
  const duration = Math.round(audio.duration);
  const properties = {
    duration,
  };
  audio.addEventListener('play', () => {
    trackAudioEvent('play', properties);
  });
  audio.addEventListener('pause', () => {
    const currentTime = Math.round(audio.currentTime);
    if (currentTime !== duration) {
      trackAudioEvent('paused', properties);
    }
  });
  audio.addEventListener('ended', () => {
    trackAudioEvent('finished', properties);
  });
}

function attachListenersToAudio(domElement, mixpanelContext = {}) {
  mixpanelDefaultProperties = mixpanelContext;
  const audios = domElement.getElementsByTagName('audio');
  // eslint-disable-next-line no-restricted-syntax
  for (const audio of audios) {
    audio.addEventListener('loadedmetadata', () => attachAudioListener(audio));
  }
}

export default attachListenersToAudio;
