<template>
  <div class="padd-left-l padd-right-l padd-top-xxl flexContainer flexContainer-column">
    <h1 class="heading-xl"> Insights </h1>
    <kog-loader :loading="tabs.length === 0">
      <div class="positionRelative">
        <kog-tabs
          v-model:value="tab"
          class="flexContainer-flexStart margin-top-m"
          :has-transparent-background="true"
          :tabs="tabs"
          heading-size="xs"
          @input="onUserSelectTab"
        />
      </div>
      <reflection-questions-tab
        v-if="tab === tabNames.reflectionQuestions"
        :tab-name="tabNames.reflectionQuestions"
        :is-fetching-data="isFetchingData"
        :is-loading-table-content="isLoadingReflectionQuestionTableContent"
        :subject-class="subjectClass"
        :students-list="studentsList"
        :subject-tree="validSubjectTree"
      />
      <last-activity-tab
        v-else-if="tab === tabNames.lastActivity"
        :students="studentsList"
        :tab-name="tabNames.lastActivity"
      />
      <textbook-tab
        v-else-if="tab === tabNames.textbook"
        :tab-name="tabNames.textbook"
        :is-fetching-data="isFetchingData"
        :is-loading-table-content="isLoadingTextbookTableContent"
        :students-list="studentsList"
        :subject-class="subjectClass"
        :subject-tree="validSubjectTree"
        :education-system="educationSystem"
        @apply-filters="fetchBookProgress"
      />
      <questions-tab
        v-else-if="tab === tabNames.questions"
        :tab-name="tabNames.questions"
        :is-fetching-data="isFetchingData"
        :is-loading-table-content="isLoadingQuestionsTableContent"
        :students-list="studentsList"
        :subject-class="subjectClass"
        :subject-tree="validSubjectTree"
        :education-system="educationSystem"
        :user="user"
        @apply-filters="fetchQuestionProgress"
      />
    </kog-loader>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogTabs from 'sharedApp/components/base/tabs/kog-tabs.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import LastActivityTab from 'studyApp/components/insights/last-activity-tab.vue';
import QuestionsTab from 'studyApp/components/insights/questions-tab.vue';
import ReflectionQuestionsTab from 'studyApp/components/insights/reflection-questions-tab.vue';
import TextbookTab from 'studyApp/components/insights/textbook-tab.vue';

export default {
  name: 'TeacherInsights',
  components: {
    KogLoader,
    QuestionsTab,
    LastActivityTab,
    TextbookTab,
    ReflectionQuestionsTab,
    KogTabs,
  },
  mixins: [RoutesMixin],
  data() {
    return {
      tab: '',
      tabs: [],
      tabNames: {
        reflectionQuestions: 'reflections',
        textbook: 'textbook',
        questions: 'questions',
        lastActivity: 'last_activity',
      },
    };
  },
  computed: {
    ...mapState({
      studentsList: state => state.teacherInsightsModule.studentsList,
      subjectTree: state => state.teacherInsightsModule.subjectTree,
      subjectClass: state => state.teacherInsightsModule.subjectClass,
      subjectReflectionSections: state => state.teacherInsightsModule.subjectReflectionSections,
      educationSystem: state => state.teacherInsightsModule.educationSystem,
      user: state => state.userModule.user,
      userProfile: state => state.teacherDetailModule.userProfile,
    }),
    ...mapGetters('subjectClassModule', ['isNodeVisible']),
    isFetchingData() {
      return this.$wait.is([
        'fetching_students',
        'fetching_subject_details',
        'fetching_subject_class',
        'fetching_subject_reflection_sections',
      ]);
    },
    hasReflectionQuestions() {
      return this.subjectReflectionSections && this.subjectReflectionSections.length > 0;
    },
    isLoadingReflectionQuestionTableContent() {
      return this.$wait.is('fetching_reflection_question_practice_items');
    },
    isLoadingQuestionsTableContent() {
      return this.$wait.is('fetching_question_progress');
    },
    isLoadingTextbookTableContent() {
      return this.$wait.is('fetching_book_progress');
    },
    validSubjectTree() {
      if (this.subjectTree.children) {
        const subjectTree = cloneDeep(this.subjectTree);
        subjectTree.children = subjectTree.children.filter(topic => this.isNodeVisible(topic.id));
        return subjectTree;
      }
      return this.subjectTree;
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.onRouteChange();
      },
      immediate: true,
    },
    tab() {
      this.onTabSelected();
    },
  },
  async created() {
    this.fetchStudentsList(this.classId);
    this.fetchSubjectClass(this.classId);
    this.fetchReflectionQuestionProgress({ subjectClassId: this.classId });
    this.fetchBookProgress({
      subjectClassId: this.classId,
    });
    this.fetchQuestionProgress({
      subjectClassId: this.classId,
    });

    await Promise.all([
      this.fetchSubjectDetails(this.subjectId),
      this.fetchSubjectReflectionQuestionSections({ subjectId: this.subjectId }),
    ]);
    this.initTabs();
  },
  mounted() {
    this.trackOpenInsights();
  },
  methods: {
    ...mapWaitingActions('teacherInsightsModule', {
      fetchStudentsList: 'fetching_students',
      fetchSubjectDetails: 'fetching_subject_details',
      fetchQuestionProgress: 'fetching_question_progress',
      fetchSubjectClass: 'fetching_subject_class',
      fetchBookProgress: 'fetching_book_progress',
      fetchReflectionQuestionProgress: 'fetching_reflection_question_practice_items',
      fetchSubjectReflectionQuestionSections: 'fetching_subject_reflection_sections',
    }),
    onRouteChange() {
      const activeTab = this.$route.query.active_tab;
      if (activeTab && activeTab !== this.tab) {
        this.tab = activeTab;
      }
    },
    onTabSelected() {
      const tabName = this.tab;
      if (this.$route.query?.active_tab !== tabName) {
        this.$router.push({ query: { active_tab: tabName } });
      }
    },
    onUserSelectTab(tabName) {
      this.$mixpanel.trackEvent('Insights - Click tab', {
        tabName,
      });
    },
    initTabs() {
      const tabs = [];
      let defaultTab = this.tabNames.textbook;

      if (this.hasReflectionQuestions) {
        defaultTab = this.tabNames.reflectionQuestions;
        tabs.push({
          name: this.tabNames.reflectionQuestions,
          label: 'Reflections',
        });
      }

      tabs.push(
        ...[
          {
            name: this.tabNames.textbook,
            label: 'Textbook',
          },
          {
            name: this.tabNames.questions,
            label: 'Questions',
          },
          {
            name: this.tabNames.lastActivity,
            label: 'Last activity',
            newDot: {
              key: 'dot-insights-last-activity',
              horizontalOffset: 85,
              verticalOffset: -4,
              tooltipText:
                'From now on, you’ll find Class Overview here, with a new name and a slightly new look',
            },
          },
        ],
      );

      const isAnyTabAlreadySelected = !!this.tab;
      if (!isAnyTabAlreadySelected) {
        this.tab = defaultTab;
      }
      this.tabs = tabs;
    },
    trackOpenInsights() {
      this.$event.track('load_insights', {
        subject_id: this.subjectId,
        subject_class_id: this.classId,
      });
      intercomModule.trackEvent('entered-teacher-insights');
      this.$mixpanel.trackEventViaBackend('Insights - Open Insights page');
    },
  },
};
</script>

<style scoped>
.TeacherInsights-infoIcon {
  color: var(--kog-text-brand);
}
</style>
