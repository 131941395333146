import fetchIntercomScript from 'sharedApp/libs/fetch-intercom-script.js';

const BASE_INTERCOM_OFFSET_PX = 24;

const intercomModule = {
  loaded: false,
  start(user) {
    const studentAndNoCommunication =
      user.isStudent() && !user.school.is_student_communication_enabled;
    if (studentAndNoCommunication || user.isDemo()) {
      return;
    }

    const intercomAppId = window.KOG.INTERCOM_APP_ID;
    const hasRequiredUserProperties = !!user.intercom_id || !!user.email;

    if (intercomAppId && hasRequiredUserProperties) {
      fetchIntercomScript();
      window.Intercom('boot', {
        app_id: intercomAppId,
        user_id: user.intercom_id,
        user_hash: user.intercom_user_hash,
        email: user.email,
        hide_default_launcher: false,
      });
      this.loaded = true;
    }
  },
  stop() {
    if (this.loaded) {
      window.Intercom('shutdown');
    }
    this.loaded = false;
  },
  updateRoute() {
    if (this.loaded) {
      window.Intercom('update');
    }
  },
  trackEvent(eventName) {
    if (this.loaded) {
      window.Intercom('trackEvent', eventName);
    }
  },
  showNewMessage() {
    if (this.loaded) {
      window.Intercom('showNewMessage');
    }
  },
  updateLauncherPosition(horizontalPadding, verticalPadding) {
    if (this.loaded) {
      window.Intercom('update', {
        horizontal_padding: horizontalPadding,
        vertical_padding: verticalPadding,
      });
    }
  },
  updateLauncherVerticalPadding(offsetProperties) {
    if (!this.loaded) {
      return;
    }

    let verticalPadding = 0;
    if (offsetProperties) {
      offsetProperties.forEach(property => {
        if (property.condition) {
          verticalPadding += property.height;
        }
      });
    }
    if (verticalPadding > 0) {
      verticalPadding += BASE_INTERCOM_OFFSET_PX;
    }
    this.updateLauncherPosition(0, verticalPadding);
  },
  updateIsVisible(isVisible) {
    if (this.loaded) {
      window.Intercom('update', {
        hide_default_launcher: !isVisible,
      });
    }
  },
};

export default intercomModule;
