<template>
  <div
    v-if="shouldShowContentBox"
    :class="['content-editable', classAttr]"
    :style="styleAttr"
  >
    <content-box
      :template="content.template"
      :content="content.content_html"
    />
  </div>
</template>

<script>
import ContentBox from 'learning/common/components/content-box.vue';

import useStyleClassExtractedAttrs from 'sharedApp/composables/use-style-class-extracted-attrs.ts';

export default {
  name: 'ActivityContentBoxBlock',
  components: {
    ContentBox,
  },
  inheritAttrs: false,
  props: {
    content: {
      type: Object,
      required: true,
    },
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const extractedAttrs = useStyleClassExtractedAttrs();

    return {
      ...extractedAttrs,
    };
  },
  computed: {
    shouldShowContentBox() {
      const { is_teacher_only: isTeacherOnly } = this.content.template;
      return !isTeacherOnly || this.isInReviewMode;
    },
  },
};
</script>
