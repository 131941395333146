import { useSubscription } from '@vueuse/rxjs';
import { concatMap, debounceTime, fromEvent, merge, Subject, take, takeUntil } from 'rxjs';

const SAVE_DEBOUNCE_TIME = 1200;

const TextAutosave = {
  props: {
    /**
     * Specify observable responsible for triggering "save"
     * api calls both from inside and outside of this component
     */
    saveRequest$: {
      type: Object,
      default: null,
    },
    /**
     * Specify to toggle autosave functionality
     */
    isAutosaveEnabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['trigger-save'],
  data() {
    return {
      textInput$: null,
      textBlur$: null,
      destroyed$: new Subject(),
    };
  },
  beforeUnmount() {
    this.destroyed$.next();
  },
  methods: {
    initObservableStreams(domElement) {
      if (!this.isAutosaveEnabled) {
        return;
      }

      this.textInput$ = fromEvent(domElement, 'input');
      this.textBlur$ = fromEvent(domElement, 'blur');

      // everytime the textarea receives focus
      useSubscription(
        fromEvent(domElement, 'focus')
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            // start listening to text input
            this.textInput$
              .pipe(
                // end listening to text input when the textarea loses focus;
                // also causes the outer Observable to emit instantly when that happens
                takeUntil(merge(this.textBlur$, this.destroyed$)),
                // if textarea still has focus, emit after a period of silence (nothing typed)
                debounceTime(SAVE_DEBOUNCE_TIME),
              )
              .subscribe(this.onTextSaveRequest);
          }),
      );
      useSubscription(
        this.saveRequest$
          .pipe(
            concatMap(source => this.saveText$(source)),
            takeUntil(this.destroyed$),
          )
          .subscribe(() => {}),
      );
    },
    onTextSaveRequest() {
      this.saveRequest$.next('input');
    },
    saveText$(source) {
      const save$ = new Subject();
      const saveCompleteCallback = () => {
        save$.next(true);
        save$.complete();
      };

      /**
       * Emits new value after the previous save operation completes
       *
       * @event trigger-save
       * @property {Object}
       *  source: can be 'draft' or 'submit'
       *  value: the text value to be saved
       *  saveCompleteCallback: must be called by the entity performing the API call,
       * after it completes.
       */
      this.$emit('trigger-save', { source, value: this.value, saveCompleteCallback });
      return save$.pipe(take(1)).asObservable();
    },
  },
};

export default TextAutosave;
