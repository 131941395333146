<template>
  <div class="QuizQuestions width-100">
    <div
      class="QuizQuestions-questionContainer flexContainer flexChild-canGrow flexContainer-column"
    >
      <div class="flexContainer flexContainer-row">
        <div class="margin-top-xl margin-bottom-xl flexChild-canGrow flexChild-center">
          <kog-ball-step-paginator
            :is-clickable="!$wait.is('question-navigation-disabled')"
            :number-of-pages="quizQuestions.length"
            :current-page="displayedCurrentQuestionNumber"
            :is-step-done="isQuestionAnsweredByNumber"
            :is-step-correct="isQuestionCorrectByNumber"
            @change-page="setCurrentQuestion"
          />
        </div>
        <kog-round-button
          class="QuizQuestions-closeButton flexChild-noGrow flexChild-center"
          aria-label="Close quiz"
          icon-class="fa-xmark"
          button-style="default"
          @click="$emit('quiz-closed')"
        />
      </div>

      <kog-loader
        class="flexContainer flexContainer-column"
        :loading="$wait.is('submitting-quiz-occasion')"
        loading-msg="Checking your answers..."
      >
        <kog-card
          v-if="!isQuizSubmitted"
          class="QuizQuestions-card flexChild-center"
          :default-padding="false"
        >
          <div class="padd-l">
            <div
              class="flexContainer flexContainer-alignCenter flexContainer-spaceBetween padd-bottom-l"
            >
              <h2 class="heading-s"> Question {{ displayedCurrentQuestionNumber }} </h2>
              <div class="flexContainer">
                <question-level-labels
                  v-if="shouldShowLevelsInfo"
                  :levels="currentQuestion.attributes.levels"
                  :relevant-group="subjectClassLevelGroup"
                />
                <question-tags
                  v-if="subject"
                  :tags="currentQuestionTags"
                  :show-difficulty="showDifficulty"
                  :subject-id="subject.id"
                />
              </div>
            </div>
            <div v-if="!isReviewMode">
              <multiple-choice-question
                v-if="isMCQ"
                :question="currentQuestion"
                :multi-select="currentQuestion.question_context.is_multi_select"
                :choices="MCQChoices"
                :choice-state="userMCQChoiceState"
                :disabled="
                  isCurrentQuestionAnswered || $wait.is('submitting-quiz-question-response')
                "
                @input="setMCQChoice"
              />
              <div
                v-if="isSTQ"
                class="ShortTextQuestionAnswer-STQInputContainer"
              >
                <short-text-question
                  :question="currentQuestion"
                  :initial-answer="userSTQText"
                  :disabled="isCurrentQuestionAnswered"
                  :is-correct="isCurrentQuestionAnswered && isCurrentQuestionCorrect"
                  :is-incorrect="isCurrentQuestionAnswered && !isCurrentQuestionCorrect"
                  @input="setSTQAnswer"
                  @submit-answer="submitQuestionResponse"
                />
              </div>
              <fill-in-the-blanks-question
                v-if="isFBQ"
                :key="currentQuestion.id"
                :question="currentQuestion"
                :register-fill-blanks-methods="setFBQFillBlanksMethods"
                :user-answers="userFBQAnswers"
                :disable-user-input="isCurrentQuestionAnswered"
                :display-input-index="isCurrentQuestionAnswered"
                @input="setFBQAnswers"
              />
              <kog-loader
                class="flexContainer flexContainer-center padd-top-l"
                :loading="$wait.is('submitting-quiz-question-response')"
                loading-msg="Checking your answer..."
              >
                <kog-button
                  v-if="!isCurrentQuestionAnswered"
                  class="QuizQuestions-interactButton"
                  button-style="primary"
                  label="Submit answer"
                  size="large"
                  :disabled="!isAllowedToSubmit"
                  @click="submitQuestionResponse"
                />
                <kog-button
                  v-else-if="isLastQuestion"
                  class="QuizQuestions-interactButton"
                  button-style="primary"
                  size="large"
                  label="Complete quiz"
                  :disabled="!allQuestionsAnswered"
                  :tooltip="!allQuestionsAnswered ? 'Please answer all questions' : ''"
                  @click="submitQuiz"
                />
                <kog-button
                  v-else
                  class="QuizQuestions-interactButton"
                  button-style="primary"
                  label="Next question"
                  size="large"
                  @click="nextQuestion"
                />
              </kog-loader>
            </div>
            <div v-else>
              <question-preview :question="currentQuestion" />
              <div class="flexContainer flexContainer-spaceBetween">
                <kog-button
                  label="Previous"
                  button-style="primary"
                  :has-icon="true"
                  icon-class="fa-chevron-left"
                  :disabled="currentQuestionIndex === 0"
                  @click="previousQuestion"
                />
                <kog-button
                  label="Next"
                  :has-icon="true"
                  icon-class="fa-chevron-right"
                  icon-position="right"
                  button-style="primary"
                  :disabled="isLastQuestion"
                  @click="nextQuestion"
                />
              </div>
            </div>
          </div>
        </kog-card>
        <quiz-completed
          v-else
          :total-questions="quizQuestions.length"
          :total-correct-answers="
            quizQuestionResponses.filter(resp => resp.answered_correctly).length
          "
        />
        <transition name="fade">
          <kog-card
            v-if="isCurrentQuestionAnswered && !isQuizSubmitted && !isReviewMode"
            class="QuizQuestions-solutionCard QuizQuestions-card margin-top-xs flexChild-center"
            :default-padding="false"
          >
            <div
              v-if="isMCQ"
              class="padd-l"
            >
              <h2 class="heading-s">
                Correct {{ pluralize('Answer', MCQCorrectChoices.length) }}
              </h2>
              <div
                v-for="correctChoiceId in MCQCorrectChoices"
                :key="correctChoiceId"
              >
                <mcq-choice-item
                  class="margin-top-s"
                  :option-index="MCQChoices.findIndex(choice => choice.id === correctChoiceId)"
                  :choice-id="correctChoiceId"
                  :is-solution="false"
                  :is-selected="true"
                  :is-correct="true"
                  :is-incorrect="false"
                  :choice-html="
                    MCQChoices.find(choice => choice.id === correctChoiceId).answer_html
                  "
                  type="normal"
                  :disabled="true"
                />
                <h3 class="margin-top-m heading-xs"> Explanation </h3>
                <content-container
                  :content="currentQuestionExplanation.choice_explanations[correctChoiceId]"
                  class="margin-top-s content-editable divider-bottom"
                />
              </div>
            </div>
            <div
              v-else-if="isSTQ"
              class="padd-l"
            >
              <h2 class="heading-s">
                {{ pluralize('Answer', currentQuestionSolution.answer_list.length) }}
              </h2>
              <div class="padd-top-s">
                <kog-short-text-question-correct-answers
                  :correct-answers="currentQuestionSolution.answer_list"
                  :secondary-accepted-answers="currentQuestionSolution.secondary_accepted_answers"
                  :answer-explanation-html="currentQuestionExplanation"
                />
              </div>
            </div>
            <div
              v-else-if="isFBQ"
              class="padd-l"
            >
              <h2 class="heading-s">
                {{ pluralize('Answer', STQSolution.length) }}
              </h2>
              <div class="padd-top-s">
                <fill-in-the-blanks-solution
                  :solution="STQSolution"
                  :question-html="currentQuestion.question_html"
                />
              </div>
            </div>
          </kog-card>
        </transition>
      </kog-loader>
      <div
        v-if="!isQuizSubmitted"
        class="flexContainer margin-top-l"
      >
        <report-feedback-button
          v-if="currentQuestion.id"
          :subject-node-id="nodeId"
          :content-id="currentQuestion.id"
          label="Report feedback on question"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import isNil from 'lodash/isNil.js';
import shuffle from 'lodash/shuffle.js';
import pluralize from 'pluralize';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import QuestionLevelLabels from 'learning/common/components/question-level-labels.vue';
import ReportFeedbackButton from 'learning/common/components/report-feedback-button.vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogBallStepPaginator from 'sharedApp/components/base/pagination/kog-ball-step-paginator.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import ContentContainer from 'sharedApp/components/content/content-container.vue';
import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import FillInTheBlanksSolution from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-solution.vue';
import {
  getSelectedChoiceIds,
  setChoiceItemState,
} from 'sharedApp/components/multiple-choice-question/choice-state-helper.js';
import McqChoiceItem from 'sharedApp/components/multiple-choice-question/mcq-choice-item.vue';
import MultipleChoiceQuestion from 'sharedApp/components/multiple-choice-question/multiple-choice-question.vue';
import KogShortTextQuestionCorrectAnswers from 'sharedApp/components/questions/kog-short-text-question-correct-answers.vue';
import ShortTextQuestion from 'sharedApp/components/short-text-question/short-text-question.vue';
import { IGCSE_LEVELS } from 'sharedApp/const/level-groups.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { shouldShowDifficultyTagForSubject } from 'sharedApp/services/featureflags/fixed-feature-flags.ts';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import {
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import QuestionPreview from 'studyApp/components/questions/question-preview.vue';
import QuestionTags from 'studyApp/components/questions/question-tags.vue';
import QuestionDifficultyMixin from 'studyApp/mixins/question-difficulty-mixin.js';
import QuizCompleted from 'studyApp/pages/features/quiz/quiz-completed.vue';

export default {
  name: 'QuizQuestions',
  components: {
    KogRoundButton,
    QuizCompleted,
    KogBallStepPaginator,
    ContentContainer,
    ReportFeedbackButton,
    MultipleChoiceQuestion,
    McqChoiceItem,
    ShortTextQuestion,
    FillInTheBlanksQuestion,
    FillInTheBlanksSolution,
    QuestionTags,
    QuestionLevelLabels,
    KogButton,
    KogCard,
    KogLoader,
    KogShortTextQuestionCorrectAnswers,
    QuestionPreview,
  },
  mixins: [QuestionDifficultyMixin, RoutesMixin],
  props: {
    quizQuestions: {
      type: Array,
      required: true,
    },
    isReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['quiz-closed', 'question-answered', 'quiz-submitted'],
  data() {
    return {
      markFBQInputs: () => {},
      userMCQChoiceState: {},
      userSTQText: '',
      userFBQAnswers: {},
      currentQuestionIndex: 0,
      showDifficulty: false,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      level: state => state.subjectModule.level,
      quiz: state => state.quizModule.quiz,
      quizOccasion: state => state.quizModule.quizOccasion,
      quizQuestionResponses: state => state.quizModule.quizQuestionResponses,
    }),
    ...mapGetters({
      sectionNodes: 'bookModule/sectionNodes',
      getQuestionResponseByQuestionId: 'quizModule/getQuestionResponseByQuestionId',
    }),
    currentQuestion() {
      return this.quizQuestions[this.currentQuestionIndex];
    },
    currentQuestionResponse() {
      return this.getQuestionResponseByQuestionId(this.currentQuestion.id);
    },
    currentQuestionSolution() {
      return this.currentQuestionResponse?.solution ?? {};
    },
    currentQuestionExplanation() {
      return this.currentQuestionResponse.explanation;
    },
    currentQuestionTags() {
      return this.currentQuestion.tags ?? [];
    },
    displayedCurrentQuestionNumber() {
      return this.isQuizSubmitted ? 0 : this.currentQuestionIndex + 1;
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subject.possible_levels);
    },
    subjectClassLevelGroup() {
      return IGCSE_LEVELS;
    },
    MCQChoices() {
      return shuffle(this.currentQuestion.question_context?.choices || []);
    },
    MCQCorrectChoices() {
      return this.currentQuestionSolution;
    },
    STQSolution() {
      const solution = {
        explanation: this.currentQuestionExplanation.explanation,
        correct_answers: {},
      };
      const answerUIds = Object.keys(this.currentQuestionSolution);
      answerUIds.forEach(answerUId => {
        solution.correct_answers[answerUId] = {
          answers: this.currentQuestionSolution[answerUId].answers,
          secondary_accepted_answers:
            this.currentQuestionSolution[answerUId].secondary_accepted_answers,
          explanation_html: this.currentQuestionExplanation.answer_explanations[answerUId],
        };
      });
      return solution;
    },
    isMCQ() {
      return isMCQ(this.currentQuestion);
    },
    isSTQ() {
      return isSTQ(this.currentQuestion);
    },
    isFBQ() {
      return isFBQ(this.currentQuestion);
    },
    isCurrentQuestionAnswered() {
      return !isNil(this.currentQuestionResponse);
    },
    isCurrentQuestionCorrect() {
      return this.isCurrentQuestionAnswered && this.currentQuestionResponse.answered_correctly;
    },
    isQuizSubmitted() {
      return this.quizOccasion.is_submitted;
    },
    allQuestionsAnswered() {
      return this.quizQuestions.length === this.quizQuestionResponses.length;
    },
    isAllowedToSubmit() {
      if (this.isMCQ) {
        return Object.keys(this.userMCQChoiceState).length > 0;
      }
      if (this.isSTQ) {
        return !!this.userSTQText;
      }
      if (this.isFBQ) {
        const blanksIds = Object.keys(this.userFBQAnswers);
        return blanksIds.length > 0 && blanksIds.every(blankId => !!this.userFBQAnswers[blankId]);
      }
      return false;
    },
    isLastQuestion() {
      return this.currentQuestionIndex === this.quizQuestions.length - 1;
    },
  },
  created() {
    this.showDifficulty = shouldShowDifficultyTagForSubject(this.subject.id);
  },
  methods: {
    ...mapWaitingActions({
      createQuizOccasion: {
        action: 'quizModule/createQuizOccasion',
        loader: 'submitting-quiz-question-response',
      },
      createQuizQuestionResponse: {
        action: 'quizModule/createQuizQuestionResponse',
        loader: 'submitting-quiz-question-response',
      },
      submitQuestionOccasion: {
        action: 'quizModule/submitQuestionOccasion',
        loader: 'submitting-quiz-occasion',
      },
    }),
    setMCQChoice(selectionEvent) {
      const { answer, selected } = selectionEvent;
      const baseState = this.currentQuestion.question_context.is_multi_select
        ? this.userMCQChoiceState
        : {};
      this.userMCQChoiceState = setChoiceItemState(baseState, answer, { selected });
    },
    setMCQChoiceStateFromResponse() {
      this.userMCQChoiceState = {};
      if (!this.isCurrentQuestionAnswered || !this.isMCQ) {
        return;
      }

      const correctIds = this.currentQuestionSolution;
      this.currentQuestionResponse.user_answer.user_response.forEach(choiceId => {
        const answer = +choiceId;
        const selected = true;
        const correct = correctIds.includes(answer);
        const baseState = this.currentQuestion.question_context.is_multi_select
          ? this.userMCQChoiceState
          : {};
        this.userMCQChoiceState = setChoiceItemState(baseState, answer, { selected, correct });
      });
    },
    setSTQAnswer(answer) {
      this.userSTQText = answer;
    },
    setSTQAnswerFromResponse() {
      const answer = this.isCurrentQuestionAnswered
        ? this.currentQuestionResponse.user_answer.user_text
        : '';
      this.setSTQAnswer(answer);
    },
    setFBQAnswers({ answers }) {
      this.userFBQAnswers = answers || {};
    },
    setFBQAnswersFromResponse() {
      const answers = this.isCurrentQuestionAnswered
        ? this.currentQuestionResponse.user_answer.user_answers
        : {};
      this.setFBQAnswers({ answers });

      if (this.isCurrentQuestionAnswered) {
        nextTick(() => this.markFBQInputs(this.currentQuestionSolution));
      }
    },
    setFBQFillBlanksMethods({ markInputs }) {
      this.markFBQInputs = markInputs;
    },
    createOccasionIfNotStarted() {
      if (!isNil(this.quizOccasion.id)) {
        return Promise.resolve();
      }
      return this.createQuizOccasion(this.quiz.id);
    },
    setCurrentQuestion(currentQuestionNumber) {
      this.currentQuestionIndex = currentQuestionNumber - 1;
      this.setSTQAnswerFromResponse();
      this.setFBQAnswersFromResponse();
      this.setMCQChoiceStateFromResponse();
    },
    nextQuestion() {
      this.setCurrentQuestion(this.displayedCurrentQuestionNumber + 1);
    },
    previousQuestion() {
      this.setCurrentQuestion(this.displayedCurrentQuestionNumber - 1);
    },
    async submitQuestionResponse() {
      this.$wait.start('question-navigation-disabled');
      await this.createOccasionIfNotStarted();
      const userAnswer = {};
      if (this.isMCQ) {
        userAnswer.user_response = getSelectedChoiceIds(this.userMCQChoiceState).map(id => +id);
      }
      if (this.isSTQ) {
        userAnswer.user_text = this.userSTQText;
      }
      if (this.isFBQ) {
        userAnswer.user_answers = this.userFBQAnswers;
      }
      await this.createQuizQuestionResponse({
        quizId: this.quiz.id,
        occasionId: this.quizOccasion.id,
        questionId: this.currentQuestion.id,
        userAnswer,
      });
      this.$emit('question-answered', this.currentQuestion.id);
      this.setMCQChoiceStateFromResponse();
      this.setFBQAnswersFromResponse();
      this.$wait.end('question-navigation-disabled');
    },
    async submitQuiz() {
      await this.submitQuestionOccasion({
        quizId: this.quiz.id,
        occasionId: this.quizOccasion.id,
      });
      this.$emit('quiz-submitted');
    },
    isQuestionAnsweredByNumber(questionNumber) {
      const questionId = this.quizQuestions[questionNumber - 1].id;
      const questionResponse = this.getQuestionResponseByQuestionId(questionId);
      return !isNil(questionResponse);
    },
    isQuestionCorrectByNumber(questionNumber) {
      const questionId = this.quizQuestions[questionNumber - 1].id;
      const questionResponse = this.getQuestionResponseByQuestionId(questionId);
      return questionResponse?.answered_correctly;
    },
    pluralize,
  },
};
</script>

<style scoped>
.QuizQuestions {
  padding-right: var(--space-l);
  padding-bottom: var(--space-l);
  padding-left: var(--space-l);
  background-color: var(--kogPlatformGray093);
}

.QuizQuestions-closeButton {
  margin-top: calc(-1 * var(--space-xs));
}

.QuizQuestions-card {
  width: 752px;
  box-shadow: 0 12px 24px -6px var(--kogShadow020);
}

.QuizQuestions-solutionCard {
  font-family: var(--kog-lato);
}

.QuizQuestions-questionContainer {
  min-width: 100%;
  max-width: 760px;
  font-family: var(--kog-lato);
}

.QuizQuestions-interactButton {
  width: 100%;
}
</style>
