export default {
  USER__FULL_NAME: 'user__last_name,user__first_name',
  USER__EMAIL: 'user__email',
  USER__PROFILE__LAST_ACTIVE: 'user__profile__last_active',
  SUBJECT_CLASS_COUNT: 'subject_class_count',
  FULL_NAME: 'full_name',
  EMAIL: 'email',
  YEARCLASS__ENDS: 'yearclass__ends',
  LEVEL__NAME: 'level',
};
