<template>
  <div
    :id="item.id"
    class="KogMenuBookItem"
    @click.stop="$emit('click')"
    @keyup.enter.stop="$emit('click')"
  >
    <component
      :is="url ? 'router-link' : 'button'"
      :class="[
        'KogMenuBookItem-titleContainer',
        'KogButtonLegacy--noStyle',
        {
          'KogMenuBookItem-titleContainer--topic': isTopic,
          'KogMenuBookItem-titleContainer--subtopic': isSubTopic,
          'KogMenuBookItem-titleContainer--section': isSection,
          'KogMenuBookItem-titleContainer--subsection': isSubSection,
          'KogMenuBookItem-titleContainer--selected': shouldShowSelected,
          'KogMenuBookItem-titleContainer--topShadow': showTopShadow,
          'KogMenuBookItem-titleContainer--bottomShadow': showBottomShadow,
          'KogMenuBookItem-titleContainer--verticalShadow': showVerticalShadow,
        },
      ]"
      :to="url ? url : undefined"
    >
      <div
        v-if="shouldShowSelected"
        class="KogMenuBookItem-titleContainer--selectedBorder"
      />
      <div class="screenreader-only">
        <span v-if="!isSubject">
          {{ itemType }} {{ item.formatted_number_including_ancestors }}
        </span>
        {{ item.name }}
      </div>
      <div aria-hidden="true">
        <i
          v-if="shouldShowExpandCollapseArrows"
          :class="[
            'fas',
            'padd-right-fine-1',
            {
              'fa-chevron-right': !item.expanded,
              'fa-chevron-down': item.expanded,
            },
          ]"
          aria-hidden="true"
        />
        <span v-if="!isSubject">
          {{ item.formatted_number_including_ancestors }}
        </span>
        {{ item.name }}
      </div>
      <div class="KogMenuBookItem-progressContainer">
        <component
          :is="indicatorComponent"
          v-if="indicatorComponent"
          :item="item"
        />
      </div>
    </component>
    <div>
      <slot v-if="item.expanded" />
    </div>
  </div>
</template>

<script>
import {
  isSection,
  isSubject,
  isSubSection,
  isSubTopic,
  isTopic,
} from 'sharedApp/libs/subject-tree-functions.js';
import AnnotationsIndicator from 'studyApp/components/side-navigation/annotations-indicator.vue';
import ProgressIndicator from 'studyApp/components/side-navigation/progress-indicator.vue';

export default {
  name: 'MenuBookItem',
  components: {
    AnnotationsIndicator,
    ProgressIndicator,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    indicatorComponent: {
      type: String,
      default: null,
    },
    isLastOfItem: {
      type: Boolean,
      default: null,
    },
    isFirstOfItem: {
      type: Boolean,
      default: null,
    },
    selectedNode: {
      type: Object,
      default: null,
    },
    url: {
      type: Object,
      default: null,
    },
  },
  emits: ['click'],
  computed: {
    isSubject() {
      return isSubject(this.item);
    },
    isTopic() {
      return isTopic(this.item);
    },
    isSubTopic() {
      return isSubTopic(this.item);
    },
    isSection() {
      return isSection(this.item);
    },
    isSubSection() {
      return isSubSection(this.item);
    },
    hasChildren() {
      return this.item.children.length > 0;
    },
    isSectionWithSubsection() {
      return this.isSection && this.hasChildren;
    },
    isSelected() {
      if (this.isSubject && this.selectedNode) {
        return this.selectedNode.id === this.item.id;
      }
      return this.item.selected;
    },
    shouldShowSelected() {
      return this.isSelected && ((this.isSection && !this.hasChildren) || this.isSubSection);
    },
    shouldShowExpandCollapseArrows() {
      return this.isSubTopic || this.isTopic || this.isSectionWithSubsection;
    },
    showTopShadow() {
      const isFirstNotTopicItem = this.isFirstOfItem && !this.isLastOfItem && !this.isTopic;
      const isOnlyExpandedSubtopic =
        this.isFirstOfItem && this.isLastOfItem && this.isSubTopic && this.item.expanded;
      return isFirstNotTopicItem || isOnlyExpandedSubtopic;
    },
    showBottomShadow() {
      const isLastNotTopicCollapsedItem =
        !this.isFirstOfItem && this.isLastOfItem && !this.isTopic && !this.item.expanded;
      return isLastNotTopicCollapsedItem;
    },
    showVerticalShadow() {
      const isOnlySection = this.isFirstOfItem && this.isLastOfItem && this.isSection;
      const isOnlyCollapsedSubtopic =
        this.isFirstOfItem && this.isLastOfItem && this.isSubTopic && !this.item.expanded;
      return isOnlyCollapsedSubtopic || isOnlySection;
    },
    itemType() {
      if (this.isTopic) {
        return 'Topic';
      }
      if (this.isSubTopic) {
        return 'Subtopic';
      }
      if (this.isSection) {
        return 'Section';
      }
      return 'Subsection';
    },
  },
};
</script>

<style scoped>
.KogMenuBookItem-titleContainer {
  position: relative;

  display: flex;
  justify-content: space-between;

  width: 100%;
  min-height: 24px; /* To match progressIcon */
  padding: 15px 15px;

  color: var(--kog-text-default);
  text-align: left;
}

.KogMenuBookItem-titleContainer:hover {
  color: var(--kog-text-default);
  background-color: var(--kog-list-item-hover-background);
}

.KogMenuBookItem-titleContainer--selectedBorder {
  position: absolute;
  top: 0;
  left: 0;

  width: 4px;
  height: 100%;

  background-color: var(--kog-list-item-selected-border);
  border-radius: 0 4px 4px 0;
}

.KogMenuBookItem-titleContainer--topic {
  border-top: solid 1px var(--kog-border-default-300);
}

.KogMenuBookItem-titleContainer--subtopic {
  padding-left: 30px;
}

.KogMenuBookItem-titleContainer--section {
  padding-left: 60px;
}

.KogMenuBookItem-titleContainer--subsection {
  padding-left: 90px;
}

.KogMenuBookItem-titleContainer--selected {
  background-color: var(--kog-list-item-selected-background);
}

.KogMenuBookItem-titleContainer--selected:hover {
  background-color: var(--kog-list-item-selected-hover-background);
}

.KogMenuBookItem-titleContainer--topShadow {
  box-shadow: inset 0 10px 10px -10px var(--kogShadow020);
}

.KogMenuBookItem-titleContainer--bottomShadow {
  box-shadow: inset 0 -10px 10px -10px var(--kogShadow020);
}

.KogMenuBookItem-titleContainer--verticalShadow {
  box-shadow:
    inset 0 -10px 10px -10px var(--kogShadow020),
    inset 0 10px 10px -10px var(--kogShadow020);
}

.KogMenuBookItem-progressContainer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: var(--space-l);
  padding-left: var(--space-xs);
}
</style>
