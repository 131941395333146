const TableOfContentsMixin = {
  data() {
    return {
      selectedTopicId: undefined,
      selectedSubtopicId: undefined,
      selectedTopic: undefined,
      selectedSubtopic: undefined,
    };
  },
  methods: {
    selectTopic(topic) {
      this.selectedTopicId = topic?.id;
      this.selectedTopic = topic;

      if (typeof this.onSelectedTopicChange === 'function') {
        this.onSelectedTopicChange(topic);
      }
    },
    selectSubtopic(subtopic) {
      this.selectedSubtopicId = subtopic?.id;
      this.selectedSubtopic = subtopic;

      if (typeof this.onSelectedSubtopicChange === 'function') {
        this.onSelectedSubtopicChange(subtopic);
      }
    },
  },
  provide() {
    const tableOfContentsContext = {};

    Object.defineProperty(tableOfContentsContext, 'selectedTopic', {
      enumerable: true,
      get: () => this.selectedTopic,
      set: this.selectTopic,
    });

    Object.defineProperty(tableOfContentsContext, 'selectedSubtopic', {
      enumerable: true,
      get: () => this.selectedSubtopic,
      set: this.selectSubtopic,
    });

    return {
      tableOfContentsContext,
    };
  },
};

export default TableOfContentsMixin;
