import { Plugin } from '@ckeditor/ckeditor5-core';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';

import SpanKogId from '../span-id/span-id.js';
import ContentBoxEditing from './content-box-editing.js';
import ContentBoxUI from './content-box-ui.js';

export default class ContentBox extends Plugin {
  static get requires() {
    return [ContentBoxEditing, ContentBoxUI, Paragraph, Heading, SpanKogId];
  }
}
