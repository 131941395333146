<template>
  <div class="CompletionBar">
    <div
      class="flexContainer flexContainer-row flexContainer-alignCenter padd-m padd-top-0 padd-bottom-0"
      aria-hidden="true"
    >
      <i class="fa-regular fa-face-surprise padd-right-xs" />
      <kog-progress-bar
        :progress="[{ percentage: 25.0 }, { percentage: 30.0 }]"
        size="s"
        type="mixed"
      />
    </div>
    <ul
      class="margin-0 padd-top-xs"
      aria-hidden="true"
    >
      <li class="flexContainer flexContainer-alignCenter">
        <div class="circle correct" />
        <span class="padd-xs padd-top-0 padd-bottom-0">Correct</span>
      </li>
      <li class="flexContainer flexContainer-alignCenter">
        <div class="circle incorrect" />
        <span class="padd-xs padd-top-0 padd-bottom-0">Incorrect</span>
      </li>
      <li class="flexContainer flexContainer-alignCenter">
        <div class="circle unanswered" />
        <span class="padd-xs padd-top-0 padd-bottom-0">Unanswered</span>
      </li>
    </ul>
    <p
      id="completion-bar-desc"
      class="padd-s margin-0"
    >
      Shows what you've completed out of all available questions on the topic.
    </p>
  </div>
</template>

<script>
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';

export default {
  components: {
    KogProgressBar,
  },
};
</script>

<style scoped>
.CompletionBar ul {
  display: flex;
  list-style: none;
}

.CompletionBar .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.CompletionBar .correct {
  background: var(--kog-progressbar-double-mixed-bar1-color);
}

.CompletionBar .incorrect {
  background: var(--kog-progressbar-double-mixed-bar2-color);
}
</style>
