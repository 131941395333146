<template>
  <kog-default-study-page>
    <template #body>
      <kog-loader
        :loading="!assignment"
        loading-msg="Loading assignment..."
      >
        <div v-if="assignment">
          <div class="text-center divider-bottom">
            <h3 class="margin-top-0">
              You have reached the end, nice work!
              <span class="KogEmoji KogEmoji--large KogEmoji--tada margin-left-fine-1" />
            </h3>
          </div>
          <div class="EsqAssignmentResult-content padd-top-xxl">
            <div
              v-if="assignment.message"
              class="EsqAssignmentResult-instructionsCard shadow-m-020 margin-top-l"
            >
              <h5 class="text-center"> Instructions </h5>
              <p class="text-center">
                {{ assignment.message }}
              </p>
            </div>

            <div
              v-else
              class="EsqAssignmentResult-thumbUp shadow-m-020 margin-top-l"
            >
              <i class="fas fa-thumbs-up" />
            </div>
            <div
              v-if="assignment.is_sent_to_google_classroom"
              class="margin-top-xl margin-bottom-xl"
            >
              <google-classroom-reminder />
            </div>
            <div
              class="margin-bottom-m text-center"
              :class="[assignment.is_sent_to_google_classroom ? '' : 'margin-top-xxxl']"
            >
              <router-link
                :to="{
                  name: 'classAssignmentsOverview',
                  params: {
                    classSlug: subjectClassSlug,
                    sid: subjectId,
                    cid: classId,
                  },
                }"
                class="KogButtonLegacy KogButtonLegacy--primary"
              >
                Go to assignment overview
              </router-link>
              <p class="margin-top-1">
                Not finished?
                <router-link
                  v-slot="{ href, navigate }"
                  custom
                  :to="{
                    name: 'classEsqAssignmentTake',
                    params: {
                      classSlug: subjectClassSlug,
                      sid: subjectId,
                      cid: classId,
                      assignmentId: assignment.id,
                    },
                  }"
                >
                  <a
                    :href="href"
                    @click="
                      e => {
                        backToQuestions();
                        navigate(e);
                      }
                    "
                  >
                    Go back to the questions
                  </a>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </kog-loader>
    </template>
  </kog-default-study-page>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { mapActions, mapState, useStore } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import GoogleClassroomReminder from 'studyApp/components/google-classroom-reminder/google-classroom-reminder.vue';
import KogDefaultStudyPage from 'studyApp/components/kog-default-study-page.vue';

export default {
  name: 'EsqAssignmentResult',
  components: {
    GoogleClassroomReminder,
    KogDefaultStudyPage,
    KogLoader,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const { assignmentModule, subjectModule } = store.state;

    const assignmentName = computed(() => assignmentModule.assignment?.name);
    const subjectName = computed(() => subjectModule.subject?.name);

    useHead({
      title: () => `${assignmentName.value} | ESQ assignment results | ${subjectName.value}`,
    });
  },
  computed: {
    ...mapState('subjectModule', {
      subject: state => state.subject,
    }),
    ...mapState('assignmentModule', {
      assignment: state => state.assignment,
    }),
    assignmentId() {
      return this.$route.params.assignmentId;
    },
  },
  created() {
    this.getAssignment(this.assignmentId);
  },
  methods: {
    ...mapActions('assignmentModule', {
      getAssignment: 'getAssignment',
    }),
    backToQuestions() {
      this.trackAssignmentGoBack();
    },
    trackAssignmentGoBack() {
      const eventName = 'Assignment - Go back';
      const eventProperties = {
        assignment_type: ASSIGNMENT_TYPES.EXAMSTYLE.toLowerCase(),
        num_subject_classes: this.assignment.subject_classes.length,
      };
      this.$mixpanel.trackEvent(eventName, eventProperties);
    },
  },
};
</script>

<style scoped>
.EsqAssignmentResult-content {
  background: url('./images/esq-background.svg');
  background-size: cover;
}

.EsqAssignmentResult-instructionsCard {
  width: 380px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;

  background-color: var(--kogPlatformWhite);
  border-radius: 5px;
}

.EsqAssignmentResult-thumbUp {
  width: 160px;
  height: 160px;
  margin-right: auto;
  margin-left: auto;

  text-align: center;
  vertical-align: center;

  background-color: var(--kogPlatformWhite);
  border-radius: 50%;
}

.EsqAssignmentResult-thumbUp .fas {
  transform: rotate(-8deg);
  margin-top: 10px;
  font-size: 120px;
  color: var(--kog-text-brand);
}

.EsqAssignmentResult-tada {
  font-size: 30px;
}
</style>
