<template>
  <kog-table-row :row-index="index">
    <kog-table-cell-generic>
      <kog-avatar
        class="margin-right-s"
        :src="teacher.avatar_url"
        :name="teacher.name"
        theme="accent"
      />
      {{ teacher.name }}
    </kog-table-cell-generic>
    <kog-table-cell-text>
      <div v-if="teacher.last_active"> {{ teacher.last_active }} ago </div>
      <div v-else> Never logged in </div>
    </kog-table-cell-text>
    <kog-table-cell-actions
      :actions="actions"
      :context="{}"
      :is-kebab-menu="true"
      kebab-icon-class="fa-ellipsis-h"
      :dropdown-aria-label="`Actions for ${teacher.name}`"
    />
  </kog-table-row>
</template>

<script>
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import KogTableCellActions from 'sharedApp/components/tables/kog-table-cell-actions.vue';
import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogRemoveClassTeacherModal from 'studyApp/components/teacher/teachers/kog-remove-class-teacher-modal.vue';

export default {
  name: 'ClassTeacherListItem',
  components: {
    KogAvatar,
    KogTableRow,
    KogTableCellText,
    KogTableCellGeneric,
    KogTableCellActions,
  },
  props: {
    teacher: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    removeTeacher: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isTheSameUserLoggedIn() {
      return this.user.id === this.teacher.user_id;
    },
    actions() {
      const dropdownText = this.isTheSameUserLoggedIn ? 'Leave class' : 'Remove teacher';
      return [
        {
          callback: this.showRemoveTeacherModal,
          dropdownText,
        },
      ];
    },
    user() {
      return window.KOG.USER_CONSTANTS.User;
    },
  },
  methods: {
    showRemoveTeacherModal() {
      this.$modal(KogRemoveClassTeacherModal, {
        removeTeacher: this.removeTeacher,
        teacherId: this.teacher.user_id,
        teacherName: this.teacher.name,
        deletingSelf: this.isTheSameUserLoggedIn,
      });
    },
  },
};
</script>
