import { Command } from '@ckeditor/ckeditor5-core';

import type { Writer } from '@ckeditor/ckeditor5-engine';

function getUniqueId() {
  for (let i = 0; ; i += 1) {
    const id = `showSolutionPlugin_${i}-solution`;
    if (!document.getElementById(id)) {
      return id;
    }
  }
}

export default class InsertShowSolutionCommand extends Command {
  execute() {
    const { model } = this.editor;
    model.change(writer => {
      model.insertObject(this.create(writer));
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const position = document.selection.getFirstPosition();
    const allowedIn = position && schema.findAllowedParent(position, 'showSolution');
    this.isEnabled = allowedIn !== null;
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer: Writer) {
    const contentId = getUniqueId();

    const showSolution = writer.createElement('showSolution');
    const showSolutionToggle = writer.createElement('showSolutionToggle');
    const showSolutionToggleButton = writer.createElement('showSolutionToggleButton', {
      contentId,
    });
    const showSolutionContent = writer.createElement('showSolutionContent', { id: contentId });
    const showSolutionContentParagraph = writer.createElement('paragraph');
    const contentText = writer.createText('Add a great solution here...');
    const showSolutionToggleButtonParagraph = writer.createElement('paragraph');
    const buttonText = writer.createText('Show or hide solution');

    writer.append(showSolutionToggle, showSolution);
    writer.append(showSolutionToggleButton, showSolutionToggle);
    writer.append(showSolutionContent, showSolution);
    writer.append(contentText, showSolutionContentParagraph);
    writer.append(showSolutionContentParagraph, showSolutionContent);
    writer.append(buttonText, showSolutionToggleButtonParagraph);
    writer.append(showSolutionToggleButtonParagraph, showSolutionToggleButton);

    return showSolution;
  }
}
