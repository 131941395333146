<template>
  <div class="margin-bottom-l">
    <kog-item-list
      role="list"
      class="list-style-none"
      :is-handling-tab-index="true"
    >
      <li
        v-for="material of materials"
        :key="`sml-${material.id}`"
      >
        <content-node-item
          :external-link="material.link"
          position="ungrouped"
          :row-aria-label="`Open material ${material.title}`"
          :button-aria-label="`Open material ${material.title}`"
          :title="material.title"
          :subtitle="supportingMaterialTypeToText(material.type)"
          :icon="getSupportingMaterialIconUrl(material.type)"
          :has-button="true"
          button-icon="fa-arrow-up-right-from-square"
          @clickButton="openSupportingMaterialLink(material)"
          @clickContainer="trackSupportingMaterialLink(material)"
        />
      </li>
    </kog-item-list>
  </div>
</template>

<script>
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import {
  getSupportingMaterialIconUrl,
  supportingMaterialTypeToText,
} from 'sharedApp/utils/supporting-material-link.js';

import ContentNodeItem from './content-node-item.vue';

export default {
  name: 'SupportingMaterialLinkList',
  components: {
    ContentNodeItem,
    KogItemList,
  },
  mixins: [RoutesMixin],
  props: {
    materials: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getSupportingMaterialIconUrl(type) {
      return getSupportingMaterialIconUrl(type);
    },
    supportingMaterialTypeToText(type) {
      return supportingMaterialTypeToText(type);
    },
    trackSupportingMaterialLink(material) {
      this.$mixpanel.trackEvent('Supporting material - Open link', {
        link_title: material.title,
        link_type: material.type,
      });
      this.$event.track(
        'load_teacher_resource',
        {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          material_id: material.id,
        },
        this.user,
      );
    },
    openSupportingMaterialLink(material) {
      this.trackSupportingMaterialLink(material);
      window.open(material.link, '_blank');
    },
  },
};
</script>
