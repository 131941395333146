import { ContextualBalloon } from '@ckeditor/ckeditor5-ui';

import openEnvelopeIcon from 'publishingApp/assets/icons/envelope-open-text-sharp-solid.svg';

import KogUI from '../utils/kogui.ts';
import { registerFactoryView } from '../utils/plugin-utils.ts';
import FormView from './content-box-form.js';

export default class ContentBoxUI extends KogUI {
  static get requires() {
    return [ContextualBalloon];
  }

  init() {
    const pluginConfig = this.editor.config.get('contentBox');
    const insertCommand = this.editor.commands.get('insertContentBox');
    const updateCommand = this.editor.commands.get('updateContentBox');
    this.contentBoxTemplateMap = pluginConfig.contentBoxTemplateMap;

    if (Object.keys(this.contentBoxTemplateMap).length === 0) {
      insertCommand.forceDisabled();
    }

    this.balloon = this.editor.plugins.get(ContextualBalloon);
    this.formView = null;

    registerFactoryView(this.editor, {
      name: 'contentbox',
      commandName: 'insertContentBox',
      label: 'Content box',
      icon: openEnvelopeIcon,
      onExecute: () => {
        this.showUI();
      },
    });

    this.listenTo(updateCommand, 'execute', () => {
      this.showUI();
    });
  }

  createFormView() {
    this.formView = new FormView(this.editor.locale, this.contentBoxTemplateMap);

    const onSubmit = () => {
      const value = {
        templateId: this.formView.templateId,
        titleTag: this.formView.titleTag,
      };
      this.editor.execute('insertContentBox', value);
      this.hideUI();
    };

    this.setupFormView(onSubmit);
  }

  showUI() {
    if (!this.formView) {
      this.createFormView();
    }

    const commandValue = this.editor.commands.get('insertContentBox').value;
    if (commandValue) {
      const { templateId, titleTag } = commandValue;
      this.formView.setFields({ templateId, titleTag });
    }

    super.showUI();
  }
}
