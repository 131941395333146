<template>
  <kog-loader
    :loading="isLoadingSection"
    loading-msg="Loading section text..."
  >
    <div
      v-if="!isNgss"
      class="kogPlatformWhite-bg margin-bottom-s"
    >
      <review-mode-header
        :nodes="sectionNodes"
        :subtopic-node="subtopicNode"
        class="SectionReviewMode-Content u-paddingTopOverride"
        @navigate-to-node="onScrollToContentRequest"
      />
    </div>
    <div v-if="areNodeSectionsLoaded">
      <div
        v-for="sectionNode of sectionNodes"
        :key="`reviewMode_${sectionNode.id}`"
        v-intersection-observer="[onSectionVisibilityChange, { threshold: 0 }]"
        class="margin-bottom-s"
        :data-node-id="sectionNode.id"
        :data-node-slug="sectionNode.slug"
      >
        <section-activity-template
          v-if="isActivityPageTemplate(sectionNode)"
          :ref="`Section-${sectionNode.id}`"
          :subject="subject"
          :user="user"
          :current-section-node="sectionNode"
          :subtopic-node="subtopicNode"
          :is-in-review-mode="true"
          class="SectionReviewMode-Content u-paddingTopOverride"
        />
        <section-default-template
          v-else
          :ref="`Section-${sectionNode.id}`"
          :subject="subject"
          :user="user"
          :current-section-node="sectionNode"
          :subtopic-node="subtopicNode"
          :is-in-review-mode="true"
          class="SectionReviewMode-Content u-paddingTopOverride"
        />
        <section-question-preview
          :subject-node-id="sectionNode.id"
          :subject="subject"
        />
      </div>
    </div>
    <content-rating
      v-if="!!currentNodeParent && !isNgss"
      :subject-node="currentNodeParent"
    />
    <section-pager v-if="isNgss" />
  </kog-loader>
</template>

<script>
import { nextTick } from 'vue';
import { useHead } from '@unhead/vue';
import { vIntersectionObserver } from '@vueuse/components';
import { useEventBus } from '@vueuse/core';
import debounce from 'lodash/debounce.js';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState, useStore } from 'vuex';

import { fetchSubjectNode } from 'sharedApp/apis/subject-nodes.js';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import ContentRating from 'studyApp/components/section-text/content-rating.vue';
import SectionActivityTemplate from 'studyApp/components/section-text/section-activity-template.vue';
import SectionDefaultTemplate from 'studyApp/components/section-text/section-default-template.vue';
import SectionPager from 'studyApp/components/section-text/section-pager.vue';
import SectionQuestionPreview from 'studyApp/components/section-text/section-question-preview.vue';
import ReviewModeHeader from 'studyApp/components/teacher/review-mode-header.vue';

export default {
  name: 'SectionReviewMode',
  components: {
    SectionPager,
    SectionActivityTemplate,
    SectionDefaultTemplate,
    ReviewModeHeader,
    KogLoader,
    SectionQuestionPreview,
    ContentRating,
  },
  directives: {
    intersectionObserver: vIntersectionObserver,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const { subject } = store.state.subjectModule;
    const scrollToContentEventBus = useEventBus('scroll-to-content');

    useHead({
      title: `Review Mode | ${subject.name}`,
    });

    return {
      scrollToContentEventBus,
    };
  },
  data() {
    return {
      visibleNodes: [],
      isFirstLoad: true,
      updateUrlOnScroll: debounce(this.updateUrlToLastVisibleNode, 300),
      node: null,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      sectionNodes: 'bookModule/sectionNodes',
      findSectionNodeById: 'bookModule/findSectionNodeById',
      currentNodeParent: 'bookModule/currentNodeParent',
      findTopicNodeBySlug: 'subjectModule/findTopicNodeBySlug',
      findSubtopicNodeBySlug: 'subjectModule/findSubtopicNodeBySlug',
    }),
    isLoadingSection() {
      return this.$wait.is('loading_section_text');
    },
    slugs() {
      return this.node?.url.split('/') || [];
    },
    subjectSlug() {
      return this.slugs[0] || null;
    },
    topicSlug() {
      return this.slugs[1] || '';
    },
    subtopicSlug() {
      return this.slugs[2] || '';
    },
    sectionSlug() {
      return this.slugs[3] || null;
    },
    subsectionSlug() {
      return this.slugs[4] || null;
    },
    topicNode() {
      return this.findTopicNodeBySlug(this.topicSlug);
    },
    subtopicNode() {
      if (!this.topicNode) return {};
      return this.findSubtopicNodeBySlug(this.topicNode, this.subtopicSlug);
    },
    currentSectionNode() {
      return this.findSectionNodeById(this.nodeId);
    },
    isNgss() {
      return isNgss(this.subject.educationsystem.name);
    },
    areNodeSectionsLoaded() {
      return this.sectionNodes.some(sectionNode => sectionNode.id === this.nodeId);
    },
  },
  watch: {
    isLoadingSection: {
      handler(loading) {
        if (!loading) {
          nextTick(() => {
            this.scrollToContent(this.nodeId);
          });
        }
      },
    },
    nodeId: {
      async handler(newId, oldId) {
        if (newId !== oldId && !this.currentSectionNode) {
          this.visibleNodes = [];
          this.isFirstLoad = true;
          this.node = await fetchSubjectNode(this.nodeId);
          if (!this.currentSectionNode) await this.getSections();
        }
      },
    },
    currentSectionNode: {
      handler(newNode) {
        if (newNode) {
          this.registerSectionActivity(newNode.id);
        }
      },
    },
  },
  async created() {
    if (this.user.isStudent()) {
      this.redirectToBook();
    }
    this.fetchSubjectNodesProgress(this.subject.id);
    this.node = await fetchSubjectNode(this.nodeId);
    if (!this.currentSectionNode || !this.areNodeSectionsLoaded) {
      await this.getSections();
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.updateUrlOnScroll);
  },
  mounted() {
    this.scrollToContentEventBus.on(this.onScrollToContentRequest);
  },
  beforeUnmount() {
    this.scrollToContentEventBus.off(this.onScrollToContentRequest);
    window.removeEventListener('scroll', this.updateUrlOnScroll);
  },
  methods: {
    ...mapWaitingActions('bookModule', {
      fetchSections: 'loading_section_text',
    }),
    ...mapWaitingActions('statisticModule', {
      fetchSubjectNodesProgress: 'loading_subject_progress_statistic',
    }),
    ...mapActions({
      registerSectionActivity: 'activityModule/registerSectionActivity',
    }),
    async getSections() {
      const { subjectSlug, topicSlug, subtopicSlug, sectionSlug, subsectionSlug } = this;
      await this.fetchSections({
        subjectSlug,
        topicSlug,
        subtopicSlug,
        sectionSlug,
        subsectionSlug,
      });
    },
    redirectToBook() {
      this.$router.replace({
        name: 'classBook',
        params: {
          classSlug: `${this.subjectClassSlug}`,
          sid: `${this.subjectId}`,
          cid: `${this.classId}`,
        },
      });
    },
    onSectionVisibilityChange([entry]) {
      const { isIntersecting, target } = entry;
      if (!this.isFirstLoad) {
        const { nodeId, nodeSlug } = target.dataset;
        if (isIntersecting) {
          const isInArray = this.visibleNodes.find(n => n.id === nodeId);
          if (isInArray) return;
          this.visibleNodes.push({
            id: nodeId,
            slug: nodeSlug,
          });
        } else {
          this.visibleNodes = this.visibleNodes.filter(n => n.id !== nodeId);
        }
      }
    },
    updateUrl(node) {
      if (this.nodeId === +node.id) return;
      this.$router.replace({
        name: 'reviewMode',
        params: {
          classSlug: `${this.subjectClassSlug}`,
          sid: `${this.subjectId}`,
          cid: `${this.classId}`,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
      });
    },
    onScrollToContentRequest(node) {
      this.scrollToContent(node.id);
    },
    scrollToContent(nodeId) {
      const sectionRef = this.$refs[`Section-${nodeId}`];
      if (sectionRef[0] && sectionRef[0].$refs) {
        const sectionEle = sectionRef[0].$refs.root;
        const scrollOptions = { behavior: 'smooth' };
        if (this.isFirstLoad) {
          scrollOptions.behavior = 'auto';
        }
        sectionEle.scrollIntoView(scrollOptions);
        setTimeout(() => {
          this.isFirstLoad = false;
        }, 300);
      }
    },
    isActivityPageTemplate(node) {
      return node.template === 'activity';
    },
    updateUrlToLastVisibleNode() {
      const lastVisibleNode = this.visibleNodes[this.visibleNodes.length - 1];
      if (lastVisibleNode?.id && this.nodeId !== +lastVisibleNode.id) {
        this.updateUrl(lastVisibleNode);
      }
    },
  },
};
</script>

<style scoped>
.SectionReviewMode-Content {
  height: auto;
  background-color: var(--kogPlatformWhite);
}

.SectionReviewMode-Content:last-child {
  margin-bottom: 0;
}

.u-paddingTopOverride {
  padding-top: 42px !important;
}
</style>
