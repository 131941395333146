<script>
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import RequestTrialModal from 'learning/common/components/start-trial/request-trial-modal.vue';
import ThreePeopleImage from 'learning/study/images/three-people.png';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogNavbar from 'sharedApp/components/core/kog-navbar.vue';

import ExtendDemoAccessModal from './components/extend-demo-access-modal.vue';

export default defineComponent({
  name: 'DemoSchoolExpired',
  components: { KogButton, KogNavbar },
  computed: {
    ...mapGetters('userModule', ['demoType', 'isNGLSchool']),
    ...mapState('userModule', ['user']),
    hasExpired() {
      return this.user.school.has_expired;
    },
    formType() {
      return this.isNGLSchool ? 'NGL' : this.demoType;
    },
  },
  async created() {
    this.imageSrc = ThreePeopleImage;
    await this.fetchUser();
    if (!this.hasExpired) {
      this.$router.replace({ name: 'studyDashboard' });
    }
  },
  mounted() {
    this.$mixpanel.trackEventViaBackend('Demo School Expired - View');
  },
  methods: {
    ...mapWaitingActions('userModule', {
      fetchUser: 'fetching_user',
    }),
    openRequestTrialModal() {
      this.$mixpanel.trackEventViaBackend('Demo School Expired - Open Start Trial', {});
      this.$modal(RequestTrialModal, {
        user: this.user,
        formType: this.formType,
        title: 'Sign up for a school trial',
      });
    },
    openExtendDemoAccessModal() {
      this.$mixpanel.trackEventViaBackend('Demo School Expired - Open extend demo access', {});
      this.$modal(ExtendDemoAccessModal);
    },
  },
});
</script>

<template>
  <div class="DemoSchoolExpired">
    <kog-navbar :user="user"></kog-navbar>
    <div class="kog-container">
      <div class="kog-row DemoSchoolExpired-card margin-top-xxl">
        <div class="kog-col-12 kog-col-md-6 text-regular">
          <h1 class="heading-m margin-top-0">Your free access has expired</h1>
          <p class="margin-top-l">
            To continue using the resources we recommend that you bring Kognity to your school.
            We've heard that teachers experience new levels of teaching joy because they see their
            students activity increase as they use the materials.
          </p>

          <h2 class="heading-xxs margin-top-xl">
            Are you ready to try Kognity out with students?</h2
          >
          <ul class="margin-top-xs">
            <li>Automatically corrected class assignments</li>
            <li>Engaging practice materials and activities for students</li>
            <li>Vast range of materials to teach with</li>
            <li>...and much more</li>
          </ul>
          <div class="margin-top-xl">
            <kog-button
              label="Sign up for a school trial"
              button-style="positive"
              :is-loading="$wait.is('fetching_user')"
              @click="openRequestTrialModal"
            />
            <kog-button
              class="margin-left-s"
              label="Extend free access for 7 days"
              @click="openExtendDemoAccessModal"
            />
          </div>
        </div>
        <div class="kog-col-12 kog-col-md-6">
          <img
            :src="imageSrc"
            alt=""
            class="DemoSchoolExpired-image margin-top-l"
          />
        </div>
      </div>
      <div class="kog-row margin-top-xxl">
        <div class="kog-col-12 kog-col-md-4">
          <p class="text-regular">
            <span class="text-italic">
              “Kognity helps me a lot with tracking and understanding how the students are learning,
              and it takes off a lot of work on my side because I don’t need to prepare anything -
              it’s all there.”</span
            ><br />
            <span class="text-medium"> - Andrea S, Teacher </span>
          </p>
        </div>
        <div class="kog-col-12 kog-col-md-4">
          <p class="text-regular">
            <span class="text-italic">
              “I was pleasantly surprised, as students at the school can be quite critical when
              presented with new things and they are not very gullible. But in the case of Kognity,
              there was unanimous approval. They find it very useful. It is a brilliant replacement
              to the textbook and the course material is accessible anywhere at anytime.” </span
            ><br />
            <span class="text-medium"> - Dr. Babus Das, Head of Science</span>
          </p>
        </div>
        <div class="kog-col-12 kog-col-md-4">
          <p class="text-regular">
            <span class="text-italic">
              “We chose Kognity because of their commitment and understanding of our education needs
              ... The teachers who utilized Kognity’s resources saw an improvement in student
              engagement, understanding, and assessments.” </span
            ><br />
            <span class="text-medium">- Ladale Bernard, District coordinator</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.DemoSchoolExpired {
  min-height: 100vh;
  padding-top: var(--space-xxxl);
  background-color: var(--kog-colors-grey-100);
}
.DemoSchoolExpired-card {
  padding: var(--space-xl) var(--space-l) var(--space-xl) var(--space-l);
  background-color: var(--kog-colors-white);
  border-radius: var(--space-s);
}

.DemoSchoolExpired-image {
  width: 100%;
  height: auto;
}
</style>
