function getDoneExerciseOccasions(exerciseOccasions) {
  return exerciseOccasions.filter(eo => eo.status === 'DONE');
}

function getExerciseOccasionQuestionIds(exerciseOccasion) {
  const practiceItems = exerciseOccasion.practice_items;
  const questionIds = practiceItems.map(pi => pi.question_id);
  return questionIds;
}

export function areAllReflectionQuestionsAnswered(reflectionQuestions, exerciseOccasions) {
  const doneExerciseOccasions = getDoneExerciseOccasions(exerciseOccasions);

  const questionIds = doneExerciseOccasions.flatMap(eo => getExerciseOccasionQuestionIds(eo));

  const reflectionQuestionIds = reflectionQuestions.map(rq => rq.id);

  return reflectionQuestionIds.every(id => questionIds.includes(id));
}

export function isContentContainingReflectionBox(contentsHtml) {
  return contentsHtml.includes('reflection-box');
}
