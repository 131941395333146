export default {
  // All subjects tours
  0: [],
  // NGSS The Living Earth
  314: [{ id: '1FhJSCSR47', title: 'Full product tour' }],
  // NGSS Chemistry in the Earth System
  315: [{ id: 'bo0g92KROx', title: 'Full product tour' }],
  // NGSS Physics of the Universe
  316: [{ id: 'wE4khmKQMi', title: 'Full product tour' }],
  // NGSS Earth and space science
  470: [{ id: '874vq0thmY', title: 'Full product tour' }],
  // Cambridge IGCSE™ Biology FE2023
  295: [{ id: 'TKTaw43wrn', title: 'Full product tour' }],
  // Cambridge IGCSE™ Business Studies FE2020
  181: [{ id: '5vOvZbixYr', title: 'Full product tour' }],
  // Cambridge IGCSE™ Chemistry FE2023
  296: [{ id: 'NF2T8KcU0j', title: 'Full product tour' }],
  // Cambridge IGCSE™ Co-ordinated Sciences FE2019
  97: [{ id: 'EjAB1zRXV2', title: 'Full product tour' }],
  // Cambridge IGCSE™ Computer Science FE2023
  300: [{ id: 'WxzfVCkOVh', title: 'Full product tour' }],
  // Cambridge IGCSE™ Economics FE2020
  183: [{ id: '2VGjUaeTyL', title: 'Full product tour' }],
  // Cambridge IGCSE™ English as a Second Language FE2024
  313: [{ id: 'GDQMB5P5Y5', title: 'Full product tour' }],
  // Cambridge IGCSE™ First Language English FE2020
  261: [{ id: 'ReiWe1aGRm', title: 'Full product tour' }],
  // Cambridge IGCSE™ Geography FE2024
  317: [{ id: 'RwfAo2b4tl', title: 'Full product tour' }],
  // Cambridge IGCSE™ ICT FE2023
  294: [{ id: 'yCSHQDJqv1', title: 'Full product tour' }],
  // Cambridge IGCSE™ International Mathematics (0607) FE2020
  189: [{ id: 'o3203vjvFg', title: 'Full product tour' }],
  // Cambridge IGCSE™ Mathematics (0580) FE2020
  117: [{ id: 'JDRtpAFOlj', title: 'Full product tour' }],
  // Cambridge IGCSE™ Physics FE2023
  297: [{ id: 'VfRaG64pYx', title: 'Full product tour' }],
  // IBDP Biology SL/HL FE2025
  422: [{ id: 'x1hLK8SeHE', title: 'Full product tour' }],
  // IBDP Chemistry SL/HL FE2025
  426: [{ id: 'eoS8g7DSol', title: 'Full product tour' }],
  // IBDP Physics SL/HL FE2025
  423: [{ id: 'XN7FFLDPV4', title: 'Full product tour' }],
  // IBDP Theory of knowledge FE2022
  188: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  // IBDP Environmental Systems and Societies SL/HL FE2026
  435: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  // IBDP subjects with default tour
  351: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  352: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  186: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  187: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  224: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  350: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  93: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  98: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  116: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  114: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  111: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  134: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  177: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  132: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  122: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  91: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  103: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
  301: [{ id: 'e4GEXUT83i', title: 'Full product tour' }],
};
