<template>
  <div class="flexContainer">
    <div
      v-for="(item, index) in legendItems"
      :key="index"
      class="AnswerStatusHeatmapLegend-block"
    >
      <span
        class="AnswerStatusHeatmapLegend-cell"
        :class="colorClass(index)"
      />
      <div class="text-small muted">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
const legendItems = [
  {
    title: 'Correct',
    color: 'GREEN',
  },
  {
    title: 'Incorrect',
    color: 'RED',
  },
  {
    title: 'Not answered',
    color: 'GRAY',
  },
];

export default {
  name: 'AnswerStatusHeatmapLegend',
  data() {
    return {
      legendItems,
    };
  },
  methods: {
    colorClass(index) {
      const color = this.legendItems[index].color.toLowerCase();
      return `AnswerStatusHeatmapLegend-cell--${color}`;
    },
  },
};
</script>
<style scoped>
.AnswerStatusHeatmapLegend-cell {
  display: inline-block;
  width: 72px;
  height: 20px;
  margin: 1px;
}

.AnswerStatusHeatmapLegend-block:first-of-type .AnswerStatusHeatmapLegend-cell {
  border-radius: 10px 0 0 10px;
}

.AnswerStatusHeatmapLegend-block:last-of-type .AnswerStatusHeatmapLegend-cell {
  border-radius: 0 10px 10px 0;
}

.AnswerStatusHeatmapLegend-cell--red {
  background: var(--kog-progressbar-single-negative-color);
}

.AnswerStatusHeatmapLegend-cell--green {
  background: var(--kog-progressbar-single-positive-color);
}

.AnswerStatusHeatmapLegend-cell--gray {
  background: var(--kog-progressbar-single-positive-background);
}
</style>
