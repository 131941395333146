<template>
  <kog-modal
    :maximize="true"
    :close-modal="closeModal"
    title="Review Updates"
    subtitle="The information below will be updated when you save"
  >
    <template #modalBody>
      <div
        class="padd-left-xl padd-right-xl padd-top-l padd-bottom-xl flexContainer text-regular UpdateGoogleClassroomModal-body"
      >
        <div class="flexChild-size-1">
          <google-class-modal-students-list
            :google-class="googleClass"
            :students="sortedGoogleClassStudentsList"
            title="Current connection"
          />
        </div>
        <div class="flexChild-size-1">
          <kog-loader
            :loading="$wait.is('polling_google_class')"
            loading-msg="Retrieving updated class"
          >
            <google-classroom-warning-box
              v-if="warningError"
              :warning-code="warningError"
              :origin="origin"
              :subject-class-id="subjectClass.id"
              :google-class-id="googleClass.id"
              request-type="update_class"
            />
            <google-class-modal-students-list
              v-else
              :google-class="updatedClass"
              :students="sortedUpdatedClassStudentsList"
              title="Update to"
            />
          </kog-loader>
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          class="KogButtonLegacy"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          class="KogButtonLegacy KogButtonLegacy--primary"
          :disabled="isSaveButtonDisabled"
          @click="saveUpdates()"
        >
          Save updates!
        </button>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import posthog from 'sharedApp/libs/posthog.ts';
import GoogleClassroomWarningBox from 'studyApp/components/teacher/google-classroom-warning-box/google-classroom-warning-box.vue';
import {
  GOOGLE_CLASSROOM_CLASS_ARCHIVED,
  IMPORT_CLASSES_RETRY_COUNT,
  IMPORT_CLASSES_RETRY_RATE_MS,
  STATUS_ARCHIVED,
} from 'studyApp/constants/google-classroom-constants.js';

import GoogleClassModalStudentsList from './google-class-modal-students-list.vue';

export default {
  name: 'UpdateGoogleClassroomModal',
  components: {
    GoogleClassModalStudentsList,
    KogLoader,
    KogModal,
    GoogleClassroomWarningBox,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    updateGoogleClass: {
      type: Function,
      required: true,
    },
    googleClass: {
      type: Object,
      required: true,
    },
    subjectClass: {
      type: Object,
      required: true,
    },
    trackUpdateGoogleClass: {
      type: Function,
      required: true,
    },
    origin: {
      type: String,
      required: true,
    },
  },
  computed: {
    sortedGoogleClassStudentsList() {
      const studentNames = this.googleClass.students.map(student => ({
        id: student.id,
        name: student.name,
      }));
      return studentNames.sort((student1, student2) => student1.name.localeCompare(student2.name));
    },
    sortedUpdatedClassStudentsList() {
      const studentNames = this.updatedClass.students?.map(student => ({
        id: student.id,
        name: student.full_name,
      }));
      return studentNames
        ? studentNames.sort((student1, student2) => student1.name.localeCompare(student2.name))
        : [];
    },
    isSaveButtonDisabled() {
      return this.$wait.is('polling_google_class') || this.warningError;
    },
    ...mapState({
      updatedClass: state => state.teacherGoogleClassroomModule.updatedClass,
      googleRequestError: state => state.teacherGoogleClassroomModule.requestError,
    }),
    warningError() {
      if (this.googleRequestError) {
        return this.googleRequestError;
      }
      if (this.updatedClass.status === STATUS_ARCHIVED) {
        return GOOGLE_CLASSROOM_CLASS_ARCHIVED;
      }
      return false;
    },
  },
  async mounted() {
    await this.importUpdatedGoogleClass();

    posthog.capture('open_update_google_classroom_modal');
  },
  methods: {
    saveUpdates() {
      this.updateGoogleClass();
      this.trackUpdateGoogleClass();
      this.closeModal();
    },
    async importUpdatedGoogleClass() {
      this.$wait.start('polling_google_class');
      await this.fetchGoogleClass({
        classId: this.subjectClass.id,
        upstreamClassId: this.googleClass.id,
        pollingIntervalMs: IMPORT_CLASSES_RETRY_RATE_MS,
        maxPollCount: IMPORT_CLASSES_RETRY_COUNT,
        timeoutMessage: 'Fetching google class took more than 5 minutes',
      });
      this.$wait.end('polling_google_class');
    },
    ...mapActions('teacherGoogleClassroomModule', ['fetchGoogleClass']),
  },
};
</script>

<style scoped>
.UpdateGoogleClassroomModal-body {
  min-height: 100px;
  background: var(--kogPlatformGray096);
}
</style>
