<template>
  <div class="StudentActivity">
    <div class="StudentActivity-containerLeft flexContainer flexContainer-column">
      <div>
        <div class="heading-xl"> {{ greeting }}, {{ userName }} </div>
      </div>
      <div class="text-regular">
        {{ schoolName }}
      </div>
    </div>
    <div
      class="StudentActivity-containerRight flexContainer flexContainer-column flexContainer-alignEnd margin-top-s"
    >
      <weekly-activity-summary :activity="activity" />
      <activity-streak-summary :aggregated-activity="aggregatedActivity" />
    </div>
  </div>
</template>

<script>
import { timeGreeting } from 'sharedApp/utils/time-utils.js';
import ActivityStreakSummary from 'studyApp/components/dashboard/activity-streak-summary.vue';
import WeeklyActivitySummary from 'studyApp/components/dashboard/weekly-activity-summary.vue';

export default {
  name: 'StudentActivity',
  components: {
    WeeklyActivitySummary,
    ActivityStreakSummary,
  },
  props: {
    userName: {
      type: String,
      default: '',
    },
    schoolName: {
      type: String,
      default: '',
    },
    activity: {
      type: Array,
      required: true,
      default: () => [],
    },
    aggregatedActivity: {
      type: Object,
      required: true,
      default: () => ({
        current_day_streak: 0,
        best_day_streak: 0,
        number_unique_days: 0,
      }),
    },
  },
  computed: {
    greeting() {
      return timeGreeting();
    },
  },
};
</script>

<style scoped>
.StudentActivity {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  max-width: 1170px;
  margin: auto;
  margin-top: var(--space-xl);
  padding: 0 var(--space-m) var(--space-m);
}

.StudentActivity-containerLeft {
  flex: 7;
}

.StudentActivity-containerRight {
  flex: 3;
}

@media (--viewport-m) {
  .StudentActivity {
    flex-direction: column;
  }

  .StudentActivity-containerLeft {
    width: 100%;
    margin-bottom: 24px;
    text-align: center;
  }

  .StudentActivity-containerRight {
    align-self: center;
  }

  .StudentActivity-greeting {
    font-weight: normal;
  }

  .StudentActivity-schoolName {
    display: none;
  }
}
</style>
