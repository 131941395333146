export const TEMP_ID = 'temp';

export type Annotation = {
  id?: number | typeof TEMP_ID;
  note: string | null;
  lastModified?: Date;
  last_modified: string;
  color: string;
  location_hint: number | null;
};

export type AnnotationMeta = {
  annotation: Annotation;
  confirmed: boolean;
  focused: boolean;
  isEditing: boolean;
  isSoftDeleted: boolean;
  location: {
    subject_name: string;
    subject_node_name: string;
  };
  subjectNodeId?: number;
  url: string;
};
