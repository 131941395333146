<template>
  <kog-alert
    mode="info"
    :title="`This section contains material in the ${$term('extended')} syllabus content only`"
  >
    If you think you should have access to this material, contact your teacher.
  </kog-alert>
</template>

<script>
import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';

export default {
  name: 'AllContentFilteredInfoBox',
  components: {
    KogAlert,
  },
};
</script>
