<template>
  <section class="kog-container">
    <div class="kog-row">
      <div class="kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
        <practice-setup-info
          class="margin-bottom-m"
          :source="source"
          :assignment-type="assignmentTypes.EXAMSTYLE"
        />
        <kog-card
          :default-padding="false"
          class="margin-bottom-l"
        >
          <div class="padd-l">
            <h1 class="divider-bottom margin-bottom-m padd-bottom-s heading-xl">
              {{ esqTerm }}
            </h1>
            <p class="text-bold">
              {{ esqTerm }} become available once you have received them in an assignment from your
              teacher.
            </p>
            <ol class="PracticeEsqSetup-nodeList">
              <li
                v-for="node in mappedNodes"
                :id="`KogTopic-${node.id}`"
                :key="node.id"
                class="PracticeEsqSetup-nodeRow margin-bottom-l"
                :class="{
                  'PracticeEsqSetup-nodeRow--selected': isNodeExpanded(node.id),
                  'display-none': node.level === 0,
                }"
              >
                <button
                  class="KogButtonLegacy--noStyle flexContainer flexContainer-alignCenter flexContainer-spaceBetween"
                  :class="{
                    cursorPointer: node.questionCount > 0,
                    'PracticeEsqSetup--nodeSelected': isNodeExpanded(node.id),
                  }"
                  aria-haspopup="true"
                  :aria-expanded="isNodeExpanded(node.id) ? 'true' : 'false'"
                  @click="toggleNodeExpanded(node)"
                >
                  <span>
                    {{ node.formatted_number_including_ancestors }} {{ node.name }}
                    <content-hidden-label v-if="!isNodeVisible(node.id)" />
                  </span>
                  <span
                    v-if="node.questionCount >= 1"
                    class="kogPlatformGray031 text-small"
                  >
                    {{ node.unlockedQuestionCount }} / {{ node.questionCount }} questions
                  </span>
                </button>

                <ol
                  v-show="isNodeExpanded(node.id)"
                  class="PracticeEsqSetup-nodeList"
                >
                  <li
                    v-for="(question, index) in node.questions"
                    :key="question.id"
                    class="PracticeEsqSetup-questionRow"
                  >
                    <template v-if="isQuestionLocked(question)">
                      <div
                        class="flexContainer flexContainer-alignCenter padd-xs padd-left-s padd-right-xl"
                      >
                        <kog-icon
                          v-kog-focusable="true"
                          v-tooltip="{
                            theme: 'kog-tooltip',
                            content: 'To unlock this question, request access from your teacher',
                            popperClass: 'text-center',
                          }"
                          fa-style="regular"
                          icon-class="fa-lock-alt"
                          theme="custom"
                          aria-label="To unlock this question, request access from your teacher"
                        />
                        <span class="padd-left-xxs muted">
                          Question {{ node.formatted_number_including_ancestors }}{{ index + 1 }}
                        </span>
                        <papertype-tag
                          v-if="question.papertype"
                          class="margin-left-xs"
                          :name="question.papertype.name"
                          :should-prefix-number-papertype="true"
                        />
                        <kog-tag
                          v-if="question.marks"
                          class="margin-left-xxs"
                          :label="marksInfo(question)"
                        />
                      </div>
                    </template>
                    <template v-else>
                      <router-link
                        class="flexContainer flexContainer-alignCenter PracticeEsqSetup-questionLink padd-xs padd-left-xxl padd-right-xl"
                        :to="getQuestionRowUrl(node.id, question)"
                      >
                        <span class="text-nowrap">
                          Question {{ node.formatted_number_including_ancestors }}{{ index + 1 }}
                        </span>
                        <papertype-tag
                          v-if="question.papertype"
                          class="margin-left-xs"
                          :name="question.papertype.name"
                          :should-prefix-number-papertype="true"
                        />
                        <kog-tag
                          v-if="question.marks"
                          class="margin-left-xxs"
                          :label="marksInfo(question)"
                        />
                        <div
                          class="PracticeEsqSetupContainer-OpenInEsqText margin-left-m muted text-ellipsis"
                        >
                          Opens in '{{ question.question_info.latest_assignment_name }}'
                        </div>
                      </router-link>
                    </template>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </kog-card>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, nextTick } from 'vue';
import { useHead } from '@unhead/vue';
import { VTooltip } from 'floating-vue';
import { mapGetters, mapState, useStore } from 'vuex';

import PapertypeTag from 'learning/common/components/papertype-tag.vue';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import PracticeSetupInfo, {
  VALID_SOURCES,
} from 'studyApp/components/practice/practice-setup-info.vue';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';
import QuestionMixin from 'studyApp/mixins/question-mixin.js';

export default {
  name: 'PracticeEsqSetup',
  components: {
    ContentHiddenLabel,
    KogCard,
    KogIcon,
    KogTag,
    PapertypeTag,
    PracticeSetupInfo,
  },
  directives: {
    tooltip: VTooltip,
  },
  mixins: [RoutesMixin, QuestionMixin],
  setup() {
    const store = useStore();
    const { subjectModule } = store.state;

    const subjectName = computed(() => subjectModule.subject.name);

    useHead({
      title: `Setup exam-style questions | ${subjectName.value}`,
    });
  },
  data() {
    return {
      nodeOpenStates: {},
      assignmentTypes: ASSIGNMENT_TYPES,
      source: VALID_SOURCES.ESQ_PRACTICE_SETUP,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      questionMappings: state => state.examStyleQuestionModule.questionMappings,
    }),
    ...mapGetters({
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    esqTerm() {
      return `${this.$term('exam-style')} questions`;
    },
    mappedNodes() {
      if (!this.subject || !this.questionMappings) {
        return [];
      }
      const topics = this.subject.subject_tree[0].children;
      const mappings = this.questionMappings;
      const rootSubjectnode = this.subject.subject_tree[0];
      const nodes = [rootSubjectnode, ...topics];
      return nodes.map(node => this.mapQuestionsToNode(node, mappings));
    },
  },
  created() {
    nextTick(this.scrollToTopic);
  },
  methods: {
    mapQuestionsToNode(node, mappings) {
      const mappingsForNode = mappings.find(mapping => mapping.node_id === node.id);
      const questions = mappingsForNode ? mappingsForNode.questions : [];
      const questionCount = questions.length;
      const unlockedQuestionCount = questions.filter(
        question => question.question_info.is_locked === false,
      ).length;
      return {
        ...node,
        questions,
        questionCount,
        unlockedQuestionCount,
      };
    },
    isNodeExpanded(nodeId) {
      return this.nodeOpenStates[nodeId];
    },
    toggleNodeExpanded(node) {
      if (node.questionCount > 0) {
        this.nodeOpenStates[node.id] = !this.isNodeExpanded(node.id);
      }
    },
    getQuestionRowUrl(nodeId, question) {
      return {
        name: 'classEsqAssignmentOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: question.question_info.latest_assignment_id,
        },
      };
    },
    isQuestionLocked(question) {
      return question.question_info.is_locked;
    },
    scrollToTopic() {
      const { topicId } = this.$route.params;
      if (!topicId) {
        return;
      }
      this.nodeOpenStates[topicId] = true;
      const topicToScrollTo = document.getElementById(`KogTopic-${topicId}`);
      const studyNavbar = document.getElementsByClassName('StudyNavbar')[0];
      if (topicToScrollTo) {
        window.scrollTo(0, topicToScrollTo.offsetTop - studyNavbar.offsetHeight);
      }
    },
  },
};
</script>

<style scoped>
.PracticeEsqSetup-nodeList {
  padding-left: 0;
  list-style: none;
}

.PracticeEsqSetup--nodeSelected {
  background-color: var(--kog-list-item-selected-background);
}

.PracticeEsqSetup-nodeRow {
  border: 1px solid var(--kogPlatformGray084);
  border-radius: 4px;
}

.PracticeEsqSetup-nodeRow > button {
  width: 100%;
  padding: var(--space-s) var(--space-m);
}

.PracticeEsqSetup-questionRow {
  border-bottom: 1px solid var(--kogPlatformGray084);
}

.PracticeEsqSetup-questionRow:last-child {
  border-bottom: none;
}

.PracticeEsqSetup-nodeRow:not(.PracticeEsqSetup-nodeRow--selected):hover,
.PracticeEsqSetup-questionRow:hover {
  background: var(--kog-list-item-hover-background);
}

.PracticeEsqSetup-questionLink {
  color: var(--kogPlatformGray018);
}

.PracticeEsqSetup-questionLink:hover,
.PracticeEsqSetup-questionLink:active,
.PracticeEsqSetup-questionLink:focus,
.PracticeEsqSetup-questionLink:visited {
  text-decoration: none;
  outline: none;
}

.PracticeEsqSetup-questionLink .PracticeEsqSetupContainer-openInEsqText {
  opacity: 0;
}

.PracticeEsqSetup-questionLink:hover .PracticeEsqSetupContainer-openInEsqText {
  opacity: 1;
}
</style>
