<template>
  <kog-modal
    title="Upload images"
    subheader="Upload one or more images to the unit "
    :close-modal="closeModal"
  >
    <template #modalBody>
      <driving-question-upload
        class="padd-m"
        :subject-node-id="subjectNodeId"
        @content-altered="closeModal"
      />
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import DrivingQuestionUpload from 'studyApp/components/driving-question/driving-question-upload.vue';

export default {
  name: 'DrivingQuestionUploadModal',
  components: {
    KogModal,
    DrivingQuestionUpload,
  },
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
};
</script>
