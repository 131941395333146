<template>
  <div
    ref="root"
    class="SubjectOverviewSubtopic"
  >
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div
      v-kog-clickable="isEditingVisibility ? toggleVisibility : undefined"
      v-kog-description:[subtopic.id]="isEditingVisibility ? 'Toggle content visibility' : ''"
      class="SubjectOverviewSubtopic-header margin-bottom-l"
      @mouseover="hoverHeading = true"
      @mouseleave="hoverHeading = false"
      @focusin="hoverHeading = true"
      @focusout="hoverHeading = false"
    >
      <div class="SubjectOverviewSubtopic-headerCover">
        <div
          class="absoluteContainer"
          :class="{
            wiggle02: isEditingVisibility,
          }"
        >
          <subject-node-visibility-toggle
            ref="visibilityToggle"
            class="SubjectOverviewSubtopic-visibilityToggle"
            :subject-node-id="subtopic.id"
            :activated="hoverHeading"
          />
          <image-box
            v-if="hasSubtopicImage"
            :key="subtopic.image_url && useImageReplacement"
            class="SubjectOverviewSubtopic-image"
            :src="subtopic.image_url"
            :w="132"
            :h="132"
            :quality="100"
            fit="crop"
            crop="entropy"
            @error="useImageReplacement = true"
          />
          <div
            v-else
            aria-hidden="true"
            class="SubjectOverviewSubtopic-image SubjectOverviewSubtopic-imageReplacement flexContainer flexContainer-center heading-xl"
          >
            {{ subtopic.formatted_number_including_ancestors }}
          </div>
        </div>
      </div>

      <div class="SubjectOverviewSubtopic-headerContent">
        <span
          aria-hidden="true"
          class="text-overline muted"
        >
          {{ $term('subtopic') }} {{ subtopic.formatted_number_including_ancestors }}
        </span>
        <content-hidden-label
          :class="{
            visibilityHidden: isSubtopicVisible,
          }"
          class="margin-left-xxs"
        />
        <h3 class="screenreader-only">
          {{ getAriaLabel }}
        </h3>
        <h3
          aria-hidden="true"
          class="heading-m margin-bottom-xs"
        >
          {{ subtopic.name }}
        </h3>
      </div>

      <div class="SubjectOverviewSubtopic-progress">
        <div v-if="showQuestionCompletionBar || studentProgressAvailable">
          <subtopic-question-completion
            v-if="showQuestionCompletionBar"
            :subtopic="subtopic"
          />
          <div
            v-else-if="studentProgressAvailable"
            class="flexContainer flexContainer-column"
          >
            <div
              :id="`strength-label-${subtopic.id}`"
              class="text-bold text-small"
            >
              Strength &nbsp;
              <i
                v-if="isQuestionProgressBarEnabled"
                v-kog-focusable="true"
                v-tooltip="{
                  theme: 'kog-tooltip',
                  // eslint-disable-next-line max-len
                  content:
                    'The progress bar indicates your knowledge. Change the progress bar type in account settings.',
                  popperClass: 'text-center',
                  boundary: 'document.body',
                }"
                class="far fa-question-circle"
              />
            </div>
            <div
              class="SubjectOverviewSubtopic-strengthStats flexContainer flexContainer-alignCenter"
            >
              <stats-emoji
                :stats-percent="strengthStatsPercent"
                class="padd-right-xs"
              />
              <kog-progress-bar
                :progress="strengthStatsPercent"
                :labelled-by="`strength-label-${subtopic.id}`"
                size="s"
                type="primary"
              />
            </div>
          </div>
        </div>
        <div v-if="userProgressStats">
          <div class="text-small text-bold"> Completed activities </div>
          <div class="SubjectOverviewSubtopic-progressCompletedActivities text-small">
            {{ userProgressStats.completed_leafs_quantity }}
            / {{ userProgressStats.total_leafs_quantity }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="shouldShowSupportingMaterial">
      <div class="heading-s font-satoshi padd-left-xs margin-bottom-s"> Supporting materials </div>
      <supporting-material-link-list
        :materials="subtopic.supporting_materials"
        :user="user"
      />
    </div>
    <div>
      <div class="heading-s font-satoshi text-capitalize padd-left-xs margin-bottom-s">
        {{ pluralize($term('section')) }}
      </div>
      <content-node-list
        :content-nodes="subtopic.children"
        content-type="section"
        :class-size="classSize"
        :user-nodes-reading-progress="userNodesReadingProgress"
        :reading-completion-data-per-node-id="readingCompletionDataPerNodeId"
        @clickButton="goToFirstNode"
        @clickContainer="onSectionClick"
        @clickCompletionStats="$event => $emit('clickCompletionStats', $event)"
      />
    </div>
    <template v-if="hasStrengthQuestions">
      <div class="heading-s font-satoshi padd-left-xs margin-top-s"> Strength questions </div>
      <content-node-item
        :row-aria-label="`Open student strength question progress for ${$term('subtopic')} ${
          subtopic.name
        }`"
        title="Strength questions"
        :subtitle="strengthTestSubtitle"
        icon="fa-book"
        icon-style="regular"
        icon-background-color="orange"
        @clickContainer="onStrengthQuestionsClick"
      />
    </template>
  </div>
</template>

<script>
import pluralize from 'pluralize';
import { mapActions, mapGetters, mapState } from 'vuex';

import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import KogFocusable from 'sharedApp/directives/kog-focusable.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';
import SubjectNodeVisibilityToggle from 'studyApp/components/subject-class-overview/subject-node-visibility-toggle.vue';
import useToggleNodeVisibilityEventHandler from 'studyApp/composables/use-toggle-node-visibility-event-handler.js';

import StatsEmoji from '../subject-overview/stats-emoji.vue';
import SubtopicQuestionCompletion from '../subject-overview/subtopic-question-completion.vue';
import ContentNodeItem from './content-node-item.vue';
import ContentNodeList from './content-node-list.vue';
import SectionWithCompletionStatsModal from './section-with-completion-stats-modal.vue';
import StrengthQuestionsModal from './strength-questions-modal.vue';
import SupportingMaterialLinkList from './supporting-material-link-list.vue';

export default {
  name: 'SubjectOverviewSubtopic',
  components: {
    ContentHiddenLabel,
    ContentNodeList,
    ContentNodeItem,
    KogProgressBar,
    ImageBox,
    SubtopicQuestionCompletion,
    SupportingMaterialLinkList,
    StatsEmoji,
    SubjectNodeVisibilityToggle,
  },
  directives: {
    KogClickable,
    KogFocusable,
    KogDescription,
  },
  mixins: [RoutesMixin],
  inject: ['isFilteringSubjectClassOverview'],
  props: {
    students: {
      type: Array,
      required: true,
    },
    subtopic: {
      type: Object,
      required: true,
    },
    progressStats: {
      type: Object,
      default: () => {},
    },
    bookCompletion: {
      type: Object,
      required: true,
    },
  },
  emits: ['clickCompletionStats'],
  setup() {
    const { handleToggleNodeVisibilityEvent } = useToggleNodeVisibilityEventHandler();

    return {
      handleToggleNodeVisibilityEvent,
    };
  },
  data() {
    return {
      isQuestionProgressBarEnabled: false,
      useImageReplacement: false,
      hoverHeading: false,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
      user: state => state.userModule.user,
      isEditingVisibility: state => state.subjectClassModule.isEditingVisibility,
    }),
    ...mapGetters({
      userNodesStrengthProgress: 'statisticModule/strengthStats',
      leafNodesWithContent: 'subjectModule/leafNodesWithContent',
      isNodeHiddenWhileEditing: 'subjectClassModule/isNodeHiddenWhileEditing',
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    isSubtopicVisible() {
      if (this.user.isStudent()) {
        return true;
      }
      return !this.isNodeHiddenWhileEditing(this.subtopic);
    },
    userNodesReadingProgress() {
      return this.progressStats;
    },
    studentProgressAvailable() {
      if (this.user.isTeacher()) {
        return false;
      }

      const subtopicId = this.subtopic.id;
      return (
        this.userNodesStrengthProgress[subtopicId] && this.userNodesReadingProgress[subtopicId]
      );
    },
    educationSystemName() {
      return this.subject.educationsystem_name || this.subject.educationsystem.name;
    },
    showQuestionCompletionBar() {
      return this.studentProgressAvailable && this.isQuestionProgressBarEnabled;
    },
    strengthTestSubtitle() {
      if (isNgss(this.educationSystemName)) {
        return `${this.subtopic.strength_test_questions_count} Questions`;
      }
      return null;
    },
    strengthStatsPercent() {
      return this.userStrengthStats?.percent ?? 0;
    },
    userProgressStats() {
      const progress = this.userNodesReadingProgress[this.subtopic.id];
      return progress && progress.progress_stats;
    },
    userStrengthStats() {
      const progress = this.userNodesStrengthProgress[this.subtopic.id];
      return progress && progress.strength_stats;
    },
    hasSubtopicImage() {
      return Boolean(this.subtopic.image_url) && !this.useImageReplacement;
    },
    hasStrengthQuestions() {
      return this.subtopic.strength_test_questions_count > 0;
    },
    classSize() {
      return this.subjectClass?.students_count ?? 0;
    },
    readingCompletionDataPerNodeId() {
      const nodeCompletionObj = { ...this.bookCompletion.perNodeId };
      const userCompletionObj = this.bookCompletion.perUserId;

      const sections = this.subtopic.children;
      sections.forEach(section => {
        const nodeId = section.id;
        const subsections = section.children;

        if (!nodeCompletionObj[nodeId]) {
          nodeCompletionObj[nodeId] = new Set();
        }

        subsections.forEach(subsection => {
          if (!nodeCompletionObj[subsection.id]) {
            nodeCompletionObj[subsection.id] = new Set();
          }
        });

        const userIds = Object.keys(userCompletionObj);
        userIds.forEach(userId => {
          const isSectionCompleted =
            (subsections.length > 0 &&
              subsections.every(subsection => userCompletionObj[userId].has(subsection.id))) ||
            userCompletionObj[userId].has(section.id);

          if (isSectionCompleted) {
            nodeCompletionObj[nodeId].add(parseInt(userId, 10));
          }
        });
      });

      return nodeCompletionObj;
    },
    shouldShowSupportingMaterial() {
      return (
        !this.user.isStudent() &&
        this.subtopic.supporting_materials?.length > 0 &&
        !this.isFilteringSubjectClassOverview
      );
    },
    getAriaLabel() {
      return `${this.$term('subtopic')} ${this.subtopic.formatted_number_including_ancestors}, ${
        this.subtopic.name
      }`;
    },
  },
  async mounted() {
    await this.fetchIsQuestionProgressBarEnabled();
  },
  methods: {
    ...mapActions({
      toggleNodeVisibility: 'subjectClassModule/toggleNodeVisibility',
    }),
    pluralize,
    async fetchIsQuestionProgressBarEnabled() {
      this.isQuestionProgressBarEnabled = await this.$featureFlags.isQuestionProgressBarEnabled();
    },
    openSectionModal(sectionNode) {
      this.$modal(SectionWithCompletionStatsModal, {
        sectionNode,
        navigateToClassBook: this.navigateToClassBook,
        classSize: this.classSize,
        userNodesReadingProgress: this.userNodesReadingProgress,
        userNodesStrengthProgress: this.userNodesStrengthProgress,
        students: this.students || [],
        readingCompletionDataPerNodeId: this.readingCompletionDataPerNodeId,
        readingCompletionDataPerUserId: this.bookCompletion.perUserId,
      });
    },
    onSectionClick(sectionNode) {
      if (sectionNode.children.length > 0) {
        this.openSectionModal(sectionNode);
      }
    },
    onStrengthQuestionsClick() {
      if (this.user.isTeacher()) {
        this.openStrengthQuestionsModal();
      } else {
        this.navigateToStrengthQuestions();
      }
    },
    openStrengthQuestionsModal() {
      this.$modal(StrengthQuestionsModal, {
        students: this.students,
        subjectNode: this.subtopic,
      });
    },
    navigateToClassBook(node) {
      this.$router.push({
        name: 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
      });
    },
    navigateToStrengthQuestions() {
      this.$router.push({
        name: 'classStrengthTestSetup',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
        query: {
          selected_nodes: `${this.subtopic.id}`,
        },
      });
    },
    goToFirstNode(sectionNode) {
      if (sectionNode.children.length > 0) {
        const [subSectionNode] = sectionNode.children;
        this.navigateToClassBook(subSectionNode);
      } else {
        this.navigateToClassBook(sectionNode);
      }
    },
    toggleVisibility() {
      this.handleToggleNodeVisibilityEvent(this.subtopic);
    },
  },
};
</script>

<style scoped>
.SubjectOverviewSubtopic {
  scroll-margin-top: var(--full-page-navbar-height);
}

.SubjectOverviewSubtopic-header {
  display: grid;
  grid-template-areas:
    'headerCover headerContent'
    'headerCover progress';
  grid-template-columns: fit-content(100%) auto;
}

.SubjectOverviewSubtopic-headerCover {
  grid-area: headerCover;
  align-self: center;
}

.SubjectOverviewSubtopic-headerContent {
  grid-area: headerContent;
  width: 100%;
}

.SubjectOverviewSubtopic-progress {
  display: grid;
  grid-area: progress;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: var(--space-m);
}

.SubjectOverviewSubtopic-progressCompletedActivities {
  font-size: 10px;
}

.SubjectOverviewSubtopic-strengthStats {
  padding-top: 3px;
}

.SubjectOverviewSubtopic-image.SubjectOverviewSubtopic-imageReplacement {
  background-color: var(--kog-background-brand-200);
  border: 1px solid var(--kog-component-outline-border-color);
}

.SubjectOverviewSubtopic-image {
  overflow: hidden;

  width: 132px;
  min-width: 132px;
  height: 132px;
  margin-right: var(--space-l);

  background-position: center;
  background-size: cover;
  border-radius: 12px;
}

.u-divider {
  margin-top: var(--space-xl);
  padding-top: var(--space-s);
  border-top: 1px solid var(--kogPlatformGray090);
}

.SubjectOverviewSubtopic-visibilityToggle {
  position: absolute;
  right: 92%;
  bottom: 90%;
}

@media (--viewport-xs) {
  .SubjectOverviewSubtopic-header {
    grid-template-areas:
      'headerCover headerContent'
      'progress progress';
  }
}

@media (--viewport-s) {
  .SubjectOverviewSubtopic-image {
    width: 64px;
    min-width: 64px;
    height: 64px;
  }
}
</style>
