<template>
  <div class="BookStatisticsContainer flexContainer text-small">
    <div
      v-if="statisticsToRender.length > 0"
      class="width-100"
    >
      <div
        v-for="classStats in statisticsToRender"
        :key="classStats.subject_class_id"
        class="tempContainer flexContainer flexContainer-alignCenter padd-xs flexContainer-spaceBetween"
      >
        <div class="flexContainer flexChild-size-1 flexContainer-alignCenter">
          <img
            class="margin-right-s"
            :src="completionIcon"
            alt=""
            role="presentation"
          />
          <div class="flexContainer flexContainer-justifyCenter flexContainer-column">
            <span class="heading-xs margin-right-xs"> Sections completed </span>
            <span
              v-if="statisticsToRender.length > 1"
              class="muted"
            >
              {{ classStats.subject_class }}
            </span>
          </div>
        </div>
        <div class="flexContainer flexContainer-alignCenter">
          <kog-tag
            is-chip
            class="margin-right-m"
            :icon-class="completionTagIconClass"
            :label="completionTagLabel(classStats)"
            :context="completionTagContext(classStats)"
            size="m"
            type="informative"
            @keydown.enter.stop="openStatisticDetail(classStats)"
            @keydown.space.stop.prevent="openStatisticDetail(classStats)"
            @click.stop="openStatisticDetail(classStats)"
          />
        </div>
      </div>
      <div
        v-if="isShowingReflectionQuestionsStatistics"
        class="tempContainer flexContainer flexContainer-alignCenter padd-xs margin-top-xs flexContainer-spaceBetween"
      >
        <div class="flexContainer flexChild-size-1 flexContainer-alignCenter">
          <img
            class="margin-right-s"
            :src="reflectionQuestionIcon"
            alt=""
            role="presentation"
          />
          <div class="flexContainer flexContainer-justifyCenter flexContainer-column">
            <span class="heading-xs margin-right-xs"> Reflections submitted </span>
          </div>
        </div>
        <div class="flexContainer flexContainer-alignCenter">
          <router-link :to="sectionResponsesRoute">
            <kog-tag
              is-chip
              class="margin-right-m js-BookStatisticsContainer-goToReflectionAnswersLink"
              :icon-class="completionTagIconClass"
              :label="reflectionQuestionsTagLabel"
              :context="reflectionQuestionsTagContext"
              size="m"
              type="informative"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { SOURCES } from 'learning/common/components/assignment/create-assignment-button.vue';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import reflectionSectionIcon from 'sharedApp/img/study/subject-class-overview-reflection-section.svg';
import sectionIcon from 'sharedApp/img/study/subject-class-overview-section.svg';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import SectionCompletionModal from 'teachApp/components/section-completion-modal.vue';

export default {
  name: 'BookStatisticsContainer',
  components: {
    KogTag,
  },
  mixins: [RoutesMixin],
  props: {
    subject: {
      type: Object,
      required: true,
    },
    subTopicId: {
      type: Number,
      required: true,
    },
    subjectNodeId: {
      type: Number,
      required: true,
    },
    subjectNodeTitle: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      required: true,
    },
    isShowingReflectionQuestionsStatistics: {
      type: Boolean,
      default: false,
    },
    classSlug: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      reflectionQuestions: state => state.questionsModule.reflectionQuestions,
      reflectionQuestionsAnswersCount: state =>
        state.exerciseOccasionsModule.exerciseOccasionsCount,
      subjectClassStudents: state => state.teacherStudentListModule.subjectClassStudentsList,
      subTopicStats: state => state.inlineStatisticsModule.subTopicStats,
    }),
    ...mapGetters({
      statistics: 'inlineStatisticsModule/classStats',
      subjectNodesById: 'subjectModule/subjectNodesById',
    }),
    sectionResponsesRoute() {
      const { classSlug, sid, cid, nodeSlug, nodeId } = this.$route.params;
      return {
        name: 'teacherSectionResponsesOverview',
        params: {
          classSlug,
          sid,
          cid,
          nodeSlug,
          nodeId,
        },
        query: {
          origin: 'book_section',
        },
      };
    },
    reflectionQuestionCount() {
      return this.reflectionQuestions?.length || 0;
    },
    studentsCount() {
      return this.subjectClassStudents?.length || 0;
    },
    reflectionQuestionsMaxAnswerCount() {
      return this.studentsCount * this.reflectionQuestionCount;
    },
    reflectionQuestionsTagLabel() {
      return `${this.reflectionQuestionsAnswersCount}/${this.reflectionQuestionsMaxAnswerCount}`;
    },
    reflectionQuestionsTagContext() {
      return `${this.reflectionQuestionsAnswersCount}/${this.reflectionQuestionsMaxAnswerCount}
          Submitted responses to "Your reflections" exercises`;
    },
    completionTagIconClass() {
      return 'far fa-user-check';
    },
    completionIcon() {
      return sectionIcon;
    },
    reflectionQuestionIcon() {
      return reflectionSectionIcon;
    },
    statisticsToRender() {
      return this.statistics.filter(statItem => statItem.subject_class_id === this.classId);
    },
  },
  watch: {
    subjectNodeId: {
      immediate: true,
      handler() {
        if (this.isShowingReflectionQuestionsStatistics) {
          this.fetchExerciseOccasionsCount({
            subjectNodeId: this.subjectNodeId,
            subjectClassId: this.classId,
          });
        }
      },
    },
  },
  methods: {
    ...mapActions('inlineStatisticsModule', ['fetchStudentsSectionCompletion']),
    ...mapActions('exerciseOccasionsModule', {
      fetchExerciseOccasionsCount: 'fetchExerciseOccasionsCount',
    }),
    openStatisticDetail(statistic) {
      this.openSectionCompletionModal(statistic);
    },
    async openSectionCompletionModal(statistic) {
      const {
        subjectNodeId,
        subTopicId,
        subject,
        subjectNodeTitle,
        subtitle,
        user,
        classSlug,
        subjectNodesById,
      } = this;
      const subjectId = subject.id;
      const subjectClassId = statistic.subject_class_id;
      const subjectNode = subjectNodesById[subjectNodeId];
      const completionData = await this.getMappedCompletionData(
        subjectId,
        subTopicId,
        subjectClassId,
        subjectNodeId,
      );
      this.$modal(
        SectionCompletionModal,
        {
          subjectNode,
          completionData,
          subjectClassId,
          subjectClassName: statistic.subject_class,
          sectionTitle: subjectNodeTitle,
          subtitle,
          subject,
          user,
          classSlug,
          source: SOURCES.TEXTBOOK_SECTION_MODAL,
        },
        {
          attachModalToTop: true,
        },
      );
    },
    async getMappedCompletionData(subjectId, subTopicId, subjectClassId, subjectNodeId) {
      const completionData = await this.fetchStudentsSectionCompletion({
        subjectId,
        subTopicId,
        subjectClassId,
        subjectNodeId,
      });
      return completionData.map(data => {
        const { user_id: id, name, avatar_url: avatarUrl, completed: isCompleted, level } = data;
        const completionText = isCompleted ? 'Completed' : '';
        return {
          id,
          name,
          avatarUrl,
          isCompleted,
          completionText,
          level,
        };
      });
    },
    completionTagLabel(classStats) {
      return `${classStats.students_completed_nodes[this.subjectNodeId] || 0}/${
        classStats.students_total
      }`;
    },
    completionTagContext(classStats) {
      return `${classStats.students_completed_nodes[this.subjectNodeId] || 0} of ${
        classStats.students_total
      }
          students have completed this section`;
    },
  },
};
</script>

<style scoped>
.tempContainer {
  background: var(--kog-colors-aubergine-100);
  border-radius: 12px;
}
</style>
