<template>
  <div>
    <tag-radio-group
      v-model:selected-value="selectedFilter"
      class="padd-top-xxs"
      group-label="Student list filter"
      :options="filterOptions"
    />
    <div class="margin-top-m">
      <div
        class="flexContainer flexContainer-spaceBetween text-regular margin-bottom-xs margin-right-l"
      >
        <span>Student</span>
        <span>Reflections</span>
      </div>
      <vertical-expand>
        <kog-loader
          v-if="isLoading"
          :loading="isLoading"
          loading-msg=""
        />
        <div v-else>
          <completion-stats-modal-body-item
            v-for="student in filteredStudentsList"
            :key="student.id"
            :student-name="student.name"
            :has-completion-column="true"
            :completion-text="getCompletionText(student.user_id)"
          />
          <completion-stats-modal-body-item
            v-if="filteredStudentsList.length === 0"
            :is-empty-row="true"
          />
        </div>
      </vertical-expand>
    </div>
    <div class="flexContainer flexContainer-flexEnd margin-top-l">
      <router-link
        :to="heatmapRoute"
        @click="$emit('close-modal')"
      >
        <kog-button
          label="View insights"
          button-style="basic"
        />
      </router-link>
      <router-link
        :to="reflectionAnswersRoute"
        @click="$emit('close-modal')"
      >
        <kog-button
          label="View student answers"
          button-style="primary"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import { SHOW_ALL_DATES } from 'sharedApp/components/datetime/interval-selector.vue';
import TagRadioGroup from 'sharedApp/components/filter/tag-radio-group.vue';

import CompletionStatsModalBodyItem from './completion-stats-modal-body-item.vue';

export default {
  name: 'ReflectionQuestionCompletionStatsModalBody',
  components: {
    KogButton,
    KogLoader,
    CompletionStatsModalBodyItem,
    TagRadioGroup,
    VerticalExpand,
  },
  props: {
    subjectNode: {
      type: Object,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
    initialFilter: {
      type: String,
      default: 'submitted',
      validator: prop => ['submitted', 'notSubmitted', 'flagged'].includes(prop),
    },
  },
  emits: ['close-modal'],
  data() {
    return {
      selectedFilter: this.initialFilter,
    };
  },
  computed: {
    ...mapState({
      reflectionQuestions: state => state.questionsModule.reflectionQuestions,
    }),
    ...mapGetters({
      getAllAnsweredLtqsGroupedByUser: 'exerciseOccasionsModule/getAllAnsweredLtqsGroupedByUser',
      getLtqAnswersGroupedByUser: 'exerciseOccasionsModule/getLtqAnswersGroupedByUser',
      getTopicNodeByDescendantId: 'subjectModule/getTopicNodeByDescendantId',
    }),
    topic() {
      return this.getTopicNodeByDescendantId(this.subjectNode.id);
    },
    filterOptions() {
      const options = [
        {
          label: `Submitted ${this.submittedStudentList.length}`,
          value: 'submitted',
        },
        {
          label: `Not submitted ${this.notSubmittedStudentList.length}`,
          value: 'notSubmitted',
        },
        {
          label: `Flagged ${this.flaggedStudentList.length}`,
          value: 'flagged',
        },
      ];

      return options;
    },
    answeredReflectionQuestionsGroupedByUser() {
      const allAnsweredLtqIds = { ...this.getAllAnsweredLtqsGroupedByUser };
      Object.keys(allAnsweredLtqIds).forEach(user => {
        allAnsweredLtqIds[user] = allAnsweredLtqIds[user].filter(value =>
          this.reflectionQuestionIds.includes(value),
        );
      });
      return allAnsweredLtqIds;
    },
    reflectionQuestionIds() {
      return this.reflectionQuestions.map(question => question.id);
    },
    submittedStudentList() {
      return this.students.filter(student => {
        const studentExerciseOccasions =
          this.answeredReflectionQuestionsGroupedByUser[student.user_id] || [];
        return studentExerciseOccasions.length === this.reflectionQuestions.length;
      });
    },
    submittedStudentIdSet() {
      return new Set(this.submittedStudentList.map(student => student.user_id));
    },
    notSubmittedStudentList() {
      return this.students.filter(student => !this.submittedStudentIdSet.has(student.user_id));
    },
    flaggedStudentList() {
      const ltqId = this.reflectionQuestionIds[0];
      const ltqAnswersByUser = this.getLtqAnswersGroupedByUser(ltqId);
      Object.keys(ltqAnswersByUser).forEach(user => {
        ltqAnswersByUser[user] = ltqAnswersByUser[user].filter(occasion =>
          occasion.chosenSubjectNodes.includes(this.subjectNode.id),
        );
      });
      const flaggedUserIds = Object.keys(ltqAnswersByUser)
        .filter(userId => ltqAnswersByUser[userId]?.length > 0)
        .filter(userId => ltqAnswersByUser[userId][0].flaggedByTeacher)
        .map(userId => parseInt(userId, 10));
      return this.students.filter(student => flaggedUserIds.includes(student.user_id));
    },
    filteredStudentsList() {
      if (this.selectedFilter === 'submitted') {
        return this.submittedStudentList;
      }
      if (this.selectedFilter === 'notSubmitted') {
        return this.notSubmittedStudentList;
      }
      return this.flaggedStudentList;
    },
    isLoading() {
      return this.$wait.is(['loading_exercise_questions']);
    },
    heatmapRoute() {
      const filtersObject = {
        student: '',
        subjectNode: this.topic.id,
        activities: [],
        datesInterval: SHOW_ALL_DATES,
      };

      return {
        name: 'teacherInsights',
        params: {
          classSlug: this.subjectClassSlug,
          cid: this.classId,
          sid: this.subjectId,
          source: 'class_overview',
        },
        query: {
          active_tab: 'reflections',
          filters: JSON.stringify(filtersObject),
        },
      };
    },
    reflectionAnswersRoute() {
      return {
        name: 'teacherSectionResponsesOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: this.subjectNode.slug,
          nodeId: this.subjectNode.id,
        },
        query: {
          origin: 'class_overview',
          origin_node: this.$route.query.node,
        },
      };
    },
  },
  created() {
    this.fetchReflectionQuestions(this.subjectNode.id);
  },
  methods: {
    ...mapWaitingActions('questionsModule', {
      fetchReflectionQuestions: 'loading_exercise_questions',
    }),
    getCompletionText(userId) {
      const completed = this.answeredReflectionQuestionsGroupedByUser[userId]?.length || 0;
      const total = this.reflectionQuestions.length;
      return `${completed} / ${total}`;
    },
  },
};
</script>

<style scoped>
.flexContainer :deep(button) {
  width: 163px;
  margin-left: var(--space-s);
}
</style>
