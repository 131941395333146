import difference from 'lodash/difference.js';

const subjectKeys = {
  IGCSE: 'IGCSE',
  IBDP: 'IBDP',
  GCSE: 'GCSE',
};

const sortableKeys = [subjectKeys.IGCSE, subjectKeys.IBDP, subjectKeys.GCSE];

export function arrangeGroupsDisplayOrder(groupedSubjects) {
  const sortedGroupedSubjects = {};
  if (groupedSubjects[subjectKeys.IGCSE]) {
    sortedGroupedSubjects[subjectKeys.IGCSE] = groupedSubjects[subjectKeys.IGCSE];
  }
  if (groupedSubjects[subjectKeys.IBDP]) {
    sortedGroupedSubjects[subjectKeys.IBDP] = groupedSubjects[subjectKeys.IBDP];
  }
  if (groupedSubjects[subjectKeys.GCSE]) {
    sortedGroupedSubjects[subjectKeys.GCSE] = groupedSubjects[subjectKeys.GCSE];
  }

  const keys = Object.keys(groupedSubjects);
  const missingKeysToAppend = difference(keys, sortableKeys);

  missingKeysToAppend.forEach(key => {
    sortedGroupedSubjects[key] = groupedSubjects[key];
  });

  return sortedGroupedSubjects;
}

export default arrangeGroupsDisplayOrder;
