<template>
  <div>
    <kog-icon
      class="InsightsHelpArticleLink-infoIcon margin-right-xxs"
      icon-class="fa-circle-info"
      fa-style="regular"
      theme="custom"
      size="s"
    />
    <a
      :href="href"
      target="_blank"
    >
      {{ linkText }}
    </a>
  </div>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'InsightsHelpArticleLink',
  components: {
    KogIcon,
  },
  props: {
    href: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.InsightsHelpArticleLink-infoIcon {
  color: var(--kog-text-brand);
}
</style>
