<template>
  <div
    :class="{
      'KogFilter-disabled': disabled,
    }"
    class="KogFilter inlineflexContainer flexContainer-alignCenter cursorDefault"
    @click="toggleFilter(filterKey)"
  >
    <i
      :class="[
        'KogFilter-selectedIndicator',
        {
          'KogFilter-selectedIndicator-selected': isSelected,
          'far fa-square': !isSelected,
          'fas fa-check-square': isSelected,
        },
      ]"
    />
    <span class="KogFilter-text padd-right-fine-2">{{ filterText }}</span>
  </div>
</template>

<script>
export default {
  name: 'KogFilter',
  props: {
    filterText: {
      type: String,
      required: true,
    },
    filterKey: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['filter-tag'],
  methods: {
    toggleFilter(filterKey) {
      this.$emit('filter-tag', { filterKey });
    },
  },
};
</script>

<style scoped>
.KogFilter {
  cursor: pointer;
  margin: 5px 5px 0 0;
  border: 1px solid var(--kog-border-brand-900);
  border-radius: 14px;
}

.KogFilter-disabled {
  pointer-events: none;
  border-color: var(--kog-text-disabled);
}

.KogFilter-selectedIndicator {
  padding: 2px 5px 2px 8px;
  font-size: 15px;
  color: var(--kog-text-brand);
}

.KogFilter-text {
  font-size: 11px;
  color: var(--kog-text-default);
}

.KogFilter-disabled > .KogFilter-selectedIndicator,
.KogFilter-disabled > .KogFilter-text {
  color: var(--kog-text-disabled);
}

.KogFilter-selectedIndicator-selected {
  color: var(--kog-text-brand);
}
</style>
