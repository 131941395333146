import qs from 'qs';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const apiBaseUrl = '/api/practice-items/';

const getUsersLastAnswers = async (subjectNodeId, questionIds) => {
  const response = await axios.get(apiBaseUrl, {
    cache: false,
    params: {
      subjectnode: subjectNodeId,
      question_id: questionIds,
      latest: true,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return response.data;
};

export default {
  getUsersLastAnswers,
};
