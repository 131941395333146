<template>
  <div
    v-if="isEdlinkAssignmentIntegrationEnabled"
    class="AssignmentIntegrationRecipients flexContainer"
    :class="{
      'flexContainer-spaceBetween flexContainer-center': isAssignmentBasketStyle,
      'flexContainer-row flexContainer-alignCenter': !isAssignmentBasketStyle,
    }"
  >
    <div class="flexContainer flexContainer-row flexContainer-center">
      <image-box
        v-tooltip="integrationProviderDisplayName"
        class="AssignmentIntegrationRecipients-logo margin-right-xs"
        :src="integrationLogo"
        :w="24"
        :h="24"
      />
      <span
        :class="{
          'text-muted': !sendToIntegration,
          'text-overline': isAssignmentBasketStyle,
          'heading-s margin-right-s': !isAssignmentBasketStyle,
        }"
      >
        Send to {{ integrationProviderDisplayName }}
      </span>
    </div>
    <kog-toggle-button
      id="send-assignment-edlink-toggle"
      v-model:is-pressed="sendAssignmentToEdlink"
      v-tooltip="{
        theme: 'kog-tooltip',
        content: disabledTooltip,
      }"
      :disabled="disabled"
      class="margin-left-xs"
      @toggled="sendAssignmentToEdlinkToggled"
    />
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';

import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogToggleButton from 'sharedApp/components/base/buttons/kog-toggle-button.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import useIntegrationLogo from 'studyApp/composables/use-integration-logo.ts';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default {
  name: 'AssignmentIntegrationRecipients',
  components: {
    ImageBox,
    KogToggleButton,
  },
  inject: {
    isAssignmentBasketStyle: {
      default: false,
    },
  },
  props: {
    sendToIntegration: {
      type: Boolean,
      required: true,
    },
    assignmentType: {
      type: String,
      required: true,
    },
    disabledTooltip: {
      type: String,
      required: true,
    },
  },
  emits: ['send-to-integration-updated'],
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const { isEdlinkAssignmentIntegrationEnabled } = storeToRefs(featureFlagStore);

    const { integrationProviderDisplayName, integrationLogo, integrationProvider } =
      useIntegrationLogo();

    return {
      isEdlinkAssignmentIntegrationEnabled,
      integrationProvider,
      integrationLogo,
      integrationProviderDisplayName,
    };
  },
  computed: {
    sendAssignmentToEdlink: {
      set(isEnabled) {
        studyAppLocalStorage.setSendAssignmentToEdlink(isEnabled);
        this.$emit('send-to-integration-updated', isEnabled);
      },
      get() {
        return this.sendToIntegration;
      },
    },
    disabled() {
      return this.disabledTooltip !== '';
    },
  },
  methods: {
    sendAssignmentToEdlinkToggled() {
      this.$mixpanel.trackEvent('Assignment - integration toggle', {
        assignment_id: this.assignmentId,
        assignment_type: this.assignmentType,
        integration_provider: this.integrationProvider,
        send_to_edlink: this.sendAssignmentToEdlink,
      });
    },
  },
};
</script>

<style scoped>
.AssignmentIntegrationRecipients {
  min-width: 195px;
  height: 40px;
  border-radius: 6px;
}
.AssignmentIntegrationRecipients-logo {
  width: 24px;
  height: 24px;
  background-color: var(--kogPlatformWhite);
}
</style>
