<template>
  <div class="margin-bottom-m margin-top-m">
    <google-classes-inline-list
      v-if="googleClassesWithSubsetRecipientsSelected.length > 0"
      :google-classes="googleClassesWithSubsetRecipientsSelected"
    >
      <template #prefix> Send to selected students in </template>
    </google-classes-inline-list>

    <google-classes-inline-list
      v-if="googleClassesWithAllRecipientsSelected.length > 0"
      :google-classes="googleClassesWithAllRecipientsSelected"
    >
      <template #prefix>
        <span v-if="googleClassesWithSubsetRecipientsSelected.length > 0">
          , to all students in
        </span>
        <span v-else> Send to all students in </span>
      </template>
    </google-classes-inline-list>

    <google-classes-inline-list
      v-if="googleClassesWithNoRecipientsSelected.length > 0"
      :google-classes="googleClassesWithNoRecipientsSelected"
    >
      <template #prefix>
        <span
          v-if="
            googleClassesWithAllRecipientsSelected.length > 0 ||
            googleClassesWithSubsetRecipientsSelected.length > 0
          "
        >
          , <span class="text-bold">not sending</span> to
        </span>
        <span v-else> <span class="text-bold">Not sending</span> to </span>
      </template>
    </google-classes-inline-list>
  </div>
</template>

<script>
import GoogleClassesInlineList from 'studyApp/components/teacher/google-classes-inline-list.vue';

export default {
  name: 'AssignmentSendoutGoogleClasses',
  components: {
    GoogleClassesInlineList,
  },
  props: {
    googleClasses: {
      type: Array,
      required: true,
    },
    googleClassroomRecipients: {
      type: Object,
      required: true,
    },
  },
  computed: {
    googleClassesWithAllRecipientsSelected() {
      return this.googleClasses.filter(googleClass => {
        const recipientsFromClassTotal = this.getRecipientsTotal(googleClass);
        const googleStudentsInClassTotal = googleClass.students.length;
        return (
          googleStudentsInClassTotal > 0 && googleStudentsInClassTotal === recipientsFromClassTotal
        );
      });
    },
    googleClassesWithSubsetRecipientsSelected() {
      return this.googleClasses.filter(googleClass => {
        const recipientsFromClassTotal = this.getRecipientsTotal(googleClass);
        const googleStudentsInClassTotal = googleClass.students.length;
        return (
          recipientsFromClassTotal > 0 && googleStudentsInClassTotal > recipientsFromClassTotal
        );
      });
    },
    googleClassesWithNoRecipientsSelected() {
      return this.googleClasses.filter(googleClass => this.getRecipientsTotal(googleClass) === 0);
    },
  },
  methods: {
    getRecipientsTotal(googleClass) {
      const recipientsFromClass = this.googleClassroomRecipients[googleClass.id];
      return recipientsFromClass ? recipientsFromClass.length : 0;
    },
  },
};
</script>
