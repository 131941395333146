<template>
  <div class="flexContainer flexContainer-column">
    <transition name="slide">
      <off-canvas
        v-if="isOffCanvasVisible"
        :heading="selectedStudent.name"
        :is-closed-on-click-outside="false"
        :is-full-height="true"
        @close="closeOffCanvas()"
      >
        <div class="margin-top-l padd-top-l QuestionsTab-OffCanvas-main">
          <h2 class="heading-s">
            {{ getSubjectNodeName(selectedSubjectNode) }}
          </h2>
          <div class="margin-top-m"> All questions attempts </div>
          <activity-cell
            class="margin-top-s"
            :is-clickable="false"
            :progress="getStudentQuestionCompletion(selectedStudent, selectedSubjectNode)"
            :color="getStudentQuestionCompletionColor(selectedStudent, selectedSubjectNode)"
          />
          <div class="margin-top-l">
            <h2 class="heading-s margin-bottom-m"> Questions </h2>
            <kog-loader
              v-if="hasStudentPracticeItemsForSubjectNode(selectedStudent, selectedSubjectNode)"
              :loading="$wait.is('fetching_student_practice_items')"
              loading-msg="Loading student responses..."
            >
              <student-response-accordion-item
                v-for="item in studentProgressForNode"
                :key="item.question.id"
                :question-responses="item"
                @toggle-accordion="trackToggleAccordion"
              />
            </kog-loader>
            <span v-else> The student has not answered any question in this area. </span>
          </div>
        </div>
      </off-canvas>
    </transition>

    <kog-loader
      class="QuestionsTab-loader flexChild-size-1 flexContainer flexContainer-column"
      :loading="isLoadingPageStructureData"
    >
      <div class="flexContainer flexContainer-spaceBetween margin-top-xl">
        <teacher-insights-filter-panel
          :is-ngss="isNgssSubject"
          :students="studentsList"
          :subject-tree="subjectTree"
          :persistence-key="`sid-${subjectId}-cid-${classId}-${tabName}`"
          :filter-options="['date', 'student', 'topic', 'activity']"
          @apply-filters="applyFilters"
        />
        <kog-dropdown-button
          :items="tableOptions"
          dropdown-placement="bottom-end"
          label="Table options"
          size="medium"
          @select="onOptionSelect"
        />
      </div>
      <insights-table
        v-if="!isLoadingPageStructureData"
        :tab-name="tabName"
        :style="{
          'max-height': tableMaxHeight,
        }"
        :is-loading-table-content="isLoadingTableContent"
        :students="filteredStudentsList"
        :subject-nodes="subjectNodes"
        :get-student-question-completion-color="getStudentQuestionCompletionColor"
        :show-student-progress="showStudentProgress"
        :get-student-progress="getStudentQuestionCompletion"
        :is-off-canvas-visible="isOffCanvasVisible"
        :sort-column="sortColumn"
        :sorted-by="sortedBy"
        :sort-order="sortOrder"
        :sticky-left-column-count="stickyLeftColumnCount"
        :education-system="educationSystem"
      />
      <insights-help-article-link
        class="margin-top-l"
        :href="helpArticleLink"
        link-text="Help article: Using the question progress page to inform your teaching"
      />
    </kog-loader>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual.js';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import { SHOW_ALL_DATES } from 'sharedApp/components/datetime/interval-selector.vue';
import sortingKeys from 'sharedApp/const/sorting-keys.js';
import CsvDownloadMixin from 'sharedApp/mixins/csv-download-mixin.js';
import FullPageTableHeightMixin from 'sharedApp/mixins/full-page-table-height-mixin.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { getTerm, isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import { capitalizeFirstLetter } from 'sharedApp/utils/string-utils.js';
import { dateFormatter, dateStringFormatter } from 'sharedApp/utils/time-utils.js';
import InsightsHelpArticleLink from 'studyApp/components/insights/insights-help-article-link.vue';
import InsightsTable from 'studyApp/components/insights/insights-table.vue';
import ActivityCell from 'studyApp/components/teacher/insights/activity-cell.vue';
import StudentResponseAccordionItem from 'studyApp/components/teacher/insights/student-response-accordion-item.vue';
import TeacherInsightsFilterPanel from 'studyApp/components/teacher/insights/teacher-insights-filter-panel.vue';
import OffCanvas from 'studyApp/components/teacher/off-canvas.vue';
import {
  getQuestionInsightsCsvBlob,
  QuestionProgressScale,
} from 'studyApp/utils/teacher/teacher-insights-utils.js';
import ScaleSelectionModal from 'teachApp/components/scale-selection-modal.vue';
import { SORT_ORDERS } from 'teachApp/utils/assignment-utils.js';
import {
  getScaleForCurriculum,
  getScaleSelection,
  getScalesForCurriculum,
  PROGRESS_SCALES,
  saveScaleSelection,
} from 'teachApp/utils/heatmap-scales.js';

const EXPORT_DATA = 'EXPORT_DATA';
const EDIT_SCORE_THRESHOLD = 'EDIT_SCORE_THRESHOLD';

export default {
  name: 'QuestionsTab',
  components: {
    ActivityCell,
    KogDropdownButton,
    KogLoader,
    InsightsHelpArticleLink,
    StudentResponseAccordionItem,
    TeacherInsightsFilterPanel,
    OffCanvas,
    InsightsTable,
  },
  mixins: [CsvDownloadMixin, FullPageTableHeightMixin, RoutesMixin],
  props: {
    isFetchingData: {
      type: Boolean,
      required: true,
    },
    isLoadingTableContent: {
      type: Boolean,
      required: true,
    },
    studentsList: {
      type: Array,
      required: true,
    },
    subjectTree: {
      type: Object,
      required: true,
    },
    subjectClass: {
      type: Object,
      required: true,
    },
    educationSystem: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    tabName: {
      type: String,
      required: true,
    },
  },
  emits: ['apply-filters'],
  data() {
    return {
      offCanvasFilters: {},
      sortedBy: sortingKeys.USER__FULL_NAME,
      sortOrder: SORT_ORDERS.desc,
      tableOptions: [
        { value: EDIT_SCORE_THRESHOLD, text: 'Edit score threshold', iconClass: 'fa-chart-column' },
        { value: EXPORT_DATA, text: 'Export data', iconClass: 'fa-download' },
      ],
      selectedStudentId: '',
      selectedTopicId: '',
      selectedInterval: '',
      selectedActivities: [],
      isOffCanvasVisible: false,
      selectedStudent: {},
      selectedSubjectNode: {},
      selectedScale: null,
      studentProgress: {},
      // eslint-disable-next-line vue/no-unused-properties
      tableDistanceFromPageBottom: 76, // needed by FullPageTableHeightMixin
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      questionTabParams: state => state.teacherInsightsModule.questionTabParams,
    }),
    ...mapGetters({
      studentsProgressForNodes: 'teacherInsightsModule/studentsProgressForNodes',
      studentProgressForNode: 'teacherInsightsModule/getStudentPracticeItemsByQuestionForNode',
    }),
    isLoadingPageStructureData() {
      return !this.selectedScale || this.isFetchingData;
    },

    sortedStudentsList() {
      const copy = [...this.studentsList];
      switch (this.sortedBy) {
        case sortingKeys.USER__FULL_NAME: {
          const sortedList = copy.sort((student1, student2) => {
            if (this.sortOrder === SORT_ORDERS.desc) {
              return student1.last_name.localeCompare(student2.last_name);
            }
            return student2.last_name.localeCompare(student1.last_name);
          });
          return sortedList;
        }
        case sortingKeys.LEVEL__NAME: {
          const sortedList = copy.sort((student1, student2) => {
            const student1LevelName = this.levelName(student1.level.name);
            const student2LevelName = this.levelName(student2.level.name);
            if (this.sortOrder === SORT_ORDERS.desc) {
              return student2LevelName.localeCompare(student1LevelName);
            }
            return student1LevelName.localeCompare(student2LevelName);
          });
          return sortedList;
        }
        default:
          return this.studentsList;
      }
    },
    filteredStudentsList() {
      const isOnlyOneStudentSelected = Boolean(this.selectedStudentId);
      if (isOnlyOneStudentSelected) {
        return [this.sortedStudentsList.find(student => student.id === this.selectedStudentId)];
      }
      return this.sortedStudentsList;
    },
    subjectNodes() {
      if (!this.selectedTopicId) {
        return this.subjectTree?.children || [];
      }

      const topicNode = this.subjectTree.children.find(topic => topic.id === this.selectedTopicId);
      return [topicNode, ...topicNode.children];
    },
    questionProgressScale() {
      return new QuestionProgressScale(this.selectedScale);
    },
    stickyLeftColumnCount() {
      return this.selectedTopicId ? 2 : 1;
    },
    isNgssSubject() {
      return isNgss(this.educationSystem);
    },
    helpArticleLink() {
      if (this.isNgssSubject) {
        return 'https://intercom.help/kognity/en/articles/6354714-using-insights-formerly-statistics-to-inform-your-teaching-the-questions-tab';
      }
      return 'https://intercom.help/kognity/en/articles/6354435-using-insights-formerly-statistics-to-inform-your-teaching-the-questions-tab';
    },
  },
  watch: {
    educationSystem: {
      immediate: true,
      handler() {
        if (this.educationSystem) {
          const savedScaleSelection = getScaleSelection(this.user.id, this.educationSystem);
          if (savedScaleSelection) {
            this.selectedScale = PROGRESS_SCALES[savedScaleSelection];
          } else {
            this.selectedScale = getScaleForCurriculum(this.educationSystem);
          }
        }
      },
    },
    subjectNodes: {
      handler() {
        this.updateStudentProgress();
      },
      deep: true,
    },
    isLoadingTableContent: {
      immediate: true,
      handler() {
        this.updateStudentProgress();
      },
    },
    isFetchingData: {
      immediate: true,
      handler() {
        this.updateStudentProgress();
      },
    },
  },
  methods: {
    updateStudentProgress() {
      if (this.isFetchingData || this.isLoadingTableContent) {
        this.studentProgress = {};
        return;
      }

      this.studentProgress = this.studentsProgressForNodes(this.subjectNodes);
    },
    ...mapWaitingActions('teacherInsightsModule', {
      fetchStudentPracticeItems: 'fetching_student_practice_items',
    }),
    getStudentQuestionCompletion(student, subjectNode) {
      const userId = student.user_id;
      if (!this.studentProgress[userId]) {
        return '-';
      }

      const correctQuestions = this.studentProgress[userId][subjectNode.id].correct;
      const totalQuestions = this.studentProgress[userId][subjectNode.id].total;

      if (totalQuestions === 0) {
        return '-';
      }

      return `${correctQuestions}/${totalQuestions}`;
    },
    getStudentQuestionCompletionColor(student, subjectNode) {
      const userId = student.user_id;
      const noProgress = -1;

      if (!this.studentProgress[userId]) {
        return this.questionProgressScale.getProgressColor(noProgress);
      }

      const answeredQuestions = this.studentProgress[userId][subjectNode.id].correct;
      const totalQuestions = this.studentProgress[userId][subjectNode.id].total;
      const progress = totalQuestions !== 0 ? answeredQuestions / totalQuestions : noProgress;
      return this.questionProgressScale.getProgressColor(progress);
    },
    sortColumn(key) {
      const isSortedDesc = this.sortOrder === SORT_ORDERS.desc;
      const order = isSortedDesc ? SORT_ORDERS.asc : SORT_ORDERS.desc;
      this.sortedBy = key;
      this.sortOrder = order;
    },
    applyFilters({ filters, isUserTriggered }) {
      if (this.isOffCanvasVisible) {
        this.closeOffCanvas();
      }

      this.selectedStudentId = filters.student;
      this.selectedTopicId = filters.subjectNode;

      let params = {};
      if (this.selectedInterval !== filters.datesInterval) {
        this.selectedInterval = filters.datesInterval;
      }

      if (!isEqual(this.selectedActivities, filters.activities)) {
        this.selectedActivities = [...filters.activities];
      }

      if (filters.datesInterval && filters.datesInterval !== SHOW_ALL_DATES) {
        params = {
          ...params,
          from_datetime: filters.fromDate,
          to_datetime: filters.toDate,
        };
      }

      if (filters.activities.length > 0) {
        params = {
          ...params,
          occasion_type: filters.activities,
        };
      }

      this.offCanvasFilters = params;

      const isDataFetchNeeded = !isEqual(params, this.questionTabParams);
      if (isDataFetchNeeded) {
        this.$emit('apply-filters', {
          subjectClassId: this.classId,
          params,
        });
      }

      if (isUserTriggered) {
        this.$mixpanel.trackEvent('Insights Questions - Click on filter', {
          filteredNodeId: this.selectedTopicId,
          filteredStudentId: this.selectedStudentId,
          filteredInterval: this.selectedInterval,
          filteredActivities: this.selectedActivities,
        });
      }
    },
    closeOffCanvas() {
      this.isOffCanvasVisible = false;
    },
    getSubjectNodeName(subjectNode) {
      return `${subjectNode.formatted_number_including_ancestors} ${subjectNode.name}`;
    },
    hasStudentPracticeItemsForSubjectNode(student, subjectNode) {
      const hasProgress = Boolean(this.studentProgress[student.user_id]);
      if (!hasProgress) {
        return false;
      }
      const totalQuestions = this.studentProgress[student.user_id][subjectNode.id].total;
      return totalQuestions > 0;
    },
    showStudentProgress(student, subjectNode) {
      if (this.hasStudentPracticeItemsForSubjectNode(student, subjectNode)) {
        this.fetchStudentPracticeItems({
          subjectClassId: this.classId,
          userId: student.user_id,
          params: {
            ...this.offCanvasFilters,
            subjectnode: subjectNode.id,
          },
        });
      }

      this.selectedStudent = student;
      this.selectedSubjectNode = subjectNode;
      this.isOffCanvasVisible = true;
      this.$mixpanel.trackEvent('Insights Questions - Click on activity cell');
    },
    onOptionSelect(item) {
      if (item.value === EXPORT_DATA) {
        this.downloadClassCsv();
      }
      if (item.value === EDIT_SCORE_THRESHOLD) {
        this.openScaleSelectionModal();
      }
    },
    getCsvMetaData() {
      const metaData = [`"Class: ${this.subjectClass.name}"`];

      if (this.selectedTopicId) {
        const topic = this.subjectNodes.find(node => node.id === this.selectedTopicId);
        const topicLabel = capitalizeFirstLetter(this.$term('topic'));
        const topicName = `${topic.formatted_number_including_ancestors} ${topic.name}`;
        metaData.push(`"${topicLabel}: ${topicName}"`);
      }

      if (this.selectedStudentId) {
        const student = this.filteredStudentsList[0];
        const studentName = `${student.first_name} ${student.last_name}`;
        metaData.push(`"Student: ${studentName}"`);
      }

      if (this.selectedInterval && this.selectedInterval !== SHOW_ALL_DATES) {
        const [from, to] = this.selectedInterval.split(' to ');
        const datesString = `${dateStringFormatter(from)} - ${dateStringFormatter(to)}`;
        metaData.push(`"Interval: ${datesString}"`);
      }

      if (this.selectedActivities.length > 0) {
        const humanReadableActivities = this.selectedActivities.map(activity => {
          const formatted = activity.split('_').join(' ');
          return formatted[0] + formatted.slice(1).toLowerCase();
        });
        metaData.push(`"Activities: ${humanReadableActivities.join(', ')}"`);
      }

      return metaData;
    },
    downloadClassCsv() {
      const metaData = this.getCsvMetaData();

      const blob = getQuestionInsightsCsvBlob(
        this.filteredStudentsList,
        this.subjectNodes,
        this.studentProgress,
        metaData,
      );
      const currentDate = dateFormatter(new Date());
      const fileName = `${this.subjectClass.name}_Performance_heatmap_created_${currentDate}.csv`;
      this.downloadCsvFile(fileName, blob);
      this.$mixpanel.trackEvent('Insights Questions - Click Export data button', {
        filteredNodeId: this.selectedTopicId,
        filteredStudentId: this.selectedStudentId,
        filteredInterval: this.selectedInterval,
        filteredActivities: this.selectedActivities,
      });
    },
    openScaleSelectionModal() {
      const allScalesForCurriculum = getScalesForCurriculum(this.educationSystem);
      this.$modal(ScaleSelectionModal, {
        scales: allScalesForCurriculum,
        currentScale: this.selectedScale.key,
        selectScale: this.changeScale,
        origin: 'insights',
      });
    },
    changeScale(scale) {
      this.selectedScale = PROGRESS_SCALES[scale];
      saveScaleSelection(this.user.id, this.educationSystem, scale);
      this.$mixpanel.trackEvent('Insights Questions - Click Save change score threshold button');
    },
    trackToggleAccordion(isOpen) {
      const event = isOpen
        ? 'Insights Question Off-canvas - Click on expand response details'
        : 'Insights Question Off-canvas - Click on close response details';
      this.$mixpanel.trackEvent(event);
    },
    levelName(studentLevel) {
      const educationSystemName = this.subject.educationsystem.name;
      return getTerm(studentLevel, educationSystemName);
    },
  },
};
</script>

<style scoped>
.QuestionsTab-OffCanvas-main {
  border-top: 1px solid var(--kogPlatformGray077);
}
.slide-leave-active,
.slide-enter-active {
  transition: transform 0.15s ease-in-out 0s;
}

.slide-leave-from,
.slide-enter-to {
  transform: translateX(0%);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
