<template>
  <double-modal
    :main-modal-title="sectionNode.name"
    :main-modal-subtitle-tags="phase"
    :main-modal-header-icon="headerIcon"
    :is-child-modal-visible="showChildModal"
    :child-modal-title="childModalTitle"
    :child-modal-subtitle="childModalSubtitle"
    :child-modal-header-icon="subsectionHeaderIcon"
    @close-main-modal="closeModal"
    @close-child-modal="
      () => {
        showChildModal = false;
      }
    "
  >
    <template #mainModalBody>
      <section-modal-body
        class="margin-right-l margin-bottom-l margin-left-l"
        :section-node="sectionNode"
        :close-modal="closeModal"
        :navigate-to-class-book="navigateToClassBook"
        :class-size="classSize"
        :user-nodes-reading-progress="userNodesReadingProgress"
        :reading-completion-data-per-node-id="readingCompletionDataPerNodeId"
        @clickCompletionStats="onClickCompletionStats"
      />
    </template>

    <template #childModalBody>
      <reflection-question-completion-stats-modal-body
        v-if="selectedChildNode.section_template === 'reflection'"
        class="margin-right-l margin-bottom-l margin-left-l"
        :subject-node="selectedChildNode"
        :students="students"
        @close-modal="closeModal"
      />
      <reading-completion-stats-modal-body
        v-else
        class="margin-right-l margin-bottom-l margin-left-l"
        :subject-node="selectedChildNode"
        :students="students"
        :reading-completion-data="readingCompletionDataPerUserId"
      />
    </template>
  </double-modal>
</template>

<script>
import DoubleModal from 'sharedApp/components/modals/double-modal.vue';
import reflectionSectionIcon from 'sharedApp/img/study/subject-class-overview-reflection-section.svg';
import sectionIcon from 'sharedApp/img/study/subject-class-overview-section.svg';
import subsectionIcon from 'sharedApp/img/study/subject-class-overview-subsection.svg';

import ReadingCompletionStatsModalBody from './reading-completion-stats-modal-body.vue';
import ReflectionQuestionCompletionStatsModalBody from './reflection-question-completion-stats-modal-body.vue';
import SectionModalBody from './section-modal-body.vue';

export default {
  name: 'SectionWithCompletionStatsModal',
  components: {
    DoubleModal,
    SectionModalBody,
    ReadingCompletionStatsModalBody,
    ReflectionQuestionCompletionStatsModalBody,
  },
  props: {
    userNodesReadingProgress: {
      type: Object,
      default: null,
    },
    sectionNode: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    navigateToClassBook: {
      type: Function,
      required: true,
    },
    classSize: {
      type: Number,
      default: 0,
    },
    students: {
      type: Array,
      required: true,
    },
    readingCompletionDataPerNodeId: {
      type: Object,
      default: undefined,
    },
    readingCompletionDataPerUserId: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      showChildModal: false,
      headerIcon: {
        type: 'image',
        imgUrl: sectionIcon,
        alt: '',
      },
      childModalTitle: '',
      childModalSubtitle: '',
      selectedChildNode: null,
    };
  },
  computed: {
    phase() {
      return this.sectionNode.ngss_section_settings?.phase
        ? [this.sectionNode.ngss_section_settings.phase]
        : [];
    },
    subsectionHeaderIcon() {
      return {
        type: 'image',
        imgUrl:
          this.selectedChildNode?.section_template === 'reflection'
            ? reflectionSectionIcon
            : subsectionIcon,
        alt: '',
      };
    },
  },
  mounted() {
    this.$mixpanel.trackEvent('Subject class overview - Open section modal');
  },
  methods: {
    onClickCompletionStats({ node }) {
      this.selectedChildNode = node;
      this.childModalTitle = node.name;
      this.childModalSubtitle = node.formatted_number_including_ancestors;
      this.showChildModal = true;
    },
  },
};
</script>
