<template>
  <div class="flexContainer flexContainer-column height-full flexContainer-spaceBetween">
    <div>
      <book-rail-slider-header
        class="margin-bottom-s"
        title="Notebook"
        @close="onCloseClick"
      />

      <ul
        v-if="activeAnnotations.length > 0"
        class="BookRailNotebook-itemList"
      >
        <li
          v-for="annotationEntity of activeAnnotations"
          :key="annotationEntity.annotation.id"
          :ref="`Annotation-${annotationEntity.annotation.id}`"
          class="BookRailNotebook-itemList-item"
        >
          <book-rail-notebook-annotation
            :annotation-entity="annotationEntity"
            :is-saving="isSaving"
            @edit="onEditClick(annotationEntity)"
            @cancel="onCancelEditClick"
            @scrollTo="scrollToHighlight(annotationEntity.annotation.id)"
            @input="debouncedSetNote"
            @change-color="setColor"
            @save="saveFocusedAnnotation"
            @delete="softDeleteAnnotation"
          />
        </li>
      </ul>

      <div
        v-else
        class="flexContainer flexContainer-column flexContainer-center kogPlatformGray096-bg border-radius-8 padd-top-s padd-right-xxl padd-bottom-s padd-left-xxl text-center"
      >
        <image-box
          class="margin-bottom-xs"
          :src="emptyListIcon"
          :w="24"
          alt="No annotations found"
          :transparent-background="true"
        />
        <div class="heading-xxs"> No notes on this page </div>
        <div class="text-regular"> Use the text highlighter to add notes here </div>
      </div>
    </div>
    <div class="divider-top flexContainer flexContainer-spaceBetween padd-top-s margin-top-s">
      <span>Open notebook on highlight</span>
      <kog-toggle-button
        :is-pressed="autoOpenNotebook"
        @toggled="toggleAutoOpenNotebook"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce.js';
import isNil from 'lodash/isNil.js';
import { mapActions as mapPiniaActions, mapState } from 'pinia';
import { mapActions } from 'vuex';

import useAnnotationStore from 'learning/common/store-modules/annotations/store.ts';

import KogToggleButton from 'sharedApp/components/base/buttons/kog-toggle-button.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import emptyListIcon from 'sharedApp/img/study/book-rail-notebook-slider-empty-list.svg';
import BookRailNotebookAnnotation from 'studyApp/components/side-navigation/book-rail-notebook-annotation.vue';
import BookRailSliderHeader from 'studyApp/components/side-navigation/book-rail-slider-header.vue';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default {
  name: 'BookRailNotebook',
  components: {
    ImageBox,
    BookRailSliderHeader,
    BookRailNotebookAnnotation,
    KogToggleButton,
  },
  data() {
    return {
      debouncedSetNote: debounce(this.setNote, 300),
      scrollIntoViewTimeout: null,
      autoOpenNotebook: true,
    };
  },
  computed: {
    ...mapState(useAnnotationStore, ['annotations', 'focusedAnnotation', 'isSaving']),
    activeAnnotations() {
      return Object.values(this.annotations).filter(annotation => !annotation.isSoftDeleted);
    },
    emptyListIcon() {
      return emptyListIcon;
    },
  },
  watch: {
    focusedAnnotation: {
      handler(value) {
        this.triggerScrollIntoView(value);
      },
      immediate: true,
    },
  },
  created() {
    const autoOpenNotebookLocalStorage = studyAppLocalStorage.getAutoOpenNotebook();
    if (!isNil(autoOpenNotebookLocalStorage)) {
      this.autoOpenNotebook = autoOpenNotebookLocalStorage;
    }
  },
  methods: {
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
    }),
    ...mapPiniaActions(useAnnotationStore, [
      'saveFocusedAnnotation',
      'clearFocusedAnnotation',
      'softDeleteAnnotation',
      'startEditing',
      'stopEditing',
      'setFocusedAnnotation',
    ]),
    scrollToHighlight(annotationId) {
      const elementId = `KogHighlight-${annotationId}`;
      const element = document.getElementById(elementId);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    },
    triggerScrollIntoView(focusedAnnotation) {
      if (focusedAnnotation === null || focusedAnnotation === undefined) {
        return;
      }

      if (this.scrollIntoViewTimeout) {
        clearTimeout(this.scrollIntoViewTimeout);
      }

      this.scrollIntoViewTimeout = setTimeout(() => {
        const annoEle = this.$refs[`Annotation-${focusedAnnotation.annotation.id}`]?.[0];
        annoEle?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        annoEle?.focus();
      }, 400);
    },
    onEditClick(annotationEntity) {
      if (this.focusedAnnotation) {
        this.stopEditing();
      }
      this.setFocusedAnnotation(annotationEntity);
      this.startEditing();
    },
    onCancelEditClick() {
      this.clearFocusedAnnotation();
    },
    onCloseClick() {
      this.clearFocusedAnnotation();
      this.closeRailSlider();
    },
    toggleAutoOpenNotebook(value) {
      studyAppLocalStorage.setAutoOpenNotebook(value);
      this.autoOpenNotebook = value;
      this.$mixpanel.trackEvent('Notebook - Toggle open notebook on highlight', {
        toggled_to: value,
      });
    },
    setColor(color) {
      this.focusedAnnotation.annotation.color = color;
    },
    setNote(note) {
      this.focusedAnnotation.annotation.note = note;
    },
  },
};
</script>

<style scoped>
.BookRailNotebook-itemList {
  scrollbar-width: none; /* Firefox */

  overflow-y: auto;

  max-height: calc(100vh - calc(var(--space-s) * 5) - 81px);
  margin: 0;
  padding: 0;

  list-style: none;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.BookRailNotebook-itemList::-webkit-scrollbar {
  width: 0;
  /* WebKit */
  height: 0;
}

.BookRailNotebook-itemList-item {
  margin-bottom: var(--space-s);
}

.BookRailNotebook-itemList-item:last-child {
  margin-bottom: 0;
}
</style>
