import { IGCSE_LEVELS } from 'sharedApp/const/level-groups.js';

export function isIGCSELevelsEnabled(possibleLevels) {
  return !!(
    possibleLevels &&
    possibleLevels.length > 0 &&
    possibleLevels.some(level => level.group === IGCSE_LEVELS)
  );
}

export default {};
