import { Plugin } from '@ckeditor/ckeditor5-core';

import ShowSolutionEditing from './show-solution-editing.ts';
import ShowSolutionUI from './show-solution-ui.ts';

export default class ShowSolution extends Plugin {
  static get requires() {
    return [ShowSolutionEditing, ShowSolutionUI];
  }
}
