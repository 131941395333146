<template>
  <div class="ReportFeedbackButton">
    <kog-button
      v-if="buttonType === 'regular'"
      key="feedback-regular"
      button-style="basic"
      :disabled="disabled"
      :label="label"
      :aria-label="ariaLabel"
      :tooltip="tooltip"
      :description="description"
      @click.prevent="openFeedbackModal"
    />
    <kog-round-button
      v-if="buttonType === 'round'"
      key="feedback-round"
      :aria-label="ariaLabel || label"
      icon-class="fa-bullhorn"
      icon-style="regular"
      icon-size="s"
      :tooltip="tooltip"
      button-style="default"
      @click.prevent="openFeedbackModal"
    />
  </div>
</template>

<script>
import ReportFeedbackModal from 'learning/common/containers/report-feedback-modal.vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';

export default {
  name: 'ReportFeedbackButton',
  components: {
    KogButton,
    KogRoundButton,
  },
  props: {
    /**
     * Controls the type of button shown.
     */
    buttonType: {
      type: String,
      default: 'regular',
      validator: prop => ['regular', 'round'].includes(prop),
    },
    /**
     * Id of the subjectnode
     */
    subjectNodeId: {
      type: Number,
      required: true,
    },
    /**
     * Id of the content to provide feedback on
     */
    contentId: {
      type: Number,
      default: null,
    },
    /**
     * Id of the content to provide feedback on
     */
    activityId: {
      type: Number,
      default: null,
    },
    /**
     * Controls the title shown in the feedback modal
     */
    contentType: {
      type: String,
      default: 'question',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Report feedback or error',
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    /**
     * Hides certain categories in the feedback category selection
     */
    categoriesToHide: {
      type: Array,
      default() {
        return ['ANSWER_MARKED_INCORRECTLY'];
      },
    },
    studentAnswer: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    modalTitle() {
      return `Report your feedback on this ${this.contentType}`;
    },
  },
  methods: {
    openFeedbackModal() {
      this.$modal(
        ReportFeedbackModal,
        {
          subjectNodeId: this.subjectNodeId,
          contentId: this.contentId,
          activityId: this.activityId,
          title: this.modalTitle,
          categoriesToHide: this.categoriesToHide,
          studentAnswer: this.studentAnswer,
        },
        { closeExisting: true },
      );
    },
  },
};
</script>
