<template>
  <td class="padd-right-s">
    <kog-tag
      v-tooltip="{
        theme: 'kog-tooltip',
        content: tooltipText,
        boundary: 'window',
        autoHide: false,
      }"
      :label="text"
    />
  </td>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { VTooltip } from 'floating-vue';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export default {
  name: 'KogTableCellLabel',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    KogTag,
  },
  props: {
    /**
     * The text displayed in the label
     */
    text: {
      type: String,
      required: true,
    },
    /**
     * Specifies content of optional tooltip that shows on label hover.
     * No tooltip shown if this property is not provided.
     */
    tooltipText: {
      type: String,
      default: '',
    },
  },
};
</script>
