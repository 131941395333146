import { isNil } from 'lodash';
import { compile } from 'path-to-regexp';

import {
  ASSIGNMENT_NOT_FOUND_ERROR_MESSAGE,
  AssignmentNotFoundError,
  AssignmentNotValidError,
  NO_ACCESS_TOKEN_ERROR_MESSAGE,
  NoAccessTokenError,
  NotSentToIntegrationError,
} from 'sharedApp/const/integrations.ts';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const userAssignmentsToPath = compile('/api/assignments/students/:userId/');
const assignmentDetailsToPath = compile('/api/assignments/:assignmentId/');

export async function fetchAssignmentDetails(assignmentId) {
  const url = assignmentDetailsToPath({ assignmentId });
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    let error = 'Something went wrong';
    if (e.response && e.response.data && e.response.data.detail) {
      error = e.response.data.detail;
    }
    throw new Error(error);
  }
}

function handleFetchIntegrationAssignmentError(error) {
  const apiErrorMessage = error?.response?.data?.detail;
  if (isNil(apiErrorMessage)) {
    throw error;
  }
  if (apiErrorMessage === 'Not sent to LMS') {
    throw new NotSentToIntegrationError();
  }
  if (apiErrorMessage === NO_ACCESS_TOKEN_ERROR_MESSAGE) {
    throw new NoAccessTokenError();
  }
  if (apiErrorMessage === ASSIGNMENT_NOT_FOUND_ERROR_MESSAGE) {
    throw new AssignmentNotFoundError();
  }
  throw new AssignmentNotValidError(apiErrorMessage);
}

export async function fetchIntegrationAssignment(assignmentId, subjectClassId) {
  const url = `/api/assignments/${assignmentId}/${subjectClassId}/integration/`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return handleFetchIntegrationAssignmentError(error);
  }
}

export async function updateAssignmentDetails(assignmentId, payload) {
  const url = assignmentDetailsToPath({ assignmentId });
  const { data } = await axios.patch(url, payload);
  return data;
}

export async function fetchUserAssignments({ userId, params }) {
  const url = userAssignmentsToPath({ userId });
  const response = await axios.get(url, { params });
  return response.data;
}
