<template>
  <div
    v-kog-trap-focus="searchExpanded"
    v-on-click-outside="collapseSearch"
    class="padd-left-s padd-right-s"
    @keydown.esc="collapseSearch"
  >
    <div
      class="flexContainer flexContainer-alignCenter"
      :class="searchBarClass"
    >
      <button
        v-tooltip="{
          theme: 'kog-tooltip',
          content: 'Search',
        }"
        class="KogButtonLegacy--noStyle"
        aria-label="Search"
        :disabled="searchExpanded"
        @click="expandSearch"
      >
        <kog-icon
          class="padd-top-xxs padd-bottom-xxs"
          fa-style="regular"
          icon-class="fa-search"
          :theme="theme"
        />
      </button>
      <kog-input
        v-if="searchExpanded"
        ref="searchInput"
        v-model:value="query"
        class="NavbarSearchBar-searchInput margin-left-xxs"
        placeholder="Search in this book"
        label="Search in this book"
        :is-label-hidden="true"
        type="text"
        autocomplete="off"
      />
    </div>
    <div
      v-if="searchExpanded"
      class="NavbarSearchBar-searchResultsContainer"
    >
      <div class="NavbarSearchBar-searchResults">
        <div
          v-if="query.length <= 3"
          class="NavbarSearchBar-infoBox margin-m text-center"
        >
          <span v-if="!query">Search for anything</span>
          <span
            v-if="query"
            aria-live="polite"
          >
            You need to type more than 3 letters
          </span>
        </div>
        <div
          v-if="query.length > 3"
          class="flexChild-canGrow"
        >
          <kog-tabs
            v-model:value="activeTab"
            :tabs="tabs"
          />
          <kog-tab-panels :value="activeTab">
            <kog-tab-panel
              v-for="tab in tabs"
              id="`${tab.name}-results`"
              :key="tab.name"
              :name="tab.name"
              aria-labelledby="`tab-${tab.name}-results`"
            >
              <search-results
                :search-results="searchResults"
                :next-page="nextPageClickHandler"
                :previous-page="previousPageClickHandler"
                :subject="subject"
                :result-click="resultClickHandler"
                :loading="loading"
                :query="query"
              />
            </kog-tab-panel>
          </kog-tab-panels>
          <button
            class="NavbarSearchBar-searchResultsClose KogButtonLegacy--noStyle"
            aria-label="Close search"
            tabindex="0"
            @click="collapseSearch"
          >
            <kog-icon
              class="NavbarSearchBar-closeIcon"
              fa-style="light"
              icon-class="fa-times"
              :theme="theme"
            />
          </button>
        </div>
        <button
          class="NavbarSearchBar-searchResultsClose KogButtonLegacy--noStyle"
          aria-label="Close search"
          tabindex="0"
          @click="collapseSearch"
        >
          <kog-icon
            class="NavbarSearchBar-closeIcon"
            fa-style="light"
            icon-class="fa-times"
            :theme="theme"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import { VTooltip } from 'floating-vue';
import { mapActions, mapState } from 'vuex';

import SearchResults from 'learning/common/components/search-results.vue';

import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogTabPanel from 'sharedApp/components/base/tabs/kog-tab-panel.vue';
import KogTabPanels from 'sharedApp/components/base/tabs/kog-tab-panels.vue';
import KogTabs from 'sharedApp/components/base/tabs/kog-tabs.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogTrapFocus from 'sharedApp/directives/kog-trap-focus.js';
import mixpanelModule from 'sharedApp/libs/mixpanel.js';

export default {
  name: 'NavbarSearchBarContainer',
  components: {
    KogIcon,
    KogInput,
    SearchResults,
    KogTabs,
    KogTabPanel,
    KogTabPanels,
  },
  directives: {
    onClickOutside: vOnClickOutside,
    tooltip: VTooltip,
    KogTrapFocus,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'dark',
      validator: prop => ['dark', 'light'].includes(prop),
    },
  },
  emits: ['expand', 'collapse'],
  data() {
    return {
      query: '',
      searchExpanded: false,
      tabs: [
        {
          name: 'book',
          label: 'Book',
          onTabSelected: () => {
            this.selectTab('book');
          },
          customProps: {
            id: 'tab-book-results',
            'aria-controls': 'book-results',
          },
        },
        {
          name: 'notes',
          label: 'Your Notes',
          onTabSelected: () => {
            this.selectTab('notes');
          },
          customProps: {
            id: 'tab-notes-results',
            'aria-controls': 'notes-results',
          },
        },
      ],
      activeTab: 'book',
    };
  },
  computed: {
    ...mapState({
      loading: state => state.searchModule.loading,
      searchResults(state) {
        const { searchResults } = state.searchModule;

        const results = searchResults.results.map((resultItem, index) => {
          return {
            ...resultItem,
            rank: index + 1 + (this.currentPage - 1) * searchResults.limit,
          };
        });

        return {
          ...searchResults,
          results,
        };
      },
      currentPage: state => {
        const { offset, limit } = state.searchModule.searchResults;
        return offset / limit + 1;
      },
      searchBarClass() {
        const classes = [`NavbarSearchBar--${this.theme}`];
        if (this.searchExpanded) {
          classes.push('NavbarSearchBar--expanded', 'padd-bottom-xxs');
        } else {
          classes.push('NavbarSearchBar--hidden');
        }

        return classes;
      },
    }),
  },
  watch: {
    query(q) {
      this.query = q;
      this.performSearch();
    },
  },
  created() {
    this.activeTab = this.tabs[0].name;
  },
  methods: {
    trackSearchQuery() {
      if (!this.query) {
        return;
      }
      mixpanelModule.trackEvent('Search - query', {
        subject_name: this.subject.name,
        query: this.query,
        tab: this.activeTab,
      });
    },
    trackSearchPrevNext(subjectName, fromPage, toPage) {
      mixpanelModule.trackEvent('Search - next/prev pressed', {
        from_page: fromPage,
        query: this.query,
        subject_name: subjectName,
        to_page: toPage,
        tab: this.activeTab,
      });
    },
    trackSearchResultSelected(subjectName, rankingNo) {
      mixpanelModule.trackEvent('Search - result selected', {
        subject_name: subjectName,
        ranking_no: rankingNo,
        query: this.query,
        tab: this.activeTab,
      });
    },
    resultClickHandler(event, result) {
      this.trackSearchResultSelected(this.subject.name, result.rank);
      this.collapseSearch();
    },
    performSearch() {
      this.search({
        subjectId: this.subject.id,
        query: this.query,
        callback: this.trackSearchQuery,
        searchOn: this.activeTab,
      });
    },
    nextPageClickHandler() {
      this.nextPage();
      this.trackSearchPrevNext(this.subject.name, this.currentPage, this.currentPage + 1);
    },
    previousPageClickHandler() {
      this.previousPage();
      this.trackSearchPrevNext(this.subject.name, this.currentPage, this.currentPage - 1);
    },
    expandSearch() {
      this.searchExpanded = true;
      this.$emit('expand');
      nextTick(() => {
        // Wait vue to render the input
        this.$refs.searchInput.focus();
      });
    },
    trackTabChange() {
      mixpanelModule.trackEvent('Search - switch tab', {
        subject_name: this.subject.name,
        tab: this.activeTab,
      });
    },
    collapseSearch() {
      this.query = '';
      this.activeTab = 'book';
      this.searchExpanded = false;
      this.$emit('collapse');
    },
    selectTab(tabKey) {
      this.activeTab = tabKey;
      this.trackTabChange();
      this.performSearch();
    },
    ...mapActions({
      search: 'searchModule/search',
      clearResults: 'searchModule/clearResults',
      nextPage: 'searchModule/nextPage',
      previousPage: 'searchModule/previousPage',
    }),
  },
};
</script>

<style scoped>
.NavbarSearchBar {
  padding: var(--space-s);
}

.NavbarSearchBar--expanded {
  min-width: 370px;
  margin-right: 50px;
}

.NavbarSearchBar--expanded.NavbarSearchBar--primary {
  border-bottom: 1px solid var(--kog-navigation-border-default);
}

.NavbarSearchBar--expanded.NavbarSearchBar--light {
  border-bottom: 1px solid var(--kog-colors-white);
}

.NavbarSearchBar--hidden {
  display: inherit;
}

.NavbarSearchBar-searchResultsClose {
  position: absolute;
  top: -46px;
  right: -24px;
}

.NavbarSearchBar-searchInput {
  width: 340px;
  color: var(--kog-text-default);
}

.NavbarSearchBar-searchResultsContainer {
  position: relative;
}

.NavbarSearchBar-searchResults {
  position: absolute;
  top: 10px;
  right: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 340px;
  min-height: 90px;

  color: var(--kogPlatformGray000);

  background-color: var(--kog-background-default-0);
  border-radius: 10px;
  box-shadow: 0 4px 8px -2px var(--kogShadow020);
}

.NavbarSearchBar-closeIcon {
  font-size: 25px;
}
</style>
