<template>
  <div>
    <book-checkpoint-container
      v-if="!isAssignmentFocus"
      :current-section-node="currentSectionNode"
      :complete-button-text="completeButtonText"
      :complete-button-tooltip="completeButtonTooltip"
      :complete-button-style="completeButtonStyle"
      :complete-button-icon="completeButtonIcon"
      :is-loading="isLoading"
      :is-complete-button-disabled="isCompleteButtonDisabled"
      :is-reset-button-visible="isResetButtonVisible"
      :is-activity-page-template="isActivityPageTemplate"
      :completion-text="completionText"
      @completed="$emit('completed')"
      @reset="$emit('reset')"
    />
    <assignment-checkpoint-container
      v-else
      :current-section-node="currentSectionNode"
      :is-complete-button-disabled="isCompleteButtonDisabled"
      :complete-button-tooltip="completeButtonTooltip"
      :is-loading="isLoading"
      :is-submittable="isSubmittable"
      @completed="$emit('completed')"
    />
  </div>
</template>

<script>
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import BookCheckpointContainer from 'studyApp/components/section-text/book-checkpoint-container.vue';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';
import AssignmentCheckpointContainer from 'studyApp/pages/assignment/components/assignment-checkpoint-container.vue';

export default {
  name: 'CheckpointContainer',
  components: {
    AssignmentCheckpointContainer,
    BookCheckpointContainer,
  },
  mixins: [RoutesMixin, ReadingAssignmentMixin],
  props: {
    currentSectionNode: {
      type: Object,
      required: true,
    },
    completeButtonText: {
      type: String,
      required: true,
    },
    completeButtonTooltip: {
      type: String,
      default: '',
    },
    completeButtonStyle: {
      type: String,
      default: 'primary',
    },
    completeButtonIcon: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCompleteButtonDisabled: {
      type: Boolean,
      default: false,
    },
    isResetButtonVisible: {
      type: Boolean,
      default: true,
    },
    isActivityPageTemplate: {
      type: Boolean,
      default: false,
    },
    completionText: {
      type: String,
      default: '',
    },
    isSubmittable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['reset', 'completed'],
};
</script>

<style scoped></style>
