<template>
  <div
    v-if="instruction"
    class="ActivityTeacherInstruction padd-l margin-bottom-l"
  >
    <div>
      <kog-icon
        class="ActivityTeacherInstructions-icon"
        icon-class="fa-person-chalkboard"
        fa-style="regular"
        :is-non-actionable="true"
      />
      <h4 class="ActivityTeacherInstructions-title">Teacher instructions</h4>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="ActivityTeacherInstructions-content"
      v-html="instruction"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'ActivityTeacherInstruction',
  components: {
    KogIcon,
  },
  props: {
    instruction: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.ActivityTeacherInstruction {
  position: relative;
  z-index: 0;

  width: var(--content-max-width);
  margin-bottom: var(--space-l);

  line-height: 24px;

  background-color: var(--kog-teacher-instructions-background);
  border-radius: 24px;
}

/**
  * This is a hack to make the teacher instructions background span the full
  * width of the page. It's not possible to do this with CSS alone because
  * the teacher instructions are rendered inside a container with a max-width.
  *
  * This need to be kept in sync with these other classes:
  * - publishing/containers/section/components/preview-section.vue
  * - learning/study/components/section-text/teacher-instructions.vue
  */
.ActivityTeacherInstruction::before {
  content: '';

  position: absolute;
  z-index: -1;
  top: 0;
  left: calc(-50vw + 50% + calc(var(--content-offset-left) / 2));

  height: 100%;

  background: var(--kog-teacher-instructions-background);
}

/* Made to imitate the .content-editable .ContentBox-icon h4 */
.ActivityTeacherInstructions-icon {
  position: absolute;
  top: var(--space-l);
  left: var(--space-l);

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-xxl);
  height: var(--space-xxl);

  font-size: var(--kog-font-size-content);

  background-color: var(--kogPlatformWhite);
  border-radius: 50%;
}
/* Made to imitate the .content-editable .ContentBox-title h4 */
.ActivityTeacherInstructions-title {
  display: block;

  margin-top: 0 !important;
  margin-bottom: var(--space-s);
  margin-left: calc(var(--space-xxl) + var(--space-s));

  font-family: var(--kog-lato);
  font-size: 20px;
  font-weight: bold;
  line-height: var(--space-xxl);
  color: var(--kogPlatformGray018);
}
/* Made to imitate the .content-editable .ContentBox-content p */
.ActivityTeacherInstructions-content {
  font-family: var(--kog-lato);
  font-size: var(--kog-font-size-content);
  line-height: 26px;
  color: var(--kogPlatformGray018);
}
</style>
