<template>
  <div>
    <kog-table
      class="margin-top-l"
      :footer-text="paginationText"
      :pagination-current-page="currentPage"
      :pagination-number-of-pages="pageCount"
      :update-current-page="gotoSectionsPage"
      :is-loading-table-data="isTableDataLoading"
      loading-table-data-message="Loading..."
      title="Textbook statistics"
    >
      <template #subtitle>
        <div class="padd-top-xs">
          Total sections completed:
          {{ studentSectionsCompleted.sections_completed }}/{{
            studentSectionsCompleted.sections_total
          }}
        </div>
      </template>
      <template #header>
        <kog-table-header>
          <kog-table-cell-header>
            <span>Section status</span>
            <kog-icon
              v-tooltip="{
                theme: 'kog-tooltip',
                content: `Sections only show if the student has opened the section.
                  <br>Sections turn green when they have been completed.`,
              }"
              v-kog-focusable="true"
              icon-class="fa-circle-info"
              fa-style="regular"
              size="s"
              class="margin-left-xxs"
            />
          </kog-table-cell-header>
          <kog-table-cell-header> Section name </kog-table-cell-header>
          <kog-table-cell-header> Last opened </kog-table-cell-header>
          <kog-table-cell-header> First completed </kog-table-cell-header>
        </kog-table-header>
      </template>
      <template #body>
        <kog-table-row
          v-for="section in sections"
          :key="section.number"
        >
          <kog-table-cell-text>
            <kog-tag
              class="TextbookTab-sectionStatusTag"
              :label="section.first_completed_at ? 'Completed' : 'Opened'"
              :type="section.first_completed_at ? 'positive' : 'default'"
              size="s"
            />
          </kog-table-cell-text>
          <kog-table-cell-text>
            <router-link :to="getSectionUrl(section)">
              {{ section.number }} {{ section.name }}
            </router-link>
          </kog-table-cell-text>
          <kog-table-cell-text>
            {{ getSectionOpenedDate(section) }}
          </kog-table-cell-text>
          <kog-table-cell-text>
            {{ getSectionCompletedDate(section) }}
          </kog-table-cell-text>
        </kog-table-row>
      </template>
      <template
        v-if="sections.length === 0"
        #info
      >
        <span class="padd-xs"> No sections opened or completed yet. </span>
      </template>
    </kog-table>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapState } from 'vuex';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { dateFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'TextbookTab',
  components: {
    KogIcon,
    KogTag,
    KogTable,
    KogTableRow,
    KogTableHeader,
    KogTableCellHeader,
    KogTableCellText,
  },
  mixins: [RoutesMixin],
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      sections: state => state.teacherInsightsModule.studentSections,
      sectionPaginationData: state => state.teacherInsightsModule.studentSectionsPagination,
      studentSectionsCompleted: state => state.teacherInsightsModule.studentSectionsCompleted,
    }),
    isTableDataLoading() {
      return this.$wait.waiting('fetching_section_data');
    },
    currentPage() {
      return this.sectionPaginationData?.currentPage;
    },
    pageCount() {
      if (!this.sectionPaginationData) {
        return 0;
      }

      const { pageSize, totalItems } = this.sectionPaginationData;
      return Math.ceil(totalItems / pageSize);
    },
    paginationText() {
      if (this.sections.length === 0) {
        return '';
      }

      const { pageSize, totalItems, currentPage } = this.sectionPaginationData;
      const firstItemNumber = pageSize * currentPage - pageSize + 1;
      const lastItemNumber = Math.min(pageSize * currentPage, totalItems);

      return `${firstItemNumber} - ${lastItemNumber} of ${totalItems}`;
    },
  },
  created() {
    this.fetchStudentSectionsCompleted({
      subjectId: this.subjectId,
      userId: this.userId,
    });
  },
  methods: {
    ...mapWaitingActions('teacherInsightsModule', {
      fetchStudentSectionActivity: 'fetching_section_data',
      fetchStudentSectionsCompleted: 'fetching_student_sections_completed',
    }),
    gotoSectionsPage(page) {
      this.fetchStudentSectionActivity({
        subjectId: this.subjectId,
        userId: this.userId,
        page,
      });
    },
    getSectionUrl(section) {
      const resolved = this.$router.resolve({
        name: 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: section.slug,
          nodeId: section.subject_node_id,
        },
      });

      return resolved.fullPath;
    },
    getSectionOpenedDate(section) {
      return dateFormatter(new Date(section.opened_date));
    },
    getSectionCompletedDate(section) {
      if (!section.first_completed_at) {
        return 'n/a';
      }
      return dateFormatter(new Date(section.first_completed_at));
    },
  },
};
</script>
