<template>
  <div>
    <div :style="{ minHeight: demoFooterHeight }" />
    <div class="z-index-4 positionFixed positionFixed-bottomLeft width-full">
      <footer
        class="DemoFooter-footer flexContainer flexContainer-alignCenter flexContainer-spaceBetween padd-right-s padd-left-s"
        :style="{ minHeight: demoFooterHeight }"
      >
        <div class="padd-left-xs">
          <demo-tours-button
            class="ProductTour-openTourList margin-right-m"
            :subject-id="subjectId"
            :tours-to-subject-map="toursToSubjectMap"
          />
        </div>
        <div
          v-if="enableTrialButton"
          class="flexContainer"
        >
          <request-trial-button
            v-if="isIGCSE || isIBDP"
            class="ProductTour-startPilotButton padd-right-xs"
            label="Free 30-Day Trial"
            :user="user"
            :demo-type="demoType"
          />
          <request-trial-button
            class="ProductTour-startPilotButton padd-right-xs"
            label="Talk to an expert"
            :user="user"
            :demo-type="demoType"
          />
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import DemoToursButton from 'learning/common/components/demo-tours-button/demo-tours-button.vue';
import toursToSubjectMap from 'learning/common/components/demo-tours-button/demo-tours.js';
import RequestTrialButton from 'learning/common/components/start-trial/request-trial-button.vue';
import {
  ALL_DEMO_TYPES,
  IBDP_DEMO_TYPE,
  IGCSE_DEMO_TYPE,
} from 'learning/common/constants/demo-type-constants.js';

export const DEMO_FOOTER_HEIGHT_PX = 72;

export default {
  name: 'DemoFooter',
  components: {
    RequestTrialButton,
    DemoToursButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    demoType: {
      type: String,
      required: true,
      validator: demoType => ALL_DEMO_TYPES.includes(demoType),
    },
    enableTrialButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toursToSubjectMap,
    };
  },
  computed: {
    subjectId() {
      return Number(this.$route?.params.sid);
    },
    isIGCSE() {
      return this.demoType === IGCSE_DEMO_TYPE;
    },
    isIBDP() {
      return this.demoType === IBDP_DEMO_TYPE;
    },
  },
  created() {
    this.demoFooterHeight = `${DEMO_FOOTER_HEIGHT_PX}px`;
  },
};
</script>

<style scoped>
.DemoFooter-footer {
  color: var(--kog-text-brand-hard);
  background-color: var(--kog-background-brand-900);
}
</style>
