import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { ClickObserver } from '@ckeditor/ckeditor5-engine';
import { toWidget, Widget } from '@ckeditor/ckeditor5-widget';

import { isClickInsideSelectedElement } from '../utils/commands.js';
import InsertAudioCommand from './audio-command.js';

export default class AudioEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineCommands();
  }

  defineSchema() {
    const { schema } = this.editor.model;

    schema.register('audioWrapper', {
      inheritAllFrom: '$blockObject',
      allowContent: 'audio',
      allowAttributes: ['alignment'],
    });

    schema.register('audio', {
      allowIn: 'audioWrapper',
      allowAttributes: ['url'],
    });
  }

  defineConverters() {
    const { conversion } = this.editor;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'div',
        classes: 'ckeditor-html5-audio',
      },
      model: (viewElement, { writer }) => {
        return writer.createElement('audioWrapper', {
          alignment: viewElement.getStyle('text-align') ?? null,
        });
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'audioWrapper',
      view: (modelElement, { writer }) => this.createWrapperViewElement(modelElement, writer),
    });

    conversion.for('editingDowncast').elementToElement({
      model: 'audioWrapper',
      view: (modelElement, { writer }) => {
        const viewElement = this.createWrapperViewElement(modelElement, writer);
        return toWidget(viewElement, writer, { label: 'Audio widget' });
      },
    });

    conversion.for('upcast').elementToElement({
      view: 'audio',
      model: 'audio',
    });
    conversion.for('downcast').elementToElement({
      model: 'audio',
      view: (modelElement, { writer }) => {
        return writer.createContainerElement('audio', {
          src: modelElement.url,
          controls: true,
        });
      },
    });
    conversion.attributeToAttribute({
      model: {
        name: 'audio',
        key: 'url',
      },
      view: {
        name: 'audio',
        key: 'src',
      },
    });
  }

  defineCommands() {
    const { editor } = this;
    const { document } = editor.model;
    const { view, mapper } = editor.editing;

    editor.commands.add('insertAudio', new InsertAudioCommand(editor));
    editor.commands.add('updateAudio', new Command(editor));

    view.addObserver(ClickObserver);
    editor.listenTo(view.document, 'click', (_, data) => {
      if (isClickInsideSelectedElement(data, document, mapper, 'audioWrapper')) {
        editor.commands.get('updateAudio').execute();
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  createWrapperViewElement(modelElement, writer) {
    const attributes = {
      class: 'ckeditor-html5-audio',
    };

    const alignment = modelElement.getAttribute('alignment');
    if (alignment) {
      attributes.style = `text-align:${alignment}`;
    }
    return writer.createContainerElement('div', attributes);
  }
}
