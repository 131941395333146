<template>
  <div
    v-if="solution && solution.correct_answers"
    v-kog-mathjax
    class="padd-top-m FillInTheBlanksSolution"
  >
    <p class="heading-xxs margin-bottom-xxs"> Accepted answers and explanation </p>
    <div
      v-for="(uid, index) in fbqElementUIDsInOrder"
      :key="uid"
      class="padd-xxs"
    >
      <div class="flexContainer">
        <span class="padd-right-s padd-top-xxs">#{{ index + 1 }}</span>
        <div>
          <ul class="content-editable">
            <!-- eslint-disable vue/no-v-html -->
            <li
              v-for="(answer, i) in getAnswerForUID(uid).answers"
              :key="i"
              v-html="answer"
            />
            <!-- eslint-enable vue/no-v-html -->
          </ul>
          <secondary-accepted-answers
            :secondary-answers="getAnswerForUID(uid).secondary_accepted_answers"
            ul-padding-size="m"
          />
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="getAnswerForUID(uid).explanation_html"
            class="padd-bottom-s muted"
            v-html="getAnswerForUID(uid).explanation_html"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </div>
    <div
      v-if="solution.explanation"
      class="padd-xxs"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="margin-top-s margin-left-l muted"
        v-html="solution.explanation"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>
</template>

<script>
import SecondaryAcceptedAnswers from 'sharedApp/components/questions/secondary-accepted-answers.vue';
import { getFtbUIDsInOrder } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

export default {
  name: 'FillInTheBlanksSolution',
  components: {
    SecondaryAcceptedAnswers,
  },
  props: {
    solution: {
      type: Object,
      default: () => {},
    },
    questionHtml: {
      type: String,
      required: true,
    },
  },
  computed: {
    fbqElementUIDsInOrder() {
      return getFtbUIDsInOrder(this.questionHtml);
    },
  },
  methods: {
    getAnswerForUID(uid) {
      return this.solution.correct_answers[uid];
    },
  },
};
</script>

<style scoped>
.FillInTheBlanksSolution ul {
  padding-left: var(--space-m);
}
</style>
