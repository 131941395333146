import { Plugin } from '@ckeditor/ckeditor5-core';

import inputIcon from 'publishingApp/assets/icons/input-pipe-regular.svg';

import { registerFactoryView } from '../utils/plugin-utils.ts';

export default class FITBQBlankUI extends Plugin {
  init() {
    registerFactoryView(this.editor, {
      name: 'fitbqblank',
      commandName: 'insertFITBQBlank',
      icon: inputIcon,
      label: 'FITBQ Blank',
      onExecute: () => this.editor.execute('insertFITBQBlank'),
    });
  }
}
