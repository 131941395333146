<template>
  <div class="width-full">
    <student-reading-assignment-focus-landing-header
      v-if="assignment"
      :assignment-name="assignment.name"
      :assignment-deadline="assignmentDeadline"
      :is-assignment-done="isReadingAssignmentDone"
      :assignment-handed-at="assignmentHandedInAt"
      :is-sent-to-integration="assignment.is_sent_to_integration"
      :integration-provider="integrationProvider"
    />
    <div class="width-full flexContainer flexContainer-column flexContainer-alignCenter padd-xl">
      <integration-assignment-alert class="margin-bottom-m" />
      <div
        v-if="assignment.message"
        class="width-full padd-bottom-l"
      >
        <h2 class="heading-xs margin-bottom-s">Instructions</h2>
        <p class="ReadingAssignmentFocusLanding-instructions-text">{{ assignment.message }}</p>
      </div>
      <reading-assignment-focus-tasks
        class="width-full"
        :assignment-reading-items="assignmentReadingItems"
        :assignment-id="assignment.id"
      />
      <submit-to-integration-button
        v-if="canSubmitIntegrationAssignment"
        class="margin-right-s margin-bottom-m width-full"
        button-style="accent"
        :assignment="assignment"
        :has-submitted-to-integration="hasSubmittedToIntegration"
      />
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapActions, mapGetters, mapState } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import IntegrationAssignmentAlert from 'studyApp/components/assignment/integration-assignment-alert.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';
import NodeProgressMixin from 'studyApp/mixins/node-progress-mixin.js';
import ReadingAssignmentFocusTasks from 'studyApp/pages/assignment/components/reading-assignment-focus-tasks.vue';
import StudentReadingAssignmentFocusLandingHeader from 'studyApp/pages/assignment/components/student-reading-assignment-focus-landing-header.vue';
import SubmitToIntegrationButton from 'studyApp/pages/assignment/components/submit-to-integration-button.vue';

export default {
  name: 'ReadingAssignmentFocusLanding',
  components: {
    SubmitToIntegrationButton,
    StudentReadingAssignmentFocusLandingHeader,
    ReadingAssignmentFocusTasks,
    IntegrationAssignmentAlert,
  },
  mixins: [RoutesMixin, NodeProgressMixin],
  emits: ['reload-assignment'],
  setup() {
    const assignmentStore = useAssignmentStore();
    const { assignment } = storeToRefs(assignmentStore);
    const { loadIntegrationAssignment, warningMessage, integrationProvider } =
      useIntegrationAssignment();
    return {
      teacherAssignment: assignment,
      loadIntegrationAssignment,
      integrationWarningMessage: warningMessage,
      integrationProvider,
    };
  },
  computed: {
    ...mapState({
      studentAssignment: state => state.assignmentModule.assignment,
      nodeProgressResults: state => state.assignmentModule.nodeProgressResults,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
    }),
    assignmentId() {
      return this.assignment.id;
    },
    assignment() {
      if (this.user.isTeacher()) {
        return this.teacherAssignment;
      }
      return this.studentAssignment;
    },
    assignmentDeadline() {
      return this.assignment ? dateTimeFormatter(this.assignment.deadline) : '';
    },
    assignmentNodes() {
      return this.assignment?.sections || [];
    },
    assignmentReadingItems() {
      if (this.user.isTeacher()) {
        return this.assignmentNodes.map(node => {
          return {
            id: node.subject_node_id,
            slug: node.slug,
            name: node.name,
          };
        });
      }
      return this.assignmentNodes.map(node => {
        const isCompleted = this.isSubjectNodeCompleted(node.subject_node_id);

        const firstCompletedAt = this.nodeProgressResults
          ? this.nodeProgressResults[node.subject_node_id]?.first_completed_at ?? null
          : null;

        return {
          id: node.subject_node_id,
          slug: node.slug,
          name: node.name,
          isCompleted,
          firstCompletedAt,
        };
      });
    },
    isReadingAssignmentDone() {
      return this.assignmentReadingItems.every(item => item.isCompleted);
    },
    canSubmitIntegrationAssignment() {
      return (
        this.assignment?.is_sent_to_integration &&
        this.isReadingAssignmentDone &&
        !this.integrationWarningMessage
      );
    },
    assignmentHandedInAt() {
      if (!this.isReadingAssignmentDone) {
        return null;
      }

      let handedAt = this.assignment.sent_at;

      this.assignmentReadingItems.forEach(item => {
        if (!item.firstCompletedAt) return;
        const handedAtDate = new Date(handedAt);
        const firstCompletedAtDate = new Date(item.firstCompletedAt);
        if (firstCompletedAtDate.getTime() > handedAtDate.getTime()) {
          handedAt = item.firstCompletedAt;
        }
      });

      return dateTimeFormatter(handedAt);
    },
    hasSubmittedToIntegration() {
      return this.assignment?.is_submitted_to_integration;
    },
  },
  created() {
    this.trackReadingAssignmentLanding();
    this.closeRailSlider();
    this.loadIntegrationAssignment(this.assignment, this.classId);
  },
  methods: {
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
    }),
    trackReadingAssignmentLanding() {
      const eventName = 'Reading Assignments - Go to overview';
      const eventProperties = {
        assignment_id: this.assignmentId,
        number_of_sections: this.assignment.sections.length,
        source: this.$route.query.source,
      };
      this.$mixpanel.trackEvent(eventName, eventProperties);
    },
  },
};
</script>

<style scoped>
.ReadingAssignmentFocusLanding-instructions-text {
  font-family: var(--kog-satoshi);
  font-size: 16px;
  line-height: 24px;
}
</style>
