<template>
  <div
    id="KogDraggableCard"
    class="KogDraggableCard-container"
    :class="{ 'KogDraggableCard-grabbing': isDragging }"
    :style="style"
  >
    <div class="screenreader-only">
      <p>{{ context }}</p>
      <p>{{ title }}</p>
      <p>{{ subtitle }}</p>
    </div>
    <div
      class="flexContainer flexContainer-alignCenter"
      :class="{ 'KogDraggableCard-grab': !isDragging }"
      @pointerdown="draggingStart"
    >
      <kog-icon icon-class="fa-grip-dots-vertical" />
    </div>
    <div class="flexContainer flexContainer-column KogDraggableCard-content">
      <div
        v-if="title"
        class="KogDraggableCard-content--topRadius KogDraggableCard-titleContainer inlineflexContainer flexContainer-alignCenter padd-s width-full"
        :class="{ 'padd-s': defaultPadding }"
      >
        <div
          aria-hidden="true"
          class="KogDraggableCard-TitleContent flexChild-canGrow"
        >
          <div class="text-bold">
            {{ title }}
          </div>
          <div
            v-if="subtitle"
            class="muted"
          >
            {{ subtitle }}
          </div>
        </div>
      </div>
      <div :class="{ 'padd-s': defaultPadding }">
        <!-- @slot Slot for content below title -->
        <slot name="bodySlot" />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'KogDraggableCard',
  components: {
    KogIcon,
  },
  props: {
    /**
     * Provides additional information to screenreaders.<br>
     * If specified, it will be read out before the `title`.
     */
    context: {
      type: String,
      default: '',
    },
    /**
     * Puts padd-s around content when `true`.
     */
    defaultPadding: {
      type: Boolean,
      default: true,
    },
    /**
     * The subtitle of the card.
     */
    subtitle: {
      type: String,
      default: '',
    },
    /**
     * The title of the card
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * The starting top position of the card in px.
     */
    startTop: {
      type: Number,
      default: 0,
    },
    /**
     * The starting left position of the card in px.
     */
    startLeft: {
      type: Number,
      default: 0,
    },
    /**
     * The z-index of the card.
     */
    zIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      topDistance: undefined,
      leftDistance: undefined,
      draggableCard: undefined,
    };
  },
  computed: {
    style() {
      return {
        top: `${this.topDistance}px`,
        left: `${this.leftDistance}px`,
        'z-index': `${this.zIndex}`,
      };
    },
  },
  mounted() {
    this.draggableCard = document.getElementById('KogDraggableCard');
    document.addEventListener('pointermove', this.dragging);
    document.addEventListener('pointerup', this.draggingEnd);
    this.topDistance = this.startTop;
    this.leftDistance = this.startLeft;
    window.addEventListener('resize', this.repositionIfNeeded);
  },
  beforeUnmount() {
    document.removeEventListener('pointermove', this.dragging);
    document.removeEventListener('pointerup', this.draggingEnd);
    window.removeEventListener('resize', this.repositionIfNeeded);
  },
  methods: {
    repositionIfNeeded() {
      const keepWithinRightDistance = 100;
      const keepWithinBottomDistance = 50;
      const rightLimit = window.innerWidth - keepWithinRightDistance;
      const downLimit = window.innerHeight - keepWithinBottomDistance;

      if (this.topDistance > downLimit) {
        this.topDistance = downLimit;
      }
      if (this.topDistance < 0) {
        this.topDistance = 0;
      }
      if (this.leftDistance > rightLimit) {
        this.leftDistance = rightLimit;
      }
      if (this.leftDistance < 0) {
        this.leftDistance = 0;
      }
    },
    pointerOutsideOfWindow(event) {
      return (
        event.clientY < 0 ||
        event.clientX < 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      );
    },
    draggingStart(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX - this.draggableCard.offsetLeft;
      this.dragStartY = event.clientY - this.draggableCard.offsetTop;
      this.draggableCard.setPointerCapture(event.pointerId);
    },
    dragging(event) {
      if (!this.isDragging || this.pointerOutsideOfWindow(event)) return;
      event.preventDefault();

      this.leftDistance = event.clientX - this.dragStartX;
      this.topDistance = event.clientY - this.dragStartY;
    },
    draggingEnd(event) {
      if (!this.isDragging) return;
      this.draggableCard.releasePointerCapture(event.pointerId);
      this.isDragging = false;
      this.repositionIfNeeded();
    },
  },
};
</script>

<style scoped>
.KogDraggableCard-container {
  position: fixed;
  transform: translate3d(0, 0, 0);

  display: flex;
  flex-direction: row;
  align-items: stretch;

  background-color: var(--kog-colors-aubergine-200);
  border-radius: 4px;
  box-shadow: 0 4px 8px -2px var(--kogShadow020);

  transition: background-color 0.1s ease;
}

.KogDraggableCard-container:hover {
  background-color: var(--kog-colors-aubergine-400);
}

.KogDraggableCard-grab {
  cursor: grab;
}
.KogDraggableCard-titleContainer {
  overflow: hidden;
  height: 72px;
}

.KogDraggableCard-TitleContent {
  overflow: hidden;
  white-space: nowrap;
}

.KogDraggableCard-content {
  height: 100%;
  background-color: var(--kog-card-background);
  border-radius: 0 4px 4px 0;
}
.KogDraggableCard-grabbing {
  cursor: grabbing;
  background-color: var(--kog-colors-aubergine-300);
  box-shadow: 0 8px 16px -4px var(--kogShadow030);
}
</style>
