<template>
  <div
    ref="imageWrapper"
    class="ImageBox flexContainer flexContainer-center"
    :class="{
      'ImageBox--defaultBackground': !transparentBackground,
      'ImageBox--transparentBackground': transparentBackground,
    }"
  />
</template>

<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import debounce from 'lodash/debounce.js';

import { getImageProvider } from 'sharedApp/utils/image-utils.js';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
const EVENT_DEBOUNCE_MS = 250;

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  w: {
    type: Number,
    default: 0,
  },
  h: {
    type: Number,
    default: 0,
  },
  fit: {
    type: String,
    default: '',
    validator(value) {
      const validFitModes = [
        'clamp',
        'clip',
        'crop',
        'facearea',
        'fill',
        'fillmax',
        'max',
        'min',
        'scale',
      ];
      return !value || validFitModes.includes(value);
    },
  },
  crop: {
    type: String,
    default: '',
    validator(value) {
      const validCropmodes = [
        'top',
        'bottom',
        'left',
        'right',
        'faces',
        'focalpoint',
        'edges',
        'entropy',
      ];
      return !value || validCropmodes.includes(value);
    },
  },
  auto: {
    type: String,
    default: 'compress',
  },
  quality: {
    type: Number,
    default: null,
    validator: quality => quality === null || (quality >= 0 && quality <= 100),
  },
  imageClasses: {
    type: String,
    default: '',
  },
  alt: {
    type: String,
    default: '',
  },
  transparentBackground: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['error']);

const width = ref<number>();
const height = ref<number>();
const url = computed(() =>
  getImageProvider(props.src)
    .getImageUrl({
      w: width.value,
      h: height.value,
      fit: props.fit,
      crop: props.crop,
      auto: props.auto,
      q: props.quality,
    })
    .toString(),
);

let imageElement: HTMLImageElement;
const imageWrapper = ref<Element>();

function updateImageSrc() {
  if (imageElement.src === url.value) return;
  imageElement.src = url.value;
}

function updateWidthHeight() {
  width.value = props.w || imageWrapper.value?.clientWidth;
  height.value = props.h || imageWrapper.value?.clientHeight;

  if (height.value) {
    imageElement.style.maxHeight = `${height.value}px`;
  }
  if (width.value) {
    imageElement.style.maxWidth = `${width.value}px`;
  }
}

function createImageElement() {
  imageElement = new Image();
  if (props.imageClasses) {
    imageElement.classList.add(...props.imageClasses.split(' '));
  }
  imageElement.alt = props.alt || '';
  imageElement.addEventListener('error', () => {
    emit('error');
  });
  updateWidthHeight();
  updateImageSrc();

  // Next tick to wait for the refs to render properly, needed to properly
  // get width and height if none is provided.
  imageElement.addEventListener('load', () => {
    nextTick(() => {
      if (!imageWrapper.value) {
        return; // component unmounted before image loaded
      }
      imageWrapper.value.appendChild(imageElement);
    });
  });
}

watch([url, width, height, props], () => {
  updateWidthHeight();
  updateImageSrc();
});

let debouncedUpdateWidthHeight: ReturnType<typeof debounce>;
let resizeObserver: ResizeObserver;
onMounted(() => {
  debouncedUpdateWidthHeight = debounce(updateWidthHeight, EVENT_DEBOUNCE_MS);
  createImageElement();
  resizeObserver = new ResizeObserver(debouncedUpdateWidthHeight);
  if (imageWrapper.value) {
    resizeObserver.observe(imageWrapper.value);
  }
});

onBeforeUnmount(() => {
  debouncedUpdateWidthHeight.cancel();
  resizeObserver.disconnect();
});
</script>

<style scoped>
.ImageBox {
  width: 100%;
  height: 100%;
}

.ImageBox--defaultBackground {
  background: var(--kogPlatformGray093);
}

.ImageBox--transparentBackground {
  background: transparent;
}
</style>
