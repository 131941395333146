<template>
  <kog-font-awesome-icon
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltipText,
      boundary: 'document.body',
    }"
    fa-class="fa-info-circle"
    fa-style="regular"
    :size="size"
    :aria-label="tooltipText"
  />
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';

export default {
  name: 'IconWithTooltip',
  components: {
    KogFontAwesomeIcon,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 's',
    },
  },
};
</script>
