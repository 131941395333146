import { mapGetters } from 'vuex';

import { isSection, isSubSection } from 'sharedApp/libs/subject-tree-functions.js';

const ActivityMixin = {
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
  },
  methods: {
    canBeCompleted(subjectNodeId) {
      const node = this.subjectNodesById[subjectNodeId];
      return isSubSection(node) || isSection(node);
    },
    getSubmissionConfirmationDialogText(activityName, isAllQuestionsAnswered) {
      if (!isAllQuestionsAnswered) {
        return {
          title: 'Submit without answering all questions?',
          text: "All questions are not answered yet. You won't be able to edit after submitting.",
        };
      }

      return {
        title: `Submit ${activityName}?`,
        text: "You won't be able to edit your answers after submitting.",
      };
    },
  },
};

export default ActivityMixin;
