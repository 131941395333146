// For the IBDP we override the sizes since 7% becomes too small, we round it up to 10
export const IBDPScale = {
  key: 'IBDP',
  intervals: [
    { min: 0, max: 71, color: 'kogPlatformRedLighten10' },
    { min: 72, max: 92, color: 'kogPlatformYellowBase' },
    { min: 93, max: 100, color: 'kogPlatformGreenBase' },
  ],
};

export const IGCSEScale = {
  key: 'IGCSE',
  intervals: [
    { min: 0, max: 40, color: 'kogPlatformRedLighten10' },
    { min: 41, max: 60, color: 'kogPlatformYellowBase' },
    { min: 61, max: 100, color: 'kogPlatformGreenBase' },
  ],
};

export const GCSEScale = {
  key: 'GCSE',
  intervals: [
    { min: 0, max: 54, color: 'kogPlatformRedLighten10' },
    { min: 55, max: 79, color: 'kogPlatformYellowBase' },
    { min: 80, max: 100, color: 'kogPlatformGreenBase' },
  ],
};

export const NGSSScale = {
  key: 'NGSS',
  intervals: [
    { min: 0, max: 59, color: 'kogPlatformRedLighten10' },
    { min: 60, max: 69, color: 'kogPlatformYellowLighten10' },
    { min: 70, max: 79, color: 'kogPlatformYellowBase' },
    { min: 80, max: 89, color: 'kogPlatformGreenLighten10' },
    { min: 90, max: 100, color: 'kogPlatformGreenBase' },
  ],
};

export const standardScale = {
  key: 'Standard',
  intervals: [
    { min: 0, max: 10, color: 'kogPlatformRedLighten10' },
    { min: 11, max: 30, color: 'kogPlatformGreenBaseAlpha30' },
    { min: 31, max: 60, color: 'kogPlatformGreenBaseAlpha60' },
    { min: 61, max: 80, color: 'kogPlatformGreenBaseAlpha80' },
    { min: 81, max: 100, color: 'kogPlatformGreenBase' },
  ],
};

export const PROGRESS_SCALES = {
  IBDP: IBDPScale,
  IGCSE: IGCSEScale,
  GCSE: GCSEScale,
  NGSS: NGSSScale,
  Standard: standardScale,
};

const validScales = [IBDPScale, IGCSEScale, GCSEScale, NGSSScale, standardScale];

const HEATMAP_SCALE_KEY_PREFIX = 'heatmapScaleSelection';

export function getScaleForCurriculum(curriculum) {
  for (let i = 0; i < validScales.length; i += 1) {
    const scale = validScales[i];
    if (scale.key === curriculum) {
      return scale;
    }
  }
  return standardScale;
}

export function getScalesForCurriculum(curriculum) {
  const scaleForCurriculum = getScaleForCurriculum(curriculum);
  const scales = [scaleForCurriculum];
  if (scaleForCurriculum.key !== standardScale.key) {
    scales.push(standardScale);
  }
  return scales;
}

export function getScaleClass(scalePercentage, curriculum, colorClassMap) {
  const curriculumScale = getScaleForCurriculum(curriculum).intervals;
  for (let i = 0; i < curriculumScale.length; i += 1) {
    const interval = curriculumScale[i];
    if (scalePercentage <= interval.max) {
      return colorClassMap[interval.color];
    }
  }
  return colorClassMap.kogPlatformRedLighten10;
}

export function saveScaleSelection(id, curriculum, scale) {
  const key = `${HEATMAP_SCALE_KEY_PREFIX}-${id}-${curriculum}`;
  localStorage.setItem(key, scale);
}

export function getScaleSelection(id, curriculum) {
  return localStorage.getItem(`${HEATMAP_SCALE_KEY_PREFIX}-${id}-${curriculum}`);
}
