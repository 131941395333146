import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { ClickObserver } from '@ckeditor/ckeditor5-engine';
import { toWidget, Widget } from '@ckeditor/ckeditor5-widget';

import { isClickInsideSelectedElement } from '../utils/commands.js';
import { createHiddenQuestionLabel, createLabel } from '../utils/editing-utils.ts';
import InsertOpenEndedTaskCommand from './open-ended-task-command.js';

function createContentText(task) {
  if (!task) {
    return 'Task not found';
  }
  if (!task.question) {
    return 'Question not found or deleted';
  }
  return task.question.question_html;
}

function handleLabel(task, writer, { addInvalidExerciseQuestion }) {
  if (!task) {
    return createLabel(writer, { text: 'Invalid task' });
  }
  if (!task.question) {
    return createLabel(writer, { text: 'Question not found or deleted' });
  }
  if (!task.question.active) {
    addInvalidExerciseQuestion(task.question);
    return createHiddenQuestionLabel(writer);
  }
  return null;
}

export default class OpenEndedTaskEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineCommands();
  }

  defineSchema() {
    const { schema } = this.editor.model;

    schema.register('openEndedTask', {
      inheritAllFrom: '$blockObject',
      allowAttributes: ['taskId'],
    });
  }

  defineCommands() {
    const { editor } = this;
    const { document } = editor.model;
    const { view, mapper } = editor.editing;
    editor.commands.add('insertOpenEndedTask', new InsertOpenEndedTaskCommand(editor));
    editor.commands.add('updateOpenEndedTask', new Command(editor));
    view.addObserver(ClickObserver);
    editor.listenTo(view.document, 'click', (_, data) => {
      if (isClickInsideSelectedElement(data, document, mapper, 'openEndedTask')) {
        editor.commands.get('updateOpenEndedTask').execute();
      }
    });
  }

  defineConverters() {
    const { conversion } = this.editor;

    conversion.for('upcast').elementToElement({
      view: 'kog-open-ended-task',
      model: (viewElement, { writer }) => {
        const taskId = viewElement.getAttribute('task-id');
        const openEndedTask = writer.createElement('openEndedTask', { taskId });
        return openEndedTask;
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'openEndedTask',
      view: (modelElement, { writer }) => {
        const taskId = modelElement.getAttribute('taskId');

        const div = writer.createRawElement('kog-open-ended-task', { 'task-id': taskId });

        return div;
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'openEndedTask',
      view: (modelElement, { writer }) => {
        const { config } = this.editor;
        const callbacks = config.get('openEndedTask.callbacks');
        const taskId = modelElement.getAttribute('taskId');
        const task = callbacks.getOpenEndedTaskById(taskId);

        const container = writer.createContainerElement('div', {
          'task-id': taskId,
        });

        const oetContainer = writer.createContainerElement('div', {
          class: 'OpenEndedTask margin-bottom-s margin-top-s',
        });
        const oetHeading = writer.createContainerElement('div', {
          class: 'OpenEndedTask-heading padd-m',
        });
        const oetHeaderStrong = writer.createContainerElement('strong');
        const oetHeaderTextPrimary = writer.createText('Open ended task');

        const oetHeaderTextSecondary = writer.createText(
          [` - ID: ${taskId}`, task ? `, LTQ ID: ${task.question?.id}` : ''].join(''),
        );

        const contentText = createContentText(task);
        const oetBody = writer.createRawElement(
          'div',
          { class: 'OpenEndedTask-body padd-m' },
          domElement => {
            // eslint-disable-next-line no-param-reassign
            domElement.innerHTML = contentText;
          },
        );

        writer.insert(writer.createPositionAt(container, 0), oetContainer);
        writer.insert(writer.createPositionAt(oetContainer, 0), oetHeading);
        writer.insert(writer.createPositionAt(oetHeading, 0), oetHeaderStrong);
        writer.insert(writer.createPositionAt(oetHeaderStrong, 0), oetHeaderTextPrimary);
        writer.insert(writer.createPositionAt(oetHeading, 1), oetHeaderTextSecondary);
        writer.insert(writer.createPositionAt(oetContainer, 1), oetBody);

        const label = handleLabel(task, writer, callbacks);
        if (label) {
          writer.insert(writer.createPositionAt(oetHeading, 2), label);
        }

        return toWidget(container, writer, { label: 'open ended task widget' });
      },
    });
  }
}
