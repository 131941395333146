<template>
  <div v-kog-mathjax>
    <draggable
      v-model="internalQuestions"
      animation="150"
      item-key="id"
    >
      <template #item="{ element: question, index }">
        <div
          class="flexContainer flexContainer-alignCenter cursorGrab"
          @click="questionPreviewModal(question)"
        >
          <div class="ArrangeList_tableRowNum"> {{ index + 1 }}. </div>
          <div
            class="inlineflexContainer flexContainer-alignCenter ArrangeList_tableRow flexChild-canGrow"
            :class="{
              'ArrangeList_tableRow--first': index === 0,
              'ArrangeList_tableRow--last': index === internalQuestions.length - 1,
              'ArrangeList_tableRow--only': internalQuestions.length === 1,
            }"
          >
            <div
              class="flexTable__item flexTable__item-size-10 ArrangeEsqList_question"
              v-text="getQuestionHtml(question)"
            />
            <div
              v-if="hasEducationSystemPapertype"
              class="text-right cursorDefault padd-right-s padd-left-s ArrangeEsqList-paperTypeCell"
            >
              <papertype-tag
                v-if="hasPaperType(question)"
                :name="getPaperType(question)"
                size="s"
              />
            </div>
            <div
              v-if="hasQuestionMarks(question) && hasEducationSystemMarks"
              v-tooltip="{
                theme: 'kog-tooltip',
                content: 'Marks',
                boundary: 'document.body',
              }"
              aria-label="Marks"
              class="flexTable__item flexTable__item-fixed-size-32 text-center cursorDefault"
            >
              {{ getQuestionMarks(question) }}
            </div>
            <div
              class="flexTable__item flexTable__item-fixed-size-42 text-center kogPlatformGray064"
            >
              <kog-round-button
                aria-label="Remove question"
                icon-class="fa-times"
                icon-style="solid"
                button-style="secondary-basic"
                @click="removeQuestion(question)"
              />
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';
import Draggable from 'vuedraggable';

import PapertypeTag from 'learning/common/components/papertype-tag.vue';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  hasEducationSystemMarks,
  hasEducationSystemPapertype,
} from 'sharedApp/services/educationSystem/education-system-service.js';
import { dangerouslyGetUnescapedText } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import QuestionPreviewModal from 'studyApp/components/teacher/assignments/question-preview-modal.vue';

export default {
  name: 'ArrangeEsqList',
  components: {
    Draggable,
    PapertypeTag,
    KogRoundButton,
  },
  directives: {
    tooltip: VTooltip,
  },
  inject: ['questionsSentBeforeSet'],
  props: {
    questions: {
      type: Array,
      required: true,
    },
    removeQuestion: {
      type: Function,
      required: true,
    },
    updateQuestions: {
      type: Function,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    educationSystem: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hasEducationSystemMarks: hasEducationSystemMarks(this.educationSystem),
      hasEducationSystemPapertype: hasEducationSystemPapertype(this.educationSystem),
    };
  },
  computed: {
    internalQuestions: {
      get() {
        if (!this.expanded) {
          return this.questions.slice(0, 1);
        }
        return this.questions;
      },
      set(newArray) {
        this.updateQuestions(newArray);
      },
    },
    paginatedQuestions() {
      return {
        results: this.questions,
        page_size: 10,
        current_page: 1,
        count: this.questions.length,
      };
    },
  },
  methods: {
    questionPreviewModal(question) {
      this.$modal(QuestionPreviewModal, {
        question,
        assignmentType: ASSIGNMENT_TYPES.EXAMSTYLE,
        paginatedQuestions: this.paginatedQuestions,
        questionsSentBeforeSet: this.questionsSentBeforeSet,
        subjectName: this.subjectName,
        showPosition: false,
        source: 'assignment_create',
        educationSystem: this.educationSystem,
      });
    },
    getQuestionHtml(question) {
      return dangerouslyGetUnescapedText(question.question_html);
    },
    hasPaperType(question) {
      return !!question.context?.papertype || !!question.papertype?.name;
    },
    getPaperType(question) {
      return question.context?.papertype || question.papertype?.name;
    },
    hasQuestionMarks(question) {
      return !!question.context?.marks || !!question.marks;
    },
    getQuestionMarks(question) {
      return question.context?.marks || question.marks;
    },
  },
};
</script>

<style scoped>
.ArrangeEsqList_question {
  overflow: hidden;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */

  max-height: 3em;

  line-height: 1.5em;
  text-overflow: ellipsis;
  white-space: pre-wrap;

  -webkit-line-clamp: 2;
}

.ArrangeEsqList-paperTypeCell {
  max-width: 25%;
}

.ArrangeEsqList-paperTypeCell > .KogTag {
  max-width: 100%;
}
</style>
