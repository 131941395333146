<template>
  <kog-modal
    title="Add student"
    class="ClassDetailsAddStudentsModalContainer"
  >
    <template #modalBody>
      <div class="flexContainer u-overflowHidden">
        <div class="FilterWrapper padd-right-fine-1">
          <input
            v-model="filterText"
            aria-label="Filter by name or email"
            type="text"
            placeholder="Filter by name or email"
            class="form-control"
          />
        </div>
        <div class="FilterWrapper">
          <select
            v-model="filterYearclassName"
            aria-label="Filter by year group"
            class="block form-control"
          >
            <option
              :value="''"
              selected="selected"
            >
              Show all active year groups
            </option>
            <option
              v-for="(students, yearclass, idx) in yearclassFilterItems"
              :key="`yc-${idx}`"
              :value="yearclass"
            >
              <div class="flexContainer flexContainer-spaceBetween">
                {{ yearclass }} ({{ students.length }})
              </div>
            </option>
          </select>
        </div>

        <div class="FilterSelectedInfo flexContainer flexContainer-flexEnd padd-right-s">
          <span
            ref="studentCount"
            v-tooltip="{
              theme: 'kog-tooltip',
              content: studentsNamesSelectedForAdding,
            }"
            class="padd-fine-2"
          >
            {{ studentsSelectedForAdding.length }}
            <span v-if="studentsSelectedForAdding.length === 1"> student </span>
            <span v-else> students </span>
            selected
          </span>
        </div>
      </div>

      <div class="flexContainer flexContainer-alignCenter margin-xxs">
        <span class="margin-right-xxs">Showing</span>
        <tag-radio-group
          v-model:selected-value="filterClassBelonging"
          group-label="Student list filter"
          :options="filterOptions"
        />
      </div>
      <kog-table>
        <template #header>
          <kog-table-header>
            <kog-table-cell-checkbox
              aria-label="Select all students"
              label="Student name"
              :is-label-hidden="false"
              :is-header-cell="true"
              :is-checked="isAllSelected"
              @check="onToggleAll(true)"
              @uncheck="onToggleAll(false)"
            />
            <kog-table-cell-text :is-header-cell="true"> Year group </kog-table-cell-text>
            <kog-table-cell-text :is-header-cell="true"> Current classes </kog-table-cell-text>
          </kog-table-header>
        </template>
        <template #body>
          <kog-table-row
            v-for="student in filteredStudents"
            :key="student.id"
            :is-disabled="student.already_in_class"
          >
            <kog-table-cell-checkbox
              :label="student.user.full_name"
              :is-label-hidden="false"
              :is-checked="selectedStudents.includes(student.id)"
              :is-disabled="student.already_in_class"
              :tooltip-text="student.user.email"
              @check="toggleStudent(student.id)"
              @uncheck="toggleStudent(student.id)"
            />
            <kog-table-cell-text>
              {{ student.yearclass.name }}
            </kog-table-cell-text>
            <kog-table-cell-text>
              <div>
                <kog-tag
                  v-if="student.already_in_class"
                  size="s"
                  label="This class"
                />
              </div>
              <div
                v-for="subject_class in student.other_classes_in_subject"
                :key="subject_class.id"
              >
                <kog-tag
                  size="s"
                  :label="subject_class.name"
                />
              </div>
            </kog-table-cell-text>
          </kog-table-row>
        </template>
      </kog-table>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-button
          label="Cancel"
          @click="closeModal"
        />
        <kog-button
          :disabled="studentsSelectedForAdding.length === 0"
          button-style="primary"
          label="Add students"
          @click="addStudentsToClass"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { VTooltip } from 'floating-vue';
import difference from 'lodash/difference.js';
import groupBy from 'lodash/groupBy.js';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import TagRadioGroup from 'sharedApp/components/filter/tag-radio-group.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import KogTableCellCheckbox from 'sharedApp/components/tables/kog-table-cell-checkbox.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';

const SHOW_ALL = 'SHOW_ALL';
const SHOW_NOT_IN_CLASS = 'SHOW_NOT_IN_CLASS';

export default {
  name: 'ClassDetailsAddStudentsModalContainer',
  components: {
    KogModal,
    KogTag,
    TagRadioGroup,
    KogTable,
    KogTableRow,
    KogTableHeader,
    KogTableCellText,
    KogTableCellCheckbox,
    KogButton,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    addStudents: {
      type: Function,
      required: true,
    },
    addableStudents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedStudents: [],
      filterText: '',
      filterYearclassName: '',
      filterClassBelonging: SHOW_NOT_IN_CLASS,
    };
  },
  computed: {
    schoolId() {
      return this.$route.params.school_id;
    },
    subjectClassId() {
      return this.$route.params.subjectclass_id;
    },
    studentsSelectedForAdding() {
      return difference(
        this.selectedStudents,
        this.addableStudents.filter(student => student.already_in_class).map(student => student.id),
      );
    },
    studentsNamesSelectedForAdding() {
      return this.addableStudents
        .filter(student => this.studentsSelectedForAdding.includes(student.id))
        .map(student => student.user.full_name)
        .join(', ');
    },
    studentsUserIDsSelectedForAdding() {
      return this.addableStudents
        .filter(student => this.studentsSelectedForAdding.includes(student.id))
        .map(student => student.user.id);
    },
    normalizedFilterText() {
      return this.filterText.toLocaleLowerCase();
    },
    studentsToShow() {
      if (this.filterClassBelonging === SHOW_NOT_IN_CLASS) {
        return this.addableStudents.filter(student => !student.already_in_class);
      }
      return this.addableStudents;
    },
    filteredStudents() {
      if (!this.filterText && !this.filterYearclassName) {
        return this.studentsToShow;
      }

      const includesNormalizedFilterText = text =>
        text.toLocaleLowerCase().includes(this.normalizedFilterText);

      const hasFilteredYearClass = student =>
        this.filterYearclassName === '' || student.yearclass.name === this.filterYearclassName;

      return this.studentsToShow.filter(
        student =>
          hasFilteredYearClass(student) &&
          (includesNormalizedFilterText(student.user.full_name) ||
            includesNormalizedFilterText(student.user.email)),
      );
    },
    yearclassFilterItems() {
      return groupBy(this.addableStudents, student => student.yearclass.name);
    },
    filterOptions() {
      const amountNotInClass = this.addableStudents.filter(
        student => !student.already_in_class,
      ).length;
      const options = [
        {
          label: `All (${this.addableStudents.length})`,
          value: SHOW_ALL,
        },
        {
          label: `Not in class (${amountNotInClass})`,
          value: SHOW_NOT_IN_CLASS,
        },
      ];
      return options;
    },
    isAllSelected() {
      const currentSelectableStudents = this.filteredStudents.filter(
        student => !student.already_in_class,
      );
      if (
        currentSelectableStudents.length > 0 &&
        currentSelectableStudents.every(student => this.selectedStudents.includes(student.id))
      ) {
        return true;
      }
      if (currentSelectableStudents.some(student => this.selectedStudents.includes(student.id))) {
        return 'mixed';
      }
      return false;
    },
  },
  mounted() {
    this.selectedStudents = this.addableStudents
      .filter(student => student.already_in_class)
      .map(student => student.id);
  },
  methods: {
    animateStudentCount() {
      const { classList } = this.$refs.studentCount;
      classList.add('ClassDetailsModals-countAnimation');
      setTimeout(() => {
        classList.remove('ClassDetailsModals-countAnimation');
      }, 200);
    },
    toggleStudent(id) {
      if (this.selectedStudents.includes(id)) {
        const index = this.selectedStudents.indexOf(id);
        this.selectedStudents.splice(index, 1);
      } else {
        this.selectedStudents.push(id);
      }
      this.animateStudentCount();
    },
    addStudentsToClass() {
      this.$mixpanel.trackEvent('Class details Add Student modal - click Add button', {
        school_id: this.schoolId,
        class_id: this.subjectClassId,
        users_selected: this.studentsUserIDsSelectedForAdding,
      });

      const numberOfStudentsAdded = this.studentsSelectedForAdding.length;
      this.closeModal();

      this.addStudents(
        {
          studentsSelectedForAdding: this.studentsSelectedForAdding,
        },
        numberOfStudentsAdded,
      );
    },
    onToggleAll(value) {
      const filteredStudentsNotInClass = this.filteredStudents
        .filter(student => !student.already_in_class)
        .map(student => student.id);
      if (value) {
        const studentsToSelect = filteredStudentsNotInClass.filter(
          id => !this.selectedStudents.includes(id),
        );
        this.selectedStudents.push(...studentsToSelect);
      } else {
        this.selectedStudents = this.selectedStudents.filter(
          id => !filteredStudentsNotInClass.includes(id),
        );
      }
      this.animateStudentCount();
    },
  },
};
</script>

<style scoped>
.ClassDetailsAddStudentsModalContainer {
  height: 95vh;
}

.FilterWrapper {
  width: 35%;
}

.FilterSelectedInfo {
  width: 30%;
}

.Table-studentName {
  cursor: pointer;
}

.Table-currentClass {
  overflow: hidden;
}

.u-fontSize80percent {
  font-size: 80%;
}

.u-overflowHidden {
  overflow: hidden;
}
</style>
