import { goToRoute } from './menu-item.js';

/* eslint-disable class-methods-use-this */
export default class SubMenuItem {
  constructor({ key, badgeCount = 0, displayText }) {
    this.key = key;
    this.badgeCount = badgeCount;
    this.displayText = displayText;
  }

  navigateTo() {
    throw new Error('No navigation behaviour implemented.');
  }

  goToRoute(router, currentRoute, name, params) {
    goToRoute(router, currentRoute, name, params);
  }
}
