<template>
  <div class="ResourceCard">
    <button
      class="ResourceCard-ResourceContainer KogButtonLegacy--noStyle"
      @click="openModal()"
    >
      <div class="ResourceCard-Overlay">
        <div class="ResourceCard-Header">
          <resource-action-buttons
            :resource="resource"
            :subject="subject"
            :theme="'light'"
            source="card"
          />
        </div>
      </div>
      <image-box
        v-if="isImage"
        ref="image"
        :src="resource.src"
        fit="clip"
      />
      <div
        v-if="isVideo"
        class="ResourceCard-IframeContainer"
      >
        <iframe
          title="Video resource"
          class="ResourceCard-Iframe"
          :src="resource.src"
          frameborder="0"
          allowfullscreen
        />
      </div>

      <div
        v-if="isInteractive"
        class="ResourceCard-IframeContainer"
      >
        <iframe
          title="Interactive resource"
          class="ResourceCard-Iframe"
          :src="resource.src"
          frameborder="0"
          allowfullscreen
        />
      </div>
    </button>
    <div class="ResourceCard-TextArea">
      <kog-icon
        v-if="isVideo"
        class="ResourceCard-TextArea-Icon"
        icon-class="fa-film"
        size="m"
        theme="light"
        fa-style="light"
      />
      <kog-icon
        v-if="isImage"
        class="ResourceCard-TextArea-Icon"
        icon-class="fa-file-image"
        size="m"
        theme="light"
        fa-style="regular"
      />
      <kog-icon
        v-if="isInteractive"
        class="ResourceCard-TextArea-Icon"
        icon-class="fa-hand-pointer"
        size="m"
        theme="light"
        fa-style="light"
      />
      <a
        :href="sectionUrl"
        @click="trackSectionLink"
      >
        <div class="text-regular line-clamp-1">
          {{ resource.subject_node_name }}
        </div>
        <div class="text-small">
          {{ resource.subject_node_number }}
        </div>
      </a>
      <content-hidden-label
        v-if="resource.isHidden"
        class="margin-left-auto"
      />
    </div>
  </div>
</template>

<script>
import ResourceActionButtons from 'learning/common/components/media-library/resource-action-buttons.vue';
import ResourceModal from 'learning/common/components/media-library/resource-modal.vue';
import SectionUrlMixin from 'learning/common/mixins/section-url-mixin.js';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';

export default {
  name: 'ResourceCard',
  components: {
    ContentHiddenLabel,
    ResourceActionButtons,
    ImageBox,
    KogIcon,
  },
  mixins: [SectionUrlMixin],
  props: {
    resource: {
      type: Object,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    trackingProps() {
      return {
        subject_name: this.subject.name,
        resource_url: this.resource.src,
        asset_type: this.resource.type,
      };
    },
    isImage() {
      return this.resource.type === 'image';
    },
    isVideo() {
      return this.resource.type === 'video';
    },
    isInteractive() {
      return this.resource.type === 'interactive';
    },
    sectionUrl() {
      const sectionUrl = this.generateSectionUrl(
        this.resource.subject_node_name,
        this.resource.subject_node_id,
        this.$route.params,
        this.resource,
      );
      return sectionUrl.toString();
    },
  },
  methods: {
    openModal() {
      this.$modal(ResourceModal, {
        resource: this.resource,
        subject: this.subject,
      });
      this.$mixpanel.trackEvent('Media library - Open card', this.trackingProps);
    },
    trackSectionLink() {
      this.$mixpanel.trackEvent('Media library - Click section link', {
        ...this.trackingProps,
        clicked_section_link_source: 'card',
      });
    },
  },
};
</script>

<style scoped>
.ResourceCard {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  background-color: var(--kogPlatformGray093);
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 var(--kogShadow020);
}

.ResourceCard-ResourceContainer {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  min-height: 64px;
}

.ResourceCard-Header {
  display: flex;
  justify-content: flex-end;

  margin: var(--space-s);

  opacity: 0;

  transition: opacity 0.1s ease;
}

.ResourceCard-TextArea {
  display: flex;
  align-items: center;
  padding: var(--space-xs) var(--space-s);
  background-color: var(--kogPlatformWhite);
}

.ResourceCard-TextArea-Icon {
  margin-right: var(--space-s);
  color: var(--kog-link-color);
}

.ResourceCard-Overlay {
  position: absolute;
  z-index: 1;

  width: 100%;
  height: 100%;

  transition: all 0.1s ease;
}

.ResourceCard-Overlay:hover {
  background-color: var(--kogShadow080);
}

.ResourceCard-Overlay:hover .ResourceCard-Header {
  opacity: 1;
}

.ResourceCard-IframeContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.ResourceCard-Iframe {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border: 0;
}
</style>
