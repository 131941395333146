<template>
  <div>
    <div
      v-if="assignment"
      class="kog-container margin-bottom-m"
    >
      <div class="kog-row">
        <div class="kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
          <h1 class="margin-top-0 margin-bottom-0 divider-bottom">
            {{ htmlEntities(assignment.name) }}
          </h1>
          <kog-paginator
            class="margin-top-m"
            :current-page="currentQuestionNumber"
            :number-of-pages="assignment.questions.length"
            @change-page="updateCurrentQuestionNumber"
          />
        </div>
      </div>
    </div>
    <div
      v-for="(question, index) in questions"
      :key="question.id"
    >
      <esq-assignment-question
        v-if="index === currentIndex"
        :answer-explanation-enabled="isMarkSchemeUnlocked"
        :is-loading="!assignment"
        :question="question"
        :question-index="index"
        :subject="subject"
        :tooltip="markSchemeLockTooltip"
        @toggle-show-answer="trackViewMarkscheme"
      >
        <template #esqCardBottomSlot>
          <div
            class="EsqAssignmentTake-footer padd-l flexContainer flexContainer-spaceBetween flexContainer-alignCenter"
          >
            <div>
              <report-feedback-button
                label="Report feedback"
                :subject-node-id="subjectNodeRootId"
                :disabled="isTeacherCreatedQuestion"
                :tooltip="feedbackButtonTooltip"
                :content-id="currentQuestion.id"
              />
            </div>
            <div>
              <router-link
                :to="goToClassOverview()"
                class="margin-right-m"
              >
                Finish later
              </router-link>
              <kog-button
                class="margin-right-m"
                :disabled="currentQuestionNumber <= 1"
                label="Previous"
                aria-label="Go to previous question"
                :has-icon="true"
                icon-class="fa-chevron-left"
                icon-style="solid"
                @click="previousQuestion"
              />
              <kog-button
                v-if="currentQuestionNumber < assignment.questions.length"
                label="Next"
                aria-label="Go to next question"
                :has-icon="true"
                icon-class="fa-chevron-right"
                icon-position="right"
                icon-style="solid"
                button-style="primary"
                @click="nextQuestion"
              />
              <kog-button
                v-if="currentQuestionNumber === assignment.questions.length"
                label="Mark as done"
                :has-icon="true"
                icon-class="fa-check-circle"
                icon-position="right"
                icon-style="solid"
                button-style="primary"
                @click="endAssignment"
              />
            </div>
          </div>
        </template>
      </esq-assignment-question>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { mapActions, mapState, useStore } from 'vuex';

import ReportFeedbackButton from 'learning/common/components/report-feedback-button.vue';
import htmlEntities from 'learning/common/libs/html-entities.js';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogPaginator from 'sharedApp/components/base/pagination/kog-paginator.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  CREATED_BY_TEACHER_PLAIN_TEXT_TAG,
  CREATED_BY_TEACHER_TAG,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { trackAssignmentOpen } from 'studyApp/utils/assignment-utils.js';

import EsqAssignmentQuestion from './components/esq-assignment-question.vue';

export default {
  name: 'EsqAssignmentTake',
  components: {
    EsqAssignmentQuestion,
    KogButton,
    KogPaginator,
    ReportFeedbackButton,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const { assignmentModule, subjectModule } = store.state;

    const assignmentName = computed(() => assignmentModule.assignment?.name);
    const subjectName = computed(() => subjectModule.subject?.name);

    useHead({
      title: () =>
        assignmentName.value
          ? `${assignmentName.value} | Take ESQ assignment | ${subjectName.value}`
          : '',
    });

    return {
      subjectName,
    };
  },
  data() {
    return {
      currentQuestionNumber: 1,
    };
  },
  computed: {
    ...mapState('userModule', {
      user: state => state.user,
    }),
    ...mapState('subjectModule', {
      subject: state => state.subject,
    }),
    ...mapState('assignmentModule', {
      assignment: state => state.assignment,
    }),
    source() {
      return this.$route.query.source || '';
    },
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    subjectId() {
      return this.subject.id;
    },
    currentIndex() {
      return this.currentQuestionNumber - 1;
    },
    currentQuestion() {
      return this.assignment.questions[this.currentIndex];
    },
    isMarkSchemeUnlocked() {
      return this.assignment.mark_scheme_status.is_unlocked;
    },
    markSchemeLockTooltip() {
      if (this.isMarkSchemeUnlocked) {
        return '';
      }

      if (this.assignment.mark_scheme_status.unlocked_setting === 'MANUAL_UNLOCK') {
        return `${this.teacherName} can unlock this mark scheme from the assignment ${this.assignment.name}`;
      }
      if (this.assignment.mark_scheme_status.unlocked_setting === 'AUTO_UNLOCK') {
        return 'The mark scheme will be unlocked immediately after the deadline';
      }
      return '';
    },
    teacherName() {
      return this.assignment.created_by;
    },
    isTeacherCreatedQuestion() {
      return (
        this.currentQuestion.tags.includes(CREATED_BY_TEACHER_TAG) ||
        this.currentQuestion.tags.includes(CREATED_BY_TEACHER_PLAIN_TEXT_TAG)
      );
    },
    feedbackButtonTooltip() {
      return this.isTeacherCreatedQuestion ? "Feedback to teachers' questions is disabled" : '';
    },
    questions() {
      return this.assignment?.questions || [];
    },
    subjectNodeRootId() {
      return this.subject?.subject_tree[0].id;
    },
  },
  async mounted() {
    await this.getAssignment(this.assignmentId);
    if (this.assignment.status === 'NOT_STARTED') {
      this.$router.push({
        name: 'classEsqAssignmentOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignmentId,
        },
      });
    }

    trackAssignmentOpen(this.subjectName, ASSIGNMENT_TYPES.EXAMSTYLE.toLowerCase(), this.source);
  },
  methods: {
    ...mapActions('assignmentModule', {
      getAssignment: 'getAssignment',
      markESQAssignmentAsDone: 'markESQAssignmentAsDone',
    }),
    previousQuestion() {
      this.currentQuestionNumber -= 1;
    },
    nextQuestion() {
      this.currentQuestionNumber += 1;
    },
    goToClassOverview() {
      return {
        name: 'subjectClassOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
      };
    },
    endAssignment() {
      this.markESQAssignmentAsDone(this.assignmentId)
        .then(() => {
          this.trackAssignment('Assignment - Complete');
          this.goToResult();
        })
        .catch(error => {
          this.$toast.showError('Saving attempt failed.');
          throw error;
        });
    },
    updateCurrentQuestionNumber(newCurrentQuestionNumber) {
      this.currentQuestionNumber = newCurrentQuestionNumber;
    },
    trackAssignment(eventName) {
      const eventProperties = {
        assignment_type: ASSIGNMENT_TYPES.EXAMSTYLE.toLowerCase(),
        num_subject_classes: this.assignment.subject_classes.length,
      };
      this.$mixpanel.trackEventViaBackend(eventName, eventProperties);
    },
    trackViewMarkscheme(showAnswer) {
      if (showAnswer) {
        this.trackAssignment('Assignment - View markscheme');
      }
    },
    goToResult() {
      this.$router.push({
        name: 'classEsqAssignmentResult',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignment.id,
        },
      });
    },
    htmlEntities,
  },
};
</script>
<style scoped>
.EsqAssignmentTake-footer {
  border-top: solid 1px var(--kog-dividers-default);
}
</style>
