import { createRouter, createWebHistory } from 'vue-router';

import MediaLibrary from 'learning/common/pages/media-library.vue';

import { TRANSITIONS } from 'sharedApp/animations/page-transition.vue';
import { setLastPageOffset } from 'sharedApp/mixins/scroll-position-mixin.js';
import DashboardContainer from 'studyApp/containers/dashboard-container.vue';
import Dashboard from 'studyApp/containers/dashboard/dashboard.vue';
import SubjectClassContainer from 'studyApp/containers/subject-class-container.vue';
import AssignmentsOverview from 'studyApp/pages/assignment/assignments-overview.vue';
import EsqAssignmentOverview from 'studyApp/pages/assignment/esq-assignment-overview.vue';
import EsqAssignmentResult from 'studyApp/pages/assignment/esq-assignment-result.vue';
import EsqAssignmentTake from 'studyApp/pages/assignment/esq-assignment-take.vue';
import QuestionAssignmentResult from 'studyApp/pages/assignment/question-assignment-result.vue';
import QuestionAssignmentTake from 'studyApp/pages/assignment/question-assignment-take.vue';
import ReadingAssignmentFocus from 'studyApp/pages/assignment/reading-assignment-focus.vue';
import TeacherAssignmentCreateContainer from 'studyApp/pages/assignment/teacher-assignment-create-container.vue';
import TeacherAssignmentDetailsContainer from 'studyApp/pages/assignment/teacher-assignment-details-container.vue';
import TeacherAssignmentEditContainer from 'studyApp/pages/assignment/teacher-assignment-edit-container.vue';
import TeacherAssignmentOverview from 'studyApp/pages/assignment/teacher-assignment-overview.vue';
import BookPrint from 'studyApp/pages/book-print.vue';
import DemoSchoolExpired from 'studyApp/pages/demo-school-expired/demo-school-expired.vue';
import ActivitySubmissions from 'studyApp/pages/features/activity-submissions.vue';
import PerformanceTaskOverview from 'studyApp/pages/features/pt-overview.vue';
import Notebook from 'studyApp/pages/notebook.vue';
import BattleResult from 'studyApp/pages/practice/battle/battle-result.vue';
import BattleSetup from 'studyApp/pages/practice/battle/battle-setup.vue';
import BattleTake from 'studyApp/pages/practice/battle/battle-take.vue';
import PracticeEsqSetup from 'studyApp/pages/practice/esq/practice-esq-setup.vue';
import TeacherSectionResponses from 'studyApp/pages/practice/section-responses/teacher-section-responses.vue';
import PracticeTestResult from 'studyApp/pages/practice/strength-test/practice-test-result.vue';
import PracticeTestSetupContainer from 'studyApp/pages/practice/strength-test/practice-test-setup-container.vue';
import PracticeTest from 'studyApp/pages/practice/strength-test/practice-test.vue';
import SectionText from 'studyApp/pages/section-text.vue';
import StudentDetails from 'studyApp/pages/student-details.vue';
import SubjectClassBook from 'studyApp/pages/subject-class-book.vue';
import SubjectClassManagement from 'studyApp/pages/subject-class-management.vue';
import SubjectClassOverview from 'studyApp/pages/subject-class-overview.vue';
import TeacherInsights from 'studyApp/pages/teacher-insights.vue';
import UserProfile from 'studyApp/pages/user-profile/user-profile.vue';

export default createRouter({
  history: createWebHistory('/study/app/'),
  routes: [
    {
      path: '/dashboard',
      component: DashboardContainer,
      children: [
        {
          path: '',
          name: 'studyDashboard',
          component: Dashboard,
        },
      ],
    },
    {
      name: 'subject-class-container',
      path: '/',
      redirect: '/dashboard',
      component: SubjectClassContainer,
      children: [
        {
          path: '/user-profile',
          name: 'userProfile',
          component: UserProfile,
          meta: {
            noClass: true,
            fullScreen: true,
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/book/',
          name: 'classBookResume',
          component: SectionText,
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/book/:nodeSlug-id-:nodeId/print/',
          name: 'bookPrint',
          component: BookPrint,
          meta: {
            menuRoute: 'book',
            fullScreen: true,
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/book/:nodeSlug-id-:nodeId/',
          name: 'classBook',
          component: SubjectClassBook,
          meta: {
            menuRoute: 'book',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/book/:nodeSlug-id-:nodeId/review/',
          name: 'reviewMode',
          component: SubjectClassBook,
          meta: {
            menuRoute: 'book',
            isReviewMode: true,
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/book/:nodeSlug-id-:nodeId/section-responses/',
          name: 'teacherSectionResponsesOverview',
          component: TeacherSectionResponses,
          meta: {
            fullScreen: true,
            enterTransition: TRANSITIONS.COVER_FROM_RIGHT,
            leaveTransition: TRANSITIONS.UNCOVER_TO_RIGHT,
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/notebook/',
          name: 'classNotebook',
          component: Notebook,
          props: true,
          meta: {
            menuRoute: 'notebook',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/notebook/:nodeSlug-id-:nodeId/',
          name: 'classNotebookNode',
          component: Notebook,
          props: true,
          meta: {
            menuRoute: 'notebook',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/media-library/',
          name: 'classMediaLibrary',
          component: MediaLibrary,
          meta: {
            menuRoute: 'overview',
            subMenuRoute: 'overview/mediaLibrary',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/overview/',
          name: 'subjectClassOverview',
          component: SubjectClassOverview,
          meta: {
            menuRoute: 'overview',
            subMenuRoute: 'overview/classOverview',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/manage/',
          name: 'manageClass',
          component: SubjectClassManagement,
          meta: {
            menuRoute: 'overview',
            subMenuRoute: 'overview/manageClass',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/create/:assignmentId/',
          name: 'teacherAssignmentCreate',
          component: TeacherAssignmentCreateContainer,
          meta: {
            fullScreen: true,
            menuRoute: 'assignments',
            enterTransition: TRANSITIONS.COVER_FROM_RIGHT,
            leaveTransition: TRANSITIONS.UNCOVER_TO_RIGHT,
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/edit/:assignmentId/',
          name: 'teacherAssignmentEdit',
          component: TeacherAssignmentEditContainer,
          meta: {
            menuRoute: 'assignments',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/overview/',
          name: 'teacherAssignmentOverview',
          component: TeacherAssignmentOverview,
          meta: {
            menuRoute: 'assignments',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/:assignmentId/details/:nodeId?/',
          name: 'teacherAssignmentDetails',
          component: TeacherAssignmentDetailsContainer,
          meta: {
            menuRoute: 'assignments',
            isReviewMode: true,
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/practice/test/setup',
          name: 'classStrengthTestSetup',
          component: PracticeTestSetupContainer,
          meta: {
            menuRoute: 'practice',
            subMenuRoute: 'practice/test',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/practice/test/:occasionId',
          name: 'classStrengthTest',
          component: PracticeTest,
          meta: {
            menuRoute: 'practice',
            subMenuRoute: 'practice/test',
          },
          children: [
            {
              name: 'classStrengthTestResult',
              path: 'result',
              component: PracticeTestResult,
              meta: {
                menuRoute: 'practice',
                subMenuRoute: 'practice/test',
              },
            },
          ],
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/practice/exams/setup/:topicId?',
          name: 'classPracticeEsqSetup',
          component: PracticeEsqSetup,
          meta: {
            menuRoute: 'practice',
            subMenuRoute: 'practice/esq',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/practice/battle/setup',
          name: 'classBattleSetup',
          component: BattleSetup,
          meta: {
            menuRoute: 'practice',
            subMenuRoute: 'practice/battle',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/practice/battle/:battleId/take',
          name: 'classBattleTake',
          component: BattleTake,
          meta: {
            menuRoute: 'practice',
            subMenuRoute: 'practice/battle',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/practice/battle/:battleId/result',
          name: 'classBattleResult',
          component: BattleResult,
          meta: {
            menuRoute: 'practice',
            subMenuRoute: 'practice/battle',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/my-assignments/',
          name: 'classAssignmentsOverview',
          component: AssignmentsOverview,
          meta: {
            menuRoute: 'assignments',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/:assignmentId/take/',
          name: 'classQuestionAssignmentTake',
          component: QuestionAssignmentTake,
          meta: {
            menuRoute: 'assignments',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/:assignmentId/result/',
          name: 'classQuestionAssignmentResult',
          component: QuestionAssignmentResult,
          meta: {
            menuRoute: 'assignments',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/esq/:assignmentId/overview/',
          name: 'classEsqAssignmentOverview',
          component: EsqAssignmentOverview,
          meta: {
            menuRoute: 'assignments',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/esq/:assignmentId/take/',
          name: 'classEsqAssignmentTake',
          component: EsqAssignmentTake,
          meta: {
            menuRoute: 'assignments',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/esq/:assignmentId/result/',
          name: 'classEsqAssignmentResult',
          component: EsqAssignmentResult,
          meta: {
            menuRoute: 'assignments',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/assignments/reading/:assignmentId/take/:nodeId?/',
          name: 'classReadingAssignmentTake',
          component: ReadingAssignmentFocus,
          meta: {
            menuRoute: 'assignments',
            isReviewMode: false,
            fullScreen: true,
            enterTransition: TRANSITIONS.COVER_FROM_RIGHT,
            leaveTransition: TRANSITIONS.UNCOVER_TO_RIGHT,
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/insights/',
          name: 'teacherInsights',
          component: TeacherInsights,
          meta: {
            menuRoute: 'insights',
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/book/:nodeSlug-id-:nodeId/submissions/',
          name: 'studentActivitySubmissions',
          component: ActivitySubmissions,
          meta: {
            fullScreen: true,
            enterTransition: TRANSITIONS.COVER_FROM_RIGHT,
            leaveTransition: TRANSITIONS.UNCOVER_TO_RIGHT,
            pageOverlaySequence: 2,
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/book/:nodeSlug-id-:nodeId/pt-overview/',
          name: 'performanceTaskOverview',
          component: PerformanceTaskOverview,
          meta: {
            fullScreen: true,
            enterTransition: TRANSITIONS.COVER_FROM_RIGHT,
            leaveTransition: TRANSITIONS.UNCOVER_TO_RIGHT,
            pageOverlaySequence: 1,
          },
        },
        {
          path: '/:classSlug/sid-:sid-cid-:cid/insights/students/:studentId/',
          name: 'studentDetails',
          component: StudentDetails,
          meta: {
            fullScreen: true,
            enterTransition: TRANSITIONS.COVER_FROM_RIGHT,
            leaveTransition: TRANSITIONS.UNCOVER_TO_RIGHT,
          },
        },
      ],
    },
    {
      name: 'demoSchoolExpired',
      path: '/demo-school-expired',
      component: DemoSchoolExpired,
      meta: {
        title: 'Demo School Expired',
      },
    },
    { path: '/:pathMatch(.*)*', redirect: '/dashboard' },
  ],
  scrollBehavior(to, from, savedPosition) {
    const pos = savedPosition ?? { left: 0, top: 0 };

    if (to.name === 'classBook' && to.path !== from.path) {
      setLastPageOffset(to.path, pos.top, pos.left);
    }
    if (to.meta.isReviewMode) {
      return false;
    }

    if (from.path === to.path) {
      return false;
    }

    return pos;
  },
});
