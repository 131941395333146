import { cloneDeep, groupBy, isEqual, keyBy } from 'lodash';

import { addTeacherToClass, removeTeacherFromClass } from '@apis/teachers';
import { fetchUsersList } from '@apis/users.js';

import { getLeafNodeIds } from 'sharedApp/libs/subject-tree-functions.js';
import {
  createSubjectClass,
  fetchSubjectClassDetails,
  fetchSubjectClassList,
} from 'studyApp/api/subject-classes.js';
import {
  fetchSubjectNodesVisibility,
  updateSubjectClassNodeVisibility,
} from 'studyApp/api/subject-nodes-visibility.js';

export default {
  namespaced: true,
  state: {
    currentSubjectClassDetails: {},
    currentSubjectClassTeachers: [],
    subjectClasses: [],
    teacherUsersBySubjectClassId: {},
    subjectNodesVisibilityMap: {},
    editedSubjectNodesVisibilityMap: {},
    isEditingVisibility: false,
    visibilityEditingTransactionToken: null,
  },
  getters: {
    activeClasses(state) {
      return state.subjectClasses.filter(subjectClass => !subjectClass.is_expired);
    },
    subjectClassesById(state) {
      return keyBy(state.subjectClasses, 'id');
    },
    getTeachingSubjectClasses(state) {
      return (teacherUserId, isExpired = false, subjectId = undefined) =>
        state.subjectClasses.filter(cls => {
          const teacherFilter = cls.teacher_user_ids.includes(teacherUserId);
          const expiredClassFilter = isExpired ? cls.is_expired : !cls.is_expired;
          const subjectFilter = subjectId ? cls.subject_id === subjectId : true;
          return teacherFilter && expiredClassFilter && subjectFilter;
        });
    },
    adminSubjectClasses(state) {
      return state.subjectClasses.filter(cls => !cls.is_expired);
    },
    isHiddenNodesModified(state) {
      return !isEqual(state.subjectNodesVisibilityMap, state.editedSubjectNodesVisibilityMap);
    },
    isAllContentHiddenWhileEditing(state) {
      return subjectNodeRoot => {
        const allLeafNodeIds = getLeafNodeIds(subjectNodeRoot);
        function isNodeHidden(leafNodeId) {
          return !state.editedSubjectNodesVisibilityMap[leafNodeId];
        }
        return allLeafNodeIds.every(isNodeHidden);
      };
    },
    isNodeHiddenWhileEditing(state) {
      return node => {
        const leafNodeIds = getLeafNodeIds(node);
        return leafNodeIds.every(
          leafNodeId => state.editedSubjectNodesVisibilityMap[leafNodeId] === false,
        );
      };
    },
    isNodeVisible(state) {
      return nodeId => state.subjectNodesVisibilityMap[nodeId];
    },
  },
  mutations: {
    setCurrentSubjectClassDetails(state, { subjectClassDetails, teachers }) {
      state.currentSubjectClassDetails = subjectClassDetails;
      state.currentSubjectClassTeachers = teachers;
    },
    setSubjectClasses(state, subjectClasses) {
      state.subjectClasses = subjectClasses;
    },
    setTeacherToClass(state, { userId, subjectClassId, isAdd }) {
      const subjectClass = state.subjectClasses.find(sc => sc.id === subjectClassId);
      if (isAdd) {
        subjectClass.teacher_user_ids = [...subjectClass.teacher_user_ids, userId];
      } else {
        subjectClass.teacher_user_ids = subjectClass.teacher_user_ids.filter(id => id !== userId);
      }
    },
    setSubjectClassName(state, { subjectClassId, name }) {
      const subjectClass = state.subjectClasses.find(sc => sc.id === subjectClassId);
      subjectClass.name = name;
    },
    setTeacherUsersBySubjectClassId(state, teacherUsersBySubjectClassId) {
      state.teacherUsersBySubjectClassId = teacherUsersBySubjectClassId;
    },
    setSubjectNodesVisibility(state, subjectNodesVisibilityMap) {
      state.subjectNodesVisibilityMap = subjectNodesVisibilityMap;
      state.editedSubjectNodesVisibilityMap = cloneDeep(subjectNodesVisibilityMap);
    },
    hideSubjectNodes(state, subjectNodeIds) {
      subjectNodeIds.forEach(subjectNodeId => {
        state.editedSubjectNodesVisibilityMap[subjectNodeId] = false;
      });
    },
    showSubjectNodes(state, subjectNodeIds) {
      subjectNodeIds.forEach(subjectNodeId => {
        state.editedSubjectNodesVisibilityMap[subjectNodeId] = true;
      });
    },
    startEditingVisibility(state) {
      state.isEditingVisibility = true;
      if (state.visibilityEditingTransactionToken === null) {
        state.visibilityEditingTransactionToken = Date.now();
      }
    },
    stopEditingVisibility(state) {
      state.isEditingVisibility = false;
    },
    resetChangesOnHiddenNodes(state) {
      state.editedSubjectNodesVisibilityMap = cloneDeep(state.subjectNodesVisibilityMap);
    },
    resetVisibilityEditingTransactionToken(state) {
      state.visibilityEditingTransactionToken = null;
    },
  },
  actions: {
    async fetchSubjectClassDetails({ commit, dispatch }, { subjectClassId }) {
      const subjectClassDetails = await fetchSubjectClassDetails(subjectClassId);
      let teachers = [];

      const teacherIds = subjectClassDetails.teacher_user_ids || [];
      if (teacherIds.length > 0) {
        teachers = await fetchUsersList(teacherIds);
      }

      commit('setCurrentSubjectClassDetails', { subjectClassDetails, teachers });
      dispatch('fetchSubjectNodesVisibility', { subjectClassId });
    },
    async fetchSubjectClassesTeachers({ commit, state }) {
      const userIds = state.subjectClasses.flatMap(cls => cls.teacher_user_ids);
      const users = await fetchUsersList(userIds);
      if (new Set(userIds).size !== users.length) {
        throw new Error('Not all teachers are loaded');
      }
      const usersById = keyBy(users, 'id');
      const teacherUsersBySubjectClassId = state.subjectClasses.reduce((result, cls) => {
        // eslint-disable-next-line no-param-reassign
        result[cls.id] = cls.teacher_user_ids.map(userId => usersById[userId]);
        return result;
      }, {});
      commit('setTeacherUsersBySubjectClassId', teacherUsersBySubjectClassId);
    },
    async fetchSubjectClassList({ commit }, useCache = undefined) {
      const subjectClasses = await fetchSubjectClassList(useCache);
      commit('setSubjectClasses', subjectClasses);
    },
    async addTeacherToSubjectClass({ commit }, { userId, subjectClassId }) {
      await addTeacherToClass(subjectClassId, userId);
      commit('setTeacherToClass', { userId, subjectClassId, isAdd: true });
    },
    async deleteTeacherFromSubjectClass({ commit }, { userId, subjectClassId }) {
      await removeTeacherFromClass(subjectClassId, userId);
      commit('setTeacherToClass', { userId, subjectClassId, isAdd: false });
    },
    async createSubjectClass({ dispatch }, { subjectClassName, subjectId, endDate }) {
      const result = await createSubjectClass(subjectClassName, subjectId, endDate);
      await dispatch('fetchSubjectClassList');
      await dispatch('fetchSubjectClassesTeachers');
      return result;
    },
    async fetchSubjectNodesVisibility({ commit }, { subjectClassId }) {
      const result = await fetchSubjectNodesVisibility(subjectClassId);
      commit('setSubjectNodesVisibility', result);
    },
    async saveHiddenSubjectNodes({ state, dispatch }, subjectClassId) {
      await updateSubjectClassNodeVisibility(subjectClassId, state.editedSubjectNodesVisibilityMap);
      dispatch('fetchSubjectNodesVisibility', { subjectClassId });
    },
    toggleNodeVisibility({ state, getters, commit }, subjectNode) {
      const affectedSubjectNodeIds = getLeafNodeIds(subjectNode);
      const isHidden = getters.isNodeHiddenWhileEditing(subjectNode);

      if (isHidden) {
        commit('showSubjectNodes', affectedSubjectNodeIds);
      } else {
        commit('hideSubjectNodes', affectedSubjectNodeIds);
      }
    },
  },
};
