<template>
  <div>
    <div
      class="padd-bottom-xl flexContainer flexContainer-center absoluteContainer SectionQuestionContainer"
    >
      <span
        v-if="!isSectionCompleted && !currentPracticeItem"
        class="heading-xs"
      >
        Complete section with {{ questionCount }}
        {{ pluralize('question', questionCount) }}
      </span>
    </div>
    <div class="flexContainer flexChild-canGrow flexContainer-column flexContainer-center">
      <div
        v-if="!currentPracticeItem"
        class="flexContainer flexChild-canGrow flexContainer-center width-100"
      >
        <div
          v-if="isSectionCompleted"
          class="flexContainer flexContainer-column"
        >
          <span class="heading-s">
            <i class="fas fa-lg fa-check-circle kogPlatformGreenBase" />
            You have completed this section with {{ questionCount }}
            {{ pluralize('question', questionCount) }}
          </span>
        </div>
        <kog-button
          v-else
          :label="`Start ${pluralize('question', questionCount)}`"
          :is-loading="$wait.is('starting_section_question')"
          button-style="primary"
          class="margin-bottom-s SectionQuestionContainer-progress"
          @click="start"
        />
      </div>
      <div
        v-if="currentPracticeItem"
        class="kog-col-lg-8 kog-col-sm-12 SectionQuestionContainer-questionContainer"
      >
        <div class="margin-bottom-l">
          <kog-ball-step-paginator
            :number-of-pages="questionCount"
            :current-page="currentQuestionNumber"
            :is-step-done="isPracticeItemDone"
          />
        </div>
        <kog-card :default-padding="false">
          <div class="padd-l">
            <p
              ref="beforeQuestionMarker"
              class="screenreader-only"
              role="note"
              tabindex="-1"
            >
              {{ beforeQuestionMarkerText }}
            </p>
            <div class="flexContainer flexContainer-flexEnd flexContainer-alignCenter">
              <question-level-labels
                v-if="shouldShowLevelsInfo"
                :levels="currentPracticeItem.question.attributes.levels"
                :relevant-group="subjectClassLevelGroup"
              />
              <question-tags
                v-if="subject && currentPracticeItem"
                :tags="currentPracticeItem.question.tags"
                :show-difficulty="showDifficulty"
                :subject-id="subject.id"
              />
            </div>
            <multiple-choice-question
              v-if="isMCQ"
              :choice-state="choiceState"
              :question="questionWithUpdatedQuestionHtml"
              :choices="currentPracticeItem.question.multiplechoicequestionchoice_set"
              :multi-select="currentPracticeItem.question.is_multi_select"
              :type="mcqType"
              :disabled="isSubmitting || isCurrentAnswerCorrect"
              @input="mcqChoiceItemClickHandler"
            />
            <div
              v-if="isSTQ"
              class="ShortTextQuestionAnswer-STQInputContainer"
            >
              <short-text-question
                :question="questionWithUpdatedQuestionHtml"
                :initial-answer="currentPracticeItem.answerToSubmit"
                :disabled="isCurrentAnswerCorrect"
                :is-correct="isCurrentAnswerCorrect"
                :is-incorrect="isCurrentAnswerIncorrect"
                @input="setStqAnswer"
                @submit-answer="onSubmit"
              />
            </div>
            <fill-in-the-blanks-question
              v-if="isFBQ"
              :question="questionWithUpdatedQuestionHtml"
              :disable-user-input="isSubmitting || isCurrentAnswerCorrect"
              :answers-with-correctness="currentPracticeItem?.solution?.correct_answers"
              :is-showing-answer-correctness="isCurrentAnswerCorrect || isCurrentAnswerIncorrect"
              @input="setFITBAnswers"
            />
            <p
              v-if="isCurrentAnswerCorrect"
              ref="afterAnswerMarker"
              class="screenreader-only"
              role="note"
              tabindex="-1"
            >
              Answer is correct. Navigate to and press the Continue button to proceed.
            </p>
          </div>
        </kog-card>
        <transition name="fade">
          <kog-card
            v-if="currentPracticeItem.solution"
            class="margin-top-xl"
          >
            <section-question-solution
              :practice-item="currentPracticeItem"
              :question-number="currentQuestionNumber"
            />
          </kog-card>
        </transition>
        <div class="flexContainer flexContainer-spaceBetween margin-top-l">
          <report-feedback-button
            :subject-node-id="nodeId"
            :content-id="currentPracticeItem.question.id"
          />
          <kog-loader
            :loading="isSubmitting"
            loading-msg="Checking your answer..."
          >
            <button
              v-if="!isCurrentAnswerCorrect"
              class="KogButtonLegacy KogButtonLegacy--primary"
              :disabled="!isQuestionAnswered"
              @click="submitAnswer"
            >
              Submit answer
            </button>
            <button
              v-if="isCurrentAnswerCorrect && hasMorePracticeItems"
              class="KogButtonLegacy KogButtonLegacy--primary"
              @click="nextQuestion()"
            >
              Continue
            </button>
            <div
              v-if="isSectionCompleted"
              class="success text-center"
            >
              Section completed.
              <router-link
                v-if="nextPageRoute"
                :to="nextPageRoute"
                class="KogButtonLegacy KogButtonLegacy--primary margin-left-m"
              >
                Continue
              </router-link>
            </div>
          </kog-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { find, isEqual, isNil, keyBy, mapValues } from 'lodash';
import pluralize from 'pluralize';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import QuestionLevelLabels from 'learning/common/components/question-level-labels.vue';
import ReportFeedbackButton from 'learning/common/components/report-feedback-button.vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogBallStepPaginator from 'sharedApp/components/base/pagination/kog-ball-step-paginator.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import MultipleChoiceQuestion from 'sharedApp/components/multiple-choice-question/multiple-choice-question.vue';
import ShortTextQuestion from 'sharedApp/components/short-text-question/short-text-question.vue';
import { IGCSE_LEVELS } from 'sharedApp/const/level-groups.js';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { shouldShowDifficultyTagForSubject } from 'sharedApp/services/featureflags/fixed-feature-flags.ts';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import {
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import QuestionTags from 'studyApp/components/questions/question-tags.vue';
import SectionQuestionSolution from 'studyApp/components/section-text/section-question-solution.vue';
import QuestionDifficultyMixin from 'studyApp/mixins/question-difficulty-mixin.js';

export default {
  name: 'SectionQuestionContainer',
  components: {
    KogBallStepPaginator,
    ReportFeedbackButton,
    MultipleChoiceQuestion,
    KogLoader,
    KogButton,
    KogCard,
    ShortTextQuestion,
    FillInTheBlanksQuestion,
    SectionQuestionSolution,
    QuestionTags,
    QuestionLevelLabels,
  },
  mixins: [QuestionDifficultyMixin, RoutesMixin],
  props: {
    currentSectionNode: {
      type: Object,
      required: true,
    },
    questionCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['completed'],
  data() {
    return {
      showDifficulty: false,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      ongoingReadingAssignmentOccasions: state =>
        state.bookModule.ongoingReadingAssignmentOccasions,
      subjectNodesProgress: state => state.statisticModule.subjectNodesProgress,
      currentPracticeItemIdx: state => state.bookModule.currentPracticeItemIdx,
      practiceItems: state => state.bookModule.sectionQuestionPracticeItems,
    }),
    ...mapGetters({
      currentPracticeItem: 'bookModule/currentPracticeItem',
      leafNodesWithContent: 'subjectModule/leafNodesWithContent',
    }),
    currentLeafNodeIndex() {
      return this.leafNodesWithContent.findIndex(ln => ln.id === this.nodeId);
    },
    nextLeafNode() {
      return this.leafNodesWithContent[this.currentLeafNodeIndex + 1] ?? null;
    },
    nextPageRoute() {
      if (this.nextLeafNode) {
        return {
          name: 'classBook',
          params: {
            classSlug: this.subjectClassSlug,
            sid: this.subjectId,
            cid: this.classId,
            nodeSlug: this.nextLeafNode.slug,
            nodeId: this.nextLeafNode.id,
          },
        };
      }
      return null;
    },
    isReadingAssignment() {
      return this.ongoingReadingAssignmentOccasions.length > 0;
    },
    isSectionCompleted() {
      return this.currentSectionStats.progress_stats?.is_completed;
    },
    currentSectionStats() {
      return this.subjectNodesProgress.stats_by_subjectnode?.[this.nodeId] || {};
    },
    beforeQuestionMarkerText() {
      const baseText = `Question ${this.currentQuestionNumber} of ${this.questionCount}.`;
      if (!this.isQuestionAnswered) {
        return `${baseText} After answering, navigate to and press the Submit Answer button`;
      }
      let endText = '';
      if (this.isCurrentAnswerCorrect) {
        endText = 'The answer was correct.';
      } else {
        endText = 'The answer was incorrect.';
      }
      return `${baseText} ${endText}`;
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subject.possible_levels);
    },
    subjectClassLevelGroup() {
      return IGCSE_LEVELS;
    },
    isMCQ() {
      return isMCQ(this.currentPracticeItem.question);
    },
    isSTQ() {
      return isSTQ(this.currentPracticeItem.question);
    },
    isFBQ() {
      return isFBQ(this.currentPracticeItem.question);
    },
    isCurrentAnswerCorrect() {
      return this.currentPracticeItem?.user_answer?.is_correct;
    },
    isCurrentAnswerIncorrect() {
      if (this.isSubmitting || !this.currentPracticeItem.last_submitted_answer) {
        return null;
      }

      return (
        this.currentPracticeItem.user_answer.is_correct === false &&
        isEqual(
          this.currentPracticeItem.answerToSubmit,
          this.currentPracticeItem.last_submitted_answer,
        )
      );
    },
    questionWithUpdatedQuestionHtml() {
      return this.getQuestionWithUpdatedHtml(
        this.currentPracticeItem.question,
        this.showDifficulty,
      );
    },
    isSubmitting() {
      return this.$wait.waiting('submit_current_answer');
    },
    currentQuestionNumber() {
      return this.currentPracticeItemIdx + 1;
    },
    hasMorePracticeItems() {
      return this.currentQuestionNumber < this.practiceItems.length;
    },
    isEveryQuestionCorrect() {
      return this.practiceItems.every(pi => pi.user_answer && pi.user_answer.is_correct);
    },
    isQuestionAnswered() {
      if (this.isSTQ || this.isMCQ) {
        return this.currentPracticeItem.user_answer?.answer?.length > 0;
      }
      if (this.isFBQ) {
        const blanks = this.currentPracticeItem.user_answer?.answer || [];
        const blanksIds = Object.keys(blanks);
        return (
          blanksIds.length > 0 &&
          blanksIds.every(blankId => blanks[blankId] && blanks[blankId].length > 0)
        );
      }
      return false;
    },
    trackingProps() {
      const sectionType = this.isReadingAssignment
        ? 'Reading assignment section'
        : 'Regular section';
      return {
        section_type: sectionType,
        subject_node_name: `${this.currentSectionNode.formatted_number_including_ancestors} ${this.currentSectionNode.name}`,
        question_count: this.questionCount,
      };
    },
    mcqType() {
      const {
        answerToSubmit,
        solution,
        last_submitted_answer: latestSubmittedAnswer,
      } = this.currentPracticeItem;

      if (solution && isEqual(answerToSubmit, latestSubmittedAnswer)) {
        return 'solution';
      }
      return 'normal';
    },
    choiceState() {
      const { answerToSubmit, user_answer: userAnswer } = this.currentPracticeItem;

      let { solution } = this.currentPracticeItem;
      if (!Array.isArray(solution)) {
        solution = [solution];
      }

      const isAnswerCorrected = !isNil(userAnswer?.is_correct);
      const correctStatus = id => {
        if (isAnswerCorrected) {
          return !!find(solution, { id });
        }
        return undefined;
      };
      const getChoiceState = id => ({ correct: correctStatus(id), selected: true });
      return mapValues(
        keyBy(answerToSubmit, id => id),
        getChoiceState,
      );
    },
  },
  created() {
    this.showDifficulty = shouldShowDifficultyTagForSubject(this.subject.id);
    this.fetchReadingAssignmentForSection(this.nodeId);
  },
  methods: {
    ...mapActions({
      updateOrCreateNodeProgress: 'statisticModule/updateOrCreateNodeProgress',
      fetchReadingAssignmentForSection: 'bookModule/fetchReadingAssignmentForSection',
    }),
    ...mapWaitingActions('bookModule', {
      submitCurrentAnswer: 'submit_current_answer',
      startSectionQuestion: 'starting_section_question',
    }),
    ...mapMutations({
      goToNextQuestion: 'bookModule/goToNextQuestion',
      setCurrentAnswer: 'bookModule/setCurrentAnswer',
    }),
    pluralize,
    markAsCompleted() {
      this.$emit('completed');
    },
    async start() {
      await this.startSectionQuestion(this.currentSectionNode.id);
      this.nextQuestion();
    },
    nextQuestion() {
      if (this.currentPracticeItemIdx < 0) {
        this.$mixpanel.trackEvent('Start section checkpoint', this.trackingProps);
      }
      this.goToNextQuestion();
      nextTick(() => {
        this.$refs.beforeQuestionMarker.focus();
      });
    },
    setStqAnswer(answer) {
      this.setCurrentAnswer(answer);
    },
    mcqChoiceItemClickHandler({ answer }) {
      this.setCurrentAnswer(answer);
    },
    setFITBAnswers(data) {
      this.setCurrentAnswer(data.answers);
    },
    onSubmit() {
      if (this.isCurrentAnswerCorrect && this.hasMorePracticeItems) {
        this.nextQuestion();
      } else {
        this.submitAnswer();
      }
    },
    async submitAnswer() {
      const practiceItem = this.currentPracticeItem;
      const hasCorrectAnswerAlready = practiceItem && this.isCurrentAnswerCorrect;
      const noAnswerSupplied = !practiceItem.user_answer.answer;

      if (hasCorrectAnswerAlready || noAnswerSupplied) {
        return;
      }
      try {
        await this.submitCurrentAnswer(this.currentSectionNode.id);
      } catch {
        this.$toast.showError("Can't submit your answer, please reload the page and try again");
        return;
      }
      if (this.isEveryQuestionCorrect) {
        await this.markAsCompleted();
      } else if (this.isCurrentAnswerCorrect) {
        this.$refs.afterAnswerMarker.focus();
      } else {
        nextTick(() => {
          this.$refs.beforeQuestionMarker.focus();
        });
      }
      intercomModule.trackEvent('answered-checkpoint-question');
    },
    isPracticeItemDone(questionNumber) {
      return (
        this.isSectionCompleted || this.practiceItems[questionNumber - 1]?.user_answer.is_correct
      );
    },
  },
};
</script>

<style scoped>
.SectionQuestionContainer :deep(.content-editable) {
  font-family: var(--kog-lato);
  font-size: var(--kog-font-size-content);
}

.SectionQuestionContainer-progress {
  width: 100%;
  max-width: 50vw;
}

.SectionQuestionContainer-questionContainer {
  max-width: 760px;
}

@media (--viewport-s) {
  .SectionQuestionContainer-progress {
    max-width: unset;
  }
}
</style>
