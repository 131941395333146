<template>
  <div>
    <kog-tag
      aria-labelledby="chip-legend"
      is-chip
      :icon-class="iconCLass"
      label=""
      size="m"
      :type="type"
      :context="context"
    />
    <span id="chip-legend">
      {{ legend }}
    </span>
  </div>
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export const SUBMISSION_STATUS = {
  SUBMITTED: 'submitted',
  NOT_SUBMITTED: 'not_submitted',
  FLAGGED: 'flagged',
};

const iconClassMapping = {
  [SUBMISSION_STATUS.SUBMITTED]: 'fa-user-check',
  [SUBMISSION_STATUS.NOT_SUBMITTED]: 'fa-user-times',
  [SUBMISSION_STATUS.FLAGGED]: 'fa-flag',
};

const typeMapping = {
  [SUBMISSION_STATUS.SUBMITTED]: 'positive',
  [SUBMISSION_STATUS.NOT_SUBMITTED]: 'informative',
  [SUBMISSION_STATUS.FLAGGED]: 'warning',
};

export default {
  name: 'ReflectionQuestionsChip',
  components: {
    KogTag,
  },
  props: {
    sectionStatus: {
      type: String,
      required: true,
      validator(value) {
        return [
          SUBMISSION_STATUS.SUBMITTED,
          SUBMISSION_STATUS.NOT_SUBMITTED,
          SUBMISSION_STATUS.FLAGGED,
        ].includes(value);
      },
    },
    legend: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconCLass() {
      return iconClassMapping[this.sectionStatus];
    },
    type() {
      return typeMapping[this.sectionStatus];
    },
    context() {
      if (this.sectionStatus === SUBMISSION_STATUS.SUBMITTED) {
        return `Section submitted`;
      }
      if (this.sectionStatus === SUBMISSION_STATUS.NOT_SUBMITTED) {
        return `Section not submitted`;
      }
      return `Section flagged`;
    },
  },
};
</script>
