<template>
  <div class="BookPrint-container">
    <header class="BookPrint-header">
      <img
        :src="ASSETS.KOGNITY_LOGO_URL"
        class="BookPrint-documentLogo"
        alt="Kognity"
      />
      <div class="BookPrint-headerCopyRight"> © {{ new Date().getFullYear() }} </div>
    </header>
    <kog-loader v-if="$wait.is('loading_print_page')" />
    <template v-else>
      <section-print
        v-if="isSection"
        @ready-to-print="print"
      />
      <scp-dqb-print
        v-else-if="isScpOrDqb"
        @ready-to-print="print"
      />
      <activity-sheet-print
        v-else-if="isActivitySheet"
        @ready-to-print="print"
      />
      <performance-task-print
        v-else-if="isPerformanceTask"
        @ready-to-print="print"
      />
    </template>
    <footer class="BookPrint-footer"> Kognity © {{ new Date().getFullYear() }} </footer>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState, useStore } from 'vuex';

import ASSETS from 'sharedApp/assets.js';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import { FEATURES_ENUM } from 'sharedApp/const/features.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ActivitySheetPrint from 'studyApp/pages/features/activity-sheet-print.vue';
import PerformanceTaskPrint from 'studyApp/pages/features/pt-print.vue';
import ScpDqbPrint from 'studyApp/pages/features/scp-dqb-print.vue';
import SectionPrint from 'studyApp/pages/section-print.vue';

export default {
  name: 'BookPrint',
  components: {
    ActivitySheetPrint,
    KogLoader,
    PerformanceTaskPrint,
    ScpDqbPrint,
    SectionPrint,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const route = useRoute();

    const { nodeId } = route.params;
    const { subject } = store.state.subjectModule;

    const getActivityBySubjectNodeId =
      store.getters['subjectNodeFeatureModule/getActivityBySubjectNodeId'];
    const subjectNodesById = store.getters['subjectModule/subjectNodesById'];
    const subjectNode = computed(() => subjectNodesById[nodeId]);
    const subjectNodeName = computed(() => subjectNode.value.name || 'Unknown section');

    useHead({
      title: `${subjectNodeName.value} | ${subject.name}`,
    });

    return {
      // Computed
      subjectNode,

      // Getters
      subjectNodesById,
      getActivityBySubjectNodeId,
    };
  },
  data() {
    return {
      ASSETS,
    };
  },
  computed: {
    isSection() {
      return !!this.subjectNode.section_id;
    },
    activity() {
      return this.getActivityBySubjectNodeId(this.nodeId);
    },
    isScpOrDqb() {
      return (
        this.activity.type === FEATURES_ENUM.STANDARD_CONTENT_PAGE ||
        this.activity.type === FEATURES_ENUM.DRIVING_QUESTION_BOARD
      );
    },
    isActivitySheet() {
      return this.activity.type === FEATURES_ENUM.ACTIVITY_SHEET;
    },
    isPerformanceTask() {
      return this.activity.type === FEATURES_ENUM.PERFORMANCE_TASK;
    },
  },
  async created() {
    if (!this.isSection) {
      await this.fetchFeaturesAndMappings({ subjectNodeIds: [this.nodeId] });
    }
  },
  methods: {
    ...mapWaitingActions('subjectNodeFeatureModule', {
      fetchFeaturesAndMappings: {
        action: 'fetchFeaturesAndMappings',
        loader: 'loading_print_page',
      },
    }),
    print() {
      setTimeout(() => window.print(), 2000);
    },
  },
};
</script>

<style>
.BookPrint-container {
  display: flex;
  place-self: center;
  max-width: 15cm;
}

.BookPrint-sectionContent .content-editable p {
  font-size: 12pt;
  line-height: 1.4em;
}

.BookPrint-sectionContent .anybox {
  margin: 24px 0;
  padding: 16px !important;
  border: 1px solid black;
}

.BookPrint-sectionContent .exercise-box {
  margin: 24px 0;
  padding: 16px !important;
  border: 1px solid black;
}

.BookPrint-sectionContent .anybox-content p {
  font-size: 11pt !important;
  line-height: 1.4em !important;
}

.BookPrint-sectionContent .anybox-content li {
  font-size: 11pt;
  line-height: 1.4em;
}

.BookPrint-sectionContent .anybox .icon_div {
  display: none !important;
}

.BookPrint-sectionContent .exercise-box-content p {
  font-size: 11pt;
  line-height: 1.4em;
}

.BookPrint-sectionContent .ContentBox {
  margin: 24px 0;
  padding: 16px !important;
  border: 1px solid black;
}

.BookPrint-sectionContent .ContentBox-icon {
  display: none !important;
}

.BookPrint-sectionContent .ContentBox-content p {
  font-size: 11pt !important;
  line-height: 1.4em !important;
}

.BookPrint-sectionContent .ContentBox-content li {
  font-size: 11pt;
  line-height: 1.4em;
}

.BookPrint-sectionContent .exercise-box .FillBlankQuestionInput {
  border: 1px solid black;
}

.BookPrint-sectionContent .exercise-box .icon_div {
  display: none !important;
}

.BookPrint-sectionContent .exercise-box-title {
  margin-left: 0 !important;
}

.BookPrint-sectionContent .exercise-box-subtitle {
  margin-left: 0;
}

.BookPrint-sectionContent .exercise-box .ExerciseQuestions-submitBtn {
  display: none !important;
}

.BookPrint-activityType {
  display: flex;
  margin-bottom: var(--space-xs);
}

.BookPrint-activityType-icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: var(--space-l);
  height: var(--space-xxl);

  font-size: var(--kog-font-size-content);
}

.BookPrint-activityType-text {
  display: flex;
  align-items: center;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  font-weight: bold;
  line-height: var(--space-l);
  color: var(--kogPlatformGray018);
}

@media print {
  .BookPrint-pageBreakBefore {
    page-break-before: always;
  }

  .BookPrint-header {
    position: relative;
    top: 0;

    display: flex;

    margin-left: -9px;
    padding-bottom: 10px;

    text-align: left;
  }

  .BookPrint-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: right;
  }

  .BookPrint-documentLogo {
    height: 100%;
    padding-left: 5px;
  }

  .BookPrint-headerCopyRight {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    padding-bottom: 3px;
    padding-left: 10px;
  }
  table tr {
    page-break-inside: auto;
  }
  table {
    word-break: break-word;
  }
}

@media screen {
  .BookPrint-header {
    display: none;
  }

  .BookPrint-footer {
    display: none;
  }
}
</style>
