<template>
  <section class="PracticeTestSetupContainer kog-container margin-bottom-xl">
    <div class="kog-row">
      <div class="kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
        <practice-setup-info
          class="margin-bottom-m"
          :source="source"
          :assignment-type="assignmentTypes.QUESTION"
        />
        <kog-card
          :default-padding="false"
          class="margin-bottom-l"
        >
          <div class="padd-top-l padd-right-l padd-left-l">
            <h1 class="divider-bottom margin-bottom-m padd-bottom-s heading-xl"> Strength test </h1>
            <kog-loader
              :loading="isTopicsListDataLoading"
              loading-msg="Fetching topics..."
            >
              <div class="margin-top-xxs flexChild-canGrow">
                <practice-test-setup-error :error="error" />
                <practice-test-setup-node-select
                  :topics="topics"
                  :checkbox-states="checkboxStates"
                  :subtopics-with-questions="subjectNodesWithStrengthQuestions"
                  :subject-nodes-visibility-map="subjectNodesVisibilityMap"
                  @toggle-selected="toggleSelected"
                />
              </div>
            </kog-loader>
          </div>
        </kog-card>
      </div>
    </div>
    <footer
      class="PracticeTestSetupContainer-footer PracticeTestSetupContainer-footer--desktop flexContainer flexContainer-alignCenter"
      :style="{ bottom: isDemoFooterShowing ? demoFooterHeight : 0 }"
    >
      <div class="kog-container">
        <div class="kog-row">
          <div
            class="kog-col-12 kog-col-lg-8 kog-offset-lg-2 flexContainer flexContainer-spaceBetween flexContainer-alignCenter"
          >
            <span
              aria-live="polite"
              class="muted"
            >
              {{ footerText }}
            </span>
            <kog-button
              label="Start strength test!"
              button-style="accent"
              :disabled="isSubmitButtonDisabled"
              @click="submit"
            />
          </div>
        </div>
      </div>
    </footer>
    <footer
      ref="footerMobile"
      class="PracticeTestSetupContainer-footer PracticeTestSetupContainer-footer--mobile flexContainer-justifyCenter"
    >
      <kog-button
        class="margin-bottom-l"
        label="Start strength test!"
        button-style="primary"
        :disabled="isSubmitButtonDisabled"
        @click="submit"
      />
    </footer>
  </section>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { cloneDeep } from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState, useStore } from 'vuex';

import { DEMO_FOOTER_HEIGHT_PX } from 'learning/common/components/demo-footer.vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import kogNodeLookupService from 'sharedApp/services/utils/nodeLookupService/node-lookup-service.js';
import PracticeSetupInfo, {
  VALID_SOURCES,
} from 'studyApp/components/practice/practice-setup-info.vue';

import PracticeTestSetupError from './practice-test-setup-error.vue';
import PracticeTestSetupNodeSelect from './practice-test-setup-node-select.vue';

const PRACTICE_TEST_SETUP_FOOTER_HEIGHT_PX = 72;
const MAX_LEVEL = 2;
const SUBTOPIC_LEVEL = 2;
const CHECKBOX_STATES = {
  selected: 'selected',
  semiselected: 'semiselected',
  deselected: 'deselected',
};

export default {
  name: 'PracticeTestSetupContainer',
  components: {
    KogButton,
    KogCard,
    KogLoader,
    PracticeTestSetupError,
    PracticeTestSetupNodeSelect,
    PracticeSetupInfo,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const { subjectModule } = store.state;
    const subjectName = computed(() => subjectModule.subject.name);

    useHead({
      title: () => `Setup strength test | ${subjectName.value}`,
    });
  },
  data() {
    return {
      subjectTreeClone: {},
      topics: [],
      selectedNodes: [],
      selectedSubtopicCount: 0,
      error: '',
      checkboxStates: CHECKBOX_STATES,
      isSubmitWithNoSelectedNodes: false,
      hasUrlParamSelectedNode: false,
      isAutomaticallySubmitted: false,
      assignmentTypes: ASSIGNMENT_TYPES,
      source: VALID_SOURCES.STRENGTH_TEST_SETUP,
    };
  },
  computed: {
    ...mapState('subjectModule', ['subject']),
    ...mapState({
      subjectNodesWithStrengthQuestions: state =>
        state.statisticModule.subjectNodesWithStrengthQuestions,
      subjectNodesVisibilityMap: state => state.subjectClassModule.subjectNodesVisibilityMap,
    }),
    ...mapGetters('userModule', ['isDemoUser']),
    showSelection() {
      return !this.hasUrlParamSelectedNode || this.isSubmitWithNoSelectedNodes;
    },
    areTopicsLoaded() {
      return this.topics && this.topics.length > 0;
    },
    isDemoFooterShowing() {
      return this.isDemoUser;
    },
    isSubmitButtonDisabled() {
      const isNoNodeSelected = this.selectedNodes.length === 0;
      const isOccasionBeingCreated = this.$wait.is('loading_occasion_creation');
      return isNoNodeSelected || isOccasionBeingCreated || this.isAutomaticallySubmitted;
    },
    isTopicsListDataLoading() {
      const isFetchingNodesWithStrengthQuestions = this.$wait.is(
        'loading_nodes_with_strength_questions',
      );
      return (
        !this.error &&
        (!this.showSelection || !this.areTopicsLoaded || isFetchingNodesWithStrengthQuestions)
      );
    },
    subtopicIds() {
      const subtopicIds = [];
      this.subjectTreeClone[0]?.children.forEach(parentNode => {
        parentNode.children.forEach(subtopic => {
          subtopicIds.push(subtopic.id);
        });
      });
      return subtopicIds;
    },
    footerText() {
      return `${this.selectedSubtopicCount} ${this.$term('subtopic')}${
        this.selectedSubtopicCount === 1 ? '' : 's'
      } selected`;
    },
    selectedNodeIds() {
      return this.$route.query.selected_nodes;
    },
  },
  watch: {
    subject: {
      async handler() {
        if (!this.subject || !this.subject.subject_tree) {
          return;
        }

        if (this.selectedNodeIds) {
          this.hasUrlParamSelectedNode = true;
        }

        this.subjectTreeClone = cloneDeep(this.subject.subject_tree);
        await this.fetchSubjectNodesWithStrengthQuestions({
          subjectId: this.subject.id,
          subjectNodeIds: this.subtopicIds,
        });

        const topicsWithSelectedStatus = this.subjectTreeClone[0].children.map(topic => {
          const newTopic = topic;
          newTopic.selectedStatus = this.checkboxStates.deselected;
          newTopic.children.map(subtopic => {
            const newSubTopic = subtopic;
            newSubTopic.selectedStatus = this.checkboxStates.deselected;
            return newSubTopic;
          });
          return newTopic;
        });

        this.topics = topicsWithSelectedStatus;
        this.checkForAutoNavigation();
      },
      immediate: true,
    },
  },
  created() {
    this.demoFooterHeight = `${DEMO_FOOTER_HEIGHT_PX}px`;
  },
  mounted() {
    intercomModule.updateLauncherVerticalPadding([
      {
        condition: this.isDemoUser,
        height: DEMO_FOOTER_HEIGHT_PX,
      },
      {
        condition: true,
        height: PRACTICE_TEST_SETUP_FOOTER_HEIGHT_PX,
      },
    ]);
  },
  unmounted() {
    this.deselectAll();
    intercomModule.updateLauncherVerticalPadding([
      {
        condition: this.isDemoUser,
        height: DEMO_FOOTER_HEIGHT_PX,
      },
    ]);
  },
  methods: {
    ...mapWaitingActions('practiceOccasionModule', {
      createSubjectStrengthOccasion: 'loading_occasion_creation',
    }),
    ...mapWaitingActions('statisticModule', {
      fetchSubjectNodesWithStrengthQuestions: 'loading_nodes_with_strength_questions',
    }),
    async submit() {
      if (this.selectedNodes.length === 0) {
        this.isSubmitWithNoSelectedNodes = true;
        return;
      }

      this.error = null;

      const data = {
        subjectId: this.subject.id,
        selectedNodes: this.selectedNodes,
      };

      try {
        const strengthOccasion = await this.createSubjectStrengthOccasion(data);
        this.handleOccasionCreatedSuccess(strengthOccasion);
      } catch (e) {
        this.handleOccasionCreatedFailed(e);
      }
    },
    handleOccasionCreatedSuccess(occasion) {
      const occasionId = occasion.id;
      this.$router.push({
        name: 'classStrengthTest',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          occasionId,
        },
      });
    },
    handleOccasionCreatedFailed(rawError) {
      const errorResponse = rawError.response;
      if (
        errorResponse &&
        errorResponse.data &&
        errorResponse.data.non_field_errors &&
        errorResponse.data.non_field_errors.length > 0
      ) {
        // Errors are returned as a list, but there's currently never more than one error returned.
        // TODO: If you have time, update BE to return something better and hand over
        //       error representation to the FE code.
        const {
          data: {
            non_field_errors: [error],
          },
        } = errorResponse;
        this.error = error;
        window.scrollTo(0, 0);
      } else {
        this.$toast.showError('There was an error starting the strength test, please try again.');
      }
    },
    checkForAutoNavigation() {
      const { subjectTreeClone, selectedNodeIds } = this;
      if (selectedNodeIds) {
        this.isAutomaticallySubmitted = true;
        selectedNodeIds.split(',').forEach(stringNodeId => {
          const selectedNodeId = parseInt(stringNodeId, 10);
          const node = kogNodeLookupService.findNodeById(selectedNodeId, subjectTreeClone);

          if (node) {
            this.toggleSelected(node);
          }
        });

        this.clearSelectedNodesParam();
        this.submit();
      }
    },
    deselectAll() {
      for (let i = 0; i < this.topics.length; i += 1) {
        const topic = this.topics[i];
        topic.selectedStatus = this.checkboxStates.deselected;
        this.updateChildrenOfNode(topic);
      }
      this.updateParentOfNode(this.topics[0]);
      this.updateSelectedNodes();
    },
    updateChildrenOfNode(node) {
      if (node.level >= MAX_LEVEL) {
        return;
      }
      node.children.forEach(child => {
        if (this.subjectNodesWithStrengthQuestions.includes(child.id)) {
          child.selectedStatus = node.selectedStatus; // eslint-disable-line no-param-reassign
        }
      });
    },
    updateParentOfNode(node) {
      if (!node.parent) {
        return;
      }
      const parent = kogNodeLookupService.findNodeById(node.parent, this.subjectTreeClone);
      const anyChildrenSelected = parent.children.some(
        c => c.selectedStatus === this.checkboxStates.selected,
      );
      const allChildrenSelected = parent.children.every(c => {
        if (node.level === 1) {
          return c.selectedStatus === this.checkboxStates.selected;
        }

        return (
          c.selectedStatus === this.checkboxStates.selected ||
          !this.subjectNodesWithStrengthQuestions.includes(c.id)
        );
      });

      if (allChildrenSelected) {
        parent.selectedStatus = this.checkboxStates.selected;
      } else if (anyChildrenSelected) {
        parent.selectedStatus = this.checkboxStates.semiselected;
      } else {
        parent.selectedStatus = this.checkboxStates.deselected;
      }

      this.updateParentOfNode(parent);
    },
    updateSelectedNodes() {
      this.selectedNodes = [];
      this.accumulateSelectedNodesExclRedundantChildren(
        this.subjectTreeClone[0],
        this.selectedNodes,
      );
      this.topics = this.topics.slice();
    },
    accumulateSelectedNodesExclRedundantChildren(node, accumulatedSelectedIds) {
      if (node.selectedStatus === this.checkboxStates.selected) {
        accumulatedSelectedIds.push(node.id);
      } else {
        for (let i = 0; i < node.children.length; i += 1) {
          const child = node.children[i];
          this.accumulateSelectedNodesExclRedundantChildren(child, accumulatedSelectedIds);
        }
      }
    },
    updateSelectedSubtopicCount() {
      let selectedSubtopicCount = 0;
      const allNodes = [this.subjectTreeClone[0]];
      while (allNodes.length > 0) {
        const node = allNodes.shift();
        if (node.selectedStatus === this.checkboxStates.selected && node.level === SUBTOPIC_LEVEL) {
          selectedSubtopicCount += 1;
        }
        if (node.children) {
          allNodes.push(...node.children);
        }
      }
      this.selectedSubtopicCount = selectedSubtopicCount;
    },
    toggleSelected(node) {
      this.toggleNode(node);
      this.updateChildrenOfNode(node);
      this.updateParentOfNode(node);
      this.updateSelectedNodes();
      this.updateSelectedSubtopicCount();
      this.error = null;
    },
    toggleNode(node) {
      const localNode = node;
      if (localNode.level < MAX_LEVEL) {
        const allChildrenSelected = localNode.children.every(
          c =>
            c.selectedStatus === this.checkboxStates.selected ||
            !this.subjectNodesWithStrengthQuestions.includes(c.id),
        );

        if (allChildrenSelected) {
          localNode.selectedStatus = this.checkboxStates.deselected;
        } else {
          localNode.selectedStatus = this.checkboxStates.selected;
        }
      } else if (localNode.selectedStatus === this.checkboxStates.selected) {
        localNode.selectedStatus = this.checkboxStates.deselected;
      } else {
        localNode.selectedStatus = this.checkboxStates.selected;
      }
    },
    clearSelectedNodesParam() {
      const query = { ...this.$route.query };
      delete query.selected_nodes;
      this.$router.replace({ query });
    },
  },
};
</script>
<style scope>
.PracticeTestSetupContainer {
  height: 100%;
}

.PracticeTestSetupContainer-footer {
  position: fixed;
  z-index: 500;
  right: 0;
  left: 0;
}

.PracticeTestSetupContainer-footer--desktop {
  bottom: 0;
  height: 72px;
  background-color: var(--kogPlatformWhite);
  box-shadow: 0 -8px 16px -4px var(--kogShadow020);
}

.PracticeTestSetupContainer-footer--mobile {
  bottom: 72px; /* this is accounting for study app bottom menu height */
  display: none;
}

.PracticeTestSetupContainer-footer--mobile button {
  box-shadow: 0 4px 8px 0 var(--kogShadow030);
}

@media only screen and (--viewport-s) {
  .PracticeTestSetupContainer-footer--desktop {
    display: none;
  }

  .PracticeTestSetupContainer-footer--mobile {
    display: flex;
  }
}
</style>
