<template>
  <div class="ShortTextQuestion">
    <div
      v-if="shouldDisplayAll || shouldDisplayQuestionOnly"
      :class="{
        'margin-bottom-l': shouldDisplayAll,
      }"
    >
      <question-text
        v-if="isShowingQuestionText"
        :question="question"
      />
    </div>
    <template v-if="shouldDisplayAll || shouldDisplayInputOnly">
      <div class="ShortTextQuestion-InputContainer">
        <question-input
          :id="`stq-${question.id}`"
          v-model:value="answer"
          type="text"
          autocomplete="off"
          maxlength="255"
          :name="`stq-${question.id}`"
          placeholder="Type here"
          class="ShortTextQuestion-Input"
          :display-state="currentState"
          :question-type="questionType"
          :disabled="disabled"
          :label="getLabel"
          input-icon="pen"
          :is-dismissible="isDismissible"
          @keypress.enter.stop="onSubmit"
          @keydown.space.stop
          @keydown.right.stop
          @keydown.left.stop
          @input="onInput"
          @focus="onFocus"
          @blur="onBlur"
          @dismiss="$event => $emit('dismiss')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {
  DISPLAY_STATE,
  VALID_QUESTION_TYPES,
} from 'sharedApp/components/question-input/question-input-utility.js';
import QuestionInput from 'sharedApp/components/question-input/question-input.vue';
import QuestionText from 'sharedApp/components/questions/question-text.vue';

import { STQ_DISPLAY_STATE, STQ_TYPE_OPTIONS } from './short-text-question-utility.ts';

export default {
  name: 'ShortTextQuestion',
  components: {
    QuestionText,
    QuestionInput,
  },
  props: {
    /**
     * Makes the input field un-clickable and un-editable
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Adds styling and SR info associated with a correct answer
     */
    isCorrect: {
      type: Boolean,
      default: false,
    },
    /**
     * Adds styling and SR info associated with an incorrect answer
     */
    isIncorrect: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, will show a "close" button that when clicked emits the 'dismiss' event
     */
    isDismissible: {
      type: Boolean,
      default: false,
    },
    /**
     * Used to set user answer
     */
    initialAnswer: {
      type: String,
      default: '',
    },
    /**
     * Determines the style which the input is rendered with
     */
    type: {
      type: String,
      default: 'normal',
      validator: val => Object.values(STQ_TYPE_OPTIONS).includes(val),
    },
    /**
     * Contains the question text and the question type
     */
    question: {
      type: Object,
      required: true,
      validator: question =>
        ['question_html', 'id'].every(key => Object.keys(question).includes(key)),
    },
    displayState: {
      type: String,
      default: 'all',
      validator: val => Object.values(STQ_DISPLAY_STATE).includes(val),
    },
    isShowingQuestionText: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['dismiss', 'blur', 'focus', 'submit-answer', 'input'],
  data() {
    return {
      answer: '',
      questionType: VALID_QUESTION_TYPES.STQ,
    };
  },
  computed: {
    getLabel() {
      if (this.type === STQ_TYPE_OPTIONS.SOLUTION) {
        return 'Question solution';
      }

      if (this.isCorrect) {
        return 'Your answer is correct';
      }

      if (this.isIncorrect) {
        return 'Your answer is incorrect';
      }

      return 'Your answer';
    },
    currentState() {
      if (this.type === STQ_TYPE_OPTIONS.SOLUTION) {
        return DISPLAY_STATE.SOLUTION;
      }
      if (this.isCorrect) {
        return DISPLAY_STATE.CORRECT;
      }
      if (this.isIncorrect) {
        return DISPLAY_STATE.INCORRECT;
      }

      return DISPLAY_STATE.NEUTRAL;
    },
    shouldDisplayAll() {
      return this.displayState === STQ_DISPLAY_STATE.ALL;
    },
    shouldDisplayQuestionOnly() {
      return this.displayState === STQ_DISPLAY_STATE.QUESTION;
    },
    shouldDisplayInputOnly() {
      return this.displayState === STQ_DISPLAY_STATE.INPUT;
    },
  },
  watch: {
    initialAnswer: {
      handler(answer) {
        this.answer = answer;
      },
      immediate: true,
    },
  },
  methods: {
    onInput() {
      /**
       * Emitted when the input changes
       *
       * @property {string} answer current answer in the input field
       */
      this.$emit('input', this.answer);
    },
    onBlur() {
      /**
       * Emitted when the input loses focus
       *
       * @property {string} answer current answer in the input field
       */
      this.$emit('blur', this.answer);
    },
    onFocus() {
      /**
       * Emitted when the input gains focus
       *
       * @property {string} answer current answer in the input field
       */
      this.$emit('focus', this.answer);
    },
    onSubmit() {
      /**
       * Emitted when the enter is pressed in the input
       *
       * @property {string} answer current answer in the input field
       */
      this.$emit('submit-answer', this.answer);
    },
  },
};
</script>
