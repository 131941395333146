<template>
  <kog-modal
    :maximize="true"
    title="Select recipients"
    :close-modal="closeModal"
  >
    <template #subHeader>
      <div class="margin-bottom-0 margin-top-m">
        <div>You can send this assignment to selected students in Kognity.</div>
        <google-classes-inline-list
          v-if="googleClasses.length > 0"
          :google-classes="googleClasses"
        >
          <template #prefix>
            <span class="text-bold">{{ subjectClassName }}</span> is connected to
          </template>
        </google-classes-inline-list>
      </div>
    </template>

    <template #modalBody>
      <div class="padd-l SelectRecipientsModal-body">
        <generic-warning-box
          v-if="isNoGCRecipientsInfoVisible"
          class="ConnectGoogleClassroomReviewStep-warningBox"
          mode="info"
        >
          <p class="text-bold">
            {{ reconnectWarningMessageTitle }}
          </p>
          <google-classes-inline-list :google-classes="googleClassesWithNoStudents">
            <template #prefix> If </template>
            <template #suffix>
              {{ reconnectWarningMessage }}
            </template>
          </google-classes-inline-list>
        </generic-warning-box>

        <kog-table>
          <template #colgroup>
            <col width="100%" />
            <col />
            <col v-if="googleClasses.length > 0" />
          </template>
          <template #header>
            <kog-table-header>
              <kog-table-cell-text :is-header-cell="true"> Student names </kog-table-cell-text>
              <kog-table-cell-checkbox
                :is-header-cell="true"
                label="Kognity"
                :is-label-hidden="false"
                :is-checked="allStudentsSelected"
                @check="toggleAllStudents()"
                @uncheck="toggleAllStudents()"
              />
              <kog-table-cell-checkbox
                v-if="googleClasses.length > 0"
                :is-checked="isAnyGoogleStudentSelected && allGoogleClassStudentsSelected"
                :is-header-cell="true"
                :is-label-hidden="false"
                :is-disabled="allGoogleClassStudentsDisabled"
                label="Google Classroom"
                @check="toggleAllGoogleClassStudents()"
                @uncheck="toggleAllGoogleClassStudents()"
              />
            </kog-table-header>
          </template>
          <template #body>
            <kog-table-row
              v-for="(student, index) in sortedSubjectClassStudents"
              :key="student.id"
              :row-index="index"
            >
              <kog-table-cell-text
                :is-multi-line="true"
                :tag-label="shouldShowLevelsInfo ? $term(student.level.name) : ''"
              >
                {{ student.name }}
              </kog-table-cell-text>
              <kog-table-cell-checkbox
                :is-checked="selectedStudentsList[student.school_student]"
                @check="toggleStudent(student)"
                @uncheck="toggleStudent(student)"
              />
              <template v-if="googleClasses.length > 0">
                <!-- eslint-disable max-len -->
                <kog-table-cell-checkbox
                  v-if="student.googleStudent"
                  :is-checked="
                    selectedGoogleClassStudentsList[student.googleStudent.upstream_student_id]
                      .isSelected
                  "
                  :is-disabled="
                    selectedGoogleClassStudentsList[student.googleStudent.upstream_student_id]
                      .isDisabled
                  "
                  @check="toggleGoogleClassStudent(student.googleStudent.upstream_student_id)"
                  @uncheck="toggleGoogleClassStudent(student.googleStudent.upstream_student_id)"
                />
                <!-- eslint-enable max-len -->
                <kog-table-cell-text
                  v-else
                  class="SelectRecipientsModal-emptyTextCell"
                >
                  -
                </kog-table-cell-text>
              </template>
            </kog-table-row>
            <kog-table-row
              v-for="(student, index) in unmatchedGoogleStudents"
              :key="student.upstream_student_id"
              :row-index="index + sortedSubjectClassStudents.length"
            >
              <kog-table-cell-text>
                {{ student.name }}
              </kog-table-cell-text>
              <kog-table-cell-text class="SelectRecipientsModal-emptyTextCell">
                -
              </kog-table-cell-text>
              <!-- eslint-disable max-len -->
              <kog-table-cell-checkbox
                :is-checked="
                  selectedGoogleClassStudentsList[student.upstream_student_id].isSelected
                "
                @check="toggleGoogleClassStudent(student.upstream_student_id)"
                @uncheck="toggleGoogleClassStudent(student.upstream_student_id)"
              />
              <!-- eslint-enable max-len -->
            </kog-table-row>
          </template>
        </kog-table>

        <generic-warning-box
          v-if="selectedUnmatchedGoogleStudents.length > 0"
          :has-padd-bottom="false"
          class="margin-top-m"
        >
          <strong>
            {{ unmatchedGoogleStudentsWarningTitle }}
          </strong>
          <p>
            {{ unmatchedGoogleStudentsWarningMessage }}
          </p>
        </generic-warning-box>
      </div>
    </template>

    <template #modalFooter>
      <div
        class="width-100 flexContainer flexContainer-center flexContainer-spaceBetween padd-left-xl"
      >
        <div class="muted">
          {{ numberOfSelectedStudentsText }}
        </div>
        <div class="KogButtonSet">
          <button
            class="KogButtonLegacy"
            @click="closeModal"
          >
            Cancel
          </button>
          <button
            class="KogButtonLegacy KogButtonLegacy--primary"
            @click="updateRecipients()"
          >
            Update recipients
          </button>
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import KogTableCellCheckbox from 'sharedApp/components/tables/kog-table-cell-checkbox.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';
import posthog from 'sharedApp/libs/posthog.ts';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import { listSeparator } from 'sharedApp/utils/string-utils.js';
import GoogleClassesInlineList from 'studyApp/components/teacher/google-classes-inline-list.vue';

export default {
  name: 'SelectRecipientsModal',
  components: {
    KogModal,
    GenericWarningBox,
    GoogleClassesInlineList,
    KogTable,
    KogTableHeader,
    KogTableRow,
    KogTableCellCheckbox,
    KogTableCellText,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    subjectClassStudents: {
      type: Array,
      required: true,
    },
    subjectClassName: {
      type: String,
      required: true,
    },
    updateSelectedStudents: {
      type: Function,
      required: true,
    },
    selectedStudents: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    googleClasses: {
      type: Array,
      required: true,
    },
    googleClassroomRecipients: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedStudentsList: { ...this.selectedStudents },
      selectedGoogleClassStudentsList: this.getSelectedGoogleClassStudentsList(),
    };
  },
  computed: {
    watchedStudentList() {
      // This trick is used for watcher to be able to have access to both
      // old and new values within the object:
      // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
      return { ...this.selectedStudentsList };
    },
    isNoGCRecipientsInfoVisible() {
      return this.googleClassesWithNoStudents.length > 0;
    },
    reconnectWarningMessageTitle() {
      let title = '';
      this.googleClassesWithNoStudents.forEach((gc, index) => {
        title = title + listSeparator(index, this.googleClassesWithNoStudents.length) + gc.name;
      });
      if (this.googleClassesWithNoStudents.length > 1) {
        title += ' have 0 students';
      } else {
        title += ' has 0 students';
      }
      return title;
    },
    reconnectWarningMessage() {
      const singleGCNoStudentsText = `does have students, you need to reconnect this class to ${this.subjectClassName}.
        This will enable a new feature, 'send to a subset of students in Classroom'.`;
      const multipleGCNoStudentsText = `do have students, you need to reconnect these classes to ${this.subjectClassName}.
        This will enable a new feature, 'send to a subset of students in Classroom'.`;
      if (this.googleClassesWithNoStudents.length === 1) {
        return singleGCNoStudentsText;
      }
      return multipleGCNoStudentsText;
    },
    googleClassesWithNoStudents() {
      return this.googleClasses.filter(gc => gc.students.length === 0);
    },
    selectedUnmatchedGoogleStudents() {
      return this.unmatchedGoogleStudents.filter(
        student => this.selectedGoogleClassStudentsList[student.upstream_student_id].isSelected,
      );
    },
    unmatchedGoogleStudentsWarningTitle() {
      const textSuffix = 'might not have access';
      const totalUmatchedStudents = this.selectedUnmatchedGoogleStudents.length;
      if (totalUmatchedStudents === 1) {
        return `${this.selectedUnmatchedGoogleStudents[0].name} ${textSuffix}`;
      }

      if (totalUmatchedStudents === 2) {
        return `
          ${this.selectedUnmatchedGoogleStudents[0].name} and
          ${this.selectedUnmatchedGoogleStudents[1].name} ${textSuffix}
        `;
      }

      const totalRemainingStudents = totalUmatchedStudents - 2;
      const otherPluralized = totalRemainingStudents === 1 ? 'other' : 'others';
      return `
          ${this.selectedUnmatchedGoogleStudents[0].name},
          ${this.selectedUnmatchedGoogleStudents[1].name}
          and ${totalRemainingStudents} ${otherPluralized} ${textSuffix}
        `;
    },
    unmatchedGoogleStudentsWarningMessage() {
      const emailPluralized =
        this.selectedUnmatchedGoogleStudents.length > 1 ? 'emails are' : 'email is';
      return `
        They will see this assignment in Classroom,
        but might not have access to the assignment in Kognity.
        Make sure their ${emailPluralized} the same in both Kognity and in Classroom.
      `;
    },
    unmatchedGoogleStudents() {
      const subjectClassStudentWithGoogleMatch = this.sortedSubjectClassStudents.filter(
        kogStudent => kogStudent.googleStudent,
      );
      const googleStudentsMatchedEmails = subjectClassStudentWithGoogleMatch.map(
        kogStudent => kogStudent.googleStudent.email,
      );
      const unmatchedGoogleStudents = [];
      Object.entries(this.googleStudentsByEmail).forEach(studentEntry => {
        const [email, googleStudent] = studentEntry;
        if (!googleStudentsMatchedEmails.includes(email)) {
          unmatchedGoogleStudents.push(googleStudent);
        }
      });
      return unmatchedGoogleStudents;
    },
    googleStudentsByEmail() {
      const googleStudentsByEmail = {};
      this.googleClasses.forEach(googleClass => {
        googleClass.students.forEach(googleStudent => {
          const { email } = googleStudent;
          if (!googleStudentsByEmail[email]) {
            googleStudentsByEmail[email] = {
              ...googleStudent,
              classes: [],
            };
          }

          googleStudentsByEmail[email].classes.push({
            class_id: googleClass.id,
            class_name: googleClass.name,
          });
        });
      });
      return googleStudentsByEmail;
    },
    sortedSubjectClassStudents() {
      const updatedSubjectClassStudents = [];

      this.subjectClassStudents.forEach(kogStudent => {
        const kogEmail = kogStudent.email;
        const updatedKogStudent = {
          ...kogStudent,
        };
        if (this.googleStudentsByEmail[kogEmail]) {
          updatedKogStudent.googleStudent = this.googleStudentsByEmail[kogEmail];
        }
        updatedSubjectClassStudents.push(updatedKogStudent);
      });

      const sortedSubjectClassStudents = updatedSubjectClassStudents.sort((a, b) => {
        const aVal = a.googleStudent ? 1 : 0;
        const bVal = b.googleStudent ? 1 : 0;
        return bVal - aVal;
      });

      return sortedSubjectClassStudents;
    },
    allStudentsSelected() {
      return Object.keys(this.selectedStudentsList).reduce(
        (allSelected, currKey) => allSelected && this.selectedStudentsList[currKey],
        true,
      );
    },
    allGoogleClassStudentsSelected() {
      return Object.keys(this.selectedGoogleClassStudentsList).reduce((allSelected, currKey) => {
        const gcStudent = this.selectedGoogleClassStudentsList[currKey];
        if (gcStudent.isDisabled) {
          return allSelected;
        }
        return allSelected && gcStudent.isSelected;
      }, true);
    },
    isAnyGoogleStudentSelected() {
      return Object.keys(this.selectedGoogleClassStudentsList).some(currKey => {
        const gcStudent = this.selectedGoogleClassStudentsList[currKey];
        return gcStudent.isSelected;
      });
    },
    allGoogleClassStudentsDisabled() {
      return Object.keys(this.selectedGoogleClassStudentsList).reduce((allDisabled, currKey) => {
        const gcStudent = this.selectedGoogleClassStudentsList[currKey];
        return allDisabled && gcStudent.isDisabled;
      }, true);
    },
    numberOfSelectedStudentsText() {
      const ids = Object.keys(this.selectedStudentsList);
      const selectedStudents = ids.filter(id => this.selectedStudentsList[id]);
      const numStudents = selectedStudents.length;
      const noun = numStudents === 1 ? 'student' : 'students';
      return `${numStudents} ${noun} selected`;
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subject.possible_levels);
    },
  },
  watch: {
    watchedStudentList: {
      handler(newList, oldList) {
        Object.keys(newList).forEach(studentId => {
          if (newList[studentId] !== oldList[studentId]) {
            this.studentToggled(studentId, newList[studentId]);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    Object.keys(this.selectedStudents).forEach(studentId => {
      this.syncGoogleStudentState(studentId, this.selectedStudents[studentId], true);
    });
    if (this.googleClasses.length > 0) {
      posthog.capture('open_select_recipients_modal_when_gc_connected');
    }
  },
  methods: {
    getSelectedGoogleClassStudentsList() {
      const selectedGCList = {};
      this.googleClasses.forEach(googleClass => {
        googleClass.students.forEach(googleStudent => {
          selectedGCList[googleStudent.upstream_student_id] = {
            isSelected: false,
            isDisabled: false,
          };
        });
      });
      this.googleClassroomRecipients.forEach(googleClass => {
        googleClass.recipient_upstream_ids.forEach(upstreamStudentId => {
          selectedGCList[upstreamStudentId] = {
            isSelected: true,
            isDisabled: false,
          };
        });
      });
      return selectedGCList;
    },
    updateRecipients() {
      this.closeModal();
      const selectedGCStudents = {};
      Object.entries(this.selectedGoogleClassStudentsList).forEach(
        ([upstreamId, gcStudentState]) => {
          selectedGCStudents[upstreamId] = gcStudentState.isSelected && !gcStudentState.isDisabled;
        },
      );

      this.updateSelectedStudents(this.selectedStudentsList, selectedGCStudents);
    },
    toggleStudent(student) {
      this.updateSelectedStudent(
        student.school_student,
        !this.selectedStudentsList[student.school_student],
      );
    },
    toggleGoogleClassStudent(upstreamStudentId) {
      const isSelected = !this.selectedGoogleClassStudentsList[upstreamStudentId].isSelected;
      this.updateSelectedGoogleClassStudent(upstreamStudentId, { isSelected });
    },
    toggleAllStudents() {
      if (this.allStudentsSelected) {
        Object.keys(this.selectedStudentsList).forEach(k => {
          this.updateSelectedStudent(k, false);
        });
      } else {
        Object.keys(this.selectedStudentsList).forEach(k => {
          this.updateSelectedStudent(k, true);
        });
      }
    },
    toggleAllGoogleClassStudents() {
      const gcStudentState = this.allGoogleClassStudentsSelected
        ? { isSelected: false }
        : { isSelected: true };

      Object.keys(this.selectedGoogleClassStudentsList).forEach(upstreamStudentId => {
        if (this.selectedGoogleClassStudentsList[upstreamStudentId].isDisabled) {
          return;
        }
        this.updateSelectedGoogleClassStudent(upstreamStudentId, gcStudentState);
      });
    },
    updateSelectedStudent(id, isSelected) {
      this.selectedStudentsList[id] = isSelected;
    },
    studentToggled(id, isSelected) {
      this.syncGoogleStudentState(id, isSelected);
    },
    syncGoogleStudentState(id, isKogStudentSelected, isInit = false) {
      const subjectClassStudent = this.sortedSubjectClassStudents.find(
        student => parseInt(student.school_student, 10) === parseInt(id, 10),
      );
      const isGoogleStudent = Boolean(subjectClassStudent?.googleStudent);
      if (!isGoogleStudent) {
        return;
      }

      const { upstream_student_id: upstreamStudentId } = subjectClassStudent.googleStudent;
      if (!isKogStudentSelected) {
        this.updateSelectedGoogleClassStudent(upstreamStudentId, {
          isSelected: false,
          isDisabled: true,
        });
      } else if (!isInit) {
        this.updateSelectedGoogleClassStudent(upstreamStudentId, { isSelected: true });
      }
    },
    updateSelectedGoogleClassStudent(upstreamId, { isSelected, isDisabled = false }) {
      this.selectedGoogleClassStudentsList[upstreamId] = { isSelected, isDisabled };
    },
  },
};
</script>

<style scoped>
.SelectRecipientsModal-body {
  background: var(--kogPlatformGray096);
}

.SelectRecipientsModal-emptyTextCell {
  padding-left: 22px;
}
</style>
