<template>
  <component
    :is="component.type"
    v-bind="component.props"
  />
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';

import type {
  Question,
  QuestionContextProps,
  QuestionDisplayModeType,
} from 'sharedApp/composables/question-context/types';

import {
  QUESTION_DISPLAY_MODE,
  useQuestionContextInject,
} from 'sharedApp/composables/question-context/index.ts';
import { QUESTION_TYPES } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

import FBQ from './question-context-wrappers/fbq.vue';
import LTQ from './question-context-wrappers/ltq.vue';
import MCQ from './question-context-wrappers/mcq.vue';
import STQ from './question-context-wrappers/stq.vue';

type Props = {
  question: Question;
  displayMode: QuestionDisplayModeType;
  disabled?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  displayMode: QUESTION_DISPLAY_MODE.USER_RESPONSE,
  disabled: false,
});
const { question, displayMode, disabled } = toRefs(props);

const { getQuestionProps } = useQuestionContextInject();

const component = computed(() => {
  const mergeProps = <TQuestion extends Question>(
    contextProps: QuestionContextProps<TQuestion>,
  ) => {
    return {
      disabled: disabled.value,
      ...contextProps,
    };
  };

  switch (question.value.type) {
    case QUESTION_TYPES.LTQ: {
      return {
        props: mergeProps(getQuestionProps(question.value, displayMode.value)),
        type: LTQ,
      };
    }
    case QUESTION_TYPES.MCQ: {
      return {
        props: mergeProps(getQuestionProps(question.value, displayMode.value)),
        type: MCQ,
      };
    }
    case QUESTION_TYPES.FBQ: {
      return {
        props: mergeProps(getQuestionProps(question.value, displayMode.value)),
        type: FBQ,
      };
    }
    case QUESTION_TYPES.STQ: {
      return {
        props: mergeProps(getQuestionProps(question.value, displayMode.value)),
        type: STQ,
      };
    }
    default:
      throw new Error('Unsupported question type');
  }
});
</script>
