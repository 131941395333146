import * as Sentry from '@sentry/vue';
import { groupBy, keyBy } from 'lodash';

import { ActivitiesService } from '@apis/generated/services.ts';

import { fetchFeatureList, fetchFeatureMappings } from 'sharedApp/apis/subject-node-features.js';
import { FEATURES_ENUM } from 'sharedApp/const/features.js';

const fetchedFeatureSet = new Set();

export default {
  namespaced: true,
  state: {
    features: [],
    featureMappings: [],
  },
  getters: {
    featuresById(state) {
      return keyBy(state.features, 'id');
    },
    featureMappingsBySubjectNodeId(state) {
      return groupBy(state.featureMappings, 'subject_node_id');
    },
    featureMappingsByFeatureId(state) {
      return keyBy(state.featureMappings, 'feature_id');
    },
    getActivityBySubjectNodeId(state, getters) {
      return subjectNodeId => {
        return getters.activityBySubjectNodeId[subjectNodeId];
      };
    },
    activityBySubjectNodeId(state) {
      return state.featureMappings.reduce((acc, mapping) => {
        acc[mapping.subject_node_id] = state.features.find(
          feature => feature.id === mapping.feature_id,
        );
        return acc;
      }, {});
    },
  },
  mutations: {
    addFeatures(state, features) {
      state.features = [...state.features, ...features];
    },
    addFeatureMappings(state, mappings) {
      state.featureMappings = [...state.featureMappings, ...mappings];
    },
  },
  actions: {
    async fetchFeaturesAndMappings({ commit }, { subjectNodeIds }) {
      const subjectNodeIdsToFetch = subjectNodeIds.filter(nodeId => !fetchedFeatureSet.has(nodeId));

      if (subjectNodeIdsToFetch.length > 0) {
        try {
          const mappings = await fetchFeatureMappings(subjectNodeIdsToFetch);
          commit('addFeatureMappings', mappings);

          const featureIds = mappings.map(mapping => mapping.feature_id);
          if (featureIds.length > 0) {
            const features = await fetchFeatureList(featureIds);
            commit('addFeatures', features);
          }

          subjectNodeIdsToFetch.forEach(nodeId => fetchedFeatureSet.add(nodeId));
        } catch (error) {
          Sentry.captureException(`fetchFeaturesAndMappings: ${error}`);
          throw error;
        }
      }
    },
    async fetchFeatures({ commit }, { subjectId }) {
      try {
        const features = await ActivitiesService.activitiesList({ subjectId });
        commit('addFeatures', features);
      } catch (error) {
        Sentry.captureException(`fetchFeatures: ${error}`);
        throw error;
      }
    },
    async fetchFeatureMappings({ commit }, { subjectId }) {
      try {
        const mappings = await ActivitiesService.activitiesMappingsList({ subjectId });
        commit('addFeatureMappings', mappings);
      } catch (error) {
        Sentry.captureException(`fetchFeatureMappings: ${error}`);
        throw error;
      }
    },
  },
};
