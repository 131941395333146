// SVG chart inspired from
https://medium.com/@pppped/how-to-code-a-responsive-circular-percentage-chart-with-svg-and-css-3632f8cd7705
// All calculation is done assuming a circumference of 100 (as in the blog link above), // to resize
just change width and height of circular-chart class

<template>
  <div
    class="inlineflexContainer flexContainer-alignCenter"
    :aria-label="label"
    :aria-valuenow="completed"
    role="progressbar"
    aria-valuemin="0"
    :aria-valuemax="total"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
  >
    <svg
      v-tooltip="{
        theme: 'kog-tooltip',
        content: tooltipText,
        shown: isTooltipVisible,
        triggers: [],
      }"
      aria-hidden="true"
      :style="{ width: size + 'px', height: size + 'px' }"
      viewBox="0 0 36 36"
      class="circular-chart"
      :class="{
        'overflow-visible': overflowVisible,
        blue: isBlue,
        white: isWhite,
      }"
    >
      <path
        :style="{ 'stroke-width': strokeWidth }"
        class="circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        v-if="percentage > 0"
        :stroke-dasharray="dashArray"
        :style="{ 'stroke-width': strokeWidth }"
        class="circle"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text
        x="18"
        y="18"
        dominant-baseline="central"
        class="center-text"
      >
        <slot />
      </text>
    </svg>
    <span
      v-if="showNumbers"
      class="padd-left-fine-1 text-underline"
    >
      {{ completed }}/{{ total }}
    </span>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

// This is a legacy component. Prefer using KogProgressCircle instead.
export default {
  name: 'DonutIndicatorLegacy',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    completed: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    tooltipText: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 25,
    },
    strokeWidth: {
      type: Number,
      default: 3.5,
    },
    showNumbers: {
      type: Boolean,
      default: true,
    },
    colorScheme: {
      type: String,
      default: 'green',
      validator: prop => ['green', 'blue', 'white'].includes(prop),
    },
    overflowVisible: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isTooltipVisible: false,
  }),
  computed: {
    dashArray() {
      const fillPercent = this.percentage;
      return `${fillPercent}, 100`;
    },
    isBlue() {
      return this.colorScheme === 'blue';
    },
    isWhite() {
      return this.colorScheme === 'white';
    },
    percentage() {
      const percentage = this.total ? (this.completed * 100) / this.total : 0;
      return percentage.toFixed();
    },
  },
  methods: {
    showTooltip() {
      if (this.tooltipText.length > 0) {
        this.isTooltipVisible = true;
      }
    },
    hideTooltip() {
      this.isTooltipVisible = false;
    },
  },
};
</script>

<style scoped>
.circular-chart {
  width: 25px;
  height: 25px;
}

.circular-chart.overflow-visible {
  overflow: visible;
}

.circular-chart.white {
  background-color: color-mod(var(--kogPlatformGray000) alpha(60%));
  border: 2px solid transparent;
  border-radius: 100%;
}

.circle-bg {
  fill: none;
  stroke: var(--kogPlatformGray084);
  stroke-width: 4.4;
}

.circular-chart.blue .circle-bg {
  stroke: var(--kog-progressbar-single-primary-background);
}

.circle {
  fill: none;
  stroke-linecap: round;
  stroke-width: 4.4;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart .circle {
  stroke: var(--kog-progressbar-single-primary-background);
}

.circular-chart.blue .circle {
  stroke: var(--kog-progressbar-single-primary-color);
}

.circular-chart.white .circle {
  stroke: var(--kogPlatformWhite);
}

.center-text {
  font-size: 0.6em;
  text-anchor: middle;
  fill: var(--kogPlatformGray018);
}

.circular-chart.white .center-text {
  fill: var(--kogPlatformWhite);
}

.circular-chart.blue .center-text {
  fill: var(--kog-progress-circle-primary-text-color);
}
</style>
