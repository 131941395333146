import { Plugin } from '@ckeditor/ckeditor5-core';

export default class ItalicOverrideEditing extends Plugin {
  init() {
    this.editor.conversion.attributeToElement({
      model: 'italic',
      view: {
        name: 'span',
        classes: 'italic',
      },
      converterPriority: 'high',
    });
  }
}
