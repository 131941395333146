const logoUrl = require('sharedApp/img/kognity-logo-no-text.png');
const avatarUrl = require('sharedApp/img/user-avatar.jpg');

export const APP_BASE_URL = '/publishing/app';

/* Internal routes */
const ASSESSMENT_MATERIAL = '/subject/:subjectId/assessment-material/';
const SUBJECT_DASHBOARD = '/subject/:subjectId/dashboard/';
const SUBJECT_QUESTION_EDIT = '/subject/:subjectId/assessment/edit/:questionId/';
const SUBJECT_QUESTION_NEW = '/subject/:subjectId/assessment/edit/new/:questionType/';
const SUBJECT_QUESTION_DUPLICATE = '/subject/:subjectId/assessment/edit/duplicate/:questionId/';
const BOOK_FEEDBACK = '/subject/:subjectId/feedback/';
const BOOK_MATERIAL = '/subject/:subjectId/book-material/';
const BOOK_PROPERTIES = '/content-admin/book-properties/:bookId/update';
const BOOK_PUBLISHED_SUCCESS = '/:bookId/book-published-success/';
const EDIT_FEATURE = '/feature/:featureType/:featureId/';
const CONTENT_DASHBOARD = '/content/dashboard/';
const CONTENT_SEARCH = '/search/';
const CONTENT_BOX_TEMPLATE_LIST_PAGE = '/content-boxes/templates/';
const EDIT_CONTENT_BOX_TEMPLATE = '/content-boxes/templates/:contentBoxTemplateId/';
const CREATE_BOOK_URL = '/create-book/';
const SYLLABUS_LIST_PAGE = '/syllabi/';
const SYLLABUS_EDIT_PAGE = '/syllabi/:syllabusId/';
const EDITORS_PAGE = '/editors/';
const EDITOR_DETAIL_PAGE = '/editors/:editorId/';
const SECTION_EDIT = '/sections/:sectionId/';
const SECTION_PREVIEW = '/sections/:sectionId/preview/';
const SECTIONS_PREVIEW = '/subject/:subjectId/subjectnode/:subjectNodeId/preview-sections/';
const GLOSSARY_ROOT = '/glossary';
const GLOSSARY_EDIT_TERM = ':termId';
const GLOSSARY_NEW_TERM = 'new';
const PREVIEW_QUESTION_CONTAINER = '/subject/:subjectId/assessment/preview/';

/* External routes */
const ACCOUNT_LOGOUT = '/accounts/logout/';
const ACCOUNT_SETTINGS = '/accounts/app/user-profile/';
const HOME = '/';
const getAssessmentOverviewUrl = subjectId =>
  `/publishing/app/subject/${subjectId}/assessment-material`;
const getStudyViewUrl = subjectId => `/study/app/class/sid-${subjectId}-cid-0/overview/`;
const getSubjectDashboardUrl = subjectId => `subject/${subjectId}/dashboard/`;
function getSectionEditUrl(sectionId, subjectId, subjectNodeId) {
  const url = new URL(`/publishing/app/sections/${sectionId}/`, window.location.href);
  url.searchParams.append('subjectId', subjectId);
  url.searchParams.append('subjectNodeId', subjectNodeId);
  return url.toString();
}
const getSectionPreviewUrl = (sectionId, subjectId, subjectNodeId, versionId) => {
  const url = new URL(`/publishing/app/sections/${sectionId}/preview/`, window.location.origin);
  url.searchParams.append('subjectId', subjectId);
  url.searchParams.append('subjectNodeId', subjectNodeId);
  url.searchParams.append('versionId', versionId);
  return url.toString();
};
const getNewQuestionBaseUrl = (subjectId, questionType) =>
  `/publishing/app/subject/${subjectId}/assessment/edit/new/${questionType}/`;
const getEditFeatureUrl = (featureId, featureType, subjectId, subjectNodeId = null) => {
  const url = new URL(
    `/publishing/app/feature/${featureType}/${featureId}/`,
    window.location.origin,
  );
  url.searchParams.append('subjectId', subjectId);
  if (subjectNodeId) {
    url.searchParams.append('subjectNodeId', subjectNodeId);
  }
  return url.toString();
};
const getViewFeatureInBookUrl = (subjectId, nodeSlug, nodeId) =>
  `/study/app/class/sid-${subjectId}-cid-0/book/${nodeSlug}-id-${nodeId}`;

export const ROUTES = {
  ASSESSMENT_MATERIAL,
  ACCOUNT_LOGOUT,
  ACCOUNT_SETTINGS,
  SUBJECT_DASHBOARD,
  SUBJECT_QUESTION_EDIT,
  SUBJECT_QUESTION_NEW,
  SUBJECT_QUESTION_DUPLICATE,
  BOOK_FEEDBACK,
  BOOK_MATERIAL,
  BOOK_PROPERTIES,
  BOOK_PUBLISHED_SUCCESS,
  EDIT_FEATURE,
  CONTENT_DASHBOARD,
  CONTENT_SEARCH,
  CONTENT_BOX_TEMPLATE_LIST_PAGE,
  EDIT_CONTENT_BOX_TEMPLATE,
  CREATE_BOOK_URL,
  SYLLABUS_LIST_PAGE,
  SYLLABUS_EDIT_PAGE,
  EDITORS_PAGE,
  EDITOR_DETAIL_PAGE,
  SECTION_EDIT,
  SECTION_PREVIEW,
  SECTIONS_PREVIEW,
  HOME,
  GLOSSARY_ROOT,
  GLOSSARY_EDIT_TERM,
  GLOSSARY_NEW_TERM,
  PREVIEW_QUESTION_CONTAINER,
  getAssessmentOverviewUrl,
  getStudyViewUrl,
  getSubjectDashboardUrl,
  getSectionPreviewUrl,
  getSectionEditUrl,
  getNewQuestionBaseUrl,
  getEditFeatureUrl,
  getViewFeatureInBookUrl,
};

/* Assets */
const KOGNITY_LOGO_URL = logoUrl;
const DEFAULT_AVATAR_URL = avatarUrl;

export const ASSETS = {
  KOGNITY_LOGO_URL,
  DEFAULT_AVATAR_URL,
};

export default {
  APP_BASE_URL,
  ASSETS,
  ROUTES,
};
