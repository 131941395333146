<template>
  <div class="height-full">
    <slide-in
      class="height-full"
      :is-showing-child="!showExtendedTableOfContents"
      enter-from="top"
    >
      <div
        v-show="!showExtendedTableOfContents"
        key="toc"
        class="BookRailToc-Container"
      >
        <div class="BookRailToc-tocContent flexContainer flexContainer-column">
          <div class="BookRailToc-topicHeadline flexContainer flexContainer-spaceBetween">
            <div
              class="BookRailToc-topicHeadlineTextContainer padd-s padd-right-0 flexContainer flexContainer-column"
            >
              <h2 class="text-overline margin-0 flexContainer flexContainer-column">
                <span>{{ $term('topic') }} {{ displayedTopic.number }} </span>
                <span>{{ displayedTopic.name }}</span>
              </h2>
            </div>
            <button
              class="BookRailToc-topicHeadlineCloseButton"
              :aria-label="`Close table of contents`"
              @click="onCloseClick"
            >
              <kog-font-awesome-icon
                fa-class="fa-chevron-left"
                fa-style="regular"
                size="custom"
                :custom-size="14"
              />
            </button>
          </div>
          <div class="BookRailToc-content">
            <div
              v-if="shouldShowSpecialIntroNodeContent"
              class="BookRailToc-featuresContainer margin-top-s"
            >
              <button
                class="BookRailToc-featureExpandButton"
                @click="toggleFeaturesExpanded"
              >
                <content-node-icon
                  class="margin-right-s"
                  icon-class="fa-book"
                  icon-style="regular"
                  background-color="pink"
                  role="presentation"
                />
                <span class="font-satoshi heading-xxs margin-right-auto text-left text-capitalize">
                  {{ $term('topic') }} content
                </span>
                <kog-font-awesome-icon
                  :fa-class="getFeatureActionIcon"
                  fa-style="regular"
                  size="s"
                />
              </button>
              <vertical-expand>
                <ul
                  v-if="isFeaturesExpanded"
                  class="padd-right-s padd-left-s list-style-none"
                >
                  <li
                    v-for="node in specialIntroductionNode.children"
                    :key="node.id"
                  >
                    <router-link
                      :to="getNodeRoute(node)"
                      class="anchor-fully-unstyled"
                    >
                      <button
                        :ref="`node-${node.id}`"
                        class="BookRailToc-featureList-item"
                        :class="{
                          'BookRailToc-featureList-item--active': isActive(node.id),
                        }"
                      >
                        <div class="BookRailToc-featureItemIcon">
                          <kog-icon
                            :icon-class="getIcon(node)"
                            fa-style="regular"
                            size="s"
                            class="u-iconColor"
                          />
                        </div>
                        <span class="font-satoshi text-left">
                          {{ node.name }}
                        </span>
                      </button>
                    </router-link>
                  </li>
                </ul>
              </vertical-expand>
            </div>
            <div class="BookRailToc-subtopicHeadline flexContainer flexContainer-column padd-s">
              <h3 class="text-overline muted margin-0 flexContainer flexContainer-column">
                <span>
                  {{ $term('subtopic') }}
                  {{ displayedSubtopic.formatted_number_including_ancestors }}
                </span>
                <span>{{ displayedSubtopic.name }}</span>
              </h3>
            </div>
            <div class="padd-s padd-top-0">
              <ul class="BookRailToc-nodeList">
                <li
                  v-for="section in displayedSubtopic.children"
                  :key="section.id"
                >
                  <button
                    :ref="`node-${section.id}`"
                    class="BookRailToc-nodeList-item"
                    :class="{
                      'BookRailToc-nodeList-item--active': isActive(section.id),
                    }"
                    :disabled="
                      isInReviewMode && section.children.length > 0 && isExpanded(section.id)
                    "
                    :aria-expanded="
                      section.children.length > 0
                        ? isExpanded(section.id)
                          ? 'true'
                          : 'false'
                        : null
                    "
                    @click="goToItem(section)"
                  >
                    <span
                      v-if="isNodeCompleted(section.id)"
                      class="BookRailToc-sectionCompletionIcon"
                      role="none"
                    >
                      <kog-font-awesome-icon
                        fa-class="fa-badge-check"
                        fa-style="solid"
                        size="s"
                        aria-label="Completed"
                      />
                    </span>
                    <span class="font-satoshi text-small muted margin-right-s">
                      {{ section.formatted_number_including_ancestors }}
                    </span>
                    <span class="font-satoshi heading-xxs margin-right-auto text-left">
                      {{ section.name }}
                    </span>
                    <content-hidden-label v-if="isShowHiddenLabelForNode(section.id)" />
                    <kog-font-awesome-icon
                      v-if="section.children.length > 0"
                      class="margin-left-xs"
                      :fa-class="getSectionActionIcon(section.id)"
                      fa-style="regular"
                      size="s"
                    />
                  </button>

                  <vertical-expand>
                    <ul
                      v-if="isExpanded(section.id)"
                      class="BookRailToc-nodeList"
                    >
                      <li
                        v-for="subsection in section.children"
                        :key="subsection.id"
                      >
                        <button
                          :ref="`node-${subsection.id}`"
                          class="BookRailToc-nodeList-item"
                          :class="{
                            'BookRailToc-nodeList-item--active': isActive(subsection.id),
                          }"
                          :aria-pressed="`${isActive(subsection.id)}`"
                          @click="goToItem(subsection)"
                        >
                          <span
                            v-if="isNodeCompleted(subsection.id)"
                            class="BookRailToc-sectionCompletionIcon"
                            role="none"
                          >
                            <kog-font-awesome-icon
                              fa-class="fa-badge-check"
                              fa-style="solid"
                              size="s"
                              aria-label="Completed"
                            />
                          </span>
                          <kog-font-awesome-icon
                            :fa-class="getIcon(subsection)"
                            fa-style="solid"
                            size="s"
                            class="kogContentPurple1 margin-right-l"
                          />
                          <div class="flexContainer flexContainer-spaceBetween flexChild-canGrow">
                            <span class="font-satoshi text-left">
                              {{ subsection.name }}
                            </span>
                            <content-hidden-label v-if="isShowHiddenLabelForNode(subsection.id)" />
                          </div>
                        </button>
                      </li>
                    </ul>
                  </vertical-expand>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="BookRailToc-footer flexContainer flexContainer-row padd-s">
          <kog-round-button
            :disabled="isPreviousDisabled"
            :is-loading="$wait.is('loading-feature-list')"
            :aria-label="`Show previous ${$term('subtopic')}`"
            icon-class="fa-chevron-left"
            button-style="default"
            @click="goToPreviousSubtopic"
          />
          <kog-button
            class="BookRailToc-toggleExpandedButton"
            :aria-label="`All ${$term('topic')}s`"
            :label="`All ${$term('topic')}s`"
            @click="toggleAllTopics"
          />
          <kog-round-button
            :disabled="isNextDisabled"
            :is-loading="$wait.is('loading-feature-list')"
            :aria-label="`Show next ${$term('subtopic')}`"
            icon-class="fa-chevron-right"
            button-style="default"
            @click="goToNextSubtopic"
          />
        </div>
      </div>
    </slide-in>
    <slide-in
      class="height-full"
      :is-showing-child="showExtendedTableOfContents"
      enter-from="bottom"
    >
      <div
        v-show="showExtendedTableOfContents"
        key="expanded"
        class="BookRailToc-expandedContainer"
      >
        <div class="BookRailToc-tocContent">
          <div
            class="BookRailToc-topicHeader flexContainer flexContainer-row padd-s padd-right-0 padd-left-m"
            :class="{
              'BookRailToc-topicHeaderShadow': showHeaderShadow,
            }"
          >
            <span class="text-overline"> {{ $term('topic') }}s </span>
            <button
              class="BookRailToc-topicHeadlineCloseButton"
              :aria-label="`Close table of contents`"
              @click="onCloseClick"
            >
              <kog-font-awesome-icon
                fa-class="fa-chevron-left"
                fa-style="regular"
                size="custom"
                :custom-size="14"
              />
            </button>
          </div>
          <div class="BookRailToc-contentExpanded">
            <table-of-contents
              v-model:show-table-of-contents="showExtendedTableOfContents"
              :topics="topics"
              :expanded-topic-id="selectedTopicId"
              :is-book-rail="true"
              @toc-scrolled="shouldShowHeaderShadow"
            />
          </div>
        </div>

        <div class="BookRailToc-footer flexContainer flexContainer-row padd-s">
          <kog-button
            class="width-100"
            :aria-label="`Current ${$term('subtopic')}`"
            :label="`Current ${$term('subtopic')}`"
            @click="toggleAllTopics"
          />
        </div>
      </div>
    </slide-in>
  </div>
</template>

<script>
import isNil from 'lodash/isNil.js';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState } from 'vuex';

import SlideIn from 'sharedApp/animations/slide-in.vue';
import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { getFeatureTypeIcon } from 'sharedApp/const/features.js';
import { getAllDescendantsNodeIdsIncludingSelf } from 'sharedApp/libs/subject-tree-functions.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';
import ContentNodeIcon from 'studyApp/components/subject-class-overview/content-node-icon.vue';
import TableOfContents from 'studyApp/components/subject-class-overview/table-of-contents.vue';
import FeaturesMixin from 'studyApp/mixins/features-mixin.js';
import TOCMixin from 'studyApp/mixins/toc-mixin.js';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default {
  name: 'BookRailToc',
  components: {
    KogButton,
    KogFontAwesomeIcon,
    KogRoundButton,
    KogIcon,
    ContentNodeIcon,
    VerticalExpand,
    ContentHiddenLabel,
    TableOfContents,
    SlideIn,
  },
  mixins: [RoutesMixin, FeaturesMixin, TOCMixin],
  props: {
    showMultipleSections: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['scroll-to-content'],
  data() {
    return {
      expanded: null,
      showExtendedTableOfContents: false,
      showHeaderShadow: false,
      isFeaturesExpanded: false,
      displayedSubtopic: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
      bookRailSliders: state => state.bookModule.bookRailSliders,
      subjectNodesProgress: state => state.statisticModule.subjectNodesProgress,
    }),
    ...mapGetters({
      featuresById: 'subjectNodeFeatureModule/featuresById',
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
      featureMappingsByFeatureId: 'subjectNodeFeatureModule/featureMappingsByFeatureId',
      isNodeVisible: 'subjectClassModule/isNodeVisible',
      findSectionNodeById: 'bookModule/findSectionNodeById',
      subjectNodesById: 'subjectModule/subjectNodesById',
      topics: 'subjectModule/topics',
    }),
    subjectTree() {
      return this.subject.subject_tree[0];
    },
    isInReviewMode() {
      return this.$route.meta.isReviewMode;
    },
    subtopicMap() {
      return this.getCollectionMap(this.getFlattenedArray(this.subjectTree.children));
    },
    sectionMap() {
      return this.getCollectionMap(this.getFlattenedArray(this.subtopicMap.values()));
    },
    subsectionMap() {
      return this.getCollectionMap(this.getFlattenedArray(this.sectionMap.values()));
    },
    subtopicNodeIds() {
      return Array.from(this.subtopicMap.keys());
    },
    isCurrentNodeFeature() {
      return this.hasNodeIdFeature(this.nodeId);
    },
    currentSubjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    currentSection() {
      if (this.currentSubjectNode.level === 4) {
        return this.sectionMap.get(this.currentSubjectNode.parent);
      }
      return this.currentSubjectNode;
    },
    displayedTopic() {
      return this.topics.find(topic => topic.id === this.displayedSubtopic.parent);
    },
    displayedSubtopicIndex() {
      return this.subtopicNodeIds.indexOf(this.displayedSubtopic.id);
    },
    isNextDisabled() {
      return this.displayedSubtopicIndex === this.subtopicNodeIds.length - 1;
    },
    isPreviousDisabled() {
      return this.displayedSubtopicIndex === 0;
    },
    specialIntroductionNode() {
      return this.displayedTopic.children.find(child => child.is_special_introduction_node);
    },
    specialIntroductionNodeChildren() {
      if (isNil(this.specialIntroductionNode)) return [];
      return this.specialIntroductionNode.children.reduce((children, childNode) => {
        const mappedFeature = this.activityBySubjectNodeId[childNode.id];
        if (!isNil(mappedFeature)) {
          children.push(mappedFeature);
        }
        const mappedSection = this.findSectionNodeById(childNode.id);
        if (!isNil(mappedSection && mappedSection.length > 0)) {
          children.push(mappedSection);
        }
        return children;
      }, []);
    },
    shouldShowSpecialIntroNodeContent() {
      return this.specialIntroductionNodeChildren.length > 0;
    },
    getFeatureActionIcon() {
      return `fa-chevron-${this.isFeaturesExpanded ? 'up' : 'down'}`;
    },
    isHiddenLabelEnabled() {
      return this.user.isTeacher();
    },
  },
  watch: {
    nodeId: {
      handler() {
        this.chooseSubtopicFromNodeId();
      },
    },
  },
  async created() {
    this.chooseSubtopicFromNodeId();
    await this.loadFeatures(this.displayedTopic);
    if (this.isCurrentNodeFeature) {
      this.fetchSubjectNodesProgress(this.subject.id);
    }
    this.expanded = this.currentSection.id;
    const unitContentExpanded = studyAppLocalStorage.getUnitContentExpandedInToc();
    if (isNil(unitContentExpanded)) {
      this.isFeaturesExpanded = !!this.isCurrentNodeFeature;
      studyAppLocalStorage.setUnitContentExpandedInToc(this.isFeaturesExpanded);
    } else {
      this.isFeaturesExpanded = unitContentExpanded;
    }
    this.selectTopic(this.displayedTopic);
  },
  updated() {
    if (this.$refs[`node-${this.nodeId}`] && this.$refs[`node-${this.nodeId}`].length > 0) {
      this.$refs[`node-${this.nodeId}`][0].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  },
  methods: {
    ...mapWaitingActions('statisticModule', {
      fetchSubjectNodesProgress: 'loading_subject_progress_statistic',
    }),
    ...mapWaitingActions({
      fetchFeaturesAndMappings: {
        action: 'subjectNodeFeatureModule/fetchFeaturesAndMappings',
        loader: 'loading-feature-list',
      },
    }),
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
    }),
    isNodeCompleted(nodeId) {
      const currentNodeStats = this.subjectNodesProgress.stats_by_subjectnode?.[nodeId];
      return currentNodeStats?.progress_stats.is_completed;
    },
    isExpanded(id) {
      return this.expanded === id;
    },
    getFlattenedArray(iterator) {
      if (Array.isArray(iterator)) {
        return iterator.flatMap(item => item.children);
      }
      return Array.from(iterator).flatMap(item => item.children);
    },
    getCollectionMap(collection) {
      return collection.reduce((acc, item) => {
        if (!acc.has(item.id)) {
          acc.set(item.id, item);
        }
        return acc;
      }, new Map());
    },
    getIcon(node) {
      const feature = this.getFeatureBySubjectNode(node);
      if (feature) {
        return getFeatureTypeIcon(feature?.type);
      }
      return 'fa-book-reader';
    },
    getSectionActionIcon(id) {
      return `fa-chevron-${this.isExpanded(id) ? 'up' : 'down'}`;
    },
    toggleExpanded(item) {
      if (this.expanded === item.id) {
        this.expanded = null;
      } else {
        this.expanded = item.id;
      }
    },
    goToItem(item) {
      if (item.children.length > 0) {
        this.toggleExpanded(item);
      } else {
        this.handleClick(item);
      }
    },
    isSameParent(item) {
      let parent;
      const subsection = this.subsectionMap.get(this.nodeId);
      if (subsection) {
        parent = this.sectionMap.get(subsection.parent);
      } else {
        const section = this.sectionMap.get(this.nodeId);
        parent = this.subtopicMap.get(section.parent);
      }
      return item.parent === parent.id;
    },
    handleClick(item) {
      if (this.showMultipleSections && this.isSameParent(item)) {
        this.$emit('scroll-to-content', item);
      } else {
        this.openBookPage(item);
      }
    },
    openBookPage(item) {
      if (this.nodeId !== item.id) {
        this.$router.push(this.getNodeRoute(item));
      }
    },
    getNodeRoute(item) {
      return {
        name: this.isInReviewMode ? 'reviewMode' : 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: item.slug,
          nodeId: item.id,
        },
      };
    },
    onCloseClick() {
      this.closeRailSlider();
      studyAppLocalStorage.setAutoOpenBookToc(false);
    },
    isActive(nodeId) {
      return nodeId === this.nodeId;
    },
    toggleFeaturesExpanded() {
      this.isFeaturesExpanded = !this.isFeaturesExpanded;
      studyAppLocalStorage.setUnitContentExpandedInToc(this.isFeaturesExpanded);
    },
    toggleAllTopics() {
      this.showExtendedTableOfContents = !this.showExtendedTableOfContents;
    },
    // eslint-disable-next-line vue/no-unused-properties
    async onSelectedSubtopicChange(subtopic) {
      if (this.displayedSubtopic.id === subtopic.id) {
        return;
      }
      await this.loadFeatures(subtopic);
      const section = subtopic.children[0];
      if (section.children.length > 0) {
        this.goToItem(section.children[0]);
      } else {
        this.goToItem(section);
      }
      this.expanded = this.currentSection.id;
      this.showExtendedTableOfContents = false;
    },
    async goToNextSubtopic() {
      const next = this.displayedSubtopicIndex + 1;
      const nextSubTopic = this.subtopicMap.get(this.subtopicNodeIds[next]);
      await this.loadFeatures(nextSubTopic);
      this.displayedSubtopic = nextSubTopic;
    },
    async goToPreviousSubtopic() {
      const previous = this.displayedSubtopicIndex - 1;
      const previousSubTopic = this.subtopicMap.get(this.subtopicNodeIds[previous]);
      await this.loadFeatures(previousSubTopic);
      this.displayedSubtopic = previousSubTopic;
    },
    async loadFeatures(node) {
      const subjectNodesIds = [...getAllDescendantsNodeIdsIncludingSelf(node), node.parent];
      await this.fetchFeaturesAndMappings({
        subjectNodeIds: subjectNodesIds,
      });
    },
    shouldShowHeaderShadow(value) {
      this.showHeaderShadow = value;
    },
    isShowHiddenLabelForNode(subjectNodeId) {
      return this.isHiddenLabelEnabled && !this.isNodeVisible(subjectNodeId);
    },
    chooseSubtopicFromNodeId() {
      let subtopic = this.subtopicMap.get(this.currentSection.parent);
      if (subtopic.is_special_introduction_node) {
        const topic = this.topics.find(t => t.id === subtopic.parent);
        subtopic = topic.children.find(st => !st.is_special_introduction_node);
      }
      this.displayedSubtopic = subtopic;
    },
  },
};
</script>

<style>
:root {
  --kogBookRailTocHeader: 56px;
  --kogBookRailTocExpandedHeader: 45px;
  --kogBookRailTocFooter: 64px;
}
</style>

<style scoped>
.BookRailToc-Container {
  display: flex;
  flex-direction: column;
  place-content: space-between;
  height: 100%;
}

.BookRailToc-sectionCompletionIcon {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--kogPlatformGreenBase);
}

.BookRailToc-topicHeadline {
  border-bottom: 1px solid var(--kog-colors-grey-300);
}

.BookRailToc-topicHeadlineTextContainer {
  overflow-x: hidden;
}

.BookRailToc-topicHeadlineCloseButton {
  margin: 0;
  padding: 0 var(--space-s);
  background-color: var(--kogTransparent);
  border: 0;
}

.BookRailToc-featuresContainer {
  background-color: var(--kog-colors-pink-100);
}

.BookRailToc-topicHeader {
  place-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--kog-colors-grey-300);
}

.BookRailToc-topicHeaderShadow {
  box-shadow: 0 2px 4px 0 var(--kogShadow010);
}

.BookRailToc-subtopicHeadline {
  border-radius: 12px;
}

.BookRailToc-tocContent {
  max-height: calc(100% - var(--kogBookRailTocFooter));
}

.BookRailToc-content {
  overflow-y: auto;
  overscroll-behavior: contain;
}

.BookRailToc-expandedContainer {
  display: flex;
  flex-direction: column;
  place-content: space-between;
  height: 100%;
}

.BookRailToc-contentExpanded {
  overflow-y: auto;
  height: calc(100% - var(--kogBookRailTocExpandedHeader));
}

.BookRailToc-nodeList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.BookRailToc-nodeList li ul li {
  margin-top: 2px;
}

.BookRailToc-nodeList li:not(:first-child) {
  margin-top: 2px;
}

.BookRailToc-content > .BookRailToc-nodeList {
  margin: var(--space-s) 0 0;
}

.BookRailToc-nodeList-item {
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  min-height: 40px;
  padding: var(--space-xs);

  background-color: var(--kogTransparent);
  border: 0;
  border-radius: 8px;
}

.BookRailToc-featureExpandButton {
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  min-height: 40px;
  padding: var(--space-xs);

  background-color: var(--kogTransparent);
  border: 0;
  border-radius: 8px;
}

.BookRailToc-featureItemIcon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
  margin-right: var(--space-s);

  border: 1px solid var(--kogPlatformGray009);
  border-radius: 50%;
}

.BookRailToc-footer {
  place-content: space-between;
}

.BookRailToc-toggleExpandedButton {
  width: 180px;
}

.BookRailToc-nodeList-item:not(.BookRailToc-nodeList-item--active):hover {
  background-color: var(--kog-list-item-hover-background);
}

.BookRailToc-nodeList-item--active {
  background-color: var(--kog-list-item-selected-background);
}

.BookRailToc-nodeList-item--active:hover {
  background-color: var(--kog-list-item-selected-hover-background);
}

.BookRailToc-featureExpandButton:hover {
  background-color: var(--kogShadow010);
}

.BookRailToc-featureList-item {
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  min-height: 40px;
  padding: var(--space-xs);

  background-color: var(--kogTransparent);
  border: 0;
  border-radius: 8px;
}

.BookRailToc-featureList-item:not(.BookRailToc-featureList-item--active):hover {
  background-color: var(--kogShadow010);
}

.BookRailToc-featureList-item--active {
  background-color: var(--kogShadow020);
}

.BookRailToc-featureList-item--active:hover {
  background-color: var(--kogShadow030);
}
</style>
