<template>
  <component
    :is="component"
    :origin="origin"
    :subject-class-id="subjectClassId"
    :request-type="requestType"
    :assignment-id="assignmentId"
    :google-class-id="googleClassId"
  />
</template>

<script>
import {
  GOOGLE_AUTH_CLASSROOM_API_DISABLED,
  GOOGLE_AUTH_ERROR_CODE,
  GOOGLE_AUTH_NO_CLASS_ACCESS,
  GOOGLE_CLASSROOM_API_UNAVAILABLE,
  GOOGLE_CLASSROOM_CLASS_ARCHIVED,
  UNKNOWN_ERROR_CODE,
} from 'studyApp/constants/google-classroom-constants.js';

import GoogleAuthApiDisabledWarningBox from './google-auth-api-disabled-warning-box.vue';
import GoogleAuthErrorWarningBox from './google-auth-error-warning-box.vue';
import GoogleAuthNoClassAccess from './google-auth-no-class-access.vue';
import GoogleClassroomAPIUnavailableWarningBox from './google-classroom-api-unavailable-warning-box.vue';
import GoogleClassroomClassArchived from './google-classroom-class-archived.vue';
import ServerErrorWarningBox from './server-error-warning-box.vue';

const warnings = {
  [GOOGLE_AUTH_ERROR_CODE]: GoogleAuthErrorWarningBox,
  [UNKNOWN_ERROR_CODE]: ServerErrorWarningBox,
  [GOOGLE_AUTH_CLASSROOM_API_DISABLED]: GoogleAuthApiDisabledWarningBox,
  [GOOGLE_AUTH_NO_CLASS_ACCESS]: GoogleAuthNoClassAccess,
  [GOOGLE_CLASSROOM_CLASS_ARCHIVED]: GoogleClassroomClassArchived,
  [GOOGLE_CLASSROOM_API_UNAVAILABLE]: GoogleClassroomAPIUnavailableWarningBox,
};

export default {
  name: 'GoogleClassroomWarningBox',
  props: {
    warningCode: {
      type: String,
      required: true,
    },
    origin: {
      type: String,
      required: true,
    },
    requestType: {
      type: String,
      default: '',
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    assignmentId: {
      type: String,
      default: '',
    },
    googleClassId: {
      type: String,
      default: '',
    },
  },
  computed: {
    component() {
      return warnings[this.warningCode];
    },
  },
};
</script>
