<template>
  <div class="text-center">
    <span
      v-if="value > 0 && value < maxTime"
      class="screenreader-only"
      aria-live="polite"
    >
      {{ timerWarning }}
    </span>
    <div aria-hidden="true">
      <kog-progress-circle
        :first-value="value"
        :total="maxTime"
        size="xl"
        type="warning"
        :text="formattedTime"
      />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format/index.js';

import KogProgressCircle from 'sharedApp/components/base/progress/kog-progress-circle.vue';
import DjangoSettings from 'sharedApp/django-settings-module.ts';

const TIMER_STEP_SEC = 1;
const TIME_FORMAT = 'm:ss';
const MILLIS_PER_SEC = 1000;
const SECONDS_PER_QUESTION = DjangoSettings.PRACTICE_SETTINGS.BATTLE_SECONDS_PER_QUESTION;

export default {
  name: 'CountdownTimer',
  components: {
    KogProgressCircle,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input', 'end', 'update:value'],
  data() {
    return {
      maxTime: SECONDS_PER_QUESTION,
    };
  },
  computed: {
    formattedTime() {
      return format(new Date(this.value * MILLIS_PER_SEC), TIME_FORMAT);
    },
    timerWarning() {
      const warningTimes = [50, 30, 20, 10, 5];
      if (warningTimes.includes(this.value)) {
        return `${this.value} seconds left.`;
      }

      return '';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.runOneStep(this.value, TIMER_STEP_SEC);
      },
    },
  },
  methods: {
    decreaseValue(currentTime, step) {
      const newTime = currentTime >= step ? currentTime - step : 0;
      this.$emit('update:value', newTime);
      this.$emit('input', newTime);
      if (newTime === 0) {
        this.$emit('end');
      }
    },
    runOneStep(currentTime, step) {
      if (currentTime <= 0 || !this.active) {
        return;
      }
      setTimeout(() => this.decreaseValue(currentTime, step), step * MILLIS_PER_SEC);
    },
  },
};
</script>
