<template>
  <div class="KogSchoolSummaryCard padd-s">
    <div class="heading-s">
      {{ title }}
    </div>
    <div
      v-if="!url"
      class="KogSchoolSummaryCard-text"
    >
      {{ text }}
    </div>
    <div v-else>
      <a :href="url">{{ text }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KogSchoolSummaryCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.KogSchoolSummaryCard {
  border: 1px solid var(--kogPlatformGray084);
  border-radius: var(--space-xs);
}

.KogSchoolSummaryCard-text {
  min-height: var(--space-l);
}
</style>
