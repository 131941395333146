<template>
  <div>
    <kog-tag
      class="AssignmentBasket"
      aria-label="Open assignment basket"
      :is-chip="true"
      :class="tagClass"
      icon-class="fa-clipboard-list-check"
      :icon-style="assignment?.sections.length > 0 ? 'solid' : 'regular'"
      :label="openDraftNodeCount"
      @click="toggleAssignmentBasket"
    />
    <transition
      name="slide-fade"
      mode="in-out"
    >
      <div
        v-show="isAssignmentBasketOpen"
        class="absoluteContainer flexContainer flexContainer-justifyCenter"
      >
        <div
          class="AssignmentBasket--popup shadow-l-020 body-text flexContainer flexContainer-column"
        >
          <div
            class="AssignmentBasket-topTitle flexContainer flexContainer-spaceBetween flexContainer-center"
          >
            <span class="text-overline">Assignment</span>
            <kog-round-button
              aria-label="Close popup"
              icon-class="fa-times"
              icon-style="solid"
              size="small"
              button-style="secondary-basic"
              @click.prevent="closeAssignmentBasket"
            />
          </div>
          <div
            v-if="hasOpenDraftAssignment"
            class="AssignmentBasket--popupEditableSection"
          >
            <integration-assignment-alert :show-icon="false" />
            <div class="AssignmentBasket--inputItem">
              <span class="text-overline">Title</span>
              <kog-input
                class="margin-top-xxs"
                :value="assignmentName"
                label="Title"
                :is-label-hidden="true"
                @focus="disableActionButton = true"
                @change="assignmentName = $event.target.value.trim()"
                @blur="disableActionButton = false"
              />
            </div>
            <div class="AssignmentBasket--inputItem">
              <div class="flexContainer flexContainer-spaceBetween flexContainer-center">
                <span class="text-overline">Tasks ({{ openDraftNodeCount }})</span>
                <kog-round-button
                  aria-label="Expand assignment"
                  :disabled="openDraftNodeCount === '0'"
                  :icon-class="isAssignmentContentExpanded ? 'fa-angle-up' : 'fa-angle-down'"
                  icon-style="solid"
                  size="small"
                  button-style="secondary-basic"
                  @click="isAssignmentContentExpanded = !isAssignmentContentExpanded"
                />
              </div>
              <vertical-expand-transition>
                <draggable
                  v-show="isAssignmentContentExpanded"
                  v-model="openDraftSubjectNodeIds"
                  animation="150"
                  :item-key="subjectNodeId => `element-${subjectNodeId}`"
                >
                  <template #item="{ element: subjectNodeId }">
                    <assignment-basket-node
                      :subject-node-id="subjectNodeId"
                      class="cursorGrab"
                      @remove-node="deleteItem"
                    />
                  </template>
                </draggable>
              </vertical-expand-transition>
            </div>
            <assignment-lms-integration-container
              v-if="!!subjectClass && !!assignment"
              class="AssignmentBasket--inputItem"
              :send-to-integration="assignment.send_to_integration"
              :subject-class="subjectClass"
              :assignment-type="assignment.assignment_type"
              :points-possible="assignment.integration_settings?.points_possible"
              :categories-by-class-id="categoriesByClassId"
              @send-to-integration-updated="updateSendToIntegration($event)"
              @points-possible-updated="updatePointsPossible($event)"
              @input-focused="disableActionButton = true"
              @input-blurred="disableActionButton = false"
              @categories-populated="bulkUpdateLmsCategory"
            />
            <div
              v-if="!!subjectClass"
              class="AssignmentBasket--inputItem"
            >
              <div class="flexContainer flexContainer-spaceBetween flexContainer-center">
                <span class="text-overline">{{ recipientsCountText }}</span>
                <kog-round-button
                  v-if="isMultipleClassAssignment"
                  aria-label="Expand recipients"
                  :icon-class="isAssignmentRecipientsExpanded ? 'fa-angle-up' : 'fa-angle-down'"
                  icon-style="solid"
                  size="small"
                  button-style="secondary-basic"
                  @click="isAssignmentRecipientsExpanded = !isAssignmentRecipientsExpanded"
                />
              </div>
              <kog-button
                v-if="!isMultipleClassAssignment"
                label="Change recipients"
                size="small"
                class="width-full margin-top-s"
                @click="showChangeRecipientsModal"
              />
              <vertical-expand-transition v-else>
                <recipient-class-list
                  v-show="isAssignmentRecipientsExpanded"
                  :selected-student-ids="assignment.selected_student_ids"
                  :update-recipients="updateRecipients"
                  :google-classroom-recipients="assignment.google_classroom_recipients"
                  :send-to-integration="assignment.send_to_integration"
                  :categories-by-class-id="categoriesByClassId"
                  :assignment-subject-class-ids="assignment.subject_classes"
                  @send-to-integration-updated="updateSendToIntegration"
                  @categories-populated="bulkUpdateLmsCategory"
                />
              </vertical-expand-transition>
            </div>
            <div class="AssignmentBasket--inputItem">
              <div class="flexContainer flexContainer-spaceBetween flexContainer-center">
                <span
                  class="text-overline"
                  :class="{ 'text-muted': !isAddingInstructions }"
                  >Instructions</span
                >
                <kog-toggle-button
                  v-model:is-pressed="isAddingInstructions"
                  aria-label="Add/Remove instructions"
                />
              </div>
              <vertical-expand-transition>
                <kog-textarea
                  v-show="isAddingInstructions"
                  class="margin-top-xxs"
                  label="Instructions"
                  :is-label-hidden="true"
                  :value="assignmentInstructions"
                  @focus="disableActionButton = true"
                  @blur="disableActionButton = false"
                  @change="assignmentInstructions = $event.target.value.trim()"
                />
              </vertical-expand-transition>
            </div>
            <div class="AssignmentBasket--inputItem">
              <div class="flexContainer flexContainer-spaceBetween flexContainer-center">
                <span
                  class="text-overline"
                  :class="{ 'text-muted': !isSchedulingAssignment }"
                  >Schedule</span
                >
                <kog-toggle-button
                  v-model:is-pressed="isSchedulingAssignment"
                  aria-label="Schedule / Unschedule assignment"
                />
              </div>
              <vertical-expand-transition>
                <div
                  v-show="isSchedulingAssignment"
                  class="margin-top-xxs"
                >
                  <kog-input
                    input-icon="calendar"
                    :is-icon-clickable="true"
                    label="Scheduled date"
                    is-label-hidden
                    data-input
                    @icon-click="() => $refs.scheduledDatePicker.fp.open()"
                  />
                  <flat-pickr
                    v-show="false"
                    ref="scheduledDatePicker"
                    v-model="assignmentScheduledSendDate"
                    :config="{ ...flatPickrConfig, maxDate: assignmentDeadline }"
                  />
                </div>
              </vertical-expand-transition>
            </div>
            <div class="AssignmentBasket--inputItem">
              <span class="text-overline">Deadline</span>
              <div class="margin-top-xxs">
                <kog-input
                  input-icon="calendar"
                  :is-icon-clickable="true"
                  label="Deadline"
                  is-label-hidden
                  data-input
                  @icon-click="() => $refs.deadlinePicker.fp.open()"
                />
                <flat-pickr
                  v-show="false"
                  ref="deadlinePicker"
                  v-model="assignmentDeadline"
                  :config="flatPickrConfig"
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="padd-m"
          >
            Add an item to create a new assignment.
          </div>
          <hidden-content-warning
            v-if="hasHiddenSections"
            class="AssignmentBasket--hiddenContentWarning"
          />
          <div
            class="padd-m kogPlatformYellowLighten20-bg text-right flexContainer flexContainer-flexEnd"
          >
            <template v-if="hasOpenDraftAssignment">
              <router-link :to="assignmentReviewRoute">
                <kog-button
                  label="Review"
                  size="small"
                  class="margin-right-xs"
                  :disabled="isActionButtonDisabled"
                />
              </router-link>
              <kog-split-buttons
                :main-button-label="isSchedulingAssignment ? 'Schedule' : 'Send'"
                :main-button-id="
                  isSchedulingAssignment ? 'schedule-assignment-button' : 'send-assignment-button'
                "
                :dropdown-items="[
                  isSchedulingAssignment
                    ? {
                        text: 'Schedule',
                        value: ACTIONS.SCHEDULE,
                        id: 'schedule-assignment-button',
                      }
                    : { text: 'Send', value: ACTIONS.SEND, id: 'send-assignment-button' },
                  { text: 'Discard draft', value: ACTIONS.DISCARD },
                  { text: 'Save draft', value: ACTIONS.SAVE },
                ]"
                button-style="accent"
                size="small"
                :is-loading="$wait.is('saving-assignment-details')"
                :disabled="isActionButtonDisabled"
                @main-button-click="
                  isSchedulingAssignment
                    ? handleAssignmentAction(ACTIONS.SCHEDULE)
                    : handleAssignmentAction(ACTIONS.SEND)
                "
                @dropdown-select="$event => handleAssignmentAction($event.value)"
              />
            </template>
            <template v-else>
              <kog-dropdown-button
                class="width-full"
                button-type="default"
                icon-style="regular"
                icon-size="s"
                :items="openDraftOptionItems"
                label="Open draft"
                size="medium"
                dropdown-item-type="text"
                dropdown-placement="bottom-end"
                :full-width="true"
                :disabled="noDraftAssignments"
                :tooltip="noDraftAssignments ? 'No draft book assignments created' : ''"
                :is-loading="$wait.is('fetching-draft-assignments')"
                @select="item => openExistingDraftAssignment(item.value)"
              />
            </template>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { provide, ref } from 'vue';
import { uniqBy } from 'lodash';
import cloneDeep from 'lodash/cloneDeep.js';
import debounce from 'lodash/debounce.js';
import { storeToRefs } from 'pinia';
import FlatPickr from 'vue-flatpickr-component';
import { waitFor } from 'vue-wait';
import Draggable from 'vuedraggable';
import { mapGetters, mapState } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import VerticalExpandTransition from 'sharedApp/animations/vertical-expand.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogSplitButtons from 'sharedApp/components/base/buttons/kog-split-buttons.vue';
import KogToggleButton from 'sharedApp/components/base/buttons/kog-toggle-button.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import useAssignments from 'sharedApp/composables/use-assignments.ts';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import { BASKET_MIXPANEL_EVENTS } from 'sharedApp/const/assignment-basket.js';
import posthog from 'sharedApp/libs/posthog.ts';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import IntegrationAssignmentAlert from 'studyApp/components/assignment/integration-assignment-alert.vue';
import HiddenContentWarning from 'studyApp/components/teacher/assignment-create/hidden-content-warning.vue';
import AssignmentLmsIntegrationContainer from 'studyApp/components/teacher/assignment-create/sendout-step/assignment-lms-integration-container.vue';
import RecipientClassList from 'studyApp/components/teacher/assignment-create/sendout-step/recipient-class-list.vue';
import SelectRecipientsModal from 'studyApp/components/teacher/assignment-create/sendout-step/select-recipients-modal.vue';
import AssignmentBasketNode from 'studyApp/components/teacher/assignments/assignment-basket-node.vue';
import SendAssignmentTrackingMixin from 'studyApp/mixins/teacher/send-assignment-tracking-mixin.js';
import StudyAppSessionStorage from 'studyApp/utils/session-storage-utils.js';
import ManualTimeInputPlugin from 'teachApp/utils/flatPickrPlugins/manual-time-input-plugin.js';

const studyAppSessionStorage = new StudyAppSessionStorage();
const ACTIONS = {
  SEND: 'send',
  SCHEDULE: 'schedule',
  DISCARD: 'discard',
  SAVE: 'save',
};

export default {
  name: 'TeacherAssignmentBasket',
  components: {
    IntegrationAssignmentAlert,
    AssignmentLmsIntegrationContainer,
    HiddenContentWarning,
    KogSplitButtons,
    AssignmentBasketNode,
    VerticalExpandTransition,
    KogTextarea,
    KogButton,
    KogInput,
    KogRoundButton,
    KogDropdownButton,
    KogToggleButton,
    KogTag,
    FlatPickr,
    Draggable,
    RecipientClassList,
  },
  mixins: [RoutesMixin, SendAssignmentTrackingMixin],
  provide() {
    return {
      isAssignmentBasketStyle: true,
      updateLmsCategory: ({ categoryId, classId }) => {
        this.updateLmsCategory({
          categoryId,
          classId,
        });
      },
    };
  },
  props: {
    theme: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'light'].includes(prop),
    },
  },
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);
    const { isEdlinkAssignmentIntegrationEnabled } = storeToRefs(featureFlagStore);

    const disabledSendAssignmentToLmsTooltip = ref('');
    provide('disabledSendAssignmentToLmsTooltip', disabledSendAssignmentToLmsTooltip);

    const assignmentStore = useAssignmentStore();
    const { assignment, isAssignmentBasketOpen } = storeToRefs(assignmentStore);
    const {
      fetchAssignment,
      sendAssignment,
      deleteAssignment,
      removeSection,
      updateSectionsOrder,
      saveAssignment,
    } = assignmentStore;

    const { draftAssignments, getDraftAssignments } = useAssignments();

    return {
      assignment,
      isAssignmentBasketOpen,
      fetchAssignment,
      sendAssignment,
      deleteAssignment,
      removeSection,
      updateSectionsOrder,
      isMultipleClassAssignmentEnabled,
      isEdlinkAssignmentIntegrationEnabled,
      saveAssignment: waitFor(
        'assignment_basket/saving_assignment_details',
        debounce(saveAssignment, 1000, { leading: true, trailing: true }),
      ),
      getUniqueId: useUniqueId().getUniqueId,
      draftAssignments,
      getDraftAssignments,
    };
  },
  data() {
    return {
      ACTIONS,
      assignmentScheduledSendDate: null,
      isAssignmentContentExpanded: false,
      isAssignmentInstructionsExpanded: false,
      isAssignmentRecipientsExpanded: true,
      isSchedulingAssignment: false,
      disableActionButton: false,
      flatPickrConfig: {
        dateFormat: 'Y-m-d H:i',
        enableTime: true,
        allowInput: false,
        time_24hr: true,
        minDate: new Date(),
        minuteIncrement: 1,
        wrap: true,
        plugins: [new ManualTimeInputPlugin()],
      },
    };
  },
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    ...mapState({
      subject: state => state.subjectModule.subject,
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
      subjectClasses: state => state.subjectClassModule.subjectClasses,
      subjectClassStudentsList: state => state.teacherStudentListModule.subjectClassStudentsList,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    isAddingInstructions: {
      get() {
        return this.isAssignmentInstructionsExpanded;
      },
      set(isAddingInstructions) {
        this.isAssignmentInstructionsExpanded = isAddingInstructions;
        if (isAddingInstructions) {
          this.$mixpanel.trackEvent(
            BASKET_MIXPANEL_EVENTS.ADD_INSTRUCTIONS,
            this.mixpanelTrackingProps,
          );
        } else {
          this.assignmentInstructions = '';
        }
      },
    },
    assignmentName: {
      get() {
        return this.assignment.name;
      },
      set(name) {
        this.assignment.name = name;
        return this.saveAssignment();
      },
    },
    assignmentInstructions: {
      get() {
        return this.assignment.message;
      },
      set(instructions) {
        this.assignment.message = instructions;
        return this.saveAssignment();
      },
    },
    assignmentDeadline: {
      get() {
        return this.assignment.deadline;
      },
      set(date) {
        this.assignment.deadline = date;
        return this.saveAssignment();
      },
    },
    openDraftAssignmentId: {
      get() {
        return studyAppSessionStorage.getOpenDraftAssignmentId(this.classId);
      },
      set(newAssignmentId) {
        return studyAppSessionStorage.setOpenDraftAssignmentId(this.classId, newAssignmentId);
      },
    },
    tagClass() {
      if (this.assignment?.sections.length > 0) {
        return `AssignmentBasket--${this.theme}--Active`;
      }
      return `AssignmentBasket--${this.theme}`;
    },
    hasOpenDraftAssignment() {
      return !!this.assignment?.id;
    },
    noDraftAssignments() {
      return this.draftAssignments.length === 0;
    },
    openDraftOptionItems() {
      return this.draftAssignments.map(assignment => ({
        text: assignment.name,
        value: assignment.id,
      }));
    },
    openDraftNodeCount() {
      return this.hasOpenDraftAssignment ? this.assignment.sections.length.toString() : '0';
    },
    openDraftSubjectNodeIds: {
      get() {
        return this.hasOpenDraftAssignment
          ? this.assignment.sections.map(section => section.subject_node_id)
          : [];
      },
      set(newArray) {
        this.updateNodeOrder(newArray);
      },
    },
    recipientsCount() {
      if (this.assignment === null) {
        return 0;
      }
      return (
        this.assignment.selected_student_ids.length +
        this.assignment.google_classroom_recipients.reduce(
          (acc, googleClass) => acc + googleClass.recipient_upstream_ids.length,
          0,
        )
      );
    },
    recipientsCountText() {
      const title = this.isMultipleClassAssignment ? 'Assign to' : 'Recipients';
      const recipientClassesCount = this.assignment?.subject_classes.length ?? 0;
      const assignees = this.isMultipleClassAssignment
        ? recipientClassesCount
        : this.recipientsCount;
      return `${title} (${assignees})`;
    },
    isStudentSelectedBySchoolStudentId() {
      return this.subjectClassStudentsList.reduce((acc, student) => {
        acc[student.school_student] = this.assignment.selected_student_ids.includes(
          student.school_student,
        );
        return acc;
      }, {});
    },
    hasHiddenSections() {
      return this.openDraftSubjectNodeIds.some(subjectNodeId => !this.isNodeVisible(subjectNodeId));
    },
    isActionButtonDisabled() {
      return this.disableActionButton || this.$wait.is('assignment_basket/*');
    },
    mixpanelTrackingProps() {
      return {
        page: this.$route.name === 'subjectClassOverview' ? 'overview' : 'book',
      };
    },
    isAssignmentListPage() {
      return this.$route.name === 'teacherAssignmentOverview';
    },
    assignmentReviewRoute() {
      return {
        name: 'teacherAssignmentCreate',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignment.id,
        },
        query: {
          source: 'assignment_basket',
        },
      };
    },
    categoriesByClassId() {
      return this.assignment.integration_settings?.categories_by_class_id ?? {};
    },
    isMultipleClassAssignmentOverride() {
      return ['true', '1'].includes(this.$route.query.override_multi_class_assignments);
    },
    isMultipleClassAssignment() {
      return this.isMultipleClassAssignmentEnabled || this.isMultipleClassAssignmentOverride;
    },
  },
  watch: {
    assignment: {
      handler(newAssignment) {
        if (newAssignment === null) {
          this.getLatestDraftAssignments();
        }
      },
    },
    'assignment.id': {
      immediate: true,
      handler(newId) {
        if (
          this.assignment &&
          (this.assignment.sent_at ||
            this.assignment.is_scheduled_to_send ||
            !this.assignment.subject_classes.includes(this.classId))
        ) {
          this.openDraftAssignmentId = null;
          this.assignment = null;
          return;
        }
        if (newId) {
          this.openDraftAssignmentId = newId;
        }
        if (this.assignment && this.assignmentInstructions !== '') {
          this.isAddingInstructions = true;
        }
      },
    },
    classId: {
      handler() {
        this.closeAssignmentBasket();
        this.assignment = null;
        this.fetchDraftAssignment();
      },
      immediate: true,
    },
  },
  methods: {
    fetchDraftAssignment() {
      if (this.openDraftAssignmentId) {
        this.fetchAssignment(this.openDraftAssignmentId);
      } else {
        this.getLatestDraftAssignments();
      }
    },
    async getLatestDraftAssignments() {
      this.$wait.start('fetching-draft-assignments');
      try {
        await this.getDraftAssignments({
          page: 1,
          pageSize: 3,
          subjectClassId: this.classId,
          sortBy: '-created_at',
          assignmentType: 'READING',
        });
      } finally {
        this.$wait.end('fetching-draft-assignments');
      }
    },
    openExistingDraftAssignment(assignmentId) {
      posthog.capture('open_assignment_in_teacher_assignment_basket');

      this.openDraftAssignmentId = assignmentId;
      this.fetchAssignment(assignmentId);
    },
    showChangeRecipientsModal() {
      this.$mixpanel.trackEvent(
        BASKET_MIXPANEL_EVENTS.CHANGE_RECIPIENTS,
        this.mixpanelTrackingProps,
      );
      return this.$modal(SelectRecipientsModal, {
        subject: this.subject,
        subjectClassStudents: this.subjectClassStudentsList,
        subjectClassName: this.subjectClass.name,
        selectedStudents: this.isStudentSelectedBySchoolStudentId,
        updateSelectedStudents: this.updateSelectedStudents,
        googleClasses: this.subjectClass.google_classes,
        googleClassroomRecipients: this.assignment.google_classroom_recipients,
      });
    },
    updateRecipients(selectedSubjectClasses, selectedStudents, selectedGoogleClassStudentsList) {
      this.assignment.subject_classes = selectedSubjectClasses.map(subjectClass => subjectClass.id);
      this.assignment.selected_student_ids = selectedStudents.map(
        student => student.school_student,
      );
      let googleClasses = selectedSubjectClasses.flatMap(
        subjectClass => subjectClass.google_classes,
      );
      googleClasses = uniqBy(googleClasses, 'id');
      const googleClassesWithSelectedStudents = googleClasses.map(googleClass => {
        const filteredGCRecipients = googleClass.students.filter(
          googleStudent => selectedGoogleClassStudentsList[googleStudent.upstream_student_id],
        );
        const filteredGCRecipientsIds = filteredGCRecipients.map(
          recipient => recipient.upstream_student_id,
        );

        return {
          upstream_class_id: googleClass.id,
          recipient_upstream_ids: filteredGCRecipientsIds,
        };
      });
      this.assignment.google_classroom_recipients = googleClassesWithSelectedStudents;
      this.saveAssignment();
    },
    updateSelectedStudents(studentsSelectedById, googleClassStudentsSelectedById) {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const selectedStudentIdList = Object.entries(studentsSelectedById)
        .filter(([_, isSelected]) => isSelected)
        .map(([studentId, _]) => studentId);

      const googleClassesRecipients = this.subjectClass.google_classes.map(googleClass => {
        const selectedUpstreamStudentIdsInClass = googleClass.students
          .filter(student => googleClassStudentsSelectedById[student.upstream_student_id])
          .map(student => student.upstream_student_id);
        return {
          upstream_class_id: googleClass.id,
          recipient_upstream_ids: selectedUpstreamStudentIdsInClass,
        };
      });

      this.assignment.selected_student_ids = selectedStudentIdList;
      this.assignment.google_classroom_recipients = googleClassesRecipients;
      this.saveAssignment();
      /* eslint-enable @typescript-eslint/no-unused-vars */
    },
    checkSendRestrictions() {
      if (this.recipientsCount === 0) {
        this.$toast.showError('Please select at least one recipient');
        return false;
      }
      if (this.assignmentName === '') {
        this.$toast.showError('Please select a title for the assignment');
        return false;
      }
      if (this.assignmentDeadline === '') {
        this.$toast.showError('Please select a deadline for the assignment');
        return false;
      }
      if (this.assignmentScheduledSendDate === null && this.isSchedulingAssignment) {
        this.$toast.showError('Please select a scheduled date for the assignment');
        return false;
      }
      if (this.isSchedulingAssignment && new Date(this.assignmentScheduledSendDate) < new Date()) {
        this.$toast.showError('Cannot schedule an assignment in the past');
        return false;
      }
      return true;
    },
    async handleAssignmentAction(action) {
      let successToasterText = `Assignment: "${this.assignment.name}" `;
      const lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 7);
      try {
        switch (action) {
          case ACTIONS.SEND:
            if (this.checkSendRestrictions()) {
              successToasterText += `has been sent to ${this.recipientsCount} students`;
              const assignmentData = cloneDeep(this.assignment);
              const extraMixpanelProps = cloneDeep(this.mixpanelTrackingProps);
              await this.sendAssignment();
              this.performTracking({
                eventName: 'send_assignment',
                assignmentData,
                googleClasses: this.subjectClass.google_classes,
                extraMixpanelProps,
              });
              break;
            }
            return;
          case ACTIONS.SCHEDULE:
            if (this.checkSendRestrictions()) {
              successToasterText += `scheduled for ${dateTimeFormatter(
                new Date(this.assignmentScheduledSendDate),
              )}`;
              this.assignment.scheduled_sent_at = this.assignmentScheduledSendDate;
              await this.saveAssignment();
              this.performTracking({
                eventName: 'schedule_assignment',
                assignmentData: this.assignment,
                googleClasses: this.subjectClass.google_classes,
                extraMixpanelProps: this.mixpanelTrackingProps,
              });
              break;
            }
            return;
          case ACTIONS.DISCARD:
            successToasterText += 'has been discarded';
            await this.deleteAssignment(this.assignment.id);
            this.$mixpanel.trackEvent(
              BASKET_MIXPANEL_EVENTS.DISCARD_DRAFT,
              this.mixpanelTrackingProps,
            );
            break;
          case ACTIONS.SAVE:
            successToasterText += 'has been stored as draft';
            this.$mixpanel.trackEvent(
              BASKET_MIXPANEL_EVENTS.SAVE_DRAFT,
              this.mixpanelTrackingProps,
            );
            break;
          default:
            return;
        }
      } catch (error) {
        this.$toast.showError('Could not perform action. Please try again later');
        throw error;
      }
      this.openDraftAssignmentId = null;
      this.assignment = null;
      if (this.isAssignmentListPage) {
        this.getDraftAssignments({
          subjectClassId: this.subjectClass.id,
          sortOrder: 'desc',
          pageSize: 10,
        });
      }
      this.closeAssignmentBasket();
      this.$toast.showSuccess(successToasterText);
    },
    async updateNodeOrder(newItemsOrder) {
      try {
        await this.updateSectionsOrder(newItemsOrder);
      } catch (error) {
        this.$toast.showError(
          'Could not update the order of one or more nodes. Please try again later',
        );
        this.fetchAssignment(this.openDraftAssignmentId);
        throw error;
      }
    },
    async deleteItem(subjectNodeId) {
      await this.removeSection(subjectNodeId);
      const subjectNode = this.subjectNodesById[subjectNodeId];
      this.$mixpanel.trackEvent(BASKET_MIXPANEL_EVENTS.DELETE_ITEM, {
        ...this.mixpanelTrackingProps,
        subject_node_id: subjectNodeId,
        subject_node_name: `${subjectNode.formatted_number_including_ancestors} ${subjectNode.name}`,
        content_type: this.getNodeContentType(subjectNodeId),
      });
    },
    getNodeContentType(nodeId) {
      if (this.getActivityBySubjectNodeId(nodeId)) {
        return this.getActivityBySubjectNodeId(nodeId).type;
      }
      return 'Section';
    },
    toggleAssignmentBasket() {
      this.isAssignmentBasketOpen = !this.isAssignmentBasketOpen;
    },
    closeAssignmentBasket() {
      this.isAssignmentBasketOpen = false;
    },
    updateSendToIntegration(isEnabled) {
      this.assignment.send_to_integration = isEnabled;
      this.saveAssignment();
    },
    updateLmsCategory({ categoryId, classId }) {
      if (!this.assignment.integration_settings.categories_by_class_id) {
        this.assignment.integration_settings.categories_by_class_id = {};
      }
      this.assignment.integration_settings.categories_by_class_id[classId] = categoryId;
      this.saveAssignment();
    },
    bulkUpdateLmsCategory(categoriesByClassId) {
      this.assignment.integration_settings.categories_by_class_id = categoriesByClassId;
      this.saveAssignment();
    },
    updatePointsPossible(pointsPossible) {
      this.assignment.integration_settings.points_possible = Number(pointsPossible);
      this.saveAssignment();
    },
  },
};
</script>

<style>
:root {
  --AssignmentBasketHeaderAndFooter: 129px;
}
</style>

<style scoped>
.AssignmentBasket {
  background-color: var(--kogInherit);
}

.AssignmentBasket--light {
  color: var(--kog-colors-white);
  border-color: var(--kog-colors-white);
}

.AssignmentBasket--light:hover {
  border-color: var(--kog-colors-white);
}

.AssignmentBasket--light--Active {
  color: var(--kog-text-brand);
  background-color: var(--kog-colors-white);
  border-color: var(--kog-colors-white);
}

.AssignmentBasket--primary {
  color: var(--kog-text-brand);
  border-color: var(--kog-border-brand-900);
}

.AssignmentBasket--primary:hover {
  border-color: var(--kog-border-brand-900);
}

.AssignmentBasket--primary--Active {
  color: var(--kog-colors-white);
  background-color: var(--kog-background-brand-900);
  border-color: var(--kog-border-brand-900);
}

.AssignmentBasket--popup {
  position: absolute;

  overflow-y: hidden;
  display: flex;

  width: 320px;
  max-height: 90vh;
  margin-top: var(--space-xs);

  background-color: var(--kog-colors-white);
  border-radius: 10px;
}

.AssignmentBasket--popupEditableSection {
  overflow-y: scroll;
  overscroll-behavior: contain;
  max-height: calc(90vh - var(--AssignmentBasketHeaderAndFooter));
}

.AssignmentBasket-topTitle {
  padding: var(--space-m) var(--space-s) var(--space-xs) var(--space-m);
  background-color: var(--kogPlatformGray093);
}

.AssignmentBasket--inputItem {
  padding: var(--space-m) var(--space-s) var(--space-xs) var(--space-m);
  border-bottom: 1px solid var(--kogPlatformGray084);
}

.AssignmentBasket--hiddenContentWarning {
  border-radius: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.15s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
