<template>
  <div class="flexContainer flexContainer-spaceBetween flexContainer-center">
    <span class="text-overline">
      {{ title }}
    </span>
    <kog-round-button
      icon-class="fa-xmark"
      button-style="basic"
      size="small"
      :aria-label="`Close ${title}`"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';

export default {
  name: 'BookRailSliderHeader',
  components: {
    KogRoundButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
};
</script>
