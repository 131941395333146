<template>
  <kog-table-cell-generic
    :is-horizontal-padding-enabled="false"
    :has-left-gap="hasLeftGap"
  >
    <activity-cell
      v-tooltip="{
        theme: 'kog-tooltip',
        content: getMarksTooltipText,
        autoHide: false,
        boundary: 'window',
      }"
      class="MarksCell"
      :is-clickable="false"
      :progress="marksLabel"
      :color="color"
    />
  </kog-table-cell-generic>
</template>

<script>
import { VTooltip } from 'floating-vue';
import { isNil } from 'lodash';

import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import ActivityCell from 'studyApp/components/teacher/insights/activity-cell.vue';

export default {
  name: 'MarksCell',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    KogTableCellGeneric,
    ActivityCell,
  },
  props: {
    marksData: {
      type: Object,
      default: null,
    },
    questionProgressScale: {
      type: Object,
      required: true,
    },
    hasLeftGap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    marks() {
      return this.marksData?.marks_draft;
    },
    marksLabel() {
      if (isNil(this.marksData)) {
        return 'x';
      }
      if (isNil(this.marks)) {
        return '-';
      }
      return this.marks;
    },
    max() {
      return this.marksData?.max;
    },

    color() {
      if (isNil(this.marksData) || isNil(this.marks)) {
        return this.questionProgressScale.getProgressColor(-1);
      }
      const progress = this.marks / this.max;
      return this.questionProgressScale.getProgressColor(progress);
    },
    getMarksTooltipText() {
      if (isNil(this.marksData)) {
        return 'Not submitted';
      }
      if (isNil(this.marks)) {
        return 'Not graded';
      }
      return `${this.marks}/${this.max} marks`;
    },
  },
};
</script>
<style scoped>
.MarksCell {
  width: unset !important;
  min-width: 80px;
  min-height: 48px;

  font-weight: bold;
  text-align: center;

  border-right: 1px solid var(--kog-colors-grey-300);
}
</style>
