<template>
  <div class="ClassCardLoader flexContainer flexContainer-column">
    <kog-skeleton
      width="100%"
      height="48px"
      style="margin-top: -5px"
    />
    <div
      class="ClassCardLoader-details flexContainer flexContainer-column flexContainer-spaceBetween"
    >
      <div>
        <kog-skeleton
          width="80%"
          height="30px"
        />
        <kog-skeleton width="100%" />
      </div>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-skeleton
          width="100px"
          height="30px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KogSkeleton from 'sharedApp/components/base/skeleton/kog-skeleton.vue';

export default {
  name: 'ClassCardLoader',
  components: {
    KogSkeleton,
  },
};
</script>

<style scoped>
.ClassCardLoader {
  height: 216px;
  background: var(--kogPlatformWhite);
  border-radius: 8px;
  box-shadow: 0 4px 8px -2px var(--kogShadow030);
}

.ClassCardLoader-details {
  flex: 1;
  padding: var(--space-m);
}
</style>
