<template>
  <div class="kog-container-lg padd-bottom-l">
    <div class="kog-row">
      <div class="kog-col-12 kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
        <div class="flexContainer flexContainer-spaceBetween">
          <div class="flexContainer margin-right-l">
            <div
              v-if="hasSubtopicImage"
              class="ReviewModeHeader-subtopicImage margin-right-l"
              :style="{ 'background-image': `url(${subtopicNode.image})` }"
            />
            <div class="flexContainer flexContainer-column">
              <div>
                <span class="margin-right-xs">
                  {{ subtopicNode.formatted_number_including_ancestors }}
                </span>
                <kog-tag
                  class="flexChild-flexStart margin-bottom-xxs"
                  type="warning"
                  label="Teacher view"
                />
              </div>
              <h2 class="heading-m margin-bottom-xs">
                {{ subtopicNode.name }}
              </h2>
            </div>
          </div>
          <div>
            <h5 class="heading-xxs"> Index </h5>
            <div
              v-for="node in nodes"
              :key="node.id"
            >
              <a
                href="#"
                @click.prevent="onNodeClick(node)"
                @keyup.enter="onNodeClick(node)"
                >{{ node.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export default {
  name: 'ReviewModeHeader',
  components: {
    KogTag,
  },
  props: {
    nodes: {
      type: Array,
      required: true,
    },
    subtopicNode: {
      type: Object,
      required: true,
    },
  },
  emits: ['navigate-to-node'],
  computed: {
    hasSubtopicImage() {
      return Boolean(this.subtopicNode.image);
    },
  },
  methods: {
    onNodeClick(node) {
      this.$emit('navigate-to-node', node);
    },
  },
};
</script>

<style scoped>
.ReviewModeHeader-subtopicImage {
  width: 132px;
  min-width: 132px;
  height: 132px;

  background-position: center;
  background-size: cover;
  border-radius: 12px;
}
</style>
