<template>
  <div class="GlossaryDrawerNavigation">
    <button
      v-for="item of navItems"
      :key="item"
      :aria-hidden="shouldHideFromAria(item) ? 'true' : null"
      :disabled="!filteredTermLetters.includes(item)"
      :aria-label="ariaLabel(item)"
      class="GlossaryDrawerNavigation-item"
      :class="{
        'GlossaryDrawerNavigation-item--active': item === currentLetter,
        'GlossaryDrawerNavigation-item--disabled': !filteredTermLetters.includes(item),
      }"
      :tabindex="shouldHideFromAria(item) ? -1 : 0"
      @click="scrollToLetter(item)"
    >
      {{ item === '#' ? '#' : item }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'GlossaryDrawerNavigation',
  data() {
    return {
      navItems: [
        '#',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
    };
  },
  computed: {
    ...mapState({
      currentLetter: state => state.glossaryV2Module.currentLetter,
    }),
    ...mapGetters({
      filteredTermLetters: 'glossaryV2Module/filteredTermLetters',
    }),
  },
  methods: {
    ...mapActions('glossaryV2Module', {
      scrollToLetter: 'scrollToLetter',
    }),
    shouldHideFromAria(item) {
      return item === this.currentLetter || !this.filteredTermLetters.includes(item);
    },
    ariaLabel(item) {
      return `Glossary letter: ${item}`;
    },
  },
};
</script>

<style scoped>
.GlossaryDrawerNavigation {
  display: grid;
  justify-items: center;

  height: 100%;
  max-height: 900px;
  padding-bottom: 24px;
}

.GlossaryDrawerNavigation-item {
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;

  color: var(--kogPlatformGray044);

  background-color: var(--kogTransparent);
  border: 0;
  border-radius: 50%;
}

.GlossaryDrawerNavigation-item:hover {
  color: var(--kogPlatformGray018);
  background-color: var(--kogPlatformGray093);
}

.GlossaryDrawerNavigation-item--active,
.GlossaryDrawerNavigation-item--active:hover {
  color: var(--kog-text-brand-hard);
  background-color: var(--kog-background-brand-900);
}

.GlossaryDrawerNavigation-item--disabled,
.GlossaryDrawerNavigation-item--disabled:hover {
  color: var(--kogPlatformGray077);
  background-color: var(--kogTransparent);
}
</style>
