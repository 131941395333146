const service = {
  /**
   * Finds a node in a recursive nodeTree by looking for a property propertyName
   * with the (unique) value uniqueValue. Should the value not be unique, the first
   * node having a property with the value will be returned.
   *
   * @param propertyName The name of the property to look for
   * @param uniqueValue The value to look for
   * @param nodeTree The recursive tree node list to look in
   * @param includeAncestors If set to true, the returned node will include a property
   * with an array of ancestors.
   * @returns node if found, undefined otherwise
   */
  findNodeByUniqueProperty(propertyName, uniqueValue, nodeTree, includeAncestors) {
    if (!nodeTree) {
      return false;
    }

    let foundNode;
    let node;
    for (let i = 0; i < nodeTree.length; i += 1) {
      node = nodeTree[i];
      if (node) {
        if (node[propertyName] === uniqueValue) {
          foundNode = node;
          break;
        } else if (node.children) {
          foundNode = this.findNodeByUniqueProperty(
            propertyName,
            uniqueValue,
            node.children,
            includeAncestors,
          );

          if (foundNode) {
            if (includeAncestors) {
              if (!foundNode.ancestors) {
                foundNode.ancestors = [];
              }
              foundNode.ancestors.push(node);
            }
            break;
          }
        }
      }
    }
    return foundNode;
  },
  findNodeById(uniqueValue, nodeTree, includeAncestors) {
    return this.findNodeByUniqueProperty('id', uniqueValue, nodeTree, includeAncestors);
  },
  findNodeBySectionId(uniqueValue, nodeTree, includeAncestors) {
    return this.findNodeByUniqueProperty('section_id', uniqueValue, nodeTree, includeAncestors);
  },
};

export default service;
