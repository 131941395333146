<template>
  <article
    ref="trendsBoundingBox"
    class="QuestionAssignmentDetailsStudents padd-l shadow-m-010"
    :class="{ 'container-wideWidth': !isFullViewport }"
  >
    <header class="flexContainer flexContainer-spaceBetween">
      <h4 class="heading-s">
        Assignment trends
        <kog-icon
          v-tooltip="{
            theme: 'kog-tooltip',
            content: trendsTooltipText,
            boundary: 'document.body',
          }"
          size="s"
          theme="custom"
          class="margin-left-xs kogPrimary"
          icon-class="fa-info-circle"
          fa-style="regular"
        />
      </h4>
      <answer-status-heatmap-legend />
    </header>
    <kog-select
      v-if="assignment.subject_classes.length > 1"
      v-model:selected-value="selectedClassFilter"
      class="QuestionAssignmentDetailsStudents-classFilter"
      label="Selected class"
      :is-label-hidden="true"
      :options="classFilterOptions"
    />
    <div class="flexContainer">
      <div
        class="QuestionAssignmentDetailsStudents-studentTableContainer flexTable absoluteContainer margin-right-l margin-top-l"
      >
        <div class="flexTable__row muted">
          <div class="flexTable__item flexTable__item-size-7 margin-right-m"> Student name </div>
          <div class="flexTable__item flexTable__item-size-4 margin-right-m"> Status </div>
          <div class="flexTable__item flexTable__item-size-3 margin-right-xs text-right">
            Score
          </div>
        </div>

        <div
          v-for="occasion in occasions"
          :key="occasion.user_id"
          class="flexTable__row flexTable__row-alignCenter cursorDefault"
          :class="{
            'QuestionAssignmentDetailsStudents-row--background':
              hoveredOccasion === occasion.user_id,
          }"
        >
          <div
            class="flexTable__item-allowOverflow flexTable__item-size-7 margin-right-m text-ellipsis"
          >
            <span
              v-tooltip="{
                theme: 'kog-tooltip',
                content: getStudentNameTooltipText(occasion),
                boundary: $refs.trendsBoundingBox,
              }"
              @mouseover="nameHovered(occasion)"
              @mouseout="nameNotHovered"
            >
              <a
                v-if="isDone(occasion)"
                class="cursorPointer QuestionAssignmentDetailsStudents-name"
                @click="openStudentAnswersModal(occasion)"
              >
                {{ occasion.name }}
              </a>
              <span v-else>
                {{ occasion.name }}
              </span>
            </span>
          </div>
          <div class="flexTable__item flexTable__item-size-4 margin-right-m muted">
            <span
              v-tooltip="{
                theme: 'kog-tooltip',
                content: getStatusTooltipText(occasion),
                boundary: 'document.body',
              }"
              class="text-capitalize"
            >
              {{ status(occasion) }}
            </span>
          </div>
          <div class="flexTable__item flexTable__item-size-3 margin-right-xs text-right muted">
            <span
              v-tooltip="{
                theme: 'kog-tooltip',
                content: getScoreTooltipText(occasion),
                boundary: 'document.body',
              }"
            >
              {{ score(occasion) }}
            </span>
          </div>
        </div>
      </div>
      <question-answers-heatmap
        :assignment="assignment"
        :assignment-progress-status="assignmentProgressStatus"
        :show-difficulty="showDifficulty"
        :track-assignment-details-students-event="trackQuestionAssignmentDetailsStudentsEvent"
        :occasions="occasions"
        @overflown="increaseContainer"
      />
    </div>
  </article>
</template>

<script>
import { VTooltip } from 'floating-vue';
import { mapGetters, mapState } from 'vuex';

import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import AnswerStatusHeatmapLegend from 'studyApp/components/teacher/assignments/details/answer-status-heatmap-legend.vue';
import QuestionAnswersHeatmap from 'studyApp/components/teacher/assignments/details/question-answers-heatmap.vue';
import StudentAnswersModal from 'studyApp/components/teacher/assignments/details/student-answers-modal.vue';

const ASSIGNMENT_PROGRESS_STATUS = {
  done: 'done',
  started: 'started',
  not_started: 'not started',
};

export default {
  name: 'QuestionAssignmentDetailsStudents',
  components: {
    KogSelect,
    KogIcon,
    QuestionAnswersHeatmap,
    AnswerStatusHeatmapLegend,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      trendsTooltipText: `Find common mistakes by clicking a question or
      a column to view student responses`,
      hoveredOccasion: -1,
      isFullViewport: false,
      selectedClassFilter: null,
    };
  },
  computed: {
    ...mapState({
      subjectClasses: state => state.subjectClassModule.subjectClasses,
    }),
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    assignmentProgressStatus() {
      return ASSIGNMENT_PROGRESS_STATUS;
    },
    defaultStudentId() {
      return +this.$route.query.student;
    },
    classFilterOptions() {
      const allClasses = {
        text: 'All classes',
        value: null,
      };
      const classOptions = this.subjectClasses
        .filter(subjectClass => this.assignment.subject_classes.includes(subjectClass.id))
        .map(sc => ({
          text: sc.name,
          value: sc.id,
        }));
      return [allClasses, ...classOptions];
    },
    selectedSubjectClass() {
      return this.subjectClassesById[this.selectedClassFilter];
    },
    studentsToRender() {
      if (!this.selectedSubjectClass) {
        return this.assignment.students;
      }
      return this.assignment.students.filter(student =>
        this.selectedSubjectClass.student_user_ids.includes(student.user_id),
      );
    },
    occasions() {
      return this.assignment.practice_occasions.filter(occasion =>
        this.studentsToRender.find(s => s.user_id === occasion.user_id),
      );
    },
  },
  mounted() {
    if (this.defaultStudentId) {
      const occasion = this.assignment.practice_occasions.find(
        o => o.user_id === this.defaultStudentId,
      );
      if (this.isDone(occasion)) {
        this.openStudentAnswersModal(occasion);
      }
    }
  },
  methods: {
    isLate(deadline, doneAt) {
      return new Date(doneAt) > new Date(deadline);
    },
    status(occasion) {
      if (this.isLate(this.assignment.deadline, occasion.done_at)) {
        return 'Late';
      }
      if (occasion.progress === this.assignmentProgressStatus.done) {
        return 'Submitted';
      }
      return occasion.progress;
    },
    getStatusTooltipText(occasion) {
      if (this.isDone(occasion)) {
        return this.getTooltipHTML(`Submitted on ${this.dateFormatted(occasion.done_at)}`);
      }
      if (this.isStarted(occasion)) {
        return this.getTooltipHTML(
          `Started on ${this.dateFormatted(new Date(occasion.started_at))}`,
        );
      }
      return '';
    },
    getTooltipHTML(text) {
      return `<p class="margin-xxs text-center">${text}</p>`;
    },
    getStudentFirstName(name) {
      return name.split(' ')[0];
    },
    getStudentNameTooltipText(occasion) {
      if (this.isDone(occasion)) {
        const tooltipText = `<p class="margin-xxs text-center">See ${this.getStudentFirstName(
          occasion.name,
        )}'s responses</p>`;
        return tooltipText;
      }
      return `
        <p class="margin-xxs text-center">
          ${this.getStudentFirstName(
            occasion.name,
          )} needs to submit the assignment before you can see their responses
        </p>
      `;
    },
    isStarted(occasion) {
      return occasion.progress === this.assignmentProgressStatus.started;
    },
    isDone(occasion) {
      return occasion.progress === this.assignmentProgressStatus.done;
    },
    score(occasion) {
      const correctQuestionAnswers = occasion.answered_questions.questions_correct_answer;
      const correctAnswerCount = correctQuestionAnswers.length;
      return `${correctAnswerCount}/${occasion.number_of_questions}`;
    },
    getScoreTooltipText(occasion) {
      if (!this.isDone(occasion)) {
        return '';
      }
      const percentage = (occasion.correct_answer_count / occasion.number_of_questions) * 100;
      return `<p class="margin-xxs text-center">${Math.round(percentage)}% correct</p>`;
    },
    dateFormatted(date) {
      return dateTimeFormatter(new Date(date), 'at');
    },
    nameHovered(occasion) {
      this.hoveredOccasion = occasion.user_id;
    },
    nameNotHovered() {
      this.hoveredOccasion = -1;
    },
    trackQuestionAssignmentDetailsStudentsEvent(eventName) {
      this.$mixpanel.trackEvent(eventName, {
        assignment_name: this.assignment.name,
      });
    },
    openStudentAnswersModal(occasion) {
      this.trackQuestionAssignmentDetailsStudentsEvent(
        'Assignment Details - Click student name button',
      );
      this.$modal(
        StudentAnswersModal,
        {
          onModalClose: () => {
            this.trackQuestionAssignmentDetailsStudentsEvent(
              'Assignment Details - Close student modal',
            );
          },
          occasion,
          assignment: this.assignment,
          trackAssignmentDetailsStudentsEvent: this.trackQuestionAssignmentDetailsStudentsEvent,
        },
        {
          attachModalToTop: true,
        },
      );
    },
    increaseContainer() {
      this.isFullViewport = true;
    },
  },
};
</script>

<style scoped>
.QuestionAssignmentDetailsStudents {
  background-color: var(--kogPlatformWhite);
  border-radius: 8px;
}

.QuestionAssignmentDetailsStudents .flexTable {
  justify-content: flex-start;
}

.QuestionAssignmentDetailsStudents .flexTable__row {
  height: 32px;
}

.QuestionAssignmentDetailsStudents-studentTableContainer {
  flex: 0 0 300px;
}

.QuestionAssignmentDetailsStudents-row--background {
  margin-left: calc(var(--space-xs) * -1);
  padding-left: var(--space-xs);
  background: var(--kogPlatformGray093);
  border-radius: 4px;
}

.QuestionAssignmentDetailsStudents-name:hover {
  text-decoration: none;
}

.QuestionAssignmentDetailsStudents-classFilter {
  width: 300px;
}
</style>
