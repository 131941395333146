<template>
  <div
    ref="root"
    class="ReflectionQuestion"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="question.question_html" />
    <!-- eslint-enable vue/no-v-html -->
    <div
      class="ReflectionQuestion-answerContainer"
      :class="{ 'ReflectionQuestion-answerContainer--collapsed': isCollapsed }"
    >
      <vertical-expand>
        <div
          v-if="isCollapsed"
          class="ReflectionQuestion-submittedContainer"
        >
          <div class="flexContainer flexContainer-alignCenter flexContainer-spaceBetween">
            <p class="ReflectionQuestion-submittedContainer-title">
              <span v-if="isCompleted"> Submitted: </span>
              <span v-else-if="isStarted"> Draft: </span>
              <b>{{ reflectionBoxTitle }}</b>
            </p>
            <kog-button
              class="ReflectionQuestion-viewSubmissionButton"
              :label="collapseButtonLabel"
              button-style="basic"
              @click="toggleCollapsed()"
            />
          </div>
          <p class="ReflectionQuestion-submittedContainer-subtitle text-regular">
            {{ currentSubtopicText }}
          </p>
        </div>
      </vertical-expand>
      <kog-tag
        v-if="isCompleted"
        :is-chip="true"
        :label="submittedMessage"
        icon-class="fa-check-square"
        size="m"
        type="positive"
      />
      <kog-alert
        v-if="!isCompleted"
        class="ReflectionQuestion-warningMessage"
        mode="warning"
      >
        Once you submit your response, you won't be able to edit it.
      </kog-alert>
      <vertical-expand>
        <div v-if="isTextAreaVisible">
          <kog-textarea
            v-model:value="answer"
            :save-request$="saveAnswerRequest$"
            :is-autosave-enabled="true"
            class="ReflectionQuestion-answer margin-top-m"
            label="Question answer"
            :is-label-hidden="true"
            :textarea-style="{
              height: `200px`,
            }"
            :max-length="maxAnswerLength"
            :disabled="isTextAreaDisabled"
            @input="handleAnswerInput"
            @trigger-save="saveAnswer"
          />
          <div class="flexContainer">
            <kog-alert
              v-if="isShowingDraftSaveSuccess && !isCompleted"
              class="ReflectionQuestion-successMessage padd-top-xs padd-bottom-xs"
              mode="success"
              icon-class="fa-save"
            >
              Saved as Draft!
            </kog-alert>
            <div class="flexChild-size-1 flexContainer flexContainer-flexEnd">
              <kog-alert
                v-if="answerTextLimitWarning"
                key="answerTextLimitWarning"
                class="ReflectionQuestion-warningMessage padd-top-xs padd-bottom-xs"
                mode="warning"
              >
                {{ answerTextLimitWarning }}
              </kog-alert>
              <kog-alert
                v-else-if="answerTextLimitApproachingWarning"
                key="answerTextLimitApproachingWarning"
                class="ReflectionQuestion-warningMessage padd-top-xs padd-bottom-xs"
                mode="warning"
              >
                {{ answerTextLimitApproachingWarning }}
              </kog-alert>
              <kog-button
                label="Close"
                :disabled="!currentExerciseOccasion"
                :button-style="closeButtonType"
                @click="toggleCollapsed()"
              />
              <kog-button
                v-if="!isCompleted"
                class="margin-left-xs"
                label="Submit"
                :disabled="isSubmitDisabled"
                :is-loading="isSubmitInProgress"
                button-style="primary"
                @click="onAnswerSubmit"
              />
            </div>
          </div>
        </div>
      </vertical-expand>
    </div>
    <div
      v-if="isFeedbackVisible"
      class="ReflectionQuestion-feedbackContainer margin-top-xxl text-regular"
    >
      <h4> Feedback </h4>
      <reflection-question-feedback
        v-for="feedbackItem in feedback"
        :key="feedbackItem.id"
        :feedback-item="feedbackItem"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
import { mapActions, mapGetters, mapState } from 'vuex';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';

import ReflectionQuestionFeedback from './reflection-question-feedback-item.vue';

export default {
  name: 'ReflectionQuestion',
  components: {
    KogAlert,
    KogButton,
    KogTag,
    KogTextarea,
    VerticalExpand,
    ReflectionQuestionFeedback,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    subjectId: {
      type: Number,
      required: true,
    },
    subjectNodeId: {
      type: Number,
      required: true,
    },
    reflectionBoxTitle: {
      type: String,
      required: true,
    },
    currentSubtopicText: {
      type: String,
      required: true,
    },
    trackingProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      answer: '',
      answerTextLimitWarning: '',
      answerTextLimitApproachingWarning: '',
      maxAnswerLength: 2000,
      characterLimitWarningBuffer: 150,
      debouncedCheckCharacterLimit: debounce(this.checkCharacterLimit, 1200),
      isCollapsed: true,
      isShowingDraftSaveSuccess: false,
      isSubmitInProgress: false,
      saveAnswerRequest$: new Subject(),
    };
  },
  computed: {
    ...mapGetters('exerciseOccasionsModule', ['getExerciseOccasionByLtqId']),
    ...mapState({
      feedbackItems: state => state.feedbackItemModule.feedbackItems,
    }),
    currentExerciseOccasion() {
      return this.getExerciseOccasionByLtqId(this.question.id);
    },
    feedback() {
      return this.feedbackItems[this.user.id];
    },
    isFeedbackVisible() {
      return this.feedback && this.feedback.length > 0;
    },
    isCompleted() {
      return this.currentExerciseOccasion?.status === 'DONE';
    },
    isStarted() {
      return this.currentExerciseOccasion?.status === 'STARTED';
    },
    isTextAreaVisible() {
      return !this.isCollapsed;
    },
    isTextAreaDisabled() {
      return this.isCompleted || this.isSubmitInProgress;
    },
    submittedMessage() {
      const formattedDoneAt = dateTimeFormatter(new Date(this.currentExerciseOccasion.done_at));
      return `Submitted ${formattedDoneAt}`;
    },
    isSubmitDisabled() {
      return !this.answer || this.isCompleted;
    },
    closeButtonType() {
      return this.isCompleted ? 'primary' : 'basic';
    },
    collapseButtonLabel() {
      return this.isCompleted ? 'View submission' : 'Edit draft';
    },
  },
  watch: {
    answer() {
      if (this.answer.length === this.maxAnswerLength) {
        this.checkCharacterLimit();
      } else {
        this.debouncedCheckCharacterLimit();
      }
    },
  },
  mounted() {
    this.isCollapsed = this.isCompleted || this.isStarted;
    if (this.currentExerciseOccasion) {
      this.answer = this.currentExerciseOccasion?.practice_items[0].user_text;
    }
  },
  methods: {
    ...mapActions('exerciseOccasionsModule', [
      'createExerciseOccasion',
      'updateExerciseOccasion',
      'fetchExerciseOccasions',
    ]),
    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    },
    checkCharacterLimit() {
      if (this.answer.length === this.maxAnswerLength) {
        this.answerTextLimitWarning = 'Character limit reached';
        this.answerTextLimitApproachingWarning = '';
      } else if (this.answer.length > this.maxAnswerLength - this.characterLimitWarningBuffer) {
        this.answerTextLimitWarning = '';
        this.answerTextLimitApproachingWarning = `Less than ${this.characterLimitWarningBuffer} characters left`;
      } else {
        this.answerTextLimitWarning = '';
        this.answerTextLimitApproachingWarning = '';
      }
    },

    onAnswerSubmit() {
      this.isSubmitInProgress = true;
      this.saveAnswerRequest$.next('submit');
    },

    async saveAnswer({ source, value, saveCompleteCallback }) {
      let result;
      let operation;
      if (source === 'submit') {
        operation = 'submit';
      } else {
        operation = 'draft';
      }
      const data = {
        subjectNodeId: this.subjectNodeId,
        ltqId: this.question.id,
        userText: value,
        operation,
      };
      try {
        if (!this.currentExerciseOccasion) {
          result = await this.createExerciseOccasion(data);
        } else {
          result = await this.updateExerciseOccasion(data);
        }
      } catch {
        this.handleSaveCompleted(source, false);
        saveCompleteCallback(result);
        return;
      }

      this.handleSaveCompleted(source, true);
      saveCompleteCallback(result);
    },
    async handleSaveCompleted(source, success) {
      if (source === 'input') {
        if (success) {
          this.isShowingDraftSaveSuccess = true;
        }
      } else if (source === 'submit') {
        this.isSubmitInProgress = false;
        if (success) {
          this.isCollapsed = true;
          this.$mixpanel.trackEventViaBackend(
            'Reflection question - Submit answer',
            this.trackingProps,
          );
          await this.fetchExerciseOccasions({ subjectNodeId: this.subjectNodeId });
          if (this.user.isStudent()) {
            this.$event.track('submit_reflection_question', {
              subject_id: this.subjectId,
              subject_class_id: this.subjectClassId,
              subject_node_id: this.subjectNodeId,
              exercise_occasion_id: this.currentExerciseOccasion.id,
            });
          }
        } else {
          this.$toast.showError('Could not save answer.');
        }
      }
    },
    handleAnswerInput() {
      this.isShowingDraftSaveSuccess = false;
    },
  },
};
</script>

<style>
.ReflectionQuestion {
  font-family: var(--kog-lato) !important;
  font-size: 16px;
}

.ReflectionQuestion-answerContainer {
  transition: all 0.3s ease;
}

.ReflectionQuestion-answerContainer--collapsed {
  padding: var(--space-m) var(--space-m) var(--space-xl) var(--space-m);
  border-radius: 8px;
  box-shadow: 0 4px 4px var(--kogShadow020);
}

.ReflectionQuestion-answer {
  line-height: 26px;
}

.ReflectionQuestion-warningMessage,
.ReflectionQuestion-successMessage {
  width: auto !important;
}

.ReflectionQuestion-submittedContainer-title {
  margin: 0 !important;
  font-size: 18px;
  line-height: 28px !important;
}

.ReflectionQuestion-submittedContainer-subtitle {
  margin-bottom: var(--space-xs) !important;
  line-height: 24px !important;
}

.ReflectionQuestion-viewSubmissionButton {
  text-decoration: underline !important;
}

.ReflectionQuestion-feedbackContainer .KogTextarea-labelIcon {
  margin-right: var(--space-xs);
  padding: var(--space-xxs);

  color: var(--kogPlatformGray018);

  background-color: var(--kog-tag-informative-background-color);
  border-radius: 50%;
}

.ReflectionQuestion-feedbackContainer textarea {
  max-height: 200px;
}
</style>
