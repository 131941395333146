<template>
  <kog-modal title="Mark scheme control">
    <template #modalBody>
      <div class="KogModal__fixedHeight">
        <div class="margin-bottom-l">
          <p class="text-bold"> Allow access to the mark scheme during assignment? </p>
          <div class="flexContainer flexContainer-alignCenter">
            <input
              id="locked"
              v-model="isStatusUnlocked"
              type="radio"
              :value="false"
            />
            <label
              class="padd-left-xs margin-0"
              for="locked"
              >No</label
            >
          </div>
          <div class="flexContainer flexContainer-alignCenter">
            <input
              id="unlocked"
              v-model="isStatusUnlocked"
              type="radio"
              :value="true"
            />
            <label
              class="padd-left-xs margin-0"
              for="unlocked"
              >Yes</label
            >
          </div>
        </div>
        <div v-show="!isStatusUnlocked">
          <p class="text-bold"> Allow the mark scheme to automatically unlock? </p>
          <div class="flexContainer flexContainer-alignCenter">
            <input
              id="manual"
              v-model="autoUnlock"
              type="radio"
              value="MANUAL_UNLOCK"
            />
            <label
              class="padd-left-xs margin-0"
              for="manual"
              >No, I want to unlock the mark scheme manually from the Assignment Page</label
            >
          </div>
          <div class="flexContainer flexContainer-alignCenter">
            <input
              id="automatic"
              v-model="autoUnlock"
              type="radio"
              value="AUTO_UNLOCK"
            />
            <label
              class="padd-left-xs margin-0"
              for="automatic"
              >Yes, I want the mark scheme to unlock once the deadline passes</label
            >
          </div>
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-button
          label="Cancel"
          @click="closeModal"
        />
        <kog-button
          label="Update"
          button-style="primary"
          @click="updateLockStatus(markschemeStatus)"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'SelectMarkschemeLockStatusModal',
  components: {
    KogModal,
    KogButton,
  },
  props: {
    markschemeStatus: {
      type: String,
      required: true,
    },
    updateMarkschemeStatus: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isStatusUnlocked: this.markschemeStatus === 'UNLOCKED',
      autoUnlock: this.markschemeStatus === 'UNLOCKED' ? 'MANUAL_UNLOCK' : this.markschemeStatus,
    };
  },
  methods: {
    updateLockStatus() {
      this.closeModal();
      const lockStatus = this.isStatusUnlocked ? 'UNLOCKED' : this.autoUnlock;
      this.updateMarkschemeStatus(lockStatus);
    },
  },
};
</script>

<style scoped>
.AssignmentSelectRecipientModal__listRow:hover {
  text-decoration: underline;
}

.KogModal__fixedHeight {
  min-height: 170px;
}

input[type='radio'] {
  margin: 0;
}

label {
  font-weight: normal;
}
</style>
