import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const schoolstaffApiBaseUrl = '/api/schoolstaff/';
const readingAssignmentBaseUrl = `${schoolstaffApiBaseUrl}reading_assignments/:assignmentId/`;
const assignmentsBaseUrl = `${schoolstaffApiBaseUrl}assignments/`;
const assignmentDetailBaseUrl = `${assignmentsBaseUrl}:assignmentId/`;

export const SCHOOLSTAFF_API_URLS = {
  'reading-assignment-student-progress': compile(`${readingAssignmentBaseUrl}student_progress/`),
  'reading-assignment-subjectnode': compile(
    `${readingAssignmentBaseUrl}subject_node/:subjectNodeId/`,
  ),
  'reading-assignment-subjectnode-list': compile(`${readingAssignmentBaseUrl}subject_node_list/`),
  'assignment-list': assignmentsBaseUrl,
  'assignment-create': compile(
    `${schoolstaffApiBaseUrl}subject_class/:subjectClassId/assignment_create/`,
  ),
  'assignment-details': compile(`${assignmentsBaseUrl}:assignmentId/`),
  'assignment-details-schedule': compile(`${assignmentDetailBaseUrl}schedule/`),
  'assignment-details-send': compile(`${assignmentDetailBaseUrl}send/`),
  'assignment-copy': compile(`${assignmentDetailBaseUrl}copy/`),
  'assignment-copy-targets': compile(`${assignmentDetailBaseUrl}copy/targets/`),
  'assignments-subject-questions': compile(`${assignmentsBaseUrl}subjects/:subjectId/questions/`),
  'assignment-end-now': compile(`${assignmentDetailBaseUrl}end/`),
  'assignment-markscheme-unlock': compile(`${assignmentDetailBaseUrl}markscheme_unlock/`),
  'assignment-archive': compile(`${assignmentDetailBaseUrl}archive/`),
  'assignment-student-status': compile(`${assignmentDetailBaseUrl}student-status/`),
  'assignment-question': compile(`${assignmentDetailBaseUrl}question/:questionUuid/`),
  'assignment-questions': compile(`${assignmentDetailBaseUrl}questions/`),
  'subjectclass-assignments-result': compile(
    `${schoolstaffApiBaseUrl}subject_class/:subjectClassId/assignments_result/`,
  ),
};

export async function fetchReadingAssignmentStudentProgress(assignmentId) {
  const url = SCHOOLSTAFF_API_URLS['reading-assignment-student-progress']({ assignmentId });
  const response = await axios.get(url);
  return response.data;
}

export async function addNodeToReadingAssignment(assignmentId, subjectNodeId, order) {
  const url = SCHOOLSTAFF_API_URLS['reading-assignment-subjectnode']({
    assignmentId,
    subjectNodeId,
  });
  const response = await axios.post(url, { order });
  return response.data;
}

export async function removeNodeFromReadingAssignment(assignmentId, subjectNodeId) {
  const url = SCHOOLSTAFF_API_URLS['reading-assignment-subjectnode']({
    assignmentId,
    subjectNodeId,
  });
  const response = await axios.delete(url);
  return response.data;
}

export async function updateNodeOrderInReadingAssignment(assignmentId, subjectNodeId, order) {
  const url = SCHOOLSTAFF_API_URLS['reading-assignment-subjectnode']({
    assignmentId,
    subjectNodeId,
  });
  const response = await axios.put(url, { order });
  return response.data;
}

export async function bulkAddNodesToReadingAssignment(assignmentId, data) {
  const url = SCHOOLSTAFF_API_URLS['reading-assignment-subjectnode-list']({ assignmentId });
  const response = await axios.post(url, data);
  return response.data;
}

export async function bulkRemoveNodesFromReadingAssignment(assignmentId, data) {
  const url = SCHOOLSTAFF_API_URLS['reading-assignment-subjectnode-list']({ assignmentId });
  const response = await axios.delete(url, { data });
  return response.data;
}

export async function getAssignmentList(params) {
  const url = SCHOOLSTAFF_API_URLS['assignment-list'];
  const response = await axios.get(url, { params });
  return response.data;
}

function getLastUsedDeadlineHourMinute(userId, subjectClassId) {
  return {
    deadline_hour:
      localStorage.getItem(`deadline_preferred_hour-${userId}-${subjectClassId}`) || '9',
    deadline_minute:
      localStorage.getItem(`deadline_preferred_minute-${userId}-${subjectClassId}`) || '0',
  };
}

export async function createAssignment({
  subjectClassId,
  name,
  assignmentType,
  user,
  subjectnodes,
  sendToIntegration = false,
  isMultiClass = false,
}) {
  const url = SCHOOLSTAFF_API_URLS['assignment-create']({ subjectClassId });
  const payload = {
    name,
    assignment_type: assignmentType,
    ...getLastUsedDeadlineHourMinute(user.id, subjectClassId),
    ...(subjectnodes && { subjectnodes }),
    ...{ send_to_integration: sendToIntegration },
  };
  const config = {
    params: { is_multi_class: isMultiClass },
  };
  const response = await axios.post(url, payload, config);

  return response.data;
}

export async function deleteAssignment(assignmentId) {
  const url = SCHOOLSTAFF_API_URLS['assignment-details']({ assignmentId });
  const response = await axios.delete(url);
  return response.data;
}

export async function updateAssignmentSchedule(assignmentId, data) {
  const url = SCHOOLSTAFF_API_URLS['assignment-details-schedule']({ assignmentId });
  const response = await axios.put(url, data);
  return response.data;
}

export async function sendAssignment(assignmentId, data) {
  const url = SCHOOLSTAFF_API_URLS['assignment-details-send']({ assignmentId });
  const response = await axios.post(url, data);
  return response.data;
}

export async function copyAssignment(assignmentId, targetSubjectClassId, assignmentName) {
  const requestData = {
    target_subject_class_id: targetSubjectClassId,
    name_of_copy: assignmentName,
  };
  const url = SCHOOLSTAFF_API_URLS['assignment-copy']({ assignmentId });
  const response = await axios.post(url, requestData);
  return response.data;
}

export async function fetchAssignmentCopyTargets(assignmentId) {
  const url = SCHOOLSTAFF_API_URLS['assignment-copy-targets']({ assignmentId });
  const response = await axios.get(url);
  return response.data;
}

export async function fetchAssignmentSubjectQuestions(subjectId, params = {}) {
  const url = SCHOOLSTAFF_API_URLS['assignments-subject-questions']({ subjectId });
  const newParams = { page_size: 50, ...params };
  const response = await axios.get(url, { params: newParams });
  return response.data;
}

export async function endAssignmentNow(assignmentId) {
  const url = SCHOOLSTAFF_API_URLS['assignment-end-now']({ assignmentId });
  await axios.put(url);
}

export async function unlockAssignmentMarkscheme(assignmentId) {
  const url = SCHOOLSTAFF_API_URLS['assignment-markscheme-unlock']({ assignmentId });
  await axios.patch(url);
}

export async function archiveAssignment(assignmentId) {
  const url = SCHOOLSTAFF_API_URLS['assignment-archive']({ assignmentId });
  await axios.put(url);
}

export async function unArchiveAssignment(assignmentId) {
  const url = SCHOOLSTAFF_API_URLS['assignment-archive']({ assignmentId });
  await axios.delete(url);
}

export async function fetchAssignmentStudentStatus(assignmentId) {
  const url = SCHOOLSTAFF_API_URLS['assignment-student-status']({ assignmentId });
  const response = await axios.get(url);
  return response.data;
}

export async function addAssignmentQuestion(assignmentId, questionUuid, order) {
  const url = SCHOOLSTAFF_API_URLS['assignment-question']({ assignmentId, questionUuid });
  const response = await axios.post(url, { order });
  return response.data;
}

export async function removeAssignmentQuestion(assignmentId, questionUuid) {
  const url = SCHOOLSTAFF_API_URLS['assignment-question']({ assignmentId, questionUuid });
  const response = await axios.delete(url);
  return response.data;
}

export async function updateAssignmentQuestionOrder(assignmentId, questionUuid, order) {
  const url = SCHOOLSTAFF_API_URLS['assignment-question']({ assignmentId, questionUuid });
  const response = await axios.put(url, { order });
  return response.data;
}

export async function addAssignmentQuestions(assignmentId, data) {
  const url = SCHOOLSTAFF_API_URLS['assignment-questions']({ assignmentId });
  const response = await axios.post(url, data);
  return response.data;
}

export async function removeAssignmentQuestions(assignmentId, data) {
  const url = SCHOOLSTAFF_API_URLS['assignment-questions']({ assignmentId });
  const response = await axios.delete(url, { data });
  return response.data;
}
