<template>
  <div class="StrengthCard">
    <h2 class="StrengthCard-title heading-s padd-l">
      <span
        class="StrengthCard-countText"
        :class="{
          'StrengthCard-countText--positive': paginationData.totalItems > 0,
        }"
      >
        {{ paginationData.totalItems }}
      </span>
      {{ pluralize(strengthItemTerm, paginationData.totalItems) }} completed
    </h2>
    <div class="padd-l">
      <kog-table
        :footer-text="tablePaginationText"
        :pagination-current-page="currentPage"
        :pagination-number-of-pages="pageCount"
        :update-current-page="goToTablePage"
        :is-loading-table-data="isTableDataLoading"
        loading-table-data-message="Loading..."
      >
        <template #header>
          <slot name="tableHeader" />
        </template>
        <template #body>
          <slot name="tableBody" />
        </template>
        <template
          v-if="strengthItems.length === 0"
          #info
        >
          <span class="padd-xs"> No {{ pluralize(strengthItemTerm) }} completed yet. </span>
        </template>
      </kog-table>
    </div>
  </div>
</template>

<script>
import pluralize from 'pluralize';

import KogTable from 'sharedApp/components/tables/kog-table.vue';

export default {
  name: 'StrengthCard',
  components: {
    KogTable,
  },
  props: {
    strengthItems: {
      type: Array,
      default: () => [],
    },
    strengthItemTerm: {
      type: String,
      required: true,
    },
    paginationData: {
      type: Object,
      default: () => {},
    },
    isTableDataLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['go-to-table-page'],
  computed: {
    currentPage() {
      return this.paginationData?.currentPage;
    },
    pageCount() {
      if (!this.paginationData) {
        return 0;
      }

      const { pageSize, totalItems } = this.paginationData;
      return Math.ceil(totalItems / pageSize);
    },
    tablePaginationText() {
      if (this.strengthItems.length === 0) {
        return '';
      }

      const { pageSize, totalItems, currentPage } = this.paginationData;
      const firstItemNumber = pageSize * currentPage - pageSize + 1;
      const lastItemNumber = Math.min(pageSize * currentPage, totalItems);

      return `${firstItemNumber} - ${lastItemNumber} of ${totalItems}`;
    },
  },
  methods: {
    pluralize,
    goToTablePage(page) {
      this.$emit('go-to-table-page', page);
    },
  },
};
</script>

<style scoped>
.StrengthCard {
  background-color: var(--kog-background-default-100);
}

.StrengthCard-title {
  border-bottom: 1px solid var(--kog-text-default);
}

.StrengthCard-countText {
  font-size: 64px;
  line-height: 64px;
  color: var(--kog-text-muted);
}

.StrengthCard-countText--positive {
  color: var(--kogPlatformGreenBase);
}
</style>
