<template>
  <div class="BookRailNotebookAnnotation">
    <div
      class="BookRailNotebookAnnotation-annotationHeader flexContainer flexContainer-alignCenter flexContainer-spaceBetween"
    >
      <div class="text-small margin-bottom-xs">
        {{ date(annotationEntity.annotation.lastModified) }}
      </div>
      <button
        v-if="!annotationEntity.isEditing && !isSaving"
        :key="`${annotationEntity.annotation.id}-edit`"
        class="BookRailNotebookAnnotation-actionButton"
        :area-label="`Edit note with id ${annotationEntity.annotation.id}`"
        @click="$emit('edit')"
      >
        <kog-font-awesome-icon
          fa-class="fa-pencil"
          fa-style="regular"
          size="custom"
          :custom-size="14"
        />
      </button>
      <button
        v-if="annotationEntity.isEditing"
        :key="`${annotationEntity.annotation.id}-cancelEdit`"
        class="BookRailNotebookAnnotation-actionButton BookRailNotebookAnnotation-actionButton--visible"
        :disabled="isSaving"
        :area-label="`Cancel editing of note with id ${annotationEntity.annotation.id}`"
        @click="$emit('cancel')"
      >
        <kog-font-awesome-icon
          fa-class="fa-close"
          fa-style="regular"
          size="custom"
          :custom-size="14"
        />
      </button>
      <button
        :key="`${annotationEntity.annotation.id}-scrollTo`"
        class="BookRailNotebookAnnotation-actionButton"
        :class="{ 'BookRailNotebookAnnotation-actionButton--visible': annotationEntity.isEditing }"
        :area-label="`scroll to note with id ${annotationEntity.annotation.id}`"
        @click="$emit('scrollTo')"
      >
        <kog-font-awesome-icon
          fa-class="fa-solid fa-arrow-right"
          fa-style="regular"
          size="custom"
          :custom-size="14"
        />
      </button>
    </div>

    <span
      class="BookRailNotebookAnnotation-actionButtonContent font-content-main heading-xs font-weight-normal padd-left-xxs padd-right-xxs"
      :class="`KogHighlight-${annotationEntity.annotation.color}${hover ? '--active' : ''}`"
      @click="$emit('scrollTo')"
      @keyDown="$emit('scrollTo')"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @focusin="hover = true"
      @focusout="hover = false"
    >
      {{ annotationEntity.annotation.exact }}
    </span>

    <vertical-expand>
      <div
        v-if="annotationEntity.annotation.note && !annotationEntity.isEditing"
        class="text-regular break-word margin-top-xs"
      >
        {{ annotationEntity.annotation.note }}
      </div>
      <kog-textarea
        v-else-if="annotationEntity.isEditing"
        class="margin-top-xs"
        label="Edit highlight note"
        :is-label-hidden="true"
        :value="annotationEntity.annotation.note"
        :disabled="isSaving"
        :max-length="noteMaxLength"
        resize="none"
        rows="4"
        @input="value => $emit('input', value)"
      />
    </vertical-expand>

    <div
      v-if="annotationEntity.isEditing"
      class="flexContainer flexContainer-alignCenter margin-top-xs"
    >
      <color-selector
        class="BookRailNotebookAnnotation-colorSection"
        :color-model="color"
        @change="selectedColor => $emit('change-color', selectedColor)"
      />

      <kog-button
        class="u-alterKogButton margin-left-auto margin-right-xxs"
        label="Delete"
        size="small"
        button-style="basic"
        :disabled="isSaving"
        @click="$emit('delete', annotationEntity.annotation.id)"
      />
      <kog-button
        class="u-alterKogButton"
        :label="isSaving ? 'Saving' : 'Save'"
        size="small"
        button-style="primary"
        :disabled="isSaving"
        @click="$emit('save')"
      />
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format/index.js';

import ColorSelector from 'learning/common/components/color-selector.vue';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';

const ANNOTATION_MAX_LEN = 1000;

export default {
  name: 'BookRailNotebookAnnotation',
  components: {
    KogFontAwesomeIcon,
    KogButton,
    KogTextarea,
    ColorSelector,
    VerticalExpand,
  },
  props: {
    annotationEntity: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input', 'change-color', 'delete', 'save', 'edit', 'cancel', 'scrollTo'],
  data() {
    return {
      hover: false,
      noteMaxLength: ANNOTATION_MAX_LEN,
    };
  },
  computed: {
    color() {
      return this.annotationEntity.annotation.color;
    },
  },
  methods: {
    date(value) {
      return value ? format(new Date(value), 'MMMM Do YYYY') : '';
    },
  },
};
</script>

<style scoped>
.BookRailNotebookAnnotation-actionButtonContent {
  cursor: pointer;
}

.BookRailNotebookAnnotation {
  position: relative;
}

.BookRailNotebookAnnotation-annotationHeader :first-child {
  margin-right: auto;
}

.BookRailNotebookAnnotation-actionButton {
  display: none;

  width: 24px;
  height: 24px;

  background-color: var(--kogTransparent);
  border: 0;
  border-radius: 50%;
}

.BookRailNotebookAnnotation-actionButton:hover {
  background-color: var(--kogPlatformGray093);
}

.BookRailNotebookAnnotation:hover .BookRailNotebookAnnotation-actionButton,
.BookRailNotebookAnnotation-actionButton--visible {
  display: block;
}

.BookRailNotebookAnnotation-colorSection :deep(.ColorSelector-container) {
  margin-right: var(--space-xs);
}

.u-alterKogButton {
  min-width: 50px;
  padding-right: var(--space-s);
  padding-left: var(--space-s);
}
</style>
