<template>
  <kog-modal
    title="Choose a subject"
    :close-modal="closeModal"
  >
    <template #modalBody>
      <ol
        class="padd-1 SelectSubjectModal-subjectList"
        aria-label="subjects"
      >
        <li
          v-for="(subjectClass, index) in subjectClasses"
          :key="index"
        >
          <a
            class="SelectSubjectModal-subjectCard padd-fine-1 flexContainer flexContainer-alignCenter cursorPointer"
            :href="getStrengthTestUrl(subjectClass)"
          >
            <image-box
              :src="getSubjectLogoUrl(subjectClass)"
              :w="70"
              :h="50"
              :alt="subjectClass.name"
              fit="clip"
              class="SelectSubjectModal-subjectLogo"
            />
            <span class="text-bold margin-left-fine-1">{{ subjectClass.name }}</span>
            <i class="fas fa-chevron-right SelectSubjectModal-arrowIcon margin-right-fine-2" />
          </a>
        </li>
      </ol>
    </template>
  </kog-modal>
</template>

<script>
import ImageBox from 'sharedApp/components/images/image-box.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'SelectSubjectModal',
  components: {
    KogModal,
    ImageBox,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    subjectsById: {
      type: Object,
      required: true,
    },
    subjectClasses: {
      type: Array,
      required: true,
    },
    getStrengthTestUrl: {
      type: Function,
      required: true,
    },
  },
  methods: {
    getSubjectLogoUrl(subjectClass) {
      return this.subjectsById[subjectClass.subject_id].logo_url;
    },
  },
};
</script>

<style scoped>
.SelectSubjectModal-subjectList {
  list-style: none;
}

.SelectSubjectModal-subjectCard {
  width: 500px;
  margin: 8px auto;

  text-decoration: none;

  border: 1px solid var(--kogPlatformGray084);
  border-radius: 3px;
}

.SelectSubjectModal-subjectCard:hover,
.SelectSubjectModal-subjectCard:focus {
  background-color: var(--kogPlatformGray096);
}

.SelectSubjectModal-subjectLogo {
  align-self: center;
  width: 70px;
}

.SelectSubjectModal-arrowIcon {
  margin-left: auto;
  color: var(--kog-text-brand);
}
</style>
