<template>
  <div>
    <kog-font-awesome-icon
      v-if="isCompleted"
      fa-class="fa-check-circle"
      size="custom"
      :custom-size="24"
      class="SectionProgressIndicator-icon--completed"
      aria-label="Completed section"
    />
    <kog-font-awesome-icon
      v-else
      fa-class="fa-circle"
      size="custom"
      :custom-size="12"
      class="SectionProgressIndicator-icon--notCompleted"
      aria-label="Incomplete section"
    />
  </div>
</template>

<script>
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';

export default {
  name: 'SectionProgressIndicator',
  components: { KogFontAwesomeIcon },
  props: {
    isCompleted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.SectionProgressIndicator-icon--completed {
  color: var(--kog-progressbar-single-primary-color);
}

.SectionProgressIndicator-icon--notCompleted {
  color: var(--kog-progressbar-single-primary-background);
}
</style>
