import { mapGetters, mapState } from 'vuex';

import { FEATURES_ENUM } from 'sharedApp/const/features.js';
import { isReadingType } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  trackActivitySheetAssignment,
  trackSendAssignment,
} from 'teachApp/services/tracking/assignment-tracking.js';

const sendAssignmentTrackingMixin = {
  computed: {
    ...mapState({
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
    }),
    ...mapGetters('subjectClassModule', ['isNodeVisible']),
    ...mapGetters('subjectNodeFeatureModule', ['featuresById', 'getActivityBySubjectNodeId']),
  },
  methods: {
    getContentTypesInAssignment(sections) {
      return sections.map(section => this.getNodeContentType(section.subject_node_id));
    },
    getNodeContentType(nodeId) {
      const activity = this.getActivityBySubjectNodeId(nodeId);
      return activity ? activity.type : 'Section';
    },
    getHasHiddenSections(sections) {
      return sections.some(section => !this.isNodeVisible(section.subject_node_id));
    },
    filterSelectedItemsByFeatureType(sections, featureType) {
      return sections.filter(
        section => this.getNodeContentType(section.subject_node_id) === featureType,
      );
    },
    performTracking({ eventName, assignmentData, googleClasses, extraMixpanelProps = {} }) {
      const {
        sections,
        subject_id: subjectId,
        subject_class_id: subjectClassId,
        id: assignmentId,
        assignment_type: assignmentType,
      } = assignmentData;
      this.$event.track(eventName, {
        subject_id: subjectId,
        subject_class_id: subjectClassId,
        assignment_id: assignmentId,
      });

      trackSendAssignment(
        assignmentData,
        googleClasses,
        this.getContentTypesInAssignment(sections),
        {
          ...extraMixpanelProps,
        },
      );

      if (isReadingType(assignmentType) && this.getHasHiddenSections(sections)) {
        this.$mixpanel.trackEvent('Hide / Show content: Nodes shown by sent reading assignment');
      }

      const activitySheetItems = this.filterSelectedItemsByFeatureType(
        sections,
        FEATURES_ENUM.ACTIVITY_SHEET,
      );
      activitySheetItems.forEach(activitySheetNode => {
        trackActivitySheetAssignment({
          assignment: assignmentData,
          activitySheetNode,
        });
      });
    },
  },
};

export default sendAssignmentTrackingMixin;
