<template>
  <kog-round-button
    v-if="isEditingVisibility"
    class="SubjectNodeVisibilityToggle"
    aria-label="Toggle this content"
    :icon-class="isHidden ? 'fa-eye-slash' : 'fa-eye'"
    icon-style="regular"
    size="x-small"
    :activated="activated"
    @click.stop.prevent="toggleVisibility(subjectNode)"
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import useToggleNodeVisibilityEventHandler from 'studyApp/composables/use-toggle-node-visibility-event-handler.js';

export default {
  name: 'SubjectNodeVisibilityToggle',
  components: { KogRoundButton },
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
    activated: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { handleToggleNodeVisibilityEvent } = useToggleNodeVisibilityEventHandler();

    return {
      handleToggleNodeVisibilityEvent,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      hiddenSubjectNodes: state => state.subjectClassModule.hiddenSubjectNodes,
      isEditingVisibility: state => state.subjectClassModule.isEditingVisibility,
      visibilityEditingTransactionToken: state =>
        state.subjectClassModule.visibilityEditingTransactionToken,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      isNodeHiddenWhileEditing: 'subjectClassModule/isNodeHiddenWhileEditing',
    }),
    isHidden() {
      return this.isNodeHiddenWhileEditing(this.subjectNode);
    },
    subjectNode() {
      return this.subjectNodesById[this.subjectNodeId];
    },
  },
  methods: {
    ...mapActions({
      toggleNodeVisibility: 'subjectClassModule/toggleNodeVisibility',
    }),
    toggleVisibility(subjectNode) {
      this.handleToggleNodeVisibilityEvent(subjectNode);
    },
  },
};
</script>

<style scoped>
.SubjectNodeVisibilityToggle {
  color: var(--kog-colors-grey-900);
  background-color: var(--kog-colors-grey-400);
}
</style>
