import { mapState as mapPiniaState } from 'pinia';
import { mapActions, mapGetters, mapState } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import { getLeafNodes, getSubjectTreeObject } from 'sharedApp/libs/subject-tree-functions.js';
import { isReadingType } from 'sharedApp/services/assignment/assignment-utility-service.js';
import ReadingAssignmentCompletedModal from 'studyApp/components/section-text/reading-assignment-completed-modal.vue';
import ReadingAssignmentFocusCompletionModal from 'studyApp/components/section-text/reading-assignment-focus-completion-modal.vue';

const ReadingAssignmentMixin = {
  computed: {
    ...mapState({
      ongoingReadingAssignmentOccasions: state =>
        state.bookModule.ongoingReadingAssignmentOccasions,
      subject: state => state.subjectModule.subject,
      studentAssignment: state => state.assignmentModule.assignment,
    }),
    ...mapPiniaState(useAssignmentStore, { teacherAssignment: 'assignment' }),
    ...mapGetters({
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
    }),
    isInReadingAssignment() {
      return this.ongoingReadingAssignmentOccasions.length > 0;
    },
    leafNodesWithContent() {
      if (!this.subject.subject_tree) return [];

      const subjectTree = this.subject.subject_tree[0];
      return getLeafNodes(subjectTree).filter(
        leafNode => !!leafNode.section_id || !!this.activityBySubjectNodeId[leafNode.id],
      );
    },
    classSlug() {
      return this.$route.params.classSlug;
    },
    subjectId() {
      return this.$route.params.sid;
    },
    classId() {
      return this.$route.params.cid;
    },
    isAssignmentFocus() {
      if (
        !isReadingType(this.teacherAssignment?.assignment_type) &&
        !isReadingType(this.studentAssignment?.assignment_type)
      ) {
        return false;
      }
      return (
        this.$route.name === 'classReadingAssignmentTake' ||
        this.$route.name === 'teacherAssignmentDetails'
      );
    },
  },
  methods: {
    ...mapActions({
      fetchReadingAssignmentForSection: 'bookModule/fetchReadingAssignmentForSection',
    }),
    fetchReadingAssignmentForSubjectNode(nodeId) {
      this.fetchReadingAssignmentForSection(nodeId);
    },
    showReadingAssignmentModal(nodeId) {
      const assignmentOccasion = this.ongoingReadingAssignmentOccasions[0];
      const { sectionMap, subsectionMap } = getSubjectTreeObject(this.subject.subject_tree);
      const assignmentSections = assignmentOccasion.readingitem_set
        .map(el => el.node_progress)
        .map(np => ({
          completed: np.subjectnode_id === nodeId ? true : np.completed,
          details: sectionMap[np.subjectnode_id] || subsectionMap[np.subjectnode_id],
        }));
      if (this.isAssignmentFocus) {
        const isAllDone = assignmentSections.every(item => item.completed);
        if (isAllDone) {
          this.$modal(ReadingAssignmentFocusCompletionModal, {});
        }
      } else {
        const currentLeafNodeIndex = this.leafNodesWithContent.findIndex(
          leafNode => leafNode.id === nodeId,
        );
        const nextLeafNode = this.leafNodesWithContent[currentLeafNodeIndex + 1] ?? null;
        const buildNextPageRoute = leafNodeData => {
          if (!leafNodeData) return null;

          return {
            name: 'classBook',
            params: {
              classSlug: this.classSlug,
              sid: this.subjectId,
              cid: this.classId,
              nodeSlug: leafNodeData.slug,
              nodeId: leafNodeData.id,
            },
          };
        };
        this.$modal(ReadingAssignmentCompletedModal, {
          nextPageRoute: buildNextPageRoute(nextLeafNode),
          assignmentOccasion,
          assignmentSections,
        });
      }
    },
  },
};

export default ReadingAssignmentMixin;
