<template>
  <div class="AssignmentReviewBox">
    <div v-show="isContentVisible && !hasErrors">
      <div
        v-if="isReadingAssignment && hasHiddenSections"
        class="padd-bottom-m"
      >
        <hidden-content-warning />
      </div>
      <assignment-sendout-recipients
        :student-list-by-class-id="studentListByClassId"
        :selected-student-ids="selectedStudentIds"
        :subject-class-ids="assignmentData.subject_classes"
        :google-classes="connectedGoogleClasses"
        :google-classroom-recipients="googleClassRoomRecipientsByUpstreamClassId"
      />
      <integration-assignment-status-tag
        v-if="assignmentData.send_to_integration"
        :integration-provider="integrationProvider"
      />
      <div v-if="isExamStyleAssignment">
        <esq-markscheme-setting-info :mark-scheme-status="assignmentData.mark_scheme_status" />
      </div>

      <div v-if="isQuestionAssignment">
        <ul class="AssignmentReviewBox-questionAssignmentInfo padd-left-s">
          <li>{{ lateSubmission }}</li>
          <li>{{ displayAnswersSetting }}</li>
        </ul>
      </div>
      <div class="AssignmentReviewBox-previewBox padd-top-xxs">
        <div class="AssignmentReviewBox-previewBoxHeader padd-bottom-s">
          The email message your students receive includes:
        </div>

        <!--Preview box-->
        <div class="AssignmentReviewBox-previewBoxInfo padd-s">
          <p class="margin-bottom-0">
            <strong>Assignment name:</strong>
          </p>
          <p>{{ assignmentData.name }}</p>

          <p class="margin-bottom-0">
            <strong>Deadline:</strong>
          </p>
          <p>{{ getReadableDeadline(assignmentData.deadline) }}</p>

          <template v-if="assignmentData.send_to_integration">
            <template v-if="category">
              <p class="margin-bottom-0">
                <strong>Assignment category:</strong>
              </p>
              <p>{{ category }}</p>
            </template>
            <p class="margin-bottom-0">
              <strong>Points:</strong>
            </p>
            <p>{{ assignmentData.integration_settings.points_possible }}</p>
          </template>
          <div v-if="isQuestionAssignment || isExamStyleAssignment">
            <p class="margin-bottom-0">
              <strong>The task:</strong>
            </p>
            <p>{{ selectedQuestionsToCompleteText }}</p>
          </div>

          <div v-if="isReadingAssignment">
            <p class="margin-bottom-0">
              <strong>The task:</strong>
            </p>
            <p>{{ selectedSectionsToCompleteText }}</p>
          </div>

          <div>
            <p class="margin-bottom-0">
              <strong>Instructions:</strong>
            </p>
            <p v-if="assignmentData.message">
              {{ assignmentData.message }}
            </p>
            <p
              v-else
              class="text-italic"
            >
              none
            </p>
          </div>
        </div>
        <!--/Preview box-->

        <div
          v-if="isExamStyleAssignment"
          class="AssignmentReviewBox-previewBoxReminder padd-top-m"
        >
          <span class="text-bold kogPlatformRedDarken20">Reminder:</span>
          Your students will not return this assignment through Kognity, and the responses will not
          affect your student's or class's statistics.
        </div>
      </div>
    </div>

    <div v-if="isContentVisible && hasErrors">
      <p>
        An error occurred while sending your assignment. Please try again. The assignment has been
        saved so you can send it out later.
      </p>
    </div>

    <div
      v-if="updateFailed() && !isSaving()"
      class="text-center"
    >
      <div class="kogPlatformRedDarken20 heading-s margin-m flexContainer flexContainer-center">
        <kog-icon
          icon-class="fa-exclamation-triangle"
          theme="custom"
          fa-style="regular"
          size="l"
          class="margin-right-m"
        />
        Some details failed to save
      </div>
      <div class="muted"> Refresh this page to try again </div>
      <div class="muted margin-bottom-l">
        Don't worry, your selected
        {{ isReadingAssignment ? 'sections' : 'questions' }} are saved as a draft
      </div>
    </div>

    <div v-if="!isContentVisible">
      <kog-loader
        :loading="sending || isSaving()"
        :loading-msg="loadingMsg"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useAssignmentReadableSettings from 'sharedApp/composables/use-assignment-readable-settings.ts';
import {
  isExamStyleType,
  isQuestionType,
  isReadingType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import HiddenContentWarning from 'studyApp/components/teacher/assignment-create/hidden-content-warning.vue';
import EsqMarkschemeSettingInfo from 'studyApp/components/teacher/assignments/esq-markscheme-setting-info.vue';
import IntegrationAssignmentStatusTag from 'studyApp/components/teacher/integration-assignment-status-tag.vue';
import useLmsIntegrationCategories from 'studyApp/composables/use-lms-integration-categories.js';
import { DISPLAY_ANSWERS_SETTING_OPTIONS } from 'studyApp/constants/teacher-assignment-constants.ts';

import AssignmentSendoutRecipients from './assignment-sendout-recipients.vue';

export default {
  name: 'AssignmentReviewBox',
  components: {
    HiddenContentWarning,
    KogLoader,
    EsqMarkschemeSettingInfo,
    KogIcon,
    AssignmentSendoutRecipients,
    IntegrationAssignmentStatusTag,
  },
  props: {
    assignmentData: {
      type: Object,
      required: true,
    },
    studentListByClassId: {
      type: Object,
      required: true,
    },
    hasErrors: {
      type: Boolean,
      required: true,
    },
    sending: {
      type: Boolean,
      required: true,
    },
    isSaving: {
      type: Function,
      required: true,
    },
    updateFailed: {
      type: Function,
      required: true,
    },
    hasHiddenSections: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { lmsIntegrationCategories } = useLmsIntegrationCategories();

    return {
      lmsIntegrationCategories,
    };
  },
  computed: {
    ...mapState({
      subjectClassDetails: state => state.schoolstaffSubjectclassModule.subjectClass,
    }),
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    selectedStudentIds() {
      return this.assignmentData.selected_student_ids.map(studentId => Number(studentId));
    },
    loadingMsg() {
      if (this.isSaving()) {
        return "Just a sec, we're still saving some details ...";
      }
      return 'Sending';
    },
    isContentVisible() {
      return !(this.sending || this.isSaving()) && !this.updateFailed();
    },
    selectedQuestionsToCompleteText() {
      let totalQuestions = 0;
      if (this.assignmentData.questions) {
        totalQuestions = this.assignmentData.questions.length;
      }
      const noun = totalQuestions === 1 ? 'question' : 'questions';
      return `${totalQuestions} ${noun} to complete`;
    },
    selectedSectionsToCompleteText() {
      let totalSections = 0;
      if (this.assignmentData.section_node_ids) {
        totalSections = this.assignmentData.section_node_ids.length;
      }
      const noun = totalSections === 1 ? 'section' : 'sections';
      return `${totalSections} ${noun} to complete`;
    },
    lateSubmission() {
      const { deadline } = useAssignmentReadableSettings(this.assignmentData);
      return deadline.allowLateSubmission
        ? 'Late submissions allowed'
        : 'Late submissions not allowed';
    },

    displayAnswersSetting() {
      return DISPLAY_ANSWERS_SETTING_OPTIONS.find(
        option => option.value === this.assignmentData.display_answers_setting,
      )?.text;
    },
    isQuestionAssignment() {
      return isQuestionType(this.assignmentData.assignment_type);
    },
    isReadingAssignment() {
      return isReadingType(this.assignmentData.assignment_type);
    },
    isExamStyleAssignment() {
      return isExamStyleType(this.assignmentData.assignment_type);
    },
    integrationProvider() {
      return this.subjectClassDetails.integration_providers.length > 0
        ? this.subjectClassDetails.integration_providers[0]
        : '';
    },
    category() {
      return this.lmsIntegrationCategories?.[this.subjectClassDetails.id]?.find(
        category => category.id === this.assignmentData.integration_settings.category_id,
      )?.title;
    },
    assignmentSubjectClasses() {
      if (!this.assignmentData) return [];
      return this.assignmentData.subject_classes.map(classId => this.subjectClassesById[classId]);
    },
    connectedGoogleClasses() {
      return this.assignmentSubjectClasses.flatMap(subjectClass => subjectClass.google_classes);
    },
    googleClassRoomRecipientsByUpstreamClassId() {
      return this.assignmentData.google_classroom_recipients.reduce((acc, upstreamClass) => {
        acc[upstreamClass.upstream_class_id] = upstreamClass.recipient_upstream_ids;
        return acc;
      }, {});
    },
  },
  methods: {
    getReadableDeadline(strDate) {
      const date = new Date(strDate);
      return `${date.toLocaleDateString()} at ${`0${date.getHours()}`.slice(
        -2,
      )}:${`0${date.getMinutes()}`.slice(-2)}`;
    },
  },
};
</script>

<style scoped>
.AssignmentReviewBox {
  width: 450px;
  margin: auto;
}

.AssignmentReviewBox-previewBox {
  text-align: center;
}

.AssignmentReviewBox-questionAssignmentInfo {
  line-height: var(--space-l);
}

.AssignmentReviewBox-previewBoxHeader {
  font-size: 16px;
}

.AssignmentReviewBox-previewBoxInfo {
  background-color: var(--kogPlatformGray096);
  border-radius: 3px;
}

.AssignmentReviewBox-previewBoxReminder {
  width: 450px;
  margin: auto;
  text-align: left;
}
</style>
