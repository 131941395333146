<template>
  <dropdown
    theme="kog-popover"
    :shown="true"
    placement="bottom"
    class="DictDefinitions"
  >
    <template #popper>
      <kog-loader
        :loading="isLoading"
        loading-msg="Searching for meanings..."
      >
        <div v-if="!isError">
          <section
            v-for="(result, resultIndex) in results"
            :key="resultIndex"
            class="DictDefinitions-definitionSection"
          >
            <p
              ><strong> {{ result.word }} </strong></p
            >
            <div
              v-for="(entry, entryIndex) in result.entries"
              :key="entryIndex"
            >
              <span v-if="entry.lexical_category"> {{ entry.lexical_category }} </span>
              <span v-if="entry.phonetic_spelling">| {{ entry.phonetic_spelling }} </span>
              <template v-if="entry.audio_file_url">
                |<button
                  class="DictDefinition-listenButton"
                  title="Listen to pronunciation"
                  aria-label="Listen to pronunciation"
                  @click="debouncedOnListenClick(entry)"
                >
                  <i class="fal fa-volume-up" />
                </button>
              </template>
              <ul>
                <li
                  v-for="(definition, definitionIndex) in entry.definitions"
                  :key="definitionIndex"
                >
                  {{ definition }}
                </li>
              </ul>
            </div>
          </section>
          <span class="DictDefinitions-dictionaryName">Powered by Oxford Languages</span>
        </div>

        <div v-if="isError">
          <strong>
            {{ error }}
          </strong>
        </div>
      </kog-loader>
    </template>
  </dropdown>
</template>

<script>
import { Dropdown } from 'floating-vue';
import { debounce } from 'lodash';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';

export default {
  name: 'DictDefinitions',
  components: {
    Dropdown,
    KogLoader,
  },
  props: {
    selectedText: {
      type: String,
      required: true,
    },
    fetchDictionaryDefinitions: {
      type: Function,
      required: true,
    },
  },
  emits: ['close-menu'],
  data() {
    return {
      isLoading: true,
      error: null,
      results: [],
      debouncedOnListenClick: debounce(this.onListenClick, 1000, {
        leading: true,
        trailing: false,
      }),
    };
  },
  computed: {
    isError() {
      return !!this.error;
    },
  },
  async created() {
    const { selectedText } = this;
    try {
      this.results = await this.fetchDictionaryDefinitions({ selectedText });
    } catch (e) {
      this.error = e.message;
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDownHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.onKeyDownHandler);
  },
  methods: {
    onListenClick(entry) {
      const audioFileUrl = entry.audio_file_url;
      const audio = new Audio(audioFileUrl);
      audio.play();
    },
    closeMenu() {
      this.$emit('close-menu');
    },
    onKeyDownHandler(event) {
      if (event.key === 'Escape') {
        this.closeMenu();
      }
    },
  },
};
</script>

<style>
.popover-inner .DictDefinitions-dictionaryName {
  direction: rtl;
  display: flex;
  font-size: 12px;
}

.popover-inner .DictDefinition-listenButton {
  background-color: var(--kogPlatformWhite);
  border: none;
}

.tooltip-inner .DictDefinitions-definitionSection {
  padding-bottom: 6px;
}

.DictDefinitions {
  max-width: 500px;
  font-size: 14px;
  color: var(--kogPlatformGray018);
}
</style>
