<template>
  <div
    v-kog-mathjax
    :class="{ 'ResultChoice-correctChoice': choice.is_correct }"
    class="ResultChoice list-group-item"
  >
    <div class="ResultChoice-questionNumber">
      {{ choice._numberSymbol }}
    </div>
    <div class="padd-xs">
      <h6 class="list-group-item-heading">
        {{ choice.is_correct ? 'Correct' : 'Incorrect' }}
      </h6>
      <content-container :content="choice.answer_html" />
      <div v-if="showExplanation">
        <h6 class="list-group-item-heading"> Explanation </h6>
        <content-container
          :content="choice.answer_explanation_html"
          class="ResultChoice-answerExplanation content-editable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContentContainer from 'sharedApp/components/content/content-container.vue';

export default {
  name: 'MultipleChoiceQuestionResultChoice',
  components: {
    ContentContainer,
  },
  props: {
    choice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showExplanation() {
      return this.choice.answer_explanation_html && this.choice.is_correct;
    },
  },
};
</script>

<style scoped>
.ResultChoice {
  display: block;

  margin: 0;
  margin-bottom: var(--space-s);
  padding: 0;

  color: var(--kog-colors-grey-800);

  background-color: var(--kog-colors-grey-300);
  border: none;
}

.ResultChoice-questionNumber {
  position: absolute;
  top: -1px;
  right: -1px;

  padding: var(--space-xxs);

  color: var(--kogPlatformWhite);
  text-align: center;

  background-color: var(--kogPlatformGray051);
  border-radius: 0 0 0 5px;
}

.ResultChoice-correctChoice {
  color: var(--kogPlatformGreenDarken20);
  background-color: var(--kogPlatformGreenLighten20);
}

.ResultChoice-answerExplanation {
  overflow-y: scroll;
}
</style>
