<template>
  <div>
    <span class="text-bold"> Filter </span>
    <tag-radio-group
      v-model:selected-value="selectedFilter"
      class="padd-top-xxs"
      group-label="Student list filter"
      :options="filterOptions"
    />
    <div class="margin-top-m">
      <div
        class="flexContainer flexContainer-spaceBetween text-regular margin-bottom-xs margin-right-l"
      >
        <span class="text-bold">Student</span>
        <div
          class="flexContainer kog-col-4"
          :class="{
            'flexContainer-spaceBetween': isLevelsSupported,
            'flexContainer-flexEnd': !isLevelsSupported,
          }"
        >
          <span
            v-if="isLevelsSupported"
            class="text-bold"
          >
            Level
          </span>
          <span
            v-if="showCompletionColumn"
            class="text-capitalize text-bold"
          >
            {{ completionColumnText }}
          </span>
        </div>
      </div>
      <completion-stats-modal-body-item
        v-for="student in filteredStudentsList"
        :key="student.id"
        :student-name="student.name"
        :student-level-name="isLevelsSupported ? $term(student.level.name) : ''"
        :has-completion-column="showCompletionColumn"
        :completion-text="student.completionText"
        :has-level-column="isLevelsSupported"
      />
      <completion-stats-modal-body-item
        v-if="filteredStudentsList.length === 0"
        :is-empty-row="true"
      />
    </div>
  </div>
</template>

<script>
import { groupBy } from 'lodash';

import TagRadioGroup from 'sharedApp/components/filter/tag-radio-group.vue';
import { isIBDP, isIGCSE } from 'sharedApp/services/educationSystem/education-system-service.js';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';

import CompletionStatsModalBodyItem from './completion-stats-modal-body-item.vue';

const STATUS_COMPLETED = 'completed';
const STATUS_NOT_COMPLETED = 'notCompleted';
const LEVEL_EXTENDED = 'extended';
const LEVEL_CORE = 'core';

export default {
  name: 'CompletionStatsModalBody',
  components: {
    CompletionStatsModalBodyItem,
    TagRadioGroup,
  },
  props: {
    studentsCompletionInfo: {
      type: Array,
      required: true,
    },
    showCompletionColumn: {
      type: Boolean,
      default: true,
    },
    completionColumnText: {
      type: String,
      default: '',
    },
    educationSystemName: {
      type: String,
      default: () => '',
    },
    hasCustomCompletionStatus: {
      type: Boolean,
      default: false,
    },
    customCompletionStatuses: {
      type: Object,
      default: () => {},
    },
    possibleLevels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedFilter: '',
    };
  },
  computed: {
    isLevelsSupported() {
      const isIBDPOrIGCSE = isIGCSE(this.educationSystemName) || isIBDP(this.educationSystemName);
      return isIBDPOrIGCSE && isIGCSELevelsEnabled(this.possibleLevels);
    },
    completedLabel() {
      if (this.hasCustomCompletionStatus) {
        return this.customCompletionStatuses.completed;
      }
      return 'Completed';
    },

    notCompletedLabel() {
      if (this.hasCustomCompletionStatus) {
        return this.customCompletionStatuses.notCompleted;
      }
      return 'Not completed';
    },

    filterOptions() {
      const completedInfo = this.studentsByCompletionInfo[STATUS_COMPLETED]?.length || 0;
      const notCompletedInfo = this.studentsByCompletionInfo[STATUS_NOT_COMPLETED]?.length || 0;
      const options = [
        {
          label: `All ${completedInfo + notCompletedInfo}`,
          value: '',
        },
        {
          label: `${this.completedLabel} ${completedInfo}`,
          value: STATUS_COMPLETED,
        },
        {
          label: `${this.notCompletedLabel} ${notCompletedInfo}`,
          value: STATUS_NOT_COMPLETED,
        },
      ];
      if (this.isLevelsSupported) {
        const studentsExtended = this.studentsByLevel[LEVEL_EXTENDED]?.length || 0;
        const studentsCore = this.studentsByLevel[LEVEL_CORE]?.length || 0;
        const extendedTerm = this.$term('Extended');
        const coreTerm = this.$term('Core');
        const levelOptions = [
          {
            label: `${extendedTerm} ${studentsExtended}`,
            value: LEVEL_EXTENDED,
          },
          {
            label: `${coreTerm} ${studentsCore}`,
            value: LEVEL_CORE,
          },
        ];
        options.push(...levelOptions);
      }
      return options;
    },
    studentsByCompletionInfo() {
      const getCompletionStatus = isCompleted =>
        isCompleted ? STATUS_COMPLETED : STATUS_NOT_COMPLETED;
      return groupBy(this.studentsCompletionInfo, info => getCompletionStatus(info.isCompleted));
    },
    studentsByLevel() {
      const getLevel = level => (level?.name === 'Extended' ? LEVEL_EXTENDED : LEVEL_CORE);
      return groupBy(this.studentsCompletionInfo, info => getLevel(info.level));
    },
    filteredStudentsList() {
      if (
        this.selectedFilter === STATUS_COMPLETED ||
        this.selectedFilter === STATUS_NOT_COMPLETED
      ) {
        return this.studentsByCompletionInfo[this.selectedFilter] || [];
      }
      if (this.selectedFilter === LEVEL_EXTENDED || this.selectedFilter === LEVEL_CORE) {
        return this.studentsByLevel[this.selectedFilter] || [];
      }
      return this.studentsCompletionInfo;
    },
  },
};
</script>
