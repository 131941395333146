<template>
  <div>
    <div class="ActivitySheetSubmissions-container flexContainer">
      <activity-sheet-submission-sidebar
        class="ActivitySheetSubmissions-sidebar"
        :students-list="studentsList"
        :selected-student-id="selectedStudentId"
        @select-student="selectStudent"
      />
      <kog-card
        :default-padding="false"
        class="flexChild-size-1 margin-l"
      >
        <activity-sheet-submissions-container
          class="margin-top-s"
          :feature="feature"
          :students-list="studentsList"
          :selected-student-id="selectedStudentId"
          @student-selection-change="selectStudent"
        />
        <kog-wave-separator class="margin-top-l width-full" />
        <student-stepper
          v-if="studentsList && selectedStudentId"
          :students-list="studentsList"
          :selected-student-id="selectedStudentId"
          @select-student="selectStudent"
        />
      </kog-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import KogWaveSeparator from 'sharedApp/components/base/separators/kog-wave-separator.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ActivitySheetSubmissionSidebar from 'studyApp/components/activities/activity-sheet-submission-sidebar.vue';
import StudentStepper from 'studyApp/components/activities/student-stepper.vue';
import ActivitySheetSubmissionsContainer from 'studyApp/containers/activity-sheet/activity-sheet-submissions-container.vue';

export default {
  name: 'ActivitySheetSubmissions',
  components: {
    ActivitySheetSubmissionsContainer,
    ActivitySheetSubmissionSidebar,
    KogCard,
    KogWaveSeparator,
    StudentStepper,
  },
  mixins: [RoutesMixin],
  props: {
    feature: { type: Object, required: true },
    studentsList: { type: Array, required: true },
  },
  data() {
    return {
      selectedStudentId: null,
    };
  },
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
    }),
    subjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
  },
  watch: {
    studentsList: {
      immediate: true,
      deep: true,
      handler(students) {
        if (students && !this.selectedStudentId) {
          const firstStudentId = students[0]?.user_id ?? null;
          if (firstStudentId) {
            this.selectStudent(firstStudentId);
          }
        }
      },
    },
    selectedStudentId() {
      if (+this.$route.query.student === this.selectedStudentId) {
        return;
      }

      this.$router.replace({
        query: {
          ...this.$route.query,
          student: this.selectedStudentId,
        },
      });
    },
  },
  created() {
    if (this.$route.query.student) {
      this.selectedStudentId = +this.$route.query.student;
    }
  },
  mounted() {
    this.$mixpanel.trackEventViaBackend('Activity Sheet - load submission view', {
      activity_sheet_id: this.feature.id,
      subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNode.name}`,
    });

    this.$event.track('load_activity_sheet_submission_view', {
      subject_id: this.subjectId,
      subject_class_id: this.classId,
      subject_node_id: this.nodeId,
      activity_sheet_id: this.feature.id,
    });
  },
  methods: {
    selectStudent(studentId) {
      this.selectedStudentId = studentId;
    },
  },
};
</script>

<style scoped>
.ActivitySheetSubmissions-container {
  min-height: 100vh;
  padding-top: var(--full-page-navbar-height);
}

.ActivitySheetSubmissions-sidebar {
  position: sticky;
  top: calc(var(--full-page-navbar-height) + var(--space-l));

  min-width: 300px;
  max-width: 300px;
  max-height: calc(100vh - var(--full-page-navbar-height) - 2 * var(--space-l));
}

.ActivitySheetSubmissions-studentSelect {
  width: 244px;
}
</style>
