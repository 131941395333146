<template>
  <vertical-expand>
    <kog-loader
      v-if="isVisible"
      :loading="isLoading"
    >
      <question-preview
        v-for="(question, index) in questions"
        :key="question.id"
        :question="question"
        :order="getOrderNumber(index)"
        :class="['QuestionPreview-exerciseQuestion', 'margin-bottom-l']"
      />
    </kog-loader>
  </vertical-expand>
</template>

<script>
import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import QuestionPreview from 'studyApp/components/questions/question-preview.vue';

export default {
  name: 'ExerciseQuestionPreviewGroup',
  components: {
    KogLoader,
    VerticalExpand,
    QuestionPreview,
  },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isLoading() {
      return this.questions.length === 0;
    },
  },
  methods: {
    getOrderNumber(index) {
      return index + 1;
    },
  },
};
</script>

<style>
.QuestionPreview-exerciseQuestion {
  padding: var(--space-xxxl);
  padding-top: var(--space-m);
  padding-bottom: var(--space-xl);

  background: var(--kog-colors-white);
  border-radius: var(--space-l);
}
</style>
