<template>
  <kog-item-list
    class="flexContainer flexContainer-alignCenter"
    role="toolbar"
    :is-handling-tab-index="true"
  >
    <template v-if="!searchExpanded">
      <teacher-assignment-basket
        v-if="showTeacherAssignmentBasket"
        :theme="theme"
      />
      <student-assignment-basket
        v-if="showStudentAssignmentBasket"
        :theme="theme"
      />
    </template>
    <navbar-search-bar-container
      v-if="subject"
      :subject="subject"
      :theme="theme === 'primary' ? 'dark' : 'light'"
      class="StudyNavbar-searchBar KogIntercomTour-search"
      @expand="onSearchExpand"
      @collapse="onSearchCollapse"
    />
    <kog-anchor
      v-if="!searchExpanded"
      :new-style="true"
      class="KogIntercomTour-support"
      url="https://intercom.help/kognity"
      icon="far fa-question-circle"
      target="_blank"
      :theme="theme"
      label="Help"
      :show-label-as-tooltip="true"
    />
    <notifications-dropdown
      v-if="!searchExpanded"
      :notifications="notifications"
      :mark-as-seen="markAsSeen"
      class="flexContainer"
      :theme="theme"
    />
    <kog-navbar-account-dropdown
      v-if="!searchExpanded"
      :user="user"
      :school-id="schoolId"
      :new-style="true"
      avatar-theme="accent"
    />
  </kog-item-list>
</template>

<script>
import { storeToRefs } from 'pinia';

import NotificationsDropdown from 'learning/common/components/notifications-dropdown.vue';
import NavbarSearchBarContainer from 'learning/common/containers/navbar-search-bar-container.vue';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import KogAnchor from 'sharedApp/components/core/kog-anchor.vue';
import KogNavbarAccountDropdown from 'sharedApp/components/core/kog-navbar-account-dropdown.vue';
import StudentAssignmentBasket from 'studyApp/components/assignment/student-assignment-basket.vue';
import TeacherAssignmentBasket from 'studyApp/components/teacher/assignments/teacher-assignment-basket.vue';

export default {
  name: 'StudyNavbarAccountGroup',
  components: {
    StudentAssignmentBasket,
    TeacherAssignmentBasket,
    KogNavbarAccountDropdown,
    KogAnchor,
    NavbarSearchBarContainer,
    NotificationsDropdown,
    KogItemList,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    markAsSeen: {
      type: Function,
      default: () => {},
    },
    subject: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'light'].includes(prop),
    },
  },
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const { fetchIsStudentAssignmentBasketEnabled } = featureFlagStore;
    const { isStudentAssignmentBasketEnabled } = storeToRefs(featureFlagStore);

    return {
      isStudentAssignmentBasketEnabled,
      fetchIsStudentAssignmentBasketEnabled,
    };
  },
  data() {
    return {
      searchExpanded: false,
    };
  },
  computed: {
    schoolId() {
      return this.user.school?.id;
    },
    showTeacherAssignmentBasket() {
      return this.user.isTeacher() && this.$route.name !== 'teacherAssignmentDetails';
    },
    showStudentAssignmentBasket() {
      const hideStudentBasketRoutes = [
        'classReadingAssignmentTake',
        'studyDashboard',
        'classQuestionAssignmentTake',
        'classEsqAssignmentTake',
      ];
      return (
        this.user.isStudent() &&
        this.isStudentAssignmentBasketEnabled &&
        !hideStudentBasketRoutes.includes(this.$route.name)
      );
    },
  },
  watch: {
    subject: {
      handler() {
        this.setAssignmentBasketFlags();
      },
      immediate: true,
    },
  },
  methods: {
    async setAssignmentBasketFlags() {
      if (this.user.isStudent()) {
        await this.fetchIsStudentAssignmentBasketEnabled();
      }
    },
    onSearchExpand() {
      this.searchExpanded = true;
    },
    onSearchCollapse() {
      this.searchExpanded = false;
    },
  },
};
</script>
