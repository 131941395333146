<template>
  <div class="KogButtonSet KogButtonSet--right flexContainer-alignCenter">
    <router-link
      :to="insightsRoute"
      @click="closeModal"
    >
      <kog-button
        button-style="basic"
        label="View insights"
        aria-label="View Insights"
      />
    </router-link>
    <router-link
      v-if="isShowingPerformanceTaskOverviewButton"
      :to="performanceTaskOverviewRoute"
      @click="closeModal"
    >
      <kog-button
        label="View results"
        aria-label="View Results"
      />
    </router-link>
    <router-link
      v-if="isShowingStudentSubmissionButton"
      :to="studentSubmissionsRoute"
      @click="closeModal"
    >
      <kog-button
        label="View submissions"
        aria-label="View submissions"
      />
    </router-link>
    <create-assignment-button
      v-if="!disableCreatingAssignment"
      button-type="primary"
      :before-open-modal="closeModal"
      :subject="subject"
      :subject-classes="subjectClasses"
      :css-class-object="{ 'KogButtonLegacy--primary': true }"
      :user="user"
      :source="source"
      :extra-query-params="nodeQueryParams"
    />
  </div>
</template>

<script>
import CreateAssignmentButton from 'learning/common/components/assignment/create-assignment-button.vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

export default {
  name: 'CompletionStatsModalFooter',
  components: {
    KogButton,
    CreateAssignmentButton,
  },
  mixins: [RoutesMixin],
  props: {
    subjectClassName: {
      type: String,
      required: true,
    },
    subjectNode: {
      type: Object,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    isShowingPerformanceTaskOverviewButton: {
      type: Boolean,
      default: false,
    },
    isShowingStudentSubmissionButton: {
      type: Boolean,
      default: false,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    classSlug: {
      type: String,
      required: true,
    },
    disableCreatingAssignment: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: 'not_declared',
    },
  },
  computed: {
    nodeQueryParams() {
      return {
        node_id: this.nodeId,
        node_slug: this.nodeSlug,
      };
    },
    subjectClasses() {
      return [
        {
          id: this.subjectClassId,
          name: this.subjectClassName,
          slug: this.classSlug,
        },
      ];
    },
    insightsRoute() {
      return {
        name: 'teacherInsights',
        params: {
          classSlug: this.classSlug,
          sid: this.subject.id,
          cid: this.subjectClassId,
        },
        query: {
          active_tab: 'textbook',
        },
      };
    },
    performanceTaskOverviewRoute() {
      return {
        name: 'performanceTaskOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: this.subjectNode.slug,
          nodeId: this.subjectNode.id,
        },
        query: {
          origin: this.source,
          origin_node: this.$route.query.node,
        },
      };
    },
    studentSubmissionsRoute() {
      return {
        name: 'studentActivitySubmissions',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: this.subjectNode.slug,
          nodeId: this.subjectNode.id,
        },
        query: {
          origin: this.source,
          origin_node: this.$route.query.node,
        },
      };
    },
  },
};
</script>
