import { useStore } from 'vuex';

import type { Assignment, TeacherSubjectClassDetails, User } from '@apis/generated/models.ts';

export default function useClassAssignmentOwnership() {
  const store = useStore();
  const { subjectClasses } = store.state.subjectClassModule;

  const findMyClassInAssignment = (assignment: Assignment, teacher: User) => {
    return subjectClasses.find(
      (sc: TeacherSubjectClassDetails) =>
        assignment.subject_classes.includes(sc.id) && sc.teacher_user_ids?.includes(teacher.id),
    );
  };

  return { findMyClassInAssignment };
}
