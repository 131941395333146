import { compile } from 'path-to-regexp';

import { NO_ACCESS_TOKEN_ERROR_MESSAGE, NoAccessTokenError } from 'sharedApp/const/integrations.ts';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_PATHS = {
  'integration-assignment-submission': compile(
    '/api/assignments/:assignmentId/:subjectClassId/integration/submission/',
  ),
};

export async function createIntegrationAssignmentSubmission(assignmentId, subjectClassId) {
  const path = API_PATHS['integration-assignment-submission']({ assignmentId, subjectClassId });
  try {
    const response = await axios.post(path);
    return response.data;
  } catch (error) {
    const apiErrorMessage = error?.response?.data?.detail;
    if (apiErrorMessage === NO_ACCESS_TOKEN_ERROR_MESSAGE) {
      throw new NoAccessTokenError();
    }
    throw error;
  }
}

export default {
  createIntegrationAssignmentSubmission,
};
