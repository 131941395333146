<template>
  <div>
    <div class="heading-xs">{{ name }}</div>
    <div class="margin-top-xs">
      <kog-icon
        icon-class="fa-clock"
        fa-style="regular"
        size="s"
        :is-non-actionable="true"
      />
      {{ deadline }}
    </div>
    <div
      class="flexContainer flexContainer-spaceBetween width-full flexContainer-center margin-top-s"
    >
      <span class="text-overline">Instructions</span>
      <kog-round-button
        aria-label="Expand instructions"
        :icon-class="isInstructionsExpanded ? 'fa-angle-down' : 'fa-angle-up'"
        icon-style="solid"
        size="small"
        button-style="secondary-basic"
        @click="toggleInstructionExpanded"
      />
    </div>
    <vertical-expand-transition>
      <div v-show="isInstructionsExpanded">
        {{ instructions ? instructions : 'No instructions' }}
      </div>
    </vertical-expand-transition>
    <div
      class="flexContainer flexContainer-spaceBetween width-full flexContainer-center margin-top-s"
    >
      <span class="text-overline">Tasks</span>
      <kog-round-button
        aria-label="Expand assignment"
        :icon-class="isTasksExpanded ? 'fa-angle-down' : 'fa-angle-up'"
        icon-style="solid"
        size="small"
        button-style="secondary-basic"
        @click="toggleTasksExpanded"
      />
    </div>
    <vertical-expand-transition>
      <div v-show="isTasksExpanded">
        <div
          v-for="(readingItem, i) in tasks"
          :key="readingItem.id"
          class="flexContainer flexContainer-alignBaseline margin-bottom-xs"
        >
          <kog-icon
            v-if="readingItem.isCompleted"
            class="QuickAssignmentOverview-icon QuickAssignmentOverview-icon--completed padd-s"
            icon-class="fa-check"
            theme="custom"
            size="s"
          />
          <div
            v-else
            class="QuickAssignmentOverview-icon flexContainer flexContainer-center"
          >
            {{ i + 1 }}
          </div>
          <div class="flexChild-canGrow margin-left-xs">
            <router-link
              class="QuickAssignmentOverview-taskLink"
              :to="getReadingItemRoute(readingItem)"
            >
              <div class="margin-bottom-xxs"> {{ readingItem.name }} </div>
            </router-link>
            <div class="text-muted text-small"> {{ readingItem.parent }} </div>
          </div>
        </div>
      </div>
    </vertical-expand-transition>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapGetters, mapState } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import VerticalExpandTransition from 'sharedApp/animations/vertical-expand.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';

export default {
  name: 'QuickAssignmentOverview',
  components: {
    KogIcon,
    VerticalExpandTransition,
    KogRoundButton,
  },
  mixins: [RoutesMixin, ReadingAssignmentMixin],
  props: {
    name: {
      type: String,
      required: true,
    },
    deadline: {
      type: String,
      required: true,
    },
    instructions: {
      type: String,
      required: true,
    },
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { ongoingAssignments } = storeToRefs(useAssignmentStore());

    return {
      ongoingAssignments,
    };
  },
  data() {
    return {
      isTasksExpanded: true,
      isInstructionsExpanded: true,
    };
  },
  computed: {
    ...mapState('assignmentModule', {
      assignmentInBasketId: state => state.assignmentInBasketId,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      statsBySubjectNode: 'statisticModule/statsBySubjectNode',
    }),
  },
  methods: {
    toggleTasksExpanded() {
      this.isTasksExpanded = !this.isTasksExpanded;
    },
    toggleInstructionExpanded() {
      this.isInstructionsExpanded = !this.isInstructionsExpanded;
    },
    getReadingItemRoute(readingItem) {
      return this.isAssignmentFocus
        ? {
            name: this.$route.meta.isReviewMode
              ? 'teacherAssignmentDetails'
              : 'classReadingAssignmentTake',
            params: {
              classSlug: this.subjectClassSlug,
              sid: this.subjectId,
              cid: this.classId,
              assignmentId: this.assignmentInBasketId,
              nodeId: readingItem.id,
            },
          }
        : {
            name: 'classBook',
            params: {
              classSlug: this.subjectClassSlug,
              sid: this.subjectId,
              cid: this.classId,
              nodeSlug: readingItem.slug,
              nodeId: readingItem.id,
            },
          };
    },
  },
};
</script>
<style scoped>
.QuickAssignmentOverview-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-top: var(--space-xxs);

  color: var(--kog-colors-grey-900);

  background-color: var(--kog-colors-grey-300);
  border-radius: 50%;
}
.QuickAssignmentOverview-icon--completed {
  background-color: var(--kog-colors-green-500);
}

.QuickAssignmentOverview-taskLink {
  color: var(--kog-text-default);
}
</style>
