<template>
  <div class="PerformanceTaskPrint-wrapper kog-col-12 kog-col-sm-12">
    <kog-loader
      :loading="isLoading"
      flex
    />
    <template v-if="!isLoading">
      <book-print-header />
      <div class="PerformanceTaskPrint-performanceTaskContentWrapper">
        <div class="PerformanceTaskPrint-content">
          <component
            :is="contentType(contentItem)"
            v-for="contentItem in content"
            :key="contentItem.id"
            :content="contentItem"
            :is-in-review-mode="false"
            class="margin-bottom-l padd-top-l"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';
import ActivityDivider from 'sharedApp/components/activity-divider/activity-divider.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ActivityContentBoxBlock from 'studyApp/components/activities/activity-content-box-block.vue';
import ActivityCustomEditorBlock from 'studyApp/components/activities/activity-custom-editor-block.vue';
import ActivityHeadingBlock from 'studyApp/components/activities/activity-heading-block.vue';
import ActivityImageBlock from 'studyApp/components/activities/activity-image-block.vue';
import ActivityQuestion from 'studyApp/components/activities/activity-question.vue';
import BookPrintHeader from 'studyApp/components/book-print-header.vue';
import { PERFORMANCE_TASK_MODULE } from 'studyApp/store/modules/performance-task.js';
import { QUESTION_ACTIVITY_MODULE } from 'studyApp/store/modules/question-activity.js';

export default {
  name: 'PerformanceTaskPrint',
  components: {
    BookPrintHeader,
    KogLoader,
  },
  mixins: [RoutesMixin],
  emits: ['ready-to-print'],
  computed: {
    ...mapState(PERFORMANCE_TASK_MODULE, ['performanceTask']),
    ...mapState({
      subject: state => state.subjectModule.subject,
    }),
    ...mapGetters({
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    isLoading() {
      return (
        this.$wait.is('loading_performance_task') || this.$wait.is('loading_available_questions')
      );
    },
    feature() {
      return this.getActivityBySubjectNodeId(this.nodeId);
    },
    featureId() {
      return this.feature.id;
    },
    content() {
      return this.performanceTask?.content || [];
    },
  },
  async created() {
    await Promise.all([
      this.fetchPerformanceTask(this.featureId),
      this.fetchAvailableQuestions({ subjectNodeId: this.nodeId }),
    ]);
    this.$emit('ready-to-print');
  },
  methods: {
    ...mapWaitingActions(PERFORMANCE_TASK_MODULE, {
      fetchPerformanceTask: 'loading_performance_task',
    }),
    ...mapWaitingActions(QUESTION_ACTIVITY_MODULE, {
      fetchAvailableQuestions: 'loading_available_questions',
    }),
    contentType(item) {
      switch (item.block_type) {
        case BLOCK_TYPE.QUESTION: {
          return ActivityQuestion;
        }
        case BLOCK_TYPE.CUSTOM_EDITOR: {
          return ActivityCustomEditorBlock;
        }
        case BLOCK_TYPE.CONTENT_BOX: {
          return ActivityContentBoxBlock;
        }
        case BLOCK_TYPE.DIVIDER: {
          return ActivityDivider;
        }
        case BLOCK_TYPE.HEADING: {
          return ActivityHeadingBlock;
        }
        case BLOCK_TYPE.IMAGE: {
          return ActivityImageBlock;
        }
        default:
          throw new Error('Not supported content type', item.block_type);
      }
    },
  },
};
</script>

<style scoped>
.PerformanceTaskPrint-wrapper {
  counter-reset: divider;
  min-height: 100vh;
  word-wrap: break-word;
}

.PerformanceTaskPrint-wrapper img {
  height: auto;
}

.PerformanceTaskPrint-content {
  font-family: var(--kog-lato);
}

@media (min-width: 1200px) {
  .PerformanceTaskPrint-performanceTaskContentWrapper {
    grid-area: performanceTaskContent;
  }
}
</style>
