<template>
  <kog-modal
    class="CompleteQuestionAssignmentModal-container"
    :maximize="true"
  >
    <template #modalBody>
      <div class="flexContainer flexContainer-column flexContainer-alignCenter padd-l">
        <vue-3-lottie
          :animation-data="checkAnimation"
          class="CompleteQuestionAssignmentModal-animation"
          :loop="1"
          :auto-play="true"
          :speed="1"
          aria-hidden="true"
        />
        <h3 class="margin-top-xs margin-bottom-xs">Assignment completed</h3>
        <span class="margin-bottom-s"
          >{{ submittedPracticeItemsCount }}/{{ practiceItemsCount }} questions completed</span
        >
        <div class="margin-top-l width-full">
          <kog-button
            class="width-full margin-bottom-xs"
            aria-label="Submit assignment"
            label="Submit assignment"
            button-style="accent"
            @click="submit"
          />
          <kog-button
            class="width-full"
            aria-label="Return to assignment"
            label="Return to assignment"
            button-style="basic"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script setup>
import { computed } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
import { useStore } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import checkAnimation from 'studyApp/images/assignment-complete-check-animation.json';

const props = defineProps({
  onConfirm: {
    type: Function,
    required: true,
  },
  closeModal: {
    type: Function,
    default: null,
  },
});

const store = useStore();
const practiceItemsCount = computed(
  () => store.getters['assignmentModule/practiceItemsCount'],
).value;
const submittedPracticeItemsCount = computed(
  () => store.getters['assignmentModule/submittedPracticeItemsCount'],
).value;

const submit = async () => {
  await props.onConfirm();
  props.closeModal();
};
</script>

<style scoped>
.CompleteQuestionAssignmentModal-container {
  width: 464px;
}

.CompleteQuestionAssignmentModal-animation {
  width: 230px;
  height: 230px;
}
</style>
