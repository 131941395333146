import { markRaw } from 'vue';
import * as textPosition from 'dom-anchor-text-position';
import { fromRange, toRange } from 'dom-anchor-text-quote';
import debounce from 'lodash/debounce';

import { fetchDefinitions as fetchDictionaryDefinitions } from '@apis/dictionary';

import DictDefinitions from 'learning/common/components/annotations-menu/dict-definitions.vue';
import mountSelectedTextMenu from 'learning/common/components/annotations-menu/selected-text-menu.utils.ts';
import KogHighlight from 'learning/common/components/kog-highlight.vue';
import highlight, { getSelectedRange } from 'learning/common/libs/highlight.js';

import mountComponent from 'sharedApp/utils/mount-component.ts';

function findPos(node) {
  const range = document.createRange();
  range.selectNode(node);
  return range.getBoundingClientRect();
}

// Finds the most right/bottom node in selection regardless of selection direction
function getLatestNode(selection) {
  const { focusNode, focusOffset, anchorNode, anchorOffset } = selection;
  const focusBottom = findPos(focusNode).bottom;
  const anchorBottom = findPos(anchorNode).bottom;
  let node = null;
  let offset = 0;
  if (focusBottom === anchorBottom) {
    if (anchorOffset < focusOffset) {
      node = focusNode;
      offset = focusOffset;
    } else {
      node = anchorNode;
      offset = anchorOffset;
    }
  } else if (focusBottom > anchorBottom) {
    node = focusNode;
    offset = focusOffset;
  } else {
    node = anchorNode;
    offset = anchorOffset;
  }
  return { node, offset };
}

function hasMathJaxSelected(selection) {
  if (selection.isCollapsed) {
    return false;
  }
  const mathjaxNodes = document.getElementsByClassName('MathJax');
  let containsMathJax = false;
  Array.from(mathjaxNodes).forEach(node => {
    if (selection.containsNode(node, true)) {
      containsMathJax = true;
    }
  });
  return containsMathJax;
}

const HighlightMixin = {
  data() {
    return {
      hlRange: null,
      hlSelectionMenu: null,
      hlSelectionMenuDestroy: null,
      hlMenuHookParent: null,
      finalSelection: null,
      renderedHighlights: {},
      ymConfig: {},
      enabled: false,
      addNewHighlightCallback: null,
    };
  },
  mounted() {
    document.addEventListener(
      'selectionchange',
      debounce(this.ymSelectionChanged, this.ymConfig.debounceDelay),
    );
    document.addEventListener('mousedown', this.ymClickOutsideHandler);
    document.addEventListener('touchstart', this.ymClickOutsideHandler);
  },
  methods: {
    initHighlightMixin(addNewHighlightCallback, config) {
      this.enabled = true;
      this.ymConfig = config || this.getAnnotationPluginSettings();
      if (!addNewHighlightCallback) {
        throw new Error('addNewHighlight callback required in highlight-mixin!');
      }
      this.addNewHighlightCallback = addNewHighlightCallback;
    },
    ymSelectionAllowed(selection) {
      if (!this.enabled) {
        return false;
      }
      if (hasMathJaxSelected(selection)) {
        this.$toast.showInfo("Sorry, it's not possible to highlight formulas", null, {
          toasterId: 'no_mathjax_toaster',
        });
        return false;
      }
      return selection.toString().trim();
    },
    ymSelectionChanged(e) {
      e.preventDefault();
      e.stopPropagation();
      const selection = window.getSelection();
      const { focusNode, anchorNode, isCollapsed } = selection;

      let isSelectionMenuFocused = false;
      if (this.hlSelectionMenu) {
        isSelectionMenuFocused = this.hlSelectionMenu.matches(':focus-within');
      }
      const isSelectionMenuActive = !!this.hlSelectionMenu && isSelectionMenuFocused;

      if (isSelectionMenuActive) {
        return;
      }

      if (
        isCollapsed ||
        !anchorNode ||
        !this.$el.contains(anchorNode) ||
        !focusNode ||
        !this.$el.contains(focusNode) ||
        !this.ymSelectionAllowed(selection)
      ) {
        // Do not show menu if selection is collapsed or outside element
        this.ymRemoveMenu();
        return;
      }

      this.hlRange = selection.getRangeAt(0);
      this.ymCreateSelectionMenu(selection);
    },
    // Highlights text and specifies a click handler
    ymHighlight(
      { prefix, suffix, exact, location_hint: locationHint },
      clickHandler,
      props,
      customObject = {},
    ) {
      if (this.$el === undefined) {
        throw new Error('highlight: this.$el is undefined. Make sure the component is mounted.');
      }
      if (!exact) {
        return undefined;
      }
      const options = {};
      if (locationHint) {
        options.hint = locationHint;
      }
      const range = toRange(
        this.$el,
        {
          prefix,
          suffix,
          exact,
        },
        options,
      );
      return highlight(
        this.$el,
        customObject,
        clickHandler,
        this.ymConfig.highlight.component,
        props,
        range,
      );
    },
    ymGetSelectionTextQuote() {
      const range = getSelectedRange(this.finalSelection);
      if (range) {
        return fromRange(this.$el, range);
      }
      return null;
    },
    ymRemoveMenu() {
      if (this.hlSelectionMenuDestroy) {
        this.hlSelectionMenuDestroy();
        this.hlSelectionMenuDestroy = null;
        this.hlSelectionMenu.remove();
        this.hlSelectionMenu = null;
        if (this.hlMenuHookParent) {
          this.hlMenuHookParent.normalize();
        }
      }
    },
    ymRemoveSelection() {
      window.getSelection().removeAllRanges();
    },
    ymAdjustMenuPosition(element) {
      const content = element.getElementsByClassName('ContextMenu-content')[0];
      const tip = element.getElementsByClassName('ContextMenu-tip')[0];
      const rect = content.getBoundingClientRect();
      if (rect.left < 0) {
        const offset = -rect.left + 1;
        // eslint-disable-next-line no-param-reassign
        element.style.left = `${offset}px`;
        const tipLeft = rect.width / 2 - offset - 8;
        tip.style.marginLeft = `${tipLeft}px`;
      } else if (rect.right > window.innerWidth) {
        const offset = (rect.right - window.innerWidth) * 2 + 2;
        content.style.marginLeft = `-${offset}px`;
        const tipLeft = rect.width / 2 + offset / 2 - 8;
        tip.style.marginLeft = `${tipLeft}px`;
      }
    },
    ymCreateSelectionMenu(selection) {
      const menu = this.ymConfig.menus.selection;
      const { props } = menu;
      this.finalSelection = {
        focusNode: selection.focusNode,
        rangeAtZero: selection.getRangeAt(0),
      };
      this.ymAddMenuToSelection(selection, props);
    },
    ymDefineSelectionNode(selection) {
      const { node } = getLatestNode(selection);
      const range = this.hlRange.cloneRange();
      range.collapse(false);
      this.hlMenuHookParent = node.parentElement;
      return range;
    },
    ymCreateSelectedTextMenuAtSelectedRangeEnd(selection, props) {
      const range = this.ymDefineSelectionNode(selection);
      const { el, destroy } = mountSelectedTextMenu(props);
      this.hlSelectionMenu = el;
      this.hlSelectionMenuDestroy = destroy;
      range.insertNode(this.hlSelectionMenu);
    },
    ymCreateDictDefinitionsMenuAtSelectedRangeEnd(selection, props) {
      const range = this.ymDefineSelectionNode(selection);
      const { el, destroy } = mountComponent({
        component: DictDefinitions,
        props,
        elementCssClasses: ['inline-block'],
      });
      this.hlSelectionMenu = el;
      this.hlSelectionMenuDestroy = destroy;
      range.insertNode(this.hlSelectionMenu);
    },
    ymAddMenuToSelection(selection, props = {}) {
      this.ymRemoveMenu();
      this.ymCreateSelectedTextMenuAtSelectedRangeEnd(selection, props);
      this.ymAdjustMenuPosition(this.hlSelectionMenu);
    },
    ymGetSelectedRange() {
      return getSelectedRange(this.finalSelection);
    },
    removeHighlightsFromHtml(ids) {
      ids.forEach(id => {
        const highlightResult = this.renderedHighlights[id];
        if (highlightResult) {
          highlightResult.cleanupMethod();
          delete this.renderedHighlights[id];
        }
      });
    },
    showHighlightsInHtml(highlightInfos) {
      highlightInfos.forEach(highlightInfo => {
        const highlightResult = this.ymHighlight(
          highlightInfo.location,
          highlightInfo.clickHandler,
          {},
          highlightInfo.object,
        );
        this.renderedHighlights[highlightInfo.id] = highlightResult;
      });
    },
    showDictionaryDefinition() {
      const selection = window.getSelection();
      const textQuote = this.ymGetSelectionTextQuote();
      const selectedText = textQuote.exact.trim();
      this.trackShowDictionaryDefinition(selectedText);
      this.ymRemoveMenu();
      this.ymCreateDictDefinitionsMenuAtSelectedRangeEnd(selection, {
        selectedText,
        fetchDictionaryDefinitions,
        onCloseMenu: this.ymRemoveMenu,
      });
    },
    trackShowDictionaryDefinition(searchTerm) {
      const name = 'Dictionary - Click See definition';
      this.$mixpanel.trackEvent(name, {
        subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNodeName}`,
        school_id: this.user.school.id,
        dictionary_term: searchTerm,
      });
    },
    getSelectionPosition() {
      const range = this.ymGetSelectedRange();
      return textPosition.fromRange(this.$el, range);
    },
    getAnnotationPluginSettings() {
      return {
        highlight: {
          component: markRaw(KogHighlight),
        },
        menus: {
          selection: {
            props: {
              isDictionaryEnabled: this.subject.is_dictionary_enabled,
              onAddHighlight: this.addNewHighlightHandler,
              onShowDictionaryDefinition: this.showDictionaryDefinition,
              onCloseMenu: this.ymRemoveMenu,
            },
          },
        },
        debounceDelay: 100,
      };
    },
    addNewHighlightHandler(color) {
      const textQuote = this.ymGetSelectionTextQuote();
      if (!textQuote) {
        return;
      }
      const locationHint = this.getSelectionPosition().end;
      this.ymRemoveMenu();
      this.ymRemoveSelection();

      this.addNewHighlightCallback(color, textQuote, locationHint);
    },
    scrollToHighlight(highlightId) {
      const noteToScrollTo = document.getElementById(`KogHighlight-${highlightId}`);
      if (noteToScrollTo) {
        noteToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
  },
};

export default HighlightMixin;
