<template>
  <kog-loader
    :loading="$wait.is('getting_assignment')"
    loading-msg="Loading assignment..."
  >
    <kog-default-study-page>
      <template #body>
        <assignment-take-overview
          v-if="assignment"
          :assignment="assignment"
          @take-assignment="startAssignment"
        />
        <div class="muted text-small">
          <template v-if="subject.educationsystem && subject.educationsystem.name === 'IGCSE'">
            The test practice questions, example answers and mark schemes that appear in this
            digital textbook were written by the authors. In examinations, the way that marks are
            awarded may be different.
          </template>
          <template v-if="subject.educationsystem && subject.educationsystem.name !== 'IGCSE'">
            Questions and answers are written by curriculum experts for Kognity, in the style of
            past exam questions. They are not official questions or endorsed by the curriculum
            provider.
          </template>
        </div>
      </template>
    </kog-default-study-page>
  </kog-loader>
</template>

<script>
import { useHead } from '@unhead/vue';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapState, useStore } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import KogDefaultStudyPage from 'studyApp/components/kog-default-study-page.vue';

import AssignmentTakeOverview from './components/assignment-take-overview.vue';

export default {
  name: 'EsqAssignmentOverview',
  components: {
    KogDefaultStudyPage,
    KogLoader,
    AssignmentTakeOverview,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const { assignmentModule } = store.state;

    useHead({
      title: () =>
        `${assignmentModule.assignment?.name} | ESQ assignment overview | ${assignmentModule.subject?.name}`,
    });
  },
  computed: {
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    source() {
      return this.$route.query.source;
    },
    ...mapState('userModule', {
      user: state => state.user,
    }),
    ...mapState('assignmentModule', {
      assignment: state => state.assignment,
      questionsCount: state => state.assignment.questions.length,
    }),
    ...mapState('subjectModule', {
      subject: state => state.subject,
    }),
  },
  async created() {
    await this.getAssignment(this.assignmentId);
    if (!this.classId) {
      const {
        id: assignmentId,
        subject_class_slug: classSlug,
        subject_class_id: classId,
      } = this.assignment;
      await this.$router.replace({
        name: 'classEsqAssignmentOverview',
        params: {
          assignmentId,
          classSlug,
          sid: `${this.subject.id}`,
          cid: `${classId}`,
        },
      });
    }
  },
  methods: {
    ...mapActions('assignmentModule', ['startESQAssignment']),
    ...mapWaitingActions('assignmentModule', {
      getAssignment: 'getting_assignment',
    }),
    startAssignment() {
      this.startESQAssignment(this.assignment.id);
      this.$router.push({
        name: 'classEsqAssignmentTake',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignment.id,
        },
        query: { source: this.source },
      });
    },
  },
};
</script>
