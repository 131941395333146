<template>
  <div
    v-if="hasQuestions"
    class="flexContainer flexContainer-column flexContainer-alignCenter"
  >
    <button
      class="SectionQuestionPreview-expandQuestionButton margin-top-l margin-bottom-s"
      aria-label="Toggle questions"
      @click="toggleQuestions"
    >
      <h2 class="margin-0">
        {{ sectionQuestionsTitle }}
        <kog-icon
          class="margin-left-xs"
          theme="custom"
          :icon-class="isQuestionsExpanded ? 'fa-angle-up' : 'fa-angle-down'"
          size="m"
        />
      </h2>
    </button>
    <vertical-expand>
      <div
        v-show="isQuestionsExpanded"
        class="flexContainer flexContainer-column flexContainer-alignCenter kog-container-lg width-100 margin-top-s"
      >
        <div
          v-for="(question, index) in questions"
          :key="question.id"
          class="SectionQuestionPreview padd-left-s padd-right-s width-full kog-col-lg-8 kog-col-sm-12"
        >
          <div class="flexContainer flexContainer-row flexContainer-spaceBetween">
            <span class="heading-xxs margin-bottom-xs"> Question {{ index + 1 }} </span>
            <div class="flexContainer flexContainer-alignCenter">
              <question-level-labels
                v-if="shouldShowLevelsInfo"
                :levels="question.attributes.levels"
                :relevant-group="subjectClassLevelGroup"
              />
              <question-tags
                :tags="question.tags"
                :show-difficulty="showDifficulty"
                :subject-id="subject.id"
              />
            </div>
          </div>
          <question-preview
            class="margin-top-s"
            :question="question"
          />
        </div>
      </div>
    </vertical-expand>
  </div>
</template>

<script>
import pluralize from 'pluralize';

import { getSectionQuestions } from '@apis/questions';

import QuestionLevelLabels from 'learning/common/components/question-level-labels.vue';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { IGCSE_LEVELS } from 'sharedApp/const/level-groups.js';
import { shouldShowDifficultyTagForSubject } from 'sharedApp/services/featureflags/fixed-feature-flags.ts';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import QuestionPreview from 'studyApp/components/questions/question-preview.vue';
import QuestionTags from 'studyApp/components/questions/question-tags.vue';

export default {
  name: 'SectionQuestionPreview',
  components: {
    KogIcon,
    QuestionPreview,
    VerticalExpand,
    QuestionTags,
    QuestionLevelLabels,
  },
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      questions: [],
      showDifficulty: false,
      isQuestionsExpanded: false,
    };
  },
  computed: {
    hasQuestions() {
      return this.questions.length > 0;
    },
    sectionQuestionsTitle() {
      const questionCount = this.questions.length;
      return `${questionCount} section ${pluralize('question', questionCount)}`;
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subject.possible_levels);
    },
    subjectClassLevelGroup() {
      return IGCSE_LEVELS;
    },
  },
  created() {
    this.showDifficulty = shouldShowDifficultyTagForSubject(this.subject.id);
    this.fetchSectionQuestions();
  },
  methods: {
    async fetchSectionQuestions() {
      this.questions = await getSectionQuestions(this.subjectNodeId, {
        additionalParams: {
          showAnswers: 'True',
        },
      });
    },
    toggleQuestions() {
      this.isQuestionsExpanded = !this.isQuestionsExpanded;
    },
  },
};
</script>

<style scoped>
.SectionQuestionPreview {
  margin-bottom: var(--space-m);
  padding: var(--space-l);
  background: var(--kogPlatformWhite);
  border-radius: 12px;
}

.SectionQuestionPreview-expandQuestionButton {
  padding: 8px 24px;
  background: var(--kogTransparent);
  border: none;
  border-radius: 4px;
}

.SectionQuestionPreview-expandQuestionButton:hover {
  color: var(--kog-round-button-secondary-basic-hover-color);
  background: var(--kog-round-button-secondary-basic-hover-background);
}
</style>
