<template>
  <ckeditor
    ref="editor"
    v-bind="$attrs"
    :model-value="compatibleContent"
    :config="processedConfig"
    :editor="editor"
    @input="onEditorInput"
    @ready="onEditorReady"
    @focus="onEditorFocus"
  />
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix
import { isVNode } from 'vue';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import {
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { Clipboard, PastePlainText } from '@ckeditor/ckeditor5-clipboard';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { FindAndReplace } from '@ckeditor/ckeditor5-find-and-replace';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import {
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  ImageToolbar,
} from '@ckeditor/ckeditor5-image';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { List } from '@ckeditor/ckeditor5-list';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import {
  SpecialCharacters,
  SpecialCharactersEssentials,
} from '@ckeditor/ckeditor5-special-characters';
import { Style } from '@ckeditor/ckeditor5-style';
import {
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
} from '@ckeditor/ckeditor5-table';
import { WordCount } from '@ckeditor/ckeditor5-word-count';
import { Math } from '@isaul32/ckeditor5-math';
import { cloneDeep, keyBy } from 'lodash';
import { mapActions as mapPiniaActions } from 'pinia';
import { mapActions, mapState } from 'vuex';

import { fetchQuestionDetail } from 'publishingApp/apis/questions.js';
import { EDIT_SECTION_MODULE_PREFIX } from 'publishingApp/store/modules/edit-section.js';
import usePublishingOetStore from 'publishingApp/store/modules/open-ended-task.ts';
import { buildLinkableActivities } from 'publishingApp/utils/content-link-utils.js';
import CKEditor from 'sharedApp/libs/ckeditor5.ts';
import { getLeafNodes } from 'sharedApp/libs/subject-tree-functions.js';
import mountComponent from 'sharedApp/utils/mount-component.ts';

import AnyBox from './plugins/any-box/any-box.js';
import Audio from './plugins/audio/audio.js';
import ContentBox from './plugins/content-box/content-box.js';
import ContentGrid from './plugins/content-grid/content-grid.js';
import EditorConfigPlugin from './plugins/editor-config/editor-config.js';
import ExerciseBox from './plugins/exercise-box/exercise-box.js';
import QuestionRenderer from './plugins/exercise-question/question-renderer.vue';
import FileDownload from './plugins/file-download/file-download.js';
import FITBQBlank from './plugins/fitbq-blank/fitbq-blank.js';
import GlossaryLink from './plugins/glossary-link/glossary-link.js';
import IconDiv from './plugins/icon-div/icon-div.js';
import InlineQuestion from './plugins/inline-question/inline-question.js';
import ItalicOverride from './plugins/italic-override/italic-override.js';
import MathOverride, { CustomRenderEngine } from './plugins/math-override/math-override.js';
import OpenEndedTask from './plugins/open-ended-task/open-ended-task.js';
import ReflectionBox from './plugins/reflection-box/reflection-box.js';
import SectionLink from './plugins/section-link/section-link.js';
import ShowSolution from './plugins/show-solution/show-solution.ts';
import Spacing from './plugins/spacing/spacing.ts';
import SpanKogId from './plugins/span-id/span-id.js';
import AddCustomCssToTables from './plugins/table-customization/table-customization.js';
import UploadAdapterPlugin from './plugins/upload-adapter/upload-adapter.ts';

const PLUGINS_BUILTIN = [
  Alignment,
  BlockQuote,
  Bold,
  Clipboard,
  CodeBlock,
  Essentials,
  FindAndReplace,
  GeneralHtmlSupport,
  Heading,
  HorizontalLine,
  Image,
  ImageCaption,
  ImageInsert,
  ImageStyle,
  ImageResize,
  ImageToolbar,
  Indent,
  IndentBlock,
  Italic,
  ItalicOverride,
  List,
  Paragraph,
  PastePlainText,
  PasteFromOffice,
  RemoveFormat,
  SourceEditing,
  Spacing,
  SpecialCharacters,
  SpecialCharactersEssentials,
  Subscript,
  Superscript,
  Style,
  Strikethrough,
  Table,
  TableCaption,
  TableColumnResize,
  TableToolbar,
  TableProperties,
  TableCellProperties,
  Underline,
  WordCount,
];

const PLUGINS_THIRDPARTY = [Math];

const PLUGINS_CUSTOM = [
  MathOverride,
  AddCustomCssToTables,
  AnyBox,
  Audio,
  ContentBox,
  ContentGrid,
  EditorConfigPlugin,
  ExerciseBox,
  FileDownload,
  FITBQBlank,
  GlossaryLink,
  IconDiv,
  InlineQuestion,
  OpenEndedTask,
  ReflectionBox,
  ShowSolution,
  SpanKogId,
  SectionLink,
  UploadAdapterPlugin,
];

const TOOLBAR_DEFAULT = [
  'sourceEditing',
  'undo',
  'redo',
  'findAndReplace',
  'codeBlock',
  'math',
  'insertImage',
  'insertTable',
  'horizontalLine',
  'blockQuote',
  'specialCharacters',
  'spacing',
  '|',
  'bold',
  'italic',
  'underline',
  'subscript',
  'superscript',
  'strikethrough',
  'removeFormat',
  'numberedList',
  'bulletedList',
  'indent',
  'outdent',
  'alignment',
  'heading',
  'style',
  '|',
  'showSolution',
  'inlineQuestion',
  'exerciseBox',
  'openEndedTask',
  'anyBox',
  'contentGrid',
  'glossaryLink',
  'contentBox',
  'fileDownload',
  'audio',
  'sectionLink',
];

const TOOLBAR_OPTIONAL = ['FITBQBlank', 'reflectionBox'];

export default {
  name: 'KogEditorCK5',
  components: {
    ckeditor: CKEditor,
  },
  inheritAttrs: false,
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    content: {
      type: String,
      required: true,
    },
    exerciseQuestions: {
      type: Array,
      default: () => [],
    },
    invalidExerciseQuestions: {
      type: Array,
      default: () => [],
    },
    openEndedTasks: {
      type: Array,
      default: () => [],
    },
    contentBoxTemplates: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'update:content',
    'editorready',
    'editorfocus',
    'contentStatsUpdate',
    'addInvalidExerciseQuestion',
  ],
  data() {
    return {
      editorMutationObserver: null,
      editorInstance: null,
      renderedVNodes: new Map(),
      editor: ClassicEditor,
      editorConfig: {
        plugins: [...PLUGINS_BUILTIN, ...PLUGINS_THIRDPARTY, ...PLUGINS_CUSTOM],
        licenseKey: window?.KOG?.CKE5_LICENSE_KEY ?? '',
        alignment: {
          options: ['left', 'center', 'right', 'justify'],
        },
        style: {
          definitions: [
            { name: 'Prompt', element: 'span', classes: ['prompt'] },
            { name: 'Prompt Command', element: 'span', classes: ['prompt-command'] },
          ],
        },
        math: {
          engine: CustomRenderEngine,
          outputType: 'span',
        },
        htmlSupport: {
          allow: [
            {
              name: 'span',
              attributes: true,
              classes: true,
              styles: true,
            },
            {
              name: 'iframe',
              attributes: true,
              classes: true,
              styles: true,
            },
          ],
        },
        wordCount: {
          onUpdate: this.onContentStatsChange,
        },
        glossarylink: {
          callbacks: {
            getGlossaryDefinitionsBySubject: this.getOrFetchGlossaryTerms,
          },
        },
        exerciseQuestion: {
          questionRenderer: ({ props, domElement }) => {
            const { el, destroy } = mountComponent({
              component: QuestionRenderer,
              props,
              element: domElement,
              appContext: this.$.appContext,
            });
            this.renderedVNodes.set(el, destroy);
          },
          callbacks: {
            getExerciseQuestions: () => this.exerciseQuestions,
            getInvalidExerciseQuestions: () => this.invalidExerciseQuestions,
            fetchQuestionDetail,
            addInvalidExerciseQuestion: this.addInvalidExerciseQuestion,
          },
        },
        linkableSections: {
          callbacks: {
            getLinkableSections: this.getOrFetchLinkableSections,
          },
        },
        linkableActivities: {
          callbacks: {
            getLinkableActivities: this.getOrFetchLinkableActivities,
          },
        },
        image: {
          insert: {
            integrations: ['url', 'upload'],
            type: 'auto',
          },
          toolbar: [
            'toggleImageCaption',
            'imageTextAlternative',
            'imageStyle:block',
            'imageStyle:inline',
            'imageStyle:side',
          ],
          resizeUnit: 'px',
        },
        openEndedTask: {
          callbacks: {
            getExerciseQuestions: () => this.exerciseQuestions,
            addInvalidExerciseQuestion: this.addInvalidExerciseQuestion,
            createOpenEndedTask: this.createOpenEndedTask,
            getOpenEndedTaskById: id => this.openEndedTasksById[id],
          },
        },
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [],
        },
        table: {
          contentToolbar: [
            'toggleTableCaption',
            '|',
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            '|',
            'tableProperties',
            'tableCellProperties',
          ],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Normal', class: 'ck-heading_paragraph' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
          ],
        },
        contentBox: {
          contentBoxTemplateMap: keyBy(this.contentBoxTemplates, 'id'),
        },
      },
      linkableActivities: [],
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      glossaryTerms: state => state.glossaryModule.terms,
      glossarySubjectId: state => state.glossaryModule.selectedSubjectId,
      isGlossaryRefetchRequired: state => state.glossaryModule.isRefetchRequired,
      linkableSections: state => state[EDIT_SECTION_MODULE_PREFIX].linkableSections,
      linkableSectionsMeta: state => state[EDIT_SECTION_MODULE_PREFIX].linkableSectionsMeta,
      linkableFeatures: state => state.subjectNodeFeatureModule.features,
      linkableFeaturesMeta: state => state.subjectNodeFeatureModule.featuresMeta,
    }),
    openEndedTasksById() {
      return keyBy(this.openEndedTasks, 'id');
    },
    compatibleContent() {
      const replacedImageClass = this.content.replaceAll(
        /(<figure[^>]*class="[\w\s]*)(caption)/gim,
        '$1 image',
      );
      return replacedImageClass;
    },
    subjectId() {
      return this.$route.query.subjectId || this.$route.params.subjectId;
    },
    sectionId() {
      return this.$route.params.sectionId;
    },
    processedConfig() {
      const temp = cloneDeep(this.editorConfig);
      temp.toolbar.items = this.completeToolbar;
      return temp;
    },
    completeToolbar() {
      const defaults = TOOLBAR_DEFAULT.filter(value => !this.config?.exclude?.includes(value));
      const optional = TOOLBAR_OPTIONAL.filter(value => this.config?.include?.includes(value));
      return [...defaults, ...optional];
    },
  },
  watch: {
    editorInstance: {
      once: true,
      handler(instance) {
        if (instance) {
          /**
           * Since the editor doesn't emit events for nodes that are getting removed we need to
           * have a mutation observer in order to clean up the rendered vnodes.
           */
          this.editorMutationObserver = new MutationObserver(mutationList => {
            mutationList.forEach(mutation => {
              mutation.removedNodes.forEach(removedNode => {
                // eslint-disable-next-line no-underscore-dangle
                if (isVNode(removedNode._vnode)) {
                  const destroy = this.renderedVNodes.get(removedNode);
                  if (destroy) {
                    destroy();
                    this.renderedVNodes.delete(removedNode);
                  }
                }
              });
            });
          });

          this.editorMutationObserver.observe(instance.editing.view.domRoots.get('main'), {
            childList: true,
            subtree: true,
          });
        }
      },
    },
  },
  beforeUnmount() {
    if (this.editorMutationObserver) {
      this.editorMutationObserver.disconnect();
    }

    this.renderedVNodes.forEach(destroy => destroy());
  },
  methods: {
    ...mapPiniaActions(usePublishingOetStore, ['createOpenEndedTask']),
    ...mapActions('glossaryModule', [
      'fetchGlossaryTerms',
      'setIdFilters',
      'setTermFilter',
      'setPageSize',
      'setSelectedSubjectId',
    ]),
    ...mapActions(EDIT_SECTION_MODULE_PREFIX, ['fetchLinkableSections']),
    ...mapActions('subjectNodeFeatureModule', ['fetchFeatureList']),
    async getOrFetchGlossaryTerms() {
      const shouldRefetchGlossaryTerms =
        this.isGlossaryRefetchRequired || this.glossarySubjectId !== this.subjectId;
      if (shouldRefetchGlossaryTerms) {
        this.setPageSize(10000);
        this.setIdFilters([]);
        this.setTermFilter(null);
        this.setSelectedSubjectId(this.subjectId);
        await this.fetchGlossaryTerms({ pageNumber: 1 });
      }

      return this.glossaryTerms;
    },
    async getOrFetchLinkableSections() {
      const { sectionId, subjectId } = this.linkableSectionsMeta || {};
      const isLinkableSectionsRefetchRequired =
        sectionId !== this.sectionId || subjectId !== this.subjectId;
      if (isLinkableSectionsRefetchRequired) {
        await this.fetchLinkableSections({ sectionId: this.sectionId, subjectId: this.subjectId });
      }
      return this.linkableSections;
    },
    async getOrFetchLinkableActivities() {
      const { featureIds, subjectId } = this.linkableFeaturesMeta || {};
      const isLinkableActivitiesRefetchRequired =
        subjectId !== this.subjectId || featureIds?.length > 0;

      if (isLinkableActivitiesRefetchRequired) {
        const subjectTreeRootNote = this.subject?.subject_tree[0];
        if (subjectTreeRootNote) {
          await this.fetchFeatureList({ featureIds: [], subjectId: this.subjectId });
          const leafNodes = getLeafNodes(subjectTreeRootNote);
          this.linkableActivities = buildLinkableActivities(this.linkableFeatures, leafNodes);
        } else {
          this.linkableActivities = [];
        }
      }
      return this.linkableActivities;
    },

    onEditorInput(contentData) {
      this.$emit('update:content', contentData);
    },
    onEditorReady(instance) {
      this.editorInstance = instance;
      this.$emit('editorready', instance);
    },
    onEditorFocus() {
      this.$emit('editorfocus');
    },
    onContentStatsChange(stats) {
      this.$emit('contentStatsUpdate', stats);
    },
    addInvalidExerciseQuestion(question) {
      this.$emit('addInvalidExerciseQuestion', question);
    },
  },
};
</script>
