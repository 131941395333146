/* eslint-disable max-classes-per-file */

import url from 'url';
import isNil from 'lodash/isNil.js';

export const cloudFrontHosts = ['d3vrb2m3yrmyfi.cloudfront.net', 'lanterna-edu.s3.amazonaws.com'];
export const imgixHosts = ['kognity-prod.imgix.net', 'kognity-test.imgix.net'];

export class BaseImageProvider {
  constructor(src) {
    this.url = new URL(src);
  }

  getImageUrl(params) {
    this.url.search = new URLSearchParams(params);
    return this.url;
  }
}

export class ImgixProvider extends BaseImageProvider {}
export class LocalImageProvider {
  constructor(src) {
    this.url = src;
  }

  // eslint-disable-next-line no-unused-vars
  getImageUrl(_searchParams) {
    return this.url;
  }
}

export function isImgixUrl(imageUrl) {
  return url.parse(imageUrl) && Boolean(imgixHosts.some(host => imageUrl.includes(host)));
}
export function isCloudfrontUrl(imageUrl) {
  return url.parse(imageUrl) && Boolean(cloudFrontHosts.some(host => imageUrl.includes(host)));
}

export function getImageProvider(src) {
  if (isNil(src) || src === '') {
    return new LocalImageProvider(src);
  }
  if (isImgixUrl(src) || isCloudfrontUrl(src)) {
    return new ImgixProvider(src);
  }
  return new LocalImageProvider(src);
}

export function getImgixSrc(imageSrc, maxWidth = 500) {
  if (!imageSrc || isImgixUrl(imageSrc)) {
    return imageSrc;
  }
  const urlObj = new URL(imageSrc);
  if (isCloudfrontUrl(urlObj.host)) {
    urlObj.host = imgixHosts[0];
  }
  urlObj.query = {
    ...urlObj.query,
    w: maxWidth,
    auto: 'compress',
  };
  return url.format(urlObj);
}

export default getImgixSrc;
