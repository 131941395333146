import { Plugin } from '@ckeditor/ckeditor5-core';

import FITBQBlankEditing from './fitbq-blank-editing.js';
import FITBQBlankUI from './fitbq-blank-ui.js';

export default class FITBQBlank extends Plugin {
  static get requires() {
    return [FITBQBlankEditing, FITBQBlankUI];
  }
}
