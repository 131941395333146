<template>
  <div class="ActivityStreakSummary muted">
    <div class="ActivityStreakSummary-item padd-right-fine-1">
      Current streak:
      <span class="ActivityStreakSummary-numbers">
        {{ aggregatedActivity.current_day_streak }}
        day{{ aggregatedActivity.current_day_streak === 1 ? '' : 's' }}
      </span>
    </div>
    <div class="ActivityStreakSummary-item">
      Longest streak:
      <span class="ActivityStreakSummary-numbers">
        {{ aggregatedActivity.best_day_streak }}
        day{{ aggregatedActivity.best_day_streak === 1 ? '' : 's' }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityStreakSummary',
  props: {
    aggregatedActivity: {
      type: Object,
      required: true,
      default: () => ({
        current_day_streak: 0,
        best_day_streak: 0,
        number_unique_days: 0,
      }),
    },
  },
};
</script>

<style scoped>
.ActivityStreakSummary {
  display: flex;
  justify-content: space-between;
  padding-top: var(--space-xs);
}

.ActivityStreakSummary-item {
  text-align: center;
}

.ActivityStreakSummary-numbers {
  font-size: var(--kog-font-size-default);
  font-weight: bold;
}
</style>
