<template>
  <div>
    <tree-node
      v-for="topicNode in topicsList"
      :key="topicNode.id"
      :node="topicNode"
      :root-node-name="rootNodeName"
      :disabled="!isNodeEnabledByNodeId(topicNode.id)"
      :disabled-node-tooltip="disabledNodeTooltip"
      :disabled-node-ids="disabledNodeIds"
      :max-level-shown="maxLevelShown"
      :selected-node="selectedNode"
      :tags-by-node-id="tagsBySubjectNodeId"
      :tag-direction="tagDirection"
      :selected-items="selectedItems"
      :expanded-node-ids="expandedNodeIds"
      :should-expand-root-node="shouldExpandRootNode"
      @select-node="selectNode"
    />
  </div>
</template>

<script>
import TreeNode from 'learning/common/components/tree-node.vue';

export default {
  name: 'BookTree',
  components: {
    TreeNode,
  },
  props: {
    topics: {
      type: Array,
      default: () => [],
    },
    maxLevelShown: {
      type: Number,
      required: true,
    },
    tagsBySubjectNodeId: {
      type: Object,
      default: () => ({}),
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    selectedNode: {
      type: Object,
      default: () => ({}),
    },
    subjectMap: {
      type: Object,
      required: true,
    },
    rootNodeName: {
      type: String,
      default: 'All',
    },
    disabledNodeIds: {
      type: Array,
      default: () => [],
    },
    disabledNodeTooltip: {
      type: String,
      default: null,
    },
    shouldExpandRootNode: {
      type: Boolean,
      default: false,
    },
    tagDirection: {
      type: String,
      default: 'column',
      validator: value => ['column', 'row'].includes(value),
    },
  },
  emits: ['node-selected'],
  data() {
    return {
      topicsList: this.topics,
      expandedNodeIds: [],
    };
  },
  watch: {
    topics: {
      handler(topics) {
        const isTopicsListEmptyOnCreate = this.topicsList.length === 0;
        if (isTopicsListEmptyOnCreate) {
          this.topicsList = topics;
        }
      },
      deep: true,
    },
    selectedNode: {
      handler(node) {
        this.expandedNodeIds = [node.id];
        let nodeToExpand = node;
        while (nodeToExpand.parent) {
          const parentNode = this.subjectMap[nodeToExpand.parent];
          this.expandedNodeIds.push(parentNode.id);
          nodeToExpand = parentNode;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.selectedNode) {
      this.selectNode(this.topicsList[0]);
    }
  },
  methods: {
    selectNode(node) {
      const isAlreadySelected = node.id === this.selectedNode.id;
      if (!isAlreadySelected) {
        this.$emit('node-selected', node);
      }
    },
    isNodeEnabledByNodeId(nodeId) {
      return !this.disabledNodeIds.includes(nodeId);
    },
  },
};
</script>
