<template>
  <kog-modal
    title="Edit assignment"
    :close-modal="closeModal"
  >
    <template #modalBody>
      <teacher-assignment-edit
        ref="TeacherAssignmentEdit"
        :assignment="assignment"
        @update:assignment="assignment = $event"
        @reload:assignment="fetchAssignmentDetails"
      />
    </template>
    <template #modalFooter>
      <kog-button
        class="margin-right-xs"
        label="End now"
        button-style="basic"
        @click="openEndNowModal"
      />
      <kog-button
        label="Save"
        button-style="accent"
        :is-loading="$wait.is('edit_assignment/save_assignment')"
        @click="saveAssignment"
      />
    </template>
  </kog-modal>
</template>

<script>
import { storeToRefs } from 'pinia';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import EndNowModal from 'studyApp/components/teacher/assignments/end-now-modal.vue';
import TeacherAssignmentEdit from 'studyApp/pages/assignment/teacher-assignment-edit.vue';

export default {
  name: 'TeacherAssignmentEditModal',
  components: {
    KogButton,
    KogModal,
    TeacherAssignmentEdit,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    reloadAssignment: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const assignmentStore = useAssignmentStore();
    const { assignment } = storeToRefs(assignmentStore);
    const { fetchAssignment } = assignmentStore;
    return {
      assignment,
      fetchAssignment,
    };
  },
  methods: {
    async saveAssignment() {
      await this.$refs.TeacherAssignmentEdit.saveAssignmentDetails();
      this.closeModal();
    },
    openEndNowModal() {
      this.$modal(
        EndNowModal,
        {
          assignment: this.assignment,
          source: 'Edit assignment modal',
          afterEndAssignment: this.reloadAssignment,
        },
        { closeOnClickOutside: false, closeExisting: true },
      );
    },
  },
};
</script>
