<template>
  <kog-card :default-padding="false">
    <div class="padd-m margin-bottom-m">
      <div class="flexContainer flexContainer-alignCenter margin-bottom-s">
        <img
          alt=""
          class="ProfessionalDevelopmentCard-Icon margin-right-s"
          :src="iconUrl"
        />
        <h2 class="heading-xs">
          {{ title }}
        </h2>
      </div>
      <p class="text-regular margin-bottom-l">
        {{ description }}
      </p>
      <a
        class="ProfessionalDevelopmentCard-Link text-center body-text flexContainer flexContainer-column flexContainer-justifyCenter"
        target="_blank"
        :href="link"
      >
        {{ linkCopy }}
      </a>
    </div>
  </kog-card>
</template>

<script>
import KogCard from 'sharedApp/components/card/kog-card.vue';

export default {
  name: 'ProfessionalDevelopmentCard',
  components: { KogCard },
  props: {
    iconUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    linkCopy: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.ProfessionalDevelopmentCard-Icon {
  width: 40px;
  height: 40px;
}

.ProfessionalDevelopmentCard-Link {
  min-height: var(--space-xl);

  border-color: var(--kogPlatformGray071);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.ProfessionalDevelopmentCard-Link:hover,
.ProfessionalDevelopmentCard-Link:focus {
  color: var(--kogPlatformGray018);
  background: var(--kogPlatformGray096);
}
</style>
