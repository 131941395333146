<template>
  <div
    ref="topicPaginator"
    class="flexContainer"
    :style="cssVariables"
  >
    <template v-if="shouldDisplayPaginationNumbers">
      <span
        ref="topicPaginatorTerm"
        class="SubjectOverviewTopicPaginator-term flexChild-center"
        >{{ $term('topic') }}</span
      >
      <kog-paginator
        class="SubjectOverviewTopicPaginator-pagination"
        :is-new-style="true"
        :is-next-and-previous-buttons-visible="false"
        :current-page="currentTopicIndex + 1"
        :number-of-pages="numberOfTopics"
        :get-page-context-by-page-number="getPageContextByPageNumber"
        :paginator-size="paginatorSize"
        @change-page="selectTopicByPageNumber"
      />
    </template>
    <nav
      ref="topicPaginatorButtonGroup"
      v-kog-description:[getUniqueId(`navigation-description`)]="navigationDescription"
      class="margin-left-auto flexContainer"
    >
      <kog-round-button
        :tooltip="getPreviousTopicTooltip"
        class="SubjectOverviewTopicPaginator-button"
        icon-class="fa-chevron-left"
        button-style="primary"
        size="small"
        :aria-hidden="!hasPreviousTopic ? 'true' : null"
        :aria-label="getNavButtonAriaLabel('Previous')"
        :disabled="!hasPreviousTopic"
        @click="selectTopicByIndex(previousTopicIndex)"
      />
      <kog-round-button
        :tooltip="getNextTopicTooltip"
        class="SubjectOverviewTopicPaginator-button"
        icon-class="fa-chevron-right"
        button-style="primary"
        size="small"
        :aria-hidden="!hasNextTopic ? 'true' : null"
        :aria-label="getNavButtonAriaLabel('Next')"
        :disabled="!hasNextTopic"
        @click="selectTopicByIndex(nextTopicIndex)"
      />
    </nav>
  </div>
</template>
<script>
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogPaginator from 'sharedApp/components/base/pagination/kog-paginator.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import { VIEWPORT_M_PX } from 'sharedApp/const/css/media.js';

const PAGINATOR_SINGLE_NUMBER_MAX_WIDTH_PX = 28;

export default {
  name: 'SubjectOverviewTopicPaginator',
  components: {
    KogPaginator,
    KogRoundButton,
  },
  props: {
    currentTopicIndex: {
      type: Number,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
    navigationDescription: {
      type: String,
      required: true,
    },
    shouldDisplayPaginationNumbers: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['select-topic'],
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      shouldDisplayFullPagination: true,
      paginationSize: 12,
      paginatorButtonGroupWidth: 0,
      paginatorTermWidth: 0,
    };
  },
  computed: {
    paginatorSize() {
      return this.shouldDisplayFullPagination ? this.numberOfTopics : this.paginationSize;
    },
    numberOfTopics() {
      return this.topics.length;
    },
    nextTopicIndex() {
      if (this.currentTopicIndex < this.numberOfTopics - 1) {
        return this.currentTopicIndex + 1;
      }
      return null;
    },
    previousTopicIndex() {
      if (this.currentTopicIndex > 0) {
        return this.currentTopicIndex - 1;
      }
      return null;
    },
    getNextTopicTooltip() {
      const context = this.getPageContextByIndex(this.nextTopicIndex);
      return context ? context?.tooltip : null;
    },
    getPreviousTopicTooltip() {
      const context = this.getPageContextByIndex(this.previousTopicIndex);
      return context ? context?.tooltip : null;
    },
    hasNextTopic() {
      return this.nextTopicIndex !== null;
    },
    hasPreviousTopic() {
      return this.previousTopicIndex !== null;
    },
    cssVariables() {
      return {
        '--paginatorButtonGroupWidth': `${this.paginatorButtonGroupWidth}px`,
        '--paginatorTermWidth': `${this.paginatorTermWidth}px`,
        '--paginatorSingleNumberMaxWidthPx': `${PAGINATOR_SINGLE_NUMBER_MAX_WIDTH_PX}px`,
      };
    },
  },
  mounted() {
    this.paginatorButtonGroupWidth = this.$refs.topicPaginatorButtonGroup?.clientWidth ?? 0;
    this.paginatorTermWidth = this.$refs.topicPaginatorTerm?.clientWidth ?? 0;

    this.paginatorObserver = new ResizeObserver(this.setPaginationSize);
    this.paginatorObserver.observe(this.$refs.topicPaginator);
  },
  beforeUnmount() {
    this.paginatorObserver.disconnect();
  },
  methods: {
    setPaginationSize(entry) {
      const paginationContentRect = entry[0].contentRect;

      this.shouldDisplayFullPagination = paginationContentRect.width >= VIEWPORT_M_PX;
      this.paginationSize = Math.floor(
        (paginationContentRect.width - this.paginatorButtonGroupWidth - this.paginatorTermWidth) /
          PAGINATOR_SINGLE_NUMBER_MAX_WIDTH_PX,
      );
    },
    selectTopicByPageNumber(pageNumber) {
      const topicIndex = pageNumber - 1;
      this.selectTopicByIndex(topicIndex);
    },
    selectTopicByIndex(topicIndex) {
      this.$emit('select-topic', topicIndex);
    },
    getPageContextByPageNumber(pageNumber) {
      const topicIndex = pageNumber - 1;
      const topic = this.topics[topicIndex];

      return {
        page: topic?.number,
        tooltip: topic?.name,
      };
    },
    getPageContextByIndex(index) {
      const topic = this.topics[index];

      return {
        page: topic?.number,
        tooltip: topic?.name,
      };
    },
    getNavButtonAriaLabel(navigationDirection) {
      return `Navigate to ${navigationDirection} ${this.$term('topic')}.`;
    },
  },
};
</script>

<style scoped>
.SubjectOverviewTopicPaginator-pagination {
  display: flex;
  width: calc(100% - var(--paginatorButtonGroupWidth) - var(--paginatorTermWidth));
}

.SubjectOverviewTopicPaginator-pagination :deep(.KogPaginator-pageNumber) {
  width: var(--paginatorSingleNumberMaxWidthPx);
  margin: 0;
}

.SubjectOverviewTopicPaginator-button {
  margin-left: var(--space-xs);
}

.SubjectOverviewTopicPaginator-term {
  margin-right: var(--space-xs);
  font-weight: bold;
  color: var(--kog-colors-grey-900);
  text-transform: capitalize;
}

@media (--viewport-s) {
  .SubjectOverviewTopicPaginator-term {
    display: none;
  }
}
</style>
