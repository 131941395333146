// eslint-disable-next-line max-classes-per-file
import type { PollingStatusChoices } from './generated';

export type GetTaskResponse<T> = {
  task_id: string;
  status: PollingStatusChoices;
  data?: T;
};

export class MaxPollCountExceededError extends Error {
  constructor() {
    super('Exceeded max attempts.');
    this.name = 'MaxPollCountExceededError';
  }
}

export class TaskExpiredError extends Error {
  constructor() {
    super('Failed to fetch due to task status EXPIRED');
    this.name = 'TaskExpiredError';
  }
}

export class TaskFailedError extends Error {
  constructor(status: PollingStatusChoices) {
    super(`Failed to fetch due to task failure. Status: ${status}`);
    this.name = 'TaskFailedError';
  }
}

export class TaskMissingDataError extends Error {
  constructor() {
    super('Data attribute is missing in the task response');
    this.name = 'TaskMissingDataError';
  }
}

export function makePoller<TArgument, TResponse>(
  startTask: (arg: TArgument) => Promise<{ task_id: string }>,
  getTaskResponse: (arg: TArgument & { taskId: string }) => Promise<GetTaskResponse<TResponse>>,
  options: { pollingIntervalMs: number; maxPollCount: number } = {
    pollingIntervalMs: 2000,
    maxPollCount: 30,
  },
): (arg: TArgument) => Promise<TResponse> {
  return async (arg: TArgument) => {
    const startTaskResponse = await startTask(arg);
    const taskId = startTaskResponse.task_id;
    const { pollingIntervalMs, maxPollCount } = options;

    let taskResponse = await getTaskResponse({ ...arg, taskId });

    const sleep = () =>
      new Promise(res => {
        setTimeout(res, pollingIntervalMs);
      });

    let pollCount = 1;
    while (taskResponse.status === 'RUNNING' && pollCount < maxPollCount) {
      // eslint-disable-next-line no-await-in-loop
      taskResponse = await getTaskResponse({ ...arg, taskId });
      pollCount += 1;
      // eslint-disable-next-line no-await-in-loop
      await sleep();
    }

    if (pollCount >= maxPollCount) {
      throw new MaxPollCountExceededError();
    }

    if (taskResponse.status === 'EXPIRED') {
      throw new TaskExpiredError();
    }

    if (['FAILURE', 'UNKNOWN'].includes(taskResponse.status)) {
      throw new TaskFailedError(taskResponse.status);
    }

    if (taskResponse.data === undefined) {
      throw new TaskMissingDataError();
    }

    return taskResponse.data;
  };
}
