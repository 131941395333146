<template>
  <div class="SubtopicCheckmark">
    <donut-indicator-legacy
      v-if="progress !== 100"
      :completed="progress"
      :total="100"
      :show-numbers="false"
      color-scheme="blue"
      :stroke-width="6"
      :size="24"
      :overflow-visible="true"
      aria-label="Incomplete subtopic"
    />
    <kog-font-awesome-icon
      v-else
      size="custom"
      :custom-size="24"
      fa-class="fa-check-circle"
      class="SubtopicCheckmark--completed"
      aria-label="Completed subtopic"
    />
  </div>
</template>

<script>
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';
import DonutIndicatorLegacy from 'sharedApp/components/indicators/donut-indicator-legacy.vue';
import { isZeroToHundredOrUndefinedOrNull } from 'sharedApp/utils/validators.js';

export default {
  name: 'SubtopicCheckmark',
  components: {
    KogFontAwesomeIcon,
    DonutIndicatorLegacy,
  },
  props: {
    progress: {
      required: true,
      validator: isZeroToHundredOrUndefinedOrNull,
    },
  },
};
</script>

<style scoped>
.SubtopicCheckmark {
  display: flex;
  align-items: center;
  min-height: calc(2 * var(--space-unit));
}

.SubtopicCheckmark--completed {
  color: var(--kogPlatformBlueDarken10);
}
</style>
