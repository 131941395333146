import mixpanelModule from 'sharedApp/libs/mixpanel.js';

require('./highlight-colors.css');

function trackEvent(event, annotationObj, extraProps = {}) {
  if (!annotationObj) {
    return;
  }
  const annotationType = annotationObj.annotation.note ? 'Note' : 'Highlight';
  const { color } = annotationObj.annotation;
  mixpanelModule.trackEvent(event, {
    annotation_type: annotationType,
    ...(annotationObj.location || {}),
    color_name: color,
    ...extraProps,
  });
}

export function trackColorChange(annotationObj, extraProps) {
  const event = 'Private Annotations - Color change';
  trackEvent(event, annotationObj, extraProps);
}

export function trackSaveNote(annotationObj, extraProps) {
  const event = 'Private Annotations - Saved';
  trackEvent(event, annotationObj, extraProps);
}

export function trackDeleteNote(annotationObj, extraProps) {
  const event = 'Private Annotations - Delete';
  trackEvent(event, annotationObj, extraProps);
}

// Hex values are defined in highlightColors.css
export const DEFAULT_HIGHLIGHT_COLOR = 'green';
export const highlightColors = [DEFAULT_HIGHLIGHT_COLOR, 'yellow', 'blue', 'peach'];

export function getHighlightClass(annotationColor, active = false, selector = false) {
  let modifier = '';

  if (active) {
    modifier = '--active';
  }

  if (selector) {
    modifier = '--selector';
  }

  const color = highlightColors.includes(annotationColor)
    ? annotationColor
    : DEFAULT_HIGHLIGHT_COLOR;

  return `KogHighlight-${color}${modifier}`;
}
