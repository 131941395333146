import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';
import SubMenuItem from 'studyApp/menus/sub-menu-item.js';

export default class EsqPracticeTestSubMenuItem extends SubMenuItem {
  constructor(config) {
    const defaults = {
      key: 'practice/esq',
      displayText: `${getTerm('exam-style', config.educationSystem)} questions`,
    };
    super({ ...defaults, ...config });
  }

  navigateTo({ router, currentRoute }) {
    const params = {
      classSlug: currentRoute.params.classSlug,
      cid: currentRoute.params.cid,
      sid: currentRoute.params.sid,
    };
    this.goToRoute(router, currentRoute, 'classPracticeEsqSetup', params);
  }
}
