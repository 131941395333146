import { inject } from 'vue';
import { VueWaitInstance } from 'vue-wait';

type callback<P, R> = (...args: P[]) => Promise<R>;

export default function useWaitFor() {
  const waitUnsetError = new Error(
    'useWaitFor requires $wait from vue-wait to be provided in the Vue app',
  );
  const wait = inject<VueWaitInstance>('wait');

  if (typeof wait === 'undefined') throw waitUnsetError;

  function waitFor<P, R>(waiter: string, action: callback<P, R>) {
    if (typeof wait === 'undefined') return () => Promise.reject(waitUnsetError);

    return async (...args: Parameters<typeof action>) => {
      wait.start(waiter);
      try {
        return await action(...args);
      } finally {
        wait.end(waiter);
      }
    };
  }

  return { wait, waitFor };
}
