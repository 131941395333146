<template>
  <header
    ref="root"
    class="Navbar flexContainer flexContainer-spaceBetween"
  >
    <div class="Navbar-leftMenu flexContainer">
      <a
        class="Navbar-logoContainer flexContainer"
        href="/"
        target="_self"
        aria-label="Go to home"
      >
        <kog-logo />
      </a>
      <div class="Navbar-leftSlot">
        <slot name="leftSlot" />
      </div>
    </div>
    <div class="Navbar-rightMenu flexContainer flexContainer-alignCenter">
      <slot name="rightSlot" />
      <kog-navbar-account-dropdown
        :user="user"
        :school-id="schoolId"
      />
    </div>
  </header>
</template>

<script>
import KogNavbarAccountDropdown from 'sharedApp/components/core/kog-navbar-account-dropdown.vue';
import KogLogo from 'sharedApp/components/logo/kog-logo.vue';

export default {
  name: 'KogNavbar',
  components: {
    KogLogo,
    KogNavbarAccountDropdown,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    schoolId: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style scoped>
.Navbar {
  position: fixed;
  z-index: 500;
  top: 0;

  width: 100%;
  height: 50px;
  margin: 0;

  color: var(--kog-text-brand-hard);
  text-align: left;

  background-color: var(--kog-background-brand-900);
  box-shadow: 0 8px 16px -4px var(--kogShadow020);
}

.Navbar-logoContainer {
  width: 40px;
  padding: 13px;
}

@media (max-width: 1024px) {
  .Navbar-leftSlot {
    display: none;
  }
}
</style>
