import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useStore } from 'vuex';

import flags from 'sharedApp/const/feature-flags.ts';
import { featureFlags } from 'sharedApp/services/featureflags/featureflags.ts';

export default defineStore('featureFlags', () => {
  const store = useStore();
  const user = computed(() => store.getters['userModule/user']).value;
  const featureFlagService = featureFlags();

  // State
  const isStudentAssignmentBasketEnabled = ref(false);
  const isEdlinkAssignmentIntegrationEnabled = ref(false);
  const isMultipleClassAssignmentEnabled = ref(false);
  const isShowFiltersOnClassOverviewEnabled = ref(false);
  const isMultipartQuestionActivityEnabled = ref(false);

  // Actions
  async function fetchIsStudentAssignmentBasketEnabled() {
    isStudentAssignmentBasketEnabled.value = await featureFlagService.isFeatureEnabled(
      flags.ENABLE_STUDENT_ASSIGNMENT_BASKET,
      { user },
    );
  }

  async function fetchIsEdlinkAssignmentIntegrationEnabled(
    integrationProvider: string | null = null,
  ) {
    isEdlinkAssignmentIntegrationEnabled.value = await featureFlagService.isFeatureEnabled(
      flags.ENABLE_EDLINK_ASSIGNMENT_INTEGRATION,
      {
        user,
        lms_integration: integrationProvider,
      },
    );
  }

  async function fetchIsMultipleClassAssignmentEnabled(integrationProvider: string | null = null) {
    isMultipleClassAssignmentEnabled.value = await featureFlagService.isFeatureEnabled(
      flags.ENABLE_MULTIPLE_CLASS_ASSIGNMENT,
      {
        user,
        lms_integration: integrationProvider,
      },
    );
  }

  async function fetchShowFiltersOnClassOverview(subjectId: string) {
    isShowFiltersOnClassOverviewEnabled.value = await featureFlagService.isFeatureEnabled(
      flags.SHOW_FILTERS_ON_CLASS_OVERVIEW,
      { user, subject_id: subjectId },
    );
  }

  async function fetchIsMultipartQuestionActivityEnabled() {
    isMultipartQuestionActivityEnabled.value = await featureFlagService.isFeatureEnabled(
      flags.ENABLE_MULTIPART_QUESTION_ACTIVITY,
      { user },
    );
  }

  return {
    isStudentAssignmentBasketEnabled,
    isEdlinkAssignmentIntegrationEnabled,
    isMultipleClassAssignmentEnabled,
    isShowFiltersOnClassOverviewEnabled,
    isMultipartQuestionActivityEnabled,
    fetchIsStudentAssignmentBasketEnabled,
    fetchIsEdlinkAssignmentIntegrationEnabled,
    fetchIsMultipleClassAssignmentEnabled,
    fetchShowFiltersOnClassOverview,
    fetchIsMultipartQuestionActivityEnabled,
  };
});
