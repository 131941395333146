<template>
  <kog-loader :loading="$wait.is('loading_media_library/*')">
    <article class="kog-container-fluid MediaLibrary-ScrollContainer">
      <div class="kog-row MediaLibrary">
        <aside class="kog-col-3 MediaLibrary-Sidebar">
          <h4>Filter by resource type:</h4>
          <kog-filter-group
            :filter-list="filterList"
            @filter-tag="toggleFilter"
          />
          <h3>Select {{ $term('topic') }}</h3>
          <book-tree
            :topics="rootAndTopicNodes"
            :subject-map="subjectMap"
            :max-level-shown="maxSubjectTreeDepth"
            :selected-node="selectedSubjectNode"
            :tags-by-subject-node-id="tagsByNodeId"
            tag-direction="row"
            @node-selected="handleSelectSubjectNode"
          />
        </aside>
        <div class="kog-col-9 MediaLibrary-Masonry">
          <div class="MediaLibrary-Masonry-Text">
            <h3 class="text-normal">
              {{ subjectNodeName }}
            </h3>
            <p class="muted">
              {{ displayedResources.length }} item{{ displayedResources.length > 1 ? 's' : '' }}
            </p>
          </div>
          <resource-masonry
            v-if="displayedResources.length > 0"
            :resource-list="displayedResources"
            :subject="subject"
          />
        </div>
      </div>
    </article>
  </kog-loader>
</template>

<script>
import { defineComponent } from 'vue';
import { useHead } from '@unhead/vue';
import mapValues from 'lodash/mapValues.js';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState, useStore } from 'vuex';

import BookTree from 'learning/common/components/book-tree.vue';
import ResourceMasonry from 'learning/common/components/media-library/resource-masonry.vue';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import nodeLookupService from 'sharedApp/services/utils/nodeLookupService/node-lookup-service.js';
import KogFilterGroup from 'teachApp/components/kog-filter-group.vue';

const cannotFetchResourcesErrorMessage = `
  Could not fetch resources for subject.
  Please try again or contact support.
`;

export default defineComponent({
  name: 'MediaLibrary',
  components: {
    BookTree,
    KogFilterGroup,
    KogLoader,
    ResourceMasonry,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const { subject } = store.state.subjectModule;

    useHead({
      title: () => (subject ? `Media library - ${subject.name}` : 'Media library'),
    });
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      resourcesGroupedBySubjectNode: state =>
        state.mediaLibraryModule.resourcesGroupedBySubjectNode,
      subject: state => state.subjectModule.subject,
      subjectMap: state => state.subjectModule.subjectMap,
    }),
    ...mapGetters({
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    maxSubjectTreeDepth() {
      if (!this.subject) {
        return 1;
      }
      return Math.max(...Object.values(this.subjectMap).map(node => node.level));
    },
    rootAndTopicNodes() {
      if (!this.subject) {
        return [];
      }
      return [this.rootNode].concat(this.rootNode.children);
    },
    selectableNodeDescendantIds() {
      if (!this.subject) {
        return {};
      }
      // recursively walk the subject tree depth-first, and for each node
      // on the subtopic level or higher, generate a set of subjectnode id's
      // containing the node's own ID and the ID's of all its descendants
      const descendantsByNodeId = {};
      const getDescendantIds = node => {
        const descendants = [node.id, ...node.children.flatMap(getDescendantIds)];
        if (node.level <= this.maxSubjectTreeDepth) {
          descendantsByNodeId[node.id] = new Set(descendants);
        }
        return descendants;
      };
      getDescendantIds(this.rootNode);
      return descendantsByNodeId;
    },
    resourcesAtSelectedNode() {
      if (!this.selectedSubjectNode) {
        return [];
      }
      const activeNodeIds = this.selectableNodeDescendantIds[this.selectedSubjectNode.id];
      if (!activeNodeIds) {
        return [];
      }
      return this.resourcesGroupedBySubjectNode
        .filter(node => activeNodeIds.has(node.id))
        .flatMap(node => node.resources);
    },
    displayedResources() {
      const resources = this.resourcesAtSelectedNode.filter(
        resource => this.filterStates[resource.type],
      );
      return resources.map(resource => ({
        ...resource,
        isHidden: this.showSubjectNodeHiddenLabel(resource.subject_node_id),
      }));
    },
    resourceCounts() {
      return mapValues(this.selectableNodeDescendantIds, descendantIds =>
        this.resourcesGroupedBySubjectNode.reduce((acc, node) => {
          if (!descendantIds.has(node.id)) {
            return acc;
          }
          const filteredResourceCount = node.resources.filter(
            resource => this.filterStates[resource.type],
          ).length;
          return acc + filteredResourceCount;
        }, 0),
      );
    },
    tagsByNodeId() {
      return Object.keys(this.resourceCounts).reduce((result, nodeId) => {
        const count = this.resourceCounts[nodeId];
        const nodeTags = [
          {
            value: `${count}`,
          },
        ];
        if (this.showSubjectNodeHiddenLabel(nodeId)) {
          nodeTags.push({
            value: 'Hidden',
            type: 'warning',
          });
        }
        if (!this.subjectMap[nodeId].is_active) {
          nodeTags.push({
            value: 'Inactive',
            type: 'warning',
          });
        }
        return {
          ...result,
          [nodeId]: nodeTags,
        };
      }, {});
    },
    subjectNodeName() {
      if (!this.selectedSubjectNode) {
        return '';
      }
      const isRoot = this.selectedSubjectNode.level === 0;
      if (isRoot) {
        return this.selectedSubjectNode.name;
      }
      return `${this.selectedSubjectNode.formatted_number_including_ancestors} ${this.selectedSubjectNode.name}`;
    },
    filterList() {
      return [
        {
          filterText: 'Videos',
          filterKey: 'video',
          isSelected: this.filterStates.video,
        },
        {
          filterText: 'Images',
          filterKey: 'image',
          isSelected: this.filterStates.image,
        },
        {
          filterText: 'Interactives',
          filterKey: 'interactive',
          isSelected: this.filterStates.interactive,
        },
      ];
    },
    subjectNodeId() {
      if (!this.$route.query) {
        return null;
      }
      return parseInt(this.$route.query.subject_node_id, 10);
    },
    rootNode() {
      return this.subject.subject_tree[0];
    },
    selectedSubjectNode() {
      if (!this.subject) {
        return {};
      }
      if (!this.subjectNodeId) {
        return this.rootNode;
      }
      return nodeLookupService.findNodeById(this.subjectNodeId, this.subject.subject_tree);
    },
    filterStates() {
      const states = {
        video: !(`${this.$route.query.video}` === 'false'),
        image: !(`${this.$route.query.image}` === 'false'),
        interactive: !(`${this.$route.query.interactive}` === 'false'),
      };
      return states;
    },
  },
  async created() {
    try {
      await this.fetchResources({ subjectId: this.subjectId });
    } catch (error) {
      this.$toast.showError(cannotFetchResourcesErrorMessage);
      throw error;
    }

    this.$mixpanel.trackEvent('Media library - Open library', {
      subject_name: this.subject.name,
    });

    if (this.user.isTeacher()) {
      this.$event.track('load_media_library', {
        subject_id: this.subjectId,
        subject_class_id: this.classId,
      });
    }
  },
  methods: {
    ...mapWaitingActions('mediaLibraryModule', {
      fetchResources: 'loading_media_library/fetchResources',
    }),
    showSubjectNodeHiddenLabel(nodeId) {
      return this.user.isTeacher() && !this.isNodeVisible(nodeId);
    },
    handleSelectSubjectNode(selectedNode) {
      this.updateRoute({ subject_node_id: selectedNode.id });
    },
    toggleFilter({ filterKey }) {
      this.updateRoute({ [filterKey]: !this.filterStates[filterKey] });
      this.$mixpanel.trackEvent('Media library - Filter resources', {
        subject_name: this.subject.name,
      });
    },
    updateRoute(keys = {}) {
      const { params, query } = this.$route;
      const overriddenQuery = {
        ...query,
        ...keys,
      };
      this.$router.push({ name: 'classMediaLibrary', query: overriddenQuery, params });
    },
  },
});
</script>

<style scoped>
.MediaLibrary-Masonry {
  overflow: auto;

  height: 100%;
  padding: var(--space-l) 0 var(--space-l) var(--space-l);

  background-color: var(--kogPlatformGray098);
  border-left: 1px solid var(--kogPlatformGray084);
}

.MediaLibrary-Masonry-Text {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 var(--space-l) var(--space-s) 0;
}

.MediaLibrary-Sidebar {
  overflow: auto;
  height: 100%;
  padding-top: var(--space-l);
}

.MediaLibrary-ScrollContainer {
  position: absolute;
  bottom: 0;

  overflow: hidden;

  height: 100%;
  padding-top: 50px;
}

.MediaLibrary {
  height: 100%;
}
</style>
