<template>
  <div
    ref="root"
    class="ContentRating padd-l"
  >
    <vertical-expand-transition>
      <div
        v-if="!feedbackSent"
        class="ContentRating-container"
      >
        <p class="heading-s"> Rate {{ subjectNodeTerm }} {{ subjectNodeNameWithNumber }} </p>
        <p class="text-regular"> Help us improve the content and user experience. </p>
        <star-rating-bar :update-feedback-rating="updateFeedbackRating" />
        <vertical-expand-transition @end="afterExpand">
          <div
            v-if="feedback.rating"
            class="ContentRating-container"
          >
            <kog-textarea
              ref="ratingComment"
              v-model:value="feedback.comment"
              class="ContentRating-comment margin-top-s"
              label="Add a comment (Optional)"
            />
            <kog-button
              class="margin-top-l"
              :disabled="!feedback.rating"
              label="Send feedback"
              @click="submitFeedback()"
            />
          </div>
        </vertical-expand-transition>
      </div>
      <div v-else>
        <p class="heading-s"> Thank you for rating {{ subjectNodeNameWithNumber }}! </p>
      </div>
    </vertical-expand-transition>
  </div>
</template>

<script>
import VerticalExpandTransition from 'sharedApp/animations/vertical-expand.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import StarRatingBar from 'studyApp/components/star-rating-bar.vue';

export default {
  name: 'ContentRating',
  components: {
    StarRatingBar,
    VerticalExpandTransition,
    KogTextarea,
    KogButton,
  },
  mixins: [RoutesMixin],
  props: {
    subjectNode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      feedbackSent: false,
      feedback: {
        rating: null,
        comment: null,
      },
    };
  },
  computed: {
    subjectNodeTerm() {
      switch (this.subjectNode.level) {
        case 2:
          return this.$term('subtopic');
        case 3:
          return this.$term('section');
        default:
          return 'content';
      }
    },
    subjectNodeNameWithNumber() {
      return [this.subjectNode.formatted_number_including_ancestors, this.subjectNode.name].join(
        ' ',
      );
    },
  },
  methods: {
    updateFeedbackRating(rating) {
      this.feedback.rating = rating;
    },
    afterExpand() {
      this.$refs.root.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
      this.$refs.ratingComment.$refs.kogTextarea.focus({ preventScroll: true });
    },
    submitFeedback() {
      if (!this.feedback.rating) {
        return;
      }
      this.$event.track('submit_content_rating', {
        subject_id: this.subjectId,
        subject_class_id: this.classId,
        subject_node_id: this.subjectNode.id,
        rating: this.feedback.rating,
        comment: this.feedback.comment || '',
      });
      this.feedbackSent = true;
    },
  },
};
</script>

<style scoped>
.ContentRating {
  display: flex;
  justify-content: center;
  background-color: var(--kog-colors-yellow-100);
}

.ContentRating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContentRating-comment {
  width: 365px;
}
</style>
