const WEEKDAYS = Object.freeze({
  mon: 'monday',
  tue: 'tuesday',
  wed: 'wednesday',
  thu: 'thursday',
  fri: 'friday',
  sat: 'saturday',
  sun: 'sunday',
});

export const getWeekdayByAbbreviation = abr => {
  if (typeof abr !== 'string') return null;

  return WEEKDAYS?.[abr.toLowerCase()];
};

export default WEEKDAYS;
