import { getBlockTypeLabel } from 'publishingApp/store/modules/feature/activity-constants.ts';

import { ActivityContextInjection } from '../../activity-context.js';

const BlockMixin = {
  emits: ['update-content'],
  inject: {
    activity: {
      from: ActivityContextInjection,
      default: undefined,
    },
  },
  computed: {
    blockLabel() {
      return getBlockTypeLabel(this.content.block_type);
    },
  },
  methods: {
    updateContentField(field, updatedValue) {
      /**
       * editorFields: string[]
       * Keys on the block which renders ckeditors.
       * Due to ckeditor adding \n when rendering we don't want it to trigger update event on mount.
       */
      if (
        (this.editorFields ?? []).includes(field) &&
        this.content[field] === updatedValue.replace(/\n$/g, '')
      ) {
        return;
      }

      this.$emit('update-content', {
        [field]: updatedValue,
      });
    },
    updateContentItem(updatedItem) {
      this.$emit('update-content', updatedItem);
    },
  },
};

export default BlockMixin;
