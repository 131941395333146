<template>
  <div class="EditVisibilityButtons">
    <kog-button
      label="Cancel"
      size="small"
      :disabled="$wait.is('saving-hidden-nodes')"
      @click="cancel"
    />
    <kog-button
      label="Done"
      size="small"
      button-style="accent"
      :disabled="!isHiddenNodesModified || isEverythingHidden"
      :tooltip="doneButtonTooltip"
      :is-loading="$wait.is('saving-hidden-nodes')"
      @click="save"
    />
  </div>
</template>

<script>
import { differenceWith, fromPairs, isEqual, toPairs } from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapMutations, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

export default {
  name: 'EditVisibilityButtons',
  components: { KogButton },
  props: {
    subjectClassId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      visibilityEditingTransactionToken: state =>
        state.subjectClassModule.visibilityEditingTransactionToken,
      subjectNodesVisibilityMap: state => state.subjectClassModule.subjectNodesVisibilityMap,
      editedSubjectNodesVisibilityMap: state =>
        state.subjectClassModule.editedSubjectNodesVisibilityMap,
    }),
    ...mapGetters({
      isHiddenNodesModified: 'subjectClassModule/isHiddenNodesModified',
      isAllContentHiddenWhileEditing: 'subjectClassModule/isAllContentHiddenWhileEditing',
    }),
    isEverythingHidden() {
      return this.isAllContentHiddenWhileEditing(this.subject.subject_tree[0]);
    },
    doneButtonTooltip() {
      if (this.isEverythingHidden) {
        return 'Cannot hide all the content. Please enable at least something.';
      }
      if (!this.isHiddenNodesModified) {
        return 'Nothing is modified.';
      }
      return 'Confirm visibility changes';
    },
  },
  methods: {
    ...mapMutations({
      stopEditingVisibility: 'subjectClassModule/stopEditingVisibility',
      resetChangesOnHiddenNodes: 'subjectClassModule/resetChangesOnHiddenNodes',
      resetVisibilityEditingTransactionToken:
        'subjectClassModule/resetVisibilityEditingTransactionToken',
    }),
    ...mapWaitingActions({
      saveHiddenSubjectNodes: {
        action: 'subjectClassModule/saveHiddenSubjectNodes',
        loader: 'saving-hidden-nodes',
      },
    }),
    async save() {
      try {
        const visibilityDiff = fromPairs(
          differenceWith(
            toPairs(this.editedSubjectNodesVisibilityMap),
            toPairs(this.subjectNodesVisibilityMap),
            isEqual,
          ),
        );
        const hiddenNodeDiffCount = Object.values(visibilityDiff).filter(
          isVisible => !isVisible,
        ).length;
        const shownNodeDiffCount = Object.values(visibilityDiff).filter(
          isVisible => isVisible,
        ).length;

        await this.saveHiddenSubjectNodes(this.subjectClassId);

        const totalNodesHidden = Object.values(this.editedSubjectNodesVisibilityMap).filter(
          isVisible => !isVisible,
        ).length;
        const totalNodesShown = Object.values(this.editedSubjectNodesVisibilityMap).filter(
          isVisible => isVisible,
        ).length;

        this.stopEditingVisibility();
        this.$mixpanel.trackEvent('Hide / Show content: Done', {
          hidden_node_diff_count: hiddenNodeDiffCount,
          shown_node_diff_count: shownNodeDiffCount,
          total_nodes_hidden: totalNodesHidden,
          total_nodes_shown: totalNodesShown,
          transaction: this.visibilityEditingTransactionToken,
        });
        this.$event.track('save_subject_node_visibility', {
          subject_id: this.subject.id,
          subject_class_id: this.subjectClassId,
        });
        this.resetVisibilityEditingTransactionToken();
        this.$toast.showSuccess('Content visibility successfully updated');
      } catch (e) {
        this.$toast.showError('Failed to update content visibility, please try again');
        throw e;
      }
    },
    cancel() {
      this.resetChangesOnHiddenNodes();
      this.stopEditingVisibility();
      this.$mixpanel.trackEvent('Hide / Show content: Cancel', {
        transaction: this.visibilityEditingTransactionToken,
      });
      this.resetVisibilityEditingTransactionToken();
    },
  },
};
</script>

<style scoped>
.EditVisibilityButtons {
  z-index: 1;

  padding: var(--space-xxs);

  background-color: var(--kog-colors-white);
  border-radius: 8px;
  box-shadow: 0 4px 4px var(--kogShadow020);
}
</style>
