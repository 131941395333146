<template>
  <div>
    <div
      v-tooltip="{
        theme: 'kog-tooltip',
        content: nodeTooltipContent,
        boundary: 'document.body',
      }"
      :class="{
        'TreeNode-link--selected': isSelected,
        'margin-top-xxs': nodeLevel === 2,
        'TreeNode--disabled': disabled,
      }"
      class="TreeNode-link flexContainer flexContainer-spaceBetween padd-xxs cursorPointer"
      role="button"
      tabindex="0"
      @click="selectNode(node)"
      @keypress.enter="selectNode(node)"
    >
      <rotation
        v-if="showRotation"
        :angle="showChildNodes ? 90 : 0"
        class="TreeNode-caret"
        transform-origin="5px 10px"
      >
        <kog-icon
          class="flexChild-noGrow padd-right-xs"
          size="s"
          icon-class="fa-caret-right"
          theme="custom"
        />
      </rotation>
      <kog-icon
        v-else
        class="flexChild-noGrow padd-right-xs padd-top-xxs"
        :style="{
          color: paperIconColor,
        }"
        size="s"
        icon-class="fa-file"
        theme="custom"
      />
      <div
        v-if="nodeLevel === 0"
        class="flexChild-canGrow"
      >
        {{ rootNodeName }}
      </div>
      <div
        v-else
        class="flexChild-canGrow"
        :class="{
          'padd-right-s': shouldShowNodeTag,
        }"
      >
        {{ getNodeName(node) }}
      </div>
      <div
        v-if="shouldShowNodeTag"
        class="flexContainer flexContainer-center"
        :class="[tagDirection === 'row' ? 'flexContainer-row' : 'flexContainer-column']"
      >
        <kog-tag
          v-for="(tag, index) in nodeTags"
          :key="index"
          :hide-overflow="false"
          :class="{
            [marginBetweenTagsClass]: index !== nodeTags.length - 1,
          }"
          size="s"
          :icon-class="tag.iconClass || ''"
          :is-svg-icon="tag.isSvgIcon || false"
          :label="tag.value || ''"
          :type="tag.type"
        />
      </div>
    </div>
    <vertical-expand>
      <div
        v-if="showChildNodes"
        class="padd-left-m"
      >
        <tree-node
          v-for="childNode in node.children"
          :key="childNode.id"
          :node="childNode"
          :disabled="!isNodeEnabledByNodeId(childNode.id)"
          :disabled-node-tooltip="disabledNodeTooltip"
          :root-node-name="rootNodeName"
          :get-node-name="getNodeName"
          :max-level-shown="maxLevelShown"
          :selected-node="selectedNode"
          :selected-items="selectedItems"
          :tags-by-node-id="tagsByNodeId"
          :tag-direction="tagDirection"
          :expanded-node-ids="expandedNodeIds"
          @select-node="selectNode"
        />
      </div>
    </vertical-expand>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

import Rotation from 'sharedApp/animations/rotation.vue';
import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KOG_COLORS from 'sharedApp/services/colors/color-service.js';

export default {
  name: 'TreeNode',
  components: {
    KogTag,
    Rotation,
    KogIcon,
    VerticalExpand,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledNodeIds: {
      type: Array,
      default: () => [],
    },
    disabledNodeTooltip: {
      type: String,
      default: null,
    },
    getNodeName: {
      type: Function,
      default: node => `${node.formatted_number_including_ancestors} ${node.name}`,
    },
    rootNodeName: {
      type: String,
      default: 'All',
    },
    shouldExpandRootNode: {
      type: Boolean,
      default: false,
    },
    maxLevelShown: {
      type: Number,
      required: true,
    },
    tagsByNodeId: {
      type: Object,
      default: () => ({}),
      validator: tagsByNodeId => Object.values(tagsByNodeId).every(Array.isArray),
    },
    tagDirection: {
      type: String,
      default: 'column',
      validator: value => ['column', 'row'].includes(value),
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    selectedNode: {
      type: Object,
      default: () => ({}),
    },
    expandedNodeIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['select-node'],
  computed: {
    nodeLevel() {
      return parseInt(this.node.level, 10);
    },
    shouldShowNodeTag() {
      return this.node.id in this.tagsByNodeId;
    },
    nodeTags() {
      return this.tagsByNodeId[this.node.id] ? this.tagsByNodeId[this.node.id] : [];
    },
    showRotation() {
      return (
        this.isNodeLevelToBeShown &&
        this.node.children.length > 0 &&
        !this.node.is_special_introduction_node
      );
    },
    isNodeLevelToBeShown() {
      return this.nodeLevel >= this.minLevelShown && this.nodeLevel < this.maxLevelShown;
    },
    showChildNodes() {
      return (
        this.isExpanded && this.isNodeLevelToBeShown && !this.node.is_special_introduction_node
      );
    },
    minLevelShown() {
      if (this.shouldExpandRootNode) {
        return 0;
      }
      return 1;
    },
    isSelected() {
      return this.selectedNode.id === this.node.id;
    },
    isExpanded() {
      return this.expandedNodeIds.includes(this.node.id);
    },
    nodeTooltipContent() {
      if (this.disabled) {
        return this.disabledNodeTooltip;
      }
      return null;
    },
    marginBetweenTagsClass() {
      return this.tagDirection === 'column' ? 'margin-bottom-xxs' : 'margin-right-xxs';
    },
    tagTypeToColor() {
      return {
        default: KOG_COLORS.PLATFORM_GRAY_018,
        warning: KOG_COLORS.YELLOW_30,
        positive: KOG_COLORS.GREEN_30,
      };
    },
    paperIconColor() {
      return this.tagTypeToColor[this.nodeTags[0]?.type || 'default'];
    },
  },
  methods: {
    selectNode(node) {
      if (!this.disabled) {
        this.$emit('select-node', node);
      }
    },
    isNodeEnabledByNodeId(nodeId) {
      return !this.disabledNodeIds.includes(nodeId);
    },
  },
};
</script>

<style scoped>
.TreeNode-link {
  font-size: var(--kog-font-size-default);
  color: var(--kog-text-brand);
  border-radius: 3px;
}

.TreeNode-caret {
  width: 14px;
  height: 14px;
}

.TreeNode-link:hover {
  color: var(--kog-text-brand);
  background-color: var(--kog-list-item-hover-background);
}

.TreeNode-link--selected {
  background-color: var(--kog-list-item-selected-background);
}

.TreeNode-noIcon {
  width: 16px;
  height: 16px;
}

.TreeNode-link--selected:hover {
  background-color: var(--kog-list-item-selected-hover-background);
}

.TreeNode--disabled {
  color: var(--kog-text-muted);
}
</style>
