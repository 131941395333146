<template>
  <kog-modal
    :scrolling-long-content="false"
    :close-modal="closeModal"
  >
    <template #modalBody>
      <iframe
        title="hiddenFrame"
        name="hiddenFrame"
        class="display-none"
      />
      <div v-show="!isSubmitted">
        <div class="RequestTrialModal-body text-regular">
          <h1 class="heading-l text-center">
            {{ title }}
          </h1>
          <div class="kog-row text-center margin-top-m">
            <p class="margin-0">
              {{ demoConfig.paragraph }}
            </p>
            <p class="margin-top-s">
              <img
                alt=""
                src="~learning/common/images/mustard.svg"
                class="RequestTrialModal-mustardImage text-center"
              />
            </p>
          </div>
          <form
            ref="trialForm"
            :action="demoConfig.formActionUrl"
            method="post"
            target="hiddenFrame"
          >
            <div class="kog-row">
              <div class="kog-col-12 kog-col-md-6 margin-top-m">
                <kog-input
                  v-model:value="form.firstName"
                  label="First name"
                  name="first_name"
                  placeholder="Enter your first name"
                  required
                />
              </div>
              <div class="kog-col-12 kog-col-md-6 margin-top-m">
                <kog-input
                  v-model:value="form.lastName"
                  label="Last name"
                  name="last_name"
                  placeholder="Enter your last name"
                  required
                />
              </div>
            </div>
            <div class="kog-row">
              <div class="kog-col-12 kog-col-md-6 margin-top-m">
                <kog-input
                  v-model:value="form.schoolName"
                  label="School name"
                  required
                  name="school_name"
                  placeholder="Enter school name"
                />
              </div>
              <div class="kog-col-12 kog-col-md-6 margin-top-m">
                <roles-dropdown
                  v-model:value="form.role"
                  placeholder-text="Select role"
                  label="Role"
                  name="role"
                />
              </div>
            </div>
            <div class="kog-row">
              <div class="kog-col-12 kog-col-md-6 margin-top-m">
                <countries-dropdown
                  v-model:value="form.country"
                  name="country"
                  placeholder-text="Select country"
                  label="Country"
                  :popular-countries="demoConfig.popularCountries"
                />
              </div>
              <div
                v-if="isUnitedStates"
                class="kog-col-12 kog-col-md-6 margin-top-m"
              >
                <states-dropdown
                  v-model:value="form.state"
                  placeholder-text="Select state"
                  label="State"
                  name="state"
                />
              </div>
            </div>
            <div
              v-if="isUnitedStates"
              class="kog-row"
            >
              <div class="kog-col-12 kog-col-md-6 margin-top-m">
                <kog-input
                  v-model:value="form.zip"
                  label="Zip code"
                  name="zip_code"
                  required
                  placeholder="Enter zip code"
                />
              </div>
            </div>

            <div class="margin-top-m">
              <label for="contact_options"> How do you prefer to be contacted? </label>
              <div
                id="contact_options"
                class="flexContainer"
              >
                <div>
                  <kog-checkbox
                    v-model:is-checked="form.isPreferEmail"
                    label="Email"
                    name="preferred_way_of_contact"
                  />
                </div>
                <div class="margin-left-l">
                  <kog-checkbox
                    v-model:is-checked="form.isPreferPhone"
                    label="Phone"
                    name="preferred_way_of_contact"
                  />
                </div>
              </div>
            </div>
            <vertical-expand>
              <div v-if="form.isPreferPhone">
                <div class="margin-top-m">
                  <kog-input
                    v-model:value="form.phoneNumber"
                    label="Phone"
                    name="phone_number"
                    type="text"
                    placeholder="Enter your phone number including country code"
                  />
                </div>
                <div class="margin-top-m">
                  <kog-input
                    v-model:value="form.preferredContactTime"
                    label="When is a good time to contact you?"
                    name="preferred_contact_time"
                    placeholder="Add your preferences here"
                  />
                </div>
              </div>
            </vertical-expand>
            <vertical-expand>
              <div
                v-if="form.isPreferEmail"
                class="margin-top-m"
              >
                <kog-input
                  :value="user.email"
                  label="Email"
                  disabled
                />
              </div>
            </vertical-expand>
            <input
              type="hidden"
              name="opening_cta"
              :value="title"
            />
            <input
              type="hidden"
              name="preferred_way_of_contact"
              :value="preferredWayOfContact"
            />
            <input
              type="hidden"
              name="email"
              :value="user.email"
            />
            <div class="KogButtonSet KogButtonSet--right margin-top-l">
              <kog-button
                type="submit"
                :disabled="!isSubmittable"
                label="Submit"
                button-style="primary"
                @click.prevent="submitTrialForm"
              />
            </div>
          </form>
        </div>
      </div>
      <div
        v-if="isSubmitted"
        class="text-regular"
      >
        <h1 class="heading-l text-center">
          {{ demoConfig.successHeader }}
        </h1>
        <p class="margin-top-m text-center">
          {{ demoConfig.successParagraph }}
        </p>
        <div class="flexContainer flexContainer-flexEnd margin-top-l">
          <kog-button
            label="Close"
            button-style="primary"
            @click.prevent="closeModal"
          />
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { mapActions } from 'vuex';

import {
  ALL_DEMO_TYPES,
  IBDP_DEMO_TYPE,
  IGCSE_DEMO_TYPE,
  NGSS_DEMO_TYPE,
} from 'learning/common/constants/demo-type-constants.js';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import RolesDropdown from 'sharedApp/components/interactive-demo/roles-dropdown.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

import CountriesDropdown from './countries-dropdown.vue';
import StatesDropdown from './states-dropdown.vue';

export const SUBMIT_START_TRIAL_MIXPANEL_EVENT = 'Demo School - Submit Start Trial';

const UNITED_STATES = 'United States';

export const formConfig = {
  [IBDP_DEMO_TYPE]: {
    paragraph: `As a next step, a Kognity representative will be in touch to
      better understand your needs and help you and your students get started
      with Kognity.`,
    formActionUrl: 'https://go.kognity.com/l/583713/2023-01-25/tjz11y',
    successHeader: 'Great! We will be in touch soon',
    successParagraph: `As a next step, a Kognity representative will be in touch to
      better understand your needs and help you and your students get started
      on the platform.`,
    popularCountries: ['China', 'India', 'United Kingdom', UNITED_STATES],
    defaultCountry: '',
  },
  [IGCSE_DEMO_TYPE]: {
    paragraph: `As a next step, a Kognity representative will be in touch to
      better understand your needs and help you and your students get started
      with Kognity.`,
    formActionUrl: 'https://go.kognity.com/l/583713/2022-03-15/n31n31',
    successHeader: 'Great! We will be in touch soon',
    successParagraph: `As a next step, a Kognity representative will be in touch to
      better understand your needs and help you and your students get started
      on the platform.`,
    popularCountries: ['China', 'India', 'United Kingdom', UNITED_STATES],
    defaultCountry: '',
  },
  [NGSS_DEMO_TYPE]: {
    paragraph: `As a next step, a Kognity representative will be in touch to
      better understand your needs and help you and your students get started
      with Kognity.`,
    formActionUrl: 'https://go.kognity.com/l/583713/2022-01-31/mkff2c',
    successHeader: 'Great! We will be in touch soon',
    successParagraph: `As a next step, a Kognity representative will be in touch to
      better understand your needs and help you and your students get started
      on the platform.`,
    popularCountries: [UNITED_STATES],
    defaultCountry: UNITED_STATES,
  },
  NGL: {
    paragraph: `As a next step, we will be in touch to better understand your
      needs and help you and your students get started with Kognity.`,
    formActionUrl: 'https://go.kognity.com/l/583713/2024-02-13/ylktng',
    successHeader: 'Great! We will be in touch soon',
    successParagraph: `As a next step, we will be in touch to better understand your
      needs and help you and your students get started on the platform.`,
    popularCountries: [UNITED_STATES],
    defaultCountry: UNITED_STATES,
  },
};

export default {
  name: 'RequestTrialModal',
  components: {
    KogButton,
    KogInput,
    KogModal,
    KogCheckbox,
    VerticalExpand,
    CountriesDropdown,
    StatesDropdown,
    RolesDropdown,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    formType: {
      type: String,
      required: true,
      validator: formType => [...ALL_DEMO_TYPES, 'NGL'].includes(formType),
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        schoolName: '',
        role: '',
        country: '',
        state: '',
        zip: '',
        phoneNumber: '',
        preferredContactTime: '',
        isPreferEmail: false,
        isPreferPhone: false,
      },
      isSubmitted: false,
    };
  },
  computed: {
    demoConfig() {
      return formConfig[this.formType];
    },
    isSubmittable() {
      const fieldsToBeFilled = [
        this.form.firstName,
        this.form.lastName,
        this.form.schoolName,
        this.form.role,
        this.form.country,
      ];
      if (this.isUnitedStates) {
        fieldsToBeFilled.push(this.form.state);
        fieldsToBeFilled.push(this.form.zip);
      }
      if (this.form.isPreferPhone) {
        fieldsToBeFilled.push(this.form.phoneNumber);
      }
      const isContactInfoFilled = fieldsToBeFilled.every(Boolean);
      const isCommunicationSelected = this.form.isPreferEmail || this.form.isPreferPhone;
      return isContactInfoFilled && isCommunicationSelected;
    },
    isUnitedStates() {
      return this.form.country === UNITED_STATES;
    },
    preferredWayOfContact() {
      const preferredWaysOfContact = [];
      if (this.form.isPreferEmail) {
        preferredWaysOfContact.push('Email');
      }
      if (this.form.isPreferPhone) {
        preferredWaysOfContact.push('Phone');
      }
      return preferredWaysOfContact.join(', ');
    },
  },
  created() {
    this.form.country = this.demoConfig.defaultCountry;
  },
  methods: {
    ...mapActions('userModule', ['updateMe']),
    getUpdatedUser() {
      return {
        ...this.user,
        first_name: this.form.firstName,
        last_name: this.form.lastName,
      };
    },
    submitTrialForm() {
      this.$refs.trialForm.submit();
      this.isSubmitted = true;
      this.updateMe(this.getUpdatedUser());
      this.$mixpanel.trackEventViaBackend(SUBMIT_START_TRIAL_MIXPANEL_EVENT, {});
    },
  },
};
</script>

<style scoped>
.RequestTrialModal-body {
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}

.RequestTrialModal-mustardImage {
  width: 160px;
}
</style>
