<template>
  <kog-button
    :label="isUnlockButtonVisible ? 'Unlock the mark scheme' : 'Mark scheme unlocked!'"
    button-style="primary"
    :has-icon="true"
    :icon-class="isUnlockButtonVisible ? 'far fa-lock-alt' : 'far fa-unlock-alt'"
    :disabled="isAutoUnlock || !isUnlockButtonVisible"
    @click="showUnlockConfirmModal"
  />
</template>
<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';

export default {
  name: 'UnlockMarkschemeButton',
  components: {
    KogButton,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  emits: ['unlock-markscheme'],
  computed: {
    isAutoUnlock() {
      const markschemeUnlockedSetting = this.assignment.mark_scheme_status.unlocked_setting;
      return markschemeUnlockedSetting === 'AUTO_UNLOCK';
    },
    isUnlockButtonVisible() {
      return !this.isUnlocked && !this.isUnlockedSetting;
    },
    isUnlocked() {
      const markschemeUnlockedAt = this.assignment.mark_scheme_status.markscheme_unlocked_at;
      return markschemeUnlockedAt !== null;
    },
    isUnlockedSetting() {
      const markschemeUnlockedSetting = this.assignment.mark_scheme_status.unlocked_setting;
      return markschemeUnlockedSetting === 'UNLOCKED';
    },
  },
  methods: {
    showUnlockConfirmModal() {
      this.$modal(KogConfirmModal, {
        title: 'This cannot be undone',
        text: `Students who received this assignment will have access
          to the mark scheme for the questions. This action cannot be undone.`,
        buttonTitle: 'Unlock the mark scheme',
        buttonClass: 'KogButtonLegacy--primary',
        onConfirm: () => this.$emit('unlock-markscheme'),
      });
    },
  },
};
</script>
