<template>
  <kog-icon
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltipText,
      boundary: 'document.body',
      placement: 'left',
    }"
    :icon-class="iconName"
    :is-svg-icon="true"
    theme="custom"
    class="QuestionTypeLabel"
  />
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'QuestionTypeLabel',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    KogIcon,
  },
  props: {
    questionType: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconName() {
      const iconMap = {
        MultipleChoiceQuestion: 'MCQ',
        ShortTextQuestion: 'STQ',
        FillInTheBlanksQuestion: 'FTBQ',
      };
      return iconMap[this.questionType];
    },
  },
};
</script>

<style scoped>
.QuestionTypeLabel {
  color: var(--kogPlatformGray064);
  fill: currentColor;
}

.QuestionTypeLabel:hover {
  color: var(--kogPlatformGray051);
  fill: currentColor;
}
</style>
