<template>
  <div
    v-if="shouldRenderBox"
    ref="root"
    class="ContentBox"
    :style="{
      backgroundColor: template.color,
    }"
  >
    <div class="ContentBox-icon">
      <kog-icon
        :icon-class="template.icon"
        fa-style="regular"
      />
    </div>
    <div class="ContentBox-title">
      <component :is="titleTag">
        {{ template.title }}
      </component>
    </div>
    <div
      class="ContentBox-content"
      v-html="content"
    />
  </div>
</template>

<script>
import {
  CORE_EDUCATION_LEVEL,
  EXTENDED_EDUCATION_LEVEL,
} from 'learning/common/constants/content-boxes.js';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'ContentBox',
  components: {
    KogIcon,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    titleTag: {
      type: String,
      default: 'span',
    },
    content: {
      type: String,
      required: true,
    },
    userLevel: {
      type: Object,
      default: null,
    },
  },
  computed: {
    shouldRenderBox() {
      return (
        this.userLevel === null ||
        this.userLevel.name.toLowerCase() === EXTENDED_EDUCATION_LEVEL ||
        (this.userLevel.name.toLowerCase() === CORE_EDUCATION_LEVEL &&
          this.template.education_level !== EXTENDED_EDUCATION_LEVEL)
      );
    },
  },
};
</script>
