<template>
  <div
    v-show="isReady"
    ref="root"
    class="ExerciseQuestionBox"
    :style="style"
  >
    <div class="ExerciseQuestionBox-header">
      <div class="ExerciseQuestionBox-iconDiv">
        <kog-font-awesome-icon
          fa-class="fa-spell-check"
          fa-style="regular"
          size="custom"
          :custom-size="17"
        />
      </div>
      <div
        class="ExerciseQuestionBox-title flexContainer flexContainer-spaceBetween"
        data-gramm="false"
      >
        <h4>{{ title }}</h4>

        <kog-round-button
          v-if="isInReviewMode"
          :icon-class="isQuestionsVisible ? 'fa-angle-up' : 'fa-angle-down'"
          aria-label="Toggle questions"
          button-style="secondary-basic"
          icon-size="m"
          @click="toggleQuestionsVisibility"
        />
      </div>
      <div class="ExerciseQuestionBox-subtitle">
        {{ subTitle }}
      </div>
    </div>
    <div
      class="ExerciseQuestionBox-content"
      data-gramm="false"
    >
      <exercise-question-preview-group
        v-if="isInReviewMode"
        :subject-node-id="subjectNodeId"
        :questions="questions"
        :is-visible="isQuestionsVisible"
      />
      <exercise-question-group
        v-else
        :subject-node-id="subjectNodeId"
        :questions="questions"
        :tracking-props="trackingProps"
      />
    </div>
  </div>
</template>

<script>
import ExerciseQuestionGroup from 'learning/common/components/exercise-question/exercise-question-group.vue';
import ExerciseQuestionPreviewGroup from 'learning/common/components/exercise-question/exercise-question-preview-group.vue';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';

export default {
  name: 'ExerciseQuestionBox',
  components: {
    KogFontAwesomeIcon,
    KogRoundButton,
    ExerciseQuestionGroup,
    ExerciseQuestionPreviewGroup,
  },
  props: {
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Exercise box title',
    },
    subTitle: {
      type: String,
      default: 'Exercise box subtitle',
    },
    subjectNodeId: {
      type: Number,
      required: true,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    trackingProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isReady: false,
      isQuestionsVisible: !this.isInReviewMode,
    };
  },
  computed: {
    style() {
      const bgColor = this.isInReviewMode
        ? 'var(--kog-colors-grey-300)'
        : 'var(--kog-colors-white)';
      const padding = this.isInReviewMode
        ? 'var(--space-l) var(--space-l) var(--space-xxl)'
        : 'var(--space-l) 0 var(--space-xxl)';

      return {
        '--background-color': bgColor,
        '--padding': padding,
      };
    },
  },
  created() {
    this.isReady = true;
  },
  methods: {
    toggleQuestionsVisibility() {
      this.isQuestionsVisible = !this.isQuestionsVisible;
    },
  },
};
</script>

<style scoped>
.ExerciseQuestionBox {
  position: relative;

  padding: var(--padding);

  font-family: var(--kog-content-main);
  font-size: var(--kog-font-size-default);
  color: var(--kogPlatformGray018);

  background: var(--background-color);
  border-radius: 24px;
}

.ExerciseQuestionBox-header {
  position: relative;
}

.ExerciseQuestionBox-iconDiv {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-xxl);
  height: var(--space-xxl);

  color: var(--kog-colors-white);

  background-color: var(--kog-colors-aubergine-500);
  border-radius: 50%;
}

.ExerciseQuestionBox-title {
  margin-left: 56px;
}

.ExerciseQuestionBox-title h4 {
  margin: 0;
  padding: 0;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-navbar-icons);
  font-weight: bold;
  line-height: 40px;
  color: var(--kogPlatformGray018);
}

.ExerciseQuestionBox-subtitle {
  margin-bottom: var(--space-l);
  margin-left: 56px;

  font-family: var(--kog-lato);
  font-size: var(--kog-font-size-content);
  color: var(--kogPlatformGray018);
}
</style>
