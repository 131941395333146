<template>
  <div>
    <kog-table
      class="margin-top-l"
      :footer-text="paginationText"
      :pagination-current-page="currentPage"
      :pagination-number-of-pages="pageCount"
      :update-current-page="goToAssignmentsPage"
      :is-loading-table-data="isTableDataLoading"
      loading-table-data-message="Loading..."
      title="Assignments statistics"
    >
      <template #subtitle>
        <div class="padd-top-xs">
          Assignments completed: {{ completedAssignmentsCount }} /
          {{ assignmentsPaginationData.totalItems }}
        </div>
      </template>
      <template #colgroup>
        <col width="110px" />
      </template>
      <template #header>
        <kog-table-header>
          <kog-table-cell-header
            class="text-center padd-left-s"
            :is-padded-left="false"
          >
            <span>Completed</span>
            <kog-icon
              v-tooltip="{
                theme: 'kog-tooltip',
                content: tableTooltip,
              }"
              v-kog-focusable="true"
              icon-class="fa-circle-info"
              fa-style="regular"
              size="s"
              class="margin-left-xxs"
            />
          </kog-table-cell-header>
          <kog-table-cell-header> Assignment name </kog-table-cell-header>
          <kog-table-cell-header class="text-center"> Type </kog-table-cell-header>
          <kog-table-cell-header class="text-center"> Sections completed </kog-table-cell-header>
          <kog-table-cell-header class="text-center"> Questions correct </kog-table-cell-header>
          <kog-table-cell-header> Deadline </kog-table-cell-header>
        </kog-table-header>
      </template>
      <template #body>
        <kog-table-row
          v-for="assignment in assignments"
          :key="assignment.assignment_id"
        >
          <kog-table-cell-text>
            <div class="flexContainer flexContainer-justifyCenter">
              <kog-icon
                class="AssignmentsTab-completionIcon"
                :class="
                  assignment.is_completed
                    ? 'AssignmentsTab-completionIcon--completed'
                    : 'AssignmentsTab-completionIcon--notCompleted'
                "
                :icon-class="assignment.is_completed ? 'fa-check' : 'fa-times'"
                :label="assignment.is_completed ? 'Completed' : 'Not completed'"
                fa-style="regular"
                size="s"
                theme="custom"
              />
            </div>
          </kog-table-cell-text>
          <kog-table-cell-text>
            <router-link :to="getAssignmentUrl(assignment)">
              {{ assignment.assignment_name }}
            </router-link>
          </kog-table-cell-text>
          <kog-table-cell-icon
            :tooltip-text="getIconDetails(assignment).tooltip"
            size="s-touch"
            :icon-class="getIconDetails(assignment).iconClass"
            :fa-style="getIconDetails(assignment).style"
            :is-svg-icon="getIconDetails(assignment).isSVG"
          />
          <kog-table-cell-text class="text-center">
            <span v-if="assignment.is_reading_assignment">
              {{ assignment.reading_sections_completed }} /
              {{ assignment.reading_sections_count }}
            </span>
            <span v-else> - </span>
          </kog-table-cell-text>
          <kog-table-cell-text class="text-center">
            <span v-if="assignment.is_reading_assignment"> - </span>
            <span v-else>
              {{ assignment.correct_answer_count }} /
              {{ assignment.question_count }}
            </span>
          </kog-table-cell-text>
          <kog-table-cell-text>
            {{ getAssignmentDeadline(assignment) }}
          </kog-table-cell-text>
        </kog-table-row>
      </template>
      <template
        v-if="assignments.length === 0"
        #info
      >
        <span class="padd-xs"> No assignments have passed their deadline yet. </span>
      </template>
    </kog-table>
  </div>
</template>

<script>
import pluralize from 'pluralize';
import { mapWaitingActions } from 'vue-wait';
import { mapState } from 'vuex';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellIcon from 'sharedApp/components/tables/kog-table-cell-icon.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { getIconDetails } from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'AssignmentsTab',
  components: {
    KogIcon,
    KogTable,
    KogTableRow,
    KogTableHeader,
    KogTableCellHeader,
    KogTableCellIcon,
    KogTableCellText,
  },
  mixins: [RoutesMixin],
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('teacherInsightsModule', {
      assignments: state => state.studentAssignments,
      completedAssignmentsCount: state => state.studentCompletedAssignmentsCount,
      assignmentsPaginationData: state => state.studentAssignmentsPagination,
    }),
    isTableDataLoading() {
      return this.$wait.waiting('fetching_assignments_data');
    },
    currentPage() {
      return this.assignmentsPaginationData?.currentPage;
    },
    pageCount() {
      if (!this.assignmentsPaginationData) {
        return 0;
      }

      const { pageSize, totalItems } = this.assignmentsPaginationData;
      return Math.ceil(totalItems / pageSize);
    },
    paginationText() {
      if (this.assignments.count === 0) {
        return '';
      }

      const { pageSize, totalItems, currentPage } = this.assignmentsPaginationData;
      const firstItemNumber = pageSize * currentPage - pageSize + 1;
      const lastItemNumber = Math.min(pageSize * currentPage, totalItems);

      return `${firstItemNumber} - ${lastItemNumber} of ${totalItems}`;
    },
    tableTooltip() {
      const assignmentCount = this.assignments.length;
      return `Last ${assignmentCount} ${pluralize('assignment', assignmentCount)}.
        <br>Assignments only show after the deadline has passed.`;
    },
  },
  methods: {
    ...mapWaitingActions('teacherInsightsModule', {
      fetchStudentAssignments: 'fetching_assignments_data',
    }),
    goToAssignmentsPage(page) {
      this.fetchStudentAssignments({
        subjectClassId: this.classId,
        userId: this.userId,
        page,
      });
    },
    getAssignmentUrl(assignment) {
      const resolved = this.$router.resolve({
        name: 'teacherAssignmentDetails',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: assignment.assignment_id,
        },
      });

      return resolved.fullPath;
    },
    getIconDetails(assignment) {
      return getIconDetails(assignment.assignment_type);
    },
    getAssignmentDeadline(assignment) {
      return dateFormatter(new Date(assignment.assignment_deadline));
    },
  },
};
</script>

<style scoped>
.AssignmentsTab-completionIcon {
  width: var(--space-xl);
  height: var(--space-xl);
  border-radius: 100%;
}

.AssignmentsTab-completionIcon--completed {
  color: var(--kog-round-button-positive-color);
  background-color: var(--kog-round-button-positive-background);
}

.AssignmentsTab-completionIcon--notCompleted {
  color: var(--kog-round-button-danger-color);
  background-color: var(--kog-round-button-danger-background);
}
</style>
