import MenuItem from 'studyApp/menus/menu-item.js';

export default class NotebookMenuItem extends MenuItem {
  constructor(config) {
    const defaults = {
      key: 'notebook',
      displayText: 'Notebook',
      iconClassActive: 'fas fa-sticky-note',
      iconClassInactive: 'fal fa-sticky-note',
    };
    super({ ...defaults, ...config });
  }

  navigateTo({ router, currentRoute }) {
    const params = {
      classSlug: currentRoute.params.classSlug,
      cid: currentRoute.params.cid,
      sid: currentRoute.params.sid,
    };
    this.goToRoute(router, currentRoute, 'classNotebook', params);
  }
}
