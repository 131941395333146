<template>
  <reading-assignment-focus-landing-header
    :is-assignment-done="false"
    :has-deadline-passed="assignment.has_deadline_passed"
    :assignment-deadline="assignmentDeadline"
    :assignment-sent-at="assignmentSentAt"
    :assignment-name="assignmentName"
  >
    <template #aboveAssignmentName>
      <kog-tag
        v-if="assignment.has_deadline_passed"
        label="Assignment ended"
        type="warning"
        class="margin-bottom-xxs"
      />
    </template>

    <template #topRowRight>
      <kog-round-button
        class="ReadingAssignmentFocusHeader-topRow-editButton margin-xs"
        aria-label="edit assignment"
        icon-class="fa-pen"
        icon-style="regular"
        icon-size="s"
        size="small"
        tooltip="Edit assignment"
        button-style="basic"
        @click.prevent="openEditAssignmentModal"
      />
    </template>

    <template #actionsBottomRight>
      <div class="flexContainer flexContainer-alignCenter">
        <integration-assignment-link
          v-if="isSentToIntegration"
          :integration-provider="integrationProvider"
          class="margin-right-xs"
        />
        <kog-round-button
          aria-label="Duplicate assignment"
          tooltip="Duplicate assignment"
          icon-class="fa-copy"
          icon-size="s"
          size="small"
          button-style="basic"
          class="TeacherReadingAssignmentFocusLandingHeader--copyButton margin-right-xs"
          @click="copy"
        />
        <copy-shareable-assignment-link
          size="small"
          :assignment-id="assignmentId"
          :show-icon-only="true"
        />
      </div>
    </template>
  </reading-assignment-focus-landing-header>
</template>

<script>
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import IntegrationAssignmentLink from 'studyApp/components/assignment/integration-assignment-link.vue';
import CopyAssignmentModal from 'studyApp/components/teacher/assignments/copy-assignment-modal.vue';
import CopyShareableAssignmentLink from 'studyApp/components/teacher/assignments/details/copy-shareable-assignment-link.vue';
import ReadingAssignmentFocusLandingHeader from 'studyApp/pages/assignment/components/reading-assignment-focus-landing-header.vue';
import TeacherAssignmentEditModal from 'studyApp/pages/assignment/teacher-assignment-edit-modal.vue';

export default {
  name: 'TeacherReadingAssignmentFocusLandingHeader',
  components: {
    KogTag,
    KogRoundButton,
    IntegrationAssignmentLink,
    CopyShareableAssignmentLink,
    ReadingAssignmentFocusLandingHeader,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    integrationProvider: {
      type: String,
      default: '',
    },
  },
  emits: ['reload-assignment'],
  computed: {
    assignmentId() {
      return this.assignment.id;
    },
    assignmentName() {
      return this.assignment.name;
    },
    assignmentDeadline() {
      return dateTimeFormatter(this.assignment.deadline);
    },
    assignmentSentAt() {
      return dateTimeFormatter(this.assignment.sent_at);
    },
    isSentToIntegration() {
      return this.assignment.is_sent_to_integration;
    },
  },
  methods: {
    openEditAssignmentModal() {
      this.$modal(
        TeacherAssignmentEditModal,
        { reloadAssignment: () => this.$emit('reload-assignment') },
        { closeOnClickOutside: false },
      );
    },
    copy() {
      this.$modal(CopyAssignmentModal, {
        assignment: this.assignment,
        subjectSlug: this.assignment.subject_slug,
        source: 'teacher_assignment_details',
      });
    },
  },
};
</script>

<style scoped>
.ReadingAssignmentFocusHeader-topRow-editButton {
  color: var(--kog-text-default);
  background-color: var(--kog-colors-white);
}
.TeacherReadingAssignmentFocusLandingHeader--copyButton {
  background-color: var(--kogPlatformWhite);
}
</style>
