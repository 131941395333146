import { createVNode } from 'vue';

import type {
  SelectedTextMenuEvents,
  SelectedTextMenuProps,
} from 'learning/common/components/annotations-menu/selected-text-menu.types.ts';

import ContextMenu from 'learning/common/components/annotations-menu/context-menu.vue';
import SelectedTextMenu from 'learning/common/components/annotations-menu/selected-text-menu.vue';

import mountComponent from 'sharedApp/utils/mount-component.ts';

import 'learning/common/components/annotations-menu/selected-text-menu.css';

export default function mountSelectedTextMenu(
  props: SelectedTextMenuProps & SelectedTextMenuEvents,
) {
  return mountComponent({
    component: ContextMenu,
    children: {
      menuBody: () => createVNode(SelectedTextMenu, props),
    },
    elementCssClasses: ['ContextMenu'],
  });
}
