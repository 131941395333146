<template>
  <div class="QuestionAnswersHeatmap">
    <div class="QuestionAnswersHeatmap-shadowLeft" />
    <div class="QuestionAnswersHeatmap-shadowRight" />
    <div
      ref="heatmap"
      class="QuestionAnswersHeatmap-table flexContainer"
    >
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
      <div
        v-for="(question, index) in assignment.questions"
        :key="index"
        class="QuestionAnswersHeatmap-column margin-top-l"
        @click="openQuestionAnswersModal(question.id)"
      >
        <div
          class="QuestionAnswersHeatmap-cell QuestionAnswersHeatmap-columnTitle QuestionAnswersHeatmap-cell--noBorderTop text-center"
        >
          <span class="QuestionAnswerHeatmap-cell-questionIndex">Q{{ index + 1 }}</span>
          <span class="QuestionAnswerHeatmap-cell-viewResponses">Open responses</span>
        </div>
        <div
          v-for="occasion in occasions"
          :key="occasion.user_id"
          class="QuestionAnswersHeatmap-cell"
          :class="statusColorClass(occasion, question.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

import QuestionAnswersModal from 'studyApp/components/teacher/assignments/details/question-answers-modal.vue';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export default {
  name: 'QuestionAnswersHeatmap',
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    assignmentProgressStatus: {
      type: Object,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      required: true,
    },
    trackAssignmentDetailsStudentsEvent: {
      type: Function,
      required: true,
    },
    occasions: {
      type: Array,
      required: true,
    },
  },
  emits: ['overflown'],
  data() {
    return {
      isHeatmapScrolling: false,
      observer: null,
    };
  },
  mounted() {
    this.initObserver();
  },
  unmounted() {
    this.observer.disconnect();
  },
  methods: {
    initObserver() {
      this.observer = new ResizeObserver(this.onHeatmapRender);
      this.observer.observe(this.$refs.heatmap);
    },
    onHeatmapRender() {
      const { scrollWidth, clientWidth } = this.$refs.heatmap;
      this.isHeatmapScrolling = clientWidth < scrollWidth;
      if (this.isHeatmapScrolling) {
        this.observer.disconnect();
        this.$emit('overflown');
      }
    },
    statusColorClass(studentPracticeOccasion, questionId) {
      const studentCorrectAnswersList =
        studentPracticeOccasion.answered_questions.questions_correct_answer.map(
          ({ question_id: id }) => id,
        );
      const studentIncorrectAnswersList =
        studentPracticeOccasion.answered_questions.questions_incorrect_answer.map(
          ({ question_id: id }) => id,
        );
      if (studentCorrectAnswersList.includes(questionId)) {
        return 'QuestionAnswersHeatmap-cell--green';
      }
      if (studentIncorrectAnswersList.includes(questionId)) {
        return 'QuestionAnswersHeatmap-cell--red';
      }
      return 'QuestionAnswersHeatmap-cell--gray';
    },
    isDone(occasion) {
      return occasion.progress === this.assignmentProgressStatus.done;
    },
    openQuestionAnswersModal(questionId) {
      this.trackAssignmentDetailsStudentsEvent('Assignment Details - View heatmap modal');
      this.$modal(QuestionAnswersModal, {
        onModalClose: () => {
          this.trackAssignmentDetailsStudentsEvent('Assignment Details - Close heatmap modal');
        },
        assignment: this.assignment,
        questionId,
        showDifficulty: this.showDifficulty,
        isDone: this.isDone,
        trackAssignmentDetailsStudentsEvent: this.trackAssignmentDetailsStudentsEvent,
      });
    },
  },
};
</script>

<style scoped>
.QuestionAnswersHeatmap {
  position: relative;
  overflow: hidden;
}

.QuestionAnswersHeatmap-table {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 var(--space-xl) var(--space-l);
}

.QuestionAnswersHeatmap-table::after {
  content: '';
  min-width: var(--space-xl);
}

.QuestionAnswersHeatmap-columnTitle {
  color: var(--kog-text-brand);
}

.QuestionAnswersHeatmap-column:hover .QuestionAnswersHeatmap-columnTitle {
  position: relative;
}

.QuestionAnswersHeatmap-column {
  transition: transform 0.05s cubic-bezier(0.5, 1, 0.89, 1);
}

.QuestionAnswersHeatmap-column:hover {
  cursor: pointer;
  z-index: 1;
  box-shadow:
    0 0 0 16px var(--kogPlatformWhite),
    0 0 8px 16px var(--kogPlatformGray071);
}

.QuestionAnswersHeatmap-column:hover .QuestionAnswerHeatmap-cell-questionIndex {
  display: none;
}

.QuestionAnswerHeatmap-cell-viewResponses {
  position: relative;
  bottom: 10px;
  display: none;
  line-height: 16px;
}

.QuestionAnswersHeatmap-column:hover .QuestionAnswerHeatmap-cell-viewResponses {
  display: block;
}

.QuestionAnswersHeatmap-cell {
  width: 72px;
  height: 32px;
  border: 1px solid var(--kogPlatformWhite);
}

.QuestionAnswersHeatmap-cell--noBorderTop {
  border-top: 0;
}

.QuestionAnswersHeatmap-cell--red {
  background: var(--kog-progressbar-single-negative-color);
}

.QuestionAnswersHeatmap-cell--green {
  background: var(--kog-progressbar-single-positive-color);
}

.QuestionAnswersHeatmap-cell--gray {
  background: var(--kog-progressbar-single-positive-background);
}

.QuestionAnswersHeatmap-shadowLeft {
  pointer-events: none;

  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;

  width: var(--space-m);
  height: 100%;

  background: linear-gradient(
    90deg,
    var(--kogPlatformWhite) 0%,
    color-mod(var(--kogPlatformWhite) alpha(0%)) 100%
  );
}

.QuestionAnswersHeatmap-shadowRight {
  pointer-events: none;

  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;

  width: var(--space-m);
  height: 100%;

  background: linear-gradient(
    90deg,
    color-mod(var(--kogPlatformWhite) alpha(0%)) 0%,
    var(--kogPlatformWhite) 100%
  );
}
</style>
