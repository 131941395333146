<template>
  <kog-icon
    v-if="!isInTableCell"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltipText,
      autoHide: false,
      boundary: 'document.body',
    }"
    class="SentBefore"
    icon-class="fa-paper-plane"
    size="s"
    theme="custom"
  />
  <kog-table-cell-icon
    v-else
    icon-class="fa-paper-plane"
    :tooltip-text="tooltipText"
    size="s-touch"
  />
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogTableCellIcon from 'sharedApp/components/tables/kog-table-cell-icon.vue';

export default {
  name: 'QuestionSentBeforeIcon',
  components: {
    KogTableCellIcon,
    KogIcon,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    isInTableCell: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    tooltipText() {
      return 'Sent to class before';
    },
  },
};
</script>
