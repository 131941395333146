const questionMixin = {
  methods: {
    marksInfo(question) {
      const marks = question.marks || question.context?.marks;
      if (!marks) {
        return 'No marks';
      }
      const plural = marks !== 1 ? 's' : '';
      return `${marks} mark${plural}`;
    },
  },
};

export default questionMixin;
