<template>
  <td class="padd-left-s padd-right-s text-center">
    <div class="flexContainer flexContainer-alignCenter flexContainer-flexEnd">
      <template v-if="!showActionsInDropdown && !isKebabMenu">
        <template
          v-for="(action, index) in actions"
          :key="`action-${index}`"
        >
          <kog-round-button
            v-if="action.iconClass"
            v-tooltip="{
              theme: 'kog-tooltip',
              content: action.tooltipText,
              boundary: 'window',
              autoHide: false,
            }"
            :aria-label="action.tooltipText"
            :icon-class="action.iconClass"
            button-style="secondary-basic"
            size="small"
            @click.prevent="callAction(action)"
          />
          <button
            v-else
            v-tooltip="{
              theme: 'kog-tooltip',
              content: action.tooltipText,
              boundary: 'window',
              autoHide: false,
            }"
            class="KogButtonLegacy--link margin-right-xxs"
            :aria-label="action.tooltipText"
            @click.prevent="callAction(action)"
          >
            {{ action.text }}
          </button>
        </template>
      </template>
      <template v-else>
        <kog-dropdown-button
          :aria-label="dropdownAriaLabel"
          button-type="round"
          :dropdown-placement="dropdownPlacement"
          :icon-class="kebabIconClass"
          icon-style="regular"
          icon-size="m"
          :items="dropdownActions"
          button-style="secondary-basic"
          @select="clickActionInDropdown"
          @click.prevent
        />
      </template>
    </div>
  </td>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { VTooltip } from 'floating-vue';

import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export default {
  name: 'KogTableCellActions',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    KogDropdownButton,
    KogRoundButton,
  },
  props: {
    /**
     * The list of items to show in the menu.<br>
     * Structure of an **item**:<br>
     * - `text: String`<br>
     * - `callback: Function`<br>
     * - `iconClass: String` (optional)<br>
     * - `tooltipText: String` (optional)<br>
     */
    actions: {
      type: Array,
      required: true,
      default: () => [],
    },
    /**
     * Provides context to the click callbacks.
     */
    context: {
      type: Object,
      required: true,
    },
    /**
     * Allows to collapse the menu into a kebab menu
     * Used in combination with `collapseActionsWidth`
     */
    isActionsCollapsible: {
      type: Boolean,
      default: false,
    },
    /**
     * If the screen size is within the given width,
     * Then the cell actions menu will be displayed as a kebab menu
     * Used in combination with `isActionsCollapsible`
     * */
    collapseActionsWidth: {
      type: Number,
      default: 0,
    },
    /**
     * Sets the position of the dropdown in the kebab menu.
     * Accepts any value that is valid for v-popover `placement`
     */
    dropdownPlacement: {
      type: String,
      default: 'bottom-end',
    },
    dropdownAriaLabel: {
      type: String,
      default: '',
    },
    /**
     * If set to true, the menu will be displayed as a kebab menu.
     */
    isKebabMenu: {
      type: Boolean,
      default: false,
    },
    /**
     * The kebab menu can be styled through this prop to display horizontally or vertically.
     */
    kebabIconClass: {
      type: String,
      default: 'fa-ellipsis-v',
      validator: prop => ['fa-ellipsis-v', 'fa-ellipsis-h'].includes(prop),
    },
  },
  data() {
    return {
      resizeObserver: null,
      showActionsInDropdown: false,
    };
  },
  computed: {
    dropdownActions() {
      return this.actions.map((action, index) => ({
        value: index,
        iconClass: action.dropdownIconClass || action.iconClass,
        text: action.dropdownText || action.text,
        callback: action.callback,
      }));
    },
  },
  mounted() {
    if (this.isActionsCollapsible) {
      this.resizeObserver = new ResizeObserver(this.setActionDropdownVisibility).observe(
        document.body,
      );
    }
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(document.body);
    }
  },
  methods: {
    callAction(action) {
      action.callback(this.context);
    },
    clickActionInDropdown(action) {
      action.callback(this.context);
    },
    setActionDropdownVisibility() {
      const { clientWidth } = document.body;
      if (clientWidth < this.collapseActionsWidth) {
        this.showActionsInDropdown = true;
      } else {
        this.showActionsInDropdown = false;
      }
    },
  },
};
</script>
