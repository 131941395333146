<template>
  <div class="text-regular margin-bottom-xxxl">
    <div class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter">
      <google-class-pill :google-class="googleClass" />
      <kog-dropdown-button
        aria-label="Update or disconnect your google class"
        button-type="round"
        dropdown-placement="bottom-end"
        icon-class="fa-ellipsis-v"
        icon-size="m"
        :items="dropdownActions"
        button-style="secondary-basic"
        @select="clickActionInDropdown"
      />
    </div>
    <div>
      <div class="muted padd-top-s padd-bottom-xs padd-left-s"> Students </div>
      <ul
        v-if="sortedGoogleClassStudentsList.length > 0"
        class="padd-0 margin-0 list-style-none"
      >
        <li
          v-for="student in sortedGoogleClassStudentsList"
          :key="student.id"
          class="padd-s GoogleClassOffCanvasItem-studentName"
        >
          {{ student.name }}
        </li>
      </ul>
      <generic-warning-box
        v-else
        class="GoogleClassOffCanvasItem-warningBox"
        mode="info"
        :has-padd-bottom="false"
      >
        There are no students in this class.
      </generic-warning-box>
    </div>
  </div>
</template>

<script>
import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';
import GoogleClassPill from 'studyApp/components/teacher/google-class-pill.vue';

const ACTION_UPDATE = 'update';
const ACTION_DISCONNECT = 'disconnect';

export default {
  name: 'GoogleClassOffCanvasItem',
  components: {
    GoogleClassPill,
    GenericWarningBox,
    KogDropdownButton,
  },
  props: {
    googleClass: {
      type: Object,
      required: true,
    },
  },
  emits: ['disconnect-google-class', 'update-google-class'],
  data() {
    return {
      dropdownActions: [
        {
          value: ACTION_UPDATE,
          iconClass: 'fa-sync',
          text: 'Update',
        },
        {
          value: ACTION_DISCONNECT,
          iconClass: 'fa-unlink',
          text: 'Disconnect',
        },
      ],
    };
  },
  computed: {
    sortedGoogleClassStudentsList() {
      const studentNames = this.googleClass.students.map(student => {
        // eslint-disable-next-line prefer-const
        let { last_name: lastName, id, name } = student;
        if (!lastName) {
          const splitFullName = name.trim().split(/\s+/);
          lastName = splitFullName[splitFullName.length - 1];
        }

        return {
          id,
          name,
          lastName,
        };
      });

      return studentNames.sort((student1, student2) =>
        student1.lastName.localeCompare(student2.lastName),
      );
    },
  },
  methods: {
    clickActionInDropdown(item) {
      const { value } = item;
      if (value === ACTION_DISCONNECT) {
        this.$emit('disconnect-google-class', this.googleClass);
      }
      this.$emit('update-google-class', this.googleClass);
    },
  },
};
</script>

<style scoped>
.GoogleClassOffCanvasItem-studentName {
  border-bottom: 1px solid var(--kogPlatformGray093);
}

.GoogleClassOffCanvasItem-studentName:first-of-type {
  border-top: 1px solid var(--kogPlatformGray093);
}
</style>
