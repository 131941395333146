import { Plugin } from '@ckeditor/ckeditor5-core';
import { Typing } from '@ckeditor/ckeditor5-typing';
import { createDropdown } from '@ckeditor/ckeditor5-ui';

import type { Locale } from '@ckeditor/ckeditor5-utils';

import spacingIcon from 'publishingApp/assets/icons/distributed-spacing-horizontal-light.svg';

import SpacingFormView from './spacing-form.ts';

export default class SpacingUI extends Plugin {
  declare contentView: SpacingFormView;

  static get requires() {
    return [Typing] as const;
  }

  static get pluginName() {
    return 'SpacingUI' as const;
  }

  setupFactoryComponent(locale: Locale) {
    const dropdownView = createDropdown(locale);
    dropdownView.buttonView.set({
      label: 'Spacing',
      icon: spacingIcon,
      tooltip: true,
    });

    dropdownView.on('execute', (_evt, data) => {
      this.editor.execute('insertText', { text: data.unicode });
      this.editor.editing.view.focus();
    });

    dropdownView.on('change:isOpen', () => {
      if (!this.contentView) {
        this.contentView = new SpacingFormView(locale);
        this.contentView.delegate('execute').to(dropdownView);
        dropdownView.panelView.children.add(this.contentView);
      }
    });

    return dropdownView;
  }

  init() {
    const componentCreator = this.setupFactoryComponent.bind(this);
    this.editor.ui.componentFactory.add('spacing', componentCreator);
  }
}
