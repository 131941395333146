import { compile } from 'path-to-regexp';

import type { OpenEndedTask, ResponseServiceLTQResponse } from '@apis/open-ended-tasks.types.ts';
import type { LTQTextUserResponse } from 'sharedApp/composables/question-context/types.ts';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const root = '/api/open-ended-tasks';

const taskUrl = `${root}/`;
const responseListUrl = compile([root, ':taskId/responses/'].join('/'));
const responseDetailUrl = compile([root, ':taskId/responses/:responseId/'].join('/'));

export async function createOpenEndedTask(task: { question_id: number }) {
  const url = taskUrl;
  const response = await axios.post<OpenEndedTask>(url, task);
  return response.data;
}

export async function fetchOpenEndedTasks(subjectnode: number, all: 'True' | 'False' = 'True') {
  const url = taskUrl;
  const response = await axios.get<OpenEndedTask[]>(url, {
    params: { subjectnode, all },
  });
  return response.data;
}

export async function fetchOpenEndedTaskResponse(taskId: number) {
  const url = responseListUrl({ taskId });
  const response = await axios.get<ResponseServiceLTQResponse[]>(url);
  return response.data;
}

export async function createOpenEndedTaskResponse(
  taskId: number,
  userResponse: LTQTextUserResponse,
) {
  const url = responseListUrl({ taskId });
  const response = await axios.post<ResponseServiceLTQResponse>(url, userResponse);
  return response.data;
}

export async function updateOpenEndedTaskResponse(
  taskId: number,
  responseId: number,
  userResponse: LTQTextUserResponse,
) {
  const url = responseDetailUrl({ taskId, responseId });
  const response = await axios.put<ResponseServiceLTQResponse>(url, userResponse);
  return response.data;
}

export async function deleteOpenEndedTaskResponse(taskId: number, responseId: number) {
  const url = responseDetailUrl({ taskId, responseId });
  await axios.delete(url);
}
