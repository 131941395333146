import { ref } from 'vue';

import { fetchLmsClassesCategories } from '@apis/integrations.js';

const lmsIntegrationCategories = ref(null);
export const DEFAULT_LMS_POINTS_POSSIBLE = 100;
export default function useLmsIntegrationCategories() {
  const isLoadingLmsIntegrationCategories = ref(false);
  const loadLmsIntegrationCategories = async (integrationSlug, subjectId) => {
    isLoadingLmsIntegrationCategories.value = true;
    const categoriesData = await fetchLmsClassesCategories(integrationSlug, subjectId);
    lmsIntegrationCategories.value = categoriesData.categories_by_subject_class_id;
    isLoadingLmsIntegrationCategories.value = false;
  };

  return {
    lmsIntegrationCategories,
    loadLmsIntegrationCategories,
    isLoadingLmsIntegrationCategories,
  };
}
