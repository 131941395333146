<template>
  <div ref="root">
    <transition name="slide">
      <off-canvas
        v-if="offCanvasVisible"
        :heading="offCanvasHeading"
        @close="closeOffCanvas()"
      >
        <generic-warning-box
          v-if="totalUniqueGoogleStudents > totalKognityStudents"
          :has-padd-bottom="false"
          class="margin-top-l margin-bottom-l"
        >
          <b>Some students might not have access.</b>
          <p>
            There appears to be more students in your Google Classes than your Kognity class.
            Students must have access to this Kognity class to receive assignments.
          </p>
        </generic-warning-box>
        <generic-warning-box
          v-else-if="totalUniqueGoogleStudents < totalKognityStudents"
          :has-padd-bottom="false"
          class="margin-top-l margin-bottom-l"
        >
          <b>Some Kognity students might not be in a connected Google class.</b>
          <p>
            There appears to be more students in your Kognity class than in the connected Google
            classes.
          </p>
        </generic-warning-box>
        <div
          v-if="subjectClass"
          class="margin-top-s"
        >
          <google-class-off-canvas-item
            v-for="gc in subjectClass.google_classes"
            :key="gc.id"
            class="margin-top-m margin-bottom-m"
            :google-class="gc"
            @update-google-class="showUpdateGoogleClassModal"
            @disconnect-google-class="showDisconnectGoogleClassModal"
          />
        </div>
      </off-canvas>
    </transition>
    <div class="margin-bottom-xxl">
      <kog-loader
        :loading="!isLoaded"
        class="margin-top-m"
      />
      <div v-if="isLoaded">
        <header
          class="container-wideWidth flexContainer flexContainer-spaceBetween padd-left-m padd-right-m"
        >
          <div class="flexContainer margin-right-xxl">
            <div class="margin-right-m">
              <image-box
                :src="subject.logo_url"
                :w="104"
                :h="72"
                fit="clip"
                class="SubjectClassManagement-subjectImage"
              />
            </div>
            <div class="flexContainer flexContainer-column">
              <h2 class="margin-0 SubjectClassManagement-className">
                {{ subjectClass.name }}
              </h2>
              <div class="margin-top-xxs">
                <kog-tag :label="subjectClass.subject_name" />
                <kog-tag :label="subjectClass.end_date" />
              </div>
            </div>
          </div>
          <div>
            <kog-dropdown-button
              aria-label="Class actions"
              button-type="round"
              button-style="secondary-basic"
              dropdown-placement="bottom-end"
              icon-class="fa-ellipsis-v"
              icon-size="m"
              :items="manageClassMenuItems"
              @select="
                item => {
                  item.action();
                }
              "
            />
          </div>
        </header>
        <div
          class="container-wideWidth flexContainer margin-top-l padd-bottom-l padd-left-m padd-right-m"
        >
          <button
            v-if="!hideConnectToGoogleClassroom && connectedGoogleClassesTotal"
            class="KogButtonLegacy margin-right-xs"
            :disabled="offCanvasVisible"
            @click="openOffCanvas()"
          >
            <div class="flexContainer flexContainer-alignCenter">
              <kog-icon
                v-tooltip="{
                  theme: 'kog-tooltip',
                  content: googleClassroomIconInfo,
                  popperClass: 'text-center',
                  boundary: 'window',
                }"
                :aria-label="googleClassroomIconInfo"
                size="m"
                fa-style="regular"
                icon-class="fa-eye"
                class="margin-right-xs"
              />
              View connected classes ({{ connectedGoogleClassesTotal }})
            </div>
          </button>

          <button
            v-if="!hideConnectToGoogleClassroom"
            v-kog-new-dot="{
              key: getNewDotGoogleClassroomId(user.id),
              horizontalOffset: 180,
              verticalOffset: -10,
              tooltipText: newDotTooltipGoogleClassroom,
              isHidden: userProfile.isGoogleClassroomTeacher,
            }"
            class="KogButtonLegacy margin-right-xs"
            @click="loginGoogleClassroom()"
          >
            <div class="flexContainer flexContainer-alignCenter">
              <img
                :src="googleClassroomIcon"
                alt=""
                class="SubjectClassManagement-googleClassroomIcon margin-right-xs"
              />
              Connect to Classroom
            </div>
          </button>
          <kog-dropdown-button
            v-if="teachers && students"
            has-icon
            icon-class="fa-user-plus"
            button-style="primary"
            :items="userTypesForAdding"
            label="Add members"
            @select="onAddMember"
          />
        </div>
        <div class="SubjectClassManagement-fullScreenSeparator" />
        <div class="container-wideWidth padd-left-m padd-right-m">
          <h3 class="margin-top-xl"> Class members </h3>

          <class-teacher-list
            v-if="teachers"
            :teachers="teachers"
            :current-ordering="orderingTeachers"
            :is-teacher-page-loading="isTeacherTableLoading"
            :add-teachers="addTeachers"
            :remove-teacher="removeTeacher"
            @update-ordering="updateSortOrderTeachers"
          />

          <div class="margin-top-xxl">
            <class-student-list
              v-if="students"
              :students="sortedStudents"
              :current-ordering="orderingStudents"
              :subject-class-name="subjectClass.name"
              :subject-name="subjectClass.subject_name"
              :enable-level-column="shouldShowLevelsInfo"
              :is-students-page-loading="isStudentsTableLoading"
              :remove-student-from-class="removeStudent"
              :add-students-to-class="addStudents"
              @update-ordering="updateSortOrderStudents"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@unhead/vue';
import lodashProperty from 'lodash/property.js';
import lodashSortBy from 'lodash/sortBy.js';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapState, useStore } from 'vuex';

import dailySubjectActivityService from 'learning/common/services/activity/daily-subject-activity-service.js';

import ClassDetailsAddStudentsModalContainer from 'schoolAdminApp/pages/class-details/class-details-add-students-modal-container.vue';
import ClassDetailsAddTeachersModalContainer from 'schoolAdminApp/pages/class-details/class-details-add-teachers-modal-container.vue';
import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';
import sortingKeys from 'sharedApp/const/sorting-keys.js';
import googleClassroomIcon from 'sharedApp/img/google-classroom-yellow-icon-64x64_2x.png';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import DisconnectGoogleClassroomModal from 'studyApp/components/teacher/disconnect-google-classroom-modal.vue';
import EditClassModal from 'studyApp/components/teacher/edit-class-modal.vue';
import GoogleClassOffCanvasItem from 'studyApp/components/teacher/google-class-off-canvas-item.vue';
import OffCanvas from 'studyApp/components/teacher/off-canvas.vue';
import ClassStudentList from 'studyApp/components/teacher/students/class-student-list.vue';
import ClassTeacherList from 'studyApp/components/teacher/teachers/class-teacher-list.vue';
import UpdateGoogleClassroomModal from 'studyApp/components/teacher/update-google-classroom-modal.vue';
import { GOOGLE_CLASSROOM_PROVIDER_NAME } from 'studyApp/constants/google-classroom-constants.js';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';

const DefaultOrderingKey = sortingKeys.USER__FULL_NAME;

export default {
  name: 'SubjectClassManagement',
  components: {
    ImageBox,
    KogIcon,
    KogLoader,
    ClassTeacherList,
    ClassStudentList,
    KogDropdownButton,
    KogTag,
    OffCanvas,
    GoogleClassOffCanvasItem,
    GenericWarningBox,
  },
  mixins: [GoogleClassroomMixin],
  setup() {
    const store = useStore();
    const { subjectClass } = store.state.schoolstaffSubjectclassModule;

    useHead({
      title: () => (subjectClass ? `Manage class - ${subjectClass.name}` : 'Manage class'),
    });
  },
  data() {
    return {
      isLoaded: false,
      orderingTeachers: DefaultOrderingKey,
      orderingStudents: DefaultOrderingKey,
      offCanvasVisible: false,
      googleClassroomIcon,
      origin: 'manage-class',
      userTypesForAdding: [
        {
          value: 'teachers',
          text: 'Teachers',
          iconClass: 'fa-user-graduate',
        },
        {
          value: 'students',
          text: 'Students',
          iconClass: 'fa-user',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
      teachers: state => state.teacherListModule.classTeachers,
      students: state => state.teacherStudentListModule.addableClassStudents,
      userProfile: state => state.teacherDetailModule.userProfile,
      subject: state => state.subjectModule.subject,
    }),
    ...mapState('userModule', ['user']),
    hideConnectToGoogleClassroom() {
      return this.shouldHideConnectToGoogleClassroom(this.subjectClass.integrations);
    },
    connectedGoogleClassesTotal() {
      return this.subjectClass.google_classes.length;
    },
    offCanvasHeading() {
      return `Connected classes (${this.connectedGoogleClassesTotal})`;
    },
    googleClassroomIconInfo() {
      if (this.connectedGoogleClassesTotal > 0) {
        return this.getGoogleClassroomIconInfo(this.subjectClass.google_classes);
      }
      return '';
    },
    manageClassMenuItems() {
      return [
        {
          text: 'Edit class',
          action: this.showEditClassModal,
        },
      ];
    },
    isTeacherTableLoading() {
      return (
        this.$wait.waiting('fetching_class_teachers') ||
        this.$wait.waiting('removing_class_teacher') ||
        this.$wait.waiting('adding_class_teachers')
      );
    },
    isStudentsTableLoading() {
      return (
        this.$wait.waiting('fetching_class_students') ||
        this.$wait.waiting('adding_class_students') ||
        this.$wait.waiting('removing_class_student')
      );
    },
    subjectClassId() {
      return this.$route.params.cid;
    },
    subjectId() {
      return this.$route.params.sid;
    },
    schoolId() {
      return window.KOG.USER_CONSTANTS.User.school.id;
    },
    studentsInClass() {
      return this.students.filter(t => t.already_in_class);
    },
    subjectClassWithStudents() {
      const studentList = this.studentsInClass.map(student => student.user);
      return { ...this.subjectClass, ...{ studentList } };
    },
    totalKognityStudents() {
      return this.studentsInClass.length;
    },
    totalUniqueGoogleStudents() {
      const googleClasses = this.subjectClass.google_classes;
      const googleStudents = googleClasses.reduce(
        (students, googleClass) => students.concat(...googleClass.students),
        [],
      );
      const uniqueEmails = googleStudents.reduce(
        (emails, student) => emails.add(student.email),
        new Set(),
      );

      return uniqueEmails.size;
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subjectClass.possible_levels);
    },
    sortedStudents() {
      const studentList = this.students;
      if (!studentList) {
        return studentList;
      }
      if (this.orderingStudents === sortingKeys.LEVEL__NAME) {
        return lodashSortBy(studentList, lodashProperty('level.name'));
      }
      if (this.orderingStudents === `-${sortingKeys.LEVEL__NAME}`) {
        return lodashSortBy(studentList, lodashProperty('level.name')).reverse();
      }
      return studentList;
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchSubjectClass({ subjectClassId: this.subjectClassId, useCache: false }),
      this.getUserProfile(),
      this.fetchClassTeachers({ subjectClassId: this.subjectClassId }),
      this.fetchAddableClassStudents({ subjectClassId: this.subjectClassId }),
    ]);

    this.isLoaded = true;
    dailySubjectActivityService().registerDailySubjectActivity(this.subjectId, this.user.id);

    const googleClassroomSuccessParam = this.$route.query.gc_success;
    if (googleClassroomSuccessParam === 'true') {
      const googleClassId = this.$route.query.google_class_id;
      if (googleClassId) {
        const googleClass = this.subjectClass.google_classes.find(gc => gc.id === googleClassId);
        this.openOffCanvas();
        this.showUpdateGoogleClassModal(googleClass);
        this.resetGoogleClassIdQueryParam();
      } else {
        this.openConnectGoogleClassroomModal(
          this.origin,
          this.subjectClassWithStudents,
          this.onConnectGoogleClass,
        );
      }
    }
    if (googleClassroomSuccessParam === 'false') {
      this.showGoogleClassroomAuthenticationErrorToast();
    }
    this.resetGcSuccessQueryParam();
  },
  methods: {
    ...mapActions({
      fetchSubjectClass: 'schoolstaffSubjectclassModule/fetchSubjectClass',
      saveSubjectClass: 'teacherSubjectClassModule/saveClassDetails',
      getUserProfile: 'teacherDetailModule/getUserProfile',
      disassociateGoogleClass: 'teacherGoogleClassroomModule/disassociateGoogleClass',
      updateGoogleClass: 'teacherGoogleClassroomModule/updateGoogleClass',
    }),
    ...mapWaitingActions({
      fetchClassTeachers: {
        action: 'teacherListModule/fetchClassTeachers',
        loader: 'fetching_class_teachers',
      },
      fetchAddableClassStudents: {
        action: 'teacherStudentListModule/fetchAddableClassStudents',
        loader: 'fetching_class_students',
      },
      addStudentsToClass: {
        action: 'teacherStudentListModule/addStudentsToClass',
        loader: 'adding_class_students',
      },
      removeStudentFromClass: {
        action: 'teacherStudentListModule/removeStudentFromClass',
        loader: 'removing_class_student',
      },
      removeTeacherFromClass: {
        action: 'teacherListModule/removeTeacherFromClass',
        loader: 'removing_class_teacher',
      },
      addTeachersToClass: {
        action: 'teacherListModule/addTeachersToClass',
        loader: 'adding_class_teachers',
      },
    }),
    onAddMember(member) {
      switch (member.value) {
        case 'teachers':
          this.onAddTeachers();
          break;
        case 'students':
          this.onAddStudents();
          break;
        default:
          break;
      }
    },
    onAddStudents() {
      this.$modal(ClassDetailsAddStudentsModalContainer, {
        addStudents: this.addStudents,
        addableStudents: this.students.filter(student => student.yearclass.is_active),
      });
    },
    onAddTeachers() {
      this.$modal(ClassDetailsAddTeachersModalContainer, {
        addTeachers: this.addTeachers,
        schoolTeachers: this.teachers,
      });
    },
    async addStudents(data, numberOfStudentsAdded) {
      try {
        await this.addStudentsToClass({
          subjectClassId: this.subjectClassId,
          schoolId: this.schoolId,
          studentsIds: data.studentsSelectedForAdding,
        });
        const successMessage = `${numberOfStudentsAdded} student(s) successfully added to this class.`;
        this.$toast.showSuccess(successMessage);
      } catch {
        const errorMessage = 'Student(s) could not be added, please try again.';
        this.$toast.showError(errorMessage);
      }
    },
    async removeStudent(studentId, studentName) {
      try {
        await this.removeStudentFromClass({
          subjectClassId: this.subjectClassId,
          schoolId: this.schoolId,
          studentId,
        });
        const successMessage = `${studentName} successfully removed as a student from this class.`;
        this.$toast.showSuccess(successMessage);
      } catch {
        const errorMessage = `Failed to remove ${studentName} as a student from this class.`;
        this.$toast.showError(errorMessage);
      }
    },
    async addTeachers(data, numberOfTeachersAdded) {
      try {
        await this.addTeachersToClass({
          subjectClassId: this.subjectClassId,
          teacherIds: data.teacherIds,
        });
        const successMessage = `${numberOfTeachersAdded} teacher(s) successfully added to this class.`;
        this.$toast.showSuccess(successMessage);
      } catch {
        const errorMessage = 'Teacher(s) could not be added, please try again.';
        this.$toast.showError(errorMessage);
      }
    },
    isTheSameUserLoggedIn(teacherId) {
      return this.user.id === teacherId;
    },
    async removeTeacher(teacherId, teacherName) {
      const removedSelf = this.isTheSameUserLoggedIn(teacherId);
      try {
        await this.removeTeacherFromClass({
          subjectClassId: this.subjectClassId,
          teacherId,
          removedSelf,
        });
        const messageRemoveOther = `Successfully removed ${teacherName} from class.`;
        const messageRemoveSelf = `You successfully removed yourself from class ${this.className}.`;
        this.$toast.showSuccess(this.deletingSelf ? messageRemoveSelf : messageRemoveOther);
      } catch {
        const errorMessage = `
          Could not remove ${teacherName}.
          You must be a teacher from the class to remove a teacher.
          Please try again or contact support.
        `;
        this.$toast.showError(errorMessage);
      }
      if (removedSelf) {
        this.$router.push('/');
      }
      return true;
    },
    updateSortOrderTeachers(newSortingKey) {
      this.orderingTeachers = newSortingKey;
      this.fetchClassTeachers({
        subjectClassId: this.subjectClassId,
        params: {
          ordering: newSortingKey,
        },
        useCache: false,
      });
    },
    showEditClassModal() {
      this.$modal(EditClassModal, {
        subjectClass: this.subjectClass,
        educationSystem: this.subject.educationsystem.name,
        saveSubjectClass: this.saveSubjectClass,
        schoolId: this.user.school.id,
      });
    },
    updateSortOrderStudents(newSortingKey) {
      this.orderingStudents = newSortingKey;
      this.fetchAddableClassStudents({
        subjectClassId: this.subjectClassId,
        params: {
          ordering: newSortingKey,
        },
        useCache: false,
      });
    },
    openOffCanvas() {
      this.offCanvasVisible = true;
    },
    closeOffCanvas() {
      this.offCanvasVisible = false;
    },
    loginGoogleClassroom() {
      const { isGoogleClassroomTeacher } = this.userProfile;
      const connectData = {
        subjectClass: this.subjectClassWithStudents,
        onConnect: this.onConnectGoogleClass,
        origin: this.origin,
        isGoogleClassroomTeacher,
      };
      this.connectToClassroom(connectData);
    },
    onConnectGoogleClass() {
      this.fetchSubjectClass({ subjectClassId: this.subjectClassId, useCache: false });
    },
    showDisconnectGoogleClassModal(googleClass) {
      this.$modal(DisconnectGoogleClassroomModal, {
        disconnectGoogleClass: () => this.disconnectGoogleClass(googleClass.id),
        kognityClassName: this.subjectClass.name,
        googleClass,
      });
    },
    showUpdateGoogleClassModal(googleClass) {
      this.$modal(UpdateGoogleClassroomModal, {
        updateGoogleClass: async () => {
          try {
            await this.updateGoogleClass();
            this.$toast.showSuccess('Successfully updated');
          } catch {
            this.$toast.showError('Update failed, please try again in a few minutes.');
          }
        },
        trackUpdateGoogleClass: () => {
          this.$mixpanel.trackEvent('Class - Click Save Update Button', {
            source: this.origin,
            subject_class_id: this.subjectClassId,
            provider: GOOGLE_CLASSROOM_PROVIDER_NAME,
          });
        },
        googleClass,
        subjectClass: this.subjectClass,
        origin: this.origin,
      });
    },
    async disconnectGoogleClass(googleClassId) {
      const classId = this.subjectClassId;

      this.isLoaded = false;
      await this.disassociateGoogleClass({ classId, googleClassId });
      await this.fetchSubjectClass({ subjectClassId: classId, useCache: false });
      this.isLoaded = true;

      this.$toast.showSuccess('Class disconnected successfully');

      this.$mixpanel.trackEvent('Class disconnected', {
        source: this.origin,
        subject_class_id: this.subjectClass.id,
        subject_class_name: this.subjectClass.name,
        disconnected_from: GOOGLE_CLASSROOM_PROVIDER_NAME,
      });

      this.$refs.root.click(); // close the kebab menu
    },
  },
};
</script>

<style scoped>
.SubjectClassManagement-className {
  word-break: break-word;
}

.SubjectClassManagement-subjectImage {
  height: 72px;
  border-radius: var(--space-xxs);
}

.SubjectClassManagement-subjectImage :deep(img) {
  border-radius: var(--space-xxs);
}

.SubjectClassManagement-topMenu {
  align-items: flex-start;
  margin-right: 10px;
}

.SubjectClassManagement-fullScreenSeparator {
  border-bottom: 1px solid var(--kogPlatformGray084);
}

.SubjectClassManagement-googleClassroomIcon {
  width: 20px;
}

.slide-leave-active,
.slide-enter-active {
  transition: transform 0.15s ease-in-out 0s;
}

.slide-leave-from,
.slide-enter-to {
  transform: translateX(0%);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
