<template>
  <kog-rounded-dropdown
    id="account-dropdown-toggle"
    label="Your account"
    align-to="right"
  >
    <template #header>
      <div
        :class="{
          'padd-right-m padd-left-m': !newStyle,
          'padd-right-xs padd-left-xs': newStyle,
        }"
        class="flexContainer flexContainer-alignCenter"
      >
        <kog-avatar
          :src="user.profile.avatar_url"
          :name="userName"
          :size="newStyle ? 'm' : 's'"
          :theme="avatarTheme"
          :class="{
            'padd-right-xxs': !newStyle,
          }"
        />
        <div v-if="!newStyle">
          <span class="KogNavbarAccountDropdown-userName padd-right-fine-1">
            {{ user.first_name }}
          </span>
          <b class="caret" />
        </div>
      </div>
    </template>
    <template #dropdown>
      <ul
        class="padd-0 margin-0"
        aria-labelledby="account-dropdown-toggle"
      >
        <li
          v-for="(menuItem, index) in menuItemsToRender"
          :key="index"
        >
          <kog-dropdown-item
            :item="menuItem"
            :new-style="newStyle"
          />
        </li>
      </ul>
    </template>
  </kog-rounded-dropdown>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { reverse } from 'schoolAdminApp/urls.js';
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import KogDropdownItem from 'sharedApp/components/dropdown/kog-dropdown-item.vue';
import KogRoundedDropdown from 'sharedApp/components/dropdown/kog-rounded-dropdown.vue';
import intercomModule from 'sharedApp/libs/intercom.js';

export default {
  name: 'KogNavbarAccountDropdown',
  components: {
    KogAvatar,
    KogRoundedDropdown,
    KogDropdownItem,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    newStyle: {
      type: Boolean,
      default: false,
    },
    avatarTheme: {
      type: String,
      default: null,
    },
    schoolId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isSchoolAdmin() {
      const roles = this.user.roles || this.user.group_names;
      return roles.includes('SchoolAdmin');
    },
    userName() {
      return this.user.first_name ? `${this.user.first_name} ${this.user.last_name}` : '';
    },
    menuItemsToRender() {
      return this.menuItems.filter(item => item.shouldRender);
    },
    schoolUrl() {
      if (!this.schoolId) {
        return null;
      }
      return reverse('schoolAdminApp', 'classList', { school_id: this.schoolId });
    },
    menuItems() {
      return [
        {
          shouldRender: this.isSchoolAdmin && this.schoolId && this.newStyle,
          text: 'Manage school',
          href: this.schoolUrl,
          action: () => true,
        },
        {
          shouldRender: true,
          text: 'Account settings',
          href: '/accounts/app/user-profile/',
          action: () => true,
        },
        {
          shouldRender: this.user.profile.is_managebac_user,
          text: 'Launchpad',
          href: '#',
          action: () => true,
          id: 'managebac-account-picker',
        },
        {
          shouldRender: true,
          text: 'Log out',
          href: '/accounts/logout/',
          action: () => {
            intercomModule.stop();
            return true;
          },
        },
      ];
    },
  },
};
</script>
