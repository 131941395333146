<template>
  <div class="SubjectClassCard absoluteContainer">
    <div
      v-if="locked"
      class="SubjectClassCard-lock-container positionAbsolute positionAbsolute-centerHorizontally"
    >
      <div
        class="SubjectClassCard-lock-circle positionAbsolute positionAbsolute-centerHorizontally"
      />
      <div class="SubjectClassCard-lock-lock positionAbsolute positionAbsolute-centerHorizontally">
        <kog-icon
          size="xl"
          fa-style="regular"
          icon-class="fa-lock-alt"
          theme="custom"
          class="SubjectClassCard-lock-icon"
        />
      </div>
      <div class="SubjectClassCard-lock-rectangle padd-top-l">
        <p class="text-center"> Subject locked </p>
        <p class="text-center"> Contact your subject teacher </p>
      </div>
    </div>
    <div
      class="SubjectClassCard-stripe positionAbsolute positionAbsolute-topLeft padd-s margin-top-l"
      :class="{ SubjectClassCard__locked: locked }"
    >
      <div class="SubjectClassCard-title">
        {{ subjectName }}
      </div>
      <div class="SubjectClassCard-title heading-xs line-clamp-3">
        {{ subjectClassName }}
      </div>
    </div>
    <image-box
      :src="imageSrc"
      :w="378"
      :h="272"
      :quality="100"
      fit="crop"
      crop="entropy"
      class="SubjectClassCard-image"
      :class="{ SubjectClassCard__locked: locked }"
    />
    <div
      v-if="endorsed"
      :class="logoContainerClasses"
    >
      <endorser-logo
        :education-system-name="educationSystemName"
        :class="logoClasses"
      />
    </div>
  </div>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import EndorserLogo from 'sharedApp/components/logo/endorser-logo.vue';

export default {
  name: 'SubjectClassCard',
  components: {
    EndorserLogo,
    ImageBox,
    KogIcon,
  },
  props: {
    subjectName: {
      type: String,
      required: true,
    },
    subjectClassName: {
      type: String,
      required: true,
    },
    educationSystemName: {
      type: String,
      required: true,
      validator: value => ['IGCSE', 'IBDP', 'NGSS'].includes(value),
    },
    imageSrc: {
      type: String,
      required: true,
    },
    endorsed: {
      type: Boolean,
      required: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logoClasses() {
      return {
        'SubjectClassCard-endorsementLogo-logoContainer': this.isIGCSESubject,
        'padd-s': this.isIGCSESubject,
      };
    },
    logoContainerClasses() {
      return {
        'SubjectClassCard-endorsementLogo-IBDP': this.isIBDPSubject,
        'SubjectClassCard-endorsementLogo-IGCSE': this.isIGCSESubject,
        positionAbsolute: true,
        'positionAbsolute-bottomRight': true,
      };
    },
    isIBDPSubject() {
      return this.educationSystemName === 'IBDP';
    },
    isIGCSESubject() {
      return this.educationSystemName === 'IGCSE';
    },
  },
};
</script>

<style scoped>
.SubjectClassCard {
  overflow: hidden;

  width: 378px;
  height: 272px;

  border-radius: 12px;

  mask-image: radial-gradient(white, black);
}

.SubjectClassCard-image {
  transition: transform 0.3s;
}

.SubjectClassCard:hover .SubjectClassCard-image {
  transform: scale(1.05);
  transition: transform 0.3s;
}

.SubjectClassCard:hover .SubjectClassCard-image.SubjectClassCard__locked {
  transform: none;
}

.SubjectClassCard-stripe {
  z-index: 1;
  width: 80%;
  background-color: var(--kog-background-brand-900);
  border-radius: 0 5px 5px 0;
}

.SubjectClassCard-title {
  color: var(--kog-text-brand-hard);
  word-break: normal !important;
}

.SubjectClassCard-endorsementLogo-IBDP {
  display: flex;
  justify-content: flex-end;

  width: 60px;
  height: 60px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.SubjectClassCard-endorsementLogo-IGCSE {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: var(--cambridgeBackgroundBlack);
}

.SubjectClassCard-endorsementLogo-logoContainer {
  max-width: 60%;
}

.SubjectClassCard__locked {
  cursor: default;
  filter: grayscale(1);
}

.SubjectClassCard-lock-container {
  z-index: 2;
  bottom: 20px;
}

.SubjectClassCard-lock-rectangle {
  width: 295px;
  height: 84px;

  color: var(--kogPlatformGray018);

  background-color: var(--kogPlatformRedLighten20);
  border-radius: 4px;
}

.SubjectClassCard-lock-circle {
  z-index: -1;
  bottom: 42px;

  width: 84px;
  height: 84px;

  background-color: var(--kogPlatformRedLighten20);
  border-radius: 42px;
}

.SubjectClassCard-lock-lock {
  bottom: 52px;
  width: 64px;
  height: 64px;
  color: var(--kogPlatformRedDarken40);
}
</style>
