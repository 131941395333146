<template>
  <div class="StudentAnswersCard shadow-s-020 padd-top-m padd-bottom-m">
    <div
      class="margin-left-xxl flexContainer margin-right-m"
      :class="previewQuestionClasses"
      :role="getRole"
      :tabIndex="getTabIndex"
      v-on="toggleListeners"
    >
      <question-text-preview
        ref="questionPreview"
        :class="isQuestionExpanded ? 'line-clamp-none' : 'line-clamp-1'"
        class="StudentAnswersCard-previewQuestion flexChild-canGrow muted"
        :question="question"
        :show-answers="isAnswerExpanded"
        :should-render-as-text="shouldRenderAsText"
      />
      <kog-icon
        v-if="isQuestionExpanded || isQuestionClipped || questionContainsExtended"
        class="padd-top-s"
        :icon-class="toggleQuestionIconClass"
        fa-style="regular"
        size="s"
      />
    </div>

    <div class="flexContainer margin-bottom-l margin-right-m">
      <div
        v-if="isMCQ"
        class="flexContainer flexContainer-column gap-row-s"
      >
        <div
          v-for="choice in studentMCQResponses"
          :key="choice.answer"
          class="flexContainer flexContainer-alignCenter"
        >
          <kog-icon
            v-tooltip="{
              theme: 'kog-tooltip',
              content: questionCorrectTooltipText,
              boundary: 'document.body',
            }"
            fa-style="regular"
            :icon-class="mcqResponseIconClass(choice)"
            theme="custom"
            :class="{
              'StudentAnswersCard-icon--correct': choice.is_correct,
              'StudentAnswersCard-icon--incorrect': !choice.is_correct,
            }"
            class="margin-left-xs margin-right-xs"
          />
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="StudentAnswersCard-mcqResponse heading-s"
            v-html="choice.answer"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
      <template v-else>
        <kog-icon
          v-tooltip="{
            theme: 'kog-tooltip',
            content: questionCorrectTooltipText,
            boundary: 'document.body',
          }"
          :icon-class="questionCorrectIconClass"
          fa-style="regular"
          :class="{
            'StudentAnswersCard-icon--correct': isCorrect,
            'StudentAnswersCard-icon--incorrect': !isCorrect,
          }"
          class="margin-left-xs margin-right-xs"
          theme="custom"
        />
        <div class="heading-s">
          <div v-if="isSTQ">
            {{ response }}
          </div>
          <div
            v-else-if="isFBQ"
            class="flexContainer flexContainer-wrap StudentAnswersCard-studentFITBQAnswer"
          >
            <div
              v-for="(ftbqResponse, index) in response"
              :key="ftbqResponse.blankUid"
            >
              <div class="margin-right-s">
                <fill-blank-question-input
                  :value="ftbqResponse.ftbAnswer"
                  :disable-user-input-prop="true"
                  :state-prop="ftbqResponse.ftbState"
                  :order-number="index + 1"
                  class="StudentAnswersCard-input"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <assignment-question-answer
      v-if="isAnswerExpanded"
      :question="question"
      class="margin-left-xxl muted StudentAnswersCard-answer margin-right-m"
    />
    <div class="flexContainer flexContainer-spaceBetween margin-left-xxl margin-right-m">
      <div class="flexContainer muted flexContainer-alignCenter">
        <div
          v-for="subjectnode in question.subjectnode_mappings"
          :key="subjectnode.id"
          class="margin-right-xs"
        >
          <kog-tag :label="formatTopicNumber(subjectnode.number_including_ancestors)" />
        </div>
        <question-type-label
          :question-type="question.type"
          :tooltip-text="questionType"
          class="margin-right-xs"
        />
        <kog-difficulty
          v-if="showDifficulty"
          :difficulty="question.difficulty"
          class="margin-right-xs"
        />
        <kog-calculator
          :is-light-icon="true"
          :tags="question.tags"
          class="margin-right-xs"
        />
      </div>
      <button
        class="KogButtonLegacy KogButtonLegacy--link KogButtonLegacy--s StudentAnswersCard-showAnswerButton"
        @click="toggleAnswer"
      >
        {{ answerButtonText }}
        <i
          :class="{
            'fa-chevron-up': isAnswerExpanded,
            'fa-chevron-down': !isAnswerExpanded,
          }"
          class="fas padd-left-xxs"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { VTooltip } from 'floating-vue';

import KogDifficulty from 'learning/common/components/difficulty/kog-difficulty.vue';
import QuestionTextPreview from 'learning/common/components/question-text-preview.vue';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import FillBlankQuestionInput, {
  states,
} from 'sharedApp/components/fill-in-the-blanks-question/fill-blank-question-input.vue';
import KogCalculator from 'sharedApp/components/icons/kog-calculator.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { formatTopicNumber } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  getFtbUIDsInOrder,
  getQuestionTypeText,
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import AssignmentQuestionAnswer from 'studyApp/components/teacher/assignments/question-answer/assignment-question-answer.vue';
import QuestionTypeLabel from 'studyApp/components/teacher/assignments/question-type-label.vue';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export default {
  name: 'StudentAnswersCard',
  components: {
    KogIcon,
    KogDifficulty,
    QuestionTypeLabel,
    KogCalculator,
    KogTag,
    FillBlankQuestionInput,
    QuestionTextPreview,
    AssignmentQuestionAnswer,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    showDifficulty: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      required: true,
    },
    occasion: {
      type: Object,
      required: true,
    },
    trackAssignmentDetailsStudentsEvent: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isQuestionExpanded: false,
      isAnswerExpanded: false,
      isQuestionClipped: false,
      observer: null,
    };
  },
  computed: {
    isClippedQuestionCollapsed() {
      return !this.isQuestionExpanded && (this.isQuestionClipped || this.questionContainsExtended);
    },
    previewQuestionClasses() {
      return {
        'StudentAnswersCard-question': this.isClippedQuestionCollapsed,
        'margin-bottom-m': this.isClippedQuestionCollapsed || this.shouldRenderAsText,
        cursorPointer:
          this.isQuestionClipped || this.isQuestionExpanded || this.questionContainsExtended,
      };
    },
    toggleQuestionIconClass() {
      return this.isQuestionExpanded ? 'fa-chevron-up' : 'fa-chevron-down';
    },
    answerButtonText() {
      return this.isAnswerExpanded ? 'Hide answers' : 'Show answers';
    },
    questionCorrectIconClass() {
      return this.isCorrect ? 'fa-check' : 'fa-times';
    },
    questionCorrectTooltipText() {
      return this.isCorrect ? 'Correct' : 'Incorrect';
    },
    questionType() {
      return getQuestionTypeText(this.question);
    },
    shouldRenderAsText() {
      return this.questionContainsExtended && !this.isQuestionExpanded;
    },
    questionContainsExtended() {
      return this.question.question_html.includes('extendedcontentbox');
    },
    response() {
      return !this.isFBQ ? this.answerForQuestion.answer : this.studentFTBResponse;
    },
    isCorrect() {
      const studentCorrectAnswersList =
        this.occasion.answered_questions.questions_correct_answer.map(({ question_id: id }) => id);
      return studentCorrectAnswersList.includes(this.question.id);
    },
    answerForQuestion() {
      const {
        questions_correct_answer: correctAnswers,
        questions_incorrect_answer: incorrectAnswers,
      } = this.occasion.answered_questions;

      const allAnswers = correctAnswers.concat(incorrectAnswers);
      return allAnswers.find(answer => answer.question_id === this.question.id);
    },
    studentFTBResponse() {
      return this.ftbBlanksInRightOrder.map(blankUid => {
        const ftbAnswer = this.getFTBAnswer(blankUid, this.occasion);
        const ftbState = this.getFTBState(blankUid, this.occasion);
        return {
          blankUid,
          ftbAnswer,
          ftbState,
        };
      });
    },
    studentMCQResponses() {
      const answerSummary = this.answerForQuestion;
      const selectedChoices = Array.isArray(answerSummary.answer)
        ? answerSummary.answer
        : [{ is_correct: this.isCorrect, answer: answerSummary.answer }];

      return selectedChoices;
    },
    ftbBlanksInRightOrder() {
      return getFtbUIDsInOrder(this.question.question_html);
    },
    isMCQ() {
      return isMCQ(this.question);
    },
    isSTQ() {
      return isSTQ(this.question);
    },
    isFBQ() {
      return isFBQ(this.question);
    },
    isToggleable() {
      return this.isQuestionExpanded || this.isQuestionClipped || this.questionContainsExtended;
    },
    getTabIndex() {
      return this.isToggleable ? 0 : -1;
    },
    getRole() {
      return this.isToggleable ? 'button' : 'div';
    },
    toggleListeners() {
      return this.isToggleable
        ? {
            click: this.toggleQuestionPreview,
            keydown: event => {
              if (event.code === 'Enter' || event.code === 'Space') {
                event.preventDefault();
                this.toggleQuestionPreview();
              }
            },
          }
        : {};
    },
  },
  mounted() {
    this.initObserver();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    initObserver() {
      this.observer = new ResizeObserver(this.onQuestionPreviewRender);
      this.observer.observe(this.$refs.questionPreview.$refs.root);
    },
    onQuestionPreviewRender() {
      const { scrollHeight, clientHeight } = this.$refs.questionPreview.$refs.root;
      /* NOTE:
       * To determine whether the question was more than one line was not
       * as straightforward as checking (scrollHeight > clientHeight) because
       * in the case of rendered Mathjax equations sometimes the scrollHeight
       * is bigger than clientHeight, even though the question is only 1 line.
       * The following formula was determined through trial and error to cover
       * all cases (hopefully).
       */
      this.isQuestionClipped = clientHeight * 1.7 < scrollHeight;
    },
    formatTopicNumber(topicNumber) {
      return formatTopicNumber(topicNumber);
    },
    toggleQuestionPreview() {
      const eventName = this.isQuestionExpanded
        ? 'Assignment Details student modal - Click Hide question icon'
        : 'Assignment Details student modal - Click Show question icon';
      this.trackAssignmentDetailsStudentsEvent(eventName);
      if (this.isQuestionClipped || this.isQuestionExpanded || this.questionContainsExtended) {
        this.isQuestionExpanded = !this.isQuestionExpanded;
      }
    },
    toggleAnswer() {
      const eventName = this.isAnswerExpanded
        ? 'Assignment Details student modal - Click Hide answers button'
        : 'Assignment Details student modal - Click Show answers button';
      this.trackAssignmentDetailsStudentsEvent(eventName);
      this.isAnswerExpanded = !this.isAnswerExpanded;
    },
    getFTBState(blankUid) {
      const ftbAnswer = this.answerForQuestion;
      const isFTBBlankCorrect = ftbAnswer.correctly_answered_blanks.includes(blankUid);
      return isFTBBlankCorrect ? states.correct : states.incorrect;
    },
    getFTBAnswer(blankUid) {
      const ftbAnswer = this.answerForQuestion;
      return ftbAnswer.answer[blankUid];
    },
    mcqResponseIconClass(response) {
      return response.is_correct ? 'fa-check' : 'fa-times';
    },
  },
};
</script>

<style scoped>
.StudentAnswersCard {
  background: var(--kogPlatformWhite);
  border-radius: 4px;
}

.StudentAnswersCard-question:hover {
  margin-right: calc(var(--space-m) - var(--space-xxs));
  margin-left: calc(var(--space-xxl) - var(--space-xxs));
  padding-right: var(--space-xxs);
  padding-left: var(--space-xxs);

  background: var(--kogPlatformGray093);
  border-radius: 4px;
}

.StudentAnswersCard-previewQuestion :deep(.content-editable) {
  max-width: 100%;
  word-break: normal;
}

.StudentAnswersCard-answer {
  margin-top: 0;
  padding-top: 0;
  border: none;
}

.StudentAnswersCard-answer :deep(h1),
.StudentAnswersCard-answer :deep(h2),
.StudentAnswersCard-answer :deep(h3),
.StudentAnswersCard-answer :deep(h4),
.StudentAnswersCard-answer :deep(h5),
.StudentAnswersCard-answer :deep(h6) {
  color: var(--kogPlatformGray044);
}

.StudentAnswersCard-studentFITBQAnswer {
  margin-bottom: calc(var(--space-xs) * -1);
}

.StudentAnswersCard-input {
  margin-top: 0;
  margin-bottom: var(--space-xs);
}

.StudentAnswersCard-mcqResponse :deep(p) {
  margin-bottom: 0;
}

.StudentAnswersCard-mcqResponse :deep(img) {
  display: inline-block;
  width: auto;
  max-width: 100%;
  height: auto;
}

.StudentAnswersCard-showAnswerButton {
  height: auto;
  padding: 0;
}

.StudentAnswersCard-icon--correct {
  color: var(--kog-colors-green-800);
}

.StudentAnswersCard-icon--incorrect {
  color: var(--kog-colors-pink-700);
}
</style>
