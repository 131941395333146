<template>
  <div class="GradingProgressCard padd-l">
    <span
      id="gradingProgressLabel"
      class="text-overline"
    >
      Grading progress
    </span>
    <kog-item-list
      role="list"
      aria-labelledby="gradingProgressLabel"
      class="GradingProgressCard-questionList margin-top-xs flexContainer gap-s flexContainer-wrap flexContainer-alignCenter"
    >
      <li
        v-for="(questionItem, index) in gradedCountPerQuestion"
        :key="questionItem.questionId"
        class="flexContainer flexContainer-column"
      >
        <span
          aria-hidden="true"
          class="text-center heading-xxs margin-bottom-xxs"
        >
          {{ index + 1 }}
        </span>
        <kog-tag
          class="GradingProgressCard-questionChip flexContainer-justifyCenter"
          :context="questionChipContext(questionItem, index + 1)"
          is-chip
          icon-class="fa-list-check"
          :label="questionChipLabel(questionItem)"
          size="m"
          type="informative"
        />
      </li>
    </kog-item-list>
    <div
      class="margin-top-l width-100 flexContainer flexContainer-row flexContainer-alignEnd flexContainer-spaceBetween"
    >
      <div
        v-if="isShowingProgressDescriptions"
        class="margin-right-l"
      >
        <div class="GradingProgressCard-progressDescription">
          <kog-icon
            class="margin-right-xxs"
            :icon-class="gradingProgress.icon"
            size="s"
            fa-style="regular"
            theme="dark"
          />
          {{ gradingProgress.text }}
        </div>
        <div class="GradingProgressCard-progressDescription margin-top-xs">
          <kog-icon
            class="margin-right-xxs"
            :icon-class="sendingProgress.icon"
            size="s"
            fa-style="regular"
            theme="dark"
          />
          {{ sendingProgress.text }}
        </div>
      </div>
      <kog-button
        class="margin-left-auto"
        :label="buttonLabel"
        size="small"
        :button-style="buttonStyle"
        @click="$emit('navigate-to-students-submissions')"
      />
    </div>
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'GradingProgressCard',
  components: {
    KogButton,
    KogIcon,
    KogItemList,
    KogTag,
  },
  props: {
    classStudentsCount: {
      type: Number,
      required: true,
    },
    gradedCountPerQuestion: {
      type: Array,
      required: true,
    },
    studentsSubmittedCount: {
      type: Number,
      required: true,
    },
    responsesToGradeCount: {
      type: Number,
      required: true,
    },
    gradesToSendCount: {
      type: Number,
      required: true,
    },
    updatesToSendCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['navigate-to-students-submissions'],
  computed: {
    gradedResponseCount() {
      return this.gradedCountPerQuestion.reduce((acc, current) => {
        return acc + current.studentsGradedCount;
      }, 0);
    },
    buttonStyle() {
      const pendingActionCount =
        this.gradesToSendCount + this.updatesToSendCount + this.responsesToGradeCount;
      if (pendingActionCount === 0) {
        return 'basic';
      }
      return 'accent';
    },
    buttonLabel() {
      if (this.responsesToGradeCount > 0) {
        if (this.gradedResponseCount > 0) {
          return 'Continue grading';
        }
        return 'Start grading';
      }
      return 'Go to submissions';
    },
    isShowingProgressDescriptions() {
      return this.studentsSubmittedCount > 0;
    },
    isNothingToGrade() {
      return this.responsesToGradeCount === 0;
    },
    isAnyResponseGraded() {
      return this.gradedResponseCount > 0;
    },
    gradingProgress() {
      if (this.isNothingToGrade) {
        return {
          icon: this.isAnyResponseGraded ? 'fa-badge-check' : 'fa-circle',
          text: 'Nothing to grade',
        };
      }

      return {
        icon: this.isAnyResponseGraded ? 'fa-circle-half-stroke' : 'fa-circle',
        text: `${this.responsesToGradeCount} responses left to grade`,
      };
    },
    sendingProgress() {
      if (this.notSentCount === this.studentsSubmittedCount) {
        return { icon: 'fa-circle', text: 'Not sent to students' };
      }
      if (this.notSentCount > 0) {
        return { icon: 'fa-circle-half-stroke', text: 'Not sent to all students' };
      }
      return { icon: 'fa-badge-check', text: 'Sent' };
    },
    notSentCount() {
      return this.gradesToSendCount + this.updatesToSendCount;
    },
  },
  methods: {
    questionChipContext(questionItem, questionNumber) {
      return `Question ${questionNumber}: ${questionItem.studentsGradedCount} out of ${this.classStudentsCount} students graded`;
    },
    questionChipLabel(questionItem) {
      return `${questionItem.studentsGradedCount}/${this.classStudentsCount}`;
    },
  },
};
</script>

<style scoped>
.GradingProgressCard {
  background-color: var(--kog-alert-info-background);
  border-radius: var(--space-s);
}

.GradingProgressCard-questionList {
  list-style: none;
}

.GradingProgressCard-questionChip {
  min-width: 70px;
}
</style>
