<template>
  <kog-modal
    title="Strength questions"
    :close-modal="closeModal"
    :header-icon="headerIcon"
  >
    <template #modalBody>
      <div>
        <p>
          These questions check and consolidate both knowledge and understanding of key concepts
          covered in the subtopic.
        </p>
        <p>
          Students can practice questions on their own through battles and self tests.<br />
          As a teacher, you can also assign specific questions via the assignments.
        </p>
        <!-- We will soon revert back to showing students after release -->
        <template v-if="false">
          <div
            class="flexContainer flexContainer-spaceBetween text-regular margin-bottom-s margin-right-l"
          >
            <span>Student</span>
            <span>Questions</span>
          </div>
          <div>
            <student-strength-questions-progress
              v-for="student in studentsWithProgress"
              :key="student.name"
              class="margin-bottom-xxs"
              :student="student"
            />
          </div>
        </template>
      </div>
    </template>
    <template #modalFooter>
      <div class="flexContainer flexContainer-flexEnd">
        <kog-button
          label="Create assignment"
          size="small"
          :is-loading="$wait.is('creating_assignment')"
          @click="goToNewAssignment"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapState } from 'vuex';

import { createAssignment } from '@apis/schoolstaff-assignments.js';

import { SOURCES } from 'learning/common/components/assignment/create-assignment-button.vue';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import strengthQuestionsIcon from 'sharedApp/img/study/subject-class-overview-strength-questions.svg';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';

import StudentStrengthQuestionsProgress from './student-strength-questions-progress.vue';

export default {
  name: 'StrengthQuestionsModal',
  components: {
    KogButton,
    KogModal,
    StudentStrengthQuestionsProgress,
  },
  mixins: [RoutesMixin],
  props: {
    subjectNode: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);

    return {
      isMultipleClassAssignmentEnabled,
    };
  },
  data() {
    return {
      headerIcon: {
        type: 'image',
        imgUrl: strengthQuestionsIcon,
        alt: '',
      },
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      user: state => state.userModule.user,
    }),
    studentsWithProgress() {
      return this.students.map(student => ({
        name: student.user.full_name,
        avatar_url: student.user.avatar_url,
        progress: '26/42',
      }));
    },
  },
  methods: {
    async goToNewAssignment() {
      this.$wait.start('creating_assignment');

      try {
        const assignment = await createAssignment({
          assignmentType: ASSIGNMENT_TYPES.QUESTION,
          subjectClassId: this.classId,
          user: this.user,
          isMultiClass: this.isMultipleClassAssignmentEnabled,
        });

        this.closeModal();
        this.$router.push({
          name: 'teacherAssignmentCreate',
          params: {
            classSlug: this.subjectClassSlug,
            sid: this.subjectId,
            cid: this.classId,
            assignmentId: assignment.id,
            subjectNode: {
              ...this.subjectNode,
              number_including_ancestors: this.subjectNode.formatted_number_including_ancestors,
            },
          },
          query: {
            just_created: true,
            source: SOURCES.SUBJECT_CLASS_OVERVIEW_STRENGTH_QUESTIONS_MODAL,
          },
        });
      } catch {
        this.$toast.showError('Something went wrong. Please try again.');
      } finally {
        this.$wait.end('creating_assignment');
      }
    },
  },
};
</script>
