<template>
  <kog-modal :title="modalTitle">
    <template #modalBody>
      <kog-input
        v-model:value="title"
        class="margin-bottom-l"
        label="Title (optional)"
        :helper-text="`Title is shown above the question in the ${activityTypeTitle}`"
      />
      <template v-if="availableQuestions.length > 0">
        <p class="font-weight-bold"> Pick a question to add it to the {{ activityTypeTitle }} </p>
        <ol class="AddQuestionModal list-style-none margin-0 padd-0">
          <li
            v-for="question in availableQuestions"
            :key="question.id"
            class="flexContainer gap-s margin-bottom-l"
          >
            <input
              :id="question.id"
              v-model="selectedQuestion"
              type="radio"
              :value="question.id"
              :disabled="isDisabled"
            />
            <label
              class="border-radius-8 border-standard padd-m flexChild-canGrow"
              :for="question.id"
            >
              <div>
                {{ `Question ${question.id} - ${questionTypeText(question)}` }}
              </div>
              <div
                class="text-normal"
                v-text="questionPreview(question)"
              />
            </label>
          </li>
        </ol>
      </template>
      <p v-else> No questions to add. </p>
    </template>
    <template #modalFooter>
      <div>
        <kog-button
          button-style="primary"
          :label="CTAButtonText"
          :disabled="!hasSelectedQuestion || !availableQuestions"
          @click="handleOnDone"
        />
        <kog-button
          label="Close"
          @click="closeModal"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { find } from 'lodash';

import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import { FEATURES_ENUM, getFeatureTypeTitle } from 'sharedApp/const/features.js';
import {
  dangerouslyGetUnescapedText,
  getQuestionTypeText,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

export default {
  name: 'EditQuestionModal',
  components: {
    KogModal,
    KogButton,
    KogInput,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    onUpdate: {
      type: Function,
      default: () => ({}),
    },
    availableQuestions: {
      type: Array,
      required: true,
    },
    activityType: {
      type: String,
      default: FEATURES_ENUM.ACTIVITY_SHEET,
      validator: prop =>
        [FEATURES_ENUM.ACTIVITY_SHEET, FEATURES_ENUM.PERFORMANCE_TASK].includes(prop),
    },
  },
  data() {
    return {
      selectedQuestion: this.model?.question_id || this.model?.question?.id,
      title: this.model ? this.model.title : '',
    };
  },
  computed: {
    hasSelectedQuestion() {
      return !!this.selectedQuestion;
    },
    modalTitle() {
      return 'Edit question';
    },
    CTAButtonText() {
      return 'Update question';
    },
    isDisabled() {
      return !!this.model?.question_id;
    },
    activityTypeTitle() {
      return getFeatureTypeTitle(this.activityType).toLowerCase();
    },
  },
  methods: {
    handleOnDone() {
      const { uuid } = find(this.availableQuestions, { id: this.selectedQuestion });

      const data = {
        question_id: this.selectedQuestion,
        block_type: BLOCK_TYPE.QUESTION,
        title: this.title,
        question_uuid: uuid,
      };

      this.onUpdate(data);
      this.closeModal();
    },
    questionPreview(question) {
      return dangerouslyGetUnescapedText(question.question_html);
    },
    questionTypeText(question) {
      return getQuestionTypeText(question);
    },
  },
};
</script>
