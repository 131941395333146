/*
This plugin handles manual input on the time picker so that the date would be updated on blur events
 */

function manualTimeInputPlugin() {
  return fp => ({
    onReady() {
      if (!fp) {
        return;
      }
      [fp.hourElement, fp.minuteElement].forEach(
        element =>
          element &&
          element.addEventListener('blur', () => {
            fp.setDate(fp.selectedDates[0], true);
          }),
      );
    },
  });
}

export default manualTimeInputPlugin;
