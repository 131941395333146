<template>
  <kog-modal
    :title="sectionTitle"
    :close-modal="closeModal"
    :header-icon="headerIcon"
    :subtitle="subtitle"
  >
    <template #modalBody>
      <completion-stats-modal-body
        :students-completion-info="completionData"
        completion-column-text="Completed"
        :education-system-name="subject.educationsystem.name"
      />
    </template>
    <template #modalFooter>
      <completion-stats-modal-footer
        :subject-node="subjectNode"
        :subject-class-name="subjectClassName"
        :subject="subject"
        :subject-class-id="subjectClassId"
        :user="user"
        :source="source"
        :class-slug="classSlug"
        :disable-creating-assignment="disableCreatingAssignment"
        :close-modal="closeModal"
      />
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import sectionIcon from 'sharedApp/img/study/subject-class-overview-section.svg';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import CompletionStatsModalBody from 'studyApp/components/subject-class-overview/completion-stats-modal-body.vue';
import CompletionStatsModalFooter from 'studyApp/components/subject-class-overview/completion-stats-modal-footer.vue';

export default {
  name: 'SectionCompletionModal',
  components: {
    CompletionStatsModalFooter,
    KogModal,
    CompletionStatsModalBody,
  },
  mixins: [RoutesMixin],
  props: {
    subjectNode: {
      type: Object,
      required: true,
    },
    completionData: {
      type: Array,
      required: true,
    },
    subjectClassName: {
      type: String,
      required: true,
    },
    sectionTitle: {
      type: String,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    classSlug: {
      type: String,
      required: true,
    },
    disableCreatingAssignment: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: 'not_declared',
    },
  },
  data() {
    return {
      headerIcon: {
        type: 'image',
        imgUrl: sectionIcon,
        alt: '',
      },
    };
  },
};
</script>

<style scoped></style>
