<template>
  <div class="AssignmentListSubtitle flexContainer flexContainer-alignCenter">
    <kog-table-info-selected-total
      :total-selected-items="selectedItemsTotal"
      :total-items="totalItems"
      type="assignment"
      class="margin-right-xs"
    />
    <bulk-delete-button
      v-if="isDeleteButtonVisible"
      :selected-assignment-ids="selectedItems"
      class="margin-right-xxs"
      @deleted="$emit('deleted')"
    />
    <bulk-archive-button
      v-if="isArchiveButtonVisible"
      :selected-assignment-ids="selectedItems"
      :list-type="listType"
      @archived="$emit('archived')"
    />
  </div>
</template>

<script>
import KogTableInfoSelectedTotal from 'sharedApp/components/tables/kog-table-info-selected-total.vue';
import BulkArchiveButton from 'studyApp/components/teacher/assignments/list/bulk-archive-button.vue';
import BulkDeleteButton from 'studyApp/components/teacher/assignments/list/bulk-delete-button.vue';

export default {
  name: 'AssignmentListSubtitle',
  components: {
    KogTableInfoSelectedTotal,
    BulkDeleteButton,
    BulkArchiveButton,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    listType: {
      type: String,
      default: '',
    },
  },
  emits: ['archived', 'deleted'],
  computed: {
    selectedItemsTotal() {
      return this.selectedItems.length;
    },
    isDeleteButtonVisible() {
      return this.listType === 'draft' && this.selectedItemsTotal > 0;
    },
    isArchiveButtonVisible() {
      return (this.listType === 'past' || this.listType === 'draft') && this.selectedItemsTotal > 0;
    },
  },
};
</script>

<style scoped>
.AssignmentListSubtitle {
  min-height: 48px;
}

.AssignmentListSubtitle-selectedAssignments {
  border-right: solid 1px var(--kogPlatformGray077);
}
</style>
