<template>
  <div>
    <kog-radio-card-group
      v-model:selected-value="progressionBarType"
      :options="progressionBarTypeOptions"
    />
    <kog-alert
      v-if="hasErrors"
      mode="error"
      title="Failed to apply selection"
    >
      <ul class="list-style-none padd-left-0 margin-bottom-0">
        <li
          v-for="(error, key) in errors"
          :key="key"
        >
          {{ error }}
        </li>
      </ul>
    </kog-alert>
  </div>
</template>

<script>
import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';
import KogRadioCardGroup from 'sharedApp/components/base/radio-card/kog-radio-card-group.vue';
import CompletionBarOption from 'studyApp/components/subject-class-overview/completion-bar-option.vue';
import StrengthBarOption from 'studyApp/components/subject-class-overview/strength-bar-option.vue';

export default {
  name: 'ProgressBarOptions',
  components: {
    KogRadioCardGroup,
    KogAlert,
  },
  data() {
    return {
      errors: [],
      progressionBarType: 'completion-bar',
    };
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },
    progressionBarTypeOptions() {
      return [
        {
          value: 'completion-bar',
          label: 'Questions completed bar',
          header: 'Questions completed',
          describedBy: 'completion-bar-desc',
          content: CompletionBarOption,
        },
        {
          value: 'strength-bar',
          header: 'Strength',
          label: 'Strength bar',
          describedBy: 'strength-bar-desc',
          content: StrengthBarOption,
        },
      ];
    },
  },
};
</script>
