<template>
  <section>
    <loader-indicator
      :loading="!isDataReady"
      loading-msg="Loading notebook contents..."
    />
    <div v-if="isDataReady">
      <notebook-side-navigation
        v-if="annotationsCount"
        :current-node="currentNode"
        :annotations-count="annotationsCount"
      />
      <div class="NotebookContainer flexContainer flexContainer-column">
        <div class="NotebookContainer-header flexContainer flexContainer-center">
          <div>
            <h1 class="NotebookContainer-h1">
              <span v-if="!isSubject">
                {{ currentNode.formatted_number_including_ancestors }}
              </span>
              {{ currentNode.name }}
            </h1>
            <div
              v-if="selfAnnotations.length > 0"
              class="text-center"
            >
              {{ filteredAnnotationsText }}
            </div>
            <div
              v-if="selfAnnotations.length > 0"
              class="padd-top-1 flexContainer flexContainer-center"
            >
              Filter by color
              <div class="inline-block padd-left-1">
                <multi-color-selector
                  :selected-colors-model="selectedColors"
                  @change="updateSelectedColors"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flexContainer flexContainer-flexEnd">
          <div
            v-if="filteredAnnotations.length > 0"
            class="NotebookContainer-rightMenu"
          >
            <router-link :to="goToCurrentBookNode()">
              <span v-if="isSection"> Go to section </span>
              <span v-if="isTopic || isSubTopic"> Go to first section </span>
              <span v-if="isSubject"> Go to subject overview </span>
            </router-link>
            <button
              class="KogButton KogButton--link KogButton--s NotebookContainer-buttonLink"
              @click.prevent="printAll"
            >
              Print all
            </button>
          </div>
        </div>
        <loader-indicator
          :loading="!isAnnotationReady"
          loading-msg="Loading your notes and highlights..."
          :class="['NotebookContainer-loader', { 'margin-top-xxxl': !isAnnotationReady }]"
        />
        <div v-if="isAnnotationReady">
          <div class="flexContainer flexContainer-column">
            <div
              v-if="selfAnnotations === null || selfAnnotations.length === 0"
              class="NotebookContainer-informationBody"
            >
              <h2 class="heading-m margin-top-m margin-bottom-s text-center">
                Welcome to your notebook!
              </h2>
              <p class="text-center kogPlatformGray031">
                Save and store the important parts of your notebook right here.
              </p>
              <p class="text-center">
                <img
                  :src="instructionUrl"
                  alt="Notebook instruction"
                  class="margin-top-1 NotebookContainer-informationBody-gif"
                />
              </p>
              <ol class="NotebookContainer-informationBody-list kogPlatformGray031">
                <li>In your textbook, highlight key ideas, terms or other important text</li>
                <li>Save it as a highlight, or add your own note to it</li>
                <li>Your highlights and notes are saved here</li>
              </ol>
              <p>
                <router-link :to="goToCurrentBookNode()">
                  Start adding notes for
                  {{ currentNode.formatted_number_including_ancestors }} {{ currentNode.name }}
                </router-link>
              </p>
            </div>
            <div
              v-if="filteredAnnotations.length === 0 && selfAnnotations.length > 0"
              class="flexContainer flexContainer-column margin-top-xxxl"
            >
              <div class="text-center">
                <h3>
                  Hidden notes!
                  <span class="KogEmoji--seeNoEvil" />
                </h3>
                <p> Add or remove some of your filters to see your notes. </p>
              </div>
            </div>
            <div
              v-if="filteredAnnotations.length > 0"
              id="noteList"
              class="NotebookContainer-noteList"
            >
              <note-list
                :annotations-list="filteredAnnotations"
                :go-to-book="goToBook"
                @deleted-annotation="onDeleteAnnotation"
                @add-text="onAddText"
                @edit-text="onEditText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import { useHead } from '@unhead/vue';
import pluralize from 'pluralize';
import { onBeforeRouteUpdate } from 'vue-router';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState, useStore } from 'vuex';

import MultiColorSelector from 'learning/common/components/multi-color-selector.vue';
import { highlightColors } from 'learning/common/libs/annotations-utils.js';

import LoaderIndicator from 'sharedApp/components/base/indicators/kog-loader.vue';
import {
  getLevelName,
  getSubjectNodeMap,
  isSection,
  isSubject,
  isSubTopic,
  isTopic,
} from 'sharedApp/libs/subject-tree-functions.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import NodeLookupService from 'sharedApp/services/utils/nodeLookupService/node-lookup-service.js';
import printHtml from 'sharedApp/utils/print-html.js';
import NoteList from 'studyApp/components/notebook/note-list.vue';
import NotebookSideNavigation from 'studyApp/components/side-navigation/notebook-side-navigation.vue';
import newNotebookInstructionUrl from 'studyApp/images/new-notebook-instruction.gif';

const printCss = `
  body {
    font-size: 16px;
    margin-top: 1em;
    font-family: "Times New Roman", Times, serif;
    padding: 3em;
  }
  .NoteCard {
    margin-bottom: 1em;
    padding-bottom: 1em;
  }
  .NoteCard-highlight {
    font-style: normal;
  }
  .NoteCard-note {
    margin-top: 0.5em;
    font-size: var(--kog-font-size-default);
    font-style: italic;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .NoteList-card {
    border-bottom: 1px solid #e5e5e3;
  }
`;

export default {
  name: 'Notebook',
  components: {
    LoaderIndicator,
    NoteList,
    MultiColorSelector,
    NotebookSideNavigation,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const currentNode = ref();

    const { subject } = store.state.subjectModule;

    onBeforeRouteUpdate((to, from, next) => {
      const nextSubjectNodeId = Number(to.params.nodeId);
      currentNode.value = NodeLookupService.findNodeById(nextSubjectNodeId, subject.subject_tree);
      next();
    });

    useHead(() => ({
      title: `Notes for ${currentNode.value?.name} | Notebook`,
    }));

    return {
      currentNode,
    };
  },
  data: () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedColors = searchParams.has('colors')
      ? searchParams.getAll('colors')
      : highlightColors;
    return {
      selectedColors,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      user: state => state.userModule.user,
      selfAnnotations: state => state.notebookModule.selfAnnotations,
      annotationsCount: state => state.notebookModule.annotationsCount,
    }),
    ...mapGetters({
      allSubjectNodes: 'subjectModule/allSubjectNodes',
    }),
    isAnnotationReady() {
      return !this.$wait.is('loading_annotations');
    },
    isDataReady() {
      return this.selfAnnotations;
    },
    filteredAnnotations() {
      return this.selfAnnotations.filter(a => this.selectedColors.includes(a.color));
    },
    instructionUrl() {
      return newNotebookInstructionUrl;
    },
    isSection() {
      return isSection(this.currentNode);
    },
    isSubTopic() {
      return isSubTopic(this.currentNode);
    },
    isTopic() {
      return isTopic(this.currentNode);
    },
    isSubject() {
      return isSubject(this.currentNode);
    },
    bookRoot() {
      return this.subject.subject_tree[0];
    },
    subjectNodeMap() {
      return getSubjectNodeMap(this.bookRoot);
    },
    filteredAnnotationsText() {
      const totalCount = this.selfAnnotations.length;
      const filteredCount = this.filteredAnnotations.length;
      return `${filteredCount} out of ${totalCount} ${pluralize('note', filteredCount)}`;
    },
  },
  watch: {
    currentNode: {
      handler(node) {
        let subjectNodeId = this.bookRoot.id;
        if (node) {
          subjectNodeId = node.id;
        }
        this.fetchSelfAnnotations({ subjectNodeId, userId: this.user.id });
      },
      immediate: true,
    },
  },
  created() {
    this.currentNode = this.getCurrentNode();
    if (this.$route.name === 'classNotebook') {
      this.$router.replace({
        name: 'classNotebookNode',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: this.currentNode.slug,
          nodeId: this.currentNode.id,
        },
      });
    }
    this.fetchAnnotationsCount(this.subject.id);
    this.sendOpenPageTrackingEvent();
  },
  methods: {
    ...mapActions({
      fetchAnnotationsCount: 'notebookModule/fetchAnnotationsCount',
    }),
    ...mapState({
      deleteAnnotationOne: state => state.subjectModule.deleteAnnotation,
    }),
    ...mapWaitingActions('notebookModule', {
      fetchSelfAnnotations: 'loading_annotations',
    }),
    getCurrentNode() {
      const currentSubjectNodeId = Number(this.$route.params.nodeId);
      if (currentSubjectNodeId) {
        return NodeLookupService.findNodeById(currentSubjectNodeId, this.subject.subject_tree);
      }

      return this.bookRoot;
    },
    sendOpenPageTrackingEvent() {
      const searchParams = new URLSearchParams(window.location.search);
      const source = searchParams.get('source') || 'not defined';
      const eventName = 'Student notebook - Open';
      const eventProperties = {
        level: getLevelName(this.currentNode.level),
        source,
      };
      this.$mixpanel.trackEvent(eventName, eventProperties);
    },
    goToCurrentBookNode() {
      return {
        name: 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: this.currentNode.slug,
          nodeId: this.currentNode.id,
        },
      };
    },
    trackEvent(partialName, extraProps = {}) {
      const sectionNumber = this.currentNode.formatted_number_including_ancestors;
      const sectionName = this.currentNode.name;
      this.$mixpanel.trackEvent(`Private annotations - ${partialName}`, {
        section_title: `${sectionNumber} ${sectionName}`,
        subject_node_id: this.currentNode.id,
        level: getLevelName(this.currentNode.level),
        source: 'notebook',
        ...extraProps,
      });
    },
    printAll() {
      const notesList = document.getElementById('noteList');
      const title = `${this.currentNode.formatted_number_including_ancestors} ${this.currentNode.name}`;
      printHtml(notesList.outerHTML, title, printCss);
      this.trackEvent('Print all');
    },
    onDeleteAnnotation() {
      this.trackEvent('Delete');
    },
    onAddText() {
      this.trackEvent('Saved', { action: 'add text' });
    },
    onEditText() {
      this.trackEvent('Saved', { action: 'edit text' });
    },
    updateSelectedColors(newSelectedColors) {
      this.trackEvent('Filter by color');
      const {
        $router,
        $route: { query },
      } = this;
      const isAll = highlightColors.every(value => newSelectedColors.includes(value));
      if (!isAll) {
        $router.replace({ query: { ...query, colors: newSelectedColors } });
      } else {
        const { colors, ...withoutColors } = query;
        $router.replace({ query: withoutColors });
      }
      this.selectedColors = newSelectedColors;
    },
    goToBook(annotation) {
      const subjectNodeId = annotation.subject_node;
      const subjectNode = this.subjectNodeMap[subjectNodeId];

      const name = 'classBook';
      const params = {
        classSlug: this.subjectClassSlug,
        sid: this.subjectId,
        cid: this.classId,
        nodeSlug: subjectNode.slug,
        nodeId: subjectNode.id,
      };
      const query = {
        note: annotation.id.toString(),
      };

      this.$router.push({ name, params, query });
    },
  },
};
</script>

<style scoped>
.NotebookContainer {
  max-width: 750px;
  min-height: 100%;
  margin: auto;
}

.NotebookContainer-informationBody {
  margin: auto;
  margin-top: 60px;
}

.NotebookContainer-h1 {
  padding: 0;
  padding-bottom: 10px;
}

.NotebookContainer-noteList {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.NotebookContainer-rightMenu a {
  margin-right: 10px;
}

.NotebookContainer-buttonLink {
  min-width: 16px;
  height: 17px;
  margin-right: 10px;
  padding: 0;

  border: none;
}

.NotebookContainer-informationBody-list {
  counter-reset: li;
  padding-inline-start: 0;
  list-style: none;
}

.NotebookContainer-informationBody-list li {
  counter-increment: li;
}

.NotebookContainer-informationBody-list li::before {
  content: counter(li) '. ';
  font-weight: bold;
}

.NotebookContainer-informationBody-gif {
  width: 400px;
  max-width: 90%;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px -2px var(--kogShadow020);
}
</style>
