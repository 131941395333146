import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';

import Component from './block.vue';

const template = {
  block_type: BLOCK_TYPE.CONTENT_BOX,
  content_html: '',
  template_id: null,
};

const validator = {
  errors: [],
  validate(blockData) {
    this.errors = [];

    if (!blockData.template_id) {
      this.errors.push('Content box has no selected template');
    }

    if (!blockData.content_html) {
      this.errors.push('Content box content is empty');
    }

    return this.errors.length === 0;
  },
};

const preSaveHandler = block => {
  return {
    payload: block,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
};

export default {
  component: Component,
  template,
  validator,
  preSaveHandler,
};
