<template>
  <kog-modal
    class="ReportFeedbackModal"
    :title="title"
  >
    <template #modalBody>
      <kog-radio-group
        v-model:selected-value="feedback.category"
        aria-label="Feedback type selection"
        :options="feedbackOptions"
      />
      <kog-textarea
        v-model:value="feedback.comment"
        class="margin-top-m"
        label="Please share your feedback or report any error you found on this page."
        placeholder="Tell us more"
      />
      <p class="margin-top-1 text-italic">
        We read and handle all the feedback you send to us. If needed we will get back to you!
      </p>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          class="KogButtonLegacy"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          class="KogButtonLegacy KogButtonLegacy--primary"
          :disabled="isSendButtonDisabled"
          @click="onSend"
        >
          <span v-if="isSending"> Sending... </span>
          <span v-else> Send </span>
        </button>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { mapActions } from 'vuex';

import KogRadioGroup from 'sharedApp/components/base/radio/kog-radio-group.vue';
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'ReportFeedbackModal',
  components: {
    KogModal,
    KogTextarea,
    KogRadioGroup,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    subjectNodeId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      default: null,
    },
    activityId: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    categoriesToHide: {
      type: Array,
      default() {
        return ['ANSWER_MARKED_INCORRECTLY'];
      },
    },
    studentAnswer: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      feedbackCategories: [
        { value: 'ANSWER_MARKED_INCORRECTLY', name: 'I think my answer was marked incorrectly' },
        { value: 'UNCLEAR', name: 'I find the information unclear' },
        { value: 'SPELLING', name: 'I noticed a spelling or grammatical error' },
        { value: 'REQUEST_CHANGE', name: 'I want to request a change to the content' },
        { value: 'OTHER', name: 'Other' },
      ],
      feedback: {
        category: '',
        comment: '',
      },
      isSending: false,
    };
  },
  computed: {
    visibleFeedbackCategories() {
      return this.feedbackCategories.filter(cat => this.isVisible(cat.value));
    },
    feedbackOptions() {
      return this.visibleFeedbackCategories.map(category => ({
        text: category.name,
        value: category.value,
      }));
    },
    isSendButtonDisabled() {
      return this.isSending || !this.feedback.comment || !this.feedback.category;
    },
  },
  methods: {
    ...mapActions('feedbackModule', ['sendFeedback']),
    isVisible(category) {
      return !this.categoriesToHide.includes(category);
    },
    async onSend() {
      this.isSending = true;
      try {
        const { category, comment } = this.feedback;
        await this.sendFeedback({
          subjectNodeId: this.subjectNodeId,
          contentId: this.contentId,
          activityId: this.activityId,
          comment,
          category,
          studentAnswer: category === 'ANSWER_MARKED_INCORRECTLY' ? this.studentAnswer : null,
        });
      } catch (e) {
        if (e.response.data.content && e.response.data.content[0].includes('deleted') >= 0) {
          this.$toast.showInfo(
            'Thank you for your feedback, but this question was deleted from our database.',
          );
        }
        this.closeModal();
        throw e;
      }
      this.$toast.showSuccess('Thank you for your feedback');
      this.isSending = false;
      this.closeModal();
    },
  },
};
</script>
