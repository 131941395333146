<template>
  <div ref="container" />
</template>
<script>
import hljs from 'highlight.js';

import 'highlight.js/styles/xcode.css';

import { keyBy } from 'lodash';
import { mapActions, mapState } from 'vuex';

import ContentBox from 'learning/common/components/content-box.vue';

import { fetchContentBoxTemplates } from 'sharedApp/apis/content-box.js';
import { fetchFeatureList } from 'sharedApp/apis/subject-node-features.js';
import inlineQuestionAnswerToggleUtil from 'sharedApp/components/content/content-material/register-inline-question-answer-toggle-listener.js';
import showSolutionUtil from 'sharedApp/components/content/content-material/register-show-solution-toggle-listener.js';
import useImageClickEvent from 'sharedApp/components/content/content-material/use-image-click-event.ts';
import kogHtmlWrapper from 'sharedApp/components/content/kog-html-wrapper.vue';
import kogNodeLookupService from 'sharedApp/services/utils/nodeLookupService/node-lookup-service.js';
import mountComponent from 'sharedApp/utils/mount-component.ts';
import GlossaryLinks from 'studyApp/components/glossary/glossary-links.vue';

export default {
  name: 'ContentContainer',
  props: {
    content: {
      type: String,
      default: '',
    },
    subjectTree: {
      type: Array,
      default: undefined,
    },
    getSectionUrl: {
      type: Function,
      default: undefined,
    },
    subjectNode: {
      type: Object,
      default: undefined,
    },
    hasContentBlanks: {
      type: Boolean,
      default: false,
    },
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
    userLevel: {
      type: Object,
      default: null,
    },
    subject: {
      type: Object,
      default: null,
    },
    isGlossaryUsed: {
      type: Boolean,
      default: false,
    },
    isReadyToAugment: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['init-interactive-content-done', 'content-rendered'],
  setup() {
    const { registerImageClickHandler } = useImageClickEvent();
    return { registerImageClickHandler };
  },
  data() {
    return {
      h5pInited: false,
      componentsToDestroy: [],
      glossaryLinksDestroyMethods: [],
    };
  },
  computed: {
    ...mapState({
      isGlossaryTermsListLoading(state) {
        return this.isGlossaryUsed ? state.glossaryV2Module?.isTermsListLoading : false;
      },
    }),
  },
  watch: {
    content() {
      this.teardownComponent();
      this.setUpComponent();
    },
    isReadyToAugment() {
      if (this.isReadyToAugment) {
        this.augmentContent();
      }
    },
  },
  created() {
    if (!this.isGlossaryTermsListLoading && this.isGlossaryUsed && this.subject) {
      this.fetchGlossaryDefinitions(this.subject.id);
    }
  },
  mounted() {
    this.setUpComponent();
  },
  beforeUnmount() {
    this.teardownComponent();
  },
  methods: {
    ...mapActions('glossaryV2Module', {
      fetchGlossaryDefinitions: 'fetchGlossaryDefinitions',
    }),
    setUpComponent() {
      const { destroy } = mountComponent({
        component: kogHtmlWrapper,
        props: {
          content: this.hasContentBlanks
            ? this.getScreenReaderAccessibleContent(this.content)
            : this.content,
          containerWidth: this.$refs.container.clientWidth,
          mixpanelContext: this.getMixpanelContext(),
        },
        element: this.$refs.container,
        appContext: this.$.appContext,
      });
      this.componentsToDestroy.push(destroy);
      this.$emit('content-rendered');
      if (this.isReadyToAugment) {
        this.augmentContent();
      }
    },
    async augmentContent() {
      await this.renderContentBoxes();
      await this.initInteractiveContent();
      this.highlightCodeSnippets();
    },
    teardownComponent() {
      this.glossaryLinksDestroyMethods.forEach(destroy => destroy());
      this.componentsToDestroy.forEach(destroy => destroy());
    },
    // replaces underscores with a screen-reader friendly span
    // except inside of the MathText span
    getScreenReaderAccessibleContent(content) {
      let notParsed = content;
      let parsed = '';
      const toReplace = /_{2,}/g;
      const replacement =
        '<span aria-hidden="true"> ______ </span><span class="screenreader-only">; Your answer here; </span>';

      let isInMathtexSpan = false;
      while (notParsed.length > 0) {
        if (notParsed.match(/<span class="math-tex">/)) {
          isInMathtexSpan = true;
          // +5 to include the leading "<span" so we don't count it twice towards `openSpanCount`
          const indexOfMathTexSpanClass = notParsed.indexOf('<span class="math-tex">') + 5;
          const slice = notParsed.slice(0, indexOfMathTexSpanClass);
          parsed += slice.replace(toReplace, replacement);
          notParsed = notParsed.replace(slice, '');
        } else if (isInMathtexSpan) {
          // take everything as it is until we find the first span closing tag
          let openSpanCount = 1;
          while (openSpanCount > 0 && notParsed.includes('</span>')) {
            const endIndexOfClosingSpan = notParsed.indexOf('</span>') + 7;
            const slice = notParsed.slice(0, endIndexOfClosingSpan);
            parsed += slice;
            notParsed = notParsed.replace(slice, '');
            openSpanCount -= 1;

            // count up other potential spans that open inside the math-tex span
            openSpanCount += (slice.match(/<span/g) || []).length;
          }

          isInMathtexSpan = false;
        } else {
          parsed += notParsed.replace(toReplace, replacement);
          notParsed = '';
        }
      }

      return parsed;
    },
    initH5P() {
      if (this.h5pInited) {
        return;
      }

      const h5pUrl = /https:\/\/kognity.h5p.com/;
      const containsH5P = h5pUrl.test(this.content);
      if (containsH5P) {
        const h5pScript = document.createElement('script');
        h5pScript.setAttribute('src', 'https://kognity.h5p.com/js/h5p-resizer.js');
        this.$refs.container.appendChild(h5pScript);
        this.h5pInited = true;
      }
    },
    async initInteractiveContent() {
      if (!this.$refs.container) {
        return;
      }

      this.initH5P();
      inlineQuestionAnswerToggleUtil.registerInlineQuestionAnswerToggleListener(
        this.$refs.container,
      );
      this.registerImageClickHandler(this.$refs.container);
      showSolutionUtil.registerShowSolutionToggleListener(this.$refs.container);
      await this.updateContentLinks();
      this.$emit('init-interactive-content-done');
    },
    async renderContentBoxes() {
      const contentBoxes = this.parseContentBoxesFromContent();
      if (contentBoxes.length > 0) {
        const templateIds = contentBoxes.map(box => box.templateId);
        const templatesById = await this.fetchContentBoxTemplatesById(templateIds);
        const isContentBoxValid = box => !!templatesById[box.templateId];
        const contentBoxesToRemove = contentBoxes.filter(box => !isContentBoxValid(box));
        contentBoxesToRemove.forEach(box => box.htmlElement.remove());
        const contentBoxesToCreate = contentBoxes.filter(isContentBoxValid);
        contentBoxesToCreate.forEach(box => {
          const { htmlElement, titleTag, templateId, contentElement } = box;
          const template = templatesById[templateId];
          const { el, destroy } = mountComponent({
            component: ContentBox,
            props: {
              template,
              titleTag,
              content: contentElement.innerHTML,
              userLevel: this.userLevel,
            },
            appContext: this.$.appContext,
          });
          this.componentsToDestroy.push(destroy);
          htmlElement.replaceWith(el);
        });
      }
    },
    parseContentBoxesFromContent() {
      return [...this.$refs.container.querySelectorAll('content-box')].map(
        contentBoxHtmlElement => ({
          htmlElement: contentBoxHtmlElement,
          templateId: parseInt(contentBoxHtmlElement.getAttribute('template-id'), 10),
          titleTag: contentBoxHtmlElement.getAttribute('title-tag'),
          contentElement: contentBoxHtmlElement.querySelector('.ContentBox-content'),
        }),
      );
    },
    async fetchContentBoxTemplatesById(templateIds) {
      const uniqueTemplateIds = Array.from(new Set(templateIds));
      const params = {
        templateIds: uniqueTemplateIds,
        excludeTeacherOnlyBox: !this.isInReviewMode,
      };
      const templates = await fetchContentBoxTemplates(params);
      return keyBy(templates, 'id');
    },
    getMixpanelContext() {
      if (this.subjectTree && this.subjectNode) {
        return {
          subject_name: this.subjectTree[0].name,
          subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNode.name}`,
        };
      }
      return {};
    },
    highlightCodeSnippets() {
      (this.$refs.container?.querySelectorAll('pre code') ?? []).forEach(block => {
        hljs.highlightElement(block);
      });
    },
    async updateContentLinks() {
      this.updateSectionLinks();
      this.updateGlossaryLinks();
      await this.updateActivityLinks();
    },
    updateSectionLinks() {
      const sectionLinks = this.$refs.container.querySelectorAll("a[href^=':sectionlink:']");
      sectionLinks.forEach(link => this.updateSectionLink(link));
    },
    updateSectionLink(link) {
      const sectionId = this.extractId(link);
      if (sectionId && this.subjectTree) {
        const node = kogNodeLookupService.findNodeBySectionId(sectionId, this.subjectTree);
        if (node) {
          const sectionUrl = this.getSectionUrl(node);
          link.setAttribute('href', sectionUrl);
          link.removeAttribute('target');
        }
      }
    },
    extractId(link) {
      const href = link.getAttribute('href');
      const matches = href.match(/\d+$/);
      if (matches) {
        return Number(matches[0]);
      }
      return null;
    },
    async updateActivityLinks() {
      const activityIds = [];
      const activityLinks = this.$refs.container.querySelectorAll("a[href^=':activitylink:']");
      activityLinks.forEach(link => {
        activityIds.push(this.extractId(link));
      });
      if (activityIds.length > 0) {
        const activities = await fetchFeatureList(activityIds);
        activityLinks.forEach(link => this.updateActivityLink(link, activities));
      }
    },
    updateActivityLink(link, activities) {
      const activityId = this.extractId(link);
      if (activityId && this.subjectTree) {
        const activity = activities.find(a => a.id === activityId);
        let node;
        activity.mapped_subject_nodes.forEach(nodeId => {
          node = kogNodeLookupService.findNodeById(nodeId, this.subjectTree);
        });
        if (node) {
          const sectionUrl = this.getSectionUrl(node);
          link.setAttribute('href', sectionUrl);
          link.removeAttribute('target');
        }
      }
    },
    updateGlossaryLinks() {
      const links = this.$refs.container?.querySelectorAll('glossary-link') ?? [];
      if (links.length > 0) {
        links.forEach(link => {
          const { el, destroy } = mountComponent({
            component: GlossaryLinks,
            props: {
              subject: this.subject,
              definitionId: parseInt(link.getAttribute('definition-id'), 10),
              termId: parseInt(link.getAttribute('term-id'), 10),
              linkedText: link.getAttribute('linked-text'),
            },
            elementCssClasses: ['inline-block'],
            appContext: this.$.appContext,
          });
          link.replaceWith(el);
          this.glossaryLinksDestroyMethods.push(destroy);
        });
      }
    },
  },
};
</script>

<style>
.content-image-figure {
  margin: 15px;
}

.content-image-figure button {
  min-height: 100px;
}

.content-image-figure button:focus {
  outline: none;
}
</style>
