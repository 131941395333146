<template>
  <kog-tag
    type="informative"
    :context="screenReaderLabel"
    :label="label"
    :size="size"
  />
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import { isNumberPaperType } from 'sharedApp/services/assignment/assignment-utility-service.js';

export default {
  name: 'PapertypeTag',
  components: { KogTag },
  props: {
    name: {
      type: String,
      required: true,
    },
    shouldPrefixNumberPapertype: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
      validator: value => ['s', 'm'].includes(value),
    },
  },
  computed: {
    label() {
      if (!isNumberPaperType(this.name)) {
        return this.name;
      }
      return `${this.shouldPrefixNumberPapertype ? 'Paper ' : ''}${this.name}`;
    },
    screenReaderLabel() {
      return `Paper type: ${this.label}`;
    },
  },
};
</script>
