<template>
  <div :class="{ 'KogDropdownItem-divider': item.divider && !newStyle }">
    <a
      :id="item.id"
      :href="item.href"
      :class="[
        { 'KogDropdownItem-anchor--newStyle': newStyle },
        { 'KogDropdownItem--allowWrapping': allowWrapping },
        ...extraClasses,
      ]"
      :target="target"
      class="KogDropdownItem-anchor cursorPointer"
      @click="handleClick($event, item)"
    >
      {{ item.text }}
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="item.itemHtml" />
    </a>
  </div>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { useEventBus } from '@vueuse/core';

export default {
  name: 'KogDropdownItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    newStyle: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_self',
    },
    allowWrapping: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['itemClick'],
  setup() {
    const kogDropdownCloseEventBus = useEventBus('kogDropdownClose');
    return {
      kogDropdownCloseEventBus,
    };
  },
  computed: {
    extraClasses() {
      return this.item.extraClasses || [];
    },
  },
  methods: {
    handleClick(event, item) {
      if (item.href === '#') {
        event.preventDefault();
      }
      item.action();
      this.$emit('itemClick', item);
      this.kogDropdownCloseEventBus.emit();
    },
  },
};
</script>

<style scoped>
.KogDropdownItem-divider {
  border-bottom: 1px solid var(--kog-dropdown-divider);
}

.KogDropdownItem-anchor {
  display: block;

  padding: var(--space-s) var(--space-m);

  font-weight: normal;
  line-height: 1.42857143;
  color: var(--kogPlatformGray018);
  white-space: nowrap;
}

.KogDropdownItem-anchor:hover {
  color: var(--kog-text-brand-hard);
  text-decoration: none;
  background-color: var(--kog-background-brand-900);
}

.KogDropdownItem-anchor--newStyle {
  display: block;

  margin: 2px 0;
  padding: 12px 16px;

  font-weight: normal;
  line-height: 1.42857143;
  color: var(--kog-text-default);
  white-space: nowrap;
}

.KogDropdownItem-anchor--newStyle:hover {
  color: var(--kog-text-default);
  text-decoration: none;
  background-color: var(--kog-list-item-hover-background);
}

.KogDropdownItem--allowWrapping,
.KogDropdownItem--allowWrapping .KogDropdownItem-anchor {
  white-space: normal;
}
</style>
