<template>
  <kog-loader
    v-if="$wait.is('loading-quiz-statistics')"
    :loading="$wait.is('loading-quiz-statistics')"
    flex
  />
  <div
    v-else
    class="QuizContainer-statisticsContainer flexContainer flexContainer-alignCenter padd-xs flexContainer-spaceBetween width-full"
  >
    <content-node-icon
      icon-class="fa-block-question"
      class="margin-right-s"
    />
    <div class="flexContainer flexChild-size-1 flexContainer-alignCenter">
      <div class="flexContainer flexContainer-justifyCenter flexContainer-column">
        <span class="heading-xs margin-right-xs"> Submitted quizzes by students </span>
      </div>
    </div>
    <div class="flexContainer flexContainer-alignCenter">
      <kog-tag
        is-chip
        class="margin-right-m"
        icon-class="fa-user-check"
        size="m"
        type="informative"
        :label="`${studentsWithSubmittedQuiz.length}/${subjectClassStudentList.length}`"
        @click="openStudentResponsesModal"
      />
    </div>
  </div>
</template>

<script>
import isNil from 'lodash/isNil.js';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ContentNodeIcon from 'studyApp/components/subject-class-overview/content-node-icon.vue';
import QuizStudentResponsesModal from 'studyApp/pages/features/quiz/quiz-student-responses-modal.vue';

export default {
  name: 'QuizStatisticsContainer',
  components: {
    ContentNodeIcon,
    KogLoader,
    KogTag,
  },
  mixins: [RoutesMixin],
  props: {},
  data() {
    return {
      studentsWithSubmittedQuiz: [],
    };
  },
  computed: {
    ...mapState({
      quiz: state => state.quizModule.quiz,
      studentOccasions: state => state.quizModule.studentOccasions,
      subjectClassStudentList: state => state.teacherStudentListModule.subjectClassStudentsList,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
    }),
    subjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
  },
  async created() {
    await Promise.all([
      this.fetchSubjectClassStudentsList(this.classId),
      this.fetchStudentOccasions({
        quizId: this.quiz.id,
        classId: this.classId,
      }),
    ]);
    this.subjectClassStudentList.forEach(student => {
      if (this.studentOccasions.some(occasion => occasion.user === student.user_id)) {
        this.studentsWithSubmittedQuiz.push(student);
      }
    });
    if (!isNil(this.$route.query.student)) {
      this.openStudentResponsesModal();
    }
  },
  methods: {
    ...mapWaitingActions({
      fetchStudentOccasions: {
        action: 'quizModule/fetchStudentOccasions',
        loader: 'loading-quiz-statistics',
      },
      fetchSubjectClassStudentsList: {
        action: 'teacherStudentListModule/fetchSubjectClassStudentsList',
        loader: 'loading-quiz-statistics',
      },
    }),
    openStudentResponsesModal() {
      this.$modal(QuizStudentResponsesModal, {
        quizId: this.quiz.id,
        studentsInClass: this.subjectClassStudentList,
        classId: this.classId,
        source: 'book',
        subjectNode: this.subjectNode,
      });
    },
  },
};
</script>

<style scoped>
.QuizContainer-statisticsContainer {
  background: var(--kog-colors-aubergine-100);
  border-radius: 12px;
}
</style>
