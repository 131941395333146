import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';

import Component from './block.vue';

const template = {
  block_type: BLOCK_TYPE.DIVIDER,
  type: 'ALPHABETICAL',
};

const validator = {
  errors: [],
  validate() {
    return true;
  },
};

const preSaveHandler = block => {
  return {
    payload: block,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
};

export default {
  component: Component,
  template,
  validator,
  preSaveHandler,
};
