import axios from 'sharedApp/vue-utils/kog-axios.ts';

export class APIError extends Error {
  constructor(message, httpStatus, data) {
    super(message);
    this.name = 'APIError';
    this.httpStatus = httpStatus;
    this.data = data;
    this.detail = data && data.detail ? data.detail : null;
  }

  get shouldSuggestRefresh() {
    return !this.httpStatus || this.httpStatus === 401 || this.httpStatus >= 500;
  }
}

export function generateStoreTypesForRequest(name) {
  return {
    mutationTypes: {
      REQUEST: `${name}_REQUEST`,
      SUCCESS: `${name}_SUCCESS`,
      FAILURE: `${name}_FAILURE`,
    },
    stateKeys: {
      isLoading: `${name}_LOADING`,
      data: `${name}_DATA`,
      errors: `${name}_ERRORS`,
    },
  };
}

export async function apiGET(store, url, mutationTypes, options = {}) {
  store.commit(mutationTypes.REQUEST);

  try {
    const response = await axios.get(url, options);
    store.commit(mutationTypes.SUCCESS, response.data);
  } catch (err) {
    const errorDetails = new APIError(
      `Failed to GET ${url}`,
      err.response ? err.response.status : null,
      err.response ? err.response.data : null,
    );
    store.commit(mutationTypes.FAILURE, errorDetails);

    if (!err.response && !err.request) {
      throw err;
    }
  }
}

export async function apiDELETE(store, url, mutationTypes, options = {}) {
  store.commit(mutationTypes.REQUEST);

  try {
    const response = await axios.delete(url, options);
    store.commit(mutationTypes.SUCCESS, response.data);
  } catch (err) {
    const errorDetails = new APIError(
      `Failed to DELETE ${url}`,
      err.response ? err.response.status : null,
      err.response ? err.response.data : null,
    );
    store.commit(mutationTypes.FAILURE, errorDetails);

    if (!err.response && !err.request) {
      throw err;
    }
  }
}

export async function apiPUT(store, url, mutationTypes, data, options = {}) {
  store.commit(mutationTypes.REQUEST);

  try {
    const response = await axios.put(url, data, options);
    store.commit(mutationTypes.SUCCESS, response.data);
  } catch (err) {
    const errorDetails = new APIError(
      `Failed to PUT ${url}`,
      err.response ? err.response.status : null,
      err.response ? err.response.data : null,
    );
    store.commit(mutationTypes.FAILURE, errorDetails);

    if (!err.response && !err.request) {
      throw err;
    }
  }
}

export async function apiPOST(store, url, mutationTypes, data, options = {}) {
  store.commit(mutationTypes.REQUEST);

  try {
    const response = await axios.post(url, data, options);
    store.commit(mutationTypes.SUCCESS, response.data);
  } catch (err) {
    const errorDetails = new APIError(
      `Failed to POST ${url}`,
      err.response ? err.response.status : null,
      err.response ? err.response.data : null,
    );
    store.commit(mutationTypes.FAILURE, errorDetails);

    if (!err.response && !err.request) {
      throw err;
    }
  }
}

export function createApiMutations({ mutationTypes, stateKeys }) {
  return {
    [mutationTypes.REQUEST](state) {
      state[stateKeys.isLoading] = true;
      state[stateKeys.errors] = null;
    },
    [mutationTypes.SUCCESS](state, payload) {
      state[stateKeys.isLoading] = false;
      state[stateKeys.data] = payload;
    },
    [mutationTypes.FAILURE](state, payload) {
      state[stateKeys.isLoading] = false;
      state[stateKeys.errors] = payload;
    },
  };
}

export function generateRequestError(error, resource, method = 'GET') {
  if (!error.response && !error.request) {
    throw error;
  }

  const errorDetails = new APIError(
    `Failed to ${method} ${resource}`,
    error.response ? error.response.status : null,
    error.response ? error.response.data : null,
  );

  return errorDetails;
}
