<template>
  <div>
    <kog-tag
      class="AssignmentBasket"
      :is-chip="true"
      :class="`AssignmentBasket--${theme}`"
      icon-class="fa-clipboard-list-check"
      :label="`${toDoAssignments.length}`"
      @click="toggleBasket"
    />
    <div
      v-show="isOpen"
      class="absoluteContainer flexContainer flexContainer-justifyCenter"
    >
      <div
        class="AssignmentBasket--popup shadow-l-020 body-text flexContainer flexContainer-column"
      >
        <div
          class="flexContainer flexContainer-spaceBetween width-full flexContainer-center padd-m"
        >
          <span class="text-overline">Assignments</span>
          <kog-round-button
            aria-label="Close popup"
            icon-class="fa-times"
            icon-style="solid"
            size="small"
            button-style="secondary-basic"
            @click.prevent="closeBasket"
          />
        </div>
        <div
          v-if="hasToDoAssignments"
          class="AssignmentBasket-assignmentList margin-bottom-m"
        >
          <div
            v-for="assignment in toDoAssignments"
            :key="assignment.id"
            class="AssignmentBasket-assignment flexContainer flexContainer-column"
          >
            <div class="flexContainer flexContainer-row flexContainer-spaceBetween">
              <router-link
                class="AssignmentBasket-assignment-title heading-xxs"
                :to="getAssignmentRoute(assignment)"
              >
                {{ assignment.name }}
              </router-link>
              <kog-tag
                v-if="assignment.has_deadline_passed"
                is-chip
                context="Late"
                label="Late"
                :hide-overflow="false"
                type="negative"
              />
            </div>
            <div>
              <kog-icon
                icon-class="fa-clock"
                fa-style="regular"
                :is-non-actionable="true"
                size="s"
              />
              <b>Due:</b> {{ getReadableDeadline(assignment.deadline) }}
            </div>
            <div>{{ getReadableAssignmentType(assignment.assignment_type) }} assignment</div>
          </div>
        </div>
        <div
          v-else
          class="padd-m flexContainer flexContainer-column"
        >
          <img
            alt=""
            src="~studyApp/images/empty-assignment-basket-student.png"
          />
          <span class="margin-top-s">There are no active assignments.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy.js';
import { storeToRefs } from 'pinia';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import {
  getTakeAssignmentRoute,
  isExamStyleType,
  isReadingType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'StudentAssignmentBasket',
  components: {
    KogIcon,
    KogRoundButton,
    KogTag,
  },
  mixins: [RoutesMixin],
  props: {
    theme: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'light'].includes(prop),
    },
  },
  setup() {
    const { assignments } = storeToRefs(useAssignmentStore());

    return {
      subjectAssignments: assignments,
    };
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    hasToDoAssignments() {
      return this.toDoAssignments.length > 0;
    },
    toDoAssignments() {
      return sortBy(
        this.subjectAssignments.results.filter(assignment => !assignment.is_completed),
        'deadline',
      );
    },
  },
  watch: {
    classId: {
      handler() {
        this.closeBasket();
      },
      immediate: true,
    },
  },
  methods: {
    getAssignmentRoute(assignment) {
      return getTakeAssignmentRoute({
        assignment,
        subjectId: this.subjectId,
        classId: this.classId,
        classSlug: this.subjectClassSlug,
        source: 'assignment_basket',
      });
    },
    getReadableAssignmentType(assignmentType) {
      if (isReadingType(assignmentType)) {
        return 'Reading';
      }
      if (isExamStyleType(assignmentType)) {
        return this.$term('exam-style');
      }
      return 'Question';
    },
    getReadableDeadline(assignmentDeadline) {
      return dateTimeFormatter(assignmentDeadline);
    },
    closeBasket() {
      this.isOpen = false;
    },
    toggleBasket() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style>
:root {
  --AssignmentBasketHeaderAndFooter: 129px;
}
</style>

<style scoped>
.AssignmentBasket {
  background-color: var(--kogInherit);
}

.AssignmentBasket--light {
  color: var(--kog-colors-white);
  border-color: var(--kog-colors-white);
}

.AssignmentBasket--light:hover {
  border-color: var(--kog-colors-white);
}

.AssignmentBasket--primary {
  color: var(--kog-text-brand);
  border-color: var(--kog-border-brand-900);
}

.AssignmentBasket--primary:hover {
  border-color: var(--kog-border-brand-900);
}

.AssignmentBasket--popup {
  position: absolute;

  overflow-y: hidden;
  display: flex;

  width: 320px;
  max-height: 90vh;
  margin-top: var(--space-xs);

  background-color: var(--kog-colors-white);
  border-radius: 10px;
}

.AssignmentBasket--popupEditableSection {
  overflow-y: scroll;
  max-height: calc(90vh - var(--AssignmentBasketHeaderAndFooter));
}

.AssignmentBasket-assignmentList {
  overflow-y: auto;
}

.AssignmentBasket-assignment {
  margin-top: var(--space-xxs);
  margin-right: var(--space-m);
  margin-left: var(--space-m);
  padding: 2px var(--space-xxs) 2px var(--space-s);

  border-left: 1px solid var(--kog-colors-grey-500);
}

.AssignmentBasket-assignment:hover {
  border-color: var(--kog-colors-grey-900);
}
.AssignmentBasket-assignment-title {
  color: var(--kog-text-default);
}
.AssignmentBasket-assignment-title:hover {
  color: var(--kog-text-default);
}
</style>
