import { Plugin } from '@ckeditor/ckeditor5-core';

import OpenEndedTaskEditing from './open-ended-task-editing.js';
import OpenEndedTaskUI from './open-ended-task-ui.js';

export default class OpenEndedTask extends Plugin {
  static get requires() {
    return [OpenEndedTaskEditing, OpenEndedTaskUI];
  }
}
