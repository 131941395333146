<template>
  <div
    ref="root"
    class="SectionDefaultTemplate-wrapper"
  >
    <div class="kog-container-lg">
      <div class="kog-row">
        <div class="kog-col-12 SectionDefaultTemplate kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
          <portal
            :to="
              mq.current === 'xl' ? `${uid}-section-header-desktop` : `${uid}-section-header-mobile`
            "
          >
            <section-header
              :subject-node-id="currentSectionNodeId"
              :is-content-containing-reflection-box="isContentContainingReflectionBox"
              :is-activity-page-template="false"
              :is-in-review-mode="isInReviewMode"
              :section-node="currentSectionNode"
            />
          </portal>
          <portal-target :name="`${uid}-section-header-mobile`" />
          <portal-target :name="`${uid}-section-header-desktop`" />
          <teacher-instructions
            v-if="isInReviewMode"
            :subject="subject"
            :subject-node="currentSectionNode"
            :content="currentSectionNode.instructions_html"
            :user="user"
          />
          <annotate-text
            ref="content"
            :key="currentSectionNodeId"
            :current-subtopic-text="currentSubtopicText"
            :highlight-enabled="canHighlight"
            :subject="subject"
            :subject-node="currentSectionNode"
            :content="subjectNodeContent"
            :user="user"
            :note-search-param="noteSearchParam"
            :subject-tree="subject.subject_tree"
            :is-in-review-mode="isInReviewMode"
            @content-altered="loadImmersiveReader"
          />
        </div>
      </div>
    </div>
    <checkpoint-container
      v-if="!isInReviewMode"
      :key="currentSectionNodeId"
      :current-section-node="currentSectionNode"
      complete-button-icon="fa-badge-check"
      complete-button-text="Mark as complete"
      completion-text="Completed"
      :is-complete-button-disabled="!hasAnsweredAllReflectionQuestions"
      :complete-button-tooltip="completeButtonTooltip"
      :is-loading="$wait.is('loading_book_checkpoint')"
      @completed="markAsCompleted"
      @reset="markAsIncomplete"
    />
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import AnnotateText from 'learning/common/components/annotate-text.vue';

import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { canUserHighlight } from 'sharedApp/utils/user-utils.js';
import CheckpointContainer from 'studyApp/components/section-text/checkpoint-container.vue';
import SectionCompletedModal from 'studyApp/components/section-text/section-completed-modal.vue';
import SectionHeader from 'studyApp/components/section-text/section-header.vue';
import TeacherInstructions from 'studyApp/components/section-text/teacher-instructions.vue';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';
import {
  areAllReflectionQuestionsAnswered,
  isContentContainingReflectionBox,
} from 'studyApp/utils/reflection-question-utils.js';

export default {
  name: 'SectionDefaultTemplate',
  components: {
    CheckpointContainer,
    TeacherInstructions,
    AnnotateText,
    SectionHeader,
  },
  mixins: [RoutesMixin, ReadingAssignmentMixin],
  inject: ['mq'],
  props: {
    user: {
      type: Object,
      required: true,
    },
    currentSectionNode: {
      type: Object,
      required: true,
    },
    subtopicNode: {
      type: Object,
      required: true,
    },
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { uid } = useUniqueId();

    return {
      uid,
    };
  },
  computed: {
    ...mapState({
      ongoingReadingAssignmentOccasions: state =>
        state.bookModule.ongoingReadingAssignmentOccasions,
      sectionCheckpointOccasionId: state => state.bookModule.sectionCheckpointOccasionId,
      reflectionQuestions: state => state.questionsModule.reflectionQuestions,
      exerciseOccasions: state => state.exerciseOccasionsModule.exerciseOccasions,
      sectionQuestionCount: state => state.bookModule.sectionQuestionCount,
      subject: state => state.subjectModule.subject,
      level: state => state.subjectModule.level,
    }),
    ...mapGetters({
      sectionNodes: 'bookModule/sectionNodes',
      leafNodesWithContent: 'subjectModule/leafNodesWithContent',
    }),
    currentSectionNodeId() {
      return this.currentSectionNode.id;
    },
    canHighlight() {
      return canUserHighlight(this.user, this.isInReviewMode);
    },
    subjectNodeContent() {
      return this.currentSectionNode.contents_html;
    },
    noteSearchParam() {
      return this.$route.query.note;
    },
    currentLeafNodeIndex() {
      return this.leafNodesWithContent.findIndex(ln => ln.id === this.nodeId);
    },
    nextLeafNode() {
      return this.leafNodesWithContent[this.currentLeafNodeIndex + 1] ?? null;
    },
    nextPageRoute() {
      if (this.nextLeafNode) {
        return {
          name: 'classBook',
          params: {
            classSlug: this.subjectClassSlug,
            sid: this.subjectId,
            cid: this.classId,
            nodeSlug: this.nextLeafNode.slug,
            nodeId: this.nextLeafNode.id,
          },
        };
      }
      return null;
    },
    isLastNode() {
      const [lastNode] = this.sectionNodes.slice(-1);
      return lastNode.id === this.nodeId;
    },
    isContentContainingReflectionBox() {
      return isContentContainingReflectionBox(this.subjectNodeContent);
    },
    currentSubtopicText() {
      return `${this.subtopicNode.formatted_number_including_ancestors} ${this.subtopicNode.name}`;
    },
    completeButtonTooltip() {
      if (this.hasAnsweredAllReflectionQuestions) {
        return '';
      }

      return 'You must answer all reflection questions in this section before completing';
    },
    hasAnsweredAllReflectionQuestions() {
      return (
        !this.isReflectionTemplate ||
        areAllReflectionQuestionsAnswered(this.reflectionQuestions, this.exerciseOccasions)
      );
    },
    isReflectionTemplate() {
      return this.currentSectionNode.template === 'reflection';
    },
    trackingProps() {
      const sectionType = this.isInReadingAssignment
        ? 'Reading assignment section'
        : 'Regular section';
      return {
        section_type: sectionType,
        subject_node_name: `${this.currentSectionNode.formatted_number_including_ancestors} ${this.currentSectionNode.name}`,
        question_count: this.sectionQuestionCount,
        source: this.$route.name === 'classReadingAssignmentTake' ? 'assignment' : 'book',
      };
    },
  },
  watch: {
    currentSectionNodeId: {
      immediate: true,
      handler(newId) {
        this.fetchSectionQuestionCount({
          subjectNodeId: newId,
          level: this.level?.id,
        });
      },
    },
  },
  methods: {
    ...mapActions({
      fetchSectionQuestionCount: 'bookModule/fetchSectionQuestionCount',
    }),
    ...mapWaitingActions('statisticModule', {
      updateOrCreateNodeProgress: 'loading_book_checkpoint',
    }),
    ...mapMutations({
      setImmersiveReaderContent: 'bookModule/setImmersiveReaderContent',
    }),
    async markAsCompleted() {
      try {
        await this.updateSectionProgress(true);
      } catch (error) {
        this.$toast.showError(
          'Failed to change completion status! Please refresh the page and try again!',
        );
        throw error;
      }
      this.showCompletedModal();
      this.trackSectionComplete();
    },
    async markAsIncomplete() {
      try {
        await this.updateSectionProgress(false);
      } catch (error) {
        this.$toast.showError(
          'Failed to change completion status! Please refresh the page and try again!',
        );
        throw error;
      }
    },
    showCompletedModal() {
      if (this.isInReadingAssignment) {
        this.showReadingAssignmentModal(this.nodeId);
      } else if (this.isLastNode) {
        this.$modal(SectionCompletedModal, {
          sectionNodeId: this.nodeId,
          nextPageRoute: this.nextPageRoute,
          nextSectionNode: this.nextLeafNode,
        });
      }
    },
    async updateSectionProgress(isCompleted) {
      await this.updateOrCreateNodeProgress({
        subjectNodeId: this.nodeId,
        subjectId: this.subject.id,
        occasionId: this.sectionCheckpointOccasionId,
        isCompleted,
      });
    },
    trackSectionComplete() {
      this.$mixpanel.trackEventViaBackend('Complete section', this.trackingProps);
      intercomModule.trackEvent('completed-book-section');
      if (this.user.isStudent()) {
        this.$event.track('complete_section', {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          subject_node_id: this.nodeId,
          practice_occasion_id: this.sectionCheckpointOccasionId,
        });
      }
    },
    loadImmersiveReader() {
      if (!this.isInReviewMode) {
        const content = this.$refs.content.$refs.root.innerHTML;
        this.setImmersiveReaderContent(content);
      }
    },
  },
};
</script>

<style scoped>
.SectionDefaultTemplate-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.kog-container-lg {
  flex: 1 1 auto;
}
</style>
