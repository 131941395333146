/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, watch } from 'vue';
import { isEqual } from 'lodash';

import type { Ref } from 'vue';
import type { Question } from './question-context/types';

type Fn = (...args: any[]) => any;
type InferredArgs<F> = F extends (...args: infer U) => any ? U : never;
type AutoSaveProps<S, I> = {
  onSave: S;
  onInput: I;
  timerMS?: number;
};

export function useQuestionAutoSave<S extends Fn, I extends S>(
  question: Ref<Question>,
  { onInput, onSave, timerMS = 1000 }: AutoSaveProps<S, I>,
) {
  const lastValue = ref<InferredArgs<S>>();
  let autoSaveTimeout: ReturnType<typeof setTimeout> | undefined;

  watch(question, () => {
    clearTimeout(autoSaveTimeout);
  });

  const saveHandler = (...args: InferredArgs<S>) => {
    if (isEqual(lastValue.value, args)) return;
    clearTimeout(autoSaveTimeout);
    onSave(...args);
    lastValue.value = args;
  };

  const inputHandler = (...args: InferredArgs<I>) => {
    onInput(...args);
    clearTimeout(autoSaveTimeout);
    autoSaveTimeout = setTimeout(() => {
      saveHandler(...args);
    }, timerMS);
  };

  return {
    inputHandler,
    saveHandler,
  };
}
