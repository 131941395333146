<template>
  <fill-in-the-blanks-question
    :question="question"
    :user-answers="userAnswers"
    :is-showing-answer-correctness="isShowingAnswerCorrectness"
    :answers-with-correctness="userAnswersCorrectness"
    :disable-user-input="disabled"
    @blur="handleUserResponse"
  />
</template>

<script>
import { isNil } from 'lodash';

import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';

export default {
  name: 'ActivityFbq',
  components: {
    FillInTheBlanksQuestion,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    /*
      user_response?: Record<string, string>
    */
    context: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['response'],
  computed: {
    userAnswers() {
      return this.context.user_response;
    },
    isShowingAnswerCorrectness() {
      return 'user_response_correctness' in this.context;
    },
    userAnswersCorrectness() {
      const userResponseCorrectness = this.context.user_response_correctness;
      if (isNil(userResponseCorrectness)) {
        return {};
      }

      const correctedStudentAnswers = {};
      Object.keys(userResponseCorrectness).forEach(fbqItem => {
        correctedStudentAnswers[fbqItem] = {
          correct: userResponseCorrectness[fbqItem],
        };
      });

      return correctedStudentAnswers;
    },
  },
  methods: {
    handleUserResponse(response) {
      this.$emit('response', {
        user_response: response.answers,
        response_type: RESPONSE_TYPES.FBR,
      });
    },
  },
};
</script>
