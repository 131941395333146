<template>
  <div class="flexContainer flexContainer-alignCenter cursorDefault">
    <kog-tag
      :label="labelText"
      type="warning"
      icon-class="fa-lock-alt"
      :tooltip-text="tooltipText"
      class="margin-right-m"
    />
  </div>
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

const DEFAULT_TOOLTIP = `The subscription type for the student’s year group does not give
    access to this subject. Upgrade the year group’s subscription type to grant the student
    access to this subject.`;

export default {
  name: 'AccessMissingLabel',
  components: {
    KogTag,
  },
  props: {
    labelText: {
      type: String,
      default: 'Access missing',
    },
    tooltipText: {
      type: String,
      default: DEFAULT_TOOLTIP,
    },
  },
};
</script>
