<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :class="classAttr"
    :style="styleAttr"
  >
    <template v-if="isCover">
      <div
        class="ImageBlock-coverWrapper"
        :style="{ '--cover-height': `${imageHeight}px` }"
      >
        <div class="ImageBlock-innerWrapper positionAbsolute">
          <image-box
            :src="imageSource"
            :transparent-background="true"
            :quality="75"
            :alt="imageAltText"
            auto="format"
            fit="crop"
            crop="entropy"
            :h="imageHeight"
          />
          <div
            class="ImageBlock-coverCaptionWrapper padd-left-s padd-top-xs padd-right-s padd-bottom-xs"
          >
            <div
              v-if="imageCaption"
              class="ImageBlock-caption font-size-content"
              >{{ imageCaption }}</div
            >
            <div
              v-if="imageCredit"
              class="ImageBlock-credit text-regular"
              v-html="imageCredit"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <image-box
        :src="imageSource"
        :transparent-background="true"
        :alt="imageAltText"
        :h="600"
      />
      <div
        class="flexContainer flexContainer-alignCenter flexContainer-justifyCenter flexContainer-column padd-top-xs"
      >
        <div
          v-if="imageCaption"
          class="ImageBlock-caption font-size-content"
          >{{ imageCaption }}</div
        >
        <div
          v-if="imageCredit"
          class="ImageBlock-credit text-regular"
          v-html="imageCredit"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ImageBox from 'sharedApp/components/images/image-box.vue';
import useStyleClassExtractedAttrs from 'sharedApp/composables/use-style-class-extracted-attrs.ts';

export default {
  name: 'ActivityImageBlock',
  components: {
    ImageBox,
  },
  inject: ['mq'],
  inheritAttrs: false,
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const extractedAttrs = useStyleClassExtractedAttrs();

    return {
      ...extractedAttrs,
    };
  },
  computed: {
    isCover() {
      return this.content.display_type === 'cover';
    },
    imageSource() {
      return this.content.image;
    },
    imageCaption() {
      return this.content.caption;
    },
    imageCredit() {
      return this.content.image_credit;
    },
    imageAltText() {
      return this.content.alternate_text;
    },
    imageHeight() {
      switch (this.mq.current) {
        case 'xs':
          return 210;
        case 's':
          return 310;
        case 'm':
          return 410;
        default:
          return 510;
      }
    },
  },
};
</script>

<style scoped>
.ImageBlock-coverWrapper {
  position: relative;
  height: var(--cover-height);
  min-height: var(--cover-height);
}
.ImageBlock-innerWrapper {
  left: calc(-50vw + 50% + var(--content-offset-left) / 2);
  width: var(--content-max-width);
  transition: all 300ms ease;
}

.ImageBlock-innerWrapper :deep(img) {
  width: 100%;
  object-fit: cover;
}

.ImageBlock-coverCaptionWrapper {
  position: absolute;
  z-index: 1;
  right: 10px;
  bottom: 10px;

  opacity: 0.7;
  background-color: var(--kog-colors-white);

  transition: all 300ms ease;
}

.ImageBlock-caption {
  font-family: var(--kog-satoshi);
  color: var(--kog-colors-grey-900);
}

.ImageBlock-credit {
  font-family: var(--kog-satoshi);
  color: var(--kog-colors-grey-700);
}
</style>
