<template>
  <div class="KogDashboard">
    <study-navbar
      :notifications="notifications"
      :subjects-by-id="subjectsById"
      :mark-as-seen="markAsSeen"
      :user="user"
      :subject-classes="subjectClassesWithAccess"
      :route-name="$route.name"
    />
    <div class="KogDashboard-pageContainer">
      <kog-loader
        :loading="loading"
        loading-msg="Loading..."
        :flex="true"
      >
        <h1 class="screenreader-only"> Your dashboard </h1>
        <div class="KogDashboard-insideLoader">
          <student-activity
            :user-name="userName"
            :school-name="schoolName"
            :avatar-url="avatarURL"
            :activity="activity"
            :aggregated-activity="aggregatedActivity"
          />
          <div class="KogDashboard-pageInnerContainer">
            <div class="KogDashboard-content flexContainer flexContainer-wrap width-100">
              <div class="KogDashboard-leftContainer">
                <subject-classes
                  :subjects-by-id="subjectsById"
                  :subject-classes-with-access="subjectClassesWithAccess"
                  :subject-classes-without-access="subjectClassesWithoutAccess"
                />
              </div>
              <div class="KogDashboard-rightContainer flexContainer flexContainer-column">
                <assignment-list
                  :assignments="ongoingAssignments"
                  :next-assignment-page="nextOngoingAssignmentPage"
                  :previous-assignments-count="prevOngoingAssignmentsCount"
                  :load-more-assignments="loadMoreOngoingAssignments"
                  :subjects-by-id="subjectsById"
                  :subject-classes="subjectClassesWithAccess"
                />
              </div>
            </div>
          </div>
        </div>
      </kog-loader>
      <kog-footer :absolute="false" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useHead } from '@unhead/vue';
import axios from 'axios';
import { uniq } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

import StudyNavbar from 'learning/common/components/study-navbar.vue';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogFooter from 'sharedApp/components/core/kog-footer.vue';
import { fetchOngoingAssignments as fetchAllOngoingAssignments } from 'studyApp/api/assignment.js';
import AssignmentList from 'studyApp/components/dashboard/assignment-list.vue';
import AssignmentNotNeededModal from 'studyApp/components/dashboard/assignment-not-needed-modal.vue';
import StudentActivity from 'studyApp/components/dashboard/student-activity.vue';
import SubjectClasses from 'studyApp/components/dashboard/subject-classes.vue';

export default defineComponent({
  name: 'StudyDashboardContainer',
  components: {
    StudyNavbar,
    SubjectClasses,
    AssignmentList,
    StudentActivity,
    KogFooter,
    KogLoader,
  },
  setup() {
    const ongoingAssignments = ref([]);
    const prevOngoingAssignmentsCount = ref(0);
    const nextOngoingAssignmentPage = ref(null);

    async function fetchOngoingAssignments() {
      const data = await fetchAllOngoingAssignments();
      ongoingAssignments.value = data.results;
      nextOngoingAssignmentPage.value = data.next;
    }

    async function loadMoreOngoingAssignments() {
      if (nextOngoingAssignmentPage.value) {
        const response = await axios.get(nextOngoingAssignmentPage.value);
        ongoingAssignments.value = ongoingAssignments.value.concat(response.data.results);
        const { page, page_size: pageSize, next } = response.data;
        nextOngoingAssignmentPage.value = next;
        prevOngoingAssignmentsCount.value = (page - 1) * pageSize;
      }
    }

    useHead({
      title: 'Dashboard',
    });

    return {
      ongoingAssignments,
      prevOngoingAssignmentsCount,
      nextOngoingAssignmentPage,
      fetchOngoingAssignments,
      loadMoreOngoingAssignments,
    };
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      schoolName: state => state.userModule.user.school.name,
      userName: state => `${state.userModule.user.first_name} ${state.userModule.user.last_name}`,
      avatarURL: state => state.userModule.user.profile.avatar_url,
      notifications: state => state.notificationsModule.notifications,
      aggregatedActivity: state => state.dashboardActivityModule.aggregatedActivity,
      activity: state => state.dashboardActivityModule.activity,
      subjectClasses: state => state.subjectClassModule.subjectClasses,
    }),
    ...mapGetters({
      subjectsById: 'subjectModule/subjectsById',
    }),
    subjectClassesWithAccess() {
      return this.subjectClasses.filter(sc => sc.is_subscription_ok);
    },
    subjectClassesWithoutAccess() {
      return this.subjectClasses.filter(sc => !sc.is_subscription_ok);
    },
  },
  watch: {
    loading(isLoading) {
      const { error } = this.$route.query;
      if (error !== 'noassignment') {
        return;
      }
      if (!isLoading) {
        this.openAssignmentNotNeededModal();
      }
    },
  },
  async created() {
    this.fetchActivityAggregated();
    this.fetchActivity();
    const startTime = performance.now();
    await Promise.all([this.fetchSubjectClassList(), this.fetchOngoingAssignments()]);
    const subjectIds = uniq(this.subjectClasses.map(sc => sc.subject_id));
    await this.fetchSubjectList(subjectIds);
    const loadClassesTime = Math.floor(performance.now() - startTime, 10);
    this.loading = false;

    this.$mixpanel.trackEvent('Study dashboard - Classes loaded', {
      load_time_ms: loadClassesTime,
    });
  },
  methods: {
    ...mapActions({
      markAsSeen: 'notificationsModule/markNotificationsAsSeen',
      fetchActivityAggregated: 'dashboardActivityModule/fetchAggregatedActivity',
      fetchActivity: 'dashboardActivityModule/fetchActivity',
      fetchSubjectClassList: 'subjectClassModule/fetchSubjectClassList',
      fetchSubjectList: 'subjectModule/fetchSubjectList',
    }),
    openAssignmentNotNeededModal() {
      this.$modal(AssignmentNotNeededModal);
    },
  },
});
</script>

<style>
.KogDashboard-pageContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
  padding-top: 54px;

  background-color: var(--kogPlatformGray098);
}

.KogDashboard-insideLoader {
  width: 100%;
}

.KogDashboard-pageInnerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.KogDashboard-content {
  max-width: 1170px;
}

.KogDashboard-leftContainer,
.KogDashboard-rightContainer {
  overflow-x: hidden;
}

.KogDashboard-leftContainer {
  flex: 7;
}

.KogDashboard-rightContainer {
  flex: 3;
  max-width: 100%;
  margin-left: var(--space-xs);
}

@media (--viewport-s) {
  .KogDashboard-rightContainer,
  .KogDashboard-leftContainer {
    flex: 1;
    max-width: 100%;
  }

  .KogDashboard-rightContainer {
    margin-left: 0;
  }

  .KogDashboard-content {
    flex-direction: column-reverse;
    max-width: 100%;
  }
}
</style>
