<template>
  <battle-page-container
    :loading="isLoadingSetupData"
    loading-msg="Loading battles..."
  >
    <template #header>
      <practice-setup-info
        class="margin-bottom-m"
        :source="source"
        :assignment-type="assignmentTypes.QUESTION"
      />
    </template>
    <template #card-header>
      <div
        class="flexContainer flexContainer-spaceBetween flexContainer-alignEnd divider-bottom margin-bottom-m padd-bottom-s"
      >
        <h1 class="heading-xl">Strength battle</h1>
        <battles-statistics class="heading-s" />
      </div>
    </template>
    <p>Battle an opponent, or view your current and past battles.</p>
    <div class="kog-row">
      <div class="kog-col-md-6">
        <h2 class="margin-top-m margin-bottom-m heading-m">New battle</h2>

        <h3 class="margin-bottom-s heading-s">1. Pick an opponent</h3>
        <pick-opponent
          v-model:value="opponent"
          class="margin-bottom-m"
        />

        <h3 class="margin-bottom-s heading-s">2. Choose {{ $term('topic') }}</h3>
        <kog-loader
          :loading="$wait.is('loading_nodes_with_strength_battle_questions')"
          :loading-msg="`Loading available ${$term('topic')}...`"
        >
          <choose-topic
            :available-topics="topicsWithStrengthBattleQuestions"
            :selected-node="selectedNode"
            @topicSelected="topicSelected"
            @topicCleared="topicCleared"
          />
        </kog-loader>

        <h3 class="padd-top-m padd-bottom-m heading-s">3. Start Battle!</h3>
        <kog-alert
          v-if="errors && errors.length > 0"
          title="Failed to set up the strength battle"
          mode="error"
          class="margin-bottom-m"
        >
          <ul class="list-style-none margin-top-s margin-bottom-0 padd-left-0">
            <li
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </li>
          </ul>
        </kog-alert>
        <div class="padd-bottom-m">
          <kog-button
            label="Start battle"
            button-style="accent"
            :disabled="!validBattleData || isLoadingNewBattle"
            @click="setupBattle"
          />
          <kog-loader :loading="isLoadingNewBattle" />
        </div>
      </div>

      <div class="kog-col-md-6">
        <h2 class="margin-top-m margin-bottom-m heading-m">Your battles</h2>
        <p>Battles won: {{ battlesWon }}</p>
        <current-battles />
        <past-battles class="margin-top-l" />
      </div>
    </div>
  </battle-page-container>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapMutations, mapState, useStore } from 'vuex';

import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import { getSubjectTreeTopicLevelNodeIds } from 'sharedApp/libs/subject-tree-functions.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import { BATTLE_TYPES } from 'studyApp/api/battle.js';
import PracticeSetupInfo, {
  VALID_SOURCES,
} from 'studyApp/components/practice/practice-setup-info.vue';

import BattlePageContainer from './components/battle-page-container.vue';
import BattlesStatistics from './components/battles-statistics.vue';
import ChooseTopic from './components/choose-topic.vue';
import CurrentBattles from './components/current-battles.vue';
import PastBattles from './components/past-battles.vue';
import PickOpponent from './components/pick-opponent.vue';
import BattleMixin from './mixins/battle-mixin.js';

const LOADING_SETUP_DATA = 'loadingSetupData';
const LOADING_NEW_BATTLE = 'loadingNewBattle';
const ONE_WEEK_IN_MILLISECONDS = 604800000;

export default {
  name: 'BattleSetup',
  components: {
    KogAlert,
    KogButton,
    KogLoader,
    ChooseTopic,
    PickOpponent,
    CurrentBattles,
    PastBattles,
    BattlesStatistics,
    BattlePageContainer,
    PracticeSetupInfo,
  },
  mixins: [RoutesMixin, BattleMixin],
  setup() {
    const store = useStore();
    const { subjectModule } = store.state;

    const subjectName = computed(() => subjectModule.subject.name);

    useHead({
      title: `Setup strength battle | ${subjectName.value}`,
    });
  },
  data() {
    return {
      opponent: {
        type: BATTLE_TYPES.random,
        selectedClassmate: '',
      },
      errors: [],
      assignmentTypes: ASSIGNMENT_TYPES,
      source: VALID_SOURCES.STRENGTH_BATTLE_SETUP,
    };
  },
  computed: {
    ...mapState({
      subjectNodesWithStrengthBattleQuestions: state =>
        state.statisticModule.subjectNodesWithStrengthBattleQuestions,
    }),
    ...mapState('subjectModule', ['subject']),
    ...mapState('battlesModule', ['selectedNode', 'classmates']),
    ...mapGetters({
      battlesWon: 'battlesModule/battlesWon',
    }),
    validBattleData() {
      const validOpponent = this.kogbotOpponent || this.opponent.selectedClassmate !== '';
      return this.selectedNode && validOpponent;
    },
    isLoadingSetupData() {
      return this.$wait.is(LOADING_SETUP_DATA);
    },
    isLoadingNewBattle() {
      return this.$wait.is(LOADING_NEW_BATTLE);
    },
    kogbotOpponent() {
      return this.opponent.type === BATTLE_TYPES.random;
    },
    rootNode() {
      return this.subject.subject_tree[0];
    },
    topicsWithStrengthBattleQuestions() {
      return this.subjectNodesWithStrengthBattleQuestions;
    },
  },
  async created() {
    const allSubjectNodes = [...getSubjectTreeTopicLevelNodeIds(this.rootNode)];
    this.fetchSubjectNodesWithStrengthBattleQuestions({
      subjectId: this.subject.id,
      subjectNodeIds: allSubjectNodes,
    });
    await this.loadSetupData();
  },
  methods: {
    ...mapWaitingActions('statisticModule', {
      fetchSubjectNodesWithStrengthBattleQuestions: 'loading_nodes_with_strength_battle_questions',
    }),
    ...mapActions('battlesModule', ['fetchSetupInfo', 'createNewBattle', 'fetchBattle']),
    ...mapMutations('battlesModule', ['setSelectedNode', 'clearSelectedNode']),
    async loadSetupData() {
      this.$wait.start(LOADING_SETUP_DATA);
      try {
        await this.fetchSetupInfo({ subjectId: this.subject.id, subjectClassId: this.classId });
        this.$wait.end(LOADING_SETUP_DATA);
      } catch {
        this.$toast.showError(
          'There was an error while loading this page, please try again',
          {},
          {
            time: ONE_WEEK_IN_MILLISECONDS,
          },
        );
      }
    },
    topicSelected(topic) {
      this.setSelectedNode(topic);
    },
    topicCleared() {
      this.clearSelectedNode();
    },
    setupBattle(event) {
      const opponent = {
        type: this.opponent.type,
      };
      if (this.opponent.type === BATTLE_TYPES.classmate) {
        opponent.classmateId = this.classmates[this.opponent.selectedClassmate].user_id;
      }

      const handleOnStart = async () => {
        this.$wait.start(LOADING_NEW_BATTLE);
        try {
          const newBattle = await this.createNewBattle({
            subjectId: this.subject.id,
            subjectNodeId: this.selectedNode.id,
            opponent,
          });

          if (newBattle.is_your_turn) {
            this.navigateToBattleTake({ battleId: newBattle.id });
          } else {
            await this.fetchSetupInfo({ subjectId: this.subject.id, subjectClassId: this.classId });
            this.$toast.showSuccess(
              'Battle invitation sent! If your opponent accepts it, they will begin the battle.',
              {},
              {
                time: 30000,
              },
            );
          }
        } catch (err) {
          if (err.response && err.response.data) {
            this.errors = err.response.data.non_field_errors;
          } else {
            this.$toast.showError(
              'There was an error creating the battle, please try again',
              {},
              {
                time: ONE_WEEK_IN_MILLISECONDS,
              },
            );
            throw err;
          }
        }
        this.$wait.end(LOADING_NEW_BATTLE);
      };

      const handleOnClose = () => {
        if (event.target) {
          event.target.focus();
        }
      };

      this.openBattleModal({
        title: this.selectedNode.name,
        onStart: handleOnStart,
        onClose: handleOnClose,
      });
    },
  },
};
</script>
