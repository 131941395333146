<template>
  <kog-modal
    :maximize="true"
    :close-modal="closeModal"
  >
    <!--Question info-->
    <template #subHeader>
      <div class="StudentAnswersModal-name padd-left-l">
        {{ occasion.name }} - {{ percentageCorrect }}%
      </div>
      <div>
        <p class="muted margin-top-xs padd-left-l">
          {{ completedInfo }}
        </p>
      </div>
      <div
        v-if="areFiltersShown"
        class="flexContainer flexContainer-wrap padd-left-l"
      >
        <label
          v-for="filter in responsesFilters"
          :key="filter.value"
          :for="filter.value"
          class="StudentAnswersModal-radio"
        >
          <input
            :id="filter.value"
            v-model="currentFilter"
            type="radio"
            :value="filter.value"
          />
          <span class="padd-left-xxs">{{ filter.name }}</span>
        </label>
      </div>
    </template>
    <!--/Question info-->

    <!--Body-->
    <template #modalBody>
      <div
        v-kog-mathjax
        class="padd-left-l padd-right-l padd-top-m padd-bottom-m StudentAnswersModal-body"
      >
        <div class="muted margin-bottom-xs"> {{ studentFirstName }}'s responses </div>
        <div
          v-for="(questionId, index) in filteredQuestionsIds"
          :key="index"
          class="flexContainer margin-bottom-xxs"
        >
          <span class="StudentAnswersModal-answerNumber padd-top-m muted">{{ index + 1 }}</span>
          <student-answers-card
            :question="getQuestion(questionId)"
            :occasion="occasion"
            :track-assignment-details-students-event="trackAssignmentDetailsStudentsEvent"
            class="flexChild-canGrow"
          />
        </div>
      </div>
    </template>
    <!--/Body-->
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import StudentAnswersCard from 'studyApp/components/teacher/assignments/details/student-answers-card.vue';

const responsesFiltersValues = {
  all: 'all',
  incorrect: 'incorrect',
  correct: 'correct',
};

export default {
  name: 'StudentAnswersModal',
  components: {
    KogModal,
    StudentAnswersCard,
  },
  props: {
    closeModal: {
      type: Function,
      default: null,
    },
    trackAssignmentDetailsStudentsEvent: {
      type: Function,
      required: true,
    },
    occasion: {
      type: Object,
      required: true,
    },
    assignment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentFilter: responsesFiltersValues.all,
      responsesFilters: [
        {
          value: responsesFiltersValues.all,
          name: 'All responses',
        },
        {
          value: responsesFiltersValues.incorrect,
          name: 'Incorrect only',
        },
        {
          value: responsesFiltersValues.correct,
          name: 'Correct only',
        },
      ],
    };
  },
  computed: {
    percentageCorrect() {
      const totalCorrectAnswer = this.occasion.correct_answer_count;
      const totalQuestions = this.occasion.number_of_questions;
      return Math.round((100 * totalCorrectAnswer) / totalQuestions);
    },
    completedInfo() {
      return `
        Completed in ${this.occasion.time_to_complete}
        over ${this.occasion.started_count}
        occasion${this.occasion.started_count.length > 1 ? 's' : ''}
      `;
    },
    studentFirstName() {
      return this.occasion.name.split(' ')[0];
    },
    questionsCorrectAnswer() {
      return this.occasion.answered_questions.questions_correct_answer;
    },
    questionsIncorrectAnswer() {
      return this.occasion.answered_questions.questions_incorrect_answer;
    },
    filteredQuestionsIds() {
      const questionsCorrectAnswerIds = this.questionsCorrectAnswer.map(
        answer => answer.question_id,
      );
      const questionsIncorrectAnswerIds = this.questionsIncorrectAnswer.map(
        answer => answer.question_id,
      );
      let filteredQuestionsIds;
      switch (this.currentFilter) {
        case responsesFiltersValues.correct:
          filteredQuestionsIds = this.assignment.questions
            .filter(question => questionsCorrectAnswerIds.includes(question.id))
            .map(question => question.id);
          break;
        case responsesFiltersValues.incorrect:
          filteredQuestionsIds = this.assignment.questions
            .filter(question => questionsIncorrectAnswerIds.includes(question.id))
            .map(question => question.id);
          break;
        default:
          filteredQuestionsIds = this.assignment.questions.map(question => question.id);
          break;
      }
      return filteredQuestionsIds;
    },
    areFiltersShown() {
      return this.questionsCorrectAnswer.length > 0 && this.questionsIncorrectAnswer.length > 0;
    },
  },
  methods: {
    getQuestion(questionId) {
      return this.assignment.questions.find(question => question.id === questionId);
    },
  },
};
</script>

<style scoped>
.StudentAnswersModal-body {
  background: var(--kogPlatformGray096);
}

.StudentAnswersModal-name {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}

.StudentAnswersModal-answerNumber {
  flex: 0 0 32px;
}

.StudentAnswersModal-radio {
  flex: 0 0 120px;
}
</style>
