import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { fetchIntegrationAssignment } from '@apis/assignments.js';

import { getAuthenticationProviders } from 'loginApp/api/logins.js';
import {
  AssignmentNotFoundError,
  AssignmentNotValidError,
  NoAccessTokenError,
} from 'sharedApp/const/integrations.ts';

const isLoading = ref(false);
const url = ref('');
const points = ref(0);
const category = ref('');
const warningMessage = ref('');
const providerSsoLoginUrl = ref('');

export default function useIntegrationAssignment() {
  const resetStates = () => {
    url.value = '';
    points.value = 0;
    category.value = '';
  };

  const resetIntegrationWarning = () => {
    warningMessage.value = '';
    providerSsoLoginUrl.value = '';
  };

  const integrationProvider = ref('');

  const store = useStore();
  const route = useRoute();
  const { user } = store.state.userModule;
  const { subjectClasses } = store.state.subjectClassModule;
  const classId = Number(route.params.cid);
  const subjectClass = subjectClasses.find(sc => sc.id === classId);
  integrationProvider.value = subjectClass?.integration_providers?.[0] || '';

  const showIntegrationWarning = async (error, afterAuthUrl) => {
    let message = `Assignment failed to sync with ${integrationProvider.value}.`;
    if (error instanceof NoAccessTokenError) {
      const authenticationProviders = await getAuthenticationProviders(user.email);
      const instantRedirectUrl = authenticationProviders.instant_redirect_url;
      const redirectUrl = new URL(instantRedirectUrl, window.location.href);
      redirectUrl.searchParams.set('next', afterAuthUrl || window.location.pathname);
      providerSsoLoginUrl.value = redirectUrl.href;
      message = `Sync with ${integrationProvider.value} failed. Please reauthenticate and try again.`;
    } else if (error instanceof AssignmentNotFoundError) {
      message = `Assignment not found in ${integrationProvider.value}.`;
    } else if (error instanceof AssignmentNotValidError && user.isTeacher()) {
      message = `${error.message}. Your students submissions might not be recorded in ${integrationProvider.value}.`;
    }
    warningMessage.value = message;
  };

  const loadIntegrationAssignment = async (assignment, subjectClassId) => {
    if (assignment && assignment.is_sent_to_integration) {
      try {
        resetStates();
        isLoading.value = true;
        const integrationAssignment = await fetchIntegrationAssignment(
          assignment.id,
          subjectClassId,
        );
        url.value = integrationAssignment.url;
        points.value = integrationAssignment.points;
        category.value = integrationAssignment.category;
      } catch (e) {
        await showIntegrationWarning(e);
      } finally {
        isLoading.value = false;
      }
    } else if (assignment && assignment.send_to_integration && assignment.sent_at) {
      await showIntegrationWarning(new AssignmentNotFoundError());
    }
  };

  return {
    url,
    points,
    category,
    isLoading,
    warningMessage,
    providerSsoLoginUrl,
    integrationProvider,
    loadIntegrationAssignment,
    showIntegrationWarning,
    resetIntegrationWarning,
  };
}
