<template>
  <skeletor
    :width="width"
    :height="height"
    :shimmer="shimmer"
    :class="{
      'vue-skeletor--transparent': isTransparent,
    }"
  />
</template>

<script>
import { Skeletor } from 'vue-skeletor';

export default {
  name: 'KogSkeleton',
  components: {
    Skeletor,
  },
  props: {
    shimmer: {
      type: Boolean,
      default: true,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>

<style scoped>
.vue-skeletor {
  position: relative;

  overflow: hidden;

  background-color: var(--kog-skeleton-background);
  background-repeat: no-repeat;
  background-size: 200px 100%;
  border-radius: 3px;
}
.vue-skeletor:not(.vue-skeletor--shimmerless) {
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  animation: 1.5s ease-in-out 0s infinite normal none running shimmer;
}
.vue-skeletor.vue-skeletor--transparent {
  background-color: var(--kogTransparent);
}
.vue-skeletor.vue-skeletor--transparent:not(.vue-skeletor--shimmerless) {
  background-image: linear-gradient(90deg, transparent, rgb(245, 245, 245), transparent);
}
.vue-skeletor--rect,
.vue-skeletor--circle {
  display: block;
}
.vue-skeletor--circle {
  border-radius: 50%;
}
.vue-skeletor--pill {
  border-radius: 9999px;
}
.vue-skeletor--text {
  top: -1px;

  display: inline-block;

  width: 100%;
  height: inherit;

  line-height: 1;
  vertical-align: middle;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}
</style>
