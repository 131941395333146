<template>
  <kog-modal
    :close-modal="closeModal"
    title="Are you sure?"
    class="DisconnectGoogleClassroomModal"
  >
    <template #modalBody>
      <div class="margin-bottom-xxxl text-regular">
        <span>
          Any scheduled assignments, and any future assignments sent to
          <span class="text-bold">{{ kognityClassName }}</span>
          , will no longer appear in
        </span>
        <div class="DisconnectGoogleClassroomModal-googleClassPill">
          <google-class-pill
            :google-class="googleClass"
            size="small"
          />
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          class="KogButtonLegacy KogButtonLegacy--primary"
          @click="disconnect()"
        >
          Disconnect class
        </button>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import GoogleClassPill from 'studyApp/components/teacher/google-class-pill.vue';

export default {
  name: 'DisconnectGoogleClassroomModal',
  components: {
    GoogleClassPill,
    KogModal,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    disconnectGoogleClass: {
      type: Function,
      required: true,
    },
    kognityClassName: {
      type: String,
      required: true,
    },
    googleClass: {
      type: Object,
      required: true,
    },
  },
  methods: {
    disconnect() {
      this.disconnectGoogleClass();
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.DisconnectGoogleClassroomModal-text {
  vertical-align: top;
}

.DisconnectGoogleClassroomModal-googleClassPill {
  display: inline-block;
}
</style>
