<template>
  <block-wrapper
    :content="content"
    :block-label="blockLabel"
    @mark-removal="$event => $emit('mark-removal', $event)"
    @unmark-removal="$event => $emit('unmark-removal', $event)"
    @move-down="$event => $emit('move-down', $event)"
    @move-up="$event => $emit('move-up', $event)"
  >
    <kog-input
      :value="content.title"
      label="Title (optional)"
      @input="value => updateContentField('title', value)"
    />
    <kog-editor-ck5
      :content="content.content_html"
      :content-box-templates="contentBoxTemplates"
      :config="cke5Options"
      aria-label="editor for custom editor block"
      @update:content="value => updateContentField('content_html', value)"
    />
  </block-wrapper>
</template>

<script>
import KogEditorCk5 from 'publishingApp/containers/section/components/kog-editor-ck5.vue';
import { getBlockTypeLabel } from 'publishingApp/store/modules/feature/activity-constants.ts';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';

import BlockWrapper from '../../block-wrapper.vue';
import BlockMixin from '../mixins/block-mixin.js';

export default {
  name: 'CustomEditorBlock',
  components: {
    BlockWrapper,
    KogEditorCk5,
    KogInput,
  },
  mixins: [BlockMixin],
  props: {
    contentBoxTemplates: {
      type: Object,
      default: () => {},
    },
    content: {
      type: Object,
      required: true,
    },
  },
  emits: ['mark-removal', 'unmark-removal', 'move-up', 'move-down'],
  data() {
    return {
      cke5Options: {
        exclude: ['showSolution', 'inlineQuestion', 'exerciseBox', 'glossaryLink'],
      },
      // eslint-disable-next-line vue/no-unused-properties
      editorFields: ['content_html'],
    };
  },
  computed: {
    blockLabel() {
      return getBlockTypeLabel(this.content.block_type);
    },
  },
};
</script>
