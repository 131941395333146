import { Command } from '@ckeditor/ckeditor5-core';

import { findNonParagraphAncestor } from '../utils/commands.js';

export default class InsertContentGridCommand extends Command {
  execute(configuration) {
    const { model } = this.editor;
    model.change(writer => {
      const selection = findNonParagraphAncestor(writer, model.document.selection);
      model.insertObject(this.create(writer, configuration), selection);
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(
      document.selection.getFirstPosition(),
      'contentGrid',
    );
    this.isEnabled = allowedIn !== null;

    const firstRange = document.selection.getFirstRange();
    if (!firstRange.isCollapsed && firstRange.start.nodeAfter?.name === 'contentGrid') {
      const node = firstRange.start.nodeAfter;
      const childNode = node.getChild(0);
      const columns = parseInt(childNode.getAttribute('columns'), 10);

      this.value = { columns };
    } else {
      this.value = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer, { columns }) {
    const contentGrid = writer.createElement('contentGrid');
    const contentGridContent = writer.createElement('contentGridContent', { columns });
    const paragraph = writer.createElement('paragraph');

    writer.append(contentGridContent, contentGrid);
    writer.append(paragraph, contentGridContent);

    return contentGrid;
  }
}
