import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';

import Component from './block.vue';

const template = {
  block_type: BLOCK_TYPE.HEADING,
  text: '',
  level: 2,
};

const validator = {
  errors: [],
  validate(blockData) {
    this.errors = [];

    if (!blockData.text) {
      this.errors.push('Heading has no text');
    }
    return this.errors.length === 0;
  },
};

const preSaveHandler = block => {
  return {
    payload: block,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
};

export default {
  component: Component,
  template,
  validator,
  preSaveHandler,
};
