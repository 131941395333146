<template>
  <kog-tag
    is-chip
    :label="tagDisplayLabel"
    :tab-index="-1"
    size="m"
    type="informative"
    :style="`background-color: ${tagColorValue}`"
  >
    {{ name }}
  </kog-tag>
</template>

<script>
import { keyBy } from 'lodash';
import { mapState } from 'vuex';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import getAlignmentsDisplayName from 'sharedApp/services/alignments/alignments.js';
import { GRAY, SYLLABUS_COLORS } from 'sharedApp/services/colors/syllabus.js';
import { isIBDP } from 'sharedApp/services/educationSystem/education-system-service.js';

export default {
  name: 'SyllabusAlignmentTag',
  components: {
    KogTag,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    shortName: {
      type: String,
      default: '',
    },
    tagColor: {
      type: String,
      default: GRAY,
    },
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
    }),
    educationSystemName() {
      return this.subject?.educationsystem?.name;
    },
    tagDisplayLabel() {
      if (isIBDP(this.educationSystemName)) {
        return getAlignmentsDisplayName(this.name, this.shortName);
      }
      return this.name || this.shortName;
    },
    tagColorValue() {
      const colors = keyBy(SYLLABUS_COLORS, color => color.name);
      return colors[this.tagColor || GRAY].cssValue;
    },
  },
};
</script>
