<template>
  <div class="padd-top-xl padd-bottom-xl">
    <div v-if="!isLoadingPtSubmissions">
      <div class="flexContainer flexContainer-alignStart">
        <div class="flexContainer flexContainer-alignEnd">
          <kog-select
            :selected-value="selectedStudentId"
            class="padd-left-m padd-right-m"
            label="View student submission"
            :options="studentsOptions"
            icon="fa-user"
            @change="onStudentSelected"
          />
          <kog-tag
            class="margin-bottom-xs"
            is-chip
            :type="submittedStatusStyle"
            :label="submittedStatusLabel"
          />
        </div>
        <div
          v-if="!isSubmitted"
          class="PerformanceTaskSubmissionContainer-submitForStudentContainer padd-left-m padd-right-m"
        >
          <div>
            <i class="far fa-info-circle"></i>
            <span class="heading-xxs"> Submit on behalf of student? </span>
          </div>
          <div>
            Submit the student's performance task in its current state. The student can’t edit,
            update or answer not yet responded questions.
            <br />
            <br />
            You can’t revoke this action.
          </div>
          <div class="margin-top-m">
            <kog-checkbox
              v-model:is-checked="isSubmitForStudentChecked"
              label="Yes, I want to submit on behalf of student"
            />
            <kog-button
              class="margin-left-m"
              size="small"
              :disabled="!isSubmitForStudentChecked"
              button-style="accent"
              label="Submit"
              @click="onSubmitForStudentClick(selectedStudentId)"
            />
          </div>
        </div>
      </div>
      <grading-overview
        v-if="performanceTask"
        class="padd-left-m margin-top-s"
        :class-id="classId"
        :subject-id="subjectId"
        :node-id="nodeId"
      />
      <kog-wave-separator class="margin-top-l width-full" />
    </div>
    <performance-task-container
      class="margin-top-s"
      :is-in-review-mode="true"
      :is-in-view-submission-mode="true"
      :feature="feature"
      :selected-student-id="selectedStudentId"
      :is-anonymized="isAnonymized"
    />
  </div>
</template>
<script>
import { useSubscription } from '@vueuse/rxjs';
import { first, map, Subject } from 'rxjs';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';
import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import KogWaveSeparator from 'sharedApp/components/base/separators/kog-wave-separator.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import GradingOverview from 'studyApp/containers/components/grading-overview.vue';
import PerformanceTaskContainer from 'studyApp/containers/performance-task/performance-task-container.vue';
import { PERFORMANCE_TASK_MODULE } from 'studyApp/store/modules/performance-task.js';

export default {
  name: 'PerformanceTaskSubmissionsContainer',
  components: {
    KogButton,
    KogCheckbox,
    KogSelect,
    KogTag,
    KogWaveSeparator,
    PerformanceTaskContainer,
    GradingOverview,
  },
  mixins: [RoutesMixin],
  props: {
    feature: {
      type: Object,
      required: true,
    },
    selectedStudentId: {
      type: Number,
      default: null,
    },
    studentsList: {
      type: Array,
      required: true,
    },
    isAnonymized: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['student-selection-change'],
  data() {
    return {
      isSubmitForStudentChecked: false,
      performanceTask$: new Subject(),
    };
  },
  computed: {
    ...mapState({
      ptOccasions: state => state[PERFORMANCE_TASK_MODULE].classOccasions,
      performanceTask: state => state[PERFORMANCE_TASK_MODULE].performanceTask,
    }),
    ...mapGetters(PERFORMANCE_TASK_MODULE, {
      ptCurrentOccasion: 'currentOccasion',
    }),
    isLoadingPtSubmissions() {
      return (
        this.$wait.is('loading_performance_task') ||
        this.$wait.is('loading_performance_task_occasions') ||
        this.$wait.is('loading_available_questions')
      );
    },
    studentsOptions() {
      return this.studentsList.map(student => ({
        value: student.user_id,
        text: student.name,
      }));
    },
    submittedAtStatus() {
      return this.ptCurrentOccasion?.submitted_at || null;
    },
    submittedStatusLabel() {
      const submittedAt = this.submittedAtStatus;
      if (submittedAt) {
        const formatted = dateTimeFormatter(new Date(submittedAt));
        return `Submitted ${formatted}`;
      }
      return 'Not submitted';
    },
    isSubmitted() {
      return !!this.submittedAtStatus;
    },
    submittedStatusStyle() {
      return this.submittedAtStatus ? 'positive' : 'informative';
    },
  },
  watch: {
    selectedStudentId: {
      immediate: true,
      handler() {
        this.isSubmitForStudentChecked = false;
        this.setSelectedStudentId(this.selectedStudentId);
      },
    },
    performanceTask: {
      immediate: true,
      handler(performanceTask) {
        this.performanceTask$.next(performanceTask);
      },
    },
  },
  created() {
    useSubscription(
      this.performanceTask$
        .pipe(
          first(({ newValue: performanceTask }) => !!performanceTask),
          map(({ newValue: performanceTask }) => performanceTask.id),
        )
        .subscribe(performanceTaskId => {
          this.$mixpanel.trackEvent('Performance task - Open submissions page', {
            performance_task_id: performanceTaskId,
          });
        }),
    );
  },
  methods: {
    ...mapActions(PERFORMANCE_TASK_MODULE, [
      'createOccasion',
      'submitPerformanceTaskOccasionForStudent',
    ]),
    ...mapWaitingActions(PERFORMANCE_TASK_MODULE, {
      fetchClassOccasions: 'loading_performance_task_occasions',
    }),
    ...mapMutations(PERFORMANCE_TASK_MODULE, ['setSelectedStudentId']),
    onStudentSelected(studentId) {
      this.$emit('student-selection-change', studentId);
      this.$mixpanel.trackEvent('Performance task - Select student in submissions dropdown', {
        performance_task_id: this.performanceTask.id,
        student_user_id: studentId,
      });
    },
    async onSubmitForStudentClick(studentUserId) {
      try {
        const performanceTaskId = this.performanceTask.id;

        let performanceTaskOccasionId = this.ptCurrentOccasion?.id;
        if (!performanceTaskOccasionId) {
          performanceTaskOccasionId = await this.createOccasion({
            performanceTaskId,
            subjectClassId: this.classId,
            userId: studentUserId,
          });
        }
        await this.submitPerformanceTaskOccasionForStudent({ performanceTaskOccasionId });
      } catch {
        this.$toast.showError(
          'Could not submit for student. Please refresh the page and try again.',
        );
        return;
      }
      this.$mixpanel.trackEvent('Performance task - Submit on behalf of student', {
        performance_task_id: this.performanceTask.id,
        student_user_id: studentUserId,
      });
      this.$toast.showSuccess('Submitted for student.');
    },
  },
};
</script>

<style scoped>
.PerformanceTaskSubmissionContainer-submitForStudentContainer {
  gap: 8px;

  width: 490px;
  height: 208px;
  margin-right: var(--space-l);
  margin-left: auto;
  padding: 16px;

  background-color: var(--kog-colors-pink-100);
  border-radius: 8px;
}
</style>
