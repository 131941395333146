<template>
  <div class="margin-top-l">
    <p>Choose answer style</p>
    <div class="QuestionModal__tab flexContainer flexContainer-spaceAround text-center">
      <button
        v-tooltip="{ theme: 'kog-tooltip', content: getTooltipContent('STQ') }"
        class="QuestionModal__tab-item flexChild-size-1 KogButtonLegacy--noStyle"
        :class="{
          'QuestionModal__tab-item--selected': selectedTab === 'STQ',
          'QuestionModal__tab-item--disabled': isTabDisabled('STQ'),
        }"
        @click="onClickTab('STQ')"
      >
        <kog-icon
          icon-class="STQ"
          theme="custom"
          class="margin-right-xxs"
          :is-svg-icon="true"
        />
        Short text answer
      </button>
      <button
        v-tooltip="{ theme: 'kog-tooltip', content: getTooltipContent('MCQ') }"
        class="QuestionModal__tab-item flexChild-size-1 KogButtonLegacy--noStyle"
        :class="{
          'QuestionModal__tab-item--selected': selectedTab === 'MCQ',
          'QuestionModal__tab-item--disabled': isTabDisabled('MCQ'),
        }"
        @click="onClickTab('MCQ')"
      >
        <kog-icon
          icon-class="MCQ"
          theme="custom"
          class="margin-right-xxs"
          :is-svg-icon="true"
        />
        Multiple choice
      </button>
    </div>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'QuestionTypeTabs',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    KogIcon,
  },
  props: {
    selectedTab: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select-tab'],
  methods: {
    onClickTab(tab) {
      if (this.disabled) {
        return;
      }
      this.$emit('select-tab', tab);
    },
    isTabDisabled(tab) {
      return this.disabled && this.selectedTab !== tab;
    },
    getTooltipContent(tab) {
      if (this.isTabDisabled(tab)) {
        return 'Answer style cannot be edited';
      }
      return '';
    },
  },
};
</script>

<style scoped>
.QuestionModal__tab {
  margin: var(--space-m) 0;
  padding: 0;
}

.QuestionModal__tab-item {
  cursor: pointer;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  padding-bottom: var(--space-xs);

  font-size: var(--kog-font-size-default);

  border-bottom: 1px solid var(--kogPlatformGray084);

  fill: currentColor; /* Cascade svg icon fill */
}

.QuestionModal__tab-item--selected {
  font-weight: bold;
  border-bottom: 3px solid var(--kog-border-brand-900);
}

.QuestionModal__tab-item--disabled {
  cursor: default;
  color: var(--kogPlatformGray044);
  fill: currentColor; /* Cascade svg icon fill */
}
</style>
