<template>
  <kog-rounded-dropdown
    ref="dropdown"
    class="ClassPickerDropdown"
  >
    <template #header>
      <class-picker-item
        v-if="isHomeSelected"
        name="Dashboard"
        :is-header="true"
        :is-transparent="isTransparent"
      />
      <class-picker-item
        v-else-if="isSubjectsAndClassesLoaded"
        :name="currentSubjectClass.name"
        :logo-url="getSubjectLogoUrl(currentSubjectClass)"
        :is-transparent="isTransparent"
        :is-header="true"
        :subject-class="currentSubjectClass"
      />
    </template>
    <template #dropdown>
      <h2 class="ClassPickerDropdown-heading margin-0"> Dashboard </h2>
      <ul class="ClassPickerDropdown-classMenu">
        <class-picker-item
          tag="li"
          name="Dashboard"
          :is-selected="isHomeSelected"
        />
      </ul>

      <template v-if="isSubjectsAndClassesLoaded">
        <h2 class="ClassPickerDropdown-heading margin-0"> My classes </h2>
        <ul class="ClassPickerDropdown-classMenu">
          <class-picker-item
            v-for="subjectClass in subjectClasses"
            :key="`${subjectClass.id}-${subjectClass.subject_class_id}`"
            tag="li"
            :name="subjectClass.name"
            :logo-url="getSubjectLogoUrl(subjectClass)"
            :is-selected="isSubjectClassActive(subjectClass.id)"
            :subject-class="subjectClass"
          />
        </ul>
      </template>
    </template>
  </kog-rounded-dropdown>
</template>

<script>
import ClassPickerItem from 'learning/common/components/class-picker-item.vue';

import KogRoundedDropdown from 'sharedApp/components/dropdown/kog-rounded-dropdown.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

export default {
  name: 'ClassPickerDropdown',
  components: {
    ClassPickerItem,
    KogRoundedDropdown,
  },
  mixins: [RoutesMixin],
  props: {
    subjectClasses: {
      type: Array,
      required: true,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
    subjectsById: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isHomeSelected() {
      return !this.currentSubjectClass;
    },
    isSubjectsAndClassesLoaded() {
      return this.subjectClasses.length > 0 && Object.keys(this.subjectsById).length > 0;
    },
    currentSubjectClass() {
      if (this.subjectClasses.length === 0) return null;

      if (this.classId || this.classId === 0) {
        if (this.user.isTeacher() || this.user.isStudent()) {
          return this.subjectClasses.find(sc => sc.id === this.classId);
        }
        return this.subjectClasses.find(sc => sc.subject_class_id === this.classId);
      }

      return null;
    },
  },
  methods: {
    isSubjectClassActive(subjectClassId) {
      if (!this.currentSubjectClass) {
        return false;
      }
      return this.currentSubjectClass.id === subjectClassId;
    },
    getSubjectLogoUrl(subjectClass) {
      return this.subjectsById[subjectClass.subject_id].logo_url;
    },
  },
};
</script>

<style>
.ClassPickerDropdown .KogRoundedDropdown-menuList {
  overscroll-behavior: contain;
  min-width: 400px;
  border-radius: 0;
}

.ClassPickerDropdown-heading {
  padding: var(--space-s) var(--space-m);
  font-size: var(--kog-font-size-default);
  font-weight: bold;
  color: var(--kog-text-default);
}

.ClassPickerDropdown-classMenu {
  margin: 0 var(--space-s);
  padding: 0;
  list-style: none;
}
</style>
