<template>
  <div
    v-if="shouldDisplayFeedback"
    :class="getClasses()"
  >
    {{ getMessage() }}
  </div>
</template>

<script>
export default {
  name: 'ExerciseQuestionFeedback',
  props: {
    numberOfQuestions: {
      type: Number,
      default: 0,
    },
    submissionResults: {
      type: Array,
      default: () => [],
    },
    displayFeedback: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldDisplayFeedback() {
      return this.displayFeedback && this.submissionResults.length > 0;
    },
    numberOfCorrectAnswers() {
      return this.submissionResults.filter(pi => pi.user_answer.is_correct).length;
    },
    numberOfWrongAnswers() {
      return this.submissionResults.filter(pi => !pi.user_answer.is_correct).length;
    },
  },
  methods: {
    getClasses() {
      const classes = ['ExerciseQuestionFeedback'];

      if (this.numberOfCorrectAnswers > 0 && this.numberOfWrongAnswers === 0) {
        classes.push('ExerciseQuestionFeedback-success');
      }

      return classes;
    },
    getMessage() {
      if (this.numberOfCorrectAnswers === this.numberOfQuestions) {
        return 'Great job! You completed the exercise.';
      }

      if (this.numberOfCorrectAnswers > 0 && this.numberOfWrongAnswers === 0) {
        return 'Well done! Continue with the questions that are left.';
      }

      return 'Almost there! Answer all the questions correctly to complete the exercise.';
    },
  },
};
</script>

<style scoped>
.ExerciseQuestionFeedback {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--space-s);

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-content);
  font-weight: 400;
  color: var(--kog-text-default);

  background-color: var(--kog-colors-yellow-100);
  border-radius: var(--space-xxs);
}

.ExerciseQuestionFeedback-success {
  background-color: var(--kog-colors-green-100);
}
</style>
