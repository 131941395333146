export function getEsqStatsByTopic(topic, mappings) {
  const mappingsForNode = mappings.find(mapping => mapping.node_id === topic.id);
  const questions = mappingsForNode ? mappingsForNode.questions : [];
  const questionCount = questions.length;
  const availableForPracticeCount = questions.filter(
    question => question.question_info.is_locked === false,
  ).length;

  return {
    questionCount,
    availableForPracticeCount,
  };
}
