/* eslint-disable class-methods-use-this */
import unlinkIcon from '@ckeditor/ckeditor5-link/theme/icons/unlink.svg';
import {
  addListToDropdown,
  createLabeledDropdown,
  LabeledFieldView,
  Model,
  ViewCollection,
} from '@ckeditor/ckeditor5-ui';
import { Collection } from '@ckeditor/ckeditor5-utils';

import './section-link.css';

import FilteredDropdownView from '../shared/filtered-dropdown-view.ts';
import FlexWrapperView from '../shared/flex-wrapper-view.ts';
import KogForm from '../utils/kogform.js';

const LINK_TYPE = {
  SECTION: 'sectionlink',
  ACTIVITY: 'activitylink',
  URL: 'url',
  EMAIL: 'mailto',
  TEL: 'tel',
};

const DROPDOWN_OPTIONS = [
  ['Section', 'sectionlink'],
  ['Activity', 'activitylink'],
  ['URL', 'url'],
  ['E-mail', 'mailto'],
  ['Phone', 'tel'],
];

const TARGET_DROPDOWN_OPTIONS = [
  ['New Window (_blank)', '_blank'],
  ['Topmost Window (_top)', '_top'],
  ['Same Window (_self)', '_self'],
  ['Parent Window (_parent)', '_parent'],
  ['not set', 'not_set'],
];

const DEFAULT_SECTIONS_LABEL = 'Linkable sections';
const DEFAULT_ACTIVITIES_LABEL = 'Linkable activities';
const [DEFAULT_TARGET_DROPDOWN_LABEL, DEFAULT_TARGET_DROPDOWN_KEY] = TARGET_DROPDOWN_OPTIONS[0];

const DEFAULT_STATE = Object.freeze({
  // General
  linkText: null,
  selectedLinkTypeKey: LINK_TYPE.SECTION,

  // Section
  selectedSectionId: null,

  // Activities
  selectedActivityId: null,

  // Url
  url: null,
  selectedTargetKey: DEFAULT_TARGET_DROPDOWN_KEY,

  // Email
  emailAddress: null,
  emailSubject: null,
  emailBody: null,

  // Tel
  phoneNumber: null,
});

const HIDE_CLASS = 'ck-section-link-hidden';

const VALIDATION_MAPPING = {
  [LINK_TYPE.SECTION]: ['linkText', 'selectedSectionId'],
  [LINK_TYPE.ACTIVITY]: ['linkText', 'selectedActivityId'],
  [LINK_TYPE.URL]: ['linkText', 'url'],
  [LINK_TYPE.EMAIL]: ['linkText', 'emailAddress'],
  [LINK_TYPE.TEL]: ['linkText', 'phoneNumber'],
};

const createInputValueChangeBinding = (inputView, callback) => {
  inputView.on('change:value', event => {
    callback(event.source.value);
  });
  inputView.on('input', event => {
    callback(event.source.element.value);
  });
};

export default class FormView extends KogForm {
  constructor(locale, linkableSections, linkableActivities) {
    super(locale);

    this.set(DEFAULT_STATE);

    const { linkTextInput, linkTypeDropdown } = this.createBaseFields();
    const { sectionDropdown } = this.createSectionFields(linkableSections);
    const { activitiesDropdown } = this.createActivitiesFields(linkableActivities);
    const { emailAddress, emailBody, emailSubject } = this.createEmailFields();
    const { phoneNumberInput } = this.createTelFields();
    const { urlInput, targetDropdown } = this.createUrlFields(TARGET_DROPDOWN_OPTIONS);
    const { saveButton, cancelButton, unlinkButton } = this.createDialogButtons();
    this.saveButton = saveButton;

    this.childViews = this.createCollection();

    // General
    const generalView = new FlexWrapperView(this.locale, {
      direction: 'col',
      children: [linkTextInput, linkTypeDropdown],
    });
    this.childViews.add(generalView);

    // Section
    const sectionsView = new FlexWrapperView(this.locale, {
      direction: 'col',
      children: [sectionDropdown],
    });
    sectionsView
      .bind('isVisible')
      .to(this, 'selectedLinkTypeKey', type => type === LINK_TYPE.SECTION);
    this.childViews.add(sectionsView);

    // Activities
    const activitiesView = new FlexWrapperView(this.locale, {
      direction: 'col',
      children: [activitiesDropdown],
    });
    activitiesView
      .bind('isVisible')
      .to(this, 'selectedLinkTypeKey', type => type === LINK_TYPE.ACTIVITY);
    this.childViews.add(activitiesView);

    // Tel
    const telView = new FlexWrapperView(this.locale, {
      direction: 'col',
      children: [phoneNumberInput],
    });
    telView.bind('isVisible').to(this, 'selectedLinkTypeKey', type => type === LINK_TYPE.TEL);
    this.childViews.add(telView);

    // Url
    const urlView = new FlexWrapperView(this.locale, {
      direction: 'col',
      children: [targetDropdown, urlInput],
    });
    urlView.bind('isVisible').to(this, 'selectedLinkTypeKey', type => type === LINK_TYPE.URL);
    this.childViews.add(urlView);

    // Email
    const emailView = new FlexWrapperView(this.locale, {
      direction: 'col',
      children: [emailAddress, emailSubject, emailBody],
    });
    emailView.bind('isVisible').to(this, 'selectedLinkTypeKey', type => type === LINK_TYPE.EMAIL);
    this.childViews.add(emailView);

    // CTA buttons
    this.childViews.add(
      new FlexWrapperView(this.locale, {
        direction: 'row',
        children: [saveButton, cancelButton, unlinkButton],
      }),
    );

    this.focusables = new ViewCollection([
      linkTextInput,
      linkTypeDropdown,
      sectionDropdown,
      activitiesDropdown,
      targetDropdown,
      emailAddress,
      emailSubject,
      emailBody,
      urlInput,
      phoneNumberInput,
      saveButton,
      cancelButton,
      unlinkButton,
    ]);

    super.setup('section-link', this.focusables);
  }

  get state() {
    return {
      type: this.selectedLinkTypeKey,
      text: this.linkText,
      sectionId: this.selectedSectionId,
      activityId: this.selectedActivityId,
      url: this.url,
      target: this.selectedTargetKey,
      emailAddress: this.emailAddress,
      emailSubject: this.emailSubject,
      emailBody: this.emailBody,
      phoneNumber: this.phoneNumber,
    };
  }

  createBaseFields() {
    const linkTextInput = this.createInput('Display text');
    createInputValueChangeBinding(linkTextInput.fieldView, value => {
      this.linkText = value;
    });

    const linkTypeDropdown = this.createDropdown(
      'Link type',
      DROPDOWN_OPTIONS,
      'selectedLinkTypeKey',
    );

    linkTypeDropdown.fieldView.buttonView.bind('label').to(this, 'selectedLinkTypeKey', type => {
      return DROPDOWN_OPTIONS.find(([, linkType]) => linkType === type)?.[0];
    });

    this.on(`setup`, (_event, { text, href }) => {
      linkTextInput.fieldView.value = text;

      if (href || text) {
        linkTextInput.set('class', HIDE_CLASS);
      }
    });

    this.on('reset', () => {
      linkTextInput.fieldView.reset();
      linkTextInput.set('class', '');
    });

    return { linkTextInput, linkTypeDropdown };
  }

  createSectionFields(linkableSections) {
    const sectionDropdown = new FilteredDropdownView(this.locale, {
      label: DEFAULT_SECTIONS_LABEL,
      values: linkableSections,
    });

    sectionDropdown.on('change:selectedValue', (_event, _name, selectedSectionId) => {
      this.selectedSectionId = selectedSectionId;
    });

    this.on(`setup:${LINK_TYPE.SECTION}`, (_evt, { href }) => {
      const section = this.getLinkedSectionOrActivity(href, linkableSections);
      if (section) {
        sectionDropdown.setSelectedValue(section.id);
      }
    });

    this.on('reset', () => sectionDropdown.reset());

    return { sectionDropdown };
  }

  createActivitiesFields(linkableActivities) {
    const activitiesDropdown = new FilteredDropdownView(this.locale, {
      label: DEFAULT_ACTIVITIES_LABEL,
      values: linkableActivities,
    });

    activitiesDropdown.on('change:selectedValue', (_event, _name, selectedActivityId) => {
      this.selectedActivityId = selectedActivityId;
    });

    this.on(`setup:${LINK_TYPE.ACTIVITY}`, (_evt, { href }) => {
      const activity = this.getLinkedSectionOrActivity(href, linkableActivities);
      if (activity) {
        activitiesDropdown.setSelectedValue(activity.id);
      }
    });
    this.on('reset', () => activitiesDropdown.reset());

    return { activitiesDropdown };
  }

  createDialogButtons() {
    const saveButton = super.createSaveButton();

    this.on('set:selectedLinkTypeKey', (_evt, _name, type) => {
      saveButton.unbind('isEnabled');
      saveButton
        .bind('isEnabled')
        .to(...VALIDATION_MAPPING[type].flatMap(field => [this, field]), (...fieldValue) => {
          return fieldValue.every(value => !!value);
        });
    });

    const cancelButton = super.createCancelButton();

    const unlinkButton = this.createButton('Unlink', unlinkIcon, 'ck-button-unlink');
    unlinkButton.delegate('execute').to(this, 'unlink');
    unlinkButton.type = 'reset';

    return { saveButton, cancelButton, unlinkButton };
  }

  createEmailFields() {
    const emailAddress = this.createInput('E-mail Address');
    createInputValueChangeBinding(emailAddress.fieldView, value => {
      this.emailAddress = value;
    });

    const emailSubject = this.createInput('Message Subject');
    createInputValueChangeBinding(emailSubject.fieldView, value => {
      this.emailSubject = value;
    });

    const emailBody = this.createInput('Message Body');
    createInputValueChangeBinding(emailBody.fieldView, value => {
      this.emailBody = value;
    });

    this.on(`setup:${LINK_TYPE.EMAIL}`, (_event, { href }) => {
      const mailUrl = new URL(href);
      const emailParams = new URLSearchParams(mailUrl.search);
      const mailAddress = mailUrl.pathname;
      const body = emailParams.get('body');
      const subject = emailParams.get('subject');

      emailAddress.fieldView.value = mailAddress;
      emailSubject.fieldView.value = subject;
      emailBody.fieldView.value = body;
    });

    this.on('reset', () => {
      emailAddress.fieldView.reset();
      emailSubject.fieldView.reset();
      emailBody.fieldView.reset();
    });

    return {
      emailAddress,
      emailSubject,
      emailBody,
    };
  }

  createTelFields() {
    const phoneNumberInput = this.createInput('Add phone number');
    createInputValueChangeBinding(phoneNumberInput.fieldView, value => {
      this.phoneNumber = value;
    });

    this.on(`setup:${LINK_TYPE.TEL}`, (_event, { href }) => {
      phoneNumberInput.fieldView.value = href.replace('tel:', '');
    });

    this.on('reset', () => phoneNumberInput.fieldView.reset());

    return { phoneNumberInput };
  }

  createUrlFields(targetOptions) {
    const urlInput = this.createInput('URL');
    createInputValueChangeBinding(urlInput.fieldView, value => {
      this.url = value;
    });

    const targetDropdown = this.createDropdown('Target', targetOptions, 'selectedTargetKey');
    targetDropdown.fieldView.buttonView.bind('label').to(this, 'selectedTargetKey', val => {
      return targetOptions.find(([_, key]) => key === val)?.[0];
    });

    this.on(`setup:${LINK_TYPE.URL}`, (_event, { href, target }) => {
      const targetMatch = targetOptions.find(([_, key]) => key === target);

      if (targetMatch) {
        const [label, key] = targetMatch;
        this.selectedTargetKey = key;
        targetDropdown.fieldView.buttonView.value = label;
      }

      urlInput.fieldView.value = href;
    });

    this.on('reset', () => {
      urlInput.fieldView.reset();
      targetDropdown.fieldView.buttonView.label = DEFAULT_TARGET_DROPDOWN_LABEL;
    });

    return { urlInput, targetDropdown };
  }

  focus() {
    this.focusCycler.focusFirst();
  }

  createDropdown(label, data, observableTarget) {
    const dropdown = new LabeledFieldView(this.locale, createLabeledDropdown);
    dropdown.bind('isEmpty').to(this, observableTarget, val => !val);
    dropdown.set({ label });

    dropdown.fieldView.buttonView.set({
      withText: true,
      ariaLabel: label,
    });
    dropdown.fieldView.on('execute', event => {
      this[observableTarget] = event.source.key;
    });

    const collectionItems = new Collection();
    data.forEach(item => {
      const [text, key] = item;
      const model = new Model({
        withText: true,
        label: text,
        key,
      });

      model.bind('isOn').to(this, observableTarget, val => val === key);

      collectionItems.add({
        type: 'button',
        model,
      });
    });

    addListToDropdown(dropdown.fieldView, collectionItems);

    return dropdown;
  }

  reset() {
    super.reset();
    this.set(DEFAULT_STATE);
    this.fire('reset');
  }

  showForm({ type, text = '', href = '', target }) {
    this.selectedLinkTypeKey = type;
    this.fire(`setup:${type}`, {
      text: text || '',
      href: href || '',
      target,
    });
  }

  getLinkedSectionOrActivity(href, links) {
    const match = /^(:sectionlink:|:activitylink:)(?<id>\d+$)/;
    const { id } = match.exec(href)?.groups ?? {};
    const linkedItem = links.find(([_, value]) => value === parseInt(id, 10));

    return linkedItem ? { label: linkedItem[0], id: linkedItem[1] } : null;
  }
}
