<template>
  <div>
    <kog-table
      class="margin-top-l"
      :is-loading-table-data="$wait.is('fetching_table_data')"
      loading-table-data-message="Loading..."
    >
      <template #header>
        <kog-table-header>
          <kog-table-cell-header> Student </kog-table-cell-header>
          <kog-table-cell-header v-if="hasSubjectLevel"> Level </kog-table-cell-header>
          <kog-table-cell-header> Last active </kog-table-cell-header>
          <kog-table-cell-header class="padd-left-s"> Last 5 sections </kog-table-cell-header>
          <kog-table-cell-header class="padd-left-s"> Last 5 assignments </kog-table-cell-header>
        </kog-table-header>
      </template>
      <template #body>
        <kog-table-row
          v-for="student in students"
          :key="student.user_id"
        >
          <kog-table-cell-text>
            <router-link
              v-slot="{ href, navigate }"
              custom
              :to="getStudentDetailsRoute(student)"
            >
              <a
                :href="href"
                @click="
                  e => {
                    trackClickStudentLink();
                    navigate(e);
                  }
                "
              >
                {{ student.first_name }} {{ student.last_name }}
              </a>
            </router-link>
          </kog-table-cell-text>
          <kog-table-cell-label
            v-if="hasSubjectLevel"
            :text="levelName(student.level.name)"
          />
          <kog-table-cell-text>
            {{ getFormattedLastActive(student) }}
          </kog-table-cell-text>
          <kog-table-cell-generic>
            <section-tag-list
              class="flexContainer"
              :sections="getStudentLastOpenSections(student.user_id)"
            />
          </kog-table-cell-generic>
          <kog-table-cell-generic>
            <assignment-button-list
              class="flexContainer"
              :assignments="getStudentLatestAssignments(student.user_id)"
              :class-slug="subjectClassSlug"
              :subject-id="subjectId"
              :class-id="classId"
            />
          </kog-table-cell-generic>
        </kog-table-row>
      </template>
    </kog-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellLabel from 'sharedApp/components/tables/kog-table-cell-label.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';
import { timeSince } from 'sharedApp/utils/time-utils.js';
import AssignmentButtonList from 'studyApp/components/student-details/assignment-button-list.vue';
import SectionTagList from 'studyApp/components/student-details/section-tag-list.vue';

export default {
  name: 'LastActivityTab',
  components: {
    KogTable,
    KogTableRow,
    KogTableHeader,
    KogTableCellHeader,
    KogTableCellLabel,
    KogTableCellGeneric,
    KogTableCellText,
    AssignmentButtonList,
    SectionTagList,
  },
  mixins: [RoutesMixin],
  props: {
    students: {
      type: Array,
      required: true,
    },
    tabName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('subjectModule', ['subject']),
    ...mapGetters('teacherInsightsModule', [
      'getStudentLastActivity',
      'getStudentLastOpenSections',
      'getStudentLatestAssignments',
    ]),
    hasSubjectLevel() {
      return this.subject?.possible_levels?.length > 0;
    },
  },
  async created() {
    this.$wait.start('fetching_table_data');
    await Promise.all(
      this.students.map(student =>
        Promise.all([
          this.fetchStudentLastActivity({
            subjectId: this.subjectId,
            userId: student.user_id,
          }),
          this.fetchStudentLatestAssignments({
            subjectClassId: this.classId,
            userId: student.user_id,
          }),
          this.fetchStudentLastOpenSections({
            subjectId: this.subjectId,
            userId: student.user_id,
          }),
        ]),
      ),
    );
    this.$wait.end('fetching_table_data');
  },
  methods: {
    ...mapActions('teacherInsightsModule', [
      'fetchStudentLastActivity',
      'fetchStudentLastOpenSections',
      'fetchStudentLatestAssignments',
    ]),
    getStudentDetailsRoute(student) {
      const studentDetailsRoute = {
        name: 'studentDetails',
        params: {
          classSlug: this.subjectClassSlug,
          cid: this.classId,
          sid: this.subjectId,
          studentId: student.user_id,
        },
        query: {
          origin: 'insights',
          origin_active_tab: this.tabName,
        },
      };

      return studentDetailsRoute;
    },
    getFormattedLastActive(student) {
      const lastActivity = this.getStudentLastActivity(student.user_id);
      if (lastActivity) {
        const lastActive = timeSince(lastActivity);
        return `${lastActive} ago`;
      }
      return 'No activity';
    },
    trackClickStudentLink() {
      this.$mixpanel.trackEvent('Insights - Click on student link', {
        tab: this.tabName,
      });
    },
    levelName(studentLevel) {
      const educationSystemName = this.subject.educationsystem.name;
      return getTerm(studentLevel, educationSystemName);
    },
  },
};
</script>
