<template>
  <div v-kog-mathjax="{ doneCallback: mathJaxDone }">
    <kog-table
      :title="title"
      :footer-text="paginationText"
      :pagination-current-page="paginatedQuestions.page"
      :pagination-number-of-pages="paginatedQuestions.num_pages"
      :update-current-page="goToPaginatedQuestionsPage"
      :is-loading-table-data="isTableDataLoading"
      loading-table-data-message="Loading questions..."
    >
      <template #subtitle>
        <select-subtitle
          type="question"
          :selected-items="selectedQuestions"
          :total-items="paginatedQuestions.count"
          :add-question-text="`Add ${$term('exam-style').toLowerCase()} question`"
          @add-question="onNewESQ"
        />
      </template>
      <template #colgroup>
        <col />
        <col />
        <col width="100%" />
        <col />
        <col v-if="hasEducationSystemPapertype" />
        <col v-if="hasEducationSystemMarks" />
        <col />
      </template>
      <template #header>
        <kog-table-header>
          <kog-table-cell-checkbox
            :is-header-cell="true"
            :is-checked="isAllSelected"
            @check="$emit('toggle-select-all')"
            @uncheck="$emit('toggle-select-all')"
          />
          <kog-table-cell-text
            class="text-center"
            :is-header-cell="true"
          >
            Sent
          </kog-table-cell-text>

          <kog-table-cell-text :is-header-cell="true"> Question </kog-table-cell-text>

          <kog-table-cell-text
            v-if="shouldShowLevelsInfo"
            :is-header-cell="true"
          >
            Level
          </kog-table-cell-text>

          <kog-table-cell-text
            v-if="hasEducationSystemPapertype"
            :is-padded-left="false"
            :is-header-cell="true"
          >
            Paper
          </kog-table-cell-text>

          <kog-table-cell-text
            v-if="hasEducationSystemMarks"
            :is-header-cell="true"
          >
            Marks
          </kog-table-cell-text>

          <kog-table-cell-header />
        </kog-table-header>
      </template>

      <template #body>
        <kog-table-row
          v-for="question in paginatedQuestions.results"
          :key="question.id"
          :is-selected="isQuestionSelected(question)"
        >
          <kog-table-cell-checkbox
            :is-checked="isQuestionSelected(question)"
            @check="toggleQuestion(question)"
            @uncheck="toggleQuestion(question)"
          />

          <!-- Sent -->
          <question-sent-before-icon v-if="questionsSentBeforeSet.has(question.uuid)" />
          <kog-table-cell-empty v-else />

          <!-- Question text -->
          <kog-table-cell-text
            :is-able-to-truncate="true"
            :tooltip-text="null"
          >
            <div
              v-kog-clickable:[question]="showQuestionPreviewModal"
              v-kog-description:[getUniqueId(question.id)]="'Click to open question preview'"
              class="flexContainer flexContainer-alignCenter SelectEsqList-questionContainer"
            >
              <div
                v-if="question.teacher_question && question.teacher_question.name"
                class="margin-right-xxs"
              >
                <kog-avatar :name="question.teacher_question.name" />
              </div>
              <div
                class="SelectEsqList-question"
                v-text="getQuestionPreview(question)"
              />
            </div>
          </kog-table-cell-text>

          <!-- Level -->
          <kog-table-cell-text v-if="shouldShowLevelsInfo">
            <question-level-labels
              :levels="question.attributes.levels"
              :relevant-group="subjectClassLevelGroup"
            />
          </kog-table-cell-text>

          <!-- Paper-->
          <kog-table-cell-tag
            v-if="question.papertype && hasEducationSystemPapertype"
            :label="question.papertype.name"
            class="SelectEsqList-paperTypeTag"
          />

          <!-- Marks-->
          <kog-table-cell-text v-if="question.marks && hasEducationSystemMarks">
            {{ question.marks }}
          </kog-table-cell-text>
          <kog-table-cell-actions
            :actions="getActions(question)"
            :context="question"
          />
        </kog-table-row>
      </template>
      <template #info>
        <div
          v-if="
            !isLoadingQuestions &&
            (!paginatedQuestions.results || paginatedQuestions.results.length === 0)
          "
        >
          <div class="text-center margin-top-xxl">
            <p>The selected subject area does not have any questions yet.</p>
            <button
              class="KogButtonLegacy KogButtonLegacy--primary margin-top-l"
              @click="onNewESQ"
            >
              Add {{ $term('exam-style').toLowerCase() }} question
            </button>
            <div class="margin-top-m">
              <a
                class="muted"
                href="https://intercom.help/kognity/en/articles/3811287-creating-your-own-questions"
                target="_blank"
              >
                <i class="fal fa-info-circle" /> View our help article on creating questions
              </a>
            </div>
          </div>
        </div>
      </template>
    </kog-table>
  </div>
</template>

<script>
import { inject } from 'vue';
import { findIndex } from 'lodash';
import { mapState } from 'vuex';

import QuestionLevelLabels from 'learning/common/components/question-level-labels.vue';

import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import KogTableCellActions from 'sharedApp/components/tables/kog-table-cell-actions.vue';
import KogTableCellCheckbox from 'sharedApp/components/tables/kog-table-cell-checkbox.vue';
import KogTableCellEmpty from 'sharedApp/components/tables/kog-table-cell-empty.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellTag from 'sharedApp/components/tables/kog-table-cell-tag.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import { IGCSE_LEVELS } from 'sharedApp/const/level-groups.js';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  hasEducationSystemMarks,
  hasEducationSystemPapertype,
} from 'sharedApp/services/educationSystem/education-system-service.js';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';
import { dangerouslyGetUnescapedText } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import SelectSubtitle from 'studyApp/components/teacher/assignment-create/select-step/select-subtitle.vue';
import ESQModal from 'studyApp/components/teacher/assignment-create/teacher-questions/esq-modal.vue';
import useTeacherQuestions from 'studyApp/components/teacher/assignment-create/teacher-questions/use-teacher-questions.js';
import QuestionPreviewModal from 'studyApp/components/teacher/assignments/question-preview-modal.vue';
import QuestionSentBeforeIcon from 'studyApp/components/teacher/assignments/question-sent-before-icon.vue';

export default {
  name: 'SelectEsqList',
  directives: {
    KogClickable,
    KogDescription,
  },
  components: {
    KogAvatar,
    KogTable,
    KogTableRow,
    KogTableHeader,
    KogTableCellHeader,
    KogTableCellActions,
    KogTableCellText,
    KogTableCellEmpty,
    KogTableCellCheckbox,
    KogTableCellTag,
    QuestionLevelLabels,
    SelectSubtitle,
    QuestionSentBeforeIcon,
  },
  props: {
    paginatedQuestions: {
      type: Object,
      required: true,
    },
    goToPaginatedQuestionsPage: {
      type: Function,
      required: true,
    },
    selectedQuestions: {
      type: Array,
      required: true,
    },
    isLoadingQuestions: {
      type: Boolean,
      required: true,
    },
    subjectId: {
      type: Number,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
    isQuestionSelected: {
      type: Function,
      required: true,
    },
    isAllSelected: {
      type: Boolean,
      default: false,
    },
    subjectAndTopics: {
      type: Array,
      required: true,
    },
    selectedNode: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
    educationSystem: {
      type: String,
      required: true,
    },
  },
  emits: ['question-deleted', 'toggle-item', 'toggle-select-all', 'question-saved'],
  setup() {
    const { getUniqueId } = useUniqueId();
    const { deleteTeacherQuestion, canEditQuestion } = useTeacherQuestions();

    const questionsSentBeforeSet = inject('questionsSentBeforeSet');

    return {
      getUniqueId,
      deleteTeacherQuestion,
      canEditQuestion,
      questionsSentBeforeSet,
    };
  },
  data() {
    return {
      mathJaxLoading: true,
      hasEducationSystemMarks: hasEducationSystemMarks(this.educationSystem),
      hasEducationSystemPapertype: hasEducationSystemPapertype(this.educationSystem),
    };
  },
  computed: {
    ...mapState('schoolstaffSubjectclassModule', ['subjectClass']),
    paginationText() {
      if (!this.paginationNumberOfPages || this.paginatedQuestions.count === 0) {
        return '';
      }
      const pageSize = this.paginatedQuestions.page_size;
      const totalItems = this.paginatedQuestions.count;
      const firstItemNumber = pageSize * this.paginatedQuestions.page - pageSize + 1;
      const lastItemNumber = Math.min(pageSize * this.paginatedQuestions.page, totalItems);

      return `
        ${firstItemNumber} - ${lastItemNumber}
         of ${totalItems} items
      `;
    },
    paginationNumberOfPages() {
      return this.isLoadingQuestions ? 0 : this.paginatedQuestions.num_pages;
    },
    isTableDataLoading() {
      return this.isLoadingQuestions || this.mathJaxLoading;
    },
    subjectClassLevelGroup() {
      return IGCSE_LEVELS;
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subjectClass.possible_levels);
    },
    trackOptions() {
      return {
        subjectId: this.subjectId,
        subjectName: this.subjectAndTopics[0].name,
        source: 'ESQ assignment',
      };
    },
  },
  methods: {
    mathJaxDone() {
      this.mathJaxLoading = false;
    },
    showQuestionPreviewModal(question) {
      const index = this.findQuestionIndex(question);

      this.$modal(
        QuestionPreviewModal,
        {
          question,
          assignmentType: ASSIGNMENT_TYPES.EXAMSTYLE,
          toggleQuestion: this.toggleQuestion,
          isQuestionSelected: this.isQuestionSelected,
          paginatedQuestions: this.paginatedQuestions,
          questionsSentBeforeSet: this.questionsSentBeforeSet,
          subjectName: this.subjectName,
          showPosition: false,
          educationSystem: this.educationSystem,
          source: 'assignment_create',
          initialCurrentQuestionIndex: index,
        },
        {
          zIndex: 900,
        },
      );
    },
    findQuestionIndex(question) {
      return findIndex(this.paginatedQuestions.results, { id: question.id });
    },
    getActions(question) {
      const actions = [
        {
          tooltipText: 'Preview',
          iconClass: 'fa-search',
          callback: this.showQuestionPreviewModal,
        },
      ];

      if (question.teacher_question && question.teacher_question.own_question) {
        if (this.canEditQuestion(question)) {
          actions.unshift({
            tooltipText: 'Edit',
            iconClass: 'fa-edit',
            callback: this.onEditQuestion,
          });
        } else {
          actions.unshift({
            tooltipText: "This question can't be edited, but you can delete it",
            iconClass: 'fa-trash',
            callback: deletedQuestion =>
              this.deleteTeacherQuestion(
                deletedQuestion,
                this.subjectId,
                this.trackOptions,
                this.questionDeletedCallback,
              ),
          });
        }
      }

      return actions;
    },

    getQuestionPreview(question) {
      return dangerouslyGetUnescapedText(question.question_html);
    },
    questionSavedCallback(node) {
      this.$emit('question-saved', this.selectedNode.level === 0 ? this.selectedNode : node);
    },
    questionDeletedCallback(question) {
      this.$emit('question-deleted', {
        node: this.selectedNode,
        question,
      });
    },
    onNewESQ() {
      this.$modal(
        ESQModal,
        {
          subjectId: this.subjectId,
          subjectAndTopics: this.subjectAndTopics,
          onSaveCallback: this.questionSavedCallback,
          educationSystem: this.educationSystem,
        },
        {
          closeOnClickOutside: false,
        },
      );
    },
    onEditQuestion(question) {
      this.$modal(ESQModal, {
        existingQuestion: question,
        subjectId: this.subjectId,
        subjectAndTopics: this.subjectAndTopics,
        onSaveCallback: this.questionSavedCallback,
        onDeleteCallback: this.questionDeletedCallback,
        educationSystem: this.educationSystem,
      });
    },
    toggleQuestion(question) {
      this.$emit('toggle-item', question);
    },
  },
};
</script>

<style scoped>
.SelectEsqList-loader :deep(.KogLoader__ballContainer) {
  margin-top: var(--space-xl);
}

.SelectEsqList-question {
  overflow: hidden;
}

.SelectEsqList-question :deep(div) {
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SelectEsqList-paperTypeTag {
  max-width: 20vw;
}

.SelectEsqList-paperTypeTag :deep(.KogTag) {
  max-width: 100%;
}
</style>
