import * as Sentry from '@sentry/vue';
import { cloneDeep, xor } from 'lodash';

import { getSectionQuestions } from '@apis/questions';

import multipleChoiceQuestionNumberingService from 'sharedApp/services/questions/multipleChoiceQuestionNumbering/multiple-choice-question-numbering-service.js';
import {
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { fetchReadingAssignmentsForSection } from 'studyApp/api/assignment.js';
import { startSectionQuestion } from 'studyApp/api/subject-classes.js';
import {
  addAnswer,
  fetchSectionNodes,
  fetchSectionQuestionPracticeItems,
} from 'studyApp/api/subject.js';

const mcqNumberingService = multipleChoiceQuestionNumberingService();

export default {
  namespaced: true,
  state: {
    sections: {},
    sectionCheckpointOccasionId: null,
    sectionQuestionPracticeItems: [],
    currentPracticeItemIdx: -1,
    ongoingReadingAssignmentOccasions: [],
    sectionQuestionCount: 0,
    bookRailSliders: {
      toc: false,
      assignment: false,
      unitBinder: false,
      glossary: false,
      notebook: false,
    },
    immersiveReaderContent: '',
  },
  mutations: {
    setRailSliderState(state, { sliderKey, flag }) {
      state.bookRailSliders[sliderKey] = flag;
    },
    setSections(state, sections) {
      state.sections = sections;
    },
    setSectionQuestionPracticeItems(state, sectionQuestionPracticeItems) {
      state.sectionQuestionPracticeItems = sectionQuestionPracticeItems.map(practiceItem => {
        if (isMCQ(practiceItem.question)) {
          mcqNumberingService.updateMultipleChoiceQuestionWithNumbering(
            practiceItem.question,
            true,
          );
        }

        return practiceItem;
      });
    },
    setSectionCheckpointOccasionId(state, occasionId) {
      state.sectionCheckpointOccasionId = occasionId;
    },
    setSectionQuestionCount(state, sectionQuestionCount) {
      state.sectionQuestionCount = sectionQuestionCount;
    },
    goToNextQuestion(state) {
      state.currentPracticeItemIdx += 1;
    },
    setCurrentAnswer(state, answer) {
      if (state.currentPracticeItemIdx >= 0) {
        state.sectionQuestionPracticeItems = state.sectionQuestionPracticeItems.map(
          (practiceItem, index) => {
            if (index !== state.currentPracticeItemIdx) return practiceItem;

            const { answerToSubmit, question } = practiceItem;

            let newAnswer = answer;

            if (isMCQ(question)) {
              if (question.is_multi_select) {
                newAnswer = xor(answerToSubmit, [answer]);
              } else {
                newAnswer = [answer];
              }
            }

            return {
              ...practiceItem,
              user_answer: { answer: newAnswer },
              answerToSubmit: newAnswer,
            };
          },
        );
      }
    },
    resetCurrentQuestion(state) {
      state.currentPracticeItemIdx = -1;
      state.sectionQuestionPracticeItems = [];
    },
    prepPracticeItemForSubmission(state) {
      state.sectionQuestionPracticeItems = state.sectionQuestionPracticeItems.map(
        (practiceItem, index) => {
          if (index !== state.currentPracticeItemIdx) return practiceItem;

          return {
            ...practiceItem,
            last_submitted_answer: practiceItem.user_answer.answer,
            user_answer: {
              ...practiceItem.user_answer,
              is_correct: undefined,
            },
          };
        },
      );
    },
    setPracticeItemSubmissionResult(state, answerResult) {
      state.sectionQuestionPracticeItems = state.sectionQuestionPracticeItems.map(
        (practiceItem, index) => {
          if (index !== state.currentPracticeItemIdx) return practiceItem;

          let { solution } = answerResult;
          const { user_answer: userAnswer } = answerResult;

          if (isSTQ(practiceItem.question)) {
            userAnswer.answer = userAnswer.user_text;
          }

          if (isMCQ(practiceItem.question)) {
            const clonedQuestion = cloneDeep(practiceItem.question);
            const clonedSolution = cloneDeep(solution);

            const mutateSolutionNumbering = questionSolution => {
              mcqNumberingService.updateSeparateMultipleChoiceQuestionChoiceWithNumbering(
                clonedQuestion,
                questionSolution,
              );
            };

            if (Array.isArray(clonedSolution)) {
              clonedSolution.forEach(mutateSolutionNumbering);
            } else {
              mutateSolutionNumbering(clonedSolution);
            }

            solution = clonedSolution;
          }

          return {
            ...practiceItem,
            solution,
            user_answer: userAnswer,
          };
        },
      );
    },
    setOngoingReadingAssignmentOccasions(state, occasions) {
      state.ongoingReadingAssignmentOccasions = occasions;
    },
    setImmersiveReaderContent(state, immersiveReaderContent) {
      state.immersiveReaderContent = immersiveReaderContent;
    },
    resetImmersiveReaderContent(state) {
      state.immersiveReaderContent = '';
    },
  },
  getters: {
    isBookRailSliderOpen(state) {
      return Object.values(state.bookRailSliders).includes(true);
    },
    sectionNodes(state) {
      if (!state.sections.sectionnodes) {
        return [];
      }
      return state.sections.sectionnodes;
    },
    currentNodeParent(state) {
      return state.sections.subjectnode;
    },
    currentPracticeItem(state) {
      if (state.currentPracticeItemIdx < 0) {
        return null;
      }
      return state.sectionQuestionPracticeItems[state.currentPracticeItemIdx];
    },
    findSectionNodeById(_, getters) {
      return sectionNodeId => {
        if (!sectionNodeId) {
          return null;
        }
        return getters.sectionNodes.find(sn => sn.id.toString() === sectionNodeId.toString());
      };
    },
  },
  actions: {
    async fetchSections(
      { commit },
      { subjectSlug, topicSlug, subtopicSlug, sectionSlug, subsectionSlug },
    ) {
      try {
        let actualSectionSlug = null;
        if (subsectionSlug) {
          actualSectionSlug = sectionSlug;
        }
        const data = await fetchSectionNodes(
          subjectSlug,
          topicSlug,
          subtopicSlug,
          actualSectionSlug,
        );
        commit('setSections', data);
      } catch (error) {
        Sentry.captureMessage(`fetchSectionNodes: ${error}`);
      }
    },
    async fetchSectionQuestionCount({ commit }, { subjectNodeId, level }) {
      commit('resetCurrentQuestion');
      const response = await getSectionQuestions(subjectNodeId, {
        additionalParams: {
          showAnswers: false,
          all: false,
          active: true,
          level,
        },
      });
      commit('setSectionQuestionCount', response.count);
    },
    async fetchSectionQuestionPracticeItems({ commit }, subjectNodeId) {
      try {
        commit('resetCurrentQuestion');
        const response = await fetchSectionQuestionPracticeItems(subjectNodeId);
        commit('setSectionQuestionPracticeItems', response);
      } catch (error) {
        Sentry.captureMessage(`fetchsectionQuestionPracticeItems: ${error}`);
      }
    },
    async submitCurrentAnswer({ getters, commit }, sectionNodeId) {
      const practiceItem = getters.currentPracticeItem;
      commit('prepPracticeItemForSubmission');
      const response = await addAnswer(
        sectionNodeId,
        practiceItem.id,
        practiceItem.question.id,
        practiceItem.user_answer.answer,
      );
      commit('setPracticeItemSubmissionResult', response[0]);
    },
    async fetchReadingAssignmentForSection({ commit }, sectionNodeId) {
      try {
        const data = await fetchReadingAssignmentsForSection(sectionNodeId);
        commit('setOngoingReadingAssignmentOccasions', data.results);
      } catch (error) {
        Sentry.captureMessage(`fetchReadingAssignmentForSection: ${error}`);
      }
    },
    async startSectionQuestion({ commit }, sectionNodeId) {
      const response = await startSectionQuestion(sectionNodeId);
      const { id: occasionId, practice_items: practiceItems } = response;
      commit('setSectionCheckpointOccasionId', occasionId);
      commit('setSectionQuestionPracticeItems', practiceItems);
    },
    closeRailSlider({ state, commit }) {
      const keys = Object.keys(state.bookRailSliders);
      keys.forEach(key => {
        commit('setRailSliderState', { sliderKey: key, flag: false });
      });
    },
    async openRailSlider({ commit, dispatch }, sliderKey) {
      await dispatch('closeRailSlider');
      commit('setRailSliderState', { sliderKey, flag: true });
    },
  },
};
