<template>
  <span
    v-tooltip="{
      theme: 'kog-tooltip',
      content: shareableLinkTooltipText,
      hideTriggers: triggers => triggers,
    }"
    :aria-label="shareableLinkTooltipText"
  >
    <kog-round-button
      v-if="showIconOnly"
      aria-label="Share assignment"
      icon-class="fa-link"
      button-style="basic"
      :size="size"
      class="CopyShareableAssignmentLink-buttonLink"
      @click="copyShareableLink"
    />
    <kog-button
      v-else
      label="Share assignment"
      :has-icon="true"
      button-style="basic"
      icon-class="far fa-link"
      @click="copyShareableLink"
    />
  </span>
</template>

<script>
import { useClipboard } from '@vueuse/core';
import { VTooltip } from 'floating-vue';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import { getShareableAssignmentLink } from 'teachApp/utils/assignment-utils.js';

const TOOLTIP_DEFAULT = 'Copy assignment link to clipboard';
const TOOLTIP_COPIED = 'Copied!';
const TOOLTIP_RESET_INTERVAL = 1500;

export default {
  name: 'CopyShareableAssignmentLink',
  components: {
    KogRoundButton,
    KogButton,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    assignmentId: {
      type: Number,
      required: true,
    },
    showIconOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        const validTypes = ['medium', 'small', 'x-small', 'xx-small'];
        return !value || validTypes.includes(value);
      },
    },
  },
  emits: ['copy'],
  setup() {
    const { copy } = useClipboard();

    return {
      copyToClipboard: copy,
    };
  },
  data() {
    return {
      shareableLinkTooltipText: TOOLTIP_DEFAULT,
    };
  },
  computed: {
    shareableAssignmentLink() {
      return getShareableAssignmentLink(this.assignmentId);
    },
  },
  methods: {
    setShareableLinkTooltip(text) {
      this.shareableLinkTooltipText = text;
    },
    copyShareableLink() {
      this.copyToClipboard(this.shareableAssignmentLink);

      this.$emit('copy');
      this.setShareableLinkTooltip(TOOLTIP_COPIED);
      setTimeout(this.setShareableLinkTooltip, TOOLTIP_RESET_INTERVAL, TOOLTIP_DEFAULT);
    },
  },
};
</script>

<style scoped>
.CopyShareableAssignmentLink-buttonLink {
  background-color: var(--kog-colors-white);
}
</style>
