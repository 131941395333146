<template>
  <kog-table :title="title">
    <template #subtitle>
      <select-subtitle
        type="section"
        :selected-items="selectedSections"
        :total-items="sections.length"
      />
    </template>
    <template #colgroup>
      <col />
      <col width="100%" />
      <col />
      <col />
    </template>
    <template #header>
      <kog-table-header>
        <kog-table-cell-checkbox
          v-if="selectedNode && selectedNode.level !== 0"
          :is-header-cell="true"
          :is-checked="isAllSelected"
          @check="$emit('toggle-select-all')"
          @uncheck="$emit('toggle-select-all')"
        />
        <kog-table-cell-text
          v-else
          :is-header-cell="true"
          class="text-center"
        >
          Add
        </kog-table-cell-text>
        <kog-table-cell-text :is-header-cell="true"> Section </kog-table-cell-text>
        <kog-table-cell-text
          :is-padded-left="false"
          :is-header-cell="true"
        >
          Subtopic
        </kog-table-cell-text>
        <kog-table-cell-text
          class="text-center"
          :is-header-cell="true"
        >
          Link to textbook
        </kog-table-cell-text>
      </kog-table-header>
    </template>

    <template
      v-if="sections.length === 0"
      #info
    >
      <div class="text-center margin-top-xxxl margin-right-m margin-left-m">
        <p class="margin-0 heading-s"> The selected subject area does not have any sections. </p>
      </div>
    </template>

    <template #body>
      <kog-table-row
        v-for="section in sections"
        :key="section.id"
        :is-selected="isSectionSelected(section)"
      >
        <kog-table-cell-checkbox
          :is-checked="isSectionSelected(section)"
          :is-disabled="false"
          @check="$emit('toggle-section', section)"
          @uncheck="$emit('toggle-section', section)"
        />

        <kog-table-cell-text :is-able-to-truncate="true">
          {{ getSectionText(section) }}
          <content-hidden-label v-if="hiddenSubjectNodeIds.includes(section.id)" />
        </kog-table-cell-text>

        <kog-table-cell-label
          :text="section.subtopicNumber"
          :tooltip-text="section.subtopicName"
        />
        <kog-table-cell-text class="text-center">
          <router-link
            :to="{ ...section.url, name: 'classBook' }"
            target="_blank"
          >
            Open section <i class="fas fa-external-link-alt" />
          </router-link>
        </kog-table-cell-text>
      </kog-table-row>
    </template>
  </kog-table>
</template>

<script>
import KogTableCellCheckbox from 'sharedApp/components/tables/kog-table-cell-checkbox.vue';
import KogTableCellLabel from 'sharedApp/components/tables/kog-table-cell-label.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';

import SelectSubtitle from './select-subtitle.vue';

export default {
  name: 'SelectSectionsList',
  components: {
    ContentHiddenLabel,
    SelectSubtitle,
    KogTable,
    KogTableHeader,
    KogTableRow,
    KogTableCellCheckbox,
    KogTableCellText,
    KogTableCellLabel,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    selectedSections: {
      type: Array,
      default: () => [],
    },
    isAllSelected: {
      type: Boolean,
      default: false,
    },
    selectedNode: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
    hiddenSubjectNodeIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['toggle-select-all', 'toggle-section'],
  methods: {
    isSectionSelected(section) {
      return (
        this.selectedSections &&
        this.selectedSections.some(selectSec => selectSec.id === section.id)
      );
    },
    getSectionText(section) {
      return `${section.number_including_ancestors} ${section.name}`;
    },
  },
};
</script>
