<template>
  <kog-modal
    class="NewProgressBarModal"
    title="New progress bar"
    :maximize="true"
  >
    <template #modalBody>
      <p class="padd-left-l padd-right-l">
        A new progress bar is launched! We've changed the Strength bar into a Questions completed
        bar, to better show your progress on all available questions on the topic. You can change
        the progress bar in account settings.
      </p>
      <progress-bar-options class="padd-left-l padd-right-l" />
    </template>
    <template #modalFooter>
      <kog-button
        label="Apply selection"
        button-style="accent"
        class="width-full"
        @click="applySelection"
      />
    </template>
  </kog-modal>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import ProgressBarOptions from 'studyApp/components/progress-bar-options/progress-bar-options.vue';

export default {
  name: 'NewProgressBarModal',
  components: {
    KogButton,
    KogModal,
    ProgressBarOptions,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    applySelection() {
      this.closeModal();
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .NewProgressBarModal > .KogModal-body > .flexContainer {
    flex-direction: column;
  }
}
</style>
