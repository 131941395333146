<template>
  <div
    ref="root"
    v-kog-mathjax
    class="FillInTheBlanksQuestion content-editable"
  >
    <span class="screenreader-only">{{ contentTypeLabel }}</span>
    <div ref="questionPlaceholder" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { escape } from 'lodash';

import FillBlankQuestionInput from 'sharedApp/components/fill-in-the-blanks-question/fill-blank-question-input.vue';
import {
  dangerouslyGetUnescapedText,
  getQuestionDescription,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import mountComponent from 'sharedApp/utils/mount-component.ts';

function getBlankElementReference(component, blankCount, elementId, vm) {
  const props = {
    displayInputIndex: vm?.displayInputIndex ?? false,
    orderNumber: blankCount,
    value: '',
  };

  if (vm) {
    const { userAnswers, question, showWithAnswers } = vm;
    if (userAnswers) {
      props.value = userAnswers[elementId];
    } else {
      props.value = showWithAnswers
        ? question.answers.find(a => a.html_element_uid === elementId).answers[0]
        : '';
    }
  }

  const { el, vNode, destroy } = mountComponent({
    component,
    props: {
      ...props,
      onInput() {
        vm.onInput();
      },
      onBlur() {
        vm.onBlur();
      },
    },
    elementCssClasses: ['inline-block'],
  });

  vm.blanksDestroyMethods.push(destroy);

  const blankRef = {
    id: elementId,
    el,
    ref: vNode.component.ctx,
    vNode,
  };
  return blankRef;
}

export function getFBQInputPlaceholders(node) {
  let blanks = [];
  const fbqEls = node.getElementsByTagName('kog-ftbq');
  for (let i = 0; i < fbqEls.length; i += 1) {
    const el = fbqEls[i];
    blanks = [...blanks, { node: el, parent: el.parentNode }];
  }
  return blanks;
}

export function getFBQElementAndReferences(component, questionHtml, vm) {
  // Create question element from the html from the backend
  // Replace the backend markup by Vue components to manage the blanks
  let blankCount = 1;
  const blankRefs = [];

  const questionElement = document.createElement('div');
  questionElement.innerHTML =
    vm && vm.shouldRenderAsText ? escape(dangerouslyGetUnescapedText(questionHtml)) : questionHtml;

  const blanks = getFBQInputPlaceholders(questionElement);
  blanks.forEach(({ node, parent }) => {
    const elementId = node.getAttribute('element-uid');
    const blankRef = getBlankElementReference(component, blankCount, elementId, vm);
    blankRefs.push(blankRef);
    parent.replaceChild(blankRef.el, node);
    blankCount += 1;
  });
  return {
    blankRefs,
    questionElement,
  };
}

export default defineComponent({
  name: 'FillInTheBlanksQuestion',
  props: {
    question: {
      type: Object,
      required: true,
    },
    showWithAnswers: {
      type: Boolean,
      default: false,
    },
    isShowingAnswerCorrectness: {
      type: Boolean,
      default: false,
    },
    answersWithCorrectness: {
      type: Object,
      default: () => ({}),
    },
    disableUserInput: {
      type: Boolean,
      default: false,
    },
    userAnswers: {
      type: Object,
      default: () => null,
    },
    shouldRenderAsText: {
      type: Boolean,
      default: false,
    },
    displayInputIndex: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'blur'],
  data() {
    return {
      blankRefs: [],
      questionElement: null,
      eventQueue: [],
      batchEvents: false,
      blanksDestroyMethods: [],
    };
  },
  computed: {
    contentTypeLabel() {
      return getQuestionDescription(this.question);
    },
  },
  watch: {
    userAnswers(newAnswers) {
      if (!newAnswers || Object.keys(newAnswers).length === 0) {
        this.removeInput();
      } else {
        this.batchEvents = true;
        this.blankRefs.forEach(blank => {
          if (!Object.keys(newAnswers).includes(blank.id)) {
            return;
          }

          const updatedAnswer = newAnswers[blank.id];
          const oldAnswer = blank.ref.getValue();

          if (updatedAnswer !== oldAnswer) {
            blank.ref.setValue(updatedAnswer);
          }
        });
        this.batchEvents = false;
        this.handleEventQueue();
        this.checkNeedToShowAnswerCorrectness();
      }
    },
    disableUserInput() {
      if (this.disableUserInput) {
        this.markUserInputsAsDisabled();
      } else {
        this.markUserInputsAsEnabled();
      }
    },
    question: {
      handler(newQuestion, oldQuestion) {
        if (
          newQuestion.id !== oldQuestion.id ||
          newQuestion.question_html !== oldQuestion.question_html
        ) {
          this.insertQuestionElement();
        }
      },
      deep: true,
    },
    displayInputIndex(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.insertQuestionElement();
      }
    },
    showWithAnswers(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.insertQuestionElement();
      }
    },
    shouldRenderAsText() {
      this.insertQuestionElement();
    },
    isShowingAnswerCorrectness() {
      this.checkNeedToShowAnswerCorrectness();
    },
    answersWithCorrectness() {
      this.checkNeedToShowAnswerCorrectness();
    },
  },
  mounted() {
    this.questionElement = this.$refs.questionPlaceholder;
    this.insertQuestionElement();
  },
  beforeUnmount() {
    this.blanksDestroyMethods.forEach(destroy => destroy());
  },
  methods: {
    handleEventQueue() {
      if (this.eventQueue.length === 0) return;

      const finalEvent = this.eventQueue.pop();
      this.eventQueue = [];
      this.triggerEvent(finalEvent);
    },
    insertQuestionElement() {
      const { blankRefs, questionElement } = getFBQElementAndReferences(
        FillBlankQuestionInput,
        this.question.question_html,
        this,
      );
      this.blankRefs = blankRefs;
      this.$refs.root.replaceChild(questionElement, this.questionElement);
      this.questionElement = questionElement;
      if (this.showWithAnswers) {
        this.markInputsAsSolution();
      }
      if (this.disableUserInput) {
        this.markUserInputsAsDisabled();
      }
      this.checkNeedToShowAnswerCorrectness();
    },
    markInputsAsSolution() {
      this.blankRefs.forEach(blank => {
        blank.ref.markAsSolution();
      });
    },
    markUserInputsAsDisabled() {
      this.blankRefs.forEach(blank => {
        blank.ref.markAsUserInputDisabled();
      });
    },
    markUserInputsAsEnabled() {
      this.blankRefs.forEach(blank => {
        blank.ref.markAsUserInputEnabled();
      });
    },
    resetInputEnableState() {
      if (this.disableUserInput) {
        this.markUserInputsAsDisabled();
      } else {
        this.markUserInputsAsEnabled();
      }
    },
    removeInput() {
      this.resetInputEnableState();

      this.blankRefs.forEach(blank => {
        blank.ref.setValue('');
        blank.ref.markAsInitial();
      });
    },
    checkNeedToShowAnswerCorrectness() {
      if (this.isShowingAnswerCorrectness && this.answersWithCorrectness) {
        this.markInputs(this.answersWithCorrectness);
      }
    },
    markInputs(answers) {
      // answers should be an object of the form { [blankId]: { correct: boolean } }
      this.blankRefs.forEach(blank => {
        if (answers[blank.id]?.correct) {
          blank.ref.markAsCorrect();
        } else {
          blank.ref.markAsIncorrect();
        }
      });
    },
    getAnswers(allowMissingAnswers = false) {
      // This method returns an object of the user answers, but if there's any
      // empty blank it marks the blanks as empty and returns null
      const missingAnswers = this.blankRefs.some(blank => !blank.ref.getValue());
      if (missingAnswers && !allowMissingAnswers) {
        this.blankRefs.forEach(blank => blank.ref.markAsEmpty());
        return null;
      }

      const answers = this.blankRefs.reduce((acc, blank) => {
        acc[blank.id] = blank.ref.getValue();
        return acc;
      }, {});
      return answers;
    },
    onBlur() {
      const event = {
        type: 'blur',
        payload: {
          answers: this.getAnswers(true),
        },
      };

      if (this.batchEvents) {
        this.eventQueue.push(event);
        return;
      }

      this.triggerEvent(event);
    },
    onInput() {
      const event = {
        type: 'input',
        payload: {
          answers: this.getAnswers(true),
        },
      };

      if (this.batchEvents) {
        this.eventQueue.push(event);
        return;
      }

      this.triggerEvent(event);
    },
    triggerEvent(eventData) {
      this.$emit(eventData.type, eventData.payload);
    },
  },
});
</script>
