/* eslint-disable no-underscore-dangle */

import type { StateTree, StoreDefinition } from 'pinia';
import type { ComponentPublicInstance } from 'vue';

type Action<Tobj> = {
  action: keyof Tobj;
  loader?: string;
};

type MapActionsObjectReturn<A, T extends Record<string, Action<A>>> = {
  [key in keyof T]: A[T[key]['action']];
};

/**
 * Built with the foundation of pinia typing for mapActions
 * (https://github.com/vuejs/pinia/blob/v2/packages/pinia/src/mapHelpers.ts#L379)
 * As well as vue-wait implementation (https://github.com/f/vue-wait/blob/master/src/helpers.js#L34)
 */
export function mapWaitingActions<
  Id extends string,
  S extends StateTree,
  G,
  A,
  ActionName extends string,
  ActionMapper extends Record<ActionName, Action<A>>,
>(
  store: StoreDefinition<Id, S, G, A>,
  actions: ActionMapper,
): MapActionsObjectReturn<A, ActionMapper> {
  return Object.keys(actions).reduce(
    (acc, key) => {
      const actionKey = key as ActionName;
      const entry = actions[actionKey];
      const storeAction = entry.action;
      const waiter = entry.loader ?? String(entry.action);

      // @ts-expect-error - ts can't infer what we assign properly
      acc[actionKey] = async function waitWrapper(
        this: ComponentPublicInstance,
        ...args: unknown[]
      ): Promise<unknown> {
        try {
          this.__$waitInstance.start(waiter);
          return await store(this.$pinia)[storeAction](...args);
        } finally {
          this.__$waitInstance.end(waiter);
        }
      };
      return acc;
    },
    {} as MapActionsObjectReturn<A, ActionMapper>,
  );
}
