<template>
  <span>
    {{ subjectNode.formatted_number_including_ancestors }} {{ subjectNode.name }}
    <content-hidden-label v-if="!isVisible" />
  </span>
</template>

<script>
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';

export default {
  name: 'PracticeTestNodeName',
  components: { ContentHiddenLabel },
  props: {
    subjectNode: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
