<template>
  <div class="flexContainer flexContainer-column flexContainer-alignCenter">
    <kog-font-awesome-icon
      class="QuizResultsPage-checkMark flexContainer flexContainer-center"
      fa-class="fa-circle-check"
      fa-style="solid"
      size="custom"
      :custom-size="160"
    />
    <span class="heading-s margin-top-l">Results</span>
    <div class="heading-xl margin-top-l"
      >{{ questionsAnsweredCorrectlyCount }} / {{ questionsCount }}</div
    >
    <image-box
      class="margin-top-l"
      :src="squigglyLineImage"
      :h="300"
      :w="300"
      alt="Squiggly line"
      :transparent-background="true"
    />
    <kog-button
      class="margin-top-l"
      label="Restart quiz"
      @click="$emit('restart-quiz')"
    />
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import squigglyLineImage from 'studyApp/images/squiggly-line.svg';

export default {
  name: 'QuizResultsPage',
  components: {
    ImageBox,
    KogFontAwesomeIcon,
    KogButton,
  },
  props: {
    questionsCount: {
      type: Number,
      required: true,
    },
    questionsAnsweredCorrectlyCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['restart-quiz'],
  data() {
    return {
      squigglyLineImage,
    };
  },
};
</script>

<style scoped>
.QuizResultsPage-checkMark {
  width: 158px;
  height: 158px;

  color: var(--kog-colors-green-500);

  background-color: var(--kog-text-default);
  border-radius: 50%;
}
</style>
