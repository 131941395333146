<template>
  <div
    class="inlineflexContainer flexContainer-alignCenter"
    :aria-label="tooltipText"
    :aria-valuenow="firstValue"
    role="progressbar"
    aria-valuemin="0"
    :aria-valuemax="total"
  >
    <svg
      v-tooltip="{
        theme: 'kog-tooltip',
        content: tooltipText,
      }"
      aria-hidden="true"
      :style="{ width: diameter + 'px', height: diameter + 'px' }"
      viewBox="0 0 36 36"
      class="KogProgressCircle"
    >
      <path
        class="KogProgressCircle-bar KogProgressCircle-emptyBar"
        :class="`KogProgressCircle-emptyBar--${barCountClass}--${type}`"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        v-if="firstPercentage > 0"
        :stroke-dasharray="firstDashArray"
        class="KogProgressCircle-bar KogProgressCircle-bar1"
        :class="`KogProgressCircle-bar1--${barCountClass}--${type}`"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        v-if="secondPercentage > 0"
        :stroke-dasharray="secondDashArray"
        class="KogProgressCircle-bar KogProgressCircle-bar2"
        :class="`KogProgressCircle-bar2--${barCountClass}--${type}`"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text
        v-if="isPercentageShown || text"
        x="18"
        y="18"
        dominant-baseline="central"
        class="KogProgressCircle-center-text"
        :class="`KogProgressCircle-center-text--${type}`"
      >
        {{ circleText }}
      </text>
    </svg>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

const SIZE_TO_PIXELS = {
  s: 24,
  m: 32,
  l: 48,
  xl: 72,
};

export default {
  name: 'KogProgressCircle',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    /**
     * A value corresponding to the first circle segment.
     * Segment percentage is calculated in relation with `total` value.
     */
    firstValue: {
      type: Number,
      required: true,
    },
    /**
     * A value corresponding to the second circle segment.
     * Segment percentage is calculated in relation with `total` value.
     */
    secondValue: {
      type: Number,
      default: null,
    },
    /**
     * Whether to display the percentage inside the circle.
     * Only available for `size: 'l'` and single-bar.
     */
    showPercentage: {
      type: Boolean,
      default: false,
    },
    /**
     * Sets the diameter of the progress bar.
     */
    size: {
      type: String,
      default: 'm',
      validator: prop => ['s', 'm', 'l', 'xl'].includes(prop),
    },
    /**
     * A tooltip to show when hovering the component.
     */
    tooltipText: {
      type: String,
      default: '',
    },
    /**
     * The maximum possible total value for the bar segments,
     * corresponding to 100%.
     */
    total: {
      type: Number,
      required: true,
    },
    /**
     * Controls the bar colors. There are five predefined sets.
     */
    type: {
      type: String,
      default: 'primary',
      validator: prop => ['primary', 'mixed', 'positive', 'warning', 'negative'].includes(prop),
    },
    /**
     * A text to show in the center of the progress circle.
     * If given, will take priority over `showPercentage`.
     */
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    barCountClass() {
      if (this.secondValue === null) {
        return 'single';
      }

      return 'double';
    },
    diameter() {
      return SIZE_TO_PIXELS[this.size];
    },
    firstDashArray() {
      return `${this.firstPercentage}, 100`;
    },
    secondDashArray() {
      return `0 ${this.firstPercentage} ${this.secondPercentage} 100`;
    },
    firstPercentage() {
      const percentage = this.total ? (this.firstValue * 100) / this.total : 0;
      return percentage.toFixed();
    },
    secondPercentage() {
      if (!this.secondValue || !this.total) {
        return 0;
      }

      const percentage = (this.secondValue * 100) / this.total;
      return percentage.toFixed();
    },
    isPercentageShown() {
      const isSizeLargeEnough = this.size === 'l' || this.size === 'xl';
      return this.showPercentage && isSizeLargeEnough && this.secondValue === null;
    },
    circleText() {
      return this.text ? this.text : `${this.firstPercentage}%`;
    },
  },
};
</script>

<style scoped>
.KogProgressCircle {
  overflow: visible;
}

.KogProgressCircle-bar {
  fill: none;
  stroke-width: 4;
}

.KogProgressCircle-bar1 {
  animation: progress1 1s ease-out forwards;
}

.KogProgressCircle-bar2 {
  animation: progress2 1s ease-out forwards;
}

@keyframes progress1 {
  0% {
    stroke-dasharray: 0 100;
  }
}

@keyframes progress2 {
  0% {
    stroke-dasharray: 0 0 0 100;
  }
}

.KogProgressCircle-emptyBar {
  stroke: var(--kog-progressbar-default-background);
}

.KogProgressCircle-emptyBar--single--primary {
  stroke: var(--kog-progressbar-single-primary-background);
}

.KogProgressCircle-emptyBar--single--positive {
  stroke: var(--kog-progressbar-single-positive-background);
}

.KogProgressCircle-emptyBar--single--negative {
  stroke: var(--kog-progressbar-single-negative-background);
}

.KogProgressCircle-emptyBar--single--warning {
  stroke: var(--kog-progressbar-single-warning-background);
}

.KogProgressCircle-emptyBar--single--mixed {
  stroke: var(--kog-progressbar-single-mixed-background);
}

.KogProgressCircle-bar1--single--primary {
  stroke: var(--kog-progressbar-single-primary-color);
}

.KogProgressCircle-bar1--single--positive {
  stroke: var(--kog-progressbar-single-positive-color);
}

.KogProgressCircle-bar1--single--negative {
  stroke: var(--kog-progressbar-single-negative-color);
}

.KogProgressCircle-bar1--single--warning {
  stroke: var(--kog-progressbar-single-warning-color);
}

.KogProgressCircle-bar1--single--mixed {
  stroke: var(--kog-progressbar-single-mixed-color);
}

.KogProgressCircle-bar1--double--primary {
  stroke: var(--kog-progressbar-double-primary-bar1-color);
}

.KogProgressCircle-bar1--double--positive {
  stroke: var(--kog-progressbar-double-positive-bar1-color);
}

.KogProgressCircle-bar1--double--negative {
  stroke: var(--kog-progressbar-double-negative-bar1-color);
}

.KogProgressCircle-bar1--double--warning {
  stroke: var(--kog-progressbar-double-warning-bar1-color);
}

.KogProgressCircle-bar1--double--mixed {
  stroke: var(--kog-progressbar-double-mixed-bar1-color);
}

.KogProgressCircle-bar2--double--primary {
  stroke: var(--kog-progressbar-double-primary-bar2-color);
}

.KogProgressCircle-bar2--double--positive {
  stroke: var(--kog-progressbar-double-positive-bar2-color);
}

.KogProgressCircle-bar2--double--negative {
  stroke: var(--kog-progressbar-double-negative-bar2-color);
}

.KogProgressCircle-bar2--double--warning {
  stroke: var(--kog-progressbar-double-warning-bar2-color);
}

.KogProgressCircle-bar2--double--mixed {
  stroke: var(--kog-progressbar-double-mixed-bar2-color);
}

.KogProgressCircle-center-text {
  font-size: 10px;
  font-weight: 700;
  line-height: 24px;
  text-anchor: middle;
}

.KogProgressCircle-center-text--primary {
  fill: var(--kog-progress-circle-primary-text-color);
}

.KogProgressCircle-center-text--positive {
  fill: var(--kog-progress-circle-positive-text-color);
}

.KogProgressCircle-center-text--negative {
  fill: var(--kog-progress-circle-negative-text-color);
}

.KogProgressCircle-center-text--warning {
  fill: var(--kog-progress-circle-warning-text-color);
}

.KogProgressCircle-center-text--mixed {
  fill: var(--kog-progress-circle-mixed-text-color);
}
</style>
