<template>
  <div>
    <kog-table
      v-if="isListShown"
      :is-loading-table-data="isTeacherPageLoading"
      loading-table-data-message="Loading teachers..."
    >
      <template #colgroup>
        <col width="71%" />
        <col width="18%" />
        <col width="11%" />
      </template>
      <template #header>
        <kog-table-header>
          <kog-table-cell-header
            :is-sorted="sortedBy === keys.USER__FULL_NAME"
            :sort-column="() => determineSortParams(keys.USER__FULL_NAME)"
            :sort-order="sortOrder"
          >
            Teachers
          </kog-table-cell-header>
          <kog-table-cell-header
            :is-sorted="sortedBy === keys.USER__PROFILE__LAST_ACTIVE"
            :sort-column="() => determineSortParams(keys.USER__PROFILE__LAST_ACTIVE)"
            :sort-order="sortOrder"
          >
            Last active
          </kog-table-cell-header>
          <kog-table-cell-header>
            <span class="screenreader-only">Actions</span>
          </kog-table-cell-header>
        </kog-table-header>
      </template>
      <template #body>
        <class-teacher-list-item
          v-for="(teacher, index) in teachersInClass"
          :key="teacher.id"
          :index="index"
          :teacher="teacher"
          :remove-teacher="removeTeacher"
        />
      </template>
    </kog-table>
    <kog-teachers-empty-section
      v-else
      :open-add-teacher-modal="openAddTeacherModal"
      :is-school-teachers-loading="isTeacherPageLoading"
    />
  </div>
</template>
<script>
import ClassDetailsAddTeachersModalContainer from 'schoolAdminApp/pages/class-details/class-details-add-teachers-modal-container.vue';
import KogTeachersEmptySection from 'schoolAdminApp/pages/class-details/components/kog-teachers-empty-section.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import sortingKeys from 'sharedApp/const/sorting-keys.js';
import ClassTeacherListItem from 'studyApp/components/teacher/teachers/class-teacher-list-item.vue';
import { SORT_ORDERS } from 'teachApp/utils/assignment-utils.js';

const DefaultOrderingKey = sortingKeys.USER__FULL_NAME;

export default {
  name: 'ClassTeacherList',
  components: {
    ClassTeacherListItem,
    KogTeachersEmptySection,
    KogTable,
    KogTableHeader,
    KogTableCellHeader,
  },
  props: {
    teachers: {
      required: true,
      type: Array,
    },
    currentOrdering: {
      type: String,
      required: true,
    },
    isTeacherPageLoading: {
      type: Boolean,
      required: true,
    },
    removeTeacher: {
      type: Function,
      required: true,
    },
    addTeachers: {
      type: Function,
      required: true,
    },
  },
  emits: ['update-ordering'],
  data() {
    return {
      keys: sortingKeys,
      sortedBy: sortingKeys.USER__FULL_NAME,
      sortOrder: SORT_ORDERS.desc,
    };
  },
  computed: {
    isListShown() {
      return this.teachersInClass && this.teachersInClass.length > 0;
    },
    teachersInClass() {
      return this.teachers.filter(t => t.is_currently_teaching);
    },
  },
  methods: {
    determineSortParams(columnKey) {
      const isNewField = columnKey !== this.sortedBy;
      const isSortedDesc = this.sortOrder === SORT_ORDERS.desc;
      const order = isNewField || isSortedDesc ? SORT_ORDERS.asc : SORT_ORDERS.desc;

      this.sortedBy = columnKey;
      this.sortOrder = order;

      const isFirstTimeSorted = !this.currentOrdering && columnKey === DefaultOrderingKey;
      if (isFirstTimeSorted || this.currentOrdering === columnKey) {
        this.$emit('update-ordering', `-${columnKey}`);
      } else {
        this.$emit('update-ordering', columnKey);
      }
    },
    openAddTeacherModal() {
      this.$modal(ClassDetailsAddTeachersModalContainer, {
        addTeachers: this.addTeachers,
        schoolTeachers: this.teachers,
      });
    },
  },
};
</script>
