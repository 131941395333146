<template>
  <kog-tag
    class="ReflectionQuestionFlaggedCell--padding"
    :is-chip="true"
    :label="`${flaggedCount}`"
    type="warning"
    icon-class="fa-flag"
    :context="tagContext"
    @click="openSummary"
  />
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export default {
  name: 'ReflectionQuestionFlaggedCell',
  components: {
    KogTag,
  },
  props: {
    topic: {
      type: Object,
      required: true,
    },
    flaggedCount: {
      type: Number,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  computed: {
    tagContext() {
      return `${this.flaggedCount} questions flagged`;
    },
  },
  methods: {
    openSummary() {
      this.onClick(this.topic);
    },
  },
};
</script>

<style scoped>
.ReflectionQuestionFlaggedCell--padding {
  padding-left: var(--space-l);
}
</style>
