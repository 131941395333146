<template>
  <kog-modal
    :title="`Choose a ${$term('topic')}`"
    :close-modal="closeModal"
  >
    <template #modalBody>
      <kog-item-list
        v-kog-description:[getUniqueId(`topic-list`)]="'Choose a topic below'"
        role="radiogroup"
        class="list-unstyled panel panel-default"
      >
        <div
          v-for="(topic, topicIndex) in topics"
          :key="topicIndex"
          :class="{
            'divider-bottom': topicIndex !== topics.length - 1,
            'ChooseTopicModal-topic--selected': isTopicSelected(topic),
            'ChooseTopicModal-topic--disabled': isTopicDisabled(topic),
          }"
        >
          <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
          <div
            v-tooltip="{
              theme: 'kog-tooltip',
              content: getTopicTooltip(topic),
              boundary: 'document.body',
            }"
            v-kog-description:[`topic-option-${topicIndex}`]="getTopicTooltip(topic)"
            class="flexContainer flexContainer-alignCenter padd-s margin-0 text-normal"
            :class="{
              cursorPointer: !showCursorNotAllowed(topic),
              cursorNotAllowed: showCursorNotAllowed(topic),
            }"
            role="radio"
            :aria-disabled="isTopicDisabled(topic) ? 'true' : null"
            :aria-checked="selectedTopic === topic ? 'true' : null"
            :tabindex="topicIndex === focusableTopicIndex ? 0 : -1"
            @click="select(topic)"
            @keydown.enter="select(topic)"
          >
            <kog-icon
              class="pull-left margin-right-fine-2"
              :icon-class="getIconClass(topic)"
              :fa-style="getIconStyle(topic)"
              theme="custom"
            />
            <span class="text-normal">
              {{ getFullTopicName(topic) }}
              <content-hidden-label v-if="!isNodeVisible(topic.id)" />
            </span>
          </div>
        </div>
      </kog-item-list>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-button
          label="Cancel"
          @click="closeModal"
        />
        <kog-button
          label="Select"
          button-style="accent"
          :disabled="!selectedTopic"
          @click="confirmSelection"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { VTooltip } from 'floating-vue';
import { mapGetters, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';

export default {
  name: 'ChooseTopicModal',
  components: {
    ContentHiddenLabel,
    KogButton,
    KogModal,
    KogIcon,
    KogItemList,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    onSelection: {
      type: Function,
      required: true,
    },
    availableTopics: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      selectedTopic: null,
      noQuestionsTooltipText: "This topic doesn't have any questions",
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
    }),
    ...mapGetters({
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    topics() {
      return this.subject.subject_tree[0].children;
    },
    focusableTopicIndex() {
      if (this.selectedTopic) {
        return this.topics.findIndex(topic => this.isTopicSelected(topic));
      }
      return this.topics.findIndex(topic => !this.isTopicDisabled(topic));
    },
  },
  methods: {
    getFullTopicName(topic) {
      return `${topic.formatted_number_including_ancestors} ${topic.name}.`;
    },
    getTopicTooltip(topic) {
      return this.isTopicDisabled(topic) ? this.noQuestionsTooltipText : '';
    },
    isTopicDisabled(topic) {
      return !this.availableTopics.includes(topic.id);
    },
    select(topic) {
      if (this.isTopicDisabled(topic) || this.isTopicSelected(topic)) {
        return;
      }
      this.selectedTopic = topic;
    },
    confirmSelection() {
      this.onSelection(this.selectedTopic);
      this.closeModal();
    },
    showCursorNotAllowed(topic) {
      return this.isTopicDisabled(topic);
    },
    isTopicSelected(topic) {
      return topic === this.selectedTopic;
    },
    getIconClass(topic) {
      if (this.isTopicDisabled(topic)) {
        return 'fa-ban';
      }
      if (!this.isTopicSelected(topic)) {
        return 'fa-circle';
      }

      return 'fa-check-circle';
    },
    getIconStyle(topic) {
      if (this.isTopicSelected(topic)) {
        return 'solid';
      }
      return 'regular';
    },
  },
};
</script>

<style scoped>
.ChooseTopicModal-topic--selected {
  background-color: var(--kog-list-item-selected-background);
}

.ChooseTopicModal-topic--disabled {
  background-color: var(--kogPlatformGray094);
}

.ChooseTopicModal-topicRadio {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
</style>
