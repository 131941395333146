<template>
  <div class="flexContainer">
    <kog-button
      :id="mainButtonId"
      class="KogSplitButtons--mainButton"
      :label="mainButtonLabel"
      :button-style="buttonStyle"
      :size="size"
      :is-loading="isLoading"
      :disabled="disabled"
      @click="$emit('main-button-click')"
    />
    <kog-dropdown-button
      class="KogSplitButtons--dropdown"
      button-type="round"
      :dropdown-placement="dropdownPlacement"
      :items="dropdownItems"
      :button-style="buttonStyle"
      :size="size"
      :is-loading="isLoading"
      :disabled="disabled"
      icon-class="fa-caret-down"
      icon-style="solid"
      @select="$emit('dropdown-select', $event)"
    />
  </div>
</template>

<script>
import { intersection } from 'lodash';

import KogButton, {
  BUTTON_SIZES,
  BUTTON_STYLES,
} from 'sharedApp/components/base/buttons/kog-button.vue';
import KogDropdownButton, {
  VALID_BUTTON_STYLES as DROPDOWN_BUTTON_STYLES,
  VALID_SIZES,
} from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';

export const SIZES = intersection(BUTTON_SIZES, VALID_SIZES);

export const STYLES = intersection(BUTTON_STYLES, DROPDOWN_BUTTON_STYLES);

export default {
  name: 'KogSplitButtons',
  components: { KogDropdownButton, KogButton },
  props: {
    mainButtonLabel: {
      type: String,
      required: true,
    },
    dropdownItems: {
      type: Array,
      required: true,
    },
    mainButtonId: {
      type: String,
      default: '',
    },
    dropdownPlacement: {
      type: String,
      default: 'bottom-end',
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return !value || SIZES.includes(value);
      },
    },
    buttonStyle: {
      type: String,
      default: '',
      validator(value) {
        return !value || STYLES.includes(value);
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['main-button-click', 'dropdown-select'],
};
</script>

<style scoped>
.KogSplitButtons--mainButton {
  margin-right: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:deep(.KogSplitButtons--dropdown button) {
  border-radius: 0 4px 4px 0;
}
</style>
