<template>
  <div class="GlossaryPopover">
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-kog-mathjax
      v-html="definitionHtml"
    />
    <!-- eslint-enable vue/no-v-html -->
    <div
      v-if="showOpenDrawer"
      class="text-right"
    >
      <kog-button
        class="GlossaryPopover-openDrawer"
        label="See entire glossary"
        size="small"
        button-style="basic"
        @click="openGlossaryDrawer"
      />
    </div>
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

export default {
  name: 'GlossaryPopover',
  components: { KogButton },
  props: {
    definitionHtml: {
      type: String,
      required: true,
    },
    showOpenDrawer: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['open-glossary-drawer'],
  methods: {
    openGlossaryDrawer() {
      this.$emit('open-glossary-drawer');
    },
  },
};
</script>

<style scoped>
.GlossaryPopover {
  width: 240px;
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  color: var(--kogPlatformGray018);
}

.GlossaryPopover blockquote {
  font-size: var(--kog-font-size-default);
}
</style>
