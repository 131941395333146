<template>
  <div>
    <div
      v-for="(choice, index) in correctChoices"
      :key="choice.clientId"
      class="margin-top-m padd-bottom-s"
    >
      <h2 class="heading-xxs"> Correct choice #{{ index + 1 }} </h2>
      <div class="flexContainer flexContainer-alignCenter">
        <mcq-edit-choice
          v-model:value="choice.answer_html"
          class="flexChild-canGrow"
          :is-correct="true"
        />
        <kog-button
          v-if="!isExistingQuestion"
          class="margin-left-s"
          label="Remove"
          size="small"
          icon-class="fa-times"
          :has-icon="true"
          :disabled="isRemoveCorrectChoiceDisabled"
          @click="removeChoice(choice)"
        />
      </div>
      <div class="margin-top-s"> Add choice explanation (optional) </div>
      <question-editor-plain-text
        v-model:value="choice.answer_explanation_html"
        class="margin-top-xs"
      />
    </div>
    <kog-button
      v-if="!isExistingQuestion"
      class="margin-top-m"
      button-style="primary"
      label="Add correct choice"
      size="small"
      icon-class="fa-plus"
      :has-icon="true"
      :disabled="isAddingChoicesDisabled"
      @click="addChoice(true)"
    />
    <div
      v-for="(choice, index) in incorrectChoices"
      :key="choice.clientId"
      class="margin-top-m padd-bottom-s"
    >
      <h2 class="heading-xxs"> Incorrect choice #{{ index + 1 }} </h2>
      <div class="flexContainer flexContainer-alignCenter">
        <mcq-edit-choice
          v-model:value="choice.answer_html"
          class="flexChild-canGrow"
          :is-correct="false"
        />
        <kog-button
          v-if="!isExistingQuestion"
          class="margin-left-s"
          label="Remove"
          size="small"
          icon-class="fa-times"
          :has-icon="true"
          @click="removeChoice(choice)"
        />
      </div>
    </div>
    <kog-button
      v-if="!isExistingQuestion"
      class="margin-top-m"
      button-style="primary"
      label="Add incorrect choice"
      size="small"
      icon-class="fa-plus"
      :has-icon="true"
      :disabled="isAddingChoicesDisabled"
      @click="addChoice(false)"
    />
    <div class="text-muted text-italic margin-top-xs">
      Response order is randomized when shown to students
    </div>
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

import McqEditChoice from './mcq-edit-choice.vue';
import QuestionEditorPlainText from './question-editor-plain-text.vue';

export default {
  name: 'McqChoicesNew',
  components: {
    KogButton,
    McqEditChoice,
    QuestionEditorPlainText,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    correctChoices() {
      return this.question.context.choices.filter(choice => choice.is_correct);
    },
    incorrectChoices() {
      return this.question.context.choices.filter(choice => !choice.is_correct);
    },
    isAddingChoicesDisabled() {
      return this.question.context.choices.length >= 6;
    },
    isExistingQuestion() {
      return !!this.question.id;
    },
    isRemoveCorrectChoiceDisabled() {
      return this.correctChoices.length <= 1;
    },
  },
  methods: {
    addChoice(isCorrect) {
      const newChoice = {
        answer_html: `${isCorrect ? 'Correct' : 'Incorrect'} choice`,
        answer_explanation_html: '',
        is_correct: isCorrect,
        clientId: window.crypto.randomUUID(),
      };

      // eslint-disable-next-line vue/no-mutating-props
      this.question.context.choices.push(newChoice);
    },
    removeChoice(choice) {
      // eslint-disable-next-line vue/no-mutating-props
      this.question.context.choices.splice(this.question.context.choices.indexOf(choice), 1);
    },
  },
};
</script>
