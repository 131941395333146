<template>
  <div v-if="isButtonVisible">
    <kog-dropdown-button
      v-if="isDropdownButton"
      class="DemoToursButton"
      :items="toursList"
      label="Take a tour"
      size="small"
      button-style="primary"
      @select="onTourSelect"
    />
    <kog-button
      v-if="!isDropdownButton"
      class="DemoToursButton"
      label="Take a tour"
      button-style="primary"
      size="small"
      @click="onSingleTourClick"
    />
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import helpHero from 'sharedApp/libs/helphero.js';

export default {
  name: 'DemoToursButton',
  components: {
    KogButton,
    KogDropdownButton,
  },
  props: {
    subjectId: {
      type: Number,
      required: true,
    },
    toursToSubjectMap: {
      type: Object,
      required: true,
    },
  },
  computed: {
    toursList() {
      const defaultTours = this.toursToSubjectMap[0] || [];
      const subjectTours = this.toursToSubjectMap[this.subjectId] || [];
      const tours = [...defaultTours, ...subjectTours];
      return tours.map(entry => ({
        value: entry.id,
        text: entry.title,
      }));
    },
    isButtonVisible() {
      return this.toursList.length > 0;
    },
    isDropdownButton() {
      return this.toursList.length > 1;
    },
  },
  methods: {
    onTourSelect(item) {
      helpHero.startTour(item.value);
    },
    onSingleTourClick() {
      helpHero.startTour(this.toursList[0].value);
    },
  },
};
</script>

<style>
.DemoToursButton,
.DemoToursButton:hover {
  border-color: var(--kog-text-brand-hard) !important;
}
</style>
