<template>
  <kog-modal title="Remove student from class">
    <template #modalBody>
      <p>
        This action removes <strong>{{ studentName }}</strong> ({{ studentEmail }}) from:<br />
        <em>{{ subjectClassName }}</em> in <em>{{ subjectName }}</em
        >.
      </p>
      <p>
        Removing the student from the class removes their access to this book and to the class'
        assignments.
      </p>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <kog-button
          label="Cancel"
          @click="closeModal"
        />
        <kog-button
          label="Remove student"
          button-style="danger"
          @click="removeThisStudentFromClass"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'ClassRemoveStudentModal',
  components: {
    KogModal,
    KogButton,
  },
  props: {
    removeStudentFromClass: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    studentId: {
      type: Number,
      required: true,
    },
    studentName: {
      type: String,
      required: true,
    },
    studentEmail: {
      type: String,
      required: true,
    },
    subjectClassName: {
      type: String,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
  },
  methods: {
    removeThisStudentFromClass() {
      this.closeModal();
      this.removeStudentFromClass(this.studentId, this.studentName);
    },
  },
};
</script>
