export default function EditorConfigurationPlugin(editor) {
  const editorClassName = 'content-editable';
  const hiddenToolbarClassName = 'hidden-toolbar';

  editor.ui.on('ready', () => {
    // eslint-disable-next-line no-underscore-dangle
    editor.ui.view.body._bodyCollectionContainer.classList.add(editorClassName);
    editor.ui.view.element?.classList.add(editorClassName);
    editor.ui.view.toolbar.element.classList.add(hiddenToolbarClassName);

    const sourceEditingButton = editor.ui.view.toolbar.items.find(
      item => item.class === 'ck-source-editing-button',
    );
    if (sourceEditingButton) {
      sourceEditingButton.withText = false;
    }
  });

  editor.ui.focusTracker.on('change:isFocused', (event, name, isFocused) => {
    if (isFocused) {
      editor.ui.view.toolbar.element.classList.remove(hiddenToolbarClassName);
    } else {
      editor.ui.view.toolbar.element.classList.add(hiddenToolbarClassName);
    }
  });
}
