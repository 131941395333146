<template>
  <div
    class="FeatureText-wrapper"
    :class="{
      'FeatureText-wrapper--grayBackground': isQuizOngoing,
      'FeatureText-wrapper--paddTop': shouldPaddTop,
    }"
  >
    <feature-section-container
      v-if="feature.type === STANDARD_CONTENT_PAGE"
      :is-review-mode="isReviewMode"
    />
    <dqb-section-container
      v-if="feature.type === DRIVING_QUESTION_BOARD"
      :is-review-mode="isReviewMode"
    />
    <activity-sheet-container
      v-if="feature.type === ACTIVITY_SHEET"
      :is-in-review-mode="isReviewMode"
      :feature="feature"
    />
    <quiz-container
      v-if="feature.type === QUIZ"
      v-model:quiz-state="quizState"
      :is-review-mode="isReviewMode"
    />
    <performance-task-container
      v-if="feature.type === PERFORMANCE_TASK"
      :is-in-review-mode="isReviewMode"
      :feature="feature"
    />
    <section-pager v-if="!isQuizOngoing && !isAssignmentFocus" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';
import { mapActions, mapState, useStore } from 'vuex';

import QuizContainer from 'learning/study/pages/features/quiz/quiz-container.vue';

import { FEATURES_ENUM } from 'sharedApp/const/features.js';
import { QUIZ_STATE } from 'sharedApp/const/quiz.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import SectionPager from 'studyApp/components/section-text/section-pager.vue';
import ActivitySheetContainer from 'studyApp/containers/activity-sheet/activity-sheet-container.vue';
import DqbSectionContainer from 'studyApp/containers/dqb-section-container.vue';
import FeatureSectionContainer from 'studyApp/containers/feature-section-container.vue';
import PerformanceTaskContainer from 'studyApp/containers/performance-task/performance-task-container.vue';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';

export default {
  name: 'FeatureText',
  components: {
    SectionPager,
    DqbSectionContainer,
    FeatureSectionContainer,
    ActivitySheetContainer,
    PerformanceTaskContainer,
    QuizContainer,
  },
  mixins: [RoutesMixin, ReadingAssignmentMixin],
  setup() {
    const store = useStore();
    const route = useRoute();
    const { subjectModule } = store.state;

    const subjectName = computed(() => subjectModule.subject?.name);

    const getActivityBySubjectNodeId =
      store.getters['subjectNodeFeatureModule/getActivityBySubjectNodeId'];

    const feature = computed(() => getActivityBySubjectNodeId(route.params.nodeId));

    useHead({
      title: () => `${feature.value?.title} | ${subjectName.value}`,
    });

    return {
      feature,
      getActivityBySubjectNodeId,
    };
  },
  data() {
    return {
      ...FEATURES_ENUM,
      quizState: QUIZ_STATE.NOT_STARTED,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    isReviewMode() {
      return this.$route.meta.isReviewMode;
    },
    isQuizOngoing() {
      return this.feature.type === this.QUIZ && this.quizState !== QUIZ_STATE.NOT_STARTED;
    },
    shouldPaddTop() {
      const controlsOwnPadding = [this.ACTIVITY_SHEET];

      return !controlsOwnPadding.includes(this.feature.type);
    },
  },
  watch: {
    nodeId: {
      handler(newId, oldId) {
        if (oldId && newId !== oldId) {
          this.registerSectionActivity(newId);
        }
      },
    },
  },
  created() {
    this.registerSectionActivity(this.nodeId);
  },
  methods: {
    ...mapActions({
      registerSectionActivity: 'activityModule/registerSectionActivity',
    }),
  },
};
</script>

<style scoped>
.FeatureText-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--kogPlatformWhite);
}

.FeatureText-wrapper--grayBackground {
  background-color: var(--kogPlatformGray093);
}

.FeatureText-wrapper--paddTop {
  padding-top: var(--space-xxl);
}

.kog-container-lg {
  flex: 1 1 auto;
}
</style>
