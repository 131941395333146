<template>
  <div
    v-kog-description:[`${battle.id}`]="
      `Navigate to the
    strength battle with ${battle.opponent_name}`
    "
    class="flexContainer flexContainer-spaceBetween PracticeBattleCard margin-bottom-xxs padd-xs"
    :aria-label="cardAriaLabel"
    @click="tryMoveToBattle($event, battle)"
    @keydown.enter.stop="tryMoveToBattle($event, battle)"
  >
    <div aria-hidden="true">
      <div class="text-bold">
        {{ battle.opponent_name }}
      </div>
      <div class="text-italic">
        {{ battle.subjectnode.name }}
      </div>
      <span
        v-if="battle.is_your_turn"
        class="PracticeBattleCard-takeLink text-bold cursorPointer"
      >
        Take your turn
      </span>
    </div>
    <kog-tag
      aria-hidden="true"
      :label="state.text"
      size="s"
      :type="state.type"
    />
  </div>
</template>

<script>
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogDescription from 'sharedApp/directives/kog-description.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

import BattleMixin from '../mixins/battle-mixin.js';

const equalScore = battle => battle.your_score === battle.opponent_score;
const states = [
  {
    condition: battle => !battle.is_your_turn && !battle.is_done,
    text: 'Their turn',
    statusText: 'Waiting for opponent to take their turn',
    type: 'default',
  },
  {
    condition: battle => !battle.did_you_win && battle.is_done && !equalScore(battle),
    text: 'Lost',
    statusText: 'You lost the strength battle',
    type: 'default',
  },
  {
    condition: battle => battle.is_done && equalScore(battle),
    text: 'Tie',
    statusText: 'The strength battle was a tie',
    type: 'default',
  },
  {
    condition: battle => battle.did_you_win && battle.is_done,
    text: 'Won',
    statusText: 'You won the strength battle',
    type: 'positive',
  },
  {
    condition: battle => battle.is_your_turn && !battle.is_done,
    text: 'Waiting for you',
    statusText: 'Take your turn',
    type: 'negative',
  },
];

export default {
  name: 'PracticeBattleCard',
  directives: {
    KogDescription,
  },
  components: {
    KogTag,
  },
  mixins: [RoutesMixin, BattleMixin],
  props: {
    battle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cardAriaLabel() {
      return `Strength battle against ${this.battle.opponent_name} on topic ${this.battle.subjectnode.name}. ${this.state.statusText}`;
    },
    state() {
      return states.find(state => state.condition(this.battle));
    },
  },
  methods: {
    tryMoveToBattle(event, battle) {
      const displayBattlePage = () => this.navigateToBattleTake({ battleId: battle.id });
      const targetInitiator = () => {
        if (event.target) {
          event.target.focus();
        }
      };

      if (battle.is_your_turn === false) {
        this.navigateToBattleResult({ battleId: battle.id });
        return;
      }

      this.openBattleModal({
        title: battle.subjectnode.name,
        onStart: displayBattlePage,
        onClose: targetInitiator,
      });
    },
  },
};
</script>

<style scoped>
.PracticeBattleCard {
  max-width: 350px;
  border: 1px solid var(--kogPlatformGray084);
  border-radius: 4px;
}

.PracticeBattleCard-takeLink {
  color: var(--kog-link-color);
}

.PracticeBattleCard:hover {
  border-color: var(--kog-link-hover-color);
}

.PracticeBattleCard:hover .PracticeBattleCard-takeLink {
  color: var(--kog-link-hover-color);
  text-decoration: underline;
}
</style>
