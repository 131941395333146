<template>
  <kog-modal
    class="ReadingAssignmentFocusCompletionModal-container"
    :maximize="true"
  >
    <template #modalBody>
      <div class="flexContainer flexContainer-column flexContainer-alignCenter padd-l">
        <integration-assignment-alert
          class="padd-right-xl padd-left-xl margin-top-m margin-bottom-m"
        />
        <kog-font-awesome-icon
          fa-class="fa-circle-check fa-duotone"
          size="custom"
          :custom-size="160"
          class="ReadingAssignmentFocusCompletionModal-icon"
        />
        <h3 class="margin-top-s margin-bottom-xs">Assignment completed</h3>
        <span class="margin-bottom-s">{{ sectionCount }}/{{ sectionCount }} tasks completed</span>
        <div class="margin-top-l width-full">
          <submit-to-integration-button
            v-if="canSubmitIntegrationAssignment"
            class="width-full margin-bottom-xs"
            button-style="accent"
            :assignment="assignment"
            :has-submitted-to-integration="hasSubmittedToIntegration"
          />
          <kog-button
            class="width-full"
            aria-label="Return to overview"
            label="Return to overview"
            :button-style="canSubmitIntegrationAssignment ? 'basic' : 'accent'"
            @click="navigateToClassOverview"
          />
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import IntegrationAssignmentAlert from 'studyApp/components/assignment/integration-assignment-alert.vue';
import SubmitToIntegrationButton from 'studyApp/pages/assignment/components/submit-to-integration-button.vue';

export default {
  name: 'ReadingAssignmentFocusCompletionModal',
  components: {
    SubmitToIntegrationButton,
    IntegrationAssignmentAlert,
    KogFontAwesomeIcon,
    KogModal,
    KogButton,
  },
  mixins: [RoutesMixin],
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState({
      assignment: state => state.assignmentModule.assignment,
    }),
    overviewRoute() {
      return {
        name: 'subjectClassOverview',
        params: {
          classSlug: `${this.subjectClassSlug}`,
          sid: `${this.subjectId}`,
          cid: `${this.classId}`,
        },
      };
    },
    canSubmitIntegrationAssignment() {
      return this.assignment.is_sent_to_integration;
    },
    sectionCount() {
      return this.assignment.sections.length;
    },
    hasSubmittedToIntegration() {
      return this.assignment.is_submitted_to_integration;
    },
  },
  created() {
    this.trackCompletedReadingAssignment();
  },
  methods: {
    navigateToClassOverview() {
      this.$router.push(this.overviewRoute);
      this.closeModal();
    },
    trackCompletedReadingAssignment() {
      this.$mixpanel.trackEventViaBackend('Assignment - Complete', {
        assignment_type: this.assignment.assignment_type,
        num_subject_classes: this.assignment.subject_classes.length,
      });
    },
  },
};
</script>

<style scoped>
.ReadingAssignmentFocusCompletionModal-container {
  width: 464px;
}

.ReadingAssignmentFocusCompletionModal-icon {
  /* stylelint-disable suitcss/custom-property-no-outside-root */
  --fa-primary-color: var(--kog-colors-aubergine-900);
  --fa-secondary-color: var(--kog-colors-green-500);
  --fa-secondary-opacity: 1;

  width: 160px;
  height: 187px;
}
</style>
