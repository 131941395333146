<template>
  <div
    class="flexContainer flexContainer-column flexChild-canGrow padd-top-xxxl kogPlatformWhite-bg"
  >
    <scheduled-assignment-banner
      v-if="assignment && assignment.is_scheduled_to_send"
      :assignment="assignment"
      :integration-provider="integrationProvider"
      @reload-assignment="fetchAssignment(assignment.id)"
    />
    <article
      v-if="assignment"
      class="kog-container-fluid container-wideWidth"
    >
      <h2 class="AssignmentDetailsName heading-m margin-top-m margin-bottom-m">
        {{ htmlEntities(assignment.name) }}
      </h2>
      <div class="flexContainer flexContainer-alignCenter">
        <badge
          v-if="isQuestion"
          text="Questions"
          icon="fa-pencil-alt"
        />
        <badge
          v-else
          :text="`${$term('exam-style')} questions`"
          icon="LTQ"
          :is-svg-icon="true"
        />
        <router-link
          v-if="canEditAssignment"
          class="padd-left-m padd-right-m padd-top-xs padd-bottom-xs margin-right-s"
          :to="assignmentEditUrl"
        >
          Edit assignment
        </router-link>
      </div>
      <integration-assignment-alert class="margin-top-m" />
      <assignment-details-info
        :assignment="assignment"
        :end-assignment-now="showEndAssignmentNowModal"
      />
      <div class="margin-top-xxl flexContainer KogButtonSet AssignmentDetails-buttonSet">
        <kog-button
          v-if="isQuestion"
          :tooltip="isOngoing ? 'Assignment results are available after deadline.' : ''"
          label="Download PDF results"
          :has-icon="true"
          :disabled="isOngoing"
          button-style="basic"
          icon-class="far fa-download"
          @click="downloadPdfFile"
        />
        <kog-button
          v-if="isQuestion"
          :tooltip="isOngoing ? 'Assignment results are available after deadline.' : ''"
          label="Download CSV results"
          :has-icon="true"
          :disabled="isOngoing"
          button-style="basic"
          icon-class="far fa-download"
          @click="downloadCsvFile"
        />
        <unlock-markscheme-button
          v-if="isExamStyle"
          :assignment="assignment"
          @unlock-markscheme="unlockAssignmentMarkscheme"
        />
        <kog-button
          :tooltip="copyAssignmentTooltip"
          label="Duplicate assignment"
          :has-icon="true"
          button-style="basic"
          icon-class="far fa-copy"
          @click="copy()"
        />
        <copy-shareable-assignment-link
          :assignment-id="assignment.id"
          @copy="onCopyShareableLink"
        />
        <kog-dropdown-button
          v-if="isExamStyle"
          :items="printOptions"
          :has-icon="true"
          icon-class="fa-print"
          label="Print"
          button-style="basic"
          size="medium"
          dropdown-placement="bottom-start"
          :full-width="true"
          @select="onPrintItemSelect"
        />
        <integration-assignment-link
          v-if="assignment.is_sent_to_integration"
          :integration-provider="integrationProvider"
        />
        <div
          v-show="false"
          ref="esqprinthelper"
          v-kog-mathjax
          aria-hidden="true"
        >
        </div>
      </div>
    </article>
    <template v-if="assignment && user">
      <question-assignment-details-tabs
        v-if="isQuestion"
        :assignment="assignment"
        :show-difficulty="showDifficulty"
      />
      <esq-assignment-details-tabs
        v-else-if="isExamStyle"
        :assignment="assignment"
        :subject="subject"
      />
    </template>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { mapState } from 'vuex';

import * as schoolStaffApi from '@apis/schoolstaff-assignments.js';

import htmlEntities from 'learning/common/libs/html-entities.js';
import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import {
  getAssignmentDetailsTrackingProps,
  isExamStyleType,
  isQuestionType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { shouldShowDifficultyTagForSubject } from 'sharedApp/services/featureflags/fixed-feature-flags.ts';
import printHtml from 'sharedApp/utils/print-html.js';
import IntegrationAssignmentAlert from 'studyApp/components/assignment/integration-assignment-alert.vue';
import IntegrationAssignmentLink from 'studyApp/components/assignment/integration-assignment-link.vue';
import CopyAssignmentModal from 'studyApp/components/teacher/assignments/copy-assignment-modal.vue';
import AssignmentDetailsInfo from 'studyApp/components/teacher/assignments/details/assignment-details-info.vue';
import CopyShareableAssignmentLink from 'studyApp/components/teacher/assignments/details/copy-shareable-assignment-link.vue';
import EsqAssignmentDetailsTabs from 'studyApp/components/teacher/assignments/details/esq-assignment-details-tabs.vue';
import QuestionAssignmentDetailsTabs from 'studyApp/components/teacher/assignments/details/question-assignment-details-tabs.vue';
import UnlockMarkschemeButton from 'studyApp/components/teacher/assignments/details/unlock-markscheme-button.vue';
import EndNowModal from 'studyApp/components/teacher/assignments/end-now-modal.vue';
import ScheduledAssignmentBanner from 'studyApp/components/teacher/assignments/scheduled-assignment-banner.vue';
import Badge from 'studyApp/components/teacher/badge.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';

import { assembleEsqForPrinting, esqPrintCss } from './esq-printing-utilities.js';

const PRINT_TYPES = {
  with_answers: 'With answers',
  without_answers: 'Without answers',
};

export default {
  name: 'AssignmentDetailsContainer',
  components: {
    IntegrationAssignmentAlert,
    AssignmentDetailsInfo,
    QuestionAssignmentDetailsTabs,
    EsqAssignmentDetailsTabs,
    ScheduledAssignmentBanner,
    KogButton,
    Badge,
    CopyShareableAssignmentLink,
    UnlockMarkschemeButton,
    KogDropdownButton,
    IntegrationAssignmentLink,
  },
  mixins: [RoutesMixin],
  setup() {
    const assignmentStore = useAssignmentStore();
    const { assignment } = storeToRefs(assignmentStore);
    const { fetchAssignment, saveAssignment } = assignmentStore;
    const { loadIntegrationAssignment, integrationProvider } = useIntegrationAssignment();
    return {
      assignment,
      loadIntegrationAssignment,
      fetchAssignmentDetails: fetchAssignment,
      saveAssignment,
      integrationProvider,
    };
  },
  data() {
    return {
      showDifficulty: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
    }),
    printOptions() {
      const assignmentTypes = [
        { text: 'With answers', value: PRINT_TYPES.with_answers },
        { text: 'Without answers', value: PRINT_TYPES.without_answers },
      ];
      return assignmentTypes;
    },
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    source() {
      return this.$route.query.source || '';
    },
    subjectSlug() {
      return this.subject.slug;
    },
    assignmentType() {
      return this.assignment?.assignment_type;
    },
    isQuestion() {
      return isQuestionType(this.assignmentType);
    },
    isExamStyle() {
      return isExamStyleType(this.assignmentType);
    },
    isOngoing() {
      if (this.assignment) {
        return new Date(this.assignment.deadline) > new Date();
      }
      return true;
    },
    copyAssignmentTooltip() {
      return 'Send these questions again (or to another class)';
    },
    assignmentEditUrl() {
      const { fullPath } = this.$router.resolve({
        name: 'teacherAssignmentEdit',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignmentId,
        },
      });
      return fullPath;
    },
    canEditAssignment() {
      return this.assignment.is_scheduled_to_send || (this.assignment.sent_at && this.isOngoing);
    },
  },
  created() {
    this.trackAssignmentStart();
    this.loadIntegrationAssignment(this.assignment, this.classId);
    this.showDifficulty = shouldShowDifficultyTagForSubject(this.subjectId);
  },
  methods: {
    onPrintItemSelect(item) {
      this.print(item.value === PRINT_TYPES.with_answers);
    },
    trackAssignmentStart() {
      this.trackAssignment('Assignment - Open');
      this.$event.track(
        'load_assignment_details',
        {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          assignment_id: this.assignmentId,
        },
        this.user,
      );
    },
    async fetchAssignment() {
      await this.fetchAssignmentDetails(this.assignment.id);
    },
    downloadPdfFile() {
      this.trackAssignmentDownloadedEvent('pdf');
      window.location.href = `${this.assignment.report_url}?filetype=pdf`;
    },
    downloadCsvFile() {
      this.trackAssignmentDownloadedEvent('csv');
      window.location.href = `${this.assignment.report_url}?filetype=csv`;
    },
    trackAssignmentDownloadedEvent(fileType) {
      const eventProperties = {
        source: 'Assignment Details',
        assignment_type: this.assignmentType.toLowerCase(),
      };
      this.$mixpanel.trackEvent(
        `Assignments - Download ${fileType.toUpperCase()} report`,
        eventProperties,
      );
      intercomModule.trackEvent(`downloaded-${fileType.toLowerCase()}-report`);
    },
    async unlockAssignmentMarkscheme() {
      await schoolStaffApi.unlockAssignmentMarkscheme(this.assignmentId);
      this.fetchAssignmentDetails(this.assignmentId);
    },
    showEndAssignmentNowModal() {
      this.$modal(EndNowModal, {
        assignment: this.assignment,
        afterEndAssignment: this.fetchAssignment,
        source: 'Details',
      });
    },
    copy() {
      this.$modal(CopyAssignmentModal, {
        assignment: this.assignment,
        subjectSlug: this.subjectSlug,
        source: 'teacher_assignment_details',
      });
    },
    async print(withAnswers) {
      const title = this.assignment.name;
      const printedHtml = assembleEsqForPrinting(this.assignment, withAnswers);
      this.$refs.esqprinthelper.innerHTML = printedHtml;
      await this.$forceUpdate();
      await new Promise(r => {
        setTimeout(r, 1000);
      });
      await nextTick();
      printHtml(this.$refs.esqprinthelper.innerHTML, title, esqPrintCss);
    },
    onCopyShareableLink() {
      let assignmentType = '';
      let source = '';

      if (this.isQuestion) {
        assignmentType = 'QUESTION';
        source = 'Question assignment details';
      }
      if (this.isExamStyle) {
        assignmentType = 'EXAM_STYLE';
        source = 'ESQ assignment details';
      }

      this.$mixpanel.trackEvent('Assignment - Copied shareable link', {
        assignment_type: assignmentType,
        num_subject_classes: this.assignment.subject_classes.length,
        source,
      });
    },
    getStudentsCompletedCount() {
      const { assignment, isQuestion, isExamStyle } = this;
      let numberOfStudentsCompleted = 0;

      if (isQuestion) {
        numberOfStudentsCompleted = assignment.practice_occasions.filter(o => o.done_at).length;
      } else if (isExamStyle) {
        numberOfStudentsCompleted = assignment.esq_occasion.filter(
          o => o.status === 'COMPLETED',
        ).length;
      }

      return numberOfStudentsCompleted;
    },
    trackAssignment(eventName) {
      const { assignment, getStudentsCompletedCount, source, $mixpanel } = this;
      const numberOfStudentsCompleted = getStudentsCompletedCount();
      const eventProperties = getAssignmentDetailsTrackingProps(
        assignment,
        assignment.students.length,
        numberOfStudentsCompleted,
        source,
      );
      $mixpanel.trackEvent(eventName, eventProperties);
    },
    htmlEntities,
  },
};
</script>

<style scoped>
.AssignmentDetails-buttonSet {
  flex-wrap: wrap;
}
</style>
