<template>
  <div class="kog-container">
    <div class="kog-row">
      <div class="kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
        <slot name="header" />
      </div>
      <section class="kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
        <kog-card :default-padding="false">
          <div
            :class="[
              'padd-top-l',
              'padd-left-l',
              'padd-right-l',
              'padd-bottom-l',
              'flexContainer',
              'flexContainer-column',
            ]"
          >
            <slot name="card-header" />
            <kog-loader
              :loading="loading"
              :loading-msg="loadingMsg"
            />
            <slot v-if="!loading" />
          </div>
        </kog-card>
      </section>
    </div>
  </div>
</template>

<script>
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';

export default {
  name: 'BattlePageContainer',
  components: {
    KogCard,
    KogLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMsg: {
      type: String,
      default: 'Loading...',
    },
  },
};
</script>
