<template>
  <div>
    <div>User profile</div>
    <div> First name: {{ user.first_name }} </div>
    <div> Last name: {{ user.last_name }} </div>
    <div> Timezone: {{ user.profile.timezone }} </div>
    <div> Email: {{ user.email }} </div>
  </div>
</template>
<script>
export default {
  name: 'UserInfo',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
