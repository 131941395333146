<template>
  <td class="padd-left-s padd-right-s text-center">
    <kog-icon
      v-tooltip="{
        theme: 'kog-tooltip',
        content: tooltipText,
        boundary: 'window',
        autoHide: false,
      }"
      :icon-class="iconClass"
      :fa-style="faStyle"
      :label="tooltipText"
      :size="size"
      :theme="theme"
      :is-disabled="isDisabled"
      :is-non-actionable="isNonActionable"
      :is-svg-icon="isSvgIcon"
    />
  </td>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import { VTooltip } from 'floating-vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'KogTableCellIcon',
  directives: {
    tooltip: VTooltip,
  },
  components: {
    KogIcon,
  },
  props: {
    /**
     * Sets the class of the icon.
     * Use any type of font awesome icon class.
     */
    iconClass: {
      type: String,
      required: true,
    },
    /**
     * Sets the fa-style of the icon.
     * Can be `solid`, `regular` or `light`
     */
    faStyle: {
      type: String,
      default: 'solid',
      validator: prop => ['solid', 'regular', 'light'].includes(prop),
    },
    /**
     * Sets the size of the icon.
     * Can be `m` or `s-touch`
     * */
    size: {
      type: String,
      default: 's-touch',
      validator: prop => ['m', 's-touch'].includes(prop),
    },
    /**
     * Sets the theme of the icon.
     * Can be `dark`, `light` or `custom`
     * */
    theme: {
      type: String,
      default: 'dark',
      validator: prop => ['dark', 'light', 'custom'].includes(prop),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, the icon is not interactable
     */
    isNonActionable: {
      type: Boolean,
      default: false,
    },
    isSvgIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Specifies content of optional tooltip that shows on icon hover.
     * No tooltip shown if this property is not provided.
     */
    tooltipText: {
      type: String,
      default: '',
    },
  },
};
</script>
