<template>
  <div>
    <h2
      v-if="hasTitle"
      class="heading-m margin-bottom-l"
    >
      {{ content.title }}
    </h2>
    <template v-if="shouldRenderQuestionContainer">
      <question-container
        v-if="content.question"
        :display-mode="displayMode"
        :question="content.question"
        :disabled="isDisabled"
        @response="handleUserResponse"
      />
    </template>
    <template v-else>
      <component
        v-bind="propsToPass"
        :is="questionComponent"
        @response="handleUserResponse"
        @rubric-selected="$emit('rubric-selected', { ...$event, responseId: userResponseId })"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import QuestionContainer from 'sharedApp/components/question-container/question-container.vue';
import { QUESTION_DISPLAY_MODE } from 'sharedApp/composables/question-context/index.ts';
import { FEATURES_ENUM } from 'sharedApp/const/features.js';
import { QUESTION_TYPES } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import ActivityFbq from 'studyApp/components/activities/questions/activity-fbq.vue';
import ActivityLtq from 'studyApp/components/activities/questions/activity-ltq.vue';
import ActivityMcq from 'studyApp/components/activities/questions/activity-mcq.vue';
import ActivityStq from 'studyApp/components/activities/questions/activity-stq.vue';
import QuestionPreview from 'studyApp/components/questions/question-preview.vue';
import { QUESTION_ACTIVITY_MODULE } from 'studyApp/store/modules/question-activity.js';

export default {
  name: 'ActivityQuestion',
  components: {
    QuestionContainer,
  },

  props: {
    content: {
      type: Object,
      required: true,
    },
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
    isShowingUserResponse: {
      type: Boolean,
      default: false,
    },
    isShowingKey: {
      type: Boolean,
      default: false,
    },
    isShowingKeyToggle: {
      type: Boolean,
      default: false,
    },
    isShowingCorrectAnswersInKey: {
      type: Boolean,
      default: false,
    },
    isShowingAlignments: {
      type: Boolean,
      default: false,
    },
    isShowingRubrics: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRubricsInteractive: {
      type: Boolean,
      default: false,
    },
    isShowingRubricsToggle: {
      type: Boolean,
      default: false,
    },
    isShowingResponseCorrectness: {
      type: Boolean,
      default: false,
    },
    userResponseData: {
      type: Object,
      default: null,
    },
    featureType: {
      type: String,
      default: '',
    },
  },
  emits: ['user-response-change', 'rubric-selected'],
  computed: {
    ...mapGetters(QUESTION_ACTIVITY_MODULE, ['getQuestionById']),
    questionComponent() {
      if (!this.contentQuestion) {
        return null;
      }

      if (this.isInReviewMode) {
        return QuestionPreview;
      }

      switch (this.contentQuestion.type) {
        case QUESTION_TYPES.STQ: {
          return ActivityStq;
        }
        case QUESTION_TYPES.MCQ: {
          return ActivityMcq;
        }
        case QUESTION_TYPES.FBQ: {
          return ActivityFbq;
        }
        case QUESTION_TYPES.LTQ: {
          return ActivityLtq;
        }
        default:
          return null;
      }
    },
    displayMode() {
      if (this.isInReviewMode) {
        return this.isShowingUserResponse
          ? QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED_WITH_CORRECTNESS
          : QUESTION_DISPLAY_MODE.CORRECT_RESPONSE;
      }

      return this.isDisabled
        ? QUESTION_DISPLAY_MODE.USER_RESPONSE_LOCKED
        : QUESTION_DISPLAY_MODE.USER_RESPONSE;
    },

    propsToPass() {
      const alwaysPass = {
        question: this.contentQuestion,
      };
      if (this.isInReviewMode) {
        const questionPreviewProps = {
          ...alwaysPass,
          isShowingExplanation: false,
          isShowingKey: this.isShowingKey,
          isShowingKeyToggle: this.isShowingKeyToggle,
          isShowingAlignments: this.isShowingAlignments,
          isShowingRubrics: this.isShowingRubrics,
          isRubricsInteractive: this.isRubricsInteractive,
          isShowingRubricsToggle: this.isShowingRubricsToggle,
          isShowingCorrectAnswersInKey: this.isShowingCorrectAnswersInKey,
          isShowingUserResponse: this.isShowingUserResponse,
          userResponseData: this.isShowingUserResponse ? this.userResponseData : null,
        };

        return questionPreviewProps;
      }

      const questionProps = {
        ...alwaysPass,
        context: {
          user_response: this.userResponseData?.user_response,
        },
        disabled: this.isDisabled,
      };

      if (!this.isShowingResponseCorrectness) {
        return questionProps;
      }

      questionProps.context.user_response_correctness =
        this.userResponseData?.user_response_correctness;
      return questionProps;
    },
    contentQuestion() {
      return this.content.question || this.getQuestionById(this.content.question_id);
    },
    hasTitle() {
      return this.content.title && this.content.title !== '';
    },
    isAts() {
      return this.featureType === FEATURES_ENUM.ACTIVITY_SHEET;
    },
    shouldRenderQuestionContainer() {
      return this.isAts && !this.isInReviewMode;
    },
    userResponseId() {
      return this.userResponseData?.id || null;
    },
  },
  methods: {
    handleUserResponse(response) {
      this.$emit('user-response-change', { response, questionId: this.contentQuestion.id });
    },
  },
};
</script>
