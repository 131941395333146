<template>
  <generic-warning-box>
    <b>
      This class is archived in Google Classroom, and Kognity won't be able to send assignments to
      it.
    </b>
    <p class="margin-top-s">
      Disconnect from this class to remove it from Kognity, or unarchive it in Google Classroom
      before updating it again in Kognity.
    </p>
  </generic-warning-box>
</template>

<script>
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';

export default {
  name: 'GoogleClassroomClassArchived',
  components: {
    GenericWarningBox,
  },
};
</script>
