<template>
  <mark
    :id="`KogHighlight-${annotation.annotation.id}`"
    ref="root"
    :class="['KogHighlight', 'cursorPointer', getColorClass()]"
    :aria-label="`${annotation.annotation.color} highlighted text`"
    tabindex="0"
    @keyup.enter.stop="select"
    @click="select"
  >
    <slot />
  </mark>
</template>
<script>
import { getHighlightClass } from 'learning/common/libs/annotations-utils.js';

export default {
  name: 'KogHighlight',
  props: {
    context: {
      type: Object,
      default: null,
    },
    selectHandler: {
      type: Function,
      required: true,
    },
  },
  computed: {
    annotation() {
      return this.context.customObject;
    },
    annotationIsFocused() {
      return this.annotation && this.annotation.focused;
    },
    annotationHasId() {
      return !!this.annotation.annotation.id;
    },
    annotationNoteConfirmed() {
      return !this.annotation.annotation.id && this.annotation.confirmed;
    },
    annotationIsActive() {
      return this.annotationIsFocused && (this.annotationHasId || this.annotationNoteConfirmed);
    },
  },
  methods: {
    select(event) {
      this.selectHandler(event, this.context, this);
    },
    getColorClass() {
      const { annotation } = this.annotation;
      return getHighlightClass(annotation.color, this.annotationIsActive);
    },
  },
};
</script>
<style scoped>
.KogHighlight {
  position: relative;
  padding: 0;
}
</style>
