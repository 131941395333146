<template>
  <button
    ref="root"
    v-kog-description:[getUniqueId(`kog-toggleable-button`)]="description || tooltip"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltip,
      popperClass: 'text-center',
      boundary: 'document.body',
    }"
    class="KogToggleableButton inline-block"
    :class="{
      'KogToggleableButton-active': isActive,
      'KogToggleableButton-iconTop': hasIcon && iconPosition === 'top',
    }"
    :disabled="disabled"
    :aria-disabled="disabled"
    :aria-label="ariaLabel || label"
    @click="handleClick"
  >
    <template v-if="hasIcon && iconPosition === 'top'">
      <div class="flexContainer flexContainer-column flexContainer-alignCenter">
        <kog-icon
          :icon-class="iconClass"
          theme="custom"
          fa-style="regular"
          class="margin-bottom-xs"
        />
        <span>{{ label }}</span>
      </div>
    </template>
    <template v-else>
      <div class="flexContainer flexContainer-alignCenter">
        <kog-icon
          v-if="hasIcon && iconPosition === 'left'"
          :icon-class="iconClass"
          theme="custom"
          fa-style="regular"
          class="margin-right-xs"
        />
        <span>{{ label }}</span>
        <kog-icon
          v-if="hasIcon && iconPosition === 'right'"
          :icon-class="iconClass"
          theme="custom"
          fa-style="regular"
          class="margin-left-xs"
        />
      </div>
    </template>
  </button>
</template>

<script setup lang="ts">
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';

const { getUniqueId } = useUniqueId();

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const props = defineProps({
  /**
   * Optional message to be read out by assistive screen reader technologies.
   */
  ariaLabel: {
    type: String,
    default: '',
  },
  /**
   * Controls if button is clickable or not.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Controls if an icon should be shown.
   */
  hasIcon: {
    type: Boolean,
    default: false,
  },
  /**
   * font-awesome icon class
   */
  iconClass: {
    type: String,
    default: '',
  },
  /**
   * Controls the placement of the icon in relation to the label.
   */
  iconPosition: {
    type: String,
    default: 'left',
    validator: (prop: string) => ['left', 'right', 'top'].includes(prop),
  },
  /**
   * The text that should be displayed in the button.
   */
  label: {
    type: String,
    required: true,
  },
  /**
   * Optional text to be shown in tooltip on button hover.<br>
   * Tooltip is centered above button.
   */
  tooltip: {
    type: String,
    default: '',
  },
  /**
   * Prop to add extra description to the button for users
   *  using screen readers.
   */
  description: {
    type: String,
    default: '',
  },
  /**
   * Controls if button is active or not.
   */
  isActive: {
    type: Boolean,
    default: false,
  },
});

function handleClick() {
  if (!props.disabled) {
    emit('click');
  }
}
</script>

<style scoped>
.KogToggleableButton {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 40px;
  padding: var(--space-xs) var(--space-m);

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  font-weight: 500;
  line-height: 20px;
  color: var(--kog-text-brand);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  background: var(--kogTransparent);
  border: 1px solid transparent;
  border-radius: var(--space-s);

  transition:
    background 0.1s ease,
    border-color 0.1s ease;
}

.KogToggleableButton-active {
  background: var(--kog-background-brand-300);
  border: 1px solid var(--kog-border-brand-500);
}

.KogToggleableButton-iconTop {
  height: 72px;
}

.KogToggleableButton[aria-disabled='true'] {
  cursor: not-allowed;
  color: var(--kog-button-default-label-disabled);
  background: var(--kog-button-default-background-disabled);
  border-color: var(--kog-button-default-border-disabled);
}

.KogToggleableButton:hover:not([aria-disabled='true']) {
  background: var(--kog-background-brand-100);
}

.KogToggleableButton-active:hover:not([aria-disabled='true']) {
  background: var(--kog-background-brand-200);
}
</style>
