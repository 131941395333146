// eslint-disable-next-line max-classes-per-file
export const INTEGRATIONS = {
  EDLINK: 'Edlink',
};

export const INTEGRATION_PROVIDERS = {
  CANVAS: 'Canvas',
  SCHOOLOGY: 'Schoology',
  GOOGLE: 'Google',
  MICROSOFT: 'Microsoft',
};

export class NotSentToIntegrationError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'NotSentToIntegrationError';
  }
}

export const NO_ACCESS_TOKEN_ERROR_MESSAGE = 'No access token is set';
export class NoAccessTokenError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'NoAccessTokenError';
  }
}

export const ASSIGNMENT_NOT_FOUND_ERROR_MESSAGE = 'Assignment not found in Edlink';
export class AssignmentNotFoundError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'AssignmentNotFoundError';
  }
}

export class AssignmentNotValidError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'AssignmentNotValidError';
  }
}
