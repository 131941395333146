<template>
  <kog-split-buttons
    class="SectionHeaderAssignmentButton-splitButton"
    :main-button-label="operation"
    button-style="accent"
    :is-loading="isSavingAssignment"
    :dropdown-items="assignmentTypeItems"
    @main-button-click="toggleNodeAssigned"
    @dropdown-select="onAssignmentTypeItemSelect"
  />
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapGetters, mapState } from 'vuex';

import { createAssignment as createAssignmentWithApi } from '@apis/schoolstaff-assignments.js';

import SelectClassModal from 'learning/common/components/assignment/select-class-modal.vue';
import useAssignmentStore from 'learning/common/store-modules/assignment.ts';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogSplitButtons from 'sharedApp/components/base/buttons/kog-split-buttons.vue';
import { BASKET_MIXPANEL_EVENTS } from 'sharedApp/const/assignment-basket.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import { isIGCSE, isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);
export default {
  name: 'SectionHeaderAssignmentButton',
  components: {
    KogSplitButtons,
  },
  mixins: [RoutesMixin],
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
    subjectClasses: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const assignmentStore = useAssignmentStore();
    const { assignment, isAssignmentBasketOpen } = storeToRefs(assignmentStore);
    const { createAssignment, addSection, removeSection } = assignmentStore;

    const featureFlagStore = useFeatureFlagStore();
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);

    return {
      assignment,
      isAssignmentBasketOpen,
      createAssignment,
      addSection,
      removeSection,
      isMultipleClassAssignmentEnabled,
    };
  },
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
    }),
    hasOngoingDraftAssignment() {
      return !!this.assignment?.id;
    },
    isSavingAssignment() {
      return this.$wait.is(this.addingNodeLoader) || this.$wait.is(this.removingNodeLoader);
    },
    educationSystemName() {
      return this.subject.education_system_name || this.subject.educationsystem.name;
    },
    isIGCSE() {
      return isIGCSE(this.educationSystemName);
    },
    isShowEsq() {
      return !isNgss(this.educationSystemName);
    },
    subjectNode() {
      return this.subjectNodesById[this.subjectNodeId];
    },
    isNodeAssignedInAssignment() {
      return (
        this.hasOngoingDraftAssignment &&
        this.assignment.sections.length > 0 &&
        this.assignment.sections.some(node => node.subject_node_id === this.subjectNodeId)
      );
    },
    operation() {
      return this.isNodeAssignedInAssignment ? 'Unassign' : 'Assign';
    },
    isNodeHidden() {
      if (this.subjectNodeId) return !this.isNodeVisible(this.subjectNodeId);
      return false;
    },
    assignmentTypeItems() {
      const assignmentTypes = [
        {
          iconClass: 'fa-pencil-alt',
          text: 'Question assignment',
          value: ASSIGNMENT_TYPES.QUESTION,
          disabled: this.isNodeHidden,
          tooltip: this.isNodeHidden
            ? "You can't send question assignment from hidden content"
            : '',
        },
      ];
      if (this.isShowEsq) {
        assignmentTypes.push({
          iconClass: 'LTQ',
          isSvgIcon: true,
          text: `${this.isIGCSE ? 'Test practice' : 'Exam-style'} assignment`,
          value: ASSIGNMENT_TYPES.EXAMSTYLE,
        });
      }
      return assignmentTypes;
    },
    contentType() {
      if (this.getActivityBySubjectNodeId(this.subjectNodeId)) {
        return this.getActivityBySubjectNodeId(this.subjectNodeId).type;
      }
      return 'Section';
    },
    addingNodeLoader() {
      return `assignment_basket/creating_draft_assignment/${this.subjectNodeId}`;
    },
    removingNodeLoader() {
      return `assignment_basket/removing_section/${this.subjectNodeId}`;
    },
    mixpanelTrackingProps() {
      return {
        subject_node_id: this.subjectNodeId,
        subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNode.name}`,
        source: this.$route.name === 'subjectClassOverview' ? 'overview' : 'book',
        content_type: this.contentType,
      };
    },
  },
  methods: {
    async toggleNodeAssigned() {
      if (!this.hasOngoingDraftAssignment) {
        this.$wait.start(this.addingNodeLoader);
        await this.createAssignment(
          this.user,
          this.classId,
          ASSIGNMENT_TYPES.READING,
          [this.subjectNodeId],
          studyAppLocalStorage.getSendAssignmentToEdlink(),
          this.isMultipleClassAssignmentEnabled,
        );
        this.$wait.end(this.addingNodeLoader);
        this.$mixpanel.trackEvent(BASKET_MIXPANEL_EVENTS.CREATE_START, this.mixpanelTrackingProps);
        this.$mixpanel.trackEvent(BASKET_MIXPANEL_EVENTS.ADD_ITEM, this.mixpanelTrackingProps);
        this.isAssignmentBasketOpen = true;
      } else {
        const loader = this.isNodeAssignedInAssignment
          ? this.removingNodeLoader
          : this.addingNodeLoader;
        this.$wait.start(loader);
        if (this.isNodeAssignedInAssignment) {
          this.removeSection(this.subjectNodeId);
        } else {
          this.addSection(this.subjectNode, this.assignment.sections.length);
        }
        this.$wait.end(loader);
        const eventName = this.isNodeAssignedInAssignment
          ? BASKET_MIXPANEL_EVENTS.DELETE_ITEM
          : BASKET_MIXPANEL_EVENTS.ADD_ITEM;
        this.$mixpanel.trackEvent(eventName, this.mixpanelTrackingProps);
      }
    },
    onAssignmentTypeItemSelect(item) {
      this.$mixpanel.trackEvent('Assignment - Create start', {
        subject_id: this.subject.id,
        subject_name: this.subject.name,
        assignment_type: item.value.toLowerCase(),
        num_subject_classes: this.subjectClasses.length,
      });
      this.createAssignmentWithSelectClassModal(item.value);
    },
    createAssignmentWithSelectClassModal(assignmentType) {
      if (this.subjectClasses.length === 1) {
        this.goToAssignment(assignmentType, this.subjectClasses[0]);
      } else {
        this.$modal(SelectClassModal, {
          subjectClasses: this.subjectClasses,
          actions: this.assignmentActions(assignmentType),
        });
      }
    },
    assignmentActions(assignmentType) {
      return this.subjectClasses.reduce((acc, subjectClass) => {
        acc[subjectClass.id] = () => {
          this.goToAssignment(assignmentType, subjectClass);
        };
        return acc;
      }, {});
    },
    async goToAssignment(assignmentType, subjectClass) {
      const params = {
        assignmentType,
        subjectClassId: subjectClass.id,
        user: this.user,
        isMultiClass: this.isMultipleClassAssignmentEnabled,
      };
      const assignment = await createAssignmentWithApi(params);
      const sectionNodeId = this.subjectNodeId;
      const assignmentCreateLocation = {
        name: 'teacherAssignmentCreate',
        params: {
          classSlug: subjectClass.slug,
          sid: this.subject.id,
          cid: subjectClass.id,
          assignmentId: assignment.id,
        },
        query: {
          just_created: true,
          source: 'textbook_section_header',
          node_id: this.nodeId,
          node_slug: this.nodeSlug,
          ...(sectionNodeId && { section_node: sectionNodeId }),
        },
      };
      this.$router.push(assignmentCreateLocation);
    },
  },
};
</script>

<style scoped>
.SectionHeaderAssignmentButton-splitButton {
  min-width: 74px;
}
</style>
