import { Command } from '@ckeditor/ckeditor5-core';

export default class InsertOpenEndedTaskCommand extends Command {
  execute(questionId) {
    return this.editor.model.change(async writer => {
      const { config, model } = this.editor;
      const callbacks = config.get('openEndedTask.callbacks');
      const { createOpenEndedTask } = callbacks;

      const task = await createOpenEndedTask(questionId);
      model.insertObject(writer.createElement('openEndedTask', { taskId: task.id }));
    });
  }

  getOpenEndedTask() {
    const { document } = this.editor.model;
    const firstRange = document.selection.getFirstRange();
    if (!firstRange.isCollapsed && firstRange.start.nodeAfter?.name === 'openEndedTask') {
      const node = firstRange.start.nodeAfter;
      const taskId = node.getAttribute('taskId');
      const value = {
        task: this.editor.config.get('openEndedTask.callbacks')?.getOpenEndedTaskById(taskId),
      };
      return value;
    }
    return null;
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const selectionFirstPosition = document.selection.getFirstPosition();
    const allowedIn = schema.findAllowedParent(selectionFirstPosition, 'openEndedTask');
    this.isEnabled = allowedIn !== null;
    this.value = this.getOpenEndedTask();
  }
}
