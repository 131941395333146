export const STQ_TYPE_OPTIONS = Object.freeze({
  NORMAL: 'normal',
  SOLUTION: 'solution',
} as const);

export const STQ_DISPLAY_STATE = Object.freeze({
  ALL: 'all',
  QUESTION: 'question',
  INPUT: 'input',
} as const);
