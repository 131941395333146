<template>
  <div>
    <div>
      <div
        v-for="classId in classesWithSelectedStudents"
        :key="classId"
        class="margin-bottom-xs"
      >
        Send to <span class="text-bold">{{ getClassSendoutInformation(classId) }}</span> in
        {{ getClassName(classId) }}
      </div>
    </div>
    <assignment-sendout-google-classes
      v-if="googleClasses.length > 0"
      class="margin-top-s"
      :google-classes="googleClasses"
      :google-classroom-recipients="googleClassroomRecipients"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AssignmentSendoutGoogleClasses from './assignment-sendout-google-classes.vue';

export default {
  name: 'AssignmentSendoutRecipients',
  components: {
    AssignmentSendoutGoogleClasses,
  },
  props: {
    googleClasses: {
      type: Array,
      required: true,
    },
    subjectClassIds: {
      type: Array,
      required: true,
    },
    studentListByClassId: {
      type: Object,
      required: true,
    },
    selectedStudentIds: {
      type: Array,
      required: true,
    },
    googleClassroomRecipients: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    selectedStudentsByClass() {
      return Object.entries(this.studentListByClassId).reduce((acc, [classId, students]) => {
        acc[classId] = students.filter(student =>
          this.selectedStudentIds.includes(student.school_student),
        );
        return acc;
      }, {});
    },
    classesWithSelectedStudents() {
      return this.subjectClassIds.filter(
        classId =>
          this.selectedStudentsByClass[classId] && this.selectedStudentsByClass[classId].length > 0,
      );
    },
  },
  methods: {
    getClassName(classId) {
      return this.subjectClassesById[classId].name;
    },
    getClassSendoutInformation(classId) {
      const selectedStudentCount = this.selectedStudentsByClass[classId].length;

      if (selectedStudentCount === this.studentListByClassId[classId].length) {
        return 'all students';
      }

      if (selectedStudentCount === 1) {
        return this.selectedStudentsByClass[classId][0].name;
      }

      return `${selectedStudentCount} students`;
    },
  },
};
</script>
