<template>
  <kog-card :default-padding="false">
    <div class="padd-m">
      <div class="flexContainer flexContainer-alignCenter">
        <div
          class="AssignmentOverviewCard-header-iconContainer flexContainer flexContainer-alignCenter flexContainer-justifyCenter margin-right-xs"
        >
          <kog-icon
            theme="custom"
            icon-class="fa-clipboard-list-check"
            fa-style="regular"
          />
        </div>
        <h2 class="heading-xs"> Assignments </h2>
      </div>
      <div
        v-if="isStudentOrAdminViewAsStudent"
        class="flexContainer flexContainer-column margin-top-l margin-bottom-l"
      >
        <div
          v-if="!anyAssignments"
          class="AssignmentOverviewCard-studentNoAssignments"
        >
          <img
            alt=""
            src="~learning/study/images/no-assignments.svg"
          />
          <p> No current assignments </p>
        </div>
        <template v-else>
          <div
            v-for="yearAndDay of assignmentDates"
            :key="yearAndDay"
            class="AssignmentOverviewCard-timelineGroup AssignmentOverviewCard-timelineGroup-student"
            :class="assignmentGroupBorderCssClassStudent(yearAndDay)"
          >
            <div class="AssignmentOverviewCard-timelineGroup-header-student">
              <i class="AssignmentOverviewCard-timeline-point fas fa-clock" />
              <span class="AssignmentOverviewCard-timelineGroup-headerText-student">
                {{ assignmentDateFormat(yearAndDay) }}
              </span>
            </div>
            <assignment-tile
              v-for="assignment of studentAssignmentsGroupedByYearAndDay[yearAndDay]"
              :key="assignment.id"
              class="AssignmentOverviewCard-AssignmentTile-student"
              :assignment="assignment"
              :is-student="isStudent"
            />
          </div>
        </template>
        <i
          v-if="anyAssignments"
          class="AssignmentOverviewCard-timeline-endPoint fas fa-circle-small"
        />
      </div>
      <div
        v-if="isTeacher"
        class="flexContainer flexContainer-column margin-top-s margin-bottom-xl"
      >
        <div
          class="AssignmentOverviewCard-timelineGroup AssignmentOverviewCard-timelineGroup--dashed padd-bottom-l"
        >
          <router-link
            :to="scheduledAssignmentsRoute"
            class="AssignmentOverviewCard-timelineGroup-header flexContainer flexContainer-alignCenter flexContainer-spaceBetween"
          >
            <i
              class="AssignmentOverviewCard-timelineGroup-header-icon AssignmentOverviewCard-timelineGroup-header-icon--calendar fas fa-calendar-alt"
            />
            Scheduled assignments ({{ scheduledCount }})
            <kog-icon
              theme="custom"
              icon-class="fa-chevron-right"
              fa-style="regular"
              size="s"
            />
          </router-link>
        </div>
        <div
          v-for="groupDate of sentAssignmentsDeadlineDates"
          :key="groupDate"
          class="AssignmentOverviewCard-timelineGroup AssignmentOverviewCard-timelineGroup--solid padd-bottom-xs"
        >
          <div
            class="AssignmentOverviewCard-timelineGroup-header flexContainer flexContainer-alignCenter margin-bottom-xs"
          >
            <i
              class="AssignmentOverviewCard-timelineGroup-header-icon AssignmentOverviewCard-timelineGroup-header-icon--clock fas fa-clock"
            />
            {{ getCurrentGroupHeader(groupDate) }}
          </div>

          <assignment-tile
            v-for="(assignment, index) of dateGroupedSentAssignments[groupDate]"
            :key="assignment.id"
            :class="{
              'margin-bottom-xxs': index < dateGroupedSentAssignments[groupDate].length - 1,
            }"
            :assignment="assignment"
            :is-student="isStudent"
            :students="students"
            @completion-chip-click="onAssignmentTileCompletionChipClick(assignment)"
          />
        </div>

        <div
          v-if="noActiveAssignments"
          class="AssignmentOverviewCard-timelineGroup AssignmentOverviewCard-timelineGroup--solid"
          :class="{
            'padd-bottom-xs': pastAssignments.length > 0,
          }"
        >
          <div
            class="AssignmentOverviewCard-timelineGroup-header flexContainer flexContainer-alignCenter"
          >
            <i
              class="AssignmentOverviewCard-timelineGroup-header-icon AssignmentOverviewCard-timelineGroup-header-icon--clock fas fa-clock"
            />
            No active assignments
          </div>
        </div>

        <div
          v-if="pastAssignments.length > 0"
          class="AssignmentOverviewCard-timelineGroup AssignmentOverviewCard-timelineGroup--dashed"
        >
          <div
            class="AssignmentOverviewCard-timelineGroup-header flexContainer flexContainer-alignCenter margin-bottom-xs"
          >
            <i
              class="AssignmentOverviewCard-timelineGroup-header-icon AssignmentOverviewCard-timelineGroup-header-icon--clock fas fa-clock"
            />
            {{ getPastGroupHeader() }}
          </div>

          <assignment-tile
            v-for="(assignment, index) of pastAssignments"
            :key="assignment.id"
            :class="{
              'margin-bottom-xxs': index < pastAssignments.length - 1,
            }"
            :assignment="assignment"
            :is-student="isStudent"
            @completion-chip-click="onAssignmentTileCompletionChipClick(assignment)"
          />
        </div>

        <div
          class="AssignmentOverviewCard-timelineGroup AssignmentOverviewCard-timelineGroup--dashed padd-top-l"
        >
          <router-link
            :to="pastAssignmentsRoute"
            class="AssignmentOverviewCard-timelineGroup-header flexContainer flexContainer-alignCenter flexContainer-spaceBetween"
          >
            <i
              class="AssignmentOverviewCard-timelineGroup-header-icon AssignmentOverviewCard-timelineGroup-header-icon--calendar fas fa-calendar-check"
            />
            All past assignments
            <kog-icon
              theme="custom"
              icon-class="fa-chevron-right"
              fa-style="regular"
              size="s"
            />
          </router-link>
        </div>
      </div>

      <div
        class="flexContainer flexContainer-alignCenter AssignmentOverviewCard-assignmentButtonContainer"
      >
        <create-assignment-button
          v-if="isTeacher && !isClassAdmin"
          :subject-classes="[subjectClass]"
          :subject="subject"
          :source="sources.ASSIGNMENT_CARD"
          :user="user"
          dropdown-placement="top-start"
          full-width
        />
        <kog-button
          v-if="isStudentOrAdminViewAsStudent"
          class="AssignmentOverviewCard-viewAllAssignmentsButton"
          label="View all assignments"
          @click="studentViewAllAssignments"
        />
      </div>
    </div>
  </kog-card>
</template>

<script>
import { groupBy, sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { mapState } from 'vuex';

import CreateAssignmentButton, {
  SOURCES,
} from 'learning/common/components/assignment/create-assignment-button.vue';
import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import {
  dateFormatter,
  getDateAsDateObject,
  getShortMonthAndDay,
} from 'sharedApp/utils/time-utils.js';
import AssignmentCompletionStatsModal from 'studyApp/components/subject-class-overview/assignment-completion-stats-modal.vue';
import AssignmentTile from 'studyApp/components/subject-class-overview/assignment-tile.vue';
import { trackSeeAssignmentProgressFromCard } from 'teachApp/services/tracking/assignment-tracking.js';

export default {
  name: 'AssignmentOverviewCard',
  components: {
    KogButton,
    KogIcon,
    KogCard,
    AssignmentTile,
    CreateAssignmentButton,
  },
  mixins: [RoutesMixin],
  setup() {
    const { assignments, pastAssignments, sentAssignments, scheduledAssignments } =
      storeToRefs(useAssignmentStore());

    return {
      subjectAssignments: assignments,
      pastAssignments: pastAssignments.value.results ?? [],
      scheduledAssignments,
      sentAssignments,
    };
  },
  data() {
    return {
      sources: SOURCES,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
      students: state => state.teacherStudentListModule.subjectClassStudentsList,
    }),
    isStudentOrAdminViewAsStudent() {
      return this.isStudent || this.isClassAdmin;
    },
    isStudent() {
      return this.user.isStudent();
    },
    isTeacher() {
      return this.user.isTeacher();
    },
    scheduledCount() {
      return this.scheduledAssignments?.count;
    },
    assignmentDates() {
      return Object.keys(this.studentAssignmentsGroupedByYearAndDay).sort().reverse();
    },
    anyAssignments() {
      return this.assignmentDates?.length;
    },
    noActiveAssignments() {
      return this.sentAssignmentsDeadlineDates?.length === 0 && this.pastAssignments?.length === 0;
    },
    studentAssignmentsGroupedByYearAndDay() {
      const assignments = this.subjectAssignments.results;
      sortBy(assignments, 'deadline');
      return groupBy(assignments, ({ deadline }) => dateFormatter(new Date(deadline)));
    },
    isClassAdmin() {
      return this.subjectClass && !this.subjectClass.is_user_class_teacher;
    },
    dateGroupedSentAssignments() {
      return groupBy(this.sentAssignments, assignment => {
        const { deadline } = assignment;
        const deadlineDate = new Date(deadline);
        return `${deadlineDate.getFullYear()}-${deadlineDate.getMonth() + 1}-${deadlineDate.getDate()}`;
      });
    },
    sentAssignmentsDeadlineDates() {
      return Object.keys(this.dateGroupedSentAssignments);
    },
    scheduledAssignmentsRoute() {
      return {
        name: 'teacherAssignmentOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
      };
    },
    pastAssignmentsRoute() {
      return {
        name: 'teacherAssignmentOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
        query: {
          tab: 'past_assignments',
        },
      };
    },
  },
  methods: {
    studentViewAllAssignments() {
      this.$router.push({
        name: 'classAssignmentsOverview',
        params: {
          classSlug: `${this.subjectClassSlug}`,
          sid: `${this.subjectId}`,
          cid: `${this.classId}`,
        },
        query: { source: 'assignment_card' },
      });
    },
    assignmentGroupBorderCssClassStudent(yearAndDay) {
      return `AssignmentOverviewCard-timelineGroup--${
        this.isAfterToday(yearAndDay) ? 'dashed' : 'solid'
      }`;
    },
    assignmentDateFormat(dateOnlyDay) {
      const assignmentDate = getDateAsDateObject(dateOnlyDay);
      const assignmentDateStr = getShortMonthAndDay(assignmentDate);
      const todayDateStr = getShortMonthAndDay(new Date());
      const isToday = assignmentDateStr === todayDateStr;
      if (isToday) {
        return `Today - ${assignmentDateStr}`;
      }
      return assignmentDateStr;
    },
    onAssignmentTileCompletionChipClick(assignment) {
      this.$modal(AssignmentCompletionStatsModal, {
        assignment,
        assignmentStudentUserIds: assignment.student_user_ids,
        assignmentCompletedStudentUserIds: assignment.completed_student_user_ids,
        educationSystemName: this.subject.educationsystem.name,
        subjectClassId: this.classId,
        possibleLevels: this.subject.possible_levels,
      });
      trackSeeAssignmentProgressFromCard({
        assignment,
        assignmentStudentCount: assignment.student_user_ids.length,
        assignmentCompletedStudentCount: assignment.completed_student_user_ids.length,
      });
    },
    getCurrentGroupHeader(date) {
      return getShortMonthAndDay(new Date(date));
    },
    getPastGroupHeader() {
      return `Today – ${getShortMonthAndDay(new Date())}`;
    },
    isAfterToday(date) {
      return new Date(date) < new Date();
    },
  },
};
</script>

<!-- eslint-disable max-len -->

<style scoped>
.AssignmentOverviewCard-assignmentButtonContainer {
  display: flex;
  justify-content: center;
}

.AssignmentOverviewCard-studentNoAssignments {
  margin: auto;
  text-align: center;
}

.AssignmentOverviewCard-viewAllAssignmentsButton {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.AssignmentOverviewCard-timelineGroup-headerText-student {
  padding-left: var(--space-xs);

  font-size: var(--kog-font-size-default);
  font-weight: bold;
  line-height: var(--kog-font-size-default);
  color: var(--kogPlatformGray018);
}

.AssignmentOverviewCard-timelineGroup-header-student {
  position: relative;
  top: -5px;
  left: calc(-1 * var(--space-s) - 8px);
  flex-direction: row;
}

.AssignmentOverviewCard-timelineGroup-student {
  display: flex;
  flex-direction: column;
}

.AssignmentOverviewCard-timelineGroup-student
  .AssignmentOverviewCard-AssignmentTile-student:first-of-type {
  margin-top: var(--space-l);
}

.AssignmentOverviewCard-timelineGroup-student
  .AssignmentOverviewCard-AssignmentTile-student:not(:last-of-type) {
  margin-bottom: var(--space-xs);
}

.AssignmentOverviewCard-timelineGroup-student
  .AssignmentOverviewCard-AssignmentTile-student:last-of-type {
  margin-bottom: var(--space-s);
}

.AssignmentOverviewCard-timeline-point {
  font-size: 16px;
  background: var(--kogPlatformWhite);
}

.AssignmentOverviewCard-timeline-endPoint {
  position: relative;
  top: -5px;
  padding-top: 0;
  padding-left: var(--space-xs);
}

.AssignmentOverviewCard-header-iconContainer {
  width: 40px;
  height: 40px;

  color: var(--kog-card-hard-icon-color);

  background-color: var(--kog-card-hard-icon-background);
  border-radius: 50%;
}

.AssignmentOverviewCard-timelineGroup {
  margin-left: var(--space-s);
  padding-left: var(--space-s);
  border-left-color: var(--kogPlatformGray018);
  border-left-width: 1px;
}

.AssignmentOverviewCard-timelineGroup--dashed {
  border-left-style: dashed;
}

.AssignmentOverviewCard-timelineGroup--solid {
  border-left-style: solid;
}

.AssignmentOverviewCard-timelineGroup-header {
  position: relative;

  padding-left: var(--space-xs);

  font-size: var(--kog-font-size-default);
  font-weight: bold;
  line-height: var(--kog-font-size-default);
  color: var(--kogPlatformGray018);
}

.AssignmentOverviewCard-timelineGroup-header:hover,
.AssignmentOverviewCard-timelineGroup-header:focus {
  text-decoration: none;
}

.AssignmentOverviewCard-timelineGroup-header-icon {
  position: absolute;
  font-size: 16px;
  background: var(--kogPlatformWhite);
}

.AssignmentOverviewCard-timelineGroup-header-icon--clock {
  left: calc(-1 * var(--space-s) - 8px);
}

.AssignmentOverviewCard-timelineGroup-header-icon--calendar {
  left: calc(-1 * var(--space-s) - 7px);
}
</style>

<!-- eslint-enable max-len  -->
