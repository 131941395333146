const STORAGE_KEY = 'currentlyRetryingEQ';

function createKey(subjectNodeId, questionId) {
  return `${subjectNodeId}_${questionId}`;
}

function getRetryMap() {
  const rawRetryString = localStorage.getItem(STORAGE_KEY) || '{}';
  return JSON.parse(rawRetryString);
}

function updateRetryMap(newValue) {
  const retrying = getRetryMap();
  retrying[newValue] = true;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(retrying));
}

function deleteValueFromRetryMap(value) {
  const retrying = getRetryMap();
  delete retrying[value];
  localStorage.setItem(STORAGE_KEY, JSON.stringify(retrying));
}

export function isRetryingExerciseQuestion(subjectNodeId, questionId) {
  const retrying = getRetryMap();
  const key = createKey(subjectNodeId, questionId);
  return retrying && Object.prototype.hasOwnProperty.call(retrying, key);
}

export function retryExerciseQuestion(subjectNodeId, questionId) {
  const key = createKey(subjectNodeId, questionId);
  updateRetryMap(key);
}

export function finishRetry(subjectNodeId, questionId) {
  const key = createKey(subjectNodeId, questionId);
  deleteValueFromRetryMap(key);
}
