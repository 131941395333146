<template>
  <div>
    <kog-item-list
      role="list"
      :is-handling-tab-index="true"
    >
      <li
        v-for="(result, index) in resultsToRender"
        :key="result.item.id"
        class="SearchResultsList"
      >
        <router-link
          v-if="result.url"
          v-slot="{ href, navigate }"
          :to="result.url"
          custom
        >
          <a
            :href="href"
            target="_self"
            :data-index="index"
            class="SearchResultsList-item block"
            @click="
              e => {
                if (!result.isAnnotation) {
                  resultClick(e, result.item);
                }
                navigate(e);
              }
            "
          >
            <template v-if="!result.isAnnotation">
              {{ result.item.formatted_number_including_ancestors }} {{ result.item.name }}
            </template>
            <note-search-result-item
              v-if="result.isAnnotation"
              :annotation="result.item"
            />
          </a>
        </router-link>
      </li>
    </kog-item-list>
    <div
      v-if="searchResults.results.length > 0 && (searchResults.next || searchResults.previous)"
      class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter padd-fine-2 padd-right-1 padd-left-1"
    >
      <button
        :class="{
          'SearchResultsList--buttonHidden': !searchResults.previous,
        }"
        :aria-hidden="!searchResults.previous ? 'true' : null"
        class="SearchResultsList-navButtons KogButtonLegacy--noStyle"
        @click.stop="previousPageNav()"
      >
        <i
          class="fas fa-chevron-left"
          aria-hidden="true"
        />
        Previous page
      </button>
      <button
        :class="{
          'SearchResultsList--buttonHidden': !searchResults.next,
        }"
        :aria-hidden="!searchResults.next ? 'true' : null"
        class="SearchResultsList-navButtons KogButtonLegacy--noStyle"
        @click.stop="nextPageNav()"
      >
        Next page
        <i
          class="fas fa-chevron-right"
          aria-hidden="true"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { chain } from 'lodash';

import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import { getSubjectNodeMap } from 'sharedApp/libs/subject-tree-functions.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import NoteSearchResultItem from 'studyApp/components/search/note-search-result-item.vue';

export default {
  name: 'SearchResultsList',
  components: {
    NoteSearchResultItem,
    KogItemList,
  },
  mixins: [RoutesMixin],
  props: {
    searchResults: { type: Object, required: true },
    resultClick: { type: Function, required: true },
    nextPage: { type: Function, required: true },
    previousPage: { type: Function, required: true },
    subject: { type: Object, required: true },
  },
  computed: {
    bookRoot() {
      return this.subject.subject_tree[0];
    },
    subjectNodeMap() {
      return getSubjectNodeMap(this.bookRoot);
    },
    resultsToRender() {
      return this.searchResults.results.map(result => ({
        item: result,
        isAnnotation: this.isAnnotation(result),
        url: this.getUrlFromSearch(result),
      }));
    },
  },
  methods: {
    isAnnotation(result) {
      return result.author;
    },
    getUrlFromSearch(searchResult) {
      if (this.isAnnotation(searchResult)) {
        return this.getAnnotationRoute(searchResult);
      }
      return this.getBookNodeRoute(searchResult);
    },
    getAnnotationRoute(annotationSearch) {
      const nodeId = annotationSearch.subject_node;
      const node = this.subjectNodeMap[nodeId];
      const nodeSlug = node.slug;
      return {
        name: 'classNotebookNode',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug,
          nodeId,
        },
      };
    },
    getBookNodeRoute(node) {
      if (node.section || node.activity) {
        return this.getBookPageRoute(node);
      }
      return this.getNonContentNodeRoute(node);
    },
    getNonContentNodeRoute(node) {
      const subjectTreeNode = this.subjectNodeMap[node.id];
      const childWithContent = this.getFirstChildWithContent(subjectTreeNode);
      if (childWithContent) {
        return this.getBookPageRoute({
          ...childWithContent,
          ...{
            section: childWithContent.section_id,
          },
        });
      }
      return null;
    },
    getFirstChildWithContent(subjectTreeNode) {
      if (subjectTreeNode.section_id) {
        return subjectTreeNode;
      }
      return chain(subjectTreeNode.children)
        .map(child => this.getFirstChildWithContent(child))
        .filter(child => child)
        .value()[0];
    },
    getBookPageRoute(node) {
      return {
        name: 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
      };
    },
    previousPageNav() {
      setTimeout(() => {
        document.getElementById('searchResultsNotice').focus();
      }, 32);
      this.previousPage();
    },
    nextPageNav() {
      setTimeout(() => {
        document.getElementById('searchResultsNotice').focus();
      }, 32);
      this.nextPage();
    },
  },
};
</script>

<style scoped>
.SearchResultsList {
  list-style: none;
}

.SearchResultsList--buttonHidden {
  visibility: hidden;
}

.SearchResultsList-item {
  margin: 2px 0;
  padding: 12px 16px;
  color: var(--kog-text-default);
}

.SearchResultsList-item:hover {
  color: var(--kog-text-default);
  text-decoration: none;
  background-color: var(--kog-list-item-hover-background);
}

.SearchResultsList-navButtons {
  color: var(--kog-text-default);
}

.SearchResultsList-navButtons:hover {
  color: var(--kog-text-default);
  text-decoration: none;
}
</style>
