const questionLocationMixin = {
  methods: {
    getLocationsHtml(locations) {
      return locations.map(i => `${i.number} ${i.title}`).join('<br>');
    },
    getFirstLocationNumber(locations) {
      if (locations.length === 0) {
        return '';
      }
      return locations[0].number;
    },
    getQuestionLocation(locations) {
      const isPlural = locations.length !== 1;
      return `
        ${locations.length} topic${isPlural ? 's' : ''}
      `;
    },
  },
};

export default questionLocationMixin;
