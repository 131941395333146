import MenuItem from 'studyApp/menus/menu-item.js';

export default class InsightsMenuItem extends MenuItem {
  constructor(config) {
    const defaults = {
      key: 'insights',
      displayText: 'Insights',
      iconClassActive: 'fas fa-chart-column',
      iconClassInactive: 'fal fa-chart-column',
    };
    super({ ...defaults, ...config });
  }

  navigateTo({ router, currentRoute }) {
    const params = {
      classSlug: currentRoute.params.classSlug,
      cid: currentRoute.params.cid,
      sid: currentRoute.params.sid,
    };
    this.goToRoute(router, currentRoute, 'teacherInsights', params);
  }
}
