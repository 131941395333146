<template>
  <kog-modal title="Edit class">
    <template #modalBody>
      <div class="Modal-InnerContainer">
        <div class="Modal-InputHeader"> Subject </div>
        <div class="flexContainer flexContainer-alignCenter">
          <img
            :src="subjectClass.logo_url"
            class="SubjectClass-Logo--medium margin-right-xs"
            alt=""
          />
          <div>
            <span class="Subject-title">{{ subjectClass.subject_name }}</span>
            <span class="Modal-Note">(cannot be changed)</span>
            <p
              v-if="shouldShowLevelsInfo"
              class="Modal-Note margin-top-xxs margin-bottom-0"
            >
              <levels-info-icon
                :education-system="educationSystem"
                :should-display-text="true"
              />
            </p>
          </div>
        </div>

        <label class="Modal-InputHeader">
          Class name
          <input
            v-model="name"
            type="text"
            name="name"
            class="form-control text-weight-initial"
            maxlength="150"
          />
        </label>
        <p class="Modal-Note">
          The maximum length for the class name is 150 characters ({{ name.length }}/150)
        </p>

        <label
          class="Modal-InputHeader"
          for="endDateSelect"
        >
          End date
          <class-enddates-select
            id="endDateSelect"
            v-model:value="selectedEndDate"
            name="endDate"
            class="text-weight-initial"
          />
        </label>
        <p class="Modal-Note">
          This is the last date when students will be able to access the class.
        </p>
      </div>
    </template>

    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          :disabled="isLoading"
          class="KogButtonLegacy"
          @click="closeModal"
        >
          Cancel
        </button>
        <kog-button
          :disabled="!isClassEdited"
          :is-loading="isLoading"
          label="Save class details"
          button-style="primary"
          @click="saveClassDetails"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import LevelsInfoIcon from 'learning/common/components/levels-info-icon.vue';

import ClassEnddatesSelect from 'schoolAdminApp/components/class-enddates-select.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import { isIGCSELevelsEnabled } from 'sharedApp/services/levels/index.js';

export default {
  name: 'EditClassModal',
  components: {
    KogModal,
    ClassEnddatesSelect,
    LevelsInfoIcon,
    KogButton,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    subjectClass: {
      type: Object,
      required: true,
    },
    educationSystem: {
      type: String,
      required: true,
    },
    saveSubjectClass: {
      type: Function,
      required: true,
    },
    schoolId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedEndDate: '',
      name: '',
      originalEndDate: '',
      originalClassName: '',
      isLoading: false,
    };
  },
  computed: {
    isClassNameChanged() {
      return this.originalClassName !== this.name;
    },
    isClassEndDateChanged() {
      return this.originalEndDate !== this.selectedEndDate;
    },
    isClassEdited() {
      return this.isClassNameChanged || this.isClassEndDateChanged;
    },
    shouldShowLevelsInfo() {
      return isIGCSELevelsEnabled(this.subjectClass.possible_levels);
    },
  },
  mounted() {
    this.name = this.subjectClass.name;
    this.selectedEndDate = this.subjectClass.end_date;
    this.originalClassName = this.subjectClass.name;
    this.originalEndDate = this.subjectClass.end_date;
  },
  methods: {
    async saveClassDetails() {
      this.isLoading = true;
      try {
        await this.saveSubjectClass({
          schoolId: this.schoolId,
          subjectClassId: this.subjectClass.id,
          name: this.name,
          endDate: this.selectedEndDate,
        });
      } catch {
        this.$toast.showError(
          `Could not save class ${this.name}. Please try again or contact support`,
        );
        return;
      } finally {
        this.isLoading = false;
      }
      const notificationText = this.getSuccessNotificationMessage();
      this.$toast.showSuccess(notificationText);
      this.closeModal();
    },
    getSuccessNotificationMessage() {
      if (this.isClassNameChanged && !this.isClassEndDateChanged) {
        return 'Class name successfully updated';
      }

      if (!this.isClassNameChanged && this.isClassEndDateChanged) {
        return 'End date successfully updated';
      }
      return 'Class name and End date successfully updated';
    },
  },
};
</script>

<style scoped>
.SubjectClass-Logo--medium {
  max-height: 55px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 var(--kogShadow020);
}

.Modal-InnerContainer {
  margin-right: 65px;
  margin-left: 65px;
}

.Modal-InputHeader {
  display: block;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 700;
}

.Modal-Note {
  color: var(--kogPlatformGray044);
}
</style>
