<template>
  <div class="kog-container">
    <div class="kog-row">
      <div class="kog-col-12 padding-0">
        <template v-if="isAssignmentScheduled || (isAssignmentSent && isOngoing)">
          <kog-alert
            v-if="isConnectedToGoogleClassroom"
            mode="info"
            class="margin-bottom-m"
          >
            Assignment details won't reflect in Google Classroom. Ensure consistency by updating
            information directly on Google Classroom.
          </kog-alert>
          <kog-input
            v-model:value="name"
            label="Name"
            helper-text="Tip: Name it something re-usable in the future"
            required
            maxlength="150"
          />

          <div v-if="isAssignmentScheduled && showEditRecipients">
            <assignment-lms-integration-container
              v-if="subjectClass"
              class="AssignmentSendoutStep-section"
              :subject-class="subjectClass"
              :send-to-integration="recipientsInputModel.send_to_integration"
              :assignment-type="assignmentType"
              :points-possible="recipientsInputModel.integration_settings?.points_possible"
              :categories-by-class-id="categoriesByClassId"
              @send-to-integration-updated="updateSendToIntegration"
              @points-possible-updated="updatePointsPossible"
            />
            <assignment-sendout-recipients-container
              v-if="subjectClass"
              :is-student-selected-by-id="isStudentSelectedById"
              :student-list-by-class-id="studentListByClassId"
              :update-selected-students="updateSelectedStudents"
              :update-selected-classes-and-students="updateSelectedClassesAndStudents"
              :query-params="$route.query"
              :assignment="assignment"
              :input-model="recipientsInputModel"
              :subject-class="subjectClass"
              :google-classroom-recipients="google_classroom_recipients"
              origin="edit-assignment"
              @send-to-integration-updated="updateSendToIntegration"
            />
          </div>

          <kog-textarea
            v-model:value="instructions"
            label="Instructions"
            placeholder="e.g. Please pay attention to the concepts covered in topic 4.1"
            class="margin-top-s"
          />

          <label
            for="deadline"
            aria-hidden="true"
            class="heading-xxs margin-top-s margin-bottom-xxs"
          >
            Deadline
          </label>
          <div class="input-group cursorPointer">
            <div
              class="input-group-addon"
              data-toggle
            >
              <i class="far fa-calendar" />
            </div>
            <flat-pickr
              id="deadline"
              v-model="deadline"
              :config="datePickerConfig"
              aria-label="Deadline"
            />
          </div>
          <kog-checkbox
            v-if="isQuestionAssignment && isAssignmentScheduled"
            v-model:is-checked="softDeadline"
            class="margin-top-s"
            label="Allow late submissions"
            tooltip="Selecting this allows students to submit their responses after the
                deadline passes"
          />
          <div v-if="isQuestionAssignment && isAssignmentScheduled">
            <div class="heading-s margin-top-s margin-bottom-m">Display answers</div>
            <kog-radio-group
              v-model:selected-value="currentDisplayAnswersSetting"
              aria-label="Display answers setting"
              :options="displayAnswersSettings"
            />
          </div>

          <kog-textarea
            v-if="isAssignmentSent"
            v-model:value="optionalMessage"
            label="Message"
            placeholder="Message (optional)"
            class="margin-top-s"
          />
        </template>

        <kog-alert
          v-else
          title="Deadline has passed"
          mode="error"
        >
          This assignment has already passed the deadline and cannot be edited.
        </kog-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { isEqual, pickBy, sortBy, xor } from 'lodash';
import cloneDeep from 'lodash/cloneDeep.js';
import flatPickr from 'vue-flatpickr-component';
import { mapState } from 'vuex';

import { AssignmentsService } from '@apis/generated/services.ts';
import { fetchStudentsList } from '@apis/subject-class.js';

import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';
import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogRadioGroup from 'sharedApp/components/base/radio/kog-radio-group.vue';
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isQuestionType } from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import AssignmentLmsIntegrationContainer from 'studyApp/components/teacher/assignment-create/sendout-step/assignment-lms-integration-container.vue';
import AssignmentSendoutRecipientsContainer from 'studyApp/components/teacher/assignment-create/sendout-step/assignment-sendout-recipients-container.vue';
import {
  DEFAULT_DISPLAY_ANSWERS_SETTING,
  DISPLAY_ANSWERS_SETTING_OPTIONS,
} from 'studyApp/constants/teacher-assignment-constants.ts';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';
import ManualTimeInputPlugin from 'teachApp/utils/flatPickrPlugins/manual-time-input-plugin.js';

export default {
  name: 'TeacherAssignmentEdit',
  components: {
    AssignmentLmsIntegrationContainer,
    AssignmentSendoutRecipientsContainer,
    flatPickr,
    KogAlert,
    KogCheckbox,
    KogInput,
    KogTextarea,
    KogRadioGroup,
  },
  mixins: [GoogleClassroomMixin, RoutesMixin],
  provide() {
    return {
      updateLmsCategory: ({ categoryId, classId }) => {
        this.updateLmsCategory({
          categoryId,
          classId,
        });
      },
    };
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    showEditRecipients: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:assignment', 'reload:assignment'],
  setup(props) {
    const assignmentName = computed(() => props.assignment?.name);
    useHead({
      title: () =>
        assignmentName.value ? `${assignmentName.value} | Edit assignment` : 'Edit assignment',
    });
  },
  data() {
    return {
      currentDisplayAnswersSetting: DEFAULT_DISPLAY_ANSWERS_SETTING,
      displayAnswersSettings: DISPLAY_ANSWERS_SETTING_OPTIONS,
      name: '',
      google_classroom_recipients: [],
      instructions: '',
      deadline: '',
      optionalMessage: '',
      softDeadline: false,
      sendToIntegration: false,
      subjectClasses: [],
      studentListByClassId: {},
      selectedStudentIds: [],
      integrationSettings: {},
      datePickerConfig: {
        altInput: true,
        altInputClass: 'KogFormInput--inputGroup KogFormInput',
        altFormat: 'Y-m-d H:i',
        dateFormat: 'Z',
        enableTime: true,
        allowInput: false,
        time_24hr: true,
        minDate: new Date(),
        wrap: true,
        minuteIncrement: 1,
        plugins: [new ManualTimeInputPlugin()],
      },
    };
  },
  computed: {
    ...mapState({
      subject: state => state.schoolstaffSubjectModule.subject,
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
      subjectClassStudents: state => state.teacherStudentListModule.subjectClassStudentsList,
      user: state => state.userModule.user,
    }),
    isStudentSelectedById() {
      const allStudents = Object.values(this.studentListByClassId).flat();
      return allStudents.reduce((acc, val) => {
        acc[val.school_student] = this.selectedStudentIds.includes(val.school_student);
        return acc;
      }, {});
    },
    isOngoing() {
      return new Date(this.assignment.deadline) > new Date();
    },
    isQuestionAssignment() {
      return isQuestionType(this.assignment.assignment_type);
    },
    isAssignmentSent() {
      return this.assignment.status === 'sent';
    },
    isAssignmentScheduled() {
      return this.assignment.is_scheduled_to_send;
    },
    assignmentType() {
      return this.assignment.assignment_type;
    },
    isConnectedToGoogleClassroom() {
      return this.subjectClass.google_classes.length > 0;
    },
    areRecipientsUpdated() {
      return (
        !this.isAssignmentSent &&
        !isEqual(sortBy(this.selectedStudentIds), sortBy(this.assignment.selected_student_ids))
      );
    },
    areGoogleClasroomRecipientsUpdated() {
      return !isEqual(
        sortBy(this.google_classroom_recipients),
        sortBy(this.assignment.google_classroom_recipients),
      );
    },
    recipientsInputModel() {
      return {
        send_to_integration: this.sendToIntegration,
        subject_classes: this.subjectClasses,
        integration_settings: this.integrationSettings,
      };
    },
    categoriesByClassId() {
      return this.recipientsInputModel.integration_settings.categories_by_class_id ?? {};
    },
    assignmentData() {
      const payload = {};
      if (this.name !== this.assignment.name) {
        payload.name = this.name;
      }
      if (this.instructions !== this.assignment.message) {
        payload.message = this.instructions;
      }
      if (dateTimeFormatter(this.deadline) !== dateTimeFormatter(this.assignment.deadline)) {
        payload.deadline = this.deadline;
      }
      if (this.areGoogleClasroomRecipientsUpdated) {
        payload.google_classroom_recipients = this.google_classroom_recipients;
      }
      if (this.softDeadline !== this.assignment.soft_deadline) {
        payload.soft_deadline = this.softDeadline;
      }
      if (this.sendToIntegration !== this.assignment.send_to_integration) {
        payload.send_to_integration = this.sendToIntegration;
      }
      if (this.areRecipientsUpdated) {
        payload.selected_student_ids = this.selectedStudentIds;
      }
      if (xor(this.subjectClasses, this.assignment.subject_classes).length > 0) {
        payload.subject_classes = this.subjectClasses;
      }
      if (this.currentDisplayAnswersSetting !== this.assignment.display_answers_setting) {
        payload.display_answers_setting = this.currentDisplayAnswersSetting;
      }
      if (!isEqual(this.integrationSettings, this.assignment.integration_settings)) {
        payload.integration_settings = this.integrationSettings;
      }
      payload.optional_message = this.optionalMessage;
      return payload;
    },
  },
  watch: {
    subjectClasses: {
      async handler(newSubjectClasses, oldSubjectClasses) {
        if (newSubjectClasses !== oldSubjectClasses && newSubjectClasses?.length > 0) {
          await Promise.all(
            newSubjectClasses.map(subjectClassId => this.setClassStudentsByClassId(subjectClassId)),
          );
        }
      },
    },
  },
  created() {
    this.name = this.assignment.name;
    this.instructions = this.assignment.message;
    this.google_classroom_recipients = this.assignment.google_classroom_recipients;
    this.deadline = this.assignment.deadline;
    this.softDeadline = this.assignment.soft_deadline;
    this.currentDisplayAnswersSetting = this.assignment.display_answers_setting;
    this.sendToIntegration = this.assignment.send_to_integration;
    this.subjectClasses = this.assignment.subject_classes;
    this.selectedStudentIds = [...this.assignment.selected_student_ids];
    this.integrationSettings = cloneDeep(this.assignment.integration_settings);
    this.setMinDeadlineDate();
  },
  methods: {
    // Save is called from outside the component
    async saveAssignmentDetails() {
      const updatedFields = Object.keys(this.assignmentData).filter(
        field => field !== 'optional_message',
      );
      try {
        this.$emit('update:assignment', {
          ...this.assignment,
          ...this.assignmentData,
        });

        await AssignmentsService.assignmentsPartialUpdate({
          assignmentId: this.assignment.id,
          requestBody: this.assignmentData,
        });
      } catch (error) {
        this.$toast.showError(
          'Could not update the assignment, please refresh the page and try again',
        );
        throw error;
      }
      this.trackEditAssignment(updatedFields);
      this.$toast.showSuccess('Assignment updated!');
      this.$emit('reload:assignment');
    },
    updateSendToIntegration(sendToIntegration) {
      this.sendToIntegration = sendToIntegration;
    },
    updatePointsPossible(pointsPossible) {
      this.integrationSettings.points_possible = pointsPossible;
    },
    // TODO: Duplicated from teacher-assignment-create-container
    setSelectedGoogleClassroomStudents(selectedGCList) {
      const googleClasses = this.subjectClass.google_classes;
      const googleClassesWithSelectedStudents = googleClasses.map(googleClass => {
        const filteredGCRecipients = googleClass.students.filter(
          googleStudent => selectedGCList[googleStudent.upstream_student_id],
        );
        const filteredGCRecipientsIds = filteredGCRecipients.map(
          recipient => recipient.upstream_student_id,
        );

        return {
          upstream_class_id: googleClass.id,
          recipient_upstream_ids: filteredGCRecipientsIds,
        };
      });
      this.google_classroom_recipients = googleClassesWithSelectedStudents;
    },
    updateSelectedClassesAndStudents(
      selectedClasses,
      selectedStudents,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      selectedGoogleClassroomStudents,
    ) {
      this.selectedStudentIds = selectedStudents.map(student => student.school_student);
      this.subjectClasses = selectedClasses.map(subjectClass => subjectClass.id);
      this.saveAssignmentDetails();
    },
    setMinDeadlineDate() {
      if (this.isAssignmentSent) {
        this.datePickerConfig.minDate = new Date(this.assignment.sent_at);
      } else {
        this.datePickerConfig.minDate = new Date(this.assignment.scheduled_sent_at);
      }
    },
    updateSelectedStudents(classStudents, googleClassroomStudents) {
      this.selectedStudentIds = Object.keys(pickBy(classStudents, isSelected => isSelected)).map(
        Number,
      );
      this.setSelectedGoogleClassroomStudents(googleClassroomStudents);
    },
    trackEditAssignment(updatedFields) {
      this.$mixpanel.trackEvent('Assignment - Edit', {
        assignment_type: this.assignment.assignment_type.toLowerCase(),
        updated_fields: updatedFields,
        optional_message: this.optionalMessage,
        is_sent: this.isAssignmentSent,
        num_subject_classes: this.assignment.subject_classes.length,
      });
    },
    async setClassStudentsByClassId(classId) {
      if (!this.studentListByClassId[classId]) {
        this.studentListByClassId[classId] = await fetchStudentsList(classId);
      }
    },
    updateLmsCategory({ categoryId, classId }) {
      if (!this.integrationSettings.categories_by_class_id) {
        this.integrationSettings.categories_by_class_id = {};
      }
      this.integrationSettings.categories_by_class_id[classId] = categoryId;
    },
  },
};
</script>

<style scoped>
.padding-0 {
  padding: 0;
}
</style>
