import MenuItem from 'studyApp/menus/menu-item.js';

export default class PracticeMenuItem extends MenuItem {
  constructor(config) {
    const defaults = {
      key: 'practice',
      displayText: 'Practice',
      iconClassActive: 'fas fa-pencil-alt',
      iconClassInactive: 'fal fa-pencil-alt',
    };
    super({ ...defaults, ...config });
  }
}
