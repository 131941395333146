<template>
  <transition
    name="SlideIn"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
  >
    <div
      v-if="isShowingChild"
      class="SlideIn-wrapper"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
/* eslint-disable no-param-reassign */
export default {
  name: 'SlideIn',
  props: {
    isShowingChild: {
      type: Boolean,
      required: true,
    },
    enterFrom: {
      type: String,
      default: 'left',
      validator: prop => ['left', 'right', 'top', 'bottom'].includes(prop),
    },
  },
  methods: {
    enter(el, done) {
      const wrapperStyle = window.getComputedStyle(el);
      const paddingLeft = parseFloat(wrapperStyle.paddingLeft);
      const paddingRight = parseFloat(wrapperStyle.paddingRight);
      const paddingTop = parseFloat(wrapperStyle.paddingTop);
      const paddingBottom = parseFloat(wrapperStyle.paddingBottom);
      const containerHeight = el.clientHeight;
      const containerWidth = el.clientWidth;
      const innerWidth = containerWidth - paddingLeft - paddingRight;
      const innerHeight = containerHeight - paddingTop - paddingBottom;

      // using the size of the container (see above)
      // to maintain a fixed child size through the transition
      el.children[0].style.minWidth = `${innerWidth}px`;
      el.children[0].style.maxWidth = `${innerWidth}px`;
      el.children[0].style.minHeight = `${innerHeight}px`;
      el.children[0].style.maxHeight = `${innerHeight}px`;
      el.children[0].style.position = 'absolute';
      el.children[0].style.opacity = '0';

      // eslint-disable-next-line default-case
      switch (this.enterFrom) {
        case 'left':
          el.children[0].style.right = `${paddingRight}px`;
          break;
        case 'right':
          el.children[0].style.left = `${paddingLeft}px`;
          break;
        case 'top':
          el.children[0].style.bottom = `${paddingBottom}px`;
          break;
        case 'bottom':
          el.children[0].style.top = `${paddingTop}px`;
          break;
      }

      el.style.width = '0';
      el.style['margin-left'] = '0';
      el.style['margin-right'] = '0';
      el.style['margin-top'] = '0';
      el.style['margin-bottom'] = '0';
      el.style['padding-left'] = '0';
      el.style['padding-right'] = '0';
      el.style['padding-top'] = '0';
      el.style['padding-bottom'] = '0';
      el.style.height = '0';

      setTimeout(() => {
        el.style.transition = 'all 0.3s ease';
        el.children[0].style.transition = 'all 0.3s ease';
        el.style.width = `${containerWidth}px`;
        el.style.height = `${containerHeight}px`;
        el.style['margin-left'] = '';
        el.style['margin-right'] = '';
        el.style['margin-top'] = '';
        el.style['margin-bottom'] = '';
        el.style['padding-left'] = '';
        el.style['padding-right'] = '';
        el.style['padding-top'] = '';
        el.style['padding-bottom'] = '';
        el.children[0].style.opacity = '1';
      });

      setTimeout(() => {
        done();
      }, 350);
    },
    afterEnter(el) {
      el.children[0].style.position = 'static';
      el.style.height = '';
      el.children[0].style.minWidth = '';
      el.children[0].style.maxWidth = '';
      el.children[0].style.minHeight = '';
      el.children[0].style.maxHeight = '';
    },
    beforeLeave(el) {
      el.style.transition = 'all 0.3s ease';
      el.style.width = `${el.clientWidth}px`;
      el.style.height = `${el.clientHeight}px`;

      setTimeout(() => {
        el.style.width = '0';
        el.style.height = '0';
        el.style['margin-left'] = '0';
        el.style['margin-right'] = '0';
        el.style['margin-top'] = '0';
        el.style['margin-bottom'] = '0';
        el.style['padding-left'] = '0';
        el.style['padding-right'] = '0';
        el.style['padding-top'] = '0';
        el.style['padding-bottom'] = '0';

        const currentWidth = el.children[0].clientWidth;
        const currentHeight = el.children[0].clientHeight;
        el.children[0].style.minWidth = `${currentWidth}px`;
        el.children[0].style.maxWidth = `${currentWidth}px`;
        el.children[0].style.minHeight = `${currentHeight}px`;
        el.children[0].style.maxHeight = `${currentHeight}px`;
        el.children[0].style.position = 'absolute';
        el.children[0].style.opacity = '0';
      });
    },
  },
};
</script>

<style scoped>
.SlideIn-wrapper {
  position: relative;
  overflow: visible;
  box-sizing: border-box;
}
</style>
