import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';

import Component, { IMAGE_DISPLAY_TYPES } from './block.vue';

const template = {
  block_type: BLOCK_TYPE.IMAGE,
  image: null,
  caption: '',
  alternate_text: '',
  image_credit: '',
  display_type: IMAGE_DISPLAY_TYPES.DEFAULT,
};

const validator = {
  errors: [],
  validate(blockData) {
    this.errors = [];

    if (!blockData.image) {
      this.errors.push('No image selected');
    }

    return this.errors.length === 0;
  },
};

const preSaveHandler = block => {
  const formData = new FormData();

  Object.keys(block).forEach(key => {
    if (block[key] !== null) {
      formData.append(key, block[key]);
    }
  });

  if (block.image instanceof File === false) {
    formData.delete('image');
  }

  return {
    payload: formData,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  };
};

export default {
  component: Component,
  template,
  validator,
  preSaveHandler,
};
