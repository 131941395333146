import { ContextualBalloon } from '@ckeditor/ckeditor5-ui';

import downloadIcon from 'publishingApp/assets/icons/download-regular.svg';

import KogUI from '../utils/kogui.ts';
import { registerFactoryView } from '../utils/plugin-utils.ts';
import FormView from './file-download-form.js';

export default class FileDownloadUI extends KogUI {
  static get requires() {
    return [ContextualBalloon];
  }

  init() {
    this.formView = null;
    this.balloon = this.editor.plugins.get(ContextualBalloon);

    registerFactoryView(this.editor, {
      name: 'fileDownload',
      commandName: 'insertFileDownload',
      icon: downloadIcon,
      label: 'File Download',
      onExecute: () => this.showUI(),
    });

    const updateCommand = this.editor.commands.get('updateFileDownload');
    this.listenTo(updateCommand, 'execute', () => {
      this.showUI();
    });
  }

  createFormView() {
    this.formView = new FormView(this.editor);

    const onSubmit = () => {
      const configuration = {
        title: this.formView.titleInputView.fieldView.element.value,
        url: this.formView.urlInputView.fieldView.element.value,
        target: this.formView.selectedTarget,
      };
      this.editor.execute('insertFileDownload', configuration);
      this.hideUI();
    };

    this.setupFormView(onSubmit);
  }

  showUI() {
    if (!this.formView) {
      this.createFormView();
    }

    const commandValue = this.editor.commands.get('insertFileDownload').value;
    if (commandValue) {
      const { title, url, target } = commandValue;
      this.formView.setFields({ title, url, target });
    }

    super.showUI();
  }
}
