<template>
  <div v-if="tabSelected">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { inject, ref, watch } from 'vue';

import type { KogTabPanelProps } from './kog-tab-panel.types.ts';

import { PanelInjectionKey } from './kog-tab-panels.types.ts';

const tabSelected = ref(false);
const props = withDefaults(defineProps<KogTabPanelProps>(), {
  name: '',
});

const activeTabName = inject(PanelInjectionKey);

watch(
  () => activeTabName?.value,
  selectedTabName => {
    tabSelected.value = selectedTabName === props.name;
  },
  { immediate: true },
);
</script>
