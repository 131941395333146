<template>
  <div>
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div
      v-kog-clickable="isEditingVisibility ? toggleVisibility : undefined"
      v-kog-description:[node.id]="isEditingVisibility ? 'Toggle content visibility' : ''"
      :class="{
        wiggle06: isEditingVisibility,
      }"
      @mouseover="hoverIcon = true"
      @mouseleave="hoverIcon = false"
      @focusin="hoverIcon = true"
      @focusout="hoverIcon = false"
    >
      <div class="ContentNodeItemChild-icon absoluteContainer">
        <subject-node-visibility-toggle
          class="ContentNodeItemChild-visibilityToggle"
          :subject-node-id="node.id"
          :activated="hoverIcon"
        />
        <kog-icon
          :icon-class="sectionIcon"
          fa-style="regular"
          size="s"
          class="u-iconColor"
        />
      </div>
    </div>
    <content-hidden-label
      v-if="isNodeHiddenWhileEditing(node)"
      class="margin-right-xxs"
    />
    <router-link
      :to="nodeLink"
      class="ContentNodeItemChild-link"
      :aria-label="rowAriaLabel"
      :tabindex="tabIndex"
      @click="trackClickNodeLink"
    >
      {{ node.name }}
    </router-link>
    <div
      class="flexContainer flexContainer-row"
      :class="{
        opacity05: isEditingVisibility,
      }"
    >
      <kog-tag
        v-if="flagCount > 0"
        is-chip
        class="ContentNodeItemChild-completionTag margin-right-s"
        icon-class="fa-flag"
        :label="`${flagCount}`"
        :context="`${flagCount} answers flagged`"
        size="m"
        type="outline"
        @click="onReflectionTagClick"
      />
      <kog-tag
        v-if="!hasClassSize && hasReadingStatus"
        is-chip
        class="ContentNodeItemChild-completionTag"
        :icon-class="readingStatus.iconClass"
        :label="readingStatus.label"
        :context="readingStatusTagContext"
        size="m"
        :type="readingStatus.chipType"
      />
      <kog-tag
        v-if="hasClassSize && hasReadingCompletionData"
        is-chip
        class="ContentNodeItemChild-completionTag"
        :icon-class="completionTagIconClass"
        :label="`${totalStudentsThatCompletedNode} / ${classSize}`"
        :context="completionTagContext"
        :tab-index="-1"
        size="m"
        type="outline"
        @click="onCompletionTagClick"
      />
      <subject-node-assign-toggle
        v-if="user.isTeacher()"
        class="margin-left-xs"
        :subject-node-id="node.id"
      />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import FeaturesMixin from 'learning/study/mixins/features-mixin.js';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { getNodeIcon } from 'sharedApp/const/node-icons.js';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';
import SubjectNodeAssignToggle from 'studyApp/components/subject-class-overview/subject-node-assign-toggle.vue';
import SubjectNodeVisibilityToggle from 'studyApp/components/subject-class-overview/subject-node-visibility-toggle.vue';
import useToggleNodeVisibilityEventHandler from 'studyApp/composables/use-toggle-node-visibility-event-handler.js';

export default {
  name: 'ContentNodeItemChild',
  components: {
    ContentHiddenLabel,
    KogTag,
    KogIcon,
    SubjectNodeAssignToggle,
    SubjectNodeVisibilityToggle,
  },
  directives: {
    KogClickable,
    KogDescription,
  },
  mixins: [RoutesMixin, FeaturesMixin],
  props: {
    node: {
      type: Object,
      required: true,
    },
    userNodeReadingProgress: {
      type: Object,
      default: null,
    },
    readingCompletionData: {
      type: Set,
      default: undefined,
    },
    classSize: {
      type: Number,
      default: 0,
    },
    tabIndex: {
      type: Number,
      default: null,
    },
    flagCount: {
      type: Number,
      default: 0,
    },
    contentType: {
      type: String,
      default: '',
    },
  },
  emits: ['clickReflectionStats', 'clickCompletionStats'],
  setup() {
    const { handleToggleNodeVisibilityEvent } = useToggleNodeVisibilityEventHandler();

    const isFilteringSubjectClassOverview = inject('isFilteringSubjectClassOverview', false);
    const activeFiltersInSubjectClassOverview = inject('activeFiltersInSubjectClassOverview', '');

    return {
      handleToggleNodeVisibilityEvent,
      isFilteringSubjectClassOverview,
      activeFiltersInSubjectClassOverview,
    };
  },
  data() {
    return {
      hoverIcon: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      isEditingVisibility: state => state.subjectClassModule.isEditingVisibility,
    }),
    ...mapGetters({
      isNodeHiddenWhileEditing: 'subjectClassModule/isNodeHiddenWhileEditing',
    }),
    readingStatus() {
      if (!this.userNodeReadingProgress) {
        return null;
      }

      const progressStats = this.userNodeReadingProgress.progress_stats;
      const completedNodes = progressStats.completed_leafs_quantity;
      const totalNodes = progressStats.total_leafs_quantity;

      const iconClasses = ['fa-badge-check'];
      let label = '';
      let chipType = 'outline';

      if (completedNodes > 0) {
        label = 'Started';
      }

      if (completedNodes === totalNodes) {
        iconClasses.push('fa-solid');
        label = 'Done';
        chipType = 'positive';
      }

      return { label, chipType, iconClass: iconClasses.join(' ') };
    },
    hasReadingStatus() {
      return !!this.readingStatus?.label;
    },
    readingStatusTagContext() {
      return `${this.$term(this.contentType)} is ${this.readingStatus?.label}`;
    },
    hasReadingCompletionData() {
      return this.readingCompletionData !== undefined;
    },
    hasClassSize() {
      return this.classSize !== 0;
    },
    totalStudentsThatCompletedNode() {
      return this.readingCompletionData?.size ?? 0;
    },
    isCompleted() {
      return this.totalStudentsThatCompletedNode === this.classSize;
    },
    completionTagIconClass() {
      const iconType = this.isCompleted ? 'fas' : 'far';
      return `${iconType} fa-user-check`;
    },
    completionTagContext() {
      return `${this.totalStudentsThatCompletedNode} of ${this.classSize} students have completed ${this.node.name}`;
    },
    rowAriaLabel() {
      const number = this.node.formatted_number_including_ancestors ?? '';
      return `${number} ${this.node.name}, open ${this.$term('subsection')}`;
    },
    sectionIcon() {
      const feature = this.getFeatureBySubjectNode(this.node);
      return getNodeIcon(this.node, feature?.type);
    },
    nodeLink() {
      const params = {
        classSlug: this.subjectClassSlug,
        sid: this.subjectId,
        cid: this.classId,
        nodeSlug: this.node.slug,
        nodeId: this.node.id,
      };

      return {
        name: this.user.isTeacher() ? 'reviewMode' : 'classBook',
        params,
      };
    },
  },
  methods: {
    ...mapActions({
      toggleNodeVisibility: 'subjectClassModule/toggleNodeVisibility',
    }),
    onReflectionTagClick(e) {
      e.preventDefault();
      this.$emit('clickReflectionStats');
    },
    onCompletionTagClick(e) {
      e.preventDefault();
      this.$emit('clickCompletionStats');
    },
    toggleVisibility() {
      this.handleToggleNodeVisibilityEvent(this.node);
    },
    trackClickNodeLink() {
      this.$mixpanel.trackEvent('Subject class overview - Content option - Open book', {
        subject_node_id: this.node.id,
        subject_node_name: `${this.node.formatted_number_including_ancestors} ${this.node.name}`,
        content_type: this.getFeatureBySubjectNode(this.node)?.type || 'Section',
        is_filtering: this.isFilteringSubjectClassOverview,
        active_filters: this.activeFiltersInSubjectClassOverview,
      });
    },
  },
};
</script>

<style scoped>
.ContentNodeItemChild-icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
  margin-right: var(--space-s);

  border: 1px solid var(--kogPlatformGray009);
  border-radius: 50%;
}

.ContentNodeItemChild-completionTag {
  flex-shrink: 0;
  margin-left: auto;
}

.u-iconColor {
  color: var(--kog-text-default);
}

.ContentNodeItemChild-visibilityToggle {
  position: absolute;
  right: 50%;
  bottom: 50%;
}
</style>

<style>
.ContentNodeItemChild-link {
  width: 100%;

  font-family: var(--kog-satoshi);
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: var(--kog-text-default);
  text-decoration: none;
}

.ContentNodeItemChild-link:hover,
.ContentNodeItemChild-link:focus {
  text-decoration: underline;
}
</style>
