<template>
  <div class="flexContainer flexContainer-alignCenter">
    <div class="js-UserProfile-avatar margin-right-m">
      <kog-avatar
        :name="fullName"
        size="xl"
      />
    </div>
    <span class="heading-xl">
      {{ fullName }}
    </span>
  </div>
</template>

<script>
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';

export default {
  name: 'UserProfileHeader',
  components: {
    KogAvatar,
  },
  props: {
    fullName: { type: String, required: true },
  },
};
</script>
