<template>
  <div>
    <fullpage-takeover-navbar
      :back-button-aria-label="backButtonAriaLabel"
      :header-text="`${sectionFormattedNumber} ${sectionNodeName}`"
      @back-button-click="onBackButtonClick"
    />
    <div class="TeacherSectionResponses kogPlatformGray094-bg">
      <div
        class="TeacherSectionResponses-headerContainer padd-top-xl padd-bottom-m kogPlatformWhite-bg"
      >
        <h1 class="heading-m"> Student responses </h1>
      </div>
      <kog-loader
        :loading="isLoading"
        loading-msg="Fetching responses..."
      >
        <div
          v-if="!hasStudents"
          class="padd-top-xxxl text-center"
        >
          <h2 class="heading-m"> No students in class </h2>
          <p class="heading-xs text-normal margin-top-m">
            This class does not have any students assigned to it so there are no answers to show
          </p>
          <div class="margin-top-m">
            <router-link :to="manageClassUrl"> Manage your class </router-link>
          </div>
        </div>
        <div
          v-else-if="hasQuestions"
          class="TeacherSectionResponses-responsesContainer"
        >
          <reflection-responses
            :question="reflectionQuestions[0]"
            :students="students"
            :subject-node-id="nodeId"
            :subject-class-id="classId"
          />
        </div>
      </kog-loader>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapState, useStore } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import FullpageTakeoverNavbar from 'sharedApp/components/core/fullpage-takeover-navbar.vue';
import posthog from 'sharedApp/libs/posthog.ts';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

import ReflectionResponses from './reflection-responses.vue';

export default {
  name: 'TeacherSectionResponses',
  components: {
    KogLoader,
    FullpageTakeoverNavbar,
    ReflectionResponses,
  },
  mixins: [RoutesMixin],
  setup() {
    const store = useStore();
    const route = useRoute();
    const { subjectModule } = store.state;
    const { nodeId } = route.params;

    const sectionNodeName = computed(() => {
      return subjectModule.subjectMap[nodeId]?.name ?? '';
    });

    useHead({
      title: `Section responses | ${sectionNodeName.value}`,
    });

    return {
      sectionNodeName,
    };
  },
  computed: {
    ...mapState({
      subjectMap: state => state.subjectModule.subjectMap,
      students: state => state.teacherStudentListModule.subjectClassStudentsList,
      user: state => state.userModule.user,
      reflectionQuestions: state => state.questionsModule.reflectionQuestions,
    }),
    hasStudents() {
      return this.students.length > 0;
    },
    hasQuestions() {
      return this.reflectionQuestions && this.reflectionQuestions.length > 0;
    },
    manageClassUrl() {
      const resolved = this.$router.resolve({
        name: 'manageClass',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
      });
      return resolved.fullPath;
    },
    isLoading() {
      return this.$wait.is([
        'loading_class_students',
        'loading_exercise_questions',
        'loading_exercise_occasions',
      ]);
    },
    sectionId() {
      return this.$route.params.nodeId;
    },
    origin() {
      return this.$route.query.origin ?? '';
    },
    backButtonAriaLabel() {
      const navigationMessage = 'Click to go back to ';
      let destination;

      switch (this.origin) {
        case 'insights':
          destination = 'insights';
          break;
        case 'class_overview':
          destination = 'the class overview';
          break;
        case 'book_section':
        default:
          destination = 'the book';
          break;
      }

      return `${navigationMessage}${destination}`;
    },
    sectionFormattedNumber() {
      return this.subjectMap[this.nodeId]?.formatted_number_including_ancestors ?? '';
    },
  },
  created() {
    this.fetchExerciseOccasions({
      subjectNodeId: this.nodeId,
      subjectClassId: this.classId,
      mine: 'False',
    });
    this.fetchReflectionQuestions(this.nodeId);
    this.fetchSubjectClassStudentsList(this.classId);

    posthog.capture('open_reflection_student_responses');

    this.$mixpanel.trackEvent('Teacher Section Responses - pageview', {
      section: this.sectionId,
      source: this.origin,
    });
  },
  methods: {
    ...mapActions({
      fetchUser: 'userModule/fetchUser',
    }),
    ...mapWaitingActions('teacherStudentListModule', {
      fetchSubjectClassStudentsList: 'loading_class_students',
    }),
    ...mapWaitingActions('questionsModule', {
      fetchReflectionQuestions: 'loading_exercise_questions',
    }),
    ...mapWaitingActions('exerciseOccasionsModule', {
      fetchExerciseOccasions: 'loading_exercise_occasions',
    }),
    onBackButtonClick() {
      switch (this.origin) {
        case 'insights':
          this.$router.push({
            name: 'teacherInsights',
            params: this.$route.params,
            query: { active_tab: this.$route.query.origin_active_tab },
          });
          break;
        case 'class_overview':
          this.$router.push({
            name: 'subjectClassOverview',
            params: this.$route.params,
            query: { node: this.$route.query.origin_node },
          });
          break;
        case 'book_section':
        default:
          this.$router.push({
            name: 'reviewMode',
            params: { ...this.$route.params },
          });
          break;
      }

      this.$mixpanel.trackEvent('Teacher Section Responses - Click Back Button', {
        section: this.sectionId,
      });
    },
  },
};
</script>

<style scoped>
.TeacherSectionResponses {
  min-height: 100vh;
  padding-top: var(--full-page-navbar-height);
}

.TeacherSectionResponses-headerContainer,
.TeacherSectionResponses :deep(.TeacherSectionResponses-responsesContainer) {
  padding-right: 150px;
  padding-left: 150px;
}

.TeacherSectionResponses :deep(.TeacherSectionResponses-responsesContainer) {
  padding-bottom: 76px;
}
</style>
