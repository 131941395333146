import { View, ViewCollection } from '@ckeditor/ckeditor5-ui';

import type { Locale } from '@ckeditor/ckeditor5-utils';
import type { HideableView } from './view.types';

import './flex-wrapper-view.css';

export default class FlexWrapperView extends View implements HideableView {
  public declare isVisible: boolean;

  public declare class: string | null;

  public declare direction: 'row' | 'column';

  public readonly children: ViewCollection;

  constructor(
    locale: Locale,
    options: {
      direction?: 'row' | 'col';
      children?: View[];
      class?: string;
    } = {},
  ) {
    super(locale);

    const bind = this.bindTemplate;

    this.set({
      direction: options.direction || 'col',
      isVisible: true,
      class: options.class || null,
    });

    this.children = this.createCollection();

    if (options.children) {
      options.children.forEach(child => this.children.add(child));
    }

    this.setTemplate({
      tag: 'div',
      attributes: {
        class: [
          'ck-flex-wrapper',
          bind.to('class'),
          bind.to('direction', direction => `ck-flex-wrapper--${direction}`),
          bind.to('isVisible', isVisible => (isVisible ? '' : 'ck-flex-wrapper--hidden')),
        ],
      },
      children: this.children,
    });
  }

  setVisibility(isVisible: boolean) {
    this.isVisible = isVisible;
  }
}
