<template>
  <short-text-question
    v-bind="attrs"
    :question="question"
    :initial-answer="userAnswers"
    @submit-answer="submitAnswer"
    @blur="eventFunctions.saveHandler"
    @input="eventFunctions.inputHandler"
  />
</template>

<script setup lang="ts">
import { computed, toRefs, useAttrs } from 'vue';

import type { Emit, Props, STQEmitResponse } from './stq.types.ts';

import ShortTextQuestion from 'sharedApp/components/short-text-question/short-text-question.vue';
import { useQuestionAutoSave } from 'sharedApp/composables/use-question-auto-save.ts';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';

const props = withDefaults(defineProps<Props>(), {
  userResponse: '',
  autoSave: false,
});
const { userResponse, question } = toRefs(props);

const attrs = useAttrs();
const emit = defineEmits<Emit>();

const userAnswers = computed(() => userResponse?.value);

const eventFunctions = computed(() => {
  function emitChange(response: STQEmitResponse) {
    emit('change', {
      question_uuid: question.value.uuid,
      response_type: RESPONSE_TYPES.STR,
      user_response: response,
    });
  }

  function emitResponse(response: STQEmitResponse) {
    emit('response', {
      question_uuid: question.value.uuid,
      response_type: RESPONSE_TYPES.STR,
      user_response: response,
    });
  }

  if (props.autoSave) {
    return useQuestionAutoSave(question, {
      onInput: emitChange,
      onSave: emitResponse,
    });
  }

  return {
    inputHandler: emitChange,
    saveHandler: emitResponse,
  };
});

function submitAnswer(response: STQEmitResponse) {
  emit('response', {
    question_uuid: props.question.uuid,
    response_type: RESPONSE_TYPES.STR,
    user_response: response,
    enterPressed: true,
  });
}
</script>
