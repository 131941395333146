<template>
  <div class="divider-top margin-top-1 padd-top-1">
    <div v-if="isMCQ">
      <multiple-choice-question-result-choices :choices="getChoices" />
    </div>

    <div v-if="isSTQ">
      <kog-short-text-question-correct-answers
        :correct-answers="stqAnswer.answers"
        :secondary-accepted-answers="stqAnswer.secondaryAcceptedAnswers"
        :answer-explanation-html="stqAnswer.explanation"
        :notify-about-missing-explanation="true"
        :title-classes="['heading-s']"
      />
    </div>

    <div v-if="isLTQ">
      <h4 class="heading-s"> Answers </h4>
      <long-text-question-correct-answer :content="ltqAnswer" />
    </div>

    <div v-if="isFBQ">
      <fill-in-the-blanks-solution
        :solution="fbqSolution"
        :question-html="question.question_html"
      />
    </div>
  </div>
</template>

<script>
import FillInTheBlanksSolution from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-solution.vue';
import KogShortTextQuestionCorrectAnswers from 'sharedApp/components/questions/kog-short-text-question-correct-answers.vue';
import LongTextQuestionCorrectAnswer from 'sharedApp/components/questions/long-text-question-correct-answer.vue';
import {
  isFBQ,
  isLTQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import MultipleChoiceQuestionResultChoices from 'teachApp/components/question/multiple-choice-question-result-choices.vue';

export default {
  name: 'AssignmentQuestionAnswer',
  components: {
    MultipleChoiceQuestionResultChoices,
    KogShortTextQuestionCorrectAnswers,
    LongTextQuestionCorrectAnswer,
    FillInTheBlanksSolution,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMCQ() {
      return isMCQ(this.question);
    },
    isSTQ() {
      return isSTQ(this.question);
    },
    isFBQ() {
      return isFBQ(this.question);
    },
    isLTQ() {
      return isLTQ(this.question);
    },
    stqAnswer() {
      if (this.question?.solution) {
        const { answer_list: answers, secondary_accepted_answers: secondaryAcceptedAnswers } =
          this.question.solution;

        return {
          answers,
          secondaryAcceptedAnswers,
          explanation: this.question.explanation,
        };
      }

      const {
        answers,
        secondary_accepted_answers: secondaryAcceptedAnswers,
        answer_explanation_html: explanation,
      } = this.question;

      return {
        answers,
        secondaryAcceptedAnswers,
        explanation,
      };
    },
    fbqSolution() {
      if (!this.isFBQ) {
        return {};
      }
      const solution = this.question?.answers_set || this.question?.solution;
      const answers = this.mapFBQAnswersToUID(solution);
      const explanation = this.question.explanation_html || this.question.explanation;
      return {
        correct_answers: answers,
        explanation,
      };
    },
    ltqAnswer() {
      return this.question.answer_explanation_html || this.question.explanation;
    },
    getChoices() {
      return this.question.context?.choices || this.question.multiplechoicequestionchoice_set;
    },
  },
  methods: {
    mapFBQAnswersToUID(answersArray) {
      return answersArray.reduce((acc, answer) => {
        acc[answer.html_element_uid] = answer;
        return acc;
      }, {});
    },
  },
};
</script>
