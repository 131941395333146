import { DISPLAY_STATE, VALID_QUESTION_TYPES } from './question-input-utility.js';

const QuestionInputMixin = {
  computed: {
    isSolution() {
      return this.displayState === DISPLAY_STATE.SOLUTION;
    },
    isCorrect() {
      return this.displayState === DISPLAY_STATE.CORRECT;
    },
    isIncorrect() {
      return this.displayState === DISPLAY_STATE.INCORRECT;
    },
    isNeutral() {
      return this.displayState === DISPLAY_STATE.NEUTRAL;
    },
    isFBQ() {
      return this.questionType === VALID_QUESTION_TYPES.FBQ;
    },
    isSTQ() {
      return this.questionType === VALID_QUESTION_TYPES.STQ;
    },
  },
};

export default QuestionInputMixin;
