<template>
  <kog-icon
    v-if="isExamStyleQuestion"
    v-kog-focusable="true"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: `${esqTerm} question`,
    }"
    :label="`${esqTerm} question`"
    fa-style="light"
    icon-class="fa-file-alt"
    size="m"
  />
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogFocusable from 'sharedApp/directives/kog-focusable.js';

const ESQ_TAG = 'exam-style';

export default {
  name: 'KogEsqIndicator',
  directives: {
    tooltip: VTooltip,
    KogFocusable,
  },
  components: {
    KogIcon,
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    esqTerm: {
      type: String,
      required: true,
    },
  },
  computed: {
    isExamStyleQuestion() {
      return this.tags.includes(ESQ_TAG);
    },
  },
};
</script>
