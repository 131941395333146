<template>
  <div
    class="ReadingAssignmentFocusHeader flexContainer flexContainer-column flexContainer-alignCenter"
    :class="{
      'ReadingAssignmentFocusHeader--done': isAssignmentDone || hasDeadlinePassed,
    }"
  >
    <div class="ReadingAssignmentFocusHeader-topRow flexContainer flexContainer-flexEnd">
      <slot name="topRowRight" />
    </div>

    <div class="ReadingAssignmentFocusHeader-content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="141"
        height="203"
        viewBox="0 0 141 203"
        fill="none"
        class="ReadingAssignmentFocusHeader-lines ReadingAssignmentFocusHeader-line-green"
      >
        <path
          d="M137.269 38.7278C67.5559 32.6498 -1.5542 92.9723 65.4384 179.718"
          stroke="var(--curvy-stroke, var(--kog-colors-green-500))"
          stroke-width="var(--curvy-stroke-width, 75)"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="233"
        height="238"
        viewBox="0 0 233 238"
        fill="none"
        class="ReadingAssignmentFocusHeader-lines ReadingAssignmentFocusHeader-line-yellow"
      >
        <path
          d="M18 183.457C109.162 230.686 232.149 177.876 184.314 11"
          stroke="var(--curvy-stroke, var(--kog-colors-yellow-500))"
          stroke-width="var(--curvy-stroke-width, 75)"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="237"
        height="146"
        viewBox="0 0 237 146"
        fill="none"
        class="ReadingAssignmentFocusHeader-lines ReadingAssignmentFocusHeader-line-orange"
      >
        <path
          d="M198.972 145.061C198.948 63.2236 120.869 -10.2298 25.7113 77.7735"
          stroke="var(--curvy-stroke, var(--kog-colors-orange-500))"
          stroke-width="var(--curvy-stroke-width, 75)"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="191"
        viewBox="0 0 160 191"
        fill="none"
        class="ReadingAssignmentFocusHeader-lines ReadingAssignmentFocusHeader-line-pink"
      >
        <path
          d="M3.47226 153.309C75.298 148.19 119.9 88.9105 122.001 0.999938"
          stroke="var(--curvy-stroke, var(--kog-colors-pink-500))"
          stroke-width="var(--curvy-stroke-width, 75)"
        />
      </svg>

      <slot name="aboveAssignmentName" />

      <h1 class="ReadingAssignmentFocusHeader-title font-satoshi">{{ assignmentName }} </h1>

      <div class="flexContainer flexContainer-alignCenter flexContainer-spaceBetween">
        <div class="flexContainer">
          <div class="ReadingAssignmentFocusHeader-date-time">
            <span class="ReadingAssignmentFocusHeader-date-label font-satoshi text-bold">
              Due date
            </span>
            <div>
              <kog-icon
                v-tooltip="{
                  content: 'Deadline',
                  classes: 'KogTooltip',
                }"
                v-kog-focusable="false"
                icon-class="fa-clock"
                fa-style="regular"
                size="s"
                class="ReadingAssignmentFocusHeader-date-time-icon"
              />
              <span class="font-satoshi text-medium">
                {{ assignmentDeadline }}
              </span>
            </div>
          </div>
          <div
            v-if="assignmentSentAt"
            class="ReadingAssignmentFocusHeader-date-time margin-left-l"
          >
            <span class="ReadingAssignmentFocusHeader-date-label font-satoshi text-bold">
              Send date
            </span>
            <div>
              <kog-icon
                v-tooltip="{
                  content: 'Sent at',
                  classes: 'KogTooltip',
                }"
                v-kog-focusable="false"
                icon-class="fa-paper-plane"
                fa-style="regular"
                size="s"
                class="ReadingAssignmentFocusHeader-date-time-icon"
              />
              <span class="font-satoshi text-medium">
                {{ assignmentSentAt }}
              </span>
            </div>
          </div>
        </div>
        <slot name="actionsBottomRight" />
      </div>
    </div>
  </div>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'ReadingAssignmentFocusLandingHeader',
  components: {
    KogIcon,
  },
  props: {
    assignmentName: {
      type: String,
      required: true,
    },
    assignmentDeadline: {
      type: String,
      required: true,
    },
    assignmentSentAt: {
      type: String,
      default: null,
    },
    isAssignmentDone: {
      type: Boolean,
      required: true,
    },
    hasDeadlinePassed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
/* stylelint-disable suitcss/custom-property-no-outside-root */
.ReadingAssignmentFocusHeader--done {
  --header-bg-color: linear-gradient(180deg, var(--kog-colors-green-500) 0%, #01b977 100%);
  --curvy-green-stroke: var(--kog-colors-green-700);
  --curvy-yellow-stroke: var(--kog-colors-green-600);
  --curvy-orange-stroke: var(--kog-colors-green-300);
  --curvy-pink-stroke: var(--kog-colors-green-600);
  --header-title-color: var(--kog-colors-grey-900);
  --header-date-label-color: var(--kog-colors-grey-900);
  --header-date-time-color: var(--kog-colors-grey-900);
}

.ReadingAssignmentFocusHeader {
  contain: paint;

  width: calc(100% - var(--space-xs) * 2);
  margin-top: var(--space-xs);
  margin-right: var(--space-xs);
  margin-left: var(--space-xs);
  padding-bottom: var(--space-xl);

  color: var(--kog-colors-white);

  background: var(--header-bg-color, var(--kog-colors-aubergine-900));
  border-radius: 12px;
}

.ReadingAssignmentFocusHeader-topRow {
  width: 100%;
  height: var(--space-xxxxl);
}

.ReadingAssignmentFocusHeader-lines {
  position: absolute;
  z-index: -1;
}

.ReadingAssignmentFocusHeader-line-green {
  --curvy-stroke: var(--curvy-green-stroke);

  top: -183px;
  left: -88px;
}

.ReadingAssignmentFocusHeader-line-yellow {
  --curvy-stroke: var(--curvy-yellow-stroke);

  bottom: -173px;
  left: -267px;
}

.ReadingAssignmentFocusHeader-line-orange {
  --curvy-stroke: var(--curvy-orange-stroke);

  top: -153px;
  right: -18px;
}

.ReadingAssignmentFocusHeader-line-pink {
  --curvy-stroke: var(--curvy-pink-stroke);

  right: -106px;
  bottom: -87px;
}

.ReadingAssignmentFocusHeader-content {
  position: relative;
  width: 75%;
}

.ReadingAssignmentFocusHeader-title {
  margin: 0 0 var(--space-xl);
  color: var(--header-title-color, var(--kog-colors-white));
}

.ReadingAssignmentFocusHeader-date-label {
  color: var(--header-date-label-color, var(--kog-colors-aubergine-200));
}

.ReadingAssignmentFocusHeader-date-time {
  color: var(--header-date-time-color, var(--kog-colors-white));
}

.ReadingAssignmentFocusHeader-date-time-icon,
.ReadingAssignmentFocusHeader-date-time-icon:hover {
  margin-right: var(--space-xxs);
  color: var(--header-date-time-color, var(--kog-colors-white));
}
/* stylelint-enable suitcss/custom-property-no-outside-root */
</style>
