<template>
  <div
    class="ResourceFullscreen"
    @click="closeModal"
  >
    <div
      v-if="type === 'image'"
      @click.stop
    >
      <image-box
        :alt="alt"
        :src="url"
        :w="imageWidth"
        :h="imageHeight"
      />
    </div>
    <div
      v-else
      class="ResourceFullscreen-IframeContainer"
    >
      <iframe
        title="ResourceFullscreenIframe"
        allow="fullscreen"
        class="ResourceFullscreen-Iframe"
        :alt="alt"
        :src="url"
        frameborder="0"
        @click.stop
      />
    </div>
  </div>
</template>

<script>
import ImageBox from 'sharedApp/components/images/image-box.vue';

export default {
  name: 'ResourceFullscreen',
  components: { ImageBox },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageWidth: window.innerWidth - 96,
      imageHeight: window.innerHeight - 100,
    };
  },
};
</script>

<style scoped>
.ResourceFullscreen {
  overflow-y: auto;

  max-width: 100vw;
  max-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ResourceFullscreen-IframeContainer {
  position: relative;
  overflow: hidden;
  width: calc(100vw - 96px);
  padding-top: 56.25%;
}

.ResourceFullscreen-Iframe {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border: 0;
}
</style>
