<template>
  <div>
    <div v-if="!isActivityPageTemplate">
      <book-print-header />
      <annotate-text
        class="BookPrint-sectionContent"
        :current-subtopic-text="currentSubtopicText"
        :highlight-enabled="false"
        :subject="subject"
        :subject-node="subjectNode"
        :content="sectionContent"
        :user="user"
        :subject-tree="subject.subject_tree"
        :is-in-review-mode="false"
      />
    </div>
    <div v-else>
      <div class="BookPrint-activityType">
        <div class="BookPrint-activityType-icon">
          <i class="far fa-cogs" />
        </div>
        <div class="BookPrint-activityType-text"> Activity </div>
      </div>
      <book-print-header />
      <annotate-text
        :current-subtopic-text="currentSubtopicText"
        :highlight-enabled="false"
        :subject="subject"
        :subject-node="subjectNode"
        :content="sectionActivityContent"
        :user="user"
        :subject-tree="subject.subject_tree"
        :is-in-review-mode="false"
      />
      <annotate-text
        class="BookPrint-pageBreakBefore"
        :current-subtopic-text="currentSubtopicText"
        :highlight-enabled="false"
        :subject="subject"
        :subject-node="subjectNode"
        :content="sectionContent"
        :user="user"
        :subject-tree="subject.subject_tree"
        :is-in-review-mode="false"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

import AnnotateText from 'learning/common/components/annotate-text.vue';

import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { fetchSectionDetails } from 'studyApp/api/sections.js';
import BookPrintHeader from 'studyApp/components/book-print-header.vue';

export default {
  name: 'SectionPrint',
  components: {
    BookPrintHeader,
    AnnotateText,
  },
  mixins: [RoutesMixin],
  emits: ['ready-to-print'],
  data() {
    return {
      section: {},
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getTopicNodeByDescendantId: 'subjectModule/getTopicNodeByDescendantId',
      getSubtopicNodeByDescendantId: 'subjectModule/getSubtopicNodeByDescendantId',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    subjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    sectionId() {
      return this.subjectNode.section_id;
    },
    sectionContent() {
      return this.section.contents_html;
    },
    sectionActivityContent() {
      return this.section.activity_html;
    },
    topicNode() {
      return this.getTopicNodeByDescendantId(this.nodeId);
    },
    subtopicNode() {
      return this.getSubtopicNodeByDescendantId(this.nodeId);
    },
    currentSubtopicText() {
      return `${this.topicNode.number}.${this.subtopicNode.number} ${this.subtopicNode.name}`;
    },
    isActivityPageTemplate() {
      return this.section.template === 'activity';
    },
  },
  async created() {
    this.section = await fetchSectionDetails(this.sectionId);
    this.$emit('ready-to-print');
  },
};
</script>
