<template>
  <div
    class="flexContainer flexContainer-alignCenter flexContainer-spaceBetween padd-top-xs padd-bottom-xs padd-left-s padd-right-s margin-top-xxs text-regular kogPlatformGray096-bg border-radius-8"
  >
    <div
      v-if="isEmptyRow"
      class="empty-row flexContainer flexContainer-alignCenter flexContainer-justifyCenter padd-top-xxs padd-bottom-xxs"
    >
      No students to show.
    </div>
    <div
      v-else
      class="flexContainer flexContainer-alignCenter"
    >
      <kog-avatar
        :name="studentName"
        size="m"
        theme="accent"
      />
      <div class="margin-left-s">
        {{ studentName }}
      </div>
    </div>
    <div
      v-if="showExtraColumns"
      class="flexContainer margin-right-s kog-col-4"
      :class="{
        'flexContainer-spaceBetween': hasLevelColumn,
        'flexContainer-flexEnd': !hasLevelColumn,
      }"
    >
      <span v-if="hasLevelColumn">
        {{ studentLevelName }}
      </span>
      <span v-if="hasCompletionColumn">
        {{ completionText }}
      </span>
    </div>
  </div>
</template>

<script>
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';

export default {
  name: 'CompletionStatsModalBodyItem',
  components: {
    KogAvatar,
  },
  props: {
    isEmptyRow: {
      type: Boolean,
      default: false,
    },
    studentName: {
      type: String,
      default: '',
    },
    studentLevelName: {
      type: String,
      default: '',
    },
    hasCompletionColumn: {
      type: Boolean,
      default: false,
    },
    completionText: {
      type: String,
      default: '',
    },
    hasLevelColumn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showExtraColumns() {
      return (this.hasCompletionColumn || this.hasLevelColumn) && !this.isEmptyRow;
    },
  },
};
</script>

<style scoped>
.empty-row {
  width: 100%;
}
</style>
