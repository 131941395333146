<template>
  <kog-modal
    title="Kognity assignment types"
    class="AssignmentTypes"
    :close-modal="closeModal"
  >
    <template #modalBody>
      <div>
        <div class="flexContainer">
          <kog-icon
            class="margin-xs"
            icon-class="fa-pencil-alt"
            fa-style="regular"
            :is-non-actionable="true"
            size="l"
          />
          <div class="AssignmentTypes-description">
            <h5>Question assignment</h5>
            <p>
              Select strength questions for your students to answer. These questions are auto-marked
              and provide data in your statistics.
            </p>
            <p> Normally used to encourage practice and as formative assessment. </p>
          </div>
        </div>
        <div
          v-if="isShowEsq"
          class="flexContainer"
        >
          <kog-icon
            class="margin-xs"
            icon-class="LTQ"
            :is-svg-icon="true"
            :is-non-actionable="true"
            size="l"
          />
          <div class="AssignmentTypes-description">
            <h5>{{ $term('exam-style') }} question assignment</h5>
            <p>
              Select {{ $term('exam-style').toLowerCase() }} questions with mark schemes for your
              students to study.
            </p>
            <p>
              These can help students familiarise themselves with vocabulary and tricky wording, as
              well as general question practice.
            </p>
          </div>
        </div>
        <div class="flexContainer">
          <kog-icon
            class="margin-xs"
            icon-class="fa-bookmark"
            fa-style="regular"
            :is-non-actionable="true"
            size="l"
          />
          <div class="AssignmentTypes-description">
            <h5>Book assignment</h5>
            <p> Select sections of the textbook for your students to complete. </p>
            <p> Great for flipping the classroom and revision prompts. </p>
          </div>
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          class="KogButtonLegacy"
          @click="closeModal"
        >
          Close
        </button>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'AssignmentTypesModal',
  components: {
    KogModal,
    KogIcon,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    isShowEsq: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.AssignmentTypes-description {
  display: block;
  margin-bottom: var(--space-l);
  margin-left: var(--space-xs);
  color: var(--kogPlatformGray051);
}

.AssignmentTypes-description h5 {
  margin-top: 0;
  color: color-mod(var(--kogPlatformGray018) alpha(85%));
}
</style>
