import { mapState } from 'vuex';

import DjangoSettings from 'sharedApp/django-settings-module.ts';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

import BattleStartModal from '../components/battle-start-modal.vue';

const navigateByState = (router, replace, params) => {
  const navigationState = replace ? 'replace' : 'push';
  router[navigationState](params);
};

const BattleMixin = {
  mixins: [RoutesMixin],
  computed: {
    ...mapState('subjectModule', ['subject']),
    numberOfQuestions: {
      get() {
        return DjangoSettings.PRACTICE_SETTINGS.BATTLE_NUMBER_QUESTIONS;
      },
    },
    secondsPerQuestion: {
      get() {
        return DjangoSettings.PRACTICE_SETTINGS.BATTLE_SECONDS_PER_QUESTION;
      },
    },
  },
  methods: {
    navigateToBattleResult({ replace = false, ...params }) {
      return navigateByState(this.$router, replace, this.getResultBattleRoute(params));
    },
    navigateToBattleSetup({ replace = false, ...params }) {
      return navigateByState(this.$router, replace, this.getSetupBattleRoute(params));
    },
    navigateToBattleTake({ replace = false, ...params }) {
      return navigateByState(this.$router, replace, this.getTakeBattleRoute(params));
    },
    getResultBattleRoute(params) {
      return {
        name: 'classBattleResult',
        params,
      };
    },
    getTakeBattleRoute(params) {
      return {
        name: 'classBattleTake',
        params,
      };
    },
    getSetupBattleRoute(params = {}) {
      return {
        name: 'classBattleSetup',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          ...params,
        },
      };
    },
    openBattleModal({ title, onStart, onClose }) {
      this.$modal(
        BattleStartModal,
        {
          subjectName: this.subject.name,
          topicName: title,
          numberOfQuestions: this.numberOfQuestions,
          secondsPerQuestion: this.secondsPerQuestion,
          onStart: typeof onStart === 'function' ? onStart : null,
          onModalClose: typeof onClose === 'function' ? onClose : null,
        },
        {
          attachModalToTop: true,
        },
      );
    },
  },
};

export default BattleMixin;
