import isNil from 'lodash/isNil.js';

export function getSectionHeaderSubtitle(topicSubjectNode, subtopicSubjectNode) {
  const topicHeader = `${topicSubjectNode.formatted_number_including_ancestors} ${topicSubjectNode.name}`;
  if (isNil(subtopicSubjectNode)) {
    return topicHeader;
  }

  return (
    `${topicHeader} / ` +
    `${subtopicSubjectNode.formatted_number_including_ancestors} ${subtopicSubjectNode.name}`
  );
}
