<template>
  <kog-alert
    v-if="shouldShowBox"
    :mode="alertMode"
  >
    <div v-if="isTeacher">
      The below interface is what a student sees, with the exception of hidden content which is
      marked "hidden".
      <br />
      To browse the {{ pageType }} questions as a teacher, go to the
      <a
        class="cursorPointer"
        @click="goToNewAssignment"
        @keypress.enter="goToNewAssignment"
      >
        {{ linkText }} </a
      >. There you can also send out the questions.
    </div>
    <div v-else-if="isStudent">
      {{ $term('exam-style') }} questions help you prepare for exams. The questions become available
      once you have received them in an assignment from your teacher. All questions, sample answers
      and mark schemes have been written by Kognity’s authors. In examinations, the way marks are
      awarded may be different.
    </div>
  </kog-alert>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapState } from 'vuex';

import { createAssignment } from '@apis/schoolstaff-assignments.js';

import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';

export const VALID_SOURCES = {
  STRENGTH_TEST_SETUP: 'strength_test_setup',
  ESQ_PRACTICE_SETUP: 'esq_practice_setup',
  STRENGTH_BATTLE_SETUP: 'strength_battle_setup',
};

export default {
  name: 'PracticeSetupInfo',
  components: {
    KogAlert,
  },
  mixins: [RoutesMixin],
  props: {
    source: {
      type: String,
      required: true,
      validator: val => Object.values(VALID_SOURCES).includes(val),
    },
    assignmentType: {
      type: String,
      required: true,
      validator: val => Object.values(ASSIGNMENT_TYPES).includes(val),
    },
  },
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);

    return {
      isMultipleClassAssignmentEnabled,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    isTeacher() {
      return this.user.isTeacher();
    },
    isStudent() {
      return this.user.isStudent();
    },
    shouldShowBox() {
      return this.isTeacher || (this.isStudent && this.pageType === this.esqTerm);
    },
    alertMode() {
      return this.isStudent ? 'info' : 'warning';
    },
    esqTerm() {
      return this.$term('exam-style').toLowerCase();
    },
    pageType() {
      if (this.source === VALID_SOURCES.STRENGTH_TEST_SETUP) {
        return 'strength test';
      }
      if (this.source === VALID_SOURCES.ESQ_PRACTICE_SETUP) {
        return this.esqTerm;
      }
      return 'strength battle';
    },
    linkText() {
      if (this.assignmentType === ASSIGNMENT_TYPES.EXAMSTYLE) {
        return `${this.esqTerm} question assignments`;
      }

      return 'question assignments';
    },
  },
  methods: {
    async goToNewAssignment() {
      const assignment = await createAssignment({
        assignmentType: this.assignmentType,
        subjectClassId: this.classId,
        user: this.user,
        isMultiClass: this.isMultipleClassAssignmentEnabled,
      });

      const assignmentCreateLocation = {
        name: 'teacherAssignmentCreate',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: assignment.id,
        },
        query: {
          just_created: true,
          source: this.source,
        },
      };

      this.$router.push(assignmentCreateLocation);
    },
  },
};
</script>
