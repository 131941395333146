<template>
  <kog-modal
    :scrolling-long-content="false"
    :close-modal="closeModal"
    :maximize="true"
    title="Extend your free access for 7 days"
    class="ExtendDemoAccessModal"
  >
    <template #modalBody>
      <div v-if="!successfullySubmitted">
        <div class="ExtendDemoAccessModal-body text-regular margin-xl margin-top-xs">
          <p class="margin-0">
            Try Kognity for a few more days and answer the following questions – they will help us
            improve the free access experience:
          </p>
          <div class="kog-row">
            <div class="kog-col-12 margin-top-m">
              <kog-textarea
                v-model:value="kognityUseCase"
                label="What do you see yourself using Kognity for?"
                placeholder="I see myself using Kognity to ..."
                required
              />
            </div>
          </div>
          <div class="kog-row">
            <div class="kog-col-12 margin-top-m">
              <kog-textarea
                v-model:value="reasonPreventingUpgrade"
                label="What’s stopping you from upgrading today?"
                placeholder="I am ..."
                required
              />
            </div>
          </div>
          <div class="KogButtonSet KogButtonSet--right margin-top-l">
            <kog-button
              label="Cancel"
              @click.prevent="closeModal"
            />
            <kog-button
              :disabled="!isSubmittable"
              :is-loading="isSubmitting"
              label="Extend access"
              button-style="primary"
              @click="submit"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="ExtendDemoAccessModal-body text-regular margin-xl margin-top-xs">
          <p> Thanks for your feedback! Your free access was extended for 7 days. </p>
          <div class="flexContainer flexContainer-flexEnd">
            <kog-button
              class="margin-top-m"
              label="Go to demo dashboard"
              button-style="primary"
              @click="goToDashboard"
            />
          </div>
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script setup>
import { computed, inject, ref } from 'vue';
import { useRouter } from 'vue-router';

import { extendDemoSchoolAccess } from '@apis/schools.js';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

const props = defineProps({
  closeModal: {
    type: Function,
    required: true,
  },
});

const mixpanel = inject('mixpanel');
const toast = inject('toast');
const router = useRouter();
const kognityUseCase = ref('');
const reasonPreventingUpgrade = ref('');
const isSubmitting = ref(false);
const successfullySubmitted = ref(false);

const isSubmittable = computed(() => kognityUseCase.value && reasonPreventingUpgrade.value);

const submit = async () => {
  const eventProps = {
    what_kognity_for: kognityUseCase.value,
    what_prevents_upgrading: reasonPreventingUpgrade.value,
  };
  isSubmitting.value = true;
  await mixpanel.trackEventViaBackend('Extend demo access - Submit', eventProps);
  try {
    await extendDemoSchoolAccess();
    successfullySubmitted.value = true;
  } catch {
    toast.showError('Could not extend access!');
    isSubmitting.value = false;
  }
};

const goToDashboard = () => {
  router.replace({ name: 'studyDashboard' });
  props.closeModal();
};
</script>

<style scoped>
.ExtendDemoAccessModal {
  width: 480px;
}
</style>
