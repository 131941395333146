export default class CsvBlobWriter {
  constructor() {
    this.metaData = [];
    this.headers = [];
    this.rows = [];
  }

  /**
   * @param {array} headers - List of csv headers.
   */
  setHeaders(headers) {
    this.headers = headers;
  }

  /**
   * @param {array} row - List of items to append.
   */
  addRow(row) {
    this.rows.push(row);
  }

  /**
   * @param {String} metaData - String of metaData.
   */
  addMetaData(metaData) {
    this.metaData.push(metaData);
  }

  buildBlobContent() {
    let csvContent = '';

    if (this.metaData.length > 0) {
      this.metaData.forEach(meta => {
        csvContent += `${meta}\n`;
      });
    }

    csvContent += `${this.headers.join(',')}\n`;

    this.rows.forEach((row, index) => {
      csvContent += row.join(',');
      const isLastItemInRow = index < this.rows.length - 1;
      if (isLastItemInRow) {
        csvContent += '\n';
      }
    });

    return csvContent;
  }

  getBlob() {
    const blobContent = this.buildBlobContent();
    return new Blob([blobContent], { type: 'text/csv' });
  }
}
