import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState } from 'vuex';

const NodeProgressMixin = {
  data() {
    return {
      submittingNodeProgress: false,
      hasFetchedNodeProgress: false,
    };
  },
  computed: {
    ...mapState('subjectModule', ['subject']),
    ...mapGetters('statisticModule', ['progressStats']),
  },
  watch: {
    subject: {
      async handler() {
        this.hasFetchedNodeProgress = false;
        if (this.subject?.id) {
          await this.fetchSubjectNodesProgress(this.subject.id);
          this.hasFetchedNodeProgress = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapWaitingActions('statisticModule', {
      updateOrCreateNodeProgress: 'loading_book_checkpoint',
    }),
    ...mapActions('statisticModule', ['fetchSubjectNodesProgress']),
    isSubjectNodeCompleted(subjectNodeId) {
      return this.progressStats[subjectNodeId]?.progress_stats?.is_completed;
    },
    async registerNodeProgress(subjectNodeId, isCompleted) {
      this.submittingNodeProgress = true;
      await this.updateOrCreateNodeProgress({
        subjectNodeId,
        isCompleted,
      });
      this.submittingNodeProgress = false;
    },
  },
};

export default NodeProgressMixin;
