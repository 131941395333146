<template>
  <div class="EmptyAssignmentListBox text-center">
    <div>
      <h2 v-if="!hadAssignmentsBefore"> Create an assignment </h2>
      <h2 v-else> No active assignments </h2>
      <div class="EmptyAssignmentListBox_container flexContainer flexContainer-center">
        <div class="EmptyAssignmentListBox_card padd-l">
          <div class="EmptyAssignmentListBox_innerCard flexContainer-spaceBetween">
            <div>
              <i class="fal fa-bookmark fa-3x" />
              <h4 class="EmptyAssignmentListBox_cardTitle margin-bottom-l"> Book assignment </h4>
            </div>
            <kog-button
              label="Create and send"
              button-style="accent"
              @click="onCreateAssignment(assignmentTypes.READING)"
            />
          </div>
          <div class="EmptyAssignmentListBox_cardDescription margin-top-l">
            <p>
              Select <b>sections</b> from the book for your students to read and track their reading
              progress.
            </p>
          </div>
        </div>
        <div
          class="padd-l"
          :class="{
            EmptyAssignmentListBox_cardBig: isShowEsq,
            EmptyAssignmentListBox_card: !isShowEsq,
          }"
        >
          <div class="EmptyAssignmentListBox_innerCard flexContainer-spaceBetween">
            <div>
              <i class="fal fa-pencil-alt fa-3x" />
              <h4 class="EmptyAssignmentListBox_cardTitle margin-bottom-l">
                Question assignment
              </h4>
            </div>
            <kog-button
              label="Create and send"
              button-style="accent"
              @click="onCreateAssignment(assignmentTypes.QUESTION)"
            />
          </div>
          <div class="EmptyAssignmentListBox_cardDescription margin-top-l">
            <p>
              Select <b>strength questions</b> for your students to answer. These questions are
              auto-marked.
            </p>
          </div>
        </div>
        <div
          v-if="isShowEsq"
          class="EmptyAssignmentListBox_card padd-l"
        >
          <div class="EmptyAssignmentListBox_innerCard flexContainer-spaceBetween">
            <div>
              <svg-icon
                icon-name="LTQ"
                size="custom"
                custom-size="42"
              />
              <h4 class="EmptyAssignmentListBox_cardTitle margin-bottom-l">
                {{ $term('exam-style') }} questions
              </h4>
            </div>
            <kog-button
              label="Create and send"
              button-style="accent"
              @click="onCreateAssignment(assignmentTypes.EXAMSTYLE)"
            />
          </div>
          <div class="EmptyAssignmentListBox_cardDescription margin-top-l">
            <p>
              Select <b>{{ $term('exam-style').toLowerCase() }} questions</b> and mark schemes for
              your students to study.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';

import { createAssignment } from '@apis/schoolstaff-assignments.js';

import SelectClassModal from 'learning/common/components/assignment/select-class-modal.vue';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import SvgIcon from 'sharedApp/components/icons/svg-icon.vue';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';

export default {
  name: 'EmptyAssignmentListBox',
  components: {
    KogButton,
    SvgIcon,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    subjectClasses: {
      type: Array,
      required: true,
    },
    hadAssignmentsBefore: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);

    return {
      isMultipleClassAssignmentEnabled,
    };
  },
  data() {
    return {
      assignmentTypes: ASSIGNMENT_TYPES,
    };
  },
  computed: {
    hasSingleClass() {
      return this.subjectClasses.length === 1;
    },
    isShowEsq() {
      return !isNgss(this.subject.educationsystem.name);
    },
  },
  methods: {
    trackMixpanelEvent(name, assignmentType) {
      const eventProperties = {
        assignment_type: assignmentType.toLowerCase(),
      };
      this.$mixpanel.trackEvent(name, eventProperties);
    },
    onCreateAssignment(assignmentType) {
      this.trackMixpanelEvent('Assignment - Create start', assignmentType);
      this.createAssignmentWithSelectClassModal(assignmentType);
    },
    async goToAssignment(assignmentType, subjectClass) {
      const assignment = await createAssignment({
        assignmentType,
        subjectClassId: subjectClass.id,
        user: this.user,
        isMultiClass: this.isMultipleClassAssignmentEnabled,
      });
      this.goToCreateAssignmentPage(assignment, subjectClass);
    },
    assignmentActions(assignmentType) {
      return this.subjectClasses.reduce((actionsMap, subjectClass) => {
        // eslint-disable-next-line no-param-reassign
        actionsMap[subjectClass.id] = () => {
          this.goToAssignment(assignmentType, subjectClass);
        };
        return actionsMap;
      }, {});
    },
    createAssignmentWithSelectClassModal(assignmentType) {
      const actions = this.assignmentActions(assignmentType);
      if (this.hasSingleClass) {
        const action = Object.values(actions)[0];
        return action();
      }
      return this.$modal(SelectClassModal, {
        subjectClasses: this.subjectClasses,
        actions,
      });
    },
    goToCreateAssignmentPage(assignment, subjectClass) {
      const classSlug = subjectClass.slug;
      this.$router.push({
        name: 'teacherAssignmentCreate',
        params: {
          classSlug,
          sid: assignment.subject_id,
          cid: subjectClass.id,
          assignmentId: assignment.id,
        },
        query: {
          just_created: true,
          source: 'empty_assignment_list_box',
        },
      });
    },
  },
};
</script>

<style scoped>
.EmptyAssignmentListBox_subText--noAssignmentsBefore {
  max-width: 380px;
  margin: auto;
}

.EmptyAssignmentListBox_container {
  min-height: 380px;
}

.EmptyAssignmentListBox_cardBig,
.EmptyAssignmentListBox_card {
  width: 225px;
  margin: 10px 10px;

  background-color: var(--kog-background-default-0);
  border-radius: 4px;
  box-shadow: 0 8px 16px -4px var(--kogShadow020);

  transition: transform 0.25s;
}

h4.EmptyAssignmentListBox_cardTitle,
.EmptyAssignmentListBox_cardBig,
.EmptyAssignmentListBox_card {
  color: var(--kogPlatformGray031);
  fill: currentColor;
}

.EmptyAssignmentListBox_cardBig {
  min-height: 320px;
  padding-top: 30px;
}

.EmptyAssignmentListBox_cardBig:hover,
.EmptyAssignmentListBox_card:hover {
  transform: scale(1.1);
  transition: transform 0.5s;
}

.EmptyAssignmentListBox_innerCard {
  display: flex;
  flex-direction: column;
  min-height: 150px;
}

.EmptyAssignmentListBox_cardDescription {
  min-height: 85px;
}
</style>
