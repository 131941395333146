<template>
  <div
    class="TeacherAssignmentTile-container"
    :class="{
      'TeacherAssignmentTile-container--active': !hasAssignmentEnded(props.assignment),
      'TeacherAssignmentTile-container--past': hasAssignmentEnded(props.assignment),
    }"
  >
    <router-link
      :to="assignmentLink"
      class="anchor-unstyled"
    >
      <div class="text-overline text-ellipsis">{{ assignment.subject_name }}</div>
      <h2 class="heading-xs">{{ assignment.name }}</h2>
      <div>
        <span class="heading-xxs">Due: </span>
        <span>{{ formattedDeadline }}</span>
      </div>
      <div>
        <span class="heading-xxs">Type: </span>
        <span>{{ assignmentTypeDisplayName }}</span>
      </div>
      <div v-if="assignment.integration_settings?.points_possible">
        <span class="heading-xxs">Points: </span>
        <span>{{ assignment.integration_settings.points_possible }}</span>
      </div>
      <div class="TeacherAssignmentTile-classList margin-top-s padd-top-xs">
        <span class="text-overline">Assigned to</span>
        <div
          v-for="subjectClass in assignment.subject_classes"
          :key="subjectClass"
          class="flexContainer flexContainer-spaceBetween padd-top-xxs"
        >
          <div
            v-tooltip="{ content: subjectClassesById[subjectClass].name }"
            class="text-ellipsis"
            >{{ subjectClassesById[subjectClass].name }}</div
          >
          <div class="margin-left-l">
            <kog-tag
              is-chip
              :icon-style="
                completionStatusByClassId[subjectClass].completed ===
                completionStatusByClassId[subjectClass].total
                  ? 'solid'
                  : 'regular'
              "
              icon-class="fa-user-check"
              :label="`${completionStatusByClassId[subjectClass].completed} / ${completionStatusByClassId[subjectClass].total}`"
              :context="`${completionStatusByClassId[subjectClass].completed} of ${completionStatusByClassId[subjectClass].total} completed the assignment`"
              size="m"
              type="outline"
              @click="onAssignmentTileCompletionChipClick(subjectClass)"
            />
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { isNil } from 'lodash';
import { intersection } from 'lodash/fp';
import { useStore } from 'vuex';

import type { AssignmentDetails, TeacherSubjectClassDetails } from '@apis/generated/models.ts';
import type { PropType } from 'vue';

import { trackSeeAssignmentProgressFromCard } from 'learning/teach/services/tracking/assignment-tracking.js';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import useModal from 'sharedApp/composables/use-modal.ts';
import {
  getAssignmentTypeDisplayName,
  hasAssignmentEnded,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import AssignmentCompletionStatsModal from 'studyApp/components/subject-class-overview/assignment-completion-stats-modal.vue';
import useClassAssignmentOwnership from 'studyApp/pages/assignment/composables/use-class-assignment-ownership.ts';

type completionStatus = {
  completed: number;
  total: number;
};

type studentCompletionData = {
  user_id: number;
  is_completed: boolean;
};

const props = defineProps({
  assignment: {
    type: Object as PropType<AssignmentDetails>,
    required: true,
  },
});

const store = useStore();
const { findMyClassInAssignment } = useClassAssignmentOwnership();

const { user } = store.state.userModule;
const subjectClassesById = computed<Record<number, TeacherSubjectClassDetails>>(() => {
  return store.getters['subjectClassModule/subjectClassesById'];
});

const formattedDeadline = computed(() => {
  return dateTimeFormatter(props.assignment.deadline);
});

const assignmentTypeDisplayName = computed(() => {
  return getAssignmentTypeDisplayName(
    props.assignment.assignment_type,
    props.assignment.education_system,
  );
});

const assignmentLink = computed(() => {
  const subjectClass = findMyClassInAssignment(props.assignment, user);
  const { subject_id: sid, id: assignmentId } = props.assignment;
  return {
    name: 'teacherAssignmentDetails',
    params: {
      classSlug: subjectClass.slug,
      sid,
      cid: subjectClass.id,
      assignmentId,
    },
  };
});

const assignmentStudentUserIds = computed(() => {
  return props.assignment.student_user_completion_data.map(
    (student: studentCompletionData) => student.user_id,
  );
});

const assignmentCompletedStudentUserIds = computed(() => {
  return props.assignment.student_user_completion_data
    .filter((student: studentCompletionData) => student.is_completed)
    .map((student: studentCompletionData) => student.user_id);
});

const completionStatusByClassId = computed<Record<number, completionStatus>>(() => {
  return props.assignment?.subject_classes.reduce(
    (acc, classId) => {
      const subjectClass = subjectClassesById.value[classId];
      if (isNil(subjectClass)) {
        acc[classId] = {
          completed: 0,
          total: 0,
        };
        return acc;
      }

      const studentInAssignmentAndClassUserIds = intersection(
        subjectClass.student_user_ids,
        assignmentStudentUserIds.value,
      );
      acc[classId] = {
        completed:
          intersection(studentInAssignmentAndClassUserIds, assignmentCompletedStudentUserIds.value)
            .length ?? 0,
        total: studentInAssignmentAndClassUserIds.length,
      };
      return acc;
    },
    {} as Record<number, completionStatus>,
  );
});

const { openModal } = useModal();
const onAssignmentTileCompletionChipClick = (subjectClassId: number) => {
  const subjectClass = subjectClassesById.value[subjectClassId];
  openModal(AssignmentCompletionStatsModal, {
    assignment: props.assignment,
    assignmentStudentUserIds: assignmentStudentUserIds.value,
    assignmentCompletedStudentUserIds: assignmentCompletedStudentUserIds.value,
    educationSystemName: props.assignment.education_system,
    subjectClassId,
    possibleLevels: subjectClass.possible_levels,
  });

  trackSeeAssignmentProgressFromCard({
    assignment: props.assignment,
    assignmentStudentCount: assignmentStudentUserIds.value.length,
    assignmentCompletedStudentCount: assignmentCompletedStudentUserIds.value.length,
  });
};
</script>

<style scoped>
.TeacherAssignmentTile-container {
  padding: var(--space-s);
  border-radius: 12px;
}

.TeacherAssignmentTile-container--active {
  background: var(--kogPlatformGray096);
  border: 1px solid var(--kogPlatformGray090);
}

.TeacherAssignmentTile-container--active:hover {
  border-color: var(--kogPlatformGray071);
}

.TeacherAssignmentTile-container--past {
  background: var(--kog-colors-green-100);
  border: 1px solid var(--kog-colors-green-100);
}

.TeacherAssignmentTile-container--past:hover {
  border-color: var(--kog-colors-green-300);
}

.TeacherAssignmentTile-classList {
  border-top: 1px solid var(--kogPlatformGray077);
}
</style>
