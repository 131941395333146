<template>
  <div>
    <div class="heading-s flexContainer flexContainer-spaceBetween margin-top-m margin-bottom-xs">
      <kog-button
        :id="showPastBattlesButtonId"
        :has-dropdown="true"
        :is-dropdown-open="expanded"
        :controls-element-id="pastBattlesContainerId"
        button-style="basic"
        label="Past battles"
        @click="onExpandPastBattlesClick"
      />
      <battles-statistics v-if="expanded" />
    </div>
    <battles-list
      v-show="expanded"
      :id="pastBattlesContainerId"
      :aria-labelledby="showPastBattlesButtonId"
      :battles="pastBattles"
      empty-message="You have no finished battles yet."
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';

import BattlesList from './battles-list.vue';
import BattlesStatistics from './battles-statistics.vue';

export default {
  name: 'PastBattles',
  components: {
    KogButton,
    BattlesList,
    BattlesStatistics,
  },
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapState('battlesModule', ['pastBattles']),
    pastBattlesContainerId() {
      return this.getUniqueId('past-battles-container');
    },
    showPastBattlesButtonId() {
      return this.getUniqueId('show-past-battles');
    },
  },
  methods: {
    onExpandPastBattlesClick() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
