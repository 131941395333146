<template>
  <kog-modal
    :maximize="true"
    :close-modal="closeModal"
    :header-icon="modalHeaderIcon"
    :title="modalHeadings.title"
    :subtitle="modalHeadings.subtitle"
  >
    <template #modalBody>
      <div class="padd-left-xl padd-right-xl padd-top-s ConnectGoogleClassroomModal-body">
        <kog-loader
          v-if="isConnectClicked"
          :loading="$wait.is('associating_google_classes') && $wait.is('loading_class_students')"
          loading-msg="Saving"
        />
        <kog-loader
          v-else-if="isSelectStep"
          :loading="$wait.is('polling_google_classes')"
          loading-msg="Retrieving your classes"
        >
          <google-classroom-warning-box
            v-if="googleRequestError"
            :warning-code="googleRequestError"
            :origin="origin"
            :subject-class-id="subjectClass.id"
            :assignment-id="assignmentId"
            request-type="connect_classes"
          />
          <span v-else>
            <div
              v-if="!isAnyClassExisting"
              class="text-regular muted"
            >
              We couldn't find any Google Classroom classes associated with this account
            </div>
            <div v-else>
              <div class="text-regular muted margin-bottom-s"> Your Google classes </div>
              <div class="flexContainer flexContainer-wrap">
                <div
                  v-for="(cls, idx) in googleClasses"
                  :key="cls.id"
                  class="width-50-percent flexContainer flexContainer-alignCenter margin-bottom-xl"
                >
                  <span
                    v-tooltip="{
                      theme: 'kog-tooltip',
                      content: gcTooltipText(cls.id),
                      popperClass: 'text-center',
                      boundary: 'window',
                    }"
                    :aria-label="gcTooltipText(cls.id, true)"
                    class="margin-right-s"
                  >
                    <input
                      :id="cls.id"
                      v-model="selectedClasses"
                      :value="cls"
                      type="checkbox"
                      :disabled="isAlreadyConnected(cls.id)"
                      class="cursorPointer"
                    />
                  </span>
                  <label
                    :for="cls.id"
                    class="ConnectGoogleClassroomModal-classBox flexContainer flexContainer-column flexChild-canGrow"
                    :class="{
                      'margin-right-m': idx % 2 === 0,
                      cursorPointer: !isAlreadyConnected(cls.id),
                    }"
                  >
                    <div
                      class="padd-m heading-m text-white ConnectGoogleClassroomModal-classBox-title"
                    >
                      {{ cls.name }}
                    </div>
                    <div
                      class="padd-s muted text-normal flexChild-canGrow flexContainer flexContainer-column"
                    >
                      <div>{{ totalStudents(cls.students) }}</div>
                      <div>{{ totalTeachers(cls.total_teachers) }}</div>
                      <div
                        v-if="isAlreadyConnected(cls.id)"
                        class="padd-top-s text-small margin-top-auto"
                      >
                        Already connected
                      </div>
                      <div
                        v-else-if="cls.connected_to.length > 0"
                        class="padd-top-s text-small margin-top-auto"
                      >
                        <span
                          v-tooltip="{
                            theme: 'kog-tooltip',
                            content: gcAlsoConnectedToTooltipMsg(cls.connected_to),
                            popperClass: 'text-center',
                            boundary: 'window',
                          }"
                          :aria-label="gcAlsoConnectedToTooltipMsg(cls.connected_to, true)"
                        >
                          {{ gcAlsoConnectedToMsg(cls.connected_to) }}
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </span>
        </kog-loader>
        <connect-google-classroom-review-step
          v-else
          :selected-google-classes="newSelectedClasses"
          :student-list="studentsList"
        />
      </div>
    </template>
    <template #modalFooter>
      <div class="flexChild-canGrow">
        <div class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter">
          <span class="margin-right-xl muted">
            Review connections in 'Manage Class and Students'
          </span>
          <button
            v-if="isSelectStep"
            :disabled="!isAnyNewClassSelected"
            class="KogButtonLegacy KogButtonLegacy--primary"
            @click="toggleSelectStep()"
          >
            Next
          </button>
          <div
            v-else
            class="KogButtonSet KogButtonSet--right"
          >
            <button
              class="KogButtonLegacy"
              @click="toggleSelectStep()"
            >
              Back
            </button>
            <button
              class="KogButtonLegacy KogButtonLegacy--primary"
              @click="connectClasses()"
            >
              Connect
            </button>
          </div>
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { VTooltip } from 'floating-vue';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapState } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import googleClassroomIcon from 'sharedApp/img/google-classroom-yellow-icon-64x64_2x.png';
import ConnectGoogleClassroomReviewStep from 'studyApp/components/teacher/connect-google-classroom-review-step.vue';
import GoogleClassroomWarningBox from 'studyApp/components/teacher/google-classroom-warning-box/google-classroom-warning-box.vue';
import {
  GOOGLE_CLASSROOM_PROVIDER_NAME,
  IMPORT_CLASSES_RETRY_COUNT,
  IMPORT_CLASSES_RETRY_RATE_MS,
} from 'studyApp/constants/google-classroom-constants.js';
import { SUBJECT_CLASS_STUDENTS_MODULE } from 'studyApp/store/modules/subject-class-students.js';

export default {
  name: 'ConnectGoogleClassroomModal',
  components: {
    KogModal,
    KogLoader,
    GoogleClassroomWarningBox,
    ConnectGoogleClassroomReviewStep,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    connectGoogleClass: {
      type: Function,
      required: true,
    },
    subjectClass: {
      type: Object,
      required: true,
    },
    origin: {
      type: String,
      required: true,
    },
    assignmentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedClasses: [],
      isConnectClicked: false,
      isSelectStep: true,
      modalHeaderIcon: {
        type: 'image',
        imgUrl: googleClassroomIcon,
        ariaLabel: 'Google Classroom Icon',
      },
    };
  },
  computed: {
    ...mapState({
      studentsList: state => state[SUBJECT_CLASS_STUDENTS_MODULE].studentsList,
      googleClasses: state => state.teacherGoogleClassroomModule.classes,
      googleRequestError: state => state.teacherGoogleClassroomModule.requestError,
    }),
    connectedGoogleClassIds() {
      return this.subjectClass.google_classes.map(gClass => gClass.id);
    },
    newSelectedClasses() {
      return this.selectedClasses.filter(
        selectedClass => !this.connectedGoogleClassIds.includes(selectedClass.id),
      );
    },
    isAnyNewClassSelected() {
      return this.newSelectedClasses.length > 0;
    },
    isAnyClassExisting() {
      return this.googleClasses.length > 0;
    },
    gcAssociationSuccessMsg() {
      const googleClassesNames = this.newSelectedClasses.map(gClass => gClass.name);
      let text = `Class <b>${this.subjectClass.name}</b> connected to`;

      if (googleClassesNames.length > 2) {
        text = `${text} ${googleClassesNames[0]}, ${googleClassesNames[1]},`;
        text = `${text} and ${googleClassesNames.length - 2} `;
        const isMoreThanThree = googleClassesNames.length > 3;
        text = `${text} other${isMoreThanThree ? 's' : ''}`;
      } else {
        googleClassesNames.forEach((className, index) => {
          if (index === 1) {
            text = `${text},`;
          }
          text = `${text} ${className}`;
        });
      }

      return text;
    },
    modalHeadings() {
      if (this.isSelectStep) {
        return {
          title: this.subjectClass.name,
          subtitle: 'Select which Google Classroom classes you want to connect to',
        };
      }
      return {
        title: 'Review connections',
        subtitle: `You are about to connect the following students to ${this.subjectClass.name}`,
      };
    },
  },
  async mounted() {
    this.fetchStudentsList(this.subjectClass.id);
    await this.importGoogleClasses();
    this.selectedClasses = this.googleClasses.filter(gc =>
      this.connectedGoogleClassIds.includes(gc.id),
    );
  },
  methods: {
    async importGoogleClasses() {
      this.$wait.start('polling_google_classes');
      await this.fetchGoogleClasses({
        classId: this.subjectClass.id,
        pollingIntervalMs: IMPORT_CLASSES_RETRY_RATE_MS,
        maxPollCount: IMPORT_CLASSES_RETRY_COUNT,
        timeoutMessage: 'Connecting to google classroom took more than 5 minutes',
      });
      this.$wait.end('polling_google_classes');
    },
    ...mapWaitingActions('teacherGoogleClassroomModule', {
      associateGoogleClasses: 'associating_google_classes',
    }),
    ...mapWaitingActions(SUBJECT_CLASS_STUDENTS_MODULE, {
      fetchStudentsList: 'loading_class_students',
    }),
    ...mapActions('teacherGoogleClassroomModule', ['fetchGoogleClasses']),
    toggleSelectStep() {
      this.isSelectStep = !this.isSelectStep;
    },
    totalStudents(students) {
      const total = students.length;
      return total === 1 ? '1 student' : `${total} students`;
    },
    totalTeachers(total) {
      return total === 1 ? '1 teacher' : `${total} teachers`;
    },
    sendEventClassConnected() {
      const googleStudentsEmails = [];
      this.newSelectedClasses.forEach(cls => {
        cls.students.forEach(student => {
          if (!googleStudentsEmails.includes(student.email)) {
            googleStudentsEmails.push(student.email);
          }
        });
      });

      const kognityStudentsEmails = this.studentsList.map(student => student.email);
      const matchedStudentEmails = kognityStudentsEmails.filter(email =>
        googleStudentsEmails.includes(email),
      );

      const totalStudentsInGoogleClassroom = googleStudentsEmails.length;
      const totalStudentsInKognity = kognityStudentsEmails.length;
      const isSameNumberOfStudents = totalStudentsInGoogleClassroom === totalStudentsInKognity;
      const totalMatchingEmails = matchedStudentEmails.length;
      this.$mixpanel.trackEvent('Class connected', {
        source: this.origin,
        subject_class_id: this.subjectClass.id,
        subject_class_name: this.subjectClass.name,
        connected_to: GOOGLE_CLASSROOM_PROVIDER_NAME,
        total_students_in_google_classroom: totalStudentsInGoogleClassroom,
        total_students_in_kognity: totalStudentsInKognity,
        is_same_number_of_students: isSameNumberOfStudents,
        total_matching_emails: totalMatchingEmails,
      });
    },
    async connectClasses() {
      this.isConnectClicked = true;
      const classId = this.subjectClass.id;
      const googleClasses = this.newSelectedClasses.map(gClass => ({
        id: gClass.id,
        name: gClass.name,
        students: gClass.students,
      }));
      await this.associateGoogleClasses({ classId, googleClasses });

      this.$toast.showSuccess(this.gcAssociationSuccessMsg);

      this.sendEventClassConnected();
      this.connectGoogleClass(googleClasses);
      this.closeModal();
    },
    isAlreadyConnected(classId) {
      return this.connectedGoogleClassIds.includes(classId);
    },
    gcTooltipText(classId, noMarkup = false) {
      if (!this.isAlreadyConnected(classId)) {
        return '';
      }
      if (noMarkup) {
        return 'This class is already connected. You can disconnect classes in the Manage Class Page.';
      }

      return `
      <div class="margin-xs text-center">
        <p>
          This class is already connected.
        </p>
        <p>
          You can disconnect classes in the Manage Class Page.
        </p>
      </div>`;
    },
    gcAlsoConnectedToTooltipMsg(otherConnectedClasses, noMarkup = false) {
      let text = 'Also connected to:';
      otherConnectedClasses.forEach((connectedClass, index) => {
        if (noMarkup) {
          text = index === 0 ? `${text} ${connectedClass}` : `${text}, ${connectedClass}`;
        } else {
          text = `${text} <br> ${connectedClass}`;
        }
      });
      return text;
    },
    gcAlsoConnectedToMsg(otherConnectedClasses) {
      const text = `Connected to ${otherConnectedClasses.length} other`;
      return otherConnectedClasses.length > 1 ? `${text} classes` : `${text} class`;
    },
  },
};
</script>

<style scoped>
.ConnectGoogleClassroomModal-body {
  min-height: 100px;
  background: var(--kogPlatformGray096);
}

.ConnectGoogleClassroomModal-classBox {
  overflow: hidden;

  min-height: 240px;

  background-color: var(--kogPlatformWhite);
  border: 1px solid var(--kogPlatformGray077);
  border-radius: 8px;
}

.ConnectGoogleClassroomModal-classBox-title {
  height: 100px;
  background-color: var(--googleClassroomGreen);
}

.ConnectGoogleClassroomModal-icon {
  width: 56px;
  height: 56px;
}
</style>
