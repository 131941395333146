<template>
  <div
    class="padd-top-m QuestionAssignmentDetailsTabs flexContainer flexContainer-column flexChild-canGrow"
  >
    <kog-tabs
      v-model:value="activeTab"
      :tabs="tabs"
    />
    <kog-tab-panels
      :value="activeTab"
      class="flexContainer flexContainer-column flexChild-canGrow QuestionAssignmentDetailsTabs-tabContainer"
    >
      <kog-tab-panel name="questions">
        <div
          class="kog-container-fluid container-wideWidth flexContainer flexContainer-column flexChild-canGrow padd-top-s padd-bottom-xxl padd-left-xl padd-right-xl"
        >
          <assignment-details-questions
            :assignment="assignment"
            :show-difficulty="showDifficulty"
          />
        </div>
      </kog-tab-panel>
      <kog-tab-panel
        name="students"
        :class="{
          'flexContainer flexContainer-column flexChild-canGrow': !assignment.is_scheduled_to_send,
        }"
      >
        <div
          v-if="!assignment.is_scheduled_to_send"
          class="flexContainer flexContainer-column flexChild-canGrow padd-top-xxl padd-bottom-xxl padd-left-s padd-right-s"
        >
          <suspense>
            <template #default>
              <question-assignment-details-students
                :assignment="assignment"
                :show-difficulty="showDifficulty"
              />
            </template>

            <template #fallback>
              <kog-loader
                :loading="true"
                loading-msg="Loading students data..."
              />
            </template>
          </suspense>
        </div>
        <basic-assignment-details-student-table
          v-else-if="assignment.students.length > 0"
          class="container-wideWidth padd-top-s padd-bottom-xxl padd-left-xl padd-right-xl"
          :assignment="assignment"
        />
        <p
          v-else
          class="text-center padd-top-s"
        >
          This assignment hasn't been sent to any students.
        </p>
      </kog-tab-panel>
    </kog-tab-panels>
  </div>
</template>

<script>
import pluralize from 'pluralize';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogTabPanel from 'sharedApp/components/base/tabs/kog-tab-panel.vue';
import KogTabPanels from 'sharedApp/components/base/tabs/kog-tab-panels.vue';
import KogTabs from 'sharedApp/components/base/tabs/kog-tabs.vue';
import AssignmentDetailsQuestions from 'studyApp/components/teacher/assignments/details/assignment-details-questions.vue';
import QuestionAssignmentDetailsStudents from 'studyApp/components/teacher/assignments/details/question-assignment-details-students.vue';
import BasicAssignmentDetailsStudentTable from 'studyApp/components/teacher/assignments/details/table/basic-assignment-details-student-table.vue';

export default {
  name: 'QuestionAssignmentDetailsTabs',
  components: {
    AssignmentDetailsQuestions,
    BasicAssignmentDetailsStudentTable,
    KogLoader,
    KogTabs,
    KogTabPanel,
    KogTabPanels,
    QuestionAssignmentDetailsStudents,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 'questions',
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: 'questions',
          label: this.questionsTabLabel,
          onTabSelected: this.onTabSelected,
        },
        {
          name: 'students',
          label: this.studentsTabLabel,
          onTabSelected: this.onTabSelected,
        },
      ];
    },
    totalQuestions() {
      return this.assignment.questions.length;
    },
    totalStudents() {
      return this.assignment.students.length;
    },
    questionsTabLabel() {
      return `${pluralize('Question', this.totalQuestions)} (${this.totalQuestions})`;
    },
    studentsTabLabel() {
      return `${pluralize('Student', this.totalStudents)} (${this.totalStudents})`;
    },
    defaultTab() {
      return this.$route.query.tab;
    },
  },
  mounted() {
    if (this.defaultTab) {
      this.activeTab = this.defaultTab;
    }
  },
  methods: {
    onTabSelected() {
      this.trackTabChange();
    },
    trackTabChange() {
      this.$mixpanel.trackEvent('Assignment Details - Switch tab', {
        tab: this.activeTab,
        assignment_type: this.assignment.assignment_type.toLowerCase(),
      });
    },
  },
};
</script>
<style scoped>
.QuestionAssignmentDetailsTabs-tabContainer {
  background-color: var(--kogPlatformGray098);
}
</style>
