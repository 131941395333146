import { inject } from 'vue';

import * as api from '@apis/questions';

import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import useModal from 'sharedApp/composables/use-modal.ts';
import {
  CREATED_BY_TEACHER_PLAIN_TEXT_TAG,
  QUESTION_TYPE_ABBREVIATIONS,
  QUESTION_TYPES,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { trackTeacherQuestionDelete } from 'teachApp/services/tracking/assignment-tracking.js';

export default function useTeacherQuestions() {
  const toast = inject('toast');
  const modal = useModal();

  const canEditQuestion = question => {
    return question.tags.includes(CREATED_BY_TEACHER_PLAIN_TEXT_TAG);
  };

  const handleDeleteTeacherQuestion = async (
    question,
    subjectId,
    trackingOptions,
    questionDeletedCallback,
  ) => {
    try {
      await api.deleteTeacherQuestion(subjectId, question.id, question.type);
    } catch (error) {
      toast.showError(`There was an error deleting the question, please try again. ${error}`);
      return;
    }
    questionDeletedCallback(question);
    const trackingType =
      question.type === QUESTION_TYPES.LTQ ? 'ESQ' : QUESTION_TYPE_ABBREVIATIONS[question.type];
    trackTeacherQuestionDelete({
      ...trackingOptions,
      questionType: trackingType,
    });
  };

  const deleteTeacherQuestion = (question, subjectId, trackingOptions, questionDeletedCallback) => {
    modal.openModal(KogConfirmModal, {
      title: 'Delete question?',
      text: 'The question will be permanently removed.',
      buttonTitle: 'Yes, delete',
      onConfirm: () =>
        handleDeleteTeacherQuestion(question, subjectId, trackingOptions, questionDeletedCallback),
    });
  };

  return { deleteTeacherQuestion, canEditQuestion };
}
