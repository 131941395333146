<template>
  <div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, provide, withDefaults } from 'vue';

import type { KogTabPanelsProps } from './kog-tab-panels.types.ts';

import { PanelInjectionKey } from './kog-tab-panels.types.ts';

const props = withDefaults(defineProps<KogTabPanelsProps>(), {
  value: '',
});

provide(
  PanelInjectionKey,
  computed(() => props.value),
);
</script>
