import { ContextualBalloon } from '@ckeditor/ckeditor5-ui';

import clipboardQuestion from 'publishingApp/assets/icons/clipboard-question-regular.svg';

import KogUI from '../utils/kogui.ts';
import { registerFactoryView } from '../utils/plugin-utils.ts';
import FormView from './exercise-question-form.js';

export default class ExerciseQuestionUI extends KogUI {
  static get requires() {
    return [ContextualBalloon];
  }

  init() {
    this.balloon = this.editor.plugins.get(ContextualBalloon);
    this.callbacks = this.editor.config.get('exerciseQuestion.callbacks');
    const command = this.editor.commands.get('insertExerciseQuestion');
    this.formView = null;
    command.forceDisabled();

    registerFactoryView(this.editor, {
      name: 'exerciseQuestion',
      commandName: 'insertExerciseQuestion',
      icon: clipboardQuestion,
      label: 'Exercise question',
      onExecute: () => {
        if (this.exerciseQuestions?.length > 0) {
          this.showUI();
        }
      },
    });

    const updateCommand = this.editor.commands.get('updateExerciseQuestion');
    this.listenTo(updateCommand, 'execute', () => {
      this.showUI();
    });

    this.exerciseQuestions = this.callbacks.getExerciseQuestions();
    if (this.exerciseQuestions.length > 0) {
      command.clearForceDisabled();
    }
  }

  showUI() {
    if (!this.formView) {
      this.createFormView();
    }
    const commandValue = this.editor.commands.get('insertExerciseQuestion').value;
    if (commandValue) {
      const { questionId } = commandValue;
      this.formView.setFields(questionId);
    }

    super.showUI();
  }

  createFormView() {
    this.formView = new FormView(this.editor.locale, this.exerciseQuestions);

    const onSubmit = () => {
      const questionId = this.formView.selectedQuestionId;
      this.editor.execute('insertExerciseQuestion', questionId);
      this.hideUI();
    };

    this.setupFormView(onSubmit);
  }
}
