import { Command } from '@ckeditor/ckeditor5-core';

import { findNonParagraphAncestor } from '../utils/commands.js';
import { ANYBOX_TYPES } from './content.js';

export default class InsertAnyBoxCommand extends Command {
  execute(configuration) {
    if (!configuration) {
      throw new Error('The configuration object is required.');
    }

    const { model } = this.editor;
    model.change(writer => {
      const selection = findNonParagraphAncestor(writer, model.document.selection);
      const updatedConfiguration = {
        ...configuration,
        contentNode: this.value?.contentNode,
      };
      model.insertObject(this.create(writer, updatedConfiguration), selection);
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(document.selection.getFirstPosition(), 'anyBox');
    this.isEnabled = allowedIn !== null;

    const firstRange = document.selection.getFirstRange();
    if (!firstRange.isCollapsed && firstRange.start.nodeAfter?.name === 'anyBox') {
      const node = firstRange.start.nodeAfter;
      const titleNode = node.getChild(1);
      const contentNode = node?.getChild(2);

      const value = {
        boxType: node.getAttribute('boxType'),
        headingLevel: titleNode?.getAttribute('headingLevel'),
        contentNode,
      };
      this.value = value;
    } else {
      this.value = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer, { boxType, headingLevel, contentNode }) {
    const boxTitle = ANYBOX_TYPES[boxType]?.title || '';

    const anyBox = writer.createElement('anyBox', { boxType });
    const anyBoxIcon = writer.createElement('iconDiv');
    const anyBoxTitle = writer.createElement('anyBoxTitle', { headingLevel });
    let anyBoxContent;
    if (contentNode) {
      anyBoxContent = writer.cloneElement(contentNode);
    } else {
      anyBoxContent = writer.createElement('anyBoxContent');
      const content = writer.createElement('paragraph');
      const textToInsert = 'Add some content here...';
      writer.insertText(textToInsert, content);
      writer.append(content, anyBoxContent);
    }

    let anyBoxTitleHeading = null;
    if (headingLevel === 'span') {
      anyBoxTitleHeading = writer.createText(boxTitle, { spanKogId: 'new' });
    } else {
      anyBoxTitleHeading = writer.createElement(headingLevel);
      writer.insertText(boxTitle, anyBoxTitleHeading);
    }

    writer.append(anyBoxIcon, anyBox);
    writer.append(anyBoxTitle, anyBox);
    writer.append(anyBoxTitleHeading, anyBoxTitle);
    writer.append(anyBoxContent, anyBox);

    return anyBox;
  }
}
