<template>
  <div class="flexContainer flexContainer-center">
    <button
      v-for="(color, index) in colorList"
      :key="index"
      :class="{
        'KogMultiColorSelector-container--unselected': !isSelected(color.colorName),
        'KogMultiColorSelector-container--selected':
          isSelected(color.colorName) && theme === 'light',
        'KogMultiColorSelector-containerDark--selected':
          isSelected(color.colorName) && theme === 'dark',
      }"
      class="KogMultiColorSelector-container cursorPointer KogButtonLegacy--noStyle"
      @click="toggleColor(color.colorName)"
    >
      <div
        :class="[
          color.colorClass,
          {
            'KogMultiColorSelector-circle--unselected': !isSelected(color.colorName),
            'KogMultiColorSelector-circle--selected':
              isSelected(color.colorName) && theme === 'light',
            'KogMultiColorSelector-circleDark--selected':
              isSelected(color.colorName) && theme === 'dark',
          },
        ]"
        class="KogMultiColorSelector-circle"
      />
    </button>
  </div>
</template>

<script>
import { getHighlightClass, highlightColors } from 'learning/common/libs/annotations-utils.js';

export default {
  name: 'KogMultiColorSelector',
  props: {
    selectedColorsModel: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  emits: ['change', 'update:selectedColorsModel'],
  data() {
    const selectedColors = this.selectedColorsModel.reduce((acc, c) => {
      acc[c] = true;
      return acc;
    }, {});
    return {
      colorList: highlightColors.map(color => ({
        colorName: color,
        colorClass: getHighlightClass(color, false, true),
      })),
      selectedColors,
    };
  },
  methods: {
    isSelected(colorName) {
      return this.selectedColors[colorName];
    },
    toggleColor(colorName) {
      this.selectedColors[colorName] = !this.selectedColors[colorName];
      const onlySelected = Object.keys(this.selectedColors).filter(c => this.selectedColors[c]);
      this.$emit('change', onlySelected);
      this.$emit('update:selectedColorsModel', onlySelected);
    },
  },
};
</script>

<style scoped>
.KogMultiColorSelector-container {
  margin-right: 15px;
}

.KogMultiColorSelector-container:last-of-type {
  margin-right: 0;
}

.KogMultiColorSelector-circle {
  width: 18px;
  height: 18px;
  border-radius: 9px;
}

.KogMultiColorSelector-container--unselected {
  border: 2px solid var(--kogTransparent);
  border-radius: 12px;
}

.KogMultiColorSelector-container--selected {
  border: 2px solid var(--kogPlatformGray031);
  border-radius: 12px;
}

.KogMultiColorSelector-circleDark--selected {
  border: 1px solid var(--kogPlatformGray031);
}

.KogMultiColorSelector-containerDark--selected {
  border: 2px solid var(--kogPlatformWhite);
  border-radius: 12px;
}

.KogMultiColorSelector-green {
  background: var(--kogPlatformGreenLighten10);
}

.KogMultiColorSelector-yellow {
  background: var(--kogPlatformYellowBase);
}

.KogMultiColorSelector-blue {
  background: var(--kogPlatformBlueLighten10);
}

.KogMultiColorSelector-peach {
  background: var(--kogPlatformRedBase);
}
</style>
