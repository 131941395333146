<template>
  <div class="AssignmentArrangeList">
    <arrange-questions-list
      v-if="items.length > 0 && isQuestion"
      :questions="items"
      :remove-question="removeItemModal"
      :update-questions="updateItems"
      :show-difficulty="showDifficulty"
      :get-question-locations="getQuestionLocations"
      :subject-name="subjectName"
      :expanded="expanded"
    />
    <arrange-sections-list
      v-if="items.length > 0 && isReading"
      :sections="items"
      :remove-section="removeItemModal"
      :update-sections="updateItems"
      :expanded="expanded"
      :hidden-subject-node-ids="hiddenSubjectNodeIds"
    />
    <arrange-esq-list
      v-if="items.length > 0 && isExamStyle"
      :is-preparing-questions="isPreparingQuestions"
      :questions="items"
      :remove-question="removeItemModal"
      :update-questions="updateItems"
      :subject-name="subjectName"
      :expanded="expanded"
      :education-system="educationSystem"
    />
    <div
      v-if="items.length === 0"
      class="text-center kogPlatformGray051"
    >
      <div>
        {{ noItemsSelectedMsg }}
      </div>
    </div>
    <div class="text-center">
      <button
        v-if="items.length > 1"
        class="KogButtonLegacy KogButtonLegacy--link"
        @click="expandToggle"
      >
        {{ expandToggleMsg }}
        <i
          v-if="expanded"
          class="fas fa-chevron-up padd-left-xxs"
        />
        <i
          v-else
          class="fas fa-chevron-down padd-left-xxs"
        />
      </button>
    </div>
  </div>
</template>

<script>
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import {
  isExamStyleType,
  isQuestionType,
  isReadingType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';

import ArrangeEsqList from './arrange-esq-list.vue';
import ArrangeQuestionsList from './arrange-questions-list.vue';
import ArrangeSectionsList from './arrange-sections-list.vue';

export default {
  name: 'AssignmentArrangeList',
  components: {
    ArrangeQuestionsList,
    ArrangeSectionsList,
    ArrangeEsqList,
  },
  props: {
    assignmentType: {
      type: String,
      required: true,
    },
    getQuestionLocations: {
      type: Function,
      required: true,
    },
    isPreparingQuestions: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    removeItem: {
      type: Function,
      required: true,
    },
    updateItems: {
      type: Function,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      default: false,
    },
    subjectName: {
      type: String,
      required: true,
    },
    educationSystem: {
      type: String,
      required: true,
    },
    hiddenSubjectNodeIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    isQuestion() {
      return isQuestionType(this.assignmentType);
    },
    isReading() {
      return isReadingType(this.assignmentType);
    },
    isExamStyle() {
      return isExamStyleType(this.assignmentType);
    },
    expandToggleMsg() {
      if (!this.expanded) {
        return `Show all ${this.items.length}`;
      }
      return 'Show less';
    },
    noItemsSelectedMsg() {
      if (this.isReading) {
        return 'No sections selected, you can add sections in the previous step';
      }
      return 'No questions selected, you can add questions in the previous step';
    },
  },
  methods: {
    removeItemModal(item) {
      const itemText = this.isReading ? 'section' : 'question';

      this.$modal(KogConfirmModal, {
        title: `Remove ${itemText} from assignment?`,
        text: `Remove this ${itemText} from the assignment. You can add it by going back to the previous step and selecting it again.`,
        buttonTitle: `Remove ${itemText}`,
        buttonClass: 'KogButtonLegacy--danger',
        onConfirm: () => {
          this.removeItem(item);
        },
      });
    },
    expandToggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped>
.AssignmentArrangeList :deep(.ArrangeList_tableRowNum) {
  min-width: 45px;
}

.AssignmentArrangeList :deep(.ArrangeList_tableRow) {
  height: 50px;
  margin-top: -1px;
  padding: var(--space-xxs) var(--space-l);
  border: 1px solid var(--kogPlatformGray084);
}

.AssignmentArrangeList :deep(.ArrangeList_tableRow--first) {
  border-radius: 8px 8px 0 0;
}

.AssignmentArrangeList :deep(.ArrangeList_tableRow--last) {
  border-radius: 0 0 8px 8px;
}

.AssignmentArrangeList :deep(.ArrangeList_tableRow--only) {
  border-radius: 8px;
}
</style>
