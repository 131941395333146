<template>
  <kog-card
    title="Grading Overview"
    icon-class="fa-pen-ruler"
    class="margin-top-l margin-left-l"
    :is-collapsible="true"
    :is-content-scrolling="true"
  >
    <div class="margin-bottom-xl">
      <div class="heading-xxs text-overline"> Settings </div>
      <div class="flexContainer flexContainer-spaceBetween margin-top-s">
        <span>Anonymous Grading</span>
        <kog-toggle-button
          :is-pressed="isAnonymized"
          @toggled="value => $emit('toggle-anonymized', value)"
        />
      </div>
    </div>
    <div class="margin-bottom-xl">
      <activity-submission-class-switcher />
    </div>
    <div
      id="performance-task-submission-sidebar-heading"
      class="PerformanceTaskSubmissionSidebar-heading flexContainer font-weight-medium"
    >
      <span class="flexChild-size-1">STUDENT</span>
      <span>TOTAL MARKS</span>
    </div>
    <kog-item-list
      role="list"
      class="margin-top-m list-style-none PerformanceTaskSubmissionSidebar-studentList"
      aria-labelledby="performance-task-submission-sidebar-heading"
      :is-handling-tab-index="true"
    >
      <li
        v-for="student in studentsWithScores"
        :key="student.user_id"
      >
        <div
          class="PerformanceTaskSubmissionSidebar-studentListItem flexContainer flexContainer-spaceBetween padd-xs margin-top-xxs"
          :class="{
            'PerformanceTaskSubmissionSidebar-studentListItem--selected':
              student.user_id === selectedStudentId,
          }"
          :aria-label="getItemAriaLabel(student)"
          :aria-pressed="`${student.user_id === selectedStudentId}`"
          role="button"
          tabindex="-1"
          @click="selectStudent(student)"
          @keypress.enter="selectStudent(student)"
        >
          <div>
            {{ student.name }}
          </div>
          <div>
            {{ student.score }}
            <kog-icon
              v-tooltip="{
                theme: 'kog-tooltip',
                content: gradingStatusLabel(student),
                boundary: 'window',
                autoHide: false,
              }"
              class="PerformanceTaskSubmissionSidebar-studentListItem-gradingStatusIcon margin-left-xxs"
              :icon-class="gradingStatusIcon(student)"
              fa-style="regular"
              size="s"
              :label="gradingStatusLabel(student)"
              :is-non-actionable="true"
            />
          </div>
        </div>
      </li>
    </kog-item-list>
    <kog-button
      class="margin-top-s margin-bottom-s PerformanceTaskSubmissionSidebar-sendAllGradesButton"
      label="Send grades"
      :button-style="sendAllGradesButtonStyle"
      icon-class="fa-paper-plane"
      :has-icon="true"
      :disabled="isSendAllGradesDisabled"
      @click="onSendAllGradesClick()"
    />
  </kog-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogToggleButton from 'sharedApp/components/base/buttons/kog-toggle-button.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import ActivitySubmissionClassSwitcher from 'studyApp/components/activities/activity-submission-class-switcher.vue';
import {
  PERFORMANCE_TASK_MODULE,
  PT_GRADING_STATUS,
} from 'studyApp/store/modules/performance-task.js';

const gradingStatusLabelMap = new Map([
  [PT_GRADING_STATUS.NOT_SUBMITTED, 'Not submitted'],
  [PT_GRADING_STATUS.NOT_GRADED, 'Not graded'],
  [PT_GRADING_STATUS.GRADES_NOT_SENT, 'Not completed'],
  [PT_GRADING_STATUS.GRADES_UPDATED_NOT_SENT, 'Not completed'],
  [PT_GRADING_STATUS.GRADES_SENT, 'All done!'],
]);

const gradingStatusMap = new Map([
  [PT_GRADING_STATUS.NOT_SUBMITTED, 'fa-x'],
  [PT_GRADING_STATUS.NOT_GRADED, 'fa-minus'],
  [PT_GRADING_STATUS.GRADES_NOT_SENT, 'fa-circle-half-stroke'],
  [PT_GRADING_STATUS.GRADES_UPDATED_NOT_SENT, 'fa-circle-half-stroke'],
  [PT_GRADING_STATUS.GRADES_SENT, 'fa-check-circle'],
]);

export default {
  name: 'PerformanceTaskSubmissionSidebar',
  components: {
    ActivitySubmissionClassSwitcher,
    KogCard,
    KogIcon,
    KogItemList,
    KogButton,
    KogToggleButton,
  },
  props: {
    studentsList: {
      type: Array,
      required: true,
    },
    selectedStudentId: {
      type: Number,
      default: null,
    },
    isAnonymized: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['send-all-grades', 'toggle-anonymized', 'select-student'],
  computed: {
    ...mapState({
      ptOccasions: state => state[PERFORMANCE_TASK_MODULE].classOccasions,
      performanceTask: state => state[PERFORMANCE_TASK_MODULE].performanceTask,
    }),
    ...mapGetters(PERFORMANCE_TASK_MODULE, [
      'scoredOccasionByStudentUserId',
      'isAllClassOccasionsGraded',
      'isAllClassMarksSent',
      'isAnyMarksChangedAfterSending',
      'isNoneClassOccasionsGraded',
      'isClassOccasionsPresent',
    ]),
    isSendAllGradesDisabled() {
      const isMarksSentAndNoUpdates =
        this.isAllClassMarksSent && !this.isAnyMarksChangedAfterSending;
      if (
        isMarksSentAndNoUpdates ||
        this.isNoneClassOccasionsGraded ||
        !this.isClassOccasionsPresent
      ) {
        return true;
      }
      return false;
    },
    studentsWithScores() {
      return this.studentsList.map(student => {
        let studentScore = 0;
        studentScore = this.scoredOccasionByStudentUserId[student.user_id]?.totalScore ?? 0;
        const gradeSentAt =
          this.scoredOccasionByStudentUserId[student.user_id]?.occasion.grade_sent_at ?? 0;
        const ptOccasionStatus =
          this.scoredOccasionByStudentUserId[student.user_id]?.grading.status ??
          PT_GRADING_STATUS.NOT_SUBMITTED;
        return {
          ...student,
          score: studentScore,
          ptOccasionStatus,
          gradeSentAt,
        };
      });
    },
    sendAllGradesButtonStyle() {
      if (this.isAllClassMarksSent && !this.isAnyMarksChangedAfterSending) {
        return '';
      }

      if (this.isAllClassOccasionsGraded) {
        return 'accent';
      }

      return '';
    },
  },
  methods: {
    getItemAriaLabel(student) {
      return `${student.name}, ${student.score} marks`;
    },
    gradingStatusLabel(student) {
      const status = gradingStatusLabelMap.get(student.ptOccasionStatus, '-');
      if (student.ptOccasionStatus === PT_GRADING_STATUS.GRADES_SENT) {
        const formattedGradedAt = dateTimeFormatter(new Date(student.gradeSentAt));
        return `${status} Sent ${formattedGradedAt}`;
      }
      return status;
    },
    gradingStatusIcon(student) {
      return gradingStatusMap.get(student.ptOccasionStatus, '');
    },
    selectStudent(student) {
      this.$emit('select-student', student.user_id);
      this.$mixpanel.trackEvent('Performance task - Select student in submissions sidebar', {
        performance_task_id: this.performanceTask.id,
        student_user_id: student.user_id,
      });
    },
    onSendAllGradesClick() {
      this.$emit('send-all-grades');
    },
  },
};
</script>
<style scoped>
.PerformanceTaskSubmissionSidebar-heading {
  font-size: var(--kog-font-size-smaller);
}

.PerformanceTaskSubmissionSidebar-studentList {
  overflow-y: auto;
}

.PerformanceTaskSubmissionSidebar-studentListItem {
  cursor: pointer;
  line-height: 24px;
  border-radius: 8px;
}

.PerformanceTaskSubmissionSidebar-studentListItem-gradingStatusIcon {
  min-width: var(--space-m);
}

.PerformanceTaskSubmissionSidebar-studentListItem:hover {
  color: var(--kog-text-brand);
  background-color: var(--kog-list-item-hover-background);
}

.PerformanceTaskSubmissionSidebar-studentListItem--selected {
  cursor: default;
  background-color: var(--kog-list-item-selected-background);
}

.PerformanceTaskSubmissionSidebar-studentListItem--selected:hover {
  background-color: var(--kog-list-item-selected-hover-background);
}

.PerformanceTaskSubmissionSidebar-sendAllGradesButton {
  width: 100%;
}
</style>
