/* global MathJax */
import { Plugin } from '@ckeditor/ckeditor5-core';

import MathOverrideEditing from './math-override-editing.js';

export default class MathOverride extends Plugin {
  static get requires() {
    return [MathOverrideEditing];
  }
}

export function CustomRenderEngine(equation, element, display) {
  if (!MathJax.tex2chtmlPromise) {
    return;
  }

  let promise = Promise.resolve();
  const typeset = el => {
    promise = promise.then(() => MathJax.typesetPromise([el], { display }));
  };

  MathJax.tex2chtmlPromise(equation, { display }).then(node => {
    if (element.firstChild) {
      MathJax.typesetClear([element]);
      element.firstChild.remove();
    }
    element.appendChild(node);
    element.classList.add('ck-reset_all-excluded');
    typeset(element);
  });
}
