import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import type { TeacherSubjectClassDetails } from '@apis/generated';

import {
  getIntegrationProviderDisplayName,
  getIntegrationProviderLogo,
} from 'learning/common/services/integration/integration-service.js';

import { INTEGRATION_PROVIDERS } from 'sharedApp/const/integrations.ts';

const integrationLogo = ref('');
const integrationProviderDisplayName = ref('');
const subjectClassesWithIntegrationClassIds = ref<number[]>([]);
const integrationProvider = ref('');

export default function useIntegrationLogo() {
  const store = useStore();
  const route = useRoute();
  const { subjectClasses } = store.state.subjectClassModule;
  const classId = Number(route.params.cid);
  const subjectClass = subjectClasses.find((sc: TeacherSubjectClassDetails) => sc.id === classId);
  if (subjectClass.google_classes?.length > 0) {
    integrationProvider.value = INTEGRATION_PROVIDERS.GOOGLE;
  } else {
    integrationProvider.value = subjectClass?.integration_providers[0] || '';
  }
  integrationLogo.value = getIntegrationProviderLogo(integrationProvider.value);
  integrationProviderDisplayName.value = getIntegrationProviderDisplayName(
    integrationProvider.value,
  );
  subjectClassesWithIntegrationClassIds.value = subjectClasses
    .filter(
      (sc: TeacherSubjectClassDetails) =>
        sc.integration_providers?.length > 0 || sc.google_classes?.length > 0,
    )
    .map((sc: TeacherSubjectClassDetails) => sc.id);

  return {
    integrationProvider,
    integrationLogo,
    integrationProviderDisplayName,
    subjectClassesWithIntegrationClassIds,
  };
}
