<template>
  <kog-font-awesome-icon
    :aria-label="statsEmoji.ariaLabel"
    :fa-class="statsEmoji.emoji"
    fa-style="light"
    size="s"
  />
</template>

<script>
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';

export default {
  name: 'StatsEmoji',
  components: {
    KogFontAwesomeIcon,
  },
  props: {
    statsPercent: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    statsEmoji() {
      let emoji = 'fa-face-grin-stars';
      let ariaLabel = 'happy star eyed smiley';
      if (this.statsPercent < 1) {
        emoji = 'fa-face-meh-blank';
        ariaLabel = 'no face smiley';
      } else if (this.statsPercent < 25) {
        emoji = 'fa-face-surprise';
        ariaLabel = 'surprised smiley';
      } else if (this.statsPercent < 50) {
        emoji = 'fa-face-smile';
        ariaLabel = 'happy smiley';
      } else if (this.statsPercent < 75) {
        emoji = 'fa-face-grin-beam';
        ariaLabel = 'happy laughing smiley';
      }

      return { emoji, ariaLabel };
    },
  },
};
</script>
