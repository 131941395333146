<template>
  <div
    :class="{
      'width-full': fullWidth,
    }"
  >
    <kog-dropdown-button
      :items="assignmentTypeItems"
      label="Create assignment"
      :button-style="buttonType"
      :size="buttonSize"
      :dropdown-placement="dropdownPlacement"
      :full-width="fullWidth"
      @select="onAssignmentTypeItemSelect"
    />
    <div
      v-if="showInfoLink"
      class="CreateAssignmentButton-info cursorPointer"
      role="button"
      tabindex="0"
      @click="showAssignmentsInfo"
      @keydown.enter.prevent="showAssignmentsInfo"
    >
      <i class="fal fa-info-circle" /> Learn more about assignments
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapGetters } from 'vuex';

import { createAssignment } from '@apis/schoolstaff-assignments.js';

import AssignmentTypesModal from 'learning/common/components/assignment/assignment-types-modal.vue';
import SelectClassModal from 'learning/common/components/assignment/select-class-modal.vue';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import mixpanelModule from 'sharedApp/libs/mixpanel.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import { getTerm, isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';

export const SOURCES = {
  ASSIGNMENT_OVERVIEW: 'assignment_overview_top_right',
  TEXTBOOK_SECTION_HEADER: 'textbook_section_header',
  TEXTBOOK_SECTION_MODAL: 'textbook_section_modal',
  ASSIGNMENT_CARD: 'assignment_card',
  TEACHER_CLASS_CARD: 'teacher_class_card',
  SUBJECT_CLASS_OVERVIEW_STRENGTH_QUESTIONS_MODAL:
    'subject_class_overview_strength_questions_modal',
  SUBJECT_CLASS_OVERVIEW_SECTION_MODAL: 'subject_class_overview_section_modal',
  TEACHER_ASSIGNMENT_OVERVIEW: 'teacher_assignment_overview',
};

export default {
  name: 'CreateAssignmentButton',
  components: {
    KogDropdownButton,
  },
  props: {
    buttonType: {
      type: String,
      default: '',
    },
    buttonSize: {
      type: String,
      default: 'medium',
    },
    subjectClasses: {
      type: Array,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    beforeOpenModal: {
      type: Function,
      default: null,
    },
    source: {
      type: String,
      default: 'not_declared',
    },
    extraQueryParams: {
      type: Object,
      default: () => ({}),
    },
    showInfoLink: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
    dropdownPlacement: {
      type: String,
      default: 'bottom-start',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    subjectNodeId: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const { fetchIsMultipleClassAssignmentEnabled } = featureFlagStore;
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);

    return {
      fetchIsMultipleClassAssignmentEnabled,
      isMultipleClassAssignmentEnabled,
    };
  },
  computed: {
    ...mapGetters({
      isNodeVisible: 'subjectClassModule/isNodeVisible',
    }),
    isNodeHidden() {
      if (this.subjectNodeId) return !this.isNodeVisible(this.subjectNodeId);
      return false;
    },
    assignmentTypeItems() {
      const assignmentTypes = [
        { iconClass: 'fa-bookmark', text: 'Book assignment', value: ASSIGNMENT_TYPES.READING },
        {
          iconClass: 'fa-pencil-alt',
          text: 'Question assignment',
          value: ASSIGNMENT_TYPES.QUESTION,
          disabled: this.isNodeHidden,
          tooltip: this.isNodeHidden
            ? "You can't send question assignment from hidden content"
            : '',
        },
      ];
      if (this.isShowEsq) {
        assignmentTypes.push({
          iconClass: 'LTQ',
          isSvgIcon: true,
          text: `${getTerm('exam-style', this.educationSystemName)} assignment`,
          value: ASSIGNMENT_TYPES.EXAMSTYLE,
        });
      }
      return assignmentTypes;
    },
    educationSystemName() {
      return this.subject.education_system_name || this.subject.educationsystem.name;
    },
    isShowEsq() {
      return !isNgss(this.educationSystemName);
    },
  },
  methods: {
    trackMixpanelEvent(name, assignmentType) {
      const eventProperties = {
        subject_id: this.subject.id,
        subject_name: this.subject.name,
        assignment_type: assignmentType.toLowerCase(),
        num_subject_classes: this.subjectClasses.length,
      };
      mixpanelModule.trackEvent(name, eventProperties);
    },
    onAssignmentTypeItemSelect(item) {
      if (this.beforeOpenModal) {
        this.beforeOpenModal();
      }
      this.trackMixpanelEvent('Assignment - Create start', item.value);
      this.createAssignmentWithSelectClassModal(item.value);
    },
    assignmentActions(assignmentType) {
      return this.subjectClasses.reduce((acc, subjectClass) => {
        acc[subjectClass.id] = () => {
          this.goToAssignment(assignmentType, subjectClass);
        };
        return acc;
      }, {});
    },
    createAssignmentWithSelectClassModal(assignmentType) {
      if (this.subjectClasses.length === 1) {
        this.goToAssignment(assignmentType, this.subjectClasses[0]);
      } else {
        this.$modal(SelectClassModal, {
          subjectClasses: this.subjectClasses,
          actions: this.assignmentActions(assignmentType),
        });
      }
    },
    getClassIntegrationProvider(subjectClass) {
      return subjectClass?.integration_providers?.length > 0
        ? subjectClass.integration_providers[0]
        : '';
    },
    async goToAssignment(assignmentType, subjectClass) {
      await this.fetchIsMultipleClassAssignmentEnabled(
        this.getClassIntegrationProvider(subjectClass),
      );
      const params = {
        assignmentType,
        subjectClassId: subjectClass.id,
        user: this.user,
        isMultiClass: this.isMultipleClassAssignmentEnabled,
      };
      if (this.subjectNodeId && assignmentType === ASSIGNMENT_TYPES.READING) {
        params.subjectnodes = [this.subjectNodeId];
      }
      const assignment = await createAssignment(params);
      const sectionNodeId = this.subjectNodeId;
      const assignmentCreateLocation = {
        name: 'teacherAssignmentCreate',
        params: {
          classSlug: subjectClass.slug,
          sid: this.subject.id,
          cid: subjectClass.id,
          assignmentId: assignment.id,
        },
        query: {
          just_created: true,
          source: this.source,
          ...this.extraQueryParams,
          ...(sectionNodeId && { section_node: sectionNodeId }),
          ...this.$route.query,
        },
      };

      this.$router.push(assignmentCreateLocation);
    },
    showAssignmentsInfo() {
      if (this.beforeOpenModal) {
        this.beforeOpenModal();
      }
      this.$modal(AssignmentTypesModal, {
        isShowEsq: this.isShowEsq,
      });
    },
  },
};
</script>

<style scoped>
.CreateAssignmentButton-info {
  margin-top: 15px;
  font-size: 0.8em;
  color: color-mod(var(--kogPlatformGray018) alpha(85%));
}

.CreateAssignmentButton-info:hover {
  color: var(--kogPlatformGray018);
}
</style>
