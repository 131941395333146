<template>
  <kog-modal :title="`Add ${$term('exam-style').toLowerCase()} question`">
    <template #modalBody>
      <div>
        <div class="ESQModal-label"> Add a question </div>
        <question-editor-plain-text
          v-model:value="question.question"
          label="Question text"
        />
      </div>
      <div
        v-if="hasEducationSystemMarkscheme"
        class="margin-top-l"
      >
        <div class="ESQModal-label"> Add a markscheme </div>
        <question-editor-plain-text
          v-model:value="question.markscheme"
          label="Markscheme"
        />
      </div>
      <div
        class="margin-top-l margin-bottom-l"
        :class="{
          flexContainer: hasEducationSystemPapertype || hasEducationSystemMarks,
        }"
      >
        <div>
          <div class="ESQModal-label"> Link to book </div>
          <select
            v-model="question.subject_node_id"
            class="KogFormInput"
            aria-label="Topic"
          >
            <option
              v-for="node in topicList"
              :key="node.id"
              :value="node.id"
            >
              {{ node.formatted_number_including_ancestors }} {{ node.name }}
            </option>
          </select>
        </div>
        <div
          v-if="hasEducationSystemPapertype"
          class="margin-left-s margin-right-s"
        >
          <div class="ESQModal-label"> Paper type </div>
          <select
            v-model="question.paper_type_name"
            class="KogFormInput"
            aria-label="Paper type"
          >
            <option
              v-for="(papertype, index) in paperTypes"
              :key="index"
              :value="papertype.name"
            >
              {{ papertype.name }}
            </option>
          </select>
        </div>
        <div v-if="hasEducationSystemMarks">
          <label
            for="marks"
            class="ESQModal-label"
          >
            Total marks
          </label>
          <input
            id="marks"
            v-model="question.marks"
            name="marks"
            type="number"
            class="KogFormInput ESQModalMarksInput"
            min="1"
            oninput="validity.valid||(value='')"
          />
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div
        class="flexContainer flexChild-size-1 flexContainer-spaceBetween flexContainer-alignCenter"
      >
        <a
          href="#"
          @click.prevent="openNewIntercomChat"
        >
          Click here if you want to give feedback!
        </a>
        <div class="KogButtonSet KogButtonSet--right">
          <kog-button
            v-if="canDelete"
            class="ESQModal-delete"
            label="Delete"
            button-style="basic"
            button-size="small"
            @click="onDelete"
          />
          <button
            class="KogButtonLegacy"
            @click="onCancel"
          >
            Cancel
          </button>
          <button
            class="KogButtonLegacy KogButtonLegacy--primary"
            :disabled="!areRequiredFieldsFilled"
            @click="onSave"
          >
            Save
          </button>
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { mapState } from 'vuex';

import {
  createTeacherQuestion,
  deleteTeacherQuestion,
  editTeacherQuestion,
} from '@apis/questions.js';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import DjangoSettings from 'sharedApp/django-settings-module.ts';
import intercomModule from 'sharedApp/libs/intercom.js';
import {
  hasEducationSystemMarks,
  hasEducationSystemMarkscheme,
  hasEducationSystemPapertype,
} from 'sharedApp/services/educationSystem/education-system-service.js';
import { QUESTION_TYPES } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import {
  trackTeacherQuestionCancel,
  trackTeacherQuestionCreate,
  trackTeacherQuestionDelete,
  trackTeacherQuestionFeedbackClick,
  trackTeacherQuestionOpenCreateModal,
  trackTeacherQuestionUpdate,
} from 'teachApp/services/tracking/assignment-tracking.js';

import QuestionEditorPlainText from './question-editor-plain-text.vue';

export default {
  name: 'ESQModal',
  components: {
    KogButton,
    KogModal,
    QuestionEditorPlainText,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    existingQuestion: {
      type: Object,
      default: null,
    },
    subjectId: {
      type: Number,
      required: true,
    },
    subjectAndTopics: {
      type: Array,
      required: true,
    },
    onSaveCallback: {
      type: Function,
      default: () => {},
    },
    onDeleteCallback: {
      type: Function,
      default: () => {},
    },
    educationSystem: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      question: {
        question: '',
        markscheme: '',
        paper_type_name: '',
        marks: 1,
        subject_node_id: '',
      },
      esqId: '',
      hasEducationSystemMarkscheme: hasEducationSystemMarkscheme(this.educationSystem),
      hasEducationSystemMarks: hasEducationSystemMarks(this.educationSystem),
      hasEducationSystemPapertype: hasEducationSystemPapertype(this.educationSystem),
    };
  },
  computed: {
    ...mapState({
      user: state => state.appModule.user,
    }),
    topicList() {
      return this.subjectAndTopics.filter(node => node.level === 1);
    },
    areRequiredFieldsFilled() {
      const isMarkschemeValid =
        !this.hasEducationSystemMarkscheme || this.question.markscheme.trim();
      return this.question.question.trim() && isMarkschemeValid;
    },
    trackOptions() {
      return {
        subjectId: this.subjectId,
        subjectName: this.subjectAndTopics[0].name,
        source: 'ESQ assignment',
      };
    },
    canDelete() {
      return !!this.esqId;
    },
    paperTypes() {
      const paperTypes = DjangoSettings.CONTENT_SETTINGS.PAPERTYPE_OPTIONS;

      return paperTypes.sort((papertype1, papertype2) =>
        papertype1.name > papertype2.name ? 1 : -1,
      );
    },
  },
  created() {
    if (this.existingQuestion) {
      this.esqId = this.existingQuestion.id;
      this.question.question = this.existingQuestion.question_html;
      this.question.markscheme = this.existingQuestion.answer_explanation_html;
      this.question.paper_type_name = this.existingQuestion.papertype?.name || null;
      this.question.marks = this.existingQuestion.marks;
    } else {
      this.question.paper_type_name = this.paperTypes[0].name;
    }

    this.setInitalSubjectNodeMapping();

    trackTeacherQuestionOpenCreateModal(this.trackOptions);
  },
  methods: {
    async onSave() {
      const data = {
        question_html: this.question.question,
        answer_explanation_html: this.question.markscheme,
        subject_node_id: this.question.subject_node_id,
        paper_type_name: this.hasEducationSystemPapertype ? this.question.paper_type_name : null,
        marks: this.hasEducationSystemMarks ? this.question.marks : null,
        is_active: true,
        feature_assignment_exam_style: true,
      };
      if (this.esqId) {
        await editTeacherQuestion(this.subjectId, this.esqId, data, QUESTION_TYPES.LTQ);
      } else {
        await createTeacherQuestion(this.subjectId, data, QUESTION_TYPES.LTQ);
      }
      const selectedNode = this.subjectAndTopics.find(
        node => node.id === this.question.subject_node_id,
      );
      this.onSaveCallback(selectedNode);
      this.showToast();
      this.trackSave();
      this.closeModal();
    },
    onCancel() {
      this.trackCancel();
      this.closeModal();
    },
    async onDelete() {
      // eslint-disable-next-line no-alert
      if (window.confirm('Delete question? This action cannot be undone.')) {
        try {
          await deleteTeacherQuestion(this.subjectId, this.esqId, QUESTION_TYPES.LTQ);
        } catch (error) {
          this.showErrorToast(error, true);
          return;
        }
        this.onDeleteCallback(this.existingQuestion);
        trackTeacherQuestionDelete({
          ...this.trackOptions,
          questionType: 'ESQ',
        });
        this.closeModal();
      }
    },
    showToast() {
      const action = this.esqId ? 'saved' : 'created';
      this.$toast.showSuccess(`Your question was ${action} successfully`);
    },
    trackSave() {
      if (this.esqId) {
        trackTeacherQuestionUpdate({
          ...this.trackOptions,
          questionType: 'ESQ',
        });
      } else {
        trackTeacherQuestionCreate({
          ...this.trackOptions,
          questionType: 'ESQ',
        });
      }
    },
    trackCancel() {
      if (this.question.question && !this.esqId) {
        trackTeacherQuestionCancel(this.trackOptions);
      }
    },
    openNewIntercomChat() {
      trackTeacherQuestionFeedbackClick(this.trackOptions);
      intercomModule.showNewMessage();
    },
    setInitalSubjectNodeMapping() {
      const currentNode = this.topicList.find(node => node.expanded);
      if (currentNode) {
        this.question.subject_node_id = currentNode.id;
      } else if (this.existingQuestion) {
        this.question.subject_node_id = this.existingQuestion.subjectnode_mappings[0].id;
      } else {
        this.question.subject_node_id = this.topicList[0].id;
      }
    },
  },
};
</script>

<style scoped>
.ESQModalMarksInput {
  width: 72px;
}

.ESQModal-label {
  margin-bottom: var(--space-xs);
  font-weight: inherit;
  color: var(--kogPlatformGray031);
}

.ESQModal-delete {
  color: var(--kogPlatformRedDarken20);
}
</style>
