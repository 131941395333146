import type { DowncastWriter } from '@ckeditor/ckeditor5-engine';

export const createLabel = (
  writer: DowncastWriter,
  { text, tooltip }: { text: string; tooltip?: string },
) => {
  const wrapper = writer.createContainerElement('div', {
    class: 'KogLabelLegacy KogLabelLegacy--danger margin-left-xxs',
    title: tooltip ?? null,
  });
  const labelText = writer.createText(text);
  writer.insert(writer.createPositionAt(wrapper, 0), labelText);
  return wrapper;
};

export const createHiddenQuestionLabel = (writer: DowncastWriter) =>
  createLabel(writer, {
    text: 'Hidden',
    tooltip:
      'The question is not visible to students and teachers on the current ' +
      'subject node. It can be either not marked as active or not mapped to the current ' +
      'subject node.',
  });
