<template>
  <div>
    <kog-loader
      :loading="mathJaxLoading"
      loading-msg="Rendering questions..."
      :class="[{ 'margin-top-1': mathJaxLoading }]"
    />
    <div
      v-show="!mathJaxLoading"
      v-kog-mathjax="{ doneCallback: mathJaxDone }"
    >
      <draggable
        v-model="internalQuestions"
        animation="150"
        item-key="id"
      >
        <template #item="{ element: question, index }">
          <div
            class="flexContainer flexContainer-alignCenter cursorGrab"
            @click="questionPreviewModal(question)"
          >
            <div class="ArrangeList_tableRowNum"> {{ index + 1 }}. </div>
            <div
              class="inlineflexContainer flexContainer-alignCenter ArrangeList_tableRow flexChild-canGrow kogPlatformWhite-bg"
              :class="{
                'ArrangeList_tableRow--first': index === 0,
                'ArrangeList_tableRow--last': index === internalQuestions.length - 1,
                'ArrangeList_tableRow--only': internalQuestions.length === 1,
              }"
            >
              <div
                class="flexTable__item flexTable__item-size-10 ArrangeQuestionsList_question"
                v-text="getQuestionPreview(question)"
              />
              <question-location
                class="flexTable__item flexTable__item-size-1 text-center cursorDefault kogPlatformGray064"
                :locations="getQuestionLocations(question)"
              />
              <question-type-label
                class="flexTable__item flexTable__item-size-1 text-center cursorDefault"
                :question-type="question.type"
                :tooltip-text="getQuestionTypeText(question)"
              />
              <kog-difficulty
                v-if="showDifficulty"
                class="flexTable__item flexTable__item-size-1 text-center cursorDefault"
                :difficulty="question.difficulty"
              />
              <div class="flexTable__item flexTable__item-size-1 text-center kogPlatformGray064">
                <i
                  class="fas fa-times cursorPointer padd-xs"
                  @click="removeQuestion(question)"
                />
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

import KogDifficulty from 'learning/common/components/difficulty/kog-difficulty.vue';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  dangerouslyGetUnescapedText,
  getQuestionTypeText,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import QuestionPreviewModal from 'studyApp/components/teacher/assignments/question-preview-modal.vue';
import QuestionTypeLabel from 'studyApp/components/teacher/assignments/question-type-label.vue';

import QuestionLocation from './question-location.vue';

export default {
  name: 'ArrangeQuestionsList',
  components: {
    QuestionTypeLabel,
    KogLoader,
    KogDifficulty,
    Draggable,
    QuestionLocation,
  },
  inject: ['questionsSentBeforeSet'],
  props: {
    getQuestionLocations: {
      type: Function,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    removeQuestion: {
      type: Function,
      required: true,
    },
    updateQuestions: {
      type: Function,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      default: false,
    },
    subjectName: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mathJaxLoading: true,
    };
  },
  computed: {
    internalQuestions: {
      get() {
        if (!this.expanded) {
          return this.questions.slice(0, 1);
        }
        return this.questions;
      },
      set(newArray) {
        this.updateQuestions(newArray);
      },
    },
    paginatedQuestions() {
      return {
        results: this.questions,
        page_size: 10,
        current_page: 1,
        count: this.questions.length,
      };
    },
  },
  methods: {
    getQuestionTypeText,
    mathJaxDone() {
      this.mathJaxLoading = false;
    },
    getQuestionPreview(question) {
      return dangerouslyGetUnescapedText(question.question_html);
    },
    questionPreviewModal(question) {
      this.$modal(QuestionPreviewModal, {
        question,
        assignmentType: ASSIGNMENT_TYPES.QUESTION,
        showDifficulty: this.showDifficulty,
        paginatedQuestions: this.paginatedQuestions,
        questionsSentBeforeSet: this.questionsSentBeforeSet,
        subjectName: this.subjectName,
        showPosition: false,
        source: 'assignment_create',
      });
    },
  },
};
</script>

<style scoped>
.ArrangeQuestionsList_question {
  overflow: hidden;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */

  max-height: 3em;

  line-height: 1.5em;
  text-overflow: ellipsis;
  white-space: pre-wrap;

  -webkit-line-clamp: 2;
}
</style>
