import { Plugin } from '@ckeditor/ckeditor5-core';
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository.js';
import FileDownloadEditing from './file-download-editing.js';
import FileDownloadUI from './file-download-ui.js';

export default class FileDownload extends Plugin {
  static get requires() {
    return [FileDownloadEditing, FileDownloadUI, FileRepository];
  }
}
