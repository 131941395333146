<script setup lang="ts">
import { onBeforeUnmount, onMounted, withDefaults } from 'vue';

import type {
  SelectedTextMenuEmits,
  SelectedTextMenuProps,
} from 'learning/common/components/annotations-menu/selected-text-menu.types.ts';

import ColorSelector from 'learning/common/components/color-selector.vue';

import vKogTrapFocus from 'sharedApp/directives/kog-trap-focus.js';

const props = withDefaults(defineProps<SelectedTextMenuProps>(), {
  isDictionaryEnabled: false,
});
const emit = defineEmits<SelectedTextMenuEmits>();

function addHighlightWithColor(color: string) {
  emit('addHighlight', color);
}

function closeMenu() {
  emit('closeMenu');
}

function showDictionaryDefinition() {
  emit('showDictionaryDefinition');
}

function onKeyDownHandler(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    closeMenu();
  }
}

onMounted(() => {
  window.addEventListener('keydown', onKeyDownHandler);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyDownHandler);
});
</script>

<template>
  <div
    v-kog-trap-focus
    class="flexContainer cursorDefault"
  >
    <color-selector
      theme="dark"
      class="SelectedTextMenu-colorSelector"
      @change="addHighlightWithColor"
    />
    <template v-if="props.isDictionaryEnabled">
      <span class="SelectedTextMenu-optionDivider" />
      <button
        class="KogButtonLegacy--noStyle SelectedTextMenu-option"
        aria-label="Show the dictionary definition"
        @click.stop="showDictionaryDefinition"
      >
        Dictionary
      </button>
    </template>
  </div>
</template>

<style scoped>
.SelectedTextMenu-option {
  cursor: pointer;

  display: inline-block;

  padding: 8px 12px;

  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);

  background: none;
  border: none;
}

.SelectedTextMenu-optionDivider {
  margin: 8px 0;
  border-left: 1px solid var(--kogPlatformGray018);
}

.SelectedTextMenu-colorSelector {
  padding-right: 7px;
  padding-left: 15px;
}
</style>
