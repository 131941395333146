<template>
  <div
    v-if="stats && stats.won > 0"
    class="margin-0"
  >
    Battles won: {{ stats.won }}
    <span
      role="img"
      aria-label="Party popper"
      >&#x1f389;</span
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BattlesStatistics',
  computed: {
    ...mapState('battlesModule', ['stats']),
  },
};
</script>
