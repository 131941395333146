import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { ClickObserver } from '@ckeditor/ckeditor5-engine';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { toWidget, toWidgetEditable, Widget } from '@ckeditor/ckeditor5-widget';

import IconDiv from '../icon-div/icon-div.js';
import SpanKogId from '../span-id/span-id.js';
import { isClickInsideSelectedElement } from '../utils/commands.js';
import InsertAnyBoxCommand from './any-box-command.js';
import { ANYBOX_TYPES } from './content.js';

const setsAreEqual = (xs, ys) => xs.size === ys.size && [...xs].every(x => ys.has(x));

const tagToTitleType = tag =>
  ({
    h2: 'heading2',
    h3: 'heading3',
    h4: 'heading4',
    span: 'span',
  })[tag];
export default class AnyBoxEditing extends Plugin {
  static get requires() {
    return [Widget, Paragraph, IconDiv, SpanKogId, Heading];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineCommands();
  }

  defineSchema() {
    const { editor } = this;
    const { schema } = editor.model;

    schema.register('anyBox', {
      inheritAllFrom: '$blockObject',
      allowAttributes: ['boxType'],
    });

    schema.register('anyBoxTitle', {
      isLimit: true,
      allowIn: 'anyBox',
      allowContentOf: '$root',
      allowAttributes: ['headingLevel'],
    });

    schema.register('anyBoxContent', {
      isLimit: true,
      allowIn: 'anyBox',
      allowContentOf: '$root',
    });
  }

  defineConverters() {
    const { conversion } = this.editor;

    // <anyBox> converters
    conversion.for('upcast').elementToElement({
      model: (viewElement, { writer }) => {
        const classNames = Array.from(viewElement.getClassNames()).filter(d => d !== 'anybox');
        const boxType = Object.entries(ANYBOX_TYPES).find(([_, value]) =>
          setsAreEqual(new Set(value.classes), new Set(classNames)),
        )?.[0];
        return writer.createElement('anyBox', { boxType });
      },
      view: {
        name: 'div',
        classes: 'anybox',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'anyBox',
      view: (modelElement, { writer }) => {
        const boxType = modelElement.getAttribute('boxType');
        const classes = ['anybox', ...(ANYBOX_TYPES[boxType]?.classes || [])];
        const viewElement = writer.createContainerElement('div', {
          class: classes.join(' '),
        });
        return viewElement;
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'anyBox',
      view: (modelElement, { writer }) => {
        const boxType = modelElement.getAttribute('boxType');

        const classes = ['anybox', ...(ANYBOX_TYPES[boxType]?.classes || [])];
        const viewElement = writer.createContainerElement('div', {
          class: classes.join(' '),
        });
        return toWidget(viewElement, writer, { label: 'lap_anybox widget (deprecated)' });
      },
    });

    // <anyBoxTitle> converters
    conversion.for('upcast').elementToElement({
      model: (viewElement, { writer }) => {
        const childNode = viewElement.getChild(0);

        if (!childNode || !childNode.is('element')) {
          return null;
        }

        const headingLevel = tagToTitleType(childNode.name);
        const modelElement = writer.createElement('anyBoxTitle', { headingLevel });

        if (childNode.name === 'span' && !childNode.hasAttribute('kog-id')) {
          const textChild = childNode.getChild(0);
          const text = textChild?.is('$text') ? textChild.data : '';
          // eslint-disable-next-line no-underscore-dangle
          viewElement._removeChildren(0);
          writer.insertText(text, { spanKogId: 'old' }, modelElement, 0);
        }

        return modelElement;
      },
      view: {
        name: 'div',
        classes: 'anybox-title',
      },
    });
    conversion.for('downcast').elementToElement({
      model: 'anyBoxTitle',
      view: {
        name: 'div',
        classes: 'anybox-title',
      },
    });

    conversion.attributeToAttribute({
      model: {
        name: 'anyBoxTitle',
        key: 'headingLevel',
      },
      view: 'heading-level',
    });

    // <anyBoxContent> converters
    conversion.for('upcast').elementToElement({
      model: 'anyBoxContent',
      view: {
        name: 'div',
        classes: 'anybox-content',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'anyBoxContent',
      view: {
        name: 'div',
        classes: 'anybox-content',
        attributes: {
          'data-gramm': 'false',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'anyBoxContent',
      view: (_, { writer }) => {
        const div = writer.createEditableElement('div', {
          class: 'anybox-content',
          'data-gramm': 'false',
        });
        return toWidgetEditable(div, writer);
      },
    });
  }

  defineCommands() {
    const { editor } = this;
    const { document } = editor.model;
    const { view, mapper } = editor.editing;

    editor.commands.add('updateAnyBox', new Command(editor));
    editor.commands.add('insertAnyBox', new InsertAnyBoxCommand(editor));

    view.addObserver(ClickObserver);
    editor.listenTo(view.document, 'click', (_, data) => {
      if (isClickInsideSelectedElement(data, document, mapper, 'anyBox', ['anyBoxContent'])) {
        editor.commands.get('updateAnyBox').execute();
      }
    });
  }
}
