<template>
  <kog-table>
    <template #colgroup>
      <col width="100%" />
      <col width="72px" />
    </template>
    <template #header>
      <kog-table-header>
        <kog-table-cell-header class="padd-left-l"> Student </kog-table-cell-header>
        <kog-table-cell-header class="text-center"> Submitted </kog-table-cell-header>
      </kog-table-header>
    </template>

    <template #body>
      <kog-table-row
        v-for="(student, index) in assignment.students"
        :key="`student-${student.user_id}`"
        :row-index="index"
      >
        <kog-table-cell-text
          class="padd-left-l"
          :is-able-to-truncate="true"
        >
          {{ student.name }}
        </kog-table-cell-text>

        <kog-table-cell-text
          v-if="!assignment.is_scheduled_to_send"
          class="text-capitalize muted text-center"
          :tooltip-text="getStatusTooltipText(student.user_id)"
          :aria-label="getStatusAriaLabel(student.user_id)"
        >
          {{ occasionStatusByUserId[student.user_id].statusText }}
        </kog-table-cell-text>
        <kog-table-cell-text
          v-else
          class="muted text-center"
        >
          Scheduled
        </kog-table-cell-text>
      </kog-table-row>
    </template>
  </kog-table>
</template>

<script>
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import { isExamStyleType } from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateTimeFormatter, humanUnderstandableDateTime } from 'sharedApp/utils/time-utils.js';

const OCCASION_STATUSES = {
  NOT_STARTED: 'Not started',
  STARTED: 'Started',
  COMPLETED: 'Yes',
  LATE: 'Late',
};

export default {
  name: 'BasicAssignmentDetailsStudentTable',
  components: {
    KogTable,
    KogTableHeader,
    KogTableRow,
    KogTableCellText,
    KogTableCellHeader,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    occasionStatusByUserId() {
      if (!isExamStyleType(this.assignment.assignment_type)) {
        return {};
      }

      return this.assignment.esq_occasion.reduce((acc, esqOccasion) => {
        acc[esqOccasion.student_user_id] = this.getEsqReadableStatus(
          esqOccasion,
          this.assignment.deadline,
        );
        return acc;
      }, {});
    },
  },
  methods: {
    isDone(status) {
      return (
        status.statusText === OCCASION_STATUSES.COMPLETED ||
        status.statusText === OCCASION_STATUSES.LATE
      );
    },
    isStarted(status) {
      return status.statusText === OCCASION_STATUSES.STARTED;
    },
    isLate(deadline, doneAt) {
      return new Date(doneAt) > new Date(deadline);
    },
    getStatusTooltipText(studentUserId) {
      const status = this.occasionStatusByUserId[studentUserId];
      if (this.isDone(status)) {
        return `Submitted on ${this.dateFormatted(status.doneAt)}`;
      }
      if (this.isStarted(status)) {
        return `Started on ${this.dateFormatted(new Date(status.startedAt))}`;
      }
      return '';
    },
    getStatusAriaLabel(studentUserId) {
      const status = this.occasionStatusByUserId[studentUserId];
      if (!this.isDone(status)) {
        return status.statusText === OCCASION_STATUSES.STARTED
          ? `Started on ${humanUnderstandableDateTime(status.startedAt)}`
          : 'Not started';
      }

      return `Submitted on ${humanUnderstandableDateTime(status.doneAt)}`;
    },
    dateFormatted(date) {
      return dateTimeFormatter(new Date(date), 'at');
    },
    getEsqReadableStatus(esqOccasion, deadline) {
      const status = {
        doneAt: esqOccasion.done_at,
        startedAt: esqOccasion.started_at,
      };

      if (this.isLate(deadline, esqOccasion.done_at)) {
        status.statusText = OCCASION_STATUSES.LATE;
        return status;
      }

      status.statusText = OCCASION_STATUSES[esqOccasion.status] || '';
      return status;
    },
  },
};
</script>
