<template>
  <generic-warning-box>
    <b>We couldn't fetch data from Google Classroom</b>
    <p>
      It seems like Google Classroom is unavailable at the moment
      <br />
      Please try again in a few minutes
    </p>
  </generic-warning-box>
</template>

<script>
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';

export default {
  name: 'GoogleClassroomAPIUnavailableWarningBox',
  components: {
    GenericWarningBox,
  },
};
</script>
