<template>
  <div class="flexContainer padd-bottom-xl text-regular">
    <div class="flexChild-size-1">
      <h2 class="heading-xxs"> Kognity Students </h2>
      <div
        v-for="student in sortedStudentList"
        :key="student.id"
        class="margin-top-m"
      >
        {{ student.name }}
      </div>
    </div>
    <div class="flexChild-size-1">
      <h2 class="heading-xxs"> Google Students </h2>
      <div
        v-for="googleClass in selectedGoogleClasses"
        :key="googleClass.id"
        class="padd-bottom-m"
      >
        <google-class-pill
          :google-class="googleClass"
          size="small"
          class="margin-top-m inlineflexContainer"
        />
        <div v-if="sortedGoogleClassStudentsList(googleClass.students).length > 0">
          <div
            v-for="(student, index) in sortedGoogleClassStudentsList(googleClass.students)"
            :key="student.id"
            :class="{
              'margin-top-m': index !== 0,
              'margin-top-xs': index === 0,
            }"
          >
            {{ student.name }}
          </div>
        </div>
        <generic-warning-box
          v-else
          class="margin-top-m ConnectGoogleClassroomReviewStep-warningBox"
          mode="info"
          :has-padd-bottom="false"
        >
          There are no students in this class
        </generic-warning-box>
      </div>
    </div>
  </div>
</template>

<script>
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';
import GoogleClassPill from 'studyApp/components/teacher/google-class-pill.vue';

export default {
  name: 'ConnectGoogleClassroomReviewStep',
  components: {
    GoogleClassPill,
    GenericWarningBox,
  },
  props: {
    selectedGoogleClasses: {
      type: Array,
      required: true,
    },
    studentList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortedStudentList() {
      const studentNames = this.studentList.map(student => ({
        id: student.id,
        name: student.name || student.full_name,
      }));
      return studentNames.sort((student1, student2) => student1.name.localeCompare(student2.name));
    },
  },
  methods: {
    sortedGoogleClassStudentsList(students) {
      const studentNames = students.map(student => ({
        id: student.id,
        name: student.full_name,
      }));
      return studentNames.sort((student1, student2) => student1.name.localeCompare(student2.name));
    },
  },
};
</script>
