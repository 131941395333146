import { Plugin } from '@ckeditor/ckeditor5-core';
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';

import SpanKogIdEditing from './span-id-editing.js';

export default class SpanKogId extends Plugin {
  static get requires() {
    return [SpanKogIdEditing, GeneralHtmlSupport];
  }
}
