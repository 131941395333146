import { Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget, toWidgetEditable, Widget } from '@ckeditor/ckeditor5-widget';

import { forbidNesting } from '../utils/commands.js';
import InsertInlineQuestionCommand from './inline-question-command.js';

export default class InlineQuestionEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.editor.commands.add('insertInlineQuestion', new InsertInlineQuestionCommand(this.editor));
    this.defineSchema();
    this.defineConverters();
  }

  defineSchema() {
    const { schema } = this.editor.model;

    schema.register('inlineQuestion', {
      inheritAllFrom: '$blockObject',
    });

    schema.register('inlineQuestionHeader', {
      allowContentOf: '$root',
      allowIn: 'inlineQuestion',
    });

    schema.register('inlineQuestionContent', {
      isLimit: true,
      allowIn: 'inlineQuestion',
      allowContentOf: '$root',
    });

    schema.register('inlineQuestionToggle', {
      allowIn: 'inlineQuestion',
      allowContentOf: '$root',
    });

    schema.register('inlineQuestionAnswer', {
      isLimit: true,
      allowIn: 'inlineQuestion',
      allowContentOf: '$root',
    });

    schema.addChildCheck(forbidNesting('inlineQuestion'));
  }

  defineConverters() {
    const { conversion } = this.editor;

    // <inlineQuestion> converters
    conversion.for('upcast').elementToElement({
      model: 'inlineQuestion',
      view: {
        name: 'div',
        classes: 'inline-question',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'inlineQuestion',
      view: {
        name: 'div',
        classes: 'inline-question',
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'inlineQuestion',
      view: (_, { writer }) => {
        const viewElement = writer.createContainerElement('div', { class: 'inline-question' });
        return toWidget(viewElement, writer, { label: 'Show solution widget' });
      },
    });

    // <inlineQuestionHeader> converters
    conversion.elementToElement({
      model: 'inlineQuestionHeader',
      view: 'h5',
    });

    // <inlineQuestionContent> converters
    conversion.for('upcast').elementToElement({
      model: 'inlineQuestionContent',
      view: {
        name: 'div',
        classes: 'inline-question-content',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'inlineQuestionContent',
      view: {
        name: 'div',
        classes: 'inline-question-content',
        attributes: {
          'data-gramm': 'false',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'inlineQuestionContent',
      view: (modelItem, { writer }) => {
        const viewElement = writer.createEditableElement('div', {
          class: 'inline-question-content',
          'data-gramm': 'false',
        });
        return toWidgetEditable(viewElement, writer);
      },
    });

    // <inlineQuestionToggle> converters
    conversion.for('upcast').elementToElement({
      model: (viewElement, { writer }) => {
        const { childCount } = viewElement;
        // eslint-disable-next-line no-underscore-dangle
        viewElement._removeChildren(0, childCount);
        const modelItem = writer.createElement('inlineQuestionToggle');
        return modelItem;
      },
      view: {
        name: 'a',
        classes: 'inline-question-view-answer',
      },
    });
    conversion.for('downcast').elementToElement({
      model: 'inlineQuestionToggle',
      view: (modelItem, { writer }) => {
        const { childCount } = modelItem;
        // eslint-disable-next-line no-underscore-dangle
        modelItem._removeChildren(0, childCount);

        const viewElement = writer.createContainerElement('a', {
          class: 'inline-question-view-answer',
        });
        const showView = writer.createContainerElement('span', {
          class: 'inline-question-view-answer-title',
        });
        const hideView = writer.createContainerElement('span', {
          class: 'inline-question-view-answer-title hidden-widget',
        });
        const showText = writer.createText('+ Show answer');
        const hideText = writer.createText('- Hide answer');

        writer.insert(writer.createPositionAt(showView, 0), showText);
        writer.insert(writer.createPositionAt(hideView, 0), hideText);
        writer.insert(writer.createPositionAt(viewElement, 0), showView);
        writer.insert(writer.createPositionAt(viewElement, 1), hideView);

        return viewElement;
      },
    });

    // <inlineQuestionAnswer> converters
    conversion.for('upcast').elementToElement({
      model: 'inlineQuestionAnswer',
      view: {
        name: 'div',
        classes: 'inline-question-answer',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'inlineQuestionAnswer',
      view: {
        name: 'div',
        classes: 'inline-question-answer hidden-widget',
        attributes: {
          'data-gramm': 'false',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'inlineQuestionAnswer',
      view: (modelItem, { writer }) => {
        const viewElement = writer.createEditableElement('div', {
          class: 'inline-question-answer',
          'data-gramm': 'false',
        });
        return toWidgetEditable(viewElement, writer);
      },
    });
  }
}
