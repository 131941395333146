<template>
  <kog-modal>
    <template #modalBody>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          type="button"
          class="close margin-bottom-l"
          @click="closeModal"
        >
          <kog-icon
            fa-style="regular"
            icon-class="fa-times"
          />
        </button>
      </div>
      <h1 class="heading-s flexContainer flexContainer-justifyCenter margin-bottom-l">
        You don't need to do this assignment
      </h1>
      <div
        class="width-50-percent margin-auto flexContainer flexContainer-alignCenter text-center AssignmentNotNeededModal-textContainer"
      >
        You are either not assigned to this assignment or it has been removed.
      </div>
      <div class="flexContainer flexContainer-spaceBetween flexContainer-alignEnd">
        <img
          :src="peopleIllustrationUrl"
          alt=""
          class="AssignmentNotNeededModal-peopleIllustration"
        />
        <button
          class="KogButtonLegacy KogButtonLegacy--primary"
          @click="closeModal"
        >
          Close
        </button>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

const peopleIllustration = require('learning/common/images/people-2.svg');

export default {
  name: 'AssignmentNotNeededModal',
  components: {
    KogIcon,
    KogModal,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      peopleIllustrationUrl: peopleIllustration,
    };
  },
};
</script>

<style scoped>
.AssignmentNotNeededModal-peopleIllustration {
  width: 300px;
  max-width: 60%;
  margin-bottom: -16px;
}

@media (max-width: 767px) {
  .AssignmentNotNeededModal-textContainer {
    width: 90%;
  }
}
</style>
