<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div class="SubjectOverviewTopic padd-l">
    <div
      v-kog-clickable="isEditingVisibility ? toggleVisibility : undefined"
      v-kog-description:[topic.id]="isEditingVisibility ? 'Toggle content visibility' : ''"
      class="flexContainer-column"
      @mouseover="hoverHeading = true"
      @mouseleave="hoverHeading = false"
      @focusin="hoverHeading = true"
      @focusout="hoverHeading = false"
    >
      <div class="absoluteContainer inline-block">
        <subject-node-visibility-toggle
          class="SubjectOverviewTopic-visibilityToggle"
          :subject-node-id="topic.id"
          :activated="hoverHeading"
        />
        <span class="text-overline"> {{ $term('topic') }} {{ topic.number }} </span>
      </div>
      <content-hidden-label
        :class="{
          visibilityHidden: !isNodeHiddenWhileEditing(topic),
        }"
        class="margin-left-xxs"
      />
      <h2 class="screenreader-only">
        {{ getAriaLabel }}
      </h2>
      <div class="flexContainer flexContainer-spaceBetween">
        <h2
          aria-hidden="true"
          class="heading-l margin-bottom-l"
        >
          {{ topic.name }}
        </h2>
      </div>
      <div
        v-if="isStudent"
        class="flexContainer flexContainer-alignCenter margin-bottom-l"
      >
        <div class="flexContainer flexContainer-alignCenter padd-left-xs">
          <content-node-icon
            class="margin-right-xs"
            icon-class="fa-book-blank"
          />
          <div class="flexContainer-column margin-right-m padd-left-xxs">
            <div class="font-weight-bold text-capitalize"> {{ $term('section') }}s </div>
            <div>
              {{ sectionCompletionText }}
            </div>
          </div>
        </div>
        <router-link
          v-if="!isNgssSubject && showESQOverview"
          class="SubjectOverviewTopic-esqLink flexContainer flexContainer-alignCenter"
          :to="esqPracticeRoute"
          :aria-label="`${$term('exam-style')} questions`"
          :aria-describedby="`${topic.id}-esq-description`"
          @click="trackGoToEsqSetup(topic.id)"
        >
          <content-node-icon
            class="margin-right-xs"
            icon-class="LTQ"
            background-color="orange"
          />
          <div
            :id="`${topic.id}-esq-description`"
            class="flexContainer-column margin-right-m"
            :aria-label="`${esqLinkText} ${$term('exam-style')} questions in the topic ${
              topic.name
            }`"
          >
            <div class="font-weight-bold"> {{ $term('exam-style') }} questions </div>
            <div>
              {{ esqLinkText }}
            </div>
          </div>
          <i class="far fa-chevron-right KogFontAwesomeIcon--s margin-right-m" />
        </router-link>
      </div>
    </div>
    <div
      :class="{
        'divider-top padd-top-l': showDrivingQuestion || hasFeaturesMapped,
      }"
    >
      <div
        v-if="specialIntroductionNode"
        class="margin-bottom-l"
      >
        <kog-loader
          v-if="isFeatureListLoading"
          :loading="isFeatureListLoading"
          loading-msg="Loading features..."
        />
        <template v-else>
          <content-node-item
            v-for="node of specialIntroductionNode.children"
            :key="node.id"
            :data-what="node.id"
            :row-aria-label="`Open ${node.name} for` + ` ${$term('topic')} ${topic.name}`"
            :title="node.name"
            :icon="getIcon(node)"
            :icon-style="getIconStyle(node)"
            :class-size="classSize"
            :reading-completion-data="completionDataPerNodeId[node.id]"
            icon-background-color="pink"
            :internal-link-context="getNodeUrl(node)"
            :user-node-reading-progress="progressStats[node.id]"
            :content-node-id="node.id"
            @click-completion-stats="openCompletionStatsModal({ node })"
          />
        </template>
      </div>
      <div
        v-if="showDrivingQuestion"
        class="padd-left-s flexContainer flexContainer-column margin-bottom-l"
      >
        <span class="text-overline margin-bottom-xs"> Driving question board </span>
        <driving-question-image
          v-if="drivingQuestion"
          :driving-question="drivingQuestion"
          :subject-node-id="drivingQuestionBoardFeature.subjectNodeId"
        />
      </div>
    </div>
    <div
      v-for="subtopic in subtopicsWithoutIntroduction"
      :key="subtopic.id"
      class="divider-top padd-top-xl margin-bottom-xl"
    >
      <subject-overview-subtopic
        :ref="subtopic.id"
        :students="students"
        :subtopic="subtopic"
        :progress-stats="progressStats"
        :book-completion="bookCompletion"
        @click-completion-stats="openCompletionStatsModal"
      />
    </div>
  </div>
</template>

<script>
import { inject, nextTick } from 'vue';
import isNil from 'lodash/isNil.js';
import { mapActions, mapGetters, mapState } from 'vuex';

import { SOURCES } from 'learning/common/components/assignment/create-assignment-button.vue';
import ActivitySubmissionsModal from 'learning/study/components/activities/activity-submissions-modal.vue';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import {
  ACTIVITY_SHEET,
  DRIVING_QUESTION_BOARD,
  getFeatureTypeIcon,
  getFeatureTypeIconStyle,
  PERFORMANCE_TASK,
  QUIZ,
} from 'sharedApp/const/features.js';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import DrivingQuestionImage from 'studyApp/components/driving-question/driving-question-image.vue';
import ContentHiddenLabel from 'studyApp/components/subject-class-overview/content-hidden-label.vue';
import ContentNodeIcon from 'studyApp/components/subject-class-overview/content-node-icon.vue';
import ContentNodeItem from 'studyApp/components/subject-class-overview/content-node-item.vue';
import ReadingCompletionStatsModal from 'studyApp/components/subject-class-overview/reading-completion-stats-modal.vue';
import SubjectNodeVisibilityToggle from 'studyApp/components/subject-class-overview/subject-node-visibility-toggle.vue';
import SubjectOverviewSubtopic from 'studyApp/components/subject-class-overview/subject-overview-subtopic.vue';
import useToggleNodeVisibilityEventHandler from 'studyApp/composables/use-toggle-node-visibility-event-handler.js';
import FeaturesMixin from 'studyApp/mixins/features-mixin.js';
import QuizStudentResponsesModal from 'studyApp/pages/features/quiz/quiz-student-responses-modal.vue';

export default {
  name: 'SubjectOverviewTopic',
  components: {
    DrivingQuestionImage,
    ContentHiddenLabel,
    SubjectNodeVisibilityToggle,
    KogLoader,
    SubjectOverviewSubtopic,
    ContentNodeItem,
    ContentNodeIcon,
  },
  directives: {
    KogClickable,
    KogDescription,
  },
  mixins: [RoutesMixin, FeaturesMixin],
  inject: ['tableOfContentsContext'],
  props: {
    esqStatsByTopicId: {
      type: Object,
      default: () => ({}),
    },
    isFeatureListLoading: {
      type: Boolean,
      required: true,
    },
    isNgssSubject: {
      type: Boolean,
      required: true,
    },
    isTeacher: {
      type: Boolean,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
    topic: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { handleToggleNodeVisibilityEvent } = useToggleNodeVisibilityEventHandler();

    const isFilteringSubjectClassOverview = inject('isFilteringSubjectClassOverview', false);
    const activeFiltersInSubjectClassOverview = inject('activeFiltersInSubjectClassOverview', '');

    return {
      handleToggleNodeVisibilityEvent,
      isFilteringSubjectClassOverview,
      activeFiltersInSubjectClassOverview,
    };
  },
  data() {
    return {
      hoverHeading: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      features: state => state.subjectNodeFeatureModule.features,
      isEditingVisibility: state => state.subjectClassModule.isEditingVisibility,
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
      bookProgress: state => state.teacherInsightsModule.bookProgress,
      subject: state => state.subjectModule.subject,
    }),
    ...mapGetters({
      progressStats: 'statisticModule/progressStats',
      featuresById: 'subjectNodeFeatureModule/featuresById',
      featureMappingsBySubjectNodeId: 'subjectNodeFeatureModule/featureMappingsBySubjectNodeId',
      getDrivingQuestionByDqbId: 'drivingQuestionModule/getDrivingQuestionByDqbId',
      isNodeHiddenWhileEditing: 'subjectClassModule/isNodeHiddenWhileEditing',
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
      leafNodesWithContent: 'subjectModule/leafNodesWithContent',
    }),
    subtopicsWithoutIntroduction() {
      return this.topic.children.filter(subtopic => !subtopic.is_special_introduction_node);
    },
    esqLinkText() {
      if (this.isTeacher) {
        return `${this.esqCount} available`;
      }
      return `${this.esqAvailableForPracticeCount} / ${this.esqCount} available`;
    },
    esqCount() {
      return this.esqStatsByTopicId[this.topic.id]?.questionCount || 0;
    },
    esqAvailableForPracticeCount() {
      return this.esqStatsByTopicId[this.topic.id]?.availableForPracticeCount || 0;
    },
    sectionCompletionText() {
      let completedSections = 0;
      let totalSections = 0;
      this.topic.children.forEach(subtopic => {
        subtopic.children.forEach(section => {
          if (section.children.length === 0) {
            completedSections += this.progressStats[section.id]?.progress_stats.is_completed || 0;
            return;
          }
          completedSections += section.children.every(
            subsection => this.progressStats[subsection.id]?.progress_stats.is_completed || 0,
          );
        });
        totalSections += subtopic.children.length;
      });
      return `${completedSections} / ${totalSections}`;
    },
    specialIntroductionNode() {
      return this.topic.children.find(child => child.is_special_introduction_node);
    },
    specialIntroductionNodeFeatures() {
      if (isNil(this.specialIntroductionNode)) return [];
      return this.specialIntroductionNode.children.reduce((features, child) => {
        const featureMappings = this.featureMappingsBySubjectNodeId[child.id];
        if (featureMappings !== undefined && featureMappings.length > 0) {
          features.push({
            feature: this.featuresById[featureMappings[0].feature_id],
            featureMapping: featureMappings[0],
            subjectNodeId: child.id,
          });
        }
        return features;
      }, []);
    },
    drivingQuestionBoardFeature() {
      if (!this.hasFeaturesMapped) {
        return null;
      }
      return this.specialIntroductionNodeFeatures.find(
        ({ feature }) => feature?.type === DRIVING_QUESTION_BOARD,
      );
    },
    drivingQuestionBoard() {
      return this.drivingQuestionBoardFeature?.feature;
    },
    drivingQuestion() {
      if (isNil(this.drivingQuestionBoard)) return null;
      return this.getDrivingQuestionByDqbId(this.drivingQuestionBoard.id);
    },
    showDrivingQuestion() {
      return (
        !!this.drivingQuestion &&
        (this.drivingQuestion.images.length > 0 || !!this.drivingQuestion.external_url)
      );
    },
    hasFeaturesMapped() {
      return this.specialIntroductionNodeFeatures.length > 0;
    },
    showESQOverview() {
      return this.esqCount > 0;
    },
    esqPracticeRoute() {
      return {
        name: 'classPracticeEsqSetup',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          topicId: this.topic.id,
        },
      };
    },
    isStudent() {
      return this.user.isStudent();
    },
    getAriaLabel() {
      return `${this.$term('topic')} ${this.topic.number}, ${this.topic.name}`;
    },
    classSize() {
      return this.subjectClass?.students_count ?? 0;
    },
    leafNodeIds() {
      return new Set(this.leafNodesWithContent.map(({ id }) => id));
    },
    bookCompletion() {
      return this.bookProgress
        .filter(node => this.nodeHasContent(node) || this.nodeHasFeatures(node))
        .reduce(
          (acc, node) => {
            const nodeId = node.subjectnode_id;
            const userId = node.user_id;

            if (!acc.perNodeId[nodeId]) {
              acc.perNodeId[nodeId] = new Set();
            }
            acc.perNodeId[nodeId].add(userId);

            if (!acc.perUserId[userId]) {
              acc.perUserId[userId] = new Set();
            }
            acc.perUserId[userId].add(nodeId);

            return acc;
          },
          {
            perNodeId: {},
            perUserId: {},
          },
        );
    },
    completionDataPerNodeId() {
      const userCompletionObj = this.bookCompletion.perUserId;
      const sections = this.specialIntroductionNode.children;
      return sections.reduce((acc, section) => {
        const nodeId = section.id;
        acc[nodeId] = new Set();

        const userIds = Object.keys(userCompletionObj);
        userIds.forEach(userId => {
          if (userCompletionObj[userId].has(section.id)) {
            acc[nodeId].add(parseInt(userId, 10));
          }
        });
        return acc;
      }, {});
    },
  },
  watch: {
    'tableOfContentsContext.selectedSubtopic': {
      handler(subtopic) {
        if (!subtopic) return;

        const [ref] = this.$refs[subtopic.id];

        if (ref?.$refs?.root) {
          ref.$refs.root.scrollIntoView({
            behavior: 'smooth',
          });

          nextTick(() => {
            this.tableOfContentsContext.selectedSubtopic = undefined;
          });
        }
      },
    },
  },
  methods: {
    ...mapActions({
      toggleNodeVisibility: 'subjectClassModule/toggleNodeVisibility',
    }),
    getIcon(node) {
      const feature = this.getFeatureBySubjectNode(node);
      return getFeatureTypeIcon(feature?.type);
    },
    getIconStyle(node) {
      const feature = this.getFeatureBySubjectNode(node);
      return getFeatureTypeIconStyle(feature?.type);
    },
    trackGoToEsqSetup() {
      this.$mixpanel.trackEvent('Subject Overview - Navigate to ESQ');
    },
    getNodeUrl(node) {
      let routeName;
      if (this.user.isTeacher()) {
        routeName = 'reviewMode';
      } else {
        routeName = 'classBook';
      }
      return {
        name: routeName,
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
      };
    },
    toggleVisibility() {
      this.handleToggleNodeVisibilityEvent(this.topic);
    },
    nodeHasContent(node) {
      return this.leafNodeIds.has(node.subjectnode_id);
    },
    nodeHasFeatures(node) {
      return this.activityBySubjectNodeId[node.subjectnode_id];
    },
    isPerformanceTask(node) {
      const feature = this.getFeatureBySubjectNode(node);
      return feature ? feature.type === PERFORMANCE_TASK : false;
    },
    isActivitySheets(node) {
      const feature = this.getFeatureBySubjectNode(node);
      return feature ? feature.type === ACTIVITY_SHEET : false;
    },
    isQuiz(node) {
      const feature = this.getFeatureBySubjectNode(node);
      return feature?.type === QUIZ;
    },
    openCompletionStatsModal({ node: subjectNode, filter: initialFilter }) {
      if (this.isPerformanceTask(subjectNode) || this.isActivitySheets(subjectNode)) {
        const feature = this.getFeatureBySubjectNode(subjectNode);
        this.$modal(ActivitySubmissionsModal, {
          subjectNode,
          subjectClassId: this.classId,
          students: this.students,
          subjectClassName: this.subjectClass.name,
          feature,
          user: this.user,
          subject: this.subject,
          classSlug: this.subjectClassSlug,
          source: 'class_overview',
        });
      }
      if (this.isQuiz(subjectNode)) {
        const quiz = this.getFeatureBySubjectNode(subjectNode);
        this.$modal(QuizStudentResponsesModal, {
          quizId: quiz.id,
          studentsInClass: this.students,
          classId: this.classId,
          source: 'class_overview',
          subjectNode,
        });
      } else {
        this.$modal(ReadingCompletionStatsModal, {
          subjectNode,
          subjectClassId: this.classId,
          students: this.students || [],
          readingCompletionData: this.bookCompletion.perUserId,
          initialFilter: initialFilter || 'submitted',
          user: this.user,
          subject: this.subject,
          classSlug: this.subjectClassSlug,
          subjectClassName: this.subjectClass.name,
          source: SOURCES.SUBJECT_CLASS_OVERVIEW_SECTION_MODAL,
        });
      }
      this.$mixpanel.trackEvent('Subject class overview - Open completion stats modal', {
        subject_node_id: subjectNode.id,
        subject_node_name: subjectNode.name,
        source: 'class_overview',
        content_type: this.contentType,
        is_filtering: this.isFilteringSubjectClassOverview,
        active_filters: this.activeFiltersInSubjectClassOverview,
      });
    },
  },
};
</script>

<style scoped>
.SubjectOverviewTopic {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.SubjectOverviewTopic-esqLink {
  width: fit-content;
  color: var(--kogPlatformGray018);
}

.SubjectOverviewTopic-esqLink:hover {
  text-decoration: none;
}

.SubjectOverviewTopic-visibilityToggle {
  position: absolute;
  right: 75%;
  bottom: 50%;
}

.KogAnchor-wrapper {
  padding: 0;
}
</style>
