import * as Sentry from '@sentry/vue';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

function dailySubjectActivityService() {
  const localStorageKey = 'DailySubjectActivity';
  const milliSecondsPerFifteenMinutes = 900000;
  let serviceState;
  let timeNow;
  let currentUserId;

  const clearServiceState = () => {
    serviceState = {
      userId: currentUserId,
      subjectIds: [],
      lastCleared: timeNow,
    };
  };

  const isValidStateFormat = () =>
    serviceState && serviceState.subjectIds && serviceState.lastCleared;

  const initServiceState = userId => {
    currentUserId = userId;
    timeNow = Date.now();
    try {
      if (window.localStorage.getItem(localStorageKey)) {
        serviceState = JSON.parse(window.localStorage.getItem(localStorageKey));
        if (!isValidStateFormat()) {
          clearServiceState();
        }
      } else {
        clearServiceState();
      }
    } catch {
      clearServiceState();
    }
  };

  const storeServiceState = () => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(serviceState));
  };

  const shouldClearServiceCache = () => {
    const timeExpired =
      serviceState.lastCleared &&
      timeNow >= serviceState.lastCleared + milliSecondsPerFifteenMinutes;
    const differentUser = serviceState.userId !== currentUserId;
    return timeExpired || differentUser;
  };

  const isSubjectInState = subjectId => serviceState.subjectIds.includes(subjectId);

  const addSubjectToState = subjectId => serviceState.subjectIds.push(subjectId);

  const registerDailySubjectActivity = async (subjectId, userId) => {
    initServiceState(userId);
    try {
      if (shouldClearServiceCache()) {
        clearServiceState();
      }

      if (!isSubjectInState(subjectId)) {
        const path = '/api/usage_statistics/subject_activity/';
        await axios.post(path, { subject_id: subjectId });
        addSubjectToState(subjectId);
        storeServiceState();
      }
    } catch (error) {
      Sentry.captureMessage(`registerDailySubjectActivity: ${error}`);
    }
  };

  return {
    registerDailySubjectActivity,
  };
}

export default dailySubjectActivityService;
