import { Plugin } from '@ckeditor/ckeditor5-core';

import ExerciseQuestionEditing from './exercise-question-editing.js';
import ExerciseQuestionUI from './exercise-question-ui.js';

export default class ExerciseQuestion extends Plugin {
  static get requires() {
    return [ExerciseQuestionEditing, ExerciseQuestionUI];
  }
}
