<template>
  <div class="StepHeader padd-bottom-s padd-top-s">
    <div
      v-if="currentStep === 1"
      class="StepHeader--stepName heading-m"
    >
      {{ headerTitle }}
    </div>
    <div v-if="currentStep === 2">
      <a
        href="#"
        @click.prevent="goToStep(1)"
        @keyup.prevent="goToStep(1)"
      >
        <i class="fas fa-chevron-left margin-right-xxs" />Step 1
      </a>
      <div class="StepHeader--stepName heading-m"> Modify assignment </div>
    </div>
  </div>
</template>

<script>
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';

export default {
  name: 'StepHeader',
  props: {
    assignmentType: {
      type: String,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    goToStep: {
      type: Function,
      required: true,
    },
  },
  computed: {
    headerTitle() {
      return this.assignmentType === ASSIGNMENT_TYPES.READING
        ? 'Select your sections'
        : 'Select your questions';
    },
  },
};
</script>

<style scoped>
.StepHeader {
  min-height: 65px;
  background-color: var(--kogPlatformWhite);
}

.StepHeader--stepName {
  color: color-mod(var(--kogPlatformGray018) alpha(85%));
}
</style>
