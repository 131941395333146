<template>
  <kog-loader
    :loading="!isPracticeTestReady"
    loading-msg="Setting up questions.."
  >
    <div>
      <practice-test-take
        v-if="!isOccasionTaken"
        :is-showing-navigation-modal="isShowingNavigationModal"
      />
      <router-view v-else />
    </div>
  </kog-loader>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { mapWaitingActions } from 'vue-wait';
import { mapState, useStore } from 'vuex';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';

import PracticeTestTake from './practice-test-take.vue';

export default {
  name: 'PracticeTest',
  components: {
    KogLoader,
    PracticeTestTake,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isOccasionTaken) {
      this.showConfirmationNavigationModal(next);
    } else {
      next();
    }
  },
  setup() {
    const store = useStore();
    const { subjectModule } = store.state;
    const subjectName = computed(() => subjectModule.subject.name);

    useHead({
      title: () => `Take strength test | ${subjectName.value}`,
    });
  },
  data() {
    return {
      isShowingNavigationModal: false,
    };
  },
  computed: {
    ...mapState('practiceOccasionModule', ['practiceOccasion', 'isOccasionTaken']),
    ...mapState('subjectModule', ['subject']),
    isPracticeTestReady() {
      const isPracticeOccasionCreated = !this.$wait.is('fetch_practice_occasion');
      return isPracticeOccasionCreated;
    },
    occasionId() {
      return this.$route.params.occasionId;
    },
  },
  watch: {
    occasionId: {
      handler(id) {
        this.fetchPracticeOccasion(id);
      },
      immediate: true,
    },
    practiceOccasion: {
      handler() {
        if (this.isOccasionTaken) {
          this.$router.replace({ name: 'classStrengthTestResult' });
        }
      },
    },
  },
  methods: {
    ...mapWaitingActions('practiceOccasionModule', {
      fetchPracticeOccasion: 'fetch_practice_occasion',
    }),
    showConfirmationNavigationModal(next) {
      this.isShowingNavigationModal = true;
      this.$modal(KogConfirmModal, {
        title: 'Leave the test?',
        text: 'By navigating away, you will lose all your answers.',
        buttonTitle: 'Yes, leave the test',
        onConfirm: next,
        onCancel: () => {
          this.isShowingNavigationModal = false;
          next(false);
        },
      });
    },
  },
};
</script>

<style scoped></style>
