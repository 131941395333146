<template>
  <div class="Badge">
    <kog-icon
      v-if="icon"
      class="margin-right-xxs"
      :icon-class="icon"
      :is-svg-icon="isSvgIcon"
      fa-style="regular"
      size="s"
      theme="custom"
    />
    {{ text }}
  </div>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'Badge',
  components: {
    KogIcon,
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    isSvgIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.Badge {
  user-select: none;

  display: inline-flex;
  align-items: center;

  padding: 4px 8px;

  font-size: 12px;
  color: var(--kogPlatformGray051);
  text-align: center;
  white-space: nowrap;

  background-color: var(--kogPlatformGray093);
  border-radius: 12px;

  fill: currentColor;
}
</style>
