<template>
  <div
    :class="[
      'FileUpload muted padd-m',
      {
        cursorPointer: !disabled,
      },
    ]"
    @click="triggerInputClick"
    @keyup.enter="triggerInputClick"
  >
    <input
      :id="labelId"
      ref="fileInput"
      :key="isLoading"
      :disabled="inputDisabled"
      :class="{ cursorNotAllowed: isLoading }"
      aria-label="File upload"
      type="file"
      :name="name"
      :accept="acceptedFileTypes"
      class="FileUpload-inputFile cursorPointer"
      @change="fileChanged($event.target.name, $event.target.files)"
      @drop="resetTarget"
      @click="resetTarget"
    />
    <template v-if="!isLoading">
      <slot name="body" />
      <p
        v-if="!$slots.body"
        class="padd-m"
      >
        Drag your file here to begin<br />
        or click to browse
      </p>
    </template>
    <p
      v-else
      class="padd-m"
    >
      {{ uploadingMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  props: {
    labelId: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    uploadingMessage: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    allowedExtensions: {
      type: Array,
      default: () => ['jpg', 'jpeg'],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showToastOnExtensionError: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['upload'],
  data() {
    return {
      multipleFilesErrorMessage: 'Please select one file to upload.',
    };
  },
  computed: {
    fileFormatErrorMessage() {
      const extensions = this.allowedExtensions.join(', ');
      return `Only ${extensions} images are supported.`;
    },
    acceptedFileTypes() {
      const accept = this.allowedExtensions.map(ext => `image/${ext}`);
      return accept.join(', ');
    },
    inputDisabled() {
      return this.isLoading || this.disabled;
    },
  },
  methods: {
    fileChanged(fieldName, files) {
      if (files.length > 1) {
        this.$toast.showError(this.multipleFilesErrorMessage);
        return;
      }

      const file = files[0];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!this.allowedExtensions.includes(fileExtension) && this.showToastOnExtensionError) {
        this.$toast.showError(this.fileFormatErrorMessage);
        return;
      }

      this.$emit('upload', file);
    },
    resetTarget(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    },
    triggerInputClick() {
      this.$refs.fileInput.click();
    },
  },
};
</script>

<style scoped>
.FileUpload {
  position: relative;

  min-height: 200px;

  font-size: 18px;

  background: var(--kogPlatformGray093);
  outline: 2px dashed var(--kogPlatformGray051);
  outline-offset: -10px;
}

.FileUpload-inputFile {
  display: none;
}

.FileUpload:hover:active {
  background: var(--kogPlatformGray084);
}
</style>
