<template>
  <kog-modal
    :maximize="true"
    title="Select recipients"
    :close-modal="closeModal"
  >
    <template #modalBody>
      <div
        id="AssignmentMultiClassRecipientsModal-ClassSelect"
        class="padd-l"
      >
        <kog-table
          v-for="subjectClass in subjectClasses"
          :key="subjectClass.id"
        >
          <template #colgroup>
            <col width="5%" />
            <col width="5%" />
            <col />
            <col width="10%" />
          </template>
          <template #header>
            <kog-table-header>
              <kog-table-cell-header>
                <kog-round-button
                  button-style="basic"
                  aria-label="Click to see students in this class"
                  :icon-class="`fa-chevron-${isSubjectClassExpanded(subjectClass) ? 'up' : 'down'}`"
                  @click="toggleClassExpansion(subjectClass)"
                />
              </kog-table-cell-header>
              <kog-table-cell-checkbox
                :is-checked="subjectClassesCheckedState[subjectClass.id]?.isChecked"
                :is-header-cell="true"
                @check="toggleClass(subjectClass)"
                @uncheck="toggleClass(subjectClass)"
              />
              <kog-table-cell-text
                :is-header-cell="true"
                :is-multi-line="false"
                :is-able-to-truncate="true"
              >
                <div class="flexContainer flexContainer-alignCenter">
                  <image-box
                    v-if="
                      sendToIntegration &&
                      subjectClassesWithIntegrationClassIds.includes(subjectClass.id)
                    "
                    v-tooltip="integrationProviderDisplayName"
                    class="AssignmentMultiClassRecipientsModal-integrationLogo margin-right-xs"
                    :src="integrationLogo"
                    :w="24"
                    :h="24"
                  />
                  {{ subjectClass.name }}
                </div>
              </kog-table-cell-text>
              <kog-table-cell-header>
                <kog-tag
                  is-chip
                  icon-class="fa-user"
                  :label="`${getSelectedStudentCount(subjectClass)}/${getStudentCount(subjectClass)}`"
                  type="outline"
                />
              </kog-table-cell-header>
            </kog-table-header>
          </template>
          <template
            v-if="isSubjectClassExpanded(subjectClass)"
            #body
          >
            <kog-table-row
              v-for="student in studentListByClassId[subjectClass.id]"
              :key="student.school_student"
            >
              <kog-table-cell-empty v-if="student.isKognityStudent && !student.isGoogleStudent" />
              <kog-table-cell-generic
                v-else
                class="GoogleClassroomIconCell flexContainer flexContainer-center"
                :is-horizontal-padding-enabled="false"
              >
                <google-classes-icon
                  v-tooltip="!student.isKognityStudent ? 'Not a student on Kognity' : ''"
                  :style="student.isKognityStudent ? 'classic' : 'light'"
                />
              </kog-table-cell-generic>
              <kog-table-cell-checkbox
                :is-checked="isStudentSelected(student)"
                @check="toggleStudent(student)"
                @uncheck="toggleStudent(student)"
              />
              <kog-table-cell-text :is-multi-line="true">
                <kog-avatar
                  class="margin-right-xs"
                  :avatar-url="student.avatar_url"
                  :name="student.name"
                  theme="accent"
                />
                {{ student.name }}
                <kog-tag
                  v-if="subjectHasLevels"
                  class="margin-left-xs"
                  :label="getStudentLevelName(student)"
                />
              </kog-table-cell-text>
              <kog-table-cell-empty />
            </kog-table-row>
          </template>
        </kog-table>
      </div>
    </template>

    <template #modalFooter>
      <div
        class="width-100 flexContainer flexContainer-center flexContainer-spaceBetween padd-left-xl"
      >
        <div class="muted">
          {{ numberOfSelectedStudentsText }}
        </div>
        <div>
          <kog-button
            class="margin-right-xxs"
            label="Cancel"
            @click="closeModal"
          />
          <kog-button
            button-style="primary"
            label="Update recipients"
            :disabled="!hasSelectedStudent"
            :tooltip="!hasSelectedStudent ? 'Please select at least one student' : ''"
            @click="updateRecipientsAndCloseModal"
          />
        </div>
      </div>
    </template>
  </kog-modal>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import { keyBy, uniqBy } from 'lodash';
import pluralize from 'pluralize';
import { useStore } from 'vuex';

import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import KogTableCellCheckbox from 'sharedApp/components/tables/kog-table-cell-checkbox.vue';
import KogTableCellEmpty from 'sharedApp/components/tables/kog-table-cell-empty.vue';
import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';
import GoogleClassesIcon from 'studyApp/components/teacher/google-classes-icon.vue';

const props = defineProps({
  initialSelectedStudentIds: {
    type: Array,
    required: true,
  },
  updateRecipients: {
    type: Function,
    required: true,
  },
  googleClassroomRecipients: {
    type: Array,
    required: true,
  },
  closeModal: {
    type: Function,
    required: true,
  },
  studentListByClassId: {
    type: Object,
    required: true,
  },
  sendToIntegration: {
    type: Boolean,
    default: false,
  },
  subjectClassesWithIntegrationClassIds: {
    type: Array,
    default: () => [],
  },
  integrationProviderDisplayName: {
    type: String,
    default: '',
  },
  integrationLogo: {
    type: String,
    default: '',
  },
});

const vuexStore = useStore();

const selectedStudentsById = ref({});
const selectedGoogleClassStudentsByUpstreamId = ref({});
const expandedClassIds = ref([]);

const subject = computed(() => vuexStore.state.subjectModule.subject);
const activeClasses = computed(() => vuexStore.getters['subjectClassModule/activeClasses']);
const user = computed(() => vuexStore.state.userModule.user);

function hasStudents(subjectClass) {
  return props.studentListByClassId[subjectClass.id].length > 0;
}

function isStudentSelected(student) {
  if (student.isKognityStudent) {
    return !!selectedStudentsById.value[student.school_student];
  }
  if (student.isGoogleStudent) {
    return !!selectedGoogleClassStudentsByUpstreamId.value[student.upstreamId];
  }
  throw new Error('Student is neither Kognity nor Google student');
}

function isSubjectClassSelected(subjectClass) {
  const students = props.studentListByClassId[subjectClass.id];
  if (students.length === 0) return false;
  if (students.every(student => isStudentSelected(student))) return true;
  if (students.some(student => isStudentSelected(student))) return 'mixed';
  return false;
}

const subjectHasLevels = computed(() => subject.value.possible_levels.length > 0);

const subjectClasses = computed(() => {
  return activeClasses.value.filter(subjectClass => {
    return (
      subject.value.id === subjectClass.subject_id &&
      subjectClass.teacher_user_ids.includes(user.value.id) &&
      hasStudents(subjectClass)
    );
  });
});

const selectedSubjectClassesById = computed(() => {
  return subjectClasses.value
    .filter(subjectClass => {
      return isSubjectClassSelected(subjectClass);
    })
    .reduce((acc, subjectClass) => {
      acc[subjectClass.id] = subjectClass;
      return acc;
    }, {});
});

const subjectClassesById = computed(() => keyBy(subjectClasses.value, 'id'));

const subjectClassesCheckedState = computed(() => {
  const checkedStates = subjectClasses.value.map(subjectClass => {
    return {
      id: subjectClass.id,
      isChecked: isSubjectClassSelected(subjectClass),
    };
  });
  return keyBy(checkedStates, 'id');
});

const numberOfSelectedStudentsText = computed(() => {
  const numStudents = Object.values(selectedStudentsById.value).length;
  return `${pluralize('student', numStudents, true)} selected`;
});

const nonDuplicateStudents = computed(() => {
  if (Object.keys(props.studentListByClassId).length === 0) return [];
  const classIds = Object.keys(subjectClassesById.value);
  const classStudents = classIds.flatMap(id => props.studentListByClassId[id]);
  return uniqBy(classStudents, 'school_student');
});

const hasSelectedStudent = computed(() => Object.values(selectedStudentsById.value).length > 0);

const hasMultipleClasses = computed(() => subjectClasses.value.length > 1);

function getStudentLevelName(student) {
  if (!student.level) return '';
  return getTerm(student.level.name, subject.value.educationsystem.name);
}

function getStudentCount(subjectClass) {
  return props.studentListByClassId[subjectClass.id].length;
}

function getSelectedStudentCount(subjectClass) {
  if (!isSubjectClassSelected(subjectClass)) return 0;
  const classStudents = props.studentListByClassId[subjectClass.id];
  const selectedStudents = classStudents?.filter(student => isStudentSelected(student)) ?? [];
  return selectedStudents.length;
}

function selectStudent(student) {
  if (student.isKognityStudent) {
    selectedStudentsById.value[student.school_student] = student;
  }
  if (student.isGoogleStudent) {
    selectedGoogleClassStudentsByUpstreamId.value[student.upstreamId] = true;
  }
}

function deselectStudent(student) {
  if (student.isKognityStudent) {
    delete selectedStudentsById.value[student.school_student];
  }
  if (student.isGoogleStudent) {
    selectedGoogleClassStudentsByUpstreamId.value[student.upstreamId] = false;
  }
}

function toggleStudent(student) {
  if (isStudentSelected(student)) {
    deselectStudent(student);
  } else {
    selectStudent(student);
  }
}

function toggleClass(subjectClass) {
  if (isSubjectClassSelected(subjectClass) === true) {
    props.studentListByClassId[subjectClass.id].forEach(student => {
      deselectStudent(student);
    });
  } else {
    props.studentListByClassId[subjectClass.id].forEach(student => {
      selectStudent(student);
    });
  }
}

function setInitialSelectedStudents() {
  props.initialSelectedStudentIds.forEach(studentId => {
    const student = nonDuplicateStudents.value.find(s => s.school_student === Number(studentId));
    toggleStudent(student);
  });
  props.googleClassroomRecipients.forEach(gcClass => {
    gcClass.recipient_upstream_ids.forEach(upstreamId => {
      selectedGoogleClassStudentsByUpstreamId.value[upstreamId] = true;
    });
  });
}

function isSubjectClassExpanded(subjectClass) {
  return expandedClassIds.value.find(subjectClassId => subjectClassId === subjectClass.id);
}

function expandClass(subjectClass) {
  expandedClassIds.value.push(subjectClass.id);
}

function collapseClass(subjectClass) {
  expandedClassIds.value = expandedClassIds.value.filter(
    subjectClassId => subjectClassId !== subjectClass.id,
  );
}

function toggleClassExpansion(subjectClass) {
  if (!hasMultipleClasses.value) return;
  if (isSubjectClassExpanded(subjectClass)) collapseClass(subjectClass);
  else expandClass(subjectClass);
}

function updateRecipientsAndCloseModal() {
  props.updateRecipients(
    Object.values(selectedSubjectClassesById.value),
    Object.values(selectedStudentsById.value),
    selectedGoogleClassStudentsByUpstreamId.value,
  );
  props.closeModal();
}

onBeforeMount(() => {
  setInitialSelectedStudents();
  if (!hasMultipleClasses.value) {
    expandClass(subjectClasses.value[0]);
  }
});
</script>

<style scoped>
.GoogleClassroomIconCell {
  height: inherit;
}

.AssignmentMultiClassRecipientsModal-integrationLogo {
  width: 24px;
  height: 24px;
  background-color: var(--kog-table-header-background-color);
}
</style>
