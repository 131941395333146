import { mapGetters } from 'vuex';

import { FEATURES_BY_TYPE } from 'sharedApp/const/features.js';

const FeaturesMixin = {
  computed: {
    ...mapGetters({
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
    }),
    featureConstByType() {
      return FEATURES_BY_TYPE;
    },
  },
  methods: {
    getFeatureBySubjectNodeId(subjectNodeId) {
      return this.activityBySubjectNodeId[subjectNodeId] ?? null;
    },
    getFeatureBySubjectNode(subjectNode) {
      const feature = this.getFeatureBySubjectNodeId(subjectNode.id);
      if (feature) {
        return feature;
      }

      if (subjectNode.section_template === 'reflection') {
        return { type: FEATURES_BY_TYPE.REFLECTION.type };
      }

      return null;
    },
    hasNodeIdFeature(nodeId) {
      return !!this.activityBySubjectNodeId[nodeId];
    },
  },
};

export default FeaturesMixin;
