<template>
  <div>
    <kog-item-list
      v-if="battles"
      role="list"
      :is-handling-tab-index="true"
    >
      <practice-battle-card
        v-for="battle in battles"
        :key="battle.id"
        role="button"
        tabindex="-1"
        :battle="battle"
      />
    </kog-item-list>
    <p v-if="!battles || battles.length === 0">
      {{ emptyMessage }}
    </p>
  </div>
</template>

<script>
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';

import PracticeBattleCard from './practice-battle-card.vue';

export default {
  name: 'BattlesList',
  components: {
    PracticeBattleCard,
    KogItemList,
  },
  props: {
    battles: {
      type: Array,
      required: true,
    },
    emptyMessage: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
