<template>
  <div
    v-tooltip="{
      theme: 'kog-tooltip',
      content: `Assignment will be automatically sent to ${integrationProvider}`,
    }"
    class="IntegrationAssignmentStatusTag margin-top-s margin-bottom-s border-standard flexContainer flexContainer-row flexContainer-justifyCenter"
  >
    <image-box
      class="IntegrationAssignmentStatusTag-logo margin-right-xs margin-left-xxs"
      :src="integrationProviderLogo"
      :w="16"
      :h="16"
      :alt="`Logo for ${integrationProvider}`"
    />
    <div class="text-medium padding-top-xxs margin-right-xxs">
      Sending to {{ integrationProviderDisplayName }}
    </div>
  </div>
</template>

<script>
import {
  getIntegrationProviderDisplayName,
  getIntegrationProviderLogo,
} from 'learning/common/services/integration/integration-service.js';

import ImageBox from 'sharedApp/components/images/image-box.vue';

export default {
  name: 'AssignmentReviewBox',
  components: { ImageBox },
  props: {
    integrationProvider: {
      type: String,
      default: 'Edlink',
    },
  },
  computed: {
    integrationProviderDisplayName() {
      return getIntegrationProviderDisplayName(this.integrationProvider);
    },
    integrationProviderLogo() {
      return getIntegrationProviderLogo(this.integrationProvider);
    },
  },
};
</script>

<style scoped>
.IntegrationAssignmentStatusTag {
  width: fit-content;
  height: var(--space-l);
  background-color: var(--kogPlatformWhite);
  border-radius: 8px;
}
.IntegrationAssignmentStatusTag-logo {
  width: var(--space-m);
  height: var(--space-m);
  margin-top: 3px;
  background-color: var(--kogPlatformWhite);
}
</style>
