<template>
  <kog-table>
    <template #colgroup>
      <col width="66%" />
      <col width="34%" />
    </template>
    <template #header>
      <kog-table-header>
        <kog-table-cell-header> Task </kog-table-cell-header>
        <kog-table-cell-header> Date completed </kog-table-cell-header>
      </kog-table-header>
    </template>

    <template #body>
      <kog-table-row
        v-for="(section, index) in assignment.sections"
        :key="index"
        :row-index="index"
      >
        <kog-table-cell-generic>
          <div class="flexContainer flexContainer-alignCenter">
            <kog-icon
              v-if="isCompleted(studentSectionProgress[section.subject_node_id])"
              class="ReadingAssignmentStudentProgressTable-Icon ReadingAssignmentStudentProgressTable-Icon--completed margin-right-s padd-s"
              icon-class="fa-check"
              theme="custom"
              size="s"
            />
            <div
              v-else
              class="ReadingAssignmentStudentProgressTable-Icon flexContainer flexContainer-alignCenter flexContainer-justifyCenter margin-right-s"
            >
              {{ index + 1 }}
            </div>
            <span class="margin-right-s">{{ section.number_including_ancestors }}</span>
            <span>{{ section.name }}</span>
          </div>
        </kog-table-cell-generic>

        <kog-table-cell-text
          :is-able-to-truncate="true"
          :tag-label="isLate(studentSectionProgress[section.subject_node_id]) ? 'Late' : ''"
        >
          {{
            firstCompletedAtDateFormatted(
              studentSectionProgress[section.subject_node_id].first_completed_at,
            )
          }}
        </kog-table-cell-text>
      </kog-table-row>
    </template>
  </kog-table>
</template>

<script>
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import {
  isReadingAssignmentCompleted,
  isReadingAssignmentLate,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'ReadingAssignmentStudentProgressTable',
  components: {
    KogIcon,
    KogTable,
    KogTableHeader,
    KogTableRow,
    KogTableCellGeneric,
    KogTableCellText,
    KogTableCellHeader,
  },
  props: {
    studentSectionProgress: {
      type: Object,
      required: true,
    },
    assignment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    firstCompletedAtDateFormatted(date) {
      return date ? dateTimeFormatter(new Date(date)) : '';
    },
    isLate(nodeProgress) {
      return isReadingAssignmentLate(nodeProgress, this.assignment);
    },
    isCompleted(nodeProgress) {
      return isReadingAssignmentCompleted(nodeProgress);
    },
  },
};
</script>

<style scoped>
.ReadingAssignmentStudentProgressTable-Icon {
  width: 24px;
  min-width: 24px;
  height: 24px;

  color: var(--kog-colors-grey-900);

  background-color: var(--kog-colors-grey-300);
  border-radius: 50%;
}

.ReadingAssignmentStudentProgressTable-Icon--completed {
  background-color: var(--kog-colors-green-500);
}
</style>
