<template>
  <div>
    <book-print-header />
    <annotate-text
      class="BookPrint-sectionContent"
      :current-subtopic-text="currentSubtopicText"
      :highlight-enabled="false"
      :subject="subject"
      :subject-node="subjectNode"
      :content="section.contents_html"
      :user="user"
      :subject-tree="subject.subject_tree"
      :is-in-review-mode="false"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

import AnnotateText from 'learning/common/components/annotate-text.vue';

import * as subjectNodeFeaturesApi from 'sharedApp/apis/subject-node-features.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { fetchSectionDetails } from 'studyApp/api/sections.js';
import BookPrintHeader from 'studyApp/components/book-print-header.vue';

export default {
  name: 'ScpDqbPrint',
  components: {
    BookPrintHeader,
    AnnotateText,
  },
  mixins: [RoutesMixin],
  emits: ['ready-to-print'],
  data() {
    return {
      section: {},
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getTopicNodeByDescendantId: 'subjectModule/getTopicNodeByDescendantId',
      getSubtopicNodeByDescendantId: 'subjectModule/getSubtopicNodeByDescendantId',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    subjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    topicNode() {
      return this.getTopicNodeByDescendantId(this.nodeId);
    },
    subtopicNode() {
      return this.getSubtopicNodeByDescendantId(this.nodeId);
    },
    currentSubtopicText() {
      return `${this.topicNode.number}.${this.subtopicNode.number} ${this.subtopicNode.name}`;
    },
    activity() {
      return this.getActivityBySubjectNodeId(this.nodeId);
    },
  },
  async created() {
    const activityInstance = await subjectNodeFeaturesApi.fetchFeatureInstance({
      featureId: this.activity.id,
      featureType: this.activity.type,
    });
    this.section = await fetchSectionDetails(activityInstance.section);
    this.$emit('ready-to-print');
  },
};
</script>
