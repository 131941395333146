<template>
  <div>
    <feature-section-container
      class="DqbSectionContainer-featureSection"
      :is-full-height="false"
      :is-review-mode="isReviewMode ? 'true' : null"
    >
      <template
        v-if="isReviewMode"
        #default
      >
        <div class="DqbSectionContainer-content width-full">
          <div class="padd-bottom-l">
            <h3> Saving the Driving Questions Board </h3>
            <p class="margin-bottom-s">
              When you have completed the Driving Question Board with your class, add the main
              class-generated driving question from the class DQB. This will enable you to have the
              question at hand, as you and your students progress through the unit.
            </p>
          </div>
        </div>
        <driving-question-upload :subject-node-id="nodeId" />
      </template>
    </feature-section-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import DrivingQuestionUpload from 'studyApp/components/driving-question/driving-question-upload.vue';
import FeatureSectionContainer from 'studyApp/containers/feature-section-container.vue';

export default {
  name: 'DqbSectionContainer',
  components: {
    FeatureSectionContainer,
    DrivingQuestionUpload,
  },
  mixins: [RoutesMixin],
  props: {
    isReviewMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
    }),
  },
  created() {
    const subjectNode = this.subjectNodesById[this.nodeId];
    this.$mixpanel.trackEvent('Driving Question Board - Open activity page', {
      subject_node_id: this.nodeId,
      subject_node_name: `${subjectNode.formatted_number_including_ancestors} ${subjectNode.name}`,
    });
  },
};
</script>

<style scoped>
.DqbSectionContainer-featureSection {
  min-height: 73vh;
}

.DqbSectionContainer-content {
  font-family: var(--kog-lato);
  font-size: var(--kog-font-size-content);
  line-height: 26px;
  color: var(--kogPlatformGray018);
}
</style>
