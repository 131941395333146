import { Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget, toWidgetEditable, Widget } from '@ckeditor/ckeditor5-widget';

import { forbidNesting } from '../utils/commands.js';
import InsertReflectionBoxCommand from './reflection-box-command.js';

export default class ReflectionBoxEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.editor.commands.add('insertReflectionBox', new InsertReflectionBoxCommand(this.editor));
  }

  defineSchema() {
    const { schema } = this.editor.model;

    schema.register('reflectionBox', {
      inheritAllFrom: '$blockObject',
    });

    schema.register('reflectionBoxHeader', {
      isLimit: true,
      allowIn: 'reflectionBox',
      allowContentOf: '$root',
    });

    schema.register('reflectionBoxTitle', {
      isLimit: true,
      allowIn: 'reflectionBoxHeader',
      allowContentOf: '$root',
    });

    schema.register('reflectionBoxContent', {
      isLimit: true,
      allowIn: 'reflectionBox',
      allowContentOf: '$root',
    });

    schema.addChildCheck(forbidNesting('reflectionBox'));
  }

  defineConverters() {
    const { conversion } = this.editor;

    // <reflectionBox> converters
    conversion.for('upcast').elementToElement({
      model: 'reflectionBox',
      view: {
        name: 'div',
        classes: 'reflection-box',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'reflectionBox',
      view: {
        name: 'div',
        classes: 'reflection-box',
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'reflectionBox',
      view: (_, { writer }) => {
        const viewElement = writer.createContainerElement('div', { class: 'reflection-box' });
        return toWidget(viewElement, writer, { label: 'reflection box widget' });
      },
    });

    // <reflectionBoxHeader> converters
    conversion.elementToElement({
      model: 'reflectionBoxHeader',
      view: {
        name: 'div',
        classes: 'reflection-box-header',
      },
    });

    // <reflectionBoxTitle> converters
    conversion.for('upcast').elementToElement({
      model: 'reflectionBoxTitle',
      view: {
        name: 'div',
        classes: 'reflection-box-title',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'reflectionBoxTitle',
      view: {
        name: 'div',
        classes: 'reflection-box-title',
        attributes: {
          'data-gramm': 'false',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'reflectionBoxTitle',
      view: (_, { writer }) => {
        const viewElement = writer.createEditableElement('div', {
          class: 'reflection-box-title',
          'data-gramm': 'false',
        });
        return toWidgetEditable(viewElement, writer);
      },
    });

    // <reflectionBoxContent> converters
    conversion.for('upcast').elementToElement({
      model: 'reflectionBoxContent',
      view: {
        name: 'div',
        classes: 'reflection-box-content',
      },
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'reflectionBoxContent',
      view: {
        name: 'div',
        classes: 'reflection-box-content',
        attributes: {
          'data-gramm': 'false',
        },
      },
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'reflectionBoxContent',
      view: (_, { writer }) => {
        const viewElement = writer.createEditableElement('div', {
          class: 'reflection-box-content',
          'data-gramm': 'false',
        });
        return toWidgetEditable(viewElement, writer);
      },
    });
  }
}
