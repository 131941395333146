import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import LinkCommand from '@ckeditor/ckeditor5-link/src/linkcommand.js';
import UnlinkCommand from '@ckeditor/ckeditor5-link/src/unlinkcommand.js';
import { inlineHighlight, TwoStepCaretMovement } from '@ckeditor/ckeditor5-typing';

export default class SectionLinkEditing extends Plugin {
  static get requires() {
    return [TwoStepCaretMovement];
  }

  init() {
    this.defineSchema();
    this.defineConverters();

    this.editor.commands.add('link', new LinkCommand(this.editor));
    this.editor.commands.add('updateLink', new Command(this.editor));
    this.editor.commands.add('unlink', new UnlinkCommand(this.editor));

    const twoStepCaretMovementPlugin = this.editor.plugins.get(TwoStepCaretMovement);
    twoStepCaretMovementPlugin.registerAttribute('linkHref');

    inlineHighlight(this.editor, 'linkHref', 'a', 'ck-section-link__selected');
  }

  defineSchema() {
    const { editor } = this;
    const { view } = editor.editing;
    editor.model.schema.extend('$text', { allowAttributes: ['linkHref'] });
    editor.listenTo(view.document, 'click', event => {
      const commandValue = editor.commands.get('link').value;
      if (commandValue) {
        editor.commands.get('updateLink').execute();
      }
    });
  }

  defineConverters() {
    this.editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'a',
      },
      model: {
        key: 'linkHref',
        value: viewElement => {
          const href = viewElement.getAttribute('href');
          const target = viewElement.getAttribute('target') || 'not_set';
          const attributes = { href, target };
          return attributes;
        },
      },
    });

    this.editor.conversion.for('downcast').attributeToElement({
      model: 'linkHref',
      view: (modelItem, { writer }) => {
        const href = modelItem?.href;
        let target = modelItem?.target;
        target = target === 'not_set' ? null : target;
        const linkElement = this.createLinkElement(href, target, writer);
        return linkElement;
      },
    });
  }

  // eslint-disable-next-line  class-methods-use-this
  createLinkElement(href, target, writer) {
    // Priority 5 - https://github.com/ckeditor/ckeditor5-link/issues/121.
    const linkElement = writer.createAttributeElement(
      'a',
      { href, ...(target && { target }) },
      { priority: 5 },
    );
    return linkElement;
  }
}
