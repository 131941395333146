import { Command } from '@ckeditor/ckeditor5-core';

import { findNonParagraphAncestor } from '../utils/commands.js';
import { exerciseQuestionModelTag } from './exercise-question-constants.js';

export default class InsertExerciseQuestionCommand extends Command {
  execute(selectedQuestionId) {
    const { model } = this.editor;
    model.change(writer => {
      const selection = findNonParagraphAncestor(writer, model.document.selection);
      model.insertObject(this.create(writer, selectedQuestionId), selection);
    });
  }

  getSelectedExerciseQuestionId() {
    const { document } = this.editor.model;
    const firstRange = document.selection.getFirstRange();
    if (
      !firstRange.isCollapsed &&
      firstRange.start.nodeAfter?.name === 'kogExerciseQuestionModel'
    ) {
      const node = firstRange.start.nodeAfter;
      const value = {
        questionId: node.getAttribute('questionId'),
      };
      return value;
    }
    return null;
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const allowedIn = schema.findAllowedParent(
      document.selection.getFirstPosition(),
      exerciseQuestionModelTag,
    );
    this.isEnabled = allowedIn !== null;
    this.value = this.getSelectedExerciseQuestionId();
  }

  // eslint-disable-next-line class-methods-use-this
  create(writer, selectedQuestionId) {
    return writer.createElement(exerciseQuestionModelTag, { questionId: selectedQuestionId });
  }
}
