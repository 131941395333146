<template>
  <div>
    <div class="PerformanceTaskSubmissions-container flexContainer">
      <performance-task-submission-sidebar
        class="PerformanceTaskSubmissions-sidebar"
        :students-list="studentsDisplayList"
        :selected-student-id="selectedStudentId"
        :is-anonymized="isAnonymized"
        @select-student="selectStudent"
        @send-all-grades="onSendAllGrades"
        @toggle-anonymized="onToggleAnonymized"
      />
      <kog-card
        :default-padding="false"
        class="flexChild-size-1 margin-l"
      >
        <performance-task-submissions-container
          class="margin-top-s"
          :feature="feature"
          :students-list="studentsDisplayList"
          :selected-student-id="selectedStudentId"
          :is-anonymized="isAnonymized"
          @student-selection-change="selectStudent"
        />
        <kog-wave-separator class="margin-top-l width-full" />
        <student-stepper
          :students-list="studentsDisplayList"
          :selected-student-id="selectedStudentId"
          @select-student="selectStudent"
        />
      </kog-card>
    </div>
  </div>
</template>
<script>
import { shuffle } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

import KogWaveSeparator from 'sharedApp/components/base/separators/kog-wave-separator.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import PerformanceTaskSubmissionSidebar from 'studyApp/components/activities/performance-task-submission-sidebar.vue';
import StudentStepper from 'studyApp/components/activities/student-stepper.vue';
import PerformanceTaskSubmissionsContainer from 'studyApp/containers/performance-task/performance-task-submissions-container.vue';
import { PERFORMANCE_TASK_MODULE } from 'studyApp/store/modules/performance-task.js';

export default {
  name: 'PerformanceTaskSubmissions',
  components: {
    KogCard,
    KogWaveSeparator,
    PerformanceTaskSubmissionsContainer,
    PerformanceTaskSubmissionSidebar,
    StudentStepper,
  },
  mixins: [RoutesMixin],
  props: {
    feature: { type: Object, required: true },
    studentsList: { type: Array, required: true },
  },
  data() {
    return {
      selectedStudentId: null,
      isAnonymized: false,
      anonymizedStudentsList: [],
    };
  },
  computed: {
    ...mapState({
      performanceTask: state => state[PERFORMANCE_TASK_MODULE].performanceTask,
    }),
    ...mapGetters(PERFORMANCE_TASK_MODULE, {
      ptScoredOccasionByStudentUserId: 'scoredOccasionByStudentUserId',
      ptCurrentOccasion: 'currentOccasion',
      isAllClassOccasionsGraded: 'isAllClassOccasionsGraded',
      isAllClassMarksSent: 'isAllClassMarksSent',
      isAnyMarksChangedAfterSending: 'isAnyMarksChangedAfterSending',
    }),
    studentsDisplayList() {
      return this.isAnonymized ? this.anonymizedStudentsList : this.studentsList;
    },
  },
  watch: {
    studentsList: {
      immediate: true,
      deep: true,
      handler(students) {
        this.refreshAnonymizedStudentsList();
        if (students && !this.selectedStudentId) {
          const firstStudentId = students[0]?.user_id ?? null;
          if (firstStudentId) {
            this.selectStudent(firstStudentId);
          }
        }
      },
    },
    selectedStudentId() {
      if (+this.$route.query.student === this.selectedStudentId) {
        return;
      }

      this.$router.replace({
        query: {
          ...this.$route.query,
          student: this.selectedStudentId,
        },
      });
    },
  },
  created() {
    if (this.$route.query.student) {
      this.selectedStudentId = +this.$route.query.student;
    }
  },
  methods: {
    ...mapActions(PERFORMANCE_TASK_MODULE, ['sendAllGrades']),
    sendAllGradesModal() {
      return this.$modal(KogConfirmModal, {
        title: 'Send grades?',
        text: 'You can still edit grades after sending it to students.',
        buttonTitle: 'Send',
        confirmButtonStyle: 'accent',
        cancelButtonStyle: '',
        onConfirm: this.sendAllClassGrades,
      });
    },
    sendSomeGradesModal() {
      return this.$modal(KogConfirmModal, {
        title: 'Send without all grades?',
        text: "All students submissions are not graded yet. You can still send the grades you've set so far.",
        buttonTitle: 'Send',
        confirmButtonStyle: 'accent',
        cancelButtonStyle: '',
        onConfirm: this.sendAllClassGrades,
      });
    },
    onSendAllGrades() {
      if (!this.isAllClassOccasionsGraded) {
        this.sendSomeGradesModal();
      } else {
        this.sendAllGradesModal();
      }
    },
    async sendAllClassGrades() {
      let success = false;
      try {
        await this.sendAllGrades({
          performanceTaskId: this.performanceTask.id,
          subjectClassId: this.classId,
        });
        success = true;
      } catch {
        this.$toast.showError('Failed to send grades. Please refresh the page and try again.');
      }
      if (success) {
        this.$event.track('send_performance_task_grading_to_class', {
          subject_id: this.subjectId,
          subject_class_id: this.classId,
          subject_node_id: this.nodeId,
          performance_task_id: this.performanceTask.id,
        });

        this.$mixpanel.trackEvent('Performance task - Send grades to class', {
          performance_task_id: this.performanceTask.id,
        });
      }
    },
    selectStudent(studentId) {
      this.selectedStudentId = studentId;
    },
    refreshAnonymizedStudentsList() {
      const shuffledStudents = shuffle(this.studentsList);
      this.anonymizedStudentsList = shuffledStudents.map((student, index) => {
        return {
          ...student,
          name: `Student ${index + 1}`,
        };
      });
    },
    onToggleAnonymized(value) {
      this.isAnonymized = value;
      this.refreshAnonymizedStudentsList();
      const firstStudentId = this.studentsDisplayList[0]?.user_id ?? null;
      this.selectStudent(firstStudentId);
      this.$mixpanel.trackEvent('Performance Task - Toggle Anonymous Grading', {
        anonymous: value,
      });
    },
  },
};
</script>

<style scoped>
.PerformanceTaskSubmissions-loader {
  padding-top: var(--full-page-navbar-height);
}

.PerformanceTaskSubmissions-container {
  min-height: 100vh;
  padding-top: var(--full-page-navbar-height);
}

.PerformanceTaskSubmissions-sidebar {
  position: sticky;
  top: calc(var(--full-page-navbar-height) + var(--space-l));

  min-width: 300px;
  max-width: 300px;
  max-height: calc(100vh - var(--full-page-navbar-height) - 2 * var(--space-l));
}

.PerformanceTaskSubmissions-studentSelect {
  width: 244px;
}
</style>
