<template>
  <div class="GoogleClassroomReminder-container">
    <generic-warning-box :has-padd-bottom="false">
      <div class="flexContainer">
        <div class="flexChild-noGrow margin-right-xs GoogleClassroomReminder-icon">
          <kog-font-awesome-icon
            fa-class="fa-exclamation-triangle"
            fa-style="regular"
            size="s"
          />
        </div>
        <div class="flexChild-size-1">
          <p class="heading-xs margin-bottom-xs">
            Remember to ‘mark as done’ in Google Classroom
          </p>
          <p>
            This assignment was sent via Google Classroom. Go to
            <a
              href="https://classroom.google.com/"
              class="GoogleClassroomReminder-link"
              >Google Classroom</a
            >
            and mark this assignment as ‘done’ so it counts as submitted.
          </p>
        </div>
      </div>
    </generic-warning-box>
  </div>
</template>

<script>
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';

export default {
  components: {
    KogFontAwesomeIcon,
    GenericWarningBox,
  },
};
</script>

<style scoped>
.GoogleClassroomReminder-link {
  color: var(--kogPlatformGray018);
  text-decoration: underline;
}

.GoogleClassroomReminder-container {
  width: 100%;
  max-width: 576px;
  margin-right: auto;
  margin-left: auto;
}

.GoogleClassroomReminder-icon {
  margin-top: 2px;
}
</style>
