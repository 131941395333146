<template>
  <kog-table>
    <template #colgroup>
      <col />
      <col width="73%" />
      <col v-if="showDifficulty" />
      <col />
      <col width="27%" />
    </template>
    <template #header>
      <kog-table-header>
        <kog-table-cell-header> # </kog-table-cell-header>
        <kog-table-cell-header>
          <span v-if="assignment.questions.length === 1"> Question </span>
          <kog-dropdown-button
            v-else
            :items="sortItems"
            label="Question"
            @select="setSortOrder"
          />
        </kog-table-cell-header>
        <kog-table-cell-header
          v-if="showDifficulty"
          v-tooltip="{
            theme: 'kog-tooltip',
            content: 'A difficulty level is added to each question by our subject experts.',
            boundary: 'document.body',
          }"
          aria-label="A difficulty level is added to each question by our subject experts."
        >
          Difficulty
        </kog-table-cell-header>
        <kog-table-cell-header> Type </kog-table-cell-header>
        <kog-table-cell-header> Responses </kog-table-cell-header>
        <kog-table-cell-header />
      </kog-table-header>
    </template>
    <template #body>
      <kog-table-row
        v-for="question in sortedQuestions"
        :key="question.id"
      >
        <kog-table-cell-text>
          {{ getQuestionOrderNumber(question.id) + 1 }}
        </kog-table-cell-text>
        <kog-table-cell-text :is-able-to-truncate="true">
          <div
            v-kog-clickable:[question]="previewQuestion"
            v-kog-description:[getUniqueId(question.id)]="'Click to open question preview'"
            class="AssignmentDetailsQuestions-questionText"
            v-text="getQuestionText(question.question_html)"
          />
        </kog-table-cell-text>
        <kog-table-cell-generic v-if="showDifficulty">
          <kog-difficulty
            class="flexTable__item text-center"
            :difficulty="question.difficulty"
          />
        </kog-table-cell-generic>
        <kog-table-cell-generic>
          <question-type-label
            class="center-block"
            :question-type="question.type"
            :tooltip-text="getQuestionType(question)"
          />
        </kog-table-cell-generic>
        <kog-table-cell-generic>
          <kog-progress-bar
            type="mixed"
            :progress="allQuestionsProgressBarsItems[question.id]"
          />
        </kog-table-cell-generic>
        <kog-table-cell-actions
          :actions="questionActions"
          :context="question"
        />
      </kog-table-row>
    </template>
  </kog-table>
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogDifficulty from 'learning/common/components/difficulty/kog-difficulty.vue';

import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import KogTableCellActions from 'sharedApp/components/tables/kog-table-cell-actions.vue';
import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  dangerouslyGetUnescapedText,
  getQuestionTypeText as questionType,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import QuestionPreviewModal from 'studyApp/components/teacher/assignments/question-preview-modal.vue';
import QuestionTypeLabel from 'studyApp/components/teacher/assignments/question-type-label.vue';

export default {
  name: 'AssignmentDetailsQuestions',
  directives: {
    tooltip: VTooltip,
    KogClickable,
    KogDescription,
  },
  components: {
    QuestionTypeLabel,
    KogDifficulty,
    KogProgressBar,
    KogTable,
    KogTableHeader,
    KogTableCellActions,
    KogTableCellHeader,
    KogTableRow,
    KogTableCellText,
    KogTableCellGeneric,
    KogDropdownButton,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      sortOrder: 'ASSIGNED',
    };
  },
  computed: {
    sortItems() {
      return [
        {
          text: 'Assigned order',
          value: 'ASSIGNED',
        },
        {
          text: 'Most incorrect first',
          value: 'INCORRECT',
        },
        {
          text: 'Most correct first',
          value: 'CORRECT',
        },
      ];
    },
    totalStudents() {
      return this.assignment.students.length;
    },
    allQuestionsProgressBarsItems() {
      const progressBarsItems = {};
      this.assignment.questions.forEach(q => {
        progressBarsItems[q.id] = this.generateQuestionProgressBarItems(q.id);
      });
      return progressBarsItems;
    },
    questionActions() {
      return [
        {
          tooltipText: 'Preview question',
          iconClass: 'fa-search',
          callback: this.previewQuestion,
        },
      ];
    },
    questionsAnswers() {
      const responses = {};
      this.assignment.questions.forEach(question => {
        responses[question.id] = {
          correct: 0,
          incorrect: 0,
        };
      });

      this.assignment.practice_occasions.forEach(po => {
        const answeredQuestions = po.answered_questions;
        if (answeredQuestions) {
          answeredQuestions.questions_correct_answer.forEach(response => {
            const responseId = response.question_id;
            responses[responseId].correct += 1;
          });
          answeredQuestions.questions_incorrect_answer.forEach(response => {
            const responseId = response.question_id;
            responses[responseId].incorrect += 1;
          });
        }
      });

      return responses;
    },
    sortedQuestions() {
      if (this.sortOrder === 'INCORRECT') {
        return [...this.assignment.questions].sort(this.compareQuestions());
      }
      if (this.sortOrder === 'CORRECT') {
        return [...this.assignment.questions].sort(this.compareQuestions('desc'));
      }
      return this.assignment.questions;
    },
    hasStudents() {
      return this.totalStudents > 0;
    },
  },
  created() {
    const previewQuestionId = this.$route.query.view_question_modal;

    if (!previewQuestionId) {
      return;
    }

    const previewQuestion = this.assignment.questions.find(
      question => question.id === parseInt(previewQuestionId, 10),
    );

    if (previewQuestion) {
      this.previewQuestion(previewQuestion);
    }
  },
  methods: {
    getQuestionType(question) {
      return questionType(question);
    },
    getQuestionText(questionHtml) {
      return dangerouslyGetUnescapedText(questionHtml);
    },
    getQuestionOrderNumber(questionId) {
      return parseInt(
        Object.keys(this.assignment.questions).find(
          key => this.assignment.questions[key].id === questionId,
        ),
        10,
      );
    },
    correctAnswersTotal(questionId) {
      return this.questionsAnswers[questionId].correct;
    },
    incorrectAnswersTotal(questionId) {
      return this.questionsAnswers[questionId].incorrect;
    },
    calculateCorrectPercentage(questionId) {
      return this.hasStudents
        ? (100 * this.correctAnswersTotal(questionId)) / this.totalStudents
        : 0;
    },
    calculateIncorrectPercentage(questionId) {
      return this.hasStudents
        ? (100 * this.incorrectAnswersTotal(questionId)) / this.totalStudents
        : 0;
    },
    getCorrectAnswersTooltipText(questionId) {
      return `
        <p class="margin-xs text-center">
          ${this.correctAnswersTotal(questionId)}/${this.totalStudents} answered<br>correctly
        </p>
      `;
    },
    getIncorrectAnswersTooltipText(questionId) {
      return `
        <p class="margin-xs text-center">
          ${this.incorrectAnswersTotal(questionId)}/${this.totalStudents} answered<br>incorrectly
        </p>`;
    },
    getNotSubmittedAnswersTooltipText(questionId) {
      const notSumbittedAnswers =
        this.totalStudents -
        (this.correctAnswersTotal(questionId) + this.incorrectAnswersTotal(questionId));
      return `
        <p class="margin-xs text-center">
          ${notSumbittedAnswers}/${this.totalStudents} have not<br>answered
        </p>
      `;
    },
    generateQuestionProgressBarItems(questionId) {
      return [
        {
          tooltipText: this.getCorrectAnswersTooltipText(questionId),
          percentage: this.calculateCorrectPercentage(questionId),
        },
        {
          tooltipText: this.getIncorrectAnswersTooltipText(questionId),
          percentage: this.calculateIncorrectPercentage(questionId),
        },
        {
          tooltipText: this.getNotSubmittedAnswersTooltipText(questionId),
        },
      ];
    },
    setSortOrder(eventData) {
      this.sortOrder = eventData.value;
    },
    compareQuestions(sortOrder = 'asc') {
      const { incorrectAnswersTotal } = this;
      return function innerSort(q1, q2) {
        const q1Incorrect = incorrectAnswersTotal(q1.id);
        const q2Incorrect = incorrectAnswersTotal(q2.id);
        let comparison = 0;
        if (q1Incorrect > q2Incorrect) {
          comparison = 1;
        }
        if (q1Incorrect < q2Incorrect) {
          comparison = -1;
        }
        return sortOrder === 'asc' ? comparison * -1 : comparison;
      };
    },
    previewQuestion(question) {
      const paginatedQuestions = {
        results: this.assignment.questions,
        page_size: 10,
        current_page: 1,
        count: this.assignment.questions.length,
      };
      this.$modal(QuestionPreviewModal, {
        question,
        assignmentType: ASSIGNMENT_TYPES.QUESTION,
        showDifficulty: this.showDifficulty,
        paginatedQuestions,
        subjectName: this.assignment.subject_name,
        showPosition: true,
        source: 'assignment_details',
        assignment: this.assignment,
        allQuestionsProgressBarsItems: this.allQuestionsProgressBarsItems,
        shouldFetchQuestionSubclasses: false,
      });
    },
  },
};
</script>
<style scoped>
.AssignmentDetailsQuestions-questionText :deep(div) {
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
