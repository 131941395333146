<template>
  <kog-draggable-card
    :default-padding="false"
    :start-top="buttonsDistanceFromTop"
    :start-left="buttonsDistanceFromLeft"
    :z-index="500"
  >
    <template #bodySlot>
      <div class="flexContainer">
        <div
          v-if="currentSection"
          class="EditorButtons-button"
        >
          <a
            :href="editSectionUrl"
            class="flexContainer flexContainer-column flexContainer-alignCenter"
          >
            <kog-icon
              icon-class="fa-edit"
              size="s"
            />
            Edit section
          </a>
        </div>
        <div class="EditorButtons-button">
          <a
            :href="bookMaterialUrl"
            class="flexContainer flexContainer-column flexContainer-alignCenter"
          >
            <kog-icon
              icon-class="fa-book"
              size="s"
            />
            Book material
          </a>
        </div>
        <div class="EditorButtons-button">
          <a
            :href="assessmentMaterialUrl"
            class="flexContainer flexContainer-column flexContainer-alignCenter"
          >
            <kog-icon
              icon-class="fa-pencil"
              size="s"
            />
            Assessment material
          </a>
        </div>
        <div class="EditorButtons-button">
          <a
            :href="feedbackMaterialUrl"
            class="flexContainer flexContainer-column flexContainer-alignCenter"
          >
            <kog-icon
              icon-class="fa-bullhorn"
              size="s"
            />
            Feedback
          </a>
        </div>
        <div class="EditorButtons-button">
          <a
            :href="dashboardUrl"
            class="flexContainer flexContainer-column flexContainer-alignCenter"
          >
            <kog-icon
              icon-class="fa-list-alt"
              size="s"
            />
            Dashboard
          </a>
        </div>
        <div
          v-if="currentSection"
          class="EditorButtons-button"
        >
          <button
            class="flexContainer flexContainer-column flexContainer-alignCenter EditorButtons-shareableLink"
            @click="copyShareableLinkToNode"
          >
            <kog-icon
              icon-class="fa-link"
              size="s"
            />
            Copy shareable link
          </button>
        </div>
      </div>
    </template>
  </kog-draggable-card>
</template>

<script>
import { useClipboard } from '@vueuse/core';
import { mapGetters, mapState } from 'vuex';

import { ROUTES as publishingRoutes } from 'publishingApp/urls.js';
import KogDraggableCard from 'sharedApp/components/draggable-card/kog-draggable-card.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

export default {
  name: 'EditorButtons',
  components: {
    KogDraggableCard,
    KogIcon,
  },
  mixins: [RoutesMixin],
  setup() {
    const { copy } = useClipboard();

    return {
      copyToClipboard: copy,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
    }),
    ...mapGetters({
      leafNodesWithContent: 'subjectModule/leafNodesWithContent',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    currentSection() {
      return this.leafNodesWithContent.find(n => n.id === this.nodeId);
    },
    currentActivity() {
      return this.getActivityBySubjectNodeId(this.nodeId);
    },
    editSectionUrl() {
      if (this.currentActivity) {
        return publishingRoutes.getEditFeatureUrl(
          this.currentActivity.id,
          this.currentActivity.type,
          this.subject.id,
          this.nodeId,
        );
      }
      return publishingRoutes.getSectionEditUrl(
        this.currentSection.section_id,
        this.subject.id,
        this.nodeId,
      );
    },
    bookMaterialUrl() {
      const baseUrl = `/publishing/app/subject/${this.subject.id}/book-material`;
      return this.currentSection
        ? `${baseUrl}?selected-node-id=${this.currentSection.id}`
        : baseUrl;
    },
    assessmentMaterialUrl() {
      const baseUrl = `/publishing/app/subject/${this.subject.id}/assessment-material`;
      return this.currentSection ? `${baseUrl}?subjectnode_id=${this.currentSection.id}` : baseUrl;
    },
    feedbackMaterialUrl() {
      return `/publishing/app/subject/${this.subject.id}/feedback`;
    },
    dashboardUrl() {
      return '/publishing/app/content/dashboard/';
    },
    buttonsDistanceFromTop() {
      return window.innerHeight - 56;
    },
    buttonsDistanceFromLeft() {
      return this.nodeId ? 72 : 8;
    },
  },
  methods: {
    copyShareableLinkToNode() {
      const path = `/redirect/subject/node/${this.nodeId}/`;
      const url = new URL(path, window.location.origin);
      this.copyToClipboard(url.href);
      this.$toast.showSuccess('Link copied to clipboard');
    },
  },
};
</script>

<style scoped>
.EditorButtons-button {
  padding: 8px;
  font-size: var(--kog-font-size-small);
  text-align: center;
}

.EditorButtons-shareableLink {
  color: var(--kog-link-color);
  background: none;
  border: none;
}

.EditorButtons-shareableLink:hover {
  text-decoration: underline;
}
</style>
