<template>
  <div class="flexContainer flexContainer-center">
    <button
      v-for="(color, index) in colorList"
      :key="index"
      :aria-label="`Highlight selection with ${color.colorName}`"
      :class="{
        'ColorSelector-container--selected': isSelected(color.colorName) && theme === 'light',
        'ColorSelector-containerDark--selected': isSelected(color.colorName) && theme === 'dark',
      }"
      class="KogButtonLegacy--noStyle ColorSelector-container cursorPointer"
      @click="selectColor(color.colorName)"
    >
      <span
        :class="[
          color.colorClass,
          {
            'ColorSelector-circle--selected': isSelected(color.colorName) && theme === 'light',
            'ColorSelector-circleDark--selected': isSelected(color.colorName) && theme === 'dark',
          },
        ]"
        class="ColorSelector-circle"
      />
    </button>
  </div>
</template>

<script>
import { getHighlightClass, highlightColors } from 'learning/common/libs/annotations-utils.js';

export default {
  name: 'ColorSelector',
  props: {
    colorModel: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  emits: ['change', 'update:colorModel'],
  data() {
    return {
      colorList: highlightColors.map(color => ({
        colorName: color,
        colorClass: getHighlightClass(color, false, true),
      })),
      selectedColor: this.colorModel,
    };
  },
  methods: {
    isSelected(colorName) {
      return colorName === this.colorModel;
    },
    selectColor(colorName) {
      this.selectedColor = colorName;
      this.$emit('change', this.selectedColor);
      this.$emit('update:colorModel', this.selectedColor);
    },
  },
};
</script>

<style scoped>
.ColorSelector-container {
  width: 18px;
  height: 18px;
  margin-right: 15px;
  padding: 0;

  background: none;
  border: none;
}

.ColorSelector-container:last-of-type {
  margin-right: 0;
}

.ColorSelector-circle {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 9px;
}

.ColorSelector-container--selected {
  box-sizing: content-box;
  border: 2px solid var(--kogPlatformGray031);
  border-radius: 12px;
}

.ColorSelector-containerDark--selected {
  box-sizing: content-box;
  border: 2px solid var(--kogPlatformWhite);
  border-radius: 12px;
}

.ColorSelector-green {
  background: var(--kogPlatformGreenLighten10);
}

.ColorSelector-yellow {
  background: var(--kogPlatformYellowBase);
}

.ColorSelector-blue {
  background: var(--kogPlatformBlueLighten10);
}

.ColorSelector-peach {
  background: var(--kogPlatformRedBase);
}
</style>
