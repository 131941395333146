<template>
  <div
    class="MCQChoiceItem padd-right-xs padd-left-xs flexContainer flexContainer-alignCenter"
    :class="containerCssClasses"
    tabindex="0"
    role="checkbox"
    :aria-checked="isSelected ? 'true' : null"
    :aria-labelledby="`${choiceId}_html`"
    :aria-disabled="disabled ? 'true' : null"
    @click="onSelect"
    @keydown.space.stop="onSelect"
    @keydown.enter.stop="onSelect"
  >
    <div
      class="MCQChoiceItem-Icon margin-right-s flexContainer flexContainer-alignCenter flexContainer-justifyCenter"
    >
      <svg-icon
        is-svg-icon="true"
        size="custom"
        theme="custom"
        :custom-size="'40'"
        :icon-name="optionIconClass"
        class="MCQChoiceItem-Icon--svg"
      />
    </div>
    <div
      :id="`${choiceId}_html`"
      class="flexChild-size-1"
      aria-hidden="true"
    >
      <content-container
        v-kog-mathjax
        class="choice_html"
        :content="choiceHtml"
      />
      <span
        v-if="isIncorrect"
        class="screenreader-only"
      >
        , Incorrect answer
      </span>
      <span
        v-else-if="isCorrect"
        class="screenreader-only"
      >
        , Correct answer
      </span>
    </div>
    <template v-if="type === 'solution'">
      <span
        v-if="isSelected && isCorrected"
        class="MCQChoiceItem--itemExtra"
      >
        Your answer
      </span>

      <template v-if="!!$slots.itemSlot">
        <span class="MCQChoiceItem--itemExtra">
          <slot name="itemSlot" />
        </span>
      </template>
    </template>

    <kog-round-button
      v-if="isDismissible"
      class="MCQChoiceItem-dismissButton"
      aria-label="remove answer"
      button-style="secondary-basic"
      icon-class="fa-times"
      icon-size="m"
      size="small"
      @click.prevent="$emit('dismiss')"
    />
    <kog-icon
      v-else-if="showCorrectnessIcon"
      class="MCQChoiceItem-CorrectnessIcon margin-left-xxs"
      :class="correctnessIconCssClass"
      :icon-class="correctnessIconClass"
      fa-style="regular"
      theme="custom"
      size="s-touch"
    />
  </div>
</template>

<script>
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import ContentContainer from 'sharedApp/components/content/content-container.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import SvgIcon from 'sharedApp/components/icons/svg-icon.vue';

import { MCQ_TYPE_OPTIONS } from './choice-state-helper.js';

const MCQ_OPTION_ICONS = [
  { name: 'MCQOption1' },
  { name: 'MCQOption2' },
  { name: 'MCQOption3' },
  { name: 'MCQOption4' },
  { name: 'MCQOption5' },
  { name: 'MCQOption6' },
];

export default {
  name: 'MCQChoiceItem',
  components: {
    ContentContainer,
    KogIcon,
    KogRoundButton,
    SvgIcon,
  },
  props: {
    choiceId: {
      type: Number,
      required: true,
    },
    choiceHtml: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSolution: {
      type: Boolean,
      default: false,
    },
    isDismissible: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isCorrect: {
      type: Boolean,
      default: false,
    },
    isIncorrect: {
      type: Boolean,
      default: false,
    },
    optionIndex: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: 'normal',
      validator: val => MCQ_TYPE_OPTIONS.includes(val),
    },
  },
  emits: ['dismiss', 'select'],
  data() {
    return {
      icons: MCQ_OPTION_ICONS,
    };
  },
  computed: {
    isCorrected() {
      return this.isCorrect || this.isIncorrect;
    },
    showCorrectnessIcon() {
      return this.isCorrected || this.isSolution;
    },
    correctnessIconClass() {
      if ((this.isCorrected && this.isCorrect) || this.isSolution) {
        return 'fa-check';
      }

      return 'fa-times';
    },
    correctnessIconCssClass() {
      if (this.isCorrect || this.isSolution) {
        return 'MCQChoiceItem-CorrectnessIcon--correct';
      }

      return 'MCQChoiceItem-CorrectnessIcon--incorrect';
    },
    optionIconClass() {
      return this.icons[this.optionIndex].name;
    },
    containerCssClasses() {
      const classes = [`MCQChoiceItem--${this.type}`];

      if (this.disabled) {
        classes.push('MCQChoiceItem--disabled');
      }
      if (this.isSelected) {
        classes.push('MCQChoiceItem--selected');
      }
      if (this.isCorrect) {
        classes.push('MCQChoiceItem--correct');
      }
      if (this.isIncorrect) {
        classes.push('MCQChoiceItem--incorrect');
      }
      if (this.isSolution) {
        classes.push('MCQChoiceItem--isSolution');
      }

      return classes;
    },
  },
  methods: {
    onSelect(event) {
      if (this.disabled || event.defaultPrevented) {
        return;
      }
      this.$emit('select', this.choiceId);
    },
  },
};
</script>

<style scoped>
.MCQChoiceItem :deep(p),
.MCQChoiceItem :deep(.content-editable ol),
.MCQChoiceItem :deep(.content-editable ul) {
  margin-bottom: 0;
  line-height: var(--space-l);
}

.MCQChoiceItem :deep(.content-editable) {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
  line-height: var(--space-l);
}

.MCQChoiceItem :deep(.content-editable img) {
  pointer-events: none;
}

.MCQChoiceItem {
  cursor: pointer;

  padding-top: 2px;
  padding-bottom: 2px;

  border: 2px solid transparent;
  border-radius: 10px;
}

.MCQChoiceItem-Icon {
  width: 40px;
  height: 40px;
}

.MCQChoiceItem-Icon--svg {
  --shape-color: var(--kog-mcq-icon-background-color); /* stylelint-disable-line */
  --text-color: var(--kog-mcq-icon-color); /* stylelint-disable-line */
}

.MCQChoiceItem--solution {
  background-color: var(--kog-mcq-gray-background-color);
}

.MCQChoiceItem--normal {
  background-color: var(--kog-mcq-background-color);
}

.MCQChoiceItem--outline {
  background-color: var(--kog-mcq-outline-background-color);
  border: 1px solid var(--kog-mcq-outline-border-color);
}

.MCQChoiceItem:hover:not(.MCQChoiceItem--selected):not(.MCQChoiceItem--disabled) {
  outline: 1px solid var(--kog-mcq-hover-outline-color);
}

.MCQChoiceItem--disabled {
  cursor: inherit;
  background-color: var(--kog-mcq-gray-background-color);
}

.MCQChoiceItem--selected {
  border-color: var(--kog-mcq-selected-border-color);
  border-style: solid;
  border-width: 2px;
}

.MCQChoiceItem--selected :deep(.MCQChoiceItem-Icon--svg) {
  --shape-color: var(--kog-mcq-selected-icon-background-color); /* stylelint-disable-line */
}

.MCQChoiceItem--isSolution {
  background-color: var(--kog-mcq-correct-background-color);
}

.MCQChoiceItem--selected.MCQChoiceItem--correct {
  background-color: var(--kog-mcq-correct-background-color);
  border-color: var(--kog-mcq-correct-border-color);
}

.MCQChoiceItem--selected.MCQChoiceItem--incorrect {
  background-color: var(--kog-mcq-incorrect-background-color);
  border-color: var(--kog-mcq-incorrect-border-color);
}

.MCQChoiceItem-CorrectnessIcon :deep(.KogFontAwesomeIcon--s-touch) {
  font-size: 16px;
  font-weight: 900;
}

.MCQChoiceItem--itemExtra {
  font-family: var(--kog-satoshi);
  font-size: 12px;
  font-weight: 600;
}

.MCQChoiceItem--itemExtra + .MCQChoiceItem--itemExtra::before {
  content: ', ';
}

.MCQChoiceItem--correct .MCQChoiceItem--itemExtra {
  color: var(--kog-mcq-correct-corre);
}

.MCQChoiceItem--isSolution .MCQChoiceItem-CorrectnessIcon,
.MCQChoiceItem--selected.MCQChoiceItem--correct .MCQChoiceItem--itemExtra,
.MCQChoiceItem--selected .MCQChoiceItem-CorrectnessIcon--correct {
  color: var(--kog-mcq-correct-correctness-icon-color);
}

.MCQChoiceItem--selected.MCQChoiceItem--incorrect .MCQChoiceItem--itemExtra,
.MCQChoiceItem--selected .MCQChoiceItem-CorrectnessIcon--incorrect {
  color: var(--kog-mcq-incorrect-correctness-icon-color);
}

.MCQChoiceItem--solution :deep(.MCQChoiceItem-Icon--svg) {
  --shape-color: var(--kog-mcq-disabled-icon-background-color); /* stylelint-disable-line */
  --text-color: var(--kog-mcq-disabled-icon-color); /* stylelint-disable-line */
}

.MCQChoiceItem--selected.MCQChoiceItem--correct :deep(.MCQChoiceItem-Icon--svg) {
  --shape-color: var(--kog-mcq-correct-icon-background-color); /* stylelint-disable-line */
  --text-color: var(--kog-mcq-correct-icon-color); /* stylelint-disable-line */
}

.MCQChoiceItem--selected.MCQChoiceItem--incorrect :deep(.MCQChoiceItem-Icon--svg) {
  --shape-color: var(--kog-mcq-incorrect-icon-background-color); /* stylelint-disable-line */
  --text-color: var(--kog-mcq-incorrect-icon-color); /* stylelint-disable-line */
}
</style>
