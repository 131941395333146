import intercomModule from 'sharedApp/libs/intercom.js';
import mixpanelModule from 'sharedApp/libs/mixpanel.js';
import {
  ASSIGNMENT_TYPES,
  getAssignmentDetailsTrackingProps,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { QUESTION_TYPES } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import useLmsIntegrationCategories, {
  DEFAULT_LMS_POINTS_POSSIBLE,
} from 'studyApp/composables/use-lms-integration-categories.js';

function trackAssignmentEvent(eventName, subject, assignmentType) {
  const bulk = !assignmentType;
  mixpanelModule.trackEvent(eventName, {
    subject_name: subject.name,
    assignment_type: bulk ? 'BULK' : assignmentType.toLowerCase(),
    bulk,
  });
}

function getMixpanelTrackingIntegrationSettings(assignmentData) {
  const trackingProps = {
    points: assignmentData.integration_settings.points_possible,
    default_points: DEFAULT_LMS_POINTS_POSSIBLE,
  };
  const firstSubjectClassId = assignmentData.subject_classes[0];
  const lmsIntegrationCategories = useLmsIntegrationCategories().lmsIntegrationCategories.value
    ? useLmsIntegrationCategories().lmsIntegrationCategories.value[firstSubjectClassId]
    : [];
  if (lmsIntegrationCategories?.length > 0) {
    trackingProps.defaultCategory = lmsIntegrationCategories[0].title;
    trackingProps.category = lmsIntegrationCategories?.find(
      cat => cat.id === assignmentData.integration_settings.category_id,
    )?.title;
  }
  return trackingProps;
}

export function trackAssignmentArchive(...args) {
  trackAssignmentEvent('Assignment - Archive', ...args);
}

export function trackAssignmentUnarchive(...args) {
  trackAssignmentEvent('Assignment - Unarchive', ...args);
}

export function trackAssignmentDelete(...args) {
  trackAssignmentEvent('Assignment - Delete', ...args);
}

function numOfType(questions, questionType) {
  return questions.filter(q => q.type === questionType).length;
}

export function trackSendAssignment(
  assignmentData,
  googleClasses,
  contentTypes,
  extraMixpanelProps = {},
) {
  const eventName = 'Assignment - Send';
  let intercomEvent = '';
  const sentToGoogleClassroom = googleClasses ? googleClasses.length > 0 : false;
  const sentToAllGoogleClassroomStudents = sentToGoogleClassroom; // the same until v2 release
  const eventProperties = {
    assignment_sent_to_entire_class: assignmentData.all_students_selected,
    assignment_type: assignmentData.assignment_type.toLowerCase(),
    scheduled: !!assignmentData.scheduled_sent_at,
    num_subject_classes: assignmentData.subject_classes.length,
    sent_to_google_classroom: sentToGoogleClassroom,
    sent_to_all_googleclassroom_students: sentToAllGoogleClassroomStudents,
    content_type: contentTypes,
    ...extraMixpanelProps,
  };
  eventProperties.is_sent_to_integration = assignmentData.send_to_integration;
  if (assignmentData.send_to_integration) {
    eventProperties.integration_settings = getMixpanelTrackingIntegrationSettings(assignmentData);
  }
  if (assignmentData.assignment_type === ASSIGNMENT_TYPES.QUESTION) {
    eventProperties.assignment_number_questions = assignmentData.questions.length;
    eventProperties.assignment_soft_deadline = assignmentData.soft_deadline;
    eventProperties.assignment_number_fbq = numOfType(assignmentData.questions, QUESTION_TYPES.FBQ);
    eventProperties.assignment_number_stq = numOfType(assignmentData.questions, QUESTION_TYPES.STQ);
    eventProperties.assignment_number_mcq = numOfType(assignmentData.questions, QUESTION_TYPES.MCQ);
    eventProperties.assignment_display_answers_setting = assignmentData.display_answers_setting;
    intercomEvent = 'sent-question-assignment';
  }
  if (assignmentData.assignment_type === ASSIGNMENT_TYPES.READING) {
    eventProperties.assignment_number_sections = assignmentData.sections.length;
    intercomEvent = 'sent-reading-assignment';
  }
  if (assignmentData.assignment_type === ASSIGNMENT_TYPES.EXAMSTYLE) {
    eventProperties.assignment_number_questions = assignmentData.questions.length;
    intercomEvent = 'sent-esq-assignment';
  }
  mixpanelModule.trackEventViaBackend(eventName, eventProperties);
  intercomModule.trackEvent(intercomEvent);
}

export function trackCreateAssignment(queryParams, subjectId, assignment) {
  const eventName = queryParams.just_created ? 'Assignment - Create' : 'Assignment - Open';

  const eventProperties = {
    subject_id: subjectId,
    subject_name: assignment.subject_name,
    assignment_type: assignment.assignment_type.toLowerCase(),
    assignment_state: 'draft',
    num_subject_classes: assignment.subject_classes.length,
  };

  if (queryParams.source) {
    eventProperties.source = queryParams.source;
  }
  mixpanelModule.trackEventViaBackend(eventName, eventProperties);
}

function trackTeacherQuestion(eventName, { questionType, subjectId, subjectName, source }) {
  mixpanelModule.trackEvent(eventName, {
    question_type: questionType,
    subject_id: subjectId,
    subject_name: subjectName,
    source,
  });
}

export function trackTeacherQuestionOpenCreateModal(options) {
  trackTeacherQuestion('Teacher question - Open create modal', options);
}

export function trackTeacherQuestionCreate(options) {
  trackTeacherQuestion('Teacher question - Create', options);
}

export function trackTeacherQuestionUpdate(options) {
  trackTeacherQuestion('Teacher question - Update', options);
}

export function trackTeacherQuestionCancel(options) {
  trackTeacherQuestion('Teacher question - Cancel', options);
}

export function trackTeacherQuestionDelete(options) {
  trackTeacherQuestion('Teacher question - Delete', options);
}

export function trackTeacherQuestionFeedbackClick(options) {
  trackTeacherQuestion('Teacher question - Feedback Intercom click', options);
}

export function trackActivitySheetAssignment({ assignment, activitySheetNode }) {
  mixpanelModule.trackEventViaBackend('Activity Sheet - Assign', {
    assignment_id: +assignment.id,
    activity_sheet_id: +activitySheetNode.activity,
    subject_node_id: +activitySheetNode.id,
    number_of_students_assigned: +assignment.selected_student_ids.length,
  });
}

export function trackSeeAssignmentProgressFromCard({
  assignment,
  assignmentStudentCount,
  assignmentCompletedStudentCount,
}) {
  const eventProps = getAssignmentDetailsTrackingProps(
    assignment,
    assignmentStudentCount,
    assignmentCompletedStudentCount,
  );
  mixpanelModule.trackEvent('Assignment card - See progress', eventProps);
}
