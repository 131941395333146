<template>
  <kog-modal
    :title="assignment.name"
    :close-modal="closeModal"
    :header-icon="headerIcon"
    :subtitle="subtitle"
  >
    <template #modalBody>
      <kog-loader :loading="$wait.is('fetching_class_students')">
        <completion-stats-modal-body
          :show-completion-column="false"
          :students-completion-info="studentsCompletionInfo"
          :education-system-name="educationSystemName"
          :possible-levels="possibleLevels"
        />
      </kog-loader>
      <router-link
        v-slot="{ href, navigate }"
        :to="assignmentDetailsRoute"
        custom
      >
        <a
          :href="href"
          class="AssignmentDetailLink KogButtonLegacy KogButtonLegacy--primary width-100"
          @click="
            e => {
              closeModal();
              navigate(e);
            }
          "
        >
          View assignment details
        </a>
      </router-link>
    </template>
  </kog-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { fetchStudentsList } from '@apis/subject-class.js';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import sectionIcon from 'sharedApp/img/study/subject-class-overview-section.svg';
import {
  getIconDetails,
  hasAssignmentEnded,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import useClassAssignmentOwnership from 'studyApp/pages/assignment/composables/use-class-assignment-ownership.ts';

import CompletionStatsModalBody from './completion-stats-modal-body.vue';

export default {
  name: 'AssessmentCompletionStatsModal',
  components: {
    KogLoader,
    KogModal,
    CompletionStatsModalBody,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    assignmentStudentUserIds: {
      type: Array,
      required: true,
    },
    assignmentCompletedStudentUserIds: {
      type: Array,
      required: true,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    educationSystemName: {
      type: String,
      default: () => '',
    },
    closeModal: {
      type: Function,
      required: true,
    },
    possibleLevels: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { findMyClassInAssignment } = useClassAssignmentOwnership();
    return { findMyClassInAssignment };
  },
  data() {
    return {
      studentList: [],
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    subjectClass() {
      return this.subjectClassesById[this.subjectClassId];
    },
    headerIcon() {
      return {
        type: 'image',
        imgUrl: sectionIcon,
        alt: '',
      };
    },
    subjectId() {
      return this.assignment.subject_id;
    },
    assignmentDetailsRoute() {
      let subjectClassToRedirect = this.subjectClass;
      if (!subjectClassToRedirect.teacher_user_ids.includes(this.user.id)) {
        subjectClassToRedirect = this.findMyClassInAssignment(this.assignment, this.user);
      }
      return {
        name: 'teacherAssignmentDetails',
        params: {
          classSlug: subjectClassToRedirect.slug,
          sid: this.subjectId,
          cid: subjectClassToRedirect.id,
          assignmentId: this.assignment.id,
        },
      };
    },
    subtitle() {
      const iconDetails = getIconDetails(this.assignment.assignment_type);
      const deadlineStr = dateTimeFormatter(new Date(this.assignment.deadline));
      const deadline = hasAssignmentEnded(this.assignment) ? 'Ended' : 'Deadline';
      return `${deadline}: ${deadlineStr}, ${iconDetails.tooltip}`;
    },
    studentUserIds() {
      return this.studentList.map(student => student.user_id);
    },
    studentsCompletionInfo() {
      if (this.studentList.length === 0) return [];

      const classStudentsInAssignment = this.assignmentStudentUserIds.filter(studentUserId =>
        this.studentUserIds.includes(studentUserId),
      );
      return classStudentsInAssignment.map(studentId => {
        const { id, name, level, avatar_url: avatarUrl } = this.getStudentById(studentId);
        return {
          id,
          name,
          avatarUrl,
          isCompleted: this.assignmentCompletedStudentUserIds.includes(studentId),
          level,
        };
      });
    },
  },
  async mounted() {
    this.$wait.start('fetching_class_students');
    try {
      this.studentList = await fetchStudentsList(this.subjectClassId);
    } catch {
      this.$toast.showError('Something went wrong when fetching students. Please try again later.');
      this.closeModal();
    } finally {
      this.$wait.end('fetching_class_students');
    }
  },
  methods: {
    getStudentById(studentId) {
      return this.studentList.find(student => student.user_id === studentId);
    },
  },
};
</script>

<style scoped>
.AssignmentDetailLink {
  margin-top: var(--space-l);
}
</style>
