import { getBlockTypeConfiguration } from 'publishingApp/containers/features/components/blocks/configuration.ts';
import { FEATURES_ENUM } from 'sharedApp/const/features.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

export const performanceTaskBaseUrl = '/api/performance-tasks/';
export const activitySheetBaseUrl = '/api/activity-sheets/';

const getActivityBaseUrl = activityType => {
  switch (activityType) {
    case FEATURES_ENUM.ACTIVITY_SHEET:
      return activitySheetBaseUrl;
    case FEATURES_ENUM.PERFORMANCE_TASK:
      return performanceTaskBaseUrl;
    default:
      throw new Error('Unknown activity type');
  }
};

export const getActivityInstanceUrl = (activityType, activityId) =>
  `${getActivityBaseUrl(activityType)}${activityId}/`;

const getActivitySheetExternalContentFrameUrl = () =>
  `${activitySheetBaseUrl}external-content-frames/`;

export async function fetchActivity(activityType, activityId) {
  const url = getActivityInstanceUrl(activityType, activityId);
  const response = await axios.get(url);
  return response.data;
}

export async function updateActivity(activityType, activity) {
  const response = await axios.patch(getActivityInstanceUrl(activityType, activity.id), activity);
  return response.data;
}

export async function createActivity(activityType, activity) {
  const response = await axios.post(getActivityBaseUrl(activityType), activity);
  return response.data;
}

export async function addContentBlock(activityType, activityId, block) {
  const { preSaveHandler } = getBlockTypeConfiguration(block.block_type);
  const { payload, config } = preSaveHandler(block);

  const response = await axios
    .post(`${getActivityInstanceUrl(activityType, activityId)}content/`, payload, config)
    .catch(() => {
      throw new Error(`Could not add block: ${block.block_type}`);
    });

  return response.data;
}

export async function updateContentBlock(activityType, activityId, block) {
  const { preSaveHandler } = getBlockTypeConfiguration(block.block_type);
  const { payload, config } = preSaveHandler(block);

  const response = await axios
    .patch(
      `${getActivityInstanceUrl(activityType, activityId)}content/${block.id}/`,
      payload,
      config,
    )
    .catch(() => {
      throw new Error(`Could not update block: ${block.block_type}`);
    });
  return response.data;
}

export async function removeContentBlock(activityType, activityId, id) {
  const response = await axios.delete(
    `${getActivityInstanceUrl(activityType, activityId)}content/${id}/`,
  );
  return response.data;
}

export async function updateOrdering(activityType, activityId, content) {
  const response = await axios.put(
    `${getActivityInstanceUrl(activityType, activityId)}content/order/`,
    content,
  );
  return response.data;
}

export function createExternalContentFrame(activityId, content, isActive = false) {
  return axios.post(getActivitySheetExternalContentFrameUrl(), {
    activity_sheet: activityId,
    frame_content: content,
    is_active: isActive,
  });
}

export function updateExternalContentFrame(externalContentFrameId, content, isActive = false) {
  return axios.patch(`${getActivitySheetExternalContentFrameUrl()}${externalContentFrameId}/`, {
    frame_content: content,
    is_active: isActive,
  });
}
