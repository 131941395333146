import { Plugin } from '@ckeditor/ckeditor5-core';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';

import ContentGridEditing from './content-grid-editing.js';
import ContentGridUI from './content-grid-ui.js';

export default class ContentGrid extends Plugin {
  static get requires() {
    return [ContentGridEditing, ContentGridUI, Paragraph];
  }
}
