<template>
  <div class="BallStepPaginator">
    <span
      v-if="!isClickable"
      class="screenreader-only"
    >
      {{
        stepNumbers.length === 1
          ? `There is one ${paginatedEntity}`
          : `There are a total of  ${stepNumbers.length} ${paginatedEntity}s`
      }}
    </span>
    <nav
      class="flexContainer flexContainer-center"
      :aria-label="pluralize(paginatedEntity, stepNumbers.length)"
    >
      <kog-round-button
        v-if="hasNavigationButtons"
        icon-class="fa-chevron-left"
        button-style="basic"
        :aria-hidden="!hasPrevious ? 'true' : null"
        :aria-label="`Previous ${paginatedEntity}`"
        :disabled="!hasPrevious"
        @click="goToPreviousPage()"
      />
      <kog-item-list class="flexContainer flexContainer-center">
        <!-- eslint-disable-next-line vuejs-accessibility/no-redundant-roles -->
        <ul
          class="KogBallStepPaginator-ballList padd-left-0"
          role="list"
        >
          <!-- eslint-disable-next-line vuejs-accessibility/no-redundant-roles -->
          <li
            v-for="stepNumber in stepNumbers"
            :key="stepNumber"
            role="listitem"
          >
            <kog-ball-step
              :key="currentPage + getBallState(stepNumber)"
              :step-number="stepNumber"
              :state="getBallState(stepNumber)"
              :is-clickable="isClickable"
              :button-aria-label="buttonLabel(stepNumber)"
              @click="handleClick(stepNumber)"
            >
            </kog-ball-step>
          </li>
        </ul>
      </kog-item-list>
      <kog-round-button
        v-if="hasNavigationButtons"
        icon-class="fa-chevron-right"
        button-style="basic"
        :aria-hidden="!hasNext ? 'true' : null"
        :aria-label="`Next ${paginatedEntity}`"
        :disabled="!hasNext"
        @click="goToNextPage()"
      />
    </nav>
  </div>
</template>

<script>
import { isNil, range } from 'lodash';
import pluralize from 'pluralize';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogBallStep, { BALL_STATE } from 'sharedApp/components/base/indicators/kog-ball-step.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import PaginationMixin from 'sharedApp/mixins/pagination-mixin.js';

export default {
  name: 'KogBallStepPaginator',
  components: {
    KogBallStep,
    KogItemList,
    KogRoundButton,
  },
  mixins: [PaginationMixin],
  props: {
    isClickable: {
      type: Boolean,
      default: false,
    },
    hasNavigationButtons: {
      type: Boolean,
      default: false,
    },
    isStepDone: {
      type: Function,
      default: () => {},
    },
    isStepCorrect: {
      type: Function,
      default: null,
    },
    paginatedEntity: {
      type: String,
      default: 'question',
    },
  },
  computed: {
    stepNumbers() {
      if (this.hasNavigationButtons) {
        return this.paginationRange;
      }
      return range(1, this.numberOfPages + 1);
    },
  },
  methods: {
    handleClick(stepNumber) {
      if (this.isClickable) {
        this.navigateToPage(stepNumber);
      }
    },
    buttonLabel(stepNumber) {
      if (!this.isValidPage(stepNumber)) {
        return '';
      }

      const getPageAnsweredText = () => {
        if (this.isStepDone(stepNumber)) {
          return `This ${this.paginatedEntity} has been answered.`;
        }
        return '';
      };
      const pageText =
        stepNumber === this.numberOfPages
          ? `last ${this.paginatedEntity}`
          : `${this.paginatedEntity} ${stepNumber}`;
      if (stepNumber === this.currentPage) {
        return `${pageText}. ${getPageAnsweredText()}`;
      }
      return `Go to ${pageText}. ${getPageAnsweredText()}`;
    },
    getBallState(stepNumber) {
      if (!this.isValidPage(stepNumber)) {
        return BALL_STATE.SPLITTER;
      }
      if (stepNumber === this.currentPage) {
        return BALL_STATE.ACTIVE;
      }
      if (!isNil(this.isStepCorrect)) {
        const isCorrect = this.isStepCorrect(stepNumber);
        if (isCorrect === true) {
          return BALL_STATE.CORRECT;
        }
        if (isCorrect === false) {
          return BALL_STATE.INCORRECT;
        }
      }
      if (this.isStepDone(stepNumber)) {
        return BALL_STATE.ANSWERED;
      }

      return '';
    },
    pluralize,
  },
};
</script>

<style scoped>
.KogBallStepPaginator {
  display: block;

  margin: 0;
  padding: 0;

  background: none;
  border: none;
}

.KogBallStepPaginator-ballList {
  list-style: none;
}

.KogBallStepPaginator-ballList li {
  display: inline;
}
</style>
