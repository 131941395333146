<template>
  <div
    v-if="shouldDisplayUploadState"
    @dragenter="handleDragEnter"
    @dragleave="handleDragLeave"
  >
    <file-upload
      :class="[
        'ImageUpload margin-top-m margin-bottom-xs flexContainer flexContainer-center flexContainer-column',
        {
          'ImageUpload--withError': hasError,
          'ImageUpload--dragOver': dragOver,
          'ImageUpload--uploading': uploading,
          'ImageUpload--disabled': disabled,
        },
      ]"
      uploading-message="Uploading your image"
      name="image"
      :allowed-extensions="['jpeg', 'jpg', 'png']"
      :show-toast-on-extension-error="false"
      :disabled="disabled || uploading"
      @upload="handleUpload"
    >
      <template
        v-if="shouldDisplayUploadState"
        #body
      >
        <template v-if="!uploading">
          <kog-icon
            class="ImageUpload-kogIcon"
            theme="custom"
            fa-style="regular"
            icon-class="fa-cloud-arrow-up"
            size="l"
          />
          <span class="text-muted text-small margin-bottom-m">
            JPEG or PNG, max {{ maxFileSize }} MB
          </span>
          <span class="heading-s text-bold margin-bottom-l"> Drag & drop image here </span>
          <kog-button
            class="ImageUpload-uploadButton"
            label="Select from device"
            aria-label="upload image"
            :disabled="disabled"
          />
        </template>

        <template v-if="uploading">
          <div
            class="flexContainer flexContainer-column flexContainer-alignCenter width-100 gap-xs padd-left-xl padd-right-xl"
          >
            <kog-icon
              class="ImageUpload-kogIcon"
              theme="custom"
              fa-style="regular"
              icon-class="fa-loader fa-spin"
              size="l"
            />
            <span class="heading-s">Uploading...</span>
            <span class="text-small text-muted">
              Don't leave or reload this page until upload is finished.
            </span>
            <div class="flexContainer flexContainer-spaceBetween width-100">
              <span class="text-small">{{ filename }}</span>
              <span class="text-small">{{ progress }}%</span>
            </div>
            <kog-progress-bar
              :progress="progress"
              size="s"
              type="primary"
            />
          </div>
        </template>
      </template>
    </file-upload>
    <span
      v-if="hasError"
      class="error"
    >
      {{ errorMessage }}
    </span>
  </div>
  <div
    v-else
    class="ImageUpload-imageContainer flexContainer flexContainer-column flexContainer-center width-100"
    :class="{ 'u-disabled': disabled }"
  >
    <template v-if="isImageLoading">
      <div class="flexContainer flexContainer-column flexContainer-alignCenter gap-xs padd-xl">
        <kog-icon
          theme="dark"
          fa-style="regular"
          icon-class="fa-loader fa-spin"
          size="l"
        />
        <span class="heading-xs">Loading...</span>
      </div>
    </template>
    <div
      v-else
      class="width-100 flexContainer flexContainer-center padd-xxs"
    >
      <template v-if="hasImageDownloadError">
        <div class="flexContainer flexContainer-column flexContainer-alignCenter gap-xs padd-xl">
          <span class="ImageUpload-downloadErrorIcon">
            <kog-icon
              theme="custom"
              fa-style="regular"
              icon-class="fa-image"
              size="l"
            />
            <kog-icon
              theme="custom"
              fa-style="solid"
              icon-class="fa-circle-question"
              size="m"
            />
          </span>
          <span class="heading-xs">Image cannot currently be displayed.</span>
          <div class="text-muted text-small">Please try refresh the page or come back later.</div>
        </div>
      </template>
      <template v-if="hasImageFile">
        <img
          class="ImageUpload-imagePreview"
          :src="imageUrl"
          alt=""
        />
      </template>
      <kog-round-button
        v-if="!disabled"
        class="ImageUpload-deleteImageButton"
        icon-class="fa-trash-can"
        aria-label="Delete image"
        button-style="primary"
        @click="showDeleteImageModal"
      />
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash';

import { ImageUploadTrackingInject } from 'learning/study/mixins/image-upload-tracking-mixin.js';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import kogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import FileUpload from 'sharedApp/components/file-upload/file-upload.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';

import { FILE_STATUS } from './file-status.ts';

const TRACKING_IMAGE_UPLOAD_START = 'Image Upload - Start';

export default {
  name: 'ImageUpload',
  components: {
    KogIcon,
    FileUpload,
    KogButton,
    KogRoundButton,
    kogProgressBar,
  },
  mixins: [ImageUploadTrackingInject],
  props: {
    /**
     * File context
     */
    fileContext: {
      type: Object,
      default: null,
      validator: prop => {
        if (prop === null) return true;
        const hasValidFileValue = prop?.file instanceof File || isNil(prop?.file);
        const hasValidFileStatus = prop?.status && Object.values(FILE_STATUS).includes(prop.status);
        return hasValidFileValue && hasValidFileStatus;
      },
    },
    /**
     * Disables the image upload input and delete button
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Maximum allowed file size in MB
     */
    maxFileSize: {
      type: Number,
      default: 5,
    },
  },
  emits: ['input'],
  data() {
    return {
      errorMessage: '',
      dragOver: false,
      uploadMeta: null,
    };
  },
  computed: {
    imageUrl() {
      return URL.createObjectURL(this.fileContext?.file);
    },
    hasError() {
      return this.errorMessage !== '';
    },
    uploading() {
      if (this.uploadMeta) return true;
      return this.fileStatus === FILE_STATUS.UPLOADING;
    },
    hasImageFile() {
      return this.fileContext?.file instanceof File;
    },
    fileStatus() {
      return this.fileContext?.status;
    },
    hasImageDownloadError() {
      return this.fileStatus === FILE_STATUS.ERROR;
    },
    isImageLoading() {
      return this.fileStatus === FILE_STATUS.DOWNLOADING;
    },
    shouldDisplayUploadState() {
      return !this.fileContext || this.fileStatus === FILE_STATUS.UPLOADING;
    },
    filename() {
      if (this.uploadMeta) {
        return this.uploadMeta.filename;
      }

      if (this.fileContext?.file instanceof File) {
        return this.fileContext.file.name;
      }

      return '';
    },
    progress() {
      if (this.uploadMeta) {
        return this.uploadMeta.progress;
      }

      return 0;
    },
  },
  watch: {
    fileContext: {
      handler(wrapper) {
        if ((wrapper?.file instanceof File || !wrapper?.file) && this.uploadMeta) {
          this.uploadMeta = null;
        }
      },
    },
  },
  methods: {
    async handleUpload(file) {
      this.clearDragState();

      const fileTooLarge = file.size > this.maxFileSize * 1_000_000;
      const fileWrongFormat = !['jpg', 'jpeg', 'png'].includes(
        this.getFileExtension(file.name).toLowerCase(),
      );
      if (fileTooLarge && fileWrongFormat) {
        this.errorMessage = `Please check file size and format. Max. file size: ${this.maxFileSize}MB, as JPEG or PNG`;
        return;
      }
      if (fileTooLarge) {
        this.errorMessage = `File too large. Max. file size: ${this.maxFileSize}MB`;
        return;
      }
      if (fileWrongFormat) {
        this.errorMessage = 'File format not supported. Please upload as JPEG or PNG';
        return;
      }

      const isImage = await this.isFileImage(file);
      if (!isImage) {
        this.errorMessage = 'Invalid image, please upload another!';
        return;
      }

      this.trackImageUploadStart();

      this.errorMessage = '';
      const fileContext = {
        file,
        status: FILE_STATUS.UPLOADING,
      };

      this.setUploadMeta(file.name, 0);

      this.$emit('input', {
        fileContext,
        onError: () => {
          this.errorMessage =
            "Image couldn't be uploaded. Please check file size and format or upload a different image.";
          this.$forceUpdate();
        },
        onProgress: progressEvent => {
          const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
          this.setUploadMeta(file.name, progress);
        },
      });
    },
    isFileImage(file) {
      return new Promise(resolve => {
        const verificationImage = new Image();
        const revokeUrlAndResolve = isImage => {
          URL.revokeObjectURL(verificationImage.src);
          resolve(isImage);
        };
        verificationImage.addEventListener('error', () => revokeUrlAndResolve(false));
        verificationImage.addEventListener('load', () => revokeUrlAndResolve(true));
        verificationImage.src = URL.createObjectURL(file);
      });
    },
    showDeleteImageModal() {
      this.$modal(KogConfirmModal, {
        title: 'Delete image?',
        text: `The image will be permamently deleted.`,
        buttonTitle: 'Yes, delete',
        confirmButtonStyle: 'accent',
        onConfirm: () => this.deleteImage(),
      });
    },
    deleteImage() {
      this.$emit('input', null);
    },
    getFileExtension(fileName) {
      return fileName.split('.').pop();
    },
    handleDragEnter(e) {
      this.dragOver = true;
      e.preventDefault();
    },
    handleDragLeave(e) {
      this.dragOver = false;
      e.preventDefault();
    },
    clearDragState() {
      this.dragOver = false;
    },
    trackImageUploadStart() {
      this.$mixpanel.trackEventViaBackend(
        TRACKING_IMAGE_UPLOAD_START,
        this.uploadTracking.context ?? {},
      );
    },
    setUploadMeta(filename, progress) {
      this.uploadMeta = {
        filename,
        progress,
      };
    },
  },
};
</script>

<style scoped>
.ImageUpload {
  overflow: hidden;

  color: var(--kogPlatformGray009);

  background: var(--kog-colors-aubergine-100);
  border-radius: 20px;
  outline-color: var(--kog-colors-aubergine-700);
  outline-width: 1px;
  outline-offset: 0;
}

.ImageUpload--disabled {
  color: var(--kog-text-disabled);
  background: var(--kog-colors-grey-300);
  outline-color: var(--kog-text-disabled);
}

.ImageUpload.ImageUpload--uploading {
  background-color: var(--kog-colors-white);
}

.ImageUpload-kogIcon {
  color: var(--kog-colors-grey-700);
}

.ImageUpload:not(.ImageUpload--uploading):not(.ImageUpload--disabled):hover {
  background: var(--kog-colors-grey-100);
}

.ImageUpload--withError {
  outline-color: var(--kogPlatformRedDarken20);
}

.ImageUpload--dragOver {
  background: var(--kog-colors-grey-100);
  outline-color: var(--kog-colors-aubergine-600);
  outline-width: 2px;
}

.ImageUpload--dragOver .ImageUpload-kogIcon {
  color: var(--kog-colors-aubergine-700);
}

.ImageUpload-uploadButton {
  pointer-events: none;
}

.ImageUpload-imageContainer {
  position: relative;

  overflow: hidden;

  min-height: 64px;
  padding: var(--space-m) 64px;

  background-color: var(--kog-colors-aubergine-100);
  border-radius: var(--space-s);
}

.ImageUpload-imageContainer.u-disabled {
  background-color: var(--kog-colors-grey-300);
}

.ImageUpload-imagePreview {
  width: auto;
  max-width: 100%;
  max-height: 600px;
  object-fit: contain;
}

.ImageUpload-deleteImageButton {
  position: absolute;
  z-index: 2;
  top: var(--space-s);
  right: var(--space-s);
}

.ImageUpload-downloadErrorIcon {
  position: relative;
  color: var(--kog-colors-grey-700);
}
.ImageUpload-downloadErrorIcon > span:last-child {
  position: absolute;
  top: -25%;
  right: -25%;
}
.ImageUpload-downloadErrorIcon > span:last-child::before {
  content: '';

  position: absolute;

  width: 50%;
  height: 50%;

  background-color: var(--kog-colors-white);
}
.ImageUpload-downloadErrorIcon > span:last-child :deep(i) {
  z-index: 1;
}
</style>
