<template>
  <long-text-question
    v-bind="attrs"
    :question="getQuestion"
    :auto-save="getAutoSaveCallback"
    :answer="answer"
    @blur="handleTextUserResponse"
    @input="handleUserInput"
  />
</template>

<script setup lang="ts">
import { computed, ref, toRefs, useAttrs, watch } from 'vue';

import type { LTQ, LTQTextUserResponse } from 'sharedApp/composables/question-context/types.ts';
import type { AutoSaveEvent, Emit, LTQImageUserResponse, Props } from './ltq.types';

import LongTextQuestion from 'sharedApp/components/long-text-question/long-text-question.vue';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';

const props = defineProps<Props>();

const { autoSave, question, userResponse } = toRefs(props);

const attrs = useAttrs();
const emit = defineEmits<Emit>();

const answer = ref(userResponse?.value || '');

function handleOnAutoSave(saveEvent: AutoSaveEvent) {
  const { value, ...rest } = saveEvent;

  if (!autoSave?.value) return;

  autoSave.value({
    question: question.value,
    response_type: RESPONSE_TYPES.LTR,
    user_response: value,
    ...rest,
  });
}

function handleTextInput(response: LTQTextUserResponse) {
  answer.value = response;
  emit('change', {
    question_uuid: question.value.uuid,
    response_type: RESPONSE_TYPES.LTR,
    user_response: response,
  });
}

function handleTextUserResponse(response: LTQTextUserResponse) {
  if (autoSave?.value) return;

  emit('response', {
    question_uuid: question.value.uuid,
    response_type: RESPONSE_TYPES.LTR,
    user_response: response,
  });
}

function handleImageInput(response: LTQImageUserResponse) {
  const { value, ...rest } = response;
  answer.value = value;

  emit('change', {
    question_uuid: question.value.uuid,
    response_type: RESPONSE_TYPES.IMR,
    user_response: value,
  });

  emit('response', {
    question_uuid: question.value.uuid,
    response_type: RESPONSE_TYPES.IMR,
    user_response: value,
    ...rest,
  });
}

function handleUserInput(response: LTQImageUserResponse | LTQTextUserResponse) {
  if (typeof response === 'string') {
    handleTextInput(response);
  } else {
    handleImageInput(response);
  }
}

watch(
  () => userResponse?.value,
  newValue => {
    answer.value = newValue || '';
  },
);

const getAutoSaveCallback = computed(() => (autoSave?.value ? handleOnAutoSave : undefined));
const getQuestion = computed<LTQ>(() => ({
  ...question.value,
  question_context: question.value.context,
}));
</script>
