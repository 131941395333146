<template>
  <div>
    <supporting-material-link-list
      v-if="shouldShowSupportingMaterial"
      :materials="sectionNode.supporting_materials"
      heading-size="xxs"
      :heading-level="3"
      :should-group-items="false"
      :user="user"
    />
    <div v-if="hasSubSections">
      <h3 class="heading-xxs margin-bottom-s text-capitalize"> {{ $term('subsection') }}s </h3>
      <content-node-list
        :content-nodes="sectionNode.children"
        content-type="subsection"
        :icon="itemIcon"
        :should-group-items="false"
        :class-size="classSize"
        :user-nodes-reading-progress="userNodesReadingProgress"
        :reading-completion-data-per-node-id="readingCompletionDataPerNodeId"
        @click-button="navigateToSubsection"
        @click-container="closeModal()"
        @click-completion-stats="$event => $emit('clickCompletionStats', $event)"
      />
    </div>
    <div v-else>
      <kog-button
        class="width-full"
        label="Open Section"
        button-style="primary"
        @click="navigateToSection"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import subsectionIcon from 'sharedApp/img/study/subject-class-overview-subsection.svg';

import ContentNodeList from './content-node-list.vue';
import SupportingMaterialLinkList from './supporting-material-link-list.vue';

export default {
  name: 'SectionModalBody',
  components: {
    ContentNodeList,
    KogButton,
    SupportingMaterialLinkList,
  },
  props: {
    userNodesReadingProgress: {
      type: Object,
      default: null,
    },
    sectionNode: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    navigateToClassBook: {
      type: Function,
      required: true,
    },
    classSize: {
      type: Number,
      default: 0,
    },
    readingCompletionDataPerNodeId: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['clickCompletionStats'],
  data() {
    return {
      itemIcon: subsectionIcon,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      user: state => state.userModule.user,
    }),
    hasSubSections() {
      return this.sectionNode.children?.length > 0;
    },
    shouldShowSupportingMaterial() {
      return this.user.isTeacher() && this.sectionNode.supporting_materials?.length > 0;
    },
  },
  methods: {
    navigateToSection() {
      this.navigate(this.sectionNode);
    },
    navigateToSubsection(node) {
      this.navigate(node);
    },
    navigate(node) {
      this.closeModal();
      this.navigateToClassBook(node);
    },
  },
};
</script>
