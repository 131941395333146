<template>
  <div class="NotebookSubjectTreeMenu">
    <menu-book-item
      :item="subjectTree"
      :action="toggleItemsAndSelectNode"
      :indicator-component="indicatorComponent"
      :url="getNodeUrl(subjectTree)"
    />
    <div
      v-for="topic in subjectTree.children"
      :key="topic.id"
    >
      <menu-book-item
        :item="topic"
        :action="toggleItemsAndSelectNode"
        :indicator-component="indicatorComponent"
        :url="getNodeUrl(topic)"
      >
        <menu-book-item
          v-for="subtopic in topic.children"
          :key="subtopic.id"
          :item="subtopic"
          :action="toggleItemsAndSelectNode"
          :indicator-component="indicatorComponent"
          :url="getNodeUrl(subtopic)"
        >
          <menu-book-item
            v-for="section in subtopic.children"
            :key="section.id"
            :item="section"
            :action="toggleItemsAndSelectNode"
            :indicator-component="indicatorComponent"
            :url="getNodeUrl(section)"
          >
            <menu-book-item
              v-for="subsection in section.children"
              :key="subsection.id"
              :item="subsection"
              :action="toggleItemsAndSelectNode"
              :indicator-component="indicatorComponent"
              :url="getNodeUrl(subsection)"
            />
          </menu-book-item>
        </menu-book-item>
      </menu-book-item>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep.js';

import {
  getSubjectNodeMap,
  isContentNode,
  isSection,
} from 'sharedApp/libs/subject-tree-functions.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import MenuBookItem from 'studyApp/components/side-navigation/menu-book-item.vue';

export default {
  name: 'NotebookSubjectTreeMenu',
  components: {
    MenuBookItem,
  },
  mixins: [RoutesMixin],
  props: {
    tree: {
      type: Object,
      required: true,
    },
    annotationsCount: {
      type: Object,
      required: true,
    },
    indicatorComponent: {
      type: String,
      default: null,
    },
    currentNode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedSection: null,
      subjectTree: null,
      lastSelectedNode: null,
    };
  },
  computed: {
    subjectNodeMap() {
      return getSubjectNodeMap(this.subjectTree);
    },
    subjectNodeList() {
      return Object.values(this.subjectNodeMap);
    },
  },
  watch: {
    currentNode: {
      handler(newValue) {
        const node = this.subjectNodeMap[newValue.id];
        this.toggleItems(node);
      },
    },
    subjectTree: {
      handler() {
        const selectedSection = this.subjectNodeList.find(sn => sn.selected && isSection(sn));
        if (
          selectedSection &&
          (!this.selectedSection || selectedSection.id !== this.selectedSection.id)
        ) {
          this.selectedSection = selectedSection;
          this.toggleItems(selectedSection);
          setTimeout(() => {
            const el = document.getElementById(selectedSection.id);
            if (el) {
              el.scrollIntoView({
                behavior: 'smooth',
              });
            }
          }, 100);
        }
      },
      deep: true,
    },
  },
  created() {
    this.subjectTree = this.addMenuAndProgressProperties(cloneDeep(this.tree));
    this.updateTreeNodes(this.subjectTree, this.annotationsCount);
  },
  methods: {
    addMenuAndProgressProperties(subTree) {
      const st = subTree;
      st.selected = this.currentNode.id === st.id;
      st.expanded = false;
      st.annotations_count = 0;
      st.children.forEach(this.addMenuAndProgressProperties);
      return st;
    },
    toggleItems(item) {
      const node = item;
      node.expanded = !node.expanded;
      this.unselectAll();
      this.collapseAll();
      this.expandAndSelectItemAndParents(node);
    },
    toggleItemsAndSelectNode(currentNode) {
      if (this.lastSelectedNode !== currentNode) {
        this.lastSelectedNode = currentNode;
        this.toggleItems(currentNode);
      }
    },
    unselectAll() {
      this.subjectNodeList.forEach(item => {
        const node = item;
        node.selected = false;
      });
    },
    collapseAll() {
      this.subjectNodeList.forEach(item => {
        const node = item;
        node.expanded = false;
      });
    },
    expandAndSelectItemAndParents(item) {
      const node = item;
      node.expanded = true;
      node.selected = true;
      if (node.parent) {
        this.expandAndSelectItemAndParents(this.subjectNodeMap[node.parent]);
      }
    },
    updateTreeNodes(subTree, annotationsCount) {
      const treeNode = subTree;
      let count = 0;
      if (treeNode.children) {
        treeNode.children.forEach(node => {
          count += this.updateTreeNodes(node, annotationsCount);
        });
      }
      if (isContentNode(treeNode)) {
        treeNode.annotations_count = annotationsCount[treeNode.id] || 0;
        count = treeNode.annotations_count;
      } else {
        treeNode.annotations_count = count;
      }
      return count;
    },
    getNodeUrl(node) {
      return {
        name: 'classNotebookNode',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: node.slug,
          nodeId: node.id,
        },
      };
    },
  },
};
</script>

<style scoped>
.NotebookSubjectTreeMenu {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: var(--kogGreyBase);
}
</style>
