<template>
  <kog-modal
    :title="subjectNode.name"
    :close-modal="closeModal"
    :header-icon="headerIcon"
    :subtitle="subtitle"
  >
    <template #modalBody>
      <reflection-question-completion-stats-modal-body
        v-if="subjectNode.section_template === 'reflection'"
        :subject-node="subjectNode"
        :students="students"
        :initial-filter="initialFilter"
        @close-modal="closeModal"
      />
      <reading-completion-stats-modal-body
        v-else
        :subject-node="subjectNode"
        :students="students"
        :reading-completion-data="readingCompletionData"
        :subject="subject"
      />
    </template>
    <template
      v-if="subjectNode.section_template !== 'reflection'"
      #modalFooter
    >
      <completion-stats-modal-footer
        :subject-class-name="subjectClassName"
        :subject="subject"
        :subject-node="subjectNode"
        :subject-class-id="subjectClassId"
        :user="user"
        :source="source"
        :class-slug="classSlug"
        :disable-creating-assignment="disableCreatingAssignment"
        :close-modal="closeModal"
      />
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import sectionIcon from 'sharedApp/img/study/subject-class-overview-section.svg';
import CompletionStatsModalFooter from 'studyApp/components/subject-class-overview/completion-stats-modal-footer.vue';

import ReadingCompletionStatsModalBody from './reading-completion-stats-modal-body.vue';
import ReflectionQuestionCompletionStatsModalBody from './reflection-question-completion-stats-modal-body.vue';

export default {
  name: 'ReadingCompletionStatsModal',
  components: {
    KogModal,
    ReadingCompletionStatsModalBody,
    ReflectionQuestionCompletionStatsModalBody,
    CompletionStatsModalFooter,
  },
  props: {
    subjectNode: {
      type: Object,
      required: true,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
    readingCompletionData: {
      type: Object,
      default: undefined,
    },
    initialFilter: {
      type: String,
      default: null,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    classSlug: {
      type: String,
      required: true,
    },
    subject: {
      type: Object,
      required: true,
    },
    subjectClassName: {
      type: String,
      required: true,
    },
    disableCreatingAssignment: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: 'not_declared',
    },
  },
  data() {
    return {
      headerIcon: {
        type: 'image',
        imgUrl: sectionIcon,
        alt: '',
      },
    };
  },
  computed: {
    subtitle() {
      return this.subjectNode.formatted_number_including_ancestors;
    },
  },
};
</script>
