<template>
  <kog-card id="subjectOverviewFiltersContainer">
    <div class="flexContainer flexContainer-column">
      <span
        id="subjectOverviewFiltersHeading"
        class="text-overline margin-bottom-xxs"
        >Filters</span
      >
      <div
        class="flexContainer flexContainer-row flexContainer-alignCenter"
        role="group"
        aria-labelledby="subjectOverviewFiltersHeading"
      >
        <kog-toggleable-button
          v-for="filter in filters"
          :key="filter.name"
          class="margin-right-xs"
          has-icon
          :icon-class="filter.icon"
          icon-position="top"
          :label="filter.name"
          :is-active="filter.isActive"
          role="checkbox"
          :aria-checked="filter.isActive"
          @click="$emit('update-filter', filter)"
        />
      </div>
      <vertical-expand>
        <div
          v-show="isFiltering"
          class="padd-top-xs padd-left-xs subjectOverviewFilters-filteredItems"
          aria-hidden="true"
        >
          <p>{{ numberOfFilteredItemsToShow }} items found</p>
        </div>
      </vertical-expand>
      <span
        class="screenreader-only"
        aria-live="polite"
      >
        {{ screenReaderText }}
      </span>
    </div>
  </kog-card>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import type { SubjectOverviewFiltersProps } from 'studyApp/components/subject-class-overview/subject-overview-filters.types.ts';

import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogToggleableButton from 'sharedApp/components/base/buttons/kog-toggleable-button.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';

const props = defineProps<SubjectOverviewFiltersProps>();

defineEmits(['update-filter']);

const numberOfFilteredItemsToShow = ref(props.numberOfFilteredItems);
const screenReaderText = computed(() =>
  props.isFiltering ? `${numberOfFilteredItemsToShow.value} items found` : '',
);
watch(
  () => props.numberOfFilteredItems,
  newValue => {
    if (props.isFiltering) {
      numberOfFilteredItemsToShow.value = newValue;
    }
  },
);
</script>
