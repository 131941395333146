<template>
  <div>
    <h2
      id="legend-label"
      class="heading-xxs"
    >
      Legend
    </h2>
    <div
      role="list"
      aria-labelledby="legend-label"
    >
      <div
        role="listitem"
        class="flexContainer ReflectionQuestionsLegend-item"
      >
        <reflection-questions-chip
          class="margin-right-xs"
          :section-status="submissionStatus.SUBMITTED"
          legend="Submitted - Default status after student submits their work."
        />
      </div>

      <div
        role="listitem"
        class="flexContainer ReflectionQuestionsLegend-item"
      >
        <reflection-questions-chip
          class="margin-right-xs"
          :section-status="submissionStatus.NOT_SUBMITTED"
          legend="Not submitted - Student has not submitted any work yet."
        />
      </div>
      <div
        role="listitem"
        class="flexContainer ReflectionQuestionsLegend-item"
      >
        <reflection-questions-chip
          class="margin-right-xs"
          :section-status="submissionStatus.FLAGGED"
          legend="Flagged - Manually flagged student submission. Not visible for the student."
        />
      </div>
    </div>
  </div>
</template>

<script>
import ReflectionQuestionsChip, {
  SUBMISSION_STATUS,
} from 'studyApp/components/insights/reflection-questions-chip.vue';

export default {
  name: 'ReflectionQuestionsLegend',
  components: {
    ReflectionQuestionsChip,
  },
  data() {
    return {
      submissionStatus: SUBMISSION_STATUS,
    };
  },
};
</script>
<style scoped>
.ReflectionQuestionsLegend-item {
  margin: var(--space-xs) var(--space-xs) var(--space-xs) 0;
}
</style>
