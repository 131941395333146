export function buildLinkableActivities(activities, subjectNodes) {
  return activities.map(activity => {
    const nodes = subjectNodes.filter(leafNode =>
      activity.mapped_subject_nodes.includes(leafNode.id),
    );
    const name = nodes
      .map(node => `${node.formatted_number_including_ancestors} ${activity.title}`)
      .join(' / ');
    return [name, activity.id];
  });
}
