<template>
  <div class="inlineflexContainer flexContainer-alignCenter">
    <div v-if="showDifficulty && hasDifficultyTags">
      <span
        class="kogPlatformGray051 text-small"
        aria-hidden="true"
      >
        Difficulty:
      </span>
      <kog-difficulty :tags="tags" />
    </div>
    <kog-calculator
      class="margin-left-m"
      :subject-id="subjectId"
      :tags="tags"
    />
    <esq-indicator
      class="margin-left-m QuestionTags-esq"
      :tags="tags"
      :esq-term="$term('exam-style')"
    />
  </div>
</template>

<script>
import { QUESTION_DIFFICULTIES } from 'learning/common/components/difficulty/difficulty.ts';
import KogDifficulty from 'learning/common/components/difficulty/kog-difficulty.vue';

import EsqIndicator from 'sharedApp/components/icons/esq-indicator.vue';
import KogCalculator from 'sharedApp/components/icons/kog-calculator.vue';

const difficultyTags = Object.values(QUESTION_DIFFICULTIES);

export default {
  name: 'QuestionTags',
  components: {
    EsqIndicator,
    KogCalculator,
    KogDifficulty,
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    subjectId: {
      type: Number,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    hasDifficultyTags() {
      return this.tags.some(tag => difficultyTags.includes(tag));
    },
  },
};
</script>

<style scoped>
.QuestionTags-esq {
  margin-top: -4px;
}
</style>
