<template>
  <div
    :class="{
      'KogCard--collapsible': isCollapsible,
      containContent: isContentScrolling,
      flexContainer: isContentScrolling,
      'flexContainer-column': isContentScrolling,
    }"
    :style="style"
  >
    <template v-if="isCollapsible">
      <transition name="fade">
        <div
          v-if="isCollapsed"
          class="KogCard-expandButtonContainer inlineflexContainer flexContainer-alignCenter positionAbsolute padd-s"
        >
          <kog-round-button
            ref="expandButton"
            :aria-label="`Show card content for: ${title}`"
            aria-expanded="false"
            :icon-class="iconClass"
            icon-style="regular"
            icon-size="m"
            button-style="primary"
            @click="expand"
          />
        </div>
      </transition>
    </template>
    <bidirectional-expand-transition
      @after-collapse="onAfterCardCollapse"
      @after-expand="onAfterCardExpand"
    >
      <div
        v-if="!isCollapsed"
        class="KogCard-contentContainer flexChild-size-1"
        :class="{
          containContent: isContentScrolling,
          flexContainer: isContentScrolling,
          'flexContainer-column': isContentScrolling,
        }"
      >
        <div class="screenreader-only">
          <p>{{ context }}</p>
          <p>{{ title }}</p>
          <p>{{ subtitle }}</p>
        </div>
        <div
          v-if="hasTopSlot"
          class="KogCard-contentSlot"
          :class="{
            'KogCard-contentSlot--topRadius': hasBottomSlot || title,
            'KogCard-contentSlot--radius': !hasBottomSlot && !title,
            'padd-s': defaultPadding,
          }"
        >
          <!-- @slot Slot for displaying information above the card's title -->
          <slot name="topSlot" />
        </div>
        <div
          v-if="title"
          class="KogCard-titleContainer inlineflexContainer flexContainer-alignCenter padd-s width-full flexChild-noShrink"
          :class="{
            'KogCard-titleContainer--bottomRadius': !hasBottomSlot && !hasDefaultSlot,
            'KogCard-titleContainer--topRadius': !hasTopSlot,
          }"
        >
          <div
            v-if="iconClass"
            class="KogCard-iconContainer inlineflexContainer flexContainer-justifyCenter flexContainer-alignCenter margin-right-xs"
          >
            <kog-icon
              theme="custom"
              :icon-class="iconClass"
              :is-svg-icon="isSvgIcon"
              fa-style="regular"
              size="m"
            />
          </div>
          <div
            aria-hidden="true"
            class="KogCard-TitleContent flexChild-canGrow"
          >
            <div class="text-bold">
              {{ title }}
            </div>
            <div
              v-if="subtitle"
              class="muted"
            >
              {{ subtitle }}
            </div>
          </div>
          <kog-dropdown-button
            v-if="hasDropdownMenu"
            class="margin-left-xs"
            :aria-label="`Show dropdown menu for: ${title}`"
            button-type="round"
            icon-class="fa-ellipsis-v"
            icon-style="regular"
            :items="menuOptions"
            button-style="secondary-basic"
            @select="selectItem"
          />
          <kog-round-button
            v-if="isCollapsible"
            ref="collapseButton"
            class="margin-left-xs"
            button-style="basic"
            :aria-label="`Hide card content for: ${title}`"
            aria-expanded="true"
            icon-class="fa-chevron-left"
            icon-style="regular"
            icon-size="s"
            @click="collapse"
          />
        </div>
        <div
          v-if="hasBottomSlot"
          class="KogCard-contentSlot"
          :class="{
            'KogCard-contentSlot--bottomRadius': hasTopSlot || title,
            'KogCard-contentSlot--radius': !hasTopSlot && !title,
            'padd-s': defaultPadding,
          }"
        >
          <!-- @slot Slot for displaying information below the card's title -->
          <slot name="bottomSlot" />
        </div>
        <div
          v-else-if="hasDefaultSlot"
          class="KogCard-contentSlot"
          :class="[
            {
              'padd-s': defaultPadding,
              containContent: isContentScrolling,
              flexContainer: isContentScrolling,
              'flexContainer-column': isContentScrolling,
            },
            !!title ? 'KogCard-contentSlot--bottomRadius' : 'KogCard-contentSlot--radius',
          ]"
        >
          <!-- @slot Default slot for displaying information below the card's title -->
          <slot />
        </div>
      </div>
    </bidirectional-expand-transition>
  </div>
</template>

<script>
import BidirectionalExpandTransition from 'sharedApp/animations/bidirectional-expand.vue';
import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'KogCard',
  components: {
    BidirectionalExpandTransition,
    KogIcon,
    KogDropdownButton,
    KogRoundButton,
  },
  props: {
    /**
     * Provides additional information to screenreaders.<br>
     * If specified, it will be read out before the `title`.
     */
    context: {
      type: String,
      default: '',
    },
    /**
     * Puts padd-s around content when `true`.
     */
    defaultPadding: {
      type: Boolean,
      default: true,
    },
    /**
     * Sets the class of the icon.
     * Use any type of font awesome icon class or custom svg icon.
     * Only available with `size: 'm'`
     */
    iconClass: {
      type: String,
      default: '',
    },
    /**
     * Passed down to the `KogIcon` component
     */
    isSvgIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Specifies the menu items in the card dropdown menu. <br>
     * A menu item must contain at least a `text` value, <br>
     * a `value` (string), and a `callback` action which is <br>
     * triggered on item select.
     */
    menuOptions: {
      type: Array,
      default: () => [],
      validator: options =>
        options.every(
          option =>
            Object.prototype.hasOwnProperty.call(option, 'text') &&
            Object.prototype.hasOwnProperty.call(option, 'value') &&
            Object.prototype.hasOwnProperty.call(option, 'callback'),
        ),
    },
    /**
     * The subtitle of the card.
     */
    subtitle: {
      type: String,
      default: '',
    },
    /**
     * The title of the card
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * The width of the card in px.
     */
    width: {
      type: String,
      default: '',
    },
    /*
     * Whether the card is collapsible or not.
     */
    isCollapsible: {
      type: Boolean,
      default: false,
    },
    isContentScrolling: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isCollapsed: false,
  }),
  computed: {
    hasTopSlot() {
      return Boolean(this.$slots.topSlot);
    },
    hasBottomSlot() {
      return Boolean(this.$slots.bottomSlot);
    },
    hasDefaultSlot() {
      return Boolean(this.$slots.default);
    },
    hasDropdownMenu() {
      return this.menuOptions.length > 0;
    },
    style() {
      if (this.width) {
        return `width: ${this.width}px;`;
      }
      return '';
    },
  },
  mounted() {
    if (!this.hasTopSlot && !this.hasBottomSlot && !this.hasDefaultSlot) {
      // eslint-disable-next-line no-console
      console.warn('KogCard should have at least one content slot.');
    }
  },
  methods: {
    selectItem(item) {
      item.callback();
    },
    collapse() {
      this.isCollapsed = true;
    },
    expand() {
      this.isCollapsed = false;
    },
    onAfterCardExpand() {
      this.$refs.collapseButton.$refs.root.focus();
    },
    onAfterCardCollapse() {
      this.$refs.expandButton.$refs.root.focus();
    },
  },
};
</script>

<style scoped>
.KogCard--collapsible {
  position: relative;
}

.KogCard-titleContainer {
  overflow: hidden;
  background: var(--kog-card-background);
}

.KogCard-titleContainer,
.KogCard-expandButtonContainer {
  height: 72px;
}

.KogCard-titleContainer--bottomRadius,
.KogCard-contentSlot--bottomRadius {
  border-radius: 0 0 var(--space-xs) var(--space-xs);
}

.KogCard-contentSlot--topRadius,
.KogCard-titleContainer--topRadius {
  border-radius: var(--space-xs) var(--space-xs) 0 0;
}

.KogCard-TitleContent {
  overflow: hidden;
  white-space: nowrap;
}

.KogCard-contentContainer {
  height: inherit;
}

.KogCard-contentSlot {
  background-color: var(--kog-card-background);
}

.KogCard-contentSlot--radius {
  border-radius: var(--space-xs);
}

.KogCard-iconContainer {
  width: var(--space-xxl);
  min-width: var(--space-xxl);
  height: var(--space-xxl);
  min-height: var(--space-xxl);

  color: var(--kog-card-icon-color);

  background-color: var(--kog-card-icon-background-color);
  border-radius: 50%;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
</style>
