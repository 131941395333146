// eslint-disable-next-line max-classes-per-file
import { Plugin } from '@ckeditor/ckeditor5-core';
import { FileRepository } from '@ckeditor/ckeditor5-upload';
import { isAxiosError } from 'axios';

import type { FileLoader, UploadAdapter } from '@ckeditor/ckeditor5-upload';
import type { AxiosResponse } from 'axios';

import axios, { externalAxios } from 'sharedApp/vue-utils/kog-axios.ts';

type ContentUploadResponse = {
  file_media_url: string;
  form_data: Record<string, string>;
  post_url: string;
  public_url: string;
};

function getErrorMessage(error: unknown) {
  let errorMessage = 'Upload error: ';

  if (isAxiosError<string>(error) && error.response) {
    const { response, message } = error;

    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, 'text/xml');
      const responseCode = xmlDoc.getElementsByTagName('Code')[0].innerHTML;
      const responseMessage = xmlDoc.getElementsByTagName('Message')[0].innerHTML;
      errorMessage += `${response.status} ${response.statusText}: ${responseCode} ${responseMessage}`;
    } catch {
      errorMessage += message;
    }
  } else if (error instanceof Error) {
    errorMessage += error.message;
  }
  return errorMessage;
}

class CustomUploadAdapter implements UploadAdapter {
  private declare loader: FileLoader;

  private declare abortController: AbortController;

  private postSigningURL: string = '/api/content/media_browser/post_signing_params/';

  constructor(loader: FileLoader) {
    this.loader = loader;
    this.abortController = new AbortController();
  }

  async getSignedPostParams(file: File | null) {
    if (!file) throw new Error('File is null');

    const response = await axios.get<ContentUploadResponse>(this.postSigningURL, {
      signal: this.abortController.signal,
      params: {
        s3_object_type: file.type,
        s3_object_name: file.name,
      },
    });
    const formData = new FormData();
    Object.entries(response.data.form_data).forEach(entry => {
      const [key, value] = entry;
      formData.append(key, value);
    });
    formData.append('file', file);
    return {
      response,
      formData,
    };
  }

  async uploadToS3({
    response,
    formData,
  }: {
    response: AxiosResponse<ContentUploadResponse>;
    formData: FormData;
  }) {
    await externalAxios.post(response.data.post_url, formData, {
      signal: this.abortController.signal,
    });
    return { default: response.data.file_media_url };
  }

  upload() {
    return this.loader.file
      .then(this.getSignedPostParams.bind(this))
      .then(this.uploadToS3.bind(this))
      .catch(error => {
        const errorMessage = getErrorMessage(error);
        return Promise.reject(errorMessage);
      });
  }

  abort() {
    this.abortController.abort();
  }
}

export default class UploadAdapterPlugin extends Plugin {
  static get requires() {
    return [FileRepository];
  }

  static get pluginName() {
    return 'UploadAdapterPlugin' as const;
  }

  init() {
    this.editor.plugins.get(FileRepository).createUploadAdapter = loader => {
      return new CustomUploadAdapter(loader);
    };
  }
}
