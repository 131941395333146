<template>
  <generic-warning-box>
    <b> Something went wrong! </b>
    <p>
      Please try again, or contact
      <a href="mailto:support@kognity.com"> support@kognity.com </a>
    </p>
  </generic-warning-box>
</template>

<script>
import GenericWarningBox from 'sharedApp/components/warning-box/generic-warning-box.vue';

export default {
  name: 'ServerErrorWarningBox',
  components: {
    GenericWarningBox,
  },
};
</script>
