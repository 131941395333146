<template>
  <div
    class="ExerciseQuestion-wrapper"
    :class="{
      'u-sending': isSendingAnswer,
    }"
    tabindex="0"
    role="button"
    @keydown.space.prevent="onToggleExpand"
    @keydown.enter.prevent="onToggleExpand"
  >
    <kog-accordion-item
      :is-open="isExpanded || isAlwaysOpen"
      :toggle-accordion="onToggleExpand"
      :header-class="getAccordionHeaderClasses"
      body-class="ExerciseQuestion-accordionBody"
      :item-aria-label="`Question ${position}`"
    >
      <template #closedContent>
        <exercise-question-status-icon
          class="margin-xxs"
          :has-answered="hasUserAnswer"
          :has-answered-correctly="isCorrect"
        />

        <template v-if="isFBQ">
          <fill-in-the-blanks-question
            v-if="isFBQ"
            class="width-full ExerciseQuestion-questionContent"
            :question="question"
            :user-answers="practiceItem.answerToSubmit"
            :is-showing-answer-correctness="isCorrected"
            :answers-with-correctness="practiceItemResult?.solution?.correct_answers"
            :data-position="position"
            @input="setFitbqAnswer"
          />
        </template>

        <template v-if="isMCQ">
          <multiple-choice-question
            class="width-full ExerciseQuestion-questionContent"
            :question="practiceItem.question"
            :choices="practiceItem.question.multiplechoicequestionchoice_set"
            :display-state="mcqDisplayState.QUESTION"
            :data-position="position"
          />
        </template>

        <template v-if="isSTQ">
          <short-text-question
            class="width-full font-lato ExerciseQuestion-questionContent"
            :question="practiceItem.question"
            :initial-answer="practiceItem.answerToSubmit"
            :display-state="stqDisplayState.QUESTION"
            :data-position="position"
          />
        </template>

        <kog-icon
          v-if="!isAlwaysOpen"
          class="ExerciseQuestion-chevron"
          :icon-class="isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
          fa-style="regular"
          size="s"
          theme="custom"
        />
      </template>

      <template #body>
        <multiple-choice-question
          v-if="isMCQ"
          class="width-full padd-left-xl padd-right-xl padd-top-m"
          :multi-select="isMultiSelect"
          :disabled="isCorrect"
          :question="practiceItem.question"
          :choices="practiceItem.question.multiplechoicequestionchoice_set"
          :choice-state="currentChoiceState"
          :display-state="mcqDisplayState.CHOICES"
          @input="setMcqAnswer"
        />
        <short-text-question
          v-if="isSTQ"
          class="width-full font-lato padd-left-xxl padd-right-xxl padd-top-m"
          :question="practiceItem.question"
          :initial-answer="practiceItem.answerToSubmit"
          :is-correct="showStqResult && isCorrect"
          :is-incorrect="showStqResult && !isCorrect"
          :disabled="isCorrect"
          :display-state="stqDisplayState.INPUT"
          @input="answer => setAnswer({ answer })"
        />
      </template>
    </kog-accordion-item>
  </div>
</template>

<script>
import { isNil, xor } from 'lodash';

import KogAccordionItem from 'sharedApp/components/accordion/kog-accordion-item.vue';
import FillInTheBlanksQuestion from 'sharedApp/components/fill-in-the-blanks-question/fill-in-the-blanks-question.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { MCQ_DISPLAY_STATE } from 'sharedApp/components/multiple-choice-question/choice-state-helper.js';
import MultipleChoiceQuestion from 'sharedApp/components/multiple-choice-question/multiple-choice-question.vue';
import { STQ_DISPLAY_STATE } from 'sharedApp/components/short-text-question/short-text-question-utility.ts';
import ShortTextQuestion from 'sharedApp/components/short-text-question/short-text-question.vue';
import {
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

import ExerciseQuestionStatusIcon from './exercise-question-status-icon.vue';

export default {
  name: 'ExerciseQuestion',
  components: {
    FillInTheBlanksQuestion,
    KogIcon,
    ShortTextQuestion,
    MultipleChoiceQuestion,

    ExerciseQuestionStatusIcon,
    KogAccordionItem,
  },
  props: {
    position: {
      type: Number,
      required: true,
    },
    practiceItem: {
      type: Object,
      required: true,
    },
    hasUserAnswer: {
      type: Boolean,
      default: false,
    },
    practiceItemResult: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['set-answer'],
  data() {
    return {
      isExpanded: false,
      isSendingAnswer: false,
      mcqDisplayState: MCQ_DISPLAY_STATE,
      stqDisplayState: STQ_DISPLAY_STATE,
    };
  },
  computed: {
    getAccordionHeaderClasses() {
      const classes = new Set(['ExerciseQuestion-accordionHeader']);

      if (this.isAlwaysOpen) {
        classes.add('u-stale');
      }

      return Array.from(classes.values()).join(' ');
    },
    isAlwaysOpen() {
      return this.isFBQ;
    },
    isMCQ() {
      return isMCQ(this.question);
    },
    isSTQ() {
      return isSTQ(this.question);
    },
    isFBQ() {
      return isFBQ(this.question);
    },
    question() {
      return this.practiceItem.question;
    },
    isCorrect() {
      return this.practiceItemResult.user_answer && this.practiceItemResult.user_answer.is_correct;
    },
    isCorrected() {
      return !isNil(this.practiceItemResult.user_answer?.is_correct);
    },
    showStqResult() {
      return (
        this.isCorrected &&
        this.practiceItemResult.user_answer.user_text === this.practiceItem.answerToSubmit
      );
    },
    currentChoiceState() {
      const { answerToSubmit } = this.practiceItem;
      if (!answerToSubmit) {
        return {};
      }

      const createAnswerStateRow = (acc, answer) => ({
        ...acc,
        [answer]: { selected: true, correct: this.isCorrectChoice(answer) },
      });

      if (Array.isArray(answerToSubmit)) {
        return answerToSubmit.reduce(createAnswerStateRow, {});
      }

      return createAnswerStateRow({}, answerToSubmit);
    },
    isMultiSelect() {
      return this.question.is_multi_select;
    },
    solutionAsArray() {
      return Array.isArray(this.practiceItemResult.solution)
        ? this.practiceItemResult.solution
        : [this.practiceItemResult.solution];
    },
  },
  watch: {
    isOpen(newIsOpen) {
      if (newIsOpen) {
        document.addEventListener('keyup', this.onEscKeyUp);
      } else {
        document.removeEventListener('keyup', this.onEscKeyUp);
      }
    },
  },
  methods: {
    setAnswer({ answer }) {
      this.$emit('set-answer', { answer });
    },
    setFitbqAnswer({ answers }) {
      this.setAnswer({ answer: answers });
    },
    setMcqAnswer(answerObject) {
      let newAnswer;
      if (this.isMultiSelect) {
        const answerToSubmit = this.practiceItem.answerToSubmit || [];
        newAnswer = xor(answerToSubmit, [answerObject.answer]);
      } else {
        newAnswer = [answerObject.answer];
      }

      this.setAnswer({ answer: newAnswer });
    },
    // eslint-disable-next-line vue/no-unused-properties
    setIsSendingAnswer(flag) {
      this.isSendingAnswer = flag;
    },
    onToggleExpand() {
      if (this.isAlwaysOpen) return;

      this.isExpanded = !this.isExpanded;
    },
    isCorrectChoice(id) {
      return (
        this.practiceItemResult.solution &&
        this.solutionAsArray.map(choice => choice.id).includes(id)
      );
    },
  },
};
</script>

<style scoped>
.u-sending {
  transform: scale(0.95);
  opacity: 0.5;
}

.u-sending :deep(.MCQChoiceItem) {
  background-color: var(--kogTransparent);
}

.u-sending .ExerciseQuestion-questionPosition {
  background-color: var(--kog-content-purple-3);
}

.u-sending :deep(.ShortTextQuestion-Input) {
  background-color: var(--kogTransparent);
}

.u-sending :deep(.FillBlankQuestionInput) {
  background-color: var(--kogTransparent);
  border-color: var(--kogPlatformGray084);
}

.ExerciseQuestion-wrapper {
  justify-content: flex-start;

  min-height: var(--space-xxxl);
  margin-bottom: var(--space-m);
  padding: var(--space-xs) var(--space-m) var(--space-l);

  background: var(--kog-colors-white);
  border: 1px solid var(--kog-colors-grey-800);
  border-radius: var(--space-m);

  transition:
    border-color ease-in-out 0.15s,
    opacity cubic-bezier(0.25, 0.1, 0.25, 1) 0.5s,
    transform cubic-bezier(0.25, 0.1, 0.25, 1) 0.5s;
}

.ExerciseQuestion-chevron {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;

  width: 44px;
  margin: var(--space-s) 0 auto auto;

  color: var(--kog-colors-aubergine-700);
}

.ExerciseQuestion-wrapper:hover {
  border-color: var(--kog-colors-aubergine-600);
}

.ExerciseQuestion-wrapper:hover .ExerciseQuestion-chevron {
  color: var(--kog-colors-aubergine-600);
}

.ExerciseQuestion-wrapper :deep(.ExerciseQuestion-accordionHeader) {
  display: flex;
  font-family: var(--kog-satoshi);
}

.ExerciseQuestion-wrapper :deep(.ExerciseQuestion-accordionHeader:not(.u-stale)) {
  cursor: pointer;
}

.ExerciseQuestion-questionContent {
  display: flex;
  padding-top: var(--space-xxs);
  font-size: var(--kog-font-size-content);
  color: var(--kog-text-default);
}

.ExerciseQuestion-questionContent::before {
  content: attr(data-position) '.';

  float: left;

  margin-right: var(--space-xs);
  margin-left: var(--space-xs);

  font-family: var(--kog-satoshi);
  font-size: 17px;
  font-weight: bold;
}

.ExerciseQuestion-questionContent::after {
  content: ' ';
  clear: both;
  display: table;
}

.ExerciseQuestion-questionContent :deep(p) {
  margin: 0;
}

.ExerciseQuestion-questionContent :deep(p + p) {
  margin-top: 10px;
}
</style>
