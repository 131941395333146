<template>
  <block-wrapper
    :content="content"
    :block-label="blockLabel"
    :available-actions="availableActions"
    @mark-removal="$event => $emit('mark-removal', $event)"
    @unmark-removal="$event => $emit('unmark-removal', $event)"
    @move-down="$event => $emit('move-down', $event)"
    @move-up="$event => $emit('move-up', $event)"
  >
    <file-upload
      class="ImageBlock-upload flexContainer flexContainer-column flexContainer-alignCenter flexContainer-justifyCenter"
      name="Image upload"
      uploading-message="Uploading..."
      :allowed-extensions="['jpeg', 'jpg', 'png']"
      @upload="handleOnUpload"
    >
      <template #body>
        <div
          v-if="!imageToDisplay"
          class="absoluteContainer flexContainer flexContainer-column flexContainer-alignCenter u-ignorePointerEvents"
        >
          <kog-icon
            fa-style="light"
            icon-class="fa-file-image"
            size="xl"
          />
          <span class="text-regular text-bold"> Click here to upload or drag and drop </span>
          <span class="text-small"> Maximum file size 5 MB</span>
        </div>
        <image-box
          v-else
          :key="imageToDisplay"
          :src="imageToDisplay"
        />
      </template>
    </file-upload>

    <template v-if="imageToDisplay">
      <kog-select
        placeholder-text="Select display type for image"
        label="Display as"
        :options="displayTypeOptions"
        :selected-value="displayTypeValue"
        @change="value => updateContentField('display_type', value)"
      />

      <kog-input
        :value="content.caption"
        label="Caption"
        @input="value => updateContentField('caption', value)"
      />

      <kog-input
        :value="content.alternate_text"
        label="Alternate text"
        @input="value => updateContentField('alternate_text', value)"
      />
      <div>
        <label
          :for="getUniqueId('credit-source-editor')"
          class="KogInput-label"
          >Credit/Source</label
        >
        <kog-editor-ck5
          :id="getUniqueId('credit-source-editor')"
          class="ImageBlock-editor"
          :content="content.image_credit || ''"
          :config="cke5Options"
          aria-label="image credit/source"
          @update:content="value => updateContentField('image_credit', value)"
          @editorready="handleEditorReady"
        />
      </div>
    </template>
  </block-wrapper>
</template>

<script>
import KogEditorCk5 from 'publishingApp/containers/section/components/kog-editor-ck5.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import FileUpload from 'sharedApp/components/file-upload/file-upload.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import ImageBox from 'sharedApp/components/images/image-box.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import { capitalizeFirstLetter } from 'sharedApp/utils/string-utils.js';

import BlockWrapper, { ITEM_ACTIONS } from '../../block-wrapper.vue';
import BlockMixin from '../mixins/block-mixin.js';

export const IMAGE_DISPLAY_TYPES = Object.freeze({
  DEFAULT: 'default',
  COVER: 'cover',
});

const ENTER_KEY_CODE = 13;

export default {
  name: 'ContentBoxBlock',
  components: {
    FileUpload,
    ImageBox,
    KogIcon,
    KogSelect,
    BlockWrapper,
    KogInput,
    KogEditorCk5,
  },
  mixins: [BlockMixin],
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  emits: ['mark-removal', 'unmark-removal', 'move-up', 'move-down'],
  setup() {
    const { getUniqueId } = useUniqueId();

    return {
      getUniqueId,
    };
  },
  data() {
    return {
      cke5Options: {
        exclude: ['showSolution', 'inlineQuestion', 'exerciseBox', 'glossaryLink', 'fileDownload'],
      },
      availableActions: [ITEM_ACTIONS.MARK_REMOVAL],
      imagePreviewUrl: null,
      // eslint-disable-next-line vue/no-unused-properties
      editorFields: ['credit'],
    };
  },
  computed: {
    imageToDisplay() {
      return this.imagePreviewUrl || this.content?.image;
    },
    displayTypeOptions() {
      return Object.values(IMAGE_DISPLAY_TYPES).map(type => ({
        value: type,
        text: capitalizeFirstLetter(type),
      }));
    },
    displayTypeValue() {
      return this.content?.display_type;
    },
  },
  watch: {
    'content.image': {
      handler(image) {
        if (typeof image === 'string') {
          this.imagePreviewUrl = null;
        }
      },
    },
  },
  methods: {
    handleEditorReady(instance) {
      instance.on('key', event => {
        if (event.data.domEvent.getKey() === ENTER_KEY_CODE) {
          event.cancel();
        }
      });
    },
    handleOnUpload(image) {
      this.updateContentField('image', image);
      this.imagePreviewUrl = URL.createObjectURL(image).toString();
    },
  },
};
</script>

<style scoped>
.ImageBlock-upload {
  border-radius: var(--space-xs);
  outline-offset: 0;
}

.ImageBlock-editor {
  font-family: var(--kog-satoshi);
  font-size: var(--kog-font-size-default);
}

.ImageBlock-upload :deep(.FileUpload-inputFile) {
  height: 100%;
}
</style>
