<template>
  <kog-modal :maximize="true">
    <template #modalBody>
      <div
        class="text-center"
        :class="[
          isSentToGoogleClassroom && isEverySectionCompleted
            ? 'padd-top-xxl padd-right-xxl padd-left-xxl'
            : 'padd-xxl',
        ]"
      >
        <integration-assignment-alert
          class="text-left padd-right-xl padd-left-xl margin-top-m margin-bottom-m"
        />
        <h3>You have completed the section.</h3>
        <p>
          <i class="fas fa-4x fa-check-circle kogPlatformGreenBase" />
        </p>
      </div>
      <div
        v-if="isSentToGoogleClassroom && isEverySectionCompleted"
        class="margin-top-xl margin-bottom-xl"
      >
        <google-classroom-reminder />
      </div>
      <div
        class="kogPlatformGray096-bg padd-m flexContainer flexContainer-justifyCenter"
        :class="{ 'kogPlatformGreenLighten20-bg': isEverySectionCompleted }"
      >
        <div>
          <div class="kogPlatformGray031">
            <i class="far fa-bookmark" />
            Book assignment
          </div>
          <h5 class="margin-top-xxs">
            {{ assignment.name }}
          </h5>
          <div class="text-bold margin-top-m">
            {{ assignmentSections.length }} section{{ assignmentSections.length > 1 ? 's' : '' }}
          </div>
          <div
            v-for="section in assignmentSections"
            :key="section.id"
          >
            <i
              class="fas fa-check-circle"
              :class="{
                kogPlatformGreenBase: section.completed,
                kogPlatformGray084: !section.completed,
              }"
            />
            <router-link
              v-slot="{ href, navigate }"
              custom
              :to="getSectionUrl(section)"
            >
              <a
                :href="href"
                @click="
                  e => {
                    beforeNavigate('Section link');
                    navigate(e);
                  }
                "
              >
                {{ section.details.formatted_number_including_ancestors }}
                {{ section.details.name }}
              </a>
            </router-link>
          </div>
          <div class="text-center margin-top-m">
            <h5 v-if="isEverySectionCompleted">
              You have completed the book assignment!
              <span class="margin-left-xxs KogEmoji KogEmoji--large KogEmoji--tada" />
            </h5>
            <div class="KogButtonSet KogButtonSet--center">
              <button
                class="KogButtonLegacy"
                @click="closeModal"
              >
                Close
              </button>
              <router-link
                v-if="!isEverySectionCompleted"
                v-slot="{ href, navigate }"
                :to="getSectionUrl(nextAssignmentSection)"
                custom
              >
                <a
                  :href="href"
                  class="KogButtonLegacy KogButtonLegacy--primary"
                  @click="
                    e => {
                      beforeNavigate('Next section button');
                      navigate(e);
                    }
                  "
                >
                  Continue with section
                  {{ nextAssignmentSection.details.formatted_number_including_ancestors }}
                </a>
              </router-link>
              <submit-to-integration-button
                v-if="canSubmitIntegrationAssignment"
                button-style="accent"
                :assignment="assignment"
                :has-submitted-to-integration="hasSubmittedToIntegration"
              />
              <router-link
                v-if="isEverySectionCompleted"
                v-slot="{ href, navigate }"
                :to="assignmentRoute"
                custom
              >
                <a
                  :href="href"
                  class="KogButtonLegacy KogButtonLegacy--primary"
                  @click="
                    e => {
                      beforeNavigate('Assignment overview');
                      navigate(e);
                    }
                  "
                >
                  Go to assignment overview
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isEverySectionCompleted && nextPageRoute"
        class="padd-m text-center"
      >
        <router-link
          v-slot="{ href, navigate }"
          :to="nextPageRoute"
          custom
        >
          <a
            :href="href"
            @click="
              e => {
                closeModal();
                navigate(e);
              }
            "
          >
            Continue to the next section
          </a>
        </router-link>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { mapState } from 'vuex';

import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import IntegrationAssignmentAlert from 'studyApp/components/assignment/integration-assignment-alert.vue';
import GoogleClassroomReminder from 'studyApp/components/google-classroom-reminder/google-classroom-reminder.vue';
import SubmitToIntegrationButton from 'studyApp/pages/assignment/components/submit-to-integration-button.vue';

export default {
  name: 'ReadingAssignmentCompletedModal',
  components: {
    SubmitToIntegrationButton,
    IntegrationAssignmentAlert,
    KogModal,
    GoogleClassroomReminder,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    assignmentOccasion: {
      type: Object,
      required: true,
    },
    nextPageRoute: {
      type: Object,
      default: null,
    },
    assignmentSections: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
    }),
    classSlug() {
      return this.$route.params.classSlug;
    },
    subjectId() {
      return this.$route.params.sid;
    },
    classId() {
      return this.$route.params.cid;
    },
    assignment() {
      return this.assignmentOccasion.assignment;
    },
    isEverySectionCompleted() {
      return this.assignmentSections.every(s => s.completed);
    },
    isSentToGoogleClassroom() {
      return this.assignmentOccasion.is_sent_to_google_classroom;
    },
    hasSubmittedToIntegration() {
      return this.assignment.is_submitted_to_integration;
    },
    canSubmitIntegrationAssignment() {
      return this.assignment?.is_sent_to_integration && this.isEverySectionCompleted;
    },
    nextAssignmentSection() {
      return this.assignmentSections.find(section => !section.completed);
    },
    assignmentRoute() {
      return {
        name: 'classReadingAssignmentTake',
        params: {
          classSlug: this.classSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignment.id,
        },
        query: { source: 'completion-modal' },
      };
    },
  },
  created() {
    if (this.isEverySectionCompleted) {
      this.trackCompletedReadingAssignment();
    }
  },
  methods: {
    beforeNavigate(origin) {
      this.closeModal();
      const eventProperties = { linkType: origin };
      this.$mixpanel.trackEvent('Reading assignments - continue assignment', eventProperties);
    },
    trackCompletedReadingAssignment() {
      const mixpanelEventProperties = {
        assignment_type: this.assignment.assignment_type,
      };
      this.$mixpanel.trackEventViaBackend('Assignment - Complete', mixpanelEventProperties);
      intercomModule.trackEvent('completed-reading-assignment');
      const eventProperties = {
        subject_id: this.subjectId,
        subject_class_id: this.classId,
        assignment_id: this.assignment.id,
        num_subject_classes: this.assignment.subject_classes.length,
      };
      this.$event.track('submit_reading_assignment', eventProperties);
    },
    getSectionUrl(section) {
      return {
        name: 'classBook',
        params: {
          classSlug: this.classSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: section.details.slug,
          nodeId: section.details.id,
        },
      };
    },
  },
};
</script>
