<template>
  <kog-table
    :title="title"
    :pagination-number-of-pages="paginationNumberOfPages"
    :pagination-current-page="paginationCurrentPage"
    :update-current-page="updateCurrentPage"
    :footer-text="paginationText"
  >
    <template #subtitle>
      <assignment-list-subtitle
        :selected-items="selectedAssignmentIds"
        :total-items="assignmentCount"
        :list-type="listType"
        @deleted="assignmentsDeleted"
        @archived="assignmentsArchived"
      />
    </template>
    <template #colgroup>
      <col v-if="isItemSelectionAllowed" />
      <col width="50%" />
      <col />
      <col width="50%" />
      <col />
      <col />
      <col v-if="!isArchived" />
    </template>
    <template #header>
      <kog-table-header>
        <kog-table-cell-checkbox
          v-if="isItemSelectionAllowed"
          :is-header-cell="true"
          :is-checked="isAllSelected"
          @check="toggleSelectAllVisible()"
          @uncheck="toggleSelectAllVisible()"
        />
        <kog-table-cell-header
          :is-sorted="sortedBy === sortOptions.name"
          :sort-column="() => sortByField(sortOptions.name)"
          :sort-order="sortOrder"
        >
          Assignment name
        </kog-table-cell-header>
        <kog-table-cell-header
          :is-sorted="sortedBy === sortOptions.type"
          :sort-column="() => sortByField(sortOptions.type)"
          :sort-order="sortOrder"
        >
          Type
        </kog-table-cell-header>
        <kog-table-cell-header
          :sort-column="() => sortByField(sortOptions.subject_class)"
          :is-sorted="sortedBy === sortOptions.subject_class"
          :sort-order="sortOrder"
        >
          Subject classes
        </kog-table-cell-header>

        <template v-if="isArchived">
          <kog-table-cell-header
            :is-sorted="sortedBy === sortOptions.archived_at"
            :sort-column="() => sortByField(sortOptions.archived_at)"
            :sort-order="sortOrder"
          >
            Archived at
          </kog-table-cell-header>
        </template>

        <template v-else>
          <kog-table-cell-header
            :is-sorted="sortedBy === sortOptions.created_at || sortedBy === sortOptions.sent"
            :sort-column="() => sortByField(isDraft ? sortOptions.created_at : sortOptions.sent)"
            :sort-order="sortOrder"
          >
            {{ isDraft ? 'Date created' : 'Sent' }}
          </kog-table-cell-header>
          <kog-table-cell-header
            :is-sorted="sortedBy === sortOptions.deadline"
            :sort-column="() => sortByField(sortOptions.deadline)"
            :sort-order="sortOrder"
          >
            Deadline
          </kog-table-cell-header>
        </template>

        <kog-table-cell-header />
      </kog-table-header>
    </template>

    <template #body>
      <kog-loader
        :loading="isSorting"
        class="AssignmentListTable-loader"
        loading-msg="Sorting assignments..."
      />
      <kog-table-row
        v-for="(assignment, index) in assignments"
        :key="assignment.id"
        :class="{ 'AssignmentListTable-body--sorting': isSorting }"
        :is-selected="false"
        :is-top-aligned="isTopAligned(assignment.id)"
      >
        <kog-table-cell-checkbox
          v-if="isItemSelectionAllowed"
          class="AssignmentListTable-checkbox"
          :is-disabled="false"
          :is-checked="isAssignmentSelected(assignment)"
          @check="toggleAssignment(index, assignment)"
          @uncheck="toggleAssignment(index, assignment)"
        />
        <kog-table-cell-text
          :tooltip-text="assignmentNameTooltipText(assignment)"
          :is-multi-line="true"
          :tag-label="
            isArchived ? draftAssignmentBadgeText(assignment) : scheduledBadgeText(assignment)
          "
          @on-multiple-line="updateTopAligned(assignment.id, $event)"
        >
          <div
            v-if="isAssignmentClickable(assignment)"
            v-kog-clickable:[assignment]="assignmentClick"
            v-kog-description:[getUniqueId(assignment.id)]="
              'Click to navigate to assignment details'
            "
            class="AssignmentListTable-assignmentName--clickable"
          >
            {{ assignment.name }}
          </div>
          <div
            v-else
            class="AssignmentListTable-assignmentName--disabled"
          >
            {{ assignment.name }}
          </div>
        </kog-table-cell-text>

        <kog-table-cell-text>
          <kog-icon
            size="s-touch"
            :icon-class="iconDetails(assignment).iconClass"
            :fa-style="iconDetails(assignment).style"
            :is-svg-icon="iconDetails(assignment).isSVG"
          />
          <span class="margin-left-s"
            >{{ isEsqAssignment(assignment) ? esqTerm : iconDetails(assignment).shortLabel }}
          </span>
        </kog-table-cell-text>

        <kog-table-cell-text
          :is-able-to-truncate="true"
          :tag-label="isClassExpired(assignment) ? 'Expired' : ''"
          @on-multiple-line="updateTopAligned(assignment.id, $event)"
        >
          {{ getSubjectClassNames(assignment) }}
        </kog-table-cell-text>

        <template v-if="isArchived">
          <kog-table-cell-text v-if="isArchived">
            {{ datetime(assignment.archived_at) }}
          </kog-table-cell-text>
        </template>
        <template v-else-if="isDraft">
          <kog-table-cell-text>
            {{ datetime(assignment.created_at) }}
          </kog-table-cell-text>
        </template>
        <template v-else>
          <kog-table-cell-text v-if="assignment.is_scheduled_to_send">
            <i class="fal fa-clock margin-right-xxs" />
            {{ datetime(assignment.scheduled_sent_at) }}
          </kog-table-cell-text>
          <kog-table-cell-text v-else>
            {{ datetime(assignment.sent_at) }}
          </kog-table-cell-text>
        </template>

        <kog-table-cell-text v-if="!isArchived">
          {{ datetime(assignment.deadline) }}
        </kog-table-cell-text>

        <kog-table-cell-actions
          class="padd-left-l"
          :actions="getActions(assignment)"
          :context="assignment"
          :is-actions-collapsible="!isArchived"
          :collapse-actions-width="1024"
          :dropdown-aria-label="`Actions for assignment ${assignment.name}`"
        />
      </kog-table-row>
    </template>
  </kog-table>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapGetters } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogTableCellActions from 'sharedApp/components/tables/kog-table-cell-actions.vue';
import KogTableCellCheckbox from 'sharedApp/components/tables/kog-table-cell-checkbox.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import KogClickable from 'sharedApp/directives/kog-clickable.js';
import KogDescription from 'sharedApp/directives/kog-description.js';
import {
  ASSIGNMENT_TYPES,
  getIconDetails,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import AssignmentListSubtitle from 'studyApp/components/teacher/assignments/list/assignment-list-subtitle.vue';
import StudyAppSessionStorage from 'studyApp/utils/session-storage-utils.js';
import {
  ASSIGNMENT_LIST_TYPES,
  ASSIGNMENT_TABLE_SORT_BY_OPTIONS,
  SORT_ORDERS,
} from 'teachApp/utils/assignment-utils.js';

const ASSIGNMENT_STATUS = {
  sent: 'sent',
  saved: 'saved',
};
const SHIFT_KEY_CODE = 16;
const ESC_KEY_CODE = 27;
const studyAppSessionStorage = new StudyAppSessionStorage();

export default {
  name: 'AssignmentListTable',
  components: {
    KogIcon,
    KogLoader,
    KogTable,
    KogTableHeader,
    KogTableRow,
    KogTableCellActions,
    KogTableCellCheckbox,
    KogTableCellText,
    KogTableCellHeader,
    AssignmentListSubtitle,
  },
  directives: {
    KogClickable,
    KogDescription,
  },
  props: {
    assignments: {
      type: Array,
      default: () => [],
    },
    assignmentCount: {
      type: Number,
      default: 0,
    },
    listType: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isItemSelectionAllowed: {
      type: Boolean,
      default: true,
    },
    isSorting: {
      type: Boolean,
      default: false,
    },
    paginationCurrentPage: {
      type: Number,
      default: 0,
    },
    paginationNumberOfPages: {
      type: Number,
      default: 0,
    },
    paginationPageSize: {
      type: Number,
      default: 0,
    },
    sortCallback: {
      type: Function,
      default: () => {},
    },
    updateCurrentPage: {
      type: Function,
      default: () => false,
    },
    copy: {
      type: Function,
      default: () => {},
    },
    delete: {
      type: Function,
      default: () => {},
    },
    goToAssignment: {
      type: Function,
      default: () => {},
    },
    endNow: {
      type: Function,
      default: () => {},
    },
    archive: {
      type: Function,
      default: () => {},
    },
    afterArchiveAssignment: {
      type: Function,
      default: () => {},
    },
    afterDeleteAssignment: {
      type: Function,
      default: () => {},
    },
    unarchive: {
      type: Function,
      default: () => {},
    },
    unschedule: {
      type: Function,
      default: () => {},
    },
    copyLinkToClipboard: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['print-assignment'],
  setup() {
    const { getUniqueId } = useUniqueId();
    const assignmentStore = useAssignmentStore();
    const { isAssignmentBasketOpen } = storeToRefs(assignmentStore);

    return {
      isAssignmentBasketOpen,
      fetchAssignmentBasketDetails: assignmentStore.fetchAssignment,
      getUniqueId,
    };
  },
  data() {
    let defaultSortedBy;

    if (this.listType === ASSIGNMENT_LIST_TYPES.ARCHIVED)
      defaultSortedBy = ASSIGNMENT_TABLE_SORT_BY_OPTIONS.archived_at;
    else if (this.listType === ASSIGNMENT_LIST_TYPES.DRAFT)
      defaultSortedBy = ASSIGNMENT_TABLE_SORT_BY_OPTIONS.created_at;
    else defaultSortedBy = ASSIGNMENT_TABLE_SORT_BY_OPTIONS.sent;

    return {
      isMultiSelect: false,
      lastToggledIndex: -1,
      lastSelectedIndex: -1,
      sequenceSelectionCount: 0,
      toasterShownKey: '',
      isToasterShownBefore: false,
      selectedAssignmentIds: [],
      sortedBy: defaultSortedBy,
      sortOptions: ASSIGNMENT_TABLE_SORT_BY_OPTIONS,
      sortOrder: SORT_ORDERS.desc,
      topAlignedRows: {},
    };
  },
  computed: {
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    paginationText() {
      if (!this.paginationNumberOfPages || this.assignmentCount === 0) {
        return '';
      }
      const pageSize = this.paginationPageSize;
      const totalItems = this.assignmentCount;
      const firstItemNumber = pageSize * this.paginationCurrentPage - pageSize + 1;
      const lastItemNumber = Math.min(pageSize * this.paginationCurrentPage, totalItems);

      return `${firstItemNumber} - ${lastItemNumber} of ${totalItems} assignments`;
    },
    esqTerm() {
      return this.$term('exam-style');
    },
    isCurrent() {
      return this.listType === ASSIGNMENT_LIST_TYPES.CURRENT;
    },
    isPast() {
      return this.listType === ASSIGNMENT_LIST_TYPES.PAST;
    },
    isDraft() {
      return this.listType === ASSIGNMENT_LIST_TYPES.DRAFT;
    },
    isArchived() {
      return this.listType === ASSIGNMENT_LIST_TYPES.ARCHIVED;
    },
    isAllSelected() {
      return this.assignments.every(assignment =>
        this.selectedAssignmentIds.includes(assignment.id),
      );
    },
  },
  created() {
    window.addEventListener('keydown', this.handleKeyDownEvent);
    window.addEventListener('keyup', this.handleKeyUpEvent);
    this.toasterShownKey = `assignments_shift_selection_shown_${this.user.id}`;
    this.isToasterShownBefore = localStorage[this.toasterShownKey];
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDownEvent);
    window.removeEventListener('keyup', this.handleKeyUpEvent);
  },
  methods: {
    assignmentNameTooltipText(assignment) {
      if (this.isClassExpired(assignment) && !this.isAssignmentClickable(assignment)) {
        return 'You can no longer edit this assignment as the class is expired. If you want to reuse this assignment, you must copy it first';
      }
      return '';
    },
    getActions(assignment) {
      const actions = [
        ...(this.isCurrent && !this.isAssignmentScheduled(assignment)
          ? [
              {
                tooltipText: 'End assignment now',
                text: 'End',
                callback: this.endNow,
                dropdownText: 'End now',
                dropdownIconClass: 'fa-stop-circle',
              },
            ]
          : []),
        ...(this.isCurrent && this.isAssignmentScheduled(assignment)
          ? [
              {
                tooltipText: 'Cancel scheduled assignment',
                text: 'Cancel',
                callback: this.unschedule,
                dropdownIconClass: 'fa-stop-circle',
              },
            ]
          : []),
        ...(this.isCurrent
          ? [
              {
                tooltipText: 'Copy shareable link',
                iconClass: 'fa-link',
                callback: this.copyLinkToClipboard,
                dropdownText: 'Get shareable link',
                dropdownIconClass: 'fa-share',
              },
            ]
          : []),
        ...(this.isDraft && this.isEsqAssignment(assignment)
          ? [
              {
                tooltipText: 'Print draft',
                iconClass: 'fa-print',
                callback: this.printAssignment,
                dropdownText: 'Print',
              },
            ]
          : []),
        ...(this.isDraft || this.isPast
          ? [
              {
                tooltipText: 'Archive',
                iconClass: 'fa-archive',
                callback: this.archiveAssignment,
                dropdownText: 'Archive',
              },
            ]
          : []),
        ...(this.isDraft
          ? [
              {
                tooltipText: 'Delete draft',
                iconClass: 'fa-trash-alt',
                callback: this.delete,
                dropdownText: 'Delete',
              },
            ]
          : []),
        ...(!this.isArchived
          ? [
              {
                tooltipText: this.isDraft ? 'Duplicate draft' : 'Duplicate assignment',
                iconClass: 'fa-copy',
                callback: this.copy,
                dropdownText: 'Duplicate',
              },
            ]
          : []),
        ...(this.isArchived
          ? [
              {
                text: 'Unarchive',
                callback: this.unarchive,
              },
            ]
          : []),
      ];

      return actions;
    },
    isEsqAssignment({ assignment_type: assignmentType }) {
      return assignmentType === ASSIGNMENT_TYPES.EXAMSTYLE;
    },
    isTopAligned(rowModelId) {
      // row it top-aligned if at least one cell within spreads on multiple lines
      return this.topAlignedRows[rowModelId] > 0;
    },
    updateTopAligned(rowModelId, isCellMultiline) {
      this.topAlignedRows[rowModelId] = this.topAlignedRows[rowModelId] || 0;
      if (isCellMultiline) {
        this.topAlignedRows = {
          ...this.topAlignedRows,
          [rowModelId]: this.topAlignedRows[rowModelId] + 1,
        };
      } else {
        this.topAlignedRows = {
          ...this.topAlignedRows,
          [rowModelId]: this.topAlignedRows[rowModelId] - 1,
        };
      }
    },
    getSubjectClasses(assignment) {
      return assignment.subject_classes.map(subjectClassId => {
        return this.subjectClassesById[subjectClassId];
      });
    },
    getSubjectClassNames(assignment) {
      return this.getSubjectClasses(assignment)
        .map(subjectClass => subjectClass.name)
        .join(', ');
    },
    scheduledBadgeText(assignment) {
      return assignment.is_scheduled_to_send ? 'Scheduled' : '';
    },
    draftAssignmentBadgeText(assignment) {
      return this.isAssignmentDraft(assignment) ? 'Draft' : '';
    },
    iconDetails(assignment) {
      return getIconDetails(assignment.assignment_type);
    },
    isClassExpired(assignment) {
      return this.getSubjectClasses(assignment).every(subjectClass => subjectClass.is_expired);
    },
    isAssignmentDraft(assignment) {
      return assignment.status === ASSIGNMENT_STATUS.saved;
    },
    isAssignmentArchived(assignment) {
      return !!assignment.archived_at;
    },
    isAssignmentScheduled(assignment) {
      return !assignment.sent_at && assignment.scheduled_sent_at;
    },
    isAssignmentClickable(assignment) {
      return (
        (!this.isClassExpired(assignment) || assignment.status === ASSIGNMENT_STATUS.sent) &&
        !this.isAssignmentArchived(assignment)
      );
    },
    isAssignmentSelected(assignment) {
      return this.selectedAssignmentIds.includes(assignment.id);
    },
    handleKeyDownEvent(e) {
      if (e.keyCode === SHIFT_KEY_CODE) {
        this.isMultiSelect = true;
      } else if (e.keyCode === ESC_KEY_CODE) {
        this.unselectAll();
      }
    },
    handleKeyUpEvent(e) {
      if (e.keyCode === SHIFT_KEY_CODE) {
        this.isMultiSelect = false;
      }
    },
    showShiftClickHelperToaster() {
      this.$toast.showInfo(
        'Use ‘<strong>Shift</strong>’ + ‘<strong>click</strong>’ to select multiple items at once',
      );
      localStorage[this.toasterShownKey] = true;
      this.isToasterShownBefore = true;
    },
    getAssignmentIndex(assignmentId) {
      return this.assignments.findIndex(assignment => assignment.id === assignmentId);
    },
    resetSequenceSelection() {
      this.sequenceSelectionCount = 0;
      this.lastSelectedIndex = -1;
    },
    updateSequenceSelectionCounters(selectedAssignment) {
      const position = this.getAssignmentIndex(selectedAssignment.id);
      const isSelectedAssignmentAdjacentToLastSelectedAssignment =
        Math.abs(this.lastSelectedIndex - position) === 1;

      if (isSelectedAssignmentAdjacentToLastSelectedAssignment) {
        this.sequenceSelectionCount += 1;

        if (this.sequenceSelectionCount === 3) {
          this.showShiftClickHelperToaster();
        }
      } else {
        this.sequenceSelectionCount = 1;
      }
      this.lastSelectedIndex = position;
    },
    toggleSelectAllVisible() {
      const selectionToToggle = this.assignments.map(assignment => assignment.id);
      this.selectedAssignmentIds = this.getSelectionIdsAfterBatchToggle(
        selectionToToggle,
        this.isAllSelected,
      );
      this.lastToggledIndex = -1;
      this.resetSequenceSelection();
    },
    unselectAll() {
      this.selectedAssignmentIds = [];
      this.lastToggledIndex = -1;
      this.resetSequenceSelection();
    },
    getSelectionIdsAfterBatchToggle(selection, shouldUnselect) {
      if (shouldUnselect) {
        return this.selectedAssignmentIds.filter(id => !selection.includes(id));
      }

      const updatedAssignmentIdsSelection = new Set([...this.selectedAssignmentIds, ...selection]);

      return [...updatedAssignmentIdsSelection];
    },
    toggleAssignment(index, assignment) {
      if (this.isMultiSelect && this.lastToggledIndex !== -1) {
        const start = Math.min(index, this.lastToggledIndex);
        const end = Math.max(index, this.lastToggledIndex);
        const shiftSelection = this.assignments.slice(start, end + 1).map(a => a.id);

        this.selectedAssignmentIds = this.getSelectionIdsAfterBatchToggle(
          shiftSelection,
          this.isAssignmentSelected(assignment),
        );
        this.resetSequenceSelection();
      } else if (this.isAssignmentSelected(assignment)) {
        this.selectedAssignmentIds = this.selectedAssignmentIds.filter(id => id !== assignment.id);
        this.resetSequenceSelection();
      } else {
        if (!this.isToasterShownBefore) {
          this.updateSequenceSelectionCounters(assignment);
        }
        this.selectedAssignmentIds.push(assignment.id);
      }

      this.lastToggledIndex = index;
    },
    assignmentClick(assignment) {
      if (this.isAssignmentClickable(assignment)) {
        if (this.isDraft && assignment.assignment_type === 'READING') {
          this.openReadingAssignmentInBasket(assignment.id);
        } else {
          this.goToAssignment(assignment);
          this.isAssignmentBasketOpen = false;
        }
      }
    },
    async openReadingAssignmentInBasket(assignmentId) {
      studyAppSessionStorage.setOpenDraftAssignmentId(this.classId, assignmentId);
      await this.fetchAssignmentBasketDetails(assignmentId);
      this.isAssignmentBasketOpen = true;
    },
    sortByField(sortBy) {
      const isNewField = sortBy !== this.sortedBy;
      const isSortedDesc = this.sortOrder === SORT_ORDERS.desc;

      const order = isNewField || !isSortedDesc ? SORT_ORDERS.desc : SORT_ORDERS.asc;

      this.sortedBy = sortBy;
      this.sortOrder = order;

      this.sortCallback(sortBy, order);
    },
    assignmentsDeleted() {
      this.refreshCurrentPage();
      this.afterDeleteAssignment();
    },
    archiveAssignment(assignment) {
      this.selectedAssignmentIds = this.selectedAssignmentIds.filter(id => id !== assignment.id);
      this.archive(assignment);
    },
    assignmentsArchived() {
      this.unselectAll();
      this.afterArchiveAssignment();
    },
    printAssignment(assignment) {
      this.$emit('print-assignment', assignment);
    },
    refreshCurrentPage() {
      this.unselectAll();
      this.updateCurrentPage();
    },
    datetime(date) {
      return dateTimeFormatter(new Date(date));
    },
  },
};
</script>

<style scoped>
.AssignmentListTable-checkbox {
  user-select: none;
}

.AssignmentListTable-body--sorting {
  visibility: hidden;
  border: none;
}

.AssignmentListTable-assignmentName--disabled {
  color: var(--kog-text-disabled);
}

.AssignmentListTable-assignmentName--clickable {
  color: var(--kog-link-color);
}

.AssignmentListTable-assignmentName--clickable:hover {
  color: var(--kog-link-hover-color);
}

.AssignmentListTable-loader {
  position: absolute;
  right: 0;
  left: 0;
}
</style>
