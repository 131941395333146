import { Plugin } from '@ckeditor/ckeditor5-core';

import GlossaryLinkEditing from './glossary-link-editing.js';
import GlossaryLinkUI from './glossary-link-ui.js';

export default class GlossaryLink extends Plugin {
  static get requires() {
    return [GlossaryLinkEditing, GlossaryLinkUI];
  }
}
