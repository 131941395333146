<template>
  <nav
    ref="root"
    class="NavbarCenterMenu flexContainer"
  >
    <kog-item-list
      role="list"
      class="NavbarCenterMenu-list flexContainer flexContainer-justifyCenter"
    >
      <!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events
      eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
      <div
        v-for="menuItem in menuItems"
        :key="menuItem.key"
        :class="[
          {
            'NavbarCenterMenu-menuItem--selected': isSelected(menuItem.key),
            'NavbarCenterMenu-menuItem--transparent': isTransparent,
            'NavbarCenterMenu-menuItem--parent--selected': isSelectedParent(menuItem.key),
          },
          'KogIntercomTour-' + menuItem.key,
        ]"
        class="NavbarCenterMenu-menuItem cursorPointer flexContainer flexContainer-center text-center"
        role="button"
        :tabindex="menuItem.key === lastTargetedMenuItemKey ? 0 : -1"
        @mouseover.stop="onHoverMenuItem(menuItem.key)"
        @keydown.enter="selectMenuItem(menuItem.key)"
        @keydown.up="handleArrowUpKey($event)"
        @keydown.down="handleArrowDownKey($event, menuItem)"
        @focus="onFocusMenuItem(menuItem.key)"
        @click="selectMenuItem(menuItem.key)"
      >
        <!-- eslint-enable vuejs-accessibility/mouse-events-have-key-events -->
        <span class="screenreader-only">
          {{ screenreaderDisplayText(menuItem) }}
        </span>
        <span aria-hidden="true">
          {{ menuItem.displayText }}
        </span>

        <kog-notification
          v-if="menuItem.badgeCount"
          :offset-top="-12"
        />
        <div
          v-if="isUnderlined(menuItem.key)"
          class="NavbarCenterMenu-selectedItemUnderline"
        />
      </div>
    </kog-item-list>
  </nav>
</template>

<script>
import KogNotification from 'sharedApp/components/base/tags/kog-notification.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';

export default {
  name: 'NavbarCenterMenu',
  components: {
    KogNotification,
    KogItemList,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    selectMenuItem: {
      type: Function,
      required: true,
    },
    selectedMenuItem: {
      type: String,
      default: null,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    isSubmenu: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hover-menu-item', 'focus-sub-menu', 'focus-main-menu'],
  data() {
    return {
      lastTargetedMenuItemKey: this.menuItems[0].key,
    };
  },
  computed: {
    hasSubMenu() {
      const menu = this.menuItems.find(i => i.key === this.selectedMenuItem);
      return menu.subMenuItems && menu.subMenuItems.length > 0;
    },
  },
  methods: {
    screenreaderDisplayText(menuItem) {
      const currentActive = this.isUnderlined(menuItem.key) || this.isSelected(menuItem.key);
      const currentlyActiveNotice = currentActive ? ' is active' : '';
      const navigationalPrefix = currentActive ? '' : 'Go to';
      if (menuItem.key === 'assignments') {
        const badgeCountText =
          menuItem.badgeCount === 0 ? 'none ongoing' : `${menuItem.badgeCount} ongoing`;
        return `${navigationalPrefix} ${menuItem.displayText} - ${badgeCountText} ${currentlyActiveNotice}`;
      }
      return `${navigationalPrefix} ${menuItem.displayText} ${currentlyActiveNotice}`;
    },
    isSelected(menuName) {
      return this.selectedMenuItem === menuName;
    },
    isSelectedParent(menuName) {
      return this.isSelected(menuName) && this.hasSubMenu;
    },
    isUnderlined(menuName) {
      return this.isSelected(menuName) && !this.hasSubMenu;
    },
    onHoverMenuItem(menuItemKey) {
      this.$emit('hover-menu-item', menuItemKey);
    },
    onFocusMenuItem(menuItemKey) {
      this.$emit('hover-menu-item', menuItemKey);
      this.lastTargetedMenuItemKey = menuItemKey;
    },
    handleArrowDownKey(event, menuItem) {
      if (menuItem.subMenuItems?.length > 0) {
        event.stopPropagation();
        event.preventDefault();

        this.$emit('focus-sub-menu');
      }
    },
    handleArrowUpKey(event) {
      if (!this.isSubmenu) return;

      event.stopPropagation();
      event.preventDefault();

      this.$emit('focus-main-menu');
    },
  },
};
</script>

<style scoped>
.NavbarCenterMenu,
.NavbarCenterMenu-list {
  flex: 1;
}

.NavbarCenterMenu-list {
  margin: 0;
  padding: 0;
}

.NavbarCenterMenu-menuItem {
  flex: 0 1 auto;
  min-width: 70px;
  padding: 0 30px;
  list-style-type: none;
}

.NavbarCenterMenu-menuItem:hover {
  color: var(--kog-navigation-button-hover);
  background-color: var(--kog-navigation-background-default-hover);
}

.NavbarCenterMenu-menuItem--transparent:hover {
  color: var(--kog-colors-white);
  background-color: var(--kog-navigation-background-translucent-hover);
}

.NavbarCenterMenu-menuItem--selected {
  position: relative;
}

.NavbarCenterMenu-menuItem--transparent.NavbarCenterMenu-menuItem--selected,
.NavbarCenterMenu-menuItem--transparent.NavbarCenterMenu-menuItem--selected:hover {
  color: var(--kog-colors-white);
}

.NavbarCenterMenu-selectedItemUnderline {
  position: absolute;
  right: 10px;
  bottom: 0;
  left: 10px;

  height: 3px;

  background: var(--kog-navigation-border-brand);
  border-radius: 3px 3px 0 0;
}

.NavbarCenterMenu-menuItem--parent--selected,
.NavbarCenterMenu-menuItem--parent--selected:hover {
  background: var(--kog-navigation-background-default-active);
}

.NavbarCenterMenu-menuItem--transparent .NavbarCenterMenu-selectedItemUnderline {
  background-color: var(--kog-navigation-border-default);
}
</style>
