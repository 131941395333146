<template>
  <div>
    <div
      :class="{
        'kog-container-lg': !hasActiveExternalContentFrame,
        'padd-top-xxl': !hasActiveExternalContentFrame,
      }"
    >
      <div
        class="ActivitySheet-wrapper kog-col-12 kog-col-sm-12"
        :class="{
          'kog-col-lg-8 kog-offset-lg-2': !hasActiveExternalContentFrame,
          'kog-col-xl-12 kog-offset-xl-0': hasActiveExternalContentFrame,
        }"
      >
        <kog-loader
          :loading="isLoading"
          flex
        />
        <template v-if="!isLoading">
          <!-- Content portal -->
          <portal
            :to="
              isInReviewMode
                ? `${uid}-activity-sheet-content-reviewMode`
                : `${uid}-activity-sheet-content-study`
            "
          >
            <div
              class="ActivitySheetContainer ActivitySheetContainer--external"
              :class="{
                'ActivitySheetContainer--reviewMode': isInReviewMode,
                'ActivitySheetContainer--sideBySide': hasActiveExternalContentFrame,
              }"
            >
              <div class="ActivitySheetContainer-activityHeader">
                <section-header
                  :subject-node-id="nodeId"
                  :is-in-review-mode="isInReviewMode"
                  :is-showing-subtitle="isShowingSubtitle"
                  :is-showing-activity-statistics="!isInViewSubmissionMode"
                  :is-showing-section-tools="!isInViewSubmissionMode"
                  :is-showing-book-statistics="false"
                />
                <activity-teacher-instruction
                  v-if="isShowingTeacherInstruction"
                  :instruction="instruction"
                />
              </div>

              <activity-sheet-external-content-frame
                v-if="hasActiveExternalContentFrame"
                class="ActivitySheetContainer-externalContent"
                :content-frame="activitySheet.activity_sheet_external_content_frame"
                :subject="subject"
                :is-in-review-mode="isInReviewMode"
              />
              <div
                class="ActivitySheetContainer-activitySheetContentWrapper"
                :class="{
                  'ActivitySheetContainer-activitySheetContentWrapper--external':
                    hasActiveExternalContentFrame,
                }"
              >
                <div
                  ref="atsContent"
                  class="ActivitySheet-content"
                >
                  <component
                    :is="contentType(contentItem)"
                    v-for="contentItem in content"
                    :key="contentItem.id"
                    :content="contentItem"
                    :feature-type="feature.type"
                    :user-response-data="occasionResponses[contentItem.question?.id]"
                    :is-disabled="isSubmitted || isActivitySheetDisabled"
                    :is-in-review-mode="isInReviewMode"
                    :is-showing-user-response="isInViewSubmissionMode"
                    :is-showing-correct-answers-in-key="isInViewSubmissionMode"
                    :is-showing-key="isShowingKeyForQuestion(contentItem)"
                    :is-showing-key-toggle="isInViewSubmissionMode"
                    class="margin-bottom-l padd-top-l"
                    @user-response-change="handleUserResponse"
                  />
                </div>
              </div>
            </div>
          </portal>

          <!-- Component -->
          <div
            v-if="hasActiveExternalContentFrame && isInReviewMode"
            class="kog-container-lg"
          >
            <div class="kog-row">
              <div class="kog-col-12 kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
                <portal-target :name="`${uid}-activity-sheet-content-reviewMode`" />
              </div>
            </div>
          </div>

          <div v-else>
            <portal-target :name="`${uid}-activity-sheet-content-reviewMode`" />
          </div>

          <portal-target :name="`${uid}-activity-sheet-content-study`" />
        </template>
      </div>
    </div>
    <checkpoint-container
      v-if="isInTakeActivitySheetMode && canBeCompleted(nodeId)"
      :current-section-node="subjectNode"
      :complete-button-icon="submitButtonIcon"
      :complete-button-text="submitButtonText"
      :complete-button-style="submitButtonStyle"
      :completion-text="completionText"
      :is-loading="isCompleteButtonLoading || submittingNodeProgress"
      :is-reset-button-visible="false"
      :is-complete-button-disabled="!canSubmitActivity"
      @completed="trySendActivitySheet"
    />
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue';
import { isNil } from 'lodash';
import {
  mapActions as mapPiniaActions,
  mapGetters as mapPiniaGetters,
  mapState as mapPiniaState,
} from 'pinia';
import {
  mapActions as mapVuexActions,
  mapGetters as mapVuexGetters,
  mapMutations as mapVuexMutations,
  mapState as mapVuexState,
} from 'vuex';

import {
  DEFAULT_HIGHLIGHT_COLOR,
  trackDeleteNote,
  trackSaveNote,
} from 'learning/common/libs/annotations-utils.js';
import HighlightMixin from 'learning/common/mixins/highlight-mixin.js';
import useAnnotationStore from 'learning/common/store-modules/annotations/store.ts';
import { ImageUploadTrackingProvide } from 'learning/study/mixins/image-upload-tracking-mixin.js';
import { RouteNavigationEventInject } from 'learning/study/mixins/route-navigation-event-mixin.js';

import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';
import ActivityDivider from 'sharedApp/components/activity-divider/activity-divider.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import { FILE_STATUS } from 'sharedApp/components/image-upload/file-status.ts';
import KogConfirmModal from 'sharedApp/components/modals/kog-confirm-modal.vue';
import { useQuestionContextProvide } from 'sharedApp/composables/question-context/index.ts';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import { FEATURES_ENUM } from 'sharedApp/const/features.js';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';
import { mapWaitingActions as mapWaitingPiniaActions } from 'sharedApp/libs/pinia.ts';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isLTQ } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';
import ActivityContentBoxBlock from 'studyApp/components/activities/activity-content-box-block.vue';
import ActivityCustomEditorBlock from 'studyApp/components/activities/activity-custom-editor-block.vue';
import ActivityHeadingBlock from 'studyApp/components/activities/activity-heading-block.vue';
import ActivityImageBlock from 'studyApp/components/activities/activity-image-block.vue';
import ActivityQuestion from 'studyApp/components/activities/activity-question.vue';
import ActivitySheetExternalContentFrame from 'studyApp/components/activities/activity-sheet-external-content-frame.vue';
import ActivityTeacherInstruction from 'studyApp/components/activities/activity-teacher-instruction.vue';
import CheckpointContainer from 'studyApp/components/section-text/checkpoint-container.vue';
import SectionHeader from 'studyApp/components/section-text/section-header.vue';
import ActivityMixin from 'studyApp/mixins/activity-mixin.js';
import NodeProgressMixin from 'studyApp/mixins/node-progress-mixin.js';
import ReadingAssignmentMixin from 'studyApp/mixins/reading-assignment-mixin.js';
import useActivitySheetStore from 'studyApp/store/modules/activity-sheet/store.ts';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default defineComponent({
  name: 'ActivitySheetContainer',
  components: {
    CheckpointContainer,
    KogLoader,
    SectionHeader,
    ActivityTeacherInstruction,
    ActivitySheetExternalContentFrame,
    KogConfirmModal,
  },
  mixins: [
    RoutesMixin,
    ActivityMixin,
    NodeProgressMixin,
    ReadingAssignmentMixin,
    ImageUploadTrackingProvide,
    RouteNavigationEventInject,
    HighlightMixin,
  ],
  props: {
    isInReviewMode: {
      type: Boolean,
      default: false,
    },
    isInViewSubmissionMode: {
      type: Boolean,
      default: false,
    },
    feature: {
      type: Object,
      required: true,
    },
    selectedStudentId: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const store = useActivitySheetStore();
    const { proxy: component } = getCurrentInstance();
    const { uid } = useUniqueId();

    useQuestionContextProvide({
      retrieveResponses: question => store.occasionResponses[question?.id],
      context: {
        LTQ: {
          maxFileSize: 10,
          maxLength: 2000,
          autoSave: response => {
            const { question } = response;
            component.handleUserResponse({ response, questionId: question.id });
          },
        },
      },
    });

    return {
      uid,
    };
  },
  data() {
    return {
      intialScrollToHighlight: true,
      isShowAnswerKeyEnabled: false,
    };
  },
  computed: {
    ...mapPiniaState(useActivitySheetStore, [
      'activitySheet',
      'ownOccasion',
      'classOccasions',
      'occasionResponses',
    ]),
    ...mapPiniaState(useAnnotationStore, ['annotations', 'lastSoftDeletedId']),
    ...mapVuexState('userModule', ['user']),
    ...mapVuexState('bookModule', ['bookRailSliders']),
    ...mapVuexGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
    }),
    ...mapPiniaGetters(useActivitySheetStore, [
      'isProcessingResponses',
      'isActivitySheetDisabled',
      'isCreatingOccasion',
    ]),
    subjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    isLoading() {
      return (
        this.$wait.is('loading_activity_sheet') || this.$wait.is('loading_available_questions')
      );
    },
    isCompleteButtonLoading() {
      return this.$wait.is('loading_book_checkpoint') || this.$wait.is('submitting-activity-sheet');
    },
    featureId() {
      return this.feature.id;
    },
    content() {
      return this.activitySheet?.content || [];
    },
    isTeacher() {
      return this.user.isTeacher();
    },
    instruction() {
      return this.activitySheet.teacher_instruction_html;
    },
    isInTakeActivitySheetMode() {
      return !this.isInReviewMode && !this.isInViewSubmissionMode;
    },
    contentQuestions() {
      return this.content.filter(content => content.block_type === 'question');
    },
    contentQuestionIds() {
      return this.contentQuestions
        .filter(content => !!content.question)
        .map(content => content.question.id);
    },
    isShowingSubtitle() {
      return !this.isInViewSubmissionMode;
    },
    isShowingTeacherInstruction() {
      return this.isInReviewMode && !this.isInViewSubmissionMode;
    },
    isAllQuestionsAnswered() {
      const numberOfQuestions = this.contentQuestions.length;
      const numberOfResponses = Object.keys(this.occasionResponses).length;

      if (numberOfQuestions !== numberOfResponses) {
        return false;
      }

      const lengthCheck = toCheck => toCheck.length > 0;
      const objectCheck = toCheck => Object.values(toCheck).every(lengthCheck);

      return Object.values(this.occasionResponses).every(response => {
        const { user_response: userResponse, response_type: responseType } = response;

        if (responseType === RESPONSE_TYPES.IMR) {
          return userResponse && userResponse.file && userResponse.status === FILE_STATUS.DONE;
        }

        if (typeof userResponse === 'string' || Array.isArray(userResponse)) {
          return lengthCheck(userResponse);
        }

        return userResponse && objectCheck(userResponse);
      });
    },
    submitButtonStyle() {
      return this.isAllQuestionsAnswered ? 'accent' : 'primary';
    },
    completionText() {
      if (this.isSubmitted) {
        const formattedDoneAt = dateTimeFormatter(new Date(this.ownOccasion.submitted_at));
        return `Submitted ${formattedDoneAt}`;
      }

      return '';
    },
    hasActiveExternalContentFrame() {
      return !!this.activitySheet?.activity_sheet_external_content_frame?.is_active;
    },
    assignmentIds() {
      return this.ongoingReadingAssignmentOccasions.map(occasion => occasion.assignment.id);
    },
    isSubmitted() {
      return !!this.ownOccasion?.submitted_at;
    },
    isSubmissionInProcess() {
      return this.isCreatingOccasion || this.isProcessingResponses || this.submittingNodeProgress;
    },
    canSubmitActivity() {
      if (this.isTeacher) return false;
      return !this.isSubmissionInProcess;
    },
    submitButtonIcon() {
      return this.isSubmissionInProcess ? 'fa-spin fa-loader' : 'fa-paper-plane';
    },
    submitButtonText() {
      if (this.isCreatingOccasion) return 'Creating occasion...';
      if (this.isProcessingResponses) return 'Processing responses...';

      return 'Submit to teacher';
    },
    activeAnnotationsIds() {
      return Object.entries(this.annotations).reduce((acc, [id, annotation]) => {
        if (annotation.subjectNodeId === this.nodeId && !annotation.isSoftDeleted) {
          acc.push(id);
        }
        return acc;
      }, []);
    },
  },
  watch: {
    async featureId() {
      await this.reloadActivitySheetContent();
    },
    isInReviewMode() {
      this.reloadActivitySheetContent();
    },
    selectedStudentId: {
      handler() {
        this.loadSelectedStudentResponses();
      },
    },
    annotations: {
      handler() {
        const renderedIds = Object.keys(this.renderedHighlights);
        const newIds = this.activeAnnotationsIds.filter(id => !renderedIds.includes(id));
        const deletedIds = renderedIds.filter(id => !this.activeAnnotationsIds.includes(id));
        this.removeHighlightsFromHtml(deletedIds);
        const newHighlights = this.generateHighlightInfos(newIds);
        this.showHighlightsInHtml(newHighlights);

        if (this.$route.query.note && this.intialScrollToHighlight) {
          this.scrollToHighlight(this.$route.query.note);
          this.intialScrollToHighlight = false;
        }
      },
      deep: true,
    },
    lastSoftDeletedId(id) {
      const annotation = this.annotations[id];
      // On the teacher book this method can be called for different sn ids
      if (!id || annotation.subjectNodeId !== this.nodeId) {
        return;
      }
      if (annotation) {
        this.showDeleteToaster(annotation);
      }
    },
  },
  async created() {
    this.initHighlightMixin(this.addNewHighlight);

    this.unsubscribe = this.routeEvent.register(() => {
      return new Promise((resolve, reject) => {
        if (!this.isSubmissionInProcess) {
          resolve();
          return;
        }
        // eslint-disable-next-line no-alert
        const answer = window.confirm(
          'Your changes are currently being saved, do you still want to leave?',
        );

        if (answer) {
          this.deletePendingImageUploadResponses();
          resolve();
          return;
        }

        reject();
      });
    });
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('unload', this.handleUnload);
    this.setTrackingContext({
      feature: FEATURES_ENUM.ACTIVITY_SHEET,
      feature_id: this.featureId,
    });
    this.fetchReadingAssignmentForSubjectNode(this.nodeId);
    await this.reloadActivitySheetContent();
    await this.fetchAnnotations(this.nodeId);

    if (this.$route.query?.note) {
      this.scrollToHighlight(this.$route.query.note);
    }

    this.isShowAnswerKeyEnabled = await this.$featureFlags.showActivitySheetAnswerKeys(
      this.subjectId,
    );
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('unload', this.handleUnload);
  },
  methods: {
    ...mapVuexMutations({
      setImmersiveReaderContent: 'bookModule/setImmersiveReaderContent',
    }),
    ...mapWaitingPiniaActions(useActivitySheetStore, {
      fetchActivitySheet: {
        action: 'fetchActivitySheet',
        loader: 'loading_activity_sheet',
      },
    }),
    ...mapPiniaActions(useActivitySheetStore, [
      'fetchOwnOccasion',
      'fetchOwnResponses',
      'fetchClassOccasions',
      'fetchResponses',
      'createOccasion',
      'submitOwnOccasion',
      'createOccasionResponse',
      'updateOccasionResponse',
      'deletePendingImageUploadResponses',
      'clearAllOccasionResponses',
    ]),
    ...mapPiniaActions(useAnnotationStore, {
      clearFocusedAnnotation: 'clearFocusedAnnotation',
      getNewAnnotationTemplate: 'getNewAnnotationTemplate',
      newAnnotation: 'startNewAnnotation',
      setFocusedAnnotationUrl: 'setFocusedAnnotationUrl',
      saveHighlight: 'saveHighlight',
      fetchAnnotations: 'fetchAnnotations',
      setFocusedAnnotationById: 'setFocusedAnnotationById',
      deleteAnnotation: 'deleteAnnotation',
      startEditing: 'startEditing',
      revertSoftDeleteAnnotation: 'revertSoftDeleteAnnotation',
      setFocusedAnnotation: 'setFocusedAnnotation',
    }),
    ...mapVuexActions('bookModule', ['openRailSlider']),
    contentType(item) {
      switch (item.block_type) {
        case BLOCK_TYPE.QUESTION: {
          return ActivityQuestion;
        }
        case BLOCK_TYPE.CUSTOM_EDITOR: {
          return ActivityCustomEditorBlock;
        }
        case BLOCK_TYPE.CONTENT_BOX: {
          return ActivityContentBoxBlock;
        }
        case BLOCK_TYPE.DIVIDER: {
          return ActivityDivider;
        }
        case BLOCK_TYPE.HEADING: {
          return ActivityHeadingBlock;
        }
        case BLOCK_TYPE.IMAGE: {
          return ActivityImageBlock;
        }
        default:
          throw new Error('Not supported content type', item.block_type);
      }
    },
    isShowingKeyForQuestion(item) {
      if (item.block_type !== BLOCK_TYPE.QUESTION || !this.isShowAnswerKeyEnabled) {
        return false;
      }

      return this.isInReviewMode && isLTQ(item.question);
    },
    async reloadActivitySheetContent() {
      await this.fetchActivitySheet(this.featureId);
      if (this.activitySheet) {
        if (this.isInTakeActivitySheetMode) {
          await this.fetchOwnOccasion({
            subjectClassId: this.classId,
            showTeacherOccasion: this.isTeacher,
          });
          await this.fetchOwnResponses();
        } else if (this.isInViewSubmissionMode) {
          await this.fetchClassOccasions({
            activitySheetId: this.activitySheet.id,
            subjectClassId: this.classId,
          });
          this.loadSelectedStudentResponses();
        }
      }

      const content = this.$refs.atsContent.innerHTML;
      this.setImmersiveReaderContent(content);
    },
    loadSelectedStudentResponses() {
      this.clearAllOccasionResponses();
      if (!this.selectedStudentId || !this.classOccasions || !this.activitySheet) {
        return;
      }

      const selectedStudentOccasion = this.classOccasions.find(
        occasion => occasion.user_id === this.selectedStudentId,
      );

      if (selectedStudentOccasion) {
        this.fetchResponses({
          occasionId: selectedStudentOccasion.id,
        });
      }
    },
    async handleUserResponse({ response, questionId }) {
      if (!this.ownOccasion && !this.isCreatingOccasion) {
        this.createOccasion(this.classId);
        this.trackActivitySheetStart();
      }
      const existingResponse = this.occasionResponses[questionId];

      if (existingResponse) {
        await this.updateOccasionResponse({ question_id: questionId, ...response });
      } else {
        await this.createOccasionResponse({ question_id: questionId, ...response });
      }

      this.handleResponseSaved(response);
    },
    handleResponseSaved(response) {
      if (typeof response?.saveCompleteCallback === 'function') {
        response.saveCompleteCallback();
      }
    },
    trySendActivitySheet() {
      const { title, text } = this.getSubmissionConfirmationDialogText(
        'activity sheet',
        this.isAllQuestionsAnswered,
      );

      this.$modal(KogConfirmModal, {
        title,
        text,
        buttonTitle: 'Yes, submit',
        confirmButtonStyle: 'accent',
        cancelButtonStyle: 'basic',
        onConfirm: this.submitActivitySheet,
      });
    },
    async submitActivitySheet() {
      try {
        this.$wait.start('submitting-activity-sheet');

        if (!this.ownOccasion) {
          await this.createOccasion(this.classId);
        }

        await this.registerNodeProgress(this.nodeId, true);
        await this.submitOwnOccasion();
        this.trackActivitySheetSubmit();
        if (this.isInReadingAssignment) {
          this.showReadingAssignmentModal(this.nodeId);
        }
      } catch {
        this.$toast.showError(
          'Failed to change completion status. Please refresh the page and try again.',
        );
      } finally {
        this.$wait.end('submitting-activity-sheet');
      }
    },
    trackActivitySheetSubmit() {
      this.$event.track('submit_activity_sheet', {
        subject_id: this.subject.id,
        subject_class_id: this.classId,
        subject_node_id: this.nodeId,
        activity_sheet_id: this.activitySheet.id,
        activity_sheet_occasion_id: this.ownOccasion.id,
      });

      const answeredQuestionIds = Object.values(this.occasionResponses).map(
        response => response.question_id,
      );
      if (answeredQuestionIds.length === 0) {
        this.trackActivitySheetStart();
      }

      this.$mixpanel.trackEventViaBackend('Activity Sheet - Submit', {
        activity_sheet_id: this.activitySheet.id,
        answered_questions_count: answeredQuestionIds.length,
        assignment_ids: this.assignmentIds,
        subject_node_id: this.nodeId,
        questions_count: this.contentQuestionIds.length,
        subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNode.name}`,
        source: this.$route.name === 'classReadingAssignmentTake' ? 'assignment' : 'book',
      });
    },
    trackActivitySheetStart() {
      this.$mixpanel.trackEventViaBackend('Activity Sheet - Start', {
        activity_sheet_id: this.activitySheet.id,
        assignment_ids: this.assignmentIds,
        subject_node_id: this.nodeId,
        questions_count: this.contentQuestionIds.length,
      });
    },
    handleBeforeUnload(event) {
      const softDeletedCount = Object.values(this.annotations).filter(a => a.isSoftDeleted).length;

      if (this.isSubmissionInProcess || softDeletedCount) {
        // Gecko + IE
        // eslint-disable-next-line no-param-reassign
        event.returnValue = false;

        // Safari, Chrome, and other WebKit-derived browsers
        event.preventDefault();
      }

      if (softDeletedCount) {
        this.trackUnloadWithSoftDeleted();
      }
    },
    handleUnload() {
      if (this.isSubmissionInProcess) {
        const useBeacon = true;
        this.deletePendingImageUploadResponses(useBeacon);
      }
    },
    async addNewHighlight(color, textQuote, locationHint) {
      this.clearFocusedAnnotation();
      const annotationObj = await this.createNewAnnotation(textQuote, locationHint, color);
      const autoOpenNotebook = studyAppLocalStorage.getAutoOpenNotebook();
      if (!this.bookRailSliders.notebook && (isNil(autoOpenNotebook) || autoOpenNotebook)) {
        this.openRailSlider('notebook');
      }
      this.saveHighlight().then(() => {
        this.startEditing();
      });
      trackSaveNote(annotationObj, { source: 'textbook' });
    },
    async createNewAnnotation(textQuote, locationHint, color = DEFAULT_HIGHLIGHT_COLOR) {
      const annotationObj = await this.getNewAnnotationTemplate();
      Object.assign(annotationObj.annotation, textQuote);
      annotationObj.subjectNodeId = this.nodeId;
      annotationObj.location = this.getLocation();
      annotationObj.annotation.color = color;
      annotationObj.annotation.location_hint = locationHint;
      this.newAnnotation(annotationObj);
      this.setFocusedAnnotationUrl(this.subjectNode.url);
      return annotationObj;
    },
    getLocation() {
      return {
        subject_name: this.subjectName,
        subject_node_name: this.subjectNodeName,
      };
    },
    generateHighlightInfos(annotationIds) {
      return annotationIds.map(id => {
        const annotation = this.annotations[id];
        return {
          id: annotation.annotation.id,
          location: annotation.annotation,
          clickHandler: this.annotationClickHandler,
          object: annotation,
        };
      });
    },
    annotationClickHandler(event, context) {
      if (this.isSaving) {
        return;
      }

      const annotationId = context.customObject.annotation.id;

      if (!this.bookRailSliders.notebook) {
        this.openRailSlider('notebook');
      }
      this.clearFocusedAnnotation();
      this.setFocusedAnnotationById(annotationId);
      this.startEditing();
    },
    showDeleteToaster(annotation) {
      const isNote = annotation.annotation.note.length > 0;
      const toasterHtml = isNote ? 'Note deleted' : 'Highlight deleted';
      const postActionText = isNote ? 'Note restored!' : 'Highlight restored!';
      this.$toast.showSuccess(toasterHtml, {
        postActionText,
        actionText: 'Undo',
        time: 10000,
        toasterActionPerformed: () => {
          this.restoreDeletedHighlight(annotation);
        },
        toasterClosedNoActionPressed: () => {
          const { id } = annotation.annotation;
          this.deleteAnnotation(id);
          trackDeleteNote({ ...annotation, location: this.getLocation() }, { source: 'textbook' });
        },
      });
    },
    restoreDeletedHighlight(annotation) {
      this.revertSoftDeleteAnnotation(annotation.annotation.id);
      this.setFocusedAnnotation(annotation);
    },
    trackUnloadWithSoftDeleted() {
      this.$mixpanel.trackEvent('Private Annotations - Unload before delete', {
        ...this.getLocation(),
        source: 'textbook',
      });
    },
  },
});
</script>

<style>
/* stylelint-disable selector-combinator-space-before */

/* stylelint-disable indentation */

.ActivitySheet-wrapper {
  counter-reset: divider;
  min-height: 100vh;
  word-wrap: break-word;
}

.ActivitySheet-wrapper img {
  height: auto;
}

.ActivitySheet-content {
  font-family: var(--kog-lato);
}
</style>

<style scoped>
.ActivitySheetContainer.ActivitySheetContainer--sideBySide {
  display: grid;
  grid-template-areas:
    'activityHeader'
    'externalContent'
    'activitySheetContent';
}

.ActivitySheetContainer-activityHeader {
  grid-area: activityHeader;
}

.ActivitySheetContainer-externalContent {
  grid-area: externalContent;
  max-height: 100vh;
}

.ActivitySheetContainer-externalContent :deep(.content-editable) {
  height: 100%;
}

.ActivitySheetContainer-externalContent :deep(.content-editable p) {
  height: 100%;
  margin: 0;
}

.ActivitySheetContainer-externalContent :deep(.content-editable iframe) {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
}

.ActivitySheetContainer-activitySheetContentWrapper--external {
  position: relative;
  min-width: 400px;
  padding: var(--space-xxl) var(--space-l);
}

.ActivitySheetContainer-activitySheetContentWrapper--external :deep(.ActivityDivider) {
  margin-left: 0;
}

.ActivitySheetContainer--reviewMode .ActivitySheetContainer-activitySheetContentWrapper--external {
  overflow: visible;
  max-height: initial;
}

.ActivitySheetContainer--reviewMode.ActivitySheetContainer--sideBySide {
  padding-top: var(--space-xxl);
}

.u-isBookRailSliderOpen .ActivitySheetContainer-activitySheetContentWrapper--external {
  max-height: calc(100vh - var(--space-s) * 2);
}

.ActivitySheetContainer--external:not(
    .ActivitySheetContainer--reviewMode
  ).ActivitySheetContainer--sideBySide
  :deep(.ImageBlock-innerWrapper) {
  left: 0;
  width: 100%;
}

@media (--viewport-l) {
  .ActivitySheetContainer--sideBySide {
    padding: var(--space-m);
    padding-top: var(--space-xxl);
  }
  .ActivitySheetContainer-activitySheetContentWrapper--external {
    padding: 0;
  }

  .ActivitySheetContainer-activitySheetContentWrapper--external :deep(.ActivityDivider) {
    margin-left: -24px;
  }
}

@media (max-width: 1200px) {
  .ActivitySheetContainer-externalContent {
    margin: calc(var(--space-m) * -1);
    padding: var(--space-m);
    padding-bottom: 0;
    background-color: var(--kogPlatformWhite);
  }
}

@media (min-width: 1200px) {
  .ActivitySheetContainer--external:not(
      .ActivitySheetContainer--reviewMode
    ).ActivitySheetContainer--sideBySide {
    overflow-y: scroll;
    display: grid;
    grid-template-areas:
      'activityHeader externalContent'
      'activitySheetContent externalContent';
    grid-template-columns: minmax(400px, 1fr) 2fr;
  }

  .ActivitySheetContainer-activityHeader {
    display: grid !important;
  }

  .ActivitySheetContainer-activitySheetContentWrapper {
    grid-area: activitySheetContent;
  }

  .ActivitySheetContainer-activitySheetContentWrapper--external {
    overflow-y: scroll;
    max-height: 100vh;
  }

  .ActivitySheetContainer-activitySheetContentWrapper--external :deep(.ActivityDivider) {
    margin-left: -24px;
  }

  .ActivitySheetContainer--sideBySide .ActivitySheetContainer-activityHeader {
    padding: var(--space-xxl) var(--space-l);
  }
}
</style>
