import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { ClickObserver } from '@ckeditor/ckeditor5-engine';
import { toWidget, Widget } from '@ckeditor/ckeditor5-widget';

import { isClickInsideSelectedElement } from '../utils/commands.js';
import InsertExerciseQuestionCommand from './exercise-question-command.js';
import {
  exerciseQuestionHtmlTag,
  exerciseQuestionModelTag,
} from './exercise-question-constants.js';

export default class ExerciseQuestionEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineCommands();
  }

  defineSchema() {
    const { schema } = this.editor.model;

    schema.register('kogExerciseQuestionModel', {
      isBlock: true,
      isObject: true,
      allowIn: ['$clipboardHolder', 'exerciseBoxContent', 'reflectionBoxContent'],
      allowAttributes: ['questionId'],
    });
  }

  defineConverters() {
    const { editor } = this;
    const { conversion, config } = editor;
    const { questionRenderer } = config.get('exerciseQuestion');

    conversion.for('upcast').elementToElement({
      model: (viewElement, { writer }) => {
        // eslint-disable-next-line no-underscore-dangle
        viewElement._removeChildren();
        const questionId = viewElement.getAttribute('question-id');
        const modelItem = writer.createElement(exerciseQuestionModelTag, {
          questionId,
        });
        const questionData = this.getQuestionData(questionId);
        if (!questionData.question) {
          this.updateInvalidQuestion(questionId, modelItem);
        }

        return modelItem;
      },
      view: exerciseQuestionHtmlTag,
    });

    conversion.for('dataDowncast').elementToElement({
      model: exerciseQuestionModelTag,
      view: (modelItem, { writer }) => {
        const questionId = modelItem.getAttribute('questionId');
        const viewElement = writer.createContainerElement(exerciseQuestionHtmlTag, {
          'question-id': questionId,
        });
        return viewElement;
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: exerciseQuestionModelTag,
      view: (modelItem, { writer }) => {
        const questionId = modelItem.getAttribute('questionId');
        const viewElement = writer.createContainerElement(exerciseQuestionHtmlTag, {
          class: 'block margin-bottom-s margin-top-s',
        });
        const questionWrapper = writer.createRawElement(
          'div',
          { 'data-vnode-wrapper': true },
          domElement => {
            questionRenderer({
              props: {
                ...this.getQuestionData(questionId),
                questionId: questionId.toString(),
              },
              domElement,
            });
          },
        );

        writer.insert(writer.createPositionAt(viewElement, 0), questionWrapper);
        return toWidget(viewElement, writer);
      },
    });
  }

  getQuestionData(questionId) {
    const { config } = this.editor;
    const callbacks = config.get('exerciseQuestion.callbacks');
    const exerciseQuestions = callbacks.getExerciseQuestions();
    const qid = parseInt(questionId, 10);
    let question = exerciseQuestions.find(q => q.id === qid);

    if (question) {
      return {
        question,
        isValid: true,
      };
    }

    const invalidQuestions = callbacks.getInvalidExerciseQuestions();
    question = invalidQuestions.find(q => q.id === qid);

    return {
      question,
      isValid: false,
    };
  }

  async updateInvalidQuestion(questionId, modelItem) {
    const {
      editor: { config, editing },
    } = this;
    const callbacks = config.get('exerciseQuestion.callbacks');
    try {
      const invalidQuestion = await callbacks.fetchQuestionDetail(questionId);
      callbacks.addInvalidExerciseQuestion(invalidQuestion);
      editing.reconvertItem(modelItem);
    } catch {
      // Do nothing
    }
  }

  defineCommands() {
    const { editor } = this;
    const { document } = editor.model;
    const { view, mapper } = editor.editing;

    editor.commands.add('insertExerciseQuestion', new InsertExerciseQuestionCommand(editor));
    editor.commands.add('updateExerciseQuestion', new Command(editor));

    view.addObserver(ClickObserver);
    editor.listenTo(view.document, 'click', (_, data) => {
      if (isClickInsideSelectedElement(data, document, mapper, 'kogExerciseQuestionModel')) {
        editor.commands.get('updateExerciseQuestion').execute();
      }
    });
  }
}
