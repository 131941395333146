<template>
  <div
    class="BookCheckpointContainer"
    :class="{
      'BookCheckpointContainer--activity': isActivityPageTemplate,
    }"
  >
    <div class="padd-top-xl absoluteContainer">
      <section-question-container
        v-if="hasQuestions"
        :current-section-node="currentSectionNode"
        :question-count="sectionQuestionCount"
        @completed="$emit('completed')"
      />
      <div
        v-else
        class="padd-top-xl flexContainer flexContainer-column flexContainer-center"
      >
        <kog-button
          v-if="!isSectionCompleted"
          :disabled="isCompleteButtonDisabled"
          :is-loading="isLoading"
          :tooltip="completeButtonTooltip"
          :label="completeButtonText"
          :has-icon="!!completeButtonText"
          :icon-class="completeButtonIcon"
          :button-style="completeButtonStyle"
          class="margin-bottom-s BookCheckpointContainer-progress"
          @click="$emit('completed')"
        />
        <span
          v-else
          class="BookCheckpointContainer--completed BookCheckpointContainer-progress"
        >
          <kog-icon
            icon-class="fa-badge-check"
            size="s"
            class="BookCheckpointContainer--completedIcon padd-right-s"
          />
          {{ completionText }}
        </span>
        <kog-button
          v-if="!isNgssSubject && !isLastSectionInTheBook"
          class="margin-bottom-l BookCheckpointContainer-progress"
          label="Continue to next section"
          :tooltip="nextLeafNode.name"
          :disabled="!isSectionCompleted"
          button-style="primary"
          @click="navigateToNextPageRoute"
        />
      </div>
      <div
        v-if="isSectionCompleted && isResetButtonVisible"
        class="padd-top-m positionAbsolute positionAbsolute-topRight"
      >
        <kog-button
          v-if="!isActivityPageTemplate"
          :is-loading="isLoading"
          label="Reset"
          @click="$emit('reset')"
        />
        <kog-round-button
          v-else
          aria-label="Reset"
          icon-class="fa-undo"
          icon-style="solid"
          icon-size="m"
          type="default"
          @click="$emit('reset')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import SectionQuestionContainer from 'studyApp/components/section-text/section-question-container.vue';
import QuestionDifficultyMixin from 'studyApp/mixins/question-difficulty-mixin.js';

export default {
  name: 'BookCheckpointContainer',
  components: {
    KogIcon,
    KogRoundButton,
    SectionQuestionContainer,
    KogButton,
  },
  mixins: [QuestionDifficultyMixin, RoutesMixin],
  props: {
    currentSectionNode: {
      type: Object,
      required: true,
    },
    completeButtonText: {
      type: String,
      required: true,
    },
    completeButtonTooltip: {
      type: String,
      default: '',
    },
    completeButtonStyle: {
      type: String,
      default: 'primary',
    },
    completeButtonIcon: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCompleteButtonDisabled: {
      type: Boolean,
      default: false,
    },
    isResetButtonVisible: {
      type: Boolean,
      default: true,
    },
    isActivityPageTemplate: {
      type: Boolean,
      default: false,
    },
    completionText: {
      type: String,
      default: '',
    },
  },
  emits: ['reset', 'completed'],
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
      subjectNodesProgress: state => state.statisticModule.subjectNodesProgress,
      sectionQuestionCount: state => state.bookModule.sectionQuestionCount,
      level: state => state.subjectModule.level,
    }),
    ...mapGetters({
      sectionNodes: 'bookModule/sectionNodes',
      leafNodesWithContent: 'subjectModule/leafNodesWithContent',
    }),
    currentLeafNodeIndex() {
      return this.leafNodesWithContent.findIndex(ln => ln.id === this.nodeId);
    },
    nextLeafNode() {
      return this.leafNodesWithContent[this.currentLeafNodeIndex + 1] ?? null;
    },
    nextPageRoute() {
      if (this.nextLeafNode) {
        return {
          name: 'classBook',
          params: {
            classSlug: this.subjectClassSlug,
            sid: this.subjectId,
            cid: this.classId,
            nodeSlug: this.nextLeafNode.slug,
            nodeId: this.nextLeafNode.id,
          },
        };
      }
      return null;
    },
    isSectionCompleted() {
      return this.currentSectionStats.progress_stats?.is_completed;
    },
    isLastSectionInTheBook() {
      return !this.nextLeafNode;
    },
    currentSectionStats() {
      return this.subjectNodesProgress.stats_by_subjectnode?.[this.nodeId] || {};
    },
    hasQuestions() {
      return this.sectionQuestionCount > 0;
    },
    isNgssSubject() {
      return isNgss(this.subject.educationsystem.name);
    },
  },
  created() {
    this.fetchReadingAssignmentForSection(this.nodeId);
  },
  methods: {
    ...mapActions({
      fetchReadingAssignmentForSection: 'bookModule/fetchReadingAssignmentForSection',
    }),
    navigateToNextPageRoute() {
      return this.$router.push(this.nextPageRoute);
    },
  },
};
</script>

<style scoped>
.BookCheckpointContainer {
  padding-right: var(--space-l);
  padding-bottom: var(--space-l);
  padding-left: var(--space-l);

  font-family: var(--kog-lato);

  background-color: var(--kogPlatformGray093);
}

.BookCheckpointContainer--completed {
  display: flex;
  align-items: center;
  justify-content: center;

  height: var(--space-xxl);
  margin-bottom: var(--space-xs);

  background-color: var(--kogPlatformGreenLighten10);
  border-radius: 4px;
}

.BookCheckpointContainer-progress {
  width: 100%;
  max-width: 50vw;
}

.BookCheckpointContainer--activity {
  margin-bottom: 0;
  margin-left: calc(var(--space-s) * -1);
}

.BookCheckpointContainer--completedIcon {
  color: var(--kog-colors-green-700);
}

@media (--viewport-s) {
  .BookCheckpointContainer-progress {
    max-width: unset;
  }
}
</style>
