<template>
  <kog-new-navbar
    :right-slot-expanded="false"
    :search-expanded="false"
    role="banner"
  >
    <template #leftCornerSlot>
      <div class="flexContainer flexContainer-center width-100 flexContainer-spaceBetween">
        <div class="flexContainer">
          <div
            class="FullpageTakeoverFocusNavbar-backButtonContainer divider-right flexContainer flexContainer-alignCenter margin-right-s padd-right-s"
          >
            <kog-round-button
              :aria-label="backButtonAriaLabel"
              icon-class="fa-chevron-left"
              size="medium"
              @click="$emit('back-button-click')"
            />
          </div>
          <div class="heading-xxs font-weight-medium flexContainer flexContainer-alignCenter">
            <div class="FullpageTakeoverFocusNavbar-headerText text-ellipsis">{{ headerText }}</div>
            <span> / </span>
            <router-link
              :to="headerLinkRoute"
              class="FullpageTakeoverFocusNavbar-headerLink font-weight-bold"
            >
              {{ headerLinkText }}
            </router-link>
          </div>
        </div>
        <kog-round-button
          :aria-label="closeButtonAriaLabel"
          icon-class="fa-close"
          size="medium"
          button-style="inverted"
          @click="$emit('close-button-click')"
        />
      </div>
    </template>
  </kog-new-navbar>
</template>

<script>
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogNewNavbar from 'sharedApp/components/core/kog-new-navbar.vue';

export default {
  name: 'FullpageTakeoverFocusNavbar',
  components: {
    KogRoundButton,
    KogNewNavbar,
  },
  props: {
    closeButtonAriaLabel: {
      type: String,
      required: true,
    },
    backButtonAriaLabel: {
      type: String,
      required: true,
    },
    headerText: {
      type: String,
      required: true,
    },
    headerLinkText: {
      type: String,
      required: true,
    },
    headerLinkRoute: {
      type: Object,
      required: true,
    },
  },
  emits: ['close-button-click', 'back-button-click'],
};
</script>

<style scoped>
.FullpageTakeoverFocusNavbar-backButtonContainer {
  height: var(--full-page-navbar-height);
}

.FullpageTakeoverFocusNavbar-headerLink {
  color: var(--kog-navigation-button-default);
}

.FullpageTakeoverFocusNavbar-headerText {
  max-width: 350px;
}
</style>
