<template>
  <div
    class="ActivityDivider font-meta-serif-pro"
    :class="classAttr"
    :style="styleAttr"
  ></div>
</template>

<script>
import useStyleClassExtractedAttrs from 'sharedApp/composables/use-style-class-extracted-attrs.ts';

export default {
  name: 'ActivityDivider',
  inheritAttrs: false,
  setup() {
    const extractedAttrs = useStyleClassExtractedAttrs();

    return {
      ...extractedAttrs,
    };
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .ActivityDivider {
    margin-left: -140px;
  }
}

.ActivityDivider {
  position: relative;

  width: 118px;
  height: 48px;
  margin-bottom: var(--space-l);
  padding-top: 0;

  background: url('~sharedApp/img/divider.svg');
}

.ActivityDivider::before {
  content: counter(divider, upper-alpha);
  counter-increment: divider;

  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: inherit;
  height: inherit;

  font-size: 40px;
  font-weight: 700;
  line-height: 68px;
  color: var(--kog-colors-aubergine-700);
}
</style>
