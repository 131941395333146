export default function AddCustomCssToTables(editor) {
  editor.conversion.for('downcast').add(dispatcher =>
    dispatcher.on(
      'insert:table',
      (evt, data, conversionApi) => {
        const figureElement = conversionApi.mapper.toViewElement(data.item);
        if (figureElement) {
          conversionApi.writer.addClass('figure-with-table', figureElement);
          const tableElement = [...figureElement.getChildren()].find(e => e.name === 'table');
          if (tableElement) {
            conversionApi.writer.addClass(
              'table table-bordered table-content-list table-striped',
              tableElement,
            );
          }
        }
      },
      { priority: 'lowest' },
    ),
  );
}
