<template>
  <kog-modal :title="modalTitleText">
    <template #modalBody>
      <p>{{ modalBodyText }}</p>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          class="KogButtonLegacy"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          class="KogButtonLegacy KogButtonLegacy--primary"
          @click="removeThisTeacher"
        >
          {{ modalButtonText }}
        </button>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'KogRemoveClassTeacherModal',
  components: { KogModal },
  props: {
    removeTeacher: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      default: null,
    },
    teacherName: {
      type: String,
      required: true,
    },
    teacherId: {
      type: Number,
      required: true,
    },
    deletingSelf: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    modalTitleText() {
      const titleRemovingSelf = 'Leave class as teacher';
      const titleRemovingOtherTeacher = 'Remove teacher from class';
      return this.deletingSelf ? titleRemovingSelf : titleRemovingOtherTeacher;
    },
    modalBodyText() {
      const bodyRemovingSelf = `
        This action will remove you from the class so you will not be able to access it.
        You can re-join it via the home page at any time.
      `;
      const bodyRemovingOtherTeacher = `
        This action will remove ${this.teacherName} from the class so they will not be able to access it.
        They can be re-added at any time or join it via their home page.
      `;
      return this.deletingSelf ? bodyRemovingSelf : bodyRemovingOtherTeacher;
    },
    modalButtonText() {
      const buttonRemovingSelf = 'Leave class';
      const buttonRemovingOtherTeacher = 'Remove teacher';
      return this.deletingSelf ? buttonRemovingSelf : buttonRemovingOtherTeacher;
    },
  },
  methods: {
    removeThisTeacher() {
      this.removeTeacher(this.teacherId, this.teacherName);
      this.closeModal();
    },
  },
};
</script>
