import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { ClickObserver } from '@ckeditor/ckeditor5-engine';
import { toWidget, Widget } from '@ckeditor/ckeditor5-widget';

import { isClickInsideSelectedElement } from '../utils/commands.js';
import GlossaryLinkCommand from './glossary-link-command.js';

export default class GlossaryLinkEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineCommands();
  }

  defineSchema() {
    const { editor } = this;
    const { schema } = editor.model;

    schema.register('glossaryLink', {
      inheritAllFrom: '$inlineObject',
      allowAttributes: ['termId', 'definitionId', 'linkedText'],
      allowChildren: '$text',
    });
  }

  defineConverters() {
    const { conversion } = this.editor;

    conversion.for('upcast').elementToElement({
      model: 'glossaryLink',
      view: {
        name: 'glossary-link',
        classes: 'CKEditorKognityGlossaryLink',
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: 'glossaryLink',
      view: (modelItem, { writer }) => {
        const viewElement = writer.createContainerElement('glossary-link', {
          class: 'CKEditorKognityGlossaryLink',
        });
        if (modelItem.childCount === 0) {
          const linkedText = writer.createText(modelItem.getAttribute('linkedText'));
          writer.insert(writer.createPositionAt(viewElement, 'end'), linkedText);
        }
        return viewElement;
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: 'glossaryLink',
      view: (modelItem, { writer }) => {
        const viewElement = writer.createContainerElement('glossary-link', {
          class: 'CKEditorKognityGlossaryLink',
        });
        if (modelItem.childCount === 0) {
          const linkedText = writer.createText(modelItem.getAttribute('linkedText'));
          writer.insert(writer.createPositionAt(viewElement, 'end'), linkedText);
        }
        return toWidget(viewElement, writer);
      },
    });

    conversion.attributeToAttribute({
      model: {
        name: 'glossaryLink',
        key: 'termId',
      },
      view: 'term-id',
    });
    conversion.attributeToAttribute({
      model: {
        name: 'glossaryLink',
        key: 'definitionId',
      },
      view: 'definition-id',
    });
    conversion.attributeToAttribute({
      model: {
        name: 'glossaryLink',
        key: 'linkedText',
      },
      view: 'linked-text',
    });
  }

  defineCommands() {
    const { editor } = this;
    const { document } = editor.model;
    const { view, mapper } = editor.editing;

    editor.commands.add('updateGlossaryLink', new Command(editor));
    editor.commands.add('insertGlossaryLink', new GlossaryLinkCommand(editor));

    view.addObserver(ClickObserver);
    editor.listenTo(view.document, 'click', (_, data) => {
      if (isClickInsideSelectedElement(data, document, mapper, 'glossaryLink')) {
        editor.commands.get('updateGlossaryLink').execute();
      }
    });
  }
}
