export const setChoiceItemState = (choiceState, id, state) => ({
  ...choiceState,
  [id]: {
    ...choiceState[id],
    ...state,
  },
});

export const getSelectedChoiceIds = choiceState =>
  Object.entries(choiceState)
    .filter(([key, state]) => state.selected)
    .map(([key]) => key);

export const MCQ_DISPLAY_STATE = Object.freeze({
  ALL: 'all',
  QUESTION: 'question',
  CHOICES: 'choices',
});
export const MCQ_TYPE_OPTIONS = Object.freeze(['normal', 'solution', 'outline']);
