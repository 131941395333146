<template>
  <div class="flexContainer flexContainer-alignCenter">
    <div
      v-for="(section, index) in scaleSections"
      :key="index"
      class="PerformanceScale"
    >
      <div class="PerformanceScale-sectionColorbox">
        <scale-section
          :scale="scale"
          :size="section.size"
          :scale-percentage="section.percentage"
        />
      </div>
      <div class="text-center text-nowrap">
        {{ section.title }}
      </div>
    </div>
  </div>
</template>

<script>
import ScaleSection from 'teachApp/components/scale-section.vue';
import { getScaleForCurriculum } from 'teachApp/utils/heatmap-scales.js';

export default {
  name: 'PerformanceScale',
  components: {
    ScaleSection,
  },
  props: {
    scale: {
      type: String,
      default: null,
    },
    origin: {
      type: String,
      default: 'insights',
    },
  },
  computed: {
    explanatoryIntervals() {
      const intervals = [
        {
          title: 'No activity',
          percentage: -1,
          size: 80,
        },
      ];

      if (this.origin !== 'insights') {
        intervals.unshift({
          title: 'No questions',
          percentage: -2,
          size: 80,
        });
      }

      return intervals;
    },
    scaleSections() {
      const curriculumScale = getScaleForCurriculum(this.scale);
      const scaleSections = [...this.explanatoryIntervals];
      curriculumScale.intervals.forEach(interval => {
        const halfDiff = (interval.max - interval.min) / 2;
        const middleValue = interval.min + halfDiff;
        scaleSections.push({
          title: `${interval.min} - ${interval.max}%`,
          percentage: middleValue,
          size: interval.size
            ? interval.size
            : this.percentageSizeToPixel(interval.max - interval.min),
        });
      });
      return scaleSections;
    },
  },
  methods: {
    percentageSizeToPixel(percentage) {
      const smallIntervalSize = 50;
      const mediumIntervalSize = 65;
      const bigIntervalSize = 80;

      if (!percentage || percentage <= 10) {
        return smallIntervalSize;
      }

      if (percentage < 19 && percentage > 10) {
        return mediumIntervalSize;
      }

      return bigIntervalSize;
    },
  },
};
</script>

<style scoped>
.PerformanceScale {
  font-size: 11px;
}

.PerformanceScale-sectionColorbox {
  height: 21px;
}
</style>
