import { ContextualBalloon } from '@ckeditor/ckeditor5-ui';

import bookOpenIcon from 'publishingApp/assets/icons/book-open-regular.svg';

import KogUI from '../utils/kogui.ts';
import { registerFactoryView } from '../utils/plugin-utils.ts';
import FormView from './glossary-link-form.js';
import getRangeText from './utils.js';

export default class GlossaryLinkUI extends KogUI {
  static get requires() {
    return [ContextualBalloon];
  }

  init() {
    this.balloon = this.editor.plugins.get(ContextualBalloon);
    this.formView = null;
    this.callbacks = this.editor.config.get('glossarylink.callbacks');
    const updateCommand = this.editor.commands.get('updateGlossaryLink');

    registerFactoryView(this.editor, {
      name: 'glossarylink',
      commandName: 'insertGlossaryLink',
      icon: bookOpenIcon,
      label: 'Glossary link',
      onExecute: () => this.triggerShowUI(this.getFormData.bind(this)),
    });

    this.listenTo(updateCommand, 'execute', evt => {
      // eslint-disable-next-line no-param-reassign
      evt.return = this.triggerShowUI(this.getFormData.bind(this));
    });
  }

  createFormView() {
    this.formView = new FormView(this.editor.locale, this.definitions);

    const onSubmit = () => {
      const value = {
        linkedText: this.formView.textInputView.fieldView.element.value,
        termId: this.formView.selectedTermId,
        definitionId: this.formView.selectedDefinitionId,
      };
      this.editor.execute('insertGlossaryLink', value);
      this.hideUI();
    };

    this.setupFormView(onSubmit);
  }

  async getFormData() {
    if (!this.definitions) {
      this.definitions = (await this.callbacks.getGlossaryDefinitionsBySubject()) ?? [];
    }
  }

  showUI() {
    if (!this.formView) {
      this.createFormView();
    }
    const { selection } = this.editor.model.document;
    const selectedText = getRangeText(selection.getFirstRange());
    const commandValue = this.editor.commands.get('insertGlossaryLink').value;
    const { linkedText, termId, definitionId } = commandValue || {};

    this.formView.setFields({
      linkedText: selectedText || linkedText,
      termId,
      definitionId,
    });

    super.showUI();
  }
}
