import MenuItem from 'studyApp/menus/menu-item.js';

export default class OverviewMenuItem extends MenuItem {
  constructor(config) {
    const defaults = {
      key: 'overview',
      displayText: 'Overview',
      iconClassActive: 'fas fa-home',
      iconClassInactive: 'fal fa-home',
    };
    super({ ...defaults, ...config });
  }

  navigateTo({ router, currentRoute }) {
    const params = {
      classSlug: currentRoute.params.classSlug,
      cid: currentRoute.params.cid,
      sid: currentRoute.params.sid,
    };
    this.goToRoute(router, currentRoute, 'subjectClassOverview', params);
  }
}
