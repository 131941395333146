const styles = getComputedStyle(document.documentElement);

function getStyleByName(constant) {
  return styles.getPropertyValue(constant).replace(' ', '');
}

export default {
  PLATFORM_GRAY_096: getStyleByName('--kogPlatformGray096'),
  PLATFORM_GRAY_093: getStyleByName('--kogPlatformGray093'),
  PLATFORM_GRAY_018: getStyleByName('--kogPlatformGray018'),

  CONTENT_RED_LIGHT_5: getStyleByName('--kog-content-red-light-5'),
  CONTENT_GREEN_LIGHT_10: getStyleByName('--kog-content-green-light-10'),
  CONTENT_YELLOW_LIGHT_2: getStyleByName('--kog-content-yellow-light-2'),
  CONTENT_PURPLE_LIGHT_4: getStyleByName('--kog-content-purple-light-4'),
  CONTENT_GREEN_LIGHT_11: getStyleByName('--kog-content-green-light-11'),

  AUBERGINE_30: getStyleByName('--kog-colors-aubergine-300'),
  YELLOW_30: getStyleByName('--kog-colors-yellow-300'),
  GREEN_30: getStyleByName('--kog-colors-green-300'),
  ORANGE_30: getStyleByName('--kog-colors-orange-300'),
  GRAY_30: getStyleByName('--kog-colors-grey-300'),
  BLUE_30: getStyleByName('--kog-colors-blue-300'),
  YELLOW_10: getStyleByName('--kog-colors-yellow-100'),
};
