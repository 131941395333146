<template>
  <div>
    <fullpage-takeover-focus-navbar
      close-button-aria-label="Click to go back to last page"
      back-button-aria-label="Click to go back to the class overview"
      :header-text="subjectClassName"
      :header-link-text="assignmentName"
      :header-link-route="focusLandingRoute"
      @back-button-click="navigateBack"
      @close-button-click="navigateToPreviousRoute"
    />
    <kog-loader
      v-if="$wait.is('loading-reading-assignment-focus/*')"
      class="padd-top-xxxl"
      :loading="$wait.is('loading-reading-assignment-focus/*')"
      loading-msg="Loading the assignment..."
    />
    <div
      v-else
      class="ReadingAssignmentFocus padd-top-xxxl"
    >
      <book-rail
        class="padd-top-xxxl"
        :is-collapsed="isBookRailCollapsed"
        :tracking-props="trackingPropsForBookRail"
        @collapse="isBookRailCollapsed = true"
        @expand="isBookRailCollapsed = false"
      />
      <div
        :class="{
          'ReadingAssignmentFocus-content--bookRail': !isBookRailCollapsed,
          'ReadingAssignmentFocus-content--collapsedBookRail': isBookRailCollapsed,
        }"
        class="ReadingAssignmentFocus-content"
      >
        <div class="kog-container-fluid">
          <div class="kog-row">
            <book-rail-slider
              class="ReadingAssignmentFocus-bookRailSlider"
              :node-id="nodeId"
            />
            <div
              class="positionRelative ReadingAssignmentFocus-mainPage flexContainer flexContainer-column flexContainer-alignCenter"
              :class="{
                'ReadingAssignmentFocus-mainPage--railSliderOpen': isBookRailSliderOpen,
                'ReadingAssignmentFocus-mainPage--railSliderClosed': !isBookRailSliderOpen,
              }"
            >
              <reading-assignment-focus-landing
                v-if="!nodeId"
                @reload-assignment="fetchAssignment"
              />
              <div
                v-else
                class="width-full"
              >
                <feature-text v-if="isCurrentNodeActivity" />
                <section-text
                  v-else
                  :is-in-review-mode="isReviewMode"
                />
                <assignment-checkpoint-container
                  v-if="isReviewMode"
                  :current-section-node="currentSubjectNode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="isBookRailSliderOpen"
          v-kog-clickable="closeRailSlider"
          class="ReadingAssignmentFocus-backdrop"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { mapWaitingActions, waitFor } from 'vue-wait';
import { mapActions, mapGetters, mapState, useStore } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';

import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import FullpageTakeoverFocusNavbar from 'sharedApp/components/core/fullpage-takeover-focus-navbar.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import BookRailSlider from 'studyApp/components/side-navigation/book-rail-slider.vue';
import BookRail from 'studyApp/components/side-navigation/book-rail.vue';
import AssignmentCheckpointContainer from 'studyApp/pages/assignment/components/assignment-checkpoint-container.vue';
import ReadingAssignmentFocusLanding from 'studyApp/pages/assignment/components/reading-assignment-focus-landing.vue';
import FeatureText from 'studyApp/pages/features/feature-text.vue';
import SectionText from 'studyApp/pages/section-text.vue';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const PAGE_NAVIGATION_HEIGHT = 80;
const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default {
  name: 'ReadingAssignmentFocus',
  components: {
    AssignmentCheckpointContainer,
    SectionText,
    FeatureText,
    KogLoader,
    ReadingAssignmentFocusLanding,
    FullpageTakeoverFocusNavbar,
    BookRail,
    BookRailSlider,
  },
  mixins: [RoutesMixin],
  beforeRouteEnter(to, from, next) {
    next(() => {
      if (from.name !== 'teacherAssignmentDetails') {
        studyAppLocalStorage.setLastUrl(from.href);
      }
    });
  },
  setup() {
    const store = useStore();
    const { assignmentModule, subjectModule, userModule } = store.state;
    const assignmentStore = useAssignmentStore();
    const getTeacherAssignment = waitFor(
      'loading-reading-assignment-focus/teacher-assignment',
      assignmentStore.fetchAssignment,
    );
    const { user } = userModule;

    const teacherAssignment = storeToRefs(assignmentStore).assignment;
    const studentAssignment = computed(() => assignmentModule.assignment);
    const assignment = user.isTeacher() ? teacherAssignment : studentAssignment;

    const assignmentName = computed(() => assignment.value?.name ?? '');
    const subjectName = computed(() => subjectModule.subject?.name);

    useHead({
      title: () => `${assignmentName.value} | ${subjectName.value}`,
    });

    return {
      assignment,
      getTeacherAssignment,
      assignmentName,
      user,
    };
  },
  data() {
    return {
      isBookRailCollapsed: false,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.subjectModule.subject,
      subjectClasses: state => state.subjectClassModule.subjectClasses,
    }),
    ...mapGetters({
      isBookRailSliderOpen: 'bookModule/isBookRailSliderOpen',
      subjectNodesById: 'subjectModule/subjectNodesById',
      activityBySubjectNodeId: 'subjectNodeFeatureModule/activityBySubjectNodeId',
    }),
    isCurrentNodeActivity() {
      return !!this.activityBySubjectNodeId[this.nodeId];
    },
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    assignmentSectionNodeIds() {
      return this.assignment?.sections?.map(section => section.subject_node_id);
    },
    subjectClass() {
      return this.subjectClasses.find(sc => sc.id === this.classId);
    },
    subjectClassName() {
      return this.subjectClass.name;
    },
    currentSubjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    currentSubjectNodeFullName() {
      const nodePathNumber = this.currentSubjectNode?.formatted_number_including_ancestors;
      const nodeName = this.currentSubjectNode?.name;
      if (nodePathNumber && nodeName) {
        return `${nodePathNumber} ${nodeName}`;
      }
      return null;
    },
    trackingPropsForBookRail() {
      return {
        subject_id: this.subject.id,
        subject_class_id: this.classId,
        subject_node_id: this.nodeId,
        subject_node_name: `${this.currentSubjectNodeFullName}`,
        source: 'assignment',
      };
    },
    trackingPropsAssignmentClose() {
      return this.user.isStudent()
        ? {}
        : { num_subject_classes: this.assignment.subject_classes.length };
    },
    trackingPropsAssignmentOpen() {
      const trackingProps = {
        assignment_type: this.assignment?.assignment_type.toLowerCase(),
        source: this.$route.query.source,
      };

      if (!this.user.isStudent()) {
        trackingProps.num_subject_classes = this.assignment.subject_classes.length;
      }

      return trackingProps;
    },
    focusLandingRoute() {
      return {
        name: this.isReviewMode ? 'teacherAssignmentDetails' : 'classReadingAssignmentTake',
        params: {
          assignmentId: this.assignmentId,
          classSlug: this.subjectClass.slug,
          sid: `${this.subject.id}`,
          cid: `${this.classId}`,
        },
      };
    },
    isReviewMode() {
      return this.$route.meta.isReviewMode;
    },
  },
  watch: {
    nodeId: {
      handler(newNodeId, oldNodeId) {
        if (this.user.isTeacher() && newNodeId !== oldNodeId) {
          this.fetchSubtopicStatistics({
            subjectId: this.subjectId,
            subTopicId: newNodeId,
          });
        }
      },
      immediate: true,
    },
  },
  async created() {
    await this.fetchAssignment();
    if (this.user.isStudent()) {
      this.fetchNodeProgress(this.assignmentId);
      this.trackReadingAssignmentOpen();
    }
    this.fetchFeaturesAndMappings({ subjectNodeIds: this.assignmentSectionNodeIds });
  },
  mounted() {
    intercomModule.updateLauncherVerticalPadding([
      {
        condition: true,
        height: PAGE_NAVIGATION_HEIGHT,
      },
    ]);
  },
  methods: {
    ...mapActions({
      closeRailSlider: 'bookModule/closeRailSlider',
      fetchSubtopicStatistics: 'inlineStatisticsModule/fetchSubtopicStatistics',
    }),
    ...mapWaitingActions({
      fetchNodeProgress: {
        action: 'assignmentModule/fetchNodeProgress',
        loader: 'loading-reading-assignment-focus/progress',
      },
      getStudentAssignment: {
        action: 'assignmentModule/getAssignment',
        loader: 'loading-reading-assignment-focus/student-assignment',
      },
      fetchFeaturesAndMappings: {
        action: 'subjectNodeFeatureModule/fetchFeaturesAndMappings',
        loader: 'loading-reading-assignment-focus/features',
      },
    }),
    navigateToPreviousRoute() {
      const previousRoute = studyAppLocalStorage.getLastUrl();
      if (previousRoute) {
        this.$mixpanel.trackEvent('Assignment - Close', this.trackingPropsAssignmentClose);
        window.location.href = previousRoute;
      } else {
        this.navigateToClassOverview();
      }
    },
    navigateToClassOverview() {
      this.$mixpanel.trackEvent('Assignment - Close', this.trackingPropsAssignmentClose);
      this.$router.push({
        name: 'subjectClassOverview',
        params: {
          classSlug: this.$route.params.classSlug,
          sid: this.$route.params.sid,
          cid: this.$route.params.cid,
        },
      });
    },
    navigateBack() {
      if (this.nodeId) {
        this.$router.push(this.focusLandingRoute);
      } else {
        this.navigateToPreviousRoute();
      }
    },
    trackReadingAssignmentOpen() {
      this.$mixpanel.trackEvent('Assignment - Open', this.trackingPropsAssignmentOpen);
    },
    async fetchAssignment() {
      if (this.user.isStudent()) {
        await this.getStudentAssignment(this.assignmentId);
      } else {
        await this.getTeacherAssignment(this.assignmentId);
      }
    },
  },
};
</script>

<style scoped>
.ReadingAssignmentFocus {
  display: flex;
  flex: 1 1 auto;

  width: 100%;
  height: calc(100% - var(--space-xxxl));

  background-color: var(--kog-colors-grey-100);
}
.ReadingAssignmentFocus-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: padding-left 0.2s ease-in-out;
}

.ReadingAssignmentFocus-content--bookRail {
  padding-left: var(--study-rail-menu-width);
}

.ReadingAssignmentFocus-content--collapsedBookRail {
  padding-left: var(--study-rail-menu-collapsed-width);
}

.ReadingAssignmentFocus-mainPage {
  overflow-y: scroll;
  flex: 1;
  height: calc(100vh - var(--full-page-navbar-height));
  padding: 0;
}

.ReadingAssignmentFocus-content--collapsedBookRail
  .ReadingAssignmentFocus-mainPage--railSliderOpen {
  width: calc(100% - var(--study-rail-slider-width) - var(--space-s));
  padding-left: var(--study-rail-menu-collapsed-width);
}

@media (--viewport-l) {
  .ReadingAssignmentFocus-backdrop {
    position: fixed;
    z-index: 100;

    display: block;

    width: 100%;
    height: 100%;
    padding-left: var(--study-rail-menu-width);

    background-color: var(--kogShadow080);
  }
  .ReadingAssignmentFocus-bookRailSlider {
    padding-top: var(--space-xxxl);
  }
}
</style>
