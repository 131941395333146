<template>
  <span>
    <info-icon
      :tooltip-text="tooltip"
      :class="{ 'margin-right-xs': !shouldDisplayText }"
    />
    {{ shouldDisplayText ? content : '' }}
  </span>
</template>

<script>
import InfoIcon from 'learning/common/components/info-icon.vue';

import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';

export default {
  name: 'LevelsInfoIcon',
  components: {
    InfoIcon,
  },
  props: {
    educationSystem: {
      type: String,
      required: true,
    },
    shouldDisplayText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    termCore() {
      return getTerm('core', this.educationSystem);
    },
    termExtended() {
      return getTerm('extended', this.educationSystem);
    },
    content() {
      return `This subject has ${this.termCore} and ${this.termExtended} material.`;
    },
    tooltip() {
      const tooltip = `
        Students are added as ${this.termExtended} by default.
        The student's level can be changed at any time.
      `;
      return tooltip;
    },
  },
};
</script>
