import { Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget, Widget } from '@ckeditor/ckeditor5-widget';

import InsertFITBQBlankCommand from './fitbq-blank-command.js';

export default class FITBQBlankEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.editor.commands.add('insertFITBQBlank', new InsertFITBQBlankCommand(this.editor));
  }

  defineSchema() {
    const { schema } = this.editor.model;

    schema.register('FITBQBlank', {
      allowIn: '$root',
      inheritAllFrom: '$inlineObject',
      allowAttributes: ['element-uid'],
    });
  }

  defineConverters() {
    const { conversion } = this.editor;

    // <FITBQBlank> converters
    conversion.for('upcast').elementToElement({
      model: 'FITBQBlank',
      view: 'kog-ftbq',
    });
    conversion.for('dataDowncast').elementToElement({
      model: 'FITBQBlank',
      view: 'kog-ftbq',
    });
    conversion.for('editingDowncast').elementToElement({
      model: 'FITBQBlank',
      view: (modelItem, { writer }) => {
        const viewElement = writer.createContainerElement('kog-ftbq');
        return toWidget(viewElement, writer, { label: 'FITBQ Blank' });
      },
    });
    conversion.attributeToAttribute({
      model: {
        name: 'FITBQBlank',
        key: 'element-uid',
      },
      view: 'element-uid',
    });
  }
}
