<template>
  <div
    v-kog-focusable="!!tooltip"
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltip,
      boundary: 'document.body',
    }"
    class="EsqMarkschemeStatus flexContainer flexContainer-alignCenter"
    :class="{ 'EsqMarkschemeStatus-locked': isLocked }"
  >
    <kog-icon
      class="padd-right-s"
      :icon-class="iconCssClass"
      fa-style="regular"
      theme="custom"
    />
    <div>{{ message }}</div>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import KogFocusable from 'sharedApp/directives/kog-focusable.js';
import { dateTimeFormatter } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'EsqMarkschemeStatus',
  directives: {
    tooltip: VTooltip,
    KogFocusable,
  },
  components: {
    KogIcon,
  },
  props: {
    status: {
      type: Object,
      required: true,
    },
    teacherName: {
      type: String,
      required: true,
    },
    assignmentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      messages: {
        AUTO_UNLOCK: 'The mark scheme is locked until the deadline passes',
        MANUAL_UNLOCK: `The mark scheme is locked until ${this.teacherName} unlocks it`,
      },
    };
  },
  computed: {
    isLocked() {
      return !this.status.is_unlocked;
    },
    iconCssClass() {
      return this.isLocked ? 'fa-lock-alt' : 'fa-lock-open-alt';
    },
    message() {
      if (this.isLocked) {
        return this.messages[this.status.unlocked_setting];
      }
      return 'The mark scheme is unlocked';
    },
    tooltip() {
      if (!this.isLocked) {
        if (this.status.unlocked_setting === 'AUTO_UNLOCK') {
          return `Kognity unlocked the markscheme for ${this.assignmentName} at ${this.readableUnlockedAt}`;
        }
        if (this.status.unlocked_setting === 'MANUAL_UNLOCK') {
          return `${this.teacherName} unlocked the mark scheme for ${this.assignmentName} at ${this.readableUnlockedAt}`;
        }
      }
      return '';
    },
    readableUnlockedAt() {
      const date = new Date(this.status.markscheme_unlocked_at);
      return dateTimeFormatter(date);
    },
  },
};
</script>

<style scoped>
.EsqMarkschemeStatus-locked {
  color: var(--kogPlatformRedDarken20);
}
</style>
