<template>
  <div>
    <div
      class="flexContainer flexContainer-row flexContainer-alignCenter padd-left-m padd-right-m"
      aria-hidden="true"
    >
      <i class="fa-regular fa-face-surprise padd-right-xs" />
      <kog-progress-bar
        :progress="25"
        size="s"
        type="primary"
      />
    </div>
    <p
      id="strength-bar-desc"
      class="padd-s"
    >
      Shows your strength on the topic. Decreases over time and can be maintained through strength
      tests, battles and assignments.
    </p>
  </div>
</template>

<script>
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';

export default {
  components: {
    KogProgressBar,
  },
};
</script>
