<template>
  <div
    class="text-center"
    :class="[
      {
        'NodeProgressCell-fixedWidth': mode !== 'circle',
        'flexContainer flexContainer-alignCenter': mode === 'circle',
      },
    ]"
  >
    <template v-if="mode === 'bar'">
      {{ `${completed} / ${total}` }}
      <kog-progress-bar
        :progress="progressBar"
        type="primary"
        size="s"
      />
    </template>
    <template v-if="mode === 'circle'">
      <kog-round-button
        v-if="isClickable"
        :icon-class="isSectionCompleted ? 'fa-check' : ''"
        icon-size="s"
        size="x-small"
        :button-style="isSectionCompleted ? 'primary' : 'default'"
        :aria-label="sectionStatus"
        :tooltip="tooltipText"
        @click="$emit('click')"
      />
      <div
        v-else
        v-tooltip="{
          theme: 'kog-tooltip',
          content: tooltipText,
          popperClass: 'text-center',
          boundary: 'document.body',
        }"
        class="NodeProgressCell-iconContainer flexContainer flexContainer-center"
        :class="iconContainerCss"
      >
        <kog-icon
          fa-style="regular"
          theme="custom"
          size="s"
          :icon-class="completionIconClass"
          :label="sectionStatus"
        />
      </div>
    </template>
  </div>
</template>

<script>
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'NodeProgressCell',
  components: {
    KogProgressBar,
    KogRoundButton,
    KogIcon,
  },
  props: {
    progress: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'bar',
      validator: mode => ['bar', 'circle'].includes(mode),
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  computed: {
    sectionStatus() {
      return this.isSectionCompleted ? 'Completed section' : 'Incomplete section';
    },
    isSectionCompleted() {
      return this.percentageCompleted === 100;
    },
    percentageCompleted() {
      return Math.round((this.completed / this.total) * 100);
    },
    progressBar() {
      return this.percentageCompleted === 100
        ? 100
        : [{ percentage: 0 }, { percentage: this.percentageCompleted }];
    },
    total() {
      return this.progress.total;
    },
    completed() {
      return this.progress.completed;
    },
    completionIconClass() {
      return this.isSectionCompleted ? 'fa-check' : '';
    },
    iconContainerCss() {
      return {
        'NodeProgressCell-iconContainer--empty': !this.isSectionCompleted,
        'NodeProgressCell-iconContainer--notEmpty': this.isSectionCompleted,
      };
    },
    tooltipText() {
      return !this.showTooltip || this.isSectionCompleted ? '' : 'Incomplete section';
    },
  },
};
</script>

<style scoped>
.NodeProgressCell-fixedWidth {
  width: 80px;
}

.NodeProgressCell-iconContainer {
  width: var(--space-l);
  height: var(--space-l);
  border-style: solid;
  border-radius: 100%;
}

.NodeProgressCell-iconContainer--notEmpty {
  color: var(--kog-round-button-primary-color);
  background: var(--kog-round-button-primary-background);
  border: none;
}

.NodeProgressCell-iconContainer--empty {
  color: var(--kog-round-button-default-color);
  background: var(--kog-round-button-default-background);
  border-color: var(--kog-round-button-default-border-color);
  border-width: 1px;
}
</style>
