import { Plugin } from '@ckeditor/ckeditor5-core';

export default class SpanKogIdEditing extends Plugin {
  init() {
    const { model, conversion } = this.editor;

    model.schema.extend('$text', {
      allowAttributes: ['spanKogId'],
    });

    conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        attributes: {
          'kog-id': /.+/,
        },
      },
      model: {
        key: 'spanId',
        value: viewElement => viewElement.getAttribute('kog-id'),
      },
    });

    conversion.for('downcast').attributeToElement({
      model: 'spanKogId',
      view: (modelAttributeValue, { writer }) => {
        return writer.createAttributeElement('span', {
          'kog-id': modelAttributeValue,
        });
      },
    });
  }
}
