const ImageUploadTrackingMixinContextInjection = Symbol('image-upload-tracking-context');

export const ImageUploadTrackingProvide = {
  data() {
    return {
      mixinTrackingContext: {},
    };
  },
  methods: {
    setTrackingContext(context) {
      this.mixinTrackingContext = context;
    },
  },
  provide() {
    const tracking = {};

    Object.defineProperty(tracking, 'context', {
      get: () => this.mixinTrackingContext,
    });

    return {
      [ImageUploadTrackingMixinContextInjection]: tracking,
    };
  },
};

export const ImageUploadTrackingInject = {
  inject: {
    uploadTracking: {
      from: ImageUploadTrackingMixinContextInjection,
      default: {},
    },
  },
};
