import { useStore } from 'vuex';

import type { SupportingMaterialLink, SyllabusNode } from '@apis/generated/models.ts';

export type SubjectTreeNode = {
  formatted_number_including_ancestors: string;
  number: string;
  section_id: number;
  section_template: string;
  level: number;
  name: string;
  parent: number;
  id: number;
  orderby: number;
  slug: string;
  url: string;
  is_active: boolean;
  strength_test_questions_count: number;
  image: string | null;
  image_url: string | null;
  supporting_materials: SupportingMaterialLink[];
  syllabus_nodes: SyllabusNode[];
  is_special_introduction_node: boolean;
  activity: number | null;
  children: SubjectTreeNode[];
};

export type FilterFunction = (node: SubjectTreeNode) => boolean;

export default function useSubjectTreeFilter() {
  function filterSubjectTree(
    node: SubjectTreeNode,
    filters: FilterFunction[],
  ): SubjectTreeNode | null {
    if (filters.length === 0) {
      return node;
    }

    const nodePassesFilter = filters.some(filter => filter(node));

    const filteredChildren = node.children
      .map(child => filterSubjectTree(child, filters))
      .filter((child): child is SubjectTreeNode => child !== null);

    if (nodePassesFilter || filteredChildren.length > 0) {
      return {
        ...node,
        children: filteredChildren,
      };
    }

    return null;
  }

  const store = useStore();
  const getActivityBySubjectNodeId =
    store.getters['subjectNodeFeatureModule/getActivityBySubjectNodeId'];

  function filterActivitiesFactory(filter: string, filterByAttributes = false): FilterFunction {
    const filterSubjectTreeNode: FilterFunction = (node: SubjectTreeNode) => {
      if (!node.activity) {
        return false;
      }

      const activity = getActivityBySubjectNodeId(node.id);

      if (filterByAttributes) {
        return activity?.attributes?.category === filter;
      }

      return activity?.type === filter;
    };

    return filterSubjectTreeNode;
  }

  return { filterSubjectTree, filterActivitiesFactory };
}
