<template>
  <kog-round-button
    is-chip
    class="SubjectNodeAssignToggle"
    :aria-label="ariaLabel"
    icon-class="fa-clipboard-list-check"
    icon-style="regular"
    size="x-small"
    :tooltip="tooltip"
    :button-style="isNodeAssignedInAssignment ? 'primary' : 'default'"
    @click.prevent="toggleNodeAssigned"
  />
</template>

<script>
import { computed, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { waitFor } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import { BASKET_MIXPANEL_EVENTS } from 'sharedApp/const/assignment-basket.js';
import posthog from 'sharedApp/libs/posthog.ts';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);
export default {
  name: 'SubjectNodeAssignToggle',
  components: { KogRoundButton },
  mixins: [RoutesMixin],
  props: {
    subjectNodeId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const assignmentStore = useAssignmentStore();
    const addingNodeLoader = computed(
      () => `assignment_basket/creating_draft_assignment/${props.subjectNodeId}`,
    );
    const removingNodeLoader = computed(
      () => `assignment_basket/removing_section/${props.subjectNodeId}`,
    );
    const { assignment, isAssignmentBasketOpen } = storeToRefs(assignmentStore);

    const featureFlagStore = useFeatureFlagStore();
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);

    const isFilteringSubjectClassOverview = inject('isFilteringSubjectClassOverview', false);
    const activeFiltersInSubjectClassOverview = inject('activeFiltersInSubjectClassOverview', '');

    return {
      assignment,
      isAssignmentBasketOpen,
      addingNodeLoader,
      removingNodeLoader,
      createAssignment: waitFor(addingNodeLoader, assignmentStore.createAssignment),
      addSection: waitFor(addingNodeLoader, assignmentStore.addSection),
      removeSection: waitFor(removingNodeLoader, assignmentStore.removeSection),
      isFilteringSubjectClassOverview,
      activeFiltersInSubjectClassOverview,
      isMultipleClassAssignmentEnabled,
    };
  },
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
    }),
    hasOngoingDraftAssignment() {
      return this.assignment?.id;
    },
    subjectNode() {
      return this.subjectNodesById[this.subjectNodeId];
    },
    contentType() {
      if (this.getActivityBySubjectNodeId(this.subjectNodeId)) {
        return this.getActivityBySubjectNodeId(this.subjectNodeId).type;
      }
      return 'Section';
    },
    isNodeAssignedInAssignment() {
      if (this.$wait.is(this.addingNodeLoader)) return true;
      if (this.$wait.is(this.removingNodeLoader)) return false;
      return (
        this.hasOngoingDraftAssignment &&
        this.assignment.sections.length > 0 &&
        this.assignment.sections.some(node => node.subject_node_id === this.subjectNodeId)
      );
    },
    operation() {
      return this.isNodeAssignedInAssignment ? 'Unassign' : 'Assign';
    },
    ariaLabel() {
      return `${this.operation} this content`;
    },
    tooltip() {
      return `${this.operation} to students`;
    },
    mixpanelTrackingProps() {
      return {
        subject_node_id: this.subjectNodeId,
        subject_node_name: `${this.subjectNode.formatted_number_including_ancestors} ${this.subjectNode.name}`,
        source: this.$route.name === 'subjectClassOverview' ? 'overview' : 'book',
        content_type: this.contentType,
        is_filtering: this.isFilteringSubjectClassOverview,
        active_filters: this.activeFiltersInSubjectClassOverview,
      };
    },
  },
  methods: {
    async toggleNodeAssigned() {
      if (!this.hasOngoingDraftAssignment) {
        await this.createAssignment(
          this.user,
          this.classId,
          ASSIGNMENT_TYPES.READING,
          [this.subjectNodeId],
          studyAppLocalStorage.getSendAssignmentToEdlink(),
          this.isMultipleClassAssignmentEnabled,
        );
        posthog.capture('open_assignment_in_teacher_assignment_basket');
        this.$mixpanel.trackEvent(BASKET_MIXPANEL_EVENTS.CREATE_START, this.mixpanelTrackingProps);
        this.$mixpanel.trackEvent(BASKET_MIXPANEL_EVENTS.ADD_ITEM, this.mixpanelTrackingProps);
        this.isAssignmentBasketOpen = true;
        return;
      }
      if (this.isNodeAssignedInAssignment) {
        this.removeSection(this.subjectNodeId);
      } else {
        this.addSection(this.subjectNode, this.assignment.sections.length);
      }
      const eventName = this.isNodeAssignedInAssignment
        ? BASKET_MIXPANEL_EVENTS.DELETE_ITEM
        : BASKET_MIXPANEL_EVENTS.ADD_ITEM;
      this.$mixpanel.trackEvent(eventName, this.mixpanelTrackingProps);
    },
  },
};
</script>
