import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget, Widget } from '@ckeditor/ckeditor5-widget';
import { ClickObserver } from '@ckeditor/ckeditor5-engine';
import InsertFileDownloadCommand from './file-download-command.js';
import { isClickInsideSelectedElement } from '../utils/commands.js';

export default class FileDownloadEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineCommands();
  }

  defineSchema() {
    this.editor.model.schema.register('fileDownload', {
      allowWhere: '$text',
      allowAttributes: ['title', 'url', 'target'],
      isInline: true,
      isObject: true,
    });
  }

  defineConverters() {
    const { conversion } = this.editor;

    conversion.for('upcast').add(dispatcher => {
      dispatcher.on('element:a', (evt, data, conversionApi) => {
        const { consumable, writer, safeInsert, convertChildren, updateConversionResult } =
          conversionApi;
        const { viewItem, modelCursor } = data;
        const aElem = { name: 'a', classes: 'CKEditorKognityFileUpload' };
        const iconSpan = { name: 'span', classes: 'CKEditorKognityFileUpload-icon' };
        const textSpan = { name: 'span', classes: 'CKEditorKognityFileUpload-text' };

        if (!consumable.test(viewItem, aElem)) {
          return;
        }
        const childElements = Array.from(viewItem.getChildren()).filter(child =>
          child.is('element'),
        );
        if (childElements.length !== 2) {
          return;
        }
        const firstChildItem = childElements[0];
        if (!firstChildItem.is('element', 'span') || !consumable.test(firstChildItem, iconSpan)) {
          return;
        }
        const secondChildItem = childElements[1];
        if (!secondChildItem.is('element', 'span') || !consumable.test(secondChildItem, textSpan)) {
          return;
        }
        const title = secondChildItem.getChild(0).data;
        const url = viewItem.getAttribute('href');
        const target = viewItem.getAttribute('target');
        const modelElement = writer.createElement('fileDownload', { title, url, target });
        if (!safeInsert(modelElement, modelCursor)) {
          return;
        }
        consumable.consume(viewItem, aElem);
        consumable.consume(firstChildItem, iconSpan);
        consumable.consume(secondChildItem, textSpan);
        updateConversionResult(modelElement, data);
      });
    });

    conversion.for('dataDowncast').elementToElement({
      model: 'fileDownload',
      view: (modelItem, { writer }) => {
        const viewElement = this.createViewElement(modelItem, writer);
        return viewElement;
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: 'fileDownload',
      view: (modelItem, { writer }) => {
        const viewElement = this.createViewElement(modelItem, writer, { forEditing: true });
        return toWidget(viewElement, writer);
      },
    });
  }

  defineCommands() {
    const { editor } = this;
    const { document } = editor.model;
    const { view, mapper } = editor.editing;

    editor.commands.add('updateFileDownload', new Command(editor));
    editor.commands.add('insertFileDownload', new InsertFileDownloadCommand(editor));

    view.addObserver(ClickObserver);
    editor.listenTo(view.document, 'click', (_, data) => {
      if (isClickInsideSelectedElement(data, document, mapper, 'fileDownload')) {
        editor.commands.get('updateFileDownload').execute();
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  createViewElement(modelItem, writer, options) {
    const title = modelItem.getAttribute('title');
    const url = modelItem.getAttribute('url');
    const target = modelItem.getAttribute('target');

    const forEditing = options?.forEditing ?? false;

    const elemTag = forEditing ? 'span' : 'a';
    const attrs = {
      class: 'CKEditorKognityFileUpload',
      href: url,
      target,
    };
    if (target === '_blank') {
      attrs.rel = 'noopener noreferrer';
    }
    const fileDownloadView = writer.createContainerElement(elemTag, attrs);
    const iconView = writer.createContainerElement('span', {
      class: 'CKEditorKognityFileUpload-icon far fa-download',
    });
    const textView = writer.createContainerElement('span', {
      class: 'CKEditorKognityFileUpload-text',
    });
    const titleText = writer.createText(title);
    writer.insert(writer.createPositionAt(fileDownloadView, 'end'), iconView);
    writer.insert(writer.createPositionAt(textView, 'end'), titleText);
    writer.insert(writer.createPositionAt(fileDownloadView, 'end'), textView);
    return fileDownloadView;
  }
}
