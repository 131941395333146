/* eslint-disable class-methods-use-this */

import { Command } from '@ckeditor/ckeditor5-core';

import { isElementTypeSelected } from '../utils/commands.js';

export default class InsertFITBQBlankCommand extends Command {
  execute() {
    const { model } = this.editor;
    model.change(writer => {
      model.insertObject(this.create(writer));
    });
  }

  refresh() {
    const { document, schema } = this.editor.model;
    const isWidgetSelected = isElementTypeSelected(document.selection, 'FITBQBlank');
    const allowedIn = schema.findAllowedParent(document.selection.getFirstPosition(), 'FITBQBlank');
    this.isEnabled = !isWidgetSelected && allowedIn !== null;
  }

  create(writer) {
    const uid = window.crypto.randomUUID();
    return writer.createElement('FITBQBlank', { 'element-uid': uid });
  }
}
