<template>
  <transition
    name="BidirectionalExpandTransition"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </transition>
</template>

<script>
import { setHeight, setSize, setWidth } from 'sharedApp/utils/dom-utils.js';

export default {
  name: 'BidirectionalExpandTransition',
  emits: ['after-expand', 'after-collapse'],
  data: () => ({
    elWidth: null,
    elHeight: null,
  }),
  methods: {
    beforeEnter(element) {
      const requestId = requestAnimationFrame(() => {
        if (!element.style.height) {
          this.elWidth = element.offsetWidth;
          this.elHeight = element.offsetHeight;
          setSize(element, '0px', '0px');
        }

        // eslint-disable-next-line no-param-reassign
        element.style.display = null;
        cancelAnimationFrame(requestId);
      });
    },
    enter(element) {
      // nesting requestAnimationFrame to ensure that `enter` code executes after `beforeEnter`
      const parentRequestId = requestAnimationFrame(() => {
        const nestedRequestId = requestAnimationFrame(() => {
          setSize(element, `${this.elWidth}px`, `${this.elHeight}px`);
          cancelAnimationFrame(nestedRequestId);
        });

        cancelAnimationFrame(parentRequestId);
      });
    },
    afterEnter(element) {
      setSize(element, null, null);
      this.$emit('after-expand');
    },
    beforeLeave(element) {
      const requestId = requestAnimationFrame(() => {
        if (!element.style.height) {
          setHeight(element, `${element.offsetHeight}px`);
        }
        if (!element.style.width) {
          setWidth(element, `${element.offsetWidth}px`);
        }

        cancelAnimationFrame(requestId);
      });
    },
    leave(element) {
      const parentRequestId = requestAnimationFrame(() => {
        const nestedRequestId = requestAnimationFrame(() => {
          setSize(element, '0px', '0px');

          cancelAnimationFrame(nestedRequestId);
        });

        cancelAnimationFrame(parentRequestId);
      });
    },
    afterLeave(element) {
      setSize(element, null, null);
      this.$emit('after-collapse');
    },
  },
};
</script>

<style scoped>
.BidirectionalExpandTransition-enter-from,
.BidirectionalExpandTransition-leave-to {
  opacity: 0;
}

.BidirectionalExpandTransition-enter-active,
.BidirectionalExpandTransition-leave-active {
  will-change: height, opacity, width;
  overflow: hidden;
  transition: all 0.3s ease;
}
</style>
