import { Plugin } from '@ckeditor/ckeditor5-core';
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository.js';
import AudioEditing from './audio-editing.js';
import AudioUI from './audio-ui.js';

export default class Audio extends Plugin {
  static get requires() {
    return [AudioEditing, AudioUI, FileRepository];
  }
}
