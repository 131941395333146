import axios from 'sharedApp/vue-utils/kog-axios.ts';

export async function fetchDefinitions({ selectedText }) {
  const apiUrl = '/api/dictionary/';
  const targetUrl = `${apiUrl}?search_term=${selectedText}`;
  try {
    const { data } = await axios.get(targetUrl);
    return data;
  } catch (e) {
    let error = 'Something went wrong';
    if (e.response && e.response.data && e.response.data.detail) {
      error = e.response.data.detail;
    }
    throw new Error(error);
  }
}
