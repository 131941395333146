<template>
  <div>
    <kog-round-button
      v-for="assignment in assignments"
      :key="assignment.assignment_id"
      class="AssignmentButtonList-button"
      :aria-label="`Go to assignment ${assignment.assignment_name}`"
      size="small"
      :button-style="assignment.is_completed ? 'positive' : 'danger'"
      :icon-class="assignment.is_completed ? 'fa-check' : 'fa-times'"
      icon-size="s"
      icon-style="solid"
      :tooltip="assignment.assignment_name"
      @click="goToAssignment(assignment)"
    />
  </div>
</template>

<script>
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';

export default {
  name: 'AssignmentButtonList',
  components: {
    KogRoundButton,
  },
  props: {
    assignments: {
      type: Array,
      required: true,
    },
    classSlug: {
      type: String,
      required: true,
    },
    subjectId: {
      type: Number,
      required: true,
    },
    classId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    goToAssignment(assignment) {
      const { classSlug, subjectId: sid, classId: cid, $router } = this;

      $router.push({
        name: 'teacherAssignmentDetails',
        params: {
          classSlug,
          sid,
          cid,
          assignmentId: assignment.assignment_id,
        },
      });
    },
  },
};
</script>
<style scoped>
.AssignmentButtonList-button {
  margin-left: var(--space-xxs);
}

.AssignmentButtonList-button:first-of-type {
  margin-left: 0;
}
</style>
