<template>
  <div class="LongTextQuestionCorrectAnswer">
    <content-container
      v-kog-mathjax
      :content="content"
    />
  </div>
</template>

<script>
import ContentContainer from 'sharedApp/components/content/content-container.vue';

export default {
  name: 'LongTextQuestionCorrectAnswer',
  components: { ContentContainer },
  props: {
    content: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.LongTextQuestionCorrectAnswer {
  display: block;

  margin: 0;
  padding: 0;

  background: none;
  border: none;
}
</style>
