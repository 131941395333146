<template>
  <div
    class="KogTextarea-container"
    :class="classAttr"
    :style="styleAttr"
  >
    <div class="KogTextArea-labelContainer">
      <div
        class="heading-xxs flexContainer flexContainer-row flexContainer-alignCenter"
        :class="{
          'margin-bottom-xxs': hasLabelContainerVisibleContent,
        }"
      >
        <span
          v-if="labelIcon"
          aria-hidden="true"
          class="KogTextarea-labelIcon"
        >
          <i :class="`far fa-${labelIcon}`" />
        </span>
        <label
          v-if="label && !isLabelHidden"
          class="KogTextarea-label"
          aria-hidden="true"
          :for="getUniqueId('textarea')"
        >
          {{ label }}
        </label>
      </div>
      <kog-tag
        v-if="topRightHelperText"
        :id="getUniqueId('top-info')"
        type="basic"
        :label="topRightHelperText"
      />
    </div>
    <textarea
      v-bind="otherAttrs"
      :id="getUniqueId('textarea')"
      ref="kogTextarea"
      class="KogTextarea"
      :class="textareaClass"
      :maxlength="maxLength"
      :aria-label="ariaLabel || label"
      :aria-describedby="getIdsForAriaDescribedby"
      :style="style"
      :value="value"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
    />
    <div
      v-if="maxLength"
      :id="getUniqueId('counter')"
      class="text-muted text-right text-small"
      :aria-label="countMessage"
    >
      {{ countMessage }}
    </div>
    <div
      :id="getUniqueId('bottom-info')"
      :aria-label="validationMessage || helperText"
    >
      <span
        v-if="validationMessage"
        role="alert"
        class="KogTextarea-error"
      >
        {{ validationMessage }}
      </span>
      <span
        v-else-if="helperText"
        class="KogTextarea-helper"
      >
        {{ helperText }}
      </span>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

import TextAutosave from 'learning/common/mixins/text-autosave-mixin.js';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import useStyleClassExtractedAttrs from 'sharedApp/composables/use-style-class-extracted-attrs.ts';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';

export default {
  name: 'KogTextarea',
  components: {
    KogTag,
  },
  mixins: [TextAutosave],
  inheritAttrs: false,
  props: {
    /**
     * The value of the textarea
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * The label should always be specified, for accessibility purposes.
     * If you don't want it to be visible, use `isLabelHidden: true`
     */
    label: {
      type: String,
      required: true,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    isLabelHidden: {
      type: Boolean,
      default: false,
    },
    /**
     * Class of a font-awesome icon to be shown before the label
     */
    labelIcon: {
      type: String,
      default: '',
    },
    /**
     * Specifies style of the input field
     */
    styleType: {
      type: String,
      default: 'outline',
      validator: val => ['outline', 'prominent'].includes(val),
    },
    /**
     * A description or additional information to be displayed below the textarea
     */
    helperText: {
      type: String,
      default: '',
    },
    /**
     * A description or additional information to be displayed
     *  at the top-right corner of the input
     */
    topRightHelperText: {
      type: String,
      default: '',
    },
    /**
     * Max length for the text area value. If set a counter will be rendered.
     */
    maxLength: {
      type: Number,
      default: undefined,
    },
    /**
     * Proxies the value for the inner `textarea` element's `resize` attribute.
     */
    resize: {
      type: String,
      default: 'none',
      validator: prop => ['none', 'both', 'horizontal', 'vertical'].includes(prop),
    },
    /**
     * Specifies whether the `textarea` height should adjust dynamically to its contents.
     */
    fitToContent: {
      type: Boolean,
      default: false,
    },
    /**
     * Object containing any style properties to be passed to the `textarea` element
     */
    textareaStyle: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Specify whether the validation message should be updated while changing the value
     * or when the control loses focus.
     */
    validateOn: {
      type: String,
      default: 'input',
      validator: prop => ['input', 'blur'].includes(prop),
    },
  },
  emits: ['input', 'blur', 'focus', 'update:value'],
  setup() {
    const { getUniqueId } = useUniqueId();
    const extractedAttrs = useStyleClassExtractedAttrs();

    return {
      getUniqueId,
      ...extractedAttrs,
    };
  },
  data: () => ({
    hasFocus: false,
    shouldValidate: false,
    validationClass: '',
    validationMessage: '',
  }),
  computed: {
    getIdsForAriaDescribedby() {
      return `${this.getUniqueId('top-info')} ${this.getUniqueId('bottom-info')}`;
    },
    textareaClass() {
      const classes = [this.validationClass, `KogTextarea--${this.styleType}`];

      return classes;
    },
    style() {
      return {
        ...this.textareaStyle,
        resize: this.resize,
      };
    },
    countMessage() {
      const count = this.value?.length || 0;
      return `${count}/${this.maxLength}`;
    },
    hasLabelContainerVisibleContent() {
      return (this.label && !this.isLabelHidden) || this.labelIcon || this.topRightHelperText;
    },
  },
  watch: {
    value() {
      if (this.fitToContent) {
        this.adjustHeightToContent();
      }
      if (!this.shouldValidate) {
        return;
      }

      if (!this.hasFocus || this.validateOn === 'input') {
        nextTick(() => {
          this.checkValidity();
        });
      }
    },
  },
  mounted() {
    this.initObservableStreams(this.$refs.kogTextarea);
    if (this.fitToContent) {
      this.adjustHeightToContent();
    }
  },
  methods: {
    adjustHeightToContent() {
      const el = this.$refs.kogTextarea;
      const style = window.getComputedStyle(el);

      el.style.height = '0';
      const { scrollHeight } = el;

      el.style.height = `calc(${scrollHeight}px + ${style.borderTopWidth} + ${style.borderBottomWidth})`;
    },
    handleInput(event) {
      /**
       * Emitted when the user changes the value
       *
       * @property {string} value
       */
      this.$emit('update:value', event.target.value);
      this.$emit('input', event.target.value);
    },
    handleBlur(event) {
      this.hasFocus = false;
      if (this.validateOn === 'blur') {
        this.checkValidity();
      }
      /**
       * Emitted when the textarea loses focus
       *
       * @property {object} event original event object emitted by the native textarea `blur`
       */
      this.$emit('blur', event);
    },
    handleFocus(event) {
      this.hasFocus = true;
      this.shouldValidate = true;
      this.$emit('focus', event);
    },
    checkValidity() {
      const validityState = this.$refs.kogTextarea.validity;
      if (validityState.valid) {
        this.clearValidationMessage();
      } else {
        this.setValidationMessage(this.$refs.kogTextarea.validationMessage);
      }
    },
    clearValidationMessage() {
      this.validationClass = '';
      this.validationMessage = '';
    },
    setValidationMessage(message) {
      this.validationClass = 'KogTextarea--invalid';
      this.validationMessage = message;
    },
  },
};
</script>

<style scoped>
.KogTextarea {
  width: 100%;
  min-height: 40px;
  padding: var(--space-xs) var(--space-s);

  font-weight: normal;

  border-style: solid;
}

.KogTextarea--outline {
  background-color: var(--kog-input-outline-background-color);
  border-color: var(--kog-input-outline-border-color);
  border-width: 1px;
  border-radius: 8px;
}

.KogTextarea--prominent {
  padding-bottom: calc(var(--space-xs) + 1px);
  background-color: var(--kog-input-prominent-background-color);
  border-color: var(--kog-input-prominent-border-color);
  border-width: 0 0 1px 0;
}

.KogTextarea-label {
  cursor: default;
  display: block;
  margin: 0;
}

.KogTextarea-labelIcon {
  display: flex;
  justify-content: center;
  width: var(--space-l);
  color: var(--kog-input-icon-color);
}

.KogTextarea::placeholder {
  color: var(--kog-input-placeholder-color);
}

.KogTextarea-helper,
.KogTextarea-error {
  display: inline-block;
  margin-top: var(--space-xxs);
  font-size: 12px;
  line-height: 24px;
}

.KogTextarea-error {
  color: var(--kog-input-error-text-color);
}

.KogTextarea-helper {
  color: var(--kog-input-helper-text-color);
}

.KogTextArea-labelContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.KogTextarea:focus,
.KogTextarea:focus-visible {
  outline: none;
  box-shadow: none;
}

.KogTextarea--outline:focus,
.KogTextarea--outline:focus-visible {
  border-color: var(--kog-input-outline-focus-border-color);
}

.KogTextarea--prominent:focus,
.KogTextarea--prominent:focus-visible {
  border-color: var(--kog-input-prominent-focus-border-color);
}

.KogTextarea:focus::placeholder,
.KogTextarea:focus-visible::placeholder {
  color: var(--kog-input-focus-placeholder-color);
}

.KogTextarea--outline:disabled {
  background-color: var(--kog-input-outline-disabled-background-color);
  border-color: var(--kog-input-outline-disabled-border-color);
}

.KogTextarea--prominent:disabled {
  background-color: var(--kog-input-prominent-disabled-background-color);
  border-color: var(--kog-input-prominent-disabled-border-color);
}

.KogTextarea:disabled::placeholder {
  color: var(--kog-input-disabled-placeholder-color);
}

.KogTextarea--prominent:focus,
.KogTextarea--prominent:focus-visible,
.KogTextarea--prominent:hover:not(:disabled),
.KogTextarea--prominent.KogTextarea--invalid {
  padding-bottom: var(--space-xs);
  border-bottom-width: 2px;
}

.KogTextarea--outline:hover:not(:disabled):not(:focus) {
  border-color: var(--kog-input-outline-hover-border-color);
}

.KogTextarea--prominent:hover:not(:disabled):not(:focus) {
  border-color: var(--kog-input-prominent-hover-border-color);
}

.KogTextarea--outline.KogTextarea--invalid {
  border-color: var(--kog-input-outline-error-border-color) !important;
}

.KogTextarea--prominent.KogTextarea--invalid {
  border-color: var(--kog-input-prominent-error-border-color) !important;
}

.KogTextarea--invalid::placeholder,
.KogTextarea--invalid:focus::placeholder {
  color: var(--kog-input-error-placeholder-color);
}
</style>
