<template>
  <div v-if="isEdlinkAssignmentIntegrationEnabled">
    <assignment-integration-recipients
      v-if="subjectClass"
      :assignment-type="assignmentType"
      :send-to-integration="sendToIntegration"
      :disabled-tooltip="disabledSendAssignmentToLmsTooltip"
      @send-to-integration-updated="$emit('send-to-integration-updated', $event)"
    />
    <vertical-expand-transition>
      <div
        v-show="sendToIntegration"
        class="margin-top-s"
      >
        <kog-skeleton
          v-if="isLoadingLmsIntegrationCategories"
          height="68px"
        />
        <kog-select
          v-if="
            !isMultipleClassAssignmentEnabled &&
            !isLoadingLmsIntegrationCategories &&
            lmsIntegrationCategories &&
            lmsCategories.length > 0
          "
          :selected-value="selectedCategory"
          :options="lmsCategories"
          label="Assignment category"
          @change="onCategoryChanged"
        />
        <kog-input
          label="Assignment points"
          type="number"
          helper-text="If this field is left empty or set to 0 it will be set to a default value"
          class="margin-top-s"
          :value="pointsPossible"
          @focus="$emit('input-focused')"
          @blur="$emit('input-blurred')"
          @change="$emit('points-possible-updated', Number($event.target.value))"
        />
      </div>
    </vertical-expand-transition>
  </div>
</template>

<script>
import { inject } from 'vue';
import { storeToRefs } from 'pinia';

import {
  isCategoriesAllowedForAssignment,
  isIntegratedWithEdlink,
} from 'learning/common/services/integration/integration-service.js';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import VerticalExpandTransition from 'sharedApp/animations/vertical-expand.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import KogSelect from 'sharedApp/components/base/select/kog-select.vue';
import KogSkeleton from 'sharedApp/components/base/skeleton/kog-skeleton.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import AssignmentIntegrationRecipients from 'studyApp/components/teacher/assignments/assignment-integration-recipients.vue';
import useIntegrationAssignment from 'studyApp/composables/use-integration-assignment.js';
import useLmsIntegrationCategories, {
  DEFAULT_LMS_POINTS_POSSIBLE,
} from 'studyApp/composables/use-lms-integration-categories.js';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default {
  name: 'AssignmentLmsIntegrationContainer',
  components: {
    KogInput,
    KogSkeleton,
    KogSelect,
    AssignmentIntegrationRecipients,
    VerticalExpandTransition,
  },
  mixins: [RoutesMixin],
  inject: ['updateLmsCategory'],
  props: {
    subjectClass: {
      type: Object,
      required: true,
    },
    assignmentType: {
      type: String,
      required: true,
    },
    sendToIntegration: {
      type: Boolean,
      required: true,
    },
    pointsPossible: {
      type: Number,
      default: null,
    },
    categoriesByClassId: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'input-focused',
    'input-blurred',
    'points-possible-updated',
    'send-to-integration-updated',
  ],
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const { isEdlinkAssignmentIntegrationEnabled, isMultipleClassAssignmentEnabled } =
      storeToRefs(featureFlagStore);

    const disabledSendAssignmentToLmsTooltip = inject('disabledSendAssignmentToLmsTooltip', '');
    const { showIntegrationWarning } = useIntegrationAssignment();
    const {
      loadLmsIntegrationCategories,
      lmsIntegrationCategories,
      isLoadingLmsIntegrationCategories,
    } = useLmsIntegrationCategories();

    return {
      isEdlinkAssignmentIntegrationEnabled,
      isMultipleClassAssignmentEnabled,
      disabledSendAssignmentToLmsTooltip,
      showIntegrationWarning,
      loadLmsIntegrationCategories,
      lmsIntegrationCategories,
      isLoadingLmsIntegrationCategories,
    };
  },
  computed: {
    integrationProvider() {
      return this.subjectClass.integration_providers?.length > 0
        ? this.subjectClass.integration_providers[0]
        : '';
    },
    isCategoriesAllowedForAssignment() {
      return (
        this.isEdlinkAssignmentIntegrationEnabled &&
        isCategoriesAllowedForAssignment(this.integrationProvider)
      );
    },
    lmsCategories() {
      const categories = this.lmsIntegrationCategories[this.subjectClass.id];
      if (!categories) {
        return [];
      }
      return categories.map(category => ({
        value: category.id,
        text: category.title,
      }));
    },
    subjectClassIntegrationSlug() {
      const subjectClassIntegrations = this.subjectClass.integrations;
      return subjectClassIntegrations.length > 0 ? subjectClassIntegrations[0].toLowerCase() : '';
    },
    selectedCategory() {
      return this.isCategoriesAllowedForAssignment
        ? this.categoriesByClassId[this.subjectClass.id]
        : '';
    },
  },
  watch: {
    sendToIntegration: {
      handler(newValue) {
        if (newValue) {
          this.setDefaultPoints();
          this.reloadIntegrationInfo();
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.sendToIntegration) {
      this.setDefaultPoints();
    }
  },
  methods: {
    setDefaultPoints() {
      if (!this.pointsPossible) {
        this.$emit('points-possible-updated', DEFAULT_LMS_POINTS_POSSIBLE);
      }
    },
    async reloadIntegrationInfo() {
      if (isIntegratedWithEdlink(this.subjectClass.integrations)) {
        if (!this.isMultipleClassAssignmentEnabled) {
          this.$wait.start('fetching_categories');
          if (this.isCategoriesAllowedForAssignment) {
            await this.loadCategories();
          }
          this.$wait.end('fetching_categories');
        }
      } else {
        studyAppLocalStorage.setSendAssignmentToEdlink(false);
        this.updateSendToIntegration(false);
      }
    },
    async loadCategories() {
      try {
        await this.loadLmsIntegrationCategories(this.subjectClassIntegrationSlug, this.subjectId);
      } catch (error) {
        this.showIntegrationWarning(error);
        this.disableSendAssignmentToLmsButton(
          `Failed to fetch integration data from ${this.integrationProvider}`,
        );
      }
    },
    onCategoryChanged(event) {
      const categoryAndClassId = {
        categoryId: event,
        classId: this.subjectClass.id,
      };
      this.updateLmsCategory(categoryAndClassId);
    },
  },
};
</script>
