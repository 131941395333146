<template>
  <completion-stats-modal-body
    :students-completion-info="studentsCompletionInfo"
    :completion-column-text="hasChildNodes ? pluralize($term('subsection')) : 'Completed'"
    :education-system-name="subject.educationsystem.name"
  />
</template>

<script>
import pluralize from 'pluralize';

import CompletionStatsModalBody from './completion-stats-modal-body.vue';

export default {
  name: 'ReadingCompletionStatsModalBody',
  components: {
    CompletionStatsModalBody,
  },
  props: {
    subjectNode: {
      type: Object,
      required: true,
    },
    students: {
      type: Array,
      required: true,
    },
    readingCompletionData: {
      type: Object,
      default: undefined,
    },
    subject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasChildNodes() {
      return this.subjectNode.children.length > 0;
    },
    studentsCompletionInfo() {
      return this.students.map(student => {
        const { id, user_id: userId, name, avatar_url: avatarUrl, level } = student;
        const studentReadingCompletionData = this.readingCompletionData[userId];
        let completionText = `${this.nodesCompletedPerStudentId[userId]} / ${this.subjectNode.children.length}`;
        let isCompleted;
        if (this.hasChildNodes) {
          isCompleted = this.subjectNode.children.every(node =>
            studentReadingCompletionData?.has(node.id),
          );
        } else {
          isCompleted = studentReadingCompletionData?.has(this.subjectNode.id);
          completionText = isCompleted ? 'Completed' : '';
        }
        return {
          id,
          name,
          avatarUrl,
          isCompleted,
          completionText,
          level,
        };
      });
    },
    nodesCompletedPerStudentId() {
      return this.students.reduce((acc, student) => {
        const studentCompletionSet = this.readingCompletionData[student.user_id] ?? new Set();
        const completedNodes = this.subjectNode.children.filter(node =>
          studentCompletionSet.has(node.id),
        );
        acc[student.user_id] = completedNodes.length;
        return acc;
      }, {});
    },
  },
  mounted() {
    this.$mixpanel.trackEvent('Subject class overview - Open student reading progress', {
      content_level: this.subjectNode.level,
    });
  },
  methods: {
    pluralize,
  },
};
</script>

<style scoped></style>
