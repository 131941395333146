<template>
  <div>
    <div class="flexContainer flexContainer-alignCenter flexContainer-spaceBetween margin-bottom-s">
      <div class="heading-s"> Recipients </div>
      <template v-if="!hideConnectToGoogleClassroom && !$wait.waiting('fetching_user_profile')">
        <google-classes-icon
          v-if="isConnectedToGoogleClassroom"
          v-kog-clickable="loginGoogleClassroom"
          size="medium"
          :google-classes="connectedGoogleClasses"
        />
        <kog-round-button
          v-else
          aria-label="Connect to google classroom"
          icon-class="fa-link"
          button-style="default"
          size="small"
          @click="loginGoogleClassroom"
        />
      </template>
    </div>
    <div
      v-if="categoryError"
      class="KogFormInput-helper KogFormInput-helper--error margin-bottom-xs"
    >
      {{ categoryError }}
    </div>
    <div v-if="numberOfStudents">
      <div v-if="!isMultipleClassAssignment">
        <assignment-sendout-recipients
          :student-list-by-class-id="studentListByClassId"
          :selected-student-ids="selectedStudents"
          :subject-class-ids="assignment.subject_classes"
          :google-classes="connectedGoogleClasses"
          :google-classroom-recipients="googleClassRoomRecipientsByUpstreamClassId"
        />
        <div class="KogButtonSet">
          <button
            class="AssignmentSendoutRecipientsContainer-changeRecipients KogButtonLegacy KogButtonLegacy--link KogButtonLegacy--s"
            @click.prevent="showRecipientsModal"
          >
            Change recipients
          </button>
        </div>

        <p
          v-if="!anyStudentsSelected"
          class="kogPlatformRedDarken20"
        >
          In order to send it out, you need to select at least one student as recipient.
        </p>
      </div>
      <recipient-class-list
        v-else
        :selected-student-ids="selectedStudents"
        :update-recipients="updateSelectedClassesAndStudents"
        :google-classroom-recipients="googleClassroomRecipients"
        show-classes-count
        :send-to-integration="inputModel.send_to_integration"
        :categories-by-class-id="categoriesByClassId"
        :assignment-subject-class-ids="inputModel.subject_classes"
        @send-to-integration-updated="$emit('send-to-integration-updated', $event)"
        @categories-populated="$emit('categories-populated', $event)"
      />
    </div>
    <p
      v-else
      class="kogPlatformRedDarken20"
    >
      There are no students in your class yet, so the assignment cannot be sent. Learn how to easily
      add students to your class
      <a href="https://intercom.help/kognity/en/articles/4387014-add-students-to-my-class">
        here.
      </a>
    </p>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapState } from 'vuex';

import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import AssignmentSendoutRecipients from 'studyApp/components/teacher/assignment-create/sendout-step/assignment-sendout-recipients.vue';
import RecipientClassList from 'studyApp/components/teacher/assignment-create/sendout-step/recipient-class-list.vue';
import SelectRecipientsModal from 'studyApp/components/teacher/assignment-create/sendout-step/select-recipients-modal.vue';
import GoogleClassesIcon from 'studyApp/components/teacher/google-classes-icon.vue';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';

export default {
  name: 'AssignmentSendoutRecipientsContainer',
  components: {
    KogRoundButton,
    GoogleClassesIcon,
    RecipientClassList,
    AssignmentSendoutRecipients,
  },
  mixins: [GoogleClassroomMixin],
  props: {
    subjectClass: {
      type: Object,
      required: true,
    },
    studentListByClassId: {
      type: Object,
      required: true,
    },
    updateSelectedStudents: {
      type: Function,
      required: true,
    },
    updateSelectedClassesAndStudents: {
      type: Function,
      required: true,
    },
    isStudentSelectedById: {
      type: Object,
      required: true,
    },
    queryParams: {
      type: Object,
      default: () => ({}),
    },
    assignment: {
      type: Object,
      required: true,
    },
    inputModel: {
      type: Object,
      required: true,
    },
    googleClassroomRecipients: {
      type: Array,
      required: true,
    },
    origin: {
      type: String,
      default: 'create-assignment',
    },
    categoryError: {
      type: String,
      default: '',
    },
  },
  emits: ['send-to-integration-updated', 'categories-populated'],
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);

    return {
      isMultipleClassAssignmentEnabled,
    };
  },
  computed: {
    ...mapState({
      subject: state => state.schoolstaffSubjectModule.subject,
      subjectClasses: state => state.subjectClassModule.subjectClasses,
      userProfile: state => state.teacherDetailModule.userProfile,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    hideConnectToGoogleClassroom() {
      return this.shouldHideConnectToGoogleClassroom(this.subjectClass.integrations);
    },
    assignmentId() {
      return this.assignment.id;
    },
    anyStudentsSelected() {
      const values = Object.values(this.isStudentSelectedById);
      return values.length > 0 && values.some(value => value);
    },
    selectedStudents() {
      return Object.keys(this.isStudentSelectedById)
        .filter(studentId => this.isStudentSelectedById[studentId])
        .map(studentId => Number(studentId));
    },
    numberOfStudents() {
      return Object.values(this.isStudentSelectedById).length;
    },
    subjectClassStudents() {
      return this.studentListByClassId[this.subjectClass.id];
    },
    subjectClassWithStudents() {
      return { ...this.subjectClass, studentList: this.subjectClassStudents };
    },
    assignmentSubjectClasses() {
      if (this.assignment === null) return [];
      return this.assignment.subject_classes.map(classId => this.subjectClassesById[classId]);
    },
    connectedGoogleClasses() {
      return this.assignmentSubjectClasses.flatMap(subjectClass => subjectClass.google_classes);
    },
    isConnectedToGoogleClassroom() {
      return this.connectedGoogleClasses.length > 0;
    },
    googleClassRoomRecipientsByUpstreamClassId() {
      return this.googleClassroomRecipients.reduce((acc, upstreamClass) => {
        acc[upstreamClass.upstream_class_id] = upstreamClass.recipient_upstream_ids;
        return acc;
      }, {});
    },
    categoriesByClassId() {
      return this.inputModel.integration_settings?.categories_by_class_id ?? {};
    },
    isMultipleClassAssignmentOverride() {
      return ['true', '1'].includes(this.$route.query.override_multi_class_assignments);
    },
    isMultipleClassAssignment() {
      return this.isMultipleClassAssignmentEnabled || this.isMultipleClassAssignmentOverride;
    },
  },
  mounted() {
    this.getUserProfile();
    const googleClassroomSuccessParam = this.queryParams.gc_success;
    if (googleClassroomSuccessParam === 'true') {
      this.openConnectGoogleClassroomModal(
        this.origin,
        this.subjectClassWithStudents,
        this.onConnectGoogleClass,
      );
    }
    if (googleClassroomSuccessParam === 'false') {
      this.showGoogleClassroomAuthenticationErrorToast();
    }
    this.resetGcSuccessQueryParam();
  },
  methods: {
    ...mapActions({
      fetchSubjectClass: 'schoolstaffSubjectclassModule/fetchSubjectClass',
    }),
    ...mapWaitingActions('teacherDetailModule', {
      getUserProfile: { action: 'getUserProfile', loader: 'fetching_user_profile' },
    }),
    loginGoogleClassroom() {
      const { isGoogleClassroomTeacher } = this.userProfile;
      const connectData = {
        subjectClass: this.subjectClassWithStudents,
        onConnect: this.onConnectGoogleClass,
        origin: this.origin,
        assignmentId: this.assignmentId,
        isGoogleClassroomTeacher,
      };
      this.connectToClassroom(connectData);
    },
    async onConnectGoogleClass(newGoogleClasses) {
      await this.fetchSubjectClass({
        subjectClassId: this.subjectClass.id,
        useCache: false,
      });

      const selectedGoogleRecipients = this.getSelectedGoogleClassStudentsList();
      newGoogleClasses.forEach(gc => {
        gc.students.forEach(student => {
          selectedGoogleRecipients[student.id] = true;
        });
      });
      this.updateSelectedStudents(this.isStudentSelectedById, selectedGoogleRecipients);
    },
    getSelectedGoogleClassStudentsList() {
      const selectedGCList = {};
      this.subjectClass.google_classes.forEach(googleClass => {
        googleClass.students.forEach(googleStudent => {
          selectedGCList[googleStudent.upstream_student_id] = false;
        });
      });
      this.googleClassroomRecipients.forEach(googleClass => {
        googleClass.recipient_upstream_ids.forEach(upstreamStudentId => {
          selectedGCList[upstreamStudentId] = true;
        });
      });
      return selectedGCList;
    },
    showRecipientsModal() {
      return this.$modal(
        SelectRecipientsModal,
        {
          subject: this.subject,
          subjectClassStudents: this.subjectClassStudents,
          subjectClassName: this.subjectClass.name,
          selectedStudents: this.isStudentSelectedById,
          updateSelectedStudents: this.updateSelectedStudents,
          googleClasses: this.connectedGoogleClasses,
          googleClassroomRecipients: this.googleClassroomRecipients,
        },
        { closeExisting: true },
      );
    },
  },
};
</script>

<style scoped>
.AssignmentSendoutRecipientsContainer-googleClassroomIcon {
  width: 20px;
}

.AssignmentSendoutRecipientsContainer-googleClassroomButton {
  margin-left: var(--space-m);
  padding: 0;
}

.AssignmentSendoutRecipientsContainer-changeRecipients {
  padding: 0;
}
</style>
