<template>
  <kog-modal
    title="Update scheduled time"
    class="UpdateScheduleModal"
    :close-modal="closeModal"
  >
    <!--Body-->
    <template #modalBody>
      <!--Form-->
      <div
        v-show="!isUpdating"
        class="UpdateScheduleModal-content flexContainer flexContainer-center flexContainer-column"
      >
        <flat-pickr
          v-model="date"
          :config="config"
          class="hidden"
        />
        <div class="margin-top-1">
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="margin-top-fine-1"
            v-html="getDeadlineLabel()"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
      <!--/Form-->

      <!--Error-->
      <div
        v-if="hasApiErrors"
        class="text-center kogPlatformRedDarken20"
      >
        An error occurred while updating your assignment. Please try again.
      </div>
      <div
        v-if="hasValidationErrors"
        class="text-center kogPlatformRedDarken20"
      >
        The scheduled time must be in the future.
      </div>
      <!--/Error-->

      <!--Loader-->
      <kog-loader
        :loading="isUpdating"
        loading-msg="Updating..."
      />
      <!--/Loader-->
    </template>
    <!--/Body-->

    <!--Footer-->
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right flexContainer-alignCenter">
        <kog-button
          :disabled="isUpdating"
          label="Cancel"
          @click="closeModal"
        />
        <kog-button
          label="Cancel assignment"
          button-style="danger"
          @click="cancelScheduling"
        />
        <kog-button
          v-if="!hasApiErrors"
          :disabled="isUpdating"
          label="Update scheduled time"
          button-style="primary"
          @click="onUpdate"
        />
        <kog-button
          v-if="hasApiErrors"
          :disabled="isUpdating"
          label="Try again"
          button-style="primary"
          @click="onUpdate"
        />
      </div>
    </template>
    <!--/Footer-->
  </kog-modal>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { mapGetters } from 'vuex';

import * as schoolStaffApi from '@apis/schoolstaff-assignments.js';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import {
  getDateAsDateObject,
  getDateInNearest5Minutes,
  getDateTimeStringsPair,
  getDateXMinutesLess,
} from 'sharedApp/utils/time-utils.js';
import ManualTimeInputPlugin from 'teachApp/utils/flatPickrPlugins/manual-time-input-plugin.js';

export default {
  name: 'UpdateScheduleModal',
  components: {
    KogModal,
    KogLoader,
    KogButton,
    flatPickr,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    assignment: {
      type: Object,
      required: true,
    },
    scheduledDate: {
      type: Date,
      required: true,
    },
    deadlineDate: {
      type: Date,
      required: true,
    },
    onUpdateSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      date: null,
      isUpdating: false,
      hasApiErrors: false,
      hasValidationErrors: false,
      config: {
        altInput: false,
        dateFormat: 'Y-m-d H:i',
        enableTime: true,
        allowInput: false,
        time_24hr: true,
        defaultDate: this.scheduledDate,
        minDate: new Date(),
        maxDate: getDateXMinutesLess(5, getDateInNearest5Minutes(this.deadlineDate)),
        minuteIncrement: 5,
        inline: true,
        plugins: [new ManualTimeInputPlugin()],
      },
    };
  },
  computed: {
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    subjectClassId() {
      if (this.$route.params.cid) {
        return parseInt(this.$route.params.cid, 10);
      }
      return this.assignment.subject_classes[0];
    },
    subjectClass() {
      return this.subjectClassesById[this.subjectClassId];
    },
    subjectClassSlug() {
      return this.subjectClass.slug;
    },
    selectedDate() {
      if (!this.date) {
        return getDateAsDateObject(this.scheduledDate);
      }
      return new Date(this.date);
    },
    assignmentId() {
      return this.assignment.id;
    },
    subjectId() {
      return this.assignment.subject_id;
    },
  },
  methods: {
    onUpdate() {
      if (!this.validateSendDate()) {
        return;
      }

      this.hasApiErrors = false;
      this.isUpdating = true;

      this.updateScheduleHandler(this.selectedDate)
        .then(() => {
          this.$mixpanel.trackEvent('Assignment - Reschedule', {
            num_subject_classes: this.assignment.subject_classes.length,
            deadline: this.deadlineDate.toISOString(),
            old_schedule: this.scheduledDate.toISOString(),
            new_schedule: this.selectedDate.toISOString(),
          });
          this.isUpdating = false;
          this.onUpdateSuccess();
          this.closeModal();
        })
        .catch(err => {
          this.hasApiErrors = true;
          this.isUpdating = false;
          throw err;
        });
    },
    async updateScheduleHandler(newDate) {
      const data = { scheduled_sent_at: newDate.toISOString() };
      await schoolStaffApi.updateAssignmentSchedule(this.assignmentId, data);
      this.$toast.showSuccess(this.getSuccessToastCopy(newDate));
    },
    getSuccessToastCopy(newDate) {
      const { date, time } = getDateTimeStringsPair(newDate);
      return `Schedule changed to ${date} at ${time}`;
    },
    getDeadlineLabel() {
      const { date, time } = getDateTimeStringsPair(this.deadlineDate);
      return `Deadline: ${date} at ${time}`;
    },
    validateSendDate() {
      const now = new Date();
      const isValid = now < this.selectedDate;
      if (!isValid) {
        this.hasValidationErrors = true;
      } else {
        this.hasValidationErrors = false;
      }
      return isValid;
    },
    goToAssignmentList() {
      this.$router.push({
        name: 'teacherAssignmentOverview',
        params: {
          classSlug: this.subjectClassSlug,
          cid: this.subjectClass,
          sid: this.subjectId,
        },
        query: { tab: 'draft_assignments' },
      });
    },
    async cancelScheduling() {
      await schoolStaffApi.updateAssignmentSchedule(this.assignment.id, {
        scheduled_sent_at: null,
      });
      const toasterId = this.$toast.showSuccess(
        "Assignment cancelled. It has been moved to your 'Draft assignments'.",
        {
          actionText: 'Open the draft',
          toasterActionPerformed: () => {
            this.$toast.close(toasterId);
            this.goToAssignmentUpdate();
          },
        },
      );
      this.$mixpanel.trackEvent('Assignment - Scheduled cancelled', {
        deadline: this.assignment.deadline,
        scheduled_sent_at: this.assignment.scheduled_sent_at,
        assignment_type: this.assignment.assignment_type.toLowerCase(),
        num_subject_classes: this.assignment.subject_classes.length,
        source: 'Assignment details',
      });
      this.goToAssignmentList();
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.UpdateScheduleModal-content {
  width: 70%;
  margin: 0 auto;
}
</style>
