<template>
  <div>
    <h2 class="screenreader-only"> To do {{ getAssignmentCountCopy(toDoAssignments.length) }} </h2>
    <h2
      class="AssignmentList-header AssignmentList-padding heading-s margin-top-xs margin-bottom-xs muted"
      aria-hidden="true"
    >
      To do
      <span
        v-if="toDoAssignments.length > 0"
        aria-hidden="true"
      >
        ({{ getAssignmentCountCopy(toDoAssignments.length) }})
      </span>
    </h2>
    <h2
      class="AssignmentList-mobileHeader AssignmentList-padding heading-s margin-top-xs margin-bottom-xs muted"
      :aria-label="`Assignments ${getAssignmentCountCopy(assignments.length)}`"
    >
      Assignments
      <span
        v-if="assignments.length > 0"
        class="AssignmentList-assignmentCount"
        aria-hidden="true"
      >
        ({{ getAssignmentCountCopy(assignments.length) }})
      </span>
    </h2>
    <div
      v-if="toDoAssignments.length === 0"
      class="AssignmentList-padding text-center margin-top-s"
    >
      <div class="margin-bottom-s">
        You have no ongoing assignments!
        <span class="margin-left-s KogEmoji KogEmoji--medium KogEmoji--tada" />
      </div>
      <button
        :disabled="!isSubjectClassesAvailable"
        class="KogButtonLegacy KogButtonLegacy--primary AssignmentList-takeStrengthTestButton"
        @click="takeStrengthTest"
      >
        Take a strength test
      </button>
    </div>
    <div class="AssignmentList-assignmentListOuter">
      <div
        v-if="assignments.length > 0"
        class="AssignmentList-assignmentList"
      >
        <kog-item-list role="list">
          <assignment-list-item
            v-for="(occasion, index) in toDoAssignments"
            :key="occasion.assignment.id"
            :occasion="occasion"
            :is-completed="false"
            :is-reading-assignment="isReadingAssignment(occasion)"
            :current-index="index"
            :index-to-set-message-at="previousAssignmentsCount"
          />
        </kog-item-list>
        <h2
          v-if="doneAssignments.length > 0"
          class="heading-s margin-top-xs margin-bottom-xs muted AssignmentList-subHeader"
          :aria-label="`Done ${getAssignmentCountCopy(doneAssignments.length)}`"
        >
          Done
          <span
            class="AssignmentList-assignmentCount"
            aria-hidden="true"
          >
            ({{ getAssignmentCountCopy(doneAssignments.length) }})
          </span>
        </h2>
        <kog-item-list role="list">
          <assignment-list-item
            v-for="occasion in doneAssignments"
            :key="occasion.assignment.id"
            :occasion="occasion"
            :is-completed="true"
            :is-reading-assignment="isReadingAssignment(occasion)"
          />
        </kog-item-list>
        <div
          v-if="nextAssignmentPage"
          class="AssignmentList-nextButton text-bold margin-bottom-l flexContainer flexContainer-alignCenter"
        >
          <button
            aria-label="Load more assignments"
            class="KogButtonLegacy"
            @click="loadMoreAssignments"
          >
            Load more
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reverse from 'learning/common/libs/kog-router.js';

import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import AssignmentListItem from 'studyApp/components/dashboard/assignment-list-item.vue';
import SelectSubjectModal from 'studyApp/components/dashboard/select-subject-modal.vue';

export default {
  name: 'AssignmentList',
  components: {
    AssignmentListItem,
    KogItemList,
  },
  props: {
    assignments: {
      type: Array,
      default: () => [],
    },
    nextAssignmentPage: {
      type: String,
      default: null,
    },
    previousAssignmentsCount: {
      type: Number,
      default: null,
    },
    loadMoreAssignments: {
      type: Function,
      required: true,
    },
    subjectsById: {
      type: Object,
      required: true,
    },
    subjectClasses: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isSubjectClassesAvailable() {
      return this.subjectClasses.length > 0;
    },
    toDoAssignments() {
      return this.assignments.filter(a => !a.is_completed);
    },
    doneAssignments() {
      return this.assignments.filter(a => a.is_completed);
    },
  },
  methods: {
    getAssignmentCountCopy(length) {
      if (length > 1) {
        return `${length} assignments`;
      }
      return `${length} assignment`;
    },
    isReadingAssignment(assignmentOccasion) {
      return assignmentOccasion.assignment.assignment_type === 'READING';
    },
    takeStrengthTest() {
      if (this.subjectClasses.length === 1) {
        window.location = this.getStrengthTestUrl(this.subjectClasses[0]);
      } else if (this.subjectClasses.length > 1) {
        this.openSubjectSelectionModal();
      }
    },
    getStrengthTestUrl(subjectClass) {
      const subject = this.subjectsById[subjectClass.subject_id];
      return reverse('studyApp', 'newPracticeTestSetup', {
        classSlug: subjectClass.slug,
        sid: subject.id,
        cid: subjectClass.id,
      });
    },
    openSubjectSelectionModal() {
      this.$modal(SelectSubjectModal, {
        subjectsById: this.subjectsById,
        subjectClasses: this.subjectClasses,
        getStrengthTestUrl: this.getStrengthTestUrl,
      });
      this.$mixpanel.trackEvent('Open strength test modal from empty state dashboard', {
        navigated_from: 'student_hompage_empty_state',
      });
    },
  },
};
</script>

<style scoped>
.AssignmentList-assignmentCount {
  font-size: 0.9em;
  font-weight: normal;
}

.AssignmentList-mobileHeader {
  display: none;
}

.AssignmentList-subHeader {
  margin-right: var(--space-m);
  padding-top: var(--space-m);
}

.AssignmentList-padding,
.AssignmentList-assignmentList {
  margin-right: var(--space-m);
}

.AssignmentList-nextButton {
  justify-content: center;
}

@media (--viewport-s) {
  .AssignmentList-header {
    display: none;
  }

  .AssignmentList-mobileHeader {
    display: block;
  }

  .AssignmentList-subHeader {
    display: none;
  }

  .AssignmentList-padding {
    margin-left: var(--space-m);
  }

  .AssignmentList-assignmentListOuter {
    position: relative;
  }

  .AssignmentList-takeStrengthTestButton {
    margin-bottom: var(--space-l);
  }

  .AssignmentList-assignmentList {
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;

    margin-right: 0;
    padding: 0 var(--space-m);

    -webkit-overflow-scrolling: touch;
  }

  .AssignmentList-nextButton {
    flex: 0 0 110px;
    justify-content: left;
    text-align: left;
    vertical-align: top;
  }

  .AssignmentList-assignmentList::after {
    content: '';
    flex: 0 0 30px;
  }
}
</style>
