<template>
  <div v-if="subject && subject.id === subjectId">
    <fullpage-takeover-navbar
      :back-button-aria-label="backButtonAriaLabel"
      :header-text="assignmentTypeLabel"
      @back-button-click="goToPreviousPage"
    />
    <div class="TeacherAssignmentCreate-mainContainer padd-right-l padd-left-l">
      <step-header
        :assignment-type="assignmentType"
        :current-step="urlParamStep"
        :go-to-step="goToStep"
      />
      <article
        class="TeacherAssignmentCreate-scrollContainer"
        :style="{ marginBottom: isDemoUser ? demoAndFooterHeight : footerHeight }"
      >
        <wizard-page-slide :current-step-index="urlParamStep">
          <assignment-select-step
            v-if="urlParamStep === 1"
            :selected-node="selectedNode"
            :selected-items="selectedItems"
            :subject-and-topics="subjectAndTopics"
            :max-subject-tree-level="maxSubjectTreeLevel"
            :paginated-items="questions"
            :assignment-type="assignmentType"
            :selectable-item-counts="selectableItemCounts"
            :subject-name="subjectName"
            :subject-id="subjectId"
            :subject-map="subjectMap"
            :show-difficulty="showDifficulty"
            :sections="sections"
            :is-all-selected="isAllSelected"
            :get-question-locations="getQuestionLocations"
            :is-loading-questions="$wait.is('loading_questions/*')"
            :fetch-questions="fetchQuestions"
            :education-system="subject.education_system"
            :hidden-subject-node-ids="hiddenSubjectNodeIds"
            @node-selected="selectNode"
            @toggle-item="toggleItem"
            @toggle-select-all="toggleSelectAllItems"
            @filter-changed="updateDifficultyFilter"
            @question-saved="questionSaved"
            @question-deleted="questionDeleted"
          />
          <assignment-sendout-step
            v-else-if="urlParamStep === 2 && user && isInitComplete"
            v-model:value="inputModel"
            :subject-name="subjectName"
            :update-selected-students="updateSelectedStudentsAndSave"
            :student-list-by-class-id="studentListByClassId"
            :save-assignment-handler="saveAssignment"
            :assignment="assignment"
            :items="selectedItems"
            :remove-item="removeItem"
            :update-items="rearrangeItems"
            :show-difficulty="showDifficulty"
            :get-question-locations="getQuestionLocations"
            :validate-assignment-for-sending="validateAssignmentForSending"
            :errors="errors"
            :subject-class-id="subjectClassId"
            :education-system="subject.education_system"
            :hidden-subject-node-ids="hiddenSubjectNodeIds"
            :update-selected-classes-and-students="updateSelectedClassesAndStudents"
          />
        </wizard-page-slide>
      </article>
      <assignment-create-footer
        v-if="user"
        :assignment-name="inputModel.name"
        :is-saving="$wait.is('saving_assignment/*')"
        :current-step="urlParamStep"
        :selected-items="selectedItems"
        :assignment-type="assignmentType"
        :go-to-step="goToStep"
        :save-assignment-handler="saveAssignment"
        :send-assignment-handler="sendAssignmentHandler"
        :get-assignment-data-handler="getAssignmentData"
        :subject-name="subjectName"
        :student-list-by-class-id="studentListByClassId"
        :schedule-assignment-handler="scheduleAssignmentHandler"
        :input-model="inputModel"
        :validate-assignment-for-sending="validateAssignmentForSending"
        :update-failed="errorWhileSavingAssignment"
        :is-demo-footer-showing="isDemoUser"
        :has-hidden-sections="hasHiddenSections"
      />
    </div>
  </div>
</template>

<script>
import { computed, provide, ref } from 'vue';
import { useHead } from '@unhead/vue';
import { cloneDeep, debounce, differenceBy } from 'lodash';
import { waitFor } from 'vue-wait';
import { mapActions, mapGetters, mapState, useStore } from 'vuex';

import * as assignmentsApi from '@apis/assignments';
import { fetchAssignmentDetails } from '@apis/assignments';
import { AssignmentsService } from '@apis/generated/services.ts';
import * as schoolStaffApi from '@apis/schoolstaff-assignments.js';
import { fetchStudentsList } from '@apis/subject-class';

import { SOURCES } from 'learning/common/components/assignment/create-assignment-button.vue';
import { DEMO_FOOTER_HEIGHT_PX } from 'learning/common/components/demo-footer.vue';
import reverse from 'learning/common/libs/kog-router.js';

import WizardPageSlide from 'sharedApp/animations/wizard-page-slide.vue';
import FullpageTakeoverNavbar from 'sharedApp/components/core/fullpage-takeover-navbar.vue';
import intercomModule from 'sharedApp/libs/intercom.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import {
  formatAssignmentSentMessage,
  isExamStyleType,
  isQuestionType,
  isReadingType,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import { shouldShowDifficultyTagForSubject } from 'sharedApp/services/featureflags/fixed-feature-flags.ts';
import multipleChoiceQuestionNumberingService from 'sharedApp/services/questions/multipleChoiceQuestionNumbering/multiple-choice-question-numbering-service.js';
import { isMCQ } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { getDateAsDateObject } from 'sharedApp/utils/time-utils.js';
import { VALID_SOURCES } from 'studyApp/components/practice/practice-setup-info.vue';
import AssignmentCreateFooter, {
  ASSIGNMENT_CREATE_FOOTER_HEIGHT_PX,
} from 'studyApp/components/teacher/assignment-create/assignment-create-footer.vue';
import AssignmentSelectStep from 'studyApp/components/teacher/assignment-create/assignment-select-step.vue';
import AssignmentSendoutStep from 'studyApp/components/teacher/assignment-create/assignment-sendout-step.vue';
import ClassEsqAssignmentInstructionsModal from 'studyApp/components/teacher/assignment-create/class-esq-assignment-instructions-modal.vue';
import StepHeader from 'studyApp/components/teacher/assignment-create/step-header.vue';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';
import SendAssignmentTrackingMixin from 'studyApp/mixins/teacher/send-assignment-tracking-mixin.js';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';
import StudyAppSessionStorage from 'studyApp/utils/session-storage-utils.js';
import { isEsqInstructionsVisible } from 'studyApp/utils/teacher/esq-instruction-utils.js';
import examStyleQuestionsApi from 'teachApp/apis/exam-style-questions.js';
import { trackCreateAssignment } from 'teachApp/services/tracking/assignment-tracking.js';

import { getAssignableNodeCounts } from './teacher-assignment-utilities.js';

const TOPIC_LEVEL = 1;
const SUBTOPIC_LEVEL = 2;
const sessionStorage = new StudyAppSessionStorage();
const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);
const kogMultipleChoiceQuestionNumberingService = multipleChoiceQuestionNumberingService();
const debouncedUpdateAssignmentDetails = debounce(assignmentsApi.updateAssignmentDetails, 500, {
  leading: true,
  trailing: true,
});

export default {
  name: 'TeacherAssignmentCreateContainer',
  components: {
    AssignmentSelectStep,
    AssignmentSendoutStep,
    StepHeader,
    AssignmentCreateFooter,
    FullpageTakeoverNavbar,
    WizardPageSlide,
  },
  mixins: [GoogleClassroomMixin, RoutesMixin, SendAssignmentTrackingMixin],
  setup() {
    const store = useStore();
    const { schoolstaffSubjectModule } = store.state;
    const subjectName = computed(() => schoolstaffSubjectModule.subject?.name);
    const questionsSentBeforeSet = ref(new Set());
    provide('questionsSentBeforeSet', questionsSentBeforeSet);

    useHead({
      title: () => `Create assignment | ${subjectName.value}`,
    });
    return {
      questionsSentBeforeSet,
    };
  },
  data() {
    return {
      assignment: null,
      studentListByClassId: {},
      questionData: {},
      errorWhileSavingAssignment: false,
      isInitComplete: false,
      showDifficulty: false,
      selectedItems: [],
      topics: [],
      sections: [],
      selectedNode: {},
      sectionCounts: {},
      inputModel: {
        student_selected_status_by_id: {},
        google_classroom_recipients: [],
        name: 'Assignment name',
        message: '',
        deadline: null,
        all_students_selected: false,
        scheduled_sent_at: null,
        soft_deadline: true,
        show_mark_scheme: false,
        markscheme_status: null,
        send_to_integration: studyAppLocalStorage.getSendAssignmentToEdlink(),
        integration_settings: {},
        display_answers_setting: '',
      },
      errors: {},
      unselectedDifficulties: [],
      sourceRouteMapping: {
        [SOURCES.ASSIGNMENT_OVERVIEW]: 'teacherAssignmentOverview',
        [SOURCES.TEXTBOOK_SECTION_HEADER]: 'reviewMode',
        [SOURCES.TEXTBOOK_SECTION_MODAL]: 'reviewMode',
        [SOURCES.ASSIGNMENT_CARD]: 'subjectClassOverview',
        [SOURCES.SUBJECT_CLASS_OVERVIEW_SECTION_MODAL]: 'subjectClassOverview',
        [SOURCES.SUBJECT_CLASS_OVERVIEW_STRENGTH_QUESTIONS_MODAL]: 'subjectClassOverview',
        [SOURCES.TEACHER_CLASS_CARD]: 'studyDashboard',
        [SOURCES.TEACHER_ASSIGNMENT_OVERVIEW]: 'teacherAssignmentOverview',
        [VALID_SOURCES.STRENGTH_TEST_SETUP]: 'classStrengthTestSetup',
        [VALID_SOURCES.ESQ_PRACTICE_SETUP]: 'classPracticeEsqSetup',
        [VALID_SOURCES.STRENGTH_BATTLE_SETUP]: 'classBattleSetup',
      },
    };
  },
  computed: {
    ...mapState('teacherStudentListModule', {
      subjectClassStudents: 'subjectClassStudentsList',
    }),
    ...mapState('schoolstaffSubjectModule', ['subject', 'subjectMap', 'questionCounts']),
    ...mapState('schoolstaffSubjectclassModule', ['subjectClass']),
    ...mapGetters('userModule', ['isDemoUser', 'user']),
    ...mapGetters('subjectClassModule', ['isNodeVisible', 'subjectClassesById']),
    ...mapGetters('subjectNodeFeatureModule', ['featuresById', 'getActivityBySubjectNodeId']),
    questions: {
      get() {
        return this.questionData;
      },
      set(questionData) {
        questionData.results.forEach(question => {
          const choices = question.context?.choices;
          if (!isMCQ(question)) {
            return question;
          }
          kogMultipleChoiceQuestionNumberingService.updateMultipleChoiceQuestionWithNumbering(
            question,
            false,
            choices,
          );
          return question;
        });
        this.questionData = questionData;
      },
    },
    subjectName() {
      return this.subject.name;
    },
    subjectAndTopics() {
      const topics = this.subject.subject_tree[0].children;
      return [this.subject.subject_tree[0]].concat(topics);
    },
    assignmentId() {
      return this.$route.params.assignmentId;
    },
    assignmentType() {
      return this.assignment?.assignment_type || '';
    },
    source() {
      return this.$route.query.source;
    },
    assignmentTypeLabel() {
      if (this.isReading) {
        return 'Create Book Assignment';
      }
      if (this.isESQ) {
        const term = this.$term('exam-style');
        const formattedTerm = term
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        return `Create ${formattedTerm} Assignment`;
      }
      return 'Create Question Assignment';
    },
    hasHiddenSections() {
      return (
        this.isReading &&
        this.assignment.sections.some(section => !this.isNodeVisible(section.subject_node_id))
      );
    },
    backButtonAriaLabel() {
      const navigationMessage = 'Click to go back to ';
      let destination;

      switch (this.sourceRouteMapping[this.source]) {
        case 'reviewMode':
          destination = 'the book';
          break;
        case 'subjectClassOverview':
          destination = 'the class overview';
          break;
        case 'studyDashboard':
          destination = 'the dashboard';
          break;
        case 'classStrengthTestSetup':
          destination = 'the strength test setup';
          break;
        case 'classPracticeEsqSetup':
          destination = 'the exam style questions test setup';
          break;
        case 'classBattleSetup':
          destination = 'the battle setup';
          break;
        case 'teacherAssignmentOverview':
        default:
          destination = 'the assignment overview';
          break;
      }

      return `${navigationMessage}${destination}`;
    },
    selectableItemCounts() {
      if (this.isQuestion || this.isESQ) {
        return this.questionCounts;
      }

      return this.sectionCounts;
    },
    isAllSelected() {
      if (this.isQuestion || this.isESQ) {
        return this.isAllQuestionsSelected;
      }
      if (this.isReading) {
        return this.isAllSectionsSelected;
      }
      return false;
    },
    isAllSectionsSelected() {
      if (!this.sections || this.sections.length === 0) {
        return false;
      }
      const difference = differenceBy(this.sections, this.selectedItems, 'id');
      return difference.length === 0;
    },
    sectionLevelForReadingAssignment() {
      return Math.max(...Object.values(this.subjectMap).map(node => node.level));
    },
    maxSubjectTreeLevel() {
      if (this.isReading) {
        return this.sectionLevelForReadingAssignment - 1;
      }

      if (this.isESQ) {
        return TOPIC_LEVEL;
      }

      return SUBTOPIC_LEVEL;
    },
    anyStudentsSelected() {
      const isSelectedById = this.inputModel.student_selected_status_by_id;
      const values = Object.values(isSelectedById);
      const ret = values.length > 0 && values.some(value => value);
      return ret;
    },
    isAllQuestionsSelected() {
      if (!this.questions.results || this.questions.results.length === 0) {
        return false;
      }
      const difference = differenceBy(this.questions.results, this.selectedItems, 'id');
      return difference.length === 0;
    },
    isReading() {
      return isReadingType(this.assignmentType);
    },
    isQuestion() {
      return isQuestionType(this.assignmentType);
    },
    isESQ() {
      return isExamStyleType(this.assignmentType);
    },
    urlParamStep() {
      return Number(this.$route.query.step);
    },
    subjectClassId() {
      return Number(this.$route.params.cid);
    },
    subjectClassSlug() {
      return this.subjectClass.slug;
    },
    practiceCenterUrl() {
      return reverse('studyApp', 'esqSetup', {
        subject_slug: this.subject.slug,
      });
    },
    hiddenSubjectNodeIds() {
      return Object.keys(this.subjectMap)
        .map(nodeId => +nodeId)
        .filter(nodeId => !this.isNodeVisible(nodeId));
    },
  },
  async created() {
    this.footerHeight = `${ASSIGNMENT_CREATE_FOOTER_HEIGHT_PX}px`;
    this.demoAndFooterHeight = `${ASSIGNMENT_CREATE_FOOTER_HEIGHT_PX + DEMO_FOOTER_HEIGHT_PX}px`;
    intercomModule.updateLauncherVerticalPadding([
      {
        condition: this.isDemoUser,
        height: DEMO_FOOTER_HEIGHT_PX,
      },
      {
        condition: true,
        height: ASSIGNMENT_CREATE_FOOTER_HEIGHT_PX,
      },
    ]);
    await Promise.all([
      this.fetchSubject({ subjectId: this.subjectId }),
      this.reloadAssignment(),
      this.fetchSubjectClass({ subjectClassId: this.subjectClassId }),
    ]);
    this.topics = this.subject.subject_tree[0].children;
    await this.fetchClassStudentLists();

    this.selectFilterNodeFromUrl();
    this.setSelectedItems();
    this.esqModalHandler();
    if (this.isQuestion) {
      this.showDifficulty = shouldShowDifficultyTagForSubject(this.subjectId);
    }
    if (this.isReading) {
      this.buildSectionCounts();
    } else {
      this.setQuestionCountsForAssignmentType(this.assignmentType);
    }

    this.populateInputModel();
    if (!this.isStepValid(this.urlParamStep)) {
      this.goToStep(1, true);
    }
    trackCreateAssignment(this.$route.query, this.subjectId, this.assignment);
  },
  unmounted() {
    intercomModule.updateLauncherVerticalPadding([
      {
        condition: this.isDemoUser,
        height: DEMO_FOOTER_HEIGHT_PX,
      },
    ]);
  },
  methods: {
    ...mapActions('schoolstaffSubjectModule', [
      'fetchSubject',
      'setQuestionCountsForAssignmentType',
    ]),
    ...mapActions('schoolstaffSubjectclassModule', ['fetchSubjectClass']),
    ...mapActions('teacherStudentListModule', {
      fetchSubjectClassStudents: 'fetchSubjectClassStudentsList',
    }),
    updateAssignmentDetails: waitFor(
      'saving_assignment/save_assignment_details',
      debouncedUpdateAssignmentDetails,
    ),
    addQuestion: waitFor('saving_assignment/add_question', schoolStaffApi.addAssignmentQuestion),
    removeQuestion: waitFor(
      'saving_assignment/remove_question',
      schoolStaffApi.removeAssignmentQuestion,
    ),
    bulkAddQuestions: waitFor(
      'saving_assignment/bulk_add_questions',
      schoolStaffApi.addAssignmentQuestions,
    ),
    bulkRemoveQuestions: waitFor(
      'saving_assignment/bulk_remove_questions',
      schoolStaffApi.removeAssignmentQuestions,
    ),
    updateQuestionsOrder: waitFor(
      'saving_assignment/update_questions_order',
      schoolStaffApi.updateAssignmentQuestionOrder,
    ),
    addSection: waitFor('saving_assignment/add_section', schoolStaffApi.addNodeToReadingAssignment),
    removeSection: waitFor(
      'saving_assignment/remove_section',
      schoolStaffApi.removeNodeFromReadingAssignment,
    ),
    bulkAddSections: waitFor(
      'saving_assignment/bulk_add_sections',
      schoolStaffApi.bulkAddNodesToReadingAssignment,
    ),
    bulkRemoveSections: waitFor(
      'saving_assignment/bulk_remove_sections',
      schoolStaffApi.bulkRemoveNodesFromReadingAssignment,
    ),
    updateSectionsOrder: waitFor(
      'saving_assignment/update_sections_order',
      schoolStaffApi.updateNodeOrderInReadingAssignment,
    ),
    sendAssignment: waitFor('saving_assignment/send_assignment', schoolStaffApi.sendAssignment),
    fetchQuestionsForSelectedNode: waitFor(
      'loading_questions/questions',
      schoolStaffApi.fetchAssignmentSubjectQuestions,
    ),
    fetchExamStyleQuestionsForSelectedNode: waitFor(
      'loading_questions/exam_style_questions',
      examStyleQuestionsApi.listExamStyleQuestionPerSubject,
    ),
    fetchStudentsList: waitFor('fetching_student_list', fetchStudentsList),
    async fetchClassStudentLists() {
      await this.fetchSubjectClassStudents(this.subjectClassId);
      await Promise.all(this.assignment.subject_classes.map(this.fetchClassStudentList));
    },
    async fetchClassStudentList(subjectClass) {
      if (!this.studentListByClassId[subjectClass]) {
        this.studentListByClassId[subjectClass] = await this.fetchStudentsList(subjectClass);
      }
    },
    isSubjectNodeEnabledBySubjectNodeId(subjectNodeId) {
      return this.isNodeVisible(subjectNodeId);
    },
    selectFilterNodeFromUrl() {
      const { subjectNode } = this.$route.params;
      if (subjectNode) {
        this.selectNode(subjectNode);
        return;
      }

      const { section_node: sectionNode } = this.$route.query;
      if (sectionNode) {
        let filterTreeNode = this.subjectMap[sectionNode];

        while (filterTreeNode.level > this.maxSubjectTreeLevel) {
          filterTreeNode = this.subjectMap[filterTreeNode.parent];
        }

        this.selectNode(filterTreeNode);
        return;
      }

      this.selectNode(this.subject.subject_tree[0]);
    },
    goToStep(stepNumber, replace = false) {
      if (!this.canStep(stepNumber)) {
        return;
      }
      this.reloadAssignment();
      const query = {
        ...this.$route.query,
        step: `${stepNumber}`,
      };
      if (replace) {
        this.$router.replace({ query });
      } else {
        this.$router.push({ query });
      }
    },
    canStep(stepNumber) {
      if (this.urlParamStep === stepNumber) {
        return false;
      }
      return this.isStepValid(stepNumber);
    },
    isStepValid(stepNumber) {
      const validSteps = [1, 2];
      if (!validSteps.includes(stepNumber)) {
        return false;
      }
      if (stepNumber > 1 && this.selectedItems.length === 0) {
        return false;
      }
      return true;
    },
    getQuestionLocations(question) {
      const result = [];
      question.subjectnode_mappings.forEach(node => {
        let title = '';
        let number = node.number_including_ancestors;
        const subjectNode = this.subjectMap[node.id];
        if (!subjectNode) {
          title = '';
        } else if (subjectNode.level === 3) {
          const parent = this.subjectMap[subjectNode.parent];
          if (parent) {
            title = parent.name;
            number = parent.formatted_number_including_ancestors;
          }
        } else {
          title = subjectNode.name;
        }
        if (title && !result.some(item => item.number === number)) {
          result.push({ number, title });
        }
      });
      return result;
    },
    selectNode(node) {
      this.selectedNode = node;
      if (this.isReading) {
        this.fetchSectionsForSelectedNode();
      } else if (this.isQuestion || this.isESQ) {
        this.fetchQuestions();
      }
    },
    async fetchQuestions(page = null) {
      if (this.isQuestion) {
        const params = {
          startnode_id: this.selectedNode.id,
          exclude_difficulty: this.unselectedDifficulties,
          'exclude-hidden-nodes-for-subject-class-id': this.subjectClassId,
          page,
          page_size: 50,
        };
        this.questions = await this.fetchQuestionsForSelectedNode(this.subjectId, params);
      } else if (this.isESQ) {
        this.questions = await this.fetchExamStyleQuestionsForSelectedNode(
          this.subjectId,
          this.selectedNode.id,
          page,
        );
      }
      this.fetchQuestionsSentBeforeSet();
    },
    getSectionSubtopic(node) {
      let currentNode = node;
      if (currentNode.level < SUBTOPIC_LEVEL) {
        return null;
      }

      while (currentNode.level > SUBTOPIC_LEVEL) {
        currentNode = this.subjectMap[currentNode.parent];
      }

      return currentNode;
    },
    getSectionUrl(section) {
      return {
        name: 'classBook',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.subjectClassId,
          nodeSlug: section.slug,
          nodeId: section.id,
        },
        query: {
          source: 'reading-Assignment-select-sections',
        },
      };
    },
    buildSectionCounts() {
      this.sectionCounts = getAssignableNodeCounts(this.subject.subject_tree[0]);
    },
    loadSections(result, parentNode) {
      const isNodeEnabled = this.isSubjectNodeEnabledBySubjectNodeId(parentNode.id);
      const isSectionParent = parentNode.level === this.sectionLevelForReadingAssignment - 1;
      const isParentSpecialIntroductionNode = parentNode.is_special_introduction_node;

      if (!isNodeEnabled) {
        return;
      }

      if (isSectionParent || isParentSpecialIntroductionNode) {
        result.push(...parentNode.children);
        return;
      }

      parentNode.children.forEach(node => this.loadSections(result, node));
    },
    fetchSectionsForSelectedNode() {
      const { selectedNode } = this;

      const sections = [];
      this.loadSections(sections, selectedNode);

      this.sections = sections.map(section => {
        const subtopic = this.getSectionSubtopic(section);
        return {
          ...section,
          subtopicNumber: subtopic.number_including_ancestors,
          subtopicName: subtopic.name,
          url: this.getSectionUrl(section),
        };
      });
    },
    populateInputModel() {
      const { placeholderName, isQuestion, isESQ, inputModel } = this;
      const assignment = cloneDeep(this.assignment);
      const localInputModel = cloneDeep(inputModel);
      localInputModel.google_classroom_recipients = assignment.google_classroom_recipients || [];
      localInputModel.subject_classes = assignment.subject_classes;
      localInputModel.name = assignment.name || placeholderName;
      localInputModel.message = assignment.message || '';
      localInputModel.deadline = assignment.deadline;
      localInputModel.integration_settings = assignment.integration_settings;
      if (isQuestion) {
        localInputModel.soft_deadline = assignment.soft_deadline;
        localInputModel.display_answers_setting = assignment.display_answers_setting;
      } else if (isESQ) {
        localInputModel.show_mark_scheme = true;
        localInputModel.markscheme_status = assignment.mark_scheme_status;
      }
      this.inputModel = localInputModel;
      if (assignment.selected_student_ids) {
        this.updateSelectedStudentsForMultipleClasses(
          assignment.selected_student_ids,
          assignment.subject_classes,
        );
      } else {
        this.selectAllStudents();
      }
      this.isInitComplete = true;
    },
    async updateSelectedClassesAndStudents(
      selectedClasses,
      selectedStudents,
      selectedGoogleClasses,
    ) {
      const selectedClassIds = selectedClasses.map(subjectClass => subjectClass.id);
      const deselectedClassIds = this.assignment.subject_classes.filter(
        classId => !selectedClassIds.includes(classId),
      );
      this.removeDeselectedClassStudents(deselectedClassIds);
      this.inputModel.subject_classes = selectedClassIds;
      await Promise.all(selectedClassIds.map(this.fetchClassStudentList));
      const selectedStudentIds = selectedStudents.map(student => student.school_student);
      this.updateSelectedStudentsForMultipleClasses(selectedStudentIds, selectedClassIds);
      this.updateSelectedGoogleClassroomStudents(selectedGoogleClasses);
      await this.saveAssignment();
      await this.reloadAssignment();
    },
    removeDeselectedClassStudents(deselectedClassIds) {
      deselectedClassIds.forEach(classId => {
        this.studentListByClassId[classId].forEach(student => {
          delete this.inputModel.student_selected_status_by_id[student.school_student];
        });
      });
    },
    updateSelectedStudentsForMultipleClasses(selectedStudents, selectedClassIds) {
      selectedClassIds.forEach(classId => {
        for (let i = 0; i < this.studentListByClassId[classId].length; i += 1) {
          const student = this.studentListByClassId[classId][i];
          const isSelected = selectedStudents.includes(student.school_student);
          this.toggleStudentSelection(student, isSelected);
        }
      });
      this.inputModel.all_students_selected = Object.values(this.studentListByClassId).some(
        students =>
          students.every(
            student => this.inputModel.student_selected_status_by_id[student.school_student],
          ),
      );
    },
    updateSelectedStudentsAndSave(selectedStudents, selectedGoogleClassStudents) {
      this.updateStudentSelectedStatusById(selectedStudents);
      this.updateSelectedGoogleClassroomStudents(selectedGoogleClassStudents);
      this.saveAssignment();
    },
    updateStudentSelectedStatusById(studentSelectedStatusById) {
      this.inputModel.student_selected_status_by_id = studentSelectedStatusById;
    },
    updateSelectedGoogleClassroomStudents(selectedGCList) {
      const selectedSubjectClasses = this.inputModel.subject_classes.map(
        subjectClassId => this.subjectClassesById[subjectClassId],
      );
      let googleClasses = selectedSubjectClasses.flatMap(
        subjectClass => subjectClass.google_classes,
      );
      googleClasses = googleClasses.filter(
        (googleClass, index, self) => index === self.findIndex(t => t.id === googleClass.id),
      );
      const googleClassesWithSelectedStudents = googleClasses.map(googleClass => {
        const filteredGCRecipients = googleClass.students.filter(
          googleStudent => selectedGCList[googleStudent.upstream_student_id],
        );
        const filteredGCRecipientsIds = filteredGCRecipients.map(
          recipient => recipient.upstream_student_id,
        );

        return {
          upstream_class_id: googleClass.id,
          recipient_upstream_ids: filteredGCRecipientsIds,
        };
      });
      this.inputModel.google_classroom_recipients = googleClassesWithSelectedStudents;
    },
    selectAllStudents() {
      for (let i = 0; i < this.subjectClassStudents.length; i += 1) {
        const student = this.subjectClassStudents[i];
        this.toggleStudentSelection(student, true);
      }
      this.inputModel.all_students_selected = true;
    },
    toggleStudentSelection(student, selected) {
      this.inputModel.student_selected_status_by_id[student.school_student] = selected;
    },
    toggleItem(item) {
      if (this.isItemSelected(item)) {
        this.removeItem(item);
      } else {
        this.selectItem(item);
      }
    },
    toggleSelectAllItems() {
      if (!this.isAllSelected) {
        this.selectAllItems();
      } else {
        this.deselectAllItems();
      }
    },
    selectAllItems() {
      if (this.isQuestion || this.isESQ) {
        this.selectAllQuestions();
      } else if (this.isReading) {
        this.selectAllSections();
      }
      this.trackMixpanelEvent('Assignment - Select all items');
    },
    deselectAllItems() {
      if (this.isQuestion || this.isESQ) {
        this.deselectAllQuestions();
      } else if (this.isReading) {
        this.deselectAllSections();
      }
      this.trackMixpanelEvent('Assignment - Deselect all items');
    },
    selectAllQuestions() {
      const newItems = [];
      this.questions.results.forEach(item => {
        if (!this.isItemSelected(item)) {
          this.selectedItems.push(item);
          const order = this.selectedItems.indexOf(item);
          newItems.push({
            question_uuid: item.uuid,
            order,
          });
        }
      });
      this.bulkAddQuestions(this.assignmentId, newItems);
    },
    deselectAllQuestions() {
      const deletedItems = [];
      this.questions.results.forEach(item => {
        if (this.isItemSelected(item)) {
          this.selectedItems = this.selectedItems.filter(i => i.id !== item.id);
          deletedItems.push({
            question: item.id,
          });
        }
      });
      this.bulkRemoveQuestions(this.assignmentId, deletedItems);
    },
    selectAllSections() {
      const newItems = [];
      this.sections.forEach(item => {
        if (!this.isItemSelected(item)) {
          this.selectedItems.push(item);
          const order = this.selectedItems.indexOf(item);
          newItems.push({
            subject_node: item.id,
            order,
          });
        }
      });
      this.bulkAddSections(this.assignmentId, newItems);
    },
    deselectAllSections() {
      const deletedItems = [];
      this.sections.forEach(item => {
        if (this.isItemSelected(item)) {
          this.selectedItems = this.selectedItems.filter(i => i.id !== item.id);
          deletedItems.push({
            subject_node: item.id,
          });
        }
      });
      this.bulkRemoveSections(this.assignmentId, deletedItems);
    },
    isItemSelected(item) {
      return this.selectedItems && this.selectedItems.some(i => i.id === item.id);
    },
    selectItem(item) {
      this.selectedItems.push(item);
      const order = this.selectedItems.indexOf(item);
      if (this.isReading) {
        this.addSection(this.assignmentId, item.id, order);
        this.assignment.sections.push({ subject_node_id: item.id });
      } else {
        this.addQuestion(this.assignmentId, item.uuid, order);
      }
    },
    removeItem(item) {
      const removedItem = this.selectedItems.find(i => i.id === item.id);
      if (removedItem) {
        this.selectedItems = this.selectedItems.filter(i => i.id !== item.id);
        if (this.isReading) {
          this.removeSection(this.assignmentId, item.id);
          this.assignment.sections = this.assignment.sections.filter(
            section => section.subject_node_id !== item.id,
          );
        } else {
          this.removeQuestion(this.assignmentId, item.uuid);
        }
      }
    },
    rearrangeItems(newItemsOrder) {
      const oldItemsOrder = this.selectedItems.slice();
      this.selectedItems = newItemsOrder;
      this.selectedItems.forEach((item, index) => {
        if (oldItemsOrder[index].id !== item.id) {
          if (this.isReading) {
            this.updateSectionsOrder(this.assignmentId, item.id, index);
          } else {
            this.updateQuestionsOrder(this.assignmentId, item.uuid, index);
          }
        }
      });
    },
    trackMixpanelEvent(name) {
      this.$mixpanel.trackEvent(name, {
        type: this.assignmentType,
      });
    },
    async reloadAssignment() {
      this.assignment = await fetchAssignmentDetails(this.assignmentId);
      if (this.assignment?.questions) {
        this.assignment.questions.forEach(question => {
          if (!isMCQ(question)) {
            return;
          }
          kogMultipleChoiceQuestionNumberingService.updateMultipleChoiceQuestionWithNumbering(
            question,
            false,
            question.context?.choices,
          );
        });
      }
    },
    async saveAssignment(assignmentListTypeToNavigate = null) {
      const assignmentData = this.getAssignmentData();
      try {
        await this.updateAssignmentDetails(assignmentData.id, assignmentData);
      } catch (error) {
        this.errorWhileSavingAssignment = true;
        this.$toast.showError(
          'Could not update the assignment, please refresh the page and try again',
        );
        throw error;
      }
      if (assignmentListTypeToNavigate) {
        this.goToAssignmentList(assignmentListTypeToNavigate);
      }
    },
    async scheduleAssignmentHandler(googleClasses) {
      const assignmentData = this.getAssignmentData();
      await this.updateAssignmentDetails(assignmentData.id, assignmentData);
      this.performTracking({
        eventName: 'schedule_assignment',
        assignmentData,
        googleClasses,
        extraMixpanelProps: {
          page: 'create_assignment',
        },
      });
      this.emptyAssignmentBasketIfOpenDraftIsSent();
      this.goToAssignmentList();
    },
    async sendAssignmentHandler(googleClasses) {
      const assignmentData = this.getAssignmentData();
      debouncedUpdateAssignmentDetails.flush();
      await this.sendAssignment(assignmentData.id, assignmentData);
      this.performTracking({
        eventName: 'send_assignment',
        assignmentData,
        googleClasses,
        extraMixpanelProps: {
          page: 'create_assignment',
        },
      });
      this.emptyAssignmentBasketIfOpenDraftIsSent();
      const notificationMessage = formatAssignmentSentMessage(googleClasses);
      this.$toast.showSuccess(notificationMessage);
      this.goToAssignmentList();
    },
    emptyAssignmentBasketIfOpenDraftIsSent() {
      const openDraftInAssignmentBasketId = sessionStorage.getOpenDraftAssignmentId(this.classId);
      if (openDraftInAssignmentBasketId === this.assignmentId) {
        sessionStorage.setOpenDraftAssignmentId(this.classId, null);
      }
    },
    goToAssignmentList(listType = 'current') {
      this.$router.push({
        name: 'teacherAssignmentOverview',
        params: {
          classSlug: this.subjectClassSlug,
          cid: this.subjectClassId,
          sid: this.subjectId,
        },
        query: {
          tab: `${listType}_assignments`,
        },
      });
    },
    getAssignmentData() {
      const data = {
        id: this.assignmentId,
        name: this.inputModel.name,
        message: this.inputModel.message || '',
        deadline: this.inputModel.deadline,
        subject_class_id: this.subjectClassId,
        selected_student_ids: this.getSelectedStudentIds(),
        all_students_selected: this.inputModel.all_students_selected,
        assignment_type: this.assignmentType,
        scheduled_sent_at: this.inputModel.scheduled_sent_at,
        google_classroom_recipients: this.inputModel.google_classroom_recipients,
        send_to_integration: this.inputModel.send_to_integration,
        integration_settings: this.inputModel.integration_settings,
        subject_classes: this.inputModel.subject_classes,
      };

      if (this.isReading) {
        data.section_node_ids = this.selectedItems.map(section => section.id);
      } else if (this.isQuestion) {
        data.soft_deadline = this.inputModel.soft_deadline;
        data.display_answers_setting = this.inputModel.display_answers_setting;
        data.ordered_question_ids = this.selectedItems.map(q => q.id);
      } else if (this.isESQ) {
        data.show_mark_scheme = this.inputModel.show_mark_scheme;
        data.ordered_question_ids = this.selectedItems.map(q => q.id);
        data.mark_scheme_status = this.inputModel.markscheme_status;
      }
      const dataWithState = { ...this.assignment, ...data };
      return dataWithState;
    },
    getSelectedStudentIds() {
      const keys = Object.keys(this.inputModel.student_selected_status_by_id);
      const selectedIds = [];
      keys.forEach(studentId => {
        const studentSelectedStatus = this.inputModel.student_selected_status_by_id[studentId];
        if (studentSelectedStatus === true) {
          selectedIds.push(studentId);
        }
      });
      return selectedIds;
    },
    validateAssignmentForSending() {
      const assignmentData = this.getAssignmentData();
      this.errors = {};
      let hasErrors = false;
      if (!assignmentData.name) {
        this.errors.name = 'Name your assignment. This makes it easier to find it and refer to it.';
        hasErrors = true;
      }
      const deadlineAsDate = getDateAsDateObject(assignmentData.deadline);
      if (deadlineAsDate < new Date()) {
        this.errors.deadline = 'The deadline must be in the future in order to send this out.';
        hasErrors = true;
      }
      if (!this.anyStudentsSelected) {
        this.errors.students = 'The assignment needs to be sent to someone.';
        hasErrors = true;
      }
      if (
        (this.isQuestion || this.isExamStyle) &&
        assignmentData.ordered_question_ids.length === 0
      ) {
        this.errors.items = 'This assignment has no questions. Please add some on Step 1';
        hasErrors = true;
      }
      if (this.isReading && assignmentData.section_node_ids.length === 0) {
        this.errors.items = 'You have not selected any sections.';
        hasErrors = true;
      }
      return !hasErrors;
    },
    setSelectedItems() {
      if (this.isReading) {
        this.assignment.subject_node_ids.forEach(snId => {
          this.selectedItems.push(this.subjectMap[snId]);
        });
      } else {
        this.selectedItems = [...this.assignment.questions];
      }
    },
    async fetchQuestionsSentBeforeSet() {
      const data = await AssignmentsService.assignmentsQuestionsSentBeforeRetrieve({
        assignmentId: this.assignmentId,
      });
      this.questionsSentBeforeSet = new Set(data.questions_sent_before);
    },
    updateDifficultyFilter(unselectedDifficulties) {
      this.unselectedDifficulties = unselectedDifficulties;
      this.fetchQuestions();
    },
    async updateQuestionCounters() {
      await this.fetchSubject({ subjectId: this.subjectId, useCache: false });
      this.setQuestionCountsForAssignmentType(this.assignmentType);
    },
    questionSaved(node) {
      this.selectNode(node);
      this.updateQuestionCounters();
    },
    questionDeleted({ node, question }) {
      this.selectNode(node);
      this.updateQuestionCounters();
      this.selectedItems = this.selectedItems.filter(i => i.id !== question.id);
    },
    showNewClassESQIntructionsModal() {
      if (isEsqInstructionsVisible(this.user.id, this.subjectClassId)) {
        this.$modal(
          ClassEsqAssignmentInstructionsModal,
          {
            practiceCenterUrl: this.practiceCenterUrl,
            userId: this.user.id,
            subjectClassId: this.subjectClassId,
          },
          { closeOnEsc: false, closeOnClickOutside: false },
        );
      }
    },
    esqModalHandler() {
      if (this.isESQ && this.subjectClassId && this.$route.query.just_created) {
        this.showNewClassESQIntructionsModal();
      }
    },
    goToPreviousPage() {
      const routeName = this.sourceRouteMapping[this.source] ?? 'teacherAssignmentOverview';
      let extraParams = {};
      let queryParams = {};
      if (
        this.source === SOURCES.TEXTBOOK_SECTION_HEADER ||
        this.source === SOURCES.TEXTBOOK_SECTION_MODAL
      ) {
        extraParams = {
          nodeSlug: this.$route.query.node_slug,
          nodeId: this.$route.query.node_id,
        };
      }

      if (this.source === SOURCES.TEACHER_ASSIGNMENT_OVERVIEW) {
        queryParams = { tab: 'draft_assignments' };
      }

      this.$router.push({
        name: routeName,
        query: queryParams,
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          ...extraParams,
        },
      });
    },
  },
};
</script>

<style scoped>
.TeacherAssignmentCreate-mainContainer {
  margin-top: var(--full-page-navbar-height);
  background-color: var(--kog-background-default-0);
}

.TeacherAssignmentCreate-scrollContainer {
  position: relative;
  overflow-y: auto;
  flex: 1;
}
</style>
