<template>
  <div
    v-if="hasTeacherInstructions"
    class="TeacherInstructions padd-l margin-bottom-l"
  >
    <div>
      <kog-icon
        class="TeacherInstructions-icon"
        icon-class="fa-person-chalkboard"
        fa-style="regular"
        :is-non-actionable="true"
      />
      <h4 class="TeacherInstructions-title">Teacher instructions</h4>
    </div>
    <annotate-text
      class="TeacherInstructions-content"
      :subject="subject"
      :subject-node="subjectNode"
      :content="instructionsHtml"
      :user="user"
      :subject-tree="subject.subject_tree"
      :is-in-review-mode="true"
    />
    <slot />
  </div>
</template>

<script>
import AnnotateText from 'learning/common/components/annotate-text.vue';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'TeacherInstructions',
  components: {
    AnnotateText,
    KogIcon,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    subjectNode: {
      type: Object,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    instructionsHtml() {
      return this.content;
    },
    hasTeacherInstructions() {
      const div = document.createElement('div');
      div.innerHTML = this.instructionsHtml;
      return !!div.textContent.trim() || Boolean(this.$slots.default);
    },
  },
};
</script>

<style scoped>
.TeacherInstructions {
  position: relative;
  z-index: 0;

  width: var(--content-max-width);

  background-color: var(--kog-teacher-instructions-background);
  border-radius: var(--space-l);
}

/**
  * This is a hack to make the teacher instructions background span the full
  * width of the page. It's not possible to do this with CSS alone because
  * the teacher instructions are rendered inside a container with a max-width.
  *
  * This need to be kept in sync with these other classes:
  * - publishing/containers/section/components/preview-section.vue
  * - learning/study/components/activities/activity-teacher-instruction.vue
  */
.TeacherInstructions::before {
  content: '';

  position: absolute;
  z-index: -1;
  top: 0;
  left: calc(-50vw + 50% + calc(var(--content-offset-left) / 2));

  height: 100%;

  background: var(--kog-teacher-instructions-background);
}

/* Made to imitate the .content-editable .ContentBox-icon h4 */
.TeacherInstructions-icon {
  position: absolute;
  top: var(--space-l);
  left: var(--space-l);

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-xxl);
  height: var(--space-xxl);

  font-size: var(--kog-font-size-content);

  background-color: var(--kogPlatformWhite);
  border-radius: 50%;
}
/* Made to imitate the .content-editable .ContentBox-title h4 */
.TeacherInstructions-title {
  display: block;

  margin-top: 0 !important;
  margin-bottom: var(--space-s);
  margin-left: calc(var(--space-xxl) + var(--space-s));

  font-family: var(--kog-lato);
  font-size: 20px;
  font-weight: bold;
  line-height: var(--space-xxl);
  color: var(--kogPlatformGray018);
}
/* Made to imitate the .content-editable .ContentBox-content p */
.TeacherInstructions-content {
  font-family: var(--kog-lato);
  font-size: var(--kog-font-size-content);
  line-height: 26px;
  color: var(--kogPlatformGray018);
}
</style>
