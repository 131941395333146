import { isIntegratedWithEdlink } from 'learning/common/services/integration/integration-service.js';

import posthog from 'sharedApp/libs/posthog.ts';
import ConnectGoogleClassroomModal from 'studyApp/components/teacher/connect-google-classroom-modal.vue';
import getGoogleClassroomUrl from 'studyApp/utils/teacher/google-classroom-utils.js';

const googleClassroomMixin = {
  data() {
    return {
      newDotTooltipGoogleClassroom: `
        Send assignments directly to Classroom
        by connecting your Kognity classes here.
      `,
    };
  },
  methods: {
    shouldHideConnectToGoogleClassroom(subjectClassIntegrations) {
      return isIntegratedWithEdlink(subjectClassIntegrations);
    },
    showGoogleClassroomAuthenticationErrorToast() {
      this.$toast.showError(
        `
            We were unable to log you in.
            Please try again, if the issue continues contact us at
            <a href="mailto:support@kognity.com">support@kognity.com</a>.
          `,
      );
    },
    // eslint-disable-next-line default-param-last
    openConnectGoogleClassroomModal(origin, subjectClass, onConnect = () => {}, assignmentId) {
      posthog.capture('Open Connect to Google classroom modal');
      this.$mixpanel.trackEvent('Open Connect to Google classroom modal', {
        source: origin,
        subject_class_id: subjectClass.id,
        subject_class_name: subjectClass.name,
      });

      this.$modal(
        ConnectGoogleClassroomModal,
        {
          origin,
          subjectClass,
          assignmentId,
          connectGoogleClass: onConnect,
        },
        {
          attachModalToTop: true,
        },
      );
    },
    connectToClassroom({
      origin = 'teacher-dashboard',
      onConnect = () => {},
      isGoogleClassroomTeacher,
      subjectClass,
      assignmentId,
    }) {
      if (isGoogleClassroomTeacher) {
        this.openConnectGoogleClassroomModal(origin, subjectClass, onConnect, assignmentId);
      } else {
        window.location = getGoogleClassroomUrl(subjectClass.id, origin, assignmentId);
      }
    },
    getGoogleClassroomIconInfo(googleClasses) {
      let info = 'Connected to:';
      googleClasses.forEach(cls => {
        info += `<br>${cls.name}`;
      });
      return info;
    },
    getGoogleClassroomIconInfoNoMarkup(googleClasses) {
      let info = 'Connected to:';
      googleClasses.forEach((cls, index) => {
        if (index > 0) {
          info += ',';
        }
        info += ` ${cls.name}`;
      });
      return info;
    },
    resetGcSuccessQueryParam() {
      this.replaceQueryParam('gc_success');
    },
    resetGoogleClassIdQueryParam() {
      this.replaceQueryParam('google_class_id');
    },
    replaceQueryParam(queryParamName) {
      const query = { ...this.$route.query };
      if (query[queryParamName] !== undefined) {
        delete query[queryParamName];
        this.$router.replace({ query });
      }
    },
    getNewDotGoogleClassroomId(userId) {
      return `google-classroom-${userId}`;
    },
  },
};

export default googleClassroomMixin;
