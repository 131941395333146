import { INTEGRATION_PROVIDERS, INTEGRATIONS } from 'sharedApp/const/integrations.ts';
import canvasIcon from 'sharedApp/img/canvas-icon-32x32.png';
import edlinkIcon from 'sharedApp/img/edlink-icon-32x32.png';
import googleclassroomIcon from 'sharedApp/img/google-classroom-yellow-icon-64x64_2x.png';
import msteamsIcon from 'sharedApp/img/msteams-icon-36x36.png';
import schoologyIcon from 'sharedApp/img/schoology-icon-32x32.png';

export function isIntegratedWithEdlink(integrations) {
  return integrations.includes(INTEGRATIONS.EDLINK);
}

export function getIntegrationProviderDisplayName(integrationProvider) {
  switch (integrationProvider) {
    case INTEGRATION_PROVIDERS.MICROSOFT:
      return 'Microsoft Teams';
    case INTEGRATION_PROVIDERS.GOOGLE:
      return 'Google Classroom';
    default:
      return integrationProvider;
  }
}

export function getIntegrationProviderLogo(integrationProvider) {
  switch (integrationProvider) {
    case INTEGRATION_PROVIDERS.CANVAS:
      return canvasIcon;
    case INTEGRATION_PROVIDERS.SCHOOLOGY:
      return schoologyIcon;
    case INTEGRATION_PROVIDERS.MICROSOFT:
      return msteamsIcon;
    case INTEGRATION_PROVIDERS.GOOGLE:
      return googleclassroomIcon;
    default:
      return edlinkIcon;
  }
}

export function isCategoriesAllowedForAssignment(integrationProvider) {
  return (
    integrationProvider !== INTEGRATION_PROVIDERS.GOOGLE &&
    integrationProvider !== INTEGRATION_PROVIDERS.MICROSOFT
  );
}
