<template>
  <div
    :class="{ 'NoteCard--softDeleted': isSoftDeleted }"
    class="NoteCard"
  >
    <div class="flexContainer flexContainer-spaceBetween">
      <span
        :class="['NoteCard-highlight', 'cursorPointer', getColorClass()]"
        @click="goToBook(annotation)"
      >
        {{ annotation.exact }}
      </span>
      <kog-kebab-menu class="NoteCard-kebabMenu margin-left-fine-2">
        <template #kebabMenuBody="{ hideMenu }">
          <div class="NoteCard-kebabMenuSlot">
            <div
              class="NoteCard-kebabMenu--item NoteCard-kebabMenu--actionItem cursorPointer"
              @click="deleteNote"
            >
              Delete
            </div>
            <div
              class="NoteCard-kebabMenu--item NoteCard-kebabMenu--actionItem cursorPointer"
              @click="editNoteEnable(hideMenu)"
            >
              <span v-if="!annotation.note">Add text</span>
              <span v-if="annotation.note">Edit text</span>
            </div>
            <div class="NoteCard-kebabMenu--item cursorPointer">
              <color-selector
                :color-model="annotation.color"
                @change="updateColor"
              />
            </div>
            <div class="NoteCard-kebabMenu--item NoteCard-kebabMenu--created">
              Created on {{ createdDate }}
            </div>
          </div>
        </template>
      </kog-kebab-menu>
    </div>
    <div
      v-if="annotation.note"
      class="NoteCard-divider flexContainer flexContainer-center margin-m"
    >
      <span />
    </div>
    <div
      v-if="annotation.note && !isEditingNote"
      class="NoteCard-note"
    >
      {{ annotation.note }}
    </div>
    <div v-if="isEditingNote">
      <textarea
        ref="noteTextArea"
        v-model="noteTemporaryText"
        :maxlength="noteMaxLen"
        placeholder="Write something, e.g. a definition..."
        class="NoteCard-noteInput padd-fine-2 margin-top-fine-2 margin-bottom-fine-2"
      />
      <div class="flexContainer flexContainer-alignCenter flexContainer-flexEnd">
        <a
          class="margin-right-1 cursorPointer"
          @click="cancelEditNote"
          >Cancel</a
        >
        <button
          :class="['KogButtonLegacy', 'KogButtonLegacy--primary', 'margin-left-fine-2']"
          @click="editNote"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { mapActions } from 'vuex';

import ColorSelector from 'learning/common/components/color-selector.vue';
import { getHighlightClass } from 'learning/common/libs/annotations-utils.js';

import KogKebabMenu from 'sharedApp/components/kebab-menu/kog-kebab-menu.vue';
import { dateFormatter } from 'sharedApp/utils/time-utils.js';

const ANNOTATION_NOTE_MAX_LEN = 1000;

export default {
  name: 'NoteCard',
  components: {
    ColorSelector,
    KogKebabMenu,
  },
  props: {
    annotation: {
      type: Object,
      required: true,
    },
    goToBook: {
      type: Function,
      required: true,
    },
  },
  emits: ['edit-text', 'add-text', 'deleted-annotation'],
  data() {
    return {
      noteTemporaryText: '',
      noteMaxLen: ANNOTATION_NOTE_MAX_LEN,
      isEditingNote: false,
      isSoftDeleted: false,
    };
  },
  computed: {
    createdDate() {
      return dateFormatter(new Date(this.annotation.last_modified));
    },
  },
  methods: {
    ...mapActions({
      deleteAnnotation: 'notebookModule/deleteAnnotation',
      updateAnnotation: 'notebookModule/updateAnnotation',
    }),
    deleteNote() {
      const annotationId = this.annotation.id;
      const subjectNodeId = this.annotation.subject_node;
      const text = this.annotation.note ? 'Note deleted' : 'Highlight deleted';
      const postActionText = this.annotation.note ? 'Note restored!' : 'Highlight restored!';
      this.$toast.showSuccess(text, {
        actionText: 'Undo',
        postActionText,
        toasterActionPerformed: () => {
          this.isSoftDeleted = false;
        },
        toasterClosedNoActionPressed: () => {
          this.deleteAnnotation({ subjectNodeId, annotationId });
        },
      });
      this.isSoftDeleted = true;
      this.$emit('deleted-annotation', { annotationId, subjectNodeId });
    },
    editNoteEnable(hideMenu) {
      this.isEditingNote = true;
      nextTick(() => {
        // The text area will only be visible on the next tick
        this.$refs.noteTextArea.focus();
      });
      this.noteTemporaryText = this.annotation.note;
      if (hideMenu) {
        hideMenu();
      }
    },
    cancelEditNote() {
      this.isEditingNote = false;
      this.noteTemporaryText = this.annotation.note;
    },
    editNote() {
      const previousNote = this.annotation.note;
      const note = this.noteTemporaryText;
      const annotationId = this.annotation.id;
      const subjectNodeId = this.annotation.subject_node;
      this.updateAnnotation({
        subjectNodeId,
        annotationId,
        annotation: { ...this.annotation, note },
      }).then(() => {
        this.isEditingNote = false;
        this.noteTemporaryText = '';
        if (previousNote) {
          this.$emit('edit-text');
        } else {
          this.$emit('add-text');
        }
      });
    },
    updateColor(color) {
      const annotationId = this.annotation.id;
      const subjectNodeId = this.annotation.subject_node;
      this.updateAnnotation({
        subjectNodeId,
        annotationId,
        annotation: { ...this.annotation, color },
      });
      this.$mixpanel.trackEvent('Private annotations - Color change', {
        color_name: color,
        source: 'notebook',
      });
    },
    getColorClass() {
      return getHighlightClass(this.annotation.color, false);
    },
  },
};
</script>

<style scoped>
.NoteCard {
  padding: 30px;
}

.NoteCard-divider > span {
  width: 50px;
  border-top: 1px solid var(--kogPlatformGray077);
}

.NoteCard-highlight {
  font-family: var(--kog-content-main);
  font-size: 17px;
  line-height: 1.6;
}

.NoteCard-noteInput {
  resize: none;
  min-height: 100px;
  border-color: var(--kogPlatformGray064);
  border-radius: 5px;
}

.NoteCard-noteInput:focus {
  outline: none;
}

.NoteCard-kebabMenu {
  margin-right: -18px;
}

.NoteCard-kebabMenuSlot {
  min-width: 150px;
  background: var(--kogPlatformWhite);
}

.NoteCard-kebabMenu--item {
  padding: 10px 20px;
}

.NoteCard-kebabMenu--actionItem:hover {
  color: var(--kogPlatformWhite);
  background: var(--kog-background-brand-900);
}

.NoteCard-kebabMenu--created {
  font-size: var(--kog-font-size-smaller);
  color: var(--kogPlatformGray044);
}

.NoteCard--softDeleted {
  display: none;
}
</style>
