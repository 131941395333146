<template>
  <div class="QuestionText content-editable">
    <span class="screenreader-only">{{ contentTypeLabel }}</span>
    <content-container
      v-kog-mathjax
      :content="question.question_html"
      :has-content-blanks="isSTQ || isMCQ"
    />
  </div>
</template>

<script>
import ContentContainer from 'sharedApp/components/content/content-container.vue';
import {
  getQuestionDescription,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

export default {
  name: 'QuestionText',
  components: { ContentContainer },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contentTypeLabel() {
      return getQuestionDescription(this.question);
    },
    isSTQ() {
      return isSTQ(this.question);
    },
    isMCQ() {
      return isMCQ(this.question);
    },
  },
};
</script>
<style scoped>
.QuestionText {
  display: block;

  max-width: 100%;
  margin: 0;
  padding: 0;

  background: none;
  border: none;
}
</style>
