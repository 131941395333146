import useAssignmentReadableSettings from 'sharedApp/composables/use-assignment-readable-settings.ts';
import useToaster from 'sharedApp/composables/use-toaster.ts';
import { isQuestionType } from 'sharedApp/services/assignment/assignment-utility-service.js';
import multipleChoiceQuestionNumberingService from 'sharedApp/services/questions/multipleChoiceQuestionNumbering/multiple-choice-question-numbering-service.js';
import { isMCQ } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import {
  getAssignmentDetails,
  getNodeProgress,
  saveAssignment,
  startQuestionAssignment,
  submitQuestionAnswer,
  updateEsqAssignmentDetail,
} from 'studyApp/api/assignment.js';
import { getAnswer, isAnswered, isSubmitted } from 'studyApp/utils/practice-item-utils.js';

const kogMultipleChoiceQuestionNumberingService = multipleChoiceQuestionNumberingService();

const namespaced = true;

function getCurrentPracticeItemIndex(assignment) {
  if (!assignment || !assignment.practice_occasion?.practice_items) {
    return -1;
  }
  return assignment.current_practice_item_index;
}

function findQuestion(assignment, questionId) {
  return assignment.questions.find(question => question.id === questionId);
}

function randomizeMcqChoicesForQuestionAssignment(assignmentOccasion) {
  const { assignment, practice_occasion: practiceOccasion } = assignmentOccasion;
  if (assignment && isQuestionType(assignment.assignment_type)) {
    const { practice_items: practiceItems } = practiceOccasion;
    if (!practiceItems) {
      return;
    }
    practiceItems
      .map(practiceItem => findQuestion(assignment, practiceItem.question_id))
      .filter(isMCQ)
      .forEach(question => {
        kogMultipleChoiceQuestionNumberingService.updateMultipleChoiceQuestionWithNumbering(
          question,
          true,
        );
      });
  }
}

function setAnswerToSubmit(assignmentOccasion) {
  const { assignment, practice_occasion: practiceOccasion } = assignmentOccasion;
  if (assignment && isQuestionType(assignment.assignment_type)) {
    const { practice_items: practiceItems } = practiceOccasion;
    if (!practiceItems) {
      return;
    }
    practiceItems.forEach(practiceItem => {
      const matchingQuestion = assignment.questions.find(
        question => question.uuid === practiceItem.question_uuid,
      );
      // eslint-disable-next-line no-param-reassign
      practiceItem.answerToSubmit = getAnswer(practiceItem, matchingQuestion);
    });
  }
}

const mutations = {
  setAssignment(state, assignment) {
    if (assignment) {
      randomizeMcqChoicesForQuestionAssignment(assignment);
      setAnswerToSubmit(assignment);
    }
    state.assignment = assignment;
  },
  updateAssignmentStatus(state, status) {
    state.assignment.status = status;
  },
  setNodeProgressResults(state, nodeProgressPayload) {
    const nodeProgressObj = nodeProgressPayload.reduce((accumulated, nodeProgress) => {
      // eslint-disable-next-line no-param-reassign
      accumulated[nodeProgress.subjectnode_id] = nodeProgress;
      return accumulated;
    }, {});
    state.nodeProgressResults = nodeProgressObj;
  },
  setCurrentPracticeItemIndex(state, index) {
    state.assignment.current_practice_item_index = index;
  },
  setPracticeItemAnswerById(state, { practiceItemId, answer }) {
    const practiceItems = state.assignment.practice_occasion.practice_items;
    const foundItem = practiceItems.find(item => item.id === practiceItemId);
    if (foundItem) {
      foundItem.answerToSubmit = answer;
    } else {
      throw new Error('PracticeItem not found');
    }
  },
  addPendingAnswerToSave(state, questionUuid) {
    state.pendingAnswersToSave.add(questionUuid);
  },
  removePendingAnswerToSave(state, questionUuid) {
    state.pendingAnswersToSave.delete(questionUuid);
  },
  updatePracticeItem(state, updatedItem) {
    const practiceItems = state.assignment.practice_occasion.practice_items;
    const itemIndex = practiceItems.findIndex(item => item.id === updatedItem.id);

    if (itemIndex >= 0) {
      practiceItems[itemIndex] = Object.assign(practiceItems[itemIndex], updatedItem);
    }
  },
};

const actions = {
  clearAssignment({ commit }) {
    commit('setAssignment', null);
  },
  async getAssignment({ commit }, assignmentId) {
    commit('setAssignment', await getAssignmentDetails(assignmentId));
  },
  async pollForSubmissionToIntegration({ commit }, assignmentId) {
    const pollingIntervalMs = 1000;
    const maxPollCount = 30;
    let pollCount = 0;
    for (; pollCount < maxPollCount; pollCount += 1) {
      // eslint-disable-next-line no-await-in-loop
      const result = await getAssignmentDetails(assignmentId);
      if (result.upstream_submission_id) {
        commit('setAssignment', result);
        break;
      }

      // eslint-disable-next-line no-await-in-loop
      await new Promise(resolve => {
        setTimeout(resolve, pollingIntervalMs);
      });
    }
    if (pollCount === maxPollCount) {
      throw new Error('Polling for submission timed out');
    }
  },
  async startQuestionAssignment({ state, commit }) {
    commit('setAssignment', await startQuestionAssignment(state.assignment.assignment.id));
  },
  async startESQAssignment({ commit }, assignmentId) {
    await updateEsqAssignmentDetail(assignmentId, { status: 'STARTED' });
    commit('updateAssignmentStatus', 'STARTED');
  },
  async markESQAssignmentAsDone({ commit }, assignmentId) {
    await updateEsqAssignmentDetail(assignmentId, { status: 'COMPLETED' });
    return commit('updateAssignmentStatus', 'COMPLETED');
  },
  async fetchNodeProgress({ commit }, assignmentId) {
    commit('setNodeProgressResults', await getNodeProgress(assignmentId));
  },
  setCurrentPracticeItemAnswer({ commit, getters }, answer) {
    const practiceItem = getters.currentPracticeItem;
    if (!practiceItem) return;

    commit('addPendingAnswerToSave', practiceItem.question_uuid);
    commit('setPracticeItemAnswerById', { practiceItemId: practiceItem.id, answer });
  },
  setCurrentPracticeItem({ commit, state }, index) {
    const { assignment } = state;
    const practiceOccasion = assignment.practice_occasion;
    const practiceItems = practiceOccasion.practice_items;
    if (!practiceItems) {
      return;
    }
    if (index >= practiceItems.length) {
      throw new Error('PracticeItemIndexOutOfBoundsException');
    }
    commit('setCurrentPracticeItemIndex', index);
  },
  async saveQuestionAnswer({ state, commit, getters }) {
    const practiceItem = getters.currentPracticeItem;
    const assignmentId = state.assignment.assignment.id;
    const newItem = {
      id: practiceItem.id,
      user_answer: practiceItem.answerToSubmit,
    };
    await saveAssignment(assignmentId, [newItem], false);
    commit('removePendingAnswerToSave', practiceItem.question_uuid);
  },
  async submitQuestionAnswer({ dispatch, state, commit, getters }) {
    dispatch('wait/start', 'submitting_question_answer', { root: true });
    const practiceItem = getters.currentPracticeItem;
    if (practiceItem) {
      const assignmentId = state.assignment.assignment.id;
      const newItem = {
        id: practiceItem.id,
        user_answer: practiceItem.answerToSubmit,
      };
      try {
        const updatedItems = await submitQuestionAnswer(assignmentId, [newItem]);
        commit('updatePracticeItem', updatedItems[0]);
      } catch (error) {
        const { showError } = useToaster();
        if (error.response?.status === 400) {
          const errorType = error.response.data?.non_field_errors?.[0];
          if (errorType === 'DEADLINE_PASSED') {
            showError(
              'The deadline for this assignment has passed. You can no longer submit answers.',
            );
            return;
          }
        }
        showError('There was an error saving your answer. Please try again.');
      }
    }
    dispatch('wait/end', 'submitting_question_answer', { root: true });
  },
  async submitAssignment({ state, dispatch }) {
    const assignmentId = state.assignment.assignment.id;
    const practiceItems = state.assignment.practice_occasion.practice_items;

    const answers = [];
    const settings = useAssignmentReadableSettings(state.assignment.assignment);
    if (!settings.answers.displayImmediately) {
      practiceItems.forEach(item =>
        answers.push({
          id: item.id,
          user_answer: item.answerToSubmit,
        }),
      );
    }
    await saveAssignment(assignmentId, answers, true);
    await dispatch('getAssignment', assignmentId);
  },
};

const getters = {
  getQuestionByUuid: state => questionUuid => {
    return state.assignment.assignment?.questions?.find(question => question.uuid === questionUuid);
  },
  getAllPracticeItems(state) {
    return state.assignment?.practice_occasion?.practice_items;
  },
  currentPracticeItemIndex(state) {
    return getCurrentPracticeItemIndex(state.assignment);
  },
  currentPracticeItem(state) {
    const index = getCurrentPracticeItemIndex(state.assignment);
    if (index < 0) {
      return null;
    }
    return state.assignment.practice_occasion.practice_items[index];
  },
  isTeacherCreatedQuestion(_state, ownGetters) {
    return !!ownGetters.currentPracticeItem.question.teacher_question;
  },
  hasAnsweredCurrentQuestion(state, ownGetters) {
    if (!ownGetters.currentPracticeItem) return false;

    return (
      !state.pendingAnswersToSave.has(ownGetters.currentPracticeItem?.question_uuid) &&
      isAnswered(
        ownGetters.currentPracticeItem,
        ownGetters.getQuestionByUuid(ownGetters.currentPracticeItem?.question_uuid),
      )
    );
  },
  hasAnsweredAllQuestions(state, ownGetters) {
    if (!state.assignment?.practice_occasion?.practice_items) return false;

    return (
      state.pendingAnswersToSave.size === 0 &&
      state.assignment.practice_occasion.practice_items.every(practiceItem =>
        isAnswered(practiceItem, ownGetters.getQuestionByUuid(practiceItem.question_uuid)),
      )
    );
  },
  hasSubmittedAllQuestions(state, ownGetters) {
    if (!state.assignment?.practice_occasion?.practice_items || !ownGetters.hasAnsweredAllQuestions)
      return false;

    return state.assignment.practice_occasion.practice_items.every(practiceItem =>
      isSubmitted(practiceItem),
    );
  },
  practiceItemsCount(state) {
    return state.assignment?.practice_occasion?.practice_items.length || 0;
  },
  submittedPracticeItemsCount(state) {
    return (
      state.assignment?.practice_occasion?.practice_items.filter(
        practiceItem => !!practiceItem.is_submitted,
      ).length || 0
    );
  },
};

const state = {
  pendingAnswersToSave: new Set(),
  assignment: null,
  nodeProgressResults: null,
};

export default {
  namespaced,
  actions,
  mutations,
  state,
  getters,
};
