<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    ref="root"
    class="content-editable"
    v-html="content"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
import attachListenersToAudio from 'sharedApp/utils/audio-statistics.js';
import { getImgixSrc, isCloudfrontUrl } from 'sharedApp/utils/image-utils.js';
import VideoStatistics from 'sharedApp/utils/video-statistics.js';

const MAX_WIDTH = 600;
const MAX_WIDTH_MODAL = 900;
const MAX_WIDTH_ROUND_TO = 100;

export default {
  name: 'KogHtmlWrapper',
  props: {
    content: {
      type: String,
      default: '',
    },
    containerWidth: {
      type: Number,
      required: true,
    },
    mixpanelContext: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      videoStatisticsObj: null,
    };
  },
  computed: {
    imgMaxWidth() {
      let imgMaxWidth = Math.ceil(this.containerWidth / MAX_WIDTH_ROUND_TO) * MAX_WIDTH_ROUND_TO;
      imgMaxWidth = imgMaxWidth || MAX_WIDTH;
      return imgMaxWidth <= MAX_WIDTH ? imgMaxWidth : MAX_WIDTH;
    },
  },
  watch: {
    content() {
      this.replaceAllImages();
    },
  },
  mounted() {
    this.replaceAllImages();
    this.videoStatisticsObj = new VideoStatistics(this.mixpanelContext);
    this.videoStatisticsObj.addStatisticsToVideo(this.$refs.root);
    attachListenersToAudio(this.$refs.root, this.mixpanelContext);
  },
  methods: {
    replaceAllImages() {
      const imgElements = this.$refs.root.querySelectorAll('img');
      imgElements.forEach(el => {
        // Skip gifs for now because it requires imgix premium
        if (el.src.endsWith('.gif')) {
          return;
        }
        this.replaceWithImgix(el, this.imgMaxWidth);
      });
    },
    replaceWithImgix(el) {
      const img = el;
      const originalSrc = img.src;

      if (!isCloudfrontUrl(originalSrc)) {
        return;
      }

      const imgWidth = img.width && this.imgMaxWidth > img.width ? img.width : this.imgMaxWidth;
      const img1x = getImgixSrc(originalSrc, imgWidth * 1.5);
      const img2x = getImgixSrc(originalSrc, imgWidth * 2);
      const img3x = getImgixSrc(originalSrc, imgWidth * 3);
      const img4x = getImgixSrc(originalSrc, imgWidth * 4);

      const figcaptionElement = [...img.parentNode.children].find(
        child => child.nodeName.toUpperCase() === 'FIGCAPTION',
      );

      img.src = img1x;
      img.removeAttribute('height');
      img.setAttribute('data-largeSrc', getImgixSrc(originalSrc, MAX_WIDTH_MODAL));
      img.setAttribute('srcset', `${img1x} 1x, ${img2x} 2x, ${img3x} 3x, ${img4x} 4x`);

      const imgContainer = document.createElement('div');
      img.parentNode.replaceChild(imgContainer, img);
      imgContainer.appendChild(img);
      if (figcaptionElement) {
        figcaptionElement.parentNode.replaceChild(imgContainer, figcaptionElement);
        imgContainer.appendChild(figcaptionElement);
      }

      imgContainer.classList.add('flexContainer');
      imgContainer.classList.add('flexContainer-center');
      imgContainer.classList.add('flexContainer-column');
    },
  },
};
</script>
