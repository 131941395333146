<template>
  <div class="padd-bottom-xl flexContainer flexContainer-column flexContainer-spaceBetween">
    <div class="heading-xl"> {{ greeting }}, {{ userName }} </div>
    <div class="text-regular">
      {{ schoolName }}
    </div>
  </div>
</template>

<script>
import { timeGreeting } from 'sharedApp/utils/time-utils.js';

export default {
  name: 'KogWelcomeBar',
  props: {
    schoolName: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
  },
  computed: {
    greeting() {
      return timeGreeting();
    },
  },
};
</script>
