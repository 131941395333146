<template>
  <div class="TermsListItem">
    <h3
      aria-hidden="true"
      class="TermsListItem-text"
    >
      {{ termText }}
    </h3>
    <h3 class="screenreader-only">
      {{ labelText }}
    </h3>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="TermsListItem-definition"
      v-html="definitionHtml"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
export default {
  name: 'TermsListItem',
  props: {
    term: {
      type: Object,
      required: true,
    },
  },
  computed: {
    definitionHtml() {
      return this.term.definition_html;
    },
    termText() {
      return this.term.term;
    },
    labelText() {
      return `Glossary term: ${this.term.term}.`;
    },
  },
};
</script>

<style scoped>
.TermsListItem {
  background-color: var(--kogPlatformWhite);
  border-radius: 12px;
}

.TermsListItem-text {
  margin-top: 0;
  margin-bottom: 16px;

  font-family: var(--kog-satoshi);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.TermsListItem-definition {
  overflow-x: auto;

  margin-bottom: 20px;

  font-family: var(--kog-content-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.TermsListItem-definition :deep(p) {
  margin-bottom: 10px;
}

.TermsListItem-definition :deep(p:last-child) {
  margin-bottom: 0;
}
</style>
