<template>
  <img
    v-tooltip="{
      theme: 'kog-tooltip',
      content: googleClassroomIconInfo,
      popperClass: 'text-center',
      boundary: 'window',
    }"
    :aria-label="googleClassroomIconInfo"
    :src="googleClassroomIcon"
    :class="`GoogleClassroomIcon ${iconClassSize}`"
    :alt="googleClassroomIconInfoNoMarkup"
  />
</template>

<script>
import googleClassroomLightIcon from 'sharedApp/img/google-classroom-icon-32x32_2x.png';
import googleClassroomClassicIcon from 'sharedApp/img/google-classroom-yellow-icon-64x64_2x.png';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';

export default {
  name: 'GoogleClassesIcon',
  mixins: [GoogleClassroomMixin],
  props: {
    googleClasses: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
      validator(value) {
        const validTypes = ['medium', 'small'];
        return !value || validTypes.includes(value);
      },
    },
    style: {
      type: String,
      default: 'classic',
      validator(value) {
        const validTypes = ['classic', 'light'];
        return !value || validTypes.includes(value);
      },
    },
  },
  computed: {
    googleClassroomIcon() {
      return this.style === 'classic' ? googleClassroomClassicIcon : googleClassroomLightIcon;
    },
    googleClassroomIconInfo() {
      if (this.googleClasses.length === 0) {
        return '';
      }
      return this.getGoogleClassroomIconInfo(this.googleClasses);
    },
    googleClassroomIconInfoNoMarkup() {
      if (this.googleClasses.length === 0) {
        return '';
      }
      return this.getGoogleClassroomIconInfoNoMarkup(this.googleClasses);
    },
    iconClassSize() {
      return `GoogleClassroomIcon--${this.size}`;
    },
  },
};
</script>

<style scoped>
.GoogleClassroomIcon--small {
  width: 20px;
  height: 20px;
}
.GoogleClassroomIcon--medium {
  width: 32px;
  height: 32px;
}
</style>
