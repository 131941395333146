<template>
  <div>
    <kog-table
      v-if="isListShown"
      :is-loading-table-data="isStudentsPageLoading"
      loading-table-data-message="Loading students..."
    >
      <template #colgroup>
        <template v-if="enableLevelColumn">
          <col width="35%" />
          <col width="18%" />
        </template>
        <col
          v-else
          width="53%"
        />
        <col width="18%" />
        <col width="11%" />
      </template>
      <template #header>
        <kog-table-header>
          <kog-table-cell-header
            :is-sorted="sortedBy === keys.USER__FULL_NAME"
            :sort-column="() => determineSortParams(keys.USER__FULL_NAME)"
            :sort-order="sortOrder"
          >
            Students
          </kog-table-cell-header>
          <kog-table-cell-header
            v-if="enableLevelColumn"
            :is-sorted="sortedBy === keys.LEVEL__NAME"
            :sort-column="() => determineSortParams(keys.LEVEL__NAME)"
            :sort-order="sortOrder"
          >
            Level
          </kog-table-cell-header>
          <kog-table-cell-header
            :is-sorted="sortedBy === keys.YEARCLASS__ENDS"
            :sort-column="() => determineSortParams(keys.YEARCLASS__ENDS)"
            :sort-order="sortOrder"
          >
            Year group
          </kog-table-cell-header>
          <kog-table-cell-header
            :is-sorted="sortedBy === keys.USER__PROFILE__LAST_ACTIVE"
            :sort-column="() => determineSortParams(keys.USER__PROFILE__LAST_ACTIVE)"
            :sort-order="sortOrder"
          >
            Last active
          </kog-table-cell-header>
          <kog-table-cell-header>
            <span class="screenreader-only">Actions</span>
          </kog-table-cell-header>
        </kog-table-header>
      </template>
      <template #body>
        <class-student-list-item
          v-for="(student, index) in studentsInClass"
          :key="student.id"
          :index="index"
          :student="student"
          :subject-class-name="subjectClassName"
          :subject-name="subjectName"
          :enable-level-column="enableLevelColumn"
          :remove-student-from-class="removeStudentFromClass"
        />
      </template>
    </kog-table>
    <students-empty-section
      v-else
      :open-add-student-modal="openAddStudentModal"
      :is-addable-students-loading="isStudentsPageLoading"
    />
  </div>
</template>
<script>
import ClassDetailsAddStudentsModalContainer from 'schoolAdminApp/pages/class-details/class-details-add-students-modal-container.vue';
import StudentsEmptySection from 'schoolAdminApp/pages/class-details/components/students-empty-section.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';
import sortingKeys from 'sharedApp/const/sorting-keys.js';
import ClassStudentListItem from 'studyApp/components/teacher/students/class-student-list-item.vue';
import { SORT_ORDERS } from 'teachApp/utils/assignment-utils.js';

const DefaultOrderingKey = sortingKeys.USER__FULL_NAME;

export default {
  name: 'ClassStudentList',
  components: {
    ClassStudentListItem,
    StudentsEmptySection,
    KogTable,
    KogTableHeader,
    KogTableCellHeader,
  },
  props: {
    students: {
      required: true,
      type: Array,
    },
    currentOrdering: {
      type: String,
      required: true,
    },
    subjectClassName: {
      type: String,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
    enableLevelColumn: {
      type: Boolean,
      default: false,
    },
    isStudentsPageLoading: {
      type: Boolean,
      required: true,
    },
    removeStudentFromClass: {
      type: Function,
      required: true,
    },
    addStudentsToClass: {
      type: Function,
      required: true,
    },
  },
  emits: ['update-ordering'],
  data() {
    return {
      keys: sortingKeys,
      sortedBy: sortingKeys.USER__FULL_NAME,
      sortOrder: SORT_ORDERS.desc,
    };
  },
  computed: {
    isListShown() {
      return this.studentsInClass && this.studentsInClass.length > 0;
    },
    studentsInClass() {
      return this.students.filter(t => t.already_in_class);
    },
  },
  methods: {
    determineSortParams(columnKey) {
      const isNewField = columnKey !== this.sortedBy;
      const isSortedDesc = this.sortOrder === SORT_ORDERS.desc;
      const order = isNewField || isSortedDesc ? SORT_ORDERS.asc : SORT_ORDERS.desc;

      this.sortedBy = columnKey;
      this.sortOrder = order;

      const isFirstTimeSorted = !this.currentOrdering && columnKey === DefaultOrderingKey;
      if (isFirstTimeSorted || this.currentOrdering === columnKey) {
        this.$emit('update-ordering', `-${columnKey}`);
      } else {
        this.$emit('update-ordering', columnKey);
      }
    },
    openAddStudentModal() {
      this.$modal(ClassDetailsAddStudentsModalContainer, {
        addStudents: this.addStudentsToClass,
        addableStudents: this.students.filter(student => student.yearclass.is_active),
      });
    },
  },
};
</script>
