<template>
  <div class="SubtopicQuestionCompletion text-small">
    <div class="text-bold">
      Completed questions &nbsp;
      <i
        v-kog-focusable="true"
        v-tooltip="{
          theme: 'kog-tooltip',
          // eslint-disable-next-line max-len
          content:
            'The progress bar indicates your knowledge. Change the progress bar type in account settings.',
          popperClass: 'text-center',
          autoHide: false,
          boundary: 'document.body',
        }"
        class="far fa-question-circle"
      />
    </div>
    <div class="SubtopicQuestionCompletion-bar">
      <stats-emoji
        class="padding-right-xs"
        :stats-percent="questionCompletionProgressPercentageCorrectly"
      />
      <kog-progress-bar
        :progress="[
          { percentage: questionCompletionProgressPercentageCorrectly },
          { percentage: questionCompletionProgressPercentageIncorrectly },
        ]"
        size="s"
        type="mixed"
      />
    </div>
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';
import { mapGetters } from 'vuex';

import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import KogFocusable from 'sharedApp/directives/kog-focusable.js';

import StatsEmoji from './stats-emoji.vue';

export default {
  name: 'SubtopicQuestionCompletion',
  directives: {
    VTooltip,
    KogFocusable,
  },
  components: {
    KogProgressBar,
    StatsEmoji,
  },
  props: {
    subtopic: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      subjectNodeQuestionCompletionProgress:
        'statisticModule/subjectNodeQuestionCompletionProgress',
    }),
    questionCompletionProgressPercentageCorrectly() {
      const progress = this.subjectNodeQuestionCompletionProgress(this.subtopic.id);
      if (!progress || progress.totalQuestions <= 0) {
        return 0;
      }

      const { correctlyAnsweredQuestions, totalQuestions } = progress;

      return (correctlyAnsweredQuestions / totalQuestions) * 100;
    },
    questionCompletionProgressPercentageIncorrectly() {
      const progress = this.subjectNodeQuestionCompletionProgress(this.subtopic.id);
      if (!progress || progress.totalQuestions <= 0) {
        return 0;
      }

      const { incorrectlyAnsweredQuestions, totalQuestions } = progress;

      return (incorrectlyAnsweredQuestions / totalQuestions) * 100;
    },
  },
};
</script>

<style scoped>
.SubtopicQuestionCompletion {
  display: flex;
  flex-direction: column;
}

.SubtopicQuestionCompletion-bar {
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-right: var(--space-l);
}

.SubtopicQuestionCompletion-bar i {
  padding-right: 5px;
}
</style>
