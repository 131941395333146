import { ref } from 'vue';

import { fetchStudentsList as fetchStudentsListApi } from '@apis/subject-class.js';

type Student = {
  email: string;
  name: string;
  isGoogleStudent: boolean;
  isKognityStudent: boolean;
  upstreamId: string | undefined;
};
type GoogleStudent = {
  email: string;
  name: string;
  upstream_student_id: string;
};
type GoogleClass = {
  students: GoogleStudent[];
};
type SubjectClass = {
  id: number;
  google_classes: GoogleClass[];
};

export default function useStudentListByClassId() {
  const studentListByClassId = ref<Record<number, Student[]>>({});

  async function populateStudentListByClassIdForClass(subjectClass: SubjectClass) {
    if (!studentListByClassId.value[subjectClass.id]) {
      const studentList: Student[] = await fetchStudentsListApi(subjectClass.id);
      const googleClassStudents = subjectClass.google_classes.flatMap(googleClass => {
        return googleClass.students;
      });

      studentListByClassId.value[subjectClass.id] = studentList.map(student => {
        const googleStudent = googleClassStudents.find(
          gcStudent => gcStudent.email === student.email,
        );
        const isGoogleStudent = !!googleStudent;
        return {
          ...student,
          isGoogleStudent,
          isKognityStudent: true,
          upstreamId: isGoogleStudent ? googleStudent.upstream_student_id : undefined,
        };
      });

      googleClassStudents.forEach(googleStudent => {
        const isGoogleStudentOnKognity = studentListByClassId.value[subjectClass.id].some(
          student => student.email === googleStudent.email,
        );
        if (!isGoogleStudentOnKognity) {
          studentListByClassId.value[subjectClass.id].push({
            name: googleStudent.name,
            email: googleStudent.email,
            upstreamId: googleStudent.upstream_student_id,
            isGoogleStudent: true,
            isKognityStudent: false,
          });
        }
      });
    }
  }

  async function populateStudentListByClassId(subjectClasses: SubjectClass[]) {
    await Promise.all(subjectClasses.map(populateStudentListByClassIdForClass));
  }

  return { populateStudentListByClassId, studentListByClassId };
}
