<template>
  <div class="ReadingAssignmentStudentProgress">
    <h2 class="heading-xs margin-bottom-s">Task progress</h2>
    <kog-card>
      <kog-loader
        v-if="!isScheduledToSend"
        class="flexContainer flexContainer-column padd-left-xxs padd-right-xxs margin-bottom-xs"
        :loading="$wait.is('student_progress/*')"
        loading-msg="Loading students..."
      >
        <div v-if="isMultipleClassAssignmentEnabled">
          <subject-class-student-progress-accordion-item
            v-for="classProgress in studentProgressSummaryByClass"
            :key="classProgress.classId"
            class="ReadingAssignmentStudentProgress-accordionItem"
            :class-name="classProgress.name"
            :student-progress="classProgress.studentProgress"
            @student-clicked="openStudentProgressModal"
          />
        </div>
        <div v-else>
          <div class="flexContainer flexContainer-row width-full">
            <div class="text-overline width-50-percent">Students</div>
            <div class="text-overline width-50-percent">Progress</div>
          </div>
          <div
            v-for="student in students"
            :key="student.user_id"
            v-kog-clickable:[student.user_id]="
              hasUserValidData(student.user_id) ? openStudentProgressModal : undefined
            "
            class="flexContainer flexContainer-row width-full flexContainer-alignCenter margin-top-xs"
          >
            <div class="width-50-percent flexContainer flexContainer-row flexContainer-alignCenter">
              <kog-avatar
                v-if="['xl', 'l'].includes(mq.current)"
                :aria-label="`Student avatar for ${student.name}`"
                class="margin-right-xs"
                :name="student.name"
                size="s"
                :src="studentAvatarById[student.user_id]"
              />
              <div class="ReadingAssignmentStudentProgress-name margin-right-xs">
                {{ student.name }}
              </div>
            </div>
            <kog-progress-bar
              class="width-50-percent"
              :progress="getStudentCompletionPercentage(student.user_id)"
              size="s"
              type="positive"
            />
          </div>
        </div>
      </kog-loader>
      <kog-button
        v-else
        label="Edit recipients"
        button-style="primary"
        class="width-full"
        :is-loading="$wait.is('student_progress/*')"
        @click="showRecipientsModal"
      />
    </kog-card>
  </div>
</template>

<script>
import { inject } from 'vue';
import { storeToRefs } from 'pinia';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import { fetchUsersList } from '@apis/users.js';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { isReadingAssignmentOnTime } from 'sharedApp/services/assignment/assignment-utility-service.js';
import SelectRecipientsModal from 'studyApp/components/teacher/assignment-create/sendout-step/select-recipients-modal.vue';
import AssignmentMultiClassRecipientsModal from 'studyApp/components/teacher/assignments/assignment-multi-class-recipients-modal.vue';
import ReadingAssignmentDetailsModal from 'studyApp/components/teacher/assignments/details/reading-assignment-details-modal.vue';
import useStudentListByClassId from 'studyApp/composables/use-student-list-by-class-id.ts';
import SubjectClassStudentProgressAccordionItem from 'studyApp/pages/assignment/components/subject-class-student-progress-accordion-item.vue';

const STUDENT_LIST_LOADER = 'student_progress/fetching_student_list';

export default {
  name: 'ReadingAssignmentStudentProgress',
  components: {
    SubjectClassStudentProgressAccordionItem,
    KogAvatar,
    KogCard,
    KogButton,
    KogLoader,
    KogProgressBar,
  },
  mixins: [RoutesMixin],
  inject: ['mq'],
  props: {
    students: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    const assignmentStore = useAssignmentStore();
    const { isMultipleClassAssignmentEnabled } = storeToRefs(featureFlagStore);
    const { assignment } = storeToRefs(assignmentStore);
    const { saveAssignment } = assignmentStore;
    const readingAssignmentStudentProgress = inject('readingAssignmentStudentProgress', []);
    const { populateStudentListByClassId, studentListByClassId } = useStudentListByClassId();

    return {
      assignment,
      saveAssignment,
      isMultipleClassAssignmentEnabled,
      readingAssignmentStudentProgress,
      populateStudentListByClassId,
      studentListByClassId,
    };
  },
  data() {
    return {
      studentAvatarById: {},
    };
  },
  computed: {
    ...mapState({
      subject: state => state.schoolstaffSubjectModule.subject,
      allSubjectClasses: state => state.subjectClassModule.subjectClasses,
      subjectClassStudents: state => state.teacherStudentListModule.subjectClassStudentsList,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    subjectClass() {
      return this.subjectClassesById[this.classId];
    },
    subjectClasses() {
      return this.allSubjectClasses.filter(subjectClass => {
        return (
          this.subject.id === subjectClass.subject_id &&
          subjectClass.teacher_user_ids.includes(this.user.id)
        );
      });
    },
    nodeProgressByUserId() {
      return this.readingAssignmentStudentProgress.reduce((acc, progress) => {
        if (!acc[progress.user_id]) {
          acc[progress.user_id] = {};
        }

        acc[progress.user_id][progress.subjectnode_id] = {
          ...progress,
        };

        return acc;
      }, {});
    },
    studentProgressSummaryByUserId() {
      return this.readingAssignmentStudentProgress.reduce((acc, nodeProgress) => {
        if (!acc[nodeProgress.user_id]) {
          acc[nodeProgress.user_id] = {
            totalCount: 0,
            completedInTime: 0,
            completedLate: 0,
          };
        }

        if (nodeProgress.completed || nodeProgress.first_completed_at) {
          if (isReadingAssignmentOnTime(nodeProgress, this.assignment)) {
            acc[nodeProgress.user_id].completedInTime += 1;
          } else {
            acc[nodeProgress.user_id].completedLate += 1;
          }
        }
        acc[nodeProgress.user_id].totalCount += 1;
        return acc;
      }, {});
    },
    studentProgressSummaryByClass() {
      const progress = this.assignment.subject_classes.map(classId => {
        const subjectClass = this.subjectClassesById[classId];
        const studentIds = this.students.filter(student =>
          subjectClass.student_user_ids.includes(student.user_id),
        );
        const studentProgress = studentIds.map(student => {
          const completion = this.studentProgressSummaryByUserId[student.user_id];
          return {
            ...completion,
            completedCount: completion.completedInTime + completion.completedLate,
            name: student.name,
            avatar: this.studentAvatarById[student.user_id],
            user_id: student.user_id,
          };
        });
        return {
          classId,
          name: subjectClass.name,
          studentProgress,
        };
      });
      return progress;
    },
    defaultStudentId() {
      return +this.$route.query.student;
    },
    isScheduledToSend() {
      return this.assignment.is_scheduled_to_send || this.assignment.scheduled_send_at;
    },
  },
  async created() {
    this.fetchStudentAvatarById();
    this.fetchSubjectClassStudentList(this.subjectClass.id);
    if (this.isScheduledToSend) {
      await this.populateStudentListByClassId(this.subjectClasses);
    }
  },
  mounted() {
    if (this.defaultStudentId) {
      if (!this.hasUserValidData(this.defaultStudentId)) {
        this.$toast.showError('Student progress data is not available');
        return;
      }
      this.openStudentProgressModal(this.defaultStudentId);
    }
  },
  methods: {
    ...mapWaitingActions({
      fetchSubjectClassStudentList: {
        action: 'teacherStudentListModule/fetchSubjectClassStudentsList',
        loader: 'student_progress/fetching_student_list',
      },
    }),
    async fetchStudentAvatarById() {
      this.$wait.start(STUDENT_LIST_LOADER);
      const studentUserIds = this.students.map(student => student.user_id);
      const studentUsersList = await fetchUsersList(studentUserIds);
      this.studentAvatarById = studentUsersList.reduce((acc, user) => {
        if (user.profile.avatar_url) {
          acc[user.id] = user.profile.avatar_url;
        }
        return acc;
      }, {});
      this.$wait.end(STUDENT_LIST_LOADER);
    },
    getStudentCompletionPercentage(userId) {
      const studentProgress = this.studentProgressSummaryByUserId[userId];
      if (!studentProgress) return 0;
      const { totalCount, completedInTime, completedLate } =
        this.studentProgressSummaryByUserId[userId];
      if (totalCount === 0) return 0;

      const totalCompleted = completedInTime + completedLate;
      return Math.round(100 * (totalCompleted / totalCount));
    },
    hasUserValidData(userId) {
      const isStudentExisting = this.students.some(student => student.user_id === userId);
      return (
        this.studentProgressSummaryByUserId[userId] &&
        this.nodeProgressByUserId[userId] &&
        isStudentExisting
      );
    },
    openStudentProgressModal(userId) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          student: userId,
        },
      });

      const selectedStudent = this.students.find(student => student.user_id === userId);
      this.$modal(ReadingAssignmentDetailsModal, {
        assignment: this.assignment,
        student: {
          ...selectedStudent,
          avatar: this.studentAvatarById[userId],
        },
        studentAggregateProgress: this.studentProgressSummaryByUserId[userId],
        studentSectionProgress: this.nodeProgressByUserId[userId],
      });
    },
    showRecipientsModal() {
      if (this.isMultipleClassAssignmentEnabled) {
        return this.$modal(
          AssignmentMultiClassRecipientsModal,
          {
            initialSelectedStudentIds: this.assignment.selected_student_ids,
            updateRecipients: this.updateSelectedClassesAndStudents,
            googleClassroomRecipients: this.assignment.google_classroom_recipients,
            studentListByClassId: this.studentListByClassId,
          },
          {
            closeExisting: true,
          },
        );
      }

      const isStudentSelectedByNumber = this.subjectClassStudents.reduce((acc, student) => {
        acc[student.school_student] = this.students.some(s => s.user_id === student.user_id);
        return acc;
      }, {});
      return this.$modal(SelectRecipientsModal, {
        subject: this.subject,
        subjectClassStudents: this.subjectClassStudents,
        subjectClassName: this.subjectClass.name,
        selectedStudents: isStudentSelectedByNumber,
        updateSelectedStudents: this.updateSelectedStudents,
        googleClasses: this.subjectClass.google_classes,
        googleClassroomRecipients: this.assignment.google_classroom_recipients,
      });
    },
    updateSelectedClassesAndStudents(
      selectedClasses,
      selectedStudents,
      selectedGoogleClassStudentsByUpstreamId,
    ) {
      const gcRecipients = this.getGoogleClassroomRecipients(
        selectedGoogleClassStudentsByUpstreamId,
      );
      this.assignment.google_classroom_recipients = gcRecipients;
      this.assignment.selected_student_ids = selectedStudents.map(
        student => student.school_student,
      );
      this.assignment.subject_classes = selectedClasses.map(subjectClass => subjectClass.id);
      this.saveAssignment()
        .then(() => {
          this.$toast.showSuccess('Recipients updated successfully');
        })
        .catch(() => {
          this.$toast.showError('Failed to update recipients');
        });
    },
    /**
     * @deprecated When send to multi class gets released this method will be removed. Use updateSelectedClassesAndStudents instead.
     */
    updateSelectedStudents(isStudentSelectedBySchoolStudentId, selectedGCList) {
      const selectedStudentIds = Object.keys(isStudentSelectedBySchoolStudentId).reduce(
        (acc, schoolStudentId) => {
          if (isStudentSelectedBySchoolStudentId[schoolStudentId]) {
            acc.push(schoolStudentId);
          }
          return acc;
        },
        [],
      );

      if (selectedStudentIds.length === 0 && Object.keys(selectedGCList).length === 0) {
        this.$toast.error('Please select at least one student to send the assignment to');
        return;
      }

      this.assignment.google_classroom_recipients =
        this.getGoogleClassroomRecipients(selectedGCList);
      this.assignment.selected_student_ids = selectedStudentIds;
      this.saveAssignment();
    },
    getGoogleClassroomRecipients(selectedGCList) {
      const googleClasses = this.subjectClass.google_classes;

      if (googleClasses.length > 0) {
        return googleClasses.map(googleClass => {
          const filteredGCRecipients = googleClass.students.filter(
            googleStudent => selectedGCList[googleStudent.upstream_student_id],
          );
          const filteredGCRecipientsIds = filteredGCRecipients.map(
            recipient => recipient.upstream_student_id,
          );

          return {
            upstream_class_id: googleClass.id,
            recipient_upstream_ids: filteredGCRecipientsIds,
          };
        });
      }

      return [];
    },
  },
};
</script>

<style scoped>
.ReadingAssignmentStudentProgress .ProgressBar {
  width: 50%;
}

.ReadingAssignmentStudentProgress-name {
  overflow: hidden;
  white-space: nowrap;
}
.ReadingAssignmentStudentProgress-accordionItem {
  padding-bottom: var(--space-s);
}
.ReadingAssignmentStudentProgress-accordionItem:last-child {
  padding-bottom: 0;
}
</style>
