export default class StudyAppSessionStorage {
  #openDraftAssignmentIdKey;

  constructor() {
    this.storage = sessionStorage;
    this.#openDraftAssignmentIdKey = 'open-draft-assignment-id';
  }

  getOpenDraftAssignmentId(subjectClassId) {
    const key = this.#getOpenDraftAssignmentIdKey(subjectClassId);
    const value = this.storage.getItem(key);
    if (value) {
      return parseInt(value, 10) || null;
    }
    return null;
  }

  setOpenDraftAssignmentId(subjectClassId, value) {
    const key = this.#getOpenDraftAssignmentIdKey(subjectClassId);
    if (!value) {
      this.storage.removeItem(key);
    } else {
      this.storage.setItem(key, value.toString());
    }
    return value;
  }

  #getOpenDraftAssignmentIdKey(subjectClassId) {
    return `${this.#openDraftAssignmentIdKey}-${subjectClassId}`;
  }
}
