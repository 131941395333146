<template>
  <div>
    <span
      v-if="teachers.length > 0"
      class="screenreader-only"
    >
      {{ teachers.length }} class teachers: {{ getTeacherNames(teachers).join() }}
    </span>
    <div aria-hidden="true">
      <div
        v-if="teachers.length === 1"
        class="flexContainer flexContainer-alignCenter"
      >
        <kog-avatar
          :name="getTeacherFullName(teachers[0])"
          size="l"
          :src="teachers[0].profile.avatar_url"
          theme="accent"
        />
        <div class="heading-xxs margin-left-s">
          {{ getTeacherFullName(teachers[0]) }}
        </div>
      </div>
      <div
        v-else
        class="flexContainer"
      >
        <kog-avatar
          v-for="teacher in teachers.slice(0, numberOfAvatarsToShow)"
          :key="teacher.id"
          v-tooltip="{
            theme: 'kog-tooltip',
            content: getTeacherFullName(teacher),
          }"
          :name="getTeacherFullName(teacher)"
          size="l"
          :src="teacher.profile.avatar_url"
          theme="accent"
          class="margin-left-xxs"
        />
        <kog-avatar
          v-if="teachers.length > 5"
          v-tooltip="{
            theme: 'kog-tooltip',
            content: getTeacherNames(teachers.slice(numberOfAvatarsToShow, teachers.length)).join(
              '<br>',
            ),
          }"
          :name="`+ ${teachers.length - numberOfAvatarsToShow}`"
          size="l"
          class="margin-left-xxs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';

export default {
  name: 'TeacherAvatars',
  components: {
    KogAvatar,
  },
  props: {
    teachers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    numberOfAvatarsToShow() {
      if (this.teachers.length > 5) {
        return 4;
      }
      return 5;
    },
  },
  methods: {
    getTeacherFullName(teacher) {
      return `${teacher.first_name} ${teacher.last_name}`;
    },
    getTeacherNames(teachers) {
      return teachers.map(teacher => this.getTeacherFullName(teacher));
    },
  },
};
</script>
