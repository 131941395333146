<template>
  <div>
    <kog-textarea
      ref="textarea"
      v-model:value="editorContent"
      :is-label-hidden="true"
      :label="label"
    ></kog-textarea>
  </div>
</template>
<script>
import { nextTick } from 'vue';

import KogTextarea from 'sharedApp/components/base/textarea/kog-textarea.vue';

export default {
  name: 'QuestionEditorPlainText',
  components: {
    KogTextarea,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    initialFocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      editorContent: this.value,
    };
  },
  watch: {
    editorContent(val) {
      this.$emit('update:value', val);
      this.$emit('input', val);
    },
    value() {
      this.editorContent = this.value;
    },
  },
  mounted() {
    this.editorContent = this.value;
    if (this.initialFocus) {
      nextTick(() => {
        const childRef = this.$refs.textarea.$refs.kogTextarea;
        childRef.focus();
      });
    }
  },
};
</script>
