import { Plugin } from '@ckeditor/ckeditor5-core';

import questionIcon from 'publishingApp/assets/icons/circle-question-regular.svg';

import { registerFactoryView } from '../utils/plugin-utils.ts';

export default class InlineQuestionUI extends Plugin {
  init() {
    registerFactoryView(this.editor, {
      name: 'inlinequestion',
      commandName: 'insertInlineQuestion',
      icon: questionIcon,
      label: 'Inline question',
      onExecute: () => this.editor.execute('insertInlineQuestion'),
    });
  }
}
