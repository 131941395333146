<template>
  <kog-alert
    mode="info"
    :show-icon="true"
  >
    Hidden content will be made available to students after sending.
  </kog-alert>
</template>

<script>
import KogAlert from 'sharedApp/components/base/alert/kog-alert.vue';

export default {
  components: {
    KogAlert,
  },
};
</script>
