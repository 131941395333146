<template>
  <div>
    <div> {{ subtopicText }} </div>
    <h1> {{ subjectNodeName }} </h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

export default {
  name: 'BookPrintHeader',
  mixins: [RoutesMixin],
  computed: {
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getTopicNodeByDescendantId: 'subjectModule/getTopicNodeByDescendantId',
      getSubtopicNodeByDescendantId: 'subjectModule/getSubtopicNodeByDescendantId',
    }),
    subjectNode() {
      return this.subjectNodesById[this.nodeId];
    },
    subjectNodeName() {
      return this.subjectNode.name || 'Unknown section';
    },
    topicNode() {
      return this.getTopicNodeByDescendantId(this.nodeId);
    },
    subtopicNode() {
      return this.getSubtopicNodeByDescendantId(this.nodeId);
    },
    subtopicText() {
      return `${this.topicNode.number}.${this.subtopicNode.number} ${this.subtopicNode.name}`;
    },
  },
};
</script>
