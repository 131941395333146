const allowedSubjects = [
  56, // IBDP Mathematics SL
  58, // IBDP Mathematics HL
  134, // IBDP Mathematics: Analysis and approaches HL
  177, // IBDP Mathematics: Analysis and approaches SL
  189, // Cambridge IGCSE™ International Mathematics
  428, // Cambridge IGCSE™ Mathematics (0580/0980) FE2025 (NEW)
  430, // Cambridge IGCSE™ International Mathematics (0607) FE2025 (NEW)
];

export function calculatorIconAllowed(subjectId) {
  return allowedSubjects.includes(subjectId);
}

export default calculatorIconAllowed;
