<template>
  <div
    role="listitem"
    class="AssignmentListItem-assignment padd-m"
  >
    <div
      v-if="isNextLoadedAssignment"
      ref="nextAssignmentIntro"
      class="screenreader-only"
      tabindex="-1"
    >
      More assignments loaded from here: {{ htmlEntities(occasion.assignment.name) }}
    </div>
    <div
      :aria-hidden="isNextLoadedAssignment ? 'true' : null"
      class="text-bold line-clamp-1"
    >
      {{ htmlEntities(occasion.assignment.name) }}
    </div>
    <div class="flexContainer flexContainer-column flexContainer-spaceBetween flexChild-canGrow">
      <div>
        <div class="margin-top-xxs flexContainer flexContainer-wrap">
          <kog-tag
            class="margin-bottom-xxs margin-right-xxs"
            :context="assignmentTypeScreenReaderDescription"
            :label="assignmentType"
            :icon-class="assignmentTypeIcon"
            :is-svg-icon="assignmentTypeIcon === 'LTQ'"
          />
          <div class="width-full flexContainer flexContainer-column">
            <kog-tag
              v-for="subjectClass in subjectClasses"
              :key="subjectClass.id"
              class="AssignmentListItem-classNameTag margin-bottom-xxs"
              icon-class="fa-screen-users"
              :context="subjectClassScreenReaderDescription(subjectClass)"
              :label="subjectClass.name"
            />
          </div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="isReadingAssignment && !isCompleted"
          class="screenreader-only"
          v-html="getReadingAssignmentCompletion(true)"
        />
        <div
          v-if="isReadingAssignment && !isCompleted"
          aria-hidden="true"
          v-html="getReadingAssignmentCompletion(false)"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
      <div>
        <div
          class="margin-top-fine-1 flexContainer flexContainer-spaceBetween flexContainer-alignCenter"
        >
          <span> Deadline: {{ getDate(occasion.assignment.deadline) }} </span>
          <kog-tag
            v-if="isDueSoon && !isCompleted"
            type="negative"
            size="s"
            :context="dueMessageScreenReaderDescription"
            :label="dueMessage"
          />
        </div>
        <div
          v-if="!subscriptionAccess"
          class="margin-top-s"
        >
          <div
            class="KogButtonLegacy KogButtonLegacy--default KogButtonLegacy--s AssignmentListItem-assignmentButtonContainer"
          >
            <kog-icon
              size="s"
              fa-style="regular"
              icon-class="fa-lock-alt"
              theme="custom"
            />
            Subject locked
          </div>
        </div>
        <div v-else>
          <div
            v-if="!isCompleted"
            class="margin-top-fine-2"
          >
            <router-link
              v-show="!isStarted"
              :to="assignmentUrl"
              class="KogButtonLegacy KogButtonLegacy--primary KogButtonLegacy--s AssignmentListItem-assignmentButtonContainer"
              target="_self"
              :aria-label="getAssignmentLinkLabel('Take ')"
              :tabindex="tabIndex"
            >
              Take assignment now
            </router-link>
            <router-link
              v-if="isStarted"
              :to="assignmentUrl"
              target="_self"
              class="KogButtonLegacy KogButtonLegacy--primary KogButtonLegacy--s AssignmentListItem-assignmentButtonContainer"
              :aria-label="getAssignmentLinkLabel('Continue ')"
              :tabindex="tabIndex"
            >
              Continue assignment
            </router-link>
          </div>
          <div
            v-else
            class="margin-top-fine-2"
          >
            <router-link
              :to="assignmentUrl"
              :aria-label="getAssignmentLinkLabel('View ')"
              target="_self"
              class="KogButtonLegacy KogButtonLegacy--s AssignmentListItem-assignmentButtonContainer"
              :tabindex="tabIndex"
            >
              View assignment
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import htmlEntities from 'learning/common/libs/html-entities.js';

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import {
  getTakeAssignmentRoute,
  isReadingAssignmentCompleted,
} from 'sharedApp/services/assignment/assignment-utility-service.js';

export default {
  name: 'AssignmentListItem',
  components: {
    KogIcon,
    KogTag,
  },
  props: {
    occasion: {
      type: Object,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    isReadingAssignment: {
      type: Boolean,
      required: true,
    },
    currentIndex: {
      type: Number,
      default: null,
    },
    indexToSetMessageAt: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      subjectClassesById: 'subjectClassModule/subjectClassesById',
    }),
    subjectClassId() {
      if (this.$route.params.cid) {
        return parseInt(this.$route.params.cid, 10);
      }
      return this.assignment.subject_classes[0];
    },
    subjectClassIds() {
      return this.occasion.assignment.subject_classes;
    },
    subjectClasses() {
      return this.subjectClassIds
        .filter(id => this.subjectClassesById[id])
        .map(id => this.subjectClassesById[id]);
    },
    subscriptionAccess() {
      return this.occasion.subscription_access === 'subscription_ok';
    },
    isNextLoadedAssignment() {
      if (this.indexToSetMessageAt && this.currentIndex) {
        return this.indexToSetMessageAt === this.currentIndex;
      }
      return false;
    },
    assignment() {
      return this.occasion.assignment;
    },
    practiceOccasion() {
      return this.occasion.practice_occasion;
    },
    assignmentType() {
      const examStyleTerm =
        this.assignment.education_system_name === 'IGCSE' ? 'Test practice' : 'Exam-style';
      const assignmentTypes = {
        READING: 'Book',
        QUESTION: 'Questions',
        EXAMSTYLE: examStyleTerm,
      };
      return assignmentTypes[this.assignment.assignment_type];
    },
    assignmentTypeIcon() {
      const assignmentIcons = {
        READING: 'fa-bookmark',
        QUESTION: 'fa-pencil-alt',
        EXAMSTYLE: 'LTQ',
      };
      return assignmentIcons[this.assignment.assignment_type];
    },
    assignmentUrl() {
      const { assignment, subjectClassesById, subjectClassId } = this;
      const subjectClass = subjectClassesById[subjectClassId];

      if (subjectClass && assignment) {
        const { slug: classSlug, id: classId } = subjectClass;
        return getTakeAssignmentRoute({
          assignment,
          subjectId: assignment.subject_id,
          classId,
          classSlug,
          source: 'dashboard',
        });
      }

      return '';
    },
    assignmentTypeScreenReaderDescription() {
      return `${this.assignmentType} assignment.`;
    },
    dueMessageScreenReaderDescription() {
      return `The assignment is ${this.dueMessage}.`;
    },
    isStarted() {
      return this.practiceOccasion && this.practiceOccasion.status === 'STARTED';
    },
    isDueSoon() {
      const now = new Date();
      const { deadline } = this.assignment;
      const deadlineDate = new Date(deadline);
      const remainingDays = this.calcRemainingDays(now, deadlineDate);
      return remainingDays <= 3;
    },
    dueMessage() {
      const now = new Date();
      const { deadline } = this.assignment;
      const deadlineDate = new Date(deadline);
      const remainingDays = this.calcRemainingDays(now, deadlineDate);
      if (remainingDays < 0 || deadlineDate < now) {
        return 'Late';
      }
      const remainingDaysMsg = ['Due today', 'Due tomorrow', 'Due in 2 days', 'Due in 3 days'];
      return remainingDaysMsg[remainingDays] || '';
    },
    tabIndex() {
      if (this.currentIndex === 0) {
        return 0;
      }
      return -1;
    },
  },
  mounted() {
    if (this.isNextLoadedAssignment) {
      this.$refs.nextAssignmentIntro.focus();
    }
  },
  methods: {
    getAssignmentLinkLabel(prefix) {
      return `${prefix} assignment ${this.assignment.name}, ${this.dueMessage}.`;
    },
    getReadingAssignmentCompletion(forScreenreader) {
      const assignmentOccasion = this.occasion;
      const total = assignmentOccasion.readingitem_set.length;
      const completed = assignmentOccasion.readingitem_set.filter(readingItem =>
        isReadingAssignmentCompleted(readingItem.node_progress),
      ).length;
      if (completed === total) {
        const url = this.assignmentUrl;
        return `
          <strong>
            You’ve completed <a href="${url}">all sections</a>
            <span class="KogEmoji KogEmoji--large KogEmoji--tada" aria-hidden="true" />
          <strong>
        `;
      }
      if (forScreenreader) {
        return `${completed} out of ${total} sections completed`;
      }
      return `${completed}/${total} sections completed`;
    },
    getDate(date) {
      return new Date(date).toLocaleDateString('UTC', {
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    calcRemainingDays(currentDate, deadlineDate) {
      const oneDayInMs = 24 * 60 * 60 * 1000;
      const dayBeforeAssignment = new Date(deadlineDate.getTime() - oneDayInMs);
      const twoDaysBeforeAssignment = new Date(deadlineDate.getTime() - oneDayInMs * 2);
      const threeDaysBeforeAssignment = new Date(deadlineDate.getTime() - oneDayInMs * 3);

      const today = currentDate.toDateString();

      if (today === deadlineDate.toDateString()) return 0;
      if (today === dayBeforeAssignment.toDateString()) return 1;
      if (today === twoDaysBeforeAssignment.toDateString()) return 2;
      if (today === threeDaysBeforeAssignment.toDateString()) return 3;

      const timeBetween = deadlineDate.getTime() - currentDate.getTime();
      return Math.floor(timeBetween / oneDayInMs);
    },
    htmlEntities,
    subjectClassScreenReaderDescription(subjectClass) {
      return `Assignment for ${subjectClass.name}.`;
    },
  },
};
</script>

<style scoped>
.AssignmentListItem-assignment {
  display: flex;
  flex-direction: column;

  margin-bottom: var(--space-xs);

  background: var(--kogPlatformWhite);
  border-radius: 5px;
  box-shadow: 0 4px 8px -2px var(--kogShadow020);
}

.AssignmentListItem-assignmentButtonContainer {
  width: 100%;
}

@media (--viewport-s) {
  .AssignmentListItem-assignment {
    flex: 0 0 300px;
    width: 300px;
    margin-right: 10px;
    margin-bottom: var(--space-l);
  }
}
.AssignmentListItem-classNameTag {
  width: fit-content;
  max-width: 100%;
}
</style>
