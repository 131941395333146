<template>
  <kog-modal :title="title">
    <template #modalBody>
      <p>{{ text }}</p>
    </template>
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right">
        <button
          class="KogButtonLegacy KogButtonLegacy--primary"
          @click="closeModal"
        >
          OK
        </button>
      </div>
    </template>
  </kog-modal>
</template>

<script>
import KogModal from 'sharedApp/components/modals/kog-modal.vue';

export default {
  name: 'KogInfoModal',
  components: {
    KogModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
};
</script>
