<template>
  <kog-table title="Join a class in your school">
    <template #subtitle>
      <p>The following classes have already been added to your school.</p>
      <div class="kogPlatformGray064 text-small">
        {{ subjectClasses.length }} {{ subjectClasses.length === 1 ? 'class' : 'classes' }}
      </div>
    </template>
    <template #colgroup>
      <col />
      <col width="34%" />
      <col width="33%" />
      <col width="33%" />
    </template>
    <template #header>
      <kog-table-header>
        <kog-table-cell-header />
        <kog-table-cell-header> Class </kog-table-cell-header>
        <kog-table-cell-header> Subject </kog-table-cell-header>
        <kog-table-cell-header> Teacher </kog-table-cell-header>
      </kog-table-header>
    </template>
    <template #body>
      <kog-table-row
        v-for="(subjectClass, index) in sortedSubjectClasses"
        :key="subjectClass.id"
        :row-index="index"
      >
        <kog-table-cell-checkbox
          :is-disabled="subjectClassIsUpdating[subjectClass.id]"
          :is-checked="isTeacherInClass(subjectClass)"
          :aria-label="getCheckboxAriaLabel(subjectClass)"
          @check="toggleClass(subjectClass)"
          @uncheck="toggleClass(subjectClass)"
        />
        <kog-table-cell-text :is-multi-line="true">
          {{ subjectClass.name }}
        </kog-table-cell-text>
        <kog-table-cell-text :is-multi-line="true">
          {{ subjectsById[subjectClass.subject_id].display_name }}
        </kog-table-cell-text>
        <kog-table-cell-text
          :is-multi-line="true"
          :tooltip-text="teacherTooltipText(subjectClass)"
        >
          <span class="screenreader-only">
            {{ teacherScreenReaderText(subjectClass) }}
          </span>
          <span aria-hidden="true">
            {{ teacherCellText(subjectClass) }}
          </span>
        </kog-table-cell-text>
      </kog-table-row>
    </template>
  </kog-table>
</template>

<script>
import KogTableCellCheckbox from 'sharedApp/components/tables/kog-table-cell-checkbox.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import KogTable from 'sharedApp/components/tables/kog-table.vue';

export default {
  name: 'JoinClassTable',
  components: {
    KogTable,
    KogTableRow,
    KogTableCellText,
    KogTableCellCheckbox,
    KogTableCellHeader,
    KogTableHeader,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    teacherUsersBySubjectClassId: {
      type: Object,
      required: true,
    },
    subjectsById: {
      type: Object,
      required: true,
    },
    subjectClasses: {
      type: Array,
      required: true,
    },
    addSubjectClass: {
      type: Function,
      required: true,
    },
    removeSubjectClass: {
      type: Function,
      required: true,
    },
  },
  emits: ['update-class-success', 'update-class-fail'],
  data() {
    return {
      subjectClassIsUpdating: {},
    };
  },
  computed: {
    sortedSubjectClasses() {
      return [...this.subjectClasses].sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );
    },
  },
  methods: {
    isTeacherInClass(subjectClass) {
      return subjectClass.teacher_user_ids.includes(this.user.id);
    },
    getCheckboxAriaLabel(subjectClass) {
      const isInClass = this.isTeacherInClass(subjectClass);

      if (isInClass) {
        return `Uncheck to leave ${subjectClass.name}`;
      }

      return `Check to join ${subjectClass.name}`;
    },
    toggleClass(subjectClass) {
      this.subjectClassIsUpdating[subjectClass.id] = true;
      const updateMethod = this.isTeacherInClass(subjectClass)
        ? this.removeSubjectClass
        : this.addSubjectClass;

      updateMethod(subjectClass.subject_id, subjectClass.id).then(
        () => this.saveSuccess(subjectClass.id),
        () => this.saveFailure(subjectClass.id),
      );
    },
    saveSuccess(subjectClassId) {
      this.subjectClassIsUpdating[subjectClassId] = false;
      this.$emit('update-class-success', subjectClassId);
    },
    saveFailure(subjectClassId) {
      this.subjectClassIsUpdating[subjectClassId] = false;
      this.$emit('update-class-fail', subjectClassId);
    },
    teacherScreenReaderText(subjectClass) {
      if (this.getSubjectClassTeachers(subjectClass.id).length > 1) {
        return this.teacherTooltipText(subjectClass);
      }

      return this.teacherCellText(subjectClass);
    },
    getSubjectClassTeachers(subjectClassId) {
      return this.teacherUsersBySubjectClassId[subjectClassId];
    },
    teacherTooltipText(subjectClass) {
      const teachers = this.getSubjectClassTeachers(subjectClass.id);
      if (teachers.length > 1) {
        return teachers.map(t => this.getUserName(t)).join(', ');
      }

      return '';
    },
    getUserName(user) {
      return `${user.first_name} ${user.last_name}`;
    },
    teacherCellText(subjectClass) {
      const teachers = this.getSubjectClassTeachers(subjectClass.id);
      if (teachers.length === 0) {
        return 'No teacher yet';
      }
      let text = this.getUserName(teachers[0]);
      if (teachers.length > 1) {
        text += ` + ${teachers.length - 1} more`;
      }

      return text;
    },
  },
};
</script>
