import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView, createDropdown, SplitButtonView } from '@ckeditor/ckeditor5-ui';

import filePenIcon from 'publishingApp/assets/icons/file-pen-regular.svg';

import ExerciseQuestion from '../exercise-question/exercise-question.js';

export default class ReflectionBoxUI extends Plugin {
  static get requires() {
    return [ExerciseQuestion];
  }

  init() {
    this.editor.ui.componentFactory.add('reflectionBox', locale => {
      const actionButton = new ButtonView(locale);
      actionButton.set({
        label: 'Reflection box',
        icon: filePenIcon,
        tooltip: true,
        withText: true,
      });

      actionButton.on('execute', () => this.editor.execute('insertReflectionBox'));

      const splitButton = new SplitButtonView(locale, actionButton);

      const dropdownView = createDropdown(locale, splitButton);
      dropdownView.once('change:isOpen', () => {
        const reflectionQuestionButton = this.editor.ui.componentFactory.create('exerciseQuestion');
        reflectionQuestionButton.set({
          label: 'Add reflection question',
        });
        dropdownView.panelView.children.add(reflectionQuestionButton);
      });

      return dropdownView;
    });
  }
}
