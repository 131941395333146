import { compile } from 'path-to-regexp';

import { getCsrfToken } from 'sharedApp/utils/csrf.ts';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const root = '/api/activity-sheets';
const rootUrl = compile([root, ':sheetId/'].join('/'));
const occasionDetailUrl = compile([root, ':sheetId/occasions/:occasionId/'].join('/'));
const occasionListUrl = compile([root, ':sheetId/occasions/'].join('/'));
const occasionResponseListUrl = compile(
  [root, ':sheetId/occasions/:occasionId/responses/'].join('/'),
);
const occasionResponseDetailUrl = compile(
  [root, ':sheetId/occasions/:occasionId/responses/:responseId/'].join('/'),
);
const currentOccasionUrl = compile([root, ':sheetId/occasions/current/'].join('/'));
const cleanupOccasionResponseDetailUrl = compile(
  [root, ':sheetId/occasions/:occasionId/responses/:responseId/cleanup/'].join('/'),
);

export async function fetchActivitySheet(sheetId) {
  const url = rootUrl({ sheetId });
  const response = await axios.get(url);
  return response.data;
}

export async function fetchCurrentActivitySheetOccasion(
  sheetId,
  subjectClassId,
  showTeacherOccasion,
) {
  const url = currentOccasionUrl({ sheetId });
  const response = await axios.get(url, {
    validateStatus: status => status === 200 || status === 404,
    params: {
      subject_class: subjectClassId,
      show_own: showTeacherOccasion || null,
    },
  });
  return response.status === 200 ? response.data : null;
}

export async function fetchActivitySheetOccasions(sheetId, subjectClassId) {
  const url = occasionListUrl({ sheetId });
  const response = await axios.get(url, { params: { subject_class_id: subjectClassId } });
  return response.data;
}

export async function createActivitySheetOccasion(sheetId, subjectClassId) {
  const url = occasionListUrl({ sheetId });
  const response = await axios.post(url, {
    subject_class: subjectClassId,
  });
  return response.data;
}

export async function updateActivitySheetOccasion({ sheetId, occasionId }, payload) {
  const url = occasionDetailUrl({ sheetId, occasionId });
  const response = await axios.patch(url, payload);
  return response.status === 200 ? response.data : null;
}

export async function createActivitySheetOccasionResponse({ sheetId, occasionId }, userResponse) {
  const { questionId, ...rest } = userResponse;
  const url = occasionResponseListUrl({ sheetId, occasionId });
  const response = await axios.post(url, {
    question_id: questionId,
    ...rest,
  });
  return response.data;
}

export async function updateActivitySheetOccasionResponse(
  { sheetId, occasionId, responseId },
  payload,
) {
  const url = occasionResponseDetailUrl({ sheetId, occasionId, responseId });
  const response = await axios.put(url, { ...payload });
  return response.data;
}

export async function deleteActivitySheetOccasionResponse({ sheetId, occasionId, responseId }) {
  const url = occasionResponseDetailUrl({ sheetId, occasionId, responseId });
  await axios.delete(url);
}

export function beaconDeleteActivitySheetOccasionResponse({ sheetId, occasionId, responseId }) {
  const url = cleanupOccasionResponseDetailUrl({ sheetId, occasionId, responseId });
  const formData = new FormData();
  formData.append('csrfmiddlewaretoken', getCsrfToken());

  navigator.sendBeacon(url, formData);
}

export async function fetchResponses({ sheetId, occasionId }) {
  const url = occasionResponseListUrl({ sheetId, occasionId });
  const response = await axios.get(url);
  return response.data;
}
