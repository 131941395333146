import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const baseApiUrl = '/api/features/dqb/';
const featureUrlToPath = compile(`${baseApiUrl}:featureId/`);

export default {
  async createInstance({ featureTitle, subjectNodeId }) {
    const response = await axios.post(baseApiUrl, {
      title: featureTitle,
      subject_node_id: subjectNodeId,
    });
    return response.data;
  },
  async removeInstance(featureId) {
    const url = featureUrlToPath({ featureId });
    const response = await axios.delete(url);
    return response.data;
  },
  async fetchInstance(featureId) {
    const url = featureUrlToPath({ featureId });
    const response = await axios.get(url);
    return response.data;
  },
};
