<template>
  <kog-loader
    :loading="isLoading"
    :class="{ 'SubjectClassOverview--loading': isLoading }"
  >
    <article>
      <subject-class-overview-hero
        :subject-class-name="subjectClassName"
        :subject-name="subjectName"
        :subject-logo-url="subjectLogoUrl"
        :teachers="currentSubjectClassTeachers"
        :student-count="subjectClassStudentsList.length"
        :is-teacher="user.isTeacher()"
        :education-system-name="educationSystemName"
        :endorsed="endorsed"
      />
      <div class="kog-container SubjectClassOverview-container">
        <div class="flexContainer flexContainer-flexEnd">
          <edit-visibility-buttons
            v-if="isEditingVisibility"
            class="SubjectClassOverview-editVisibilityButtons margin-top-xs"
            :subject-class-id="classId"
          />
        </div>

        <div class="kog-row">
          <slide-in
            class="kog-col-4"
            enter-from="left"
            :is-showing-child="showTableOfContents"
          >
            <kog-card
              v-if="selectedTopicId !== undefined"
              class="SubjectClassOverview-tableOfContents KogCard-contentSlot--radius"
              :default-padding="false"
            >
              <table-of-contents
                :id="tableOfContentsId"
                v-model:show-table-of-contents="showTableOfContents"
                :topics="topics"
                :expanded-topic-id="selectedTopicId"
              />
            </kog-card>
          </slide-in>
          <div class="kog-col-8 SubjectClassOverview-middleContainer">
            <div class="kogPlatformWhite-bg padd-xs border-radius-8 margin-bottom-s flexContainer">
              <kog-toggleable-button
                :description="
                  showTableOfContents
                    ? 'Hide the table of contents'
                    : 'Show the table of contents before'
                "
                label="Table of contents"
                class="ProductTour-tableOfContents margin-right-xs"
                :is-active="showTableOfContents"
                @click="toggleToc"
              />
              <kog-toggleable-button
                v-if="shouldDisplayFilters"
                class="margin-right-xs"
                label="Filters"
                :disabled="isEditingVisibility"
                :description="showFilters ? 'Hide filters' : 'Show filters'"
                :is-active="showFilters"
                :tooltip="
                  isEditingVisibility
                    ? 'Filters cannot be used while hiding or showing content'
                    : ''
                "
                :aria-expanded="showFilters"
                aria-controls="subjectOverviewFiltersContainer"
                @click="toggleFilters"
              />
              <kog-toggleable-button
                v-if="isUserTeacher"
                label="Hide/show content"
                :disabled="disableShowHide"
                :is-active="isEditingVisibility"
                :tooltip="showHideDisabledTooltip"
                @click="toggleEditVisibilityMode"
              />
              <div class="divider-left margin-left-xs margin-right-xs" />
              <kog-button
                label="Media library"
                button-style="basic"
                @click="
                  $router.push({
                    name: 'classMediaLibrary',
                    params: {
                      classSlug: subjectClassSlug,
                      sid: subjectId,
                      cid: classId,
                    },
                  })
                "
              />
              <kog-round-button
                v-if="isUserTeacher"
                class="margin-left-auto"
                icon-class="fa-gear"
                button-style="basic"
                aria-label="Manage class"
                tooltip="Manage class"
                @click="
                  $router.push({
                    name: 'manageClass',
                    params: {
                      classSlug: subjectClassSlug,
                      sid: subjectId,
                      cid: classId,
                    },
                  })
                "
              />
            </div>
            <section>
              <vertical-expand transition-mode="out-in">
                <div
                  v-if="isTeacherWelcomeMessageVisible"
                  class="kog-row margin-bottom-s"
                >
                  <div class="kog-col-12">
                    <div
                      class="SubjectClassOverview-teacherWelcomeContainer kogPlatformWhite-bg border-radius-8"
                    >
                      <teacher-welcome-message
                        @display-onboarding-modal="displayOnboardingModal"
                        @dismiss="dismissTeacherWelcomeMessage"
                      />
                    </div>
                  </div>
                </div>
              </vertical-expand>
              <vertical-expand transition-mode="out-in">
                <subject-overview-filters
                  v-if="showFilters"
                  :filters="filters"
                  :is-filtering="isFiltering"
                  :number-of-filtered-items="numberOfFilteredItems"
                  class="margin-bottom-xs"
                  @update-filter="updateActiveFilter"
                />
              </vertical-expand>
              <template v-if="isShowingAllTopics">
                <template v-if="filteredTopics.length > 0">
                  <kog-card
                    v-for="topic in filteredTopics"
                    :key="topic.id"
                    :default-padding="false"
                  >
                    <subject-overview-topic
                      :esq-stats-by-topic-id="esqStatsByTopicId"
                      :is-feature-list-loading="isFeatureListLoading"
                      :is-ngss-subject="isNgssSubject"
                      :is-teacher="isUserTeacher"
                      :students="subjectClassStudentsList"
                      :subject-name="subjectName"
                      :topic="topic"
                      class="margin-bottom-s"
                    />
                  </kog-card>
                </template>
                <kog-card
                  v-else
                  :default-padding="false"
                >
                  <subject-overview-empty-topic />
                </kog-card>
              </template>
              <kog-card
                v-else
                class="absoluteContainer"
                :default-padding="false"
              >
                <subject-overview-topic-paginator
                  class="padd-right-l padd-top-l positionAbsolute positionAbsolute-right"
                  :should-display-pagination-numbers="false"
                  :current-topic-index="currentTopicIndex"
                  :topics="topics"
                  :navigation-description="getPaginatorNavigationDescription('below')"
                  @select-topic="selectTopicByIndex"
                />
                <subject-overview-topic
                  v-if="currentTopic"
                  :key="currentTopic.id"
                  :esq-stats-by-topic-id="esqStatsByTopicId"
                  :is-feature-list-loading="isFeatureListLoading"
                  :is-ngss-subject="isNgssSubject"
                  :is-teacher="isUserTeacher"
                  :students="subjectClassStudentsList"
                  :subject-name="subjectName"
                  :topic="currentTopic"
                />
                <subject-overview-empty-topic v-else />
                <div class="SubjectClassOverview-topicPaginatorContainer">
                  <subject-overview-topic-paginator
                    :current-topic-index="currentTopicIndex"
                    :topics="topics"
                    :navigation-description="getPaginatorNavigationDescription('above')"
                    @select-topic="selectTopicByIndex"
                  />
                </div>
              </kog-card>
            </section>
          </div>
          <slide-in
            enter-from="right"
            :is-showing-child="!showTableOfContents"
            class="kog-col-4"
          >
            <section class="SubjectClassOverview-rightContainer">
              <professional-development-card
                v-if="shouldShowEducatorHubLink"
                :icon-url="require('sharedApp/img/educator-hub-icon.png')"
                title="Kognity’s Educator Hub"
                description="Discover videos & guides to develop your skills and maximize Kognity"
                link="https://kognity.com/educator-hub/"
                link-copy="Get started"
              />
              <assignments-overview-card v-if="isUserStudent" />
              <suspense v-else>
                <template #default>
                  <teacher-assignment-overview-card :subject-class-id="classId" />
                </template>
                <template #fallback>
                  <kog-loader
                    :loading="true"
                    loading-msg="Loading assignments..."
                  />
                </template>
              </suspense>
            </section>
          </slide-in>
        </div>
      </div>
    </article>
  </kog-loader>
</template>

<script>
import { computed, provide, ref } from 'vue';
import { useHead } from '@unhead/vue';
import sumBy from 'lodash/sumBy.js';
import { storeToRefs } from 'pinia';
import { mapWaitingActions } from 'vue-wait';
import { mapActions, mapGetters, mapMutations, mapState, useStore } from 'vuex';

import { ContentAttributesCategoryEnumEnum } from '@apis/generated/models.ts';

import useAssignmentStore from 'learning/common/store-modules/assignment.ts';
import useFeatureFlagStore from 'learning/common/store-modules/feature-flag.ts';

import SlideIn from 'sharedApp/animations/slide-in.vue';
import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogToggleableButton from 'sharedApp/components/base/buttons/kog-toggleable-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogCard from 'sharedApp/components/card/kog-card.vue';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';
import useWaitFor from 'sharedApp/composables/use-wait-for.ts';
import {
  DRIVING_QUESTION_BOARD,
  FEATURES_BY_TYPE,
  PERFORMANCE_TASK,
  QUIZ,
} from 'sharedApp/const/features.js';
import posthog from 'sharedApp/libs/posthog.ts';
import { getLeafNodes } from 'sharedApp/libs/subject-tree-functions.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ScrollPositionMixin from 'sharedApp/mixins/scroll-position-mixin.js';
import {
  isIBDP,
  isIGCSE,
  isNgss,
} from 'sharedApp/services/educationSystem/education-system-service.js';
import TeacherAssignmentOverviewCard from 'studyApp/components/assignment/teacher-assignment-overview-card.vue';
import OnboardingModal from 'studyApp/components/onboarding-modal/onboarding-modal.vue';
import AssignmentsOverviewCard from 'studyApp/components/subject-class-overview/assignments-overview-card.vue';
import EditVisibilityButtons from 'studyApp/components/subject-class-overview/edit-visibility-buttons.vue';
import NewProgressBarModal from 'studyApp/components/subject-class-overview/new-progress-bar-modal.vue';
import ProfessionalDevelopmentCard from 'studyApp/components/subject-class-overview/professional-development-card.vue';
import SubjectClassOverviewHero from 'studyApp/components/subject-class-overview/subject-class-overview-hero.vue';
import SubjectOverviewEmptyTopic from 'studyApp/components/subject-class-overview/subject-overview-empty-topic.vue';
import SubjectOverviewFilters from 'studyApp/components/subject-class-overview/subject-overview-filters.vue';
import SubjectOverviewTopicPaginator from 'studyApp/components/subject-class-overview/subject-overview-topic-paginator.vue';
import SubjectOverviewTopic from 'studyApp/components/subject-class-overview/subject-overview-topic.vue';
import TableOfContents from 'studyApp/components/subject-class-overview/table-of-contents.vue';
import TeacherWelcomeMessage from 'studyApp/components/subject-class-overview/teacher-welcome-message.vue';
import useSubjectTreeFilter from 'studyApp/composables/use-subject-tree-filter.ts';
import { ALL_TOPIC_ID } from 'studyApp/constants/subject-class-overview-constants.js';
import TOCMixin from 'studyApp/mixins/toc-mixin.js';
import StudyAppLocalStorage from 'studyApp/utils/local-storage-utils.js';
import { getEsqStatsByTopic } from 'studyApp/utils/stats-utils.js';

const studyAppLocalStorage = new StudyAppLocalStorage(localStorage);

export default {
  name: 'SubjectClassOverview',
  components: {
    KogToggleableButton,
    SubjectOverviewFilters,
    EditVisibilityButtons,
    ProfessionalDevelopmentCard,
    TeacherWelcomeMessage,
    KogCard,
    KogButton,
    KogLoader,
    AssignmentsOverviewCard,
    TeacherAssignmentOverviewCard,
    SubjectOverviewTopicPaginator,
    SubjectOverviewEmptyTopic,
    SubjectClassOverviewHero,
    TableOfContents,
    SubjectOverviewTopic,
    SlideIn,
    VerticalExpand,
    KogRoundButton,
  },
  mixins: [RoutesMixin, TOCMixin, ScrollPositionMixin],
  setup() {
    const store = useStore();
    const featureFlagStore = useFeatureFlagStore();

    const { isShowFiltersOnClassOverviewEnabled } = storeToRefs(featureFlagStore);
    const { schoolstaffSubjectclassModule } = store.state;
    const { waitFor } = useWaitFor();
    const { fetchAssignments } = useAssignmentStore();

    const subjectClassName = computed(() => schoolstaffSubjectclassModule.subjectClass?.name);

    const { filterSubjectTree, filterActivitiesFactory } = useSubjectTreeFilter();

    useHead({
      title: () =>
        subjectClassName.value ? `${subjectClassName.value} | Class Overview` : 'Class Overview',
    });

    const { getUniqueId } = useUniqueId();

    const filters = ref([
      {
        name: 'Hands-on labs',
        icon: 'fa-microscope',
        isActive: false,
        useAttributes: true,
        type: ContentAttributesCategoryEnumEnum.HANDS_ON_LAB,
      },
      {
        name: 'Quizzes',
        icon: FEATURES_BY_TYPE[QUIZ].icon,
        isActive: false,
        useAttributes: false,
        type: QUIZ,
      },
      {
        name: 'Virtual labs',
        icon: 'fa-vr-cardboard',
        isActive: false,
        useAttributes: true,
        type: ContentAttributesCategoryEnumEnum.VIRTUAL_LAB,
      },
      {
        name: 'DQB',
        icon: FEATURES_BY_TYPE[DRIVING_QUESTION_BOARD].icon,
        isActive: false,
        useAttributes: false,
        type: DRIVING_QUESTION_BOARD,
      },
      {
        name: 'Performance tasks',
        icon: FEATURES_BY_TYPE[PERFORMANCE_TASK].icon,
        isActive: false,
        useAttributes: false,
        type: PERFORMANCE_TASK,
      },
    ]);
    const activeFilters = computed(() => filters.value.filter(filter => filter.isActive));
    const activeFiltersString = computed(() =>
      activeFilters.value.map(filter => filter.name).join(', '),
    );
    const isFiltering = computed(() => activeFilters.value.length > 0);
    provide('isFilteringSubjectClassOverview', isFiltering);
    provide('activeFiltersInSubjectClassOverview', activeFiltersString);
    return {
      isShowFiltersOnClassOverviewEnabled,
      getUniqueId,
      fetchAssignments: waitFor('fetching_subject_assignments', fetchAssignments),
      filterSubjectTree,
      filterActivitiesFactory,
      filters,
      activeFilters,
      isFiltering,
    };
  },
  data() {
    return {
      showTableOfContents: studyAppLocalStorage.getAutoOpenSubjectClassOverviewToc(),
      showFilters: false,
    };
  },
  computed: {
    ...mapState({
      subjectClassStudentsList: state => state.teacherStudentListModule.subjectClassStudentsList,
      subject: state => state.subjectModule.subject,
      subjectList: state => state.subjectModule.subjectList,
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
      user: state => state.userModule.user,
      userProfile: state => state.teacherDetailModule.userProfile,
      questionMappings: state => state.examStyleQuestionModule.questionMappings,
      currentSubjectClassTeachers: state => state.subjectClassModule.currentSubjectClassTeachers,
      subjectClasses: state => state.subjectClassModule.subjectClasses,
      isEditingVisibility: state => state.subjectClassModule.isEditingVisibility,
      visibilityEditingTransactionToken: state =>
        state.subjectClassModule.visibilityEditingTransactionToken,
    }),
    ...mapGetters({
      subtopicIds: 'subjectModule/subtopicIds',
      educationSystemName: 'subjectModule/educationSystemName',
      isNgssSubject: 'subjectModule/isNgssSubject',
      isSyllabusEnabled: 'subjectModule/isSyllabusEnabled',
      topics: 'subjectModule/topics',
      subtopics: 'subjectModule/subtopics',
      sections: 'subjectModule/sections',
      isDemoUser: 'userModule/isDemoUser',
    }),
    tableOfContentsId() {
      return this.getUniqueId('tableOfContents');
    },
    isTeacherWelcomeMessageVisible() {
      return this.userProfile.showTeacherOverviewWelcomeMessage;
    },
    subjectName() {
      return this.subject.name;
    },
    subjectClassName() {
      if (this.isUserTeacher) {
        return this.subjectClass?.name;
      }

      if (this.user.isStudent()) {
        return this.subjectClasses.find(sc => sc.id === this.classId)?.name;
      }
      return `My ${this.subject.name} class`;
    },
    subjectLogoUrl() {
      return this.subject.logo_url;
    },
    endorsed() {
      return this.subject.endorsed;
    },
    isFeatureListLoading() {
      return this.$wait.is(['loading-features', 'loading-feature-mappings']);
    },
    isLoading() {
      if (this.isUserTeacher) {
        return this.$wait.waiting([
          'fetching_students',
          'fetching_class_details',
          'fetching_class',
          'fetching_user_profile',
        ]);
      }
      return this.$wait.waiting([
        'fetching_subject_assignments',
        'fetching_subject_nodes_progress',
        'fetching_subject_nodes_strength',
        'fetching_class',
        'fetching_user_profile',
        'fetching_class_details',
        'fetching_driving_questions',
      ]);
    },
    isUserStudent() {
      return this.user.isStudent();
    },
    isUserTeacher() {
      return this.user.isTeacher();
    },
    isUserContentEditor() {
      return this.user.hasEditContentPermission();
    },
    disableShowHide() {
      return !this.subjectClass?.is_user_class_teacher || this.showFilters;
    },
    showHideDisabledTooltip() {
      if (!this.subjectClass?.is_user_class_teacher) {
        return 'Visibility can only be edited by teachers of this class';
      }
      if (this.showFilters) {
        return 'Hide/show content cannot be used while filtering';
      }
      return '';
    },
    isShowingAllTopics() {
      return this.selectedTopicId === ALL_TOPIC_ID;
    },
    topics() {
      return this.subject.subject_tree[0].children;
    },
    filteredTopics() {
      if (!this.isFiltering) {
        return this.topics;
      }

      const filterFunctions = this.activeFilters.map(filter =>
        this.filterActivitiesFactory(filter.type, filter.useAttributes),
      );
      const filteredSubjectTree = this.filterSubjectTree(
        this.subject.subject_tree[0],
        filterFunctions,
      );
      if (!filteredSubjectTree) {
        return [];
      }
      return filteredSubjectTree.children;
    },
    currentTopic() {
      if (this.selectedTopicId) {
        return this.filteredTopics.find(topic => topic.id === this.selectedTopicId);
      }
      return this.filteredTopics[0];
    },
    currentTopicIndex() {
      if (this.selectedTopicId) {
        const topicIndex = this.topics.findIndex(topic => topic.id === this.selectedTopicId);
        if (topicIndex !== -1) {
          return topicIndex;
        }
      }

      return 0;
    },
    numberOfFilteredItems() {
      if (this.filteredTopics.length === 0 || !this.currentTopic) {
        return 0;
      }

      if (this.selectedTopicId === ALL_TOPIC_ID) {
        const numberOfFilteredNodes = sumBy(
          this.filteredTopics,
          topic => getLeafNodes(topic).length,
        );
        return numberOfFilteredNodes;
      }
      return getLeafNodes(this.currentTopic).length;
    },
    shouldShowEducatorHubLink() {
      return this.user.isTeacher();
    },
    esqStatsByTopicId() {
      if (this.isNgssSubject) {
        return {};
      }
      const esqStatsByTopicId = {};
      this.topics.forEach(topic => {
        esqStatsByTopicId[topic.id] = getEsqStatsByTopic(topic, this.questionMappings);
      });
      return esqStatsByTopicId;
    },
    selectedSectionByQuery() {
      return this.sections.find(section => section.id === this.nodeIdQueryParams);
    },
    nodeIdQueryParams() {
      return +this.$route.query.node;
    },
    selectedTopicByQuery() {
      if (!this.selectedSectionByQuery) {
        return this.topics.find(topic => topic.id === this.nodeIdQueryParams);
      }
      const selectedSubtopic = this.subtopics.find(
        subtopic => subtopic.id === this.selectedSectionByQuery.parent,
      );
      return this.topics.find(topic => topic.id === selectedSubtopic.parent);
    },
    lastOpenedTopic() {
      const storedTopic = studyAppLocalStorage.getLastOpenTopicBySubjectUser(
        this.user.id,
        this.subjectId,
        this.classId,
      );
      return this.topics.find(topic => topic.id === storedTopic);
    },
    shouldDisplayOnboardingModal() {
      const educationSystemHasWelcomeVideo =
        isIBDP(this.educationSystemName) ||
        isIGCSE(this.educationSystemName) ||
        isNgss(this.educationSystemName);
      return (
        !this.isDemoUser &&
        this.$route.query.show_onboarding_modal === 'true' &&
        this.user.isTeacher() &&
        educationSystemHasWelcomeVideo
      );
    },
    shouldDisplayFilters() {
      return (
        this.isShowFiltersOnClassOverviewEnabled && (this.isUserTeacher || this.isUserContentEditor)
      );
    },
  },
  unmounted() {
    this.stopEditingVisibility();
  },
  async created() {
    this.getUserProfile();
    this.fetchFeatures({
      subjectId: this.subjectId,
    });
    this.fetchFeatureMappings({
      subjectId: this.subjectId,
    });

    if (this.user.isTeacher() || this.user.isStudent()) {
      this.fetchSubjectClassDetails({ subjectClassId: this.classId });
      this.fetchDrivingQuestions(this.classId);
    }

    if (this.user.isTeacher()) {
      this.fetchSubjectClass({ subjectClassId: this.classId, useCache: false });
      this.fetchSubjectClassStudentsList(this.classId);
      this.fetchBookProgress({ subjectClassId: this.classId });
    }

    if (this.user.isStudent()) {
      const { subjectId, classId, subtopicIds, $featureFlags } = this;
      this.fetchAssignments(classId);
      this.fetchSubjectNodesStrength(subjectId);
      this.fetchSubjectNodesProgress(subjectId);
      if (await $featureFlags.isQuestionProgressBarEnabled()) {
        this.fetchSubjectNodeQuestionCompletionProgress(subtopicIds);
      }
    }

    this.fetchSyllabusMappings();
    this.openSelectedTopic();
  },
  async mounted() {
    this.$mixpanel.trackEventViaBackend('Subject class overview - Open');
    if (await this.$featureFlags.isQuestionProgressBarEnabled()) {
      this.showNewProgressBarModal();
    }
    if (this.shouldDisplayOnboardingModal) {
      this.displayOnboardingModal();
    }
  },
  methods: {
    ...mapMutations({
      startEditingVisibility: 'subjectClassModule/startEditingVisibility',
      stopEditingVisibility: 'subjectClassModule/stopEditingVisibility',
    }),
    ...mapWaitingActions('teacherStudentListModule', {
      fetchSubjectClassStudentsList: 'fetching_students',
    }),
    ...mapWaitingActions('schoolstaffSubjectclassModule', {
      fetchSubjectClass: 'fetching_class',
    }),
    ...mapWaitingActions('teacherInsightsModule', {
      fetchBookProgress: 'fetching_book_progress',
    }),
    ...mapWaitingActions('statisticModule', {
      fetchSubjectNodesProgress: 'fetching_subject_nodes_progress',
      fetchSubjectNodesStrength: 'fetching_subject_nodes_strength',
    }),
    ...mapWaitingActions({
      fetchFeatures: {
        action: 'subjectNodeFeatureModule/fetchFeatures',
        loader: 'loading-features',
      },
      fetchFeatureMappings: {
        action: 'subjectNodeFeatureModule/fetchFeatureMappings',
        loader: 'loading-feature-mappings',
      },
    }),
    ...mapActions('exerciseOccasionsModule', ['fetchExerciseOccasions']),
    ...mapActions('teacherDetailModule', ['updateShowTeacherOverviewWelcomeMessage']),
    ...mapActions('statisticModule', ['fetchSubjectNodeQuestionCompletionProgress']),
    ...mapActions('syllabusModule', ['fetchSyllabusDetails']),
    ...mapWaitingActions('subjectClassModule', {
      fetchSubjectClassDetails: {
        action: 'fetchSubjectClassDetails',
        loader: 'fetching_class_details',
      },
    }),
    ...mapWaitingActions('drivingQuestionModule', {
      fetchDrivingQuestions: {
        action: 'fetchDrivingQuestions',
        loader: 'fetching_driving_questions',
      },
    }),
    ...mapWaitingActions('teacherDetailModule', {
      getUserProfile: { action: 'getUserProfile', loader: 'fetching_user_profile' },
    }),
    selectTopicByIndex(topicIndex) {
      const topic = this.topics[topicIndex];
      this.selectTopic(topic);
    },
    dismissTeacherWelcomeMessage() {
      this.updateShowTeacherOverviewWelcomeMessage(false);
    },
    showNewProgressBarModal() {
      this.$modal(NewProgressBarModal, {});
    },
    async fetchSyllabusMappings() {
      const { subject, isSyllabusEnabled } = this;
      if (isSyllabusEnabled) {
        await this.fetchSyllabusDetails(subject.syllabus_id);
      }
    },
    fetchReflectionQuestionProgress(topic) {
      const reflectionNodeIds = getLeafNodes(topic)
        .filter(node => node.section_template === 'reflection')
        .map(node => node.id);
      if (reflectionNodeIds.length > 0) {
        this.fetchExerciseOccasions({
          subjectNodeId: reflectionNodeIds,
          subjectClassId: this.subjectClassId,
          mine: 'False',
        });
      }
    },
    // eslint-disable-next-line vue/no-unused-properties
    onSelectedTopicChange(topic) {
      if (this.user.isTeacher()) {
        this.fetchReflectionQuestionProgress(topic);
      }
      studyAppLocalStorage.setLastOpenTopicForSubjectUser(
        topic.id,
        this.user.id,
        this.subjectId,
        this.classId,
      );

      this.storeLastPageOffset();
      if (!this.nodeIdQueryParams || this.selectedTopicByQuery.id !== this.selectedTopic.id) {
        this.$router.replace({
          name: 'subjectClassOverview',
          params: {
            classSlug: this.subjectClassSlug,
            sid: this.subjectId,
            cid: this.classId,
          },
          query: {
            ...this.$route.query,
            node: topic.id,
          },
        });
        this.tryRestoreLastPageOffset();
      }
    },
    getPaginatorNavigationDescription(paginatorContentPosition) {
      return `Navigate to different topic. This changes the content ${paginatorContentPosition}`;
    },
    openSelectedTopic() {
      let selectedTopic = this.topics[0];
      if (this.selectedTopicByQuery) {
        selectedTopic = this.selectedTopicByQuery;
      } else {
        selectedTopic = this.lastOpenedTopic;
      }

      if (selectedTopic) {
        this.selectTopic(selectedTopic);
      } else {
        this.selectTopic(this.topics[0]);
      }
    },
    toggleToc() {
      this.showTableOfContents = !this.showTableOfContents;
      studyAppLocalStorage.setAutoOpenSubjectClassOverviewToc(this.showTableOfContents);
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
      if (this.showFilters) {
        this.$mixpanel.trackEvent('Subject class overview - Open filters');
      }
      if (!this.showFilters) {
        this.filters = this.filters.map(filter => ({ ...filter, isActive: false }));
      }

      posthog.startSessionRecording();
    },
    toggleEditVisibilityMode() {
      if (this.isEditingVisibility) {
        this.stopEditingVisibility();
        this.$mixpanel.trackEvent('Hide / Show content: Stop', {
          transaction: this.visibilityEditingTransactionToken,
        });
      } else {
        this.startEditingVisibility();
        this.$mixpanel.trackEvent('Hide / Show content: Begin', {
          transaction: this.visibilityEditingTransactionToken,
        });
      }
    },
    displayOnboardingModal() {
      this.$modal(
        OnboardingModal,
        {
          educationSystemName: this.educationSystemName,
        },
        {
          closeOnClickOutside: false,
        },
      );
    },
    updateActiveFilter(updatedFilter) {
      this.$router.replace({ ...this.$route.query, node: undefined });
      const toggledFilter = this.filters.find(filter => filter.name === updatedFilter.name);
      toggledFilter.isActive = !toggledFilter.isActive;
      if (toggledFilter.isActive) {
        this.$mixpanel.trackEvent('Subject class overview - Filter', {
          filter: updatedFilter.name,
        });
      }
    },
  },
};
</script>

<style scoped>
.SubjectClassOverview--loading {
  padding-top: var(--study-menu-height);
}

.SubjectClassOverview-container {
  position: relative;
  left: 0;
  margin-top: -28px;
  transition: all 0.3s ease-in-out;
}

.SubjectClassOverview-rightContainer {
  padding-top: 68px;
}

.SubjectClassOverview-topicPaginatorContainer {
  padding: var(--space-s) var(--space-l);
  border-top: 1px solid var(--kog-colors-grey-300);
}

.SubjectClassOverview-teacherWelcomeContainer {
  padding: var(--space-s);
}

.SubjectClassOverview-tableOfContents {
  position: sticky !important;
  top: 68px;
  bottom: var(--space-s);

  overflow-y: hidden;

  max-height: calc(100vh - 92px);
}

.SubjectClassOverview-editVisibilityButtons {
  position: fixed;
  top: 56px;
}

@media (--viewport-s) {
  .SubjectClassOverview-middleContainer {
    width: 100%;
    margin-top: var(--space-l);
  }

  .SubjectClassOverview-rightContainer {
    padding-top: 0;
  }

  .SlideIn-wrapper {
    width: 100%;
    margin-top: var(--space-l);
  }
}
</style>
