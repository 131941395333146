<template>
  <kog-table-row :row-index="index">
    <kog-table-cell-generic>
      <access-missing-label
        v-if="!student.access_properties.has_access"
        :tooltip-text="student.access_properties.missing_access_info"
      />
      <kog-avatar
        class="margin-right-s"
        :src="student.user.avatar_url"
        :name="student.user.full_name"
        theme="accent"
      />
      {{ student.user.full_name }}
    </kog-table-cell-generic>

    <kog-table-cell-generic v-if="enableLevelColumn">
      <student-level-selector
        :current-level="student.level"
        :possible-levels="subjectClass.possible_levels"
        :disabled="!canChangeLevel"
        @level="setLevel"
      />
    </kog-table-cell-generic>

    <kog-table-cell-text :tooltip-text="yearGroupEndDateString">
      {{ student.yearclass.name }}
    </kog-table-cell-text>

    <kog-table-cell-text>
      <div v-if="student.user.last_active"> {{ student.user.last_active }} ago </div>
      <div v-else> Never logged in </div>
    </kog-table-cell-text>

    <kog-table-cell-actions
      :actions="actions"
      :context="{}"
      :is-kebab-menu="true"
      kebab-icon-class="fa-ellipsis-h"
      :dropdown-aria-label="`Actions for ${student.name}`"
    />
  </kog-table-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import ClassRemoveStudentModal from 'schoolAdminApp/components/class-remove-student-modal.vue';
import KogAvatar from 'sharedApp/components/base/avatar/kog-avatar.vue';
import StudentLevelSelector from 'sharedApp/components/dropdown/student-level-selector.vue';
import AccessMissingLabel from 'sharedApp/components/labels/access-missing-label.vue';
import KogTableCellActions from 'sharedApp/components/tables/kog-table-cell-actions.vue';
import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';

export default {
  name: 'ClassStudentListItem',
  components: {
    KogAvatar,
    StudentLevelSelector,
    AccessMissingLabel,
    KogTableRow,
    KogTableCellText,
    KogTableCellGeneric,
    KogTableCellActions,
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    subjectClassName: {
      type: String,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
    enableLevelColumn: {
      type: Boolean,
      default: false,
    },
    removeStudentFromClass: {
      type: Function,
      required: true,
    },
  },
  computed: {
    actions() {
      return [
        {
          callback: this.showRemoveStudentModal,
          dropdownText: 'Remove student',
        },
      ];
    },
    yearGroupEndDateString() {
      return `End date: ${this.student.yearclass.ends}`;
    },
    subjectClassId() {
      return this.subjectClass.id;
    },
    schoolId() {
      return window.KOG.USER_CONSTANTS.User.school.id;
    },
    canChangeLevel() {
      return this.student.level.subject_class_id === this.subjectClassId;
    },
    ...mapState({
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
    }),
  },
  methods: {
    showRemoveStudentModal() {
      this.$modal(ClassRemoveStudentModal, {
        removeStudentFromClass: this.removeStudentFromClass,
        studentId: this.student.id,
        studentName: this.student.user.full_name,
        studentEmail: this.student.user.email,
        subjectClassName: this.subjectClassName,
        subjectName: this.subjectName,
      });
    },
    setLevel(levelId) {
      this.setClassStudentLevel({
        schoolId: this.schoolId,
        studentId: this.student.user.id,
        levelId,
        subjectClassId: this.subjectClassId,
      });
    },
    ...mapActions('teacherStudentListModule', ['setClassStudentLevel']),
  },
};
</script>

<style scoped>
.student-Table-bodyRow {
  height: 50px;
  padding: var(--space-m);
}

.levelCell {
  overflow-x: visible;
  overflow-y: visible;
}
</style>
