<template>
  <div class="ScheduledAssignmentBanner text-center padd-top-s padd-bottom-s">
    <b class="heading-xxs margin-bottom-s">Assignment scheduled</b>
    <div class="flexContainer flexContainer-justifyCenter flexContainer-alignCenter">
      <kog-icon
        icon-class="fa-paper-plane"
        fa-style="regular"
        size="s"
        is-non-actionable
        class="margin-right-xxs"
      />
      Sends at
      <span
        v-kog-clickable="showUpdateScheduleModal"
        :aria-label="`Edit scheduled time: ${formattedDate}`"
        class="ScheduledAssignmentBanner-timeText margin-left-xs margin-right-xs"
      >
        {{ formattedDate }}
      </span>
      <template v-if="assignment.send_to_integration && integrationProvider">
        — Also sends to {{ integrationProviderDisplayName }}
      </template>
    </div>
  </div>
</template>

<script>
import { getIntegrationProviderDisplayName } from 'learning/common/services/integration/integration-service.js';

import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { getDateAsDateObject, getDateTimeStringsPair } from 'sharedApp/utils/time-utils.js';
import UpdateScheduleModal from 'studyApp/components/teacher/assignments/update-schedule-modal.vue';

export default {
  name: 'ScheduledAssignmentBanner',
  components: { KogIcon },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    integrationProvider: {
      type: String,
      default: () => '',
    },
  },
  emits: ['reload-assignment'],
  computed: {
    integrationProviderDisplayName() {
      return getIntegrationProviderDisplayName(this.integrationProvider);
    },
    formattedDate() {
      const scheduledDate = getDateAsDateObject(this.assignment.scheduled_sent_at);
      const { date, time } = getDateTimeStringsPair(scheduledDate);
      return `${date} ${time}`;
    },
  },
  methods: {
    showUpdateScheduleModal() {
      this.$modal(UpdateScheduleModal, {
        assignment: this.assignment,
        deadlineDate: getDateAsDateObject(this.assignment.deadline),
        scheduledDate: getDateAsDateObject(this.assignment.scheduled_sent_at),
        onUpdateSuccess: () => this.$emit('reload-assignment'),
      });
    },
  },
};
</script>

<style scoped>
.ScheduledAssignmentBanner {
  background-color: var(--kog-colors-yellow-500);
}

.ScheduledAssignmentBanner-timeText {
  text-decoration: underline;
  text-underline-offset: var(--space-xxs);
}
</style>
