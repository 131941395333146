<template>
  <div>
    <div class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter">
      <h4 class="margin-top-l margin-bottom-xs muted"> Your classes </h4>
      <button
        v-if="!(isLoading || isTeachersListLoading)"
        class="KogButtonLegacy KogButtonLegacy--s"
        @click="openAddClassModal"
      >
        Create or join class
      </button>
    </div>
    <div
      v-if="isLoading"
      class="flexContainer flexContainer-wrap"
    >
      <div
        v-for="key in [1, 2, 3, 4]"
        :key="key"
        class="TeacherDashboard-cardContainer"
      >
        <class-card-loader />
      </div>
    </div>
    <div v-else>
      <div class="flexContainer flexContainer-wrap">
        <div
          v-for="subjectClass in teacherSubjectClasses"
          :key="subjectClass.id"
          class="TeacherDashboard-cardContainer"
        >
          <class-card
            :subject-class="subjectClass"
            :subject="subjectsById[subjectClass.subject_id]"
            :teachers="teacherUsersBySubjectClassId[subjectClass.id]"
            :user="user"
            :on-add-subject-class="onAddSubjectClass"
            :on-remove-subject-class="onRemoveSubjectClass"
            :reload-classes="reloadClasses"
            @login-google-classroom="loginGoogleClassroom"
          />
        </div>
      </div>
      <div
        v-if="teacherSubjectClasses.length === 0"
        class="ClassList-emptyState shadow-m-020 flexContainer-center"
      >
        <div class="heading-s"> Add your first class on Kognity </div>
        <div class="ClassList-emptyState--action">
          Add your classes to start using Kognity with your students and to explore the available
          subjects.
          <button
            class="KogButtonLegacy KogButtonLegacy--primary margin-top-s width-50-percent"
            @click="openAddClassModal"
          >
            Create or join class
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="user.isSchoolAdmin()"
      class="margin-bottom-xxl padd-bottom-xs margin-top-m"
    >
      <div class="flexContainer flexContainer-spaceBetween flexContainer-alignCenter">
        <h4 class="margin-top-m margin-bottom-xs muted">All classes</h4>
        <a
          ref="manageSchoolLink"
          class="KogButtonLegacy KogButtonLegacy--subtleLink KogButtonLegacy--s"
          :href="manageClassesUrl"
        >
          Manage school
        </a>
      </div>
      <div
        v-if="isLoading"
        class="flexContainer flexContainer-wrap"
      >
        <div
          v-for="key in [1, 2, 3, 4]"
          :key="key"
          class="TeacherDashboard-cardContainer"
        >
          <class-card-loader />
        </div>
      </div>
      <div
        v-else
        class="flexContainer flexContainer-wrap"
      >
        <div
          v-for="subjectClass in limitedAdminSubjectClasses"
          :key="subjectClass.id"
          class="TeacherDashboard-cardContainer"
        >
          <class-card
            :subject-class="subjectClass"
            :subject="subjectsById[subjectClass.subject_id]"
            :teachers="teacherUsersBySubjectClassId[subjectClass.id]"
            :is-admin-class="true"
            :user="user"
          />
        </div>
        <kog-button
          v-if="totalAdminSubjectClasses > adminClassesLimit"
          :label="`Show all ${totalAdminSubjectClasses} classes`"
          class="width-full"
          icon-class="fa-plus"
          has-icon
          @click="showAllClasses"
        />
        <kog-empty-state-card
          v-if="!hasAdminClasses"
          empty-state-text="You don't have any classes assigned yet"
          :height="160"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { reverse } from 'schoolAdminApp/urls.js';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import AddClassModal from 'studyApp/components/teacher/add-class/add-class-modal.vue';
import ClassCardLoader from 'studyApp/components/teacher/class-card-loader.vue';
import ClassCard from 'studyApp/components/teacher/class-card.vue';
import KogEmptyStateCard from 'studyApp/components/teacher/kog-empty-state-card.vue';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';

export default {
  name: 'ClassList',
  components: {
    ClassCard,
    ClassCardLoader,
    KogEmptyStateCard,
    KogButton,
  },
  mixins: [GoogleClassroomMixin, RoutesMixin],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['subject-classes-reloaded'],
  data() {
    return {
      addedClassesIds: new Set(),
      removedClassesIds: new Set(),
      origin: 'teacher-dashboard',
      adminClassesLimit: 4,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subjectClasses: state => state.subjectClassModule.subjectClasses,
      students: state => state.subjectClassModule.students,
      subjectList: state => state.subjectModule.subjectList,
      allSubjects: state => state.subjectModule.allSubjects,
      userProfile: state => state.teacherDetailModule.userProfile,
      teacherUsersBySubjectClassId: state => state.subjectClassModule.teacherUsersBySubjectClassId,
    }),
    ...mapGetters({
      activeClasses: 'subjectClassModule/activeClasses',
      subjectClassesById: 'subjectClassModule/subjectClassesById',
      subjectsById: 'subjectModule/subjectsById',
      allSubjectsById: 'subjectModule/allSubjectsById',
      getTeachingSubjectClasses: 'subjectClassModule/getTeachingSubjectClasses',
      adminSubjectClasses: 'subjectClassModule/adminSubjectClasses',
    }),
    isTeachersListLoading() {
      return Object.keys(this.teacherUsersBySubjectClassId).length === 0;
    },
    limitedAdminSubjectClasses() {
      return this.adminSubjectClasses.slice(0, this.adminClassesLimit);
    },
    totalAdminSubjectClasses() {
      return this.adminSubjectClasses.length;
    },
    teacherSubjectClasses() {
      return this.getTeachingSubjectClasses(this.user.id);
    },
    hasAdminClasses() {
      return this.adminSubjectClasses.length > 0;
    },
    manageClassesUrl() {
      return reverse('schoolAdminApp', 'classList', { school_id: this.user.school.id });
    },
  },
  watch: {
    isLoading(newValue) {
      if (!newValue && this.user.isSchoolAdmin()) {
        this.$mixpanel.trackLinks(
          this.$refs.manageSchoolLink,
          'Teacher dashboard - Click old manage school button',
        );
      }
    },
  },
  mounted() {
    this.fetchAllSubjects();
    const googleClassroomSuccessParam = this.$route.query.gc_success;
    const subjectClass = this.subjectClassesById[this.classId];
    if (googleClassroomSuccessParam === 'true') {
      this.openConnectGoogleClassroomModal(this.origin, subjectClass, this.onConnectGoogleClass);
    }
    if (googleClassroomSuccessParam === 'false') {
      this.showGoogleClassroomAuthenticationErrorToast();
    }
    this.resetGcSuccessQueryParam();
  },
  methods: {
    ...mapActions({
      addTeacherToSubjectClass: 'subjectClassModule/addTeacherToSubjectClass',
      deleteTeacherFromSubjectClass: 'subjectClassModule/deleteTeacherFromSubjectClass',
      fetchSubjectClassList: 'subjectClassModule/fetchSubjectClassList',
      createSubjectClass: 'subjectClassModule/createSubjectClass',
      fetchAllSubjects: 'subjectModule/fetchAllSubjects',
    }),
    isClassAlreadyInOriginalList(subjectClassId) {
      return this.teacherSubjectClasses.map(c => c.id).includes(subjectClassId);
    },
    async onAddSubjectClass(subjectId, subjectClassId) {
      if (!this.isClassAlreadyInOriginalList(subjectClassId)) {
        this.addedClassesIds.add(subjectClassId);
      }
      this.removedClassesIds.delete(subjectClassId);
      await this.addTeacherToSubjectClass({
        userId: this.user.id,
        subjectId,
        subjectClassId,
      });
      this.$mixpanel.trackEvent('Teacher dashboard - Join class', {
        subject_id: subjectId,
        subject_class_id: subjectClassId,
      });
    },
    async onRemoveSubjectClass(subjectId, subjectClassId) {
      this.addedClassesIds.delete(subjectClassId);
      if (this.isClassAlreadyInOriginalList(subjectClassId)) {
        this.removedClassesIds.add(subjectClassId);
      }
      await this.deleteTeacherFromSubjectClass({
        userId: this.user.id,
        subjectId,
        subjectClassId,
      });
      this.$mixpanel.trackEvent('Teacher dashboard - Leave class', {
        subject_id: subjectId,
        subject_class_id: subjectClassId,
      });
    },
    async reloadClasses(force = false) {
      const haveClassesBeenAdded = this.addedClassesIds.size > 0;
      const haveClassesBeenRemoved = this.removedClassesIds.size > 0;
      const hasChanged = haveClassesBeenAdded || haveClassesBeenRemoved;
      this.addedClassesIds.clear();
      this.removedClassesIds.clear();

      if (hasChanged || force) {
        await this.fetchSubjectClassList(false);
        this.$emit('subject-classes-reloaded');
      }
    },
    openAddClassModal() {
      this.$modal(AddClassModal, {
        allSubjects: this.allSubjects,
        allSubjectsById: this.allSubjectsById,
        subjectClasses: this.activeClasses,
        user: this.user,
        teacherUsersBySubjectClassId: this.teacherUsersBySubjectClassId,
        teachingSubjectClasses: this.getTeachingSubjectClasses(this.user.id),
        addSubjectClass: this.onAddSubjectClass,
        removeSubjectClass: this.onRemoveSubjectClass,
        createSubjectClass: this.createClass,
        onModalClose: this.reloadClasses,
      });
    },
    showAllClasses() {
      this.adminClassesLimit = this.totalAdminSubjectClasses;
    },
    async createClass(data, newClassName, twoYearsAhead) {
      try {
        const result = await this.createSubjectClass(data);
        this.addedClassesIds.add(result.id);
        this.$toast.showSuccess(`Class <strong>${newClassName}</strong> successfully created`);
        this.$mixpanel.trackEvent('Teacher Dashboard - Create class', {
          name: data.subjectClassName,
          end_date: data.endDate,
          subject: this.allSubjects.find(s => s.id === data.subjectId).name,
          default_end_date_used: data.end_date === twoYearsAhead,
        });
      } catch (e) {
        this.$toast.showError('Unable to create class');
        throw e;
      }
    },
    loginGoogleClassroom(classId) {
      const { isGoogleClassroomTeacher } = this.userProfile;
      const subjectClass = this.subjectClassesById[classId];
      const connectData = {
        origin: this.origin,
        onConnect: this.onConnectGoogleClass,
        isGoogleClassroomTeacher,
        subjectClass,
      };
      this.connectToClassroom(connectData);
    },
    onConnectGoogleClass() {
      this.reloadClasses(true);
    },
  },
};
</script>

<style scoped>
.TeacherDashboard-cardContainer:nth-of-type(2n + 1) {
  width: 50%;
  padding: 0 var(--space-s) var(--space-l) 0;
}

.TeacherDashboard-cardContainer:nth-of-type(2n) {
  width: 50%;
  padding: 0 0 var(--space-l) var(--space-s);
}

@media (width <= 980px) {
  .TeacherDashboard-cardContainer:nth-of-type(2n + 1),
  .TeacherDashboard-cardContainer:nth-of-type(2n) {
    width: 100%;
    padding: 0 0 var(--space-l) 0;
  }
}

.ClassList-emptyState {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 200px;

  background: var(--kog-alert-info-background);
  border-radius: 8px;
}

.ClassList-emptyState--action {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  padding-top: var(--space-m);

  text-align: center;
}
</style>
