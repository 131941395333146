<template>
  <div
    class="Rotate"
    :style="{
      rotate: `${angle}deg`,
      'transform-origin': transformOrigin,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Rotation',
  props: {
    angle: {
      type: Number,
      default: 0,
    },
    transformOrigin: {
      type: String,
      default: () => 'center',
    },
  },
};
</script>

<style scoped>
.Rotate {
  transition: rotate 0.2s ease-in;
}
</style>
