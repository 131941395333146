<template>
  <div class="KogEmptySelectList">
    <h4 class="margin-top-0">
      {{ filterText }}
    </h4>
    <kog-filter-group
      :filter-list="filterList"
      @filter-tag="toggleFilterTag"
    />
  </div>
</template>

<script>
import KogFilterGroup from 'teachApp/components/kog-filter-group.vue';

export default {
  name: 'EmptySelectList',
  components: {
    KogFilterGroup,
  },
  props: {
    filterList: {
      type: Array,
      required: true,
    },
    toggleFilterTag: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filtersActiveCount() {
      return this.filterList.filter(f => f.isSelected).length;
    },
    filterText() {
      if (!this.filtersActiveCount) {
        return 'Select a difficulty level to see questions';
      }
      if (this.filtersActiveCount === 1) {
        return "Sorry, we don't have any questions for this filter";
      }
      return "Sorry, we don't have any questions for these filters";
    },
  },
};
</script>

<style scoped>
.KogEmptySelectList {
  max-width: 485px;
  margin: auto;
  margin-top: 100px;
  padding: var(--space-l);

  text-align: center;

  border-radius: 5px;
  box-shadow: 0 4px 8px -2px var(--kogShadow020);
}
</style>
