<template>
  <div>
    <study-navbar
      :notifications="notifications"
      :mark-as-seen="markNotificationsAsSeen"
      :user="user"
      :subjects-by-id="subjectsById"
      :subject-classes="classesForNavigation"
      :route-name="$route.name || 'null'"
    />
    <div class="TeacherDashboard">
      <div class="container-wideWidth">
        <div class="flexContainer flexContainer-spaceBetween padd-top-xl">
          <welcome-bar
            :user-name="teacherName"
            :school-name="user.school.name"
          />
          <kog-button
            v-if="user.isSchoolAdmin()"
            label="Manage school"
            button-style="primary"
            has-icon
            icon-class="fa-gear"
            size="large"
            role="link"
            @click="navigateToManageSchool"
          />
        </div>

        <main class="flexContainer">
          <section class="TeacherDashboard-leftContainer">
            <!-- Main tabs -->
            <kog-tabs
              v-model:value="mainTab"
              class="flexContainer-flexStart"
              :has-transparent-background="true"
              :tabs="mainTabTabs"
              heading-size="s"
            />
            <kog-tab-panels :value="mainTab">
              <kog-tab-panel name="dashboard">
                <class-list
                  :is-loading="$wait.is('fetching_classes')"
                  @subject-classes-reloaded="rerenderAssignments"
                />
              </kog-tab-panel>
              <kog-tab-panel name="school">
                <kog-school-summary
                  :user="user"
                  class="margin-bottom-xl"
                />
              </kog-tab-panel>
            </kog-tab-panels>
            <!-- /Main tabs -->
          </section>

          <aside class="TeacherDashboard-rightSide">
            <!-- Right side tabs -->
            <kog-tabs
              v-model:value="rightTab"
              class="flexContainer-flexStart"
              :has-transparent-background="true"
              :tabs="rightSideTabs"
              heading-size="s"
            />
            <kog-tab-panels :value="rightTab">
              <kog-tab-panel name="assignment">
                <suspense v-if="showAssignments && !$wait.is('fetching_classes')">
                  <template #default>
                    <teacher-assignment-overview-card class="margin-top-m" />
                  </template>
                  <template #fallback>
                    <kog-loader
                      :loading="true"
                      loading-msg="Loading assignments..."
                    />
                  </template>
                </suspense>
              </kog-tab-panel>
              <kog-tab-panel name="support">
                <div class="heading-xxs margin-top-m margin-bottom-xs">Recommended for you</div>
                <braze-cards
                  class="margin-top-s"
                  placement="right"
                >
                  <template #empty-state>
                    <resources-tab-empty-state />
                  </template>
                </braze-cards>
                <other-resources-links class="margin-top-m" />
              </kog-tab-panel>
            </kog-tab-panels>
            <!-- /Right side tabs -->
          </aside>
        </main>
      </div>
    </div>
    <demo-footer
      v-if="isDemoUser"
      :user="user"
      :demo-type="demoType"
      :enable-trial-button="enableTrialCTA"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useHead } from '@unhead/vue';
import { uniq } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

import DemoFooter from 'learning/common/components/demo-footer.vue';
import StudyNavbar from 'learning/common/components/study-navbar.vue';
import WelcomeBar from 'learning/common/components/welcome-bar.vue';

import { reverse } from 'schoolAdminApp/urls.js';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogTabPanel from 'sharedApp/components/base/tabs/kog-tab-panel.vue';
import KogTabPanels from 'sharedApp/components/base/tabs/kog-tab-panels.vue';
import KogTabs from 'sharedApp/components/base/tabs/kog-tabs.vue';
import posthog from 'sharedApp/libs/posthog.ts';
import TeacherAssignmentOverviewCard from 'studyApp/components/assignment/teacher-assignment-overview-card.vue';
import BrazeCards from 'studyApp/components/braze-cards/braze-cards.vue';
import ClassList from 'studyApp/components/dashboard/teacher/class-list.vue';
import KogSchoolSummary from 'studyApp/components/dashboard/teacher/kog-school-summary.vue';
import OtherResourcesLinks from 'studyApp/containers/components/other-resources-links.vue';
import ResourcesTabEmptyState from 'studyApp/containers/components/resources-tab-empty-state.vue';

const RIGHT_TAB_SUPPORT = 'support';
const RIGHT_TAB_ASSIGNMENT = 'assignment';

export default defineComponent({
  name: 'TeacherDashboardContainer',
  components: {
    BrazeCards,
    ResourcesTabEmptyState,
    OtherResourcesLinks,
    TeacherAssignmentOverviewCard,
    StudyNavbar,
    WelcomeBar,
    DemoFooter,
    ClassList,
    KogSchoolSummary,
    KogTabPanels,
    KogTabPanel,
    KogTabs,
    KogLoader,
    KogButton,
  },
  setup() {
    useHead({
      title: () => 'Teacher Home',
    });
  },
  data() {
    return {
      mainTab: 'dashboard',
      rightTab: RIGHT_TAB_ASSIGNMENT,
      showAssignments: true,
      mainTabTabs: [
        {
          name: 'dashboard',
          label: 'Teacher dashboard',
        },
        {
          name: 'school',
          label: 'School info',
        },
      ],
      rightSideTabs: [
        {
          name: RIGHT_TAB_ASSIGNMENT,
          label: 'Assignments',
        },
        {
          name: RIGHT_TAB_SUPPORT,
          label: 'Resources',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      notifications: state => state.notificationsModule.notifications,
      subjectClasses: state => state.subjectClassModule.subjectClasses,
      userProfile: state => state.teacherDetailModule.userProfile,
      user: state => state.userModule.user,
    }),
    ...mapGetters({
      getTeachingSubjectClasses: 'subjectClassModule/getTeachingSubjectClasses',
      subjectsById: 'subjectModule/subjectsById',
    }),
    ...mapGetters('userModule', ['isDemoUser', 'demoType', 'enableTrialCTA']),
    classesForNavigation() {
      return this.getTeachingSubjectClasses(this.user.id);
    },
    teacherName() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    hasTeacherClasses() {
      return this.getTeachingSubjectClasses(this.user.id).length > 0;
    },
  },
  watch: {
    rightTab(newValue) {
      if (newValue === RIGHT_TAB_SUPPORT) {
        this.$mixpanel.trackEvent('Teach with Kognity - click');
      }
    },
  },
  async mounted() {
    // Start wait manually, because blocked by below await statements.
    const startTime = performance.now();
    this.$wait.start('fetching_classes');
    await Promise.all([this.getUserProfile(), this.fetchSubjectClassList()]);
    const subjectIds = uniq(this.subjectClasses.map(sc => sc.subject_id));
    await Promise.all([this.fetchSubjectList(subjectIds), this.fetchSubjectClassesTeachers()]);
    this.$wait.end('fetching_classes');
    const loadingClassesTime = Math.floor(performance.now() - startTime, 10);

    this.$mixpanel.trackEvent('Teacher dashboard - Classes loaded', {
      load_time_ms: loadingClassesTime,
    });

    if (!this.hasTeacherClasses) {
      // Initial tab selection if teacher has no classes
      this.rightTab = RIGHT_TAB_SUPPORT;
    }

    const { isGoogleClassroomTeacher } = this.userProfile;

    if (isGoogleClassroomTeacher) {
      posthog.setUserProperties({
        'Google classroom teacher': true,
      });
    }
  },
  methods: {
    ...mapActions('teacherDetailModule', ['getUserProfile']),
    ...mapActions({
      markNotificationsAsSeen: 'notificationsModule/markNotificationsAsSeen',
      fetchSubjectClassList: 'subjectClassModule/fetchSubjectClassList',
      fetchSubjectList: 'subjectModule/fetchSubjectList',
      fetchSubjectClassesTeachers: 'subjectClassModule/fetchSubjectClassesTeachers',
    }),
    rerenderAssignments() {
      this.showAssignments = false;
      this.$nextTick(() => {
        this.showAssignments = true;
      });
    },
    navigateToManageSchool() {
      const manageSchoolLink = reverse('schoolAdminApp', 'classList', {
        school_id: this.user.school.id,
      });
      this.$mixpanel.trackEvent('Teacher dashboard - click new manage school button');
      window.location.href = manageSchoolLink;
    },
  },
});
</script>

<style scoped>
.TeacherDashboard {
  flex: 1;

  min-height: 100vh;
  padding: 0 var(--space-m);
  padding-top: 54px;

  background: var(--kogPlatformGray098);
}

.TeacherDashboard-leftContainer {
  flex: 8 0 0;
  padding-right: var(--space-m);
}

.TeacherDashboard-rightSide {
  flex: 4 0 0;
  min-width: 320px;
  padding-left: var(--space-xs);
}

.TeacherDashboard-assignmentList .AssignmentCard:first-of-type {
  border-radius: var(--space-xs) var(--space-xs) 0 0;
}

.TeacherDashboard-assignmentList .AssignmentCard:last-of-type {
  border-radius: 0 0 var(--space-xs) var(--space-xs);
}

.TeacherDashboard-assignmentList .AssignmentCard:only-of-type {
  border-radius: var(--space-xs);
}

.TeacherDashboard-assignmentList .AssignmentCard:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

@media (width <= 980px) {
  .TeacherDashboard-leftContainer {
    flex: 3 1 auto;
    min-width: 320px;
  }

  .TeacherDashboard-rightSide {
    flex: 1 1 min-content;
  }
}
</style>
