import { Plugin } from '@ckeditor/ckeditor5-core';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';

import IconDiv from '../icon-div/icon-div.js';
import ReflectionBoxEditing from './reflection-box-editing.js';
import ReflectionBoxUI from './reflection-box-ui.js';

export default class ReflectionBox extends Plugin {
  static get requires() {
    return [ReflectionBoxEditing, ReflectionBoxUI, IconDiv, Paragraph];
  }
}
