<template>
  <kog-modal
    title="Question preview"
    class="QuestionPreviewModal"
    :close-modal="closeModal"
  >
    <!--Question info-->
    <template #subHeader>
      <div
        class="QuestionPreviewModal-statusLine margin-top-xxs flexContainer flexContainer-spaceBetween flexContainer-center"
      >
        <div>
          {{ positionText }}
        </div>
        <div>
          <!--Question assignment status-->
          <div
            v-if="isQuestionAssignment"
            class="flexContainer flexContainer-alignCenter"
          >
            <kog-tag
              v-for="subjectNode in currentQuestion.subjectnode_mappings"
              :key="subjectNode.id"
              class="margin-right-m"
              :label="formatTopicNumber(subjectNode.number_including_ancestors)"
            />
            <question-type-label
              class="margin-right-xs"
              :question-type="currentQuestion.type"
              :tooltip-text="questionType"
            />
            <kog-difficulty
              v-if="showDifficulty"
              class="margin-right-xs"
              :difficulty="currentQuestion.difficulty"
            />
            <kog-calculator
              class="margin-right-xs"
              :is-light-icon="true"
              :tags="currentQuestion.tags"
            />
            <question-sent-before-icon
              v-if="questionsSentBeforeSet.has(currentQuestion.uuid)"
              :is-in-table-cell="false"
            />
          </div>
          <!--/Question assignment status-->

          <!--ESQ assignment status-->
          <div v-if="isExamstyleAssignment">
            <kog-calculator
              class="margin-right-xs"
              :tags="currentQuestion.tags"
            />
            <question-sent-before-icon
              v-if="questionsSentBeforeSet.has(currentQuestion.uuid)"
              :is-in-table-cell="false"
              class="margin-right-xs"
            />
            <span
              v-if="currentQuestionPapertype && hasEducationSystemPapertype"
              class="margin-right-xs"
            >
              Paper: {{ currentQuestionPapertype }}
            </span>
            <span v-if="currentQuestionMarks && hasEducationSystemMarks">
              Marks: {{ currentQuestionMarks }}
            </span>
          </div>
          <!--/ESQ assignment status-->
        </div>
      </div>
    </template>
    <!--/Question info-->

    <!--Body-->
    <template #modalBody>
      <div class="QuestionPreviewModal-body text-regular">
        <p
          v-show="isCurrentQuestionMcq"
          class="muted"
        >
          The order that students receive answer options is randomized
        </p>
        <div class="flexContainer flexContainer-spaceBetween flexContainer-center">
          <h4 class="inline-block"> Question </h4>
          <kog-progress-bar
            v-if="progressBarItems"
            class="PreviewQuestionModal-progressBar"
            type="mixed"
            :progress="progressBarItems"
            size="xs"
          />
        </div>
        <question-text-preview
          :question="currentQuestion"
          :show-answers="showAnswers && hasQuestionDetails"
        />
        <button
          v-if="isMarkschemeVisible"
          class="KogButtonLegacy KogButtonLegacy--link KogButtonLegacy--s QuestionPreviewModal-action margin-top-m"
          :aria-expanded="showAnswers ? 'true' : 'false'"
          @click="toggleShowAnswers()"
        >
          {{ !showAnswers ? 'Show answers' : 'Hide answers' }}
          <i
            :class="{
              'fa-chevron-up': showAnswers,
              'fa-chevron-down': !showAnswers,
            }"
            class="fas padd-left-xxs"
          />
        </button>
        <div v-if="showAnswers || !isMarkschemeVisible">
          <kog-loader :loading="!hasQuestionDetails">
            <assignment-question-answer
              v-if="isMarkschemeVisible && hasQuestionDetails"
              :question="currentQuestion"
            />
          </kog-loader>
          <div class="text-right">
            <report-feedback-button
              v-if="!isTeacherQuestion"
              :subject-node-id="subjectNodeId"
              :content-id="currentQuestion.id"
            />
            <span v-else> Feedback to teachers' questions is disabled </span>
          </div>
        </div>
      </div>
    </template>
    <!--/Body-->

    <!--Footer-->
    <template #modalFooter>
      <div class="KogButtonSet KogButtonSet--right flexContainer-alignCenter">
        <kog-checkbox
          v-if="toggleQuestion"
          :is-checked="isCurrentQuestionSelected"
          :label="addQuestionLabel"
          :is-label-on-left="true"
          @toggled="localToggleQuestion(currentQuestion)"
        />
        <button
          :disabled="!hasPrevious"
          class="KogButtonLegacy KogButtonLegacy--iconLeft"
          @click="previousQuestion()"
        >
          <i class="fas fa-chevron-left" />Previous question
        </button>
        <button
          :disabled="!hasNext"
          class="KogButtonLegacy KogButtonLegacy--iconRight"
          @click="nextQuestion()"
        >
          Next question<i class="fas fa-chevron-right" />
        </button>
      </div>
    </template>
    <!--/Footer-->
  </kog-modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

import KogDifficulty from 'learning/common/components/difficulty/kog-difficulty.vue';
import QuestionTextPreview from 'learning/common/components/question-text-preview.vue';
import ReportFeedbackButton from 'learning/common/components/report-feedback-button.vue';

import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import KogProgressBar from 'sharedApp/components/base/progress/kog-progress-bar.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogCalculator from 'sharedApp/components/icons/kog-calculator.vue';
import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import {
  ASSIGNMENT_TYPES,
  formatTopicNumber,
} from 'sharedApp/services/assignment/assignment-utility-service.js';
import {
  hasEducationSystemMarks,
  hasEducationSystemMarkscheme,
  hasEducationSystemPapertype,
} from 'sharedApp/services/educationSystem/education-system-service.js';
import {
  getQuestionTypeText,
  isLTQ,
  isMCQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import AssignmentQuestionAnswer from 'studyApp/components/teacher/assignments/question-answer/assignment-question-answer.vue';
import QuestionSentBeforeIcon from 'studyApp/components/teacher/assignments/question-sent-before-icon.vue';
import QuestionTypeLabel from 'studyApp/components/teacher/assignments/question-type-label.vue';
import { fetchCompleteQuestion } from 'studyApp/utils/assignment-utils.js';
import {
  trackPreviewModalEvents,
  trackPreviewModalEventsOnDetailsPage,
} from 'studyApp/utils/teacher/teach-mixpanel-utils.js';

export default {
  name: 'QuestionPreviewModal',
  components: {
    KogDifficulty,
    KogModal,
    KogCheckbox,
    QuestionTypeLabel,
    QuestionTextPreview,
    QuestionSentBeforeIcon,
    AssignmentQuestionAnswer,
    KogCalculator,
    ReportFeedbackButton,
    KogProgressBar,
    KogTag,
    KogLoader,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    assignmentType: {
      type: String,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      default: false,
    },
    toggleQuestion: {
      type: Function,
      default: null,
    },
    isQuestionSelected: {
      type: Function,
      default: null,
    },
    paginatedQuestions: {
      type: Object,
      required: true,
    },
    questionsSentBeforeSet: {
      type: Set,
      default: new Set(),
    },
    subjectName: {
      type: String,
      required: true,
    },
    showPosition: {
      type: Boolean,
      default: true,
    },
    allQuestionsProgressBarsItems: {
      type: Object,
      default: null,
    },
    source: {
      type: String,
      default: '',
    },
    assignment: {
      type: Object,
      default: () => {},
    },
    educationSystem: {
      type: String,
      default: '',
    },
    shouldFetchQuestionSubclasses: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentQuestionIndex: 0,
      questionsIndexOffset: 0,
      questions: [],
      totalCount: 0,
      currentPage: 0,
      pageSize: 0,
      showAnswers: false,
      hasEducationSystemMarks: hasEducationSystemMarks(this.educationSystem),
      hasEducationSystemPapertype: hasEducationSystemPapertype(this.educationSystem),
      completelyFetchedQuestionIds: new Set(),
    };
  },
  computed: {
    questionType() {
      return getQuestionTypeText(this.currentQuestion);
    },
    hasNext() {
      return this.currentQuestionIndex + 1 < this.questions.length;
    },
    hasPrevious() {
      return this.currentQuestionIndex > 0;
    },
    addQuestionLabel() {
      return this.isCurrentQuestionSelected ? 'Added' : 'Add Question';
    },
    isExamstyleAssignment() {
      return this.assignmentType === ASSIGNMENT_TYPES.EXAMSTYLE;
    },
    isQuestionAssignment() {
      return this.assignmentType === ASSIGNMENT_TYPES.QUESTION;
    },
    isCurrentQuestionSelected() {
      return this.isQuestionSelected && this.isQuestionSelected(this.currentQuestion);
    },
    isCurrentQuestionMcq() {
      return isMCQ(this.currentQuestion);
    },
    isTeacherQuestion() {
      return this.currentQuestion.teacher_question;
    },
    positionText() {
      if (!this.showPosition) {
        return '';
      }
      return `${this.currentQuestionIndex + 1 + this.questionsIndexOffset} of ${this.totalCount}`;
    },
    progressBarItems() {
      if (!this.allQuestionsProgressBarsItems) {
        return null;
      }
      return this.allQuestionsProgressBarsItems[this.currentQuestion.id];
    },
    isMarkschemeVisible() {
      return !isLTQ(this.question) || hasEducationSystemMarkscheme(this.educationSystem);
    },
    subjectNodeId() {
      if (this.question.subjectnode_mappings.length > 0) {
        return this.question.subjectnode_mappings[0].id;
      }
      return 0;
    },
    currentQuestionMarks() {
      return this.currentQuestion.marks || this.currentQuestion.context?.marks;
    },
    currentQuestionPapertype() {
      return this.currentQuestion.papertype?.name || this.currentQuestion.context?.papertype;
    },
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    hasQuestionDetails() {
      return this.completelyFetchedQuestionIds.has(this.currentQuestion.uuid);
    },
  },
  async created() {
    this.questions = cloneDeep(this.paginatedQuestions.results);
    this.currentQuestionIndex = this.questions.findIndex(
      question => question.uuid === this.question.uuid,
    );

    this.totalCount = this.paginatedQuestions.count;
    this.currentPage = this.paginatedQuestions.current_page || this.paginatedQuestions.page;
    this.pageSize = this.paginatedQuestions.page_size;
    this.questionsIndexOffset = (this.currentPage - 1) * this.pageSize;
    this.trackAction('open');

    await this.updateQuestionAtIndex(this.currentQuestionIndex);
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keyHandler);
    this.trackAction('hide');
  },
  methods: {
    async updateQuestionAtIndex(index) {
      if (index < 0) {
        return;
      }
      const question = this.questions[index];
      if (this.completelyFetchedQuestionIds.has(question.uuid)) {
        return;
      }

      if (this.shouldFetchQuestionSubclasses) {
        const questionWithSubtype = await fetchCompleteQuestion(question.id, question.type);
        this.questions[index] = questionWithSubtype;
      }

      this.completelyFetchedQuestionIds.add(question.uuid);
    },
    async nextQuestion() {
      const nextIndex = this.currentQuestionIndex + 1;
      if (nextIndex >= this.questions.length) {
        return;
      }
      this.currentQuestionIndex = nextIndex;
      await this.updateQuestionAtIndex(nextIndex);

      this.trackAction('next');
    },
    async previousQuestion() {
      const previousIndex = this.currentQuestionIndex - 1;
      if (previousIndex < 0) {
        return;
      }
      this.currentQuestionIndex = previousIndex;
      await this.updateQuestionAtIndex(previousIndex);

      this.trackAction('previous');
    },
    toggleShowAnswers() {
      this.showAnswers = !this.showAnswers;
      this.trackAction(this.showAnswers ? 'show-answers' : 'hide-answers');
    },
    keyHandler(event) {
      if (event.target.id === 'feedback_comment') {
        return;
      }
      switch (event.keyCode) {
        case 39: // arrow right
          this.nextQuestion();
          break;
        case 37: // arrow left
          this.previousQuestion();
          break;
        case 32: // space
          event.preventDefault(); // Do not scroll
          this.localToggleQuestion(this.currentQuestion);
          break;
        default:
          break;
      }
    },
    localToggleQuestion(question) {
      if (!this.toggleQuestion) {
        return;
      }
      this.toggleQuestion(
        question,
        null,
        'Assignment question preview modal - Add/Remove question',
      );
    },
    trackAction(eventName, props = {}) {
      if (this.source === 'assignment_details') {
        trackPreviewModalEventsOnDetailsPage(eventName, props, this.assignment);
      } else {
        const payload = {
          subject_name: this.subjectName,
          assignment_type: this.assignmentType,
          ...props,
        };
        trackPreviewModalEvents(eventName, payload);
      }
    },
    formatTopicNumber,
  },
};
</script>

<style scoped>
.QuestionPreviewModal-body {
  overflow: auto;
  height: 50vh;
  min-height: 300px;
  max-height: 800px;
}

.QuestionPreviewModal-statusLine {
  color: var(--kogPlatformGray051);
}

.QuestionPreviewModal-action {
  min-width: 0;
  padding: 0;
}

.PreviewQuestionModal-progressBar {
  width: 100px;
}
</style>
