<template>
  <td class="padd-right-s">
    <div
      v-if="isTruncated"
      class="KogTableCellTag-container"
    >
      <div
        class="flexContainer"
        :class="alignmentClass"
      >
        <kog-tag
          :label="label"
          type="informative"
        />
      </div>
    </div>
    <kog-tag
      v-else
      :label="label"
      type="informative"
    />
  </td>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix

import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';

export default {
  name: 'KogTableCellTag',
  components: {
    KogTag,
  },
  props: {
    /**
     * The text displayed in the label
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * The text can be truncated if the label is longer than the column width.
     */
    isTruncated: {
      type: Boolean,
      default: false,
    },
    /**
     * When the cell can be truncated
     * and there is enough space to display the label,
     * then the tag can be aligned to the left, center or right of the cell. <br>
     * <b>isTruncated</b> should be true for the alignment to be usable.
     */
    tagAlignment: {
      type: String,
      default: 'start',
      validator: val => ['start', 'center', 'end'].includes(val),
    },
  },
  computed: {
    alignmentClass() {
      if (this.tagAlignment === 'center') {
        return ['flexContainer-justifyCenter'];
      }
      if (this.tagAlignment === 'end') {
        return ['flexContainer-flexEnd'];
      }
      return [];
    },
  },
};
</script>

<style scoped>
.KogTableCellTag-container {
  display: table;
  table-layout: fixed;
  width: 100%;
}
</style>
