import cloneDeep from 'lodash/cloneDeep.js';

import { getContentWithoutStars } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

const questionDifficultyMixin = {
  methods: {
    getQuestionWithUpdatedHtml(currentQuestion, showDifficulty) {
      const newQuestion = { ...cloneDeep(currentQuestion) };
      if (showDifficulty) {
        newQuestion.question_html = getContentWithoutStars(newQuestion.question_html);
      }
      return newQuestion;
    },
  },
};

export default questionDifficultyMixin;
