<template>
  <div>
    <h2 class="heading-xs margin-bottom-s">Tasks</h2>
    <div
      v-for="(readingItem, i) in assignmentReadingItems"
      :key="readingItem.id"
      class="ReadingAssignmentFocusTasks-mainPage-task flexContainer flexContainer-spaceBetween margin-bottom-xs kogPlatformWhite-bg"
    >
      <router-link
        :to="getNodeFocusRoute(readingItem.id)"
        class="anchor-unstyled flexContainer flexContainer-spaceBetween width-full padd-xxs"
      >
        <div class="flexContainer flexContainer-row">
          <kog-icon
            v-if="readingItem.isCompleted"
            class="ReadingAssignmentFocusTasks-mainPage-task-icon margin-xs ReadingAssignmentFocusTasks-mainPage-task-icon--completed"
            icon-class="fa-check"
            theme="custom"
            size="m"
          />
          <div
            v-else
            class="ReadingAssignmentFocusTasks-mainPage-task-icon margin-xs flexContainer flexContainer-center"
          >
            {{ i + 1 }}
          </div>
          <div class="flexContainer flexContainer-column flexChild-center margin-left-xs">
            <span
              class="margin-top-xxs margin-bottom-xxs ReadingAssignmentFocusTasks-mainPage-task-title"
            >
              {{ readingItem.name }}
            </span>
            <span class="margin-bottom-xxs text-muted">
              {{ getReadingItemSubtitle(readingItem) }}
            </span>
          </div>
        </div>
        <div
          v-if="isReviewMode"
          class="flexChild-center"
        >
          <kog-skeleton
            v-if="$wait.is('loading_pt_submissions/*') && isPerformanceTask(readingItem.id)"
            class="margin-xs"
            :height="24"
            :width="135"
            :shimmer="true"
          />
          <router-link
            v-else-if="
              isPerformanceTask(readingItem.id) &&
              hasUngradedSubmissionsBySubjectNodeId[readingItem.id]
            "
            class="margin-xs"
            :to="performanceTaskOverviewRoute(readingItem.id)"
          >
            <kog-tag
              aria-label="Go to the grade Performance task submissions page"
              :is-chip="true"
              label="Ungraded submissions"
              type="negative"
            >
            </kog-tag>
          </router-link>
          <kog-tag
            is-chip
            class="flexChild-center margin-right-xs green"
            icon-class="fa-user-check"
            :icon-style="
              readingItem.studentCompletedCount === readingItem.studentCount ? 'solid' : 'regular'
            "
            :label="`${readingItem.studentCompletedCount} / ${readingItem.studentCount}`"
            :context="`${readingItem.studentCompletedCount} out of ${readingItem.studentCount}`"
            :tab-index="-1"
            size="m"
            type="outline"
            @click.stop="$emit('open-completion-modal', readingItem.id)"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import isNil from 'lodash/isNil.js';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import KogSkeleton from 'sharedApp/components/base/skeleton/kog-skeleton.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { PERFORMANCE_TASK } from 'sharedApp/const/features.js';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

export default {
  name: 'ReadingAssignmentFocusTasks',
  components: {
    KogIcon,
    KogTag,
    KogSkeleton,
  },
  mixins: [RoutesMixin],
  props: {
    assignmentReadingItems: {
      type: Array,
      required: true,
    },
    assignmentId: {
      type: Number,
      required: true,
    },
  },
  emits: ['open-completion-modal'],
  data() {
    return {
      hasUngradedSubmissionsBySubjectNodeId: {},
    };
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      ptClassOccasions: state => state.performanceTaskModule.classOccasions,
    }),
    ...mapGetters({
      subjectNodesById: 'subjectModule/subjectNodesById',
      getActivityBySubjectNodeId: 'subjectNodeFeatureModule/getActivityBySubjectNodeId',
    }),
    isTeacher() {
      return this.user.isTeacher();
    },
    isReviewMode() {
      return this.$route.meta.isReviewMode;
    },
  },
  async created() {
    if (!this.isTeacher) return;
    const subjectNodeIds = this.assignmentReadingItems.map(readingItem => readingItem.id);
    await this.fetchFeaturesAndMappings({ subjectNodeIds });
    this.assignmentReadingItems.forEach(readingItem => {
      if (this.isPerformanceTask(readingItem.id)) {
        this.fetchAndCheckUngradedPtResponses(readingItem.id);
      }
    });
  },
  methods: {
    ...mapWaitingActions({
      fetchFeaturesAndMappings: {
        action: 'subjectNodeFeatureModule/fetchFeaturesAndMappings',
        loader: 'loading_pt_submissions/fetching_features_and_mappings',
      },
      fetchPtClassOccasions: {
        action: 'performanceTaskModule/fetchClassOccasions',
        loader: 'loading_pt_submissions/fetching_submissions',
      },
    }),
    getNodeFocusRoute(nodeId) {
      const route = {
        name: this.isReviewMode ? 'teacherAssignmentDetails' : 'classReadingAssignmentTake',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignmentId,
          nodeId,
        },
      };
      return route;
    },
    getReadingItemSubtitle(readingItem) {
      const subjectNode = this.subjectNodesById[readingItem.id];
      const parent = this.subjectNodesById[subjectNode.parent];
      if (!parent) return '';
      return `${parent.formatted_number_including_ancestors} ${parent.name}`;
    },
    isPerformanceTask(subjectNodeId) {
      const subjectNode = this.subjectNodesById[subjectNodeId];
      if (!subjectNode.activity) return false;
      const activity = this.getActivityBySubjectNodeId(subjectNodeId);
      return activity?.type === PERFORMANCE_TASK;
    },
    performanceTaskOverviewRoute(subjectNodeId) {
      const subjectNode = this.subjectNodesById[subjectNodeId];
      return {
        name: 'performanceTaskOverview',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          nodeSlug: subjectNode.slug,
          nodeId: subjectNode.id,
        },
        query: {
          origin: 'assignment_details',
        },
      };
    },
    async fetchAndCheckUngradedPtResponses(subjectNodeId) {
      const activity = this.getActivityBySubjectNodeId(subjectNodeId);
      await this.fetchPtClassOccasions({
        performanceTaskId: activity.id,
        subjectClassId: this.classId,
      });
      const hasUngradedResponses = this.ptClassOccasions.some(
        occasion =>
          occasion.performance_task_id === activity.id &&
          isNil(occasion.grade_sent_at) &&
          !isNil(occasion.submitted_at),
      );
      this.hasUngradedSubmissionsBySubjectNodeId[subjectNodeId] = hasUngradedResponses;
    },
  },
};
</script>

<style scoped>
.ReadingAssignmentFocusTasks-mainPage-task {
  border: 1px solid transparent;
  border-radius: 16px;
}
.ReadingAssignmentFocusTasks-mainPage-task-icon {
  align-self: center;

  width: 40px;
  min-width: 40px;
  height: 40px;

  color: var(--kog-colors-grey-900);

  background-color: var(--kog-colors-grey-300);
  border-radius: 50%;
}

.ReadingAssignmentFocusTasks-mainPage-task-icon--completed {
  padding: 20px;
  background-color: var(--kog-colors-green-500);
}

.ReadingAssignmentFocusTasks-mainPage-task-title {
  font-size: 16px;
  font-weight: 500;
}

.ReadingAssignmentFocusTasks-mainPage-task:hover {
  border: 1px solid var(--kog-colors-grey-600);
}
</style>
