import { ref } from 'vue';
import { keyBy } from 'lodash';
import { defineStore } from 'pinia';

import * as api from '@apis/open-ended-tasks.ts';

import { postImageToS3SignedUrl, UPLOAD_STATUS } from 'learning/study/utils/image-upload-utils.js';

import { FILE_STATUS } from 'sharedApp/components/image-upload/file-status.ts';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const isImageResponse = response => {
  return response.response_type === RESPONSE_TYPES.IMR;
};

const extractPayloadByResponseType = clientResponse => {
  const responseToSave = { ...clientResponse };
  if (isImageResponse(clientResponse)) {
    responseToSave.s3_object_type = clientResponse.user_response?.file?.type;
    responseToSave.user_response = clientResponse.user_response?.file?.name;
  }

  return responseToSave;
};

const uploadPendingImageResponse = async ({ signedUrl, file, onProgress, onError }) => {
  let hasError = false;

  if (file instanceof File) {
    try {
      await postImageToS3SignedUrl(file, signedUrl, onProgress);
    } catch {
      onError();
      hasError = true;
    }
  }

  return [hasError];
};

const populateImageResponse = async (response, taskId, handleCommit) => {
  // Pre-commit downloading state
  handleCommit(taskId, {
    ...response,
    user_response: {
      file: null,
      status: FILE_STATUS.DOWNLOADING,
    },
  });

  let file;
  let status;
  try {
    const imageResponse = await axios.get(response.signed_image_url, { responseType: 'blob' });
    file = new File([imageResponse.data], response.user_response);
    status = FILE_STATUS.DONE;
  } catch {
    file = null;
    status = FILE_STATUS.ERROR;
  }

  const clientImageUserResponse = {
    ...response,
    user_response: {
      file,
      status,
    },
  };

  handleCommit(taskId, clientImageUserResponse);
};

export default defineStore('openEndedTasks', () => {
  const availableTasksBySubjectNodeId = ref({});
  const taskResponses = ref({});
  const loading = ref(false);

  const updateResponseInState = (taskId, response) => {
    const updatedResponse = { ...taskResponses.value[taskId], ...response };

    taskResponses.value = {
      ...taskResponses.value,
      [taskId]: updatedResponse,
    };
  };

  const fetchOpenEndedTasks = async subjectNodeId => {
    loading.value = true;
    const tasks = await api.fetchOpenEndedTasks(subjectNodeId);
    availableTasksBySubjectNodeId.value = {
      ...availableTasksBySubjectNodeId.value,
      [subjectNodeId]: keyBy(tasks, 'id'),
    };

    const newResponses = {};
    await Promise.all(
      Object.keys(availableTasksBySubjectNodeId.value[subjectNodeId]).map(async taskId => {
        const taskResponse = await api.fetchOpenEndedTaskResponse(taskId);
        if (taskResponse.length > 0) {
          if (isImageResponse(taskResponse[0])) {
            populateImageResponse(taskResponse[0], taskId, updateResponseInState);
          } else {
            newResponses[taskId] = taskResponse[0];
          }
        }
      }),
    );
    taskResponses.value = {
      ...taskResponses.value,
      ...newResponses,
    };

    loading.value = false;
  };

  const clearResponseInState = taskId => {
    const newState = { ...taskResponses.value };
    delete newState[taskId];
    taskResponses.value = newState;
  };

  const updateTaskResponse = async (taskId, response) => {
    if (isImageResponse(response) && !response.user_response) {
      api.deleteOpenEndedTaskResponse(taskId, taskResponses.value[taskId].id);
      clearResponseInState(taskId);
    } else {
      updateResponseInState(taskId, response);
      const responsePayload = extractPayloadByResponseType(response);
      await api.updateOpenEndedTaskResponse(
        taskId,
        taskResponses.value[taskId].id,
        responsePayload,
      );
    }
  };

  const handleImageUpload = async ({ taskId, clientResponse, apiResponse }) => {
    const [hasError] = await uploadPendingImageResponse({
      signedUrl: apiResponse.signed_url,
      file: clientResponse.user_response.file,
      onProgress: clientResponse.onProgress,
      onError: clientResponse.onError,
    });

    if (hasError) {
      await api.deleteOpenEndedTaskResponse(taskId, taskResponses.value[taskId].id);
      clearResponseInState(taskId);
      return;
    }

    const updateResponse = {
      question_id: clientResponse.question_id,
      response_type: clientResponse.response_type,
      status: UPLOAD_STATUS.UPLOADED,
      user_response: {
        ...clientResponse.user_response,
        status: FILE_STATUS.DONE,
      },
    };

    await updateTaskResponse(taskId, updateResponse);
  };

  const createTaskResponse = async (taskId, response) => {
    // Set state for client responsiveness
    updateResponseInState(taskId, response);

    const responseToSave = extractPayloadByResponseType(response);
    const apiResponse = await api.createOpenEndedTaskResponse(taskId, responseToSave);

    // Add response id
    updateResponseInState(taskId, { id: apiResponse.id });

    if (isImageResponse(response) && isImageResponse(apiResponse)) {
      handleImageUpload({
        taskId,
        clientResponse: response,
        apiResponse,
      });
    }
  };

  const updateOrCreateResponse = async (taskId, response) => {
    if (taskResponses.value[taskId]) {
      await updateTaskResponse(taskId, response);
    } else {
      await createTaskResponse(taskId, response);
    }
  };

  return {
    availableTasksBySubjectNodeId,
    taskResponses,
    loading,
    fetchOpenEndedTasks,
    updateOrCreateResponse,
  };
});
