import { keyBy } from 'lodash';

import { getExerciseQuestions } from '@apis/questions.js';

import * as activityApi from 'publishingApp/apis/activities.js';
import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';
import { contentActions } from 'publishingApp/store/modules/feature/activity-content-actions.js';
import { contentMutations } from 'publishingApp/store/modules/feature/activity-content-mutations.js';
import { fetchContentBoxTemplates } from 'sharedApp/apis/content-box.js';
import { fetchFeatureMappings } from 'sharedApp/apis/subject-node-features.js';
import { FEATURES_ENUM } from 'sharedApp/const/features.js';

export const defaultState = () => ({
  hasMappingError: false,
  performanceTask: { content: [] },
  availableQuestions: [],
  itemsMarkedForRemoval: [],
  itemsMarkedForUpdate: [],
  error: '',
  isSaving: false,
  availableContentBoxTemplates: [],
});

const linkNewBlock = async (sheetId, block) => {
  const { id: blockId } = await activityApi.addContentBlock(
    FEATURES_ENUM.PERFORMANCE_TASK,
    sheetId,
    block,
  );
  return { ...block, id: blockId };
};

const linkUpdatedBlock = async (sheetId, block) => {
  const updated = await activityApi.updateContentBlock(
    FEATURES_ENUM.PERFORMANCE_TASK,
    sheetId,
    block,
  );
  return { ...block, ...updated };
};

const linkContentInTask =
  ({ state }, taskId) =>
  item => {
    if (!item.id) {
      return linkNewBlock(taskId, item);
    }

    if (state.itemsMarkedForUpdate.includes(item.clientId)) {
      return linkUpdatedBlock(taskId, item);
    }

    return { ...item };
  };

export const getters = {
  getActivityContent(state, _getter) {
    const { performanceTask } = state;
    return performanceTask.content.map(content => ({
      ...content,
      ...(content.question_id && {
        question: _getter.questionLookup[content.question_id],
      }),
    }));
  },
  questionLookup(state) {
    return keyBy(state.availableQuestions, question => question.id);
  },
  getQuestionDetails(_, _getters) {
    return id => _getters.questionLookup[id];
  },
  isItemMarkedForRemoval(state) {
    return itemId => state.itemsMarkedForRemoval.includes(itemId);
  },
  hasItemsMarkedForRemovalOrUpdate(state) {
    return state.itemsMarkedForRemoval.length > 0 || state.itemsMarkedForUpdate.length > 0;
  },
  hasMissingQuestions(state, _getters) {
    const { performanceTask } = state;
    const isAnyQuestionMissing = performanceTask.content.some(
      content =>
        content.block_type === BLOCK_TYPE.QUESTION &&
        (!content.question_id ||
          !_getters.questionLookup[content.question_id] ||
          _getters.questionLookup[content.question_id]?.active !== true),
    );
    return isAnyQuestionMissing;
  },
};

export const actions = {
  async fetchPerformanceTask({ commit }, performanceTaskId) {
    let performanceTask;
    try {
      performanceTask = await activityApi.fetchActivity(
        FEATURES_ENUM.PERFORMANCE_TASK,
        performanceTaskId,
      );
    } catch (err) {
      commit('setError', `An error occured when fetching the activity task: ${err}.`);
      return;
    }
    const clientIdContent = performanceTask.content.map(item => ({
      ...item,
      clientId: window.crypto.randomUUID(),
    }));
    performanceTask.content = clientIdContent;
    if (!performanceTask.teacher_instruction_html) {
      performanceTask.teacher_instruction_html = '';
    }

    commit('setPerformanceTask', performanceTask);
  },

  async updatePerformanceTask({ commit, state, dispatch }, task) {
    commit('setError', null);
    commit('setIsSaving', true);

    try {
      const {
        id,
        title,
        teacher_instruction_html, // eslint-disable-line camelcase
        attributes,
      } = task;
      const updatedTask = await activityApi.updateActivity(FEATURES_ENUM.PERFORMANCE_TASK, {
        id,
        title,
        teacher_instruction_html, // eslint-disable-line camelcase
        attributes,
      });

      const { content } = task;
      const { itemsMarkedForRemoval } = state;
      const isNotMarkedForRemoval = item => !itemsMarkedForRemoval.includes(item.clientId);
      const newContent = content.filter(isNotMarkedForRemoval);

      await dispatch('removeBlocks', { content, updatedTask });

      const linkContent = linkContentInTask({ state, commit }, task.id);
      const linkedContent = await Promise.all(newContent.map(linkContent));
      updatedTask.content = linkedContent;

      dispatch('updateOrdering', { task, content: linkedContent });

      commit('clearItemsForUpdate');
      commit('setPerformanceTask', updatedTask);
    } catch (e) {
      commit('setError', `Error occured while saving: ${e.message}`);
    } finally {
      commit('setIsSaving', false);
    }
  },

  async removeBlocks({ state, dispatch }, { content, updatedTask }) {
    await Promise.all(
      state.itemsMarkedForRemoval.map(async clientId => {
        const block = content.find(item => item.clientId === clientId && item.id);
        if (block) {
          await activityApi.removeContentBlock(
            FEATURES_ENUM.PERFORMANCE_TASK,
            updatedTask.id,
            block.id,
          );
        }
        dispatch('unmarkItemForRemoval', clientId);
        dispatch('unmarkItemForUpdate', clientId);
      }),
    );
  },

  updateOrdering(_, { task, content }) {
    const contentIds = content.map(item => ({ id: item.id }));
    if (contentIds.length > 0) {
      activityApi.updateOrdering(FEATURES_ENUM.PERFORMANCE_TASK, task.id, contentIds);
    }
  },

  async fetchAvailableQuestions({ commit }, subjectNodeIds = []) {
    let questions = [];
    try {
      questions = await getExerciseQuestions({ subjectNodeIds, detail: true, active: null });
    } catch (err) {
      commit('setError', `An error occured when fetching available questions: ${err}.`);
      return;
    }

    commit('setAvailableQuestions', questions);
  },

  async verifyPerformanceTaskMapping({ commit }, { performanceTaskId, subjectNodeId }) {
    try {
      const mappings = await fetchFeatureMappings([subjectNodeId]);
      const hasValidMapping = mappings.some(mapping => mapping.feature_id === performanceTaskId);
      if (!hasValidMapping) {
        throw Error;
      }
    } catch {
      commit('setHasMappingError', true);
    }
  },

  async fetchAvailableContentBoxTemplates({ commit }, subjectId) {
    try {
      const templates = await fetchContentBoxTemplates({
        subjectId,
        status: 'PUBLISHED',
      });

      commit('setAvailableContentBoxTemplates', templates);
    } catch (error) {
      commit('setError', 'Could not load content box templates', error);
    }
  },
};

const mutations = {
  setPerformanceTask(state, task) {
    state.performanceTask = task;
  },
  clearItemsForUpdate(state) {
    state.itemsMarkedForUpdate = [];
  },
};

export const PERFORMANCE_TASK_MODULE = 'performanceTaskModule';

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations: { ...mutations, ...contentMutations },
  actions: { ...actions, ...contentActions },
};
