<template>
  <div>
    <div class="StudentStepper padd-bottom-l">
      <kog-button
        size="medium"
        :has-icon="true"
        icon-class="fa-chevron-left"
        label="Previous Student"
        :disabled="isPreviousStudentButtonDisabled"
        @click="navigateToPreviousStudent"
      />

      <div class="heading-xs">
        {{ selectedStudentName }}
      </div>

      <kog-button
        size="medium"
        button-style="primary"
        icon-class="fa-chevron-right"
        :has-icon="true"
        label="Next Student"
        icon-position="right"
        :disabled="isNextStudentButtonDisabled"
        @click="navigateToNextStudent"
      />
    </div>
  </div>
</template>

<script>
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';

export default {
  name: 'StudentStepper',
  components: {
    KogButton,
  },
  props: {
    selectedStudentId: {
      type: Number,
      required: true,
    },
    studentsList: {
      type: Array,
      required: true,
    },
  },
  emits: ['select-student'],
  computed: {
    isPreviousStudentButtonDisabled() {
      return this.selectedStudentIndex === 0;
    },
    isNextStudentButtonDisabled() {
      return this.selectedStudentIndex === this.studentsList.length - 1;
    },
    selectedStudentIndex() {
      return this.studentsList.findIndex(student => student.user_id === this.selectedStudentId);
    },
    selectedStudentName() {
      return this.studentsList[this.selectedStudentIndex]?.name;
    },
  },
  methods: {
    navigateToPreviousStudent() {
      const currentIndex = this.selectedStudentIndex;
      const previousStudent = this.studentsList[currentIndex - 1];
      if (previousStudent) {
        this.$emit('select-student', previousStudent.user_id);
      }
    },
    navigateToNextStudent() {
      const currentIndex = this.selectedStudentIndex;
      const nextStudent = this.studentsList[currentIndex + 1];
      if (nextStudent) {
        this.$emit('select-student', nextStudent.user_id);
      }
    },
  },
};
</script>

<style scoped>
.StudentStepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: var(--space-xl);
  margin-right: var(--space-xl);
  margin-left: var(--space-xl);
}
</style>
