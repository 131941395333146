<template>
  <div class="kog-container">
    <div class="kog-row">
      <section class="kog-col-lg-8 kog-offset-lg-2 kog-col-sm-12">
        <slot name="header" />
        <kog-card :default-padding="false">
          <template #topSlot>
            <div class="padd-l">
              <slot name="body" />
            </div>
          </template>
          <template
            v-if="hasCardBottomSlot"
            #bottomSlot
          >
            <slot name="cardBottomSlot" />
          </template>
        </kog-card>
        <slot name="footer" />
      </section>
    </div>
  </div>
</template>

<script>
import KogCard from 'sharedApp/components/card/kog-card.vue';

export default {
  name: 'KogDefaultStudyPage',
  components: {
    KogCard,
  },
  computed: {
    hasCardBottomSlot() {
      return Boolean(this.$slots.cardBottomSlot);
    },
  },
};
</script>
