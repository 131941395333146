<template>
  <kog-modal maximize>
    <template #modalBody>
      <div class="text-center">
        <div class="padd-l">
          <h1 class="screenreader-only"> Section complete </h1>
          <div class="heading-m margin-top-l margin-bottom-s">
            You have completed the section.
          </div>
          <p>
            <i class="fas fa-4x fa-check-circle kogPlatformGreenBase" />
          </p>
          <div class="margin-top-m">
            <template v-if="isLastSectionInTheNode && isSubtopicHasStrengthQuestions">
              <router-link
                v-slot="{ href, navigate }"
                :to="subtopicPracticeUrl"
                custom
              >
                <a
                  :href="href"
                  class="KogButtonLegacy KogButtonLegacy--primary"
                  @click="
                    e => {
                      handleGoToStrengthTest();
                      navigate(e);
                    }
                  "
                >
                  Test your knowledge on this subtopic
                </a>
              </router-link>
              <template v-if="!isLastSectionInTheBook">
                <p class="margin-m"> or </p>
                <router-link
                  v-slot="{ href, navigate }"
                  :to="nextPageRoute"
                  custom
                >
                  <a
                    :href="href"
                    @click="
                      e => {
                        closeModal();
                        navigate(e);
                      }
                    "
                  >
                    Continue to next section
                  </a>
                </router-link>
              </template>
            </template>
            <router-link
              v-else-if="nextPageRoute"
              v-slot="{ href, navigate }"
              :to="nextPageRoute"
              custom
            >
              <a
                :href="href"
                class="KogButtonLegacy KogButtonLegacy--primary"
                :aria-label="screenreaderInfo"
                @click="
                  e => {
                    closeModal();
                    navigate(e);
                  }
                "
              >
                Continue to next section
              </a>
            </router-link>
            <button
              v-else
              class="KogButtonLegacy KogButtonLegacy--primary"
              @click="closeModal"
            >
              Close
            </button>
          </div>
        </div>
        <content-rating
          v-if="isStudent"
          :subject-node="currentNodeParent"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import ContentRating from 'studyApp/components/section-text/content-rating.vue';

export default {
  name: 'SectionCompletedModal',
  components: {
    KogModal,
    ContentRating,
  },
  mixins: [RoutesMixin],
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    sectionNodeId: {
      type: Number,
      required: true,
    },
    nextSectionNode: {
      type: Object,
      default: null,
    },
    isCompletedByQuestionsAnswered: {
      type: Boolean,
      default: false,
    },
    nextPageRoute: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
      subject: state => state.subjectModule.subject,
      subjectNodesWithStrengthQuestions: state =>
        state.statisticModule.subjectNodesWithStrengthQuestions,
    }),
    ...mapGetters({
      sectionNodes: 'bookModule/sectionNodes',
      currentNodeParent: 'bookModule/currentNodeParent',
    }),
    isStudent() {
      return this.user.isStudent();
    },
    screenreaderInfo() {
      let info = 'You have completed the section';
      if (this.isCompletedByQuestionsAnswered) {
        info = `${info} by answering all section questions correctly`;
      }
      return `${info}. Click to continue to next section`;
    },
    isLastSectionInTheNode() {
      const sectionNodeIndex = this.sectionNodes.findIndex(sn => sn.id === this.sectionNodeId) + 1;
      return sectionNodeIndex + 1 > this.sectionNodes.length - 1;
    },
    isLastSectionInTheBook() {
      return !this.nextSectionNode;
    },
    isSubtopicHasStrengthQuestions() {
      return this.subjectNodesWithStrengthQuestions.includes(this.currentSubtopic.id);
    },
    subtopicPracticeUrl() {
      const resolved = this.$router.resolve({
        name: 'classStrengthTestSetup',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
        },
        query: {
          selected_nodes: this.currentSubtopic.id,
          next: this.nextSectionNode?.id,
        },
      });
      return resolved.fullPath;
    },
    currentSubtopic() {
      if (this.currentNodeParent.level === 3) {
        return this.currentNodeParent.parent;
      }
      return this.currentNodeParent;
    },
  },
  created() {
    this.fetchSubjectNodesWithStrengthQuestions({
      subjectId: this.subject.id,
      subjectNodeIds: [this.currentSubtopic.id],
    });
  },
  methods: {
    ...mapActions({
      fetchSubjectNodesWithStrengthQuestions:
        'statisticModule/fetchSubjectNodesWithStrengthQuestions',
    }),
    handleGoToStrengthTest() {
      this.closeModal();
      this.trackGoToStrengthTest();
    },
    trackGoToStrengthTest() {
      const source = 'strength_source_end_of_section_popup';
      const eventProperties = {
        source,
      };
      this.$mixpanel.trackEvent('Navigate to strength test', eventProperties);
    },
  },
};
</script>
