import { icons } from '@ckeditor/ckeditor5-core';
import {
  ButtonView,
  createLabeledInputText,
  FocusCycler,
  LabeledFieldView,
  submitHandler,
  View,
} from '@ckeditor/ckeditor5-ui';
import { FocusTracker, KeystrokeHandler } from '@ckeditor/ckeditor5-utils';

export default class KogForm extends View {
  constructor(locale) {
    super(locale);

    this.focusTracker = new FocusTracker();
    this.keystrokes = new KeystrokeHandler();
  }

  setup(name, focusables) {
    this.focusCycler = new FocusCycler({
      focusables: focusables || this.childViews,
      focusTracker: this.focusTracker,
      keystrokeHandler: this.keystrokes,
      actions: {
        focusPrevious: 'shift + tab',
        focusNext: 'tab',
      },
    });

    this.setTemplate({
      tag: 'form',
      attributes: {
        class: ['ck', 'ck-form', `ck-${name}-form`],
        tabindex: '-1',
      },
      children: this.childViews,
    });
  }

  render() {
    super.render();
    submitHandler({ view: this });
    // eslint-disable-next-line no-underscore-dangle
    this.focusCycler.focusables._items.forEach(view => {
      this.focusTracker.add(view.element);
    });
    this.keystrokes.listenTo(this.element);
    this.updateUI();
  }

  // eslint-disable-next-line class-methods-use-this
  updateUI() {}

  destroy() {
    super.destroy();
    this.focusTracker.destroy();
    this.keystrokes.destroy();
  }

  reset() {
    this.element.reset();
  }

  createInput(label) {
    const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText);
    labeledInput.label = label;
    return labeledInput;
  }

  createButton(label, icon, className) {
    const button = new ButtonView(this.locale);
    button.set({
      label,
      icon,
      tooltip: true,
      class: className,
    });
    return button;
  }

  createSaveButton() {
    const saveButtonView = this.createButton('Save', icons.check, 'ck-button-save');
    saveButtonView.type = 'submit';
    return saveButtonView;
  }

  createCancelButton() {
    const cancelButtonView = this.createButton('Cancel', icons.cancel, 'ck-button-cancel');
    cancelButtonView.delegate('execute').to(this, 'cancel');
    return cancelButtonView;
  }
}
