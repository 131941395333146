import { without } from 'lodash';

export const contentMutations = {
  setError(state, error) {
    state.error = error;
  },
  setAvailableQuestions(state, questions) {
    state.availableQuestions = questions;
  },
  markItemForRemoval(state, itemClientId) {
    state.itemsMarkedForRemoval = [...state.itemsMarkedForRemoval, itemClientId];
  },
  unmarkItemForRemoval(state, itemClientId) {
    state.itemsMarkedForRemoval = without(state.itemsMarkedForRemoval, itemClientId);
  },
  markItemForUpdate(state, itemClientId) {
    state.itemsMarkedForUpdate = Array.from(new Set([...state.itemsMarkedForUpdate, itemClientId]));
  },
  unmarkItemForUpdate(state, itemClientId) {
    state.itemsMarkedForUpdate = without(state.itemsMarkedForUpdate, itemClientId);
  },
  clearItemsForUpdate(state) {
    state.itemsMarkedForUpdate = [];
  },
  clearItemsForRemoval(state) {
    state.itemsMarkedForRemoval = [];
  },
  setHasMappingError(state, hasError) {
    state.hasMappingError = hasError;
  },
  setIsSaving(state, isSaving) {
    state.isSaving = isSaving;
  },
  setAvailableContentBoxTemplates(state, templates) {
    state.availableContentBoxTemplates = templates;
  },
};
