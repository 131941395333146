<template>
  <div
    class="BlockContentWrapper"
    :class="statusClass"
    :data-status-message="status?.message"
  >
    <div class="flexContainer flexContainer-center flexContainer-spaceBetween">
      <div class="flexContainer flexContainer-center gap-xxs">
        <kog-icon
          v-if="status"
          v-tooltip="{
            theme: 'kog-tooltip',
            content: status.message,
          }"
          v-kog-focusable="true"
          icon-class="fa-circle-exclamation"
          fa-style="solid"
          size="m"
          theme="custom"
          class="margin-left-xxs"
        />
        <span class="small-text text-muted align-center">
          {{ blockLabel }}
        </span>
      </div>
      <div class="flexContainer gap-xs">
        <kog-button
          :disabled="!canMoveDownward"
          aria-label="Move downwards"
          class="BlockContentWrapper-moveDownward"
          icon-class="fa-arrow-down"
          :has-icon="true"
          type="default"
          label="Move down"
          size="small"
          button-style="basic"
          @click="moveDownward"
        />
        <kog-button
          :disabled="!canMoveUpward"
          aria-label="Move upwards"
          icon-class="fa-arrow-up"
          :has-icon="true"
          type="default"
          class="BlockContentWrapper-moveUpward"
          label="Move up"
          size="small"
          button-style="basic"
          @click="moveUpward"
        />
        <kog-dropdown-button
          :items="actionItems"
          dropdown-placement="bottom-end"
          label="Item actions"
          size="x-small"
          button-style="default"
          button-type="round"
          :has-icon="true"
          icon-class="fa-ellipsis-vertical"
          @select="onOptionSelect"
        />
      </div>
    </div>
    <div
      class="padd-top-xs padd-bottom-xs padd-right-xs padd-left-xs border-standard border-radius-8 flexContainer flexContainer-alignCenter"
      :class="{
        'BlockContentWrapper-markedForRemoval': isMarkedForRemoval,
      }"
      :style="customStyle"
    >
      <div class="flexContainer flexContainer-column width-100 gap-xs">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import {
  MARK_REMOVAL,
  MOVE_ITEM_DOWN,
  MOVE_ITEM_UP,
  UNMARK_REMOVAL,
} from 'publishingApp/store/modules/feature/activity-constants.ts';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogDropdownButton from 'sharedApp/components/base/buttons/kog-dropdown-button.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export const ITEM_ACTIONS = Object.freeze({
  MARK_REMOVAL: 'mark_removal',
  UNMARK_REMOVAL: 'unmark_removal',
  EDIT_CONTENT: 'edit_content',
});

export default {
  name: 'BlockWrapper',
  components: {
    KogDropdownButton,
    KogButton,
    KogIcon,
  },
  props: {
    blockLabel: {
      type: String,
      required: true,
    },
    contentIndex: {
      type: Number,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    contentLength: {
      type: Number,
      required: true,
    },
    isMarkedForRemoval: {
      type: Boolean,
      default: false,
    },
    availableActions: {
      type: Array,
      default: () => [ITEM_ACTIONS.MARK_REMOVAL],
    },
    customStyle: {
      type: Object,
      default: () => {},
    },
    status: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['edit', MARK_REMOVAL, UNMARK_REMOVAL, MOVE_ITEM_UP, MOVE_ITEM_DOWN],
  computed: {
    statusClass() {
      if (!this.status) return null;

      return `BlockContentWrapper-${this.status.type}`;
    },
    canMoveUpward() {
      return this.contentIndex > 0 && this.contentLength > 1;
    },
    canMoveDownward() {
      return this.contentIndex < this.contentLength - 1 && this.contentLength > 1;
    },
    actionItems() {
      const items = [];

      if (this.availableActions.includes(ITEM_ACTIONS.MARK_REMOVAL)) {
        items.push({
          text: this.isMarkedForRemoval ? 'Unmark removal' : 'Mark for removal',
          value: this.isMarkedForRemoval ? 'unmark_removal' : 'mark_removal',
          iconClass: this.isMarkedForRemoval ? 'fa-trash-can-undo' : 'fa-trash-can',
        });
      }

      if (this.availableActions.includes(ITEM_ACTIONS.EDIT_CONTENT)) {
        items.push({
          text: 'Edit',
          value: 'edit_content',
          iconClass: 'fa-pen',
          disabled: this.isMarkedForRemoval,
        });
      }

      return items;
    },
  },
  methods: {
    onOptionSelect(option) {
      switch (option.value) {
        case ITEM_ACTIONS.MARK_REMOVAL: {
          return this.$emit(MARK_REMOVAL, this.content.clientId);
        }
        case ITEM_ACTIONS.UNMARK_REMOVAL: {
          return this.$emit(UNMARK_REMOVAL, this.content.clientId);
        }
        case ITEM_ACTIONS.EDIT_CONTENT: {
          return this.$emit('edit', this.content);
        }
        default:
          throw new Error('Not a valid option');
      }
    },
    moveUpward() {
      if (this.canMoveUpward) {
        this.$emit(MOVE_ITEM_UP, this.contentIndex);
      }
    },
    moveDownward() {
      if (this.canMoveDownward) {
        this.$emit(MOVE_ITEM_DOWN, this.contentIndex);
      }
    },
  },
};
</script>

<style scoped>
.BlockContentWrapper {
  position: relative;
  background-color: var(--kog-colors-white);
}

.BlockContentWrapper-iconContainer {
  position: absolute;
  top: -5px;
  left: -45px;

  min-width: 40px;
  height: 40px;

  border: 1px solid var(--kog-border-brand-900);
  border-radius: 100%;
}

.BlockContentWrapper-icon {
  color: var(--kog-text-brand);
  fill: var(--kog-text-brand);
}

.BlockContentWrapper-contentBoxPreview :deep(.content-editable),
.BlockContentWrapper-contentBoxPreview :deep(.content-editable p) {
  font-family: var(--kog-satoshi);
  font-size: 14px;
}

.BlockContentWrapper-unavailable {
  background-color: var(--kog-colors-yellow-100);
}

.BlockContentWrapper-markedForRemoval {
  opacity: 0.6;
  background-color: var(--kogShadow020) !important;
}

.BlockContentWrapper-moveUpward,
.BlockContentWrapper-moveDownward {
  margin-right: auto;
  margin-left: auto;

  text-align: center;

  visibility: hidden;
  background-color: var(--kogPlatformWhite);
  border-color: var(--kogPlatformGray077);
}

.BlockContentWrapper:hover .BlockContentWrapper-moveUpward,
.BlockContentWrapper:hover .BlockContentWrapper-moveDownward {
  visibility: visible;
}

.BlockContentWrapper-error,
.BlockContentWrapper-warning {
  border-radius: var(--space-xxs);
  outline: 2px dashed transparent;
  outline-offset: var(--space-xs);
}

.BlockContentWrapper-error {
  outline-color: var(--kog-border-danger-600);

  & [class^='KogIcon--'] {
    color: var(--kog-border-danger-600);
  }
}

.BlockContentWrapper-warning {
  outline-color: var(--kog-colors-yellow-500);

  & [class^='KogIcon--'] {
    color: var(--kog-colors-yellow-500);
  }
}
</style>
