<template>
  <component
    :is="headingProps.component"
    :class="[classAttr, headingProps.class]"
    :style="styleAttr"
  >
    {{ text }}
  </component>
</template>

<script>
import useStyleClassExtractedAttrs from 'sharedApp/composables/use-style-class-extracted-attrs.ts';

export default {
  name: 'ActivityHeadingBlock',
  inheritAttrs: false,
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const extractedAttrs = useStyleClassExtractedAttrs();

    return {
      ...extractedAttrs,
    };
  },
  computed: {
    headingProps() {
      switch (this.content.level) {
        case 1:
          return { component: 'h1', class: 'heading-xl' };
        case 2:
          return { component: 'h2', class: 'heading-l' };
        case 3:
          return { component: 'h3', class: 'heading-m' };
        case 4:
          return { component: 'h4', class: 'heading-s' };
        case 5:
          return { component: 'h5', class: 'heading-xs' };
        case 6:
          return { component: 'h6', class: 'heading-xxs' };
        default:
          return { component: 'h2', class: 'heading-l' };
      }
    },
    text() {
      return this.content.text;
    },
  },
};
</script>
