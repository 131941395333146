import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const drivingQuestionDetailsUrl =
  '/api/features/dqb/:featureId/subject-class/:subjectClassId/driving-questions/:drivingQuestionId/';
const drivingQuestionListURL = '/api/features/dqb/subject-class/:subjectClassId/driving-questions/';
const drivingQuestionImagesUrl = `${drivingQuestionDetailsUrl}images/`;

const drivingQuestionDetailsUrlToPath = compile(drivingQuestionDetailsUrl);
const drivingQuestionListUrlToPath = compile(drivingQuestionListURL);
const drivingQuestionImagesUrlToPath = compile(drivingQuestionImagesUrl);

export async function fetchDrivingQuestions(subjectClassId) {
  const url = drivingQuestionListUrlToPath({ subjectClassId });
  const result = await axios.get(url);
  return result.data;
}

export async function createDrivingQuestion({ subjectClassId, featureId, externalUrl }) {
  const url = drivingQuestionListUrlToPath({ subjectClassId });
  const response = await axios.post(url, {
    driving_question_board: featureId,
    external_url: externalUrl,
  });
  return response.data;
}

export async function updateDrivingQuestion({
  subjectClassId,
  featureId,
  drivingQuestionId,
  externalUrl,
}) {
  const url = drivingQuestionDetailsUrlToPath({ featureId, subjectClassId, drivingQuestionId });
  const response = await axios.patch(url, {
    external_url: externalUrl,
  });
  return response.data;
}

export async function uploadDrivingQuestionImages({
  subjectClassId,
  featureId,
  drivingQuestionId,
  images,
}) {
  const url = drivingQuestionImagesUrlToPath({ featureId, subjectClassId, drivingQuestionId });
  const formData = new FormData();
  images.forEach(image => {
    formData.append('images', image);
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await axios.post(url, formData, config);
  return response.data;
}

export async function deleteDrivingQuestionImages({
  subjectClassId,
  featureId,
  drivingQuestionId,
  imageIds,
}) {
  const url = drivingQuestionImagesUrlToPath({ featureId, subjectClassId, drivingQuestionId });
  const params = new URLSearchParams();
  imageIds.forEach(id => {
    params.append('id', id);
  });
  const response = await axios.delete(url, { params });
  return response.data;
}
