const listSeparator = (index, totalItems) => {
  if (index === 0) {
    return '';
  }
  if (index === totalItems - 1) {
    return ' and ';
  }
  return ', ';
};

function capitalizeFirstLetter(str) {
  return `${str[0].toLocaleUpperCase()}${str.slice(1)}`;
}

module.exports = {
  listSeparator,
  capitalizeFirstLetter,
};
