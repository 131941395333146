<template>
  <div class="flexContainer margin-top-l">
    <strength-card
      class="flexChild-size-1"
      :is-table-data-loading="isStrengthTestTableDataLoading"
      :strength-items="strengthTests"
      strength-item-term="strength test"
      :pagination-data="strengthTestsPagination"
      @go-to-table-page="goToStrengthTestPage"
    >
      <template #tableHeader>
        <kog-table-header>
          <kog-table-cell-header> Questions from </kog-table-cell-header>
          <kog-table-cell-header> Score </kog-table-cell-header>
          <kog-table-cell-header> Date </kog-table-cell-header>
        </kog-table-header>
      </template>
      <template #tableBody>
        <kog-table-row
          v-for="strengthTest in strengthTests"
          :key="strengthTest.id"
        >
          <kog-table-cell-generic>
            <ul class="StrengthTestsBattlesTab-topicsList margin-0 padd-0">
              <li
                v-for="topicName in strengthTest.topics_related_to_occasion"
                :key="topicName"
              >
                {{ topicName }}
              </li>
            </ul>
          </kog-table-cell-generic>
          <kog-table-cell-text> {{ getStrengthTestScore(strengthTest) }}% </kog-table-cell-text>
          <kog-table-cell-text>
            {{ getStrengthTestDate(strengthTest) }}
          </kog-table-cell-text>
        </kog-table-row>
      </template>
    </strength-card>
    <strength-card
      class="flexChild-size-1 margin-left-xs"
      :is-table-data-loading="isBattlesTableDataLoading"
      :strength-items="battles"
      strength-item-term="battle"
      :pagination-data="battlesPagination"
      @go-to-table-page="goToBattlesPage"
    >
      <template #tableHeader>
        <kog-table-header>
          <kog-table-cell-header> Topics included </kog-table-cell-header>
          <kog-table-cell-header> Score </kog-table-cell-header>
          <kog-table-cell-header> Date </kog-table-cell-header>
        </kog-table-header>
      </template>
      <template #tableBody>
        <kog-table-row
          v-for="battle in battles"
          :key="battle.id"
        >
          <kog-table-cell-text :is-multi-line="true">
            {{ battle.topic_name }}
          </kog-table-cell-text>
          <kog-table-cell-text> {{ getBattleScore(battle) }}% </kog-table-cell-text>
          <kog-table-cell-text>
            {{ getBattleDate(battle) }}
          </kog-table-cell-text>
        </kog-table-row>
      </template>
    </strength-card>
  </div>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapState } from 'vuex';

import KogTableCellGeneric from 'sharedApp/components/tables/kog-table-cell-generic.vue';
import KogTableCellHeader from 'sharedApp/components/tables/kog-table-cell-header.vue';
import KogTableCellText from 'sharedApp/components/tables/kog-table-cell-text.vue';
import KogTableHeader from 'sharedApp/components/tables/kog-table-header.vue';
import KogTableRow from 'sharedApp/components/tables/kog-table-row.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import { dateFormatter } from 'sharedApp/utils/time-utils.js';

import StrengthCard from './strength-card.vue';

export default {
  name: 'StrengthTestsBattlesTab',
  components: {
    KogTableRow,
    KogTableHeader,
    KogTableCellGeneric,
    KogTableCellHeader,
    KogTableCellText,
    StrengthCard,
  },
  mixins: [RoutesMixin],
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('teacherInsightsModule', {
      strengthTests: state => state.studentStrengthTests,
      strengthTestsPagination: state => state.studentStrengthTestsPagination,
      battles: state => state.studentBattles,
      battlesPagination: state => state.studentBattlesPagination,
    }),
    isStrengthTestTableDataLoading() {
      return this.$wait.waiting('fetching_strength_test_data');
    },
    isBattlesTableDataLoading() {
      return this.$wait.waiting('fetching_battles_data');
    },
  },
  methods: {
    ...mapWaitingActions('teacherInsightsModule', {
      fetchStudentStrengthTests: 'fetching_strength_test_data',
      fetchStudentBattles: 'fetching_battles_data',
    }),
    goToStrengthTestPage(page) {
      this.fetchStudentStrengthTests({
        subjectClassId: this.classId,
        userId: this.userId,
        page,
      });
    },
    getStrengthTestScore(strengthTest) {
      const percent = strengthTest.correct_answer_count / strengthTest.total_answer_count;
      return Math.floor(percent * 100);
    },
    getStrengthTestDate(strengthTest) {
      return dateFormatter(new Date(strengthTest.done_at));
    },
    goToBattlesPage(page) {
      this.fetchStudentBattles({
        subjectClassId: this.classId,
        userId: this.userId,
        page,
      });
    },
    getBattleScore(battle) {
      const percent = battle.correct_answer_count / battle.total_answer_count;
      return Math.floor(percent * 100);
    },
    getBattleDate(battle) {
      return dateFormatter(new Date(battle.done_at));
    },
  },
};
</script>

<style scoped>
.StrengthTestsBattlesTab-topicsList {
  list-style: none;
}
</style>
