<template>
  <div>
    <h3 class="heading-s margin-bottom-xs"> Current battles </h3>
    <battles-list
      v-if="ongoingBattles"
      :battles="ongoingBattles"
      empty-message="You have no current battles. Start one now!"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BattlesList from './battles-list.vue';

export default {
  name: 'CurrentBattles',
  components: {
    BattlesList,
  },
  computed: {
    ...mapState('battlesModule', ['ongoingBattles']),
  },
};
</script>
