<template>
  <div class="AssignmentSendoutStep-container">
    <integration-assignment-alert class="AssignmentSendoutStep-integrationAlert" />
    <div class="AssignmentSendoutStep">
      <!--Name-->
      <div class="AssignmentSendoutStep-section">
        <div class="heading-s padd-bottom-m"> Name </div>
        <input
          id="assignment_name"
          v-model.lazy="inputModel.name"
          class="KogFormInput"
          name="assignment_name"
          type="text"
          maxlength="150"
          required
          aria-label="Assignment name"
          @change="validateAndSaveAssignment()"
        />
        <div
          v-if="errors.name"
          class="KogFormInput-helper KogFormInput-helper--error"
        >
          {{ errors.name }}
        </div>
        <div
          v-else
          class="muted padd-top-xxs"
        >
          Tip: Name it something re-usable in the future
        </div>
      </div>
      <!--/Name-->

      <!-- LMS Integration-->
      <assignment-lms-integration-container
        v-if="subjectClass"
        class="AssignmentSendoutStep-section"
        :subject-class="subjectClass"
        :send-to-integration="inputModel.send_to_integration"
        :assignment-type="assignmentType"
        :points-possible="inputModel.integration_settings?.points_possible"
        :categories-by-class-id="categoriesByClassId"
        @send-to-integration-updated="updateSendToIntegration"
        @points-possible-updated="updatePointsPossible"
        @categories-populated="bulkUpdateLmsCategory"
      />
      <!-- /LMS Integration -->

      <!--Recipients-->
      <div class="AssignmentSendoutStep-section">
        <assignment-sendout-recipients-container
          v-if="subjectClass"
          :is-student-selected-by-id="inputModel.student_selected_status_by_id"
          :student-list-by-class-id="studentListByClassId"
          :update-selected-students="updateSelectedStudents"
          :update-selected-classes-and-students="updateSelectedClassesAndStudents"
          :query-params="$route.query"
          :assignment="assignment"
          :input-model="inputModel"
          :subject-class="subjectClass"
          :google-classroom-recipients="inputModel.google_classroom_recipients"
          @send-to-integration-updated="updateSendToIntegration"
          @categories-populated="bulkUpdateLmsCategory"
        />
      </div>
      <!--/Recipients-->

      <!--Show mark scheme (ESQ)-->
      <div
        v-if="isExamStyle"
        class="AssignmentSendoutStep-section"
      >
        <div class="heading-s padd-bottom-m"> Mark scheme control </div>

        <div>
          <div>
            <esq-markscheme-setting-info
              v-if="inputModel.markscheme_status"
              :mark-scheme-status="inputModel.markscheme_status"
            />
          </div>
          <kog-button
            button-style="basic"
            size="small"
            label="Change"
            @click="showMarkschemeLockStatusModal"
          />
        </div>
      </div>
      <!--/Show mark scheme (ESQ)-->

      <!--Arrange-->
      <div class="AssignmentSendoutStep-section">
        <div class="padd-bottom-m">
          <span class="heading-s margin-right-s"> Arrange </span>
          <span class="muted"> {{ items.length }} {{ isReading ? 'sections' : 'questions' }} </span>
        </div>
        <div
          v-if="isReading && hasHiddenSections"
          class="padd-bottom-m"
        >
          <hidden-content-warning />
        </div>
        <assignment-arrange-list
          :items="items"
          :remove-item="removeItem"
          :update-items="updateItems"
          :assignment-type="assignmentType"
          :show-difficulty="showDifficulty"
          :get-question-locations="getQuestionLocations"
          :subject-name="subjectName"
          :education-system="educationSystem"
          :hidden-subject-node-ids="hiddenSubjectNodeIds"
        />
      </div>
      <!--/Arrange-->

      <!--Instructions-->
      <div class="AssignmentSendoutStep-section">
        <div class="heading-s padd-bottom-m"> Instructions </div>
        <textarea
          id="assignment_message"
          v-model="inputModel.message"
          placeholder="e.g. Please pay attention to the concepts covered in topic 4.1"
          class="KogFormInput"
          rows="3"
          aria-label="Instructions"
          @change="validateAndSaveAssignment()"
        />
      </div>
      <!--/Instructions-->

      <!--Deadline-->
      <div class="AssignmentSendoutStep-section">
        <div class="heading-s padd-bottom-m"> Deadline </div>
        <div class="input-group cursorPointer">
          <div
            class="input-group-addon calendar-icon-container"
            data-toggle
          >
            <kog-icon
              icon-class="fa-calendar"
              fa-style="regular"
              size="s-touch"
              :is-non-actionable="true"
            />
          </div>
          <flat-pickr
            id="deadline"
            v-model="inputModel.deadline"
            :config="config"
            @on-close="validateAndSaveAssignment()"
          />
        </div>
        <div
          v-show="errors.deadline"
          class="KogFormInput-helper KogFormInput-helper--error"
        >
          {{ errors.deadline }}
        </div>
        <div class="padd-top-xxs flexContainer">
          <kog-button
            button-style="basic"
            has-icon
            icon-class="fa-calendar"
            label="Set deadline tomorrow"
            @click.prevent="setDeadlineToTomorrow()"
          />
          <kog-button
            button-style="basic"
            has-icon
            icon-class="fa-calendar"
            label="Set deadline in a week"
            @click.prevent="setDeadlineInOneWeek()"
          />
        </div>
        <!--/Deadline-->

        <!-- Allow late submission settings (QUESTION)-->
        <kog-checkbox
          v-if="isQuestion"
          v-model:is-checked="inputModel.soft_deadline"
          class="margin-left-xxs"
          label="Allow late submissions"
          tooltip-text="Selecting this allows students to submit their responses after the
              deadline passes"
          @toggled="validateAndSaveAssignment()"
        />
      </div>
      <!-- /Allow late submission settings (QUESTION)-->

      <!-- Display answers settings (QUESTION)-->
      <div
        v-if="isQuestion"
        class="AssignmentSendoutStep-section"
      >
        <div class="heading-s padd-bottom-m">Display answers</div>
        <kog-radio-group
          id="display_answers_setting"
          v-model:selected-value="inputModel.display_answers_setting"
          aria-label="Display answers setting"
          :options="displayAnswersSettings"
          @checked="validateAndSaveAssignment"
        />
      </div>
      <!--/Display answers settings (QUESTION)-->
    </div>
  </div>
</template>

<script>
import { provide, ref } from 'vue';
import { cloneDeep } from 'lodash';
import flatPickr from 'vue-flatpickr-component';
import { mapActions, mapState } from 'vuex';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';
import KogRadioGroup from 'sharedApp/components/base/radio/kog-radio-group.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import { ASSIGNMENT_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import { getDateAsDateObject } from 'sharedApp/utils/time-utils.js';
import IntegrationAssignmentAlert from 'studyApp/components/assignment/integration-assignment-alert.vue';
import HiddenContentWarning from 'studyApp/components/teacher/assignment-create/hidden-content-warning.vue';
import AssignmentArrangeList from 'studyApp/components/teacher/assignment-create/sendout-step/assignment-arrange-list.vue';
import NewDisplayAnswersSettingModal from 'studyApp/components/teacher/assignment-create/teacher-questions/new-display-answers-setting-modal.vue';
import EsqMarkschemeSettingInfo from 'studyApp/components/teacher/assignments/esq-markscheme-setting-info.vue';
import { DISPLAY_ANSWERS_SETTING_OPTIONS } from 'studyApp/constants/teacher-assignment-constants.ts';
import { setPreferredDeadlineHour } from 'teachApp/utils/assignment-utils.js';
import ManualTimeInputPlugin from 'teachApp/utils/flatPickrPlugins/manual-time-input-plugin.js';

import AssignmentLmsIntegrationContainer from './sendout-step/assignment-lms-integration-container.vue';
import AssignmentSendoutRecipientsContainer from './sendout-step/assignment-sendout-recipients-container.vue';
import SelectMarkschemeLockStatusModal from './sendout-step/select-markscheme-lock-status-modal.vue';

function getTomorrowDate() {
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  return tomorrowDate;
}

function getWeekAheadDate() {
  const oneWeekAheadDate = new Date();
  oneWeekAheadDate.setDate(oneWeekAheadDate.getDate() + 7);
  return oneWeekAheadDate;
}

export default {
  name: 'AssignmentSendoutStep',
  components: {
    IntegrationAssignmentAlert,
    HiddenContentWarning,
    flatPickr,
    AssignmentArrangeList,
    EsqMarkschemeSettingInfo,
    AssignmentSendoutRecipientsContainer,
    AssignmentLmsIntegrationContainer,
    KogButton,
    KogCheckbox,
    KogRadioGroup,
    KogIcon,
  },
  provide() {
    return {
      updateLmsCategory: ({ categoryId, classId }) => {
        this.updateLmsCategory({
          categoryId,
          classId,
        });
      },
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    subjectName: {
      type: String,
      required: true,
    },
    subjectClassId: {
      type: Number,
      required: true,
    },
    updateSelectedStudents: {
      type: Function,
      required: true,
    },
    updateSelectedClassesAndStudents: {
      type: Function,
      required: true,
    },
    studentListByClassId: {
      type: Object,
      required: true,
    },
    saveAssignmentHandler: {
      type: Function,
      required: true,
    },
    assignment: {
      type: Object,
      required: true,
    },
    getQuestionLocations: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    removeItem: {
      type: Function,
      required: true,
    },
    updateItems: {
      type: Function,
      required: true,
    },
    showDifficulty: {
      type: Boolean,
      default: false,
    },
    validateAssignmentForSending: {
      type: Function,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    educationSystem: {
      type: String,
      required: true,
    },
    hiddenSubjectNodeIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input', 'update:value'],
  setup() {
    const disabledSendAssignmentToLmsTooltip = ref('');
    provide('disabledSendAssignmentToLmsTooltip', disabledSendAssignmentToLmsTooltip);
  },
  data() {
    return {
      isNewDisplayAnswersSettingFamiliar: false,
      displayNewAnswersSettingModalKey: '',
      displayAnswersSettings: DISPLAY_ANSWERS_SETTING_OPTIONS,
      inputModel: {},
      config: {
        altInput: true,
        altInputClass: 'KogFormInput--inputGroup KogFormInput',
        altFormat: 'Y-m-d H:i',
        dateFormat: 'Z',
        enableTime: true,
        allowInput: false,
        time_24hr: true,
        minDate: new Date(),
        wrap: true,
        minuteIncrement: 1,
        plugins: [new ManualTimeInputPlugin()],
      },
    };
  },
  computed: {
    ...mapState({
      subjectClass: state => state.schoolstaffSubjectclassModule.subjectClass,
      user: state => state.userModule.user,
    }),
    assignmentType() {
      return this.assignment.assignment_type;
    },
    isQuestion() {
      return this.assignmentType === ASSIGNMENT_TYPES.QUESTION;
    },
    isReading() {
      return this.assignmentType === ASSIGNMENT_TYPES.READING;
    },
    isExamStyle() {
      return this.assignmentType === ASSIGNMENT_TYPES.EXAMSTYLE;
    },
    hasHiddenSections() {
      return this.items.some(item => this.hiddenSubjectNodeIds.includes(item.id));
    },
    categoriesByClassId() {
      return this.inputModel.integration_settings.categories_by_class_id ?? {};
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.initInputModel();
      },
    },
    // eslint-disable-next-line func-names
    'inputModel.deadline': function () {
      setPreferredDeadlineHour(
        getDateAsDateObject(this.inputModel.deadline),
        this.user.id,
        this.subjectClassId,
      );
    },
  },
  async created() {
    this.initInputModel();
    this.setValidDeadline();

    this.displayNewAnswersSettingModalKey = `is-new-display-answers-setting-familiar-${this.user.id}`;
    this.isNewDisplayAnswersSettingFamiliar = localStorage[this.displayNewAnswersSettingModalKey];
    if (!this.isNewDisplayAnswersSettingFamiliar && this.isQuestion) {
      this.showNewDisplayAnswersSettingModal();
    }

    const subjectNodesIds = this.items.map(item => item.id);
    await this.fetchFeaturesAndMappings({ subjectNodeIds: subjectNodesIds });
  },
  methods: {
    ...mapActions('subjectNodeFeatureModule', ['fetchFeaturesAndMappings']),
    initInputModel() {
      const localValue = cloneDeep(this.value);
      localValue.deadline = getDateAsDateObject(localValue.deadline);
      this.inputModel = localValue;
    },
    setValidDeadline() {
      const { deadline } = this.inputModel;
      const dateNow = new Date();
      const assignmentWithPastDeadline = deadline && getDateAsDateObject(deadline) <= dateNow;
      if (assignmentWithPastDeadline || !deadline) {
        this.setDeadlineToTomorrow();
      }
    },
    showNewDisplayAnswersSettingModal() {
      this.$modal(NewDisplayAnswersSettingModal, {
        modalShownStorageKey: this.displayNewAnswersSettingModalKey,
      });
    },
    setDeadlineToTomorrow() {
      this.inputModel.deadline = getTomorrowDate();
      this.validateAndSaveAssignment();
    },
    setDeadlineInOneWeek() {
      this.inputModel.deadline = getWeekAheadDate();
      this.validateAndSaveAssignment();
    },
    validateAndSaveAssignment() {
      this.$emit('update:value', this.inputModel);
      this.$emit('input', this.inputModel);
      if (this.validateAssignmentForSending()) {
        this.saveAssignmentHandler();
      }
    },
    updateMarkschemeStatus(status) {
      this.inputModel.markscheme_status.unlocked_setting = status;
      this.validateAndSaveAssignment();
    },
    updateSendToIntegration(sendToIntegration) {
      this.inputModel.send_to_integration = sendToIntegration;
      this.validateAndSaveAssignment();
    },
    updateLmsCategory({ categoryId, classId }) {
      if (!this.inputModel.integration_settings.categories_by_class_id) {
        this.inputModel.integration_settings.categories_by_class_id = {};
      }
      this.inputModel.integration_settings.categories_by_class_id[classId] = categoryId;
      this.validateAndSaveAssignment();
    },
    bulkUpdateLmsCategory(categoriesByClassId) {
      this.inputModel.integration_settings.categories_by_class_id = categoriesByClassId;
      this.validateAndSaveAssignment();
    },
    updatePointsPossible(pointsPossible) {
      this.inputModel.integration_settings.points_possible = pointsPossible;
      this.validateAndSaveAssignment();
    },
    showMarkschemeLockStatusModal() {
      return this.$modal(SelectMarkschemeLockStatusModal, {
        markschemeStatus: this.inputModel.markscheme_status.unlocked_setting,
        updateMarkschemeStatus: this.updateMarkschemeStatus,
      });
    },
  },
};
</script>

<style scoped>
.AssignmentSendoutStep-container {
  min-width: 100%;
}

.AssignmentSendoutStep {
  max-width: 750px;
  margin: var(--space-xs) auto var(--space-m);
  border-radius: 8px;
  box-shadow: 0 8px 16px -4px var(--kogShadow020);
}

.AssignmentSendoutStep-section {
  padding: var(--space-l);
  border-bottom: 1px solid var(--kogPlatformGray090);
}

.AssignmentSendoutStep-section:last-of-type {
  border-bottom: none;
}

.AssignmentSendoutStep-integrationAlert {
  max-width: 750px;
  margin: auto;
}
</style>

<style>
.KogFormInput--inputGroup {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.calendar-icon-container {
  padding-right: var(--space-xs) !important;
  padding-left: var(--space-xs) !important;
}
</style>
