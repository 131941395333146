<template>
  <div
    :class="{
      'MultipleChoiceQuestionResultCorrectChoice-correctUserChoice': selectedByUser,
    }"
    class="MultipleChoiceQuestionResultCorrectChoice list-group-item"
  >
    <div
      class="MultipleChoiceQuestionResultCorrectChoice-questionNumber is-topRight"
      aria-hidden="true"
    >
      {{ correctChoice._numberSymbol }}
    </div>
    <p
      v-if="selectedByUser"
      class="heading-xxs margin-bottom-xxs"
    >
      Your choice
    </p>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-kog-mathjax
      class="content-editable"
      v-html="correctChoice.answer_html"
    />
    <div v-if="answerExplanationHtml">
      <p class="margin-top-m heading-xxs margin-bottom-xxs"> Explanation: </p>
      <div
        v-kog-mathjax
        class="content-editable"
        v-html="answerExplanationHtml"
      />
    </div>
    <!-- eslint-enable vue/no-v-html -->
    <p
      v-if="isOpponentAnswerCorrect && opponentName"
      class="kogPlatformGray031 margin-right-l"
    >
      {{ opponentName }}'s choice
    </p>
  </div>
</template>
<script>
export default {
  name: 'MultipleChoiceQuestionResultCorrectChoice',
  props: {
    correctChoice: {
      type: Object,
      required: true,
    },
    opponentName: {
      type: String,
      default: 'Opponent',
    },
    isOpponentAnswerCorrect: {
      type: Boolean,
      default: false,
    },
    answerExplanationHtml: {
      type: String,
      default: '',
    },
    userResponse: {
      type: Array,
      default: undefined,
    },
  },
  computed: {
    selectedByUser() {
      return this.userResponse && this.userResponse.includes(this.correctChoice.id);
    },
  },
};
</script>
<style scoped>
.MultipleChoiceQuestionResultCorrectChoice {
  display: block;
  background: none;
}

.MultipleChoiceQuestionResultCorrectChoice :deep(.content-editable img) {
  pointer-events: none;
}

.MultipleChoiceQuestionResultCorrectChoice-questionNumber {
  position: absolute;
  top: -1px;
  right: -1px;

  padding: 5px;

  color: var(--kogPlatformWhite);
  text-align: center;

  background-color: var(--kogPlatformGray031);
  border-radius: 0 0 0 5px;
}

.MultipleChoiceQuestionResultCorrectChoice-questionNumber.is-topRight {
  border-radius: 0 4px 0 5px;
}

.MultipleChoiceQuestionResultCorrectChoice-correctUserChoice {
  color: var(--kogPlatformGreenDarken20);
  background-color: var(--kogPlatformGreenLighten20);
}
</style>
