<template>
  <span
    aria-hidden="true"
    class="ExerciseQuestionStatusIcon"
    :class="getStatusClass"
  >
    <kog-font-awesome-icon
      :fa-class="getIconClass"
      fa-style="solid"
      size="custom"
      :custom-size="16"
    />
  </span>
</template>

<script>
import KogFontAwesomeIcon from 'sharedApp/components/icons/kog-font-awesome-icon.vue';

export default {
  name: 'ExerciseQuestionStatusIcon',
  components: {
    KogFontAwesomeIcon,
  },
  props: {
    hasAnswered: {
      type: Boolean,
      default: false,
    },
    hasAnsweredCorrectly: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    getStatusClass() {
      if (!this.hasAnswered) {
        return null;
      }

      const classes = ['ExerciseQuestionStatusIcon--hasAnswer'];

      if (this.hasAnsweredCorrectly === null) {
        return classes;
      }

      if (this.hasAnsweredCorrectly) {
        classes.push('ExerciseQuestionStatusIcon--rightAnswer');
      } else {
        classes.push('ExerciseQuestionStatusIcon--wrongAnswer');
      }

      return classes;
    },
    getIconClass() {
      if (this.hasAnswered && this.hasAnsweredCorrectly !== null) {
        return this.hasAnsweredCorrectly ? 'fa-check' : 'fa-xmark';
      }

      return 'fa-question';
    },
  },
};
</script>

<style scoped>
.ExerciseQuestionStatusIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--space-xl);
  min-width: var(--space-xl);
  height: var(--space-xl);
  min-height: var(--space-xl);

  color: var(--kog-colors-aubergine-600);

  border: 2px dashed var(--kog-colors-aubergine-600);
  border-radius: 50%;
}

.ExerciseQuestionStatusIcon--hasAnswer {
  background-color: var(--kog-colors-aubergine-100);
  border-style: solid;
}

.ExerciseQuestionStatusIcon--wrongAnswer {
  color: var(--kog-colors-white);
  background-color: var(--kog-colors-pink-800);
  border-style: none;
}

.ExerciseQuestionStatusIcon--rightAnswer {
  color: var(--kog-colors-white);
  background-color: var(--kog-colors-green-800);
  border-style: none;
}
</style>
