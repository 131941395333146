<template>
  <kog-loader
    class="kog-container"
    :loading="$wait.is('edit_assignment/fetching*')"
    loading-msg="Fetching assignment"
  >
    <h1 class="heading-m padd-left-s margin-bottom-s"> Edit assignment </h1>
    <teacher-assignment-edit
      v-if="assignment"
      ref="TeacherAssignmentEdit"
      :assignment="assignment"
      @update:assignment="assignment = $event"
      @reload:assignment="fetchAssignmentDetails"
    />
    <div class="padd-l flexContainer flexContainer-flexEnd">
      <kog-button
        label="Save assignment"
        button-style="primary"
        :is-loading="$wait.is('edit_assignment/save_assignment')"
        @click="saveAssignment"
      />
    </div>
  </kog-loader>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';

import { AssignmentsService } from '@apis/generated/services.ts';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogLoader from 'sharedApp/components/base/indicators/kog-loader.vue';
import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';
import GoogleClassroomMixin from 'studyApp/mixins/teacher/google-classroom-mixin.js';
import TeacherAssignmentEdit from 'studyApp/pages/assignment/teacher-assignment-edit.vue';

export default {
  name: 'TeacherAssignmentEditContainer',
  components: {
    TeacherAssignmentEdit,
    KogButton,
    KogLoader,
  },
  mixins: [GoogleClassroomMixin, RoutesMixin],
  metaInfo() {
    return {
      title: `${this.assignment?.name ? `${this.assignment.name} | ` : ''}Edit assignment`,
    };
  },
  data() {
    return {
      assignment: null,
    };
  },
  computed: {
    assignmentId() {
      return this.$route.params.assignmentId;
    },
  },
  created() {
    this.fetchAssignmentDetails(this.assignmentId);
    this.fetchSubject({ subjectId: this.subjectId });
    this.fetchSubjectClass({ subjectClassId: this.classId, useCache: false });
    this.fetchSubjectClassStudentsList(this.classId);
  },
  methods: {
    ...mapWaitingActions('schoolstaffSubjectclassModule', {
      fetchSubjectClass: 'edit_assignment/fetching_subject_class',
    }),
    ...mapWaitingActions('schoolstaffSubjectModule', {
      fetchSubject: 'edit_assignment/fetching_subject',
    }),
    ...mapWaitingActions('teacherStudentListModule', {
      fetchSubjectClassStudentsList: 'edit_assignment/fetching_subject_class_students',
    }),
    async fetchAssignmentDetails() {
      this.$wait.start('edit_assignment/fetching_assignment_details');
      this.assignment = await AssignmentsService.assignmentsRetrieve({
        assignmentId: this.assignmentId,
      });
      this.$wait.end('edit_assignment/fetching_assignment_details');
    },
    async saveAssignment() {
      await this.$refs.TeacherAssignmentEdit.saveAssignmentDetails();
      this.goToAssignmentDetails();
    },
    goToAssignmentDetails() {
      this.$router.push({
        name: 'teacherAssignmentDetails',
        params: {
          classSlug: this.subjectClassSlug,
          sid: this.subjectId,
          cid: this.classId,
          assignmentId: this.assignmentId,
        },
      });
    },
  },
};
</script>
