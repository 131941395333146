<template>
  <div
    class="StudyMiniNavbar-container positionFixed positionFixed-topRight z-index-1 kogPlatformWhite-bg"
  >
    <study-navbar-account-group
      :user="user"
      :subject="subject"
      :notifications="notifications"
      :mark-as-seen="markAsSeen"
      theme="primary"
    />
  </div>
</template>

<script>
import StudyNavbarAccountGroup from 'learning/common/components/study-navbar-account-group.vue';

export default {
  name: 'StudyMiniNavbar',
  components: {
    StudyNavbarAccountGroup,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    markAsSeen: {
      type: Function,
      default: () => {},
    },
    subject: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.StudyMiniNavbar-container {
  padding: 8px 0 8px 12px;
  border-radius: 100px;
}
</style>
